import {Product} from "@natomas-org/core";

export const sortProducts = (products) => {
  return products.sort((a, b) => {
    let aPrice = Product.getPriceInMicros(a.price, a.priceMicros);
    let bPrice = Product.getPriceInMicros(b.price, b.priceMicros);
    if (aPrice === bPrice) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
    }
    return aPrice - bPrice;
  });
};

export const getModels = (products) => {
  return products.filter((p) => {
    return p && !p.inventory_info;
  });
};
