import React, {useMemo} from "react";
import styled from "styled-components";
import {BLACK, WHITE} from "../../../../../_shared/colors";
import model1 from "./model1.jpeg";
import model2 from "./model2.jpeg";
import model3 from "./model3.jpeg";
import {IInstantQuoteCustomerData} from "../../../../../admin/DeveloperView/InstantQuoteContinue/components/QuestionnaireForm/interface";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";

const InstantQuoteModelHomes = (props: {
  customerData?: IInstantQuoteCustomerData;
}) => {
  const {customerData} = props;
  const utm = useSelector((state: IStore) => state.utm);
  const {utm_source, utm_campaign, utm_content, utm_medium, utm_term} = utm;
  const utmParameters = useMemo(() => {
    let utmString = "";
    if (utm_source) {
      utmString += `&utm_source=${utm_source}`;
    }
    if (utm_campaign) {
      utmString += `&utm_campaign=${utm_campaign}`;
    }
    if (utm_content) {
      utmString += `&utm_content=${utm_content}`;
    }
    if (utm_medium) {
      utmString += `&utm_medium=${utm_medium}`;
    }
    if (utm_term) {
      utmString += `&utm_term=${utm_term}`;
    }
    return utmString;
  }, [utm_source, utm_campaign, utm_content, utm_medium, utm_term]);
  const parameters = useMemo(() => {
    if (!customerData) return "";
    return (
      "&invitee_first_name=" +
      customerData["first-name"] +
      "&invitee_last_name=" +
      customerData["last-name"] +
      "&invitee_email=" +
      customerData.email
    );
  }, [customerData, utm]);
  const getLink = (link: string) => {
    return link + "?ref=instant_quote" + parameters + utmParameters;
  };
  return (
    <Container>
      <Header>Schedule a model home tour</Header>
      <TileContainer>
        <InstantQuoteModelHomeTile
          title={"Alamo, East Bay"}
          subTitle={"3 bed • 2 bath • 1,196 sq. ft. • H1200b"}
          image={model1}
          link={getLink(
            "https://calendly.com/villa-model-homes/alamo-model-home-tour-villa"
          )}
        />
        <InstantQuoteModelHomeTile
          title={"Saratoga, South Bay"}
          subTitle={"2 bed • 2 bath • 800 sq. ft. • H800"}
          image={model2}
          link={getLink(
            "https://calendly.com/villa-model-homes/saratoga-model-home-tour"
          )}
        />
        <InstantQuoteModelHomeTile
          title={"San Diego"}
          subTitle={"3 bed • 2 bath • 1,000 sq. ft. • H1000b"}
          image={model3}
          link={getLink("https://calendly.com/villa-model-homes/san-diego")}
        />
      </TileContainer>
    </Container>
  );
};

const InstantQuoteModelHomeTile = (props: {
  title: string;
  subTitle: string;
  image: string;
  link: string;
}) => {
  const {title, subTitle, image, link} = props;
  return (
    <Tile
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <TileTitle>{title}</TileTitle>
      <TileSubTitle>{subTitle}</TileSubTitle>
      <TileGradient />
      <TileImage src={image} />
    </Tile>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
  margin-top: 32px;
`;

const Header = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin: 0;
`;

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Tile = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 228px;
  overflow: clip;
  border-radius: 10px;
  cursor: pointer;
  background: ${BLACK};
  padding: 16px;
  :hover {
    img {
      opacity: 0.35;
    }
  }
`;

const TileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  z-index: 10;
  position: absolute;
  opacity: 0.5;
  pointer-events: none;
  transition: all 0.2s;
`;

const TileGradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
  z-index: 25;
`;

const TileTitle = styled.h3`
  color: ${WHITE};

  /* Heading/H2/Semibold */
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  z-index: 50;
  pointer-events: none;
  text-align: center;
`;

const TileSubTitle = styled.h3`
  color: ${WHITE};

  /* Paragraph/P1/Regular */
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  z-index: 50;
  text-align: center;
  pointer-events: none;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;

export default InstantQuoteModelHomes;
