import {
  currencyStringFromMicros,
  DateUtils,
  IConfiguration,
} from "@natomas-org/core";
import {usePage} from "../../../../_shared/hooks/usePage";
import {
  ProjectSummaryButtonContainer,
  SplashScreenProjectSummaryProductContextTextContainer,
} from "../styles";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import React from "react";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";

export const SplashScreenProjectCTA = React.memo(
  (props: {
    configuration: IConfiguration | undefined;
    viewPortalAction: () => void;
  }) => {
    const {isNatMobile} = usePage();
    const {customer} = useCurrentCustomer();
    const {configuration, viewPortalAction} = props;
    let buttonLabel: string | undefined = configuration?.product?.title;
    if (buttonLabel) {
      buttonLabel = `View ${buttonLabel} project`;
    } else {
      buttonLabel = `View project`;
    }
    let dateString;
    const dateNumber =
      configuration?.last_update_date ?? configuration?.created_date;
    if (dateNumber) {
      if (isNatMobile) {
        dateString = DateUtils.getMonthAndDayShortHand(new Date(dateNumber), {
          showYear: true,
        });
      } else {
        dateString = DateUtils.getFullDate(new Date(dateNumber), {
          showYear: true,
        });
      }
      if (configuration?.last_update_date) {
        dateString = `Modified: ${dateString}`;
      } else {
        dateString = `Created: ${dateNumber}`;
      }
    }
    let upgradesString = configuration?.upgradesInMicros
      ? currencyStringFromMicros(configuration?.upgradesInMicros) + " upgrades"
      : "No upgrades";
    let contextString = dateString;
    if (
      customer?.default_project_id &&
      customer?.default_project_id === configuration?.id
    ) {
      contextString = dateString
        ? `Default Project. ${dateString}.`
        : `Default Project`;
    }
    return (
      <ProjectSummaryButtonContainer>
        <NatButton
          label={buttonLabel}
          option={StyleOption.PRIMARY}
          clickEvent={viewPortalAction}
        />
        <SplashScreenProjectSummaryProductContextTextContainer>
          <div style={{fontStyle: "italic"}}>{upgradesString}</div>
          <div>{contextString}</div>
        </SplashScreenProjectSummaryProductContextTextContainer>
      </ProjectSummaryButtonContainer>
    );
  }
);
