import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {NatSaveIcon} from "../../../../../../../_shared/icon/icons";
import {NatButton} from "../../../../../../../_shared/generics/button";
import React from "react";

export const SaveButton = (props: {save: () => void}) => {
  return (
    <NatButton
      label={"Save"}
      clickEvent={props.save}
      option={StyleOption.PRIMARY_ALT}
      size={NatSize.SMALL}
      icon={{icon: <NatSaveIcon />, iconPosition: IconPosition.ONLY}}
    />
  );
};
