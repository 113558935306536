import {useSelector} from "react-redux";
import {IStore, StudioState} from "../../../slices/types/Store";
import {useMemo} from "react";
import {useStudioProduct} from "../useStudioProduct";
import {
  getActiveStudioStructure,
  getSnapshotStudioStructure,
  validateSelections,
} from "./index";
import {getStudioSummary, IStudioSummary} from "@natomas-org/service";
import {useStudioConfiguration} from "../useStudioConfiguration";
import {Product} from "@natomas-org/core";
import {useDeepEffect} from "../../useDeepEffect";
import {useStudioStructure} from "../StudioStructure";
import useActiveProject from "../../useProject/useActiveProject";

/*
 Purpose: Prepare all the information that is needed by the <Studio />
 */
export const useStudioEngine = (modeOverride?: any, productOverride?: any) => {
  // Product assignment
  const {projectPriceMode} = useActiveProject();
  const autoSelectProduct = useStudioProduct(productOverride);
  // Full structure
  const priceMode = projectPriceMode;
  const _fullStudioStructure = useStudioStructure(autoSelectProduct, priceMode);
  // Configuration assigment
  const isEditing = useSelector(
    (state: IStore) => state.studio.editingSelections
  );
  const {
    selections,
    isInventory,
    studioStructure: configurationStructure,
    studioProduct: configurationProduct,
    additionalItemsTotalInMicros,
  } = useStudioConfiguration(
    autoSelectProduct,
    _fullStudioStructure,
    isEditing
  );
  // Studio State information
  const isSaving = useSelector((state: IStore) => state.studio.saving);
  const structure = useMemo(() => {
    console.log(
      `StudioEngine: Rebuilding structure {${
        !!configurationStructure ? "snapshot" : "live"
      }, ${isEditing ? "editing" : "not editing"}, ${
        isSaving ? "saving" : "not saving"
      }`
    );
    if (configurationStructure && !isEditing && !isSaving) {
      console.log("StudioEngine: Using snapshot structure");
      console.log(
        "StudioEngine: ConfigurationStructure",
        configurationStructure
      );
      return getSnapshotStudioStructure(
        configurationStructure,
        selections,
        priceMode
      );
    } else {
      console.log("StudioEngine: Using live structure");
      return getActiveStudioStructure(
        _fullStudioStructure,
        selections,
        priceMode
      );
    }
  }, [
    configurationStructure,
    isEditing,
    isSaving,
    selections,
    priceMode,
    _fullStudioStructure,
  ]);
  const studioSummary: IStudioSummary = useMemo(() => {
    console.log(
      "StudioEngine: Rebuilding summary",
      configurationProduct,
      structure,
      additionalItemsTotalInMicros,
      priceMode
    );
    return getStudioSummary(
      configurationProduct,
      structure,
      additionalItemsTotalInMicros,
      priceMode
    );
  }, [
    additionalItemsTotalInMicros,
    configurationProduct,
    structure,
    priceMode,
  ]);
  const studioMode = useMemo(() => {
    /*
     If mode is derived (no override),
     determine the mode on product change
     */
    if (!!studioSummary && !!configurationProduct && !modeOverride) {
      if (Product.isInventory(configurationProduct)) {
        // console.log("StudioEngine: Setting mode to showcase");
        return StudioState.SHOWCASE;
      } else {
        // console.log("StudioEngine: Setting mode to design");
        return StudioState.DESIGNER;
      }
    }
    if (!configurationProduct && !modeOverride) {
      return StudioState.BLANK;
    }
    // console.log("StudioEngine: Setting mode to " + modeOverride + " or Unknown");
    return modeOverride ?? StudioState.UNKNOWN;
  }, [modeOverride, configurationProduct, studioSummary]);

  // Since studioStructure is already dependent on selections,
  // it does not need to be a dependency here
  useDeepEffect(() => {
    // console.log("StudioEngine: Validating selections");
    if (!isInventory) {
      validateSelections(selections, structure);
    }
  }, [selections, structure]);

  return {
    structure: structure,
    summary: studioSummary,
    mode: studioMode,
  };
};
