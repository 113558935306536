import React from "react";
import {IHCPViewProps} from "../interfaces";
import {PORTAL_SITE_MAP_ID} from "../../HCPDashboardLayout/HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {SitePlanView} from "./SitePlanView";

export const FPSiteMap = (props: IHCPViewProps) => {
  const {width} = props;

  return (
    <PortalSection
      sectionId={PORTAL_SITE_MAP_ID}
      headerTitle={"Site plan"}
      width={width}
    >
      <SitePlanView />
    </PortalSection>
  );
};
