import {createSlice} from "@reduxjs/toolkit";
import {getParameter} from "../cookies";

const utmSlice = createSlice({
  name: "utm",
  initialState: {
    page_source: getParameter("pgsrc", {useCookie: true}),
    utm_adgroup: getParameter("utm_adgroup", {useCookie: true}),
    utm_campaign: getParameter("utm_campaign", {useCookie: true}),
    utm_content: getParameter("utm_content", {useCookie: true}),
    utm_medium: getParameter("utm_medium", {useCookie: true}),
    utm_placement: getParameter("utm_placement", {useCookie: true}),
    utm_source: getParameter("utm_source", {useCookie: true}),
    utm_term: getParameter("utm_term", {useCookie: true}),
    key: getParameter("key", {useCookie: true}),
  },
});

export const utmReducer = utmSlice.reducer;
