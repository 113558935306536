import React from "react";

export interface RichTextDivProps {
  html: string;
  style?: React.CSSProperties;
}
export const RichTextDiv = (props: RichTextDivProps) => {
  const {html, style} = props;

  return <div style={style} dangerouslySetInnerHTML={{__html: html}} />;
};
