import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {BiCopyAlt} from "react-icons/bi";
import {duplicateFactoryLineElement} from "../../../../../database/firebase/api/catalog";
import {NatButton} from "../../../../_shared/generics/button";
import React from "react";
import {DuplicateFactoryLineElementRequestType} from "@natomas-org/core";

export const DuplicateFactoryLineElementButton = (props: {
  factoryLineId: string;
  requestType: DuplicateFactoryLineElementRequestType;
  requestId: string;
  resetAction?: () => void;
}) => {
  const {factoryLineId, requestType, requestId, resetAction} = props;
  return (
    <NatButton
      label={"Duplicate"}
      type={"button"}
      size={NatSize.SMALL}
      option={StyleOption.SECONDARY_ALT}
      icon={{
        icon: <BiCopyAlt />,
        iconPosition: IconPosition.LEFT,
      }}
      spinnerEnabled={true}
      clickEvent={() => {
        resetAction?.();
        return duplicateFactoryLineElement(
          factoryLineId,
          requestType,
          requestId
        );
      }}
    />
  );
};
