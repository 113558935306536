import React, {useMemo} from "react";
import {priceTextFromMicros} from "../../../../../../../../../studio/_shared/helper";
import styled from "styled-components";
import {
  ADMIN_EDIT,
  ADMIN_SELECTED,
} from "../../../../../../../../../_shared/colors";
import _ from "lodash";
import PriceInput from "../../_shared/FactoryInventoryInput/PriceInput";

const getBackgroundColor = (isSelected: boolean, isEdited: boolean) => {
  if (isEdited) {
    return ADMIN_EDIT;
  } else if (isSelected) {
    return ADMIN_SELECTED;
  }
};

const CellContainer = styled.div<{isSelected: boolean; isEdited: boolean}>`
  height: 100%;
  width: 100%;
  background-color: ${(props) =>
    getBackgroundColor(props.isSelected, props.isEdited)};
`;

const FactoryInventoryPricingCell = (props: any) => {
  const {
    value,
    column,
    row,
    categories,
    modifierGroups,
    selectedId,
    initialData,
    setModifierPrice,
  } = props;

  const id = useMemo(() => row?.original?.id, [row?.original]);
  const {id: columnId} = column;

  const cellProps = {
    isSelected: id === selectedId,
    isEdited: !_.isEqual(initialData?.[id]?.[columnId], value),
  };

  if (columnId === "categoryId") {
    return (
      <CellContainer {...cellProps}>{categories[value]?.title}</CellContainer>
    );
  }
  if (columnId === "modifierGroupId") {
    return (
      <CellContainer {...cellProps}>
        {modifierGroups[value]?.title}
      </CellContainer>
    );
  }
  if (columnId === "priceInMicros") {
    return (
      <CellContainer {...cellProps}>
        {!cellProps?.isSelected ? (
          priceTextFromMicros(value, "accurate")
        ) : (
          <PriceInput
            id={`${id}-price-input`}
            initialValue={value}
            onChange={(value: number) => setModifierPrice(id, value)}
            hideFormattedValue={true}
          />
        )}
      </CellContainer>
    );
  }
  return <CellContainer {...cellProps}>{value}</CellContainer>;
};

export default FactoryInventoryPricingCell;
