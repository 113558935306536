import React from "react";
import {AiOutlineClose} from "react-icons/ai";
import {
  PhotoViewDismiss,
  PresentationTopRight,
  PresentationViewBackdrop,
  PresentationViewContainer,
} from "./styles";
import {MODAL_Z_INDEX} from "../../styles";

export interface NatPresentationProps {
  show: boolean;
  close: () => void;
  additionalActions?: JSX.Element;
  children: JSX.Element;
}

export const NatPresentation = (props: NatPresentationProps) => {
  if (!props.show) {
    return null;
  }
  return (
    <PresentationViewContainer>
      <PhotoViewDismiss
        onClick={props.close}
        style={{zIndex: MODAL_Z_INDEX + 1}}
      >
        <AiOutlineClose />
      </PhotoViewDismiss>
      <PresentationTopRight>{props.additionalActions}</PresentationTopRight>
      {props.children}
      <PresentationViewBackdrop />
    </PresentationViewContainer>
  );
};
