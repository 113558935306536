// import {Utilities} from "../../database/firebase";
// import {
//   CatalogQuizAnswers,
//   PROJECT_COLLECTION_KEY,
//   PROJECT_SURVEY_COLLECTION_KEY,
//   SurveyType,
// } from "@natomas-org/core";
// import {store} from "../../store";
// import {setCatalogQuizAnswers} from "../../components/_shared/slices/CatalogSlice/CatalogSessionSlice";
// import {useEffect} from "react";
// import {useCatalogBannerProducts} from "../../components/_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useCatalogBannerProducts";
// import {useSelector} from "react-redux";
// import {IStore} from "../../components/_shared/slices/types/Store";
// import {
//   areCatalogQuizAnswersOfNonRegistrationType,
//   getAduQuizFromCache,
// } from "./helpers";

// let aduQuizDataUnsubscribe: any = null;
export const SurveysInSlicesManager = (): null => {
  // const {quizDisabled} = useCatalogBannerProducts();
  //
  // const currentConfigurationId = useSelector(
  //   (state: IStore) => state.configuration.currentConfigurationId
  // );
  //
  // useEffect(() => {
  //   const cachedAnswers = getAduQuizFromCache();
  //   // If the user is logged in, we subscribe to the project's ADU Quiz data.
  //   if (aduQuizDataUnsubscribe) {
  //     aduQuizDataUnsubscribe();
  //   }
  //   if (!quizDisabled && !currentConfigurationId) {
  //     if (
  //       cachedAnswers &&
  //       areCatalogQuizAnswersOfNonRegistrationType(cachedAnswers)
  //     ) {
  //       // Only use cached answers if not logged in
  //       store.dispatch(setCatalogQuizAnswers(cachedAnswers));
  //     } else {
  //       store.dispatch(setCatalogQuizAnswers(undefined));
  //     }
  //     return;
  //   }
  //   if (!currentConfigurationId) {
  //     return;
  //   }
  //   const aduQuizData = Utilities.collection(PROJECT_COLLECTION_KEY)
  //     .doc(currentConfigurationId)
  //     .collection(PROJECT_SURVEY_COLLECTION_KEY)
  //     .doc(SurveyType.ADU_QUIZ);
  //   aduQuizDataUnsubscribe = aduQuizData.onSnapshot(
  //     (snapshot) => {
  //       if (snapshot.exists) {
  //         store.dispatch(
  //           setCatalogQuizAnswers(snapshot.data() as CatalogQuizAnswers)
  //         );
  //         return snapshot.data();
  //       }
  //     },
  //     (e) => {
  //       console.log(e);
  //     }
  //   );
  // }, [currentConfigurationId, quizDisabled]);
  return null;
};
