import React, {ReactNode, useEffect} from "react";
import {useInView} from "@react-spring/web";

export const DynamicScrollComponentAnchor = (props: {
  setIsSticky: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
}) => {
  const [ref, isInView] = useInView({amount: "any"});

  useEffect(() => {
    props.setIsSticky(!isInView);
  }, [isInView, props]);

  return (
    <div ref={ref} style={{width: "100%"}}>
      {props.children}
    </div>
  );
};
