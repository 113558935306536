import {IStore} from "../../slices/types/Store";
import {useSelector} from "react-redux";
import {useEffect, useMemo} from "react";
import {useUrl} from "../useUrl";
import {useProduct} from "../useProductCatalog/useProduct";
import {tryFetchingCatalogFactory} from "../../../../root/FetchingManager";
import {useActiveCartItem} from "../useCart/useCartItem/useActiveCartItem";

export const useStudioProduct = (productOverride?: any) => {
  const stateProduct = useSelector((state: IStore) => state.studio.product);

  const {details} = useActiveCartItem() ?? {};
  const {productId: cartItemProductId, factoryId: cartItemFactoryId} =
    details ?? {};
  const {product: cartItemProduct} = useProduct(
    cartItemProductId,
    cartItemFactoryId
  );
  const {getProductInfoFromURL, url} = useUrl();

  const urlProductInfo = useMemo(() => {
    return getProductInfoFromURL();
  }, [url]);

  const {product: urlProduct} = useProduct(
    urlProductInfo?.productId ?? undefined,
    urlProductInfo.productGroupId ?? undefined
  );
  /* Track product assignment
   * Order of assignment:
   * 1. Override - is there an explicit assignment passed to the hook
   * 2. URL - Are there valid product url params
   * 3. State - is there an explicit assignment in redux store currently
   * 4. Project - Is there a project (configuration) with a product assignment
   * 5. Empty - No product assignment can be derived
   * */
  const product = useMemo(() => {
    if (!!productOverride) {
      console.log("StudioProduct: Product override exists.");
      return productOverride;
    }
    // The url contains product ids
    if (urlProduct) {
      // Get the product from the catalog state
      console.log("StudioProduct: URL product found.", urlProduct);
      return urlProduct;
    } else if (stateProduct) {
      console.log("StudioProduct: State Product product found.", stateProduct);
      return stateProduct;
    } else if (cartItemProduct) {
      console.log("StudioProduct: Cart Item product found.", cartItemProduct);
      return cartItemProduct;
    }
    console.log("StudioProduct: Product not found.");
    return null;
  }, [productOverride, urlProduct, stateProduct, cartItemProduct]);

  // Full fetch factory line
  useEffect(() => {
    if (!!product) {
      tryFetchingCatalogFactory(product?.productGroupId, {
        fullFetch: true,
      });
    }
  }, [product]);

  return product;
};
