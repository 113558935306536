import React from "react";
import {NatStarFillIcon} from "../../../../../_shared/icon/icons";
import {CardTag} from "../styles";
import {VILLA_LILAC} from "../../../../../_shared/colors";

export const PercentMatchTag: React.FC<{
  labels: (string | number)[] | null;
  position?: "relative" | "absolute";
}> = ({labels, position}) => {
  const percentMatch: number | undefined | string = labels?.find((label) => {
    return typeof label === "number";
  });
  if (percentMatch && typeof percentMatch === "number") {
    if (percentMatch < 0) {
      return null;
    }
    return (
      <CardTag
        key={`card-${percentMatch}`}
        style={{
          backgroundColor: VILLA_LILAC,
          color: "black",
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
          top: position === "absolute" ? "8px" : undefined,
          right: position === "absolute" ? "8px" : undefined,
          position: position ?? "absolute",
        }}
      >
        <NatStarFillIcon />
        {` ${percentMatch}% match`}
      </CardTag>
    );
  }
  return null;
};
