import {IHCPViewProps} from "../interfaces";
import React from "react";
import {PORTAL_TOUR_ID} from "../HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {DateUtils, IEventId, Project} from "@natomas-org/core";
import {ModelHomeTour} from "../../../_shared/components/ModelHomeTour";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

export const HCPModelHomeTour = (props: IHCPViewProps) => {
  const {width} = props;
  const {id, info} = useActiveProject();

  const modelHomeTourEvent = Project.getProjectEventWithOptions(info, {
    eventId: IEventId.MODEL_HOME_TOUR,
    statusType: "all",
  });

  if (!info) {
    return null;
  }

  if (
    modelHomeTourEvent &&
    modelHomeTourEvent.end_timestamp < DateUtils.getCurrentTimeInMilliseconds()
  ) {
    return null;
  }

  return (
    <PortalSection
      headerTitle={"Schedule a model home tour"}
      width={width}
      sectionId={PORTAL_TOUR_ID}
    >
      <ModelHomeTour
        projectSummary={info}
        modelHomeTourEvent={modelHomeTourEvent}
        projectId={id}
      />
    </PortalSection>
  );
};
