import {
  Customer,
  CustomerProjectInfo,
  DEFAULT_PDF_DOCUMENT_ID,
  doesPDFInfoFitEventIdResolution,
  ICustomerInfo,
  IDocumentDetails,
  IDocusignRequest,
  IGCloudFileStorageDetails,
  IRecipient,
  isBlankString,
  SITE_PLAN_DOCUMENT_ID,
  StringParameter,
  TeamMemberRole,
} from "@natomas-org/core";
import {ISendDocusignProps} from "./constants";

export const populateDocuSignTextParameters = (
  text: string,
  customer: ICustomerInfo | null,
  mergedProjectInfos: CustomerProjectInfo | null | undefined
): string => {
  const hasCode = !isBlankString(mergedProjectInfos?.code);
  if (hasCode) {
    return text
      .replace(
        StringParameter.CUSTOMER_FIRST_NAME,
        customer ? Customer.getFirstName(customer) : ""
      )
      .replace(StringParameter.CUSTOMER_LAST_NAME, "")
      .replace(
        StringParameter.PROJECT_ID,
        mergedProjectInfos?.code?.toString() ?? ""
      );
  }
  return text
    .replace(
      StringParameter.CUSTOMER_FIRST_NAME,
      customer ? Customer.getFirstName(customer) : ""
    )
    .replace(
      StringParameter.CUSTOMER_LAST_NAME,
      customer ? Customer.getLastName(customer) : ""
    )
    .replace(
      StringParameter.PROJECT_ID,
      mergedProjectInfos?.number?.toString() ?? "Villa"
    );
};

export const getRecipientErrorKey = (
  recipientOrCC: string,
  nameOrEmail: string,
  index: number
) => {
  return `${index}${recipientOrCC}${nameOrEmail}`;
};

export const getEventId = (initialProps: ISendDocusignProps) => {
  return (
    initialProps.pdfDocument.smartsheet_info?.event_id ??
    DEFAULT_PDF_DOCUMENT_ID
  );
};

export const formDocusignSendRequest = (
  formikValues: any,
  projectId: string,
  initialProps: ISendDocusignProps,
  customer: any,
  mergedProjectInfos: CustomerProjectInfo | null | undefined,
  recipientKey: string,
  ccKey: string
): IDocusignRequest => {
  const recipients: IRecipient[] = formikValues[recipientKey];
  const ccs: IRecipient[] = formikValues[ccKey];

  const requestToReturn: IDocusignRequest = {
    documentId: initialProps.pdfDocument?.document_id,
    eventId: getEventId(initialProps),
    recipients: recipients,
    projectId: projectId,
  };
  requestToReturn.fromEmail = initialProps.senderEmail;
  requestToReturn.recipients = recipients;
  requestToReturn.ccs = ccs;
  requestToReturn.emailSubject =
    formikValues.subject ??
    populateDocuSignTextParameters(
      initialProps.subject,
      customer,
      mergedProjectInfos
    );
  requestToReturn.emailBody =
    formikValues.body ??
    populateDocuSignTextParameters(
      initialProps.body,
      customer,
      mergedProjectInfos
    );
  requestToReturn.title = initialProps.pdfDocument.title;

  return requestToReturn;
};

export const getInitialValues = (
  customer: ICustomerInfo | null,
  mergedProjectInfos: CustomerProjectInfo | null | undefined,
  loggedInUser: any,
  initialProps: ISendDocusignProps,
  recipientKey: string,
  ccKey: string
) => {
  const valuesToReturn = {
    subject: populateDocuSignTextParameters(
      initialProps.subject,
      customer,
      mergedProjectInfos
    ),
    body: populateDocuSignTextParameters(
      initialProps.body,
      customer,
      mergedProjectInfos
    ),
    [recipientKey]: [
      {
        email: customer?.person_info?.contact.email,
        name: `${customer?.person_info?.first_name} ${customer?.person_info?.last_name}`,
      },
    ],
    [ccKey]: [
      {
        email: loggedInUser?.email,
        name: loggedInUser?.displayName
          ? loggedInUser?.displayName
          : `${loggedInUser?.firstName} ${loggedInUser?.lastName}`,
      },
    ],
  };
  initialProps.defaultCCList.forEach((recipient, index) => {
    (valuesToReturn[ccKey] as IRecipient[]).push(recipient);
  });

  // Logic for: if for site plan, add PPM
  if (initialProps.pdfDocument.document_id === SITE_PLAN_DOCUMENT_ID) {
    let ppm: IRecipient | null = null;
    if (
      mergedProjectInfos?.team_contacts &&
      mergedProjectInfos?.team_contacts?.length > 0
    ) {
      const foundPPM = mergedProjectInfos?.team_contacts?.find((teamMember) => {
        return teamMember.role === TeamMemberRole.PC;
      });
      if (
        foundPPM &&
        foundPPM?.email &&
        foundPPM?.first_name &&
        foundPPM?.last_name
      ) {
        ppm = {
          email: foundPPM.email,
          name: `${foundPPM.first_name} ${foundPPM.last_name}`,
        };
      }
    }
    if (ppm) {
      (valuesToReturn[ccKey] as IRecipient[]).push(ppm);
    }
  }
  return valuesToReturn;
};

export const getErrorArrayFromRecipients = (
  recipients: IRecipient[],
  RECIPIENT_VALIDATE_KEY: string,
  EMAIL_VALIDATE_KEY: string,
  NAME_VALIDATE_KEY: string
): {[key: string]: any} => {
  const errorsToReturn: {[key: string]: any} = {};
  recipients.forEach((recipient: IRecipient, index: number) => {
    if (!recipient.email) {
      errorsToReturn[
        getRecipientErrorKey(RECIPIENT_VALIDATE_KEY, EMAIL_VALIDATE_KEY, index)
      ] = "Email is required.";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(recipient.email)
    ) {
      errorsToReturn[
        getRecipientErrorKey(RECIPIENT_VALIDATE_KEY, EMAIL_VALIDATE_KEY, index)
      ] = "Invalid email address";
    }
    if (!recipient.name) {
      errorsToReturn[
        getRecipientErrorKey(RECIPIENT_VALIDATE_KEY, NAME_VALIDATE_KEY, index)
      ] = "Name is required.";
    }
  });
  return errorsToReturn;
};

// Returns a dimension string only if info exists and all page resolutions are the same
export const getPDFResolutionString = (
  documentDetails: IDocumentDetails
): string | null => {
  const pdfInfo = (documentDetails?.details as IGCloudFileStorageDetails)
    ?.pdfInfo;
  if (!pdfInfo) {
    return null;
  } else {
    if (pdfInfo.pageCount > 0) {
      const firstPageWidth = pdfInfo.pages[0].width;
      const firstPageHeight = pdfInfo.pages[0].height;
      for (let i = 1; i < pdfInfo.pageCount; i++) {
        if (
          firstPageWidth !== pdfInfo.pages[i].width ||
          firstPageHeight !== pdfInfo.pages[i].height
        ) {
          return null;
        }
      }
      return `${firstPageWidth}x${firstPageHeight}`;
    } else {
      return null;
    }
  }
};

// Returns if document details resolution fits template. If unknown, defaults to true
export const isDocusignDocumentDetailsResolutionValid = (
  documentDetails: IDocumentDetails,
  initialProps: ISendDocusignProps
): boolean => {
  const pdfInfo = (documentDetails?.details as IGCloudFileStorageDetails)
    ?.pdfInfo;
  const eventId = getEventId(initialProps);
  if (!pdfInfo || !eventId) {
    return true;
  }
  return doesPDFInfoFitEventIdResolution(pdfInfo, eventId);
};
