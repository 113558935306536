import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../SSDDashboardView/styles/globals";
import {BUDGET_SUMMARY_GRAY_ROW_BG} from "../../../../../_shared/colors";

export const TableEditorButtonContainer = styled.div`
  padding: 1rem 1rem;
  gap: 1rem;
  display: flex;
`;

export const FinalRowContainer = styled.div<{
  isNatMobile: boolean;
  isFullView?: boolean;
  isPresentationMode?: boolean;
  highlight?: boolean;
}>`
  min-height: 70px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 16px;
  font-family: ${FONT_FAMILY_BOLD};
  background-color: ${(props) =>
    props.highlight && !props.isPresentationMode
      ? "rgba(130, 194, 117, 0.4)"
      : props.isPresentationMode
      ? BUDGET_SUMMARY_GRAY_ROW_BG
      : undefined};
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  ${(props) => (props.isNatMobile ? "margin-bottom: 20px;" : "")};
`;
