import {useQuery} from "@tanstack/react-query";
import {getAdminInstantQuoteService} from "./index";
import {IInstantQuote, IInstantQuoteLocation} from "./interface";

const fetchInstantQuoteItems = async (locationId?: string): Promise<any> => {
  if (!locationId) return null;
  const response = await getAdminInstantQuoteService().then((service) => {
    return service.getLocation(locationId);
  });
  console.log("fetchLocation", response);
  return response;
};

function useAdminInstantQuoteByLocation(
  location?: IInstantQuoteLocation
): IInstantQuote | null {
  const {data: items} = useQuery<any, Error>(
    ["location", `${location?.uuid}`],
    () => fetchInstantQuoteItems(location?.uuid),
    {cacheTime: 0}
  );
  if (!location) return null;
  return {...location, items};
}

export default useAdminInstantQuoteByLocation;
