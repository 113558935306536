import styled from "styled-components";

export const AdminCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
`;
