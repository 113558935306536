import styled from "styled-components";
import {WHITE} from "../../../../../../../_shared/colors";
import {FONT_FAMILY_BOLD} from "../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const OrderFormDownloaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: ${WHITE};
  font-size: 1rem;
  font-weight: normal;
  font-family: ${FONT_FAMILY_BOLD};
  border-radius: 4px 4px 0 0;
`;
