import React, {useMemo, useState} from "react";
import {InfoSetItem, InfoSetItemOption} from "../../shared/interfaces";
import {ReadOnlyFactoryInfoSetItemOption} from "./ReadOnlyFactoryInfoSetItemOption";
import {
  ReadOnlyFactoryInfoSetItemSection,
  ReadOnlyFactoryInfoSetItemSectionField,
  ReadOnlyFactoryInfoSetItemSectionTitle,
  ReadOnlyFactoryInfoSetItemSectionTitleContainer,
} from "./styles";
import {ReadOnlyFactoryInfoSetItemCategoryTitle} from "../ReadOnlyFactoryInfoSet/styles";
import {NatDownChevron, NatRightChevron} from "../../../_shared/icon/icons";

const ReadOnlyFactoryInfoSetItem = (props: {
  item?: InfoSetItem;
  showCategoryTitle?: boolean;
}) => {
  const {item, showCategoryTitle} = props;
  const {id, options, label, isMultiOption, internalCategory} = item ?? {};
  const [expanded, setExpanded] = useState<boolean>(false);

  const multiOptionDescription = useMemo(() => {
    if (options && options?.length > 1) {
      return isMultiOption ? (
        <ReadOnlyFactoryInfoSetItemSectionField>
          Selection: Many
        </ReadOnlyFactoryInfoSetItemSectionField>
      ) : (
        <ReadOnlyFactoryInfoSetItemSectionField>
          Selection: Single
        </ReadOnlyFactoryInfoSetItemSectionField>
      );
    } else {
      return null;
    }
  }, [options, isMultiOption]);

  return (
    <>
      {showCategoryTitle && (
        <ReadOnlyFactoryInfoSetItemCategoryTitle id={`${internalCategory}`}>
          {internalCategory}
        </ReadOnlyFactoryInfoSetItemCategoryTitle>
      )}
      <ReadOnlyFactoryInfoSetItemSection key={id}>
        <ReadOnlyFactoryInfoSetItemSectionTitleContainer
          onClick={() => setExpanded((current) => !current)}
        >
          <ReadOnlyFactoryInfoSetItemSectionTitle id={id}>
            {label}
          </ReadOnlyFactoryInfoSetItemSectionTitle>
          <div>
            {expanded ? (
              <NatDownChevron size={32} />
            ) : (
              <NatRightChevron size={32} />
            )}
          </div>
        </ReadOnlyFactoryInfoSetItemSectionTitleContainer>
        {expanded && (
          <>
            {multiOptionDescription}
            {options?.map((o: InfoSetItemOption, index: number) => {
              return (
                <ReadOnlyFactoryInfoSetItemOption
                  id={`${id}-${index}`}
                  option={o}
                />
              );
            })}
          </>
        )}
      </ReadOnlyFactoryInfoSetItemSection>
    </>
  );
};

export default ReadOnlyFactoryInfoSetItem;
