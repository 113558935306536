import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SHOW_MULTI_SELECT_PROPERTY_KEY} from "@natomas-org/core";
import {IStudioSlice} from "./types/Store";

const getInitialState = (): IStudioSlice => {
  return {
    configuration: undefined,
    editingSelections: false,
    modifierGroups: undefined,
    product: undefined,
    saving: false,
    section: undefined,
    selections: {},
    showCartLibrary: false,
    showEditingConfirmation: false,
    showOverwriteProjectWarning: false,
    showSavingConfirmation: false,
    step: 0,
    hash: undefined,
  };
};

const studioSlice = createSlice({
  name: "studio",
  initialState: getInitialState(),
  reducers: {
    setStudioProduct: (state, action: PayloadAction<any>) => {
      state.product = action.payload;
    },
    setStudioConfiguration: (state, action: PayloadAction<any>) => {
      state.configuration = action.payload;
    },
    setStudioSelections: (state, action: PayloadAction<any>) => {
      if (!action.payload) {
        state.selections = {};
      } else {
        state.selections = action.payload;
      }
    },
    setStudioSelection: (
      state,
      action: PayloadAction<{
        modifier: any;
        modifierGroup: any;
        isValidating: boolean;
        status?: true | null;
      }>
    ) => {
      const {modifier, modifierGroup, isValidating, status} = action.payload;
      const modifierId = modifier?.id;
      if (!modifierId) {
        return;
      }
      if (state.editingSelections === false && !isValidating) {
        return;
      }

      // Determine if group is multi select
      const isMultiSelect =
        modifierGroup?.properties?.[SHOW_MULTI_SELECT_PROPERTY_KEY] === true;

      // Determine the current status of the modifier
      const isSelected = state.selections?.[modifierId] === true;

      // Confirm that intent is different from current state
      if (status !== undefined) {
        console.log(status, isSelected);
        if (status && isSelected) {
          return;
        } else if (status === null && !isSelected) {
          return;
        }
      }

      /*
       If it is a single selection,
       we will need to deselect other modifiers in the group
       If it is a multi selection,
       validation in StudioEngine/index.ts will handle the rest
       */
      console.log("isMultiSelect", isMultiSelect);
      console.log("isSelected", isSelected);
      console.log("modifierId", modifierId);
      if (isMultiSelect) {
        if (isSelected) {
          // Deselect modifier
          state.selections[modifierId] = null;
        } else {
          // Select modifier
          state.selections[modifierId] = true;
        }
      } else {
        // If single select option
        if (!isSelected) {
          // If modifier is not selected
          console.log("Selecting modifier", modifierId);
          state.selections[modifierId] = true;
          // Ensure the others are deselected
          const otherIds: string[] = modifierGroup?.modifiers?.filter(
            (id: string) => {
              return id !== modifierId;
            }
          );
          // Iterate through and deselect other modifiers in group
          otherIds.forEach((_modifierId: string) => {
            // Deselect modifier
            console.log("Deselecting", otherIds);
            state.selections[_modifierId] = null;
          });
        }
      }
    },
    setStudioStep: (state, action: PayloadAction<number>) => {
      // console.log("Setting studio step -> " + action.payload);
      state.step = action.payload;
    },
    setStudioSection: (state, action: PayloadAction<any>) => {
      state.section = action.payload;
    },
    setStudioModifierGroups: (state, action: PayloadAction<any>) => {
      state.modifierGroups = action.payload;
    },
    setShowEditingConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showEditingConfirmation = action.payload;
    },
    setShowCartLibrary: (state, action: PayloadAction<boolean>) => {
      state.showCartLibrary = action.payload;
    },
    setEditingSelections: (state, action: PayloadAction<boolean>) => {
      state.editingSelections = action.payload;
    },
    setShowOverwriteProjectWarning: (state, action: PayloadAction<boolean>) => {
      state.showOverwriteProjectWarning = action.payload;
    },
    setShowSavingConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showSavingConfirmation = action.payload;
    },
    setSaving: (state, action: PayloadAction<boolean>) => {
      // console.log("Setting studio saving -> " + action.payload);
      state.saving = action.payload;
    },
    setStudioHash: (state, action: PayloadAction<string>) => {
      // console.log("Setting studio hash -> " + action.payload);
      state.hash = action.payload;
    },
  },
});

export const studioReducer = studioSlice.reducer;
export const {
  setEditingSelections,
  setSaving,
  setShowCartLibrary,
  setShowEditingConfirmation,
  setShowSavingConfirmation,
  setStudioConfiguration,
  setStudioHash,
  setStudioSection,
  setStudioSelections,
  setStudioSelection,
  setStudioStep,
} = studioSlice.actions;
