import {ICustomerInfo} from "@natomas-org/core";
import {IStore} from "../../slices/types/Store";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {useUsers} from "../useUser/useUsers";

export const useCustomer = (
  customerId?: string
): {
  id?: string;
  info?: ICustomerInfo;
  actions?: any;
} => {
  const users = useUsers();
  const userActionsMap = useSelector((state: IStore) => state.user.userActions);

  const userInfo: ICustomerInfo | undefined = useMemo(() => {
    return getValueByKey(customerId, users) ?? undefined;
  }, [users, customerId]);

  const userActions: any = useMemo(() => {
    return getValueByKey(customerId, userActionsMap);
  }, [userActionsMap, customerId]);

  return {
    id: customerId,
    info: userInfo,

    actions: userActions,
  };
};

const getValueByKey = <T>(
  key: string | undefined,
  obj: Record<string, T>
): T | undefined => {
  if (!key) {
    return undefined;
  }
  return obj[key];
};
