import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../SSDDashboardView/styles/globals";

export const DesignCTABlurbContainer = styled.div`
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 15rem;
    height: auto;
  }
`;

export const DesignCTAText = styled.div<{
  size: string;
  bold?: boolean;
  align?: string;
}>`
  font-family: ${(props) =>
    props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  text-align: ${(props) => props.align};
  font-size: ${(props) => props.size};
  color: gray;
  margin-bottom: 0.85rem;
`;
