import {
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import React from "react";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../_shared/generics/label/NatLabel";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {NatLink} from "../../../../../../_shared/generics/button";

export const ControlPanelSectionWrapper = (props: {
  title: string;
  children: React.ReactNode | null;
  style?: React.CSSProperties;
  onClick?: () => void;
}) => {
  if (props.children == null) {
    return null;
  }
  const label = (
    <NatLabel
      label={props.title}
      type={NatLabelType.H4}
      weight={NatLabelWeight.BOLD}
    />
  );
  const link = (
    <div style={{width: "fit-content"}}>
      <NatLink
        size={NatSize.LARGE}
        label={props.title}
        option={StyleOption.PRIMARY_LINK}
        clickEvent={props.onClick}
      />
    </div>
  );
  return (
    <NatFlex
      style={props.style}
      COLUMN
      VERTICAL_ALIGN={NatVerticalAlign.CENTER}
      FULL_WIDTH
      FULL_HEIGHT
      NOGAP
    >
      {!!props.onClick ? link : label}
      {props.children}
    </NatFlex>
  );
};
