import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
  .vcl-text-entry-container {
    flex-grow: 1;
    min-width: 100px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SpinnerContainer = styled.div`
  height: 288px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubtitleWithLink = styled.div`
  padding: 0;
  margin: 8px 0 0 0;
  font-size: 16px;
  color: var(--branding-granite);
  font-family: Inter-Regular, serif;
  font-weight: 400;
  a {
    color: var(--branding-granite);
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
