import {v4 as uuidv4} from "uuid";
import {IFactoryInventoryStudioEditorPopupPathOptions} from "../PopupContent/interface";
import {StudioEditorFieldType} from "./StudioEditorFieldAddButton";

export const getUniqueId = (): string => {
  return uuidv4();
};

export const getCreateActionProps = (
  type: StudioEditorFieldType,
  rootPath: IFactoryInventoryStudioEditorPopupPathOptions
): IFactoryInventoryStudioEditorPopupPathOptions | undefined => {
  let value = undefined;
  if (type === StudioEditorFieldType.RENDERING) {
    value = {imageGroupId: getUniqueId()};
  } else if (type === StudioEditorFieldType.CATEGORY) {
    value = {categoryId: getUniqueId()};
  } else if (type === StudioEditorFieldType.MODIFIER_GROUP) {
    value = {modifierGroupId: getUniqueId()};
  } else if (type === StudioEditorFieldType.MODIFIER) {
    value = {modifierId: getUniqueId()};
  }
  if (value) {
    return {...rootPath, ...value};
  }
};
