import React from "react";
import {QuickInfoSectionTitle} from "../styles";

interface PricingInfoSection_DeprecatedProps {}

const PricingInfoSection_Deprecated = (
  props: PricingInfoSection_DeprecatedProps
) => {
  const {} = props;

  return (
    <>
      <QuickInfoSectionTitle>Project Pricing</QuickInfoSectionTitle>
      Please refer to the Budget Summary tab for detailed pricing information.
    </>
  );
};

export default PricingInfoSection_Deprecated;
