import {
  AdminPage,
  convertRemsToPixels,
  getPage,
  getTotalTabWidthRems,
  PRIMARY_SIDEBAR_WIDTH,
} from "../../constants/AdminPage";
import {useUrl} from "../../../../_shared/hooks/useUrl";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {updateAdditionLeftPadding} from "../../../../_shared/slices/PageSlice";
import {Helmet} from "react-helmet-async";
import {MainContentWrapper, MainLayout} from "../../styles/AdminStyles";
import {AdminSideBar} from "../AdminSideBar";
import {setAdminPage} from "../../../../_shared/slices/AdminSlice";
import {IStore} from "../../../../_shared/slices/types/Store";
import {toPage} from "../../../../portal/_shared/navigation/PageNavigation";

export interface AdminViewProps {
  title: string;
  tabs: AdminPage[];
  headerContent?: JSX.Element;
  footerContent?: JSX.Element;
  preTabContent?: JSX.Element;
  modals?: JSX.Element;
}

export const AdminView = (props: AdminViewProps) => {
  const {tabs, title, headerContent, preTabContent, modals, footerContent} =
    props;
  const selectedTab = useSelector(
    (state: IStore) => state.administration?.page
  );
  const {url, getPageFromURL} = useUrl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      tabs?.length > 0 &&
      (selectedTab === undefined ||
        !tabs.find((t: AdminPage) => t?.id === selectedTab))
    ) {
      dispatch(setAdminPage(tabs[0]?.id));
      toPage(tabs[0].id);
    }
  }, [tabs]);

  useEffect(() => {
    let pageId = getPageFromURL();
    if (selectedTab !== pageId) {
      const tab = getPage(tabs, getPageFromURL());
      if (tab) {
        dispatch(setAdminPage(tab?.id));
        dispatch(
          updateAdditionLeftPadding(
            convertRemsToPixels(getTotalTabWidthRems(tab))
          )
        );
      }
    }
    return () => {
      dispatch(updateAdditionLeftPadding(0));
    };
  }, [url, tabs]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={title} />
      </Helmet>
      <MainLayout>
        {modals}
        <AdminSideBar
          tabs={tabs}
          widthRems={PRIMARY_SIDEBAR_WIDTH}
          headerContent={
            headerContent ?? (
              <div style={{textAlign: "center", width: "100%"}}>{title}</div>
            )
          }
          preTabContent={preTabContent}
          footerContent={footerContent}
        />
        <MainContentWrapper>
          {getPage(tabs, selectedTab)?.component}
        </MainContentWrapper>
      </MainLayout>
    </div>
  );
};
