import styled from "styled-components";
import {DIVIDER_COLOR, SIDEBAR_COLOR} from "../../../_shared/colors";

export const CustomerDatabaseLayout = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: calc(100% - 45px);
`;

export const CustomerDatabaseLayoutMenu = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 12rem;
  min-width: 12rem;
  border-right: 1px solid ${DIVIDER_COLOR};
  background-color: ${SIDEBAR_COLOR};
  border-top: 1px solid ${DIVIDER_COLOR};

  overflow-y: auto;
`;

export const CustomerSideBarSectionWrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${DIVIDER_COLOR};
  width: 100%;
`;

export const CustomerDatabaseLayoutContent = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;
