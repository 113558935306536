import React from "react";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";
import {
  SSCComponentContainer,
  SSCComponentLabel,
  SSCComponentSubLabel,
} from "../../_shared/styles";

interface SSCTimelineProps {
  timelineInMonths: number;
}

const SSCTimeline = (props: SSCTimelineProps) => {
  const {timelineInMonths} = props;

  return (
    <SSCBlockContainer>
      <SSCComponentContainer>
        <SSCComponentLabel>{timelineInMonths} months*</SSCComponentLabel>
        <SSCComponentSubLabel>*Varies by jurisdiction</SSCComponentSubLabel>
      </SSCComponentContainer>
    </SSCBlockContainer>
  );
};

export default SSCTimeline;
