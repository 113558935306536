import React from "react";
import {ImageAssignmentManager} from "../../../../../../../../../_shared/generics/image/ImageAssignmentManager";
import styled from "styled-components";

const ImageContainer = styled.div`
  height: 300px;
  min-width: 500px;
  width: 100%;
`;

interface SingleImageInputProps {
  id: string;
  onChange: (value: string | null) => void;
  initialValue: string;
}

const SingleImageInput = (props: SingleImageInputProps) => {
  const {id, onChange, initialValue} = props;

  return (
    <ImageContainer>
      <ImageAssignmentManager
        key={`catalog-image-manager-${id}`}
        imageIdValue={initialValue}
        onComplete={(value) => {
          onChange(value);
        }}
        forceAllow={true}
        hideImageDetailsEditor={true}
      />
    </ImageContainer>
  );
};

export default SingleImageInput;
