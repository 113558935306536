import React, {ChangeEvent, useCallback, useMemo, useState} from "react";
import VCL from "@natomas-org/villa-component-library";
import _ from "lodash";
import {InstantQuoteProductLocationSchema} from "@natomas-org/villa-nexus-client";
import {
  fullPriceTextFromMicros,
  getPriceMicrosFromText,
} from "@natomas-org/core";
import Map from "./Map";
import {
  getLocationLabelFromKey,
  LocationPermitPlansLabelSlug,
  LocationSitePreparationFoundationLabelSlug,
  LocationStandardFinishingLabelSlug,
  LocationUtilitiesLabelSlug,
  LocationVillaProjectManagementLabelSlug,
} from "./EditLocation";

const alert = (message: string) => {
  window.alert(message);
  return null;
};

const getSum = (formData: IInstantQuoteProductLocationForm) => {
  let sum = 0;
  sum += formData["location-permits-plans"];
  sum += formData["location-construction-installation"];
  sum += formData["location-utilities"];
  sum += formData["location-project-management"];
  sum += formData["location-standard-finishing"];
  return sum;
};

const getSchema = (
  form: IInstantQuoteProductLocationForm
): InstantQuoteProductLocationSchema | null => {
  if (!form["location-name"]) return alert("Name is required");
  if (!form["location-latitude"]) return alert("Latitude is required");
  if (!form["location-longitude"]) return alert("Longitude is required");
  if (!form["location-permits-plans"])
    return alert(
      `${LocationPermitPlansLabelSlug} is required, and cannot be $0`
    );
  if (!form["location-construction-installation"])
    return alert(
      `${LocationSitePreparationFoundationLabelSlug} is required, and cannot be $0`
    );
  if (!form["location-utilities"])
    return alert(`${LocationUtilitiesLabelSlug} is required, and cannot be $0`);
  if (!form["location-project-management"])
    return alert(
      `${LocationVillaProjectManagementLabelSlug} is required, and cannot be $0`
    );
  if (!form["location-standard-finishing"])
    return alert(
      `${LocationStandardFinishingLabelSlug} is required, and cannot be $0`
    );

  return {
    location_id: form["location-id"] ?? undefined,
    label: form["location-name"],
    latitude: form["location-latitude"],
    longitude: form["location-longitude"],
    items: [
      {
        slug: LocationPermitPlansLabelSlug,
        price: form["location-permits-plans"],
      },
      {
        slug: LocationSitePreparationFoundationLabelSlug,
        price: form["location-construction-installation"],
      },
      {
        slug: LocationUtilitiesLabelSlug,
        price: form["location-utilities"],
      },
      {
        slug: LocationVillaProjectManagementLabelSlug,
        price: form["location-project-management"],
      },
      {
        slug: LocationStandardFinishingLabelSlug,
        price: form["location-standard-finishing"],
      },
    ],
  };
};

export interface IInstantQuoteProductLocationForm {
  "location-name": string;
  "location-latitude": number;
  "location-longitude": number;
  "location-permits-plans": number;
  "location-construction-installation": number;
  "location-utilities": number;
  "location-project-management": number;
  "location-standard-finishing": number;
  "location-id"?: string;
}

function getObjectDifferences(obj1: any, obj2: any): any[] {
  if (!obj1 || !obj2) return [];
  const keys = _.union(Object.keys(obj1), Object.keys(obj2));

  return keys.reduce((differences: any[], key: string) => {
    if (!_.isEqual(obj1[key], obj2[key])) {
      differences.push({
        key,
        oldValue: obj1[key],
        newValue: obj2[key],
      });
    }
    return differences;
  }, []);
}

const getDefault = (): IInstantQuoteProductLocationForm => {
  return {
    "location-name": "",
    "location-latitude": 0,
    "location-longitude": 0,
    "location-permits-plans": 0,
    "location-construction-installation": 0,
    "location-utilities": 0,
    "location-project-management": 0,
    "location-standard-finishing": 0,
  };
};

const Form = (props: {
  hidden: boolean;
  hide: () => void;
  softLocked: boolean;
  initialFormData?: IInstantQuoteProductLocationForm;
  action?: (schema: InstantQuoteProductLocationSchema) => Promise<boolean>;
}) => {
  const {initialFormData, hidden, hide, action, softLocked} = props;
  // State to manage form data
  const [formData, setFormData] = useState<IInstantQuoteProductLocationForm>(
    getDefault()
  );
  const [lockedCoordinates, setLockedCoordinates] =
    useState<boolean>(softLocked);

  const defaultFormData = useMemo(() => {
    const defaultValues = initialFormData ?? getDefault();
    setFormData(defaultValues);
    return defaultValues;
  }, [initialFormData]);

  const reset: () => void = useCallback(() => {
    setLockedCoordinates(true);
    hide();
  }, [hide, setLockedCoordinates]);

  const handleSubmit = () => {
    const schema = getSchema(formData);
    if (!schema) return;
    if (action) {
      action(schema).then((success: boolean) => {
        if (success) {
          reset();
        }
      });
    }
  };

  // Function to handle form input changes
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {id, value, type} = e.target;
    if (type === "number") {
      setFormData({
        ...formData,
        [id]: parseFloat(value),
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleMapCoordinatesChange = (lat: number, lng: number): void => {
    if (lockedCoordinates) {
      return;
    } else {
      setFormData((current) => {
        return {
          ...current,
          "location-latitude": lat,
          "location-longitude": lng,
        };
      });
    }
  };

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target;
    const numberValue = Math.round(getPriceMicrosFromText(value));
    setFormData({
      ...formData,
      [id]: numberValue,
    });
  };

  const changes = useMemo(() => {
    return getObjectDifferences(defaultFormData, formData);
  }, [formData, defaultFormData]);

  const formatNumber = (micros: number) => {
    return fullPriceTextFromMicros(micros, {
      showPositiveSign: false,
      hideCommas: false,
      hideCurrency: false,
      roundToInteger: false,
    });
  };

  return (
    <div
      hidden={hidden}
      className={
        "vcl-container-primary-16 vcl-border-primary vcl-background-cool-gray"
      }
    >
      <div className={"vcl-container-row-spaced"}>
        <div className={"vcl-container-primary-16"}>
          <VCL.Components.Molecules.TextEntry
            label={"Name"}
            input={{
              id: "location-name",
              value: formData["location-name"],
              required: true,
              type: "text",
              autoComplete: "on",
              onChange: handleChange,
            }}
          />
          <VCL.Components.Molecules.TextEntry
            label={"Latitude"}
            input={{
              id: "location-latitude",
              value: formData["location-latitude"],
              required: true,
              type: "number",
              autoComplete: "on",
              onChange: handleChange,
            }}
            options={{
              lock: {
                isLocked: lockedCoordinates,
                unlock: () => {
                  setLockedCoordinates(false);
                },
              },
            }}
          />
          <VCL.Components.Molecules.TextEntry
            label={"Longitude"}
            input={{
              id: "location-longitude",
              value: formData["location-longitude"],
              required: true,
              type: "number",
              autoComplete: "on",
              onChange: handleChange,
            }}
            options={{
              lock: {
                isLocked: lockedCoordinates,
                unlock: () => {
                  setLockedCoordinates(false);
                },
              },
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            position: "relative",
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <Map
            label={formData["location-name"]}
            longitude={formData["location-longitude"]}
            latitude={formData["location-latitude"]}
            setCoordinates={handleMapCoordinatesChange}
          />
        </div>
      </div>

      <VCL.Components.Atoms.Spacer
        style={{
          backgroundColor: "var(--black-10)",
          height: "1px",
        }}
      />
      <VCL.Components.Molecules.TextEntry
        label={getLocationLabelFromKey(LocationPermitPlansLabelSlug)}
        input={{
          id: "location-permits-plans",
          value: formatNumber(formData["location-permits-plans"]),
          required: true,
          type: "text",
          autoComplete: "on",
          onChange: handlePriceChange,
        }}
      />
      <VCL.Components.Molecules.TextEntry
        label={getLocationLabelFromKey(
          LocationSitePreparationFoundationLabelSlug
        )}
        input={{
          id: "location-construction-installation",
          value: formatNumber(formData["location-construction-installation"]),
          required: true,
          type: "text",
          autoComplete: "on",
          onChange: handlePriceChange,
        }}
      />
      <VCL.Components.Molecules.TextEntry
        label={getLocationLabelFromKey(LocationUtilitiesLabelSlug)}
        input={{
          id: "location-utilities",
          value: formatNumber(formData["location-utilities"]),
          required: true,
          type: "text",
          autoComplete: "on",
          onChange: handlePriceChange,
        }}
      />
      <VCL.Components.Molecules.TextEntry
        label={getLocationLabelFromKey(LocationVillaProjectManagementLabelSlug)}
        input={{
          id: "location-project-management",
          value: formatNumber(formData["location-project-management"]),
          required: true,
          type: "text",
          autoComplete: "on",
          onChange: handlePriceChange,
        }}
      />
      <VCL.Components.Molecules.TextEntry
        label={getLocationLabelFromKey(LocationStandardFinishingLabelSlug)}
        input={{
          id: "location-standard-finishing",
          value: formatNumber(formData["location-standard-finishing"]),
          required: true,
          type: "text",
          autoComplete: "on",
          onChange: handlePriceChange,
        }}
      />
      <VCL.Components.Atoms.Spacer
        style={{
          backgroundColor: "var(--black-10)",
          height: "1px",
        }}
      />
      <VCL.Components.Atoms.Text
        text={fullPriceTextFromMicros(getSum(formData), {
          showPositiveSign: false,
          hideCommas: false,
          hideCurrency: false,
          roundToInteger: false,
        })}
        style={{fontSize: "24px", fontFamily: "Inter-Medium"}}
      />
      <div className={"vcl-container-row-spaced"}>
        <VCL.Components.Atoms.Button
          label={"Cancel"}
          type={"button"}
          size={"large"}
          onClick={() => reset()}
          theme={"secondary"}
        />
        <VCL.Components.Atoms.Button
          type={"button"}
          disabled={changes.length === 0}
          style={
            changes.length === 0
              ? {backgroundColor: "var(--black-60)"}
              : undefined
          }
          onClick={() => handleSubmit()}
          label={"Save Changes"}
        />
      </div>
    </div>
  );
};

export default Form;
