import styled from "styled-components";
import {
  DIVIDER_COLOR,
  SUPER_ADMIN_HIGHLIGHT_COLOR,
  VILLA_BLUE,
} from "../../_shared/colors";
import {FONT_FAMILY_BOLD} from "../../portal/views/SSDDashboardView/styles/globals";
import {HEADER_Z_INDEX} from "../../_shared/styles";

export const EmployeeManagementContainer = styled.div`
  padding: 32px;
  background: ${DIVIDER_COLOR};
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const EmployeeTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const EmployeeManagementTitle = styled.div`
  // font-size: 1.5rem;
  // font-family: ${FONT_FAMILY_BOLD};
  // margin: 1rem;
`;

export const EmployeeTable = styled.table`
  border-spacing: 0;
  border: 1px solid black;
  border-collapse: separate; /* Don't collapse */
  thead {
    position: sticky;
    top: 0;
    z-index: ${HEADER_Z_INDEX};
  }
  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    font-size: 12px;
    :last-child {
      border-right: 0;
    }
  }
`;

export const SuperAdminTag = styled.div`
  color: ${SUPER_ADMIN_HIGHLIGHT_COLOR};
  font-family: ${FONT_FAMILY_BOLD};
`;

export const AccessTag = styled.div`
  color: ${VILLA_BLUE};
  font-family: ${FONT_FAMILY_BOLD};
`;
