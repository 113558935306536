import React from "react";
import {IProject} from "../../../../../../_shared/slices/ProjectSlice/ProjectSlice";
import {useCartItems} from "../../../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import {ICartItem} from "@natomas-org/core";
import ProductInfoCell from "./ProductInfoCell";
import {useProject} from "../../../../../../_shared/hooks/useProject/useProject";

interface CustomerProjectProductCellProps {
  projectId?: string;
}

const CustomerProjectProductCell = (props: CustomerProjectProductCellProps) => {
  const {projectId} = props;
  const {isCartEnabled, cartItemIds} = useProject(projectId);
  const cartItems: ICartItem[] = useCartItems(cartItemIds ?? []);
  if (!projectId) return <div>Loading...</div>;

  return (
    <div>
      {cartItems?.map((cartItem: ICartItem, index: number) => {
        if (!isCartEnabled && index > 0) {
          return null;
        }
        return (
          <ProductInfoCell
            key={`${projectId}-cart-product-${index}`}
            productId={cartItem?.details?.productId}
            factoryId={cartItem?.details?.factoryId}
          />
        );
      })}
    </div>
  );
};

export default CustomerProjectProductCell;
