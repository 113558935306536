import {NatModal} from "../../../../modal/NatModal";
import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../../application";
import {useFormik} from "formik";
import {getInitialValues, saveNewImageTag, TagStructure} from "./helper";
import {AdminInput} from "../../../../../../admin/_shared/components/AdminComponentFactory/AdminComponentFactory";
import {AddNewImageTagModalErrorMessage, SanitizedTagTitle} from "./styles";

export const AddNewImageTagModal = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const {show, setShow} = props;

  const tagStructure = getFormStructure(TagStructure);

  const [initialValues] = useState(
    getInitialValues(getFormInitialValues(TagStructure))
  );

  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [spinning, setSpinning] = useState<boolean>(true);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
    setErrorMessage(undefined);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      const infoToSave = Object.assign({}, values);
      return saveNewImageTag(infoToSave?.label)
        .then(() => handleClose())
        .catch(() => {
          setSpinning(false);
          setErrorMessage(
            "Error: Tag already exists, or something went wrong."
          );
        });
    },
  });

  const sanitizedLabel = useMemo(() => {
    const l = formik?.values?.["label"];
    if (!!l) {
      setErrorMessage(undefined);
      return (
        "What will be saved: " + l?.replace(/[^a-zA-Z_]/gi, "")?.toUpperCase()
      );
    }
    return null;
  }, [formik?.values?.["label"]]);

  const getModalContent = () => {
    return (
      <div>
        <div>Only use alphabetical characters, and underscores.</div>
        <div>Image tags are not case-sensitive.</div>
        {tagStructure?.map((item: any, index: number) => {
          const {key} = item;
          // @ts-ignore
          let value = formik?.values?.[key];
          // @ts-ignore
          let initial = initialValues?.[key];
          return (
            <div key={"add-image-tag-field-" + index}>
              <AdminInput
                key={key}
                value={value}
                fieldInfo={item}
                initialValue={initial}
                onChange={(value: any) => {
                  formik.setFieldValue(key, value);
                }}
              />
            </div>
          );
        })}
        <SanitizedTagTitle>{sanitizedLabel}</SanitizedTagTitle>
        <AddNewImageTagModalErrorMessage>
          {errorMessage}
        </AddNewImageTagModalErrorMessage>
      </div>
    );
  };

  return (
    <NatModal
      show={show}
      header={"Product Catalog: Create a new image tag"}
      centeredContent={true}
      content={getModalContent()}
      footerButtons={[
        {
          label: "Save",
          disabled: !!errorMessage || !sanitizedLabel,
          spinnerEnabled: spinning,
          type: "button",
          clickEvent: () => formik.handleSubmit(),
        },
      ]}
      handleClose={() => handleClose()}
    />
  );
};
