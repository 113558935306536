import styled from "styled-components";

// Contains all the content of authentication modal
export const ContentContainer = styled.div`
  max-width: 450px;
  margin: 16px auto 16px auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
`;

// Contains the dynamic content of the authentication modal
// - This component should be mounted dynamically to make use of the fadeIn animation
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  a {
    font-size: 12px !important;
  }
  span {
    font-size: 12px !important;
  }
`;
