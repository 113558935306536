import React, {useContext} from "react";
import styled from "styled-components";
import NatLabel, {INatLabel} from "../label/NatLabel";
import {SmartImage} from "./SmartImage";
import {PROPOSAL_GENERATE_PDF} from "../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/components/constants";

export const ImageAndTextView = (props: IImageAndTextViewProps) => {
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);

  return (
    <Container>
      <TextContainer>
        {props.labels.map((label) => (
          <NatLabel {...label} />
        ))}
      </TextContainer>
      <ImageContainer style={props.imageStyle}>
        {props.images.map((image) => {
          return (
            <SmartImage
              preserveAspectRatio={true}
              imageId={image.imageId}
              imageURL={image.imageURL}
              forceWidth={"369px"}
              forceHeight={"278px"}
            />
          );
        })}
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  position: relative;
  column-gap: 3rem;
  margin: 1rem 0;
  align-items: center;
  page-break-inside: avoid;
  break-inside: avoid;
`;

const ImageContainer = styled.div`
  flex-grow: 1;
  width: 40%;
  min-height: 278px;
  position: relative;
  min-width: 300px;
  border-radius: 1rem;
  overflow: hidden;
`;

const TextContainer = styled.div`
  width: 50%;
  flex-grow: 1;
`;

export interface IImageAndTextViewProps {
  images: {imageId?: string; imageURL?: string}[];
  labels: INatLabel[];
  imageStyle?: React.CSSProperties;
}
