import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import {store} from "../../../store";
//Core
import {Customer, Event, EventStatus, IEventInfo} from "@natomas-org/core";
//Shared
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {Page} from "../../_shared/generics/page";
import {Catalog} from "../_shared/components/Catalog";
import {updateAdditionLeftPadding} from "../../_shared/slices/PageSlice";
import {ProductDiscoveryView} from "./ProductDiscoveryView";
import {LoadingPanel} from "../../_shared/generics/loading-panel";
import {usePage} from "../../_shared/hooks/usePage";
import {customerInfoToNatomasCustomer} from "../../../database/firebase/adapters";
import {Users} from "../../../database/firebase";
import {applyReset, checkForFilter} from "../../_shared/catalog/filter/logic";
import {useCatalogSession} from "../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {PortalHeader} from "../../portal/_shared/components/PortalHeader";
import {useIsPublicApp} from "../../_shared/hooks/useIsPublicApp";
import LegalCopyright from "../../../0-app/1-container/legal-copyright";
import {INVENTORY_UNIT_PREFIX} from "../../_shared/constants/labels";

export enum CatalogMode {
  NONE,
  PRODUCT_LINES,
  PRODUCT_LINE,
  PRODUCT,
  INVENTORY,
}
const PortalCatalog = (props: {defaultMode?: CatalogMode}) => {
  const location = useLocation();
  const {defaultMode} = props;
  //Custom Hooks
  const {customer} = useCurrentCustomer();
  const {filters} = useCatalogSession();
  const {leftPadding} = usePage();
  const [showFinder, setShowFinder] = useState<boolean>(false);
  const isPublicApp = useIsPublicApp();
  const {isNatMobile} = usePage();
  //Effects
  useEffect(() => {
    //Catalog view has no sidebar - make sure padding is reset.
    store.dispatch(updateAdditionLeftPadding(0));
    return () => {
      //Reset when we leave portal
      applyReset();
    };
  }, []);

  const firstTimeUser = useMemo(() => {
    if (!customer) {
      return true;
    }
    //See if event exists
    let r = Customer.getEvents(customer).find(
      (event: IEventInfo) => event.event_id === "catalog_form"
    );
    //Check to see if completed
    return r?.details?.status !== EventStatus.COMPLETED;
  }, [customer]);

  const completeDiscovery = () => {
    setShowFinder(false);
    if (!firstTimeUser || !customer) {
      applyReset();
      return;
    }

    const event = Event.createGenericEvent(
      "catalog_form",
      Date.now(),
      EventStatus.COMPLETED
    );
    const newInfo = Customer.addEvent(customer, event);
    const natUser = customerInfoToNatomasCustomer(newInfo);
    Users.setNatomasUser(natUser.user_id, natUser);
    applyReset();
  };

  const showLoading = () => {
    return leftPadding !== 0;
  };

  const isInventoryView = location.pathname.includes(
    INVENTORY_UNIT_PREFIX.toLowerCase()
  );
  return (
    <>
      <LoadingPanel show={showLoading()} />
      <PortalHeader
        showAppSwitcher={isPublicApp}
        title={isNatMobile ? undefined : <>Our Homes</>}
      />
      <Page>
        {showFinder ? (
          <ProductDiscoveryView completeDiscovery={completeDiscovery} />
        ) : (
          <Catalog
            setShowFinder={setShowFinder}
            applyDefaultFilterOptions={!checkForFilter("bedroom", filters)}
            defaultMode={
              isInventoryView
                ? CatalogMode.INVENTORY
                : defaultMode ?? CatalogMode.PRODUCT_LINES
            }
          />
        )}
      {`${window.location.pathname}${window.location.search}`.match(/^\/products(.*)?unit=.*/)
        // Show the Legal Copyright only when the GlobalFooter is not displayed (Only when there is a unit selected)
        ? <LegalCopyright />
        : <></>
      }
      </Page>
    </>
  );
};

export default PortalCatalog;
