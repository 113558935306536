import {IFactoryInventoryStudioEditorModifierGroup} from "../Editor/ModifierGroupSection/interface";
import {IFactoryInventoryStudioEditorModifier} from "../Editor/ModifierSection/interface";
import {IFactoryInventoryStudioEditorCategory} from "../Editor/CategorySection/interface";
import {IFactoryInventoryStudioEditorCategoryImageGroup} from "../Editor/CategorySection/CategoryImagesEditor/interface";

export const getDefaultModifierGroup = (
  id: string,
  index: number
): IFactoryInventoryStudioEditorModifierGroup => {
  return {
    id: id,
    index: index,
    title: "New Modifier Group",
  };
};

export const getDefaultModifier = (
  id: string,
  index: number
): IFactoryInventoryStudioEditorModifier => {
  return {
    id: id,
    index: index,
    title: "New Modifier",
  };
};

export const getDefaultCategory = (
  id: string,
  index: number
): IFactoryInventoryStudioEditorCategory => {
  return {
    id: id,
    title: "New Category",
    index: index,
  };
};

export const getDefaultRendering = (
  id: string,
  index: number
): IFactoryInventoryStudioEditorCategoryImageGroup => {
  return {
    id: id,
    index: index,
    images: [],
  };
};
