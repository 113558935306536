import {
  Customer,
  DateUtils,
  IConfiguration,
  IConfigurationSite,
  IConfigurationSnapshot,
  ICustomerInfo,
  IDocumentDetails,
  IEventId,
  IProjectInfo,
  Person,
  Project,
  StringParameter,
  TeamMemberRole,
} from "@natomas-org/core";
import {CallToActionSectionProps} from "../../../SSDDashboardView/components/ANDY-components/CallToActionSection";
import {
  GREETING_KICK_OFF_COMPLETE_NO_UPCOMING_PROPOSAL,
  GREETING_PROPOSAL_COMPLETE,
  GREETING_SCHEDULE_KICK_OFF,
  GREETING_UPCOMING_DESIGN_SESSION,
  GREETING_UPCOMING_KICK_OFF,
  GREETING_UPCOMING_PROPOSAL_LOCKED,
  GREETING_UPCOMING_PROPOSAL_NO_LOCK,
  GREETING_WAITING_FOR_HC,
  IGreetingStatus,
} from "../constants/HCPSections";

export const determineCTAContent = (
  customer: ICustomerInfo | null | undefined,
  project: IProjectInfo | null | undefined,
  configuration: IConfiguration | null | undefined,
  configurationSnapshot: IConfigurationSnapshot | undefined,
  launchKickOff: () => void,
  configurationSite: IConfigurationSite | undefined,
  to: any,
  globalConfig: any,
  isNatMobile: boolean,
  budgetHidden: boolean,
  documentDictionary: {[p: string]: IDocumentDetails}
): CallToActionSectionProps => {
  if (!customer || !project) {
    return {
      buttons: [],
      footerLinks: [],
      footerTexts: [],
      titles: [],
    };
  }

  const modelHomeTourEvent = Project.getProjectEventWithOptions(project, {
    eventId: IEventId.MODEL_HOME_TOUR,
    statusType: "all",
  });

  const status: IGreetingStatus = {
    scheduledModelHomeTour:
      !!modelHomeTourEvent || !globalConfig.MODEL_HOME_TOUR_ENABLED,
    selectedProduct: !!configuration?.product,
  };

  let content;
  let date = new Date();
  if (project?.team_members?.length < 1) {
    content = GREETING_WAITING_FOR_HC(to, status);
  } else {
    const today = new Date();
    const todayTimestamp = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    ).getTime();
    const kickOffDate = Project.getEvent(project, "kick-off");
    const kickOffDateTimestamp = convertSalesforceDateToLocalTime(
      kickOffDate?.timestamp
    );
    // Design session event
    const designSessionDate = Project.getEvent(
      project,
      IEventId.DESIGN_SESSION
    );
    const designSessionTimestamp = convertSalesforceDateToLocalTime(
      designSessionDate?.timestamp
    );

    const proposalDate = Project.getEvent(project, "proposal");
    const proposalDateTimestamp = convertSalesforceDateToLocalTime(
      proposalDate?.timestamp
    );
    if (kickOffDate == null) {
      content = GREETING_SCHEDULE_KICK_OFF(launchKickOff, status);
    } else if (kickOffDateTimestamp && todayTimestamp < kickOffDateTimestamp) {
      date = new Date(kickOffDateTimestamp);
      content = GREETING_UPCOMING_KICK_OFF(to, status);
    } else if (
      designSessionTimestamp &&
      todayTimestamp < designSessionTimestamp
    ) {
      date = new Date(designSessionTimestamp);
      content = GREETING_UPCOMING_DESIGN_SESSION(to, status);
    } else if (proposalDate == null) {
      content = GREETING_KICK_OFF_COMPLETE_NO_UPCOMING_PROPOSAL;
    } else if (
      proposalDateTimestamp &&
      todayTimestamp < proposalDateTimestamp
    ) {
      date = new Date(proposalDateTimestamp);
      if (!project?.settings?.design_lock?.is_locked) {
        content = GREETING_UPCOMING_PROPOSAL_NO_LOCK;
      } else {
        content = GREETING_UPCOMING_PROPOSAL_LOCKED;
      }
    } else {
      content = GREETING_PROPOSAL_COMPLETE(
        globalConfig,
        project,
        isNatMobile,
        budgetHidden,
        documentDictionary,
        customer
      );
    }
  }

  if (configuration?.product && configurationSite?.visible) {
    content = GREETING_PROPOSAL_COMPLETE(
      globalConfig,
      project,
      isNatMobile,
      budgetHidden,
      documentDictionary,
      customer
    );
  }

  return {
    buttons: content.buttons,
    footerLinks: content.footerLinks,
    footerTexts: content.footerTexts,
    titles: content.titles.map((title) =>
      populateTextParameters(title, customer, project, date)
    ),
    titleComponents: content.titleComponents?.map((e) => {
      return {
        text: populateTextParameters(e.text, customer, project, date),
        style: e.style,
      };
    }),
  };
};

const populateTextParameters = (
  text: string,
  customer: ICustomerInfo,
  project: IProjectInfo,
  date: Date
): string => {
  const teamMember = Project.getTeamMemberForRole(project, TeamMemberRole.HC);

  return text
    .replace(
      StringParameter.CUSTOMER_FIRST_NAME,
      Customer.getFirstName(customer)
    )
    .replace(StringParameter.CUSTOMER_LAST_NAME, Customer.getLastName(customer))
    .replace(
      StringParameter.OWNER_FIRST_NAME,
      Person.getFirstName(teamMember?.personInfo)
    )
    .replace(
      StringParameter.DATE,
      `${DateUtils.getMonthName(date)} ${DateUtils.getCalendarDayAndSuffix(
        date
      )}`
    );
};

// Converts a date saved as 12 AM GMT to local time 12 PM
export const convertSalesforceDateToLocalTime = (
  timestamp: number | undefined
): number | undefined => {
  if (!timestamp) {
    return undefined;
  }
  const twelveHours = 12 * 60 * 60 * 1000;
  return (
    new Date(timestamp).getTimezoneOffset() * 60 * 1000 +
    timestamp +
    twelveHours
  );
};
