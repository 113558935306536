import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import React, {useEffect, useState} from "react";
import {
  scrollToElementWithOffset,
  scrollToTop,
} from "../../../../_shared/navigation/_helpers";
import {HCP_SECTIONS} from "./navigation_ids";
import {HCPTitleNavWrapper} from "./styles";
import {NatPopupItem} from "../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../../../_shared/generics/popup/dropdown/constants";
import {NatSizeType} from "../../../../_shared/generics/_shared";
import {usePage} from "../../../../_shared/hooks/usePage";

export const HCPTitleNav = () => {
  const sections = HCP_SECTIONS;
  const [selectedValue, setSelectedValue] = useState<NatPopupItem>(
    sections?.[0]
  );
  const {isNatMobile} = usePage();

  useEffect(() => {
    scrollToTop(false);
    window.addEventListener("scroll", getLabel);
  }, []);

  const getLabel = () => {
    const headerHeight = 64;
    let minDistance = Infinity;
    let minOption: NatPopupItem | null;
    sections?.forEach((option: NatPopupItem) => {
      const doc = document.getElementById(option?.id)?.getBoundingClientRect();
      if (doc) {
        let docDistance = Math.abs(doc.y - headerHeight);
        if (docDistance < minDistance) {
          minOption = option;
          minDistance = docDistance;
        }
      }
    });
    // @ts-ignore
    if (minOption) {
      setSelectedValue(minOption);
    }
  };

  const getOptions = () => {
    return sections
      .filter((section: NatPopupItem) => !!document.getElementById(section?.id))
      ?.map((item) => {
        return {
          ...item,
          callback: (option: NatPopupItem) => {
            if (option?.id === undefined) {
              scrollToTop(true);
            } else {
              scrollToElementWithOffset(option?.id, {top: 70}, true);
            }
          },
        };
      });
  };

  return (
    <HCPTitleNavWrapper>
      <NatDropdown
        toggleButtonOrLabel={selectedValue?.label}
        selectedValue={selectedValue}
        contentItemArray={getOptions()}
        trackingId={"hcp-title-nav"}
        style={{
          ...NatDropdownStyles[DropdownStyles.DYNAMIC_PAGE_TITLE][
            isNatMobile ? NatSizeType.NORMAL : NatSizeType.LARGE
          ],
          dropdownButtonCSS: {
            padding: 0,
          },
        }}
      />
    </HCPTitleNavWrapper>
  );
};
