import React, {useCallback, useEffect, useMemo} from "react";
import {Helmet} from "react-helmet-async";
import {
  StudioContent,
  StudioContentProps,
} from "../_shared/components/StudioContent";
import {DesignerController} from "./components/DesignerController";
import {useStudioSettings} from "../../_shared/hooks/useStudio/useStudioSettings";
import {useDispatch} from "react-redux";
import {
  setEditingSelections,
  setSaving,
  setShowSavingConfirmation,
  setStudioSelections,
} from "../../_shared/slices/StudioSlice";
import {saveStudioDesignToConfiguration} from "./helper";
import {StudioWarningModal} from "../_shared/components/StudioWarningModal";
import {StudioUnitCard} from "../_shared/components/StudioUnitCard";
import {StudioNavigationBar} from "../_shared/components/StudioNavigationBar";
import {useIsPublicApp} from "../../_shared/hooks/useIsPublicApp";
import {StudioSaveWarningModal} from "../_shared/components/StudioSaveWarningModal";
import {DesktopFixedAdminContainer} from "../_shared/components/StudioContent/styles";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import useActiveDesign from "../../_shared/hooks/useDesign/useActiveDesign";
import useCatalogCartController from "../../_shared/hooks/useCart/Controllers/useCatalogCartController";
import {useCartItemMetadata} from "../../_shared/hooks/useCart/useCartItem/useCartItemMetadata";
import {useActiveCartItem} from "../../_shared/hooks/useCart/useCartItem/useActiveCartItem";
import {useCartItemSnapshot} from "../../_shared/hooks/useCart/useCartItem/useCartItemSnapshot";
import {ICartItem} from "@natomas-org/core";
import useActiveProject from "../../_shared/hooks/useProject/useActiveProject";
import {AdminStudioController} from "../../_shared/generics/admin-controller/AdminStudioController";

interface StudioDesignProps extends StudioContentProps {}

export const Designer = (props: StudioDesignProps) => {
  const {
    height,
    formik,
    stackedMode,
    summary,
    structure,
    product,
    price,
    options,
    atBottom,
    priceMode,
  } = props;
  const {isEditing} = useStudioSettings();
  const {saveCartItem} = useCatalogCartController();
  const activeCartItem = useActiveCartItem();
  const {isProjectLocked, isCartEnabled, id: projectId} = useActiveProject();
  const {hasDesign} = useCartItemSnapshot(activeCartItem ?? ({} as ICartItem));
  const {details} = activeCartItem ?? {};
  const {
    productId: cartItemProductId,
    factoryId: cartItemFactoryId,
    versionId: cartItemVersionId,
  } = details ?? {};
  const {configuration, id: designId} = useActiveDesign();
  const {source, isUpToDate} = useCartItemMetadata(activeCartItem);
  const {selections, product: activeProduct} = configuration ?? {};
  const dispatch = useDispatch();
  const isPublicApp = useIsPublicApp();
  const {isAdmin} = useCurrentUser();

  useEffect(() => {
    // If the product is not currently selected, automatically enable editing
    // Or, if you are using the public app, enable editing automatically
    const isProductSaved =
      cartItemProductId === product?.id &&
      cartItemFactoryId === product?.productGroupId &&
      cartItemVersionId === product?.versionId;
    const isDefaultEditing = isPublicApp || !isProductSaved || !hasDesign;
    if (!product) {
      return;
    }
    if (isDefaultEditing && !isEditing && !isProjectLocked && isUpToDate) {
      dispatch(setEditingSelections(true));
    }
  }, [
    cartItemFactoryId,
    cartItemProductId,
    cartItemVersionId,
    dispatch,
    hasDesign,
    isProjectLocked,
    isPublicApp,
    isUpToDate,
    product,
    isEditing,
  ]);

  // We allow saves in design studio when it is a new product, or editing is enabled
  const canSave = useMemo(() => {
    return activeProduct?.id !== product?.id || isEditing;
  }, [activeProduct, product, isEditing]);

  const save = useCallback(
    async (formik: any, summary: any) => {
      dispatch(setSaving(true));
      dispatch(setShowSavingConfirmation(true));
      dispatch(setEditingSelections(false));
      const id = await saveStudioDesignToConfiguration(
        projectId,
        formik,
        summary,
        designId
      );
      if (id) {
        console.log("saving cart item", id, source, activeCartItem);
        saveCartItem(product, id, source, activeCartItem);
      }
      // if (!isCartEnabled) {
      //   executeRequest("/project/v1/regenerateSiteWorkQuote", {
      //     projectId: projectId,
      //     productGroupId: product.productGroupId,
      //     productId: product.id,
      //   });
      // }
      dispatch(setSaving(false));
    },
    [
      activeCartItem,
      designId,
      dispatch,
      isCartEnabled,
      product,
      projectId,
      saveCartItem,
      source,
    ]
  );

  const reset = useCallback(() => {
    // Set studio selections to configuration selections
    dispatch(setStudioSelections(selections));
    dispatch(setEditingSelections(false));
  }, [dispatch, selections]);

  const eligibleCategories = useMemo(() => {
    console.log("Designer-eligibleCategories", structure);
    if (structure) {
      if (isAdmin) {
        return structure;
      } else {
        return structure?.filter(
          (category: any) => !category?.isAdminSelection
        );
      }
    } else {
      return [];
    }
  }, [isAdmin, structure]);

  const controller = useMemo(() => {
    return (
      <DesignerController
        formik={formik}
        summary={summary}
        minimize={false}
        canSave={canSave}
        save={save}
        reset={reset}
        options={options}
      />
    );
  }, [formik, summary, canSave, save, reset, options]);

  return (
    <>
      <Helmet>
        <title>Villa | Design Studio</title>
        <meta name="description" content="Design your dream ADU" />
      </Helmet>
      <DesktopFixedAdminContainer>
        <AdminStudioController />
      </DesktopFixedAdminContainer>
      <StudioNavigationBar
        stackedMode={stackedMode}
        height={"4rem"}
        studioPrice={price}
        displayOptions={options}
      />
      <StudioContent
        {...props}
        product={product}
        summary={summary}
        structure={structure}
        height={height}
        eligibleCategories={eligibleCategories}
      />
      <StudioUnitCard
        priceMode={priceMode}
        stackedMode={stackedMode}
        isEditing={isEditing}
        atBottom={atBottom ?? false}
        controller={controller}
        price={price}
        product={product}
        structure={eligibleCategories}
        selectedModifiers={summary?.selectedModifiers}
        additionLineItemTotalInMicros={summary?.additionalItemsTotalInMicros}
      />
      <StudioSaveWarningModal
        onConfirmSave={() => {
          return save(formik, summary);
        }}
      />
      <StudioWarningModal canSave={canSave} />
    </>
  );
};
