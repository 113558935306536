import React, {useContext} from "react";
import styled from "styled-components";
import {HCPComponentHR} from "../HCPDashboardLayout/styles";
import {
  IImageAndTextViewProps,
  ImageAndTextView,
} from "../../../_shared/generics/image/ImageAndTextView";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../_shared/generics/label/NatLabel";
import {NextStepsComponent} from "../HCPDashboardLayout/HCPNextSteps/components/NextStepsComponent";
import {PROPOSAL_GENERATE_PDF} from "../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {
  FULL_SCREEN_HEIGHT_REM,
  FULL_SCREEN_WIDTH_REM,
} from "../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";
import {NatHorizontalAlign} from "../../../_shared/generics/_shared";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";

const PHASE_1_CONTENTS: IImageAndTextViewProps[] = [
  {
    labels: [
      {label: "Expert and thorough inspection", type: NatLabelType.H3},
      {
        label:
          "We make sure that every step is smooth and stress-free for our customers. That’s why we have a skilled electrician and plumber make a series of site visits to ensure your ADU will be safe and functional.",
        type: NatLabelType.P1,
      },
    ],
    images: [
      {
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/assets%2Fphotos%2Fshaking-hands.jpg?alt=media&token=e94ce357-9f9d-457a-b903-18a2d9a5d6ab",
      },
    ],
  },
  {
    labels: [
      {
        label: "Property scan with state-of-the-art technology",
        type: NatLabelType.H3,
      },
      {
        label:
          "We'll get a detailed, accurate view of your property using an orthomosaic 3D drone scan. This allows us to ensure your ADU will be placed perfectly onto your property.",
        type: NatLabelType.P1,
      },
    ],
    images: [
      {
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/assets%2Fphotos%2Fbackyard-placement.jpg?alt=media&token=f9e17190-22a6-44d8-a28e-88b6902cf889",
      },
    ],
  },
  {
    labels: [
      {
        label: "Permitting made simple",
        type: NatLabelType.H3,
      },
      {
        label:
          "While you’re dreaming of where to hang the “Home Sweet Home” sign, we’ll be creating plans and pulling permits. You’ll be glad we handle the many required documents and the back-and-forth with local agencies.",
        type: NatLabelType.P1,
      },
      // {
      //   label:
      //     "Your Phase 1 fee is secure - if permits aren’t approved, you’ll receive a refund of your Phase 1 payment.",
      //   type: NatLabelType.P1,
      //   weight: NatLabelWeight.BOLD,
      // },
    ],
    images: [
      {
        imageURL:
          "https://firebasestorage.googleapis.com/v0/b/natomas-app.appspot.com/o/assets%2Fphotos%2Fblueprint.jpg?alt=media&token=e9ac7da7-7e6d-4c0f-ae3b-dabde2741ed3",
      },
    ],
  },
];

const Phase1ContentContainer = styled.div`
  page-break-after: always;
  page-break-inside: avoid;
  break-after: page;
  break-inside: avoid;
`;

interface IPhase1ViewProps {}

const IMAGE_ASPECT_RATIO = 1.27;
const PDF_IMAGE_HEIGHT_REM = FULL_SCREEN_HEIGHT_REM / 8;
export const Phase1View = (props: IPhase1ViewProps) => {
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const nextStepsStyle: React.CSSProperties = {
    width: isGeneratingPDF ? `${FULL_SCREEN_WIDTH_REM * 0.927}rem` : undefined,
  };
  const {mergedCustomerProjectInfos} = useActiveProject();
  const hrStyle: React.CSSProperties | undefined = isGeneratingPDF
    ? {height: "1px", margin: "27px 0", padding: "0 0"}
    : undefined;
  const labelStyle: React.CSSProperties | undefined = isGeneratingPDF
    ? {margin: "0 0", padding: "0 0"}
    : undefined;
  return (
    <Phase1ContentContainer>
      <NatLabel
        style={labelStyle}
        type={NatLabelType.P1}
        label={`During Phase 1, you can relax as our preconstruction team will handle creating your plan set and obtaining permits. We will also secure subcontractors and present you with a final quote so we can begin building!`}
      ></NatLabel>
      <HCPComponentHR style={hrStyle} />
      {PHASE_1_CONTENTS.map((entry) => {
        return (
          <ImageAndTextView
            {...entry}
            imageStyle={
              isGeneratingPDF
                ? {
                    height: `${PDF_IMAGE_HEIGHT_REM}rem`,
                    maxHeight: `${PDF_IMAGE_HEIGHT_REM}rem`,
                    minHeight: `${PDF_IMAGE_HEIGHT_REM}rem`,
                    width: `${PDF_IMAGE_HEIGHT_REM * IMAGE_ASPECT_RATIO}rem`,
                    flexGrow: 0.5,
                  }
                : undefined
            }
          />
        );
      })}
      <HCPComponentHR style={hrStyle} />
      <div
        style={{
          textAlign: "center",
          marginBottom: isGeneratingPDF ? "0.5rem" : "2rem",
          marginTop: isGeneratingPDF ? "0.5rem" : undefined,
        }}
      >
        <NatLabel
          style={labelStyle}
          label={"Let's get started"}
          type={NatLabelType.H2}
          weight={NatLabelWeight.MEDIUM}
        />
      </div>
      <NatFlex COLUMN HORIZONTAL_ALIGN={NatHorizontalAlign.CENTER} FULL_WIDTH>
        <NextStepsComponent step={1} style={nextStepsStyle} />
        {mergedCustomerProjectInfos?.settings?.pricing_mode
          ?.complex_pricing_enabled && (
          <NextStepsComponent step={3} style={nextStepsStyle} />
        )}
        <NextStepsComponent step={2} style={nextStepsStyle} />
      </NatFlex>
    </Phase1ContentContainer>
  );
};
