import {Address} from "@natomas-org/core";

function handleScriptLoad(autoCompleteRef, validateAddressDetails) {
  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    {
      types: ["address"],
      componentRestrictions: {country: "us"},
    }
  );

  autoCompleteRef.current.addListener("place_changed", () => {
    handlePlaceSelect(autoCompleteRef.current, validateAddressDetails);
  });
}

function handlePlaceSelect(autoComplete, validateAddressDetails) {
  const addressObject = autoComplete.getPlace();
  // Extract address details and format them as needed
  const details = Address.autoCompleteToPropertyAddressDetails(addressObject);
  validateAddressDetails(details);
}

const handleAddressSearch = async (
  preformatted,
  setResponse,
  skipEqualityCheck
) => {
  // Create a Geocoder instance
  const geocoder = new window.google.maps.Geocoder();
  // Perform a geocode request to get place details based on the search address
  try {
    geocoder.geocode({address: preformatted}, (results, status) => {
      // Filter the results to only include street_address types or if there is only one result
      const streetAddressResults =
        results?.length === 1
          ? results
          : results?.filter((result) =>
              result?.types?.includes?.("street_address")
            );
      const hasSingleResult = streetAddressResults?.length === 1;
      console.log(JSON.stringify(results, null, 2));
      console.log("status", status);
      console.log("hasSingleResult", hasSingleResult);
      // Check if the search result is one result
      if (hasSingleResult && status === "OK") {
        const placeDetails = streetAddressResults?.[0]; // Assuming we take the first result
        if (
          !skipEqualityCheck &&
          preformatted !== placeDetails?.["formatted_address"]
        ) {
          console.warn("Preformat does not match formatted response.");
        } else if (placeDetails?.["partial_match"] === true) {
          console.warn("Partial match found, skipping assignment");
        } else {
          setResponse(
            Address.autoCompleteToPropertyAddressDetails(placeDetails)
          );
        }
      } else {
        console.warn("Found many results or no results");
      }
    });
  } catch (error) {
    console.warn("handleAddressSearch", error);
    setResponse(null);
  }
};

export {handleAddressSearch, handleScriptLoad};
