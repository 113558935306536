import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {DynamicDataSheetGrid} from "react-datasheet-grid";
import {
  IEstimateProjectRange,
  IEstimateRangeItem,
} from "../../../api/Estimate/EstimateService";
import useActiveProject from "../../_shared/hooks/useProject/useActiveProject";
import {getAllLineItems} from "../_shared/helper";
import "../../portal/views/ProposalBudgetView/components/generic/NexusBudgetEditor/BudgetEditorStyles.css";
import {
  fullPriceTextFromMicros,
  getPriceMicrosFromText,
  TBudgetSection,
} from "@natomas-org/core";
import {NexusTableEditorButtonContainer} from "../../portal/views/ProposalBudgetView/components/generic/NexusBudgetEditor/styles";
import {NatButton} from "../../_shared/generics/button";
import {NatSize, StyleOption} from "../../_shared/generics/_shared";
import {NatFlex} from "../../_shared/generics/flex/NatFlex";
import {getColumns} from "./columns";
import {getBucketConfig} from "./buckets";
import {areAllElementsLockedOrSelected} from "./update";
import {VILLA_SLATE} from "../../_shared/colors";

interface EstimateEditorTableProps {
  range: IEstimateProjectRange;
  saveData: (data: IEstimateRangeItem[]) => void;
}

export interface BudgetEntryCellProps {
  code?: string;
  name: string;
  price_low: string;
  price_high: string;
  cost_low: string;
  cost_high: string;
  gp_low: string;
  gp_high: string;
  gm_low: string;
  gm_high: string;
  bucket: TBudgetSection;
  notes: string;
  selected: boolean;
  modifiedFields: string[];
  propsIndex: number;
  locked?: boolean;
}

function getEstimateRangeItemFromTableRow(
  cellData: BudgetEntryCellProps
): IEstimateRangeItem {
  return {
    name: cellData.name ?? "",
    price_low: cellData.price_low
      ? Math.round(getPriceMicrosFromText(cellData.price_low))
      : 0,
    price_high: cellData.price_high
      ? Math.round(getPriceMicrosFromText(cellData.price_high))
      : 0,
    cost_low: cellData.cost_low
      ? Math.round(getPriceMicrosFromText(cellData.cost_low))
      : 0,
    cost_high: cellData.cost_high
      ? Math.round(getPriceMicrosFromText(cellData.cost_high))
      : 0,
    bucket: cellData.bucket,
    notes: cellData.notes ?? null,
    locked: cellData.locked,
  };
}

const EstimateEditorTable = (props: EstimateEditorTableProps) => {
  const {range} = props;
  const {projectPriceMode} = useActiveProject();
  // const [tableConfiguration, setTableConfiguration] =
  //   useState<EstimateTableConfiguration>(AdminEstimateTables.ALL);

  const filteredEntries = getAllLineItems(range);
  const formatNumber = (value: number) => {
    const truncate = parseFloat(value.toFixed(2));
    const micros = truncate * 100;
    return fullPriceTextFromMicros(micros, {
      showPositiveSign: false,
      hideCommas: false,
      hideCurrency: false,
      roundToInteger: false,
    });
  };
  const data: BudgetEntryCellProps[] = [];
  const [tableData, setTableData] = useState<BudgetEntryCellProps[]>(data);

  const setInitialData = () => {
    filteredEntries?.forEach((entry, idx) => {
      if (!entry || !entry?.bucket) {
        return;
      }
      let priceDecimalLow = entry.price_low ?? null;
      if (priceDecimalLow) {
        priceDecimalLow = parseFloat((priceDecimalLow / 100).toFixed(2));
      }
      priceDecimalLow = priceDecimalLow ?? 0;
      let costDecimalLow = entry.cost_low ?? null;
      if (costDecimalLow) {
        costDecimalLow = parseFloat((costDecimalLow / 100).toFixed(2));
      }
      costDecimalLow = costDecimalLow ?? 0;
      let priceDecimalHigh = entry.price_high ?? null;
      if (priceDecimalHigh) {
        priceDecimalHigh = parseFloat((priceDecimalHigh / 100).toFixed(2));
      }
      costDecimalLow = costDecimalLow ?? 0;
      let costDecimalHigh = entry.cost_high ?? null;
      if (costDecimalHigh) {
        costDecimalHigh = parseFloat((costDecimalHigh / 100).toFixed(2));
      }
      costDecimalHigh = costDecimalHigh ?? 0;
      let notes: string = entry.notes ?? "";
      const gp_low = formatNumber(priceDecimalLow - costDecimalLow);
      const gp_high = formatNumber(priceDecimalHigh - costDecimalHigh);
      let gm_low: string = "-";
      if (costDecimalLow > 0) {
        gm_low = (
          ((priceDecimalLow - costDecimalLow) / costDecimalLow) *
          100
        ).toFixed(2);
        gm_low = `${gm_low}%`;
      }
      let gm_high: string = "-";
      if (costDecimalHigh > 0) {
        gm_high = (
          ((priceDecimalHigh - costDecimalHigh) / costDecimalHigh) *
          100
        ).toFixed(2);
        gm_high = `${gm_high}%`;
      }
      const entryFormatted: BudgetEntryCellProps = {
        bucket: entry.bucket as TBudgetSection,
        price_low: formatNumber(priceDecimalLow),
        price_high: formatNumber(priceDecimalHigh),
        cost_low: formatNumber(costDecimalLow),
        cost_high: formatNumber(costDecimalHigh),
        name: entry.name,
        notes: notes,
        gp_low: gp_low,
        gp_high: gp_high,
        gm_low: gm_low,
        gm_high: gm_high,
        // @ts-ignore
        code: entry?.code,
        selected: false,
        modifiedFields: [],
        propsIndex: idx,
        locked: entry.locked ?? false,
      };
      data.push(entryFormatted);
    });
    setTableData(data);
  };
  useEffect(() => {
    setInitialData();
  }, []);

  const rowsSelected = useMemo(() => {
    return tableData.filter((row) => row.selected);
  }, [tableData]);
  const columns = useMemo(() => {
    console.log("Getting columns");
    const bucketConfig = getBucketConfig(projectPriceMode);
    return getColumns(
      ["name", "price_low", "price_high", "cost_low", "cost_high", "notes"],
      {
        bucketConfig,
      }
    );
  }, [projectPriceMode]);

  return (
    <>
      <Container>
        <NexusTableEditorButtonContainer hidden={!tableData}>
          <NatFlex>
            <>
              <NatButton
                label={`Select All`}
                disabled={areAllElementsLockedOrSelected(tableData)}
                option={StyleOption.PRIMARY_ALT}
                size={NatSize.SMALL}
                clickEvent={() => {
                  setTableData(
                    tableData.map((row) => {
                      return {
                        ...row,
                        selected: !row.locked, // only select if not locked
                      };
                    })
                  );
                }}
              />
              <NatButton
                label={`Unselect Line ${
                  rowsSelected.length > 0 ? "Items" : "Item"
                }`}
                hidden={rowsSelected.length === 0}
                disabled={rowsSelected.length === 0}
                option={StyleOption.PRIMARY_ALT}
                size={NatSize.SMALL}
                clickEvent={() => {
                  setTableData(
                    tableData.map((row) => {
                      return {
                        ...row,
                        selected: false,
                      };
                    })
                  );
                }}
              />
              <NatButton
                label={`Duplicate ${rowsSelected.length} Line ${
                  rowsSelected.length > 1 ? "Items" : "Item"
                }`}
                hidden={rowsSelected.length === 0}
                disabled={rowsSelected.length === 0}
                option={StyleOption.WARNING_INVERSE}
                size={NatSize.SMALL}
                clickEvent={() => {
                  let rows: BudgetEntryCellProps[] = [];
                  let index = 0;
                  const getIndex = () => {
                    return index++;
                  };
                  tableData.forEach((row) => {
                    // Copy once
                    rows.push({
                      ...row,
                      selected: false,
                      propsIndex: getIndex(),
                    });
                    // Copy twice if selected
                    if (row.selected) {
                      rows.push({
                        ...row,
                        name: `${row.name} (Copy)`,
                        selected: false,
                        propsIndex: getIndex(),
                      });
                    }
                    setTableData(rows);
                  });
                }}
              />
              <NatButton
                label={`Delete ${rowsSelected.length} Line ${
                  rowsSelected.length > 1 ? "Items" : "Item"
                }`}
                hidden={rowsSelected.length === 0}
                disabled={rowsSelected.length === 0}
                option={StyleOption.DESTRUCTIVE}
                size={NatSize.SMALL}
                clickEvent={() => {
                  setTableData(tableData.filter((row) => !row.selected));
                }}
              />
            </>
          </NatFlex>
          <NatFlex>
            <NatButton
              label={"Discard Changes"}
              size={NatSize.SMALL}
              option={StyleOption.PRIMARY_ALT}
              clickEvent={() => {
                setInitialData();
              }}
            />
            <NatButton
              label={`Save Estimate`}
              spinnerEnabled={true}
              clickEvent={() => {
                const budgetEntryArray: IEstimateRangeItem[] = [];
                tableData.forEach((cellData) => {
                  const item = getEstimateRangeItemFromTableRow(cellData);
                  budgetEntryArray.push(item);
                });
                return props.saveData(budgetEntryArray);
              }}
              size={NatSize.SMALL}
              option={StyleOption.PRIMARY}
            />
          </NatFlex>
        </NexusTableEditorButtonContainer>
        <DynamicDataSheetGrid
          value={tableData}
          columns={columns}
          createRow={() => {
            return {
              name: "Additional Line Item",
              price_low: "$0",
              price_high: "$0",
              cost_low: "$0",
              cost_high: "$0",
              gp_low: "",
              gp_high: "",
              gm_low: "",
              gm_high: "",
              bucket: "Preconstruction",
              notes: " ",
              selected: false,
              modifiedFields: [],
              propsIndex: tableData.length,
            };
          }}
          onChange={(newValue, operations) => {
            for (const operation of operations) {
              // Handle new rows
              const newTableData = [...newValue];
              if (operation.type === "UPDATE") {
                newValue
                  .slice(operation.fromRowIndex, operation.toRowIndex)
                  .forEach((row, idx) => {
                    console.log("row", row);
                    const priceLow = getPriceMicrosFromText(row.price_low);
                    const priceHigh = getPriceMicrosFromText(row.price_high);
                    const costLow = getPriceMicrosFromText(row.cost_low);
                    const costHigh = getPriceMicrosFromText(row.cost_high);
                    const modifiedFields = [];
                    const originalRow =
                      filteredEntries[
                        newTableData[operation.fromRowIndex + idx].propsIndex
                      ];
                    if (originalRow) {
                      if (originalRow.price_low !== priceLow) {
                        modifiedFields.push("price_low");
                      }
                      if (originalRow.price_high !== priceHigh) {
                        modifiedFields.push("price_high");
                      }
                      if ((originalRow.cost_low ?? 0) !== costLow) {
                        modifiedFields.push("cost_low");
                      }
                      if ((originalRow.cost_high ?? 0) !== costHigh) {
                        modifiedFields.push("cost_high");
                      }
                      if (originalRow.notes !== row.notes) {
                        modifiedFields.push("notes");
                      }
                      if (originalRow.name !== row.name) {
                        modifiedFields.push("name");
                      }
                      if (originalRow.bucket !== row.bucket) {
                        modifiedFields.push("bucket");
                      }
                    }
                    newTableData[operation.fromRowIndex + idx].modifiedFields =
                      modifiedFields;
                  });
              }
            }
            setTableData(newValue);
          }}
          gutterColumn={false}
          height={700}
          rowHeight={45.25}
        />
      </Container>
      <div style={{display: "flex", color: VILLA_SLATE}}></div>
      <p>
        *Prices low and high must match if negative. (Discounts should never be
        a range).
      </p>
    </>
  );
};

const Container = styled.div`
  border: 1px solid #dddddd;
  border-radius: 14px;
  overflow: clip;
  margin: 32px 0;
  width: 100%;
`;

export default EstimateEditorTable;
