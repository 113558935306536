import {IGenericBudgetSummaryProps} from "../generic/GenericBudgetSummary";
import React, {useContext, useMemo, useState} from "react";
import {
  BUDGET_SECTION_UNIT,
  BudgetEntry,
  BudgetEntryProps,
  ENTRY_ID_UNIT_BASE_NO_TAX,
  ENTRY_ID_UNIT_BASE_WITH_TAX,
  entryIsEditable,
  getBreakdownTitleText,
  getUnitBudgetEntryInfoFromId,
  IPriceTextOptions,
  LineItemType,
  ProjectPriceMode,
  UnitBudget,
} from "@natomas-org/core";
import {isAdmin} from "../../../../../_shared/user/UserUtils";
import {GenericBudgetSummaryEditor} from "../generic/GenericBudgetEditor";
import {
  GRAY_BORDER,
  GRAY_BORDER_LIGHT,
  VILLA_APPLE_GREEN_LIGHT_2,
  WHITE,
} from "../../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../SSDDashboardView/styles/globals";
import {
  MainContainer,
  RowContainer,
  TableContainer,
} from "../../styles/SiteWorkTableStyles";
import {TableRow} from "../generic/TableRow";
import {TableEditorButtonContainer} from "../generic/styles";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {BudgetAdminContext} from "../../ProposalBudgetSummaryView";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";
import {saveBudgetChanges} from "../../../../../../database/firebase/api/project/budget";
import {GenericLoadingComponent} from "../generic/GenericLoadingComponent";

export const UnitTable = (
  props: IGenericBudgetSummaryProps & {
    unit: UnitBudget;
    projectPriceMode: ProjectPriceMode;
    includeHeader: boolean;
    isCartEnabled: boolean;
    refresh: () => Promise<string>;
  }
) => {
  const {unit, isAdmin: isAdminProp, projectPriceMode} = props;
  const currentUser = useSelector((state: IStore) => state.global.user);
  const {id, isCartEnabled} = useActiveProject();
  const {isNatMobile} = usePage();
  const [showUpgrades, setShowUpgrades] = useState(true);
  const [showAdditionalLineItems, setShowAdditionalLineItems] = useState(true);
  const [editing, setEditing] = useState(false);
  const adminContext = useContext(BudgetAdminContext);
  const admin = adminContext ?? isAdminProp ?? isAdmin(currentUser);
  const budgetEntriesForDisplay = useMemo(() => {
    return unit.getTableRows(
      showUpgrades,
      showAdditionalLineItems,
      isCartEnabled
    );
  }, [isCartEnabled, showAdditionalLineItems, showUpgrades, unit]);
  const [isSaving, setIsSaving] = useState(false);
  const budgetEntriesForEditor = useMemo(() => {
    return unit.getTableRows(showUpgrades, showAdditionalLineItems, false);
  }, [showAdditionalLineItems, showUpgrades, unit]);

  if (isAdmin(currentUser) && editing) {
    return (
      <React.Fragment>
        <GenericBudgetSummaryEditor
          {...props}
          columns={props.columns}
          budgetEntries={budgetEntriesForEditor}
          displayType={BUDGET_SECTION_UNIT}
          saveModifications={async (entries, baseBudget) => {
            setEditing(false);
            setIsSaving(true);
            const filteredEntries: {[key: string]: BudgetEntryProps} = {};
            const entryKeys = Object.keys(entries);
            for (let i = 0; i < entryKeys.length; i++) {
              const entryProps = entries[entryKeys[i]];
              if (!entryProps) {
                continue;
              }
              const entry = new BudgetEntry(entryProps);
              if (entryIsEditable(entry)) {
                filteredEntries[entryKeys[i]] = entryProps;
              }
            }
            return saveBudgetChanges(
              id,
              filteredEntries,
              baseBudget,
              () => {
                props.refreshBudget?.();
              },
              props.clearBudget
            );
          }}
        />
        <TableEditorButtonContainer>
          <NatButton
            clickEvent={() => {
              setEditing(false);
            }}
            label={"Discard Edits"}
            type={"button"}
            option={StyleOption.SECONDARY}
          />
        </TableEditorButtonContainer>
      </React.Fragment>
    );
  }

  const header = props.includeHeader ? (
    <RowContainer key={"row-title"} isNatMobile={isNatMobile}>
      <TableRow
        data={["Item", "Price", "Description"]}
        style={{
          backgroundColor: GRAY_BORDER,
          fontFamily: FONT_FAMILY_BOLD,
        }}
      />
    </RowContainer>
  ) : null;
  const rows = budgetEntriesForDisplay.map(
    (row: BudgetEntry, index: number) => {
      if (!row || !row.price?.price || row?.price?.price === 0) {
        return null;
      }
      let priceDisplayOptions: IPriceTextOptions | undefined = undefined;
      const style: React.CSSProperties = {
        backgroundColor: row.isTotal()
          ? VILLA_APPLE_GREEN_LIGHT_2
          : row.isHeader()
          ? GRAY_BORDER_LIGHT
          : row.isSubtotal()
          ? `rgb(234, 234, 234)`
          : WHITE,
        fontFamily: row.isBold() ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR,
        cursor: [
          LineItemType.UPGRADES_TOTAL,
          LineItemType.UNIT_ADDITIONAL_LINE_ITEMS_TOTAL,
        ].includes(row.id as LineItemType)
          ? "pointer"
          : "default",
      };
      let onClick: (() => void) | undefined;
      if (row.id === LineItemType.UPGRADES_TOTAL) {
        onClick = () => {
          setShowUpgrades(!showUpgrades);
        };
      } else if (row.id === LineItemType.UNIT_ADDITIONAL_LINE_ITEMS_TOTAL) {
        onClick = () => {
          setShowAdditionalLineItems(!showAdditionalLineItems);
        };
      }
      const {modifierId} = getUnitBudgetEntryInfoFromId(row?.id ?? "");
      if (
        modifierId &&
        !row.isHeader() &&
        !row.isSubtotal() &&
        !row.isTotal() &&
        !row.isBold() &&
        ![
          ENTRY_ID_UNIT_BASE_WITH_TAX,
          ENTRY_ID_UNIT_BASE_NO_TAX,
          LineItemType.OP_HOME_DESIGN,
        ].includes(modifierId)
      ) {
        priceDisplayOptions = {
          showPositiveSign: true,
          roundToInteger: true,
          hideCommas: false,
          hideCurrency: false,
        };
      }
      return (
        <RowContainer
          key={"row-" + index}
          isNatMobile={isNatMobile}
          onClick={onClick}
          style={style}
        >
          <TableRow
            data={row}
            style={style}
            displayProps={{
              displayDescription: true,
              priceDisplayOptions: priceDisplayOptions,
            }}
          />
        </RowContainer>
      );
    }
  );
  let table = (
    <TableContainer>
      {header}
      {rows}
    </TableContainer>
  );
  if (isSaving) {
    table = <GenericLoadingComponent />;
  }

  return (
    <MainContainer>
      {table}
      {isAdmin(currentUser) && admin && props.isFullView && !props.viewOnly ? (
        <TableEditorButtonContainer>
          <NatButton
            clickEvent={() => {
              setEditing(true);
            }}
            label={`Edit ${getBreakdownTitleText(
              BUDGET_SECTION_UNIT,
              projectPriceMode
            )} Line Items`}
            type={"button"}
            option={StyleOption.SECONDARY}
          />
        </TableEditorButtonContainer>
      ) : null}
    </MainContainer>
  );
};
