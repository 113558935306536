import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../../SSDDashboardView/styles/globals";
import {VILLA_APPLE_GREEN_20, WHITE} from "../../../../../../../_shared/colors";

export const ProposalSummarySectionDiv = styled.div<{
  highlighted?: boolean;
  generatingPDF?: boolean;
}>`
  border: 1px solid #000;
  border-radius: 1rem;
  background-color: ${(props) =>
    props.highlighted ? VILLA_APPLE_GREEN_20 : WHITE};
  padding: ${(props) => (props.generatingPDF ? undefined : "0.5rem 2rem")};
  margin-top: ${(props) => (props.generatingPDF ? undefined : "0.5rem")};
  print-color-adjust: exact;
`;

export const SpanBold = styled.span`
  font-style: italic;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const StyledBudgetSummaryHR = styled.hr`
  width: 100%;
  @media print {
    //display: none;
    height: 10px;
  }
`;
