import styled from "styled-components";
import {WHITE} from "../../../../_shared/colors";

export const ReferralRockContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 8500000;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ReferralRockContent = styled.iframe<{fullscreen: boolean}>`
  position: relative;
  background-color: ${WHITE};
  height: ${(props) => (props.fullscreen ? "100%" : "383px")};
  width: ${(props) => (props.fullscreen ? "100%" : "500px")};
  pointer-events: all;
  border-radius: ${(props) => (props.fullscreen ? "0" : "2rem")};
  border: 0;
  overflow: hidden;
  padding: ${(props) =>
    props.fullscreen ? "50px 10px 0px 10px" : "20px 20px 0px 20px"};
`;

export const CloseButton = styled.div`
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;
