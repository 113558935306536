import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useMemo} from "react";
import {derivePublicAddress} from "./helper";
import {useCurrentUser} from "../useCurrentUser";
import {getCacheParameters} from "../../slices/AddressSlice/helper";
import {useIsPublicApp} from "../useIsPublicApp";

/* --------------------------------------

useAddress is an important hook used throughout the app.

Returns the following:

projectAddress: IAddressDetails
    If a project address is in state, regardless of if there is
     a pid/cid in the URL, projectAddress returns its address.
propertySearch: IAddressDetails
   propertySearch is the last property address that has been searched for
     in publicAppSearchBar.
publicAddress: IAddressDetails
   publicAddress is our way of returning an address. There are different use cases
    for publicAddress: (1) nobody logged in or (2) customer logged in.

    For case 1, we return ANY address. In order, it returns
    whichever source can contains a property address. If none have a property address,
    it returns whichever source has an address. The priority ranking is:
       - URL
       - propertySearch
       - areaSearch
       - cache
       - ip

    For case 2, we omit cache. Cache is no longer useful after account creation
    for public apps.

catalogAddress: IAddressDetails
   catalogAddress returns either publicAddress or projectAddress, depending on
    what state the app is. If there's a pid/cid in the URL and a projectAddress
    loaded, the projectAddress is returned. Otherwise, it returns publicAddress.
    CatalogAddress is the only address ever used by the catalog.
areaSearch: IAreaDetails
  areaSearch returns the last area that has been entered into the publicAppSearchBar
    and is guaranteed to be an area, not a property address.
 ----------------------------------------- */

export const useAddress = () => {
  const {loggedInUser} = useCurrentUser();
  const isPublicApp = useIsPublicApp();
  const {
    ip,
    url,
    project: projectAddress,
    property_search: propertySearch,
    areaSearch,
  } = useSelector((state: IStore) => state.address);

  const publicAddress = useMemo(() => {
    // Determine the best source for the public apps
    return derivePublicAddress(
      ip,
      url,
      !loggedInUser ? getCacheParameters() : null, // Don't use cache if user is logged in
      undefined, // Project address should not taken into account for public address
      propertySearch,
      areaSearch,
      {
        cacheProjectAddress: false,
        enforcePropertyAddress: false,
      }
    );
  }, [ip, url, loggedInUser, propertySearch, areaSearch]);

  const catalogAddress = useMemo(() => {
    if (isPublicApp) {
      return publicAddress;
    } else {
      return projectAddress;
    }
  }, [isPublicApp, projectAddress, publicAddress]);

  return {
    catalogAddress,
    publicAddress,
    projectAddress,
    propertySearch,
    areaSearch,
    url,
  };
};
