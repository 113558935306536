import styled from "styled-components";

export interface ITextOverflow {
  maxLines?: number;
  ellipsis?: boolean;
}

export const NatLabelWrapper = styled.div<{
  textOverflow?: ITextOverflow;
  hoverColor?: string;
  color?: string;
}>`
  margin: 1rem 0;

  ${(props) =>
    props.textOverflow &&
    `
  display: -webkit-box;
  -webkit-line-clamp: ${props?.textOverflow?.maxLines ?? 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`}
  color: ${(props) => props.color};

  &:hover {
    ${(props) => props.hoverColor && `color: ${props.hoverColor};`}
  }
`;
