import React from "react";
import {
  InfoDescription,
  InfoItemContainer,
  InfoLabel,
  InfoValue,
} from "./styles";

export const InfoItem = (props: {
  label: string;
  value: string;
  description?: string;
  customColor?: string;
  clickEvent?: () => void;
}) => {
  const {label, value, description, customColor, clickEvent} = props;
  return (
    <InfoItemContainer>
      <InfoLabel>{label}</InfoLabel>
      <InfoValue
        customColor={customColor}
        hasClickEvent={!!clickEvent}
        onClick={clickEvent}
      >
        {value}
      </InfoValue>
      {description && <InfoDescription>{description}</InfoDescription>}
    </InfoItemContainer>
  );
};
