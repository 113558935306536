import {IMatrixTableCell} from "./interfaces";
import {getPriceValue} from "../../../utilities";
import {isBlankString, priceFromText} from "@natomas-org/core";
import {
  AdminProductCatalogMatrixModes,
  AdminProductCatalogMatrixSubModes,
} from "../../../../../../../../_shared/slices/types/Store";

export const sanitizeMatrixCellProps = (
  cellProps: any,
  matrixEdits: any
): IMatrixTableCell => {
  const isEdited = !!matrixEdits?.[cellProps?.cell?.key] ?? null;
  return {
    attributesRenderer: cellProps.attributesRenderer,
    cell: cellProps.cell,
    children: cellProps.children,
    col: cellProps.col,
    editing: cellProps.editing,
    onContextMenu: cellProps.onContextMenu,
    onDoubleClick: cellProps.onDoubleClick,
    onMouseDown: cellProps.onMouseDown,
    onMouseOver: cellProps.onMouseOver,
    row: cellProps.row,
    selected: cellProps.selected,
    edited: isEdited,
  };
};

export const getValue = (cell: any) => {
  const value = cell.value;
  if (cell?.valueType === "currency") {
    const priceValue = getPriceValue(value);
    if (priceValue !== 0 && cell.readOnly !== true) {
      return priceFromText(value, "min");
    }
  }

  return value;
};

export const getChanges = (
  mode: AdminProductCatalogMatrixModes,
  subMode: AdminProductCatalogMatrixSubModes,
  changes: any[],
  edits: any
) => {
  const newEdits: any = {};
  changes.forEach(
    (change: {cell: any; row: number; col: number; value: string | null}) => {
      if (change.cell.value !== change.value) {
        const editDictionary =
          newEdits[change.cell.key] != null ? newEdits[change.cell.key] : {};
        if (isBlankString(change.value)) {
          if (mode === AdminProductCatalogMatrixModes.PRICE_SET) {
            const dependencies =
              editDictionary[mode] ?? change?.cell?.dependencies?.value;
            const persistentDependencies = dependencies?.filter(
              (d: any) => d?.condition !== "default"
            );
            const originalDefault = dependencies?.find(
              (d: any) => d?.condition === "default"
            );
            let newDefault;
            if (subMode === AdminProductCatalogMatrixSubModes.PRICE) {
              newDefault = {
                priceMicros: null,
                costMicros: originalDefault?.costMicros,
                requiredModifiers: [],
                condition: "default",
                type: "baseline",
              };
            } else {
              newDefault = {
                priceMicros: originalDefault?.priceMicros,
                costMicros: null,
                requiredModifiers: [],
                condition: "default",
                type: "baseline",
              };
            }

            editDictionary[mode] = [...persistentDependencies, newDefault];
          } else if (mode === AdminProductCatalogMatrixModes.PRODUCT_INFO) {
            editDictionary[mode] = null;
          } else {
            editDictionary[mode] = "";
          }
        } else {
          if (mode === AdminProductCatalogMatrixModes.PRICE_SET) {
            const dependencies =
              editDictionary[mode] ?? change?.cell?.dependencies?.value;
            const persistentDependencies = dependencies?.filter(
              (d: any) => d?.condition !== "default"
            );
            const originalDefault = dependencies?.find(
              (d: any) => d?.condition === "default"
            );
            let newDefault;
            if (subMode === AdminProductCatalogMatrixSubModes.PRICE) {
              newDefault = {
                priceMicros: getPriceValue(change.value) * 100,
                costMicros: originalDefault?.costMicros,
                requiredModifiers: [],
                condition: "default",
                type: "baseline",
              };
            } else {
              newDefault = {
                priceMicros: originalDefault?.priceMicros,
                costMicros: getPriceValue(change.value) * 100,
                requiredModifiers: [],
                condition: "default",
                type: "baseline",
              };
            }
            editDictionary[mode] = [...persistentDependencies, newDefault];
          } else if (mode === AdminProductCatalogMatrixModes.PRODUCT_INFO) {
            editDictionary[mode] = getPriceValue(change.value) * 100;
          } else {
            editDictionary[mode] = change.value;
          }
        }
        newEdits[change.cell.key] = editDictionary;
      }
    }
  );
  const copyOfEdits = Object.assign({}, edits);
  return Object.assign(copyOfEdits, newEdits);
};

export const getProductRow = (products: any[], withDefault?: boolean) => {
  const row: any[] = [{value: "", readOnly: true}];
  if (withDefault) {
    row.push({
      value: "Default",
      readOnly: true,
      key: "Default Key",
    });
  }
  products?.forEach((p: any) => {
    row.push({
      value: p,
      readOnly: true,
    });
  });
  return row;
};
