import React from "react";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {Container, Title} from "../../styles";

export const DeveloperViewContainer = (props: {
  title: string;
  children: any;
}) => {
  const {title, children} = props;
  return (
    <PageContainer>
      <Title>{title}</Title>
      <Container>{children}</Container>
    </PageContainer>
  );
};
