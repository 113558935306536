import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {
  BottomMenuItem,
  BottomMenuItemIcon,
  BottomMenuItemText,
} from "../_shared/styles/AdminStyles";
import {EditProductOptionKeys} from "../_shared/components/ProductOptions/ProductOptionKeyEditor";
import {EditProductOptionValues} from "../_shared/components/ProductOptions/ProductOptionValueEditor";
import {PRODUCT_LINE_TABS} from "../_shared/constants/AdminPage";
import {AdminView} from "../_shared/components/AdminView";
import {toPage} from "../../portal/_shared/navigation/PageNavigation";
import {clearEditing} from "../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {
  resetFilters,
  setProductGroupVersionId,
} from "../../_shared/slices/CatalogSlice/CatalogSessionSlice";
import {HelpModal} from "./components/CustomSideBar/HelpModal/HelpModal";
import {AdminHeaderTitle} from "../_shared/components/AdminHeaderTitle";
import {useNavigation} from "../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../_shared/hooks/useNavigation/paths";
import {AdminProCatHeaderContainer} from "./styles";
import {VersionSideBarManagerContainer} from "./components/VersionSideBarManagerContainer";
import {useAdminProductCatalog} from "../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {useCatalogSession} from "../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useCatalogSessionDispatcher} from "../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";
import {NatInfoIcon} from "../../_shared/icon/icons";

export const AdminStudioManagerView = () => {
  const [showHelp, setShowHelp] = useState(false);
  const {factoryLine} = useAdminProductCatalog();
  const {selectedProductGroupVersionId} = useCatalogSession();
  const {selectFactoryById} = useCatalogSessionDispatcher();
  const {details} = factoryLine ?? {};
  const {to} = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedProductGroupVersionId && details?.versionId) {
      dispatch(setProductGroupVersionId(details?.versionId));
    }
  }, [details?.versionId, dispatch, selectedProductGroupVersionId]);

  const returnToProductCatalog = useCallback(() => {
    to(NavigationPaths.ADMIN_PRODUCTS);
    toPage(null);
    dispatch(clearEditing());
    selectFactoryById(null);
    dispatch(resetFilters());
  }, [dispatch, selectFactoryById, to]);

  return (
    <AdminView
      title={"Factory Studio Manager"}
      tabs={PRODUCT_LINE_TABS}
      headerContent={
        <AdminProCatHeaderContainer>
          <AdminHeaderTitle
            title={details?.internal_title ?? "Product Lines"}
            clickEvent={returnToProductCatalog}
          />
        </AdminProCatHeaderContainer>
      }
      preTabContent={<VersionSideBarManagerContainer />}
      footerContent={
        <>
          <BottomMenuItem
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <BottomMenuItemText>Icon Legend</BottomMenuItemText>
            <BottomMenuItemIcon>
              <NatInfoIcon color={"white"} size={12} />
            </BottomMenuItemIcon>
          </BottomMenuItem>
        </>
      }
      modals={
        <>
          <EditProductOptionKeys />
          <EditProductOptionValues />
          {showHelp && (
            <HelpModal close={() => setShowHelp(!showHelp)} show={showHelp} />
          )}
        </>
      }
    />
  );
};
