// Feasibility information
import {useCurrentCustomer} from "./useCurrentCustomer";
import {
  CustomerProjectInfo,
  ICustomerInfo,
  INITIAL_DEPOSIT_PAYMENT_EVENT_ID,
  PHASE_1_PAYMENT_EVENT_ID,
} from "@natomas-org/core";
import {
  getEventObject,
  getEventObjectFromUser,
  isInitialDepositPaid,
} from "../../portal/_shared/utilities";
import useActiveProject from "./useProject/useActiveProject";
import useActiveDesign from "./useDesign/useActiveDesign";
import {usePropertyData} from "./usePropertyData/usePropertyData";

export enum CUSTOMER_STATUS {
  COMPLETED = "completed",
  IN_PROGRESS = "in-progress",
  DISABLED = "disabled",
  SKIPPED = "skipped",
}

export interface ICustomerStatus {
  contactInfoStatus: CUSTOMER_STATUS;
  productSelectedStatus: CUSTOMER_STATUS;
  instantEstimateStatus: CUSTOMER_STATUS;
  unlockQuoteStatus: CUSTOMER_STATUS;
  mappingStatus: CUSTOMER_STATUS;
  depositPaidStatus: CUSTOMER_STATUS;
  designProductStatus: CUSTOMER_STATUS;
}

export enum FEASIBILITY_STATUS {
  FEASIBLE = "feasible",
  INFEASIBLE = "infeasible",
  UNKNOWN = "unknown",
  SFINFEASIBLE = "sf_infeasible",
}

const isCustomerPostDeposit = (
  userInfo: ICustomerInfo | null,
  projectSummary: CustomerProjectInfo | null
) => {
  if (!userInfo) {
    return false;
  }
  const initialDepositFee =
    userInfo != null &&
    getEventObjectFromUser(userInfo, INITIAL_DEPOSIT_PAYMENT_EVENT_ID) != null;

  const hasPaidFee = !!userInfo?.initial_fee_paid || initialDepositFee;
  const opportunityExists = !!projectSummary?.opportunity_id;

  return hasPaidFee || opportunityExists;
};

const getProjectCompletionStatuses = (
  designReady: boolean,
  sitePlanUploaded: boolean,
  proposalReady: any,
  projectSummary: any
) => {
  const designStudio = !designReady
    ? CUSTOMER_STATUS.IN_PROGRESS
    : CUSTOMER_STATUS.COMPLETED;
  const sitePlan =
    designReady && sitePlanUploaded
      ? CUSTOMER_STATUS.COMPLETED
      : designReady
      ? CUSTOMER_STATUS.IN_PROGRESS
      : CUSTOMER_STATUS.DISABLED;
  const phase1PaymentEvent = getEventObject(
    projectSummary,
    PHASE_1_PAYMENT_EVENT_ID
  );

  let proposal;
  if (phase1PaymentEvent != null && proposalReady) {
    proposal = CUSTOMER_STATUS.COMPLETED;
  } else if (proposalReady) {
    proposal = CUSTOMER_STATUS.IN_PROGRESS;
  } else {
    proposal = CUSTOMER_STATUS.DISABLED;
  }

  return {
    designStudio,
    sitePlan,
    proposal,
  };
};

export const useCustomerStatus = (): {
  isCustomerLoaded: boolean;
  isCustomerPreDeposit: boolean;
  customerStatus: ICustomerStatus;
  feasibility_status: FEASIBILITY_STATUS;
  status: any;
  projectCompletion: any;
} => {
  const {customer} = useCurrentCustomer();
  const {mergedCustomerProjectInfos, address, site} = useActiveProject();
  const {configuration, snapshot} = useActiveDesign();
  const {projectPropertyData} = usePropertyData();

  let contactInfoStatus =
    address === undefined
      ? CUSTOMER_STATUS.IN_PROGRESS
      : CUSTOMER_STATUS.COMPLETED;
  let productSelectedStatus = configuration?.product
    ? CUSTOMER_STATUS.COMPLETED
    : CUSTOMER_STATUS.IN_PROGRESS;
  let designCompleteStatus = snapshot
    ? CUSTOMER_STATUS.COMPLETED
    : productSelectedStatus !== CUSTOMER_STATUS.COMPLETED
    ? CUSTOMER_STATUS.DISABLED
    : site?.surveyAnswers
    ? CUSTOMER_STATUS.SKIPPED
    : CUSTOMER_STATUS.IN_PROGRESS;
  let instantEstimateStatus = site?.surveyAnswers
    ? CUSTOMER_STATUS.COMPLETED
    : designCompleteStatus === CUSTOMER_STATUS.COMPLETED ||
      productSelectedStatus === CUSTOMER_STATUS.COMPLETED
    ? CUSTOMER_STATUS.IN_PROGRESS
    : CUSTOMER_STATUS.DISABLED;
  let unlockQuoteStatus =
    instantEstimateStatus === CUSTOMER_STATUS.COMPLETED
      ? CUSTOMER_STATUS.COMPLETED
      : CUSTOMER_STATUS.DISABLED;
  let mappingStatus =
    productSelectedStatus !== CUSTOMER_STATUS.COMPLETED
      ? CUSTOMER_STATUS.DISABLED
      : CUSTOMER_STATUS.IN_PROGRESS;
  let depositPaidStatus =
    productSelectedStatus === CUSTOMER_STATUS.COMPLETED &&
    isInitialDepositPaid(customer)
      ? CUSTOMER_STATUS.COMPLETED
      : productSelectedStatus === CUSTOMER_STATUS.COMPLETED &&
        unlockQuoteStatus === CUSTOMER_STATUS.COMPLETED
      ? CUSTOMER_STATUS.IN_PROGRESS
      : CUSTOMER_STATUS.DISABLED;

  const isPreDeposit = !isCustomerPostDeposit(
    customer,
    mergedCustomerProjectInfos ?? null
  );
  if (!isPreDeposit) {
    contactInfoStatus = CUSTOMER_STATUS.COMPLETED;
    productSelectedStatus = CUSTOMER_STATUS.COMPLETED;
    mappingStatus = CUSTOMER_STATUS.COMPLETED;
    depositPaidStatus = CUSTOMER_STATUS.COMPLETED;
    instantEstimateStatus = CUSTOMER_STATUS.COMPLETED;
    unlockQuoteStatus = CUSTOMER_STATUS.COMPLETED;
  }

  const feasibilityStatus = !projectPropertyData?.serviceableZones
    ? FEASIBILITY_STATUS.UNKNOWN
    : (projectPropertyData?.serviceableZones &&
        projectPropertyData?.serviceableZones.length === 0) ||
      (customer?.status_details &&
        customer?.status_details.length > 0 &&
        customer?.status === "Dead") ||
      customer?.status === "Dead"
    ? FEASIBILITY_STATUS.INFEASIBLE
    : FEASIBILITY_STATUS.FEASIBLE;

  const designReady = snapshot != null;
  let sitePlanImages: string[] = [];
  if (site?.sitePlan?.images != null) {
    sitePlanImages = [...site?.sitePlan?.images];
  } else if (site?.sitePlan?.imageId != null) {
    sitePlanImages = [site?.sitePlan?.imageId];
  }

  const sitePlanReady = sitePlanImages.length > 0;
  const proposalReady =
    site?.totalSiteWorkCostInMicros &&
    site?.visible &&
    designReady &&
    sitePlanReady;

  return {
    isCustomerLoaded: !!customer,
    isCustomerPreDeposit: isPreDeposit,
    customerStatus: {
      contactInfoStatus,
      productSelectedStatus,
      instantEstimateStatus,
      unlockQuoteStatus,
      designProductStatus: designCompleteStatus,
      mappingStatus,
      depositPaidStatus,
    },
    feasibility_status: feasibilityStatus,
    status: {
      designReady,
      sitePlanReady,
      proposalReady,
    },
    projectCompletion: getProjectCompletionStatuses(
      designReady,
      sitePlanReady,
      proposalReady,
      mergedCustomerProjectInfos
    ),
  };
};
