import {ICartItem} from "@natomas-org/core";
import {saveProjectCart} from "../../../../../database/firebase/project/cart";
import {useCallback} from "react";
import useCartLibrary from "../useCartLibrary";
import {
  removeCartItemFromCartLibrary,
  removeCartItemsFromCartLibrary,
  saveCartItemsToCartLibrary,
  saveCartItemToCartLibrary,
} from "../../../../../database/firebase/user/cart_library";

const useCartLibraryController = () => {
  const {customerCartItems, customerInfo, activeProjectCartItems, projectId} =
    useCartLibrary();

  const removeProjectCartItem: (id: string) => void = useCallback(
    (id: string) => {
      const cartItem: ICartItem | undefined = activeProjectCartItems.find(
        (item: ICartItem) => item.id === id
      );

      if (!cartItem) {
        return;
      }

      const newCartItems: ICartItem[] = activeProjectCartItems.filter(
        (item: ICartItem) => item.id !== id
      );
      const saveProjectPromise = saveProjectCart(projectId, newCartItems);
      const saveUser = saveCartItemToCartLibrary(customerInfo, cartItem);
      return Promise.resolve([saveProjectPromise, saveUser]);
    },
    [customerInfo, activeProjectCartItems, projectId]
  );

  const removeProjectCartItems: (ids: string[]) => void = useCallback(
    (ids: string[]) => {
      const removedItems: ICartItem[] = activeProjectCartItems.filter(
        (item: ICartItem) => ids.includes(item.id)
      );
      const newCartItems: ICartItem[] = activeProjectCartItems.filter(
        (item: ICartItem) => !ids.includes(item.id)
      );
      console.log(removedItems, newCartItems);
      if (
        !removedItems ||
        removedItems?.length === 0 ||
        removedItems.length !== ids.length
      ) {
        return;
      }

      const saveProjectPromise = saveProjectCart(projectId, newCartItems);
      const saveUser = saveCartItemsToCartLibrary(customerInfo, removedItems);
      return Promise.resolve([saveProjectPromise, saveUser]);
    },
    [customerInfo, activeProjectCartItems, projectId]
  );

  const addProjectCartItemFromCartLibrary: (id: string) => void = useCallback(
    (id: string) => {
      const cartItem: ICartItem | undefined = customerCartItems.find(
        (item: ICartItem) => item.id === id
      );

      if (!cartItem) {
        return;
      }

      const newCartItems: ICartItem[] = [...activeProjectCartItems, cartItem];
      const saveProjectPromise = saveProjectCart(projectId, newCartItems);
      const saveUser = removeCartItemFromCartLibrary(customerInfo, cartItem.id);
      return Promise.resolve([saveProjectPromise, saveUser]);
    },
    [customerCartItems, customerInfo, activeProjectCartItems, projectId]
  );

  const addProjectCartItemsFromCartLibrary: (ids: string[]) => void =
    useCallback(
      (ids: string[]) => {
        const cartItems: ICartItem[] = customerCartItems.filter(
          (item: ICartItem) => ids.includes(item.id)
        );

        if (
          !cartItems ||
          cartItems?.length === 0 ||
          cartItems.length !== ids.length
        ) {
          return;
        }

        const newCartItems: ICartItem[] = [
          ...activeProjectCartItems,
          ...cartItems,
        ];
        const saveProjectPromise = saveProjectCart(projectId, newCartItems);
        const saveUser = removeCartItemsFromCartLibrary(
          customerInfo,
          cartItems?.map((item: ICartItem) => item.id)
        );
        return Promise.resolve([saveProjectPromise, saveUser]);
      },
      [customerCartItems, customerInfo, activeProjectCartItems, projectId]
    );

  return {
    removeProjectCartItem,
    removeProjectCartItems,
    addProjectCartItemFromCartLibrary,
    addProjectCartItemsFromCartLibrary,
  };
};

export default useCartLibraryController;
