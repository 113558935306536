import React from "react";
import styled from "styled-components";
import NatLabel, {NatLabelType} from "../../../_shared/generics/label/NatLabel";
import {getEnhancedTeamMemberFromEmail} from "@natomas-org/core";
import {usePage} from "../../../_shared/hooks/usePage";

const TEAM_VIEW_CONTACTS_FIRST_ROW: string[] = [
  "abecerril@villahomes.com",
  "cmagana@villahomes.com",
  "lnegro@villahomes.com",
  "twells@villahomes.com",
];

const TEAM_VIEW_CONTACTS_SECOND_ROW: string[] = [
  "nchant@villahomes.com",
  "rveyck@villahomes.com",
  "ngates@villahomes.com",
];

const TeamViewContentContainer = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-height: (FULL_SCREEN_HEIGHT_REM - 2) rem;
  page-break-after: avoid;
  break-after: avoid;
`;

const TeamMemberListWrapper = styled.div`
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  align-self: center;
`;
const TeamMemberCard = styled.div`
  text-align: center;
`;

const TeamMemberImage = styled.img`
  width: 125px;
  height: 125px;
  border-radius: 1rem;
  overflow: hidden;
`;

interface IProposalTeamViewProps {}

export const ProposalTeamView = (props: IProposalTeamViewProps) => {
  const {isNatMobile} = usePage();
  let content: null | JSX.Element;
  if (isNatMobile) {
    content = (
      <TeamViewRow
        emails={[
          ...TEAM_VIEW_CONTACTS_FIRST_ROW,
          ...TEAM_VIEW_CONTACTS_SECOND_ROW,
        ]}
      />
    );
  } else {
    content = (
      <>
        <TeamViewRow emails={TEAM_VIEW_CONTACTS_FIRST_ROW} />
        <TeamViewRow emails={TEAM_VIEW_CONTACTS_SECOND_ROW} />
      </>
    );
  }

  return (
    <TeamViewContentContainer>
      <NatLabel
        type={NatLabelType.P1}
        style={{maxWidth: "1000px", marginBottom: "4rem"}}
        label={`Building in your backyard has never been easier with our team of preconstruction experts. We’ll prepare a detailed package of drawings, documents, and permits for local jurisdiction review. All you need to do is relax and daydream about decor.`}
      ></NatLabel>
      {content}
    </TeamViewContentContainer>
  );
};

const TeamViewRow = (props: {emails: string[]}) => {
  const {emails} = props;
  return (
    <TeamViewContentContainer>
      <TeamMemberListWrapper>
        {emails.map((email) => {
          const teamMember = getEnhancedTeamMemberFromEmail(email);
          if (!teamMember) {
            return null;
          }
          return (
            <TeamMemberCard>
              <TeamMemberImage src={teamMember.photo_url_high_res ?? ""} />

              <NatLabel
                type={NatLabelType.P3}
                label={`${teamMember.first_name} ${teamMember.last_name}`}
              ></NatLabel>
            </TeamMemberCard>
          );
        })}
      </TeamMemberListWrapper>
    </TeamViewContentContainer>
  );
};
