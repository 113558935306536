import React, {useMemo} from "react";
import {NatDropdown} from "../../popup/dropdown/NatDropdown";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../popup/dropdown/constants";
import {NatSizeType} from "../../_shared";
import {IStore} from "../../../slices/types/Store";
import {useDispatch, useSelector} from "react-redux";
import {ProjectPriceMode} from "@natomas-org/core";
import {setAdminProductCatalogDemoPricingMode} from "../../../slices/AdminSlice";

export const PricingDemoSelectionDropdown = () => {
  const demoPricingMode = useSelector(
    (state: IStore) => state.administration.productCatalog.demoPricingMode
  );
  const dispatch = useDispatch();

  const label = useMemo(() => {
    if (!demoPricingMode) {
      return "Loading...";
    }
    switch (demoPricingMode) {
      case ProjectPriceMode.DTC:
        return "Demo: DTC (Price)";
      case ProjectPriceMode.CostPlus:
        return "Demo: CostPlus (Cost)";
      default:
        return "Demo: Unknown";
    }
  }, [demoPricingMode]);

  const dropdownViews = useMemo(() => {
    return [
      {
        label: "DTC (Price)",
        id: `${ProjectPriceMode.CostPlus}`,
        callback: () => {
          dispatch(setAdminProductCatalogDemoPricingMode(ProjectPriceMode.DTC));
        },
      },
      {
        label: "CostPlus (Cost)",
        id: `${ProjectPriceMode.CostPlus}`,
        callback: () => {
          dispatch(
            setAdminProductCatalogDemoPricingMode(ProjectPriceMode.CostPlus)
          );
        },
      },
    ];
  }, [dispatch]);

  return (
    <NatDropdown
      style={NatDropdownStyles[DropdownStyles.ADMIN][NatSizeType.XSMALL]}
      toggleButtonOrLabel={label}
      contentItemArray={dropdownViews}
      trackingId={"pricing-demo-dropdown-menu"}
    />
  );
};
