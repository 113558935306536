import {IImageInfo, ImageCropMode} from "@natomas-org/core";
import {updateImageInfo} from "../../../../../../../database/firebase/api/images";

export const ImageDetailStructure = {
  title: {
    name: "Title",
    type: "input",
    index: 0,
  },
  tags: {
    name: "Tags",
    type: "dropdown-array",
    index: 1,
  },
  crop_mode: {
    name: "Crop mode",
    type: "dropdown",
    options: {
      [ImageCropMode.COVER]: {
        label: "Fill Container (Crop)",
        value: ImageCropMode.COVER,
      },
      [ImageCropMode.CONTAIN]: {
        label: "Fit Container (No Crop)",
        value: ImageCropMode.CONTAIN,
      },
    },
    index: 2,
  },
};

export const getInitialValues = (imageInfo: IImageInfo, defaultValues: any) => {
  let values = defaultValues;
  if (imageInfo != null) {
    values["title"] = imageInfo?.title ?? "";
    values["tags"] = imageInfo?.tags ?? [];
    values["crop_mode"] = imageInfo?.crop_mode ?? "";
  }
  return values as IImageInfo;
};

export const saveImageDetails = (id: string, toSave: IImageInfo) => {
  return updateImageInfo(id, toSave);
};
