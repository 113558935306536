import React from "react";
import {ProductSublistCardProps} from "../index";
import {SmartImage} from "../../../../../../../../_shared/generics/image/SmartImage";
import {PageElement} from "../../../../../../../../_shared/generics/page/components/PageElement";
import {
  SublistCardContent,
  SublistCardPriceRange,
  SublistCardTitle,
} from "./styles";
import {GenericImageWrapper} from "../../styles";

export const CatalogBannerSublistCard = (props: {
  card: ProductSublistCardProps;
  size: number;
}) => {
  const {title, priceRange, image, clickEvent} = props.card;
  return (
    <PageElement size={props.size} height={"auto"}>
      <SublistCardContent
        id={"catalog-banner-card-" + title}
        onClick={() => clickEvent()}
      >
        <GenericImageWrapper height={"12rem"}>
          <SmartImage imageId={image} />
        </GenericImageWrapper>
        <SublistCardTitle>{title}</SublistCardTitle>
        <SublistCardPriceRange>{priceRange}</SublistCardPriceRange>
      </SublistCardContent>
    </PageElement>
  );
};
