import React, {Dispatch, SetStateAction} from "react";
import {
  NatLockedIcon,
  NatUnlockedIcon,
} from "../../../../../../../../../_shared/icon/icons";

export const OrderFormTableCellLockToggle = (props: {
  setLocked: Dispatch<SetStateAction<boolean>>;
  locked: boolean;
}) => {
  const {setLocked, locked} = props;
  return locked ? (
    <NatLockedIcon
      size={20}
      onClick={() => setLocked(!locked)}
      style={{cursor: "pointer"}}
    />
  ) : (
    <NatUnlockedIcon
      size={20}
      onClick={() => setLocked(!locked)}
      style={{cursor: "pointer"}}
    />
  );
};
