import React from "react";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";

export const QUALIFICATIONS_AND_ASSUMPTIONS_PREVIEW_TITLE =
  "Qualifications and Assumptions Preview";
export const TIMELINE_PREVIEW_TITLE = "Proposal Timeline Preview";
export const DESIGN_SELECTIONS_PREVIEW_TITLE =
  "Design Selections Section Preview";
const PreviewTitle = (props: {title: string}) => {
  return (
    <NatFlex FULL_WIDTH>
      <NatLabel label={props.title} type={NatLabelType.H4} />
    </NatFlex>
  );
};

export const ProposalEditorElementPreviewWrapper = (props: {
  title: string;
  hideHr?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <NatFlex COLUMN FULL_WIDTH NOWRAP>
      <hr style={{width: "100%"}} hidden={props.hideHr} />
      <PreviewTitle title={props.title} />
      {props.children}
    </NatFlex>
  );
};
