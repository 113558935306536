import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {
  IDocumentDetails,
  IGCloudFileStorageDetails,
  IMimeType,
} from "@natomas-org/core";
import {storage} from "../../../../database/firebase";
import {downloadFileToDesktop} from "../../../portal/views/SitePlanView/logic";

export const useProjectDocuments = (projectId?: string) => {
  const documents = useSelector(
    (state: IStore) => state.project?.[projectId ?? ""]?.documents ?? {}
  );
  const downloadFile = (documentInfo: IDocumentDetails) => {
    let ref: undefined | string;
    if (
      !!documentInfo?.details &&
      !!(documentInfo?.details as IGCloudFileStorageDetails)?.storageRefPath
    ) {
      ref = (documentInfo?.details as IGCloudFileStorageDetails)
        ?.storageRefPath;
    }
    if (!ref) {
      return;
    }
    let title = documentInfo?.filename;
    if (
      !title &&
      (documentInfo?.details as IGCloudFileStorageDetails)?.mimeType ===
        IMimeType.PDF
    ) {
      title = `${documentInfo?.title}.pdf`;
    } else {
      title = `${documentInfo?.title}`;
    }
    return storage
      .ref(ref)
      .getDownloadURL()
      .then((url: string) => {
        return downloadFileToDesktop(url, title);
      });
  };

  return {
    projectDocuments: Object.values(documents ?? {}),
    documentDictionary: documents ?? {},
    downloadFile: downloadFile,
  };
};
