import React, {useEffect, useMemo} from "react";
import {
  DateUtils,
  IDocumentDetails,
  IProjectNoteDetails,
  isBlankString,
} from "@natomas-org/core";
import {
  ProjectNoteContainer,
  ProjectNoteImages,
  ProjectNoteWrapper,
} from "./styles";
import {fetchUserAuthInfo} from "../../../../../../../../database/firebase/user";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../../../_shared/slices/types/Store";
import {NatLink} from "../../../../../../../_shared/generics/button";
import {
  NatHorizontalAlign,
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {HideItemButton} from "../../../_shared/HideItemButton";
import {toggleProjectNoteVisibility} from "../../../../../../../../database/firebase/project/notes";
import {DocumentFilePreviewer} from "../../../../../../../_shared/generics/documents/components/DocumentFilePreviewer";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {NatFlex} from "../../../../../../../_shared/generics/flex/NatFlex";
import {EditButtonWithIcon} from "../../../../../../../_shared/generics/documents/components";
import {useModal} from "../../../../../../../_shared/generics/modal/useModal";
import {ProjectNoteFullView} from "../../../../../../../portal/views/ProjectNotes/ProjectNoteFullView";
import {NatModalSize} from "../../../../../../../_shared/generics/modal/NatModal";
import {useActiveProjectDocuments} from "../../../../../../../_shared/hooks/useProject/useActiveProjectDocuments";
import useActiveDesign from "../../../../../../../_shared/hooks/useDesign/useActiveDesign";
import {AuthorIcon, getAuthorDisplayName} from "./AuthorIcon";

interface ProjectNoteProps extends IProjectNoteDetails {
  onEdit: (() => void) | null;
  public_facing: boolean;
}

const AUTHORS_TO_SHOW = 2;

export const ProjectNote = (props: ProjectNoteProps) => {
  const {
    source_id,
    title,
    content,
    authors,
    documentIds,
    onEdit,
    hidden,
    timestamp,
    public_facing,
  } = props;
  const {configuration} = useActiveDesign();
  const {projectDocuments} = useActiveProjectDocuments();
  const authInfos = useSelector((state: IStore) => state?.user?.userAuthInfo);
  useEffect(() => {
    authors?.map(async (id: string) => fetchUserAuthInfo(id));
  }, [authors]);
  const documents = documentIds
    ?.map((documentId: string, index: number) => {
      const document =
        projectDocuments?.find((doc) => {
          return doc?.document_id === documentId;
        }) ?? null;
      if (document) {
        return document;
      }
      return null;
    })
    .filter((document) => !!document) as IDocumentDetails[];
  const dateString = DateUtils.getFullDate(new Date(timestamp));
  const header = (
    <NatFlex style={{gap: "0.5rem", paddingLeft: "0.5rem"}}>
      <NatLabel
        label={dateString}
        type={NatLabelType.P2}
        weight={NatLabelWeight.BOLD}
      />
      <NatLabel
        type={NatLabelType.P2}
        weight={NatLabelWeight.BOLD}
        label={"•"}
        hidden={isBlankString(title)}
      />
      <NatLabel label={title} type={NatLabelType.P2} />
    </NatFlex>
  );

  const {openModal, Modal} = useModal({
    content: <ProjectNoteFullView {...props} />,
    header: header,
    size: NatModalSize.LARGE,
    removeFixedPlacementLogic: true,
  });

  const authorIcons = useMemo(() => {
    const toReturn = [];
    const displayAuthors =
      authors && authors.length >= AUTHORS_TO_SHOW
        ? authors?.slice(0, AUTHORS_TO_SHOW)
        : authors ?? [];
    displayAuthors?.forEach((authorId: string) => {
      const authorDisplayName =
        getAuthorDisplayName(authInfos, authorId) ?? "Villa Team";
      toReturn.push(
        <AuthorIcon
          allAuthInfo={authInfos}
          hoverText={`Published by ${authorDisplayName}`}
          id={authorId}
        />
      );
    });

    if (authors && authors.length > AUTHORS_TO_SHOW) {
      const hoverText = `${authors
        ?.slice(AUTHORS_TO_SHOW)
        .map((authorId) => {
          return getAuthorDisplayName(authInfos, authorId);
        })
        .join(", ")}`;
      toReturn.push(
        <AuthorIcon
          allAuthInfo={authInfos}
          text={`+${authors?.length - AUTHORS_TO_SHOW}`}
          hoverText={hoverText}
          id={hoverText}
        />
      );
    }
    return toReturn;
  }, [authInfos, authors]);

  return (
    <ProjectNoteWrapper>
      {Modal}
      <ProjectNoteContainer isHidden={hidden}>
        <NatFlex
          style={{justifyContent: "space-between", alignItems: "flex-start"}}
          VERTICAL_ALIGN={NatVerticalAlign.CENTER}
        >
          <NatFlex
            style={{gap: "0rem", margin: "0 0", padding: "0 0"}}
            VERTICAL_ALIGN={NatVerticalAlign.CENTER}
          >
            {authorIcons.map((icon) => {
              return icon;
            })}
            {header}
          </NatFlex>
          <NatFlex
            style={{gap: "0.5rem"}}
            VERTICAL_ALIGN={NatVerticalAlign.CENTER}
          >
            {!!onEdit && !public_facing && (
              <EditButtonWithIcon onClick={onEdit} tooltipTitle={"Edit note"} />
            )}
            {configuration?.id && !public_facing && (
              <HideItemButton
                clickEvent={() => {
                  return Promise.resolve(
                    toggleProjectNoteVisibility(configuration.id, source_id)
                  );
                }}
                hidden={hidden}
                itemId={source_id}
                tooltipTitle={"note"}
              />
            )}
          </NatFlex>
        </NatFlex>
        <NatFlex COLUMN FULL_WIDTH HORIZONTAL_ALIGN={NatHorizontalAlign.LEFT}>
          <NatLabel
            textOverflow={{maxLines: 2, ellipsis: true}}
            label={content}
            type={NatLabelType.P4}
            style={{flexGrow: 1}}
          />
          <NatLink
            label={"Show More"}
            size={NatSize.SMALL}
            option={StyleOption.PRIMARY_LINK_ALT}
            clickEvent={() => {
              openModal();
            }}
          />
        </NatFlex>
        <ProjectNoteImages>
          {documentIds?.map((documentId: string, index: number) => {
            const document = projectDocuments?.find((doc) => {
              return doc?.document_id === documentId;
            });
            if (!document) {
              return null;
            }
            return (
              <DocumentFilePreviewer
                subType={"file"}
                enablePresentation={true}
                key={documentId}
                document={document}
                documentList={documents}
                type={"thumbnail"}
              />
            );
          })}
        </ProjectNoteImages>
      </ProjectNoteContainer>
    </ProjectNoteWrapper>
  );
};
