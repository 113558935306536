import {
  BLACK,
  VILLA_BLUE,
  VILLA_DEEP_LILAC,
  VILLA_LILAC,
  WHITE,
} from "src/components/_shared/colors";
import styled from "styled-components";

export const ContactUsButton = styled.a`
  align-content: center;
  border-radius: 5px;
  border: ${VILLA_DEEP_LILAC};
  background-color: ${VILLA_DEEP_LILAC};
  color: ${WHITE};
  height: 40px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  line-height: normal;
  padding: 4px 16px;

  &:hover {
    text-decoration: none;
    color: ${WHITE};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContactUs = styled.div`
  align-content: center;
  background-color: ${VILLA_LILAC};
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  margin: 12px;
  text-decoration: none;
  padding: 12px;
  vertical-align: middle;
  p {
    align-items: center;
    color: ${BLACK};
    display: flex;
    font-size: 21px;
    font-family: "p22-mackinac-pro", serif;
    font-weight: 500;
    margin: 0;
  }
`;

export const GlobalFooterContainer = styled.div`
  background-color: ${VILLA_BLUE};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  padding: 16px;
  margin-top: 16px;
  @media screen and (min-width: 768px) {
    padding: 16px 32px;
  }
`;

export const FooterCopyright = styled.div`
  color: ${WHITE};
  font-size: 14px;
  text-align: left;
  width: 100%;
  padding: 0 16px;
`;

export const ContainerColumn = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  gap: 24px;
  width: fit-content;
`;

export const ContainerLeft = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

export const ContainerRight = styled.div`
  align-items: flex-end;
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  margin: auto;
  margin-right: 0;
  @media (max-width: 1031px) {
    // 1031 is where the footer wraps
    align-items: flex-start;
    justify-content: flex-start;
    flex-basis: 100%;
    margin-right: auto;
    margin-left: 0;
    width: 100%;
  }
`;

export const ContainerRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px 17px;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
  @media (max-width: 599px) {
    // 599 is where the legal links div wraps
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
`;

export const LegalLink = styled.div`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  a {
    color: ${WHITE};
    text-decoration: none;
    gap: 8px;
    padding-left: 16px;
  }
  a:hover {
    opacity: 0.7;
  }
`;

export const SocialMediaLinksContainer = styled.div``;

export const SocialMediaLinks = styled.ul`
  color: ${VILLA_LILAC};
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  gap: 15px;
  svg {
    height: 100%;
    width: 20px;
  }
  a {
    color: ${VILLA_LILAC};
    display: flex;
  }
  a:hover {
    opacity: 0.7;
  }
`;
