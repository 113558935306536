import {getPricingForUnitAndPriceMode, ICartItem} from "@natomas-org/core";
import {useProduct} from "../../useProductCatalog/useProduct";
import useDesign from "../../useDesign/useDesign";
import useActiveProject from "../../useProject/useActiveProject";

export const useCartItemSnapshot = (item: ICartItem) => {
  const {details} = item;
  const {productId, factoryId, configurationId} = details ?? {};
  const {factoryDetails, product} = useProduct(productId, factoryId);
  const {configuration, snapshot, map} = useDesign(configurationId);
  const {projectPriceMode} = useActiveProject();
  // TODO B2B Review these pricing calculations
  const pricing = getPricingForUnitAndPriceMode(
    configuration,
    projectPriceMode,
    {addSalesTax: false}
  );
  // const totalUnitPriceInMicros = configuration?.totalUnitPrice?.price ?? 0;
  // const upgradePriceInMicros = configuration?.upgradePrice?.price ?? 0;

  return {
    hasDesign: !!configuration?.selectedModifiers,
    isInventory: !!product?.inventory_info,
    factory: factoryDetails,
    product,
    configuration,
    snapshot,
    map,
    // totalUnitPriceInMicros,
    // upgradePriceInMicros,
    pricing,
    projectPriceMode,
  };
};
