import {IoIosHelpCircle} from "react-icons/io";
import React from "react";
import {AdminHelpIconContainer} from "./styles";
import {openURLInNewTab} from "../../../../_shared/navigation";
import {WHITE} from "../../../../_shared/colors";

export interface AdminHelpIconProps {
  link: string;
  color?: string;
  size?: number;
}

export const AdminHelpIcon = (props: AdminHelpIconProps) => {
  const {link, color, size} = props;

  return (
    <AdminHelpIconContainer onClick={() => openURLInNewTab(link)}>
      <IoIosHelpCircle size={size ?? 24} color={color ?? WHITE} />
    </AdminHelpIconContainer>
  );
};
