import {
  Customer,
  ICatalogTag,
  IProduct,
  IProductGroupDetails,
  ProductGroup,
} from "@natomas-org/core";
import {ICurrentUser} from "../slices/types/Store";
import {useSelectedProduct} from "./useProductCatalog/useSelectedProduct";
import {useCurrentUser} from "./useCurrentUser";
import React, {Fragment, useCallback, useMemo, useState} from "react";
import {useSelectedFactoryLine} from "./useProductCatalog/useSelectedFactoryLine";
import {NavigationPaths} from "./useNavigation/paths";
import {useCustomerStatus} from "./useCustomerStatus";
import {
  HOME_PAGE_ID,
  INSTANT_ESTIMATE_PAGE_ID,
} from "../../portal/_shared/navigation/constants";
import {updateUserProductSelection} from "../../../database/firebase/api/user";
import {CustomerJourneyStepName, useTracking} from "./useTracking";
import {store} from "../../../store";
import {setRegistrationModalState} from "../slices/SessionSlice/SessionSlice";
import {StyleOption} from "../generics/_shared";
import {NatButton} from "../generics/button";
import {useNavigation} from "./useNavigation";
import {useCurrentCustomer} from "./useCurrentCustomer";
import {useIsPublicApp} from "./useIsPublicApp";
import {useUrl} from "./useUrl";
import {MAP_PATH} from "../../../paths";
import {DesignHomeModalWarning} from "../../catalog/views/ProductView/components/DesignHomeModalWarning";
import {StudioSaveWarningModal} from "../../studio/_shared/components/StudioSaveWarningModal";
import useActiveDesign from "./useDesign/useActiveDesign";
import useActivitySlice from "./useActivity/useActivitySlice";
import useActiveProject from "./useProject/useActiveProject";
import {useActiveCartItem} from "./useCart/useCartItem/useActiveCartItem";
import useActivitySliceDispatcher from "./useCustomer/useActivitySliceDispatcher";
import useCatalogCartController from "./useCart/Controllers/useCatalogCartController";
import {INTAKE_FORM_REDIRECT_TYPE} from "../../intake-form/constants";

const getSelectButtonLabel = (
  product: IProduct | undefined,
  productLineDetails: IProductGroupDetails | undefined
) => {
  if (!product) {
    return undefined;
  }

  return "Continue with this home";
};

const getSelectButtonTrackingId = (
  product: IProduct | undefined,
  loggedInUser: ICurrentUser | undefined | null,
  mapNotCatalog: boolean
) => {
  if (!product) {
    return "undefined";
  }
  if (mapNotCatalog) {
    return !!loggedInUser ? "account-map-select-home" : "pa-map-select-home";
  }
  return !!loggedInUser
    ? "account-catalog-select-home"
    : "pa-catalog-select-home";
};

const getUpgradeButtonLabel = (
  product: IProduct | undefined,
  productLineDetails: IProductGroupDetails | undefined
) => {
  const fewChoicesTag =
    productLineDetails &&
    ProductGroup.containsTag(
      productLineDetails,
      ICatalogTag.FEW_DESIGN_CHOICES
    );
  const isInventory = !!product?.inventory_info;
  const hasFewChoices = fewChoicesTag || isInventory;

  if (hasFewChoices) {
    return "View design details";
  } else {
    return "Customize this model";
  }
};
const getUpgradeButtonTrackingId = (
  product: IProduct | undefined,
  loggedInUser: ICurrentUser | undefined | null,
  mapNotCatalog: boolean
) => {
  if (!product) {
    return undefined;
  }
  let usingProduct = "catalog";
  if (mapNotCatalog) {
    usingProduct = "map";
  }
  if (product?.inventory_info) {
    return !!loggedInUser
      ? `account-${usingProduct}-readybuilt-view-in-studio`
      : `pa-${usingProduct}-readybuilt-view-in-studio`;
  } else {
    return !!loggedInUser
      ? `account-${usingProduct}-design-home`
      : `pa-${usingProduct}-design-home`;
  }
};

export const useCatalogProductSelectionButtons = () => {
  const selectedProduct = useSelectedProduct();
  const {details} = useSelectedFactoryLine();
  const {loggedInUser, isAdmin} = useCurrentUser();
  const {configuration} = useActiveDesign();
  const {id: cartItemId} = useActiveCartItem() ?? {};
  const {isCartEnabled} = useActiveProject();
  const {activeProjectId, activeCustomerId} = useActivitySlice();
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const {saveCartItem} = useCatalogCartController();
  const {customer} = useCurrentCustomer();
  const {to} = useNavigation();
  const [showDesignWarningModal, setShowDesignWarningModal] =
    useState<boolean>(false);
  const {trackJourneyStep} = useTracking();
  const {getURLPath} = useUrl();
  const isPublicApp = useIsPublicApp();
  const map = getURLPath()?.includes(MAP_PATH);
  const {isCustomerPreDeposit, customerStatus} = useCustomerStatus();
  const [designWarningTitle, setDesignWarningTitle] = useState<string>("");
  const [designWarningButton, setDesignWarningButton] = useState<string>("");
  const [designWarningContent, setDesignWarningContent] = useState<string>("");
  const [selectNotDesign, setSelectNotDesign] = useState<boolean>(false);

  const navigateToStudio = useCallback(() => {
    setActiveCartItemById(cartItemId);
    to(NavigationPaths.STUDIO, {
      productId: selectedProduct?.id,
      productGroupId: details?.id,
      cartItemId: cartItemId,
    });
  }, [cartItemId, details?.id, selectedProduct?.id, to, setActiveCartItemById]);

  const toCorrectPage = useCallback(
    (pid: string) => {
      if (isCartEnabled) {
        to(NavigationPaths.CART);
      } else if (isCustomerPreDeposit) {
        to(NavigationPaths.PORTAL, {
          page: INSTANT_ESTIMATE_PAGE_ID,
          pid: pid,
        });
      } else {
        to(NavigationPaths.PORTAL, {page: HOME_PAGE_ID, pid: pid});
      }
    },
    [
      isCartEnabled,
      isCustomerPreDeposit,
      to,
      customerStatus.instantEstimateStatus,
    ]
  );

  const saveProductToProjectNoDesign = useCallback(() => {
    setActiveCartItemById(cartItemId);
    if (activeProjectId) {
      updateUserProductSelection({
        customerId: activeCustomerId,
        projectId: activeProjectId,
        cartItemId: cartItemId,
        product: selectedProduct,
      }).then(() => {
        toCorrectPage(activeProjectId);
        trackJourneyStep(CustomerJourneyStepName.UNIT_SELECTION_COMPLETED);
      });
    }
  }, [
    setActiveCartItemById,
    cartItemId,
    activeProjectId,
    toCorrectPage,
    activeCustomerId,
    selectedProduct,
    trackJourneyStep,
    saveCartItem,
  ]);

  const executeDesignClick = (title: string, design: boolean) => {
    // Set modal title
    let modifiedTitle = title;
    let designWarningButton;
    if (isAdmin && customer) {
      modifiedTitle = modifiedTitle.replace(
        "your",
        Customer.getFullName(customer) + "'s"
      );
      if (design) {
        designWarningButton =
          "Confirm change & design for " + Customer.getFirstName(customer);
      } else {
        designWarningButton =
          "Confirm change for " + Customer.getFirstName(customer);
      }
    } else {
      if (design) {
        designWarningButton = "Confirm & design";
      } else {
        designWarningButton = "Confirm";
      }
    }

    if (design) {
      setDesignWarningContent(
        "Upon designing a new unit, your previous design selections will be overwritten."
      );
    } else {
      setDesignWarningContent(
        "Selecting a new home resets your previous design choices, so you may need to design your home again."
      );
    }

    setDesignWarningButton(designWarningButton);
    setDesignWarningTitle(modifiedTitle);

    // Button logic
    if (customer) {
      // Show warning if there is a saved unit, and you are an admin or post-deposit
      if (configuration && (isAdmin || !isCustomerPreDeposit)) {
        setShowDesignWarningModal(true);
      } else {
        if (design) {
          navigateToStudio();
        } else {
          saveProductToProjectNoDesign();
        }
      }
    } else if (isAdmin) {
      //  Just admin, no customer
      navigateToStudio();
    } else {
      //  Nobody logged in
      if (design) {
        navigateToStudio();
      } else {
        store.dispatch(
          setRegistrationModalState({
            visible: true,
            signUp: true,
            registrationValues: {
              product: selectedProduct,
            },
            redirectType: INTAKE_FORM_REDIRECT_TYPE.INSTANT_ESTIMATE,
          })
        );
      }
    }
  };

  const designButtonAction = () => {
    setSelectNotDesign(false);
    executeDesignClick("Want to replace your previous choice?", true);
  };

  const selectHomeButtonAction = () => {
    setSelectNotDesign(true);
    executeDesignClick("Want to replace your previous choice?", false);
  };

  const designButtonLabel = useMemo(() => {
    if (!selectedProduct || selectedProduct?.disableCustomization) {
      return undefined;
    }
    return getUpgradeButtonLabel(selectedProduct, details);
  }, [selectedProduct, details]);

  const designButtonTrackingId = useMemo(() => {
    return getUpgradeButtonTrackingId(
      selectedProduct,
      loggedInUser,
      map ?? false
    );
  }, [loggedInUser, map, selectedProduct]);
  const selectButtonLabel = useMemo(() => {
    return getSelectButtonLabel(selectedProduct, details);
  }, [selectedProduct, details]);

  const selectButtonTrackingId = useMemo(() => {
    return getSelectButtonTrackingId(
      selectedProduct,
      loggedInUser,
      map ?? false
    );
  }, [loggedInUser, map, selectedProduct]);

  const designButton = designButtonLabel ? (
    <NatButton
      label={designButtonLabel}
      type={"button"}
      id={designButtonTrackingId}
      trackingId={designButtonTrackingId}
      clickEvent={designButtonAction}
      option={StyleOption.PRIMARY}
      style={{width: "100%"}}
      spinnerEnabled={!showDesignWarningModal}
      disabled={false}
    />
  ) : null;

  const selectButton = selectButtonLabel ? (
    <NatButton
      label={selectButtonLabel}
      type={"button"}
      id={selectButtonTrackingId}
      trackingId={selectButtonTrackingId}
      clickEvent={selectHomeButtonAction}
      option={StyleOption.PRIMARY_ALT}
      spinnerEnabled={!showDesignWarningModal && !!loggedInUser}
      disabled={isAdmin && isPublicApp && !customer} // Disable the button for admins without a user selected
      style={{width: "100%"}}
    />
  ) : null;

  const modals = (
    <Fragment>
      <DesignHomeModalWarning
        show={showDesignWarningModal}
        setShow={setShowDesignWarningModal}
        continueAction={() => {
          customer && selectNotDesign
            ? saveProductToProjectNoDesign()
            : navigateToStudio();
        }}
        title={designWarningTitle}
        continueString={designWarningButton}
        showComparison={true}
        content={designWarningContent}
      />
      <StudioSaveWarningModal onConfirmSave={saveProductToProjectNoDesign} />
    </Fragment>
  );

  return {
    designButton,
    selectButton,
    modals,
  };
};
