import React from "react";
import {InputProps, InputSelectionProps} from "../../interface";
import {CheckContainer, Label, List, SelectionLabel} from "./style";
import {ThemeProvider} from "styled-components";
import check from "./check.svg";
import {DefaultError} from "../../generic/DefaultError";

const RadioList = (props: InputProps) => {
  const {
    id,
    label,
    error,
    setError,
    selectionProps,
    additionalContent,
    ...rest
  } = props;
  if (!selectionProps) {
    window.alert("SingleSelectList: No selections provided");
    return null;
  }

  return (
    <List>
      <Label hidden={!label}>{label}</Label>
      {selectionProps?.map((selection: InputSelectionProps, index: number) => {
        const selectionId = `${id}-${index}`;
        const selected = props.value === selection.value;
        return (
          <ThemeProvider
            theme={{
              selected,
            }}
            key={`${selectionId}-theme`}
          >
            <SelectionLabel
              id={`${selectionId}-label`}
              htmlFor={selectionId}
              key={`${selectionId}-label`}
            >
              {selection.label}
              <CheckContainer src={check} alt={"Selected"} />
            </SelectionLabel>
            <input
              {...rest}
              hidden={true}
              type="radio"
              id={selectionId}
              name={id}
              value={selection.value}
              checked={props.value === selection.value}
              key={`${selectionId}-input`}
            />
          </ThemeProvider>
        );
      })}
      {additionalContent}
      <DefaultError id={id} error={error} />
    </List>
  );
};

export default RadioList;
