import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {GRAY_BORDER_LIGHT} from "../../../../../../../_shared/colors";

export const ProjectNoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  position: relative;
  border-bottom: 1px solid ${GRAY_BORDER_LIGHT};
  width: 100%;
`;

export const ProjectNoteContainer = styled.div<{isHidden?: boolean}>`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  padding: 1rem 0;
  opacity: ${(props) => (props?.isHidden ? 0.5 : 1)};
  transition: opacity 150ms ease-in-out;
`;

export const ProjectNoteImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const ProjectNoteAdvancedDetailsTitle = styled.div`
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_BOLD};
`;
