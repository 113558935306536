import styled from "styled-components";
import {DEFAULT_SHADOW, VILLA_BLUE, WHITE} from "../../../../_shared/colors";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const AdminViewCardWrapper = styled.div<{sizeRem: number}>`
  height: ${(props) => props.sizeRem}rem;
  width: ${(props) => props.sizeRem}rem;
`;
export const AdminViewCardContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${WHITE};
  padding: 1rem;
  text-align: center;
  svg {
    margin-bottom: 1.5rem;
    width: 4rem;
    height: 4rem;
    color: ${VILLA_BLUE};
  }
  border-radius: 1rem;
  cursor: pointer;
  box-shadow: none;
  &:hover {
    box-shadow: ${DEFAULT_SHADOW};
    transform: scale(1.05);
  }
  transition: all 100ms ease-out;
`;

export const AdminViewCardTitle = styled.div`
  font-size: 0.825rem;
  font-family: ${FONT_FAMILY_BOLD};
  text-transform: uppercase;
  color: ${VILLA_BLUE};
`;
