import React, {useCallback, useEffect, useRef, useState} from "react";
import {NatCloseIcon} from "../../icon/icons";
import {NatButton} from "../button";
import {IconPosition, NatSize, StyleOption} from "../_shared";
import {useMicroModalResize} from "./useMicroModalResize";
import {
  DraggingPanel,
  MicroModalContainer,
  MicroModalContainerHeader,
} from "./styles";

export const sanitizeModalLocation = (
  x: number,
  y: number,
  width: number,
  height: number,
  postOffset?: number
): {x: number; y: number} => {
  if (!width || !height) {
    return {x: 0, y: 0};
  }
  let xPos = Math.floor(Math.max(Math.min(x, window.innerWidth - width), 0));
  let yPos = Math.floor(Math.max(Math.min(y, window.innerHeight - height), 0));
  return {
    x: xPos - (postOffset ?? 0),
    y: yPos - (postOffset ?? 0),
  };
};

interface MicroModalProps {
  id: string;
  draggable: boolean;
  children: any;
  maxWidthPx?: number;
  title?: string;
  additionalReset?: () => void;
  event?: any;
  startInCenter?: boolean;
  startInTopLeft?: boolean;
}

const animationTimeMs = 200;

/*
 * This thing is pretty simple to use, however there is a strange but necessary mechanism to capture the initial coordinates location.
 * Pass in the click event that you want to capture to the event prop.
 * Example: ConditionAssociations was the initial use case
 * */
export const NAT_MICRO_MODAL_ID_PREFIX = "micro-modal-";
export const NatMicroModal = (props: MicroModalProps) => {
  const {
    additionalReset,
    children,
    draggable,
    event,
    id,
    maxWidthPx,
    title,
    startInCenter,
    startInTopLeft,
  } = props;
  const [show, setShow] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<
    {x: number; y: number} | undefined
  >(undefined);
  const [offset, setOffset] = useState<{x: number; y: number} | undefined>(
    undefined
  );
  const [dragging, setDragging] = useState(false);
  const ref = useRef(null);
  const {width, height} = useMicroModalResize(ref);

  useEffect(() => {
    if (event) {
      captureCoordinates(event);
      setShow(true);
    } else {
      setShow(false);
    }
  }, [event, width, height]);

  const captureCoordinates = useCallback(
    (e: any) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();

      let x = e.clientX;
      let y = e.clientY;
      if (startInCenter) {
        x = window.innerWidth / 2 - width / 2;
        y = window.innerHeight / 2 - height / 2;
      } else if (startInTopLeft) {
        x = 24;
        y = 24;
      }

      setCoordinates(sanitizeModalLocation(x, y, width, height, 15));
    },
    [startInCenter, startInTopLeft, width, height]
  );

  const closeMicroModal = () => {
    setShow(false);
    additionalReset?.();
    setDragging(false);
    setOffset(undefined);
  };

  const onDragStart = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(true);
      setOffset({
        x: e.pageX - (coordinates?.x ?? 0),
        y: e.pageY - (coordinates?.y ?? 0),
      });
    },
    [coordinates]
  );

  const onDrag = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      if (dragging) {
        let x = e.pageX - (offset?.x ?? 0);
        let y = e.pageY - (offset?.y ?? 0);
        setCoordinates(sanitizeModalLocation(x, y, width, height));
      }
    },
    [dragging, offset, width, height]
  );

  const onDragStop = () => {
    setDragging(false);
  };

  useEffect(() => {
    if (coordinates && width && height)
      setCoordinates(
        sanitizeModalLocation(coordinates.x, coordinates.y, width, height)
      );
  }, [width, height]);

  useEffect(() => {
    if (dragging) {
      document.addEventListener("pointermove", onDrag);
      document.addEventListener("pointerup", onDragStop);
    }
    return () => {
      document.removeEventListener("pointermove", onDrag);
      document.removeEventListener("pointerup", onDragStop);
    };
  }, [dragging]);

  return (
    <MicroModalContainer
      ref={ref}
      key={`${NAT_MICRO_MODAL_ID_PREFIX}${id}`}
      id={`${NAT_MICRO_MODAL_ID_PREFIX}${id}`}
      maxWidthPx={Math.min(maxWidthPx ?? 700, window.innerWidth)}
      show={show}
      animationTimeMs={animationTimeMs}
      style={{
        left: coordinates?.x ?? 0,
        top: coordinates?.y ?? 0,
      }}
    >
      <MicroModalContainerHeader>
        <NatButton
          size={NatSize.XSMALL}
          label={"close"}
          option={StyleOption.DESTRUCTIVE}
          clickEvent={() => {
            closeMicroModal();
          }}
          icon={{
            icon: <NatCloseIcon />,
            iconPosition: IconPosition.ONLY,
          }}
        />
        <DraggingPanel
          isDraggable={draggable}
          onMouseDown={draggable ? onDragStart : undefined}
        >
          {title}
        </DraggingPanel>
      </MicroModalContainerHeader>

      {children}
    </MicroModalContainer>
  );
};
