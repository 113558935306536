import React, {useMemo} from "react";
import {IProduct, ProjectPriceMode} from "@natomas-org/core";
import {useFactoryInventoryEditor} from "../../../../../../_shared/hooks/admin-only-hooks/useFactoryInventoryEditor";
import FactoryInventoryDefaultView from "./FactoryInventoryDefaultView";
import {AdminFactoryInventoryEditorView} from "../../../../../../_shared/slices/CatalogSlice/interfaces";
import {FactoryInventoryCatalogEditor} from "./FactoryInventoryEditorViews/FactoryInventoryCatalogEditor";
import {FactoryInventoryStudioEditor} from "./FactoryInventoryEditorViews/FactoryInventoryStudioEditor";
import {FactoryInventoryInformationEditor} from "./FactoryInventoryEditorViews/FactoryInventoryInformationEditor";
import FactoryInventoryPricingEditor from "./FactoryInventoryEditorViews/FactoryInventoryPricingEditor";
import {useProduct} from "../../../../../../_shared/hooks/useProductCatalog/useProduct";

export interface FactoryInventoryViewProps {
  priceMode: ProjectPriceMode;
  inventory?: {[id: string]: IProduct};
  factoryId?: string;
  factoryTitle?: string;
}

export interface FactoryInventoryEditorProps {
  priceMode: ProjectPriceMode;
  product: IProduct;
  configuration: any;
  isEditing: boolean;
}

const FactoryInventoryView = (props: FactoryInventoryViewProps) => {
  const {
    view: editorView,
    editingProduct,
    isEditing,
    editingConfiguration,
  } = useFactoryInventoryEditor();

  const {product} = useProduct(
    editingProduct?.id,
    editingProduct?.productGroupId
  );

  const targetProduct = useMemo(() => {
    if (!product) {
      return editingProduct;
    } else {
      return product;
    }
  }, [product, editingProduct]);

  const editorProps: FactoryInventoryEditorProps = useMemo(() => {
    return {
      product: targetProduct,
      configuration: editingConfiguration,
      isEditing: isEditing,
      priceMode: props.priceMode,
    };
  }, [props.priceMode, targetProduct, editingConfiguration, isEditing]);

  if (editorView === AdminFactoryInventoryEditorView.CATALOG) {
    return <FactoryInventoryCatalogEditor {...editorProps} />;
  }
  if (editorView === AdminFactoryInventoryEditorView.STUDIO) {
    return <FactoryInventoryStudioEditor {...editorProps} />;
  }
  if (editorView === AdminFactoryInventoryEditorView.PRICING) {
    return <FactoryInventoryPricingEditor {...editorProps} />;
  }
  if (editorView === AdminFactoryInventoryEditorView.INFO) {
    return <FactoryInventoryInformationEditor {...editorProps} />;
  }

  return <FactoryInventoryDefaultView {...props} />;
};

export default FactoryInventoryView;
