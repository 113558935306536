import {getAssociationSection} from "./index";
import {
  getCategoryTitle,
  getModifierGroupTitle,
  getProductTitle,
} from "../TabColumn/helper";
import {
  viewCategory,
  viewModifierGroup,
  viewProduct,
} from "../../logic/helpers";

export const getModifierGroupSection = (modifierGroups: any) => {
  return getAssociationSection(
    "Modifier Groups",
    modifierGroups,
    getModifierGroupTitle,
    viewModifierGroup
  );
};

export const getCategorySection = (categories: any) => {
  return getAssociationSection(
    "Categories",
    categories,
    getCategoryTitle,
    viewCategory
  );
};

export const getProductSection = (products: any) => {
  return getAssociationSection(
    "Products",
    products,
    getProductTitle,
    viewProduct
  );
};
