import {IEligibleFeatures} from "@natomas-org/core";

export const getFactoryKeysFromEligibility = (
  e?: IEligibleFeatures
): string[] => {
  if (!e) {
    return [];
  }
  const fullyEligibleFactories: string[] = Object.keys(e?.factories ?? {});
  const partiallyEligibleFactories: string[] = Object.keys(e?.products ?? {});
  return [...fullyEligibleFactories, ...partiallyEligibleFactories];
};
