import React, {useState} from "react";
import {
  HeaderNewItemButton,
  HeaderRow,
  HeaderSearchContainer,
  HeaderSearchInput,
  HeaderSearchModeContainer,
  HeaderSearchModeLabel,
  TabColumnContainer,
} from "../../../_shared/styles/AdminStyles";
import {AiOutlinePlus} from "react-icons/ai";
import {TabColumnSectionContainer} from "./TabColumnSectionContainer";
import {NatButton} from "../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {MicroSwitch} from "../../../../_shared/generics/switch/microSwitch";

export interface TabColumnProps {
  title: string;
  width: string;
  sections: TabColumnRowSection[];
  headerAddAction?: () => void;
}

export interface RowStatusType {
  hasWarning: boolean;
  customStatuses?: JSX.Element;
}

export interface TabColumnRowSection {
  rows: TabColumnRow[];
  title?: string;
}

export interface TabColumnRow {
  title: string;
  internal_title: string;
  key: string;
  data: any;
  action: (data: any) => void;
  active: boolean;
  statuses?: RowStatusType;
}

export const TabColumn = (props: TabColumnProps) => {
  const {title, headerAddAction, sections, width} = props;
  const [searchText, setSearchText] = useState<string>();
  const [searchByStudioTitle, setSearchByStudioTitle] =
    useState<boolean>(true);
  return (
    <TabColumnContainer width={width}>
      <HeaderRow>
        <HeaderSearchContainer>
          <HeaderSearchInput
            spellCheck={false}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={title + "..."}
          />
          {!!headerAddAction && (
            <HeaderNewItemButton>
              <NatButton
                label={"New " + title}
                size={NatSize.XSMALL}
                option={StyleOption.SECONDARY_ALT}
                clickEvent={() => headerAddAction()}
                icon={{
                  icon: <AiOutlinePlus />,
                  iconPosition: IconPosition.ONLY,
                }}
                type={"button"}
              />
            </HeaderNewItemButton>
          )}
        </HeaderSearchContainer>
        <HeaderSearchModeContainer>
          <HeaderSearchModeLabel selected={!searchByStudioTitle}>
            Internal Title
          </HeaderSearchModeLabel>
          <HeaderSearchModeLabel selected={searchByStudioTitle}>
            Studio Title
          </HeaderSearchModeLabel>
          <MicroSwitch
            isChecked={searchByStudioTitle}
            setChecked={setSearchByStudioTitle}
          />
        </HeaderSearchModeContainer>
      </HeaderRow>
      <TabColumnSectionContainer
        searchText={searchText}
        sections={sections}
        searchMode={searchByStudioTitle ? "studio" : "internal"}
      />
    </TabColumnContainer>
  );
};
