import React, {useMemo} from "react";
import {
  OrderFormAlertBoxContainer,
  OrderFormAlertHeader,
  OrderFormAlertItem,
  OrderFormAlertItemIcon,
  OrderFormAlertItemLabel,
  OrderFormAlertItemLabelInfo,
  OrderFormAlertItemLabelTitle,
} from "./styles";
import {
  Address,
  DesignStudioModifier,
  IAddressDetails,
  IProduct,
} from "@natomas-org/core";
import {
  NatCheckCircleHollow,
  NatWarningDiamondIcon,
} from "../../../../../../../_shared/icon/icons";
import {
  DESTRUCTIVE_FILL,
  VILLA_APPLE_GREEN,
} from "../../../../../../../_shared/colors";

export const OrderFormAlertBox = (props: {
  allSelectionModifierIds: string[];
  usedSelectionModifierIds: string[];
  modifiers: {[key: string]: DesignStudioModifier};
  allFieldsSatisfied: boolean;
  address: IAddressDetails;
  product?: IProduct;
  code?: string;
}) => {
  const {
    allSelectionModifierIds,
    usedSelectionModifierIds,
    modifiers,
    allFieldsSatisfied,
    address,
    code,
    product,
  } = props;

  const unusedModifiers = useMemo(() => {
    const unused = allSelectionModifierIds?.filter(
      (modifierId: string) => !usedSelectionModifierIds?.includes(modifierId)
    );
    return unused
      .map((modifierId: string) => {
        return modifiers?.[modifierId];
      })
      .sort((a: any, b: any) => {
        if (!!a === !!b) {
          // If both exist, or both don't exist -> do nothing
          return 0;
        } else if (!!a) {
          // If a exists, move 'up'
          return -1;
        } else {
          // Move 'down'
          return 1;
        }
      });
  }, [allSelectionModifierIds, usedSelectionModifierIds, modifiers]);

  const checkIcon = (
    <NatCheckCircleHollow color={VILLA_APPLE_GREEN} size={18} />
  );
  const warningRedIcon = (
    <NatWarningDiamondIcon color={DESTRUCTIVE_FILL} size={18} />
  );
  const warningOrangeIcon = (
    <NatWarningDiamondIcon color={"darkorange"} size={18} />
  );

  return (
    <OrderFormAlertBoxContainer>
      <OrderFormAlertHeader>Requirements</OrderFormAlertHeader>
      <OrderFormAlertItem>
        <OrderFormAlertItemLabel>
          <OrderFormAlertItemLabelTitle>
            Order Form Status
          </OrderFormAlertItemLabelTitle>
          <OrderFormAlertItemLabelInfo>
            {allFieldsSatisfied ? "Completed" : "Incomplete"}
          </OrderFormAlertItemLabelInfo>
        </OrderFormAlertItemLabel>
        <OrderFormAlertItemIcon>
          {allFieldsSatisfied ? checkIcon : warningRedIcon}
        </OrderFormAlertItemIcon>
      </OrderFormAlertItem>
      <OrderFormAlertItem>
        <OrderFormAlertItemLabel>
          <OrderFormAlertItemLabelTitle>
            Product Selected
          </OrderFormAlertItemLabelTitle>
          <OrderFormAlertItemLabelInfo>
            {!!product ? product?.title : "None"}
          </OrderFormAlertItemLabelInfo>
        </OrderFormAlertItemLabel>
        <OrderFormAlertItemIcon>
          {!!product ? checkIcon : warningRedIcon}
        </OrderFormAlertItemIcon>
      </OrderFormAlertItem>
      <OrderFormAlertItem>
        <OrderFormAlertItemLabel>
          <OrderFormAlertItemLabelTitle>
            Project Code
          </OrderFormAlertItemLabelTitle>
          <OrderFormAlertItemLabelInfo>
            <div>{code ?? "None (ex. 'SMITH - 123')"}</div>
            <div>{!code && "Will not sync to Smartsheet"}</div>
          </OrderFormAlertItemLabelInfo>
        </OrderFormAlertItemLabel>
        <OrderFormAlertItemIcon>
          {code ? checkIcon : warningRedIcon}
        </OrderFormAlertItemIcon>
      </OrderFormAlertItem>
      <OrderFormAlertItem>
        <OrderFormAlertItemLabel>
          <OrderFormAlertItemLabelTitle>Address</OrderFormAlertItemLabelTitle>
          <OrderFormAlertItemLabelInfo>
            {Address.getFullAddress(address)}
          </OrderFormAlertItemLabelInfo>
        </OrderFormAlertItemLabel>
        <OrderFormAlertItemIcon>{checkIcon}</OrderFormAlertItemIcon>
      </OrderFormAlertItem>
      <OrderFormAlertItem>
        <OrderFormAlertItemLabel>
          <OrderFormAlertItemLabelTitle>
            Factory Print Number
          </OrderFormAlertItemLabelTitle>
          <OrderFormAlertItemLabelInfo>
            {product?.factoryPrintNumber ?? "Unknown / Undeclared"}
          </OrderFormAlertItemLabelInfo>
        </OrderFormAlertItemLabel>
        <OrderFormAlertItemIcon>
          {product?.factoryPrintNumber ? checkIcon : warningRedIcon}
        </OrderFormAlertItemIcon>
      </OrderFormAlertItem>
      <OrderFormAlertItem>
        <OrderFormAlertItemLabel>
          <OrderFormAlertItemLabelTitle>
            Number of Modules
          </OrderFormAlertItemLabelTitle>
          <OrderFormAlertItemLabelInfo>
            {product?.moduleCount ?? "Unknown / Undeclared"}
          </OrderFormAlertItemLabelInfo>
        </OrderFormAlertItemLabel>
        <OrderFormAlertItemIcon>
          {product?.moduleCount ? checkIcon : warningRedIcon}
        </OrderFormAlertItemIcon>
      </OrderFormAlertItem>
      {unusedModifiers?.length > 0 ? (
        <OrderFormAlertItem>
          <OrderFormAlertItemLabel>
            <OrderFormAlertItemLabelTitle>
              Missing Design Selection(s)
            </OrderFormAlertItemLabelTitle>
            <OrderFormAlertItemLabelInfo>
              {unusedModifiers?.map((modifier: any, index: number) => {
                if (!modifier) {
                  return (
                    <div key={`${modifier?.id}-${index}`}>
                      Unknown | Out-of-date
                    </div>
                  );
                } else {
                  return (
                    <div key={`${modifier?.id}-${index}`}>
                      {modifier?.title ?? "Loading..."}
                    </div>
                  );
                }
              })}
            </OrderFormAlertItemLabelInfo>
          </OrderFormAlertItemLabel>
          <OrderFormAlertItemIcon>{warningOrangeIcon}</OrderFormAlertItemIcon>
        </OrderFormAlertItem>
      ) : (
        <OrderFormAlertItem>
          <OrderFormAlertItemLabel>
            <OrderFormAlertItemLabelTitle>
              Design Selections
            </OrderFormAlertItemLabelTitle>
            <OrderFormAlertItemLabelInfo>
              {!!product ? "Fully represented" : "No product selected"}
            </OrderFormAlertItemLabelInfo>
          </OrderFormAlertItemLabel>
          <OrderFormAlertItemIcon>
            {!!product ? checkIcon : warningRedIcon}
          </OrderFormAlertItemIcon>
        </OrderFormAlertItem>
      )}
    </OrderFormAlertBoxContainer>
  );
};
