import {Customer, ICustomerInfo} from "@natomas-org/core";
import {usePage} from "../../../../_shared/hooks/usePage";
import {ProjectSummaryTextTitle} from "../styles";
import React from "react";

export const SplashScreenWelcomeText = (props: {customer: ICustomerInfo}) => {
  const isNatMobile = usePage();
  const {customer} = props;
  let welcomeText = "Welcome";
  const firstName = Customer.getFirstName(customer);
  if (firstName) {
    welcomeText = isNatMobile
      ? `Welcome, ${firstName}`
      : `Welcome back, ${Customer.getFirstName(customer)}`;
  }
  return <ProjectSummaryTextTitle>{welcomeText}</ProjectSummaryTextTitle>;
};
