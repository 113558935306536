import React from "react";
import {ProductOptionTab} from "../../ProductCatalogView/tabs/ProductOptionTab";
import {FactoriesTab} from "../../ProductCatalogView/tabs/FactoriesTab";
import {ModifierTab} from "../../ProductLineView/tabs/ModifierTab/ModifierTab";
import {CategoryTab} from "../../ProductLineView/tabs/CategoryTab/CategoryTab";
import {ModifierGroupTab} from "../../ProductLineView/tabs/ModifierGroupTab/ModifierGroupTab";
import {ProductsTab} from "../../ProductLineView/tabs/ProductsTab/ProductsTab";
import {OverviewTab} from "../../ProductLineView/tabs/OverviewTab/OverviewTab";
import {MatrixTab} from "../../ProductLineView/tabs/MatrixTab";
import {CustomersTable} from "../../CustomerDatabase/CustomerDatabaseView/tabs/CustomersTable";
import {CustomerInfoTab} from "../../CustomerDatabase/CustomerManagerView/sections/CustomerInfo";
import {CustomerProjectsTable} from "../../CustomerDatabase/CustomerManagerView/sections/CustomerProjectsTable";
import {ImagesTab} from "../../ProductCatalogView/tabs/ImagesTab";
import {ImageTagsTab} from "../../ProductCatalogView/tabs/ImageTagsTab";

export interface AdminPage {
  id: string;
  title: string;
  component: JSX.Element;
  sideBarWidth?: number;
}

export const getTotalTabWidthRems = (page?: AdminPage): number => {
  if (page?.sideBarWidth) {
    return PRIMARY_SIDEBAR_WIDTH + page.sideBarWidth;
  } else {
    return PRIMARY_SIDEBAR_WIDTH;
  }
};

export const convertRemsToPixels = (remUnits: number) => {
  return remUnits * 16;
};

export const getPage = (tabs: AdminPage[], pageId: string | null) => {
  return tabs.find((t: AdminPage) => t.id === pageId);
};

const PRODUCT_LINE_INFO_TAB: AdminPage = {
  id: "overview",
  title: "Product Line Info",
  component: <OverviewTab />,
};

const PRODUCTS_TAB: AdminPage = {
  id: "products",
  title: "Products",
  sideBarWidth: 14,
  component: <ProductsTab widthRems={14} />,
};

const CATEGORIES_TAB: AdminPage = {
  id: "categories",
  title: "Categories",
  sideBarWidth: 14,
  component: <CategoryTab widthRems={14} />,
};

const MODIFIER_GROUPS_TAB: AdminPage = {
  id: "modifierGroups",
  title: "Modifier Groups",
  sideBarWidth: 14,
  component: <ModifierGroupTab widthRems={14} />,
};

const MODIFIERS_TAB: AdminPage = {
  id: "modifiers",
  title: "Modifiers",
  sideBarWidth: 16,
  component: <ModifierTab widthRems={16} />,
};

const MATRIX_TAB: AdminPage = {
  id: "matrix",
  title: "Matrix",
  component: <MatrixTab />,
};

const PRODUCT_LINES_TAB: AdminPage = {
  id: "factories",
  title: "Factories",
  component: <FactoriesTab />,
};

const PRODUCT_OPTIONS_TAB: AdminPage = {
  id: "options",
  title: "Product Options",
  component: <ProductOptionTab />,
};

const CATALOG_IMAGES_TAB: AdminPage = {
  id: "images",
  title: "Images",
  component: <ImagesTab />,
};

const CATALOG_TAGS_TAB: AdminPage = {
  id: "image-tags",
  title: "Image Tags",
  component: <ImageTagsTab />,
};

const CUSTOMER_TABLE_TAB: AdminPage = {
  id: "search-customers",
  title: "Search Customers",
  component: <CustomersTable />,
};

const CUSTOMER_INFO_TAB: AdminPage = {
  id: "customer-info",
  title: "Account Info",
  component: <CustomerInfoTab />,
};

const CUSTOMER_PROJECTS_TAB: AdminPage = {
  id: "customer-projects",
  title: "Projects",
  component: <CustomerProjectsTable />,
};

export const PRIMARY_SIDEBAR_WIDTH = 12;

export const PRODUCT_LINE_TABS = [
  PRODUCT_LINE_INFO_TAB,
  PRODUCTS_TAB,
  CATEGORIES_TAB,
  MODIFIER_GROUPS_TAB,
  MODIFIERS_TAB,
  MATRIX_TAB,
];

export const PRODUCT_CATALOG_TABS = [
  PRODUCT_LINES_TAB,
  PRODUCT_OPTIONS_TAB,
  CATALOG_IMAGES_TAB,
  CATALOG_TAGS_TAB,
];

export const ALL_TABS = [
  PRODUCT_LINE_INFO_TAB,
  PRODUCTS_TAB,
  CATEGORIES_TAB,
  MODIFIER_GROUPS_TAB,
  MODIFIERS_TAB,
  MATRIX_TAB,
  PRODUCT_LINES_TAB,
  PRODUCT_OPTIONS_TAB,
  CUSTOMER_TABLE_TAB,
  CUSTOMER_INFO_TAB,
  CUSTOMER_PROJECTS_TAB,
];
