import {store} from "../../../../store";
import {
  setEditingSelections,
  setStudioConfiguration,
  setStudioSelections,
} from "../../slices/StudioSlice";
import {loadInventoryUnitConfiguration} from "../../../../database/firebase/api/catalog";
import {NavigationPaths} from "../useNavigation/paths";

export const isProjectInventoryUnit = () => {
  const state = store.getState();
  return !!state?.configuration?.fetchedConfiguration?.product?.inventory_info;
};

export const viewInventoryUnitInStudio = (to: any, product?: any) => {
  if (!!product) {
    to(NavigationPaths.STUDIO, {
      productId: product?.id,
      productGroupId: product?.productGroupId,
    });
  }
};

export const getSelectionsFromConfiguration = (selectedModifierObject: any) => {
  if (selectedModifierObject) {
    let selections: {[key: string]: boolean} = {};
    Object.keys(selectedModifierObject)?.forEach((key: string) => {
      selections[key] = true;
    });
    return selections;
  } else return undefined;
};

export const fetchInventoryUnitConfiguration = async (product: any) => {
  await loadInventoryUnitConfiguration(product?.id).then(
    (inventoryUnitConfiguration: any) => {
      store.dispatch(setStudioConfiguration(inventoryUnitConfiguration));
      const selections = getSelectionsFromConfiguration(
        inventoryUnitConfiguration?.selectedModifiers
      );
      if (selections) {
        store.dispatch(setEditingSelections(false));
        store.dispatch(setStudioSelections(selections));
      }
    }
  );
};
