import React, {useState} from "react";
import {AiOutlineZoomIn, AiOutlineZoomOut} from "react-icons/ai";
import {BLACK} from "../../../../../colors";
import {NatNumberSlider} from "../../../ImageAssignmentManager/ImageAssignmentOptions/styles";
import {useImageFinder} from "../../../../../hooks/useImageFinder";

export const ZoomController = () => {
  const {zoomModifier, setImageFinderZoomModifier} = useImageFinder();
  const [defaultZoom] = useState(zoomModifier);

  const updateZoomModifier = (zoom: number) => {
    if (zoom !== zoomModifier) {
      setImageFinderZoomModifier(zoom);
    }
  };

  return (
    <>
      <AiOutlineZoomOut color={BLACK} />
      <div style={{width: "6rem", height: "30px"}}>
        <NatNumberSlider
          aria-label="Zoom"
          defaultValue={defaultZoom}
          onChange={(e: any) => updateZoomModifier(e?.target?.value)}
          getAriaValueText={() => "Zoom"}
          step={1}
          marks
          min={0}
          max={4}
        />
      </div>
      <AiOutlineZoomIn color={BLACK} />
    </>
  );
};
