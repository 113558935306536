import styled from "styled-components";
import {APP_HEADER_HEIGHT} from "../../../../map/constants";

export const MapViewContainer = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  pointer-events: all;
`;
export const MapViewLoading = styled.div`
  height: calc(100vh - ${APP_HEADER_HEIGHT});
  width: 100%;
  opacity: 0.4;
  position: relative;
  background: rgba(108, 175, 223, 0.4);
  z-index: 10000000;
`;
export const MapViewSidebar = styled.div`
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
`;
