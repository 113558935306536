import {createSlice} from "@reduxjs/toolkit";
import {IIntakeFormSliceState} from "../../_shared/slices/types/Store";
import {getParameter} from "../../_shared/cookies";
import {INTAKE_FORM_REDIRECT_TYPE} from "../constants";

const getInitialState = (): IIntakeFormSliceState => {
  const redirectType =
    (getParameter("intake_redirect_type") as INTAKE_FORM_REDIRECT_TYPE) ??
    INTAKE_FORM_REDIRECT_TYPE.DASHBOARD;
  const redirectURL = getParameter("intake_redirect_url");

  return {
    step: "",
    redirect_type: redirectType,
    redirect_url: redirectURL,
  };
};

const intakeFormSlice = createSlice({
  name: "intake",
  initialState: getInitialState(),
  reducers: {
    resetIntakeForm: (state: IIntakeFormSliceState) => {
      const initialState = getInitialState();
      Object.keys(initialState).forEach((k: string) => {
        // @ts-ignore
        state[k] = initialState[k];
      });
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
  },
});

export const intakeFormReducer = intakeFormSlice.reducer;
export const {setStep, resetIntakeForm} = intakeFormSlice.actions;
