import {useSelector} from "react-redux";
import {IStore} from "../../../slices/types/Store";
import {ICatalogSessionSlice} from "../../../slices/CatalogSlice/interfaces";
import {ProjectPriceMode} from "@natomas-org/core";
import useActiveDesign from "../../useDesign/useActiveDesign";

export const useCatalogSession = (): ICatalogSessionSlice => {
  const sessionSlice = useSelector((state: IStore) => state.catalogSession);
  const {id} = useActiveDesign();
  const priceMode = sessionSlice?.priceMode;
  return {
    ...sessionSlice,
    allInEstimateCatalog:
      sessionSlice.allInEstimateCatalog &&
      (priceMode === ProjectPriceMode.DTC || !id),
  };
};
