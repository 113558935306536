import React from "react";
import {IProduct} from "@natomas-org/core";
//Shared
import {sort} from "../../utilities";
import {useGlobalConfiguration} from "../../../../_shared/hooks/useGlobalConfiguration";
import {RecommendedCardContainer} from "./styles";
import {ProductCard} from "../ProductCard/ProductCard";
import {isProductEligible} from "../../../../_shared/hooks/useMappingFeatures/helper";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {useProductRecommendations} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useProductRecommendations";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

export const RecommendedProductCards = (props: {
  completeDiscovery: () => void;
  products: IProduct[];
}) => {
  const {isAdmin} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const {products} = props;
  const {PRICING_MODE} = useGlobalConfiguration();
  const {eligibility, adminCatalogMode} = useCatalogSession();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const percentRecommendation = useProductRecommendations();
  const {projectPriceMode} = useActiveProject();
  return (
    <RecommendedCardContainer>
      {sort(products, PRICING_MODE).map((product: IProduct) => {
        return (
          <ProductCard
            percentRecommendation={
              percentRecommendation[product.id ?? -1]?.percentScore ?? -1
            }
            key={"product-card-" + product.id}
            isEligible={
              isAdmin &&
              adminCatalogMode &&
              isProductEligible(
                eligibility,
                product?.productGroupId,
                product?.id
              )
            }
            product={product}
            isCustomerPreDeposit={isCustomerPreDeposit || !customer}
          />
        );
      })}
    </RecommendedCardContainer>
  );
};
