import {isBlankString} from "@natomas-org/core";

export const getDescriptionForModifier = (modifier: any) => {
  if (!isBlankString(modifier.displayDescription)) {
    return modifier.displayDescription;
  } else if (!isBlankString(modifier.displayDescriptionDefault)) {
    return modifier.displayDescriptionDefault;
  } else if (!isBlankString(modifier.prettyDescription)) {
    return modifier.prettyDescription;
  }
  return "";
};
