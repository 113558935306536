import {createSlice} from "@reduxjs/toolkit";
import {getParameter} from "../../cookies";
import {IConfigurationSlice} from "../types/Store";
import {
  CONFIGURATION_ID_URL_KEY,
  PROJECT_ID_URL_KEY,
} from "../../../../database/firebase/configuration/configuration";

const getInitialState = (): IConfigurationSlice => {
  return {
    editingSelections: true,
    showEditingConfirmation: false,
    showConfirmation: false,
    fetchedProjectSummary: undefined,
    fetchedProjectOpportunity: undefined,
    fetchedProjectSmartsheet: undefined,
    fetchedProjectNotes: undefined,
    fetchedProjectDocuments: undefined,
    fetchedConfiguration: null,
    fetchedConfigurationSnapshot: null,
    fetchedConfigurationMap: null,
    fetchedConfigurationSite: undefined,
    currentConfigurationId:
      getParameter(CONFIGURATION_ID_URL_KEY) ??
      getParameter(PROJECT_ID_URL_KEY),
  };
};

const configurationSlice = createSlice({
  name: "configuration",
  initialState: getInitialState(),
  reducers: {
    setShowEditingConfirmation: (state, action) => {
      state.showEditingConfirmation = action.payload;
    },
    setEditingSelections: (state, action) => {
      state.editingSelections = action.payload;
    },
    setConfigurationId: (state, action) => {
      state.currentConfigurationId = action.payload;
    },
    setShowConfirmation: (state, action) => {
      state.showConfirmation = action.payload;
    },
    loadedConfiguration: (state, action) => {
      state.fetchedConfiguration = action.payload;
    },
    loadedProjectSummary: (state, action) => {
      state.fetchedProjectSummary = action.payload;
    },
    loadedProjectOpportunity: (state, action) => {
      state.fetchedProjectOpportunity = action.payload;
    },
    loadedProjectSmartsheet: (state, action) => {
      state.fetchedProjectSmartsheet = action.payload;
    },
    loadedProjectNotes: (state, action) => {
      state.fetchedProjectNotes = {
        ...state.fetchedProjectNotes,
        ...action.payload,
      };
    },
    loadedProjectDocuments: (state, action) => {
      state.fetchedProjectDocuments = {
        ...state.fetchedProjectDocuments,
        ...action.payload,
      };
    },
    loadedConfigurationSnapshot: (state, action) => {
      state.fetchedConfigurationSnapshot = action.payload;
    },
    loadedConfigurationMap: (state, action) => {
      state.fetchedConfigurationMap = action.payload;
    },
    loadedConfigurationSite: (state, action) => {
      state.fetchedConfigurationSite = action.payload;
    },
    resetConfiguration: (state) => {
      state.fetchedProjectSummary = undefined;
      state.fetchedProjectOpportunity = undefined;
      state.fetchedProjectSmartsheet = undefined;
      state.fetchedProjectNotes = undefined;
      state.fetchedProjectDocuments = undefined;
      state.fetchedConfiguration = null;
      state.fetchedConfigurationSnapshot = null;
      state.fetchedConfigurationMap = null;
      state.fetchedConfigurationSite = undefined;
      state.currentConfigurationId = null;
    },
  },
});

export const configurationReducer = configurationSlice.reducer;
export const {
  resetConfiguration,
  setConfigurationId,
  setShowConfirmation,
  loadedConfiguration,
  setEditingSelections,
  loadedProjectSummary,
  loadedProjectOpportunity,
  loadedProjectSmartsheet,
  loadedProjectNotes,
  loadedProjectDocuments,
  loadedConfigurationSnapshot,
  loadedConfigurationMap,
  loadedConfigurationSite,
} = configurationSlice.actions;
