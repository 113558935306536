import React from "react";
import SSCRichTextDivRow from "../../_shared/SSCRichTextDivRow/SSCRichTextDivRow";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";

interface SSCCustomOptionsRowProps {
  primaryContent: string;
  otherContent?: string;
}

const SSCStandardFeaturesRow = (props: SSCCustomOptionsRowProps) => {
  const {otherContent, primaryContent} = props;

  return (
    <SSCRichTextDivRow
      label={"Standard Features"}
      primaryContent={primaryContent}
      otherContent={otherContent}
    />
  );
};

export default SSCStandardFeaturesRow;
