import React, {useMemo} from "react";
import {TableRowContainer} from "../../../_shared/components/AdminTable";
import {ITableCell} from "../../../_shared/components/AdminTable/table";
import {getDateString} from "../../../_shared/_helpers";
import {useEmployeeInfo} from "../../../../_shared/hooks/useEmployeeInfo";

export interface ImageTagsTableRowProps {
  title: string | null;
  authorId: string | null;
  timestamp: number | null;
}

export const ImageTagsTableRow = (props: {tagInfo: ImageTagsTableRowProps}) => {
  const {title, authorId, timestamp} = props?.tagInfo;
  const authInfo = useEmployeeInfo(authorId ?? undefined);

  const cells: ITableCell[] = useMemo(() => {
    return [
      {
        bold: true,
        content: title ?? "Unknown",
        center: true,
      },
      {
        content: timestamp ? getDateString(timestamp, true) : "Unknown",
      },
      {
        content: authInfo?.displayName ?? "Unknown",
      },
    ];
  }, [title, authorId, timestamp]);

  return (
    <TableRowContainer
      key={title}
      keyId={title + "-container-"}
      cells={cells}
    />
  );
};
