import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../SSDDashboardView/styles/globals";

export const MainContainer = styled.div`
  margin-bottom: 0;
  break-inside: auto;
  page-break-inside: auto;
  page-break-before: avoid;
  break-before: avoid;
`;
export const TableContainer = styled.div`
  width: 100%;
  break-inside: auto;
  page-break-inside: auto;
`;

export const HeaderRowContainer = styled.div<{disableSticky: boolean}>`
  page-break-before: avoid;
  break-before: avoid;
  height: 50px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: ${(props) => (props.disableSticky ? "relative" : "sticky")};
  top: 0;
  display: flex;
  background-color: #eaeaea;
  z-index: 10;
  font-family: ${FONT_FAMILY_BOLD};
`;
export const Opaque = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const RowContainer = styled.div<{isNatMobile: boolean}>`
  min-height: 50px;
  width: 100%;
  min-width: fit-content;
  ${(props) =>
    props.isNatMobile ? "" : "border-top: solid rgba(0, 0, 0, 0.1) 1px;"};
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: inherit;
  font-size: 16px;
  break-inside: auto;
  page-break-inside: auto;
  page-break-after: auto;
  break-after: auto;
`;

export const ColumnText = styled.div`
  position: relative;
  margin: 10px;
  margin-left: 30px;
  min-width: 160px;
`;

export const Column = styled.div<{width?: string}>`
  min-height: inherit;
  min-width: ${(props) => props.width ?? "160px"};
  width: inherit;
  height: inherit;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
`;

export const SecondColumn = styled.div`
  min-height: 100%;
  min-width: 160px;
  max-width: 200px;
  width: 15%;
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-left: solid rgba(0, 0, 0, 0.1) 1px;
`;

export const ThirdColumn = styled.div<{multipleItems?: boolean}>`
  border-left: solid rgba(0, 0, 0, 0.1) 1px;
  min-height: 100%;
  min-width: 160px;
  flex-grow: 2;
  max-width: 50%;
  width: 30%;
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: ${(props) => (props.multipleItems ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.multipleItems ? "column" : undefined)};
  gap: ${(props) => (props.multipleItems ? "0" : undefined)};
`;

export const ErrorDiv = styled.div`
  position: relative;
  color: red;
`;
