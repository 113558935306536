import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../SSDDashboardView/styles/globals";

export const HCPCalendarEventContainer = styled.div<{disabled: boolean}>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  overflow: hidden;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 1.5rem;
  position: relative;
`;

export const HCPCalendarDivider = styled.div`
  background-color: #0000004d;
  height: 2px;
  width: 100%;
  margin-top: 1rem;
`;

export const HCPCalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HCPCalendarStatusAndTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
`;

export const HCPCalendarTop = styled.div``;

export const HCPCalendarBottom = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

export const HCPCalendarTextContainer = styled.div`
  margin-top: 1rem;
  text-align: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const HCPCalendarTitle = styled.div`
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: 100%;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const HCPCalendarTextLine = styled.div`
  font-size: 0.9rem;
  padding-top: 0.25rem;
  display: flex;
  gap: 0.2rem;
`;

export const HCPCalendarTextRegular = styled.div``;

export const HCPCalendarTextBold = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
`;
