import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {calculatePadding, NatSize} from "../../../../_shared/generics/_shared";
import {PASTEL_GREEN} from "../../../../_shared/colors";

const ProductCardInfoRow = styled.div<{
  fontSize: string;
  bold?: boolean;
  isAdminView?: boolean;
}>`
  font-size: ${(props) => props.fontSize};
  font-family: ${(props) =>
    props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_MEDIUM};
  font-style: normal;
  align-content: center;
  font-weight: normal;
  margin-bottom: 0.25rem;
  color: ${(props) => (props.isAdminView ? "#2196f3" : "#555555")};
  display: flex;
`;

const ProductCardImage = styled.div<{height?: string}>`
  display: grid;
  min-height: ${(props) => props?.height ?? props.theme?.Card?.imageHeight};
  max-height: ${(props) => props?.height ?? props.theme?.Card?.imageHeight};
  position: relative;
  overflow: hidden;
  transition: height 150ms ease-in-out;
`;

const HoverOverImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 15;

  .fullDiv {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }

  &:hover {
    background-color: white;
  }

  &:hover .fullDiv {
    opacity: 1;
  }
`;

const ProductContents = styled.div<{
  shadowColor?: string;
  hoverEnabled?: boolean;
}>`
  background-color: white;
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: ${(props) => props.theme?.Card?.borderRadius ?? "1rem"};
  box-shadow: ${(props) =>
    ` ${
      props.shadowColor
        ? `0 3px 6px ${props.shadowColor}`
        : `0 3px 6px rgba(0, 0, 0, 0.2)`
    }`};
  ${(props) =>
    (props.hoverEnabled ?? props.theme?.Page?.columnCount > 4) &&
    !!props.shadowColor
      ? `
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {

      box-shadow: 0 1px 1px  ${props.shadowColor};
    }`
      : props.hoverEnabled ?? props.theme?.Page?.columnCount > 4
      ? `
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 1px 1.25px rgba(0, 0, 0, 0.16);
    }
`
      : ``};
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
`;

/* outline: 1px solid ${props.shadowColor};
      outline-offset: -1px;
      outline-color: ${props.shadowColor};*/
export const ProductCardTagContainer = styled.div<{
  color: string;
  size: NatSize;
  displayLeft?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}>`
  position: relative;
  z-index: 100;
  ${(props) =>
    props.displayLeft
      ? `border-radius: ${props.isFirst ? "1rem" : "0"} 0 ${
          props.isLast ? "1rem" : "0"
        } 0;`
      : `border-radius: 0 ${props.isFirst ? "1rem" : "0"} 0 ${
          props.isLast ? "1rem" : "0"
        };`}
  top: 0;

  transition: all 200ms ease-in-out, transform 200ms ease-in-out,
    width 200ms ease-in-out;
  ${(props) => (props.displayLeft ? "left: inherit" : "right: 0")}
  row-gap: -0.25rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  background-color: ${(props) => props.color};
  overflow: hidden;
  color: white;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 0.5rem;
  padding: ${(props) => calculatePadding(props.size)};
  width: max-content;
  height: 2.15rem;
  min-height: 2.15rem;
  max-height: 2.15rem;
  font-size: 0;
  padding-right: 4px;
  padding-left: 10px;

  &:hover {
    transition: all 200ms ease-in-out, transform 200ms ease-in-out,
      width 200ms ease-in-out;
    min-height: 2.15rem;
    max-height: 2.15rem;
    font-size: ${(props) => props.size};
    padding-right: ${(props) => props.size};
  }
`;

export const IconTooltipText = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
`;
export const CardTag = styled.div`
  background-color: ${PASTEL_GREEN};
  position: relative;
  width: fit-content;
  z-index: 16;
  font-size: 12px;
  border-radius: 5px;
  padding: 4px;
`;

export {HoverOverImage, ProductCardImage, ProductCardInfoRow, ProductContents};
