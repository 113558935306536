import {
  CustomerProjectInfo,
  ICustomerInfo,
  IEventInfo,
  INITIAL_DEPOSIT_PAYMENT_EVENT_ID,
  PHASE_1_PAYMENT_EVENT_ID,
  PHASE_1_PAYMENT_FOUR_THOUSAND_EVENT_ID,
  PHASE_1_PAYMENT_TWO_THOUSAND_EVENT_ID,
  PSA_SIGNED_EVENT_ID,
} from "@natomas-org/core";

export const isPSASigned = (project: CustomerProjectInfo | null) => {
  return getEventObject(project, PSA_SIGNED_EVENT_ID) != null;
};

export const getEventObject = (
  project: CustomerProjectInfo | null,
  eventId: string
): IEventInfo | null => {
  let returnEvent = null;
  project?.events?.forEach((event) => {
    if (event?.event_id === eventId) {
      returnEvent = event;
    }
  });
  return returnEvent;
};

export const getEventObjectFromUser = (
  user: ICustomerInfo | null,
  eventId: string
) => {
  let returnEvent = null;
  user?.events?.forEach((event) => {
    if (event?.event_id === eventId) {
      returnEvent = event;
    }
  });
  return returnEvent;
};

export const getPhase1PaymentAmount = (project: CustomerProjectInfo | null) => {
  const phase1PaymentEvent = getPhase1PaymentEvent(project);
  if (phase1PaymentEvent?.event_id === PHASE_1_PAYMENT_EVENT_ID) {
    return 950000;
  } else if (
    phase1PaymentEvent?.event_id === PHASE_1_PAYMENT_FOUR_THOUSAND_EVENT_ID ||
    project?.settings?.pricing_mode?.complex_pricing_enabled
  ) {
    return 400000;
  } else {
    return 200000;
  }
};

const getPhase1PaymentEvent = (project: CustomerProjectInfo | null) => {
  return (
    getEventObject(project, PHASE_1_PAYMENT_EVENT_ID) ||
    getEventObject(project, PHASE_1_PAYMENT_TWO_THOUSAND_EVENT_ID) ||
    getEventObject(project, PHASE_1_PAYMENT_FOUR_THOUSAND_EVENT_ID)
  );
};

export const isPhase1Complete = (project: CustomerProjectInfo | null) => {
  return getPhase1PaymentEvent(project) != null;
};

export const isInitialDepositPaid = (user: ICustomerInfo | null) => {
  return getEventObjectFromUser(user, INITIAL_DEPOSIT_PAYMENT_EVENT_ID) != null;
};
