import React from "react";
import {
  PageContainerBackgroundImage,
  PageContainerRoot,
  PageContainerWrapper,
  PageContent,
} from "./styles";
import {usePage} from "../../../../hooks/usePage";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../hooks/useDynamicValue";

export interface PageContainerOptionalProps {
  marginTop?: string;
  border?: boolean;
  backgroundColor?: string;
  backgroundImage?: PageContainerBackgroundImageOptions;
  stickyOptions?: PageContainerStickyOptions;
  overridePadding?: boolean;
  overrideAnimation?: boolean;
  overflow?: string;
  style?: React.CSSProperties;
  FLEX?: boolean;
  GROW?: boolean;
}

interface PageContainerProps extends PageContainerOptionalProps {
  children: any;
}

export enum PageContainerStickyMode {
  HEADER = "HEADER",
  FOOTER = "FOOTER",
  MODAL = "MODAL",
}

export interface PageContainerStickyOptions {
  height?: string;
  mode?: PageContainerStickyMode;
  offset?: string;
  zIndex?: number | string;
}

interface PageContainerBackgroundImageOptions {
  height: string;
  image: string;
}

export const PageContainer = (props: PageContainerProps) => {
  const {
    children,
    backgroundColor,
    backgroundImage,
    border,
    stickyOptions,
    marginTop,
    overridePadding,
    overrideAnimation,
    overflow,
  } = props;
  const {containerWidth, containerPadding, pageWidth} = usePage();
  const fullWidth = useDynamicValue(fullColumnWidths);

  const calculateTop = () => {
    if (stickyOptions?.mode === PageContainerStickyMode.HEADER) {
      return "0";
    } else if (stickyOptions?.mode === PageContainerStickyMode.MODAL) {
      return stickyOptions.offset;
    }
  };

  if (
    children &&
    containerWidth &&
    containerPadding &&
    pageWidth &&
    fullWidth
  ) {
    return (
      <>
        <PageContainerRoot
          top={calculateTop()}
          sticky={stickyOptions}
          marginTop={marginTop}
          overflow={overflow}
          style={{...props.style, display: props.FLEX ? "flex" : undefined}}
        >
          {backgroundImage && (
            <PageContainerBackgroundImage
              image={backgroundImage.image}
              width={containerWidth}
              height={backgroundImage.height}
            />
          )}
          <PageContainerWrapper
            sticky={stickyOptions}
            padding={!overridePadding ? containerPadding : 0}
            backgroundColor={backgroundColor}
            border={border}
            width={containerWidth}
            overrideAnimation={overrideAnimation || true}
            style={props.GROW ? {flexGrow: 1} : undefined}
          >
            <PageContent
              enabled={pageWidth > 0}
              width={!overridePadding ? pageWidth + "px" : "100%"}
              style={props.GROW ? {height: "100%"} : undefined}
            >
              {children}
            </PageContent>
          </PageContainerWrapper>
        </PageContainerRoot>
      </>
    );
  } else return <></>;
};
