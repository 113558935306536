import styled from "styled-components";

export const DrawerToggleArrowWrapper = styled.div<{expanded: boolean}>`
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    transform: ${(props) => (props.expanded ? "rotate(90deg)" : "")};
    transition: transform 75ms ease-in-out;
  }
`;
