import styled from "styled-components";

export const OrderFormTableContainer = styled.div`
  max-width: calc(100% - 20rem);
`;

export const OrderFormTableRow = styled.tr``;

export const OrderFormTableHeader = styled.th`
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  :last-child {
    border-right: 0;
  }
`;

export const OrderFormTableCellContainer = styled.td`
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  :last-child {
    border-right: 0;
  }
`;
