import React from "react";
import Image from "../../../2-component/Image";
import logo from "./villa-logo-cropped.svg";

const Logo = () => {
  return (
    <Image
      src={logo}
      alt={"Villa Homes"}
      style={{
        width: "100px",
        margin: "32px auto 32px auto",
        display: "block",
      }}
    />
  );
};

export default Logo;
