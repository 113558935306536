import React, {useEffect, useState} from "react";
import VCL from "@natomas-org/villa-component-library";
import {useNavigation} from "../../../components/_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../components/_shared/hooks/useNavigation/paths";

const FormRedirect = (props: {context: string}) => {
  const [countdown, setCountdown] = useState(7); // Starting the countdown from 10
  const {to} = useNavigation();

  useEffect(() => {
    // Exit early when countdown reaches 0
    if (countdown === 0) {
      // Redirect logic here. For React Router:
      to(NavigationPaths.CATALOG);
      // Or for a hard redirect: window.location.href = 'https://example.com';
      return;
    }

    // Decrease countdown every second
    const timerId = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);

    // Cleanup the timer
    return () => clearTimeout(timerId);
  }, [countdown, to]); // Dependencies: countdown changes, re-run the effect

  return (
    <>
      <VCL.Components.Atoms.Header properties={{children: props.context}} />
      <VCL.Components.Atoms.Text
        text={`Browsing homes in ${countdown} second${
          countdown === 1 ? "" : "s"
        }`}
      />
      <VCL.Components.Atoms.Disclaimer
        html={
          [
            `Visit <a style="text-decoration: underline" href="https://villahomes.com">villahomes.com</a>`,
          ] as unknown as TrustedHTML[]
        }
      />
    </>
  );
};

export default FormRedirect;
