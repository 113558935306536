import React, {useState} from "react";
import {DocumentWrapper, PDFDateContainer, PDFTitleContainer} from "../styles";
import {
  DeleteDocumentIcon,
  DocumentDownloadIcon,
  DocumentLink,
  EditButtonWithIcon,
  HideDocumentIcon,
} from "./index";
import {getDocumentUploadDateAndSizeString} from "../helpers";
import {IDocumentDetails, IGCloudFileStorageDetails} from "@natomas-org/core";
import {DocumentFilePreviewer} from "./DocumentFilePreviewer";
import {NatFlex} from "../../flex/NatFlex";
import {NatJustifyContent, NatVerticalAlign, StyleOption} from "../../_shared";
import NatLabel, {NatLabelType, NatLabelWeight} from "../../label/NatLabel";
import {downloadFileToDesktop} from "../../../../portal/views/SitePlanView/logic";
import {NatDownloadIcon} from "../../../icon/icons";
import {TaskAndDocumentContainer} from "../../../../portal/views/ProjectTasks/ProjectTaskListItem";
import {VILLA_INFORMATIONAL, VILLA_PERIWINKLE} from "../../../colors";
import {storage} from "../../../../../database/firebase";
import {NatLink} from "../../button";

const DocumentTitle = ({
  document,
  documents,
  hidePreview,
  fileFetchTitle,
  styleOption,
}: {
  styleOption?: StyleOption;
  fileFetchTitle?: string;
  hidePreview?: boolean;
  document: IDocumentDetails;
  documents?: IDocumentDetails[];
}) => {
  const [show, setShow] = useState(false);
  if (styleOption === StyleOption.PRIMARY) {
    return (
      <>
        {show && (
          <DocumentFilePreviewer
            type={"full-screen"}
            document={document}
            documentList={documents}
            fileFetchTitle={fileFetchTitle}
            presentation={{
              show: show,
              close: () => {
                setShow(false);
              },
            }}
          />
        )}
        <TaskAndDocumentContainer isHidden={document?.hidden}>
          <NatFlex JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}>
            <NatLink
              label={document?.title}
              option={StyleOption.PRIMARY_LINK}
              clickEvent={() => {
                setShow(true);
              }}
            />

            <NatLabel
              label={
                <NatFlex
                  VERTICAL_ALIGN={NatVerticalAlign.CENTER}
                  ROW
                  NOWRAP
                  style={{
                    padding: "0 0",
                    margin: "0 0",
                    columnGap: "0.75rem",
                    cursor: "pointer",
                    height: "100%",
                  }}
                >
                  Download File
                  <NatDownloadIcon size={26} />
                </NatFlex>
              }
              onClick={() => {
                return storage
                  .ref(
                    (document?.details as IGCloudFileStorageDetails)
                      ?.storageRefPath ?? document?.url
                  )
                  .getDownloadURL()
                  .then((url: string) => {
                    return downloadFileToDesktop(
                      url,
                      document?.filename ?? document?.title
                    );
                  });
              }}
              type={NatLabelType.P1}
              weight={NatLabelWeight.BOLD}
              hoverColor={VILLA_INFORMATIONAL}
              color={VILLA_PERIWINKLE}
              style={{
                margin: "0 0",
                padding: "0 0",
              }}
            />
          </NatFlex>
        </TaskAndDocumentContainer>
      </>
    );
  }

  return (
    <NatFlex style={{flexGrow: 1}}>
      {show && (
        <DocumentFilePreviewer
          type={"full-screen"}
          document={document}
          documentList={documents}
          fileFetchTitle={fileFetchTitle}
          presentation={{
            show: show,
            close: () => {
              setShow(false);
            },
          }}
        />
      )}
      <DocumentFilePreviewer
        hidden={hidePreview}
        fileFetchTitle={fileFetchTitle}
        type={"thumbnail"}
        documentList={documents}
        document={document}
      />
      <div>
        <DocumentLink
          documentDetails={document}
          onClick={() => {
            setShow(true);
          }}
        />
        {!styleOption && (
          <PDFDateContainer>
            {getDocumentUploadDateAndSizeString(document)}
          </PDFDateContainer>
        )}
      </div>
    </NatFlex>
  );
};

const DocumentActions = ({
  document,
  projectId,
  onDelete,
  onHide,
  onEdit,
}: {
  document: IDocumentDetails;
  projectId: string;
  onHide?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
}) => (
  <>
    <DocumentDownloadIcon document={document} />
    {onHide && (
      <HideDocumentIcon
        currentDocument={document}
        projectId={projectId}
        onHide={onHide}
      />
    )}
    {onEdit && (
      <span>
        <EditButtonWithIcon onClick={onEdit} tooltipTitle={"Edit document"} />
      </span>
    )}
    {onDelete && (
      <span>
        <DeleteDocumentIcon
          projectId={projectId}
          currentDocument={document}
          onComplete={onDelete}
        />
      </span>
    )}
  </>
);

export const DocumentDetailsPreview = ({
  document,
  projectId,
  onDelete,
  onHide,
  documents,
  onEdit,
  hidePreview,
  fileFetchTitle,
  styleOption,
}: {
  hidePreview?: boolean;
  styleOption?: StyleOption;
  document: IDocumentDetails;
  documents?: IDocumentDetails[];
  projectId: string;
  onHide?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  fileFetchTitle?: string;
}) => {
  if (styleOption === StyleOption.PRIMARY) {
    return (
      <DocumentTitle
        fileFetchTitle={fileFetchTitle}
        hidePreview={hidePreview}
        document={document}
        documents={documents}
        styleOption={styleOption}
      />
    );
  }
  return (
    <DocumentWrapper>
      <PDFTitleContainer>
        <DocumentTitle
          fileFetchTitle={fileFetchTitle}
          hidePreview={hidePreview}
          document={document}
          documents={documents}
        />
        <DocumentActions
          document={document}
          projectId={projectId}
          onHide={onHide}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      </PDFTitleContainer>
    </DocumentWrapper>
  );
};
