import {INVENTORY_UNIT_PREFIX} from "../../../../../../../_shared/constants/labels";

let leadingColumns = [
  {
    Header: "Actions",
    accessor: "id",
    disableSortBy: true,
    locked: true,
  },
  {
    Header: "Internal Title",
    accessor: "internalTitle",
  },
  {
    Header: "Title",
    accessor: "title",
  },
];

let pricingColumns = [
  {
    Header: "Pricing Summary",
    columns: [
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Cost",
        accessor: "cost",
      },
      {
        Header: "Margin ($)",
        accessor: "margin",
      },
      {
        Header: "Margin (%)",
        accessor: "marginPercentage",
      },
    ],
  },
  {
    Header: "Price",
    columns: [
      {
        Header: "Unit Price",
        accessor: "unitPrice",
      },
      {
        Header: "Upgrade(s) Price",
        accessor: "upgradePrice",
      },
    ],
  },
  {
    Header: "Cost",
    columns: [
      {
        Header: "Factory Cost",
        accessor: "factoryCost",
      },
      {
        Header: "Sales Tax",
        accessor: "salesTax",
      },
    ],
  },
];

let info = [
  {
    Header: "Created",
    accessor: "createdDateTime",
  },
  {
    Header: "Last Updated",
    accessor: "updatedDateTime",
  },
  {
    Header: "Status",
    accessor: "status",
    filter: (rows: any[], columnIds: ["status"], filterValue?: number[]) => {
      if (!filterValue) {
        return rows;
      }
      return rows?.filter((row: any) => {
        return filterValue?.includes(row?.original?.status);
      });
    },
  },
];
const factorySummary = [
  {
    Header: "Age",
    accessor: "daysSinceStorageDateTime",
  },
  {
    Header: "Storage Date",
    accessor: "storageDateTime",
  },
  {
    Header: "Production Date",
    accessor: "productionDateTime",
  },
  {
    Header: "Serial Number",
    accessor: "serialNumber",
  },
  {
    Header: "Sales Quote / Blacklines",
    accessor: "blacklinesUrl",
    disableSortBy: true,
  },
];

export const getTableColumns = (showFactoryColumn?: boolean) => {
  let c = [...leadingColumns];
  if (showFactoryColumn) {
    c.push({
      Header: "Factory",
      accessor: "factory",
    });
  }
  return [
    {
      Header: `${INVENTORY_UNIT_PREFIX}s`,
      columns: c,
    },
    {
      Header: "Info",
      columns: info,
    },
    {
      Header: "Factory Summary",
      columns: factorySummary,
    },
    ...pricingColumns,
  ];
};
