import {getProductPricingInfo} from "../../../_shared/_legacy/logic/ProductUtils";
import {PricingMode} from "../../../_shared/slices/GlobalSlice";

export enum SortModes {
  DEFAULT,
}

/*
 * Default sorting of the products is by price, and then title as a tie-settler.
 * */
const defaultSort = (products: any[], priceMode: PricingMode) => {
  return products.sort(function (a: any, b: any) {
    let priceA = getProductPricingInfo(priceMode, a)?.price ?? -1;
    let priceB = getProductPricingInfo(priceMode, b)?.price ?? -1;

    if (priceA === priceB) return a.title.localeCompare(b.title);
    else return priceA - priceB;
  });
};

export const sort = (
  products: any[] = [],
  priceMode: PricingMode,
  mode: SortModes = SortModes.DEFAULT
) => {
  if (mode === SortModes.DEFAULT) {
    return defaultSort(products, priceMode);
  } else {
    return products;
  }
};
