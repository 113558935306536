import {IProjectTimeline, ProjectTimelineStage} from "@natomas-org/core";
import {
  BLACK,
  VILLA_ATB,
  VILLA_TIMELINE_INSTALLATION,
  VILLA_TIMELINE_MANUFACTURING,
  VILLA_TIMELINE_PERMITTING_AND_DESIGN,
  VILLA_TIMELINE_PROPOSAL,
  WHITE,
} from "../../../../../../../_shared/colors";

import {B2B_TIMELINE_INFO} from "../../../../../../../portal/views/ProposalView/constants/TimelineInfoConstants";

export function getColor(stage: ProjectTimelineStage): string {
  switch (stage) {
    case ProjectTimelineStage.ProjectProposal:
      return VILLA_TIMELINE_PROPOSAL;
    case ProjectTimelineStage.PermittingAndDesign:
      return VILLA_TIMELINE_PERMITTING_AND_DESIGN;
    case ProjectTimelineStage.AuthorizationToBuild:
      return VILLA_ATB;
    case ProjectTimelineStage.Manufacturing:
      return VILLA_TIMELINE_MANUFACTURING;
    case ProjectTimelineStage.Installation:
      return VILLA_TIMELINE_INSTALLATION;
    default:
      return WHITE;
  }
}

export const timelineOrder = [
  ProjectTimelineStage.ProjectProposal,
  ProjectTimelineStage.PermittingAndDesign,
  ProjectTimelineStage.AuthorizationToBuild,
  ProjectTimelineStage.Manufacturing,
  ProjectTimelineStage.Installation,
];
export const timelineDescriptions = [
  `We help you finalize your home choice, design selections and budget.`,
  `Your permits are prepared and submitted by our preconstruction team.`,
  `We will present the preconstruction buyout and a not to exceed quote.`,
  `Our factory partner builds your home while we prepare your backyard.`,
  `We handle the delivery, install and final touches. Then hand you the keys!`,
];

export interface B2BTimelineChartDisplayOptions {
  showSectionTitles: boolean;
  showLabelsOnBars: boolean;
  showTooltip: boolean;
  showLegend: boolean;
  displayQuarters: boolean;
  expandMilestones?: boolean;
}

interface INextStep {
  color: string;
  endDate?: Date;
  startDate?: Date;
  details?: string | undefined;
  subtitle?: string | undefined;
  title?: string | undefined;
  id?: string | undefined;
  milestones?: any[] | undefined;
  phase?: {
    additionalSteps: number;
    endColor: string;
    phaseEndDate: Date;
    title: string;
  };
}

export function getTimelinePropsFromProjectTimelineDictionary(data: {
  [key: string]: IProjectTimeline;
}): {
  nextStepsInfo: INextStep[];
  actualTimeline: boolean;
  overallEndDate: Date;
  startDate: Date;
  totalMonths: number;
  displayQuarters: boolean;
} {
  const newNextSteps: {
    color: string;
    endDate?: Date;
    startDate?: Date;
    details?: string | undefined;
    subtitle?: string | undefined;
    title?: string | undefined;
    id?: string | undefined;
    milestones?: any[] | undefined;
    phase?: {
      additionalSteps: number;
      endColor: string;
      phaseEndDate: Date;
      title: string;
    };
  }[] = [];
  Object.values(data)
    .sort((a: IProjectTimeline, b: IProjectTimeline) => {
      return timelineOrder.indexOf(a.stage) - timelineOrder.indexOf(b.stage);
    })
    .forEach((value: IProjectTimeline) => {
      let phase:
        | {
            additionalSteps: number;
            endColor: string;
            phaseEndDate: Date;
            title: string;
          }
        | undefined;
      if (value.stage === ProjectTimelineStage.PermittingAndDesign) {
        const atbEndDate =
          data[ProjectTimelineStage.AuthorizationToBuild]?.end_date;
        if (atbEndDate) {
          phase = {
            additionalSteps: 1,
            endColor: getColor(ProjectTimelineStage.AuthorizationToBuild),
            title: "Phase 1",
            phaseEndDate: new Date(atbEndDate),
          };
        }
      } else if (value.stage === ProjectTimelineStage.Manufacturing) {
        const atbEndDate = data[ProjectTimelineStage.Installation]?.end_date;
        if (atbEndDate) {
          phase = {
            additionalSteps: 1,
            endColor: getColor(ProjectTimelineStage.Installation),
            title: "Phase 2",
            phaseEndDate: new Date(atbEndDate),
          };
        }
      }

      const genericInfo = B2B_TIMELINE_INFO.find((genericValue) => {
        return genericValue.title === value.stage;
      });
      newNextSteps.push({
        color: getColor(value.stage),
        startDate: new Date(value.start_date),
        endDate: new Date(value.end_date),
        title: genericInfo?.title ?? value.stage,
        subtitle:
          genericInfo?.subtitle ??
          timelineDescriptions[timelineOrder.indexOf(value.stage)],
        details:
          genericInfo?.details ??
          timelineDescriptions[timelineOrder.indexOf(value.stage)],
        milestones: genericInfo?.milestones,
        id: value.stage,
        phase: phase,
      });
    });
  const overallEndDate = new Date(
    data[ProjectTimelineStage.Installation]?.end_date
  );
  const startDate = new Date(
    data[ProjectTimelineStage.ProjectProposal]?.start_date
  );
  const overallTimelineMonths =
    (overallEndDate.getDate() - startDate.getDate()) / 30 +
    overallEndDate.getMonth() -
    startDate.getMonth() +
    12 * (overallEndDate.getFullYear() - startDate.getFullYear());
  return {
    nextStepsInfo: newNextSteps,
    actualTimeline: false,
    overallEndDate: overallEndDate,
    startDate: startDate,
    totalMonths: overallTimelineMonths,
    displayQuarters: true,
  };
}

export function getPhaseRange(
  data: {
    [key: string]: IProjectTimeline;
  },
  startStage: ProjectTimelineStage,
  endStage: ProjectTimelineStage,
  label: string,
  color: string
): any | null {
  const x1Obj = Object.values(data).find((value) => {
    return value.stage === startStage;
  });
  const x2Obj = Object.values(data).find((value) => {
    return value.stage === endStage;
  });
  if (!x1Obj?.start_date || !x2Obj?.end_date) {
    return null;
  }
  return {
    x: x1Obj?.start_date,
    x2: x2Obj?.end_date,
    borderColor: color,
    fillColor: `${color}`,
    label: {
      style: {
        color: BLACK,
        background: color,
      },
      fillColor: color,
      borderColor: color,
      text: label,
      orientation: "horizontal",
    },
  };
}

export function getPhase1Range(data: {
  [key: string]: IProjectTimeline;
}): any | null {
  return getPhaseRange(
    data,
    ProjectTimelineStage.PermittingAndDesign,
    ProjectTimelineStage.AuthorizationToBuild,
    "Phase 1",
    VILLA_TIMELINE_PERMITTING_AND_DESIGN
  );
}

export function getPhase2Range(data: {
  [key: string]: IProjectTimeline;
}): any | null {
  return getPhaseRange(
    data,
    ProjectTimelineStage.Manufacturing,
    ProjectTimelineStage.Installation,
    "Phase 2",
    VILLA_TIMELINE_INSTALLATION
  );
}

export function timestampToQuarter(startTimestamp: number): string {
  const startDate = new Date(startTimestamp);
  const dayNumber = startDate.getDate();
  let year = startDate.getFullYear();
  if (dayNumber > 15) {
    startDate.setMonth(startDate.getMonth() + 1);
    startDate.setDate(1);
  }
  let monthNumber = startDate.getMonth();
  let quarterNumber = Math.floor((monthNumber + 1) / 3) + 1;
  if (quarterNumber > 4) {
    quarterNumber = 1;
  }
  if (quarterNumber < 1) {
    quarterNumber = 1;
  }

  return `Q${quarterNumber} '${year.toString().slice(2)}`;
}
