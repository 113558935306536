import styled from "styled-components";
import {ADMIN_EDIT, VILLA_BLUE} from "../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../portal/views/SSDDashboardView/styles/globals";

export const FactoryInfoFormWrapper = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const FactoryInfoFormFieldWrapper = styled.div<{edited?: boolean}>`
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({edited}) => (edited ? ADMIN_EDIT : "transparent")};
`;

export const FactoryInfoFormFieldHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FactoryInfoFormFieldHeader = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 16px;
`;

export const FactoryInfoFormControllerWrapper = styled.div`
  position: fixed;
  right: 8px;
  bottom: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${VILLA_BLUE};
  border-radius: 12px;
`;
