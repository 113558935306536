import {
  fetchOptionKeys,
  fetchOptionValues,
} from "../components/admin/_shared/components/ProductOptions/ProductOptionHelper";
import {fetchProductCatalogTags} from "../database/firebase/admin/tags";
import {tryFetchingAllCatalogFactories} from "./FetchingManager";

let initialized = false;

export const AdminCatalogSliceManager = () => {
  if (!initialized) {
    fetchOptionKeys();
    fetchOptionValues();
    tryFetchingAllCatalogFactories({force: true});
    fetchProductCatalogTags();

    initialized = true;
  }

  return null;
};
