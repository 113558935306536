import styled from "styled-components";
import {
  calculateBorder,
  IconPosition,
  INatStyle,
  ITextStyle,
} from "../_shared/";
import {NatLinkStyle, NatStyle} from "../../styles";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../portal/views/SSDDashboardView/styles/globals";

const NatomasButton = styled.button<INatStyle>`
  ${NatStyle};
  border-radius: 3rem;
  font-family: ${(props) => props.fontFamily ?? FONT_FAMILY_BOLD};
  border: ${(props) =>
    calculateBorder(
      props.initialBorderColor,
      props.borderSize,
      props.disabled ?? false
    )};
  height: fit-content;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  transition: transform 100ms ease-in-out;

  &:hover {
    border: ${(props) =>
      calculateBorder(
        props.hoverBorderColor,
        props.borderSize,
        props.disabled ?? false
      )};
  }

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.additionalStyling ? {...props.additionalStyling} : undefined};
`;

const NatomasLink = styled.a<INatStyle>`
  ${NatLinkStyle};
  font-family: ${(props) => props.fontFamily ?? FONT_FAMILY_MEDIUM};
  border: ${(props) =>
    calculateBorder(props.initialBorderColor, props.borderSize, false)};
  border-radius: 3rem;

  &:hover {
    border: ${(props) =>
      calculateBorder(props.hoverBorderColor, props.borderSize, false)};
  }
`;

const TextContainer = styled.div<ITextStyle>`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props: ITextStyle) => props.fontSize};

  svg {
    margin-right: ${(props: ITextStyle) =>
      props.hasLabel && props.iconPosition === IconPosition.LEFT && "0.25rem"};
    margin-left: ${(props: ITextStyle) =>
      props.hasLabel && props.iconPosition === IconPosition.RIGHT && "0.25rem"};
    height: ${(props: ITextStyle) => props.fontSize};
    width: ${(props: ITextStyle) => props.fontSize};
  }

  ${(props) =>
    props.additionalTextStyling ? {...props.additionalTextStyling} : undefined};
`;

const IconOnlyContainer = styled.div<ITextStyle>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: ${(props: ITextStyle) => props.fontSize};
    width: ${(props: ITextStyle) => props.fontSize};
  }
`;

export {NatomasButton, NatomasLink, TextContainer, IconOnlyContainer};
