import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../views/SSDDashboardView/styles/globals";
import {TeamMemberCardLayoutStyle} from "../TeamMemberCard";
import {STEPH_SHADOW} from "../../../../../_shared/colors";

export const HCCardImage = styled.div`
  margin: auto;
  border-radius: 50%;
  padding: 15px;
  flex-shrink: 0;
`;

export const HCCardInfoWrapper = styled.div`
  width: 90%;
`;

export const HCCardTitle = styled.div`
  font-size: 1rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  padding: 0 4px;
`;

export const HCCardSubTitle = styled.div`
  font-size: 1rem;
  padding: 0 4px;
  margin: 0.25rem 0;
`;

export const HCFooter = styled.div`
  padding: 0 4px;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const HCCardLinkContainer = styled.div`
  margin: 12px auto;
  padding: 0 4px;
  width: fit-content;
`;

export const HCCardLinkWrapper = styled.div`
  margin: 0.5rem 0;
  text-align: left;
`;

export const HCCardLink = styled.a`
  color: inherit;
  font-size: 16px;
`;

export const HCCardContainer = styled.div<{
  shouldLookMobile: boolean;
  layout: TeamMemberCardLayoutStyle;
}>`
  margin: ${(props) =>
    props.shouldLookMobile ? "0rem 0 1rem 0" : "2rem 0 1rem 0"};
  padding: 1rem 0;
  width: 100%;
  background: #ffffff;
  box-shadow: ${STEPH_SHADOW};
  border-radius: 8px;
  text-align: ${(props) => (props.shouldLookMobile ? "left" : "center")};
  display: ${(props) => (props.shouldLookMobile ? "flex" : "block")};
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.layout === "compact" ? "unset" : "100%")};

  ${HCCardSubTitle} {
    font-size: ${(props) => (props.shouldLookMobile ? "0.8rem" : "1rem")};
  }
  ${HCCardLinkContainer} {
    font-size: ${(props) => (props.shouldLookMobile ? "0.8rem" : "1rem")};
    margin: ${(props) => (props.shouldLookMobile ? "0 0" : "12px auto")};
    justify-content: ${(props) => (props.shouldLookMobile ? "left" : "center")};
  }
  ${HCCardImage} {
    margin: ${(props) => (props.shouldLookMobile ? "unset" : "auto")};
    width: ${(props) =>
      props.layout === "compact"
        ? "60px"
        : props.layout === "full-details"
        ? "150px"
        : "118px"};
    height: ${(props) =>
      props.layout === "compact"
        ? "60px"
        : props.layout === "full-details"
        ? "150px"
        : "118px"};
  }
  ${HCFooter} {
    margin: ${(props) => (props.shouldLookMobile ? "0 0" : "16px 0")};
    font-size: ${(props) => (props.shouldLookMobile ? "0.8rem" : "0.9rem")};
  }
  ${HCCardInfoWrapper} {
    margin: auto;
    display: ${(props) => (props.layout === "compact" ? "flex" : "block")};
    justify-content: space-between;
    width: ${(props) => (props.layout === "compact" ? "80%" : "90%")};
    align-items: center;
  }
`;
