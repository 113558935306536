export const processTypeChange = (
  option: string,
  initialValue: any,
  updateEdits: (field: string, value?: any) => void
) => {
  if (option === "basic") {
    updateEdits("imageId", undefined);
    updateEdits("color", undefined);
    updateEdits("secondaryColor", undefined);
  }
  if (option === "single-color") {
    updateEdits("imageId", undefined);
    updateEdits("color", initialValue?.color);
    updateEdits("secondaryColor", undefined);
  }
  if (option === "two-color") {
    updateEdits("imageId", undefined);
    updateEdits("color", initialValue?.color);
    updateEdits("secondaryColor", initialValue?.secondaryColor);
  }
  if (option === "image") {
    updateEdits("imageId", initialValue?.imageId);
    updateEdits("color", undefined);
    updateEdits("secondaryColor", undefined);
  }
};
