import React from "react";
import {IProduct, IProductGroupDetails} from "@natomas-org/core";
import {ProductFlexList} from "./styles";
import {ProductListContainer} from "../../../views/styles";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {ProductCard} from "../ProductCard/ProductCard";
import {
  useProductFitsOnLot,
  useProductSiteWork,
} from "../../../../_shared/hooks/useProductParcelData";
import {isProductEligible} from "../../../../_shared/hooks/useMappingFeatures/helper";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useUrl} from "../../../../_shared/hooks/useUrl";
import {MAP_PATH} from "../../../../../paths";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {useProductRecommendations} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useProductRecommendations";
import {SectionTitle} from "./components/SectionTitle";
import {useCatalogSessionDispatcher} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";
import {scrollToTop} from "../../../../_shared/navigation/_helpers";

interface ProductListProps {
  products?: IProduct[];
  factory?: IProductGroupDetails;
  title?: string;
  subtitle?: string;
  hideTitle?: boolean;
  hideLink?: boolean;
  hideDescription?: boolean;
  disableSorting?: boolean;
  setProductOnHover?: boolean;
  removeBottomMargin?: boolean;
}

export const ProductList = (props: ProductListProps) => {
  const {factory, products, hideDescription, title, subtitle} = props;
  const {isAdmin} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const {isNatMobile} = usePage();
  const {getURLPath} = useUrl();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {filters, allInEstimateCatalog, eligibility, adminCatalogMode} =
    useCatalogSession();
  const {selectFactoryById} = useCatalogSessionDispatcher();
  const {productInstantQuoteData} = useProductSiteWork({productId: undefined});
  const {productFitsOnLotData} = useProductFitsOnLot({productId: undefined});
  let displayTitle = title ?? factory?.title ?? null;

  const percentRecommendation = useProductRecommendations();
  if (products?.length === 0) {
    return null;
  }

  let linkText = null;
  let linkEvent = null;
  let descriptionText = null;
  let titleText = null;
  if (products && products?.length >= 0 && !!displayTitle && !props.hideTitle) {
    titleText = displayTitle;
  }
  if (filters?.length > 0 && !props.hideTitle) {
    descriptionText = products?.length + " products";
    linkText = null;
  } else if (!hideDescription && displayTitle && !props.hideTitle) {
    descriptionText = props?.factory?.displayDescription ?? null;
    if (!!props.title || props.hideLink || !displayTitle) {
    } else {
      linkEvent = () => {
        selectFactoryById(props?.factory?.id ?? null);
        scrollToTop(true);
      };
      linkText = `View ${displayTitle} page`;
    }
  }
  if (subtitle) {
    descriptionText = subtitle;
  }
  return (
    <ProductListContainer
      removeBottomMargin={
        isNatMobile ||
        getURLPath()?.includes(MAP_PATH) ||
        props.removeBottomMargin
      }
    >
      <SectionTitle
        title={titleText}
        description={descriptionText}
        linkText={linkText}
        linkEvent={linkEvent ?? undefined}
      />
      <ProductFlexList hidden={products?.length === 0}>
        {products?.map((product: IProduct) => {
          const cardProps = {
            product: product,
            allInEstimate:
              allInEstimateCatalog && product?.id
                ? productInstantQuoteData?.[product?.id]
                : undefined,
            key: "product-card-" + product.id,
            productFitsOnLotData: product?.id
              ? productFitsOnLotData?.[product?.id]
              : undefined,
            setProductOnHover: props.setProductOnHover,
          };
          return (
            <ProductCard
              isEligible={
                isAdmin && adminCatalogMode
                  ? isProductEligible(
                      eligibility,
                      product?.productGroupId,
                      product?.id
                    )
                  : undefined
              }
              percentRecommendation={
                percentRecommendation[product.id ?? -1]?.percentScore ?? -1
              }
              isCustomerPreDeposit={isCustomerPreDeposit || !customer}
              {...cardProps}
            />
          );
        })}
      </ProductFlexList>
    </ProductListContainer>
  );
};
