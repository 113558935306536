import {ImageAssignmentFields} from "../ImageAssignmentManager/ImageAssignmentOptions/ImageAssignmentFields";
import React, {Dispatch, SetStateAction} from "react";
import {NatModal, NatModalSize} from "../../modal/NatModal";

export const ImageDetailsEditor = (props: {
  imageId: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const {show, setShow, imageId} = props;
  const close = () => setShow(false);
  return (
    <NatModal
      header={"Image Details Editor"}
      size={NatModalSize.NORMAL}
      show={show}
      handleClose={close}
      content={<ImageAssignmentFields imageId={imageId} close={close} />}
    />
  );
};
