import React from "react";
import {InputProps} from "../interface";
import RadioList from "./Radio";
import DefaultInput from "./Default";
import Address from "./Address";
import Phone from "./Phone";
import Checkbox from "./Checkbox";
import CreatePassword from "./CreatePassword";
import Text from "./../../Text";
import Select from "./Select";

const getType = (props: InputProps) => {
  const {id, textProps, children, ..._input} = props;
  let Input = null;
  let inputProps = {..._input};
  switch (props.type) {
    case "create-password":
      Input = CreatePassword;
      inputProps.type = "password";
      break;
    case "address":
      Input = Address;
      break;
    case "phone":
      Input = Phone;
      break;
    case "checkbox":
      Input = Checkbox;
      break;
    case "select":
      Input = Select;
      break;
    case "radio":
      Input = RadioList;
      break;
    default:
      Input = DefaultInput;
      break;
  }

  const DescriptionComponent = textProps ? <Text {...textProps} /> : null;

  return (
    <Input
      {...inputProps}
      id={id}
      key={id}
      additionalContent={
        <>
          {children}
          {DescriptionComponent}
        </>
      }
    />
  );
};

export default getType;
