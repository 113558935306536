import {
  formatPhoneNumber,
  IAddressDetails,
  IProjectInfo,
  IProjectStage,
  PhoneNumber,
  PhoneNumberFormatType,
  PhoneNumberTypes,
  Project,
} from "@natomas-org/core";

export const setCurrentProject = (projectId: string) => {
  // TODO B2B Refactor this
  // store.dispatch(setConfigurationId(projectId));
  // setURLQueryKeyValue(CONFIGURATION_ID_URL_KEY, projectId);
  // return loadConfiguration(true, projectId);
  return Promise.resolve();
};

export const getNewProjectInfo = (
  firstName: string,
  lastName: string,
  phoneNumber: string,
  address: IAddressDetails,
  currentConfigurationId: string,
  projectSummary?: IProjectInfo | null
) => {
  const cleanedPhoneNumber =
    formatPhoneNumber(phoneNumber, PhoneNumberFormatType.RAW) ?? "";
  const phoneDetails = PhoneNumber.create(
    PhoneNumberTypes.PRIMARY,
    cleanedPhoneNumber
  );
  if (projectSummary) {
    let newProject;
    newProject = Project.setClientFirstName(projectSummary, firstName);
    newProject = Project.setClientLastName(newProject, lastName);
    newProject = Project.setClientPhone(newProject, phoneDetails);
    newProject = Project.setAddress(newProject, address);
    return newProject;
  } else {
    return Project.dataToProject({
      id: currentConfigurationId,
      first_name: firstName,
      last_name: lastName,
      phone: phoneDetails,
      address: address,
      events: [],
      status_details: {
        stage: IProjectStage.PRE_DEPOSIT,
      },
      metadata: {},
      settings: {},
      created_date: new Date().getTime(),
      last_updated_date: new Date().getTime(),
    });
  }
};
