import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useEffect} from "react";
import {fetchUserAuthInfo} from "../../../../database/firebase/user";

export const useEmployeeInfo = (uid?: string) => {
  const authInfo = useSelector(
    (state: IStore) => state?.user?.userAuthInfo[uid ?? "null"]
  );

  useEffect(() => {
    if (!authInfo && uid) {
      fetchUserAuthInfo(uid).then(() => {
        console.log(`useEmployeeInfo: Fetched user: ${uid}`);
      });
    }
  }, [uid]);

  return authInfo;
};
