import {Address, DateUtils, IAddressDetails} from "@natomas-org/core";
import {getLengthAndWidthString} from "../../../../../../../studio/_shared/product";

export const getOrderFormProjectInfoRows = (
  code: string,
  address: IAddressDetails,
  product: any
) => {
  return [
    getCustomerNameRow(code),
    getCustomerAddressRow(address),
    getDateRow(),
    getProjectProductRow(product),
    getFactoryPrintNumberRow(product),
    getDimensionsRow(product),
    getModuleCountRow(product),
  ];
};

const PROJECT_INFO_CATEGORY_KEY = "Project Info";

const getCustomerNameRow = (code: string): string[] => {
  const customerCode = code ?? "Unknown";
  return [
    PROJECT_INFO_CATEGORY_KEY,
    "Customer Name",
    customerCode.toUpperCase(),
  ];
};

const getCustomerAddressRow = (address: IAddressDetails): string[] => {
  const a = Address.getFullAddress(address);
  return [
    PROJECT_INFO_CATEGORY_KEY,
    "Customer Address",
    a?.length > 0 ? a : "UNKNOWN",
  ];
};

const getDateRow = (): string[] => {
  const d = new Date();
  return [
    PROJECT_INFO_CATEGORY_KEY,
    "Date",
    DateUtils.getFullDate(d, {showYear: true}),
  ];
};

const getProjectProductRow = (product: any): string[] => {
  const model = product?.title;
  return [PROJECT_INFO_CATEGORY_KEY, "Villa Model", model ?? "UNKNOWN"];
};

const getFactoryPrintNumberRow = (product: any): string[] => {
  const printNumber = product?.factoryPrintNumber;
  return [
    PROJECT_INFO_CATEGORY_KEY,
    "Factory Print Number",
    printNumber ?? "UNKNOWN",
  ];
};

const getDimensionsRow = (product: any): string[] => {
  const dimensions = getLengthAndWidthString(product);
  return [
    PROJECT_INFO_CATEGORY_KEY,
    "Unit Dimensions",
    dimensions ?? "UNKNOWN",
  ];
};

const getModuleCountRow = (product: any): string[] => {
  const moduleCount = product?.moduleCount;
  return [
    PROJECT_INFO_CATEGORY_KEY,
    "Number of Modules",
    moduleCount ?? "UNKNOWN",
  ];
};
