import {
  InputTextAreaContainer,
  InputTitle,
} from "../../../_shared/styles/AdminStyles";
import React, {useMemo, useState} from "react";
import {DatePickerInputProps} from "../../../_shared/components/AdminComponentFactory/AdminInputProps";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {DateUtils} from "@natomas-org/core";
import {NatLink} from "../../../../_shared/generics/button";
import {NatSize} from "../../../../_shared/generics/_shared";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import {NatPopupType} from "../../../../_shared/generics/popup/dropdown/constants";
import {NatFlex} from "../../../../_shared/generics/flex/NatFlex";
import {NatLabelType} from "../../../../_shared/generics/label/NatLabel";

export const DatePickerInput = (props: DatePickerInputProps) => {
  const {value, onChange, fieldInfo, changeHighlight, disabled, type} = props;
  const {name, showTime, resetType} = fieldInfo;
  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (e: any) => {
    setIsOpen(!isOpen);
    onChange(e);
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  let valueDate = "Select Date";
  if (value) {
    valueDate = DateUtils.getFullDate(value, {
      showYear: true,
      showTime: showTime,
      showAMPM: true,
      shorthandFormat: true,
    });
  }
  const size = useMemo(() => {
    switch (type) {
      case NatLabelType.H1:
      case NatLabelType.H2:
        return NatSize.LARGE;
      case NatLabelType.P1:
      case NatLabelType.P2:
      case NatLabelType.H3:
      case NatLabelType.H4:
        return NatSize.NORMAL;
      case NatLabelType.P3:
        return NatSize.SMALL;
      case NatLabelType.P4:
        return NatSize.XSMALL;
      default:
        return NatSize.LARGE;
    }
  }, [type]);
  return (
    <InputTextAreaContainer>
      {changeHighlight}
      <InputTitle bold={true} enabled={!disabled} title={name} type={type} />
      <NatFlex>
        <NatDropdown
          toggleButtonOrLabel={valueDate}
          style={{
            TYPE: NatPopupType.HIDE,
            DROPDOWN_BUTTON_SIZE: size,
          }}
          contentItemArray={[
            {
              component: (
                <DatePicker
                  isClearable={true}
                  onChange={handleChange}
                  selected={value}
                  showTimeSelect={showTime ?? true}
                  minDate={fieldInfo.startTime ?? null}
                  maxDate={fieldInfo.endTime ?? null}
                  // peekNextMonth={fieldInfo.peekNextMonth ?? true}
                  showMonthDropdown={fieldInfo.showMonthDropdown ?? false}
                  showYearDropdown={fieldInfo.showYearDropdown ?? true}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  inline={true}
                />
              ),
              style: {width: "max-content"},
              id: "datePicker",
              label: "Date Picker",
            },
          ]}
        />
        <NatLink
          label={resetType === "clear" ? "Clear" : "Now"}
          style={{margin: 0}}
          size={NatSize.SMALL}
          hidden={!value || !resetType}
          clickEvent={() => {
            if (resetType === "clear") {
              onChange(null);
            } else {
              onChange(new Date());
            }
          }}
        />
      </NatFlex>
    </InputTextAreaContainer>
  );
};
