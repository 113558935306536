import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NatomasMapFeature} from "@natomas-org/core";

export interface INatomasMapSlice {
  features: {[id: string]: NatomasMapFeature | null};
  visible: {[id: string]: boolean};
  editingFeatureId: string | undefined;
}

const getInitialState = (): INatomasMapSlice => {
  return {
    features: {},
    visible: {},
    editingFeatureId: undefined,
  };
};

const natomasMapSlice = createSlice({
  name: "natomasMap",
  initialState: getInitialState(),
  reducers: {
    storeMapFeature: (
      state,
      action: PayloadAction<{
        id: string;
        mapping: NatomasMapFeature | null;
      }>
    ) => {
      const {id, mapping} = action.payload;
      state.features[id] = mapping;
    },
    storeMapFeatures: (
      state,
      action: PayloadAction<NatomasMapFeature[] | null>
    ) => {
      const mappings = action.payload;
      mappings?.forEach((mapping: NatomasMapFeature) => {
        state.features[mapping.id] = mapping;
      });
    },
    toggleFeatureVisibility: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      const id: string | undefined = action.payload;
      if (!id) {
        return;
      }
      // Switch to opposite state or initialize to true
      state.visible[id] =
        state.visible[id] !== undefined ? !state.visible[id] : true;
    },
    setEditingFeatureId: (state, action: PayloadAction<string | undefined>) => {
      // Switch to opposite state or initialize to true
      state.editingFeatureId = action.payload;
    },
    resetVisibility: (state) => {
      state.visible = {};
    },
    resetEditingFeatureId: (state) => {
      state.editingFeatureId = undefined;
    },
  },
});

export const natomasMapReducer = natomasMapSlice.reducer;
export const {
  storeMapFeature,
  storeMapFeatures,
  toggleFeatureVisibility,
  setEditingFeatureId,
  resetEditingFeatureId,
} = natomasMapSlice.actions;
