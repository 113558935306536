import React, {Dispatch, FormEvent, SetStateAction} from "react";
import {NatModal} from "../../../../_shared/generics/modal/NatModal";
import {StyleOption} from "../../../../_shared/generics/_shared";

export interface AdminSaveActionWarningProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  saveAction?: (e?: FormEvent<HTMLFormElement> | undefined) => void;
}

export const AdminSaveActionWarning = (props: AdminSaveActionWarningProps) => {
  const {show, setShow, saveAction} = props;
  const close = () => setShow(false);
  return (
    <NatModal
      header={"Attempting to save changes to live version..."}
      show={show}
      centeredContent={true}
      handleClose={close}
      content={
        <>
          <div>Please consider the following:</div>
          <div>
            Saving these changes will update the live AND draft versions.
          </div>
          <div>Changes will be immediately seen by customers.</div>
        </>
      }
      footerButtons={[
        {
          label: "Abort",
          option: StyleOption.DESTRUCTIVE,
          type: "button",
          clickEvent: close,
        },
        {
          label: "Save changes to Live & Draft",
          option: StyleOption.PRIMARY_ALT,
          type: "button",
          clickEvent: () => {
            saveAction?.();
            close();
          },
        },
      ]}
    />
  );
};
