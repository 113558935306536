import React from "react";
import DateTimePicker from "react-datetime-picker";

interface DateTimeInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: number;
}

const DateTimeInput = (props: DateTimeInputProps) => {
  const {initialValue, onChange} = props;

  return (
    <>
      <DateTimePicker
        onChange={(value) => {
          value && onChange(value.getTime());
        }}
        value={initialValue ? new Date(initialValue) : undefined}
      />
    </>
  );
};

export default DateTimeInput;
