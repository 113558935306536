import React from "react";
import {CartSectionContainer} from "./styles";

interface CartSectionWrapperProps {
  hasFrame: boolean;
  willExpand: boolean;
  children: React.ReactNode;
}

const CartSectionWrapper = (props: CartSectionWrapperProps) => {
  const {hasFrame, willExpand, children} = props;

  return (
    <CartSectionContainer hasFrame={hasFrame} willExpand={willExpand}>
      {children}
    </CartSectionContainer>
  );
};

export default CartSectionWrapper;
