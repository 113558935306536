import React, {useMemo} from "react";
import ReactApexChart from "react-apexcharts";
import {GRAY_BORDER} from "../../../../../../../_shared/colors";
import {DateUtils, IProjectTimeline} from "@natomas-org/core";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {
  B2BTimelineChartDisplayOptions,
  getColor,
  getPhase1Range,
  getPhase2Range,
  timelineDescriptions,
  timelineOrder,
  timestampToQuarter,
} from "./helpers";

export const B2BTimelineChart = (props: {
  data: {[key: string]: IProjectTimeline};
  displayOptions: B2BTimelineChartDisplayOptions;
}) => {
  const {displayOptions} = props;
  const data = useMemo(() => {
    return Object.values(props.data)
      .sort((a: IProjectTimeline, b: IProjectTimeline) => {
        return timelineOrder.indexOf(a.stage) - timelineOrder.indexOf(b.stage);
      })
      .map((value) => {
        return {
          x: value.stage,
          y: [value.start_date, value.end_date],
          fillColor: getColor(value.stage),
        };
      });
  }, [props.data]);
  const series = [
    {
      data: data,
    },
  ];
  const xAxisAnnotations = useMemo(() => {
    const toReturn = [];
    const phase1Range = getPhase1Range(props.data);
    const phase2Range = getPhase2Range(props.data);
    if (phase1Range) {
      toReturn.push(phase1Range);
    }
    if (phase2Range) {
      toReturn.push(phase2Range);
    }
    return toReturn;
  }, [props.data]);
  const options = useMemo(() => {
    let startTime: number | undefined;
    let endTime: number | undefined;
    let tickAmount: number | undefined;
    let minTick: number | undefined;
    let maxTick: number | undefined;
    let rangeTick: number | undefined;
    if (data && data[0]?.y) {
      startTime = data[0]?.y[0];
      endTime = data[data.length - 1]?.y[1];
      if (startTime && endTime) {
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);
        const startMonth = startDate.getMonth();
        const endMonth = endDate.getMonth();
        let minTickDate = new Date(startTime);
        minTickDate.setMonth(Math.floor(startMonth - (startMonth % 3)));
        minTickDate.setDate(0);
        minTickDate = new Date(minTickDate.getTime() + 1000 * 60 * 60 * 12);
        minTick = minTickDate.getTime();
        let maxTickDate = new Date(endTime);
        maxTickDate.setMonth(Math.ceil(endMonth + (endMonth % 3)));
        maxTickDate.setDate(0);
        maxTickDate = new Date(maxTickDate.getTime() + 1000 * 60 * 60 * 12);
        maxTick = maxTickDate.getTime();
        let rangeInMonths = DateUtils.getTimeDifferenceMonthsNumber(
          minTick,
          maxTick
        );
        const originalRangeInMonths = DateUtils.getTimeDifferenceMonthsNumber(
          startDate.getTime(),
          endDate.getTime()
        );
        if (
          Math.ceil(originalRangeInMonths / 3) >= Math.ceil(rangeInMonths / 3)
        ) {
          rangeInMonths += 3;
          maxTickDate.setMonth(maxTickDate.getMonth() + 3);
          maxTick = maxTickDate.getTime();
        }
        tickAmount = rangeInMonths / 3;
        for (let i = 0; i < tickAmount; i++) {
          const dateToLog = new Date(minTick);
          dateToLog.setMonth(dateToLog.getMonth() + i * 3);
        }
      }
    }
    return {
      annotations: {
        xaxis: xAxisAnnotations,
      },
      tooltip: {
        custom: function ({series, seriesIndex, dataPointIndex, w}: any) {
          switch (parseInt(dataPointIndex)) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
              return (
                '<div class="arrow_box" style="padding: 6px 24px;">' +
                `<p style="font-family: ${FONT_FAMILY_BOLD}; font-size: 14px; text-wrap: inherit; padding: 0 0;">` +
                timelineOrder[dataPointIndex] +
                "</p>" +
                `<p style="font-size: 12px; padding: 0 0;">` +
                timelineDescriptions[dataPointIndex] +
                "</p>" +
                "</div>"
              );
            default:
              console.log(dataPointIndex);
          }
        },
        enabled: displayOptions.showTooltip,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        fillSeriesColor: false,
        theme: "light",
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          highlightDataSeries: false,
        },
        x: {
          show: false,
          format: "dd MMM",
        },
        y: {
          show: false,
          formatter: undefined,
          title: {
            // formatter: (seriesName: string) => {
            //   return seriesName;
            // },
          },
        },
        z: {
          formatter: undefined,
          title: "Size: ",
        },
        marker: {
          show: true,
        },
        items: {
          display: "flex",
        },
        fixed: {
          enabled: false,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },

      chart: {
        height: 650,
        width: "100%",
        type: "rangeBar",
        selection: {
          enabled: false,
        },
        toolbar: {
          selection: {
            enabled: false,
          },
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: true,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: displayOptions.showLabelsOnBars,
        formatter: function (val: any[], opts: any) {
          return DateUtils.getTimeDifferenceString(val[0], val[1]);
        },
      },
      xaxis: {
        tickPlacement: "on",
        tooltip: {
          enabled: false,
        },
        type: "datetime",
        decimalsInFloat: false,
        overwriteCategories: false,
        position: "bottom",
        tickAmount: tickAmount,
        min: minTick,
        max: maxTick,
        range: rangeTick,
        labels: {
          offsetX: 0,
          offsetY: 0,
          show: true,
          rotateAlways: false,
          hideOverlappingLabels: false,
          showDuplicates: true,
          formatter: function (timestamp: number, tickNumber: number) {
            return timestampToQuarter(timestamp).trim();
          },
          trim: false,
        },

        axisBorder: {
          show: true,
          color: GRAY_BORDER,
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: GRAY_BORDER,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        group: {
          groups: [
            {
              title: "Q1",
              cols: 3,
            },
            {
              title: "Q2",
              cols: 4,
            },
          ],

          style: {
            colors: ["red", "green"],
            fontSize: "12px",
            fontWeight: 400,
            fontFamily: undefined,
            cssClass: "",
          },
        },
      },
      yaxis: {
        labels: {
          trim: false,

          style: {
            fontSize: "12px",
            fontFamily: FONT_FAMILY_REGULAR,
          },
          // formatter: function (value: string) {
          //   return value;
          // },
        },
        show: displayOptions.showSectionTitles,
      },

      grid: {
        show: true,
        borderColor: GRAY_BORDER,
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    };
  }, [
    data,
    displayOptions.showLabelsOnBars,
    displayOptions.showSectionTitles,
    displayOptions.showTooltip,
    xAxisAnnotations,
  ]);

  return (
    <div id="chart">
      <ReactApexChart
        options={options as any}
        series={series}
        type="rangeBar"
        height={650}
        width={"100%"}
      />
    </div>
  );
};
