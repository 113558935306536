import React, {useState} from "react";
import {NatButton} from "../../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../../_shared/generics/_shared";
import {updateUserPassword} from "../../../../../../../database/firebase/api/admin";
import {EditFieldContainer} from "./styles";
import {useCurrentCustomer} from "../../../../../../_shared/hooks/useCurrentCustomer";

export const EditPasswordSetting = () => {
  const {user_id} = useCurrentCustomer();
  const [showPasswordEditor, setShowPasswordEditor] = useState<boolean>(false);
  const [password, setPassword] = useState<string | null>(null);

  return (
    <>
      {showPasswordEditor && (
        <input
          onChange={(event) => setPassword(event.target.value)}
          type={"email"}
          placeholder={"New password"}
        />
      )}
      {showPasswordEditor && (
        <EditFieldContainer>
          <NatButton
            size={NatSize.SMALL}
            label={"Cancel"}
            type={"button"}
            option={StyleOption.PRIMARY_ALT}
            clickEvent={() => setShowPasswordEditor(false)}
          />
          <NatButton
            size={NatSize.SMALL}
            label={"Set Password"}
            type={"button"}
            clickEvent={() => {
              if (password) {
                updateUserPassword(user_id, password).then(() =>
                  console.log("Updated " + user_id + " password")
                );
              }
              setShowPasswordEditor(false);
            }}
          />
        </EditFieldContainer>
      )}
      {!showPasswordEditor && (
        <NatButton
          size={NatSize.SMALL}
          label={"Edit Login Password"}
          type={"button"}
          clickEvent={() => setShowPasswordEditor(true)}
        />
      )}
    </>
  );
};
