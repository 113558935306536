import React, {useEffect} from "react";
import {getParameter} from "../../_shared/cookies";
import {Utilities} from "../../../database/firebase";
import firebase from "firebase";

export const IntakeError = () => {
  useEffect(() => {
    if (window.location.href.includes("errorMessage")) {
      const randomRef = Utilities.collection("intake_form_errors").doc();
      randomRef.set({
        error: getParameter("errorMessage"),
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }, []);

  return <div>Uh oh - looks like something's broken!</div>;
};
