export const getModifierGroupsByCategoryIds = (
  categories: any,
  modifierGroups: any,
  categoryIds?: string[]
) => {
  const ids: string[] = [];
  Object.values(categories)?.forEach((category: any) => {
    if (!categoryIds?.includes(category?.id)) {
      return;
    }
    category?.modifierGroups?.forEach((modifierGroupId: string) => {
      if (!ids.includes(modifierGroupId)) {
        ids.push(modifierGroupId);
      }
    });
  });
  if (ids?.length > 0) {
    return ids
      .map((id: string) => {
        return modifierGroups[id];
      })
      .filter((modifierGroup: any) => !!modifierGroup);
  }
};

export const getModifierGroupsByModifierGroupIds = (
  modifierGroups: any,
  modifierGroupIds?: string[]
) => {
  if (modifierGroupIds && modifierGroupIds?.length > 0) {
    return modifierGroupIds
      .map((id: string) => {
        return modifierGroups[id];
      })
      .filter((modifierGroup: any) => !!modifierGroup);
  }
  return [];
};
