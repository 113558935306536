import React from "react";
import {ID_KEY} from "../../../shared/constants";
import {ColumnTextFilterInput} from "./styles";
import {ResetButton} from "../_shared/ResetButton";

// Define a default UI for filtering
export const InfoSetColumnTextFilter = (columnProps: any) => {
  const {preFilteredRows, filterValue, setFilter} = columnProps.column;
  const count = [
    ...new Set(preFilteredRows.map((r: any) => r.original[ID_KEY])),
  ].length;

  return (
    <>
      <ColumnTextFilterInput
        value={filterValue || ""}
        onChange={(e) => {
          const value = e.target.value;
          if (value) {
            setFilter(value);
          } else {
            // Set undefined to remove the filter entirely
            setFilter(undefined);
          }
        }}
        placeholder={`Search ${count} keys...`}
      />
      <ResetButton
        show={!!filterValue}
        clickEvent={() => setFilter(undefined)}
      />
    </>
  );
};
