import {FactoryLineStructure} from "../../../../admin/_models/factoryLine";
import {
  ADUQuiz_ClientTypes,
  ADUQuiz_PlannedUse,
  ICatalogTag,
  IPardotIntakeFormInput,
} from "@natomas-org/core";

export enum ADUQuiz_QuestionIds {
  ClientType = "clientType",
  PlannedUse = "plannedUse",
  ClientPriority = "factoryLineTag",
  Bedrooms = "minBedroomCount",
  Bathrooms = "minBathroomCount",
  Budget = "priceRange",
}

export enum AnswerSelectionType {
  Single,
  Multiple,
}

export interface ADUQuestion {
  type: AnswerSelectionType;
  registrationOnly: boolean;
  questionId: ADUQuiz_QuestionIds;
  questionText: string;
  pardotInformation?: IPardotIntakeFormInput;
  answers: {
    answerText: string;
    answerValue:
      | undefined // Undefined means no answer selected
      | string
      | string[]
      | number
      | [number, number]
      | null;
  }[];
}

export const aDUQuizPlannedUseTextMap: Record<ADUQuiz_PlannedUse, string> = {
  [ADUQuiz_PlannedUse.Guest]: "Guest home",
  [ADUQuiz_PlannedUse.Income]: "Rental and/or investment income",
  [ADUQuiz_PlannedUse.Other]: "Other",
  [ADUQuiz_PlannedUse.General_Space]:
    "Additional space (ie. home office or pool house)",
  [ADUQuiz_PlannedUse.Living_Space_Self]: "Living space for myself",
  [ADUQuiz_PlannedUse.Living_Space_Other]:
    "Additional living space for friends and family",
};
export const ADUQuizQuestions: ADUQuestion[] = [
  {
    type: AnswerSelectionType.Single,
    registrationOnly: true,
    questionId: ADUQuiz_QuestionIds.ClientType,
    questionText: "Which best describes you?",
    answers: [
      {
        answerText: ADUQuiz_ClientTypes.Homeowner,
        answerValue: ADUQuiz_ClientTypes.Homeowner,
      },
      {
        answerText: ADUQuiz_ClientTypes.Future_Homeowner,
        answerValue: ADUQuiz_ClientTypes.Future_Homeowner,
      },
      {
        answerText: ADUQuiz_ClientTypes.Investor_Multiple_Units,
        answerValue: ADUQuiz_ClientTypes.Investor_Multiple_Units,
      },
      {
        answerText: ADUQuiz_ClientTypes.Investor_Single_Unit,
        answerValue: ADUQuiz_ClientTypes.Investor_Single_Unit,
      },
      {
        answerText: ADUQuiz_ClientTypes.Single_Family_Home,
        answerValue: ADUQuiz_ClientTypes.Single_Family_Home,
      },
      {
        answerText: ADUQuiz_ClientTypes.Real_Estate_Agent,
        answerValue: ADUQuiz_ClientTypes.Real_Estate_Agent,
      },
      {
        answerText: ADUQuiz_ClientTypes.Other,
        answerValue: ADUQuiz_ClientTypes.Other,
      },
    ],
    pardotInformation: {
      inputName: "which_best_describes_you__c",
      inputType: "text",
      inputValue: null,
    },
  },
  {
    type: AnswerSelectionType.Multiple,
    registrationOnly: true,
    questionId: ADUQuiz_QuestionIds.PlannedUse,
    questionText:
      "How are you planning to use your ADU? Select all that apply.",
    answers: [
      {
        answerText:
          aDUQuizPlannedUseTextMap[ADUQuiz_PlannedUse.Living_Space_Other],
        answerValue: ADUQuiz_PlannedUse.Living_Space_Other,
      },
      {
        answerText: aDUQuizPlannedUseTextMap[ADUQuiz_PlannedUse.General_Space],
        answerValue: ADUQuiz_PlannedUse.General_Space,
      },
      {
        answerText:
          aDUQuizPlannedUseTextMap[ADUQuiz_PlannedUse.Living_Space_Self],
        answerValue: ADUQuiz_PlannedUse.Living_Space_Self,
      },
      {
        answerText: aDUQuizPlannedUseTextMap[ADUQuiz_PlannedUse.Income],
        answerValue: ADUQuiz_PlannedUse.Income,
      },
      {
        answerText: aDUQuizPlannedUseTextMap[ADUQuiz_PlannedUse.Guest],
        answerValue: ADUQuiz_PlannedUse.Guest,
      },
      {
        answerText: aDUQuizPlannedUseTextMap[ADUQuiz_PlannedUse.Other],
        answerValue: ADUQuiz_PlannedUse.Other,
      },
    ],
    pardotInformation: {
      inputName: "how_are_you_planning__c",
      inputType: "text",
      inputValue: null,
    },
  },
  {
    type: AnswerSelectionType.Single,
    registrationOnly: false,
    questionId: ADUQuiz_QuestionIds.ClientPriority,
    questionText: "What is most important to you when looking for an ADU?",
    answers: FactoryLineStructure.tags.options
      .filter(
        (valueAndLabel) =>
          valueAndLabel.value === ICatalogTag.MANY_DESIGN_CHOICES ||
          valueAndLabel.value === ICatalogTag.QUICKEST_DELIVERY ||
          valueAndLabel.value === ICatalogTag.LOWEST_PRICE
      )
      ?.map((item) => {
        return {
          answerText: item.label,
          answerValue: item.value,
        };
      }),
    pardotInformation: {
      inputName: "most_important_when_looking_for_adu__c",
      inputType: "text",
      inputValue: null,
    },
  },
  {
    type: AnswerSelectionType.Single,
    registrationOnly: false,
    questionId: ADUQuiz_QuestionIds.Bedrooms,
    questionText: "How many bedrooms do you want?",
    answers: [
      {
        answerText: "1+",
        answerValue: 1,
      },
      {
        answerText: "2+",
        answerValue: 2,
      },
      {
        answerText: "3+",
        answerValue: 3,
      },
      {
        answerText: "I have no preference",
        answerValue: null,
      },
    ],
    pardotInformation: {
      inputName: "how_many_bedrooms_do_you_want__c",
      inputType: "text",
      inputValue: null,
    },
  },
  {
    type: AnswerSelectionType.Single,
    registrationOnly: false,
    questionId: ADUQuiz_QuestionIds.Bathrooms,
    questionText: "How many bathrooms do you want?",
    answers: [
      {
        answerText: "1+",
        answerValue: 1,
      },
      {
        answerText: "2+",
        answerValue: 2,
      },
      {
        answerText: "I have no preference",
        answerValue: null,
      },
    ],
    pardotInformation: {
      inputName: "how_many_bathrooms_do_you_want__c",
      inputType: "text",
      inputValue: null,
    },
  },
  {
    type: AnswerSelectionType.Single,
    registrationOnly: false,
    questionId: ADUQuiz_QuestionIds.Budget,
    questionText: "What is your total project budget?",
    answers: [
      {
        answerText: "$200,000 - $300,000",
        answerValue: [200, 300],
      },
      {
        answerText: "$300,000 - $400,000",
        answerValue: [300, 400],
      },
      {
        answerText: "$400,000+",
        answerValue: [400, 10000],
      },
      {
        answerText: "Undecided",
        answerValue: null,
      },
    ],
    pardotInformation: {
      inputName: "what_is_your_total_budget__c",
      inputType: "text",
      inputValue: null,
    },
  },
];
