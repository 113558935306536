import {Card} from "../ProductCard";
import React from "react";
import {
  INVENTORY_SERIES_IMAGE_ID,
  INVENTORY_UNIT_DESCRIPTION,
  INVENTORY_UNIT_PREFIX,
} from "../../../../../_shared/constants/labels";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";

const inventoryCardValues = {
  title: INVENTORY_UNIT_PREFIX,
  priceString: "",
  priceSubtext: "",
  subtextLine1: INVENTORY_UNIT_DESCRIPTION,
  subtextLine2: "",
  primaryImageId: INVENTORY_SERIES_IMAGE_ID,
  hoverImageId: "",
  labels: [],
  onMouseEnter: undefined,
  onMouseLeave: undefined,
};
export const InventoryCard = (props: {style?: React.CSSProperties}) => {
  const {to} = useNavigation();
  return (
    <Card
      height={"16rem"}
      cardValues={{
        ...inventoryCardValues,
        clickAction: () => {
          to(NavigationPaths.CATALOG_INVENTORY);
        },
      }}
      style={props.style}
    />
  );
};
