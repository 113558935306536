import React, {useMemo, useState} from "react";
import {SmartImage} from "../../../_shared/generics/image/SmartImage";
import {
  CompletedProjectGalleryContainer,
  CompletedProjectMainImageWrapper,
  SlidesComponentContainer,
  SmartImageContainer,
  SubtitleContainer,
  TitleContainer,
} from "./styles";
import {NatButton} from "../../../_shared/generics/button";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {PublicProjectInfo} from "@natomas-org/service";
import {useCompletedProjects} from "../../../_shared/hooks/useCompletedProjects";
import {usePage} from "../../../_shared/hooks/usePage";
import {useSpringCarousel} from "react-spring-carousel";
import {a, useSprings} from "@react-spring/web";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";
import {
  IconPosition,
  NatJustifyContent,
  StyleOption,
} from "../../../_shared/generics/_shared";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";

const PROJECT_IDS_TO_SHOW_IN_IMAGE_CAROUSEL: number[] = [
  23, 28, 54, 80, 177, 206, 221, 186,
];
export const CompletedProjectsBanner = () => {
  const {completedProjects} = useCompletedProjects();
  const {to} = useNavigation();
  const {loggedInUser} = useCurrentUser();
  const projectImages: string[] = useMemo(() => {
    const allProjectDict = completedProjects;
    if (!allProjectDict) {
      return [];
    } else {
      const toReturn: string[] = [];
      Object.values(allProjectDict)?.forEach((project) => {
        const projectNumber: number | null =
          PublicProjectInfo.getProjectNumber(project);
        if (
          !projectNumber ||
          !PROJECT_IDS_TO_SHOW_IN_IMAGE_CAROUSEL.includes(projectNumber)
        ) {
          return;
        }
        const imageIds: string[] | undefined = project?.imageIds;
        if (imageIds) {
          imageIds?.forEach((imageId, idx) => {
            if (idx === 0) {
              // Only take the hero
              toReturn.push(imageId);
            }
          });
        }
      });

      return toReturn;
    }
  }, [completedProjects]);

  const carouselHeight = useDynamicValue({
    forFour: "14rem",
    forEight: "16rem",
    forTwelve: "18rem",
    forSixteen: "24rem",
  });

  const {isNatMobile} = usePage();
  const [currentSlide, setCurrentSlide] = useState("0");
  const baseStyling = useMemo(() => {
    return {height: carouselHeight, width: "100%"};
  }, [carouselHeight]);
  const springs = useSprings(
    projectImages.length,
    projectImages.map((item, index) => {
      const xRotation = 0;
      const yRotation = 20;
      const IS_LEFT = currentSlide === `${index}`;
      const IS_MAIN =
        currentSlide === `${index - 1}` ||
        (currentSlide === `${projectImages?.length - 1}` && index === 0);
      if (IS_LEFT) {
        return {
          transform: `perspective(600px) rotateX(${xRotation}deg) rotateY(${-yRotation}deg) translateZ(${-150}px) scale(0.7)`,
          opacity: 0.5,
        };
      }
      if (IS_MAIN) {
        return {
          transform: `perspective(600px) rotateX(${0}deg) rotateY(${0}deg) translateZ(${0}px) scale(1)`,
          opacity: 1,
        };
      }
      return {
        transform: `perspective(600px) rotateX(${xRotation}deg) rotateY(${yRotation}deg) translateZ(${-150}px) scale(0.7)`,
        opacity: 0.5,
      };
    })
  );
  const items = useMemo(() => {
    return projectImages.length > 0
      ? springs?.map((spring, idx: number) => {
          return {
            id: `${idx}`,
            renderItem: (
              <a.div key={idx} style={{...spring, ...baseStyling}}>
                <CompletedProjectMainImageWrapper height={carouselHeight}>
                  <SmartImageContainer isNatMobile={isNatMobile}>
                    <SmartImage
                      key={idx}
                      imageId={projectImages[idx]}
                      style={{
                        objectFit: "cover",
                        borderRadius: "1rem",
                        overflow: "hidden",
                      }}
                    />
                  </SmartImageContainer>
                </CompletedProjectMainImageWrapper>
              </a.div>
            ),
          };
        })
      : [{id: "1", renderItem: <div />}];
  }, [baseStyling, carouselHeight, isNatMobile, projectImages, springs]);
  // @ts-ignore
  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    useListenToCustomEvent,
  } = useSpringCarousel({
    itemsPerSlide: Math.min(items?.length, 3),
    withLoop: true,
    items: items,
  });
  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideStartChange") {
      // @ts-ignore
      setCurrentSlide(event?.nextItem?.id);
    }
  });
  if (projectImages?.length < 1) {
    return <div />;
  }
  return (
    <CompletedProjectGalleryContainer height={"100%"}>
      <div
        style={{
          position: "relative",
          textAlign: "center",
          padding: "2rem 0",
        }}
      >
        <SlidesComponentContainer isNatMobile={isNatMobile}>
          {carouselFragment}
        </SlidesComponentContainer>
        <NatFlex
          ROW
          FULL_WIDTH
          JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
          style={{padding: "0 10%"}}
        >
          <NatButton
            label={""}
            clickEvent={() => {
              slideToPrevItem();
            }}
            icon={{icon: <BsChevronLeft />, iconPosition: IconPosition.ONLY}}
            option={StyleOption.PRIMARY_ALT}
          />
          <NatButton
            label={""}
            clickEvent={() => {
              slideToNextItem();
            }}
            icon={{icon: <BsChevronRight />, iconPosition: IconPosition.ONLY}}
            option={StyleOption.PRIMARY_ALT}
          />
        </NatFlex>
        <br />
        <TitleContainer>Explore featured projects</TitleContainer>
        <br />
        <SubtitleContainer>
          With 100s of homes in the ground, our portfolio is as unique as our
          customers. Explore what’s possible!
        </SubtitleContainer>
        <br />
        <NatButton
          trackingId={
            loggedInUser
              ? "account-splash-screen-completed-projects-cta"
              : "pa-splash-screen-completed-projects-cta"
          }
          label={"View completed Villas near you"}
          clickEvent={() => {
            to(NavigationPaths.COMPLETED_PROJECTS_MAP);
          }}
        />
      </div>
    </CompletedProjectGalleryContainer>
  );
};
