import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  IRegistrationState,
  ISessionState,
} from "../../modals/RegistrationModal/RegistrationTypes";

const getInitialState = (): ISessionState => {
  return {
    registration: {
      visible: false,
      signUp: false,
      isForced: false,
    },
    showAssessmentModal: false,
    displayCompletedProjectsPrice: false,
  };
};

const sessionSlice = createSlice({
  name: "session",
  initialState: getInitialState(),
  reducers: {
    setRegistrationModalVisible: (state, action: PayloadAction<boolean>) => {
      state.registration.visible = action.payload;
    },
    setRegistrationModalForced: (state, action: PayloadAction<boolean>) => {
      state.registration.isForced = action.payload;
    },
    setSignUp: (state, action: PayloadAction<boolean>) => {
      state.registration.signUp = action.payload;
    },
    setRegistrationModalState: (
      state,
      action: PayloadAction<IRegistrationState>
    ) => {
      state.registration.signUp = action.payload.signUp;
      state.registration.visible = action.payload.visible;
      state.registration.isForced = action.payload.isForced ?? false;
      // Action must set every time
      state.registration.redirectType = action.payload.redirectType;

      if (action.payload.registrationValues) {
        state.registration.registrationValues =
          action.payload.registrationValues;
      } else {
        state.registration.registrationValues = {};
      }
    },
    setShowAssessmentModal: (state, action: PayloadAction<boolean>) => {
      state.showAssessmentModal = action.payload;
    },
    setShowCompletedProjectPricing: (state, action: PayloadAction<boolean>) => {
      state.displayCompletedProjectsPrice = action.payload;
    },
  },
});

export const sessionReducer = sessionSlice.reducer;
export const {
  setRegistrationModalVisible,
  setRegistrationModalForced,
  setSignUp,
  setRegistrationModalState,
  setShowAssessmentModal,
  setShowCompletedProjectPricing,
} = sessionSlice.actions;
