import styled from "styled-components";
import {FOOTER_Z_INDEX} from "../../../../_shared/styles";
import {GRANITE} from "../../../../_shared/colors";

export const MobileStickyBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 16px;
  background-color: white;
  z-index: ${FOOTER_Z_INDEX};
  justify-content: space-between;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
`;

export const MobileStickyDescriptionSuffixText = styled.div`
  color: ${GRANITE};
  font-size: 1.125rem;
`;
export const MobileStickyDescriptionText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.5625rem;
  line-height: 133%;
  color: #000000;
`;
export const MobileStickyCallToActionButtonContainer = styled.div<{
  center: boolean;
}>`
  margin: ${(props) => (props.center ? "auto" : "unset")};
`;
export const MobileStickyFooterBumper = styled.div`
  width: 100%;
`;
