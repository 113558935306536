import React from "react";
import {IConstructionSpecification} from "./helper";
import {SectionTitle} from "../_shared/styles";
import {FeatureCollapsableContainer} from "../SeriesSpecifications/styles";
import NatCollapsableContainer from "../../../../../_shared/generics/collapsable-container/NatCollapsableContainer";
import ThemedRichTextDiv from "../_shared/ThemedRichTextDiv";
import {Divider} from "../../../../../_shared/generics/divider";
import ProductLinePageContainer from "../_shared/ProductLinePageContainer";

interface MobileConstructionSpecificationsProps {
  constructionSpecs: IConstructionSpecification[];
}

const MobileConstructionSpecifications = (
  props: MobileConstructionSpecificationsProps
) => {
  const {constructionSpecs} = props;

  return (
    <ProductLinePageContainer containerProps={{overridePadding: true}}>
      <SectionTitle style={{padding: "16px"}}>
        Construction Specifications
      </SectionTitle>
      {constructionSpecs?.map(
        (spec: IConstructionSpecification, specIndex: number) => {
          return (
            <>
              <Divider />
              <FeatureCollapsableContainer
                key={`construction-spec-${specIndex}`}
              >
                <NatCollapsableContainer
                  label={spec.label}
                  options={{align: "right", expand: true}}
                >
                  {spec?.items?.map((item: string, index: number) => {
                    return (
                      <ThemedRichTextDiv
                        html={item}
                        key={`${specIndex}-item-${index}`}
                      />
                    );
                  })}
                </NatCollapsableContainer>
              </FeatureCollapsableContainer>
            </>
          );
        }
      )}
    </ProductLinePageContainer>
  );
};

export default MobileConstructionSpecifications;
