import React from "react";
import {PDFProposal} from "@natomas-org/core";
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/700.css";
import TitlePage from "./TitlePage";
import QAAPage from "./QAAPage";
import BudgetSummaryPages from "./BudgetSummaryPages";
import SitePlansPage from "./SitePlans";
import SummaryPage from "./firebaseEstimate/SummaryPage";
import PageFooter from "./PageFooter";
import SitePlanPageHeader from "./SitePlanHeader";

export interface IPageProps {
  footer: React.ReactNode;
}

const ProposalTemplate = (props: PDFProposal.IProjectProposal) => {
  const {
    firstName,
    lastName,
    qaa,
    address,
    imageBase64,
    tables,
    priceMode,
    products,
    summaryData,
    company,
    generatedAt,
    additionalSiteWorkText,
  } = props ?? {};

  const footer = <PageFooter company={company} generatedAt={generatedAt} />;
  const altFooter = SitePlanPageHeader({generatedAt, company});
  return (
    <>
      <TitlePage
        firstName={firstName}
        address={address}
        lastName={lastName}
        units={products}
        footer={footer}
      />
      <SummaryPage
        summaryData={summaryData}
        additionalSiteCostsTextArray={additionalSiteWorkText}
        footer={footer}
        priceMode={priceMode}
      />
      <BudgetSummaryPages
        tables={tables}
        priceMode={priceMode}
        footer={footer}
      />
      {imageBase64?.length > 0 && (
        <SitePlansPage imageBase64={imageBase64} getFooter={altFooter} />
      )}
      <QAAPage qaa={qaa} footer={footer} />
      {/*<FixedEndingPages imageBlobs={finalPageImages} footer={footer} />*/}
    </>
  );
};
export default ProposalTemplate;
