export interface ICatalogFilter {
  type: FilterType;
  value:
    | number
    | string
    | AddressFilter
    | NumberRangeFilter
    | ProductCategoryType
    | FitsLotType
    | boolean;
  label: string;
}

export interface AddressFilter {
  state: string;
  county: string;
}

export interface NumberRangeFilter {
  low: number;
  high: number;
}

export enum ProductCategoryType {
  MODEL = "model",
  INVENTORY_UNIT = "inventory_unit",
}

export enum FitsLotType {
  BUILD = "fits_lot_build",
  PROJECT = "fits_lot_option",
}

export enum FilterType {
  SquareFootage = "square_footage",
  Bathroom = "bathroom_count",
  Bedroom = "bedroom_count",
  ProductLine = "product_line",
  UnitPriceRange = "unit_price_range",
  ProductCategory = "product_category",
  ProductParcelFit = "product_parcel_fit",
}

export enum FilterDropdownDisplayType {
  SquareFoot = "sqft",
  BedAndBath = "bedBath",
  UnitPrice = "unitPrice",
  Toggles = "toggles",
}

export enum BedBathCountFilterValue {
  Any,
  One,
  Two,
  Three,
  OnePlus,
  TwoPlus,
  ThreePlus,
}
