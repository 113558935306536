import {AdminFactoryInventoryEditorView} from "../../../../../../../../../_shared/slices/CatalogSlice/interfaces";
import {NatToggleSelectorOption} from "../../../../../../../../../_shared/generics/toggle-selector/interfaces";
import {setViewInInventoryEditor} from "../../../../../../../../../_shared/slices/CatalogSlice/AdminFactoryInventoryEditorSlice";

export const getFactoryInventoryEditorToggle = (
  viewId: AdminFactoryInventoryEditorView,
  currentViewId: AdminFactoryInventoryEditorView | null,
  dispatch: any
): NatToggleSelectorOption => {
  return {
    id: viewId,
    label: viewId,
    isSelected: currentViewId === viewId,
    clickEvent: () => {
      if (currentViewId !== viewId) {
        dispatch(setViewInInventoryEditor(viewId));
      }
    },
  };
};
