import React from "react";
import useActiveProject from "../../../hooks/useProject/useActiveProject";
import {CartButtonContainer, CartButtonCountBubble} from "./styles";
import {NatCartIcon} from "../../../icon/icons";
import {useNavigation} from "../../../hooks/useNavigation";
import {NavigationPaths} from "../../../hooks/useNavigation/paths";

const CartButton = () => {
  const {cartItemCount, isCartEnabled} = useActiveProject();
  const {to} = useNavigation();

  if (!isCartEnabled) {
    return null;
  }

  return (
    <CartButtonContainer onClick={() => to(NavigationPaths.CART)}>
      <NatCartIcon />
      {cartItemCount > 0 && (
        <CartButtonCountBubble>{cartItemCount}</CartButtonCountBubble>
      )}
    </CartButtonContainer>
  );
};

export default CartButton;
