import {PopupModal, useCalendlyEventListener} from "react-calendly";
import {EventScheduledEvent} from "react-calendly/typings";
import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {useCurrentCustomer} from "../hooks/useCurrentCustomer";
import {Customer, PhoneNumber} from "@natomas-org/core";
import {fetchAndSaveCalendlyEvent} from "../../../database/firebase/api/event";

export interface ICalendlyInfo {
  url: string;
  eventScheduled: (
    e: EventScheduledEvent,
    eventURI: string,
    inviteeURI: string,
    originalCalendlyLink: string
  ) => void;
  pageSettings?: any;
}

export const useCalendly = (props?: {projectId: string}) => {
  const {projectId} = props ?? {};
  const [calendlyEventInfo, setCalendlyEventInfo] =
    useState<null | ICalendlyInfo>(null);
  const [prefill, setPrefill] = useState<any>({});

  const {customer} = useCurrentCustomer();
  let customerInfo: any = {};
  if (customer) {
    const phoneNumber = Customer.getPrimaryContactPhoneNumber(customer);

    customerInfo = {
      email: Customer.getContactEmail(customer),
      firstName: Customer.getFirstName(customer),
      lastName: Customer.getLastName(customer),
      name: Customer.getFullName(customer),
      phone: PhoneNumber.format(phoneNumber, {
        includeInternationalCode: true,
        style: "plain",
      }),
    };
  }

  const utm = useSelector((state: IStore) => state.utm);

  useCalendlyEventListener({
    onDateAndTimeSelected: undefined,
    onEventScheduled: (event) => {
      if (calendlyEventInfo) {
        const eventURI = event.data.payload.event.uri;
        const inviteeURI = event.data.payload.invitee.uri;

        console.log("Scheduled calendly", eventURI, inviteeURI);

        calendlyEventInfo.eventScheduled(
          event,
          eventURI,
          inviteeURI,
          calendlyEventInfo.url
        );
        setCalendlyEventInfo(null);
      }
    },
    onEventTypeViewed: undefined,
    onProfilePageViewed: undefined,
  });

  const calendlyWidget = (
    <PopupModal
      url={calendlyEventInfo?.url ?? ""}
      pageSettings={
        calendlyEventInfo?.pageSettings ?? {
          hideGdprBanner: true,
          hideEventTypeDetails: true,
        }
      }
      utm={{
        utmCampaign: utm.utm_campaign,
        utmSource: utm.utm_source,
        utmMedium: utm.utm_medium,
        utmContent: utm.utm_content,
        utmTerm: utm.utm_term,
      }}
      prefill={{...customerInfo, ...prefill}}
      onModalClose={() => {
        setPrefill(null);
        setCalendlyEventInfo(null);
      }}
      open={calendlyEventInfo != null}
      rootElement={document.getElementById("natomas-web-app")!}
    />
  );

  const launchCalendly = useCallback(
    (url: string | null, eventId: string) => {
      if (url && projectId) {
        setCalendlyEventInfo({
          url: url,
          eventScheduled: (e, eventURI, inviteeURI, originalCalendlyLink) => {
            return fetchAndSaveCalendlyEvent(
              eventId,
              eventURI,
              projectId,
              originalCalendlyLink
            );
          },
        });
      }
    },
    [projectId, setCalendlyEventInfo]
  );

  return {
    setCalendlyEventInfo,
    setPrefill,
    calendlyWidget,
    customerInfo,
    launchCalendly,
  };
};
