import React from "react";
import {
  ITableCell,
  ITableHeader,
  ITableHeaders,
  ITableRow,
  ITableSortingOption,
  TableHeaderCategory,
} from "./table";
import {TableData, TableHeader, TableHeaderContainer, TableRow} from "./styles";
import {
  BsSortAlphaDown,
  BsSortAlphaDownAlt,
  BsSortDown,
  BsSortDownAlt,
  BsSortNumericDown,
  BsSortNumericDownAlt,
} from "react-icons/bs";

export const TableColumnHeaders = (props: ITableHeaders) => {
  const {headers, sortingOption} = props;
  return (
    <TableHeaderContainer>
      <TableRow key={"table-headers"}>
        {headers.map((h: ITableHeader, i: number) => (
          <ColumnHeader
            key={"table-header-column-" + i}
            header={h}
            sortingOption={sortingOption}
          />
        ))}
      </TableRow>
    </TableHeaderContainer>
  );
};

export const TableCellData = (props: ITableCell) => {
  const {content, bold, center, clickEvent} = props;
  return (
    <TableData
      onClick={() => (clickEvent ? clickEvent() : null)}
      bold={bold}
      center={center}
    >
      {content}
    </TableData>
  );
};

export const TableRowContainer = (props: ITableRow) => {
  const {keyId, cells, clickEvent, isSelected} = props;
  return (
    <TableRow
      isSelected={isSelected}
      key={keyId + "-table-row"}
      onClick={() => (clickEvent ? clickEvent() : null)}
    >
      {cells.map((c: ITableCell, index: number) => (
        <TableCellData
          key={keyId + "-cell-" + index}
          clickEvent={c?.clickEvent}
          content={c?.content}
          bold={c?.bold}
          center={c?.center}
        />
      ))}
    </TableRow>
  );
};

const getSortIcon = (
  category?: TableHeaderCategory,
  mode?: number
): JSX.Element | undefined => {
  if (!category) return;
  else if (category === TableHeaderCategory.STRING) {
    return mode === 1 ? <BsSortAlphaDown /> : <BsSortAlphaDownAlt />;
  } else if (category === TableHeaderCategory.NUMBER) {
    return mode === 1 ? <BsSortNumericDownAlt /> : <BsSortNumericDown />;
  } else {
    return mode === 1 ? <BsSortDown /> : <BsSortDownAlt />;
  }
};

const ColumnHeader = (props: {
  header: ITableHeader;
  sortingOption: ITableSortingOption;
}) => {
  const {label, column, category} = props?.header;
  const {setSorting, details} = props?.sortingOption;
  const {column: sortingColumn, mode: sortingMode} = details;
  let showIcon = column === sortingColumn;
  return (
    <TableHeader onClick={() => column && setSorting(column)}>
      {label}
      {showIcon && getSortIcon(category, sortingMode)}
    </TableHeader>
  );
};
