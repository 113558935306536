import React, {useMemo} from "react";
import {isBlankString} from "@natomas-org/core";
import {StudioEditorModifierButtonTileContainer} from "./styles";
import {SmartImage} from "../../../../../../../../../../../_shared/generics/image/SmartImage";

interface ModifierButtonTileProps {
  imageId?: string;
  color?: string;
  secondaryColor?: string;
  isSwatch?: boolean;
}

const getBackgroundStyle = (color?: string, secondaryColor?: string) => {
  if (!isBlankString(color) && !isBlankString(secondaryColor)) {
    // ts-ignore
    return {
      background: `linear-gradient(135deg, ${color} 50%, ${secondaryColor} calc(50% + 1px))`,
    };
  } else {
    // ts-ignore
    return {
      backgroundColor: color,
    };
  }
};

const ModifierButtonTile = (props: ModifierButtonTileProps) => {
  const {color, secondaryColor, imageId, isSwatch} = props;

  const style = useMemo(() => {
    return getBackgroundStyle(color, secondaryColor);
  }, [color, secondaryColor]);

  if (isBlankString(imageId)) {
    return (
      <StudioEditorModifierButtonTileContainer
        isSwatch={isSwatch}
        style={{...style}}
      />
    );
  }

  return (
    <StudioEditorModifierButtonTileContainer isSwatch={isSwatch}>
      <SmartImage imageId={imageId} />
    </StudioEditorModifierButtonTileContainer>
  );
};

export default ModifierButtonTile;
