import {DateUtils} from "@natomas-org/core";

const getMonthDayYearFormatString = (timestamp: number) => {
  const date = new Date(timestamp);
  const year = date.getUTCFullYear();
  return DateUtils.getCalendarMonthAndDayAndSuffix(date) + ", " + year;
};

export const getDateString = (
  timestamp?: number | null,
  withDifference?: boolean
) => {
  if (!timestamp || timestamp === -1) return "Unknown";
  else {
    if (!withDifference) {
      return getMonthDayYearFormatString(timestamp);
    } else {
      return (
        getMonthDayYearFormatString(timestamp) +
        " (" +
        getDaysAgo(timestamp) +
        ")"
      );
    }
  }
};

export const getDaysAgo = (
  timestamp?: number | null,
  options?: {
    onlyNumber?: boolean;
    onlyDescriptor?: boolean;
  }
): string => {
  if (!timestamp || timestamp === -1) return "";
  else {
    const oneDay = 24 * 60 * 60 * 1000;
    const now = Date.now();
    const diff = Math.round((now - timestamp) / oneDay);
    if (options?.onlyNumber) {
      return diff?.toString();
    }
    const descriptor = Math.abs(diff) === 1 ? "day" : "days";
    if (options?.onlyDescriptor) {
      return `${diff} ${descriptor}`;
    }
    return `${diff} ${descriptor} ago`;
  }
};

export const getAdminCategoryLabel = (categories: any, id?: string) => {
  if (!id) {
    return "Unknown";
  }
  if (categories?.[id]?.internal_title?.length > 0) {
    return categories?.[id]?.internal_title;
  } else if (categories?.[id]?.title?.length > 0) {
    return categories?.[id]?.title;
  } else {
    return "Unknown";
  }
};

export const getAdminModifierLabel = (modifiers: any, id?: string) => {
  if (!id) {
    return "Unknown";
  }
  if (modifiers?.[id]?.title?.length > 0) {
    return modifiers?.[id]?.title;
  } else {
    return "Unknown";
  }
};
