import React from "react";
import {AccessSummary} from "../_shared/_helpers";
import {
  EmployeeCardAccessSummary,
  EmployeeCardContainer,
  EmployeeCardDisplayName,
  EmployeeCardEmail,
} from "../_shared/styles";
import {isEmpty} from "@natomas-org/core";
import {NatButton} from "../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../_shared/generics/_shared";
import {NatEditIcon} from "../../../_shared/icon/icons";
import {IEmployee} from "../index";
import TeamMemberPhoto from "../../../../0-app/1-container/team-member-photo";

export const EmployeeRow = (props: {
  locked: boolean;
  employee: IEmployee;
  changeEmployeePermissions: (uid: string) => void;
}) => {
  const {employee, locked, changeEmployeePermissions} = props;
  const {permissions, authInfo} = employee ?? {};
  const {fullAccess, access, anyAccess, isFetching} = permissions ?? {};

  if (isEmpty(authInfo)) {
    return null;
  }

  return (
    <EmployeeCardContainer>
      <td>{authInfo?.uid}</td>
      <td>
        <NatButton
          label={"Edit"}
          type={"button"}
          disabled={locked}
          icon={{icon: <NatEditIcon />, iconPosition: IconPosition.ONLY}}
          option={StyleOption.PRIMARY_ALT}
          clickEvent={changeEmployeePermissions}
          size={NatSize.SMALL}
        />
      </td>
      <td height={"64px"} width={"64px"}>
        <TeamMemberPhoto email={authInfo?.email ?? undefined} />
      </td>
      <EmployeeCardDisplayName>{authInfo?.displayName}</EmployeeCardDisplayName>
      <EmployeeCardEmail>{authInfo?.email}</EmployeeCardEmail>
      <EmployeeCardAccessSummary>
        <AccessSummary
          isFetching={isFetching}
          access={access}
          anyAccess={anyAccess}
          fullAccess={fullAccess}
        />
      </EmployeeCardAccessSummary>
    </EmployeeCardContainer>
  );
};
