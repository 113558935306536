import React from "react";
import {IStudioSummary} from "@natomas-org/service";
import {StudioSummaryProps} from "../StudioCategoryShell/helper";
import {BLACK} from "../../../../../_shared/colors";
import {getBasePriceFromProduct} from "../../../product";
import {priceTextFromMicros} from "../../../helper";
import {StudioCategorySummary} from "../StudioCategorySummary";
import {StudioItemLine} from "../../StudioItemLine";
import {StudioSummaryUnitDescription} from "./StudioSummaryUnitDescription";
import {
  StudioCategorySummarySection,
  StudioCategorySummarySectionTitle,
} from "../StudioCategorySummary/styles";
import {ResultUnderline} from "../styles";
import LegalCopyright from "../../../../../../0-app/1-container/legal-copyright";

export const StudioSummaryPage = (props: StudioSummaryProps) => {
  const summary: IStudioSummary = props?.summary;
  const {product, upgradesInMicros, structure, selectedModifiers} = summary;
  console.log("StudioSummaryPage", summary);
  if (!structure) {
    return null;
  }

  let rawUpgradePrice = 0;
  if (upgradesInMicros) {
    rawUpgradePrice = upgradesInMicros;
  }
  // if (additionalItemsTotalInMicros) {
  //   rawUpgradePrice += additionalItemsTotalInMicros;
  // }

  let upgradeCost = priceTextFromMicros(rawUpgradePrice, "min-sign");
  if (rawUpgradePrice === 0) {
    upgradeCost = "+$0";
  }

  let basePrice = getBasePriceFromProduct(product, props.priceMode);

  return (
    <div>
      <StudioSummaryUnitDescription
        hideDimensions={true}
        product={product}
        priceMode={props.priceMode}
      />
      <ResultUnderline />
      {structure.map((category: any, index: number) => {
        return (
          <div key={category.id}>
            <StudioCategorySummary
              priceMode={props.priceMode}
              category={category}
              selectedModifiers={selectedModifiers}
              index={index + 1}
            />
          </div>
        );
      })}
      <StudioCategorySummarySection>
        <StudioCategorySummarySectionTitle>
          Summary
        </StudioCategorySummarySectionTitle>
        <div style={{fontStyle: "italic"}}>
          <StudioItemLine
            id={"design-summary-base-price"}
            leftText={"Base Package"}
            rightText={priceTextFromMicros(basePrice, "min")}
          />
          <StudioItemLine
            id={"design-summary-upgrade-price"}
            leftText="Upgrades"
            rightText={upgradeCost}
          />
          {/*{(additionalItemsTotalInMicros ?? 0) > 0 && (*/}
          {/*  <StudioItemLine*/}
          {/*    id={"design-summary-upgrade-price"}*/}
          {/*    leftText="Additional Items"*/}
          {/*    rightText={priceTextFromMicros(*/}
          {/*      additionalItemsTotalInMicros,*/}
          {/*      "min-sign"*/}
          {/*    )}*/}
          {/*  />*/}
          {/*)}*/}
        </div>

        <div className="description-wrapper base-price" style={{color: BLACK}}>
          <StudioItemLine
            id={"design-summary-total-price"}
            leftText="Unit Estimate"
            rightText={priceTextFromMicros(basePrice + rawUpgradePrice, "min")}
          />
        </div>
        <LegalCopyright />
      </StudioCategorySummarySection>
    </div>
  );
};
