import {EmailInputContainer, Error, FormSubtitle} from "../styles";
import {NatButton} from "../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../_shared/generics/_shared";
import {AiOutlineMinusCircle, AiOutlinePlusCircle} from "react-icons/ai";
import {CleanInputTextField} from "../../../admin/_shared/styles/AdminStyles";
import React from "react";
import {IRecipient} from "@natomas-org/core";
import {getRecipientErrorKey} from "../helpers";

export const EmailInput = (props: {
  title: string;
  formik: any;
  fieldValueKey: string;
  nameValidationKey: string;
  emailValidationKey: string;
  recipientKey: string;
  maxRecipients?: number;
}) => {
  const {
    title,
    formik,
    fieldValueKey,
    nameValidationKey,
    emailValidationKey,
    recipientKey,
    maxRecipients,
  } = props;
  return (
    <div>
      <FormSubtitle>
        <div>
          {title} ({formik.values[fieldValueKey].length})
        </div>
        <NatButton
          label={"Add "}
          type={"button"}
          size={NatSize.XSMALL}
          option={StyleOption.PRIMARY}
          spinnerEnabled={false}
          icon={{
            iconPosition: IconPosition.LEFT,
            icon: <AiOutlinePlusCircle />,
          }}
          clickEvent={() => {
            if (
              !maxRecipients ||
              formik.values[fieldValueKey]?.length < maxRecipients
            ) {
              formik
                .setFieldValue(fieldValueKey, [
                  ...formik.values[fieldValueKey],
                  {name: "", email: ""} as IRecipient,
                ])
                .then(() => {
                  formik.validateForm();
                });
            }
          }}
        />
      </FormSubtitle>
      {formik.values[fieldValueKey].map(
        (recipient: IRecipient, index: number) => {
          return (
            <EmailInputContainer key={`EmailInputContainer${index}`}>
              <div>
                <CleanInputTextField
                  key={`${fieldValueKey}[${index}].name`}
                  name={`${fieldValueKey}[${index}].name`}
                  value={formik.values[fieldValueKey][index].name}
                  contentEditable={true}
                  onChange={formik.handleChange}
                  spellCheck={false}
                  placeholder={"John Doe"}
                />
                {formik.errors[
                  getRecipientErrorKey(fieldValueKey, nameValidationKey, index)
                ] ? (
                  <Error
                    key={getRecipientErrorKey(
                      fieldValueKey,
                      nameValidationKey,
                      index
                    )}
                  >
                    {
                      formik.errors[
                        getRecipientErrorKey(
                          fieldValueKey,
                          nameValidationKey,
                          index
                        )
                      ]
                    }
                  </Error>
                ) : null}
              </div>
              <div>
                <CleanInputTextField
                  key={`${fieldValueKey}[${index}].email`}
                  name={`${fieldValueKey}[${index}].email`}
                  disabled={index === 0 && fieldValueKey === recipientKey}
                  contentEditable={
                    !(index === 0 && fieldValueKey === recipientKey)
                  }
                  value={formik.values[fieldValueKey][index].email}
                  onChange={
                    !(index === 0 && fieldValueKey === recipientKey)
                      ? formik.handleChange
                      : () => {}
                  }
                  spellCheck={false}
                  placeholder={"john@example.com"}
                  style={{width: "300px"}}
                />
                {formik.errors[
                  getRecipientErrorKey(fieldValueKey, emailValidationKey, index)
                ] ? (
                  <Error
                    key={getRecipientErrorKey(
                      fieldValueKey,
                      emailValidationKey,
                      index
                    )}
                  >
                    {
                      formik.errors[
                        getRecipientErrorKey(
                          fieldValueKey,
                          emailValidationKey,
                          index
                        )
                      ]
                    }
                  </Error>
                ) : null}
              </div>
              {!(index === 0 && fieldValueKey === recipientKey) && (
                <NatButton
                  key={`NatButton${index}`}
                  label={"Remove"}
                  type={"button"}
                  icon={{
                    iconPosition: IconPosition.LEFT,
                    icon: <AiOutlineMinusCircle />,
                  }}
                  size={NatSize.XSMALL}
                  option={StyleOption.DESTRUCTIVE}
                  spinnerEnabled={false}
                  clickEvent={() => {
                    formik
                      .setFieldValue(
                        fieldValueKey,
                        formik.values[fieldValueKey].filter(
                          (value: IRecipient, subIndex: number) => {
                            if (index !== subIndex) {
                              return value;
                            }
                          }
                        )
                      )
                      .then(() => {
                        formik.validateForm();
                      });
                  }}
                />
              )}
            </EmailInputContainer>
          );
        }
      )}
    </div>
  );
};
