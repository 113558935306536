import {SortDropdownValues} from "@natomas-org/core";
import {
  NatAscendingIcon,
  NatDescendingIcon,
} from "../../../../../_shared/icon/icons";
import {IconPosition} from "../../../../../_shared/generics/_shared";
import React from "react";

export const DropdownValues: {[key in SortDropdownValues]: any} = {
  [SortDropdownValues.PRICE_DESC]: {
    label: "Price",
    abbreviatedLabel: "Price",
    icon: {icon: <NatDescendingIcon />, iconPosition: IconPosition.RIGHT},
    value: SortDropdownValues.PRICE_DESC,
    ascending: false,
  },
  [SortDropdownValues.PRICE_ASC]: {
    label: "Price",
    abbreviatedLabel: "Price",
    icon: {icon: <NatAscendingIcon />, iconPosition: IconPosition.RIGHT},
    value: SortDropdownValues.PRICE_ASC,
    ascending: true,
  },
  [SortDropdownValues.SQUARE_FOOTAGE_ASC]: {
    label: "Sq. Ft.",
    abbreviatedLabel: "Sq. Ft.",
    icon: {icon: <NatAscendingIcon />, iconPosition: IconPosition.RIGHT},
    value: SortDropdownValues.SQUARE_FOOTAGE_ASC,
    ascending: true,
  },
  [SortDropdownValues.BED_ASC]: {
    label: "Bedroom Count",
    abbreviatedLabel: "Bed",
    icon: {icon: <NatAscendingIcon />, iconPosition: IconPosition.RIGHT},
    value: SortDropdownValues.BED_ASC,
    ascending: true,
  },
  [SortDropdownValues.BED_DESC]: {
    label: "Bedroom Count",
    abbreviatedLabel: "Bed",
    icon: {icon: <NatDescendingIcon />, iconPosition: IconPosition.RIGHT},
    value: SortDropdownValues.BED_DESC,
    ascending: false,
  },
  [SortDropdownValues.BATH_DESC]: {
    label: "Bath Count",
    abbreviatedLabel: "Bath",
    value: SortDropdownValues.BATH_DESC,
    icon: {icon: <NatDescendingIcon />, iconPosition: IconPosition.RIGHT},
    ascending: false,
  },
  [SortDropdownValues.BATH_ASC]: {
    label: "Bath Count",
    abbreviatedLabel: "Bath",
    value: SortDropdownValues.BATH_ASC,
    icon: {icon: <NatAscendingIcon />, iconPosition: IconPosition.RIGHT},
    ascending: true,
  },
  [SortDropdownValues.SQUARE_FOOTAGE_DESC]: {
    label: "Sq. Ft.",
    abbreviatedLabel: "Sq. Ft.",
    value: SortDropdownValues.SQUARE_FOOTAGE_DESC,
    icon: {icon: <NatDescendingIcon />, iconPosition: IconPosition.RIGHT},
    ascending: false,
  },
  [SortDropdownValues.TITLE_ASC]: {
    label: "Title",
    abbreviatedLabel: "Title",
    value: SortDropdownValues.TITLE_ASC,
    icon: {icon: <NatAscendingIcon />, iconPosition: IconPosition.RIGHT},
    ascending: true,
  },
};
