import {NatSize, StyleOption} from "../../../_shared/generics/_shared";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {NatButton} from "../../../_shared/generics/button";
import React from "react";

interface ReactPDFDemoViewProps {
  size?: NatSize;
  style?: StyleOption;
  text?: string;
}

export const ProposalPDFDownloadButton = (props: ReactPDFDemoViewProps) => {
  // const [blockFetch, setBlockFetch] = React.useState<boolean>(true);
  // const [downloadReady, setDownloadReady] = React.useState<boolean>(false);
  // const [instance, update] = usePDF({
  //   document: <div />,
  // });
  // const {mergedCustomerProjectInfos, info} = useActiveProject();
  // const {isNatMobile} = usePage();
  // const fileName = useMemo(() => {
  //   const clientName = mergedCustomerProjectInfos?.code ?? info?.last_name;
  //   const date = DateUtils.getFullDate(new Date(), {shorthandFormat: true});
  //   return `${clientName ? `${clientName}_` : ""}Proposal_${date}.pdf`;
  // }, [info?.last_name, mergedCustomerProjectInfos?.code]);
  // const {data, response, beginFetch} = useProposalDownload({
  //   updateMethod: update,
  // });
  // useDeepEffect(() => {
  //   console.log(data);
  // }, [data]);
  // useEffect(() => {
  //   return () => {
  //     setBlockFetch(true);
  //     setDownloadReady(false);
  //   };
  // }, []);
  // const buttonDisabledText = useMemo(() => {
  //   if (blockFetch) {
  //     return null;
  //   }
  //   if (instance.loading) {
  //     return "Loading...";
  //   }
  //   if (instance.error || response === "error") {
  //     return "Generating...";
  //   }
  //   if (!instance.url) {
  //     return "Generating...";
  //   }
  //   return null;
  // }, [blockFetch, response, instance.error, instance.loading, instance.url]);
  // const disabled = !!buttonDisabledText;
  // if (!downloadReady) {
  //   return (
  //     <NatButton
  //       option={props.style}
  //       spinnerEnabledWithText={true}
  //       size={props.size}
  //       label={
  //         disabled && buttonDisabledText
  //           ? buttonDisabledText
  //           : "Download Proposal PDF"
  //       }
  //       spinnerEnabled={true}
  //       clickEvent={async () => {
  //         setBlockFetch(false);
  //         return beginFetch().then(() => {
  //           setDownloadReady(true);
  //         });
  //       }}
  //     />
  //   );
  // }
  // return (
  //   <NatButton
  //     option={props.style}
  //     size={props.size}
  //     label={buttonDisabledText ?? "Download Proposal"}
  //     disabled={disabled}
  //     spinnerEnabledWithText={true}
  //     spinnerEnabled={true}
  //     clickEvent={async () => {
  //       return downloadFileToDesktop(instance.url, fileName, isNatMobile).then(
  //         (r) => {
  //           console.log(r);
  //         }
  //       );
  //     }}
  //   />
  // );
};
const ProposalPDFViewButton = (props: ReactPDFDemoViewProps) => {
  const {to} = useNavigation();
  return (
    <NatButton
      option={props.style}
      size={props.size}
      label={props.text ?? "Download Proposal PDF"}
      clickEvent={() => {
        to(NavigationPaths.DOCUMENT_PDF_PROPOSAL);
      }}
    />
  );
};

export default ProposalPDFViewButton;
