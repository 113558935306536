import React from "react";
import {
  StudioEditorActionType,
  StudioEditorFieldType,
} from "./StudioEditorFieldAddButton";
import StudioEditorFieldDeleteButton from "./StudioEditorFieldDeleteButton";
import StudioEditorFieldEditButton from "./StudioEditorFieldEditButton";
import {
  IFactoryInventoryStudioEditorPopupOptions,
  IFactoryInventoryStudioEditorPopupPathOptions,
} from "../PopupContent/interface";
import {StudioEditorMode} from "../../../index";
import MoveButton from "./MoveButton";
import {StudioEditorSwapDirection} from "../../formik/move";
import styled from "styled-components";
import {NatSize} from "../../../../../../../../../../../_shared/generics/_shared";

interface StudioEditorFieldButtonProps {
  type: StudioEditorFieldType;
  mode: StudioEditorMode;
  path: IFactoryInventoryStudioEditorPopupPathOptions;
  setActionEvent: (
    e: any,
    options?: IFactoryInventoryStudioEditorPopupOptions
  ) => void;
  orientation?: "vertical" | "horizontal";
  size?: NatSize;
}

const Container = styled.div<{orientation?: "vertical" | "horizontal"}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) =>
    props?.orientation === "vertical" ? "column" : "row"};
  row-gap: 8px;
  column-gap: 8px;
`;

const StudioEditorFieldConditionalController = (
  props: StudioEditorFieldButtonProps
) => {
  const {type, setActionEvent, path, mode, orientation, size} = props;

  if (mode === StudioEditorMode.DELETE) {
    return (
      <StudioEditorFieldDeleteButton
        clickEvent={(e: any) =>
          setActionEvent(e, {
            path,
            type,
            action: StudioEditorActionType.DELETE,
          })
        }
      />
    );
  }
  if (mode === StudioEditorMode.EDIT) {
    return (
      <StudioEditorFieldEditButton
        clickEvent={(e: any) =>
          setActionEvent(e, {
            path,
            type,
            action: StudioEditorActionType.EDIT,
          })
        }
      />
    );
  }
  if (mode === StudioEditorMode.MOVE) {
    return (
      <Container orientation={orientation ?? "vertical"}>
        <MoveButton
          size={size ?? NatSize.SMALL}
          direction={StudioEditorSwapDirection.UP}
          action={(e: any) =>
            setActionEvent(e, {
              path,
              type,
              action: StudioEditorActionType.MOVE_UP,
            })
          }
        />
        <MoveButton
          size={size ?? NatSize.SMALL}
          direction={StudioEditorSwapDirection.DOWN}
          action={(e: any) =>
            setActionEvent(e, {
              path,
              type,
              action: StudioEditorActionType.MOVE_DOWN,
            })
          }
        />
      </Container>
    );
  }
  return null;
};

export default StudioEditorFieldConditionalController;
