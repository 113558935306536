import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../../../portal/views/SSDDashboardView/styles/globals";
import {MAP_POLYGON_HOVER_FILL} from "../../colors";

export const NatomasMapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const NatomasMapContainer = styled.div`
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  pointer-events: all;
  position: relative;
`;
export const FloatingDivContainer = styled.div<{
  position:
    | "top-left"
    | "bottom-left"
    | "top-right"
    | "bottom-right"
    | "bottom-center";
}>`
  position: fixed;
  top: ${(props) =>
    (props.position === "top-left" || props.position === "top-right") &&
    "3rem"};
  bottom: ${(props) =>
    (props.position === "bottom-left" ||
      props.position === "bottom-right" ||
      props.position === "bottom-center") &&
    "3rem"};
  left: ${(props) =>
    props.position === "bottom-left" || props.position === "top-left"
      ? "3rem"
      : props.position === "bottom-center" && "40%"};
  right: ${(props) =>
    props.position === "bottom-right" || props.position === "top-right"
      ? "3rem"
      : props.position === "bottom-center" && "40%"};
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  font-family: ${FONT_FAMILY_REGULAR};
  z-index: 1;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
`;
export const FloatingDevRow = styled.div<{
  hasAction?: boolean;
  sticky?: boolean;
}>`
  padding: 8px 12px;
  font-size: 12px;
  line-height: 12px;
  ${(props) =>
    props.sticky &&
    `
    position: sticky;
    top: 0;
  `}
  white-space: pre-wrap;
  user-select: none;
  ${(props) =>
    props.hasAction &&
    `
    background-color: rgba(35, 55, 75, 1);
    cursor: pointer;
    &:hover {
      font-style: italic;
      color: ${MAP_POLYGON_HOVER_FILL};
    }
  `}
`;
