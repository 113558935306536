import React, {useCallback} from "react";
import {DateUtils, ListingStatusDetails} from "@natomas-org/core";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {priceTextFromMicros} from "../../../../../../../studio/_shared/helper";
import {duplicateInventoryUnitFromExisting} from "../../../../../../../../database/firebase/api/catalog";
import {ListingStatusTag} from "../../../_shared/ListingStatusTag";
import {ValueContainer} from "./styles";
import {
  NatCatalogIcon,
  NatCopyIcon,
  NatDesignIcon,
  NatEditIcon,
} from "../../../../../../../_shared/icon/icons";
import {NatTag} from "../../../../../../../_shared/generics/tag";
import ReactTooltip from "react-tooltip";
import {getStatusValue} from "./helper";
import {
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
} from "../../../../../../../_shared/colors";

export const AdminInventoryTableCell = (props: any) => {
  const {value, column, row, setEditingProduct} = props;
  const {factoryId, factoryTitle, id} = row?.original ?? {};
  const {id: columnKey} = column;

  const viewInCatalog = useCallback(() => {
    if (columnKey === "id") {
      window.open(
        window.location.origin + "/products?gid=" + factoryId + "&unit=" + value
      );
    }
  }, [columnKey, factoryId, value]);

  const viewInStudio = useCallback(() => {
    if (columnKey === "id") {
      window.open(
        window.location.origin + "/studio?gid=" + factoryId + "&unit=" + value
      );
    }
  }, [columnKey, factoryId, value]);

  const viewInProductManagement = useCallback(() => {
    if (columnKey === "factory") {
      window.open(
        window.location.origin +
          "/admin/products?gid=" +
          factoryId +
          "&page=factories"
      );
    }
  }, [columnKey, factoryId]);

  const editInventoryUnit = useCallback(() => {
    if (columnKey === "id") {
      setEditingProduct(value);
    }
  }, [columnKey, setEditingProduct, value]);

  const duplicateInventoryUnit = useCallback(() => {
    return duplicateInventoryUnitFromExisting(factoryId, id);
  }, [factoryId, id]);

  if (columnKey === "id") {
    return (
      <ValueContainer>
        <NatButton
          id={`${id}-catalog-page`}
          trackingId={`${id}-catalog-page`}
          tooltipPlacement={"bottom"}
          label={"Catalog"}
          disabled={!value || !factoryId}
          clickEvent={viewInCatalog}
          tooltip={"Catalog (Live)"}
          option={StyleOption.ADMIN_SOLID}
          icon={{
            icon: <NatCatalogIcon />,
            iconPosition: IconPosition.ONLY,
          }}
        />
        <NatButton
          id={`${id}-studio-page`}
          trackingId={`${id}-studio-page`}
          tooltipPlacement={"bottom"}
          label={"Studio"}
          disabled={!value || !factoryId}
          tooltip={"Studio (Live)"}
          clickEvent={viewInStudio}
          option={StyleOption.ADMIN_SOLID}
          icon={{
            icon: <NatDesignIcon />,
            iconPosition: IconPosition.ONLY,
          }}
        />
        <NatButton
          id={`${id}-editor`}
          trackingId={`${id}-editor`}
          label={"Edit"}
          tooltip={"Editor"}
          tooltipPlacement={"bottom"}
          hidden={!setEditingProduct}
          disabled={!value || !factoryId}
          option={StyleOption.ADMIN_SOLID}
          clickEvent={editInventoryUnit}
          icon={{
            icon: <NatEditIcon />,
            iconPosition: IconPosition.ONLY,
          }}
        />
        <NatButton
          id={`${id}-duplication`}
          trackingId={`${id}-duplication`}
          tooltipPlacement={"bottom"}
          label={"Duplicate"}
          tooltip={"Duplicate"}
          hidden={!setEditingProduct}
          disabled={!value || !factoryId}
          spinnerEnabled={true}
          option={StyleOption.ADMIN_SOLID}
          clickEvent={duplicateInventoryUnit}
          icon={{
            icon: <NatCopyIcon />,
            iconPosition: IconPosition.ONLY,
          }}
        />
      </ValueContainer>
    );
  }
  if (columnKey === "factory") {
    return (
      <NatButton
        label={factoryTitle}
        clickEvent={viewInProductManagement}
        option={StyleOption.PRIMARY_LINK}
        size={NatSize.SMALL}
      />
    );
    // return <ValueContainer>{factoryTitle}</ValueContainer>;
  }
  if (
    columnKey === "price" ||
    columnKey === "upgradePrice" ||
    columnKey === "cost" ||
    columnKey === "margin" ||
    columnKey === "unitPrice" ||
    columnKey === "factoryCost" ||
    columnKey === "salesTax"
  ) {
    return (
      <ValueContainer>{priceTextFromMicros(value, "accurate")}</ValueContainer>
    );
  }
  if (columnKey === "marginPercentage") {
    return <ValueContainer>{value}%</ValueContainer>;
  }
  if (
    columnKey === "storageDateTime" ||
    columnKey === "updatedDateTime" ||
    columnKey === "createdDateTime"
  ) {
    if (value) {
      const label = DateUtils.getFullDate(new Date(value), {showYear: true});
      return <ValueContainer>{label}</ValueContainer>;
    }
    return null;
  }
  if (columnKey === "productionDateTime") {
    if (value) {
      const label = DateUtils.getMonthAndYear(new Date(value));
      return <ValueContainer>{label}</ValueContainer>;
    }
    return null;
  }
  if (columnKey === "blacklinesUrl") {
    if (value) {
      return (
        <a href={value} target={"_blank"} rel="noreferrer">
          External Link
        </a>
      );
    }
    return null;
  }
  if (columnKey === "status") {
    if (value === 4) {
      return (
        <ValueContainer>
          <ReactTooltip
            id={`tooltip-sold-status`}
            effect="solid"
            place={"bottom"}
          >
            Unavailable, Sold
          </ReactTooltip>
          <NatTag
            data-tip
            data-for={`tooltip-sold-status`}
            color={VILLA_APPLE_GREEN}
            backgroundColor={VILLA_APPLE_GREEN_LIGHT_1}
            size={"0.75rem"}
          >
            {"SOLD"}
          </NatTag>
        </ValueContainer>
      );
    }
    if (value) {
      const label = getStatusValue(value);
      return (
        <ValueContainer>
          <ListingStatusTag status={label as ListingStatusDetails} />
        </ValueContainer>
      );
    }
    return null;
  }
  return <ValueContainer>{value}</ValueContainer>;
};
