import {useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {CustomerJourneyStepName, useTracking} from "./useTracking";
import {setURLQueryKeyValue} from "../navigation/_helpers";
import {useEffect, useMemo} from "react";
import {
  URL_PARAM_KEY_CART_ITEM_ID,
  URL_PARAM_KEY_PAGE_ID,
  URL_PARAM_KEY_PRODUCT_GROUP_ID,
  URL_PARAM_KEY_PROJECT_ID,
  URL_PARAM_KEY_PROJECT_ID_DEPRECATED,
  URL_PARAM_KEY_UNIT_ID,
  URL_PARAM_KEY_USER_ID,
} from "@natomas-org/service";

export const clearAllURLParams = () => {
  window.history.replaceState(
    null,
    "Villa | Login",
    window.location.protocol + "//" + window.location.host
  );
};

const getURLPath = () => {
  return window.location.pathname;
};

const getURL = () => {
  return window.location.href;
};

const getParams = () => {
  const formattedUrl = new URL(getURL());
  return new URLSearchParams(formattedUrl.search);
};

export const getParamFromURL = (param: string) => {
  const currentSearchParams = getParams();
  if (currentSearchParams.has(param)) {
    return currentSearchParams.get(param);
  } else {
    return null;
  }
};

export const useUrl = () => {
  const url = useSelector((state: IStore) => state.global.url);
  const pageSource = useSelector((state: IStore) => state.utm.page_source);
  const {trackJourneyStep} = useTracking();

  const processUrl = () => {
    let params = getParams();
    if (params.has("proposalFeePaid")) {
      if (params.get("proposalFeePaid") === "true") {
        trackJourneyStep(CustomerJourneyStepName.PAYMENT_SUBMITTED);
        setURLQueryKeyValue("proposalFeePaid", null);
      } else {
        trackJourneyStep(CustomerJourneyStepName.PAYMENT_ABANDON);
        setURLQueryKeyValue("proposalFeePaid", null);
      }
    }
  };

  useEffect(() => {
    processUrl();
  }, [url, processUrl]);

  const pid = useMemo(() => {
    return getParamFromURL(URL_PARAM_KEY_PROJECT_ID);
  }, [url]);

  const cid = useMemo(() => {
    return getParamFromURL(URL_PARAM_KEY_PROJECT_ID_DEPRECATED);
  }, [url]);

  const item = useMemo(() => {
    return getParamFromURL(URL_PARAM_KEY_CART_ITEM_ID);
  }, [url]);

  const uid = useMemo(() => {
    return getParamFromURL(URL_PARAM_KEY_USER_ID);
  }, [url]);

  const pathRoot = useMemo(() => {
    const path = getURLPath();
    return path.split("/")[1];
  }, [url]);

  const getPageFromURL = () => {
    return getParamFromURL(URL_PARAM_KEY_PAGE_ID);
  };

  const getProductInfoFromURL = () => {
    return {
      productId: getParamFromURL(URL_PARAM_KEY_UNIT_ID),
      productGroupId: getParamFromURL(URL_PARAM_KEY_PRODUCT_GROUP_ID),
    };
  };

  return {
    url,
    pageId: getPageFromURL(),
    getParamFromURL,
    getPageFromURL,
    getURLPath,
    getURL,
    getParams,
    getProductInfoFromURL,
    pageSource,
    pathRoot,
    uid,
    pid,
    cid,
    item,
  };
};
