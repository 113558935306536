import React, {useEffect, useMemo} from "react";
import {useFormik} from "formik";
import {RichTextEditor} from "../../_shared/generics/rich-text-editor/RichTextEditor";
import {PageContainer} from "../../_shared/generics/page/components/PageContainer";
import {IFactoryInfo} from "@natomas-org/core";
import {NatButton} from "../../_shared/generics/button";
import {updateFactoryLineGlobalValues} from "../../../database/firebase/factoryLine/saving";
import {
  FactoryInfoFormFieldHeader,
  FactoryInfoFormFieldHeaderWrapper,
  FactoryInfoFormFieldWrapper,
  FactoryInfoFormWrapper,
} from "./styles";
import _ from "lodash";
import {WHITE} from "../../_shared/colors";
import FactoryInfoFormController from "./FactoryInfoFormController";
import {NatUndoIcon} from "../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../_shared/generics/_shared";

interface FactoryInfoFormProps {
  info: IFactoryInfo;
}
// TODO Expand this form to handle FactoryLineGlobalValues as well; consolidate them into one form
const FactoryInfoForm = (props: FactoryInfoFormProps) => {
  const {info} = props;
  const initialValues = useMemo(() => {
    return {
      standardFeatures: info?.marketing?.standardFeatures,
      customFeatures: info?.marketing?.customFeatures,
    };
  }, [info]);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const toSave = {...initialValues, ...values};
      updateFactoryLineGlobalValues(info.id, {
        marketing: toSave,
      })?.then(() => {
        alert(`${info?.name} Factory Info saved`);
      });
    },
    onReset: () => {
      formik.setValues(initialValues);
    },
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  return (
    <>
      <FactoryInfoFormController
        canSave={!_.isEqual(initialValues, formik.values)}
        onSave={formik.handleSubmit}
        onReset={formik.handleReset}
      />
      <PageContainer>
        <FactoryInfoFormWrapper>
          <FactoryInfoFormFieldHeader
            hidden={!info?.name}
            style={{textDecoration: "underline", fontSize: "20px"}}
          >
            {info?.name}
          </FactoryInfoFormFieldHeader>
          <FactoryInfoFormFieldWrapper
            edited={
              !_.isEqual(
                initialValues?.standardFeatures,
                formik.values.standardFeatures
              )
            }
          >
            <FactoryInfoFormFieldHeaderWrapper>
              <FactoryInfoFormFieldHeader>
                Standard Features
              </FactoryInfoFormFieldHeader>
              <NatButton
                label={"Undo Changes"}
                clickEvent={() => {
                  formik.setFieldValue(
                    "standardFeatures",
                    initialValues?.standardFeatures
                  );
                }}
                hidden={_.isEqual(
                  initialValues?.standardFeatures,
                  formik.values.standardFeatures
                )}
                size={NatSize.SMALL}
                option={StyleOption.SECONDARY}
                icon={{icon: <NatUndoIcon />, iconPosition: IconPosition.ONLY}}
              />
            </FactoryInfoFormFieldHeaderWrapper>
            <RichTextEditor
              containerProps={{style: {backgroundColor: WHITE}}}
              value={formik.values.standardFeatures ?? ""}
              onChange={(value) =>
                formik.setFieldValue("standardFeatures", value)
              }
            />
          </FactoryInfoFormFieldWrapper>
          <FactoryInfoFormFieldWrapper
            edited={
              !_.isEqual(
                initialValues?.customFeatures,
                formik.values.customFeatures
              )
            }
          >
            <FactoryInfoFormFieldHeaderWrapper>
              <FactoryInfoFormFieldHeader>
                Custom Features
              </FactoryInfoFormFieldHeader>
              <NatButton
                label={"Undo Changes"}
                clickEvent={() => {
                  formik.setFieldValue(
                    "customFeatures",
                    initialValues?.customFeatures
                  );
                }}
                hidden={_.isEqual(
                  initialValues?.customFeatures,
                  formik.values.customFeatures
                )}
                size={NatSize.SMALL}
                option={StyleOption.SECONDARY}
                icon={{icon: <NatUndoIcon />, iconPosition: IconPosition.ONLY}}
              />
            </FactoryInfoFormFieldHeaderWrapper>
            <RichTextEditor
              containerProps={{style: {backgroundColor: WHITE}}}
              value={formik.values.customFeatures ?? ""}
              onChange={(value) =>
                formik.setFieldValue("customFeatures", value)
              }
            />
          </FactoryInfoFormFieldWrapper>
        </FactoryInfoFormWrapper>
      </PageContainer>
    </>
  );
};

export default FactoryInfoForm;
