import {store} from "../../../../store";
import {
  BedBathCountFilterValue,
  FilterType,
  ICatalogFilter,
  NumberRangeFilter,
  ProductCategoryType,
} from "./types";
import {
  addFilter,
  overwriteFilter,
  removeFilter,
  removeFiltersByTypes,
  resetFilters,
} from "../../slices/CatalogSlice/CatalogSessionSlice";
import {IProductParcelFitDataDictionary} from "@natomas-org/core";
import {getProductPricingInfo} from "../../_legacy/logic/ProductUtils";
import {PricingMode} from "../../slices/GlobalSlice";

export const applyReset = () => {
  store.dispatch(resetFilters());
};

export const applyFilter = (filter: ICatalogFilter) => {
  store.dispatch(addFilter(filter));
};

export const changeFilter = (filter: ICatalogFilter) => {
  store.dispatch(overwriteFilter(filter));
};

export const tryFilter = (
  filter: ICatalogFilter,
  filters: ICatalogFilter[]
) => {
  if (!checkForExactFilter(filter.type, filter.label, filters)) {
    applyFilter(filter);
  }
};

// export const deleteFilter = (filter: ICatalogFilter) => {
//   store.dispatch(removeFilter(filter));
// };

export const deleteFiltersByTypes = (filterTypes: FilterType[]) => {
  store.dispatch(removeFiltersByTypes(filterTypes));
};

export const deleteFilterByType = (type: string) => {
  store.dispatch(removeFilter({type: type}));
};

export const getBedroomFilter = (
  value: number,
  label: string
): ICatalogFilter => {
  return {
    type: FilterType.Bedroom,
    value: value,
    label: label,
  };
};

export const getSquareFootageFilter = (
  description: string,
  value: NumberRangeFilter
): ICatalogFilter => {
  // Low included, High excluded
  return {
    type: FilterType.SquareFootage,
    value: value,
    label: description,
  };
};

// export const getPriceRangeFilter = (
//   subType: FilterType,
//   description: string,
//   value: NumberRangeFilter
// ): ICatalogFilter => {
//   // Low included, High excluded
//   return {
//     type: subType,
//     value: value,
//     label: description,
//   };
// };

// export const getProductLineFilter = (name: string, internalName: string) => {
//   return {
//     type: FilterType.ProductLine,
//     value: internalName,
//     label: name,
//   };
// };

export const getProductCategoryFilter = (
  label: string,
  category: ProductCategoryType
) => {
  return {
    type: FilterType.ProductCategory,
    value: category,
    label: label,
  };
};
export const getProductParcelFitFilter = (
  label: string,
  filters: ICatalogFilter[]
): ICatalogFilter => {
  const newValue = filters.find(
    (f: ICatalogFilter) =>
      f.type === FilterType.ProductParcelFit && f.value === "fits_lot_option"
  )
    ? false
    : "fits_lot_option";
  return {
    type: FilterType.ProductParcelFit,
    value: newValue,
    label: label,
  };
};

const checkWithProductLineFilter = (product: any, filterValue: string) => {
  return product.productGroupId === filterValue;
};

const checkWithBedOrBathFilter = (
  valueToCompare: number,
  product: any,
  filterValue: BedBathCountFilterValue
) => {
  switch (filterValue) {
    case BedBathCountFilterValue.Any:
      return true;
    case BedBathCountFilterValue.One:
      return valueToCompare === 1;
    case BedBathCountFilterValue.Two:
      return valueToCompare === 2;
    case BedBathCountFilterValue.Three:
      return valueToCompare === 3;
    case BedBathCountFilterValue.OnePlus:
      return valueToCompare >= 1;
    case BedBathCountFilterValue.TwoPlus:
      return valueToCompare >= 2;
    case BedBathCountFilterValue.ThreePlus:
      return valueToCompare >= 3;
    default:
      break;
  }
  return;
};

const checkWithProductCategoryFilter = (
  product: any,
  filterValue: ProductCategoryType
) => {
  if (filterValue === ProductCategoryType.INVENTORY_UNIT) {
    return !!product?.inventory_info;
  } else {
    if (filterValue !== ProductCategoryType.MODEL) {
      console.log("Unknown product category filter type");
    }
    return !product?.inventory_info;
  }
};

const evaluateNumberRangeFilter = (value: number, range: NumberRangeFilter) => {
  return value >= range.low && value <= range.high;
};

export const productSatisfiesFilters = (
  product: any,
  filters: any,
  productFitDictionary: IProductParcelFitDataDictionary | undefined
) => {
  // Make sure the product satisfies every type of filter
  let satisfiedCategories: {[key: string]: boolean} = {};
  Object.values(FilterType).forEach((type) => {
    satisfiedCategories[type] = !filters.find(
      (f: ICatalogFilter) => f.type === type
    );
  });
  filters.forEach((f: ICatalogFilter) => {
    switch (f.type) {
      case FilterType.Bedroom:
        if (
          checkWithBedOrBathFilter(
            product.productDetails?.bedrooms,
            product,
            f.value as BedBathCountFilterValue
          )
        ) {
          satisfiedCategories[FilterType.Bedroom] = true;
        }
        break;
      case FilterType.Bathroom:
        if (
          checkWithBedOrBathFilter(
            product.productDetails?.bathrooms,
            product,
            f.value as BedBathCountFilterValue
          )
        ) {
          satisfiedCategories[FilterType.Bathroom] = true;
        }
        break;
      case FilterType.UnitPriceRange:
        if (
          evaluateNumberRangeFilter(
            getProductPricingInfo(PricingMode.UNIT, product)?.price,
            f.value as NumberRangeFilter
          )
        )
          satisfiedCategories[FilterType.UnitPriceRange] = true;
        break;
      case FilterType.SquareFootage:
        if (
          evaluateNumberRangeFilter(
            product.productDetails?.squareFeet,
            f.value as NumberRangeFilter
          )
        )
          satisfiedCategories[FilterType.SquareFootage] = true;
        break;
      case FilterType.ProductLine:
        if (checkWithProductLineFilter(product, f.value as string))
          satisfiedCategories.product_line = true;
        break;
      case FilterType.ProductCategory:
        if (
          checkWithProductCategoryFilter(
            product,
            f.value as ProductCategoryType
          )
        )
          satisfiedCategories[FilterType.ProductCategory] = true;
        break;
      case FilterType.ProductParcelFit:
        if (
          !!product?.id &&
          !!productFitDictionary &&
          !!productFitDictionary[product.id] &&
          productFitDictionary[product.id]?.productInfoAndPlacement?.productFits
        ) {
          satisfiedCategories[FilterType.ProductParcelFit] = true;
        }
        break;
    }
  });
  return Object.values(satisfiedCategories).every((v: boolean) => v);
};

export const checkForFilter = (type: string, filters: ICatalogFilter[]) => {
  return !!filters.find((f: ICatalogFilter) => f.type === type);
};

export const checkForExactFilter = (
  type: string,
  label: string,
  filters: ICatalogFilter[]
) => {
  return !!filters.find(
    (f: ICatalogFilter) => f.type === type && f.label === label
  );
};
