import {
  STR_ACCESS_FULL,
  STR_ACCESS_NONE,
  STR_CONTACT_MANAGER,
  STR_FETCHING_PERMISSIONS,
} from "../../_shared/constants/statements";

const getWelcomeBackString = (name: string) => {
  return "Welcome back, " + name + "!";
};

export const getWelcomeMessage = (
  loggedInUser: any,
  isFetching: boolean,
  anyAccess: boolean,
  fullAccess: boolean
) => {
  if (loggedInUser) {
    const name = loggedInUser?.firstName ?? "Villain";
    const message = getWelcomeBackString(name);
    if (isFetching) {
      return message + " " + STR_FETCHING_PERMISSIONS;
    } else if (!anyAccess) {
      return message + "\n" + STR_ACCESS_NONE + "\n" + STR_CONTACT_MANAGER;
    } else if (fullAccess) {
      return message + "\n" + STR_ACCESS_FULL;
    } else {
      return message;
    }
  } else {
    return getWelcomeBackString("Villain");
  }
};
