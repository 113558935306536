import {isProd} from "../application";

export const MAX_COOKIE_EXPIRATION_DAYS = 365;

export const setCookie = (
  name: string,
  value: string,
  options: {
    expirationDays: number;
    rootDomain?: boolean;
  }
) => {
  const date = new Date();
  date.setTime(date.getTime() + options.expirationDays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + date.toUTCString();

  let cookieValue = `${name}=${value};${expires};path=/;`;

  if (options.rootDomain && isProd) {
    cookieValue += "domain=villahomes.com;";
  }

  document.cookie = cookieValue;

  return getCookie(name);
};

export const getCookie = (name: string): string | null => {
  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split("; ");
  let cookieValue = null;

  cookieArray.forEach((item) => {
    if (item.indexOf(cookieName) === 0) {
      cookieValue = item.substring(cookieName.length, item.length);
    }
  });

  return cookieValue;
};

export const checkCookie = (
  name: string,
  value: string,
  expirationDays: number
) => {
  const cookie = getCookie(name);

  if (cookie !== null) {
    return cookie;
  }

  return setCookie(name, value, {expirationDays});
};
