import styled from "styled-components";

export const OrderFormTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 6rem;
`;

export const OrderFormAssistanceContainer = styled.div`
  position: fixed;
  right: 2rem;
  top: calc(45px + 2rem);
  padding: 4px;
  border-radius: 6px;
  background-color: lightgrey;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  max-width: 18rem;
  width: 18rem;
  min-width: 18rem;
`;
