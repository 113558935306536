import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {IUploadProjectFileOptions, uploadProjectFile} from "../helpers";
import {IDocumentDetails, IMimeType} from "@natomas-org/core";
import {useDropzone} from "react-dropzone";
import {
  ProgressBarContainer,
  ProgressBarFill,
  ProgressBarText,
} from "../styles";
import {NatButton} from "../../button";
import {IconPosition, NatSize, StyleOption} from "../../_shared";
import {BsCloudUpload} from "react-icons/bs";
import {IFileUploadDetails} from "../schemas";

export const ProjectDocumentUploadButton = (
  props: IGenericProjectDocumentUploadProps
) => {
  const {
    projectId,
    onComplete,
    documentId,
    documentTitle,
    disabled,
    allowedFileTypes,
    showAllowedFileTypes,
    uploadOptions,
    allowMultiple,
    buttonName,
    noDrop,
  } = props;
  const [showBarNotButton, setShowBarNotButton] = useState(false);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const multiple = allowMultiple ?? false;
  const upload = useCallback(
    (file: IFileUploadDetails[]) => {
      if (file) {
        uploadProjectFile({
          ...uploadOptions,
          file: file?.[0],
          documentTitle,
          setProgressPercent,
          setShowProgress: setShowBarNotButton,
          onComplete: (documentDetails) => {
            onComplete(documentDetails);
          },
          projectId,
          documentId,
        });

        if (multiple && file) {
          file?.forEach((f, index) => {
            if (index !== 0) {
              uploadProjectFile({
                ...uploadOptions,
                file: f,
                documentTitle,
                onComplete: (documentDetails) => {
                  onComplete(documentDetails);
                },
                projectId,
                documentId: undefined,
              });
            }
          });
        }
      }
    },
    [documentId, documentTitle, multiple, onComplete, projectId, uploadOptions]
  );
  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      let invalidFileTypes = false;
      console.log(allowedFileTypes);
      if (allowedFileTypes) {
        console.log(allowedFileTypes);
        acceptedFiles?.forEach((file) => {
          if (!allowedFileTypes.includes(file.type)) {
            alert(
              `File type ${
                file.type
              } is not allowed. Please upload a file of type ${allowedFileTypes.join(
                ", "
              )}`
            );
            invalidFileTypes = true;
            return;
          }
        });
      }

      if (!invalidFileTypes) {
        upload(acceptedFiles);
      }
    },
    [allowedFileTypes, upload]
  );

  const {getRootProps, getInputProps} = useDropzone({
    multiple,
    onDrop,
    accept: allowedFileTypes ?? [
      IMimeType.PDF,
      IMimeType.CSV,
      IMimeType.DOC,
      IMimeType.DOCX,
      IMimeType.JPEG,
      IMimeType.JPG,
      IMimeType.PNG,
      IMimeType.PPT,
      IMimeType.PPTX,
      IMimeType.XLS,
      IMimeType.XLSX,
      IMimeType.ZIP,
    ],
  });
  const buttonUpload = (
    <NatButton
      style={props.buttonStyle}
      size={NatSize.NORMAL}
      option={StyleOption.PRIMARY_ALT}
      label={buttonName ?? `Upload file${multiple ? "s" : ""}`}
      type={"button"}
      spinnerEnabled={false}
      clickEvent={onDrop}
      disabled={showBarNotButton || disabled}
      icon={{
        icon: <BsCloudUpload />,
        iconPosition: IconPosition.LEFT,
      }}
    />
  );
  return (
    <Container style={props.style}>
      {showBarNotButton && (
        <ProgressBarContainer>
          <ProgressBarFill
            percentage={progressPercent}
            complete={progressPercent >= 100}
          />
          <ProgressBarText>
            {`${Math.round(progressPercent ?? 0)}%`}
          </ProgressBarText>
        </ProgressBarContainer>
      )}
      <DropZoneContainer {...getRootProps()} noDrop={noDrop}>
        <input {...getInputProps()} />
        <br />
        {buttonUpload}
      </DropZoneContainer>
      <div hidden={!showAllowedFileTypes}>
        Valid filetypes: .pdf, .csv, .doc, .docx, .jpeg, .jpg, .png, .ppt,
        .pptx, .xls, .xlsx, .zip
      </div>
    </Container>
  );
};

const Container = styled.div``;

const DropZoneContainer = styled.div<{noDrop?: boolean}>`
  padding: ${(props) => (props.noDrop ? undefined : "2rem")};
  border: ${(props) => (props.noDrop ? undefined : "2px dashed #ccc")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ccc;
`;

interface IGenericProjectDocumentUploadProps {
  documentTitle: string | null | undefined;
  documentId: string | null | undefined;
  projectId: string;
  onComplete: (documentDetails: IDocumentDetails | null) => void;
  disabled?: boolean;
  allowedFileTypes?: IMimeType[];
  showAllowedFileTypes?: boolean;
  uploadOptions?: IUploadProjectFileOptions;
  allowMultiple?: boolean;
  buttonName?: string;
  noDrop?: boolean;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
}
