import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchImage} from "../../../database/firebase/images/fetching";
import {IStore} from "../slices/types/Store";

export const useImage = (imageId?: string | null) => {
  const image = useSelector(
    (state: IStore) => state.global.images[imageId ?? ""]
  );

  useEffect(() => {
    if (imageId && image == null) {
      fetchImage(imageId, false);
    }
  }, [image, imageId]);

  return image;
};

function preloadImage(src: string) {
  console.log("Preloading image: " + src);
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      console.log("Preloaded image: " + src);
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
      console.log("Error preloading image: " + src);
    };
    img.src = src;
  });
}

export function useImagePreloader(imageURL: string) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      await preloadImage(imageURL);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageURL]);

  return {imagesPreloaded};
}
