import {IProductImage} from "@natomas-org/core";
import {IDimensions} from "../../_shared/FactoryInventoryInput/DimensionsInput";
import {ITimelinePeriod} from "../../_shared/FactoryInventoryInput/TimelinePeriodInput";

export enum FactoryInventoryCatalogEditorKey {
  TITLE = "title",
  BASE_DESC = "baseDescription",
  INTERIOR_DESC = "interiorSectionDescription",
  EXTERIOR_DESC = "exteriorSectionDescription",
  IMAGES = "images",
  DEFAULT_IMAGE_ID = "defaultImageId",
  BEDROOM_COUNT = "bedroomCount",
  BATHROOM_COUNT = "bathroomCount",
  SQUARE_FOOTAGE = "squareFootage",
  DIMENSIONS = "dimensions",
  TIMELINE = "timeline",
  TAGLINE = "tagline",
}

export interface IFactoryInventoryCatalogEditor {
  [FactoryInventoryCatalogEditorKey.TITLE]?: string;
  [FactoryInventoryCatalogEditorKey.TAGLINE]?: string;
  [FactoryInventoryCatalogEditorKey.BASE_DESC]?: string;
  [FactoryInventoryCatalogEditorKey.INTERIOR_DESC]?: string;
  [FactoryInventoryCatalogEditorKey.EXTERIOR_DESC]?: string;
  [FactoryInventoryCatalogEditorKey.IMAGES]?: IProductImage[];
  [FactoryInventoryCatalogEditorKey.DEFAULT_IMAGE_ID]?: string;
  [FactoryInventoryCatalogEditorKey.BEDROOM_COUNT]?: number;
  [FactoryInventoryCatalogEditorKey.BATHROOM_COUNT]?: number;
  [FactoryInventoryCatalogEditorKey.SQUARE_FOOTAGE]?: number;
  [FactoryInventoryCatalogEditorKey.DIMENSIONS]?: IDimensions;
  [FactoryInventoryCatalogEditorKey.TIMELINE]?: ITimelinePeriod[];
}
