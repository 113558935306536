import React from "react";
import {IImageDetails} from "@natomas-org/core";
import {SmartImage} from "../../../generics/image/SmartImage";
import styled from "styled-components";
import {DIVIDER_COLOR} from "../../../colors";

const ImageGalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
`;

const ImageContainer = styled.div`
  height: 24rem;
  position: relative;
  min-width: 16rem;
  width: calc(33% - 1rem);
  flex-grow: 1;
  //border: 1px solid ${DIVIDER_COLOR};
  border-radius: 1rem;
  overflow: hidden;
`;

export const ImageGallery = (props: {images: IImageDetails[]}) => {
  const {images} = props;
  return (
    <ImageGalleryContainer>
      {images?.map((i: IImageDetails) => {
        return (
          <ImageContainer key={`image-gallery-${i.imageId}`}>
            <SmartImage imageId={i.imageId} />
          </ImageContainer>
        );
      })}
    </ImageGalleryContainer>
  );
};
