import styled from "styled-components";
import {
  BLACK,
  DIVIDER_COLOR,
  GRAY,
  WHITE,
} from "../../../../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const OrderFormModifierContent = styled.div`
  font-size: 0.7rem;
  font-style: italic;
`;

export const OrderFormAlertBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0.75rem;
  background-color: ${WHITE};
`;

export const OrderFormTableControllerContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: ${WHITE};
  border-radius: 0 0 4px 4px;
`;

export const OrderFormAlertItem = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${DIVIDER_COLOR};
  &:last-child {
    border: 0;
  }
`;

export const OrderFormAlertItemIcon = styled.div`
  min-width: 1rem;
`;

export const OrderFormAlertItemLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const OrderFormAlertItemLabelInfo = styled.div`
  margin-left: 0.5rem;
  font-size: 0.7rem;
  color: ${GRAY};
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const OrderFormAlertItemLabelTitle = styled.div`
  font-size: 0.8rem;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const OrderFormAlertHeader = styled.div`
  width: 100%;
  font-size: 1rem;
  padding: 0.25rem 0 0.5rem 0;
  border-bottom: 1px solid ${DIVIDER_COLOR};
  color: orangered;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const OrderFormTableControllerHeader = styled.div`
  font-size: 1rem;
  color: ${BLACK};
  font-family: ${FONT_FAMILY_BOLD};
`;
