import React from "react";
import {PageRoot} from "./styles";
import {usePage} from "../../hooks/usePage";

type PageProps = {
  children: any;
  formattingEnabled?: boolean;
  style?: {};
  id?: string;
};

export const Page = ({
  style,
  formattingEnabled = true,
  children,
  id,
}: PageProps) => {
  const {leftPadding} = usePage();

  const getPaddingString = () => {
    return "0 0 0 " + leftPadding + "px";
  };
  return (
    <>
      <PageRoot
        style={style}
        enabled={formattingEnabled}
        padding={getPaddingString()}
        id={id}
      >
        {children}
      </PageRoot>
    </>
  );
};
