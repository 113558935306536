import styled from "styled-components";
import {DROPDOWN_Z_INDEX} from "../../../../_shared/styles";

export const MatrixTableWrapper = styled.div<{heightOffset?: string}>`
  height: calc(100vh - ${(props) => props.heightOffset ?? "70px"});
  width: 100%;
  overflow: auto;
`;

export const MatrixTableHeaderRow = styled.tr`
  top: 0;
  position: sticky;
  z-index: 2;
  td {
    vertical-align: middle !important;
  }
`;

export const MatrixTabControls = styled.div`
  height: 100%;
  display: flex;
  flex-grow: 1;
  margin-left: 0.5rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  margin-right: 0.5rem;
`;

styled(MatrixTabControls)`
  padding: 0.5rem 0;
  z-index: ${DROPDOWN_Z_INDEX};
`;
