import styled from "styled-components";

export const PriceCardContainer = styled.div<{hideShadow?: boolean}>`
  background: #ffffff;
  ${(props) =>
    props.hideShadow ? "" : "box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1)"};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) => (props.hideShadow ? "0 0" : "1rem 1rem")};
  gap: 1rem;
`;
