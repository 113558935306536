import {useFactory} from "./useFactory";
import {IFactory} from "../../../slices/FactorySlice";
import {IFactoryInfo} from "@natomas-org/core";

export const useFactoryInfo = (
  factoryId?: string
): IFactoryInfo | undefined => {
  const factory: IFactory | undefined = useFactory(factoryId);
  return factory?.info;
};
