import {NatomasMapFeatureType} from "@natomas-org/core";
import {setEligibilityMap} from "../../components/_shared/slices/CatalogSlice/CatalogSessionSlice";
import {useAddress} from "../../components/_shared/hooks/useAddress";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {useGlobalConfiguration} from "../../components/_shared/hooks/useGlobalConfiguration";
import {getEligibilityFeatures} from "./logic";

const featureTypes: [NatomasMapFeatureType, ...NatomasMapFeatureType[]] = [
  NatomasMapFeatureType.PRODUCT,
  NatomasMapFeatureType.FACTORY_LINE,
];

export const CatalogSessionEligibilityManager = (): null => {
  const {USE_FACTORY_ROUTING} = useGlobalConfiguration();
  const {catalogAddress: address} = useAddress();
  const dispatch = useDispatch();
  const {latitude, longitude} = address ?? {};

  useEffect(() => {
    if (!USE_FACTORY_ROUTING) {
      return;
    }
    getEligibilityFeatures(featureTypes, latitude, longitude).then((r) => {
      if (r) {
        dispatch(setEligibilityMap(r));
      }
    });
  }, [USE_FACTORY_ROUTING, dispatch, latitude, longitude]);

  return null;
};
