import {useDispatch, useSelector} from "react-redux";
import {
  editCategory,
  editModifierGroupOverrides,
} from "../slices/CatalogSlice/AdminStudioManagerSlice";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {OverrideModifierGroupStructure} from "../../admin/_models/modifierGroup";
import {getFormInitialValues, getFormStructure} from "../application";
import {AdminForm} from "../../admin/_shared/styles/AdminStyles";
import {AdminInput} from "../../admin/_shared/components/AdminComponentFactory/AdminComponentFactory";
import {StyleOption} from "../generics/_shared";
import {NatModal} from "../generics/modal/NatModal";
import {useAdminProductCatalog} from "../hooks/admin-only-hooks/useAdminProductCatalog";

const convertToDependencyStructure = (requiredModifiers) => {
  return {
    dependencies: [
      {
        condition: "all",
        requiredModifiers: requiredModifiers,
        visibility: "show",
        tableData: {id: 0},
      },
    ],
  };
};

export const EditModifierGroupOverrides = (props) => {
  const {callback} = props;
  const modifierGroup = useSelector(
    (state) => state.adminStudioManager.editingModifierGroupOverrides
  );
  const {updateCategory} = useAdminProductCatalog();
  const currentCategory = useSelector(
    (state) => state.adminStudioManager.editingCategory
  );

  const dispatch = useDispatch();
  const close = () => {
    dispatch(editModifierGroupOverrides(null));
  };

  const setOverride = (overrideMap) => {
    let overrides = {};
    if (currentCategory?.modifierGroupOverrides != null) {
      overrides = currentCategory.modifierGroupOverrides;
    }
    let editOverrides = {};
    let overwriteKey = false;
    Object.keys(overrides)?.forEach((key) => {
      if (modifierGroup.id === key) {
        if (overrideMap !== null) {
          overwriteKey = true;
          editOverrides[key] = overrideMap;
        }
      } else {
        let otherModifierGroupOverrides = overrides[key];

        // Convert all other overrides if not in new structure
        if (otherModifierGroupOverrides.requiredModifiers) {
          otherModifierGroupOverrides = convertToDependencyStructure(
            otherModifierGroupOverrides.requiredModifiers
          );
        }

        editOverrides[key] = otherModifierGroupOverrides;
      }
    });
    if (!overwriteKey && overrideMap !== null) {
      editOverrides[modifierGroup.id] = overrideMap;
    }
    const payload = {modifierGroupOverrides: editOverrides};
    updateCategory(currentCategory?.id, payload);
    callback(payload);
    dispatch(editCategory(Object.assign({...currentCategory}, payload)));
  };

  const deleteModifierGroupAction = (e) => {
    e.preventDefault();
    setOverride(null);
    close();
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(OverrideModifierGroupStructure),
    onSubmit: (values) => {
      const modifierGroupToSave = Object.assign({}, values);
      const reqModifiers = values["requiredModifiers"];
      if (reqModifiers != null && Object.keys(reqModifiers).length > 0) {
        modifierGroupToSave.requiredModifiers = reqModifiers;
      } else {
        modifierGroupToSave.requiredModifiers = null;
      }

      setOverride(modifierGroupToSave);
      close();
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (modifierGroup != null && currentCategory != null) {
      let overrideValues = {};
      if (
        currentCategory.modifierGroupOverrides != null &&
        currentCategory.modifierGroupOverrides[modifierGroup.id] != null
      ) {
        overrideValues =
          currentCategory.modifierGroupOverrides[modifierGroup.id];
        // Convert overrides to new dependency structure
        if (overrideValues.requiredModifiers) {
          overrideValues = convertToDependencyStructure(
            overrideValues.requiredModifiers
          );
        }
      }
      for (const key in OverrideModifierGroupStructure) {
        if (overrideValues[key] != null) {
          const value = overrideValues[key];
          formik.setFieldValue(key, value);
        }
      }
    }
    // DO NOT ADD FORMIK AS A DEPENDENCY
  }, [modifierGroup, currentCategory]);

  if (modifierGroup == null || currentCategory == null) {
    return null;
  }

  const editingInputStructure = getFormStructure(
    OverrideModifierGroupStructure
  );
  return (
    <NatModal
      show={true}
      customSize={{
        width: "60rem",
        height: "40rem",
      }}
      handleClose={close}
      content={
        <AdminForm onSubmit={formik.handleSubmit}>
          {editingInputStructure.map((keyInfo) => {
            const {key} = keyInfo;
            const value = formik.values[key];

            return (
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                onChange={(value) => {
                  formik.setFieldValue(key, value);
                }}
                handleChange={formik.handleChange}
              />
            );
          })}
        </AdminForm>
      }
      footerButtons={[
        {
          label: "Remove all overrides",
          type: "button",
          disabled: formik.values["dependencies"]?.length === 0,
          clickEvent: deleteModifierGroupAction,
          option: StyleOption.DESTRUCTIVE,
        },
        {
          label: "Save",
          type: "button",
          disabled: formik.values["dependencies"]?.length === 0,
          clickEvent: () => formik.handleSubmit(),
          option: StyleOption.PRIMARY_ALT,
        },
      ]}
    />
  );
};
