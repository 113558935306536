import {
  flattenCategory,
  flattenModifier,
  flattenModifierGroup,
} from "../../../formik/initialize";
import {anonymotizeValue} from "./anonymotize";
import {mergeObjects} from "@natomas-org/core";

/*
 * To import studio components into Inventory Editor, and prevent errors
 * We need to recursively import the dependencies of each, and anonymotize them
 * */

export const importModifier = (
  raw: any,
  index: number = 0,
  skipFlatten?: boolean
) => {
  const base = {...anonymotizeValue(raw), index: index};
  if (skipFlatten) {
    return base;
  } else {
    return flattenModifier(base);
  }
};

export const importModifierGroup = (
  raw: any,
  modifierLibrary: any,
  index: number = 0,
  skipFlatten?: boolean
) => {
  const base = flattenModifierGroup(anonymotizeValue(raw), index);
  const filled = mergeObjects(base, {
    index: index,
    fullModifiers: raw?.modifiers?.map((id: string, index: number) => {
      return importModifier(modifierLibrary?.[id], index, skipFlatten);
    }),
  });
  if (skipFlatten) {
    return filled;
  } else {
    return flattenModifierGroup(filled);
  }
};
export const importCategory = (
  raw: any,
  modifierGroupLibrary: any,
  modifierLibrary: any,
  index: number = 0
) => {
  const base = anonymotizeValue(raw);
  const filled = mergeObjects(base, {
    fullModifierGroups: raw?.modifierGroups?.map((id: string, index: number) =>
      importModifierGroup(
        modifierGroupLibrary?.[id],
        modifierLibrary,
        index,
        true
      )
    ),
  });
  return flattenCategory(filled, index);
};
