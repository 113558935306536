import React from "react";
import {
  AdminViewCardContainer,
  AdminViewCardTitle,
  AdminViewCardWrapper,
} from "./styles";

export enum AdminViewCardSize {
  SMALL,
  NORMAL,
  LARGE,
}

export interface AdminViewCardProps {
  title: string;
  icon: JSX.Element;
  clickEvent: () => void;
  size?: AdminViewCardSize;
}

export const AdminViewCard = (props: AdminViewCardProps) => {
  const {title, icon, clickEvent, size} = props;

  const s =
    size === AdminViewCardSize.LARGE
      ? 12
      : size === AdminViewCardSize.SMALL
      ? 8
      : 10;

  return (
    <AdminViewCardWrapper sizeRem={s}>
      <AdminViewCardContainer onClick={() => clickEvent()}>
        {icon}
        <AdminViewCardTitle>{title}</AdminViewCardTitle>
      </AdminViewCardContainer>
    </AdminViewCardWrapper>
  );
};
