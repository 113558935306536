import {
  Address,
  DateUtils,
  DEFAULT_SURVEY_ANSWERS,
  ICoordinatesRequest,
  IProduct,
  IProductInstantQuoteDataDictionary,
  IProductParcelFitDataDictionary,
  IProductParcelFitRequest,
} from "@natomas-org/core";
import {executeRequest} from "../../../../database/firebase/api";
import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useAllPublishedAndRoutedProducts} from "../useProductCatalog/useProducts";
import {useQuery} from "@tanstack/react-query";
import {useIsPublicApp} from "../useIsPublicApp";
import {useAddress} from "../useAddress";
import {usePropertyData} from "../usePropertyData/usePropertyData";

const sanitizeProductParcelFitResponse = (
  products: IProduct[],
  response: IProductParcelFitDataDictionary | null
) => {
  // If we receive an incorrect number of product-fits or null, return a manufactured no products fit object
  if (!response || Object.keys(response)?.length !== products?.length) {
    const productPlacementMap: IProductParcelFitDataDictionary = {};
    products.forEach((product: IProduct) => {
      if (!product.id) {
        return;
      }
    });
    return productPlacementMap;
  }
  return response;
};

const useProductLotFit = () => {
  const isPublic = useIsPublicApp();
  const {catalogAddress} = useAddress();
  const {catalogPropertyData} = usePropertyData();
  const surveyAnswersProject = useSelector(
    (state: IStore) =>
      state.configuration.fetchedConfigurationSite?.surveyAnswers
  );
  const surveyAnswers = isPublic
    ? DEFAULT_SURVEY_ANSWERS
    : surveyAnswersProject;
  const products = useAllPublishedAndRoutedProducts();
  let fitRequestCoordinates: ICoordinatesRequest | undefined = undefined;
  if (
    catalogPropertyData?.address?.latitude &&
    catalogPropertyData?.address?.longitude
  ) {
    fitRequestCoordinates = {
      lat: catalogPropertyData?.address?.latitude?.toString(),
      long: catalogPropertyData?.address?.longitude?.toString(),
    };
  }
  const fitRequest: IProductParcelFitRequest = {
    address: catalogPropertyData?.address ?? undefined,
    products: products,
    jurisdictionData: catalogPropertyData?.jurisdictionData,
    propertyGeometryData: catalogPropertyData?.propertyGeometry ?? undefined,
    surveyAnswers: surveyAnswers,
  };

  const productIds = products.map((product) => product?.id ?? "").sort();
  const productParcelFitQueryKeys = [
    "fetch-product-parcel-fit",
    ...productIds,
    fitRequest?.address?.id ?? "",
    `${fitRequest.jurisdictionData?.placementConstraints?.sideAndRearSetBacks}`,
    `${fitRequest.jurisdictionData?.placementConstraints?.frontSetback}`,
    `${fitRequest.jurisdictionData?.placementConstraints?.primarySetBacks}`,
  ];
  const addressMatch =
    !!catalogAddress &&
    Address.getId(catalogAddress) === Address.getId(fitRequest?.address);
  const requestReadyPlaceProducts: boolean =
    !Address.isInvalidAddress(fitRequest.address) &&
    addressMatch &&
    !!fitRequestCoordinates &&
    !!fitRequest.address &&
    !!fitRequest.jurisdictionData &&
    fitRequest.products.length > 0;
  const {data: productFitsOnLotData} = useQuery({
    queryKey: productParcelFitQueryKeys,
    keepPreviousData: true,
    staleTime: DateUtils.convertDaysToMilliseconds(1),
    queryFn: () => {
      if (!requestReadyPlaceProducts) {
        return null;
      }
      return executeRequest("/mapping/v1/place-products", fitRequest)
        .then(
          (
            fetchedProductParcelFitDictionary: IProductParcelFitDataDictionary
          ) => {
            return sanitizeProductParcelFitResponse(
              products,
              fetchedProductParcelFitDictionary
            );
          }
        )
        .catch((e) => {
          console.log(e);
          return null;
        });
    },
  });
  let quoteRequestCoordinates: ICoordinatesRequest | undefined = undefined;
  let quoteRequest: IProductParcelFitRequest = {
    address: catalogAddress ?? undefined,
    products: products,
    jurisdictionData: undefined,
    propertyGeometryData: undefined,
    surveyAnswers: surveyAnswers,
  };
  if (
    !!catalogAddress &&
    Address.getId(catalogAddress) ===
      Address.getId(catalogPropertyData?.address)
  ) {
    // If the address we are trying to request matches the property info in state
    //  then we will use the property data in the request. Otherwise, they'll be
    //  undefined for quote products
    quoteRequest = {
      address: catalogPropertyData?.address,
      products: products,
      jurisdictionData: catalogPropertyData?.jurisdictionData,
      propertyGeometryData: catalogPropertyData?.propertyGeometry ?? undefined,
      surveyAnswers: surveyAnswers,
    };
  }
  if (quoteRequest?.address?.latitude && quoteRequest?.address?.longitude) {
    quoteRequestCoordinates = {
      lat: quoteRequest?.address?.latitude?.toString(),
      long: quoteRequest?.address?.longitude?.toString(),
    };
  }
  const requestReadyQuoteProducts: boolean =
    !!quoteRequestCoordinates && quoteRequest.products.length > 0;
  const queryKeysForQuote = [
    "fetch-product-quote",
    ...productIds,
    quoteRequest.surveyAnswers ?? {},
    quoteRequest?.address?.id ?? "",
    quoteRequest?.jurisdictionData?.jurisdiction?.jurisdictionId ?? "",
  ];
  const {data: productInstantQuoteData} = useQuery({
    queryKey: queryKeysForQuote,
    // TODO keep an eye on this staleTime
    staleTime: DateUtils.convertDaysToMilliseconds(1),
    keepPreviousData: true,
    queryFn: () => {
      if (!requestReadyQuoteProducts) {
        return null;
      }
      return executeRequest("/catalog/v1/quote-products", quoteRequest)
        .then(
          (
            productInstantQuoteDataDictionary: IProductInstantQuoteDataDictionary
          ) => {
            return productInstantQuoteDataDictionary;
          }
        )
        .catch((e) => {
          console.log(e);
          return null;
        });
    },
  });
  return {
    productInstantQuoteData,
    productFitsOnLotData,
  };
};

export const useProductFitsOnLot = (options: {
  productId: string | undefined;
}) => {
  const {productFitsOnLotData} = useProductLotFit();
  let fitsOnLotData = undefined;
  if (options.productId && productFitsOnLotData) {
    fitsOnLotData = productFitsOnLotData[options.productId];
  }
  return {productFitsOnLotData, fitsOnLotData};
};

export const useProductSiteWork = (options: {
  productId: string | undefined;
}) => {
  const {productInstantQuoteData} = useProductLotFit();
  let instantQuoteData = undefined;
  if (options.productId && productInstantQuoteData) {
    instantQuoteData = productInstantQuoteData[options.productId];
  }
  return {productInstantQuoteData, instantQuoteData};
};
