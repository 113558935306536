export enum CatalogStateOptions {
  CATEGORIES = "categories",
  FACTORY_LINES = "productGroups",
  MODIFIERS = "modifiers",
  MODIFIER_GROUPS = "modifierGroups",
  OPTION_KEYS = "optionKeys",
  OPTION_VALUES = "optionValues",
  PRODUCTS = "products",
  PRODUCT_MODIFIERS = "productModifiers",
}

export enum IFetchingDocumentType {
  FACTORY_LINE_PRODUCTS = "FACTORY_LINE_PRODUCTS",
}

export enum IFetchingStatus {
  UNKNOWN,
  FETCHING,
  COMPLETE,
}
