import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {NatEditIcon} from "../../../../../../../_shared/icon/icons";
import React from "react";

export const EditButton = (props: {edit: () => void; isValid: boolean}) => {
  return (
    <NatButton
      label={"Edit"}
      clickEvent={props.edit}
      option={props.isValid ? StyleOption.PRIMARY_ALT : StyleOption.DESTRUCTIVE}
      size={NatSize.SMALL}
      icon={{icon: <NatEditIcon />, iconPosition: IconPosition.ONLY}}
    />
  );
};
