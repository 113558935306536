import React from "react";
import {StudioItemLineContainer} from "./styles";

export const StudioItemLine = (props: any) => {
  const {leftText, rightText, id, ...otherProps} = props;

  return (
    <StudioItemLineContainer id={id}>
      <div {...otherProps}>{leftText}</div>
      <div>{rightText}</div>
    </StudioItemLineContainer>
  );
};
