import {getPolygonSetFeature, NatomasMapFeatureState} from "./formatting";
import {getAllLayers} from "./layers";
import {NatomasMapFeature, NatomasMappingFeatureZones} from "@natomas-org/core";

export const initializeMap = (
  map: any,
  polygonSet: NatomasMappingFeatureZones | null,
  otherFeatures?: NatomasMapFeature[]
) => {
  let features = [];
  if (polygonSet) {
    const polygons = getPolygonSetFeature(
      polygonSet,
      NatomasMapFeatureState.SAVED
    );
    features.push(...polygons);
  }
  if (otherFeatures) {
    const others = getOtherFeaturesPolygons(otherFeatures);
    features.push(...others);
  }
  // Add a data source containing GeoJSON data.
  // @ts-ignore
  map.current.addSource("polygon-coordinates", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: features,
    },
  });

  // Add a new layer to visualize the polygon.
  // @ts-ignore
  const layers = getAllLayers();
  layers.forEach((layer: any) => {
    // @ts-ignore
    map.current.addLayer(layer);
  });
};

export const getOtherFeaturesPolygons = (
  otherFeatures: NatomasMapFeature[]
) => {
  return otherFeatures
    ?.map((f: NatomasMapFeature) => {
      return getPolygonSetFeature(
        f?.zones ?? null,
        NatomasMapFeatureState.OTHER
      );
    })
    .flat();
};
