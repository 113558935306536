import React from "react";
import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../portal/views/SSDDashboardView/styles/globals";

// type EstimateTable = {
//   title: string;
//   priceLow: string;
//   priceHigh: string;
//   note: string;
// };
export type TableRowProps<T> = {
  [K in keyof T]: string;
} & {
  _style?: GenericTableProps;
};
interface TableProps<T extends Record<string, string>> {
  columns: T;
  rows: Array<TableRowProps<T>>;
  columnStyles?: {
    [K in keyof T]: GenericTableProps;
  };
  getDynamicCellStyle?: (columnKey: string, row: any) => GenericTableCellProps;
}

const StaticTable: React.FC<TableProps<any>> = ({
  rows,
  columns,
  columnStyles,
  getDynamicCellStyle,
}) => {
  const columnKeys = Object.keys(columns);
  const columnTitles: string[] = Object.values(columns);
  return (
    <Table>
      <Tr>
        {columnTitles.map((columnTitle, index) => (
          <Th
            backgroundColor={"#F9FAFA"}
            textColor={"#807D7A"}
            key={`thead-${index}`}
          >
            {columnTitle}
          </Th>
        ))}
      </Tr>
      {rows.map((row, index) => (
        // Conditionally render rows based on the 'isActive' property
        <Tr {...row?._style} key={`row-${index}`}>
          {columnKeys.map((columnKey, index) => {
            const {style, alert} = getDynamicCellStyle?.(columnKey, row) ?? {
              style: undefined,
              alert: undefined,
            };
            const columnStyle = columnStyles?.[columnKey];

            const {label, ...rest} = alert ?? {label: undefined};
            return (
              <Td {...columnStyle} {...style} key={`row-${index}-${columnKey}`}>
                <CellContainer>
                  {row[columnKey]}
                  <Alert hidden={!alert} {...rest}>
                    {label}
                  </Alert>
                </CellContainer>
              </Td>
            );
          })}
        </Tr>
      ))}
    </Table>
  );
};

export interface GenericTableCellProps {
  style?: GenericTableProps;
  alert?: GenericTableCellAlertProps;
}

export interface GenericTableProps {
  backgroundColor?: string;
  textColor?: string;
  fontWeight?: string;
}
export interface GenericTableCellAlertProps extends GenericTableProps {
  label?: string;
}

const GenericStyles = ({
  backgroundColor,
  textColor,
  fontWeight,
}: GenericTableProps) => `
  background-color: ${backgroundColor};
  color: ${textColor};
  font-weight: ${fontWeight};
`;

const Table = styled.table<GenericTableProps>`
  // Formatting
  border-collapse: separate;
  border-spacing: 0;
  overflow: clip;
  margin: 0;
  border: 1px solid #dddddd;
  border-radius: 10px;
  ${GenericStyles}
`;

const Th = styled.th<GenericTableProps>`
  border-left: 1px solid #dddddd;
  &:first-child {
    border-left: none;
  }
  padding: 16px;
  text-align: left;
  white-space: nowrap;
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 25%;
  ${GenericStyles}
`;

const Tr = styled.tr<GenericTableProps>`
  ${GenericStyles}
`;

const Td = styled.td<GenericTableProps>`
  border-left: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  &:first-child {
    border-left: none;
  }
  width: 25%;
  padding: 16px;
  text-align: left;
  white-space: pre-wrap;
  ${GenericStyles}
`;

const Alert = styled.div<GenericTableProps>`
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  ${GenericStyles}
`;

const CellContainer = styled.div<GenericTableProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export default StaticTable;
