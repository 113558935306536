import React, {Dispatch, SetStateAction, useEffect, useMemo} from "react";
import {useAdminProductCatalog} from "../../../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../../../_shared/hooks/useDynamicValue";
import {Header} from "../../../../../../_shared/generics/header";
import {MatrixTabControls} from "../../styles";
import {MatrixModeDropdown} from "../../../../components/MatrixModeDropdown";
import {NavigationButtons} from "../../../../../../_shared/generics/header/styles";
import {AdminBaseActions} from "../../../../components/Header/AdminBaseActions";
import {saveModifierChanges, saveProductChanges} from "./helper";
import {MatrixTableTransposeButton} from "../MatrixTableTransposeButton";
import {MatrixTableSearchInput} from "../MatrixTableSearchInput";
import {AdminProductCatalogMatrixModes} from "../../../../../../_shared/slices/types/Store";
import {MatrixPriceReviewSummary} from "../../index";
import PriceReviewButton from "../MatrixTablePricingReview/PriceReviewButton";

export interface MatrixTabHeaderProps {
  matrixEdits: any;
  setMatrixEdits: Dispatch<SetStateAction<any>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
  setTranspose: Dispatch<SetStateAction<boolean>>;
  setShowWarningPanel: Dispatch<SetStateAction<boolean>>;
  warningSummary: MatrixPriceReviewSummary;
  transpose: boolean;
  subTabMode: boolean;
  showingWarningPanel: boolean;
}

/*
 * MatrixTabHeader holds the components
 * that are responsible for manipulating the table.
 * The 'subTabMode' has reduced functionality:
 * - reduced table modes,
 * - reduced filter ability
 * - requires external matrix edit management
 * */
export const MatrixTabHeader = (props: MatrixTabHeaderProps) => {
  const {
    matrixEdits,
    setMatrixEdits,
    setLoading,
    setSearchText,
    setTranspose,
    transpose,
    subTabMode,
    warningSummary,
    showingWarningPanel,
    setShowWarningPanel,
  } = props;
  // Hooks
  const {updateModifiersInBulk, updateProduct, matrixMode} =
    useAdminProductCatalog();
  const width = useDynamicValue(fullColumnWidths);

  // Local Functions
  const saveChanges = () => {
    // Currently there is only one table
    // that is not composed for product/modifier
    if (matrixMode === AdminProductCatalogMatrixModes.PRODUCT_INFO) {
      saveProductChanges(
        matrixEdits,
        setLoading,
        setMatrixEdits,
        updateProduct
      );
    } else {
      saveModifierChanges(
        matrixEdits,
        setLoading,
        setMatrixEdits,
        updateModifiersInBulk
      );
    }
  };

  const discardChanges = () => {
    setMatrixEdits(null);
  };

  // Memoized values
  const editCount = useMemo(() => {
    if (matrixEdits) {
      return Object.keys(matrixEdits)?.length;
    } else {
      return 0;
    }
  }, [matrixEdits]);

  const saveLabel = useMemo(() => {
    if (warningSummary?.errors > 0) {
      return "*Errors*";
    } else {
      return editCount ? "(" + editCount + ")" : undefined;
    }
  }, [editCount, warningSummary?.errors]);

  // Callback effects
  useEffect(() => {
    if (matrixEdits == null) {
      setLoading(false);
    }
  }, [matrixEdits]);

  return (
    <>
      <Header
        hasBorder={true}
        width={width}
        height={"4rem"}
        noPadding={true}
        hideProgressBar={true}
      >
        <MatrixTabControls hidden={showingWarningPanel}>
          <MatrixModeDropdown
            hasEdits={!!matrixEdits}
            subTabMode={subTabMode}
          />
          <MatrixTableTransposeButton
            transpose={transpose}
            setTranspose={setTranspose}
          />
          <MatrixTableSearchInput setSearchText={setSearchText} />
        </MatrixTabControls>
        <div style={{flexGrow: 1}} hidden={!showingWarningPanel} />
        <NavigationButtons hidden={subTabMode}>
          {matrixMode === AdminProductCatalogMatrixModes.PRICE_SET && (
            <PriceReviewButton
              summary={warningSummary}
              setShowing={setShowWarningPanel}
              showing={showingWarningPanel}
            />
          )}
          <AdminBaseActions
            saveLabelAddition={saveLabel}
            saveAction={saveChanges}
            discardAction={discardChanges}
            isUpToDate={matrixEdits == null}
          />
        </NavigationButtons>
      </Header>
    </>
  );
};
