import React, {useMemo, useState} from "react";
import styled from "styled-components";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import {EstimateTables} from "./tables";
import EstimateTableDescription from "./EstimateTableDescription";
import EstimateTableHeader from "./EstimateTableHeader";
import EstimateTableTabs from "../_shared/EstimateTableTabs";
import EstimateTableTotalRange from "./EstimateTableTotalRange";
import StaticTable, {GenericTableCellProps} from "../StaticTable";
import useLatestEstimate from "../useLatestEstimate";
import {GenericLoadingComponent} from "../../portal/views/ProposalBudgetView/components/generic/GenericLoadingComponent";
import {useDynamicValue} from "../../_shared/hooks/useDynamicValue";
import {convertLatestEstimateResponseToTableRows} from "../_shared/rangeToRows";
import {EstimateTableConfiguration} from "../_shared/table";
import useActiveProject from "../../_shared/hooks/useProject/useActiveProject";

interface ICustomerEstimateTableCell {
  title: string;
  priceLow: string;
  priceHigh: string;
  note?: string;
}

const MobileTableColumns: ICustomerEstimateTableCell = {
  title: "Title",
  priceLow: "Price (Low)",
  priceHigh: "Price (High)",
};

const DesktopTableColumns: ICustomerEstimateTableCell = {
  title: "Title",
  priceLow: "Price (Low)",
  priceHigh: "Price (High)",
  note: "Notes & Comments",
};

const CustomerEstimateTable = () => {
  const {response, status} = useLatestEstimate();
  const {site} = useActiveProject();
  const columns: ICustomerEstimateTableCell = useDynamicValue({
    forFour: MobileTableColumns,
    forEight: DesktopTableColumns,
    forTwelve: DesktopTableColumns,
    forSixteen: DesktopTableColumns,
  });
  const hideTable = useDynamicValue({
    forFour: true,
    forEight: false,
    forTwelve: false,
    forSixteen: false,
  });
  const [tableConfiguration, setTableConfiguration] =
    useState<EstimateTableConfiguration>(EstimateTables.PC);

  const [hideEmptyPrices, setHideEmptyPrices] = useState(true);

  const rows = useMemo(() => {
    return convertLatestEstimateResponseToTableRows(
      response,
      tableConfiguration.key,
      {
        label: tableConfiguration.label,
        hideEmptyPrices: hideEmptyPrices,
      }
    );
  }, [
    hideEmptyPrices,
    response,
    tableConfiguration.key,
    tableConfiguration.label,
  ]);
  if (!site?.visible) {
    return null;
  }
  if (status.fetching) {
    return (
      <>
        <GenericLoadingComponent />
      </>
    );
  }

  if (!response) {
    return <>No estimate available at this time.</>;
  }

  return (
    <ErrorBoundary>
      {/*<button*/}
      {/*  hidden={true}*/}
      {/*  style={{backgroundColor: hideEmptyPrices ? "darkgreen" : "whitesmoke"}}*/}
      {/*  onClick={() => setHideEmptyPrices((current) => !current)}*/}
      {/*>*/}
      {/*  Toggle Empty Prices (Frontend Only)*/}
      {/*</button>*/}
      {/*<br />*/}
      <EstimateHeaderContainer>
        <EstimateTableHeader />
        <EstimateTableTotalRange summary={response?.summary} />
      </EstimateHeaderContainer>
      <EstimateTableContainer hidden={hideTable}>
        <EstimateTableTabs
          tableConfigurationOptions={EstimateTables}
          activeTableConfigurationKey={tableConfiguration.key}
          setTableConfiguration={setTableConfiguration}
        />
        <EstimateTableDescription
          description={tableConfiguration.description}
        />
        <StaticTable
          rows={rows}
          columnStyles={{
            title: {
              textColor: "#807D7A",
            },
          }}
          columns={columns}
          getDynamicCellStyle={getDynamicCellStyle}
        />
      </EstimateTableContainer>
    </ErrorBoundary>
  );
};

// Estimate Table Cell Styling (Dynamic, defined)
const getDynamicCellStyle = (
  columnKey: string,
  row: ICustomerEstimateTableCell
): GenericTableCellProps => {
  // Custom dynamic cell styling for the priceHigh column
  if (columnKey === "priceHigh") {
    // If the priceLow and priceHigh are the same, set the text color to a muted color for priceHigh
    if (row?.priceLow === row?.priceHigh) {
      return {
        style: {
          textColor: "#807D7A",
        },
      };
    }
  }
  // Custom dynamic cell styling for the title column
  // if (columnKey === "title") {
  //   // If the priceLow and priceHigh are different, add alert
  //   if (row?.priceLow !== row?.priceHigh) {
  //     return {
  //       alert: {
  //         label: "Variable Price",
  //         textColor: WHITE,
  //         backgroundColor: VILLA_SLATE,
  //       },
  //     };
  //   }
  // }
  return {};
};

const EstimateHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 32px;
`;

const EstimateTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 16px;
`;

export default CustomerEstimateTable;
