import {executeRequest} from "../APIExecutor";
import {
  IAddressDetails,
  IProjectProposalSettings,
  IProjectTimeline,
  NOTES_COLLECTION,
  ProjectDestination,
  ProposalDisplayType,
  UpdateOrCreateNoteRequest,
  UpdateProjectInfoRequest,
  updateProjectProposalDisplayTypeRequestEndpoint,
  updateProjectProposalSettingsRequestEndpoint,
  updateProjectTimelineRequestEndpoint,
} from "@natomas-org/core";
import {collection, deleteDocument} from "../../utilities";

const updateProjectAddressPath = "/project/v1/updateProjectAddress";
const updateProjectInfo = "/project/v1/updateInfo";
const changeArchiveStatus = "/project/v1/changeArchiveStatus";
const syncOrderFormPath = "/project/v1/sync-order-form-to-smartsheet";
const createOrUpdateProjectNotePath = "/notes/v1/createOrUpdateProjectNote";

export const syncOrderFormToSmartsheet = (
  projectId: string,
  orderForm: string[][]
) => {
  return executeRequest(syncOrderFormPath, {
    projectId,
    orderForm,
  }).then((data) => {
    console.log(data);
  });
};

export const updateProjectInfoAction = (request: UpdateProjectInfoRequest) => {
  return executeRequest(updateProjectInfo, request)
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateProjectInfoByUser = (request: UpdateProjectInfoRequest) => {
  return executeRequest("/project/v1/updateInfoByUser", request)
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateProjectArchiveStatus = (
  userId: string,
  projectId: string,
  archiveStatus: boolean
) => {
  return executeRequest(changeArchiveStatus, {
    userId,
    projectId,
    archiveStatus,
  }).then((data) => {
    console.log(data);
  });
};

export const createOrUpdateProjectNote = (
  request: UpdateOrCreateNoteRequest
) => {
  return executeRequest(createOrUpdateProjectNotePath, request).then((data) => {
    console.log(data);
  });
};

export const deleteProjectNoteId = (projectId: string, noteId: string) => {
  const projectNoteRef = collection(ProjectDestination.NATOMAS)
    .doc(projectId)
    .collection(NOTES_COLLECTION)
    .doc(noteId);
  return deleteDocument(projectNoteRef);
};

export const updateProjectAddress = (
  projectId: string,
  projectAddress: IAddressDetails | null
) => {
  return executeRequest(updateProjectAddressPath, {
    projectId: projectId,
    projectAddress: projectAddress,
  }).then((data) => {
    console.log(data);
  });
};

export const updateProjectTimeline = async (
  projectId: string,
  timeline: {[key: string]: IProjectTimeline}
) => {
  return executeRequest(`/project${updateProjectTimelineRequestEndpoint}`, {
    timeline: timeline,
    projectId: projectId,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const updateProjectProposalFullScreenDesignSelections = async (
  projectId: string,
  settings: IProjectProposalSettings
) => {
  return executeRequest(
    `/project${updateProjectProposalSettingsRequestEndpoint}`,
    {
      settings: settings,
      projectId: projectId,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
export const updateProjectProposalDisplayType = async (
  projectId: string,
  displayType: ProposalDisplayType
) => {
  return executeRequest(
    `/project${updateProjectProposalDisplayTypeRequestEndpoint}`,
    {
      proposalDisplayType: displayType,
      projectId: projectId,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
export const updateQualificationsAndAssumptions = async (
  projectId: string,
  qualificationsAndAssumptions: string
) => {
  return executeRequest(
    "/project/v1/updateProjectQualificationsAndAssumptions",
    {
      qualificationsAndAssumptions: qualificationsAndAssumptions,
      projectId: projectId,
    }
  )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
