import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../SSDDashboardView/styles/globals";

export const HCPComponentHR = styled.hr<{customMargin?: number}>`
  color: #f6f6f6;
  background-color: #f6f6f6;
  border: none;
  height: 2px;
  // customMargin is # of rems vertically
  margin: ${(props) => props.customMargin ?? 3}rem 0;
  width: 100%;
`;

export const HCPComponentTitle = styled.div<{includePadding: boolean}>`
  ${(props) => (props.includePadding ? "padding-bottom: 38px" : "")};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 2rem;
`;
