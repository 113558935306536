import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {SeriesComparisonRowTemplateOptions} from "./index";

export const StyledSeriesSummaryComparisonRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSeriesSummaryComparisonRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const StyledSeriesSummaryComparisonRowHeaderLabel = styled.div`
  width: fit-content;
  flex-grow: 1;
  font-size: 14px;
  font-family: ${FONT_FAMILY_BOLD};
  white-space: nowrap;
  text-transform: uppercase;
`;

export const StyledSeriesSummaryComparisonRowContainer = styled.div<{
  options?: SeriesComparisonRowTemplateOptions;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  padding-top: ${(props) => (props.options?.ignorePadding?.top ? 0 : 24)}px;
  padding-bottom: ${(props) =>
    props.options?.ignorePadding?.bottom ? 0 : 24}px;
`;
