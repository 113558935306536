import {IPublicProjectInfo, SortDropdownValues} from "@natomas-org/core";
import {RelatedProjectsCategory} from "./index";
import {PublicProjectInfo} from "@natomas-org/service";

export const getRelatedProjects = (
  allProjects: IPublicProjectInfo[],
  comparisonMethod?: RelatedProjectsCategory,
  comparisonProduct?: any,
  productSort?: SortDropdownValues
) => {
  const enrichedProjects = allProjects?.filter((project) => {
    return project?.featured === true && project?.published === true;
  });
  switch (comparisonMethod) {
    case RelatedProjectsCategory.ALL:
    default:
      return sortProjects(enrichedProjects, productSort ?? null);
    case RelatedProjectsCategory.SQFT:
      // Returns completed projects within 100 sqft of comparison
      if (!!comparisonProduct && allProjects?.length > 0) {
        const localArr: IPublicProjectInfo[] = [];
        Object.values(enrichedProjects)?.forEach(
          (project: IPublicProjectInfo) => {
            if (
              project?.metadata?.products[0]?.productDetails?.squareFeet &&
              project?.metadata?.products?.length > 0 &&
              Math.abs(
                project?.metadata?.products[0]?.productDetails?.squareFeet -
                  comparisonProduct?.productDetails?.squareFeet
              ) < 100
            ) {
              localArr.push(project);
            }
          }
        );
        return sortProjects(localArr, productSort ?? null);
      }
      return [];
  }
};

const sortProjects = (
  projects: IPublicProjectInfo[],
  sortBy: SortDropdownValues | null
): IPublicProjectInfo[] => {
  if (!sortBy) {
    return projects;
  }
  return projects?.sort(
    (aProject: IPublicProjectInfo, bProject: IPublicProjectInfo) => {
      if (
        !aProject?.metadata?.products ||
        aProject?.metadata?.products?.length < 1 ||
        !bProject?.metadata?.products ||
        bProject?.metadata?.products?.length < 1 ||
        !aProject?.metadata?.products[0]?.productDetails?.squareFeet ||
        !bProject?.metadata?.products[0]?.productDetails?.squareFeet ||
        !aProject?.metadata?.products[0]?.productDetails?.bedrooms ||
        !aProject?.metadata?.products[0]?.productDetails?.bathrooms ||
        !bProject?.metadata?.products[0]?.productDetails?.bedrooms ||
        !bProject?.metadata?.products[0]?.productDetails?.bathrooms
      ) {
        return 0;
      }
      const a = aProject?.metadata?.products[0];
      const b = bProject?.metadata?.products[0];
      if (!a?.productDetails) {
        return 1;
      }
      if (!b?.productDetails) {
        return -1;
      }
      switch (sortBy) {
        case SortDropdownValues.PRICE_ASC:
        case SortDropdownValues.PRICE_DESC:
          const aTotal: number | null =
            PublicProjectInfo.getTotalPrice(aProject);
          const bTotal: number | null =
            PublicProjectInfo.getTotalPrice(bProject);
          if (!aTotal || !bTotal) {
            return 0;
          }
          if (sortBy === SortDropdownValues.PRICE_ASC) {
            return aTotal - bTotal;
          }
          return bTotal - aTotal;
        case SortDropdownValues.SQUARE_FOOTAGE_ASC:
          return a.productDetails.squareFeet - b.productDetails.squareFeet;
        case SortDropdownValues.SQUARE_FOOTAGE_DESC:
          return b.productDetails.squareFeet - a.productDetails.squareFeet;
        case SortDropdownValues.BED_DESC:
          return b.productDetails.bedrooms - a.productDetails.bedrooms;
        case SortDropdownValues.BATH_DESC:
          return b.productDetails.bathrooms - a.productDetails.bathrooms;
        default:
          return 0;
      }
    }
  );
};
