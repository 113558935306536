import React, {useMemo} from "react";
import {priceTextFromMicros} from "../../../../../../../../../studio/_shared/helper";
import NumberInput from "./NumberInput";

export interface FactoryInventoryPriceInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: number;
  range?: [number, number];
  hideFormattedValue?: boolean;
}

const PriceInput = (props: FactoryInventoryPriceInputProps) => {
  const {id, onChange, initialValue, range, hideFormattedValue} = props;

  const value = useMemo(() => {
    return (initialValue ?? 0) / 100;
  }, [initialValue]);

  return (
    <>
      <NumberInput
        id={id}
        initialValue={value}
        onChange={(value: number) => onChange((value ?? 0) * 100)}
        range={range}
      />
      {!hideFormattedValue &&
        priceTextFromMicros((initialValue as number) ?? 0, "accurate")}
    </>
  );
};

export default PriceInput;
