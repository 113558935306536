import React, {useState} from "react";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../_shared/generics/label/NatLabel";
import styled from "styled-components";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {HCPComponentHR} from "../../../views/HCPDashboardLayout/styles";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {DrawerToggle} from "../../../../_shared/generics/drawer-toggle/DrawerToggle";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";

export const HeaderWrapper = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export interface IPortalSection {
  children: any;
  headerTitle: string;
  width?: any;
  sectionId: string;
  hideLineSeparator?: boolean;
  ignorePageElement?: boolean;
}

export const PortalSection = (props: IPortalSection) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const fullWidth = useDynamicValue(fullColumnWidths);

  return (
    <>
      <PageContainer>
        <div id={props.sectionId} />
        <HCPComponentHR hidden={props.hideLineSeparator} />
        <PageElement size={props.width ?? fullWidth} height={"auto"}>
          <HeaderWrapper onClick={() => setIsExpanded((current) => !current)}>
            <NatLabel
              style={{userSelect: "none"}}
              label={props.headerTitle}
              type={NatLabelType.H3}
              weight={NatLabelWeight.BOLD}
            />
            <DrawerToggle currentState={isExpanded} />
          </HeaderWrapper>
          {props.ignorePageElement ? null : (
            <div hidden={!isExpanded}>{props.children}</div>
          )}
        </PageElement>
      </PageContainer>

      {props.ignorePageElement ? (
        <div hidden={!isExpanded}>{props.children}</div>
      ) : null}
    </>
  );
};
