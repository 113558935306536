import {NatButton} from "../../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../../_shared/generics/_shared";
import {NavigationButtons} from "../../../../../../_shared/generics/header/styles";
import React from "react";
import {CustomerProjectTabProps} from "../tabs";
import {
  CustomerProjectTabHeaderButtonContainer,
  CustomerProjectTabHeaderContainer,
} from "./styles";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../_shared/generics/label/NatLabel";
import {ERROR_COLOR} from "../../../../../../_shared/colors";

export const CustomerProjectTabHeader = (props: CustomerProjectTabProps) => {
  const {
    deleteAction,
    discardAction,
    saveAction,
    isUpToDate,
    additionalActions,
    invalidSettingsWarning,
  } = props;

  return (
    <CustomerProjectTabHeaderContainer>
      <div>
        <NatLabel
          label={"Control Panel"}
          type={NatLabelType.H3}
          style={{margin: 0}}
          weight={NatLabelWeight.MEDIUM}
        />
      </div>
      <CustomerProjectTabHeaderButtonContainer>
        {additionalActions}
        {invalidSettingsWarning && (
          <NatLabel
            label={invalidSettingsWarning}
            type={NatLabelType.P3}
            style={{color: ERROR_COLOR, paddingRight: "1rem"}}
          />
        )}
        <NavigationButtons>
          {deleteAction && (
            <NatButton
              type={"button"}
              size={NatSize.SMALL}
              option={StyleOption.DESTRUCTIVE}
              clickEvent={deleteAction}
              label={"Archive"}
              trackingId={"admin-archive"}
            />
          )}
          {discardAction && isUpToDate !== undefined && (
            <NatButton
              type={"button"}
              size={NatSize.SMALL}
              option={StyleOption.SECONDARY_ALT}
              disabled={isUpToDate}
              clickEvent={discardAction}
              label={"Discard Changes"}
              trackingId={"admin-discard-changes"}
            />
          )}
          {saveAction && isUpToDate !== undefined && (
            <NatButton
              type={"button"}
              size={NatSize.SMALL}
              option={StyleOption.PRIMARY}
              disabled={isUpToDate || invalidSettingsWarning !== null}
              clickEvent={saveAction}
              label={"Save"}
              trackingId={"admin-save"}
              spinnerEnabled={true}
            />
          )}
        </NavigationButtons>
      </CustomerProjectTabHeaderButtonContainer>
    </CustomerProjectTabHeaderContainer>
  );
};
