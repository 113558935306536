import {ProposalSummarySectionDiv} from "./styles";
import {NatFlex} from "../../../../../../../_shared/generics/flex/NatFlex";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../../_shared/generics/label/NatLabel";
import React, {Fragment, useContext} from "react";
import {PROPOSAL_GENERATE_PDF} from "../../../../../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {FULL_SCREEN_WIDTH_REM} from "../../../../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";

export const ProposalSummarySection = (props: {
  lineItems: {label: JSX.Element; value: string}[];
  highlighted: boolean;
  title: string;
}) => {
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const fullWidth =
    isGeneratingPDF && false ? `${FULL_SCREEN_WIDTH_REM}rem` : "100%";
  const item1Width = isGeneratingPDF ? `90%` : "70%";
  const item2Width = isGeneratingPDF ? `10%` : "30%";
  return (
    <Fragment>
      <NatLabel
        label={props.title}
        type={NatLabelType.P1}
        weight={NatLabelWeight.MEDIUM}
        style={{marginTop: "2rem"}}
      />
      <ProposalSummarySectionDiv
        highlighted={props.highlighted}
        generatingPDF={false}
      >
        <NatFlex COLUMN NOGAP={false}>
          <NatFlex ROW FULL_WIDTH NOWRAP>
            <div style={{width: item1Width}}>
              <NatLabel
                label={"Item"}
                type={NatLabelType.P2}
                weight={NatLabelWeight.BOLD}
              />
            </div>
            <div style={{width: item2Width}}>
              <NatLabel
                label={"Total"}
                type={NatLabelType.P2}
                weight={NatLabelWeight.BOLD}
              />
            </div>
          </NatFlex>
          {props.lineItems.map((lineItem, idx) => {
            return (
              <NatFlex ROW FULL_WIDTH NOWRAP key={`${idx}-line-item`}>
                <div style={{width: item1Width}}>{lineItem.label}</div>
                <div style={{width: item2Width}}>{lineItem.value}</div>
              </NatFlex>
            );
          })}
        </NatFlex>
      </ProposalSummarySectionDiv>
    </Fragment>
  );
};
