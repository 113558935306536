import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {INavigationSlice, INavigationWarning} from "./types/Store";

const getInitialState = (): INavigationSlice => {
  return {
    show: false,
    warning: undefined,
    latestNavigationRequestType: undefined,
    latestNavigationRequestArgs: undefined,
  };
};

const navSlice = createSlice({
  name: "navigation",
  initialState: getInitialState(),
  reducers: {
    setShowWarning: (state, action: PayloadAction<boolean>) => {
      state.show = action.payload;
    },
    setWarning: (
      state,
      action: PayloadAction<INavigationWarning | undefined>
    ) => {
      state.warning = action.payload;
    },
    setLatestNavigationRequestType: (state, action: PayloadAction<any>) => {
      state.latestNavigationRequestType = action.payload;
    },
    setLatestNavigationRequestArgs: (state, action: PayloadAction<any>) => {
      state.latestNavigationRequestArgs = action.payload;
    },
    resetWarning: (state) => {
      state.show = false;
      state.warning = undefined;
      state.latestNavigationRequestType = undefined;
      state.latestNavigationRequestArgs = undefined;
    },
  },
});

export const navReducer = navSlice.reducer;
export const {
  setWarning,
  setShowWarning,
  setLatestNavigationRequestType,
  setLatestNavigationRequestArgs,
  resetWarning,
} = navSlice.actions;
