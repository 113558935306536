import React from "react";
import {ActionCard} from "../_shared/ActionCard";
import {FaFolderOpen} from "react-icons/fa";

const sort = (items: any, key: string) => {
  if (!items) {
    return null;
  } else {
    return items?.sort((a: any, b: any) => {
      return a?.[key]?.toLowerCase() > b?.[key]?.toLowerCase() ? 1 : -1;
    });
  }
};

export const getProductGroupTiles = (
  productGroups: any,
  setProductGroupId: (id: string) => void
) => {
  return sort(Object.values(productGroups), "internal_title")?.map(
    (pG: any) => {
      return (
        <ActionCard
          icon={<FaFolderOpen />}
          key={"image-search-product-group-" + pG?.id}
          action={() => setProductGroupId(pG?.id)}
          label={(pG?.internal_title ?? pG?.title) + " (Draft)"}
        />
      );
    }
  );
};

export const getProductTiles = (
  products: any,
  setProductId: (id: string) => void
) => {
  return sort(products, "title")?.map((p: any) => {
    return (
      <ActionCard
        icon={<FaFolderOpen />}
        key={"image-search-product-" + p?.id}
        action={() => setProductId(p?.id)}
        label={p?.title}
      />
    );
  });
};

export const getCategoryTiles = (
  categories: any,
  setCategoryId: (id: string) => void,
  getCategoryLabel: (id?: string) => string
) => {
  return sort(categories, "title")?.map((c: any) => {
    return (
      <ActionCard
        icon={<FaFolderOpen />}
        key={"image-search-product-category-" + c?.id}
        action={() => setCategoryId(c?.id)}
        label={getCategoryLabel(c?.id)}
      />
    );
  });
};
