import React from "react";
import styled from "styled-components";
import {BLACK, WHITE} from "../../_shared/colors";
import {useDynamicValue} from "../../_shared/hooks/useDynamicValue";
import {
  EstimateTableConfiguration,
  EstimateTableConfigurationOptions,
} from "./table";

interface EstimateTableTabsProps {
  tableConfigurationOptions: EstimateTableConfigurationOptions;
  activeTableConfigurationKey: string | null;
  setTableConfiguration: (table: EstimateTableConfiguration) => void;
}

interface IEstimateTableTabsStyle {
  gapPx: number;
  fontSizePx: number;
}

const MobileTabStyling: IEstimateTableTabsStyle = {
  gapPx: 8,
  fontSizePx: 14,
};
const DesktopTabStyling: IEstimateTableTabsStyle = {
  gapPx: 18,
  fontSizePx: 18,
};
const LargeDesktopTabStyling: IEstimateTableTabsStyle = {
  gapPx: 32,
  fontSizePx: 21,
};

const EstimateTableTabs = (props: EstimateTableTabsProps) => {
  const styling: IEstimateTableTabsStyle = useDynamicValue({
    forFour: MobileTabStyling,
    forEight: MobileTabStyling,
    forTwelve: DesktopTabStyling,
    forSixteen: LargeDesktopTabStyling,
  });
  const {activeTableConfigurationKey, setTableConfiguration} = props;

  return (
    <Container gapPx={styling.gapPx}>
      {(
        Object.values(
          props.tableConfigurationOptions
        ) as EstimateTableConfiguration[]
      ).map((table) => (
        <Tab
          fontSizePx={styling.fontSizePx}
          isActive={activeTableConfigurationKey === table.key}
          onClick={() => setTableConfiguration(table)}
        >
          {table.label}
        </Tab>
      ))}
    </Container>
  );
};
interface ContainerProps {
  gapPx: number;
}
const _Container = ({gapPx}: ContainerProps) => `
  gap: ${gapPx}px;
`;
const Container = styled.div`
  ${_Container};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #807d7a;
`;

interface TabProps {
  isActive: boolean;
  fontSizePx: number;
}
const _Tab = ({isActive, fontSizePx}: TabProps) => `
  border-bottom: 2px solid ${isActive ? "#368836" : WHITE};
  color: ${isActive ? "#368836" : BLACK};
  font-size: ${fontSizePx}px;
  line-height: ${fontSizePx + 24}px;
`;

const Tab = styled.h3<TabProps>`
  ${_Tab};
  /* Heading/H3/Medium */
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  user-select: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #368836;
  }
`;

export default EstimateTableTabs;
