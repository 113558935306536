import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../../slices/types/Store";
import {useEffect, useMemo} from "react";
import {
  IFactoryInfo,
  IProduct,
  NatomasMapFeature,
  NatomasMapFeatureType,
} from "@natomas-org/core";
import {queryFullMapFeatures} from "../../../../../database/firebase/mapping/query";
import {storeMapFeatures} from "../../../slices/NatomasMapSlice/NatomasMapSlice";
import {useFactorySlice} from "../../useProductCatalog/useFactorySlice";
import {FactoryField, IFactoryInfoSet} from "../../../slices/FactorySlice";

export interface IAdminFactoryLine {
  id: string;
  inventory: {[id: string]: IProduct} | null;
  info: IFactoryInfo | null;
  infoSet: IFactoryInfoSet | null;
  mapping: any | null;
}

export const useAdminFactoryLines = () => {
  const factorySlice = useFactorySlice();
  const dispatch = useDispatch();
  const features = useSelector((state: IStore) => state.natomasMap.features);

  useEffect(() => {
    queryFullMapFeatures({
      collectionId: "mapping",
      types: [NatomasMapFeatureType.FACTORY_LINE],
    }).then((r: NatomasMapFeature[]) => {
      if (r?.length > 0) {
        dispatch(storeMapFeatures(r));
      }
    });
  }, [dispatch]);

  return useMemo((): IAdminFactoryLine[] => {
    const ids = Object.keys(factorySlice ?? {});
    return ids?.map((factoryId: string) => {
      return {
        id: factoryId,
        inventory: factorySlice?.[factoryId]?.[FactoryField.INVENTORY] ?? null,
        info: factorySlice?.[factoryId]?.info ?? null,
        infoSet: factorySlice?.[factoryId]?.infoSet ?? null,
        mapping: features?.[factoryId] ?? null,
      };
    });
  }, [features, factorySlice]);
};
