/**
 * useEffect hook that only runs on component mount (aka once)
 */

import {useEffect} from "react";

const useMountEffect = (effect) => {
  useEffect(effect, []);
};

export default useMountEffect;
