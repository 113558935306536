import styled from "styled-components";

export const VersionManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 4px;
  padding: 8px;
  border-bottom: rgba(255, 255, 255, 0.2) solid 1px;
`;
