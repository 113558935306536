import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {DIVIDER_COLOR, GRAY, WHITE} from "../../../../_shared/colors";
import {SUB_POPOUT_Z_INDEX} from "../../../../_shared/styles";

export const UnitInfoCard = styled.div<{stackedMode?: boolean}>`
  background-color: ${WHITE};
  border-radius: ${(props) =>
    !props.stackedMode ? `1.5rem` : `1rem 1rem 0 0`};
  ${(props) =>
    !props.stackedMode
      ? `border: 1px solid rgba(0, 0, 0, 0.1);`
      : `border-top:1px solid rgba(0, 0, 0, 0.1);`};

  box-shadow: 0 4px 17px rgba(97, 97, 97, 0.17);
  margin: ${(props) => (!props.stackedMode ? `10px` : `0`)};
  position: fixed;
  bottom: 0;
  right: 0;
  left: ${(props) => props.stackedMode && 0};
  width: ${(props) => !props.stackedMode && "449px"};
  z-index: ${SUB_POPOUT_Z_INDEX};
`;

export const UnitInfoCardColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const UnitInfoCardPrice = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 1.5rem;
`;
export const UnitInfoCardPriceDescription = styled.div`
  font-size: 0.75rem;
  color: ${GRAY};
`;

export const UnitInfoCardRowGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 1.5rem;
`;
export const UnitInfoCardIndentedRowGroup = styled(UnitInfoCardRowGroup)`
  padding: 0 1.5rem;
  line-height: 1rem;
  font-size: 1rem;
`;

export const UnitInfoCardReviewContainer = styled.div<{show: boolean}>`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.show ? "447px" : 0)};
  overflow: hidden;
  padding: ${(props) => props.show && `0 0 1rem 0`};
  row-gap: 1rem;
  border-top: ${(props) => props.show && `1px solid ${DIVIDER_COLOR}`};
  transition: all 300ms ease;
`;

export const UnitInfoCardCategoryReviewContainer = styled.div`
  max-height: 281px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  overflow: auto;
  border-bottom: 1px solid ${DIVIDER_COLOR};
  padding: 1rem 0;
`;

export const UnitInfoCardTitle = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UnitInfoCardDetails = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
