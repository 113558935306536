import React, {useCallback, useEffect, useState} from "react";
import LengthInput, {DefaultDimension, IDimension} from "./LengthInput";
import styled from "styled-components";
import {WHITE} from "../../../../../../../../../_shared/colors";

export const DimensionContainer = styled.div`
  border-radius: 4px;
  background-color: ${WHITE};
  width: fit-content;
`;

export interface IDimensions {
  frontLength: IDimension;
  sideLength: IDimension;
}

export const DefaultDimensions: IDimensions = {
  frontLength: DefaultDimension,
  sideLength: DefaultDimension,
};

interface DimensionsInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: any;
}

const DimensionsInput = (props: DimensionsInputProps) => {
  const {initialValue, id, onChange} = props;
  const [frontLength, setFrontLength] = useState(
    initialValue?.frontLength ?? DefaultDimension
  );
  const [sideLength, setSideLength] = useState(
    initialValue?.sideLength ?? DefaultDimension
  );

  useEffect(() => {
    setFrontLength(initialValue?.frontLength);
    setSideLength(initialValue?.sideLength);
  }, [initialValue?.frontLength, initialValue?.sideLength]);

  const updateDimensions = useCallback(
    (d: "front" | "side", value: IDimension) => {
      let newDimensions: IDimensions = {
        frontLength: frontLength,
        sideLength: sideLength,
      };
      if (d === "front") {
        newDimensions.frontLength = value;
      } else {
        newDimensions.sideLength = value;
      }
      onChange(newDimensions);
    },
    [frontLength, onChange, sideLength]
  );

  return (
    <DimensionContainer>
      <LengthInput
        label={"Front"}
        id={`${id}-front`}
        key={`${id}-front`}
        onChange={(v: IDimension) => updateDimensions("front", v)}
        initialValue={frontLength}
      />
      <LengthInput
        label={"Side"}
        id={`${id}-side`}
        key={`${id}-side`}
        onChange={(v: IDimension) => updateDimensions("side", v)}
        initialValue={sideLength}
      />
    </DimensionContainer>
  );
};

export default DimensionsInput;
