import {InfoSetColumnSelectFilter} from "../../../../../../../factory-info-set/InfoSetTable/components/ColumnSelectFilter";
import {InfoSetColumnTextFilter} from "../../../../../../../factory-info-set/InfoSetTable/components/ColumnTextFilter";
import {
  CONDITIONS_KEY,
  ID_KEY,
  INTERNAL_CATEGORY_KEY,
  LABEL_KEY,
  VALUE_KEY,
} from "../../../../../../../factory-info-set/shared/constants";

export const orderFormColumns = [
  {
    Header: "Order Form Manager",
    columns: [
      {
        Header: "ID",
        accessor: ID_KEY,
      },
      {
        Header: "Category",
        accessor: INTERNAL_CATEGORY_KEY,
        Filter: InfoSetColumnSelectFilter,
      },
      {
        Header: "Key",
        accessor: LABEL_KEY,
        Filter: InfoSetColumnTextFilter,
      },
      {
        Header: "Option",
        accessor: VALUE_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Method",
        accessor: CONDITIONS_KEY,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
  },
];
