import React from "react";
import Form from "../../2-component/Form/form";
import {InputProps} from "../../2-component/Input/interface";
import {ButtonProps} from "../../2-component/Button/interface";
import {InputLibrary} from "./library/inputs";

const inputs: InputProps[] = [
  InputLibrary.emailInput,
  InputLibrary.createPasswordInput,
  InputLibrary.tosPrivacyCheckbox,
];

const CreateContactForm = (props: {
  buttons: ButtonProps[];
  onSubmit: (value: string) => void;
}) => {
  return (
    <Form
      key={"new-auth-account-form"}
      inputs={inputs}
      onSubmit={(values) => props.onSubmit?.(values)}
      buttons={[
        ...props?.buttons,
        {
          id: "new-auth-account-form-continue",
          label: "Create account",
          type: "submit",
        },
      ]}
    />
  );
};

export default CreateContactForm;
