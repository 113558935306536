import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import {useDesigns} from "../../../../../../../_shared/hooks/useDesign/useDesigns";
import React, {Fragment, useMemo} from "react";
import {getUpgradesList} from "../../../../../../../_shared/snapshot";
import {NatFlex} from "../../../../../../../_shared/generics/flex/NatFlex";
import {ISiteWorkBucket} from "../../../../../../../portal/views/InstantQuoteView/interface";
import {VILLA_APPLE_GREEN, WHITE} from "../../../../../../../_shared/colors";
import {NatInput} from "../../../../../../../_shared/generics/input/NatInput";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {FullScreenDesignSelections} from "../../../../../../../portal/views/HCPDashboardLayout/HCPUnitView/DesignSelections/FullScreenDesignSelections";
import {EMBEDDED_PROPOSAL} from "../../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {useFormik} from "formik";
import {
  deepEquals,
  IDesign,
  IProjectInfo,
  ProjectPriceMode,
  ProposalDisplayType,
} from "@natomas-org/core";
import {updateProjectProposalFullScreenDesignSelections} from "../../../../../../../../database/firebase/api/project";
import {ProposalViewerEditorContainer} from "../TimelineEditor/styles";
import {
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {
  DESIGN_SELECTIONS_PREVIEW_TITLE,
  ProposalEditorElementPreviewWrapper,
} from "../QualificationsAndAssumptionsPreviewTitle";

const getInitialValues = (
  designs: {
    [designId: string]: IDesign;
  },
  info: IProjectInfo | null,
  priceMode: ProjectPriceMode
) => {
  const defaultValues: {[cartId: string]: {[bucketTitle: string]: boolean}} =
    {};
  const designIdsInCart: string[] = [];
  Object.values(designs)?.forEach((design) => {
    defaultValues[design.id] = {};
    designIdsInCart.push(design.id);
    const upgradeList = getUpgradesList(design?.snapshot, priceMode, true);
    upgradeList?.forEach((item: ISiteWorkBucket) => {
      defaultValues[design.id][item.title] = false;
    });
  });
  let currentItemsOfDesignsInCart: {[p: string]: {[p: string]: boolean}} = {};
  if (!!info?.proposalSettings?.displayFullScreenDesignSelections) {
    designIdsInCart.forEach((designId) => {
      if (info?.proposalSettings?.displayFullScreenDesignSelections[designId]) {
        currentItemsOfDesignsInCart[designId] =
          info?.proposalSettings?.displayFullScreenDesignSelections[designId];
      }
    });
  }
  return Object.assign({}, defaultValues, currentItemsOfDesignsInCart);
};

const getDefaultValues = (
  designs: {
    [designId: string]: IDesign;
  },
  priceMode: ProjectPriceMode
) => {
  return getInitialValues(designs, null, priceMode);
};
export const EditCartItemFullScreenDesigns = () => {
  const {cartItemIds, projectPriceMode, info, isDevelopmentByVilla} =
    useActiveProject();
  const designs = useDesigns(cartItemIds);
  const {id: projectId} = useActiveProject();

  const initialData = useMemo(() => {
    return getInitialValues(designs, info ?? null, projectPriceMode);
  }, [designs, info, projectPriceMode]);
  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values) => {
      const newData = Object.assign({}, values);
      return updateProjectProposalFullScreenDesignSelections(projectId, {
        displayFullScreenDesignSelections: newData,
        proposalDisplayType: isDevelopmentByVilla
          ? ProposalDisplayType.DevelopmentByVilla
          : ProposalDisplayType.BackyardByVilla,
      });
    },
  });
  const hasBeenEdited = useMemo(() => {
    return !deepEquals(formik.values, initialData);
  }, [formik.values, initialData]);
  const notDefaultValues = useMemo(() => {
    return !deepEquals(
      getDefaultValues(designs, projectPriceMode),
      formik.values
    );
  }, [designs, formik.values, projectPriceMode]);
  if (!designs) {
    return null;
  }
  return (
    <Fragment>
      <ProposalViewerEditorContainer>
        <NatFlex FULL_WIDTH ROW NOWRAP VERTICAL_ALIGN={NatVerticalAlign.CENTER}>
          <NatLabel
            label={"Edit Full Screen Designs"}
            type={NatLabelType.H3}
            weight={NatLabelWeight.MEDIUM}
          />
          <NatButton
            style={{width: "fit-content"}}
            label={"Save"}
            disabled={!hasBeenEdited}
            spinnerEnabled={true}
            size={NatSize.SMALL}
            clickEvent={() => {
              return formik.submitForm();
            }}
          />
          <NatButton
            style={{width: "fit-content"}}
            label={"Reset to Default"}
            option={StyleOption.DESTRUCTIVE}
            disabled={!notDefaultValues}
            spinnerEnabled={true}
            size={NatSize.SMALL}
            clickEvent={() => {
              return formik.setValues(
                getDefaultValues(designs, projectPriceMode)
              );
            }}
          />
        </NatFlex>
        {Object.values(designs)?.map((design, idx) => {
          let upgradeList = [];
          if (!design?.snapshot) {
            return [];
          }
          upgradeList = getUpgradesList(
            design?.snapshot,
            projectPriceMode,
            true
          );

          return (
            <React.Fragment key={design.id}>
              {idx < Object.values(designs).length && (
                <hr style={{width: "100%"}} />
              )}
              <NatLabel
                label={design?.configuration?.product?.title}
                type={NatLabelType.H4}
                style={{width: "100%"}}
              />
              <NatFlex FULL_WIDTH>
                {upgradeList?.map((item: ISiteWorkBucket) => {
                  const currentCartItemDictionary: {
                    [bucketTitle: string]: boolean;
                  } = formik.values[design.id] ?? {};
                  const currentCartItemValue =
                    currentCartItemDictionary[item.title];
                  return (
                    <SelectSection
                      modifier={item}
                      currentValue={currentCartItemValue}
                      updateValue={(modifierValue) => {
                        if (!design?.id || !item?.title) {
                          return null;
                        }
                        const newCartItemValue = Object.assign(
                          {},
                          currentCartItemDictionary,
                          {[item.title]: modifierValue}
                        );
                        formik.setFieldValue(design.id, newCartItemValue);
                        return;
                      }}
                    />
                  );
                })}
              </NatFlex>
            </React.Fragment>
          );
        })}
      </ProposalViewerEditorContainer>
      <ProposalEditorElementPreviewWrapper
        title={DESIGN_SELECTIONS_PREVIEW_TITLE}
      >
        <EMBEDDED_PROPOSAL.Provider value={true}>
          <FullScreenDesignSelections />
        </EMBEDDED_PROPOSAL.Provider>
      </ProposalEditorElementPreviewWrapper>
    </Fragment>
  );
};

const SelectSection = (props: {
  modifier: ISiteWorkBucket;
  currentValue: boolean;
  updateValue: (modifierValue: boolean) => void;
}) => {
  const updateValue = () => {
    props.updateValue(!props.currentValue);
  };
  return (
    <NatFlex
      NOWRAP
      NOGAP
      ROW
      style={{
        minWidth: "8rem",
        paddingRight: "0.5rem",
        cursor: "pointer",
        gap: "0.5rem",
      }}
    >
      <NatInput
        type={"checkbox"}
        style={{
          accentColor: VILLA_APPLE_GREEN,
          color: WHITE,
          minWidth: "14px",
          borderRadius: "0",
        }}
        checked={props.currentValue}
        onChange={updateValue}
      />
      <NatLabel
        label={props.modifier?.title}
        type={NatLabelType.P2}
        onClick={updateValue}
      />
    </NatFlex>
  );
};
