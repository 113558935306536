import {MatrixTableCustomCellProps} from "../_shared/interfaces";
import {viewProduct} from "../../../../../../logic/helpers";
import {MatrixHeaderCell, MatrixTableCell} from "../../styles";
import {CONTAINER_BACKGROUND_COLOR} from "../../../../../../../../_shared/colors";
import {MatrixTableController} from "../../../MatrixTableController";
import {BackLinkIcon} from "../../../../../../components/BackLinkIcon";
import React from "react";

export const ProductInfoTableCell = (props: MatrixTableCustomCellProps) => {
  const {customProps, pageProps} = props;
  const key = customProps.col + "_" + customProps.row;
  const target = customProps?.cell?.value;
  const getBackLinkAction = () => {
    if (pageProps?.transpose) {
      return () => viewProduct(target);
    } else {
      return () => viewProduct(target);
    }
  };
  if (customProps.col === 0 && customProps.row === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        horizontalSticky={true}
        width={"20rem"}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          <MatrixTableController {...pageProps} />
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  if (customProps.col === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        horizontalSticky={true}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          {target?.title || "Default"}
          {pageProps?.transpose && (
            <BackLinkIcon action={getBackLinkAction()} />
          )}
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  if (customProps.row === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        width={!pageProps?.transpose ? "10rem" : undefined}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          {target?.title || "Default"}
          {!pageProps?.transpose && (
            <BackLinkIcon action={getBackLinkAction()} />
          )}
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  return (
    <MatrixTableCell key={key} {...customProps}>
      {customProps?.children}
    </MatrixTableCell>
  );
};
