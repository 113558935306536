import React from "react";
import {usePage} from "../../../../../../../../../../_shared/hooks/usePage";
import {DIVIDER_COLOR} from "../../../../../../../../../../_shared/colors";
import styled from "styled-components";
import {NatLockedIcon} from "../../../../../../../../../../_shared/icon/icons";

const MappingPreviewContainer = styled.div<{height: string}>`
  height: ${(props) => props.height};
  margin-top: 16px;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  background-color: ${DIVIDER_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
`;

const MappingPreview = () => {
  const {isNatMobile} = usePage();

  return (
    <MappingPreviewContainer height={isNatMobile ? "40vh" : "480px"}>
      <div>Site Mapping Tool</div>
      <NatLockedIcon />
    </MappingPreviewContainer>
  );
};

export default MappingPreview;
