import {IEstimateRangeAdu} from "../../../api/Estimate/EstimateService";
import {EstimateTables} from "../CustomerEstimate/tables";
import {fullPriceTextFromMicros} from "@natomas-org/core";
import {TableRowProps} from "../StaticTable";

export const getADURows = (
  adu: IEstimateRangeAdu,
  options?: {hideEmptyPrices: boolean; hideSelections: boolean}
): TableRowProps<any>[] => {
  if (!adu) return [];
  if (options?.hideSelections) {
    // Return the price/cost of the base price PLUS the cost of all selections
    return [
      {
        bucket: EstimateTables.HD.key,
        title: adu.title + " + upgrades",
        priceLow: fullPriceTextFromMicros(adu.summary?.total_price_low),
        priceHigh: fullPriceTextFromMicros(adu.summary?.total_price_high),
        costLow: fullPriceTextFromMicros(adu?.summary?.total_cost_low ?? 0),
        costHigh: fullPriceTextFromMicros(adu?.summary?.total_cost_high ?? 0),
        gpLow: fullPriceTextFromMicros(
          adu?.summary?.total_gross_profit_low ?? 0
        ),
        gpHigh: fullPriceTextFromMicros(
          adu?.summary?.total_gross_profit_high ?? 0
        ),
        gmLow: `${adu?.summary?.total_gross_margin_low ?? 0}%`,
        gmHigh: `${adu?.summary?.total_gross_margin_high ?? 0}%`,
        note: adu.description ?? "",
      },
    ];
  } else {
    return getItemizedADURows(adu, options?.hideEmptyPrices ?? false);
  }
};

const getItemizedADURows = (
  adu: IEstimateRangeAdu,
  hideEmptyPrices: boolean
) => {
  const price = adu.base_price ?? 0;
  const cost = adu.base_cost ?? 0;
  const gp = price - cost;
  const gm = ((gp / price) * 100).toFixed(2);

  const unit: TableRowProps<any> = {
    bucket: EstimateTables.HD.key,
    title: adu.title,
    priceLow: fullPriceTextFromMicros(price),
    priceHigh: fullPriceTextFromMicros(price),
    costLow: fullPriceTextFromMicros(cost),
    costHigh: fullPriceTextFromMicros(cost),
    gpLow: fullPriceTextFromMicros(gp),
    gpHigh: fullPriceTextFromMicros(gp),
    gmLow: `${gm}%`,
    gmHigh: `${gm}%`,
    note: adu.description ?? "",
  };
  let selections: TableRowProps<any>[] = [];
  adu.selections.forEach((selection) => {
    const price = selection.price ?? 0;
    const cost = selection.cost ?? 0;
    const gp = price - cost;
    const gm = !price ? 0 : ((gp / price) * 100).toFixed(2);
    if (hideEmptyPrices && price === 0) return;
    selections.push({
      title: selection.title,
      priceLow: fullPriceTextFromMicros(price),
      priceHigh: fullPriceTextFromMicros(price),
      costLow: fullPriceTextFromMicros(cost),
      costHigh: fullPriceTextFromMicros(cost),
      gpLow: fullPriceTextFromMicros(gp),
      gpHigh: fullPriceTextFromMicros(gp),
      gmLow: `${gm}%`,
      gmHigh: `${gm}%`,
      note: selection.notes,
    });
  });

  return [unit, ...selections];
};
