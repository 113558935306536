import React, {useEffect, useMemo} from "react";
import {ProposalViewerEditorContainer} from "./styles";
import {
  getDefaultTimelineEditorValues,
  IProjectInfo,
  IProjectTimeline,
  ProjectTimelineStage,
  shallowEquals,
} from "@natomas-org/core";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {DatePickerInput} from "../../../../../../ProductLineView/components/DatePickerInput";
import {NatFlex} from "../../../../../../../_shared/generics/flex/NatFlex";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import {B2BTimelineChart} from "./TimelineViewer";
import {useFormik} from "formik";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {updateProjectTimeline} from "../../../../../../../../database/firebase/api/project";
import {
  ProposalEditorElementPreviewWrapper,
  TIMELINE_PREVIEW_TITLE,
} from "../QualificationsAndAssumptionsPreviewTitle";
import {SmartProposalTimeline} from "../../../../../../../portal/views/ProposalBudgetView/components/Presentation/Timeline/SmartProposalTimeline";

function getInitialTimelineEditorValues(info: IProjectInfo | null): {
  [key: string]: IProjectTimeline;
} {
  if (info?.timeline && !shallowEquals(info.timeline, {})) {
    return info.timeline;
  }
  return getDefaultTimelineEditorValues(
    info?.created_date ?? info?.last_updated_date ?? new Date().getTime()
  );
}

export const TimelineEditor = (props: {displayQuarters: boolean}) => {
  const {info, isDevelopmentByVilla, id} = useActiveProject();
  const initialValues = useMemo(() => {
    return getInitialTimelineEditorValues(info ?? null);
  }, [info]);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      return updateProjectTimeline(id, values);
    },
  });
  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  const editsHaveBeenMade = useMemo(() => {
    const noTimeline = !info?.timeline || shallowEquals(info?.timeline, {});
    return noTimeline || !shallowEquals(formik.values, initialValues);
  }, [formik.values, info?.timeline, initialValues]);

  const TimelineSectionEditorArray = [];
  for (const value in ProjectTimelineStage) {
    if (ProjectTimelineStage.hasOwnProperty(value)) {
      TimelineSectionEditorArray.push(
        ProjectTimelineStage[value as keyof typeof ProjectTimelineStage]
      );
    }
  }
  if (
    !initialValues ||
    !initialValues[TimelineSectionEditorArray[0]]?.start_date
  ) {
    return null;
  }
  return (
    <NatFlex ROW FULL_WIDTH>
      <ProposalViewerEditorContainer hidden={!isDevelopmentByVilla}>
        <NatFlex ROW FULL_WIDTH VERTICAL_ALIGN={NatVerticalAlign.CENTER}>
          <NatLabel
            label={"Edit Timeline"}
            type={NatLabelType.H3}
            weight={NatLabelWeight.MEDIUM}
          />
          <NatButton
            label={"Save"}
            size={NatSize.SMALL}
            spinnerEnabled={true}
            disabled={!editsHaveBeenMade}
            clickEvent={() => {
              return updateProjectTimeline(id, formik.values);
            }}
            option={StyleOption.PRIMARY}
          />
          <NatButton
            label={"Discard Changes"}
            size={NatSize.SMALL}
            disabled={!editsHaveBeenMade}
            clickEvent={() => {
              formik.setValues(initialValues);
            }}
            option={StyleOption.DESTRUCTIVE}
          />
        </NatFlex>
        <hr style={{width: "100%"}} />
        <NatFlex ROW FULL_WIDTH NOWRAP>
          <NatFlex COLUMN>
            {TimelineSectionEditorArray.map((timelineSection) => {
              return (
                <SectionAndDateEditor
                  title={timelineSection}
                  key={timelineSection}
                  formikValues={formik.values}
                  displayQuarters={props.displayQuarters}
                  onChangeStartDate={(value: Date) => {
                    formik.setFieldValue(timelineSection, {
                      ...formik.values[timelineSection],
                      start_date: value.getTime(),
                    });
                  }}
                  onChangeEndDate={(value: Date) => {
                    formik.setFieldValue(timelineSection, {
                      ...formik.values[timelineSection],
                      end_date: value.getTime(),
                    });
                  }}
                />
              );
            })}
          </NatFlex>
          <div style={{flexGrow: 1}}>
            <B2BTimelineChart
              data={formik.values}
              displayOptions={{
                showSectionTitles: false,
                showLabelsOnBars: false,
                showTooltip: false,
                showLegend: false,
                displayQuarters: props.displayQuarters,
              }}
            />
          </div>
        </NatFlex>
      </ProposalViewerEditorContainer>
      <ProposalEditorElementPreviewWrapper
        title={TIMELINE_PREVIEW_TITLE}
        hideHr={!isDevelopmentByVilla}
      >
        <SmartProposalTimeline />
      </ProposalEditorElementPreviewWrapper>
    </NatFlex>
  );
};

const SectionAndDateEditor = (props: {
  title: ProjectTimelineStage;
  formikValues: {[key: string]: IProjectTimeline};
  onChangeStartDate: (value: Date) => void;
  onChangeEndDate: (value: Date) => void;
  displayQuarters?: boolean;
}) => {
  const currentStartDate = useMemo(() => {
    return new Date(props.formikValues[props.title]?.start_date);
  }, [props.formikValues, props.title]);
  const currentEndDate = useMemo(() => {
    return new Date(props.formikValues[props.title]?.end_date);
  }, [props.formikValues, props.title]);
  const title = useMemo(() => {
    const duration = currentEndDate.getTime() - currentStartDate.getTime();
    const durationDays = Math.round(duration / (1000 * 60 * 60 * 24));
    if (durationDays < 30 && !props.displayQuarters) {
      return `${props.title} (${durationDays} Days)`;
    }
    const durationMonths = Math.round(duration / (1000 * 60 * 60 * 24 * 30));
    if (props.displayQuarters) {
      const durationQuarters = Math.round((durationMonths / 3) * 100) / 100;
      const truncatedDurationQuarters = Math.trunc(durationQuarters);
      const fractionalQuarters = durationQuarters - truncatedDurationQuarters;
      let sanitizedDurationQuarters: string = `${truncatedDurationQuarters}`;
      if (truncatedDurationQuarters === 0) {
        return `${props.title} (< 1 Quarter)`;
      } else if (fractionalQuarters === 0) {
      } else if (fractionalQuarters > 0.6) {
        sanitizedDurationQuarters = `${truncatedDurationQuarters + 1}`;
        if (fractionalQuarters < 0.9) {
          sanitizedDurationQuarters = `< ${sanitizedDurationQuarters}`;
        }
      } else if (fractionalQuarters < 0.4 && fractionalQuarters > 0.1) {
        sanitizedDurationQuarters = `> ${sanitizedDurationQuarters}`;
      }
      if (
        (fractionalQuarters < 0.1 || fractionalQuarters > 0.9) &&
        fractionalQuarters !== 0
      ) {
        sanitizedDurationQuarters = `~ ${sanitizedDurationQuarters}`;
      }
      if (truncatedDurationQuarters === 1) {
        return `${props.title} (${sanitizedDurationQuarters} Quarter)`;
      }
      return `${props.title} (${sanitizedDurationQuarters} Quarters)`;
    }
    if (durationMonths === 1) {
      return `${props.title} (${durationMonths} Month)`;
    }
    return `${props.title} (${durationMonths} Months)`;
  }, [currentEndDate, currentStartDate, props.displayQuarters, props.title]);
  return (
    <div style={{width: "fit-content"}}>
      <NatLabel
        label={title}
        type={NatLabelType.P2}
        weight={NatLabelWeight.MEDIUM}
      />
      <NatFlex ROW>
        <DatePickerInput
          type={NatLabelType.P3}
          value={currentStartDate}
          fieldInfo={{
            key: "start-date",
            name: "Start",
            type: "date-picker",
            showTime: false,
            startTime: new Date(0),
          }}
          initialValue={currentStartDate}
          onChange={props.onChangeStartDate}
        />
        <DatePickerInput
          type={NatLabelType.P3}
          value={currentEndDate}
          fieldInfo={{
            key: "end-date",
            name: "End",
            type: "date-picker",
            showTime: false,
            startTime: currentStartDate,
          }}
          initialValue={currentEndDate}
          onChange={props.onChangeEndDate}
        />
      </NatFlex>
    </div>
  );
};
