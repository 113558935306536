import {HighlightedNote} from "../../../../../../../_shared/generics/label/HighlightedNote";
import React from "react";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import {ProjectPriceMode} from "@natomas-org/core";

export const ProposalHighlightNote = (props: {
  label: string;
  presentationStyling?: boolean;
}) => {
  const {projectPriceMode} = useActiveProject();
  if (projectPriceMode !== ProjectPriceMode.CostPlus) {
    return null;
  }
  return (
    <div style={{paddingTop: "0.5rem"}}>
      <HighlightedNote hidden={false} label={props.label} />
    </div>
  );
};
