import React from "react";
import {NatSlider} from "../../../../_shared/generics/slider/NatSlider";
import {
  changeFilter,
  deleteFilterByType,
} from "../../../../_shared/catalog/filter/logic";
import {FilterType} from "../../../../_shared/catalog/filter/types";
import {useUnfilteredCatalogProducts} from "../../../../_shared/hooks/useProductCatalog/useProducts";
import {useActiveCatalogFilters} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogFilters";

export const SquareFootageFilterSlider = React.memo(
  (props: {style?: React.CSSProperties}) => {
    const {info} = useUnfilteredCatalogProducts();
    const {square_footage: activeSqFtFilter} = useActiveCatalogFilters();

    if (!info?.lowestSqFt || !info?.highestSqFt) {
      return null;
    }
    const activeSqFtFilterValue = (activeSqFtFilter as any)?.value;
    return (
      <div style={{width: "100%", ...props.style}}>
        <NatSlider
          label={"Unit Sq. Ft."}
          valueTextFormatter={(value) => {
            return value;
          }}
          minDistanceBetweenThumbPercentInteger={20}
          disabled={false}
          setValues={(values: number[]) => {
            changeFilter({
              type: FilterType.SquareFootage,
              value: {
                low: values[0],
                high: values[1],
              },
              label: "Sq. Ft.",
            });
          }}
          resetValues={() => {
            deleteFilterByType(FilterType.SquareFootage);
          }}
          bounds={[info?.lowestSqFt, info?.highestSqFt]}
          values={
            activeSqFtFilterValue?.low && activeSqFtFilterValue?.high
              ? [activeSqFtFilterValue?.low, activeSqFtFilterValue?.high]
              : [info?.lowestSqFt, info?.highestSqFt]
          }
        />
      </div>
    );
  }
);
