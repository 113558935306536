import React, {useMemo} from "react";
import SSCRowTemplate from "../../_shared/SSCRowTemplate";
import SSCTitle from "./index";
import {SeriesSummaryComparisonSecondary} from "../../../SeriesBreakdownAdapter";
import SSCSelector from "./SSCSelector";
import SSCViewSeriesComponent from "./SSCViewSeriesComponent";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";

interface SSCTitleRowProps {
  primaryTitle: string;
  secondary: SeriesSummaryComparisonSecondary;
}

const SSCTitleRow = (props: SSCTitleRowProps) => {
  const {primaryTitle, secondary} = props;
  const {id: targetId, options} = secondary;

  const otherTitle = options?.find((option) => option.id === targetId)?.title;

  const secondaryTitleComponent = useMemo(() => {
    if (options && options?.length > 1) {
      return <SSCSelector {...secondary} />;
    } else if (otherTitle) {
      return (
        <SSCBlockContainer>
          <SSCTitle title={otherTitle} />
          <SSCViewSeriesComponent id={targetId} />
        </SSCBlockContainer>
      );
    }
  }, [targetId, options, otherTitle, secondary]);

  return (
    <SSCRowTemplate options={{ignorePadding: {top: true}}}>
      <SSCTitle title={primaryTitle} />
      {secondaryTitleComponent}
    </SSCRowTemplate>
  );
};

export default SSCTitleRow;
