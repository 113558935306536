import React from "react";
import {AdminInput} from "../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {
  DetailsContainer,
  ImageContainer,
  ImageDetailsContainer,
  ImageManagerContainer,
  NewImageContainer,
} from "./styles";
import {
  IImageDetails,
  Image,
  ImageCategory,
  ImageStatus,
  isBlankString,
} from "@natomas-org/core";
import {
  GenericInputContentContainer,
  InputTitle,
} from "../../../_shared/styles/AdminStyles";

const GetImageDetailsContainer = (props: any) => {
  const {
    index,
    imageDetails,
    editImageDetails,
    removeImage,
    initialValue,
    enabled,
  } = props;
  const imageInitialValue =
    initialValue?.length > index ? initialValue[index] : {};
  const key = "product-image-manager-image-" + index;
  return (
    <ImageDetailsContainer key={key}>
      <ImageContainer>
        <AdminInput
          key={key + "-imageId"}
          fieldInfo={{
            type: "image",
            alwaysEnabled: enabled,
            defaultValue: "",
            key: key + "-imageId",
          }}
          value={imageDetails?.imageId}
          initialValue={imageInitialValue?.imageId}
          onChange={(value: any) => {
            if (isBlankString(value)) {
              removeImage(index);
            } else {
              editImageDetails(imageDetails, "imageId", value, index);
            }
          }}
        />
      </ImageContainer>
      {imageDetails?.imageId && (
        <DetailsContainer>
          <AdminInput
            key={key + "-status"}
            fieldInfo={{
              name: "Status",
              type: "dropdown",
              alwaysEnabled: enabled,
              options: {
                unlisted: {label: "Unlisted", value: ImageStatus.UNLISTED},
                listed: {label: "Listed", value: ImageStatus.LISTED},
              },
              key: key + "-status",
            }}
            value={imageDetails?.status}
            initialValue={imageInitialValue?.status}
            onChange={(value: any) => {
              editImageDetails(imageDetails, "status", value, index);
            }}
          />
          <AdminInput
            key={key + "-categories"}
            fieldInfo={{
              name: "Page Layout Categories",
              type: "dropdown-array",
              alwaysEnabled: enabled,
              defaultValue: [],
              options: Object.values(ImageCategory),
              key: key + "-categories",
            }}
            value={imageDetails?.categories}
            initialValue={imageInitialValue?.categories}
            onChange={(value: any) => {
              editImageDetails(imageDetails, "categories", value, index);
            }}
          />
          <AdminInput
            key={key + "-description-container"}
            value={imageDetails?.description}
            initialValue={imageInitialValue?.description}
            fieldInfo={{
              key: key + "-description",
              name: "Description",
              alwaysEnabled: enabled,
              type: "textarea",
              defaultValue: "A short description about the image.",
            }}
            onChange={(e: {target: {value: any}}) =>
              editImageDetails(
                imageDetails,
                "description",
                e.target.value,
                index
              )
            }
          />
        </DetailsContainer>
      )}
    </ImageDetailsContainer>
  );
};

export const ImageManager = (props: any) => {
  const {value, fieldInfo, onChange, initialValue, enabled} = props;
  const {name, key} = fieldInfo;

  const updateImageDetails = (imageDetails: IImageDetails, index: number) => {
    const newList = [...value];
    if (value.length <= index) {
      newList.push(imageDetails);
    } else {
      newList.splice(index, 1, imageDetails);
    }
    onChange(newList);
  };

  const removeImage = (index: number) => {
    const newList = [...value];
    newList.splice(index, 1);
    onChange(newList);
  };

  const editImageDetails = (
    imageDetails: IImageDetails | undefined | null,
    key: keyof IImageDetails,
    value: any,
    index: number
  ) => {
    let newImageDetails =
      imageDetails ?? ({status: ImageStatus.LISTED} as IImageDetails);
    switch (key) {
      case "imageId":
        newImageDetails = Image.changeImageId(newImageDetails, value);
        break;
      case "categories":
        newImageDetails = Image.changeCategories(newImageDetails, value);
        break;
      case "description":
        newImageDetails = Image.changeDescription(newImageDetails, value);
        break;
      case "status":
        newImageDetails = Image.changeStatus(newImageDetails, value);
        break;
    }
    if (newImageDetails) {
      updateImageDetails(newImageDetails, index);
    }
  };

  return (
    <>
      <ImageManagerContainer key={key}>
        {value?.length > 0 && (
          <>
            <InputTitle
              bold={true}
              title={name + " (" + value?.length + ")"}
              enabled={enabled}
            />
            <GenericInputContentContainer>
              {value?.map((image: IImageDetails, index: number) => {
                const key = "product-image-manager-image-" + index;
                return (
                  <GetImageDetailsContainer
                    key={key}
                    enabled={enabled}
                    index={index}
                    imageDetails={image}
                    editImageDetails={editImageDetails}
                    removeImage={removeImage}
                    initialValue={initialValue}
                  />
                );
              })}
            </GenericInputContentContainer>
          </>
        )}
        {enabled && (
          <NewImageContainer>
            <InputTitle bold={true} title={"Add new image"} enabled={enabled} />
            <GetImageDetailsContainer
              index={value?.length ?? 0}
              imageDetails={null}
              enabled={enabled}
              editImageDetails={editImageDetails}
            />
          </NewImageContainer>
        )}
      </ImageManagerContainer>
    </>
  );
};
