import React from "react";
import {IProduct} from "@natomas-org/core/";
import {IProductAssociationDetails} from "../../../../../_shared/interfaces";
import {ConditionAssociationsTableContainer} from "./styles";

export const ConditionAssociationsTable = (props: {
  modifiers: {value: string; label: string}[];
  products: IProduct[];
  validProducts: IProductAssociationDetails[];
}) => {
  const {modifiers, products, validProducts} = props;
  return (
    <ConditionAssociationsTableContainer>
      <thead>
        <tr>
          <th rowSpan={2}>Products</th>
          {modifiers?.length > 0 && (
            <th colSpan={modifiers?.length ?? 0}>Modifiers</th>
          )}
        </tr>
      </thead>
      <tbody>
        {products?.map((e: IProduct) => {
          const associationDetails = validProducts.find(
            (p: IProductAssociationDetails) => p.product.id === e.id
          );
          return (
            <tr key={e.id}>
              <td
                key={e.id + "_title"}
                style={{
                  backgroundColor: associationDetails?.isValid
                    ? "#56e056"
                    : "#e54c4c",
                }}
              >
                {e.title}
              </td>
              {modifiers?.map((m: {value: string; label: string}) => {
                return (
                  <td
                    key={e + m.value}
                    style={{
                      backgroundColor: associationDetails?.contains.includes(
                        m.value
                      )
                        ? "#adfdad"
                        : "#ff8989",
                    }}
                  >
                    {m?.label}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </ConditionAssociationsTableContainer>
  );
};
