import {ActionButtonContainer} from "../../../../../_shared/styles";
import {ArchiveProjectAction} from "../AdminProjectActions/ArchiveProjectAction";
import {ControlPanelSectionWrapper} from "../ControlPanelSectionWrapper";
import React from "react";

export const DangerZoneControlPanelSection = () => {
  return (
    <ControlPanelSectionWrapper title={"Danger Zone"}>
      <ActionButtonContainer
        style={{minWidth: "20rem", maxWidth: "100%", width: "100%"}}
      >
        <ArchiveProjectAction />
      </ActionButtonContainer>
    </ControlPanelSectionWrapper>
  );
};
