import {InputLibrary} from "./library/inputs";
import {FormProps} from "../../2-component/Form/interface";
import Form from "../../2-component/Form/form";
import React from "react";

const inputs = [InputLibrary.emailInput];

const ResetPasswordForm = (props: FormProps) => {
  return (
    <>
      <Form
        inputs={inputs}
        onSubmit={props.onSubmit}
        buttons={[...(props.buttons ?? [])]}
      />
    </>
  );
};

export default ResetPasswordForm;
