import React from "react";
import SSCRowTemplate from "../../_shared/SSCRowTemplate";
import SSCImage from "./index";

export interface SSCImageRowProps {
  primaryImageId: string;
  otherImageId?: string;
}

const SSCImageRow = (props: SSCImageRowProps) => {
  const {primaryImageId, otherImageId} = props;

  return (
    <SSCRowTemplate options={{ignorePadding: {bottom: true}}}>
      <SSCImage imageId={primaryImageId} showBorder={true} />
      {otherImageId && <SSCImage imageId={otherImageId} />}
    </SSCRowTemplate>
  );
};

export default SSCImageRow;
