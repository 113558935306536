import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";

export const WhatHappensNextContainer = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 1.25rem;
  padding: 0.5rem 0.5rem;
`;
export const BuildInfoModalContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
`;
export const BuildInfoModalHeader = styled.div`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_MEDIUM};
`;
export const BuildInfoItem = styled.div`
  font-size: 1rem;
  padding-right: 0.5rem;
  padding-left: 0.75rem;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
