import React from "react";
import {Route, Switch} from "react-router-dom";
import {
  ADMIN_COMPLETED_PROJECTS_PATH,
  ADMIN_CUSTOMER_DATABASE_PATH,
  ADMIN_CUSTOMER_PATH,
  ADMIN_DATA_PATH,
  ADMIN_DEVELOPER_PATH,
  ADMIN_EMPLOYEE_MANAGER_PATH,
  ADMIN_FACTORY_LINE_INFO_PATH,
  ADMIN_FACTORY_LINE_PATH,
  ADMIN_PRODUCT_CATALOG_PATH,
  ADMIN_PRODUCT_CATALOG_ROUTING_PATH,
  ADMIN_PRODUCT_ID_PATH,
  ADMIN_PRODUCT_INSTANT_QUOTE_PATH,
  ADMIN_PRODUCT_INVENTORY_PATH,
  ADMIN_PRODUCT_LINE_PATH,
  ADMIN_PRODUCT_PATH,
  ADMIN_ROOT_PATH,
} from "../paths";
import {EmployeeManagementView} from "../components/admin/EmployeeManagementView";
import {AdminLandingView} from "../components/admin/LandingViews/AdminLandingView";
import {AdminStudioManagerView} from "../components/admin/ProductLineView/AdminStudioManagerView";
import {AdminProductCatalogView} from "../components/admin/ProductCatalogView";
import {CustomerDatabaseView} from "../components/admin/CustomerDatabase/CustomerDatabaseView";
import {CustomerManagerView} from "../components/admin/CustomerDatabase/CustomerManagerView";
import {DeveloperView} from "../components/admin/DeveloperView";
import {usePermissions} from "../components/_shared/hooks/usePermissions/usePermissions";
import {useCurrentUser} from "../components/_shared/hooks/useCurrentUser";
import {Helmet} from "react-helmet-async";
import {FactoryInfoSet} from "../components/factory-info-set";
import ProductCatalogRouting from "../components/routing";
import {AdminCatalogSliceManager} from "../root/AdminCatalogSliceManager";
import {ReadOnlyFactoryInfo} from "../components/factory-info-set/ReadOnlyViews";
import {CompletedProjectsEditor} from "../components/admin/CompletedProjectsEditor/CompletedProjectsEditor";
import AdminInventoryView from "../components/admin/InventoryView";
import {PermissionDeniedView} from "../components/admin/LandingViews/PermissionDeniedView";
import {NatomasDataExport} from "../components/admin/DeveloperView/NatomasDataExport/NatomasDataExport";
import AdminProductPage from "../0-app/1-container/admin-product-page";
import AdminProductInstantQuotePage from "../0-app/1-container/admin-product-instant-quote-page";

const AdminRoutes = () => {
  const {isAdmin, uid, authInitialized} = useCurrentUser();
  const {access, fullAccess, isFetching} = usePermissions(uid);

  const permissionDeniedView = (
    <PermissionDeniedView
      initialized={authInitialized}
      isAdmin={isAdmin}
      fetchingPermissions={isFetching}
    />
  );

  return (
    <>
      {isAdmin && (
        <>
          <AdminCatalogSliceManager />
          <Helmet>
            <title>Villa | Admin</title>
            <meta name="description" content="Administration" />
          </Helmet>
          <Route exact path={ADMIN_ROOT_PATH}>
            <AdminLandingView />
          </Route>
          <Route exact path={ADMIN_FACTORY_LINE_INFO_PATH}>
            <ReadOnlyFactoryInfo />
          </Route>
          <Route exact path={ADMIN_EMPLOYEE_MANAGER_PATH}>
            {fullAccess ? <EmployeeManagementView /> : permissionDeniedView}
          </Route>
          <Route path={ADMIN_DEVELOPER_PATH}>
            {access?.developer ? <DeveloperView /> : permissionDeniedView}
          </Route>
          <Switch>
            <Route exact path={ADMIN_PRODUCT_PATH}>
              <AdminProductPage />
            </Route>
            <Route exact path={ADMIN_PRODUCT_ID_PATH}>
              <AdminProductInstantQuotePage />
            </Route>
            <Route exact path={ADMIN_PRODUCT_INSTANT_QUOTE_PATH}>
              <AdminProductInstantQuotePage />
            </Route>
            {/*<Route exact path={ADMIN_PRODUCT_INSTANT_QUOTE_LOCATION_PATH}>*/}
            {/*  <AdminProductInstantQuoteLocationPage />*/}
            {/*</Route>*/}
          </Switch>
          {/*<Switch>*/}
          {/*  <Route exact path={ADMIN_LOCATIONS_PATH}>*/}
          {/*    <AdminLocationsPage />*/}
          {/*  </Route>*/}
          {/*  <Route exact path={ADMIN_LOCATION_PATH}>*/}
          {/*    <AdminLocationPage />*/}
          {/*  </Route>*/}
          {/*</Switch>*/}
          <Route exact path={ADMIN_DATA_PATH}>
            {access?.customers ? <NatomasDataExport /> : permissionDeniedView}
          </Route>
          <Route exact path={ADMIN_CUSTOMER_DATABASE_PATH}>
            {access?.customers ? (
              <CustomerDatabaseView />
            ) : (
              permissionDeniedView
            )}
          </Route>
          <Route exact path={ADMIN_CUSTOMER_PATH}>
            {access?.customers ? <CustomerManagerView /> : permissionDeniedView}
          </Route>
          <Route exact path={ADMIN_PRODUCT_LINE_PATH}>
            {access?.productLines ? (
              <AdminStudioManagerView />
            ) : (
              permissionDeniedView
            )}
          </Route>
          <Route exact path={ADMIN_COMPLETED_PROJECTS_PATH}>
            <CompletedProjectsEditor />
          </Route>
          <Route exact path={ADMIN_FACTORY_LINE_PATH}>
            <FactoryInfoSet />
          </Route>
          <Route exact path={ADMIN_PRODUCT_INVENTORY_PATH}>
            <AdminInventoryView />
          </Route>
          <Route exact path={ADMIN_PRODUCT_CATALOG_ROUTING_PATH}>
            <ProductCatalogRouting />
          </Route>
          <Route exact path={ADMIN_PRODUCT_CATALOG_PATH}>
            {access?.productLines ? (
              <AdminProductCatalogView />
            ) : (
              permissionDeniedView
            )}
          </Route>
        </>
      )}
      {!isAdmin && (
        <>
          <Route path={ADMIN_ROOT_PATH}>{permissionDeniedView}</Route>
        </>
      )}
    </>
  );
};
export default AdminRoutes;
