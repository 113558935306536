import React, {useMemo, useState} from "react";
import {MyUploadsSection} from "./MyUploadsSection";
import {
  ImageFinderHeaderBar,
  ImageFinderHeaderButtonContainer,
} from "./_shared/styles";
import {NatButton} from "../../button";
import {NatSize, StyleOption} from "../../_shared";
import {SearchByTagSection} from "./SearchByTagSection";
import {SearchAllSection} from "./SearchAllSection";
import {ZoomController} from "./_shared/ZoomController";

export enum ImageFinderState {
  MY_UPLOADS = 1,
  BY_TAG = 2,
  ALL = 3,
}

export interface ImageFinderSectionProps {
  selectImage: ((imageId: string) => any) | null;
  showEditDetails: boolean;
}

export const ImageFinder = (props: {
  showEditDetails?: boolean;
  selectImage?: (imageId: string) => any;
  style?: any;
  title?: string;
}) => {
  const {selectImage, style, title, showEditDetails} = props;
  const [currentState, setState] = useState<ImageFinderState>(
    ImageFinderState.MY_UPLOADS
  );

  const childProps = useMemo(() => {
    return {
      selectImage: selectImage ?? null,
      showEditDetails: showEditDetails ?? false,
    };
  }, [showEditDetails, selectImage]);

  return (
    <>
      <ImageFinderHeaderBar>
        <div>{title ?? "Image Finder"}</div>
        <ImageFinderHeaderButtonContainer>
          <ZoomController />
          <NatButton
            label={"My Uploads"}
            type={"button"}
            size={NatSize.XSMALL}
            disabled={currentState === ImageFinderState.MY_UPLOADS}
            option={StyleOption.PRIMARY_ALT}
            clickEvent={() => setState(ImageFinderState.MY_UPLOADS)}
          />
          <NatButton
            label={"Search By Tag"}
            type={"button"}
            size={NatSize.XSMALL}
            disabled={currentState === ImageFinderState.BY_TAG}
            option={StyleOption.PRIMARY_ALT}
            clickEvent={() => setState(ImageFinderState.BY_TAG)}
          />
          <NatButton
            label={"Search All"}
            type={"button"}
            size={NatSize.XSMALL}
            disabled={currentState === ImageFinderState.ALL}
            option={StyleOption.PRIMARY_ALT}
            clickEvent={() => setState(ImageFinderState.ALL)}
          />
        </ImageFinderHeaderButtonContainer>
      </ImageFinderHeaderBar>
      <div style={style ?? {height: "70vh", overflow: "auto"}}>
        {currentState === ImageFinderState.MY_UPLOADS && (
          <MyUploadsSection {...childProps} />
        )}
        {currentState === ImageFinderState.BY_TAG && (
          <SearchByTagSection {...childProps} />
        )}
        {currentState === ImageFinderState.ALL && (
          <SearchAllSection {...childProps} />
        )}
      </div>
    </>
  );
};
