import {executeRequest} from "../APIExecutor";
import {tryFetchingCatalogFactory} from "../../../../root/FetchingManager";
import {DuplicateFactoryLineElementRequestType} from "@natomas-org/core";

const duplicateFactoryLineElementPath = "/admin/v1/duplicateFactoryLineElement";

export const duplicateFactoryLineElement = (
  factoryLineId: string,
  requestType: string,
  requestId?: string
) => {
  return executeRequest(duplicateFactoryLineElementPath, {
    factoryLineId: factoryLineId,
    requestType: requestType,
    requestId: requestId,
  })
    .then((r: any) => {
      if (
        r &&
        requestType === DuplicateFactoryLineElementRequestType.FACTORY_LINE
      ) {
        tryFetchingCatalogFactory(r);
      }
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};
