import React from "react";
import {
  CustomerBudgetSummaryManagerId,
  CustomerControlPanelId,
  CustomerDocusignManagerId,
  CustomerOrderFormManagerId,
  CustomerProjectNotesManagerId,
  CustomerProposalViewerEditorId,
  CustomerQuickInfoId,
  CustomerSitePlanManagerId,
  IAdminCustomerTab,
  ProjectCartTabId,
} from "../tabs";
import {ControlPanelTab} from "../../CustomerProjectTabs/ControlPanelTab";
import {SitePlanManagerTab} from "../../CustomerProjectTabs/SitePlanManagerTab";
import {BudgetSummaryTab} from "../../CustomerProjectTabs/BudgetSummaryTab";
import {ProposalViewerAndEditor} from "../../CustomerProjectTabs/ProposalViewerEditor/ProposalViewerEditor";
import {NotesTab} from "../../CustomerProjectTabs/ProjectNotesTab";
import {DocumentsManagerTab} from "../../CustomerProjectTabs/DocusignManagerTab";
import {OrderFormTab} from "../../CustomerProjectTabs/OrderFormTab";
import {QuickInfoTab} from "../../CustomerProjectTabs/QuickInfoTab";
import CartView from "../../../../../../portal/views/CartView";
import AdminEstimateTableView from "../../../../../../estimate/AdminEstimateTableView";

export const getAdminQuickInfoTab = (): IAdminCustomerTab => {
  return {
    component: <QuickInfoTab />,
    id: CustomerQuickInfoId,
    title: "Quick Info",
  };
};
export const getAdminCartItemsTab = (): IAdminCustomerTab => {
  return {
    component: <CartView />,
    id: ProjectCartTabId,
    title: "Units/Products",
  };
};
export const getAdminControlPanelTab = (): IAdminCustomerTab => {
  return {
    component: <ControlPanelTab />,
    id: CustomerControlPanelId,
    title: "Control Panel",
  };
};

export const getAdminSiteManagerTab = (): IAdminCustomerTab => {
  return {
    component: <SitePlanManagerTab />,
    id: CustomerSitePlanManagerId,
    title: "Site Plans",
  };
};
export const getAdminBudgetSummaryManagerTab = (
  mode: "dtc" | "b2b"
): IAdminCustomerTab => {
  if (mode === "dtc") {
    return {
      component: <AdminEstimateTableView />,
      id: CustomerBudgetSummaryManagerId,
      title: "Budget Summary",
    };
  } else if (mode === "b2b") {
    return {
      component: <BudgetSummaryTab />,
      id: CustomerBudgetSummaryManagerId,
      title: "Budget Summary",
    };
  } else {
    throw new Error("Invalid mode");
  }
};

export const getAdminCustomerProposalViewerEditorTab =
  (): IAdminCustomerTab => {
    return {
      component: <ProposalViewerAndEditor />,
      id: CustomerProposalViewerEditorId,
      title: "Proposal Viewer/Editor",
    };
  };
export const getAdminNotesTab = (): IAdminCustomerTab => {
  return {
    component: <NotesTab />,
    id: CustomerProjectNotesManagerId,
    title: "Notes & Tasks",
  };
};
export const getAdminDocumentsManagerTab = (): IAdminCustomerTab => {
  return {
    component: <DocumentsManagerTab />,
    id: CustomerDocusignManagerId,
    title: "Documents",
  };
};
export const getAdminCustomerOrderFormManagerTab = (): IAdminCustomerTab => {
  return {
    component: <OrderFormTab />,
    id: CustomerOrderFormManagerId,
    title: "Order Form",
  };
};
