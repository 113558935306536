import React, {useEffect, useState} from "react";
import {useImageFinder} from "../../../../../hooks/useImageFinder";
import {ImageGallery} from "../../_shared/ImageGallery";

export interface SearchProductGroupCategoryProps {
  showEditDetails: boolean;
  selectImage: ((id: string) => any) | null;
  categoryId?: string;
}

export const SearchProductGroupCategory = (
  props: SearchProductGroupCategoryProps
) => {
  const {showEditDetails, categoryId, selectImage} = props;
  const {searchCategories} = useImageFinder();
  const [imageIds, setImageIds] = useState<string[]>([]);

  useEffect(() => {
    if (categoryId) {
      getCategoryImages();
    }
  }, [categoryId]);

  const getCategoryImages = () => {
    if (
      !categoryId ||
      !searchCategories ||
      !searchCategories?.[categoryId]?.renderings
    ) {
      setImageIds([]);
      return;
    }
    let ids: string[] = [];
    // Get all images in this category
    Object.values(searchCategories?.[categoryId]?.renderings)?.forEach(
      (rendering: any) => {
        if (rendering?.imageId && !ids?.includes(rendering?.imageId)) {
          ids.push(rendering?.imageId);
        }
      }
    );
    setImageIds(ids);
  };

  return (
    <ImageGallery
      showEditDetails={showEditDetails}
      imageIds={imageIds}
      selectImage={selectImage}
      isEnd={true}
    />
  );
};
