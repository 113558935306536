import {RelatedProjects} from "../../../../../../catalog/_shared/components/RelatedProjects";
import React, {Dispatch, SetStateAction, useMemo} from "react";
import {useDynamicValue} from "../../../../../../_shared/hooks/useDynamicValue";
import {useMap} from "../../../../../../_shared/hooks/useMap";
import {usePage} from "../../../../../../_shared/hooks/usePage";
import {useCatalogSession} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {IPublicProjectInfoModalPayload} from "../../../../../../mapping/views/components/ProjectDetailsModal/constants";
import {IPublicProjectInfo} from "@natomas-org/core";
import {PublicProjectInfo} from "@natomas-org/service";

export const MapProjectsSidebarContent = (props: {
  setProjectModalPayload?: Dispatch<
    SetStateAction<IPublicProjectInfoModalPayload | null>
  > | null;
  showProjectMap: boolean;
  setShowProjectMap: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {glMapState} = useMap();
  const {sortBy} = useCatalogSession();
  const {isNatMobile} = usePage();
  const {setProjectModalPayload, showProjectMap, setShowProjectMap} = props;
  const numberOfProductsInRow = useDynamicValue({
    forFour: 1,
    forEight: 2,
    forTwelve: 2,
    forSixteen: 3,
  });
  const completedProjectsToDisplay: IPublicProjectInfo[] = useMemo(() => {
    if (isNatMobile) {
      return [];
    }
    return PublicProjectInfo.getFeaturedPublishedProjectsInViewport(
      glMapState?.projectsInViewport
    );
  }, [isNatMobile, glMapState?.projectsInViewport]);
  return (
    <div>
      <RelatedProjects
        title={"Completed Villa homes"}
        description={`We've completed hundreds of homes across CA. Explore the possibilities.`}
        productSort={sortBy}
        numberOfProductsInRow={numberOfProductsInRow}
        projectsToDisplay={completedProjectsToDisplay}
        cardHeight={isNatMobile ? 18 : 11.5}
        imageHeight={isNatMobile ? 11 : 8}
        includeHorizontalRule={false}
        showAllCards={true}
        formatDisplayForMap={true}
        setProjectModalPayload={setProjectModalPayload}
        setShowProjectMap={setShowProjectMap}
        showProjectMap={showProjectMap}
      />
    </div>
  );
};
