import styled from "styled-components";
import {WHITE} from "../../../../_shared/colors";

export const Tab = styled.div`
  width: 100%;
  height: 100vh;
`;

export const OptionView = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100% - 4rem);
`;

export const OptionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

export const NewOptionContainer = styled.div`
  padding: 1rem;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const OptionRow = styled.div<{active: boolean}>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${(props) =>
    props.active === true ? "rgba(0, 0, 0, 0.1)" : WHITE};
  &:hover {
    background-color: ${(props) =>
      props.active === true ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  }
`;

export const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
`;

export const OptionEditor = styled.div`
  width: 75%;
  padding: 1rem 1rem 0 1rem;
`;
