import styled from "styled-components";
import {LIGHTBOX_Z_INDEX} from "../../../../_shared/styles";
import {NatomasButton} from "../../../../_shared/generics/button/styles";

export const LightboxContainer = styled.div`
  .lb-container {
    z-index: ${LIGHTBOX_Z_INDEX};
    height: 100%;
  }
`;

export const ProductImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 1rem;
`;

export const FullScreenButton = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 3rem;
  ${NatomasButton} {
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 2.25rem;
    padding: 0.25rem;
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
`;
