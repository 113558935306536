import {BudgetEntryCellProps} from "./EstimateEditorTable";

export type Operation = {
  type: "UPDATE" | "DELETE" | "CREATE";
  fromRowIndex: number;
  toRowIndex: number;
};
export const onEstimateEditorChange = (
  newValue: any,
  operations: Operation[]
) => {
  for (const operation of operations) {
    // Handle new rows
    const newTableData = [...newValue];
    if (operation.type === "UPDATE") {
      newValue
        .slice(operation.fromRowIndex, operation.toRowIndex)
        .forEach((row: any, idx: number) => {
          console.log(row);
          const gp_low = (row.price_low - row.cost_low).toFixed(2);
          const gp_high = (row.price_high - row.cost_high).toFixed(2);
          let gm_low: string = "-";
          if (row.cost_low > 0) {
            gm_low = (
              ((row.price_low - row.cost_low) / row.cost_low) *
              100
            ).toFixed(2);
            gm_low = `${gm_low}%`;
          }
          let gm_high: string = "-";
          if (row.cost_high > 0) {
            gm_high = (
              ((row.price_high - row.cost_high) / row.cost_high) *
              100
            ).toFixed(2);
            gm_high = `${gm_high}%`;
          }
          newTableData[operation.fromRowIndex + idx].price_high =
            sanitizeStringToNumber(row.price_high);
          newTableData[operation.fromRowIndex + idx].price_low =
            sanitizeStringToNumber(row.price_low);
          newTableData[operation.fromRowIndex + idx].cost_high =
            sanitizeStringToNumber(row.cost_high);
          newTableData[operation.fromRowIndex + idx].cost_low =
            sanitizeStringToNumber(row.cost_low);
          newTableData[operation.fromRowIndex + idx].gp_low = gp_low;
          newTableData[operation.fromRowIndex + idx].gp_high = gp_high;
          newTableData[operation.fromRowIndex + idx].gm_low = gm_low;
          newTableData[operation.fromRowIndex + idx].gm_high = gm_high;
        });
    }
    // Handle delete
  }
  return newValue;
};

const sanitizeStringToNumber = (value: string) => {
  return parseInt(value);
};

export const areAllElementsLockedOrSelected = (
  entries: BudgetEntryCellProps[]
): boolean => {
  const toVerify = entries.filter((entry) => entry.locked === false);
  console.log(toVerify);
  return toVerify.every((entry) => entry.selected);
};
