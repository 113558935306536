import React, {useState} from "react";
import {firestoreExportTransfer} from "../../../../database/firebase/api/admin";

const FirestoreExportTransferForm: React.FC = () => {
  const [bucket, setBucket] = useState<string>("");
  const [folder, setFolder] = useState<string>("");

  const handleInputChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBucket(event.target.value);
  };

  const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolder(event.target.value);
  };

  const handleSubmit = async () => {
    await firestoreExportTransfer(bucket, folder);
    setBucket("");
    setFolder("");
  };

  return (
    <div>
      <input
        type="text"
        value={bucket}
        onChange={handleInputChange1}
        placeholder="Bucket"
      />
      <input
        type="text"
        value={folder}
        onChange={handleInputChange2}
        placeholder="Folder"
      />
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default FirestoreExportTransferForm;
