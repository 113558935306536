import React, {useMemo} from "react";
import Select, {components} from "react-select";
import {FONT_FAMILY_REGULAR} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {NatDownChevron} from "../../../../_shared/icon/icons";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <NatDownChevron />
    </components.DropdownIndicator>
  );
};

export const InfoSetColumnToggleFilter = (columnProps: any) => {
  const {id, filterValue, setFilter} = columnProps.column;
  const options = useMemo(() => {
    const options: {label: string; value: string}[] = [];
    options.push({
      label: "All",
      value: "",
    });
    options.push({
      label: "Yes",
      value: "true",
    });
    options.push({
      label: "No",
      value: "false",
    });
    return options;
  }, []);

  const selected = options.find((o) => o.value === filterValue);

  // Render a multi-select box
  return (
    <Select
      id={`column-sort-toggle-${id}`}
      components={{DropdownIndicator}}
      styles={{
        container: (provided) => ({
          ...provided,
          minWidth: "8rem",
          fontFamily: FONT_FAMILY_REGULAR,
          fontWeight: "normal",
        }),

        // @ts-ignore
        control: (provided) => ({
          ...provided,
          minHeight: "24px",
          height: "24px",
        }),

        valueContainer: (provided) => ({
          ...provided,
          height: "24px",
          padding: "0 6px",
        }),

        input: (provided) => ({
          ...provided,
          margin: "0px",
          color: "transparent",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: "24px",
        }),
      }}
      onChange={(option: any) => {
        setFilter(option?.value || undefined);
      }}
      value={selected}
      options={options}
      defaultValue={options.find((o) => o.value === "")}
    />
  );
};
