import {
  NatContentListProps,
  POPUP_ANIMATION_SPEED_MS,
} from "../../../components/NatPopup/NatPopup";
import React, {useMemo, useState} from "react";
import {NatPopupStyleOptions, NatPopupType} from "../../../constants";
import {usePage} from "../../../../../../hooks/usePage";
import {FormattedListItem} from "../../../components/NatPopup/FormattedListItem";
import {
  NatomasPopupClose,
  NatomasPopupContent,
  NatomasPopupContentContainer,
  NatomasPopupTitleContainer,
  PopupContainer,
} from "../../../components/NatPopup/styles";
import {NatModal} from "../../../../../modal/NatModal";
import {DEFAULT_DROPDOWN_STYLING, PopupModalContainer} from "../styles";
import {IoMdClose} from "react-icons/io";
import {PopupTitleElement} from "./PopupTitleElement";

export const ContentElementFromList = (props: {
  listProps: NatContentListProps;
  getModal: boolean;
}) => {
  const {contentItemArray, show, selectedValue, style} = props.listProps;

  const [isMinimized, setIsMinimized] = useState<boolean>(
    style.ADDITIONAL_STYLE_OPTIONS?.includes(
      NatPopupStyleOptions.INITIALLY_MINIMIZED
    ) ?? false
  );
  const {isNatMobile} = usePage();

  const additionalStyleOptions = [NatPopupStyleOptions.NO_PADDING];
  if (style?.ADDITIONAL_STYLE_OPTIONS) {
    additionalStyleOptions.push(...style?.ADDITIONAL_STYLE_OPTIONS);
  }
  style.ADDITIONAL_STYLE_OPTIONS = additionalStyleOptions;

  const popupCSS: React.CSSProperties = useMemo(() => {
    const options: React.CSSProperties = Object.assign(
      {},
      props.listProps?.style?.popupCSS
    );
    style.ADDITIONAL_STYLE_OPTIONS?.forEach((option: NatPopupStyleOptions) => {
      switch (option) {
        case NatPopupStyleOptions.NO_ITEMS_BORDER:
          options.borderTop = "none";
          break;
        case NatPopupStyleOptions.RIGHT_ALIGNED:
          options.right = "0";
          break;
        case NatPopupStyleOptions.CENTER_ALIGNED:
          options.right = "-27vw";
          options.left = "-27vw";
          break;
        case NatPopupStyleOptions.VERTICAL_SPACING_MEDIUM:
          options.rowGap = "1rem";
          break;
        case NatPopupStyleOptions.NO_PADDING:
          options.padding = "0 0";
          break;
      }
    });
    if (style.POPUP_HEIGHT) {
      options.height = style.POPUP_HEIGHT;
    }
    if (style.POPUP_BORDER_RADIUS) {
      options.borderRadius = style.POPUP_BORDER_RADIUS;
    }
    if (style.TYPE === NatPopupType.MINIMIZE) {
      if (!isMinimized) {
        options.borderRadius = `${style.POPUP_BORDER_RADIUS} ${style.POPUP_BORDER_RADIUS} 0 0`;
      }
    }
    return options;
  }, [
    isMinimized,
    props.listProps?.style?.popupCSS,
    style.ADDITIONAL_STYLE_OPTIONS,
    style.POPUP_BORDER_RADIUS,
    style.POPUP_HEIGHT,
    style.TYPE,
  ]);
  const itemCSS: React.CSSProperties = useMemo(() => {
    const itemOptions: React.CSSProperties = {};
    style.ADDITIONAL_STYLE_OPTIONS?.forEach((option: NatPopupStyleOptions) => {
      switch (option) {
        case NatPopupStyleOptions.NO_ITEMS_BORDER:
          itemOptions.borderTop = "none";
          break;
      }
    });
    if (style.POPUP_ITEM_SIDE_PADDING) {
      itemOptions.paddingLeft = style.POPUP_ITEM_SIDE_PADDING;
      itemOptions.paddingRight = style.POPUP_ITEM_SIDE_PADDING;
    }
    if (style.POPUP_ITEM_TOP_BOTTOM_PADDING) {
      itemOptions.paddingTop = style.POPUP_ITEM_TOP_BOTTOM_PADDING;
      itemOptions.paddingBottom = style.POPUP_ITEM_TOP_BOTTOM_PADDING;
    }
    if (style.POPUP_ITEM_WIDTH) {
      itemOptions.width = style.POPUP_ITEM_WIDTH;
    }
    return itemOptions;
  }, [
    style.ADDITIONAL_STYLE_OPTIONS,
    style.POPUP_ITEM_SIDE_PADDING,
    style.POPUP_ITEM_TOP_BOTTOM_PADDING,
    style.POPUP_ITEM_WIDTH,
  ]);

  const displayThin: boolean =
    style.ADDITIONAL_STYLE_OPTIONS?.includes(
      NatPopupStyleOptions.NO_PADDING
    ) === true;
  const popupContent = (
    <>
      {contentItemArray?.map((item, idx) => {
        if (
          style.ADDITIONAL_STYLE_OPTIONS?.includes(
            NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE
          ) &&
          idx === 0
        ) {
          return null;
        }
        return (
          <FormattedListItem
            bolded={
              item?.id === selectedValue?.id &&
              !style.ADDITIONAL_STYLE_OPTIONS?.includes(
                NatPopupStyleOptions.DISABLE_BOLD_SELECTED
              )
            }
            displayThin={displayThin}
            removeBorder={style.ADDITIONAL_STYLE_OPTIONS?.includes(
              NatPopupStyleOptions.NO_ITEMS_BORDER
            )}
            idx={idx}
            key={`${idx}-${item?.id}`}
            item={item}
            style={itemCSS}
          />
        );
      })}
    </>
  );
  if (
    (style.ADDITIONAL_STYLE_OPTIONS?.includes(
      NatPopupStyleOptions.BOTTOM_SHEET_MOBILE
    ) &&
      isNatMobile) ||
    (style.TYPE === NatPopupType.MODAL_WHEN_EXPANDED && !isMinimized)
  ) {
    if (!props.getModal) {
      return null;
    }
    return (
      <NatModal
        content={
          <PopupModalContainer style={popupCSS}>
            <PopupTitleElement
              style={style}
              contentItemArray={contentItemArray}
              displayThin={displayThin}
              itemCSS={itemCSS}
              isMinimized={isMinimized}
            />

            {popupContent}
          </PopupModalContainer>
        }
        hideCloseIcon={isNatMobile}
        onlyShowContent={true}
        show={show}
        handleClose={props.listProps?.handleClose}
        customPadding={"0 0"}
      />
    );
  }
  if (style.TYPE === NatPopupType.MINIMIZE) {
    if (props.getModal) {
      return null;
    }
    return (
      <PopupContainer
        style={{
          ...popupCSS,
          transitionDelay: isMinimized
            ? `${POPUP_ANIMATION_SPEED_MS * 0.5}ms`
            : `0ms`,
          transitionDuration: isMinimized
            ? `${POPUP_ANIMATION_SPEED_MS * 0.25}ms`
            : `${POPUP_ANIMATION_SPEED_MS * 0.1}ms`,
        }}
        displayThin={displayThin}
        onClick={(e) => {
          setIsMinimized(!isMinimized);
          e.stopPropagation();
        }}
      >
        <PopupTitleElement
          style={style}
          contentItemArray={contentItemArray}
          displayThin={displayThin}
          itemCSS={itemCSS}
          isMinimized={isMinimized}
        />
        <NatomasPopupContentContainer
          height={style.POPUP_HEIGHT}
          animationSpeed={POPUP_ANIMATION_SPEED_MS}
          expanded={!isMinimized}
          displayThin={true}
          style={{
            borderRadius: `0 0 ${style?.POPUP_BORDER_RADIUS} ${style?.POPUP_BORDER_RADIUS}`,
          }}
        >
          <NatomasPopupContent displayThin={true}>
            {popupContent}
          </NatomasPopupContent>
        </NatomasPopupContentContainer>
      </PopupContainer>
    );
  }
  // Default is NatPopupType.HIDE
  if (props.getModal) {
    return null;
  }
  return (
    <div
      style={{
        position: "relative",
      }}
      hidden={!show}
    >
      <PopupContainer
        style={{
          visibility: show ? "visible" : "hidden",
          ...DEFAULT_DROPDOWN_STYLING,
          ...style,
          ...popupCSS,
        }}
        id={"popup"}
      >
        <NatomasPopupTitleContainer
          displayThin={displayThin}
          hidden={
            !style.ADDITIONAL_STYLE_OPTIONS?.includes(
              NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE
            )
          }
        >
          <PopupTitleElement
            style={style}
            contentItemArray={contentItemArray}
            displayThin={displayThin}
            itemCSS={itemCSS}
            isMinimized={isMinimized}
          />
          <NatomasPopupClose
            onClick={props.listProps?.handleClose}
            displayThin={displayThin}
            hidden={style.ADDITIONAL_STYLE_OPTIONS?.includes(
              NatPopupStyleOptions.HIDE_CLOSE_BUTTON
            )}
          >
            <IoMdClose />
          </NatomasPopupClose>
        </NatomasPopupTitleContainer>
        {contentItemArray?.map((item, idx) => {
          if (
            idx === 0 &&
            style.ADDITIONAL_STYLE_OPTIONS?.includes(
              NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE
            )
          ) {
            return null;
          }
          return (
            <FormattedListItem
              bolded={
                item?.id === selectedValue?.id &&
                !style.ADDITIONAL_STYLE_OPTIONS?.includes(
                  NatPopupStyleOptions.DISABLE_BOLD_SELECTED
                )
              }
              handleClose={() => {
                if (
                  style.ADDITIONAL_STYLE_OPTIONS?.includes(
                    NatPopupStyleOptions.CLOSE_ON_SELECT
                  )
                ) {
                  props.listProps?.handleClose &&
                    props.listProps?.handleClose();
                }
              }}
              removeBorder={style.ADDITIONAL_STYLE_OPTIONS?.includes(
                NatPopupStyleOptions.NO_ITEMS_BORDER
              )}
              displayThin={displayThin}
              idx={idx}
              key={`list-item-${idx}`}
              item={item}
              style={itemCSS}
            />
          );
        })}
      </PopupContainer>
    </div>
  );
};
