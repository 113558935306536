import {FaFacebook, FaLinkedin, FaYoutube, FaYoutubeSquare} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import {AiOutlineInstagram, AiFillYoutube} from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";

export const SocialMediaAccounts = [
  {
    platform: "Instagram",
    icon: AiOutlineInstagram,
    link: "https://www.instagram.com/villahomes_/",
  },
  {
    platform: "Facebook",
    icon: FaFacebook,
    link: "https://www.facebook.com/prefabvillahomes/",
  },
  {
    platform: "Youtube",
    icon: FaYoutube,
    link: "https://www.youtube.com/@villa7216",
  },
  {
    platform: "X",
    icon: FaXTwitter,
    link: "https://twitter.com/_VillaHomes_",
  },
  {
    platform: "LinkedIn",
    icon: FaLinkedin,
    link: "https://www.linkedin.com/company/villa-prefab-homes/",
  },
];
