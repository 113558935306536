import CatalogEditorHeader from "./components/Header";
import React, {useCallback, useEffect, useMemo} from "react";
import _ from "lodash";
import styled from "styled-components";
import {FactoryInventoryInput} from "../_shared/FactoryInventoryInput";
import {IFactoryInventoryCatalogEditor} from "./formik/interfaces";
import {InventoryCatalogPreview} from "./components/Preview/InventoryCatalogPreview";
import {PageContainer} from "../../../../../../../../_shared/generics/page/components/PageContainer";
import {flattenProductToCatalogEditor} from "./formik/flatten";
import {getInitialInventoryCatalogEditorValues} from "./formik";
import {useFormik} from "formik";
import {
  FactoryInventoryCatalogEditorStructure,
  IFactoryInventoryEditorStructureValue,
} from "./formik/structure";
import {saveCatalogEditorChangesToProduct} from "./formik/saving";
import {FactoryInventoryEditorProps} from "../../index";

const CatalogEditorForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem 0;
`;

export const FactoryInventoryCatalogEditor = (
  props: FactoryInventoryEditorProps
) => {
  const {product, isEditing} = props;

  const flattenedProduct: IFactoryInventoryCatalogEditor =
    useMemo((): IFactoryInventoryCatalogEditor => {
      return flattenProductToCatalogEditor(product);
    }, [product]);

  const initialValues = useMemo(() => {
    return getInitialInventoryCatalogEditorValues(flattenedProduct);
  }, [flattenedProduct]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      return saveCatalogEditorChangesToProduct(product, values);
    },
  });

  useEffect(() => {
    formik?.setValues(initialValues);
  }, [initialValues]);

  const hasEdits = useMemo(() => {
    return !_.isEqual(initialValues, formik.values);
  }, [formik.values, initialValues]);

  const resetChanges = useCallback(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [formik, initialValues]);

  if (!initialValues || !product) {
    return null;
  }

  if (!isEditing) {
    return (
      <>
        <CatalogEditorHeader
          resetAllChanges={resetChanges}
          hasEdits={hasEdits}
          saveChanges={formik?.submitForm}
        />
        <InventoryCatalogPreview product={formik.values} />
      </>
    );
  }

  return (
    <>
      <CatalogEditorHeader
        resetAllChanges={resetChanges}
        hasEdits={hasEdits}
        saveChanges={formik?.submitForm}
      />
      <PageContainer>
        {isEditing && (
          <CatalogEditorForm>
            {Object.keys(FactoryInventoryCatalogEditorStructure).map((id) => {
              const key: IFactoryInventoryEditorStructureValue =
                FactoryInventoryCatalogEditorStructure[id];
              return (
                <FactoryInventoryInput
                  id={id}
                  key={id}
                  label={key.label}
                  type={key.type}
                  initialValue={initialValues[id]}
                  value={formik.values[id]}
                  onChange={(value: any) => {
                    formik.setFieldValue(id, value);
                  }}
                  resetChange={() =>
                    formik.setFieldValue(id, initialValues[id])
                  }
                />
              );
            })}
          </CatalogEditorForm>
        )}
      </PageContainer>
    </>
  );
};
