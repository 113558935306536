/*
 https://react-icons.github.io/react-icons/search
 Use this link to find an icon and add it below.

 IMPORTANT!

 1. Make sure to never import from "react-icons/all"
 Only import from the icon's specific package, which can be found by the two letter prefix to the icon.
 For example, AiOutlineInfoCircle -> "react-icons/ai"

 2. Icons are usually solid (filled) or outlined
 Please label them accordingly
 For example a solid cog representing settings -> cogSolid
 */
import {
  MdEdit,
  MdEditOff,
  MdOutlineAdminPanelSettings,
  MdOutlineCreateNewFolder,
  MdOutlineDesignServices,
  MdOutlinePivotTableChart,
  MdOutlineSpaceDashboard,
} from "react-icons/md";
import {
  AiFillCaretLeft,
  AiFillCaretRight,
  AiFillCheckCircle,
  AiFillDelete,
  AiFillEye,
  AiFillEyeInvisible,
  AiFillStar,
  AiOutlineCloseCircle,
  AiOutlineDatabase,
  AiOutlineDoubleRight,
  AiOutlineDownload,
  AiOutlineInfoCircle,
  AiOutlineLeft,
  AiOutlineMinus,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
  AiOutlineRight,
  AiOutlineStar,
  AiOutlineWarning,
  AiTwotoneSetting,
} from "react-icons/ai";
import {
  BiChevronDown,
  BiChevronRight,
  BiChevronUp,
  BiComment,
  BiCommentEdit,
  BiCopy,
  BiExpand,
  BiSearch,
} from "react-icons/bi";
import {CgCheckO, CgRadioCheck, CgRadioChecked, CgUndo} from "react-icons/cg";
import {FaHome, FaLock, FaSave, FaUnlock} from "react-icons/fa";
import {
  HiDatabase,
  HiOutlineDocumentAdd,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentRemove,
  HiOutlineTerminal,
} from "react-icons/hi";
import {
  BsBrush,
  BsCalendar,
  BsCardImage,
  BsCheck,
  BsFillCircleFill,
  BsFillExclamationDiamondFill,
  BsHouseDoor,
  BsMap,
  BsPatchCheck,
  BsPatchCheckFill,
  BsSortDown,
  BsSortDownAlt,
  BsStars,
  BsTruckFront,
} from "react-icons/bs";
import {ImTruck} from "react-icons/im";
import {RiArrowGoBackFill, RiCloseFill, RiErrorWarningFill, RiMapPin2Line, RiShieldFill} from "react-icons/ri";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted, TiCancel, TiPlus} from "react-icons/ti";
import {IoEllipsisVerticalSharp, IoPeopleOutline} from "react-icons/io5";
import {TbBuildingWarehouse, TbPolygon} from "react-icons/tb";
import {GrCatalog} from "react-icons/gr";
import {HiMapPin} from "react-icons/hi2";
import React from "react";
import {IoMdRefresh} from "react-icons/io";

const starHollow = AiOutlineStar;
const starFill = AiFillStar;
const imageIcon = BsCardImage;
const calendarIcon = BsCalendar;
const cancelIcon = TiCancel;
const checkmark = BsPatchCheck;
const checkmarkCircleHollow = CgRadioCheck;
const checkmarkCircleInProgress = CgRadioChecked;
const checkmarkCircleCompleteHollow = CgCheckO;
const justCheckmark = BsCheck;
const checkmarkCircleCompleteFilled = AiFillCheckCircle;
const checkmarkFill = BsPatchCheckFill;
const circleFill = BsFillCircleFill;
const closeCircle = AiOutlineCloseCircle;
const closeSymbol = RiCloseFill;
const cogSolid = AiTwotoneSetting;
const commentIcon = BiComment;
const copySolid = BiCopy;
const dataOutline = HiDatabase;
const docAdd = HiOutlineDocumentAdd;
const docCopy = HiOutlineDocumentDuplicate;
const docRemove = HiOutlineDocumentRemove;
const doubleRightChevron = AiOutlineDoubleRight;
const downChevron = BiChevronDown;
const download = AiOutlineDownload;
const editCommentIcon = BiCommentEdit;
const editSymbol = MdEdit;
const editOffSymbol = MdEditOff;
const employeesIcon = MdOutlineAdminPanelSettings;
const featureMapIcon = TbPolygon;
const fullscreen = BiExpand;
const geoPin = RiMapPin2Line;
const goBackSolid = RiArrowGoBackFill;
const house = FaHome;
const infoOutline = AiOutlineInfoCircle;
const invisibleIcon = AiFillEyeInvisible;
const leftCaratSolid = AiFillCaretLeft;
const leftOutline = AiOutlineLeft;
const locationSymbol = HiMapPin;

const cartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M24 13.8391L12 4.52407L0 13.8391V10.0411L12 0.726074L24 10.0411V13.8391ZM21 13.5001V22.5001H15V16.5001H9V22.5001H3V13.5001L12 6.75007L21 13.5001Z"
      fill="white"
    />
  </svg>
);
const houseCity = (props: {size?: string; color?: string}) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_417_28513)">
        <path
          d="M1.66699 18.3333V35H10.0003V25H16.667V35H25.0003V18.3333L13.3337 10L1.66699 18.3333Z"
          fill="#82C275"
        />
        <path
          d="M16.667 5V8.28333L28.3337 16.6167V18.3333H31.667V21.6667H28.3337V25H31.667V28.3333H28.3337V35H38.3337V5H16.667ZM31.667 15H28.3337V11.6667H31.667V15Z"
          fill="#82C275"
        />
      </g>
      <defs>
        <clipPath id="clip0_417_28513">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const lockSymbol = FaLock;
const magnifyingGlass = BiSearch;
const mapIcon = BsMap;
const minusCircledSymbol = AiOutlineMinusCircle;
const minusSymbol = AiOutlineMinus;
const paintbrush = BsBrush;
const peopleIcon = IoPeopleOutline;
const plusCircledSymbol = AiOutlinePlusCircle;
const plusSymbol = TiPlus;
const resetSolid = CgUndo;
const rightCaratSolid = AiFillCaretRight;
const rightChevron = BiChevronRight;
const rightOutline = AiOutlineRight;
const saveIcon = FaSave;
const shieldFull = RiShieldFill;
const sortedUpArrowSymbol = TiArrowSortedUp;
const sortedDownArrowSymbol = TiArrowSortedDown;
const terminalIcon = HiOutlineTerminal;
const transposeSolid = MdOutlinePivotTableChart;
const trashSolid = AiFillDelete;
const truck = ImTruck;
const truck2 = BsTruckFront;
const undoSymbol = CgUndo;
const unlockSymbol = FaUnlock;
const unsortedArrowSymbol = TiArrowUnsorted;
const upChevron = BiChevronUp;
const verticalEllipsis = IoEllipsisVerticalSharp;
const visibleIcon = AiFillEye;
const warehouseIcon = TbBuildingWarehouse;
const warningCircle = RiErrorWarningFill;
const warningDiamond = BsFillExclamationDiamondFill;
const warningTriangle = AiOutlineWarning;
const projectIcon = MdOutlineSpaceDashboard;
const ascendingIcon = BsSortDownAlt;
const descendingIcon = BsSortDown;
const starsIcon = BsStars;
const newDocumentIcon = MdOutlineCreateNewFolder;
const refreshIcon = IoMdRefresh;
// Actions
// Symbols
export const NatNewDocumentIcon = newDocumentIcon;
export const NatRefreshIcon = refreshIcon;
export const NatAdminIcon = shieldFull;
export const NatBulletPoint = circleFill;
export const NatCalendarIcon = calendarIcon;
export const NatCartIcon = cartIcon;
export const NatCancelIcon = cancelIcon;
export const NatCheckNoCircle = justCheckmark;
export const NatCheckCircleIncompleteHollow = checkmarkCircleHollow;
export const NatCheckCircleInProgress = checkmarkCircleInProgress;
export const NatCheckCircleHollow = checkmarkCircleCompleteHollow;
export const NatCheckCircleFill = checkmarkCircleCompleteFilled;
export const NatCloseIcon = closeSymbol;
export const NatCommentIcon = commentIcon;
export const NatCopyIcon = copySolid;
export const NatCustomerDatabaseIcon = peopleIcon;
export const NatDatabaseIcon = dataOutline;
export const NatDeveloper = terminalIcon;
export const NatDocAddIcon = docAdd;
export const NatDocCopyIcon = docCopy;
export const NatDocRemoveIcon = docRemove;
export const NatDoubleRightChevron = doubleRightChevron;
export const NatDownChevron = downChevron;
export const NatDownloadIcon = download;
export const NatUpChevron = upChevron;
export const NatEditCommentIcon = editCommentIcon;
export const NatEditIcon = editSymbol;
export const NatEditOffIcon = editOffSymbol;
export const NatEllipsisIcon = verticalEllipsis;
export const NatEmployeeDatabaseIcon = employeesIcon;
export const NatDataIcon = AiOutlineDatabase;
export const NatErrorCircle = closeCircle;
export const NatFitsOnLot = checkmark;
export const NatFitsOnLotFill = checkmarkFill;
export const NatFullscreenIcon = fullscreen;
export const NatGeographyPinIcon = geoPin;
export const NatGoBackIcon = goBackSolid;
export const NatHouseIcon = house;
export const NatImageIcon = imageIcon;
export const NatInfoIcon = infoOutline;
export const NatInventoryIcon = truck;
export const NatInventoryDatabaseIcon = truck2;
export const NatInvisibleIcon = invisibleIcon;
export const NatLeftArrowIcon = leftOutline;
export const NatLeftCaratIcon = leftCaratSolid;
export const NatLockedIcon = lockSymbol;
export const NatLocationPin = locationSymbol;
export const NatMapFeatureIcon = featureMapIcon;
export const NatMinusIcon = minusSymbol;
export const NatMinusCircledIcon = minusCircledSymbol;
export const NatPaintbrushIcon = paintbrush;
export const NatPlusIcon = plusSymbol;
export const NatPlusCircledIcon = plusCircledSymbol;
export const NatProductDatabaseIcon = warehouseIcon;
export const NatResetIcon = resetSolid;
export const NatRightArrowIcon = rightOutline;
export const NatRightCaratIcon = rightCaratSolid;
export const NatRightChevron = rightChevron;
export const NatSaveIcon = saveIcon;
export const NatSearchIcon = magnifyingGlass;
export const NatSettingsIcon = cogSolid;
export const NatSortUpIcon = sortedUpArrowSymbol;
export const NatSortDownIcon = sortedDownArrowSymbol;
export const NatStarHollowIcon = starHollow;
export const NatStarFillIcon = starFill;
export const NatTableTransposeIcon = transposeSolid;
export const NatTrashIcon = trashSolid;
export const NatUndoIcon = undoSymbol;
export const NatUnlockedIcon = unlockSymbol;
export const NatAscendingIcon = ascendingIcon;
export const NatDescendingIcon = descendingIcon;
export const NatStarsIcon = starsIcon;
// Sub-App Icons
export const NatProjectIcon = projectIcon;
export const NatMapIcon = mapIcon;
export const NatDesignIcon = MdOutlineDesignServices;
export const NatCatalogIcon = GrCatalog;
export const NatCompletedProjectsIcon = BsHouseDoor;
export const NatUnsortedIcon = unsortedArrowSymbol;

export const NatVisibleIcon = visibleIcon;
export const NatWarningCircle = warningCircle;
export const NatWarningDiamondIcon = warningDiamond;
export const NatWarningTriangle = warningTriangle;
export const NatHouseCity = houseCity;
