import React, {useEffect, useState} from "react";
import {SketchPicker} from "react-color";
import {NatButton} from "../../../../../../../../../_shared/generics/button";
import styled from "styled-components";
import {
  ADMIN_EDIT,
  BLACK,
  WHITE,
} from "../../../../../../../../../_shared/colors";

interface ColorInputProps {
  id: string;
  onChange: (value?: string) => void;
  initialValue: string;
}

const ColorContainer = styled.div<{color?: string}>`
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props?.color};
`;
const ColorValueContainer = styled.div`
  font-size: 12px;
  color: ${BLACK};
  background-color: ${WHITE};
  padding: 4px 6px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${ADMIN_EDIT};
  }
`;

const processColor = (color?: string) => {
  if (color?.length === 0) {
    return undefined;
  } else {
    return color;
  }
};

const ColorInput = (props: ColorInputProps) => {
  const {id, onChange, initialValue} = props;

  const [editing, setEditing] = useState(false);
  const [color, setColor] = useState(processColor(initialValue));

  useEffect(() => {
    setColor(processColor(initialValue));
  }, [initialValue]);

  if (!editing) {
    return (
      <ColorContainer key={`${id}-value`} color={color}>
        <ColorValueContainer onClick={() => setEditing(true)}>
          {color ?? "None"}
        </ColorValueContainer>
      </ColorContainer>
    );
  }
  return (
    <>
      <SketchPicker
        key={`${id}-selector`}
        color={color ?? ""}
        onChangeComplete={(color: any) => {
          setColor(color?.hex);
        }}
      />
      <NatButton
        label={"Save Color"}
        clickEvent={() => {
          onChange(color);
          setEditing(false);
        }}
      />
    </>
  );
};

export default ColorInput;
