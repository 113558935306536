import {IModifierPricingReview} from "./interface";
import {priceTextFromMicros} from "@natomas-org/core";

export interface IModifierPricingReviewSummary {
  modifierTitle: string;
  priceLabel: string;
  costLabel: string;
  grossMargin: number;
  grossProfitMargin: number;
  productTitle?: string;
}

export const summarizeModifierPricingReview = (
  review: IModifierPricingReview
): IModifierPricingReviewSummary => {
  const modifierTitle: string =
    review?.modifier?.internal_title ??
    review?.modifier?.title ??
    "UNKNOWN MODIFIER";
  const productTitle: string | undefined =
    review?.product?.internal_title ?? review?.product?.title ?? undefined;
  const price = review?.state?.price;
  const cost = review?.state?.cost;
  const priceLabel: string =
    price !== null ? priceTextFromMicros(price ?? 0, "accurate") : "EMPTY";
  const costLabel: string =
    cost !== null ? priceTextFromMicros(cost ?? 0, "accurate") : "EMPTY";
  const _price = review?.state?.price ?? 0;
  const _cost = review?.state?.cost ?? 0;
  const grossMargin = _price - _cost;
  const grossProfitMargin = _price > 0 ? grossMargin / _price : 0;
  return {
    modifierTitle,
    productTitle,
    priceLabel,
    costLabel,
    grossMargin,
    grossProfitMargin,
  };
};
