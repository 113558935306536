import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import {TableContainer} from "../../../portal/views/ProposalView/styles/SiteWorkTableStyles";
import {FieldType, ITableColumnInfo} from "./TableTypes";
import {
  dollarsToMicros,
  getIntegerValue,
  priceTextFromMicros,
} from "@natomas-org/core";
import {NatButton} from "../button";
import {NatSize, StyleOption} from "../_shared";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";

const MainContainer = styled.div`
  margin-bottom: 0;
  width: 100%;
`;

const RowContainer = styled.div`
  //height: 50px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 14px;
`;

const HeaderRowContainer = styled(RowContainer)`
  background-color: #82c275;
  font-family: ${FONT_FAMILY_BOLD};
`;

const ContentRowContainer = styled(RowContainer)`
  min-height: 20px;
  font-size: 16px;
  height: fit-content;
`;

const ColumnText = styled.div<{bold?: boolean}>`
  position: relative;
  margin: 4px;
  //margin-left: 30px;
  font-family: ${(props) => (props.bold ? FONT_FAMILY_BOLD : undefined)};
`;

const Column = styled.div`
  min-height: 100%;
  min-width: 160px;
  position: relative;
  display: flex;
  align-items: center;
`;

const GenericColumn = styled(Column)<{index: number; total: number}>`
  width: ${(props) => 100 / props.total + "%"};
  height: fit-content;
  border-left: ${(props) =>
    props.index > 0 ? "solid rgba(0, 0, 0, 0.1) 1px" : null};
`;

const TableRow = (props: {items: any[]}) => {
  const {items} = props;

  return (
    <>
      {items.map((item, index) => (
        <GenericColumn
          key={"row-column-" + index}
          index={index}
          total={items.length}
        >
          <ColumnText bold={index === 0}>{item}</ColumnText>
        </GenericColumn>
      ))}
    </>
  );
};

// Take each row after the header and save the values to the header fields
const saveTable = (rows: any[], columns: ITableColumnInfo[], onChange: any) => {
  const dataToSave = rows.slice(1).map((row: any) => {
    const rowInfo: any = {};
    columns.forEach((columnInfo: ITableColumnInfo, index: number) => {
      let value = row[index].value;
      if (columnInfo.type === FieldType.NUMBER) {
        value = getIntegerValue(value);
      } else if (columnInfo.type === FieldType.PRICE) {
        value = getIntegerValue(value);
        value = dollarsToMicros(value);
      }
      rowInfo[columnInfo.dataField] = value;
    });
    return rowInfo;
  });

  onChange(dataToSave);
};

const isValidIndexOfArray = (arr: any[], index: number) => {
  return arr != null && arr.length > index;
};

const getTable = (
  rowCount: number,
  columns: ITableColumnInfo[],
  rigidValues: any[],
  data: any
) => {
  const table = [];
  table.push(
    columns.map((col: ITableColumnInfo) => {
      return {value: col.name, readOnly: true};
    })
  );

  /*
  We'd like to match the line items in the table to line items in the DB/data
  How we do that is we assign a primary key on the table column that we use to match with the DB
  Then we find the primary column, and compare the rigid values in that column to the data rows, looking up by primary key field name.
  */
  let primary_column_index: null | number = null;
  let primary_key: string | null = null;
  columns.forEach((column, index) => {
    if (column.primary_key) {
      primary_column_index = index;
      primary_key = column.dataField;
    }
  });

  const dataLookUpArray: any[] = data != null ? [...data] : [];
  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    const rigidValueRow =
      rigidValues.length > rowIndex ? rigidValues[rowIndex] : [];
    let dataRow: any = null;
    if (!primary_key || primary_column_index === null) {
      if (isValidIndexOfArray(data, rowIndex)) {
        dataRow = data[rowIndex];
      }
    } else if (primary_key) {
      const rigidLookUpValue = rigidValueRow[primary_column_index];
      let dataIndex = null;
      dataRow = dataLookUpArray.find((dataRow, index) => {
        if (dataRow[`${primary_key}`] === rigidLookUpValue) {
          dataIndex = index;
          return true;
        }
        return false;
      });
      if (dataRow && dataIndex) {
        dataLookUpArray.splice(dataIndex, 1);
      }
    }

    const row: {value: string; readOnly?: boolean}[] = [];

    columns.forEach((col: ITableColumnInfo, columnIndex: number) => {
      if (
        isValidIndexOfArray(rigidValues, rowIndex) &&
        isValidIndexOfArray(rigidValues[rowIndex], columnIndex)
      ) {
        const rigidValue = rigidValues[rowIndex][columnIndex];
        row.push({value: rigidValue, readOnly: true});
      } else if (dataRow != null) {
        let value = dataRow[col.dataField];
        if (col.type === FieldType.NUMBER) {
          value = `${value}`;
        } else if (col.type === FieldType.PRICE) {
          value = priceTextFromMicros(value, "accurate");
        }
        row.push({
          value: value,
        });
      } else {
        row.push({value: ""});
      }
    });

    table.push(row);
  }

  return table;
};

export const GenericTable = (props: any) => {
  const {currentValue, tableStructure, onChange, enabled} = props;
  const initialData = useMemo(() => {
    const {rowCount, columns, rigidValues} = tableStructure;
    return getTable(rowCount, columns, rigidValues, currentValue);
  }, [tableStructure, currentValue]);

  const {rowCount, columns, rigidValues} = tableStructure;
  const [data, setData] = useState(initialData);
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  if (enabled && editing) {
    return (
      <MainContainer>
        <ReactDataSheet
          // @ts-ignore
          style={{width: "100%"}}
          data={data}
          valueRenderer={(cell: any) => cell.value}
          onCellsChanged={(changes) => {
            const grid: any = data.map((row) => [...row]);
            changes.forEach(({cell, row, col, value}) => {
              const previousValues = grid[row][col];
              grid[row][col] = {...previousValues, value};
            });
            setData(grid);
          }}
        />
        <br />
        <NatButton
          option={StyleOption.SECONDARY}
          label={"Discard"}
          type={"button"}
          clickEvent={() => {
            setEditing(false);
            if (currentValue) {
              setData(getTable(rowCount, columns, rigidValues, currentValue));
            } else {
              setData(initialData);
            }
          }}
        />
        <NatButton
          option={StyleOption.PRIMARY}
          label={"Save"}
          type={"button"}
          clickEvent={() => {
            setEditing(false);
            saveTable(data, columns, onChange);
          }}
        />
      </MainContainer>
    );
  }

  const rows = data.map((row: any, index) => {
    if (index === 0) {
      return (
        <HeaderRowContainer key={"row-header-" + index}>
          <TableRow items={columns.map((columnData: any) => columnData.name)} />
        </HeaderRowContainer>
      );
    }

    const items = row.map((entry: any, index: number) => {
      return entry.value;
    });

    return (
      <ContentRowContainer key={"row-" + index}>
        <TableRow items={items} />
      </ContentRowContainer>
    );
  });

  return (
    <MainContainer>
      <TableContainer>{rows}</TableContainer>
      <br />
      <NatButton
        disabled={!enabled}
        option={StyleOption.PRIMARY_ALT}
        size={NatSize.SMALL}
        label={"Edit Table"}
        type={"button"}
        clickEvent={() => setEditing(true)}
      />
    </MainContainer>
  );
};
