import React from "react";
import styled from "styled-components";
import {ISmartImageProps, SmartImage} from "./SmartImage";

export const NatImage = (props: INatImageProps) => {
  const {
    imageURL,
    imageId,
    fallbackComp,
    size,
    style,
    roundCorners,
    imageProps,
  } = props;
  return (
    <Container
      style={{
        width: size?.width ?? "unset",
        height: size?.height ?? "unset",
        position: "relative",
        borderRadius: roundCorners ? "0.5rem" : "unset",
        overflow: roundCorners ? "hidden" : "unset",
        ...style,
      }}
    >
      <SmartImage {...imageProps} imageURL={imageURL} imageId={imageId} />
    </Container>
  );
};

const Container = styled.div``;

interface INatImageProps {
  roundCorners?: boolean;
  imageURL?: string;
  imageId?: string;
  fallbackComp?: any;
  style?: React.CSSProperties;
  size?: {
    width: React.CSSProperties["width"];
    height: React.CSSProperties["height"];
  };
  imageProps?: ISmartImageProps;
}
