import React, {useCallback, useMemo, useState} from "react";
import {usePermissions} from "../../../_shared/hooks/usePermissions/usePermissions";
import {useFormik} from "formik";
import {useDeepEffect} from "../../../_shared/hooks/useDeepEffect";
import {NatButton} from "../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../_shared/generics/_shared";
import {
  changeSuperAdminStatus,
  changeUserPermissions,
} from "../../../../database/firebase/api/admin/permissions";
import {EmployeeAccessControl, EmployeeAccessControlsContainer} from "./styles";
import {EmployeeCardContentDivider} from "../_shared/styles";
import {IUserAccess, UserAccessTypes} from "@natomas-org/core";
import _ from "lodash";

export const EmployeeAccessControlView = (props: {
  handleClose: () => void;
  uid?: string;
}) => {
  const {handleClose, uid} = props;
  const {access, fullAccess} = usePermissions(uid);
  const [initialValues, setInitialValues] = useState<IUserAccess>(access);

  useDeepEffect(() => {
    const newInitials = access;
    setInitialValues(newInitials);
    formik.setValues(newInitials);
  }, [access]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: IUserAccess) => {
      if (uid) {
        return changeUserPermissions(uid, values).then(() => {
          handleClose();
          console.log("Request complete.");
        });
      }
    },
  });

  const resetEdits = useCallback(() => {
    formik.setValues(initialValues);
  }, [formik, initialValues]);

  const hasEdits = useMemo(() => {
    return !_.isEqual(formik.values, initialValues);
  }, [formik.values, initialValues]);

  if (!uid) {
    return <>Loading...</>;
  }

  if (fullAccess) {
    return (
      <EmployeeAccessControlsContainer>
        <NatButton
          type={"button"}
          label={"Remove Full Access"}
          option={StyleOption.DESTRUCTIVE}
          size={NatSize.SMALL}
          spinnerEnabled={true}
          clickEvent={() => {
            return changeSuperAdminStatus(uid, false).then(() => {
              formik.setValues(initialValues);
              handleClose();
            });
          }}
        />
      </EmployeeAccessControlsContainer>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <EmployeeAccessControlsContainer>
        <EmployeeCardContentDivider />
        {(Object.values(UserAccessTypes) as UserAccessTypes[])?.map(
          (accessType: UserAccessTypes) => {
            return (
              <EmployeeAccessControl key={`key_${accessType}`}>
                <label htmlFor={`${uid}_${accessType}`}>
                  {UserAccessTitle[accessType]}
                  <input
                    type={"checkbox"}
                    id={`${uid}_${accessType}`}
                    onChange={() =>
                      formik.setFieldValue(
                        accessType,
                        !formik.values[accessType]
                      )
                    }
                    checked={formik.values[accessType]}
                  />
                </label>
              </EmployeeAccessControl>
            );
          }
        )}
        <NatButton
          type={"button"}
          hidden={!hasEdits}
          label={"Reset Changes"}
          option={StyleOption.SECONDARY_ALT}
          size={NatSize.SMALL}
          clickEvent={resetEdits}
        />
        <NatButton
          type={"submit"}
          hidden={!hasEdits}
          option={StyleOption.PRIMARY_ALT}
          label={"Submit"}
          size={NatSize.SMALL}
        />
        <EmployeeCardContentDivider />
        <NatButton
          type={"button"}
          label={"Grant Full Access"}
          option={StyleOption.DESTRUCTIVE}
          size={NatSize.SMALL}
          spinnerEnabled={true}
          clickEvent={() => {
            return changeSuperAdminStatus(uid, true).then(() => {
              formik.setValues(initialValues);
              handleClose();
            });
          }}
        />
      </EmployeeAccessControlsContainer>
    </form>
  );
};

export const UserAccessTitle: {[type in UserAccessTypes]: string} = {
  [UserAccessTypes.COMPLETED_PROJECTS]: "Completed Projects",
  [UserAccessTypes.CUSTOMERS]: "Customers",
  [UserAccessTypes.DEVELOPER]: "Developer",
  [UserAccessTypes.SUPER_CATALOG]: "Super Catalog",
  [UserAccessTypes.PRODUCT_LINES]: "Product Lines",
};
