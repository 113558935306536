import React, {useState} from "react";
import {NatButton} from "../../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../../_shared/generics/_shared";
import {updateUserEmail} from "../../../../../../../database/firebase/api/admin";
import {EditFieldContainer} from "./styles";
import {useCurrentCustomer} from "../../../../../../_shared/hooks/useCurrentCustomer";

export const EditEmailSetting = () => {
  const {user_id} = useCurrentCustomer();
  const [showEmailEditor, setShowEmailEditor] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);

  return (
    <>
      {showEmailEditor && (
        <input
          onChange={(event) => setEmail(event.target.value)}
          type={"email"}
          placeholder={"New email"}
        />
      )}
      {showEmailEditor && (
        <EditFieldContainer>
          <NatButton
            size={NatSize.SMALL}
            label={"Cancel"}
            type={"button"}
            option={StyleOption.PRIMARY_ALT}
            clickEvent={() => setShowEmailEditor(false)}
          />
          <NatButton
            size={NatSize.SMALL}
            label={"Set Email"}
            type={"button"}
            clickEvent={() => {
              if (email) {
                updateUserEmail(user_id, email).then(() =>
                  console.log("Updated " + user_id + " email")
                );
              }
              setShowEmailEditor(false);
            }}
          />
        </EditFieldContainer>
      )}
      {!showEmailEditor && (
        <NatButton
          size={NatSize.SMALL}
          label={"Edit Login Email"}
          type={"button"}
          clickEvent={() => setShowEmailEditor(true)}
        />
      )}
    </>
  );
};
