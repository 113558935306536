import React, {useMemo} from "react";
import Select from "react-select";

interface ListingStatusInputProps {
  id: string;
  onChange: (value?: string) => void;
  initialValue: string;
}

const options = {
  unlisted: {label: "Unlisted", value: "unlisted"},
  listed: {label: "Listed", value: "listed"},
  hidden: {label: "Hidden", value: "hidden"},
};

const ListingStatusInput = (props: ListingStatusInputProps) => {
  const {initialValue, onChange, id} = props;
  const selectOptions = useMemo(() => Object.values(options), []);
  return (
    <Select
      key={`${id}-select`}
      options={selectOptions}
      // @ts-ignore
      value={options[initialValue]}
      onChange={(o) => {
        onChange(o?.value);
      }}
    />
  );
};

export default ListingStatusInput;
