import React from "react";
import styled from "styled-components";
import {useDynamicValue} from "../../_shared/hooks/useDynamicValue";

interface EstimateTableDescriptionProps {
  description: string;
}

interface IEstimateTableDescriptionStyle {
  fontSizePx: number;
  heightPx: number;
}

const EstimateTableDescription = (props: EstimateTableDescriptionProps) => {
  const {description} = props;
  const styling: IEstimateTableDescriptionStyle = useDynamicValue({
    forFour: {
      fontSizePx: 12,
      heightPx: 32,
    },
    forEight: {
      fontSizePx: 14,
      heightPx: 36,
    },
    forTwelve: {
      fontSizePx: 14,
      heightPx: 36,
    },
    forSixteen: {
      fontSizePx: 16,
      heightPx: 40,
    },
  });
  return <P {...styling}>{description}</P>;
};
const _P = ({fontSizePx, heightPx}: IEstimateTableDescriptionStyle) => `
  font-size: ${fontSizePx}px;
  height: ${heightPx}px;
`;
const P = styled.p`
  ${_P};
  color: #807d7a;
  margin: 0 0;
  /* Paragraph/P1/Regular */
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default EstimateTableDescription;
