import React, {useCallback, useEffect, useMemo, useState} from "react";
import CatalogEditorHeader from "../FactoryInventoryCatalogEditor/components/Header";
import _ from "lodash";
import styled from "styled-components";
import {FactoryInventoryInput} from "../_shared/FactoryInventoryInput";
import {
  FactoryInventoryInformationEditorKey,
  IFactoryInventoryInformationEditor,
} from "./formik/interfaces";
import {PageContainer} from "../../../../../../../../_shared/generics/page/components/PageContainer";
import {flattenProductToInformationEditor} from "./formik/initialize";
import {getInitialInventoryInformationEditorValues} from "./formik";
import {useFormik} from "formik";
import {FactoryInventoryInformationEditorStructure} from "./formik/structure";
import {IFactoryInventoryEditorStructureValue} from "../FactoryInventoryCatalogEditor/formik/structure";
import {saveInventoryInformation} from "./formik/saving";
import {isProductListable} from "./formik/processing";
import {FactoryInventoryEditorProps} from "../../index";
import {NatButton} from "../../../../../../../../_shared/generics/button";
import {ListingStatusDetails} from "@natomas-org/core";

const InformationEditorForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem 0;
`;
export const FactoryInventoryInformationEditor = (
  props: FactoryInventoryEditorProps
) => {
  const {product, configuration} = props;

  const flattenedProduct: IFactoryInventoryInformationEditor =
    useMemo((): IFactoryInventoryInformationEditor => {
      return flattenProductToInformationEditor(product);
    }, [product]);

  const initialValues = useMemo(() => {
    return getInitialInventoryInformationEditorValues(flattenedProduct);
  }, [flattenedProduct]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      return saveInventoryInformation(product, values);
    },
  });

  useEffect(() => {
    formik?.setValues(initialValues);
  }, [initialValues]);

  const hasEdits = useMemo(() => {
    return !_.isEqual(initialValues, formik.values);
  }, [formik.values, initialValues]);

  const resetChanges = useCallback(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [formik, initialValues]);

  const [isSold, setIsSold] = useState<boolean>(
    !!product?.inventory_info?.sold
  );

  if (!initialValues || !product) {
    return null;
  }

  return (
    <>
      <CatalogEditorHeader
        resetAllChanges={resetChanges}
        hasEdits={hasEdits}
        saveChanges={formik?.submitForm}
        hideEditingToggle={true}
      />
      <PageContainer>
        <InformationEditorForm>
          <NatButton
            label={isSold ? "Clear Sold Info" : "Add Sold Info"}
            clickEvent={() => {
              setIsSold((current) => {
                if (current) {
                  formik.setFieldValue(
                    FactoryInventoryInformationEditorKey.CUSTOMER_NAME,
                    undefined
                  );
                  formik.setFieldValue(
                    FactoryInventoryInformationEditorKey.CUSTOMER_PORTAL_LINK,
                    undefined
                  );
                  formik.setFieldValue(
                    FactoryInventoryInformationEditorKey.EMPLOYEE_NAME,
                    undefined
                  );
                  formik.setFieldValue(
                    FactoryInventoryInformationEditorKey.SOLD_DATE_TIME,
                    undefined
                  );
                  formik.setFieldValue(
                    FactoryInventoryInformationEditorKey.LISTING_STATUS,
                    ListingStatusDetails.HIDDEN
                  );
                } else {
                  formik.setFieldValue(
                    FactoryInventoryInformationEditorKey.LISTING_STATUS,
                    ListingStatusDetails.UNLISTED
                  );
                }
                return !current;
              });
            }}
          />
          {Object.keys(FactoryInventoryInformationEditorStructure).map((id) => {
            const key: IFactoryInventoryEditorStructureValue =
              FactoryInventoryInformationEditorStructure[id];
            if (
              !isSold &&
              (id === FactoryInventoryInformationEditorKey.CUSTOMER_NAME ||
                id ===
                  FactoryInventoryInformationEditorKey.CUSTOMER_PORTAL_LINK ||
                id === FactoryInventoryInformationEditorKey.EMPLOYEE_NAME ||
                id === FactoryInventoryInformationEditorKey.SOLD_DATE_TIME)
            ) {
              return null;
            }
            if (
              id === FactoryInventoryInformationEditorKey.LISTING_STATUS &&
              !isProductListable(product, configuration)
            ) {
              return (
                <div style={{padding: "16px", color: "red"}}>
                  Listing Status: Disabled
                  <div>
                    Requires a floor plan image to be assigned. *Catalog, Images
                  </div>
                  <div>Requires a design studio to be configured. *Studio</div>
                </div>
              );
            }
            return (
              <FactoryInventoryInput
                id={id}
                key={id}
                label={key.label}
                type={key.type}
                initialValue={initialValues[id]}
                value={formik.values[id]}
                onChange={(value: any) => {
                  formik.setFieldValue(id, value);
                }}
                resetChange={() => formik.setFieldValue(id, initialValues[id])}
              />
            );
          })}
        </InformationEditorForm>
      </PageContainer>
    </>
  );
};
