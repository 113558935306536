import {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import useAnalytics from "src/components/_shared/hooks/useAnalytics";
import {AuthenticationFormState} from "./interface";
import {validateAuthenticationFormState} from "./validateFormState";
import {
  EventType,
  useTracking,
} from "../../../components/_shared/hooks/useTracking";
import {getLeadService} from "../../../api/Lead";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import {useCurrentUser} from "../../../components/_shared/hooks/useCurrentUser";
import {useRegistration} from "../../../components/_shared/hooks/useRegistration";
import useLeadExists from "../../../api/Lead/useLeadExists";
import {useDeepEffect} from "../../../components/_shared/hooks/useDeepEffect";
import {setRegistrationModalVisible} from "../../../components/_shared/slices/SessionSlice/SessionSlice";
import {createUserAccount} from "./createUserAccount";
import LogRocket from "logrocket";
import {Utilities} from "../../../database/firebase";
import {useAddress} from "../../2-component/Input/types/Address/useAddress";
import firebase from "firebase";
import {AuthenticationContentProps} from "./index";
import useActivitySliceDispatcher from "../../../components/_shared/hooks/useCustomer/useActivitySliceDispatcher";
import {useLocation} from "react-router-dom";
import {setURLPathAndQuery} from "../../../components/_shared/navigation/_helpers";
import {ADMIN_ROOT_PATH, EMPTY_PATH} from "../../../paths";
import {INTAKE_FORM_REDIRECT_TYPE} from "../../../components/intake-form/constants";
import {
  updateUserDesignConfiguration,
  updateUserProductSelection,
} from "../../../database/firebase/api/user";
import {NavigationPaths} from "../../../components/_shared/hooks/useNavigation/paths";
import {INSTANT_ESTIMATE_PAGE_ID} from "../../../components/portal/_shared/navigation/constants";
import {useNavigation} from "../../../components/_shared/hooks/useNavigation";

const getPath = (): string => {
  const path = window.location.pathname;
  const replacement = path.replace(/\//g, "");
  if (replacement.length > 0) {
    return replacement;
  } else {
    return "_empty";
  }
};

const getCreatedFromKey = (): string => {
  let key = "intake-form";
  // Temporarily: Do not show intake type
  // const type = getIntakeFormType();
  const path = getPath();
  return `${key}/${path}`;
};

export const pardot_submit_id = "villa-registration-hidden-pardot-form";

const submit = async (schema: any): Promise<any> => {
  try {
    if (!schema) {
      return new Error("No schema to submit");
    }
    return getLeadService().then(async (service) => {
      return service.create({...schema, created_from: getCreatedFromKey()});
    });
  } catch (error) {
    console.error("fetchLeadByAuthId", error);
    return null;
  }
};

export const useFormState = (props: AuthenticationContentProps) => {
  const {reportEvent} = useAnalytics();
  const {trackEvent} = useTracking();
  const {pathname} = useLocation();
  const {to} = useNavigation();
  // Redux state
  const {setActiveProjectById} = useActivitySliceDispatcher();
  const {isAdmin, loggedInUser, uid} = useCurrentUser();
  const {visible, signUp, redirectType, registrationValues} = useRegistration();
  // Local state
  const [schema, setSchema] = useState<CreateLeadSchema | undefined>();
  const getInitialMode = () =>
    pathname.includes("login")
      ? AuthenticationFormState.LOGIN_LANDING
      : pathname.includes("start")
      ? AuthenticationFormState.SIGN_UP_LANDING
      : signUp
      ? AuthenticationFormState.SIGN_UP_LANDING
      : AuthenticationFormState.LOGIN_LANDING;
  const [mode, setMode] = useState<AuthenticationFormState>(getInitialMode());
  useEffect(() => {
    if (visible) {
      setMode(getInitialMode());
    }
  }, [signUp, visible]);
  // Processed state
  const {data: leadExists, refetch, isFetching} = useLeadExists(uid);
  const {details, raw} = useAddress(schema?.address);
  const [nexusId, setNexusId] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  useDeepEffect(() => {
    if (pathname.includes("login") || pathname.includes("start")) {
      if (isAdmin) {
        setURLPathAndQuery(ADMIN_ROOT_PATH);
      }
      // If the user is logged in, and the lead exists, and selected a segment in the session: redirect to the homepage
      if (!schema?.lead_segment && loggedInUser && leadExists) {
        setURLPathAndQuery(EMPTY_PATH);
      }
    }
  }, [pathname, schema, loggedInUser, leadExists]);

  useDeepEffect(() => {
    if (loggedInUser && !schema) {
      setSchema({
        auth_user_id: loggedInUser.uid,
        email: loggedInUser.email,
        first_name: loggedInUser.firstName,
        last_name: loggedInUser.lastName,
      });
    } else if (!loggedInUser && schema) {
      setSchema(undefined);
    }
  }, [loggedInUser, schema]);

  const saveStudio = async (uid: string, pid: string) => {
    if (!registrationValues) {
      console.log("No registration values");
      return;
    }
    // Design Studio
    if (registrationValues?.configuration) {
      console.log("Saving configuration", registrationValues?.configuration);
      const updateDesign = await updateUserDesignConfiguration(pid, {
        ...registrationValues?.configuration,
        configurationId: pid,
      });
      if (updateDesign) {
        console.log("Design configuration saved", pid);
        return Promise.resolve();
      } else {
        console.error("Error updating design configuration");
        return Promise.resolve();
      }
    } else if (registrationValues?.product) {
      console.log("Saving configuration", registrationValues?.product);
      // Save the studio product, and configuration
      // Note: pid and cartItemId are the same as we just created them in createUser
      const updateProduct = await updateUserProductSelection({
        customerId: uid,
        projectId: pid,
        cartItemId: pid,
        product: registrationValues?.product,
      });
      if (updateProduct) {
        console.log("Product configuration saved", pid);
        return Promise.resolve();
      } else {
        console.error("Error updating product configuration");
        return Promise.resolve();
      }
    } else {
      console.error("No product or configuration to save");
      return Promise.resolve();
    }
  };

  // Make the form visible if the user is logged in and no lead is found
  useDeepEffect(() => {
    if (!isAdmin && loggedInUser) {
      // Reset visibility if the form is complete
      const isFormComplete = mode === AuthenticationFormState.COMPLETE;
      if (visible && isFormComplete) {
        dispatch(setRegistrationModalVisible(false));
        return;
      } else if (!visible && !isFormComplete) {
        dispatch(setRegistrationModalVisible(true));
        return;
      }
    }
  }, [isAdmin, loggedInUser, leadExists, visible, dispatch, mode]);

  // Create the user once schema and details are defined
  useDeepEffect(() => {
    if (!leadExists && schema && details) {
      // Evoke lead creation, then refetch lead by auth_user_id
      submit(schema).then((r) => {
        if (r?.status === 200) {
          // Capture backup
          saveBackup(schema);
          const nexusId = r?.data?.uuid ?? undefined;
          setNexusId(nexusId);
          setMode(AuthenticationFormState.MARKETING_LANDING);
          refetch().then(() => {
            createUserAccount(schema, details)
              .then((project_id: string) => {
                if (project_id) {
                  setActiveProjectById(project_id);
                }
                trackEvent(EventType.REGISTRATION_SUBMIT, {
                  type: schema?.lead_segment,
                  auth_user_id: schema?.auth_user_id,
                });
                reportEvent({
                  category: "Button",
                  action: "lead_captured",
                  label: "Registration",
                });
                // TODO: Save the studio
                saveStudio(schema.auth_user_id as string, project_id);
              })
              .catch((e: any) => console.error("Error creating account", e));
          });
        } else {
          console.error("Error creating lead", r);
        }
      });
    }
  }, [details, leadExists, raw, refetch, schema]);

  // Callback for validating the mode
  const revalidateMode = useCallback(
    (target?: AuthenticationFormState) => {
      validateAuthenticationFormState({
        target: target ?? mode,
        hasLead: leadExists,
        hasEmail: leadExists ? true : !!schema?.email,
        hasLeadSegment: leadExists ? true : !!schema?.lead_segment,
        hasAuthId: leadExists ? true : !!schema?.auth_user_id,
      }).then((result) => {
        if (result !== mode && result) {
          setMode(result);
        }
      });
    },
    [schema, leadExists, mode]
  );

  // Revalidate the mode when the schema updates
  useEffect(() => {
    revalidateMode();
  }, [revalidateMode]);

  // Maintain pardot information
  const pardotInformation =
    !schema || !leadExists || !details || !nexusId
      ? null
      : {
          id: pardot_submit_id,
          // TODO update to include marketing consent
          marketingConsent: false,
          marketingChannel: schema?.marketing_channel ?? undefined,
          firstName: schema?.first_name ?? "",
          lastName: schema?.last_name ?? "",
          email: schema?.email ?? "",
          phoneNumber: (schema?.phone_number ?? "") as string,
          createdFrom: getCreatedFromKey(),
          nexusLeadId: nexusId as string,
          address: details,
          leadSegment: (schema?.lead_segment ?? "") as string,
          company: schema?.company ?? "",
          propertyType: schema?.property_type ?? "",
        };

  const redirect = () => {
    if (!!props.redirect) {
      props.redirect();
    } else if (redirectType) {
      switch (redirectType) {
        case INTAKE_FORM_REDIRECT_TYPE.INSTANT_ESTIMATE:
          to(NavigationPaths.PORTAL, {
            page: INSTANT_ESTIMATE_PAGE_ID,
          });
          break;
      }
    }
  };

  // Submit the pardot form and complete
  const handlePardot = () => {
    let element = document.getElementById(pardot_submit_id);
    console.log("handlePardot", element);
    if (element) {
      element.click();
      // Allow Pardot to send before redirect
      setTimeout(() => {
        redirect();
      }, 1500);
    } else {
      console.warn("Could not find Pardot form", element);
      LogRocket.error("Could not find/submit registration Pardot form");
    }
    setMode(AuthenticationFormState.COMPLETE);
  };

  useDeepEffect(() => {
    // If pardot marketing channel becomes assigned, submit the form
    if (
      pardotInformation?.marketingChannel !== undefined &&
      pardotInformation?.marketingChannel !== "" &&
      mode === AuthenticationFormState.MARKETING_LANDING
    ) {
      // Submitting the pardot form
      handlePardot();
    }
  }, [pardotInformation?.marketingChannel]);

  return {
    pardotInformation,
    handlePardot: handlePardot,
    authenticatedEmail: loggedInUser?.email,
    schema,
    setSchema,
    mode: mode,
    // Validate the mode before assignment
    setMode: (target: AuthenticationFormState) => revalidateMode(target),
    isFormLoading: loggedInUser && isFetching,
  };
};

const saveBackup = (schema: any) => {
  const submissionPayload = {...schema};
  submissionPayload.timestamp = firebase.firestore.FieldValue.serverTimestamp();
  Utilities.collection("intake_form_submissions").doc().set(submissionPayload);
};
