import {
  URL_PARAM_KEY_PROJECT_ID,
  URL_PARAM_KEY_USER_ID,
} from "@natomas-org/service";

export const USER_ID_URL_KEY: string = URL_PARAM_KEY_USER_ID;
export const CONFIGURATION_ID_URL_KEY: string = "cid";
export const PROJECT_ID_URL_KEY: string = URL_PARAM_KEY_PROJECT_ID;
export const CONFIGURATION_DESIGN_DB_KEY = "configurations";
export const CONFIGURATION_MAPS_DB_KEY = "configurationMaps";
export const CONFIGURATION_SITE_DB_KEY = "configurationSites";
export const CONFIGURATION_ORDER_DB_KEY = "configuration_orders";
export const CONFIGURATION_SNAPSHOTS_DB_KEY = "configurationSnapshots";
