import {useState} from "react";

export enum TableHeaderCategory {
  NULL,
  STRING,
  NUMBER,
  OTHER,
}

export interface ITableHeader {
  label: string;
  column?: string;
  category?: TableHeaderCategory;
}

export interface ITableSortingOption {
  details: ITableSortingDetails;
  setSorting: (column: string) => void;
}

export interface ITableSortingDetails {
  column?: string;
  mode?: number;
}

export interface ITableHeaders {
  headers: ITableHeader[];
  sortingOption: ITableSortingOption;
}

export interface ITableRow {
  keyId: string;
  cells: ITableCell[];
  clickEvent?: () => void;
  isSelected?: boolean;
}

export interface ITableCell {
  content: any;
  bold?: boolean;
  center?: boolean;
  clickEvent?: () => void;
}

export const useTable = (headers: ITableHeader[]) => {
  const initialSortingState = {
    column: headers[0]?.column,
    mode: 1,
  };

  const [sortingDetails, setSortingDetails] =
    useState<ITableSortingDetails>(initialSortingState);

  const sort = (column: string) => {
    if (column === sortingDetails.column) {
      setSortingDetails({
        column: sortingDetails.column,
        mode: sortingDetails?.mode ? sortingDetails?.mode * -1 : 1,
      });
    } else {
      setSortingDetails({column: column, mode: 1});
    }
  };

  return {
    sort,
    sortingDetails,
  };
};
