import {useCurrentUser} from "../components/_shared/hooks/useCurrentUser";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setShowCompletedProjectPricing} from "../components/_shared/slices/SessionSlice/SessionSlice";
import {setCatalogSessionPriceMode} from "../components/_shared/slices/CatalogSlice/CatalogSessionSlice";
import useActiveProject from "../components/_shared/hooks/useProject/useActiveProject";

export const SessionSliceManager = () => {
  const {isAdmin} = useCurrentUser();
  const {projectPriceMode} = useActiveProject();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowCompletedProjectPricing(isAdmin));
  }, [dispatch, isAdmin]);
  useEffect(() => {
    // When the project price mode changes, update the catalog session price mode
    dispatch(setCatalogSessionPriceMode(projectPriceMode));
  }, [dispatch, projectPriceMode]);
  return null;
};
