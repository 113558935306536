import React, {Fragment, useEffect, useMemo, useState} from "react";
import {B2BPresentationView} from "../../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/B2B/B2BPresentationView";
import {DTCPresentationView} from "../../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/DTCPresentationView";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import {auth} from "../../../../../../../../database/firebase";
import {
  FULL_SCREEN_HEIGHT_REM,
  FULL_SCREEN_WIDTH_REM,
} from "../../../../../../../portal/views/HCPDashboardLayout/HCPUnitView/DesignSelections/constants";
import {API_BASE_URL} from "../../../../../../../_shared/application";
import styled from "styled-components";
import {NatButton} from "../../../../../../../_shared/generics/button";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {WHITE} from "../../../../../../../_shared/colors";
import {LoadingSpinner} from "../../../../../../../mapping/views/components/Common/LoadingSpinner";
// @ts-ignore
import * as html2pdf from "html2pdf.js/dist/html2pdf.min";
import {ProposalPreview} from "../ProposalViewerEditor";
import {PortalLayout} from "../../../../../../../portal/views/styles";
import {StyleOption} from "../../../../../../../_shared/generics/_shared";
import {Address} from "@natomas-org/core";

export class B2BProposalPreview extends React.Component {
  render() {
    return (
      <div id="componentToPrintId">
        <B2BPresentationView hideClose={true} readyToPrint={true} />
      </div>
    );
  }
}

export class DTCProposalPreview extends React.Component {
  render() {
    return (
      <div id="componentToPrintId">
        <DTCPresentationView hideClose={true} readyToPrint={true} />
      </div>
    );
  }
}

const LoadPanel = (props: {
  hidden?: boolean;
  setLoadingValue: React.Dispatch<React.SetStateAction<boolean>>;
  loadingValue: boolean;
  totalDuration: number;
}) => {
  const [ellipsis, setEllipsis] = useState(".  ");
  const [message, setMessage] = useState("Downloading Images");
  useEffect(() => {
    const intervalId = setInterval(() => {
      setMessage((prevMessage) => {
        switch (prevMessage) {
          case "Downloading Images":
            return "Rendering Document";
          case "Rendering Document":
            return "Assembling Pages";
          case "Assembling Pages":
            return "Finalizing Download";
          default:
            return "Downloading PDF";
        }
      });
    }, (props.totalDuration + 5000) / 4); // Message changes every third of the total duration
    const intervalId2 = setInterval(() => {
      setEllipsis((prevEllipses) => {
        switch (prevEllipses) {
          case ".  ":
            return ".. ";
          case ".. ":
            return "...";
          case "...":
            return ".  ";
          default:
            return ".  ";
        }
      });
    }, 500); // Ellipses changes every 1/2 second

    return () => {
      setEllipsis(".  ");
      setMessage("Downloading Images");
      clearInterval(intervalId2);
      clearInterval(intervalId);
    };
  }, []);
  const loadingText = useMemo(() => {
    return `${message}${ellipsis}`;
  }, [ellipsis, message]);
  return (
    <LoadingPanelDiv hidden={props.hidden}>
      <NatLabel
        style={{color: WHITE}}
        label={"Processing PDF Download"}
        type={NatLabelType.H1}
        weight={NatLabelWeight.BOLD}
      />
      <NatLabel
        style={{color: WHITE}}
        label={"Please do not close the window"}
        type={NatLabelType.P3}
        weight={NatLabelWeight.BOLD}
      />
      <NatLabel
        style={{
          color: WHITE,
          fontStyle: "italic",
          textAlign: "center",
          whiteSpace: "break-spaces",
        }}
        label={loadingText}
        type={NatLabelType.P4}
        weight={NatLabelWeight.MEDIUM}
      />
      <LoadingSpinner isFullScreen={false} style={{color: WHITE}} />

      <NatButton
        clickEvent={() => {
          props.setLoadingValue(false);
        }}
        label={"Abort"}
        option={StyleOption.DESTRUCTIVE}
        spinnerEnabled={false}
        style={{marginBottom: "2rem"}}
        hidden={!props.loadingValue || true}
      />
    </LoadingPanelDiv>
  );
};
const JS_PDF_FOOTER_HEIGHT = 60;
const addFooters = (
  jsPdfObj: any,
  footerText: string,
  developmentByVilla: boolean
) => {
  const pageCount = jsPdfObj.internal.getNumberOfPages();
  jsPdfObj.setFont("helvetica", "normal");
  jsPdfObj.setFontSize(24);
  jsPdfObj.setLineWidth(1.5);
  jsPdfObj.setDrawColor(85, 85, 85);
  jsPdfObj.setTextColor(128, 125, 122);
  for (let i = 1; i <= pageCount; i++) {
    jsPdfObj.setPage(i);

    if (i === 1 || (i === pageCount && developmentByVilla)) {
    } else {
      // Add a white rectangle for footer content
      jsPdfObj.setFillColor(255, 255, 255);
      jsPdfObj.rect(
        0,
        jsPdfObj.internal.pageSize.height - JS_PDF_FOOTER_HEIGHT,
        jsPdfObj.internal.pageSize.width,
        JS_PDF_FOOTER_HEIGHT,
        "F"
      );
      // Set to gray color
      jsPdfObj.setDrawColor(85, 85, 85);
      // Add the top line
      jsPdfObj.line(
        0,
        jsPdfObj.internal.pageSize.height - JS_PDF_FOOTER_HEIGHT,
        jsPdfObj.internal.pageSize.width,
        jsPdfObj.internal.pageSize.height - JS_PDF_FOOTER_HEIGHT,
        "S"
      );
      jsPdfObj.text(
        footerText + " | Page " + String(i) + " of " + String(pageCount),
        20,
        jsPdfObj.internal.pageSize.height - JS_PDF_FOOTER_HEIGHT / 2 + 4,
        {
          align: "left",
        }
      );
    }
  }
};
const TOTAL_SCROLL_INCREMENTS = 20;
const TOTAL_SCROLL_DURATION_DTC = 7500;
const TOTAL_SCROLL_DURATION_B2B = TOTAL_SCROLL_DURATION_DTC * 1.5;
const DELAY_BEFORE_BUTTON_AVAILABLE = 1000;
const LOADING_RENDER_SCALE = 0.75;
export const ProjectProposalDownloadButton = React.memo(
  (props: {previewElement?: HTMLElement}) => {
    const {
      id: pid,
      address,
      mergedCustomerProjectInfos,
      isDevelopmentByVilla,
    } = useActiveProject();
    const [downloadAvailable, setDownloadAvailable] = useState<boolean>(false);
    useEffect(() => {
      setTimeout(() => {
        setDownloadAvailable(true);
      }, DELAY_BEFORE_BUTTON_AVAILABLE);
      return () => {
        setDownloadAvailable(false);
      };
    }, []);

    const [loading, setLoading] = useState<boolean>(false);
    let timeoutDuration = TOTAL_SCROLL_DURATION_DTC / TOTAL_SCROLL_INCREMENTS;
    if (isDevelopmentByVilla) {
      timeoutDuration = TOTAL_SCROLL_DURATION_B2B / TOTAL_SCROLL_INCREMENTS;
    }
    const downloadPdf = async () => {
      let authToken = "";
      if (auth.currentUser) {
        authToken = await auth.currentUser.getIdToken();
      }
      setLoading(true);
      const element = document.getElementById("componentToPrintId");
      const customerViewTab = document.getElementById("proposal-preview");
      const marginCorrectionFraction = 0.075;
      if (element && customerViewTab) {
        const startingTopOffset =
          element.scrollHeight * ((1 - LOADING_RENDER_SCALE) / 2);
        for (let i = 0; i <= TOTAL_SCROLL_INCREMENTS; i++) {
          setTimeout(() => {
            customerViewTab.scrollTo({
              left:
                customerViewTab.scrollWidth * LOADING_RENDER_SCALE -
                (element.scrollWidth * LOADING_RENDER_SCALE) / 2,
              top:
                ((element.scrollHeight * LOADING_RENDER_SCALE) /
                  TOTAL_SCROLL_INCREMENTS) *
                  i +
                startingTopOffset,
              behavior: "smooth",
            });
          }, timeoutDuration * i);
        }
        setTimeout(() => {
          customerViewTab.scrollTo({
            top: startingTopOffset,
            behavior: "smooth",
          });
          const pageWidth =
            FULL_SCREEN_WIDTH_REM * 16 * (1 + marginCorrectionFraction);
          const pageHeight =
            FULL_SCREEN_HEIGHT_REM * 16 * (1 + marginCorrectionFraction);
          const options = {
            pagebreak: {mode: ["css"]},
            margin: [
              pageHeight * 0.25 * marginCorrectionFraction,
              pageWidth * 0.5 * marginCorrectionFraction,
              pageHeight * 0.25 * marginCorrectionFraction,
              pageWidth * 0.5 * marginCorrectionFraction,
            ],
            jsPDF: {
              orientation: "landscape",
              format: [pageHeight, pageWidth],
              unit: "px",
              compress: true,
            },
            html2canvas: {
              scale: 1,
              allowTaint: false,
              useCORS: false,
              imageTimeout: 0,
              proxy: `${API_BASE_URL()}/images/fetch-image?pid=${pid}&token=${authToken}`,
              foreignObjectRendering: false,
            },
            image: {type: "png", quality: 1},
            filename: "proposal.pdf",
          };
          let filename = "Project Proposal";
          if (mergedCustomerProjectInfos && mergedCustomerProjectInfos.code) {
            filename = `${mergedCustomerProjectInfos.code}${
              mergedCustomerProjectInfos?.customer_contact?.last_name ?? ""
            }-${filename}`;
          }
          if (address) {
            filename = `${filename}${Address.getFullAddress(address)}`;
          }

          const worker = html2pdf()
            .set(options)
            .from(element)
            .toPdf()
            .get("pdf")
            .then(function (pdfObj: any) {
              // jsPDF object
              addFooters(
                pdfObj,
                Address.getFullAddress(address ?? undefined),
                isDevelopmentByVilla
              );
              pdfObj.save(`${filename}.pdf`).then((r: any) => {
                console.log(r);
                setLoading(false);
              });
            })
            .catch((e: any) => {
              console.log(e);
              setLoading(false);
            });
        }, timeoutDuration * TOTAL_SCROLL_INCREMENTS + 100);
        return;
      }
    };

    return (
      <Fragment>
        <PreviewPopupContent hidden={!loading}>
          <PortalLayout>
            <div
              style={{
                display: "flex",
                maxHeight: "100vh",
                maxWidth: "100vw",
                minHeight: "100vh",
                minWidth: "100vw",
                overflow: "scroll",
                position: "relative",
              }}
              id={"proposal-preview"}
            >
              <ProposalPreview
                embedded={false}
                hidden={!loading}
                zoomLevel={LOADING_RENDER_SCALE}
              />
            </div>
          </PortalLayout>
        </PreviewPopupContent>
        <NatButton
          clickEvent={downloadPdf}
          label={"Download as PDF"}
          spinnerEnabled={true}
          option={StyleOption.SECONDARY}
          style={{width: "fit-content"}}
          hidden={loading}
          disabled={!downloadAvailable}
        />
        {loading && (
          <LoadPanel
            hidden={!loading}
            setLoadingValue={setLoading}
            loadingValue={loading}
            totalDuration={timeoutDuration * TOTAL_SCROLL_INCREMENTS}
          />
        )}
      </Fragment>
    );
  }
);
const PreviewPopupContent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Number.MAX_SAFE_INTEGER - 1};
  width: 100vw;
  height: 100vh;
  display: flex;
  background: WHITE;
  flex-wrap: nowrap;
  flex-direction: column;
`;
const LoadingPanelDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Number.MAX_SAFE_INTEGER};
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.77);
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;
