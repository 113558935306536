import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Error, FormSubtitle} from "./styles";
import {NatSize, StyleOption} from "../../_shared/generics/_shared";
import {NatButton, NatLink} from "../../_shared/generics/button";
import {executeRequest} from "../../../database/firebase/api";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {AdminInput} from "../../admin/_shared/components/AdminComponentFactory/AdminComponentFactory";
import {NatModal, NatModalSize} from "../../_shared/generics/modal/NatModal";
import {IDocumentDetails, IRecipient} from "@natomas-org/core";
import {ISendDocusignProps} from "./constants";
import {usePage} from "../../_shared/hooks/usePage";
import {useFormik} from "formik";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {HiOutlineExternalLink} from "react-icons/hi";
import {
  formDocusignSendRequest,
  getErrorArrayFromRecipients,
  getInitialValues,
} from "./helpers";
import {EmailInput} from "./components/EmailInput";
import {ResolutionValidityTag} from "./components/ResolutionValidityTag";
import useActiveProject from "../../_shared/hooks/useProject/useActiveProject";

export const SendDocusignForm = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  initialProps: ISendDocusignProps;
  documentDetails: IDocumentDetails | null;
  setShowSendError: Dispatch<SetStateAction<boolean>>;
  setSendErrorString: Dispatch<SetStateAction<string>>;
  projectId: string;
}) => {
  const RECIPIENT_VALIDATE_KEY = "recipients";
  const CC_VALIDATE_KEY = "ccs";
  const NAME_VALIDATE_KEY = "Name";
  const EMAIL_VALIDATE_KEY = "Email";
  const {
    show,
    setShow,
    initialProps,
    documentDetails,
    setShowSendError,
    setSendErrorString,
    projectId,
  } = props;
  const {customer} = useCurrentCustomer();
  const {mergedCustomerProjectInfos: mergedProjectInfos} = useActiveProject();
  const {loggedInUser} = useCurrentUser();
  const {isNatMobile} = usePage();
  const [docusignProps, setDocusignProps] =
    useState<ISendDocusignProps>(initialProps);
  const [initialValues, setInitialValues] = useState(
    getInitialValues(
      customer,
      mergedProjectInfos,
      loggedInUser,
      initialProps,
      RECIPIENT_VALIDATE_KEY,
      CC_VALIDATE_KEY
    )
  );

  const resetForm = () => {
    setDocusignProps(initialProps);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const request = formDocusignSendRequest(
        values,
        projectId,
        docusignProps,
        customer,
        mergedProjectInfos,
        RECIPIENT_VALIDATE_KEY,
        CC_VALIDATE_KEY
      );
      return executeRequest("/docusign/v1/send", request).then((r: any) => {
        resetForm();
        if (r.responseCode > 200) {
          setSendErrorString(r.responseString);
          setShowSendError(true);
        }
        setShow(false);
      });
    },
    validate: (values) => {
      const errors: {[key: string]: any} = {};
      const recipientErrors = getErrorArrayFromRecipients(
        values[RECIPIENT_VALIDATE_KEY] as IRecipient[],
        RECIPIENT_VALIDATE_KEY,
        EMAIL_VALIDATE_KEY,
        NAME_VALIDATE_KEY
      );
      const ccErrors = getErrorArrayFromRecipients(
        values[CC_VALIDATE_KEY] as IRecipient[],
        CC_VALIDATE_KEY,
        EMAIL_VALIDATE_KEY,
        NAME_VALIDATE_KEY
      );

      if (!documentDetails) {
        errors["pdf"] = "A document upload is required.";
      }
      return Object.assign({}, errors, recipientErrors, ccErrors);
    },
  });

  useEffect(() => {
    const newInitialValues = getInitialValues(
      customer,
      mergedProjectInfos,
      loggedInUser,
      initialProps,
      RECIPIENT_VALIDATE_KEY,
      CC_VALIDATE_KEY
    );
    setInitialValues(newInitialValues);
    formik.setValues(newInitialValues);
  }, []);
  return (
    <NatModal
      header={docusignProps?.title}
      show={show}
      handleClose={() => {
        resetForm();
        setShow(false);
      }}
      size={NatModalSize.FULLSCREEN}
      content={
        <div>
          <EmailInput
            title={"Recipients"}
            formik={formik}
            fieldValueKey={RECIPIENT_VALIDATE_KEY}
            emailValidationKey={EMAIL_VALIDATE_KEY}
            nameValidationKey={NAME_VALIDATE_KEY}
            recipientKey={RECIPIENT_VALIDATE_KEY}
            maxRecipients={5}
          />
          <EmailInput
            title={"CCS"}
            formik={formik}
            fieldValueKey={CC_VALIDATE_KEY}
            emailValidationKey={EMAIL_VALIDATE_KEY}
            nameValidationKey={NAME_VALIDATE_KEY}
            recipientKey={RECIPIENT_VALIDATE_KEY}
          />
          <FormSubtitle>Email Subject: </FormSubtitle>
          <AdminInput
            value={formik.values.subject}
            fieldInfo={{type: "text", key: "subject"}}
            id={"subject"}
            onChange={(value: any) => {
              formik.setFieldValue("subject", value);
            }}
          />
          <FormSubtitle>Email Body: </FormSubtitle>
          <AdminInput
            height={"10rem"}
            value={formik.values.body}
            fieldInfo={{type: "textarea", key: "body"}}
            id={"body"}
            onChange={(value: any) => {
              formik.setFieldValue("body", value);
            }}
          />
          <FormSubtitle>Docusign Attachment:</FormSubtitle>
          {documentDetails ? (
            <>
              <NatLink
                label={documentDetails.title}
                openInNewTab={true}
                option={StyleOption.PRIMARY_LINK}
                link={documentDetails.url}
                size={NatSize.XLARGE}
                icon={<HiOutlineExternalLink />}
                style={{padding: "0px"}}
              />
              <ResolutionValidityTag
                documentDetails={documentDetails}
                initialProps={initialProps}
              />
            </>
          ) : (
            <Error>A PDF is Required to Send.</Error>
          )}
          <br />
          <NatButton
            label={"Send Docusign"}
            type={"button"}
            size={NatSize.NORMAL}
            option={StyleOption.PRIMARY}
            disabled={!formik.isValid}
            spinnerEnabled={true}
            clickEvent={formik.handleSubmit}
          />
        </div>
      }
    />
  );
};
