import React, {useState} from "react";
import {ProductOptionValue} from "../../../../_shared/constants/ProductOption";
import {OptionView} from "../styles";
import {OptionValueList} from "./OptionValueList";
import {OptionValueEditor} from "./OptionValueEditor";

export const OptionValueTab = () => {
  const [currentValue, setCurrentValue] = useState<ProductOptionValue | null>(
    null
  );
  return (
    <OptionView>
      <OptionValueList
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
      />
      {currentValue && (
        <OptionValueEditor
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
        />
      )}
    </OptionView>
  );
};
