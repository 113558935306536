import {
  fullPriceTextFromMicros,
  IPublicProjectInfo,
  LineItemTitle,
  LineItemType,
} from "@natomas-org/core";
import {PublicProjectInfo} from "@natomas-org/service";
// Accessor constants
export const COMPLETED_PROJECT_CODE_KEY = "code";
export const COMPLETED_PROJECT_TITLE_KEY = "title";
export const COMPLETED_PROJECT_ADDRESS_KEY = "address";
export const COMPLETED_PROJECT_STAGE_KEY = "stage";
export const COMPLETED_PROJECT_PUBLISHED_KEY = "published";
export const COMPLETED_PROJECT_FEATURED_KEY = "featured";
export const COMPLETED_PROJECT_IMAGES_KEY = "imageIds";
export const ACTIONS_KEY = "actions-key";
export const COMPLETED_PROJECT_PRODUCT_TOTAL_PRICE_KEY = "total_product_price";
export const COMPLETED_PROJECT_PERMIT_TOTAL_PRICE_KEY = "permit_fee_total";
export const COMPLETED_PROJECT_TOTAL_PRICE_KEY = "total_price";
export const COMPLETED_PROJECT_SITE_WORK_TOTAL_PRICE_KEY =
  "total_site_work_price";
// Header constants
export const COMPLETED_PROJECT_TITLE_HEADER = "Title";
export const COMPLETED_PROJECT_PRODUCT_TOTAL_PRICE_HEADER =
  "Total Product Price";
export const COMPLETED_PROJECT_SITE_WORK_TOTAL_PRICE_HEADER =
  "Total Site Work Price";

const lineItemTypesToDisplay = [
  LineItemType.PERMIT_LINE_ITEM_ID,
  LineItemType.CONSULTANTS_LINE_ITEM_ID,
  LineItemType.SITE_PREP_LINE_ITEM_ID,
  LineItemType.CONSTRUCTION_MANAGEMENT_FEE_LINE_ITEM_ID,
  LineItemType.DELIVERY_LINE_ITEM_ID,
  LineItemType.INSTALLATION_LINE_ITEM_ID,
  LineItemType.EXTERIOR_FINISHING_LINE_ITEM_ID,
  LineItemType.INTERIOR_FINISHING_LINE_ITEM_ID,
  LineItemType.POWER_LINE_ITEM_ID,
  LineItemType.SEWER_LINE_ITEM_ID,
  LineItemType.WATER_LINE_ITEM_ID,
];

export const completedProjectsColumns: {
  Header: string;
  accessor: any | ((details: IPublicProjectInfo) => any);
  id: string;
}[] = [
  {
    Header: "Project Code",
    accessor: COMPLETED_PROJECT_CODE_KEY,
    id: COMPLETED_PROJECT_CODE_KEY,
  },
  {
    Header: "Published",
    accessor: COMPLETED_PROJECT_PUBLISHED_KEY,
    id: COMPLETED_PROJECT_PUBLISHED_KEY,
  },
  {
    Header: "Featured",
    accessor: COMPLETED_PROJECT_FEATURED_KEY,
    id: COMPLETED_PROJECT_FEATURED_KEY,
  },
  {
    Header: COMPLETED_PROJECT_TITLE_HEADER,
    accessor: (projectInfo: IPublicProjectInfo) => {
      return PublicProjectInfo.getProjectTitle(projectInfo);
    },
    id: COMPLETED_PROJECT_TITLE_KEY,
  },
  {
    Header: "Address",
    accessor: COMPLETED_PROJECT_ADDRESS_KEY,
    id: COMPLETED_PROJECT_ADDRESS_KEY,
  },
  {
    Header: "Stage",
    accessor: COMPLETED_PROJECT_STAGE_KEY,
    id: COMPLETED_PROJECT_STAGE_KEY,
  },
  {
    Header: "Images",
    accessor: COMPLETED_PROJECT_IMAGES_KEY,
    id: COMPLETED_PROJECT_IMAGES_KEY,
  },
  {
    Header: "Project Total",
    accessor: (details: IPublicProjectInfo) => {
      return formatPrice(PublicProjectInfo.getTotalPrice(details));
    },
    id: COMPLETED_PROJECT_TOTAL_PRICE_KEY,
  },
  {
    Header: COMPLETED_PROJECT_PRODUCT_TOTAL_PRICE_HEADER,
    accessor: (details: IPublicProjectInfo) => {
      return formatPrice(PublicProjectInfo.getProductPrice(details));
    },
    id: COMPLETED_PROJECT_PRODUCT_TOTAL_PRICE_KEY,
  },
  {
    Header: COMPLETED_PROJECT_SITE_WORK_TOTAL_PRICE_HEADER,
    accessor: (details: IPublicProjectInfo) => {
      return formatPrice(PublicProjectInfo.getTotalSiteWorkPrice(details));
    },
    id: COMPLETED_PROJECT_SITE_WORK_TOTAL_PRICE_KEY,
  },
  ...lineItemTypesToDisplay?.map((lineItemType) => {
    return {
      Header: LineItemTitle[lineItemType],
      accessor: (details: IPublicProjectInfo) => {
        return lineItemDataAccessor(details, lineItemType);
      },
      id: lineItemType,
    };
  }),
  {
    Header: "Actions",
    accessor: ACTIONS_KEY,
    id: ACTIONS_KEY,
  },
];

export const completedProjectsHidePricingState = {
  sortBy: [
    {
      id: COMPLETED_PROJECT_CODE_KEY,
      asc: true,
    },
  ],
  hiddenColumns: [
    COMPLETED_PROJECT_PRODUCT_TOTAL_PRICE_KEY,
    COMPLETED_PROJECT_SITE_WORK_TOTAL_PRICE_KEY,
    COMPLETED_PROJECT_PERMIT_TOTAL_PRICE_KEY,
    ...lineItemTypesToDisplay,
  ],
};

const lineItemDataAccessor = (
  publicProjectInfo: IPublicProjectInfo,
  lineItemType: LineItemType
) => {
  const priceInMicros: number | null = PublicProjectInfo.getLineItemPrice(
    publicProjectInfo,
    lineItemType
  );
  if (priceInMicros) {
    return fullPriceTextFromMicros(priceInMicros, {
      roundToInteger: true,
    });
  }
  return null;
};

const formatPrice = (price: number | null): string | null => {
  if (price) {
    return fullPriceTextFromMicros(price, {
      roundToInteger: true,
    });
  }
  return null;
};

export const completedProjectsShowPricingState = {
  sortBy: [
    {
      id: COMPLETED_PROJECT_CODE_KEY,
      asc: true,
    },
  ],
  hiddenColumns: [],
};
