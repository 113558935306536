export type ConditionMode = "product" | "selection" | "both" | undefined;

export const getSelectionMap = (targets: any[]) => {
  let selectionMap: {[key: string]: boolean} = {};
  targets.filter((o) => !!o).forEach((o) => (selectionMap[o.value] = true));
  return selectionMap;
};

export const hasValidMode = (mode: ConditionMode) => {
  return mode !== undefined;
};

export const isProductCondition = (mode: ConditionMode) => {
  return mode === "product";
};

export const isSelectionCondition = (mode: ConditionMode) => {
  return mode === "selection";
};

export const isMultiCondition = (mode: ConditionMode) => {
  return mode === "both";
};

export const hasProductSelector = (mode: ConditionMode) => {
  return isMultiCondition(mode) || isProductCondition(mode);
};

export const hasSelectionSelector = (mode: ConditionMode) => {
  return isMultiCondition(mode) || isSelectionCondition(mode);
};
