import {
  FactoryField,
  FactoryVersionField,
  IFactorySlice,
  IFactoryVersion,
} from "../../../slices/FactorySlice";
import {
  ListingStatusDetails,
  ProductGroup,
  IProductGroupDetails,
  IEligibleFeatures,
} from "@natomas-org/core";
import {isFactoryEligible} from "../../useMappingFeatures/helper";

export const getFactoryLineDetails = (
  factorySlice: IFactorySlice,
  options: {
    listingStatuses?: {
      [ListingStatusDetails.UNLISTED]?: boolean;
      [ListingStatusDetails.LISTED]?: boolean;
      [ListingStatusDetails.HIDDEN]?: boolean;
      [ListingStatusDetails.NULL]?: boolean;
    };
    eligibility?: IEligibleFeatures;
    excludeFactories?: string[];
  }
): IProductGroupDetails[] => {
  const {listingStatuses, eligibility, excludeFactories} = options;
  const factoryKeys = Object.keys(factorySlice);
  let factories: IProductGroupDetails[] = [];
  factoryKeys?.forEach((factoryId: string) => {
    const details = getFactoryLinePublishedVersionDetails(
      factorySlice,
      factoryId
    );
    // Only add the factory if it is not excluded, and has details
    if (details && !excludeFactories?.includes(factoryId)) {
      factories.push(details);
    }
  });
  if (listingStatuses) {
    factories = factories?.filter((factory) => {
      return listingStatuses[factory?.status] === true;
    });
  }
  if (eligibility) {
    factories = factories?.filter((factory) => {
      return isFactoryEligible(eligibility, factory?.id);
    });
  }
  return factories;
};

export const getFactoryLine = (
  factorySlice: IFactorySlice,
  eligibleListings?: {
    [ListingStatusDetails.UNLISTED]?: boolean;
    [ListingStatusDetails.LISTED]?: boolean;
    [ListingStatusDetails.HIDDEN]?: boolean;
    [ListingStatusDetails.NULL]?: boolean;
  }
): IProductGroupDetails[] => {
  const factoryKeys = Object.keys(factorySlice);
  const factories: IProductGroupDetails[] = [];
  factoryKeys?.forEach((factoryId: string) => {
    const details = getFactoryLinePublishedVersionDetails(
      factorySlice,
      factoryId
    );
    if (details) {
      factories.push(details);
    }
  });
  if (eligibleListings) {
    return factories?.filter((factory) => {
      return eligibleListings[factory?.status] === true;
    });
  } else {
    return factories;
  }
};

export const getFactoryLinePublishedVersion = (
  factorySlice: IFactorySlice,
  id: string
): IFactoryVersion | null => {
  const factory = factorySlice[id];
  if (factory) {
    const info = factory?.[FactoryField.INFO];
    if (info?.publishedVersionId) {
      const publishedVersion = factory?.versions?.[info.publishedVersionId];
      if (publishedVersion) {
        return publishedVersion;
      }
    }
  }
  return null;
};

export const getFactoryLinePublishedVersionDetails = (
  factorySlice: IFactorySlice,
  id: string
): IProductGroupDetails | null => {
  const publishedVersion = getFactoryLinePublishedVersion(factorySlice, id);
  if (publishedVersion) {
    const details = ProductGroup.getProductGroupDetails(
      publishedVersion[FactoryVersionField.DETAILS]
    );
    if (details) {
      return details;
    }
  }
  return null;
};
