import styled from "styled-components";
import {GRAY, GRAY_BORDER_LIGHT} from "../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../SSDDashboardView/styles/globals";

export const HCPComponentHR = styled.div`
  color: ${GRAY_BORDER_LIGHT};
  border: none;
  height: 2px;
  margin-bottom: 54px;
  margin-top: 54px;
  width: 100%;
  border-bottom: 1px solid #acacac;
`;

export const HCPComponentTitle = styled.div<{includePadding: boolean}>`
  ${(props) => (props.includePadding ? "padding-bottom: 38px" : "")};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-style: normal;
  font-size: 32px;
  line-height: 133%;
`;

export const DesignConfirmationStatement = styled.div`
  margin-bottom: 1rem;
  color: ${GRAY};
`;
