import React from "react";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {PORTAL_NOTES_ID} from "../../HCPDashboardLayout/HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {ProjectNotesList} from "../../ProjectNotes/ProjectNotesList";
import {useActiveProjectNotes} from "../../../../_shared/hooks/useProject/useActiveProjectNotes";

export const FPMeetingNotes = () => {
  const width = useDynamicValue(fullColumnWidths);
  const {projectNotes} = useActiveProjectNotes();

  return (
    <PortalSection
      headerTitle={"Your Latest Updates"}
      sectionId={PORTAL_NOTES_ID}
    >
      <PageElement size={width} height={"auto"}>
        <ProjectNotesList
          projectNotes={projectNotes}
          public_facing={true}
          onNoteClick={() => {}}
        />
      </PageElement>
    </PortalSection>
  );
};
