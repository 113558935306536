import {useNavigation} from "../_shared/hooks/useNavigation";
import {EmptyPage} from "../_shared/generics/empty-page";
import React from "react";
import {NavigationPaths} from "../_shared/hooks/useNavigation/paths";

export const DocumentPDFView = () => {
  const {to} = useNavigation();
  return (
    <EmptyPage
      title={"Document PDFs under development"}
      buttons={[
        {
          label: "Proposal PDF",
          clickEvent: () => to(NavigationPaths.DOCUMENT_PDF_PROPOSAL),
        },
        {
          label: "Back to All Documents",
          clickEvent: () => to(NavigationPaths.DOCUMENT),
        },
      ]}
    />
  );
};
