import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../../../styles/globals";

export const DetailsSectionContainer = styled.div`
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
`;

export const DetailsSectionContent = styled.div<{mobile: boolean}>`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  margin: 1rem 0;
`;

export const DetailsSectionTitle = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 1.5rem;
`;

export const GenericImageWrapper = styled.div<{height: string}>`
  height: ${(props) => props.height};
  width: 100%;
  position: relative;
  .fullDiv {
    border-radius: 0.5rem;
  }
`;
