import React, {useMemo} from "react";
import {QuickInfoSectionTitle} from "../styles";
import {InfoItem} from "../../../../../_shared/InfoItem";
import {
  getCityString,
  getFaultZone,
  getFireHazardLRA,
  getFireHazardSRA,
  getFloodZoneString,
  getLandslideZone,
  getLiquefactionZone,
} from "./helper";
import {IPropertyData} from "@natomas-org/core";

export const GeoDataInfoSection = (props: {
  propertyData: IPropertyData | undefined;
}) => {
  const {propertyData} = props;
  const seismicValues = useMemo(() => {
    return (
      "Landslide Zone: " +
      getLandslideZone(propertyData?.hazardData ?? undefined) +
      "\nLiquefaction Zone: " +
      getLiquefactionZone(propertyData?.hazardData ?? undefined) +
      "\nFault Zone: " +
      getFaultZone(propertyData?.hazardData ?? undefined)
    );
  }, [propertyData?.hazardData]);
  const fireValues = useMemo(() => {
    return (
      "SRA Fire Zone: " +
      getFireHazardSRA(propertyData?.hazardData ?? undefined) +
      "\nLRA Fire Zone: " +
      getFireHazardLRA(propertyData?.hazardData ?? undefined)
    );
  }, [propertyData?.hazardData]);

  if (!propertyData?.hazardData) {
    return (
      <>
        <QuickInfoSectionTitle>Geo Data</QuickInfoSectionTitle>
        <InfoItem
          label={"Status"}
          value={"No geo data determined."}
          description={
            "Geo data is automatically fetched when customer starts project journey."
          }
        />
      </>
    );
  } else {
    return (
      <>
        <QuickInfoSectionTitle>Geo Data</QuickInfoSectionTitle>
        <InfoItem
          label={"Seismic Values"}
          value={seismicValues}
          description={
            "Is the address on a fault zone, liquefaction zone, and/or landslide zone."
          }
        />
        <InfoItem
          label={"Flood Zone"}
          value={getFloodZoneString(propertyData?.hazardData ?? undefined)}
          description={
            "FEMA categorization of flood zone (AE, AO, AH, X, D, etc)."
          }
        />
        <InfoItem
          label={"Fire Zone"}
          value={fireValues}
          description={
            "Type of fire zone the address is within, if applicable."
          }
        />
        <InfoItem
          label={"AHJ City"}
          value={getCityString(propertyData?.jurisdictionData)}
          description={"City pulled from AHJ Smartsheet."}
        />
      </>
    );
  }
};
