import styled from "styled-components";
import {NatomasButton} from "../../../../_shared/generics/button/styles";
import {SIDEBAR_COLOR_LIGHT} from "../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../SSDDashboardView/styles/globals";

export const DesignContainer = styled.div`
  height: ${(props) => (props.isMobile ? "unset" : "calc(100% - 85px)")};
  width: 100%;
  overflow: ${(props) => (props.isMobile ? "auto" : "hidden")};
  position: relative;
  display: flex;
  flex-wrap: ${(props) => (props.isMobile ? "wrap" : "initial")};
  flex-direction: ${(props) => (props.isMobile ? "column-reverse" : "row")};
`;

export const DesignButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  ${NatomasButton} {
    margin-right: 0.25rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DesignContentContainer = styled.div`
  flex-grow: 1;
  padding: 0 ${(props) => (props.isMobile ? "1rem" : "2.5rem")};
  overflow-y: ${(props) => (props.isMobile ? "initial" : "auto")};
  height: ${(props) => (props.isMobile ? "fit-content" : "100%")};
  max-width: 100%;
  width: ${(props) => (props.isMobile ? "100%" : "unset")};
`;

export const DesignContentHeaderContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: ${(props) => props.justifyContentMode};
  align-items: center;
`;

export const DesignContentHeader = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 26px;
`;

export const DesignListHeader = styled.div`
  width: 100%;
  height: 50px;
  padding: 0 ${(props) => (props.isMobile ? "1rem" : "2.5rem")};
  line-height: 50px;
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 18px;
`;

export const DesignListHeaderNatPage = styled.div`
  width: 100%;
  height: 4rem;
  padding: 0 ${(props) => (props.isMobile ? "0rem" : "0rem")};
  line-height: 4rem;
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 1.375rem;
`;

export const DesignListContainer = styled.div`
  height: ${(props) => (props.isMobile ? "fit-content" : "auto")};
  overflow-y: ${(props) => (props.isMobile ? "initial" : "auto")};
  flex-shrink: 0;
  flex-grow: ${(props) => (props.isMobile ? 2 : 0)};
  max-width: 100%;
  width: ${(props) => (props.isMobile ? "auto" : "400px")};
  border: ${(props) => (!props.isMobile ? "1px solid rgba(0,0,0,0.14)" : "")};
  border-radius: 0 0 1rem 1rem;
  }
`;

export const DesignListItemContainer = styled.div`
  width: 100%;
  height: 100px;
  padding: 1rem ${(props) => (props.isMobile ? "1rem" : "2.5rem")};
  background-color: ${(props) =>
    props.selected === true ? SIDEBAR_COLOR_LIGHT : "white"};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

export const DesignListItemContainerNatPage = styled.div`
  width: 100%;
  height: 100px;
  padding: 1rem ${(props) => (props.isMobile ? "0rem" : "1rem")};
  background-color: ${(props) =>
    props.selected === true ? SIDEBAR_COLOR_LIGHT : "white"};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

export const SummaryImageContainer = styled.div`
  width: 100%;
  border-radius: 20px;
  height: ${(props) => (props.singleColumn === true ? "300px" : "500px")};
  position: relative;
  overflow: hidden;
`;

export const PageDetailsBreakdown = styled.div`
  width: 100%;
  position: relative;
  margin-top: 30px;
`;

export const PageDetailsBreakdownTitle = styled.div`
  width: 100%;
  position: relative;
  font-size: 16px;
  font-family: ${FONT_FAMILY_BOLD};
  margin-bottom: 10px;
`;

export const PageDetailsBreakdownColumnContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
`;

export const Column = styled.div`
  position: relative;
  flex-grow: 1;
  width: ${(props) => (props.singleColumn === true ? "100%" : "40%")};
  margin-right: ${(props) => (props.margin === true ? "40px" : "0px")};
`;

export const PageFooterContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export const PageFooterLeft = styled.div`
  position: relative;
  min-width: 240px;
  color: rgba(0, 0, 0, 0.6);
`;

export const PageFooterRight = styled.div`
  position: relative;
  flex-grow: 2;
  width: 50%;
`;

export const Row = styled.div`
  margin-bottom: 4px;
  position: relative;
`;

export const RowLeft = styled.div`
  color: rgba(0, 0, 0, 0.7);
  width: 70%;
  flex-grow: 1;
`;

export const RowRight = styled.div`
  width: fit-content;
  text-align: right;
`;
