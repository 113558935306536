import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const SiteInformationTableContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const BuildInfoTableItemTitle = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 12px;
`;
export const ModalInfoTableItemValue = styled.div`
  color: black;
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 1rem;
  padding-top: 0.5rem;
`;
export const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
