import React from "react";
import {CustomersTable} from "./tabs/CustomersTable";

export const CustomerDatabaseView = () => {
  return (
    <div style={{height: "100vh"}}>
      <CustomersTable />
    </div>
  );
};
