import React from "react";
import {
  FactoryInventoryInput,
  FactoryInventoryInputType,
} from "../../../../../_shared/FactoryInventoryInput";
import {IFactoryInventoryStudioEditorModifier} from "../../ModifierSection/interface";

interface ModifierBasicInfoProps {
  id: string;
  updateEdits: any;
  initialValue?: IFactoryInventoryStudioEditorModifier;
  edits?: IFactoryInventoryStudioEditorModifier;
}

const ModifierBasicInfo = (props: ModifierBasicInfoProps) => {
  const {id, initialValue, edits, updateEdits} = props;

  return (
    <>
      <FactoryInventoryInput
        id={`${id}-title`}
        key={`${id}-title`}
        label={"Title"}
        type={FactoryInventoryInputType.TEXT}
        initialValue={initialValue?.title}
        value={edits?.title}
        onChange={(value: any) => {
          updateEdits("title", value);
        }}
        resetChange={() => updateEdits("title", initialValue?.title)}
      />
      <FactoryInventoryInput
        id={`${id}-description`}
        key={`${id}-description`}
        label={"Description"}
        type={FactoryInventoryInputType.TEXT_BOX}
        initialValue={initialValue?.description}
        value={edits?.description}
        onChange={(value: any) => {
          updateEdits("description", value);
        }}
        resetChange={() =>
          updateEdits("description", initialValue?.description)
        }
      />
      <FactoryInventoryInput
        id={`${id}-priceInMicros`}
        key={`${id}-priceInMicros`}
        label={"Price"}
        type={FactoryInventoryInputType.PRICE}
        initialValue={initialValue?.priceInMicros}
        value={edits?.priceInMicros}
        onChange={(value: any) => {
          updateEdits("priceInMicros", value);
        }}
        resetChange={() =>
          updateEdits("priceInMicros", initialValue?.priceInMicros)
        }
      />
    </>
  );
};

export default ModifierBasicInfo;
