import React from "react";
import {QuickInfoSectionTitle} from "../styles";
import {InfoItem} from "../../../../../_shared/InfoItem";
import {IPropertyData} from "@natomas-org/core";

export const FeasibilityInfoSection = (props: {
  propertyData: IPropertyData | undefined;
}) => {
  const {propertyData} = props;
  const getValue = (value?: any | null) => {
    if (!value) return "Unknown";
    return value;
  };

  if (!propertyData?.jurisdictionData) {
    return (
      <>
        <QuickInfoSectionTitle>Feasibility</QuickInfoSectionTitle>
        <InfoItem
          label={"Status"}
          value={"No feasibility requirements determined."}
          description={
            "Requirements are automatically fetched when customer starts project journey."
          }
        />
      </>
    );
  } else {
    return (
      <>
        <QuickInfoSectionTitle>Feasibility</QuickInfoSectionTitle>
        <InfoItem
          label={"Maximum Square Footage"}
          value={
            getValue(
              propertyData?.jurisdictionData?.productConstraints?.maxADUSqFt
            ) + " ft²"
          }
          description={"Maximum allowed square footage for an ADU unit."}
        />
        <InfoItem
          label={"Maximum # of Bedrooms"}
          value={getValue(
            propertyData?.jurisdictionData?.productConstraints?.maxBedrooms
          )}
          description={"Maximum allowed bedroom count for an ADU unit."}
        />
        <InfoItem
          label={"Primary Setbacks"}
          value={
            getValue(
              propertyData?.jurisdictionData?.placementConstraints
                ?.primarySetBacks
            ) + " feet"
          }
          description={"Primary setbacks for the property."}
        />
        <InfoItem
          label={"Front Setbacks"}
          value={
            getValue(
              propertyData?.jurisdictionData?.placementConstraints?.frontSetback
            ) + " feet"
          }
          description={"Front setbacks for the property."}
        />
        <InfoItem
          label={"Side/Rear Setbacks"}
          value={
            getValue(
              propertyData?.jurisdictionData?.placementConstraints
                ?.sideAndRearSetBacks
            ) + " feet"
          }
          description={"Side and rear setbacks for the property."}
        />
      </>
    );
  }
};
