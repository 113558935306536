import React, {useCallback, useEffect, useMemo, useState} from "react";
import CatalogEditorHeader from "../FactoryInventoryCatalogEditor/components/Header";
import {IFactoryInventoryStudioEditorCategory} from "./components/Editor/CategorySection/interface";
import CategoryEditor from "./components/Editor/CategorySection";
import {flattenCategories} from "./components/formik/initialize";
import {StudioEditorCategorySeparator, StudioEditorContainer} from "./styles";
import {NatMicroModal} from "../../../../../../../../_shared/generics/micro-modal";
import {IFactoryInventoryStudioEditorPopupOptions} from "./components/Editor/PopupContent/interface";
import PopupContent from "./components/Editor/PopupContent";
import {useFormik} from "formik";
import _ from "lodash";
import StudioEditorPreview from "./components/Preview";
import {saveStudioEditorChanges} from "./components/formik/saving";
import StudioEditorFieldAddButton, {
  StudioEditorActionType,
  StudioEditorFieldType,
} from "./components/Editor/_shared/StudioEditorFieldAddButton";
import {sortKeys} from "./helper";
import {deleteTarget} from "./components/formik/delete";
import {FactoryInventoryEditingModeToggle} from "./components/Editor/FactoryInventoryEditingModeToggle";
import {swapTarget} from "./components/formik/move";
import StudioEditorHeader from "./components/Editor/StudioEditorHeader";
import {PageContainer} from "../../../../../../../../_shared/generics/page/components/PageContainer";
import {FactoryInventoryEditorProps} from "../../index";

export interface IFactoryInventoryStudioEditor {
  [id: string]: IFactoryInventoryStudioEditorCategory;
}

export enum StudioEditorMode {
  EDIT = "Edit",
  DELETE = "Delete",
  MOVE = "Move",
}

export const FactoryInventoryStudioEditor = (
  props: FactoryInventoryEditorProps
) => {
  const {product, configuration, isEditing, priceMode} = props;

  const [event, setEvent] = useState<any>(undefined);
  const [mode, setMode] = useState<StudioEditorMode>(StudioEditorMode.EDIT);
  const [popupOptions, setPopupOptions] = useState<
    IFactoryInventoryStudioEditorPopupOptions | undefined
  >(undefined);

  const initialValues: IFactoryInventoryStudioEditor = useMemo(() => {
    return flattenCategories(configuration);
  }, [configuration]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      return saveStudioEditorChanges(product, values);
    },
  });

  useEffect(() => {
    formik?.setValues(initialValues);
  }, [initialValues]);

  const keys: string[] = useMemo(() => {
    const values = formik.values ?? {};
    const k = Object.keys(values);
    return sortKeys(k, formik.values);
  }, [formik.values]);

  const hasEdits = useMemo(() => {
    return !_.isEqual(initialValues, formik.values);
  }, [formik.values, initialValues]);

  const resetChanges = useCallback(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [formik, initialValues]);

  const setActionEvent = useCallback(
    (e: any, options?: IFactoryInventoryStudioEditorPopupOptions) => {
      if (options?.action === StudioEditorActionType.DELETE) {
        deleteTarget(formik, options);
      } else if (
        options?.action === StudioEditorActionType.MOVE_UP ||
        options?.action === StudioEditorActionType.MOVE_DOWN
      ) {
        swapTarget(formik, options);
      } else {
        setEvent(e);
        setPopupOptions(options);
      }
    },
    [formik?.values]
  );

  const resetActionEvent = useCallback(() => {
    setEvent(undefined);
    setPopupOptions(undefined);
  }, []);

  if (!initialValues) {
    return null;
  }
  if (!isEditing) {
    return (
      <>
        <CatalogEditorHeader
          resetAllChanges={resetChanges}
          hasEdits={hasEdits}
          saveChanges={formik?.submitForm}
        />
        <StudioEditorPreview
          product={product}
          formik={formik}
          priceMode={priceMode}
        />
      </>
    );
  }
  return (
    <>
      <CatalogEditorHeader
        resetAllChanges={resetChanges}
        hasEdits={hasEdits}
        saveChanges={formik?.submitForm}
        additionalControls={
          <FactoryInventoryEditingModeToggle mode={mode} setMode={setMode} />
        }
      />
      <NatMicroModal
        id={"field-edit-popup"}
        draggable
        event={event}
        startInTopLeft={true}
        maxWidthPx={1400}
      >
        <PopupContent
          factoryId={product?.productGroupId}
          options={popupOptions}
          initialValues={initialValues}
          formik={formik}
          reset={resetActionEvent}
        />
      </NatMicroModal>
      <StudioEditorHeader
        isOlderVersion={!!configuration && configuration?.builderVersion !== 2}
      />
      <PageContainer>
        <StudioEditorContainer>
          {keys?.map((id: string) => {
            const category = formik.values[id];
            const initialCategory = initialValues[id];
            if (!category) {
              return null;
            }
            return (
              <>
                <CategoryEditor
                  key={id}
                  id={id}
                  initialValue={initialCategory}
                  currentValue={category}
                  setActionEvent={setActionEvent}
                  mode={mode}
                />
                <StudioEditorCategorySeparator />
              </>
            );
          })}
          {mode === StudioEditorMode.EDIT && (
            <>
              <StudioEditorFieldAddButton
                type={StudioEditorFieldType.CATEGORY}
                rootPath={{}}
                setActionEvent={setActionEvent}
              />
              <StudioEditorCategorySeparator />
            </>
          )}
        </StudioEditorContainer>
      </PageContainer>
    </>
  );
};
