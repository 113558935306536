import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {
  FactoryField,
  FactoryVersionField,
  IFactory,
} from "../../slices/FactorySlice";
import {useMemo} from "react";
import {ListingStatusFilter} from "./helper";
import {ListingStatusDetails} from "@natomas-org/core";

export const useFactorySlice = () => {
  return useSelector((state: IStore) => state.factory) ?? {};
};

export const useAllFactories = (options: {
  onlyFactory?: string;
  listingStatusFilter?: ListingStatusFilter;
}): IFactory[] => {
  const {listingStatusFilter, onlyFactory} = options;
  const slice = useFactorySlice();

  const ids = useMemo(() => {
    if (onlyFactory) {
      return [onlyFactory];
    }
    let allIds = Object.keys(slice ?? {});
    if (listingStatusFilter) {
      return allIds?.filter((id: string) => {
        const publishedVersionId =
          slice?.[id]?.[FactoryField.INFO]?.publishedVersionId;
        if (publishedVersionId) {
          const status: ListingStatusDetails =
            slice?.[id]?.versions?.[publishedVersionId]?.[
              FactoryVersionField.DETAILS
            ]?.status;
          if (status) {
            return listingStatusFilter?.[status] ?? false;
          }
        }
        return false;
      });
    }
    return allIds;
  }, [listingStatusFilter, onlyFactory, slice]);

  return useMemo(() => {
    // console.log(ids);
    return ids.map((id: string) => slice[id]);
  }, [slice, ids]);
};
