import {
  ARCHIVED_KEY,
  ASSEMBLY_KEY,
  CONDITIONS_KEY,
  DETAILS_KEY,
  EXTERNAL_CATEGORY_KEY,
  EXTERNAL_DESCRIPTION_KEY,
  ID_KEY,
  INCLUDED_ORDER_FORM_KEY,
  INTERNAL_CATEGORY_KEY,
  INTERNAL_NOTES_KEY,
  LABEL_KEY,
  MULTI_OPTION_KEY,
  OMIT_KEY,
  PRICING_PACKAGE_KEY,
  SOURCE_KEY,
  VALUE_KEY,
  VARIANTS_KEY,
} from "./constants";

export enum InfoSetItemInternalCategory {
  UNSET = "",
  FLOOR_PLAN = "Floor Plan",
  EXTERIOR = "Exterior",
  BATH = "Bath",
  FLOORING = "Flooring",
  APPLIANCES = "Appliances",
  KITCHEN = "Kitchen",
  OTHER = "Other",
}

export interface InfoSetItem {
  [ID_KEY]: string;
  [LABEL_KEY]: string;
  [INCLUDED_ORDER_FORM_KEY]: boolean;
  [INTERNAL_CATEGORY_KEY]: InfoSetItemInternalCategory;
  [MULTI_OPTION_KEY]: boolean;
  options?: InfoSetItemOption[];
  [ARCHIVED_KEY]: boolean;
}

export enum InfoSetItemOptionOmit {
  UNSET = "",
  CREDIT = "Credit",
  NO_CREDIT = "No Credit",
}

export enum InfoSetItemOptionSource {
  UNSET = "",
  FACTORY = "Factory",
  OTHER = "Other",
}

export enum InfoSetItemOptionAssembly {
  UNSET = "",
  FACTORY = "Factory",
  SITE = "Site",
}

/*
 Option Price Package:
 BASE: Cost is $0 for the factory order
 UPGRADE: Cost is > $0 for the factory order - is passed to the customer as a premium
 VILLA-STANDARD: Cost is > $0 for the factory order - but is factored into our models' base price
 */

export enum InfoSetItemOptionPricingPackage {
  UNSET = "",
  BASE = "Base",
  UPGRADE = "Upgrade",
  VILLA_STANDARD = "Villa Standard™",
}

export enum InfoSetItemOptionExternalCategory {
  UNSET = "",
  EXTERIOR = "Exterior Construction",
  INTERIOR = "Interior Construction",
  BATHROOM = "Bathroom Features",
  KITCHEN = "Kitchen Features",
  APPLIANCES = "Appliances",
  PLUMPING = "Plumbing",
  ELECTRICAL = "Electrical",
  WARRANTY = "Warranty Coverage",
}

export interface InfoSetItemOption {
  [VALUE_KEY]: string;
  [DETAILS_KEY]: string;
  [VARIANTS_KEY]: string; // Will add extended variant control if needed
  [CONDITIONS_KEY]: InfoSetItemOptionCondition[];
  [OMIT_KEY]: InfoSetItemOptionOmit; // Need clarification on this, believe it has to do with removing base options
  [SOURCE_KEY]: InfoSetItemOptionSource;
  [ASSEMBLY_KEY]: InfoSetItemOptionAssembly;
  [PRICING_PACKAGE_KEY]: InfoSetItemOptionPricingPackage;
  [EXTERNAL_CATEGORY_KEY]: InfoSetItemOptionExternalCategory;
  [EXTERNAL_DESCRIPTION_KEY]: string; // Rich text editor
  [INTERNAL_NOTES_KEY]: string; // Rich text editor
}

/*
 Condition Types:
 ANY: Return true if at least one modifier is selected
 ALL: Return true if every modifier is selected
 NONE: Return true if every modifier is not selected
 */

export enum InfoSetItemOptionConditionType {
  ALL = "All",
  ANY = "Any",
}

export interface InfoSetItemOptionCondition {
  targets?: string[]; // empty -> all product, productIds
  type: InfoSetItemOptionConditionType;
  requiredModifiers: {[modifierId: string]: boolean};
}

// TODO Potentially future Variant implementation?
// export interface InfoSetItemOptionVariant {
//   targets?: string[]; // empty -> all product, productIds
//   details: string;
// }
