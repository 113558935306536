import React from "react";
import {NatModal} from "../../../../../_shared/generics/modal/NatModal";
import {StyleOption} from "../../../../../_shared/generics/_shared";

export const TextInputModal = (props: any) => {
  const {show, setShow, modifierGroup, formik} = props;
  const handleClose = () => {
    setShow(false);
  };
  const handleRemove = () => {
    formik.setFieldValue("note-" + modifierGroup.id, null);
    setShow(false);
  };

  const saveChanges = () => {
    setShow(false);
  };

  if (formik == null) {
    return null;
  }

  return (
    <NatModal
      show={show}
      handleClose={handleClose}
      content={
        <div>
          <textarea
            className="form-control"
            id="message-text"
            placeholder="Ex. still deciding on skylights"
            value={formik.values["note-" + modifierGroup.id]}
            name={"note-" + modifierGroup.id}
            onChange={formik.handleChange}
          />
        </div>
      }
      header={
        modifierGroup ? '"' + modifierGroup.title + '" note' : "Add a note"
      }
      footerButtons={[
        {
          clickEvent: handleRemove,
          label: "Remove note",
          option: StyleOption.SECONDARY,
        },
        {clickEvent: saveChanges, label: "Done"},
      ]}
    />
  );
};
