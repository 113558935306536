import React from "react";
import {
  RichTextDiv,
  RichTextDivProps,
} from "../../../../../_shared/generics/rich-text-editor/RichTextDiv";
import {VILLA_SLATE} from "../../../../../_shared/colors";

const ThemedRichTextDiv = (props: RichTextDivProps) => {
  return (
    <RichTextDiv style={{color: VILLA_SLATE, fontSize: "13px"}} {...props} />
  );
};

export default ThemedRichTextDiv;
