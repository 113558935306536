import styled from "styled-components";

export const ConstructionSpecificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 80px;
  //border: 1px solid #199615;
  flex: 1 1 0;
`;

export const ConstructionSpecificationCard = styled.div`
  height: auto;
  width: 100%;
  //border: 1px solid #a6d5e7;
`;

export const ConstructionSpecificationsColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  padding: 20px 0;
  //border: 1px solid #ccc;
`;
