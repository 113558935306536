import React from "react";
import {
  NatButton,
  NatomasButtonProps,
} from "../../../../_shared/generics/button";
import {StudioControllerContainer} from "./styles";
import {useDispatch} from "react-redux";
import {setShowEditingConfirmation} from "../../../../_shared/slices/StudioSlice";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import {NatEditIcon} from "../../../../_shared/icon/icons";

export interface StudioDesignControllerProps {
  buttons?: NatomasButtonProps[];
  hideEdit?: boolean;
}

export const StudioController = (props: StudioDesignControllerProps) => {
  const {buttons, hideEdit} = props;
  const {isEditing} = useStudioSettings();
  const dispatch = useDispatch();

  const startEditing = () => {
    dispatch(setShowEditingConfirmation(true));
  };

  const startEditingButton: NatomasButtonProps = {
    clickEvent: startEditing,
    hidden: isEditing,
    id: "start-editing",
    label: "Edit design",
    option: StyleOption.PRIMARY_ALT,
    size: NatSize.NORMAL,
    type: "button",
    icon: {
      icon: <NatEditIcon />,
      iconPosition: IconPosition.LEFT,
    },
  };

  return (
    <StudioControllerContainer>
      {buttons?.map((button: NatomasButtonProps, index: number) => {
        return <NatButton {...button} key={"footer-right-button-" + index} />;
      })}
      {!hideEdit && <NatButton {...startEditingButton} />}
    </StudioControllerContainer>
  );
};
