import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  CustomerProjectInfo,
  IConfigurationSite,
  IDocumentDetails,
  IProjectInfo,
  IProjectNoteDetails,
  LineItemType,
  mergeObjects,
} from "@natomas-org/core";
import {getProjectInfo, mergeCustomerProjectInfos} from "./helper";

export interface IProject {
  id: string;
  cartItemIds: string[];
  documents: {[id: string]: IDocumentDetails} | undefined;
  // map: IConfigurationMap | undefined;
  notes: {[id: string]: IProjectNoteDetails} | undefined;
  site: IConfigurationSite | undefined;
  natomasProjectInfo: CustomerProjectInfo | null | undefined;
  salesforceProjectInfo: CustomerProjectInfo | null | undefined;
  smartsheetProjectInfo: CustomerProjectInfo | null | undefined;
  mergedCustomerProjectInfos: CustomerProjectInfo | null | undefined;
  projectInfo: IProjectInfo | null | undefined;
  isArchived: boolean;
}

export interface IProjectSlice {
  [pid: string]: IProject;
}

const getInitialState = (): IProjectSlice => {
  return {};
};

const getProjectById = (state: IProjectSlice, id: string): IProject => {
  return state[id] ?? {id: id};
};

export enum ProjectInfoSource {
  NATOMAS = "natomas",
  SALESFORCE = "salesforce",
  SMARTSHEET = "smartsheet",
}

interface UpdateProjectProps {
  id: string;
}

interface UpdateProjectInfoProps extends UpdateProjectProps {
  source: ProjectInfoSource;
  payload: CustomerProjectInfo | null;
}

// interface UpdateProjectAddressProps extends UpdateProjectProps {
//   address: IAddressDetails;
// }
// interface UpdateProjectMapProps extends UpdateProjectProps {
//   map: IConfigurationMap;
// }
interface UpdateProjectSiteProps extends UpdateProjectProps {
  site: IConfigurationSite;
}

interface UpdateProjectCartProps extends UpdateProjectProps {
  cartItemIds: string[];
}

interface UpdateProjectNotesProps extends UpdateProjectProps {
  notes: {[id: string]: IProjectNoteDetails};
}

interface UpdateProjectDocumentsProps extends UpdateProjectProps {
  docs: {[id: string]: IDocumentDetails};
}

const projectSlice = createSlice({
  name: "project",
  initialState: getInitialState(),
  reducers: {
    updateProjectInfo: (
      state,
      action: PayloadAction<UpdateProjectInfoProps>
    ) => {
      const {id, source, payload}: UpdateProjectInfoProps = action.payload;
      const project: IProject = getProjectById(state, id);
      switch (source) {
        case ProjectInfoSource.NATOMAS:
          project.natomasProjectInfo = payload;
          break;
        case ProjectInfoSource.SALESFORCE:
          project.salesforceProjectInfo = payload;
          break;
        case ProjectInfoSource.SMARTSHEET:
          project.smartsheetProjectInfo = payload;
          break;
      }
      project.projectInfo = getProjectInfo(project);
      project.mergedCustomerProjectInfos = mergeCustomerProjectInfos(project);
      state[id] = project;
    },
    updateProjectNotes: (
      state,
      action: PayloadAction<UpdateProjectNotesProps>
    ) => {
      const {id, notes}: UpdateProjectNotesProps = action.payload;
      const project: IProject = getProjectById(state, id);
      project.notes = mergeObjects(project.notes, notes);
      state[id] = project;
    },
    updateProjectDocuments: (
      state,
      action: PayloadAction<UpdateProjectDocumentsProps>
    ) => {
      const {id, docs}: UpdateProjectDocumentsProps = action.payload;
      const project: IProject = getProjectById(state, id);
      if (state[id]) {
        state[id].documents = mergeObjects(project.documents, docs);
      }
    },
    updateProjectCartItemIds: (
      state,
      action: PayloadAction<UpdateProjectCartProps>
    ) => {
      const {id, cartItemIds}: UpdateProjectCartProps = action.payload;
      const project: IProject = getProjectById(state, id);
      project.cartItemIds = cartItemIds;
      state[id] = project;
    },
    updateProjectSite: (
      state,
      action: PayloadAction<UpdateProjectSiteProps>
    ) => {
      const {id, site}: UpdateProjectSiteProps = action.payload;
      const project: IProject = getProjectById(state, id);
      let formattedLineItems = site.lineItems ?? [];
      // This logic is probably unnecessary now... but I'm leaving it in for the time being
      formattedLineItems = formattedLineItems
        .filter((lineItem) => {
          return lineItem != null;
        })
        .map((lineItem: any) => {
          let lineItemId =
            lineItem?.lineItemId ??
            lineItem?.line_item_id ??
            lineItem?.lineItemType ??
            lineItem?.line_item_type;
          let lineItemType = lineItem.lineItemType ?? lineItem.line_item_type;
          if (lineItemType?.includes(LineItemType.CONSULTANTS_LINE_ITEM_ID)) {
            lineItemType = LineItemType.CONSULTANTS_LINE_ITEM_ID;
          }
          return {
            ...lineItem,
            lineItemType: lineItemType,
            lineItemId: lineItemId,
          };
        });
      project.site = {...site, lineItems: formattedLineItems ?? []};
      state[id] = project;
    },
  },
});

export const projectReducer = projectSlice.reducer;
export const {
  // updateProjectAddress,
  updateProjectCartItemIds,
  updateProjectDocuments,
  updateProjectInfo,
  // updateProjectMap,
  updateProjectNotes,
  updateProjectSite,
} = projectSlice.actions;
