import {useDispatch} from "react-redux";
import {getViewsDropdownOptions} from "../../../_shared/constants/viewsDropdownOptions";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import React from "react";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NatSizeType} from "../../../../_shared/generics/_shared";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../../../_shared/generics/popup/dropdown/constants";
import {WHITE} from "../../../../_shared/colors";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../_shared/hooks/useDesign/useActiveDesign";

export const CustomerViewsDropdown = () => {
  const {info} = useActiveProject();
  const {configuration} = useActiveDesign();
  const {to} = useNavigation();
  const dispatch = useDispatch();

  return (
    <NatDropdown
      style={{
        ...NatDropdownStyles[DropdownStyles.PRIMARY_ALT][NatSizeType.SMALL],
        dropdownButtonCSS: {
          padding: "0 0",
          minWidth: "max-content",
          color: WHITE,
          textDecoration: "none",
        },
      }}
      toggleButtonOrLabel={"Customer Views"}
      contentItemArray={getViewsDropdownOptions(
        configuration,
        info,
        dispatch,
        to
      )}
      trackingId={"admin-tab-options"}
    />
  );
};
