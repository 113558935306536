import styled from "styled-components";
import {IconPosition} from "../../../../../_shared/generics/_shared";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const SelectionItemContainer = styled.div<{
  selected: boolean;
  iconPosition?: IconPosition;
}>`
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  overflow: hidden;
  display: flex;
  gap: 0.5rem;
  font-family: ${(props) =>
    props.selected ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  flex-direction: ${(props) =>
    props?.iconPosition === undefined ||
    props?.iconPosition === IconPosition.LEFT
      ? "row"
      : props?.iconPosition === IconPosition.RIGHT
      ? "row-reverse"
      : props?.iconPosition === IconPosition.TOP
      ? "column"
      : "column-reverse"}
  justify-content: flex-start;
  &:hover {
    background-color: #82c27520;
    cursor: pointer;
  }
`;
