import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";
import {useFormik} from "formik";
import {NatButton} from "../../../../../_shared/generics/button";
import {saveOptionValueToDB} from "../../../../_shared/components/ProductOptions/ProductOptionHelper";
import {ProductOptionValue} from "../../../../_shared/constants/ProductOption";
import {ProductOptionValueStructure} from "../../../../_models/productOption";
import {
  FormInput,
  FormSelect,
  GenericInputContentContainer,
  InputTitle,
  NatStyledForm,
} from "../../../../_shared/styles/AdminStyles";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {OptionEditor} from "../styles";
import {
  convertDBOptionKeysToOptionsCurrentSelection,
  convertKeyOptionsToDBKeys,
  convertOptionKeysToOptions,
  convertOptionKeyToOptions,
  convertOptionsToOptionKey,
} from "./helpers";

export const OptionValueEditor = (props: {
  currentValue: ProductOptionValue | null;
  setCurrentValue: any;
}) => {
  const optionKeys = useSelector((state: IStore) => state.options.optionKeys);
  const {setCurrentValue, currentValue} = props;
  const initialValues = {};
  Object.keys(ProductOptionValueStructure).forEach((key) => {
    // @ts-ignore
    initialValues[key] = "";
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (currentValue != null) {
        // @ts-ignore
        const valueToSave: ProductOptionValue = Object.assign(
          {id: currentValue.id},
          values
        );
        valueToSave.optionKey = convertOptionsToOptionKey(
          valueToSave.optionKey
        );
        valueToSave.optionKeys = convertKeyOptionsToDBKeys(
          valueToSave.optionKeys
        ) as string;
        saveOptionValueToDB(valueToSave).then(() => setCurrentValue(null));
      }
    },
  });

  useEffect(() => {
    if (currentValue == null) {
      formik.resetForm();
    } else {
      for (const key in ProductOptionValueStructure) {
        // @ts-ignore
        const value = currentValue[key];
        if (value != null) {
          if (key === "optionKey") {
            formik.setFieldValue(
              key,
              convertOptionKeyToOptions(value, optionKeys)
            );
          } else if (key === "optionKeys") {
            formik.setFieldValue(
              key,
              convertDBOptionKeysToOptionsCurrentSelection(value, optionKeys)
            );
          } else {
            formik.setFieldValue(key, value);
          }
        } else {
          formik.setFieldValue(key, "");
        }
      }
    }
  }, [currentValue, optionKeys]);

  return (
    <OptionEditor>
      <NatStyledForm onSubmit={formik.handleSubmit}>
        {Object.keys(ProductOptionValueStructure).map((key) => {
          if (key === "optionKey") {
            return (
              <>
                <InputTitle bold={true} title={"Option Key"} />
                <GenericInputContentContainer>
                  <FormSelect
                    value={formik.values[key]}
                    onChange={(value: any) => {
                      formik.setFieldValue(key, [value]);
                    }}
                    options={convertOptionKeysToOptions(optionKeys)}
                  />
                </GenericInputContentContainer>
              </>
            );
          } else if (key === "optionKeys") {
            return (
              <>
                <InputTitle bold={true} title={"Option Keys"} />
                <GenericInputContentContainer>
                  <FormSelect
                    isMulti
                    value={formik.values[key]}
                    onChange={(value: any) => {
                      formik.setFieldValue(key, value);
                    }}
                    options={convertOptionKeysToOptions(optionKeys)}
                  />
                </GenericInputContentContainer>
              </>
            );
          }
          return (
            <>
              <InputTitle bold={true} title={key} />
              <GenericInputContentContainer>
                <FormInput
                  id={key}
                  name={key}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values[key]}
                />
              </GenericInputContentContainer>
            </>
          );
        })}

        <NatButton
          style={{marginTop: "0.5rem"}}
          option={StyleOption.PRIMARY_ALT}
          label={"Submit"}
          trackingId={"admin-product-option-value-submit"}
          type="submit"
        />
      </NatStyledForm>
    </OptionEditor>
  );
};
