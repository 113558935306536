import React from "react";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../_shared/generics/_shared";
import {NatTrashIcon} from "../../../../../../../../../../../_shared/icon/icons";
import {NatButton} from "../../../../../../../../../../../_shared/generics/button";

interface StudioEditorFieldDeleteButtonProps {
  clickEvent?: (value: any) => void;
}

const StudioEditorFieldDeleteButton = (
  props: StudioEditorFieldDeleteButtonProps
) => {
  const {clickEvent} = props;

  return (
    <NatButton
      disabled={!clickEvent}
      clickEvent={clickEvent}
      label={"edit"}
      option={StyleOption.DESTRUCTIVE}
      size={NatSize.SMALL}
      icon={{icon: <NatTrashIcon />, iconPosition: IconPosition.ONLY}}
    />
  );
};

export default StudioEditorFieldDeleteButton;
