import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import {HEADER_Z_INDEX} from "../../../_shared/styles";
import {BLACK, DIVIDER_COLOR, WHITE} from "../../../_shared/colors";

export const ReadOnlyFactoryInfoSetItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-width: calc(100% - 324px);
  margin-left: 312px;
  margin-right: 12px;
  padding-bottom: 32px;
`;

export const ReadOnlyFactoryInfoSetItemLinks = styled.div`
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  padding: 24px 0 100px 0;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: ${BLACK};
  overflow-y: auto;
  background-color: ${WHITE};
`;

export const ReadOnlyFactoryInfoSetItemLinksSearch = styled.div`
  position: fixed;
  top: 52px;
  left: 0;
  padding: 8px;
  height: 48px;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: ${BLACK};
  background-color: ${WHITE};
`;

export const ReadOnlyFactoryInfoSetInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  width: 300px;
  height: 52px;
  position: fixed;
  z-index: ${HEADER_Z_INDEX};
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: ${BLACK};
  top: 0;
  left: 0;
  overflow-y: auto;
  background-color: ${DIVIDER_COLOR};
`;

export const ReadOnlyFactoryInfoSetContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #a9b1b2;
`;

export const ReadOnlyFactoryInfoSetItemLink = styled.a`
  font-size: 12px;
  padding: 0;
  margin: 0 0 0 1rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  &:before {
    content: "- ";
  }
`;

export const ReadOnlyFactoryInfoSetCategoryLink = styled.a`
  font-size: 16px;
  font-family: ${FONT_FAMILY_BOLD};
  padding: 0;
  margin: 0 0 0 1rem;
`;
export const ReadOnlyFactoryInfoSetItemCategoryTitle = styled.h2`
  margin: 2rem 0 1rem 0;
  font-size: 3rem;
`;

export const ReadOnlyFactoryInfoSetFactoryTitle = styled.h1`
  font-size: 16px;
  padding: 0;
  margin: 0;
`;

export const ReadOnlyFactoryInfoSetFactoryInternalTitle = styled.h2`
  font-size: 12px;
  padding: 0;
  margin: 0;
`;
