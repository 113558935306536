import styled from "styled-components";
import {NatomasButton} from "../../../_shared/generics/button/styles";

export const AdminInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 25%;
`;

export const FormsContainer = styled.div<{direction: string}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2rem;
  ${NatomasButton} {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SectionHeader = styled.div`
  margin: 1.25rem 0;
  font-size: 1.25rem;
  width: 100%;
`;
