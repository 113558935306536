import React from "react";
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/700.css";
import {IEstimateProjectRange} from "../../../../api/Estimate/EstimateService";
import {PDFProposal} from "@natomas-org/core";
import TitlePage from "./TitlePage";
import PageFooter from "./PageFooter";
import BudgetSummaryPages from "./BudgetSummaryPages";
import SitePlansPage from "./SitePlans";
import QAAPage from "./QAAPage";
import SitePlanPageHeader from "./SitePlanHeader";
import {getTotalEstimateRange} from "../../../estimate/_shared/helper";
import {
  ESTIMATED_PROJECT_PRICE_LABEL,
  ESTIMATED_PROJECT_RANGE_LABEL,
} from "../../../estimate/CustomerEstimate/EstimateTableTotalRange";

const EstimateTemplate = (props: {
  projectRange: IEstimateProjectRange;
  projectProposal: PDFProposal.IProjectProposal;
}) => {
  const {summary} = props?.projectRange ?? {};
  const {
    firstName,
    lastName,
    products,
    address,
    company,
    generatedAt,
    tables,
    priceMode,
    imageBase64,
    qaa,
  } = props?.projectProposal ?? {};
  const footer = <PageFooter company={company} generatedAt={generatedAt} />;
  const altFooter = SitePlanPageHeader({generatedAt, company});
  const {result: projectRange, isRange} = getTotalEstimateRange(summary);
  return (
    <>
      <TitlePage
        firstName={firstName}
        address={address}
        lastName={lastName}
        units={products}
        footer={footer}
        projectRange={
          projectRange
            ? {
                value: projectRange,
                label: isRange
                  ? ESTIMATED_PROJECT_RANGE_LABEL
                  : ESTIMATED_PROJECT_PRICE_LABEL,
              }
            : undefined
        }
      />
      <BudgetSummaryPages
        tables={tables}
        priceMode={priceMode}
        footer={footer}
        nexus={true}
      />
      {imageBase64?.length > 0 && (
        <SitePlansPage imageBase64={imageBase64} getFooter={altFooter} />
      )}
      <QAAPage qaa={qaa} footer={footer} />
      {/*<FixedEndingPages imageBlobs={finalPageImages} footer={footer} />*/}
    </>
  );
};

export default EstimateTemplate;
