import {QuickInfoSectionTitle} from "../styles";
import React from "react";
import {InfoItem} from "../../../../../_shared/InfoItem";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";

export const AdvancedStatusesSection = () => {
  const {site} = useActiveProject();
  const {info} = useActiveProject();
  const {settings} = info ?? {};
  const {design_lock} = settings ?? {};
  return (
    <>
      <QuickInfoSectionTitle>Advanced Statuses</QuickInfoSectionTitle>
      <InfoItem
        label={"Design(s) Locked"}
        value={design_lock?.is_locked ? "Yes" : "No"}
        description={"Can the customer edit their design selections? (Yes/No)"}
      />
      <InfoItem
        label={"Costs Revealed"}
        value={site?.visible ? "Yes" : "No"}
        description={
          "Can the customer see the budget summary? (Yes/No)\n(Includes Phase 1 items, site work items, and additional line items.)"
        }
      />
    </>
  );
};
