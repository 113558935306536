import {QuickInfoSectionTitle} from "../styles";
import React, {useMemo} from "react";
import {getDateString} from "../../../../../../_shared/_helpers";
import {InfoItem} from "../../../../../_shared/InfoItem";
import {
  ADMIN_BUTTON_ACTIVE,
  DESTRUCTIVE_FILL,
} from "../../../../../../../_shared/colors";
import {Address, Project} from "@natomas-org/core";
import {navigateTo} from "../../../../../../../_shared/hooks/useNavigation/navigation";
import {formNewURL} from "../../../../../../../_shared/navigation/_helpers";
import {CATALOG_ROOT_PATH, STUDIO_PATH} from "../../../../../../../../paths";
import {useFactoryLine} from "../../../../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../../../_shared/hooks/useDesign/useActiveDesign";
import {BasicLocationMap} from "../../../../../../../_shared/generics/map/BasicLocationMap";

export const GeneralInfoSection = () => {
  const {info} = useActiveProject();
  const {configuration, snapshot} = useActiveDesign();

  const productLabel = useMemo(() => {
    return configuration?.product ? configuration?.product?.title : null;
  }, [configuration]);

  const productId = useMemo(() => {
    return configuration?.product?.id ?? null;
  }, [configuration]);

  const factoryLineId: string | undefined = useMemo(() => {
    return configuration?.product?.productGroupId ?? undefined;
  }, [configuration]);

  const {details} = useFactoryLine({factoryId: factoryLineId});

  const designLabel = useMemo(() => {
    return !!snapshot
      ? configuration?.product?.inventory_info
        ? "Yes - Inventory"
        : "Yes - Custom"
      : "No";
  }, [snapshot, configuration]);

  return (
    <>
      <QuickInfoSectionTitle>General Info</QuickInfoSectionTitle>
      <InfoItem
        label={"Project Address"}
        value={info ? Address.getFullAddress(Project.getAddress(info)) : ""}
      />
      <BasicLocationMap address={info?.address} />
      <InfoItem
        label={"Visibility Status"}
        value={configuration?.archived ? "Archived" : "Visible"}
        description={
          "Whether or not this project is visible to the customer.\n(Defaulted to visible.)"
        }
        customColor={configuration?.archived ? DESTRUCTIVE_FILL : undefined}
      />
      <InfoItem
        label={"Created"}
        value={getDateString(configuration?.created_date, true)}
        description={
          "Date that the project was initially created.\n(Usually the day the customer registered.)"
        }
      />
      <InfoItem
        label={"Last Updated"}
        value={getDateString(configuration?.last_update_date, true)}
        description={
          "Date that the project design selections were last changed.\n(Either by an admin, or the customer.)"
        }
      />
      <InfoItem
        label={"Factory Line Selected"}
        value={details?.title ?? "None"}
        customColor={
          configuration?.id && factoryLineId ? ADMIN_BUTTON_ACTIVE : undefined
        }
        clickEvent={
          configuration?.id && factoryLineId
            ? () => {
                const newUrl = formNewURL({
                  params: [
                    {key: "page", value: null},
                    {key: "cid", value: configuration?.id},
                    {key: "gid", value: factoryLineId},
                  ],
                  pathName: CATALOG_ROOT_PATH,
                });
                navigateTo(newUrl, {openInNewTab: true});
              }
            : undefined
        }
        description={
          "Details the factory line that has been selected for this project."
        }
      />
      <InfoItem
        label={"Product Selected"}
        value={productLabel ?? "None"}
        customColor={
          configuration?.id && factoryLineId && productId
            ? ADMIN_BUTTON_ACTIVE
            : undefined
        }
        clickEvent={
          configuration?.id && factoryLineId && productId
            ? () => {
                const newUrl = formNewURL({
                  params: [
                    {key: "page", value: null},
                    {key: "cid", value: configuration?.id},
                    {key: "gid", value: factoryLineId},
                    {key: "unit", value: productId},
                  ],
                  pathName: CATALOG_ROOT_PATH,
                });
                navigateTo(newUrl, {openInNewTab: true});
              }
            : undefined
        }
        description={
          "Details the unit that has been selected for this project."
        }
      />
      <InfoItem
        label={"Design Configured"}
        value={designLabel}
        customColor={
          configuration?.id && !!snapshot ? ADMIN_BUTTON_ACTIVE : undefined
        }
        clickEvent={
          configuration?.id && !!snapshot
            ? () => {
                const newUrl = formNewURL({
                  pathName: STUDIO_PATH,
                });
                navigateTo(newUrl, {openInNewTab: true});
              }
            : undefined
        }
        description={"Status for whether a design configuration exists."}
      />
    </>
  );
};
