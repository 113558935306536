import styled from "styled-components";
import {MODAL_Z_INDEX} from "../../../../../styles";
import {FONT_FAMILY_BOLD} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const PopupContainer = styled.div<{
  marginTop?: string;
  displayThin?: boolean;
  dropdownDirection?: string;
}>`
  position: absolute;
  margin-top: 0;
  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  z-index: 5;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: default;
  ${(props) =>
    props.dropdownDirection !== undefined && `${props.dropdownDirection}: 0`};
`;
export const NatomasPopupContent = styled.div<{
  marginTop?: string;
  displayThin?: boolean;
}>`
  background: #ffffff;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  z-index: 5;
  padding-bottom: ${(props) => (props.displayThin ? "0.5rem" : "1rem")};
`;

export const NatomasPopupClose = styled.div<{
  displayThin?: boolean;
}>`
  position: absolute;
  top: ${(props) => (props.displayThin ? "0.25rem" : "1rem")};
  right: 1rem;
  z-index: calc(${MODAL_Z_INDEX} + 100);
  cursor: pointer;
  svg {
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
    color: black;
    background-color: white;
    &:hover {
      color: gray;
    }
  }
`;
export const NatomasPopupContentContainer = styled.div<{
  animationSpeed: number;
  expanded: boolean;
  displayThin?: boolean;
  height?: string;
}>`
  overflow: hidden;
  transition-property: clip;
  ${(props) => `transition-duration:  ${props.animationSpeed * 0.77}ms`};
  ${(props) =>
    props.expanded
      ? "transition-delay: 0"
      : `transition-delay: ${props.animationSpeed * 0.23}ms`};
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  clip: ${(props) =>
    props.expanded
      ? `rect(auto, auto, ${props.height ?? "37rem"}, auto)`
      : "rect(auto, auto, 0rem, auto)"};
  position: absolute;
  width: 100%;
  max-height: fit-content;
`;
export const NatomasPopupTitleContainer = styled.div<{
  displayThin?: boolean;
  expanded?: boolean;
}>`
  font-family: ${FONT_FAMILY_BOLD};
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding-top: ${(props) => (props.displayThin ? "0.5rem" : "1rem")};
`;

export const NatomasPopupItemContainer = styled.div<{
  displayThin?: boolean;
}>`
  padding: ${(props) => (props.displayThin ? "0rem 0rem" : "1rem 0rem")};
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const NatomasPopupChevronContainer = styled.div<{
  animationSpeed: number;
  expanded: boolean;
}>`
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transform: ${(props) =>
      props.expanded ? "rotate(-90deg)" : "rotate(0deg)"};
    transition: ${(props) => `transform ${props.animationSpeed}ms ease-in-out`};
  }
`;
