import React, {useEffect, useMemo} from "react";
import {InventorySpacer} from "./styles";
import {INVENTORY_UNIT_PREFIX} from "../../../_shared/constants/labels";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {ProductList} from "../../_shared/components/ProductList";
import {checkForExactFilter} from "../../../_shared/catalog/filter/logic";
import {ICatalogFilter} from "../../../_shared/catalog/filter/types";
import {
  addInventoryUnitFilter,
  inventoryUnitFilter,
} from "../../_shared/components/Filters/ProductCategoryFilter/helper";
import {ProductView} from "../ProductView";
import {isDefaultHidden} from "../../_shared/components/Filters/helpers";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {filterProductsByBedroom} from "../../_shared/components/ProductCard/helpers";
import {useProgressBar} from "../../../_shared/hooks/useProgressBar";
import {useCatalogSession} from "../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useSelectedProduct} from "../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import {useCatalogProducts} from "../../../_shared/hooks/useProductCatalog/useProducts";
import {PROGRESS_BAR_HEIGHT} from "../../../_shared/generics/progress-bar/ProgressBar";
import {MainCatalogBar} from "../../_shared/components/CatalogBar/MainCatalogBar";
import {NoHomesResetFiltersCTA} from "../MainView/components/NoHomesResetFiltersCTA";

export const InventoryView = () => {
  const product = useSelectedProduct();
  const filteredListedProducts = useCatalogProducts();
  const {filters} = useCatalogSession();
  useEffect(() => {
    if (
      !checkForExactFilter(
        inventoryUnitFilter.type,
        inventoryUnitFilter.label,
        filters
      )
    ) {
      addInventoryUnitFilter();
    }
  }, [filters]);

  const titleSize = useDynamicValue({
    forFour: "3rem",
    forEight: "4rem",
    forTwelve: "5rem",
    forSixteen: "6rem",
  });

  const subtitleSize = useDynamicValue({
    forFour: "0.875rem",
    forEight: "0.875rem",
    forTwelve: "1rem",
    forSixteen: "1rem",
  });

  const {showProgressBar} = useProgressBar();
  const getOffset = () => {
    let count = 103;
    if (showProgressBar) {
      count += PROGRESS_BAR_HEIGHT;
    }
    return count + "px";
  };

  const oneBedroomProducts: any = useMemo(() => {
    if (filteredListedProducts)
      return filterProductsByBedroom(filteredListedProducts, 1);
    else return [];
  }, [filteredListedProducts]);

  const twoBedroomProducts: any = useMemo(() => {
    if (filteredListedProducts)
      return filterProductsByBedroom(filteredListedProducts, 2);
    else return [];
  }, [filteredListedProducts]);

  const threeBedroomProducts: any = useMemo(() => {
    if (filteredListedProducts)
      return filterProductsByBedroom(filteredListedProducts, 3);
    else return [];
  }, [filteredListedProducts]);

  const getTitle = (prefix: string) => {
    return prefix + " " + INVENTORY_UNIT_PREFIX + "s";
  };

  if (product) {
    return <ProductView />;
  }

  const getProductCards = () => {
    if (
      filters.filter((f: ICatalogFilter) => !isDefaultHidden(f)).length !== 0
    ) {
      return (
        <>
          <ProductList products={filteredListedProducts} />
        </>
      );
    } else {
      return (
        <>
          <ProductList
            title={getTitle("One bedroom")}
            products={oneBedroomProducts}
          />
          <ProductList
            title={getTitle("Two bedroom")}
            products={twoBedroomProducts}
          />
          <ProductList
            title={getTitle("Three bedroom")}
            products={threeBedroomProducts}
          />
        </>
      );
    }
  };

  return (
    <>
      <InventorySpacer />
      <MainCatalogBar overrides={{offset: getOffset()}} />
      <InventorySpacer />
      <PageContainer>
        <NoHomesResetFiltersCTA />
        {getProductCards()}
      </PageContainer>
    </>
  );
};

// const InventoryBannerDisplay = (props: {
//   titleSize: string;
//   subtitleSize: string;
// }) => {
//   return (
//     <InventoryBanner>
//       <InventoryBannerTitle size={props.titleSize}>
//         {INVENTORY_UNIT_PREFIX}
//       </InventoryBannerTitle>
//       <InventoryBannerSubTitle size={props.subtitleSize}>
//         {INVENTORY_UNIT_DESCRIPTION}
//       </InventoryBannerSubTitle>
//     </InventoryBanner>
//   );
// };
