import React from "react";

export const HiddenInput = (props) => {
	//Add values needed for input in the associated form
	const {inputType, inputName, inputValue} = props;

	return (
		<input
			type={inputType}
			name={inputName}
			value={inputValue == null ? "" : inputValue}
			readOnly={true}
			className="hidden"
		/>
	);
};
