import React from "react";
import {toPage} from "../navigation/PageNavigation";
import {HOME_PAGE_ID} from "../navigation/constants";
import {StyleOption} from "../../../_shared/generics/_shared";
import {NatModal, NatModalSize} from "../../../_shared/generics/modal/NatModal";
import {ProjectSelectionCard} from "./ProjectSelectionCard";
import {ProjectsWrapper} from "./ProjectSelectionCard/styles";
import useCustomerProjects from "../../../_shared/hooks/useProject/useCustomerProjects";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import useActiveCustomer from "../../../_shared/hooks/useCustomer/useActiveCustomer";
import useActivitySliceDispatcher from "../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";

export const ProjectSwitcherModal = (props: {
  show: boolean;
  handleClose: () => void;
}) => {
  const {handleClose} = props;
  const {id: uid} = useActiveCustomer();
  const {id: pid} = useActiveProject();
  const {projectIds} = useCustomerProjects(uid);
  const {setActiveProjectById} = useActivitySliceDispatcher();

  return (
    <NatModal
      {...props}
      size={NatModalSize.FULLSCREEN}
      header={"Which project would you like to view?"}
      content={
        <ProjectsWrapper>
          {projectIds?.map((id: string) => {
            return (
              <ProjectSelectionCard
                projectId={id}
                key={id}
                handleClick={() => {
                  if (id === pid) {
                    handleClose();
                  } else {
                    setActiveProjectById(id);
                    handleClose();
                    toPage(HOME_PAGE_ID);
                  }
                }}
                active={id === pid}
              />
            );
          })}
        </ProjectsWrapper>
      }
      footerButtons={[
        {
          option: StyleOption.SECONDARY,
          clickEvent: handleClose,
          style: {
            margin: "auto",
            minWidth: "150px",
          },
          label: "Dismiss",
        },
      ]}
    />
  );
};
