import React, {useMemo, useState} from "react";
import {TabSelectionContainer, TabSelector} from "./styles";
import {IFactoryInventoryStudioEditorModifier} from "../../ModifierSection/interface";
import ModifierBasicInfo from "./ModifierBasicInfo";
import ModifierAdvancedInfo from "./AdvancedProperties/ModifierAdvancedInfo";

interface ModifierTabContainerProps {
  id: string;
  updateEdits: any;
  initialValue?: IFactoryInventoryStudioEditorModifier;
  edits?: IFactoryInventoryStudioEditorModifier;
}

const tabs: string[] = ["Basic", "Advanced"];

const ModifierTabContainer = (props: ModifierTabContainerProps) => {
  const {id, initialValue, updateEdits, edits} = props;
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const content = useMemo(() => {
    if (selectedTab === "Basic") {
      return (
        <ModifierBasicInfo
          id={id}
          updateEdits={updateEdits}
          initialValue={initialValue}
          edits={edits}
        />
      );
    } else {
      return (
        <ModifierAdvancedInfo
          id={id}
          updateEdits={updateEdits}
          initialValue={initialValue}
          edits={edits}
        />
      );
    }
  }, [edits, id, initialValue, selectedTab, updateEdits]);

  return (
    <div>
      <TabSelectionContainer>
        {tabs?.map((tab) => {
          return (
            <TabSelector
              isSelected={tab === selectedTab}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </TabSelector>
          );
        })}
      </TabSelectionContainer>
      {content}
    </div>
  );
};

export default ModifierTabContainer;
