import {
  NatCatalogIcon,
  NatCompletedProjectsIcon,
  NatMapIcon,
  NatProjectIcon,
} from "../../../icon/icons";
import {IconType} from "react-icons";
import {Address, IProjectInfo} from "@natomas-org/core";

export enum AppSwitcherValues {
  COMPLETED_PROJECTS = "completedProjects",
  PROPERTY_MAP = "mappingTool",
  CATALOG = "catalog",
  PROJECT = "project",
  DESIGN = "design",
  UNKNOWN = "unknown",
}

export interface IAppInfo {
  title: string;
  description: string;
  type: AppSwitcherValues;
  icon: IconType;
}

export const PropertyMapAppInfo: IAppInfo = {
  title: "Property map",
  description: "Fit an ADU seamlessly on your property",
  type: AppSwitcherValues.PROPERTY_MAP,
  icon: NatMapIcon,
};

export const CompletedProjectsAppInfo: IAppInfo = {
  title: "Completed homes",
  description: "See completed Villa homes in your area",
  type: AppSwitcherValues.COMPLETED_PROJECTS,
  icon: NatCompletedProjectsIcon,
};

export const CatalogStudioAppInfo: IAppInfo = {
  title: "Our Homes",
  description: "Select and customize an ADU",
  type: AppSwitcherValues.CATALOG,
  icon: NatCatalogIcon,
};

export const ProjectAppInfo: IAppInfo = {
  title: "My Project",
  description: "View and modify active Villa project",
  type: AppSwitcherValues.PROJECT,
  icon: NatProjectIcon,
};

const PUBLIC_APP_SWITCHER = [
  PropertyMapAppInfo,
  CompletedProjectsAppInfo,
  CatalogStudioAppInfo,
];

export const getAppSwitcherValues = (
  customer: boolean,
  isCustomerPredeposit: boolean | undefined,
  projectSummary: IProjectInfo | null | undefined
): IAppInfo[] => {
  if (customer) {
    let projectAppInfo = ProjectAppInfo;
    if (projectSummary) {
      projectAppInfo.title = Address.getStreetNumberAndStreet(
        projectSummary.address
      );
    }
    if (isCustomerPredeposit) {
      return [
        projectAppInfo,
        PropertyMapAppInfo,
        CompletedProjectsAppInfo,
        CatalogStudioAppInfo,
      ];
    }
    return [projectAppInfo, PropertyMapAppInfo, CompletedProjectsAppInfo];
  } else {
    return PUBLIC_APP_SWITCHER;
  }
};
