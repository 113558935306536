import React from "react";
import {IProductGroupDetails} from "@natomas-org/core";
import {ProductLineViewBanner} from "../../../../_shared/components/Banner";
import {getBadgesFromCatalogTags} from "../../../../_shared/components/CatalogBadge/helper";
import {getProductionTimelineInfo} from "../../../../../portal/views/ProposalView/constants/timelineHelpers";
import {VILLA_LILAC, VILLA_MINT} from "../../../../../_shared/colors";

interface SeriesBannerProps {
  numberOfProducts?: number;
  details?: IProductGroupDetails;
}

const SeriesBanner = (props: SeriesBannerProps) => {
  const {numberOfProducts, details} = props;
  if (details) {
    return (
      <ProductLineViewBanner
        imageId={details?.imageId}
        title={details?.title}
        description={details?.displayDescription}
        badges={[
          {
            label: `${numberOfProducts} Floor Plans`,
            color: VILLA_LILAC,
          },
          {
            label: `${
              getProductionTimelineInfo(details).totalMonths
            } Month Timeline`,
            color: VILLA_MINT,
          },
          ...getBadgesFromCatalogTags(details?.tags ?? []),
        ]}
      />
    );
  } else {
    return null;
  }
};

export default SeriesBanner;
