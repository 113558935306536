import {isBlankString, Product} from "@natomas-org/core";
import {getParameter} from "../../cookies";

export const getUnitFromURL = () => {
  return getParameter("unit");
};

export const getProductGroupFromURL = () => {
  return getParameter("gid");
};

export const getBedBathString = (product, styling) => {
  if (product != null) {
    const {bedrooms, bathrooms} = product?.productDetails;
    const bedString = bedrooms > 1 ? "beds" : "bed";
    const bathString = bathrooms > 1 ? "baths" : "bath";
    let seperator = ", ";
    if (styling === "line") {
      seperator = "  |  ";
    }
    return `${bedrooms} ${bedString}${seperator}${bathrooms} ${bathString}`;
  }
  return "";
};

export const getSqFtString = (product) => {
  if (product != null) {
    const squareFeet = product?.productDetails?.squareFeet ?? "Unknown";
    return squareFeet + " sq. ft.";
  }
  return "";
};

export const getLengthAndWidthString = (product) => {
  if (
    product != null &&
    product.productDetails != null &&
    product.productDetails.dimensions != null
  ) {
    const dimensions = product.productDetails.dimensions;
    return (
      getLengthFromDimension(dimensions.frontLength) +
      " x " +
      getLengthFromDimension(dimensions.sideLength)
    );
  }
  return "";
};

export const getLengthAndWidthStringFromDimensions = (dimensions) => {
  if (!!dimensions) {
    return (
      getLengthFromDimension(dimensions?.frontLength) +
      " x " +
      getLengthFromDimension(dimensions?.sideLength)
    );
  }
  return "";
};

export const getLengthFromDimension = (dimension) => {
  if (dimension == null) {
    return "";
  }

  let feetString = "" + dimension.feet + "'";
  let inchesString = "";
  if (dimension.inches > 0) {
    inchesString = " " + dimension.inches + '"';
  }
  return feetString + inchesString;
};

export const getDescriptionForModifier = (modifier) => {
  if (!isBlankString(modifier.displayDescription)) {
    return modifier.displayDescription;
  } else if (!isBlankString(modifier.displayDescriptionDefault)) {
    return modifier.displayDescriptionDefault;
  } else if (!isBlankString(modifier.prettyDescription)) {
    return modifier.prettyDescription;
  }
  return "";
};

export const getBasePriceForDesignStudio = (product) => {
  if (product == null) {
    return 0;
  }
  return Product.getUnitPrice(product, {excludeInventoryUpgrades: true});
};

export const getProductPricingInfo = (pricingMode, product) => {
  let price = Product.getUnitPrice(product);
  let productCategory = !!product?.inventory_info ? "inventory" : "model";
  return {category: productCategory, price: price};
};
