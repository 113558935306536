import {useSelector} from "react-redux";
import {ICartItem} from "@natomas-org/core";
import {IStore} from "../../../slices/types/Store";

console.log(
  "If the result of useCartItems is used in a useEffect, it must be useDeepEffect instead."
);

export const useCartItems = (ids: string[]): ICartItem[] => {
  return useSelector((state: IStore) => {
    if (!ids ?? ids?.length === 0) {
      return [];
    } else {
      let items: ICartItem[] = [];
      ids.forEach((id) => {
        const item = state.cart[id];
        if (item) {
          items.push(item);
        }
      });
      return items;
    }
  }) as ICartItem[];
};
