import React from "react";
import {useAdminProductCatalog} from "../../../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {AdminProductCatalogMatrixSubModes} from "../../../../../../_shared/slices/types/Store";
import {MicroSwitch} from "../../../../../../_shared/generics/switch/microSwitch";
import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../portal/views/SSDDashboardView/styles/globals";
import {ADMIN_BUTTON_ACTIVE, BLACK} from "../../../../../../_shared/colors";

export const MatrixSubModeLabel = styled.div<{selected: boolean}>`
  font-size: 0.85rem;
  font-family: ${(props) =>
    props.selected ? FONT_FAMILY_MEDIUM : FONT_FAMILY_REGULAR};
  color: ${(props) => (props.selected ? ADMIN_BUTTON_ACTIVE : BLACK)};
`;

export const MatrixSubModeContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;

export const MatrixSubModeSelector = () => {
  const {matrixSubMode, updateAdminProCatMatrixSubMode} =
    useAdminProductCatalog();
  const isPrice = matrixSubMode === AdminProductCatalogMatrixSubModes.PRICE;
  return (
    <MatrixSubModeContainer>
      <MatrixSubModeLabel selected={isPrice}>Price</MatrixSubModeLabel>
      <MicroSwitch
        key={"matrix-sub-mode-switch"}
        isChecked={!isPrice}
        setChecked={() => {
          if (isPrice) {
            updateAdminProCatMatrixSubMode(
              AdminProductCatalogMatrixSubModes.COST
            );
          } else {
            updateAdminProCatMatrixSubMode(
              AdminProductCatalogMatrixSubModes.PRICE
            );
          }
        }}
      />
      <MatrixSubModeLabel selected={!isPrice}>Cost</MatrixSubModeLabel>
    </MatrixSubModeContainer>
  );
};
