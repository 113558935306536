import React, {useMemo} from "react";
import {StudioImageManagerContainer} from "./styles";
import {StudioCarousel} from "../StudioCarousel";

export interface StudioImageManagerProps {
  height: string;
  isMobile: boolean;
  product: any;
  step?: number;
  categories?: any;
  disclaimer?: any;
}
export const StudioImageManager = (props: StudioImageManagerProps) => {
  const {height, isMobile, categories, step, product} = props;

  let animate = false;

  const images = useMemo(() => {
    let imgs = [];
    if (!categories || step == null) {
      return [];
    }
    if (step === categories?.length) {
      // Get all images
      animate = true;
      const all = categories?.map((c: any) => c?.activeImages).flat();
      imgs = [
        ...all,
        {
          imageId: product?.imageId,
          caption: product?.title,
        },
      ];
    } else {
      const category = categories?.[step];
      imgs = category?.activeImages;
    }
    if (imgs?.length === 0) {
      return [{imageId: product?.imageId, caption: product?.title}];
    }
    return imgs;
  }, [categories, step, product]);
  return useMemo(() => {
    return (
      <StudioImageManagerContainer
        height={height}
        stickied={isMobile}
        className="rendered-image-wrapper"
      >
        {props.disclaimer}
        <StudioCarousel
          images={images}
          animate={animate}
          mobileVersion={isMobile}
        />
      </StudioImageManagerContainer>
    );
  }, [images, animate, isMobile, height]);
};
