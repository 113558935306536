import styled from "styled-components";
import {NatStyle} from "../../styles";
import {calculateBorder, INatStyle} from "../_shared";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";

export const NatomasToggle = styled.button<INatStyle>`
  ${NatStyle};
  border: ${(props) => calculateBorder(props.initialBorderColor)};
  border-radius: 3rem;
  font-family:${(props) => props.enabled && FONT_FAMILY_BOLD}; ;
  &:hover {
    border: ${(props) => calculateBorder(props.hoverBorderColor)};
  }
    border: ${(props) =>
      props.enabled
        ? calculateBorder(props.enabledBorderColor)
        : calculateBorder(props.initialBorderColor)};
    background-color: ${(props) =>
      props.enabled
        ? props.enabledBackgroundColor
        : props.initialBackgroundColor};
  }
`;
