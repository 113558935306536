import {store} from "../../../../store";
import {IProduct, Product} from "@natomas-org/core";

export interface InventoryUnitCardDetails {
  upgradePriceInMicros: number;
  deliveryTimeInMonths: number;
}

export const getInventoryProductionTimelineTotal = (
  product?: IProduct
): number => {
  const {INVENTORY_PROGRAM} = store.getState().global.config;

  if (product?.timeline && product?.timeline?.length > 0) {
    return product?.timeline?.reduce((n, {months}) => n + months, 0);
  }
  return INVENTORY_PROGRAM?.DELIVERY_MONTHS;
};

export const getInventoryUnitCardDetails = (
  product: IProduct
): InventoryUnitCardDetails | undefined => {
  if (!!product.inventory_info) {
    return {
      upgradePriceInMicros:
        Product.getInventoryUnitUpgradePriceInMicros(product),
      deliveryTimeInMonths: getInventoryProductionTimelineTotal(product),
    };
  } else {
    return undefined;
  }
};
