import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {CartProposalSummaryContainer} from "./styles";
import CartSectionHeader from "../CartSection/CartSectionHeader";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../_shared/generics/label/NatLabel";
import {BasicLocationMap} from "../../../../../_shared/generics/map/BasicLocationMap";
import {NatFlex} from "../../../../../_shared/generics/flex/NatFlex";
import {NatVerticalAlign} from "../../../../../_shared/generics/_shared";
import {BLACK, GRANITE} from "../../../../../_shared/colors";
import {
  Address,
  getUnitBasePrice,
  getUnitUpgradePrice,
  priceTextFromMicros,
} from "@natomas-org/core";
import React, {useMemo} from "react";
import {useCartItems} from "../../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import {useDesigns} from "../../../../../_shared/hooks/useDesign/useDesigns";

const CartProposalSummarySection = () => {
  const {address} = useActiveProject();
  const {cartItemIds} = useActiveProject();
  const cartItems = useCartItems(cartItemIds);
  const designs = useDesigns(cartItemIds);
  const {allUnitsTotalPreTax, totalUnitQuantity} = useMemo(() => {
    let microsToReturn = 0;
    let totalQuantity = 0;
    cartItems?.forEach((cartItem) => {
      const design = designs[cartItem?.id];
      const unitUpgradePrice = getUnitUpgradePrice(design?.configuration, {
        addSalesTax: true,
      });
      const unitBasePrice = getUnitBasePrice(design?.configuration, {
        addSalesTax: true,
      });
      // const unitAdditionalLineItemsPrice = getUnitAdditionalLineItemPrice(
      //   design?.configuration,
      //   {
      //     excludeDiscountLineItems: false,
      //   }
      // );
      const quantity = cartItem?.quantity;
      microsToReturn += (unitBasePrice?.price ?? 0) * quantity;
      microsToReturn += (unitUpgradePrice?.price ?? 0) * quantity;
      // microsToReturn += (unitAdditionalLineItemsPrice?.price ?? 0) * quantity;
      totalQuantity += quantity;
    });
    return {
      allUnitsTotalPreTax: microsToReturn,
      totalUnitQuantity: totalQuantity,
    };
  }, [cartItems, designs]);
  if (allUnitsTotalPreTax) {
    return (
      <CartProposalSummaryContainer>
        <CartSectionHeader label={"Proposal Summary"} />
        <NatLabel
          label={Address.getFullAddress(address ?? undefined)}
          type={NatLabelType.P4}
        />
        <BasicLocationMap
          address={address}
          style={{height: "300px", width: "100%"}}
        />
        <NatFlex FULL_WIDTH NOGAP NOWRAP COLUMN>
          <NatFlex
            ROW
            NOGAP
            VERTICAL_ALIGN={NatVerticalAlign.BOTTOM}
            FULL_WIDTH
          >
            <NatLabel
              label={"Total unit price:"}
              type={NatLabelType.P2}
              style={{margin: "0 0.5rem 0 0"}}
            />
            <NatLabel
              label={`${priceTextFromMicros(allUnitsTotalPreTax, "accurate")}*`}
              type={NatLabelType.P1}
              weight={NatLabelWeight.BOLD}
              style={{margin: "0 0"}}
            />
          </NatFlex>
          <NatLabel
            label={`(${totalUnitQuantity} units)`}
            type={NatLabelType.P3}
            style={{color: GRANITE, margin: "0 0", paddingTop: "0.15rem"}}
          />
          <NatLabel
            label={`*Price only includes unit, and upgrade price(s). Any other item(s), or applicable discount(s) will be noted in the budget summary.`}
            type={NatLabelType.P3}
            style={{color: BLACK, margin: "0 0", paddingTop: "0.15rem"}}
          />
        </NatFlex>
      </CartProposalSummaryContainer>
    );
  } else {
    return null;
  }
};

export default CartProposalSummarySection;
