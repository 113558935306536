import React from "react";
import {FONT_FAMILY_BOLD} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import styled from "styled-components";
import {PRIMARY_LINK_HOVER} from "../../../../colors";

export const QuizContextTitle: React.FC<{
  title: string | undefined;
  preference: string;
  clickEvent: () => void;
}> = ({title, clickEvent, preference}) => {
  let contextString = "";
  if (preference) {
    contextString = `Great choices! Based on your preference for ${preference}, we recommend `;
  }
  return (
    <span>
      {contextString}
      <StyledSpanPrimaryLink onClick={clickEvent}>
        {title}
      </StyledSpanPrimaryLink>
      .
    </span>
  );
};

export const StyledSpanPrimaryLink = styled.span`
  font-family: ${FONT_FAMILY_BOLD};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${PRIMARY_LINK_HOVER};
    text-decoration: underline;
  }
`;
