import {AdminComponentFieldInfoProps} from "../../../../../_shared/components/AdminComponentFactory/AdminInputProps";
import {IProjectStage} from "@natomas-org/core";

export const PRIMARY_CONTACT_EMAIL_KEY = "primary_contact_email";
export const COMPLEX_PRICING_KEY = "complex_pricing_enabled";
export const LOCK_DESIGN_KEY = "lock_design";
export const UNIT_PRICING_KEY = "unit_pricing";
export const CART_ENABLED_KEY = "cart_enabled";
export const PROJECT_STAGE_KEY = "stage";

export const ProjectStructure: {
  [key: string]: {
    name: string;
    type: string;
    index: number;
    description?: string;
  };
} = {
  [CART_ENABLED_KEY]: {
    name: "Cart Enabled",
    type: "yes-no",
    index: 1,
  },
  [LOCK_DESIGN_KEY]: {
    name: "Lock Design",
    type: "yes-no",
    index: 2,
  },
  [COMPLEX_PRICING_KEY]: {
    name: "Enable Complex Project Pricing",
    description:
      "Toggling on complex project pricing allows for you to attach an addendum to the PSA.",
    type: "yes-no",
    index: 4,
  },
};

const PROJECT_STAGES = [
  {label: "Lead", value: IProjectStage.PRE_DEPOSIT},
  {label: "Opportunity", value: IProjectStage.PRE_PSA},
  {label: "Design & Engineering", value: IProjectStage.PRE_ATB},
  {label: "Permitting", value: IProjectStage.PERMITTING},
  {label: "Authorization to Build", value: IProjectStage.ATB},
];

export const PROJECT_SETTINGS_ADMIN_COMPONENTS: {
  [key: string]: AdminComponentFieldInfoProps;
} = {
  [PROJECT_STAGE_KEY]: {
    key: PROJECT_STAGE_KEY,
    name: "Project Stage",
    index: 1,
    type: "dropdown-array",
    subType: "single",
    options: PROJECT_STAGES,
    defaultValue: "",
    alwaysEnabled: true,
    valueSanitizer: (value: any) => {
      return [value];
    },
    onChangeSanitizer: (value: string[]) => {
      return value[0];
    },
  },
};
