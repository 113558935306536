import React, {Dispatch, SetStateAction, useState} from "react";
import {NatContentListProps} from "../../components/NatPopup/NatPopup";
import {NatDropdownProps} from "../../constants";
import {ContentElementFromList} from "./components/ContentElementFromList";

export const useNatPopup = (
  popupContent: JSX.Element | NatContentListProps | NatDropdownProps
): {
  popup: JSX.Element;
  modal: JSX.Element | null;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
} => {
  let popup = popupContent;
  let modal: JSX.Element | null = null;
  const [localShow, localSetShow] = useState(false);
  let show = localShow;
  let setShow = localSetShow;
  if ("contentItemArray" in popupContent) {
    if ("show" in popupContent && !!popupContent?.show) {
      show = popupContent?.show;
    }
    if (
      "setShow" in popupContent &&
      !!(popupContent as NatDropdownProps)?.setShow
    ) {
      const newSetShow = (popupContent as NatDropdownProps)?.setShow;
      if (newSetShow) {
        setShow = newSetShow;
      }
    }
    popup = (
      <ContentElementFromList
        listProps={{
          ...popupContent,
          show: show,
          handleClose: () => {
            if ("handleClose" in popupContent && !!popupContent?.handleClose) {
              popupContent.handleClose();
            }
            setShow(false);
          },
        }}
        getModal={false}
      />
    );
    modal = (
      <ContentElementFromList
        listProps={{
          ...popupContent,
          show: show,
          handleClose: () => {
            if ("handleClose" in popupContent && !!popupContent?.handleClose) {
              popupContent.handleClose();
            }
            setShow(false);
          },
        }}
        getModal={true}
      />
    );
  }
  return {
    popup: popup as unknown as JSX.Element,
    modal: modal,
    setShow: setShow,
    show: show,
  };
};
