import {
  Address,
  CustomerProjectInfo,
  IConfiguration,
  ImageCategory,
  IProduct,
  IProductImage,
  Product,
} from "@natomas-org/core";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {usePage} from "../../../../_shared/hooks/usePage";
import {Carousel} from "react-bootstrap";
import {SmartImage} from "../../../../_shared/generics/image/SmartImage";
import {PRODUCT_HERO_URL} from "../../../constants";
import {
  ProductSummaryContainer,
  ProductSummaryTextSubtitle,
  ProductSummaryTextTitle,
  ProductSummaryWrapper,
  ProjectSelectionCarouselContainer,
  ProjectSummaryImageWrapper,
} from "../styles";
import React from "react";

export const ProjectSelectionCarousel = (props: {
  handleSelect: (index: number, e: any) => void;
  projectArray:
    | {
        project: CustomerProjectInfo;
        configuration: IConfiguration;
      }[]
    | null;
}) => {
  const imageWidth = useDynamicValue({
    forFour: 20,
    forEight: 20,
    forTwelve: 20,
    forSixteen: 20,
  });
  const {handleSelect, projectArray} = props;
  const {isNatMobile} = usePage();
  const hideCarouselControlsAndIndicators =
    !projectArray || projectArray?.length < 2;
  return (
    <ProjectSelectionCarouselContainer>
      <Carousel
        onSlide={handleSelect}
        className={"project-selector"}
        controls={!isNatMobile && !hideCarouselControlsAndIndicators}
        indicators={!hideCarouselControlsAndIndicators}
      >
        {projectArray?.map((projectInfo, idx) => {
          let imageId: undefined | string = undefined;
          let smartImageToDisplay = <SmartImage imageURL={PRODUCT_HERO_URL} />;
          let product: IProduct | null =
            projectInfo?.configuration?.product ?? null;
          // product = null;
          let heroImages: IProductImage[] | null = null;
          if (product) {
            heroImages = Product.getImageDetailsByCategory(
              product,
              ImageCategory.HERO
            );
          }
          if (heroImages && heroImages?.length > 0) {
            imageId = heroImages[0].imageId;
          } else {
            const exteriorImages: IProductImage[] | null = product
              ? Product.getImageDetailsByCategory(
                  product,
                  ImageCategory.EXTERIOR
                )
              : null;
            if (exteriorImages && exteriorImages?.length > 0) {
              imageId = exteriorImages[0].imageId;
            }
          }
          if (imageId) {
            smartImageToDisplay = <SmartImage imageId={imageId} />;
          }

          return (
            <Carousel.Item key={`${idx}${projectInfo?.configuration?.id}`}>
              <ProductSummaryWrapper isNatMobile={isNatMobile}>
                <ProductSummaryContainer>
                  <ProductSummaryTextTitle>
                    {product ? product?.title : "No unit selected"}
                  </ProductSummaryTextTitle>
                  <ProductInfo product={product} />
                  <ProjectSummaryImageWrapper
                    widthRem={imageWidth}
                    heightRem={imageWidth * 0.5}
                  >
                    {smartImageToDisplay}
                  </ProjectSummaryImageWrapper>
                  <ProductSummaryTextSubtitle isMobile={isNatMobile}>
                    {Address.getFullAddress(
                      Address.dataToAddress(
                        projectInfo?.project?.project_address
                      )
                    )}
                  </ProductSummaryTextSubtitle>
                </ProductSummaryContainer>
              </ProductSummaryWrapper>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </ProjectSelectionCarouselContainer>
  );
};
const ProductInfo = (props: {product: IProduct | null | undefined}) => {
  const {product} = props;
  if (!product) {
    return null;
  }
  return (
    <ProductSummaryTextSubtitle>
      {Product.getBedBathString(product, "line")}
      {" | "}
      {Product.getSqFtString(product)}
    </ProductSummaryTextSubtitle>
  );
};
