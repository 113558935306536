import React from "react";
import {
  ImageQuality,
  SmartImage,
} from "../../../../_shared/generics/image/SmartImage";
import {HoverOverImage, ProductCardImage, ProductContents} from "./styles";
import {
  getKStringFromMicros,
  ICatalogTag,
  IInstantEstimateData,
  ImageCategory,
  IProduct,
  IProductGroupDetails,
  IProductParcelFitData,
  ListingStatus,
  ListingStatusDetails,
  Product,
  ProductGroup,
  ProjectPriceMode,
} from "@natomas-org/core";
import {getWidth, usePage} from "../../../../_shared/hooks/usePage";
import {getInventoryProductionTimelineTotal} from "../../utilities";
import {
  JUST_UNIT_COST_DESCRIPTOR,
  JUST_UNIT_PRICE_DESCRIPTOR,
  READYBUILT_UPGRADE_TEXT,
  UPGRADES_AVAILABLE,
  UPGRADES_LIMITED,
} from "../TotalEstimateToggle/constants";
import {useFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {NatFlex} from "../../../../_shared/generics/flex/NatFlex";
import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {
  NatHorizontalAlign,
  NatVerticalAlign,
} from "../../../../_shared/generics/_shared";
import {setLeafletMapUnitToPreview} from "../../../../map/slices/mapSlice";
import {store} from "../../../../../store";
import {throttleCallback} from "../../../../_shared/Utils";
import {ADMIN_BLUE} from "../../../../_shared/colors";
import {a, useSpring} from "@react-spring/web";
import {PercentMatchTag} from "./components/PercentMatchTag";
import {
  FITS_ON_LOT_TAG_TEXT,
  INVENTORY_UNIT_TAG_TEXT,
} from "../../../../_shared/constants/labels";
import {InventoryTag} from "./components/InventoryTag";
import {FitsOnLotTag} from "./components/FitsOnLotTag";
import {useSelector} from "react-redux";
import {IStore} from "../../../../_shared/slices/types/Store";
import {useCatalogSessionDispatcher} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";
import {getProductionTimelineInfo} from "../../../../portal/views/ProposalView/constants/timelineHelpers";

type ExtendedCardValues = CardValues & {additionalLines: string[] | null};
export type CardValues = {
  title: string;
  priceString: string;
  priceSubtext: string;
  subtextLine1: string | null;
  subtextLine2: string;
  primaryImageId: string | null;
  hoverImageId: string | null;
  clickAction: () => void;
  labels: (string | number)[] | null; // Holds the label text
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export function getCardValuesForProduct<T extends IProduct>(
  {...product}: T,
  pricingMode: ProjectPriceMode,
  factoryDetails: IProductGroupDetails | undefined,
  isEligible?: boolean,
  fitsOnLotData?: IProductParcelFitData,
  instantEstimateData?: IInstantEstimateData,
  setProductPreviewOnHover?: boolean,
  isCustomerPreDeposit?: boolean,
  percentRecommendation?: number,
  clickAction?: () => void
): ExtendedCardValues {
  let labels = [];
  let priceMicros = Product.getUnitPrice(product);
  const costMicros = Product.getUnitCost(product);
  const costSubtext = JUST_UNIT_COST_DESCRIPTOR;
  let priceSubtext = JUST_UNIT_PRICE_DESCRIPTOR;
  let timelineNumberMonths: number =
    getProductionTimelineInfo(factoryDetails).totalMonths;

  if (Product.isInventory(product)) {
    const inventoryDetails = {
      upgradePriceInMicros:
        Product.getInventoryUnitUpgradePriceInMicros(product),
      deliveryTimeInMonths: getInventoryProductionTimelineTotal(product),
    };
    const upgradeValueMicros =
      inventoryDetails?.upgradePriceInMicros > 100000
        ? inventoryDetails?.upgradePriceInMicros
        : inventoryDetails?.upgradePriceInMicros * 100;
    timelineNumberMonths = inventoryDetails.deliveryTimeInMonths;
    priceSubtext =
      upgradeValueMicros > 0
        ? `${getKStringFromMicros(
            upgradeValueMicros
          )} ${READYBUILT_UPGRADE_TEXT}`
        : JUST_UNIT_PRICE_DESCRIPTOR;
    labels.push(INVENTORY_UNIT_TAG_TEXT);
  }
  if (percentRecommendation) {
    labels.push(percentRecommendation);
  }
  const floorPlanImages = Product.getImageDetailsByCategory(
    product,
    ImageCategory.FLOOR_PLAN
  );
  const hasFewChoices =
    (!!factoryDetails &&
      ProductGroup.containsTag(
        factoryDetails,
        ICatalogTag.FEW_DESIGN_CHOICES
      )) ||
    Product.isInventory(product);
  const lineItem2 = `${timelineNumberMonths} month timeline | ${
    hasFewChoices ? UPGRADES_LIMITED : UPGRADES_AVAILABLE
  }`;
  const hoverImageId =
    floorPlanImages.length > 0 ? floorPlanImages[0].imageId : null;

  if (fitsOnLotData?.productInfoAndPlacement?.productFits) {
    labels = [FITS_ON_LOT_TAG_TEXT, ...labels];
  }
  let onMouseEnter;
  let onMouseLeave;
  if (setProductPreviewOnHover) {
    onMouseEnter = () => {
      store.dispatch(
        setLeafletMapUnitToPreview({
          productId: product?.id,
          factoryId: factoryDetails?.id,
        })
      );
    };
    onMouseLeave = () => {
      store.dispatch(setLeafletMapUnitToPreview(null));
    };
  }
  const priceModeMicros =
    pricingMode === ProjectPriceMode.DTC ? priceMicros : costMicros;

  return {
    title: product.title,
    priceString: getKStringFromMicros(priceModeMicros),
    priceSubtext:
      pricingMode === ProjectPriceMode.DTC ? priceSubtext : costSubtext,
    subtextLine1: Product.getBedBathSqFtString(product),
    subtextLine2: lineItem2,
    primaryImageId: product.imageId ?? null,
    hoverImageId: hoverImageId,
    clickAction: clickAction ?? (() => {}),
    labels: labels,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    additionalLines:
      isEligible !== undefined
        ? [
            isEligible ? "Routed" : "Not Routed",
            `Product Status:
  ${
    ListingStatus.isListed(product.status as ListingStatusDetails)
      ? " Listed"
      : ListingStatus.isUnlisted(product.status as ListingStatusDetails)
      ? " Unlisted"
      : " Hidden"
  }`,
            `Factory Status:
  ${
    ListingStatus.isListed(factoryDetails?.status)
      ? " Listed"
      : ListingStatus.isUnlisted(factoryDetails?.status)
      ? " Unlisted"
      : " Hidden"
  }`,

            factoryDetails?.title ?? "",
          ]
        : null,
  };
}

interface ProductCardProps {
  cardValues: CardValues | ExtendedCardValues;
  height?: string;
  style?: React.CSSProperties;
  widthInColumns?: number;
}

const CardTagtainer = styled.div`
  position: absolute;
  flex-direction: row;
  display: flex;
  gap: 0.5rem;
  top: 8px;
  left: 8px;
  width: 100%;
`;

const P3 = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 14px;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const AdminInfo = styled.div`
  transition: height 150ms ease-in-out;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${ADMIN_BLUE};
  font-size: 12px;
  flex-wrap: nowrap;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: ${(props) =>
    props?.theme?.CatalogSession?.adminCatalogMode ? undefined : "0"};
`;
export const Card = (props: ProductCardProps) => {
  const {
    title,
    priceString,
    priceSubtext,
    subtextLine1,
    subtextLine2,
    primaryImageId,
    hoverImageId,
    clickAction,
    labels,
    onMouseLeave,
    onMouseEnter,
  } = props.cardValues;
  const {height} = props;
  const {isNatMobile} = usePage();
  const [{transform}, set] = useSpring(() => ({
    transform: `perspective(600px) scale(1)`,
    config: {mass: 5, tension: 500, friction: 80},
  }));
  return (
    <CardContainer
      height={height}
      onMouseEnter={
        onMouseEnter &&
        throttleCallback(() => {
          onMouseEnter();
          set({
            transform: `perspective(600px) scale(1.02)`,
          });
        }, 300)
      }
      onMouseLeave={
        onMouseLeave &&
        throttleCallback(() => {
          onMouseLeave();
          set({
            transform: `perspective(600px) scale(1)`,
          });
        }, 300)
      }
      onClick={() => {}}
      style={{
        transformOrigin: "center",
        transform,
      }}
      componentstyle={props.style}
    >
      <ProductContents onClick={clickAction} id={"product-contents-" + title}>
        <ProductCardImage>
          <PercentMatchTag labels={labels} position={"absolute"} />
          <CardTagtainer hidden={!labels || labels?.length === 0}>
            {labels
              ?.filter((label) => {
                return typeof label !== "number";
              })
              ?.map((label, idx) => {
                if (label === INVENTORY_UNIT_TAG_TEXT) {
                  return <InventoryTag key={`card-${idx}`} />;
                } else if (label === FITS_ON_LOT_TAG_TEXT) {
                  return <FitsOnLotTag key={`card-${idx}`} />;
                }
                return null;
              })}
          </CardTagtainer>
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          >
            <SmartImage
              imageId={primaryImageId}
              quality={ImageQuality.MEDIUM}
              blur={false}
              style={{objectFit: "cover"}}
            />
          </div>
          {hoverImageId && !isNatMobile && (
            <HoverOverImage style={{position: "absolute"}}>
              <SmartImage
                blur={false}
                style={{objectFit: "cover"}}
                imageId={hoverImageId}
                quality={ImageQuality.MEDIUM}
              />
            </HoverOverImage>
          )}
        </ProductCardImage>
        <CardFlex COLUMN NOGAP>
          <NatFlex
            ROW
            NOWRAP
            NOGAP
            style={{
              justifyContent: "space-between",
            }}
          >
            <P3
              style={{
                width: "fit-content",
                gap: "0",
              }}
            >
              {title}
            </P3>
            <NatFlex
              VERTICAL_ALIGN={NatVerticalAlign.BOTTOM}
              HORIZONTAL_ALIGN={NatHorizontalAlign.RIGHT}
              ROW
              style={{
                columnGap: "0.35rem",
                rowGap: "0.25rem",
                maxHeight: "1rem",
                overflow: "visible",
                flexDirection: "row-reverse",
                justifyContent: "flex-start",
                maxWidth: "unset",
              }}
            >
              <P3 style={{textOverflow: "unset"}}>{priceString}</P3>
              <NatLabel
                type={NatLabelType.P4}
                label={priceSubtext}
                textOverflow={{maxLines: 1, ellipsis: true}}
                ignoreMargin
              />
            </NatFlex>
          </NatFlex>
          {subtextLine1 && (
            <NatLabel
              type={NatLabelType.P4}
              label={subtextLine1}
              ignoreMargin
            />
          )}
          <NatLabel type={NatLabelType.P4} label={subtextLine2} ignoreMargin />
          {"additionalLines" in props.cardValues &&
            props.cardValues?.additionalLines?.map((additionalLine, idx) => {
              return (
                <AdminInfo key={`additional-line-${idx}`}>
                  {additionalLine}
                </AdminInfo>
              );
            })}
        </CardFlex>
      </ProductContents>
    </CardContainer>
  );
};
const CardFlex = styled(NatFlex)`
  padding: 0.25rem 0.5rem;
  gap: 0.25rem;
  max-width: calc(
    100% - ${(props) => props.theme?.Page?.gutterWidthPercentage}%
  );
`;
export const CardContainer = styled(a.div)<{
  height?: string;
  componentstyle?: React.CSSProperties;
}>`
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  display: flex;
  transition: height 150ms ease-in-out;
  transform-origin: 0 100%;
  height: ${(props) => props.height ?? props.theme?.Card?.height};
  width: ${(props) =>
    `${getWidth(
      props?.theme?.Card?.columnWidth,
      props?.theme?.Page?.columnCount,
      props?.theme?.Page?.gutterWidthPercentage
    )}%`};
  margin-right: ${(props) => `${props.theme?.Page?.gutterWidthPercentage}%`};

  &:nth-child(
      ${(props) =>
          Math.floor(
            props?.theme?.Page?.columnCount / props.theme?.Card?.columnWidth
          )}n
    ) {
    margin-right: 0;
  }

  ${(props) => (props.style ? {...props.componentstyle} : undefined)};
`;

export const ProductCard = (props: {
  product: IProduct;
  productFitsOnLotData?: IProductParcelFitData;
  allInEstimate?: IInstantEstimateData;
  setProductOnHover?: boolean;
  isEligible?: boolean | undefined;
  inventoryDescriptions?: any;
  factoryStatus?: ListingStatusDetails;
  isCustomerPreDeposit?: boolean;
  style?: React.CSSProperties;
  percentRecommendation: number;
}) => {
  const priceMode = useSelector(
    (store: IStore) => store.catalogSession?.priceMode
  );
  const {selectProduct} = useCatalogSessionDispatcher();
  return (
    <Card
      cardValues={getCardValuesForProduct(
        props.product,
        priceMode,
        useFactoryLine({factoryId: props.product.productGroupId})?.details,
        props.isEligible,
        props.productFitsOnLotData,
        props.allInEstimate,
        props.setProductOnHover,
        props.isCustomerPreDeposit,
        props.percentRecommendation,
        () => selectProduct(props.product)
      )}
      style={props.style}
    />
  );
};
