import React from "react";
import {NatModal} from "../../../../../_shared/generics/modal/NatModal";
import {AdminHelpContent} from "../styles";
import {ProductsHelp} from "./components/ProductsHelp";
import {DefaultHelp} from "./components/DefaultHelp";
import {MatrixHelp} from "./components/MatrixHelp";
import {ModifiersHelp} from "./components/ModifiersHelp";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";
import {ALL_TABS, getPage} from "../../../../_shared/constants/AdminPage";

export const HelpModal = (props: {show: boolean; close: () => void}) => {
  const selectedTab = useSelector(
    (state: IStore) => state.administration?.page
  );

  const getContent = () => {
    if (selectedTab === "products") {
      return <ProductsHelp />;
    } else if (selectedTab === "matrix") {
      return <MatrixHelp />;
    } else if (selectedTab === "modifiers") {
      return <ModifiersHelp />;
    } else {
      return <DefaultHelp />;
    }
  };

  const tab = getPage(ALL_TABS, selectedTab);

  return (
    <NatModal
      header={tab ? tab?.title + " - Icon Legend" : "Icon Legend"}
      show={true}
      customSize={{
        width: "32rem",
        height: "16rem",
      }}
      handleClose={props.close}
      centeredContent={true}
      content={<AdminHelpContent>{getContent()}</AdminHelpContent>}
    />
  );
};
