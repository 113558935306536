import {updateProductCatalogTag} from "../../../../../../../database/firebase/api/tags";

export const TagStructure = {
  label: {
    name: "Tag",
    type: "input",
    index: 0,
  },
};

export const getInitialValues = (defaultValues: any) => {
  return defaultValues;
};

export const saveNewImageTag = (tag: string) => {
  return updateProductCatalogTag(null, tag).then(() =>
    console.log("Finished request to save new image tag.")
  );
};
