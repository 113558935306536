import styled from "styled-components";

interface IStandardElement {
  height: string;
  gutterWidthPercentage: number;
  width?: number;
  overrideAnimations?: boolean;
}

export const StandardElement = styled.div<IStandardElement>`
  display: ${(props) => (props.width && props.width > 0 ? "flex" : "none")};
  visibility: ${(props) =>
    props.width && props.width > 0 ? "visible" : "hidden"};
  transition: ${(props) =>
    props.overrideAnimations && props.width && props.width > 0
      ? "all 400ms ease-in-out"
      : ""};
  height: ${(props: IStandardElement) => props.height};
  width: ${(props: IStandardElement) => props.width}%;
  margin-right: ${(props: IStandardElement) => props.gutterWidthPercentage}%;
  flex-direction: column;
  justify-content: center;
  &:last-child {
    margin-right: 0;
  }
  max-width: 100%;
`;
