import {Route} from "react-router-dom";
import {
  DOCUMENT_PDF_PROPOSAL_PATH,
  DOCUMENT_PDF_PATH,
  DOCUMENT_PATH,
} from "../paths";
import React from "react";
import {DocumentView} from "../components/document";
import {DocumentPDFView} from "../components/document/DocumentPDFView";
import ProposalPDFView from "../components/document/ProposalPDFView";

const DocumentRoutes = () => {
  return (
    <>
      <Route exact path={DOCUMENT_PATH}>
        <DocumentView />
      </Route>
      <Route exact path={DOCUMENT_PDF_PATH}>
        <DocumentPDFView />
      </Route>
      <Route exact path={DOCUMENT_PDF_PROPOSAL_PATH}>
        <ProposalPDFView />
      </Route>
    </>
  );
};

export default DocumentRoutes;
