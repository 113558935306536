import {NatSize} from "./options";

export const calculateBorder = (
  borderColor: string | null,
  borderSize?: string | null,
  disabled?: boolean
) => {
  if (borderColor) return `${borderSize ?? 2}px solid ${borderColor}`;
  if (disabled && borderColor)
    return `${borderSize ?? 2}px solid rgba(62, 80, 84, 0.3)`;
  else return "none";
};

export const calculatePadding = (
  size: NatSize,
  equal?: boolean,
  noPadding?: boolean
): string => {
  if (noPadding) {
    return "0rem";
  }
  if (equal) {
    switch (size) {
      case NatSize.XSMALL:
        return "0.15625rem";
      case NatSize.SMALL:
        return "0.25rem";
      case NatSize.NORMAL:
        return "0.375rem";
      case NatSize.LARGE:
        return "0.5625rem";
      default:
        return "0.75rem";
    }
  }
  switch (size) {
    case NatSize.XSMALL:
      return "0.15625rem 0.4375rem";
    case NatSize.SMALL:
      return "0.25rem 0.75rem";
    case NatSize.NORMAL:
      return "0.375rem 1rem";
    case NatSize.LARGE:
      return "0.5625rem 1.25rem";
    default:
      return "0.75rem 1.25rem";
  }
};
