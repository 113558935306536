import React, {Dispatch, SetStateAction} from "react";
import {Customer, ICustomerInfo} from "@natomas-org/core";
import {
  CloseButton,
  ReferralRockContainer,
  ReferralRockContent,
} from "./styles";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {isMobile, openURLInNewTab} from "../../../../_shared/navigation";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {NatModal} from "../../../../_shared/generics/modal/NatModal";
import {usePage} from "../../../../_shared/hooks/usePage";

const ONE_CLICK_ACCESS = true;

enum RRType {
  IFRAME,
  SITE,
}

const formRRUrl = (customer: ICustomerInfo, type: RRType) => {
  const baseURL = `https://villahomes.referralrock.com/access/?view=${
    type === RRType.IFRAME ? "iframe" : "site"
  }&programidentifier=f82a76d3-8849-4ef2-ae3d-31cd0a36c7fd`;
  return (
    baseURL +
      "&firstname=" +
      encodeURIComponent(Customer.getFirstName(customer)) +
      "&lastname=" +
      encodeURIComponent(Customer.getLastName(customer)) +
      "&fullname=" +
      encodeURIComponent(Customer.getFullName(customer)) +
      "&email=" +
      encodeURIComponent(Customer.getContactEmail(customer)) +
      "&phone=" +
      encodeURIComponent(Customer.getPrimaryContactPhoneNumber(customer)) +
      "&externalid=" +
      customer?.user_id ?? ""
  );
};

export const ReferralRock = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const {customer} = useCurrentCustomer();
  const {isNatMobile} = usePage();
  if (!customer) {
    return null;
  }
  const src = formRRUrl(customer, RRType.IFRAME);

  const closeReferralRock = () => {
    props.setShow(false);
  };

  const toReferralRock = () => {
    closeReferralRock();
    openURLInNewTab(formRRUrl(customer, RRType.SITE));
  };

  if (ONE_CLICK_ACCESS && !isMobile()) {
    return (
      <NatModal
        show={props.show}
        handleClose={() => props.setShow(false)}
        centeredContent={true}
        customSize={{
          width: "30rem",
          height: "26rem",
        }}
        content={
          <ReferralRockContent
            fullscreen={isNatMobile}
            id={"referral-rock-iframe"}
            data-hj-allow-iframe=""
            scrolling={"no"}
            src={src}
          />
        }
        footerButtons={[
          {
            label: "Dismiss",
            trackingId: "referral-rock-close",
            option: StyleOption.SECONDARY_ALT,
            type: "button",
            clickEvent: closeReferralRock,
          },
          {
            label: "Referral Dashboard",
            option: StyleOption.PRIMARY_ALT,
            clickEvent: toReferralRock,
            trackingId: "referral-rock-dashboard",
            type: "button",
          },
        ]}
      />
    );
  }

  return (
    <>
      {props.show && (
        <ReferralRockContainer onClick={() => props.setShow(false)}>
          <ReferralRockContent
            fullscreen={isMobile()}
            id={"referral-rock-iframe"}
            data-hj-allow-iframe=""
            src={src}
          />
          {isMobile() && (
            <CloseButton onClick={() => props.setShow(false)}>
              <AiOutlineCloseCircle />
            </CloseButton>
          )}
          {/*<script*/}
          {/*  type={"text/javascript"}*/}
          {/*  src={"//villahomes.referralrock.com/webpixel/beta/universalv03.js"}*/}
          {/*/>*/}
        </ReferralRockContainer>
      )}
    </>
  );
};
