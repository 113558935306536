import {
  getDefaultCategory,
  getDefaultModifier,
  getDefaultModifierGroup,
  getDefaultRendering,
} from "../../formik/defaults";
import {IFactoryInventoryStudioEditorPopupPathOptions} from "./interface";

export const getValue = (
  data?: any,
  path?: IFactoryInventoryStudioEditorPopupPathOptions,
): any => {
  if (!data || !path) {
    return undefined;
  }

  const {categoryId, modifierGroupId, modifierId, imageGroupId} = path;
  const category = categoryId ? data?.[categoryId] : undefined;
  const modifierGroup = modifierGroupId
    ? category?.modifierGroups?.[modifierGroupId]
    : undefined;
  const modifier = modifierId
    ? modifierGroup?.modifiers?.[modifierId]
    : undefined;
  const image = imageGroupId ? category?.images?.[imageGroupId] : undefined;

  if (categoryId && modifierGroupId && modifierId) {
    return (
      modifier ??
      getDefaultModifier(
        modifierId,
        Object.keys(modifierGroup?.modifiers ?? {})?.length ?? 0
      )
    );
  } else if (categoryId && modifierGroupId) {
    return (
      modifierGroup ??
      getDefaultModifierGroup(
        modifierGroupId,
        Object.keys(category?.modifierGroups ?? {})?.length ?? 0
      )
    );
  } else if (categoryId && imageGroupId) {
    return (
      image ??
      getDefaultRendering(
        imageGroupId,
        Object.keys(category?.images ?? {})?.length ?? 0
      )
    );
  } else if (categoryId) {
    return (
      category ??
      getDefaultCategory(categoryId, Object.keys(data ?? {})?.length ?? 0)
    );
  }
  return undefined;
};

export const getFormikPath = (path?: IFactoryInventoryStudioEditorPopupPathOptions): string | undefined => {
  const {categoryId, modifierGroupId, modifierId, imageGroupId} = path ?? {};
  if (categoryId && imageGroupId) {
    return `${categoryId}.images.${imageGroupId}`;
  } else if (categoryId && modifierGroupId && modifierId) {
    return `${categoryId}.modifierGroups.${modifierGroupId}.modifiers.${modifierId}`;
  } else if (categoryId && modifierGroupId) {
    return `${categoryId}.modifierGroups.${modifierGroupId}`;
  } else if (categoryId) {
    return categoryId;
  }
  return undefined;
};
