// import React from "react";
// import {SmartCatalogHeading} from "../../../../../../map/_shared/components/ParcelInfo";
// import styled from "styled-components";
// import {FONT_FAMILY_REGULAR} from "../../../../../../portal/views/SSDDashboardView/styles/globals";
// import {GRANITE} from "../../../../../../_shared/colors";
// import {useAddress} from "../../../../../../_shared/hooks/useAddress";

// const SeriesBannerAddressContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
//   gap: 0;
//   flex-wrap: wrap;
//   * {
//     font-size: 11px;
//     font-family: ${FONT_FAMILY_REGULAR};
//   }
// `;
//
// const SeriesBannerAddressLabel = styled.p`
//   color: ${GRANITE};
//   padding: 0;
//   margin: 0;
// `;

import React from "react";
import CatalogAddressController from "../../../../../_shared/components/AddressController";

const SeriesBannerAddress = () => {
  return <CatalogAddressController />;
};

export default SeriesBannerAddress;
