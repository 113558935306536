import {
  DateUtils,
  EventStatus,
  EventTypes,
  IDocumentDetails,
  IDocumentTypes,
  IDocusignTaskDetails,
  IEventInfo,
  IMimeType,
  IPDFDocumentInfo,
  IProjectInfo,
  Project,
  PROJECT_DOCUMENTS_COLLECTION,
  ProjectDestination,
} from "@natomas-org/core";
import {collection} from "../../../../database/firebase/utilities";
import React from "react";
// @ts-ignore
import {storage} from "../../../../database/firebase";
import {executeRequest} from "../../../../database/firebase/api";
import {IFileUploadDetails} from "./schemas";

export const getDocumentUploadDateAndSizeString = (
  docToDisplay: IDocumentDetails | null
): string => {
  if (!docToDisplay) {
    return "<No File Uploaded>";
  }
  const date = new Date(docToDisplay?.timestamp_last_updated);
  if (docToDisplay?.timestamp_last_updated < 0) {
    return "";
  }
  return `Updated ${DateUtils.getMonthAndDayShortHand(date, {
    shorthandFormat: true,
  })} | ${DateUtils.getClockTime(date, true)}`;
};

export const getFileSizeString = (sizeInKb: number | undefined): string => {
  if (!sizeInKb) {
    return "";
  }
  let extension = sizeInKb < 1024 ? "Kb" : "Mb";
  switch (extension) {
    case "Mb":
      return ` [${Math.round(sizeInKb / 1024)} ${extension}]`;
    default:
      return ` [${Math.round(sizeInKb)} ${extension}]`;
  }
};

export const getDocumentDateString = (timestamp: number): string => {
  const date = new Date(timestamp);
  return `${DateUtils.getMonthAndDayShortHand(date)} ${DateUtils.getClockTime(
    date,
    true
  )}`;
};

export const getFormattedEventTitle = (event: IEventInfo): string => {
  const dateString = getDocumentDateString(event.timestamp);
  const eventStatus = getEventStatusString(
    (event.details as IDocusignTaskDetails).status
  );
  return `${eventStatus}: ${dateString}`;
};

export const getHasNewDocusignBeenSent = (
  event: IEventInfo | null,
  docToDisplay: IDocumentDetails | null
): string | null => {
  if (
    event &&
    docToDisplay &&
    docToDisplay?.timestamp_last_updated > event?.timestamp
  ) {
    return `Docusign is outdated`;
  } else {
    return null;
  }
};

const getEventStatusString = (eventStatus: EventStatus): string => {
  switch (eventStatus) {
    case EventStatus.IN_PROGRESS:
      return "Docusign sent";
    case EventStatus.COMPLETED:
      return "Docusign signed";
    case EventStatus.SCHEDULED:
      return "Awaiting Docusign";
    default:
      return "";
  }
};

export const getDocusignStatusFromDocumentInfo = (
  projectSummary: IProjectInfo | undefined | null,
  documentInfo: IPDFDocumentInfo
) => {
  const event = getDocusignEventFromDocumentInfo(projectSummary, documentInfo);
  return event?.details.status ?? EventStatus.UNKNOWN;
};

export const getDocusignEventFromDocumentInfo = (
  projectSummary: IProjectInfo | undefined | null,
  documentInfo: IPDFDocumentInfo
) => {
  if (
    !documentInfo ||
    !projectSummary ||
    !documentInfo.smartsheet_info?.event_id
  ) {
    return null;
  }

  return (
    Project.getEventWithType(
      projectSummary,
      documentInfo.smartsheet_info?.event_id ?? "",
      EventTypes.TASK_DOCUSIGN
    ) ??
    Project.getEvent(
      projectSummary,
      documentInfo.smartsheet_info?.event_id ?? ""
    )
  );
};

export const uploadProjectFile = (options: IUploadProjectFileOptions) => {
  const {
    file,
    documentId,
    documentTitle,
    projectId,
    setShowProgress,
    setProgressPercent,
    onComplete,
    defaultHiddenInProject,
  } = options;
  if (!projectId) {
    onComplete(null);
    return null;
  }
  const fileStorageBaseRef = storage.ref(`project/${projectId}`);
  const fileFirestoreBaseRef = collection(
    `${ProjectDestination.NATOMAS}/${projectId}/${PROJECT_DOCUMENTS_COLLECTION}`
  );
  const filePathSections = file?.path?.split(".");
  const fileName =
    documentTitle && documentTitle !== ""
      ? documentTitle
      : filePathSections?.[0];

  const fileDocumentIdForUpload = documentId ?? fileFirestoreBaseRef.doc().id;
  const uploadTask = fileStorageBaseRef
    .child(fileDocumentIdForUpload)
    .put(file);

  let totalKb: undefined | number = undefined;
  uploadTask.on(
    "state_changed",
    (snap: any) => {
      totalKb = snap.totalBytes / 1024;
      let progress = 0;
      if (
        !(
          !snap?.bytesTransferred ||
          typeof snap.bytesTransferred !== "number" ||
          !snap?.totalBytes ||
          typeof snap.totalBytes !== "number"
        )
      ) {
        progress = (snap.bytesTransferred / snap.totalBytes) * 100;
      }
      if (!!setProgressPercent) {
        if (!!setShowProgress) {
          setShowProgress(true);
        }
        setProgressPercent(progress ?? 0);
      }
    },
    (error: any) => {
      // Error function ...
      console.error(error);
    },
    () => {
      // complete function
      uploadTask.snapshot.ref.getDownloadURL().then((url: any) => {
        const documentDetails: IDocumentDetails = {
          deleted: false,
          document_id: fileDocumentIdForUpload,
          timestamp_last_updated: Date.now(),
          title: fileName,
          type: IDocumentTypes.GCLOUD_STORAGE,
          url: url,
          hidden: defaultHiddenInProject ?? false,
          details: {
            sizeInKb: totalKb,
            mimeType: file.type,
            storageRefPath: `project/${projectId}/${fileDocumentIdForUpload}`,
          },
        };
        return fileFirestoreBaseRef
          .doc(fileDocumentIdForUpload)
          .set(documentDetails, {merge: true})
          .then((success: any) => {
            console.log(success);
            setShowProgress?.(false);
            if (file.type === IMimeType.PDF) {
              executeRequest(
                "/project/document/v1/savePDFDataToProjectDocument",
                {
                  projectId: projectId,
                  documentId: fileDocumentIdForUpload,
                }
              ).then(() => {
                console.log("Enriched pdf data");
                onComplete(documentDetails);
              });
            } else {
              onComplete(documentDetails);
            }
          });
      });
    }
  );

  return fileDocumentIdForUpload;
};

export const getProjectDocumentIdFromDocumentDetails = (
  document: IDocumentDetails
): string | undefined => {
  return document?.document_id;
};

export interface IUploadProjectFileOptions {
  file: IFileUploadDetails;
  documentTitle: string | undefined | null;
  projectId: string | undefined;
  documentId: string | undefined | null;
  onComplete: (documentDetails: IDocumentDetails | null) => void;
  setProgressPercent?: React.Dispatch<React.SetStateAction<number>>;
  setShowProgress?: React.Dispatch<React.SetStateAction<boolean>>;
  defaultHiddenInProject?: boolean;
}
