const BLACK = "#000000";
const TRANSPARENT_BLACK = "#00000069";
const WHITE = "#FFFFFF";
const GRAY = "#5a5a5a";
const GRAY80 = "#464646";
const red = "#dd0000";
const dark_red = "#660000";
const light_gray_1 = "#E1E1E1";
const light_gray_2 = "#afafaf";
const light_gray_3 = "#C5CBCC";
// TODO Organize these colors to be in a logical order (4 is currently brighter than 5)
const light_gray_4 = "#f3f3f3";
const light_gray_5 = "#E6E6E6";
const gray30 = "#ACACAC";
const black30 = "#b3b3b3";
const gray_2 = "#6c7076";
const dark_gray = "#252525";
const dark_gray_2 = "#3d3d3d";
const green_2 = "#6A9E60";
const green_4 = "#d7eecc";
const link_green = "#368836";

const blue_gray = "#2a3639";
const dark_blue_gray = "#222628";
const light_blue_gray = "#E0EEF1";

const neon_purple = "#a256f3";
const red2 = "#FF001E";
const pale_red = "#FFD2D7";
const orange = "#FFBF00";
const yellow = "#ffdf7b";
const pale_orange = "#fff6de";
const blue = "#0191FF";
const pale_blue = "#DFF1FF";
const green = "#2ea043";
const pale_green = "#e0ffe5";
const pale_green_2 = "#CCE6C6";
const pale_green_3 = "#E8F4E6";

const villa_blue = "#3d4f54";
const villa_primary_green = "#238636";
const villa_apple_green = "#82C275";
const villa_apple_green_alt = "#64b454";
const villa_primary_hover = "#ADD7A4";
const villa_primary_light_green = "#64b753";
const villa_poppy = "#E36E61";

const pale_poppy = "#EFAEA7";
const pale_sunshine = "#FDF0B5";
const pale_sky = "#9EBBFF";
const pale_lilac = "#E6C7FF";

const light_taupe = "#AD8A70";

export {BLACK, WHITE, GRAY, GRAY80, TRANSPARENT_BLACK};
export const ADMIN_BUTTON_ACTIVE = blue;
export const ADMIN_BUTTON_INACTIVE = light_gray_2;
export const AVATAR_PILL_HOVER_COLOR = light_gray_1;
export const BUTTON_FILL_COLOR = green_2;
export const BUBBLE_BACKGROUND_COLOR = red;
export const BUTTON_FILL_COLOR_GRAY_1 = light_gray_1;
export const BUTTON_FILL_COLOR_GRAY_2 = light_gray_2;
export const CONTAINER_BACKGROUND_COLOR = light_gray_4;
export const DESIGN_STUDIO_DISABLED_BUTTON = light_gray_1;
export const DESTRUCTIVE_FILL = red;
export const DESTRUCTIVE_FILL_HOVER = dark_red;
export const DEVELOPER_FILL = dark_red;
export const DEVELOPER_FILL_HOVER = red;
export const DEVELOPER_FILL_SECTION = dark_gray;
export const DIVIDER_COLOR = gray30;
export const DROPDOWN_ACCENT_COLOR = light_gray_3;
export const ERROR_COLOR = red;
export const HIGHLIGHT_SKY = pale_sky;
export const NEUTRAL_COLOR = BLACK;
export const LINK_COLOR = villa_blue;
export const LINK_HOVER_COLOR = BLACK;
export const MAP_POLYGON_ACTIVE_FILL = blue;
export const MAP_POLYGON_ACTIVE_OUTLINE = blue;
export const MAP_POLYGON_FILL = blue;
export const MAP_POLYGON_HOVER_FILL = orange;
export const MAP_POLYGON_OUTLINE = blue;
export const MAP_POLYGON_HOVER_OUTLINE = orange;
export const MAP_POLYGON_POINT_OUTLINE = blue;
export const MAP_POLYGON_POINT_FILL = blue;
export const MAP_POLYGON_POINT_HOVER_FILL = DESTRUCTIVE_FILL;
export const PRIMARY_ALT = villa_blue;
export const PRIMARY_BLACK = BLACK;
export const PRIMARY_BLACK_HOVER = dark_gray_2;
export const SECONDARY_BLACK_HOVER = light_gray_5;
export const PRIMARY_BUTTON_COLOR = villa_apple_green;
export const PRIMARY_BUTTON_HOVER = villa_primary_hover;
export const PRIMARY_LINK_HOVER = link_green;
export const PRIMARY_LINK_ALT = link_green;
export const VILLA_APPLE_GREEN = villa_apple_green;
export const VILLA_APPLE_GREEN_HOVER = villa_apple_green_alt;
export const VILLA_APPLE_GREEN_LIGHT_2 = pale_green_2;
export const VILLA_APPLE_GREEN_LIGHT_1 = pale_green_3;
export const APP_SWITCHER_BACKGROUND = pale_green_3;
export const PRIMARY_LINK_ALT_HOVER = BLACK;
export const PROGRESS_COLOR = green_4;
export const PROGRESS_FILL_COLOR = green_2;
export const PROGRESS_TRACK_FILL_COLOR = light_gray_5;
export const PROGRESS_TRACK_FILL_HOVER_COLOR = light_gray_4;
export const PROGRESS_FILL_COLOR_OVERALL = villa_blue;
export const PROGRESS_FILL_COLOR_PROPOSAL = villa_primary_hover;
export const PROGRESS_FILL_COLOR_PERMITTING = pale_sunshine;
export const PROGRESS_FILL_COLOR_ATB = pale_poppy;
export const PROGRESS_FILL_COLOR_MANUFACTURING = pale_sky;
export const PROGRESS_FILL_COLOR_INSTALLATION = pale_lilac;
export const PROGRESS_CARD_COLOR_NAME = light_taupe;
export const SIDEBAR_COLOR = blue_gray;
export const SIDEBAR_COLOR_DARK = dark_blue_gray;
export const SIDEBAR_COLOR_LIGHT = light_blue_gray;
export const SLIDER_RAIL = light_gray_1;
export const SLIDER_THUMB = BLACK;
export const SLIDER_TRACK = BLACK;
export const STATUS_HIDDEN = red2;
export const STATUS_HIDDEN_ALT = pale_red;
export const STATUS_LISTED = blue;
export const STATUS_COMPLETE = green_2;
export const STATUS_COMPLETE_ALT = green_4;
export const STATUS_INCOMPLETE = red2;
export const STATUS_INCOMPLETE_ALT = pale_red;
export const STATUS_LISTED_ALT = pale_blue;
export const STATUS_UNLISTED = orange;
export const STATUS_UNLISTED_ALT = pale_orange;
export const STATUS_ENABLED = green;
export const STATUS_ENABLED_ALT = pale_green;
export const STATUS_DISABLED = red2;
export const STATUS_DISABLED_ALT = pale_red;
export const STRIKE_THROUGH_COLOR = villa_poppy;
export const SUCCESS_LIGHT_GREEN = villa_primary_light_green;
export const SUPER_ADMIN_HIGHLIGHT_COLOR = neon_purple;
export const TOGGLE_BORDER_ENABLED_COLOR = gray_2;
export const TOGGLE_ENABLED_FILL_COLOR = green_4;
export const VALID_COLOR = villa_primary_green;
export const VILLA_BLUE = villa_blue;
export const ERROR_PRIMARY = red2;
export const ERROR_FILL = pale_red;
export const ERROR_TEXT = red2;
export const WARNING_FILL = pale_orange;
export const WARNING_TEXT = orange;
export const GRAY_BORDER = black30;
export const GRAY_BORDER_LIGHT = gray30;
export const VILLA_SEA_FOAM = "#ADD6C2";
export const VILLA_PERIWINKLE = "#8B9FE5";
export const VILLA_LILAC = pale_lilac;
export const VILLA_DEEP_LILAC = "#572D76"
export const PASTEL_GREEN = "#C7E1C2";
export const VILLA_SLATE = "#3D4F54";
export const VILLA_INFORMATIONAL = "#4680FF";
export const VILLA_FOREST_GREEN = "#368836";
export const GRANITE = "#807D7A";
export const VILLA_HIGHLIGHTER_YELLOW = "#FFEE7E";
export const VILLA_VANILLA = "#FFF7BF";
export const VILLA_MINT = "#C0E0BA";
export const VILLA_COOL_GRAY = "#F9FAFA";
export const BLACK20 = "#00000020";
export const WARM_NEUTRAL = "#F7F5F2";
export const ADMIN_BLUE = "#2196f3";
export const ADMIN_EDIT = yellow;
export const ADMIN_SELECTED = "#d8e1ff";
export const CATEGORY_COLOR = pale_lilac;
export const MODIFIER_GROUP_COLOR = pale_green;
export const TRANSPARENT = "#ffffff00";
export const VILLA_APPLE_GREEN_20 = "#82C27533";
export const BUDGET_SUMMARY_GRAY_ROW_BG = "rgba(0,0,0,0.1)";
export const VILLA_TIMELINE_PROPOSAL = "#B3E8A8";
export const VILLA_TIMELINE_PERMITTING_AND_DESIGN = "#FFD990";
export const VILLA_ATB = "#8EE2CE";
export const VILLA_TIMELINE_MANUFACTURING = "#95B2F2";
export const VILLA_TIMELINE_INSTALLATION = pale_lilac;
export const TOGGLE_BORDER_COLOR = GRAY_BORDER_LIGHT;
