import {useEffect, useState} from "react";

import Select from "react-select";
import {isEmpty} from "@natomas-org/core";
import {useSelector} from "react-redux";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";

export const VISIBILITY_TYPE = "visibility";
export const PRICES_TYPE = "prices";

export const VISIBILITY_SHOW_KEY = "show";
export const VISIBILITY_HIDE_KEY = "hide";

export function getDataFromEntries(data, type) {
  switch (type) {
    case PRICES_TYPE:
      return data.map((item) => {
        return {
          price: item?.priceMicros / 100,
          cost: item?.costMicros / 100,
          requiredModifiers: item?.requiredModifiers,
          condition: item?.condition,
          type: item?.type,
        };
      });
    case VISIBILITY_TYPE:
      return data?.map((item) => {
        return {
          visibility: item?.visibility,
          requiredModifiers: item?.requiredModifiers,
          condition: item?.condition,
        };
      });
    default:
      throw new Error("Incorrect Dependency Table type: " + type);
  }
}

export function generateOutput(data, type) {
  switch (type) {
    case PRICES_TYPE:
      return data?.map((price) => {
        return {
          priceMicros: Math.round((price?.price ?? 0) * 100),
          costMicros: Math.round((price?.cost ?? 0) * 100),
          requiredModifiers: price.requiredModifiers,
          condition: price.condition,
          type: price.type,
        };
      });
    case VISIBILITY_TYPE:
      return data;
    default:
      throw new Error("Incorrect Dependency Table type: " + type);
  }
}

export function getOptionsFromSelections(initialSelections, allModifiers) {
  if (initialSelections == null) {
    return [];
  }
  return Object.keys(initialSelections).map((key) => {
    return {
      value: key,
      label: allModifiers[key] != null ? allModifiers[key].title : key,
    };
  });
}

const convertModifierArrayToSelectionOptions = (modifiers) => {
  if (isEmpty(modifiers)) {
    return [];
  }
  return modifiers.map((m) => {
    return {
      label: m.title,
      value: m.id,
    };
  });
};

// const getDependentModifierGroups = (modifierId, allModifierGroups) => {
//   return Object.values(allModifierGroups).filter((group) => {
//     return group?.modifiers?.includes(modifierId);
//   });
// };

const overrideRequiresModifier = (modifierId, overrideInformation) => {
  let r = false;
  let dependencies = overrideInformation?.dependencies;
  dependencies?.forEach((d) => {
    let requiredModifiers = Object.keys(d.requiredModifiers);
    if (requiredModifiers.includes(modifierId)) {
      r = true;
    }
  });
  return r;
};

const getModifierGroupIdsDependentOnModifierWithCategories = (
  modifierId,
  categories
) => {
  let groupIds = [];
  categories.forEach((c) => {
    if (c?.modifierGroupOverrides) {
      let overrideKeys = Object.keys(c?.modifierGroupOverrides);
      overrideKeys.forEach((key) => {
        if (
          overrideRequiresModifier(modifierId, c.modifierGroupOverrides[key])
        ) {
          groupIds.push(key);
        }
      });
    }
  });
  return groupIds;
};

const getModifierIdsDependentOnModifierWithModifierGroups = (
  modifierId,
  modifierGroups
) => {
  let groupIds = [];
  modifierGroups.forEach((c) => {
    if (c?.modifierOverrides) {
      let overrideKeys = Object.keys(c?.modifierOverrides);
      overrideKeys.forEach((key) => {
        if (overrideRequiresModifier(modifierId, c.modifierOverrides[key])) {
          groupIds.push(key);
        }
      });
    }
  });
  return groupIds;
};

const getCategoriesWithVisibilityDependencies = (categories) => {
  let cats = [];
  Object.values(categories).forEach((c) => {
    if (c?.modifierGroupOverrides) {
      const modifierGroupsWithOverrides = Object.keys(c.modifierGroupOverrides);
      if (modifierGroupsWithOverrides.length > 0) {
        cats.push(c);
      }
    }
  });
  return cats;
};

const getModifierGroupsWithVisibilityDependencies = (modifierGroups) => {
  let groups = [];
  Object.values(modifierGroups).forEach((g) => {
    if (g?.modifierOverrides) {
      const modifierGroupsWithOverrides = Object.keys(g.modifierOverrides);
      if (modifierGroupsWithOverrides.length > 0) {
        groups.push(g);
      }
    }
  });
  return groups;
};

const removeBlockedModifiers = (blocked, all) => {
  if (!blocked ?? blocked.length === 0) {
    return all;
  } else {
    return all.filter((m) => !blocked.includes(m.id));
  }
};

const getAvailableModifiers = (
  group,
  initials,
  categories,
  allModifiers,
  allGroups
) => {
  let categoriesWithVisibilityDependencies =
    getCategoriesWithVisibilityDependencies(categories);
  let groupsWithVisibilityDependencies =
    getModifierGroupsWithVisibilityDependencies(allGroups);
  let blockedModifiers = [];
  let modifiersToCheck = group?.modifiers ?? [];
  while (modifiersToCheck.length > 0) {
    let m = modifiersToCheck[0];
    modifiersToCheck = modifiersToCheck.slice(1);
    blockedModifiers.push(m);
    const groupIdsOfDependentsFromCategories =
      getModifierGroupIdsDependentOnModifierWithCategories(
        m,
        categoriesWithVisibilityDependencies
      );
    groupIdsOfDependentsFromCategories?.forEach((g) => {
      let newModifiersToCheck = Object.values(allGroups[g]?.modifiers);
      newModifiersToCheck?.forEach((newM) => {
        if (
          !modifiersToCheck.includes(newM) &&
          !blockedModifiers.includes(newM)
        ) {
          modifiersToCheck.push(newM);
        }
      });
    });
    const modifierIdsOfDependentsFromModifierGroups =
      getModifierIdsDependentOnModifierWithModifierGroups(
        m,
        groupsWithVisibilityDependencies
      );
    modifierIdsOfDependentsFromModifierGroups?.forEach((m) => {
      if (!modifiersToCheck.includes(m) && !blockedModifiers.includes(m)) {
        modifiersToCheck.push(m);
      }
    });
  }
  return removeBlockedModifiers(blockedModifiers, Object.values(allModifiers));
};

export const ModifierSelection = (props) => {
  const {initialSelections, onSelectionChange, disabled} = props;

  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const {modifierGroups, modifiers, categories} = useSelectedFactoryLine();
  const editingModifierGroup = useSelector(
    (state) => state.adminStudioManager.editingModifierGroup
  );

  useEffect(() => {
    const modifierOptions = getOptionsFromSelections(
      initialSelections,
      modifiers
    );
    setSelectedModifiers(modifierOptions);
  }, [initialSelections]);

  return (
    <Select
      isMulti
      isDisabled={disabled}
      value={selectedModifiers}
      onChange={(value) => {
        onSelectionChange(value);
      }}
      placeholder={"Modifiers"}
      options={convertModifierArrayToSelectionOptions(
        getAvailableModifiers(
          editingModifierGroup,
          initialSelections,
          categories,
          modifiers,
          modifierGroups
        )
      )}
    />
  );
};
