import styled from "styled-components";
import {VILLA_BLUE, WHITE} from "../../colors";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";

export const EmptyPageContentContainer = styled.div<{height?: string}>`
  height: ${(props) => props.height ?? "100vh"};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 32px;
  background-color: ${VILLA_BLUE};
`;

export const EmptyPageTitle = styled.h1`
  font-family: ${FONT_FAMILY_BOLD};
  color: ${WHITE};
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  white-space: pre-wrap;
`;

export const EmptyPageActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;
