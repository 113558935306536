import React from "react";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {DeveloperViewContainer} from "../_shared/DeveloperViewContainer";
import {NatLink} from "../../../../_shared/generics/button";
import {ElementContainer} from "../../styles";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {BLACK, VILLA_BLUE, WHITE} from "../../../../_shared/colors";

const Links = (props: {backgroundColor: string}) => {
  return (
    <PageElement size={4} height={"auto"}>
      <ElementContainer backgroundColor={props.backgroundColor}>
        <NatLink
          label={StyleOption[StyleOption.PRIMARY_LINK]}
          trackingId={"null"}
          option={StyleOption.PRIMARY_LINK}
        />
      </ElementContainer>
    </PageElement>
  );
};

export const NatLinkSection = () => {
  return (
    <DeveloperViewContainer title={"NatLink"}>
      <Links backgroundColor={WHITE} />
      <Links backgroundColor={VILLA_BLUE} />
      <Links backgroundColor={BLACK} />
    </DeveloperViewContainer>
  );
};
