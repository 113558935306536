import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
  DropdownStyles,
  INatDropdownStyleOptions,
  NatDropdownProps,
  NatDropdownStyles,
  NatPopupStyleOptions,
  NatPopupType,
} from "./constants";
import {useNatPopup} from "./hooks/useNatPopup/useNatPopup";
import {usePage} from "../../../hooks/usePage";
import {isElementOnRightSide} from "./helpers";
import {DropdownButton} from "./components/DropdownButton";
import {DropdownButtonContainer} from "./styles";

export const NatDropdown = (props: NatDropdownProps) => {
  return useNatDropdown(props)?.dropdown;
};

export const useNatDropdown = (props: NatDropdownProps) => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLInputElement>(null);
  const {isNatMobile} = usePage();
  const style: INatDropdownStyleOptions = useMemo(() => {
    let style = props.style ?? NatDropdownStyles[DropdownStyles.SINGLE_ELEMENT];
    if (isElementOnRightSide(wrapperRef?.current)) {
      let additionalStyleOptions: NatPopupStyleOptions[] = [];
      if (style.ADDITIONAL_STYLE_OPTIONS) {
        additionalStyleOptions = [...style.ADDITIONAL_STYLE_OPTIONS];
      }
      additionalStyleOptions.push(NatPopupStyleOptions.RIGHT_ALIGNED);
      style.ADDITIONAL_STYLE_OPTIONS = additionalStyleOptions;
    }
    return style;
  }, [props.style]);

  const {popup, show, setShow, modal} = useNatPopup({
    ...props,
    style: style,
  });

  const handleClose = useCallback(
    (event: MouseEvent | any) => {
      if (
        !wrapperRef?.current?.contains(event.target) &&
        !buttonRef?.current?.contains(event.target)
      ) {
        setShow(false);
        if (props?.handleClose) {
          props.handleClose();
        }
      }
    },
    [props, setShow]
  );

  useEffect(() => {
    if (
      (isNatMobile &&
        style?.ADDITIONAL_STYLE_OPTIONS?.includes(
          NatPopupStyleOptions.BOTTOM_SHEET_MOBILE
        )) ||
      style.TYPE !== NatPopupType.HIDE ||
      style.ADDITIONAL_STYLE_OPTIONS?.includes(
        NatPopupStyleOptions.NO_CLICK_THRU
      )
    ) {
      return;
    }
    document.addEventListener("mousedown", handleClose);
    return () => {
      document.removeEventListener("mousedown", handleClose);
    };
  }, [handleClose, isNatMobile, style.ADDITIONAL_STYLE_OPTIONS, style.TYPE]);

  return {
    dropdown: (
      <>
        <DropdownButtonContainer
          style={props.style?.dropdownButtonCSS}
          ref={buttonRef}
        >
          <DropdownButton
            {...props}
            setShow={setShow}
            show={show}
            style={style}
          />
          <div ref={wrapperRef}>{popup}</div>
        </DropdownButtonContainer>
        {modal}
      </>
    ),
    modal: modal,
  };
};
