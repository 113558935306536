import React from "react";
import {PDFProposal} from "@natomas-org/core";
import {Text, View} from "@react-pdf/renderer";
import {styles} from "./styles";

interface PageFooterProps {
  company: PDFProposal.ICompanyInfo;
  generatedAt: number;
}

const PageFooter = (props: PageFooterProps) => {
  const {company, generatedAt} = props;
  if (!company) {
    return null;
  }
  const companyInfo = `${company.title} | ${company.address} | CSLB#${company.CSLB} | HCD#${company.HCD}`;
  const dateTime = `${new Date(generatedAt).toLocaleDateString()} ${new Date(
    generatedAt
  ).toLocaleTimeString()}`;
  return (
    <View fixed style={styles.footer}>
      <View style={styles.footerContent}>
        <Text>{companyInfo}</Text>
        <View style={styles.flexSpacedContainer}>
          <Text>{dateTime}</Text>
          <Text
            render={({pageNumber, totalPages}) =>
              `Page ${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </View>
    </View>
  );
};

export default PageFooter;
