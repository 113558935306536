import {IFactoryInventoryStudioEditorCategoryImage} from "../../CategorySection/CategoryImagesEditor/interface";

export const sortImages = (
  images?: IFactoryInventoryStudioEditorCategoryImage[]
): IFactoryInventoryStudioEditorCategoryImage[] => {
  return (images ?? [])?.sort(
    (
      a: IFactoryInventoryStudioEditorCategoryImage,
      b: IFactoryInventoryStudioEditorCategoryImage
    ) => a?.index - b?.index
  );
};
