import React, {useState} from "react";
import Text from "../../2-component/Text";
import CreateContactForm from "../../1-container/forms/CreateContactForm";
import {ContentWrapper} from "./style";
import {signUpWithEmail} from "../../../api/Auth/Login/email";
import {DefaultError} from "../../2-component/Input/generic/DefaultError";
import {handleCredential} from "../../../api/Auth/Login/handleCredential";

const CreateContactContent = (props: {
  close: () => void;
  onSubmit: (values: any) => void;
}) => {
  const [localError, setLocalError] = useState<string>();

  const trySignup = async (values: any) => {
    try {
      const r = await signUpWithEmail(values["email"], values["password"]);
      const cred = handleCredential(r);
      return props.onSubmit({
        ...cred,
        tos_privacy: values["tos-privacy"],
      });
    } catch (error: any) {
      setLocalError(error?.message ?? "Please enter a valid email address.");
    }
  };
  return (
    <ContentWrapper>
      <Text
        text={
          "A free account lets you save your Villa design, see how it fits on your lot and get an instant quote."
        }
        style={{textAlign: "center"}}
      />
      <DefaultError id={"new-auth-email-account"} error={localError} />
      <CreateContactForm
        buttons={[
          {
            id: "new-auth-account-form-back",
            label: "Go back",
            type: "button",
            theme: "secondary",
            onClick: props.close,
          },
        ]}
        onSubmit={trySignup}
      />
    </ContentWrapper>
  );
};

export default CreateContactContent;
