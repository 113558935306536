import {Configuration, Utilities} from "../../database/firebase";
import {
  CONFIGURATION_DESIGN_DB_KEY,
  CONFIGURATION_SNAPSHOTS_DB_KEY,
} from "../../database/firebase/configuration/configuration";
import {storeSubscription, hasActiveSubscription} from "../subscriptions";
import {subscribeWithCallback} from "../../database/firebase/utilities";
import {store} from "../../store";
import {
  updateDesignConfiguration,
  updateDesignMap,
  updateDesignSnapshot,
} from "../../components/_shared/slices/DesignSlice";
import {updateCartItem} from "../../components/_shared/slices/CartSlice/CartSlice";
import {updateProjectCartItemIds} from "../../components/_shared/slices/ProjectSlice/ProjectSlice";
import {getADUCartItem} from "../../components/_shared/slices/CartSlice/helper";

interface DesignSubscriptionOptions {
  isLegacy?: boolean;
}

export const subscribeToDesign = async (
  designId: string,
  options?: DesignSubscriptionOptions
) => {
  const design = subscribeToDesignDoc(designId, options);
  const designMap = subscribeToDesignMap(designId);
  return Promise.all([design, designMap]);
};

export const subscribeToDesignDoc = async (
  docId: string,
  options?: DesignSubscriptionOptions
): Promise<any> => {
  const {isLegacy} = options ?? {};
  const ref = Utilities.collection(CONFIGURATION_DESIGN_DB_KEY).doc(docId);
  const subscriptionId = `design-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    const unsubscribe = await subscribeWithCallback(ref, (data: any) => {
      if (data) {
        if (isLegacy && data?.product) {
          store.dispatch(
            updateCartItem({
              id: docId,
              cartItem: getADUCartItem(data?.product, docId),
            })
          );
          store.dispatch(
            updateProjectCartItemIds({
              id: docId,
              cartItemIds: [docId],
            })
          );
        }
        store.dispatch(
          updateDesignConfiguration({id: docId, configuration: data})
        );
      }
    });
    storeSubscription(subscriptionId, unsubscribe);
  }
};

export const subscribeToDesignSnapshotDoc = async (docId: string) => {
  const ref = Utilities.collection(CONFIGURATION_SNAPSHOTS_DB_KEY).doc(docId);
  const subscriptionId = `design-snapshot-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    subscribeWithCallback(ref, (data: any) => {
      console.log("snapshot data", data);
      store.dispatch(updateDesignSnapshot({id: docId, snapshot: data}));
    }).then((unsubscribe: (() => void) | null) => {
      storeSubscription(subscriptionId, unsubscribe);
    });
  }
};

export const subscribeToDesignMap = async (docId: string) => {
  const ref = Utilities.collection(
    Configuration.Constants.CONFIGURATION_MAPS_DB_KEY
  ).doc(docId);
  const subscriptionId = `design-map-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    const unsubscribe = await subscribeWithCallback(ref, (data: any) => {
      if (data) {
        store.dispatch(updateDesignMap({id: docId, map: data}));
      }
    });
    storeSubscription(subscriptionId, unsubscribe);
  }
};
