import {useWindowSize} from "./useWindowSize";
import {useEffect} from "react";
import {
  calculateContainerWidth,
  calculateGutterWidthPercentage,
  calculateGutterWidthPixels,
  calculateNumberOfColumns,
} from "../generics/page/constants";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {store} from "../../../store";
import {
  updateColumnCount,
  updateContainerPadding,
  updateContainerWidth,
  updateGutterWidthPercentage,
  updateGutterWidthPixels,
  updatePageWidth,
} from "../slices/PageSlice";
import {scrollToTop} from "../navigation/_helpers";

const SHOULD_LOG = false;
export const getColumnWidthPercentage = (
  columnCount: number,
  gutterWidthPercentage: number
): number => {
  return (100 - (columnCount - 1) * gutterWidthPercentage) / columnCount;
};
export const getWidth = (
  size: number,
  columnCount: number,
  gutterWidthPercentage: number
): number => {
  if (size < 1) {
    /* Size cannot be less than 1 column wide. */
    if (SHOULD_LOG) {
      console.log("Size is less than 1");
    }
    return 0;
  } else if (size > columnCount) {
    /*
      We can not declare sizes for elements
      that are bigger than the number of total columns.
    */
    if (SHOULD_LOG) {
      console.log(
        "Size is greater than column count, Size: " +
          size +
          ", Count: " +
          columnCount
      );
    }

    return 0;
  } else if (size === 1) {
    /* Standard one column wide element */
    return getColumnWidthPercentage(columnCount, gutterWidthPercentage);
  } else {
    /*
      Multi-column elements will 'bridge' the gutter,
      so we need to include it in the calculation
     */
    return (
      size * getColumnWidthPercentage(columnCount, gutterWidthPercentage) +
      (size - 1) * gutterWidthPercentage
    );
  }
};
export const usePage = () => {
  const leftPadding = useSelector(
    (state: IStore) => state.page.additionalLeftPadding
  );
  const containerPadding = useSelector(
    (state: IStore) => state.page.containerPadding
  );
  const containerWidth = useSelector(
    (state: IStore) => state.page.containerWidth
  );
  const pageWidth = useSelector((state: IStore) => state.page.pageWidth);
  const columnCount = useSelector((state: IStore) => state.page.columnCount);
  const gutterWidthPercentage = useSelector(
    (state: IStore) => state.page.gutterWidthPercentage
  );
  const gutterWidthPixels = useSelector(
    (state: IStore) => state.page.gutterWidthPixels
  );
  /*
   * Mobile width values (anything under 768px) are entirely responsive.
   *  Because of this we need to on demand calculate widths for elements
   *  when screen size is within 0 - 768px.
   * */

  return {
    columnCount,
    gutterWidthPercentage,
    gutterWidthPixels,
    pageWidth,
    leftPadding,
    containerPadding,
    containerWidth,
    getColumnWidthPercentage,
    getWidth: (size: number) => {
      return getWidth(size, columnCount, gutterWidthPercentage);
    },
    isNatMobile: columnCount === 4,
  };
};

export const useColumnCount = () => {
  const columnCount = useSelector((state: IStore) => state.page.columnCount);
  return {
    isNatMobile: columnCount === 4,
    columnCount,
  };
};

export const usePageManager = () => {
  const {width} = useWindowSize();
  const leftPadding = useSelector(
    (state: IStore) => state.page.additionalLeftPadding
  );
  const pageWidth = useSelector((state: IStore) => state.page.pageWidth);
  const columnCount = useSelector((state: IStore) => state.page.columnCount);
  const dispatch = useDispatch();
  useEffect(() => {
    if (width) {
      const w = width - leftPadding;
      const pW = calculateContainerWidth(w);
      if (pW !== pageWidth) dispatch(updatePageWidth(pW));
      dispatch(updateContainerWidth(w));
    }
  }, [width, leftPadding, dispatch]);

  useEffect(() => {
    if (width && pageWidth) {
      const cP = (width - leftPadding - pageWidth) / 2;
      store.dispatch(updateContainerPadding(cP));
      const cC = calculateNumberOfColumns(pageWidth);
      store.dispatch(updateColumnCount(cC));
    }
  }, [width, pageWidth, leftPadding]);

  useEffect(() => {
    if (width && pageWidth && columnCount) {
      store.dispatch(
        updateGutterWidthPercentage(calculateGutterWidthPercentage(pageWidth))
      );
      store.dispatch(
        updateGutterWidthPixels(calculateGutterWidthPixels(pageWidth))
      );
    }
  }, [width, pageWidth, columnCount]);

  return {
    columnCount,
    pageWidth,
    leftPadding,
  };
};

export const useScrollPageToTopOnMount = () => {
  useEffect(() => {
    scrollToTop(false);
  }, []);
};
