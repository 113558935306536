import {productGroupLevelKey} from "../../../../utilities";
import {getProductRow} from "../../_shared/helper";
import {DesignStudioModifier} from "@natomas-org/core";

const getModifierRow = (
  modifier: any,
  products: any[],
  productModifiers: any,
  matrixEdits: any
) => {
  // FIXME: This is strange - it seems there are modifier ids that aren't in currentGroupModifiers
  if (!modifier) {
    return;
  }
  const cellKey = productGroupLevelKey + "_" + modifier?.id;
  const row: any[] = [
    {
      value: modifier,
      readOnly: true,
    },
    getCell(matrixEdits, cellKey, modifier),
  ];
  const productCells = products.map((product: any) => {
    const specificProductModifiers = productModifiers[product.id];
    const cellKey = product.id + "_" + modifier?.id;
    const s_modifier =
      specificProductModifiers != null
        ? specificProductModifiers[modifier?.id]
        : null;
    return getCell(matrixEdits, cellKey, s_modifier, product);
  });

  return [...row, ...productCells];
};

export const getDescriptionTable = (
  products: any,
  modifiers: DesignStudioModifier[],
  productModifiers: any,
  matrixEdits: any
) => {
  let table: any[] = [];
  table.push(getProductRow(products, true));

  modifiers.forEach((modifier: any) => {
    table.push(
      getModifierRow(modifier, products, productModifiers, matrixEdits)
    );
  });
  return table;
};

const getCellValue = (
  modifier: any,
  isProductModifier: boolean,
  editValue?: any
) => {
  return editValue != null
    ? editValue
    : isProductModifier
    ? modifier?.displayDescription
    : modifier?.displayDescriptionDefault;
};

const getCell = (
  matrixEdits: any,
  cellKey: any,
  modifier: any,
  product?: any
) => {
  const matrixEditValue =
    matrixEdits?.[cellKey] != null
      ? matrixEdits?.[cellKey]?.["description"]
      : null;
  let value = getCellValue(modifier, !!product, matrixEditValue);

  return {
    value: value,
    key: cellKey,
    valueType: "string",
  };
};
