import React from "react";
import {useState} from "react";
import {NatDoubleRightChevron} from "../../../icon/icons";
import {IFeatureMapSidebarItem, FeatureMapSideBarItem} from "./sidebarItem";
import {
  SideBarContainer,
  SideBarContentContainer,
  SideBarExpandSelector,
  SideBarTitle,
} from "./styles";

export const FeatureMapSideBar = (props: {
  title: string;
  items: IFeatureMapSidebarItem[];
}) => {
  const {title, items} = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <SideBarContainer expanded={expanded}>
      <SideBarExpandSelector
        expanded={expanded}
        onClick={() => setExpanded((current) => !current)}
      >
        {expanded && <SideBarTitle>{title}</SideBarTitle>}
        <NatDoubleRightChevron size={14} />
      </SideBarExpandSelector>
      <SideBarContentContainer expanded={expanded}>
        {items?.map((item, index: number) => {
          return (
            <FeatureMapSideBarItem
              key={`${index}`}
              item={item}
              index={`${index}`}
              isMenuExpanded={expanded}
              isLast={index === items?.length - 1}
            />
          );
        })}
      </SideBarContentContainer>
    </SideBarContainer>
  );
};
