import styled from "styled-components";
import {GRANITE, VILLA_APPLE_GREEN} from "../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const CatalogAddressControllerBlurb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
`;

export const CatalogAddressControllerBlurbLabel = styled.div`
  color: ${GRANITE};
  font-size: 14px;
`;

export const CatalogAddressControllerBlurbAddress = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
  cursor: pointer;
  font-size: 14px;
  font-family: ${FONT_FAMILY_MEDIUM};
  &:hover {
    color: ${VILLA_APPLE_GREEN};
    * {
      color: ${VILLA_APPLE_GREEN};
    }
  }
`;
