import React from "react";
import {DesignButton, TextContainer} from "./styles";
import styled from "styled-components";

export const TextButton = (props: any) => {
  let priceText = props.price;
  if (priceText == null) {
    priceText = "Included";
  }
  return (
    <FullTextContainer
      id={"modifier-" + props.buttonId}
      onClick={props.onClick}
      active={props.isSelected}
      disabled={props.isDisabled}
    >
      <TextContainer>
        <p className="button-text">{props.buttonText}</p>
        <p className="price">{priceText}</p>
      </TextContainer>
    </FullTextContainer>
  );
};

const FullTextContainer = styled(DesignButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 18px;
`;
