import {StudioSummaryProps} from "./helper";
import {CategoryHeader} from "../styles";
import React from "react";
import {StudioSummaryPage} from "../StudioSummaryPage";
import {StudioCategoryTop} from "../StudioCategoryTop";

export const StudioSummaryShell = (props: StudioSummaryProps) => {
  const {step, isMobile, summary} = props;
  const {structure, product} = summary ?? {};

  return (
    <div
      id={"category_step_container_" + step}
      style={{
        minHeight: "45vh",
        position: "relative",
      }}
    >
      <CategoryHeader>
        <StudioCategoryTop
          structure={structure}
          product={product}
          step={structure?.length}
          isMobile={isMobile}
          title={"Summary"}
          section={""}
        />
      </CategoryHeader>
      <StudioSummaryPage {...props} />
    </div>
  );
};
