// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useMap} from "../../../../_shared/hooks/useMap";
import {
  MAP_ANIMATION_DURATION_SECONDS,
  MAP_BOX_TOKEN,
} from "../../theme/constants";

import {
  animateMapHome,
  resetGLMapAnimationOptionsWithoutPreviousState,
  resetGLMapAnimationOptionsWithPreviousState,
  resetGLMapProjectToPreview,
  setGLMapSatelliteEnabled,
  setGLMapState,
} from "../../../../map/slices/mapSlice";
import {useDispatch} from "react-redux";
import {MapViewContainer} from "./styles";
import {
  addMapLayers,
  addMapMouseLayerEffects,
  blankGeoJSON,
  mouseLeaveMap,
  mouseLeaveProjects,
  projectPointClickAction,
  projectPointHoverAction,
  updateParcelData,
} from "./styleHelpers";
import {curve} from "./renderHelpers";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {VILLA_BLUE} from "../../../../_shared/colors";
import {
  DEFAULT_SF_VIEWPORT,
  ENABLE_SATELLITE_IN_3D,
  PROJECTS_IN_VIEWPORT_SEARCH_DELAY_MS,
  SATELLITE_THRESHOLD,
} from "./constants";
import {MapboxGeoJSONFeature} from "mapbox-gl";
import {throttleCallback} from "../../../../_shared/Utils";
import {
  Address,
  IAddressDetails,
  IGLMapState,
  INearbyProjectFeatureDetails,
  isBlankString,
} from "@natomas-org/core";
import {IPublicProjectInfoModalPayload} from "../ProjectDetailsModal/constants";
import {AREA_MAP_PAST_PROJECTS_NAV} from "../../../../map/constants";
import {useAddress} from "../../../../_shared/hooks/useAddress";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useSelectedProduct} from "../../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import {useCompletedProjects} from "../../../../_shared/hooks/useCompletedProjects";
import {getFeatures} from "./getFeatures";

mapboxgl.accessToken = MAP_BOX_TOKEN;

const AreaMap = (props: {
  setMapProjectModalPayload: React.Dispatch<
    React.SetStateAction<IPublicProjectInfoModalPayload | null>
  >;
}) => {
  const {setMapProjectModalPayload} = props;
  const mapContainer = useRef<any>();
  const map = useRef<any>();
  const {publicAddress: address, areaSearch} = useAddress();
  const {glMapState, isLoading, parcelView} = useMap();
  const {completedProjects} = useCompletedProjects();
  const dispatch = useDispatch();
  const selectedProduct = useSelectedProduct();
  const {isAdmin} = useCurrentUser();
  const {isNatMobile} = usePage();
  const [loadedMap, setLoadedMap] = useState(false);
  const [loadedStyle, setLoadedStyle] = useState(false);
  const [lng, setLng] = useState(
    Address.getLongitude(address ?? undefined) ??
      glMapState?.mLng ??
      DEFAULT_SF_VIEWPORT.mLng
  );
  const [lat, setLat] = useState(
    Address.getLatitude(address ?? undefined) ??
      glMapState?.mLat ??
      DEFAULT_SF_VIEWPORT.mLat
  );
  const [zoom, setZoom] = useState(
    Address.getCoordinates(address ?? undefined) ||
      parcelView ||
      !glMapState?.zoom
      ? DEFAULT_SF_VIEWPORT.zoom
      : glMapState?.zoom
  );
  const [pitch, setPitch] = useState(
    glMapState?.pitch ?? DEFAULT_SF_VIEWPORT.pitch
  );
  const [bearing, setBearing] = useState(
    glMapState?.bearing ?? DEFAULT_SF_VIEWPORT.bearing
  );
  const [moveend, setMoveend] = useState<number>(0);
  const [lastQueryTime, setLastQueryTime] = useState<number>(0);
  const [lastCoordinateUpdateTime, setLastCoordinateUpdateTime] = useState(0);
  const [allProjects, setAllProjects] = useState<any>(blankGeoJSON);
  const [parcelData, setParcelData] = useState<any>(blankGeoJSON);
  const [currentAddressMarker] = useState(
    new mapboxgl.Marker({
      color: VILLA_BLUE,
      cursor: "pointer",
      rotation: 0,
    })
  );
  // To disable completed projects in the sidebar on 3D map, change PAST_PROJECTS_NAV value to false
  const PAST_PROJECTS_NAV = useMemo(() => {
    return AREA_MAP_PAST_PROJECTS_NAV && !parcelView;
  }, [selectedProduct, parcelView]);
  const PAST_PROJECTS_NAV_REF = useRef(PAST_PROJECTS_NAV);
  useEffect(() => {
    PAST_PROJECTS_NAV_REF.current = PAST_PROJECTS_NAV && isAdmin;
  }, [PAST_PROJECTS_NAV, isAdmin]);
  const loadedAddressDetails = useRef<IAddressDetails | undefined>(
    areaSearch?.address
  );
  const viewportProjects = useRef<INearbyProjectFeatureDetails[]>([]);

  // useEffects
  useEffect(() => {
    if (
      !map.current ||
      !loadedMap ||
      !map.current.getLayer("projects-point") ||
      !map.current.getLayer("projects") ||
      !PAST_PROJECTS_NAV
    ) {
      return;
    }
    if (
      glMapState.projectToPreview?.projectDetails &&
      viewportProjects.current?.length > 0 &&
      typeof glMapState.projectToPreview.projectDetails === "object"
    ) {
      const featureToDisplay = viewportProjects.current.find(
        (item: INearbyProjectFeatureDetails) => {
          return (
            typeof glMapState.projectToPreview?.projectDetails === "object" &&
            "code" in glMapState.projectToPreview?.projectDetails &&
            item.key === glMapState.projectToPreview?.projectDetails?.code
          );
        }
      );
      if (
        featureToDisplay &&
        featureToDisplay?.feature &&
        glMapState.projectToPreview?.fullView === false
      ) {
        projectPointHoverAction(featureToDisplay?.feature, map);
      } else if (
        featureToDisplay?.feature &&
        glMapState.projectToPreview?.fullView === true
      ) {
        projectPointClickAction(
          featureToDisplay?.feature,
          isAdmin,
          map,
          dispatch,
          false,
          setMapProjectModalPayload,
          isNatMobile
        );
        mouseLeaveMap(map, dispatch, PAST_PROJECTS_NAV_REF);
      }
    } else if (!glMapState.projectToPreview?.projectDetails) {
      mouseLeaveProjects(map);
      mouseLeaveMap(map, dispatch, PAST_PROJECTS_NAV_REF);
    }
  }, [
    glMapState.projectToPreview?.fullView,
    glMapState.projectToPreview?.projectDetails,
  ]);
  useEffect(() => {
    //keep ref up-to-date
    loadedAddressDetails.current = areaSearch?.address;
  }, [areaSearch]);
  useEffect(() => {
    //keep ref up-to-date
    PAST_PROJECTS_NAV_REF.current = PAST_PROJECTS_NAV;
  }, [PAST_PROJECTS_NAV]);
  useEffect(() => {
    // This useEffect runs once, sets all public projects as features
    if (!!completedProjects) {
      setAllProjects({
        features: getFeatures(completedProjects),
        type: "FeatureCollection",
      });
    }
  }, [completedProjects]);
  useEffect(() => {
    if (loadedStyle && loadedMap) {
      addMapLayers(map, allProjects, parcelData);
      updateProjectsInViewport();
    }
  }, [loadedStyle, loadedMap]);
  useEffect(() => {
    if (!!map.current?.getSource("clusters")) {
      map.current?.getSource("clusters")?.setData(allProjects);
    }
  }, [allProjects, loadedMap, loadedStyle]);
  useEffect(() => {
    if (!!map.current?.getSource("clusters")) {
      map.current.getSource("parcels")?.setData(parcelData);
    }
  }, [parcelData, loadedMap, loadedStyle]);
  useEffect(() => {
    if (
      !isBlankString(areaSearch?.address?.street) &&
      !isBlankString(areaSearch?.address?.street_number) &&
      map.current &&
      loadedMap
    ) {
      currentAddressMarker
        .setLngLat([
          areaSearch?.address?.longitude,
          areaSearch?.address?.latitude,
        ])
        .addTo(map.current);
      currentAddressMarker.getElement().addEventListener("click", () => {
        dispatch(resetGLMapProjectToPreview());
        dispatch(animateMapHome({}));
      });
    }
  }, [areaSearch, loadedMap]);

  const updateProjectsInViewport = useMemo(() => {
    return throttleCallback(() => {
      if (map.current?.getLayer("projects-point") && PAST_PROJECTS_NAV) {
        setTimeout(() => {
          if (
            !map.current ||
            !map.current.getLayer("projects") ||
            !map.current.getLayer("projects-point") ||
            parcelView
          ) {
            return;
          }
          const arrayToReturn: INearbyProjectFeatureDetails[] = [];
          const features = map.current.queryRenderedFeatures(undefined, {
            layers: ["projects", "projects-point"],
          });
          features?.forEach((feature: MapboxGeoJSONFeature) => {
            if (feature?.layer?.id === "projects-point") {
              if (
                feature?.properties?.code &&
                !arrayToReturn.find(
                  (project) => project.key === feature?.properties?.code
                )
              ) {
                arrayToReturn.push({
                  key: feature?.properties?.code,
                  feature: feature,
                });
              }
            }
          });
          viewportProjects.current = arrayToReturn;
          dispatch(
            setGLMapState({
              projectsInViewport: Object.values(viewportProjects.current),
            } as IGLMapState)
          );
        }, PROJECTS_IN_VIEWPORT_SEARCH_DELAY_MS);
      }
    }, PROJECTS_IN_VIEWPORT_SEARCH_DELAY_MS);
  }, [PAST_PROJECTS_NAV, dispatch, parcelView]);

  // SINGLE CALL USE-EFFECTS
  useEffect(() => {
    // This calls "updateParcelData", which refreshes layers and updates parcel boundary data
    if (!map.current || !loadedMap || !loadedStyle || isNatMobile) {
      return;
    }
    let controller = new AbortController();
    updateParcelData(
      controller.signal,
      map,
      loadedMap,
      allProjects,
      zoom,
      lastQueryTime,
      setLastQueryTime,
      setParcelData
    );
    return () => {
      controller?.abort();
    };
  }, [moveend, loadedStyle, allProjects]);
  useEffect(() => {
    // This is called once and initializes the map
    if (map.current || !lng || !lat || !glMapState) {
      return; // initialize map only once
    } else {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style:
          !glMapState.satelliteEnabled && ENABLE_SATELLITE_IN_3D
            ? "mapbox://styles/mapbox/satellite-v9"
            : "mapbox://styles/jackconnolly415/cl8aacu6n002415qppbmydjoy",
        pitch: pitch,
        bearing: bearing,
        center: [lng, lat],
        zoom: zoom,
        minZoom: 3.7,
      });

      map.current.on("styledata", () => {
        let counter = 0;
        const waiting = () => {
          if (!map.current.isStyleLoaded() && counter < 27) {
            setTimeout(waiting, 200);
          } else if (map.current.isStyleLoaded()) {
            setLoadedStyle(true);
          }
          counter += 1;
        };
        waiting();
      });
      map.current.addControl(new mapboxgl.NavigationControl(), "bottom-left");
      map.current.on("load", () => {
        map.current.resize();
        setLng(map.current.getCenter()?.lng?.toFixed(18));
        setLat(map.current.getCenter()?.lat?.toFixed(18));
        setBearing(map.current.getBearing()?.toFixed(2));
        setPitch(map.current.getPitch()?.toFixed(2));
        setZoom(map.current.getZoom().toFixed(2));
        addMapMouseLayerEffects(
          map,
          loadedAddressDetails,
          dispatch,
          isAdmin,
          PAST_PROJECTS_NAV_REF,
          setMapProjectModalPayload,
          isNatMobile
        );
        setLoadedMap(true);
      });
      map.current.on("zoom", () => {
        setZoom(map.current.getZoom().toFixed(2));
      });
      map.current.on("zoomend", () => {
        setZoom(map.current.getZoom().toFixed(2));
        setMoveend(map.current.getZoom().toFixed(2));
      });
      map.current.on("move", () => {
        setLng(map.current.getCenter()?.lng?.toFixed(18));
        setLat(map.current.getCenter()?.lat?.toFixed(18));
      });
      map.current.on("moveend", () => {
        setLng(map.current.getCenter()?.lng?.toFixed(18));
        setLat(map.current.getCenter()?.lat?.toFixed(18));
        setMoveend(map.current.getCenter()?.lng?.toFixed(18));
      });
      map.current.on("rotate", () => {
        setBearing(map.current.getBearing()?.toFixed(2));
      });
      map.current.on("pitch", () => {
        setPitch(map.current.getPitch()?.toFixed(2));
      });
      map.current.on("pitchend", () => {
        setPitch(map.current.getPitch()?.toFixed(2));
      });
    }
  });

  // EXTERNALLY TRIGGERED VISUAL ACTIONS
  useEffect(() => {
    let didAnimate = false;
    if (map.current && glMapState?.animation) {
      setLastCoordinateUpdateTime(new Date().getTime());
      let previousMapState: any = {};
      if (
        glMapState?.animation?.zoom &&
        glMapState?.animation?.lng &&
        glMapState?.animation?.lat
      ) {
        previousMapState = Object.assign(
          {},
          {
            zoom: glMapState?.animation?.zoom,
            lat: glMapState?.animation?.lat,
            lng: glMapState?.animation?.lng,
          }
        );
        // For animating using fly animation options
        setTimeout(() => {
          map.current.flyTo({
            curve: 1,
            pitch: glMapState?.animation?.pitch ?? 0,
            bearing: glMapState?.animation?.bearing ?? 0,
            duration:
              glMapState?.animation?.duration ??
              MAP_ANIMATION_DURATION_SECONDS * 1000,
            zoom: glMapState?.animation?.zoom,
            center: [glMapState?.animation?.lng, glMapState?.animation?.lat],
            essential: true,
          });
        }, glMapState?.animation?.delay ?? 0);
        didAnimate = true;
      } else if (glMapState?.animation?.bounds) {
        previousMapState = Object.assign(
          {},
          {
            bounds: glMapState?.animation?.bounds,
          }
        );
        // For animating using bounds animation options
        setTimeout(() => {
          map.current.fitBounds(glMapState?.animation?.bounds, {
            padding: glMapState?.animation?.padding ?? 127,
            pitch: glMapState?.animation?.pitch ?? 0,
            bearing: glMapState?.animation?.bearing ?? 0,
            duration:
              glMapState?.animation?.duration ??
              MAP_ANIMATION_DURATION_SECONDS * 1000,
            curve: curve,
          });
        }, glMapState?.animation?.delay ?? 0);
        didAnimate = true;
      } else if (glMapState?.animation?.easeTo) {
        previousMapState = Object.assign(
          {},
          {
            easeTo: glMapState?.animation?.easeTo,
          }
        );
        // For animating using bounds animation options
        setTimeout(() => {
          map.current.easeTo({
            zoom: glMapState?.animation?.easeTo,
            duration:
              glMapState?.animation?.duration ??
              MAP_ANIMATION_DURATION_SECONDS * 1000,
          });
        }, glMapState?.animation?.delay ?? 0);
        didAnimate = true;
      }
      if (didAnimate) {
        setTimeout(
          () => {
            dispatch(
              setGLMapState(
                Object.assign({
                  zoom: Number(map.current.getZoom().toFixed(2)),
                  mLat: Number(map.current.getCenter()?.lat?.toFixed(18)),
                  mLng: Number(map.current.getCenter()?.lng?.toFixed(18)),
                })
              )
            );
            setMoveend(moveend * 2 + 1);
            if (glMapState?.animation?.doNotSavePreviousState === true) {
              dispatch(resetGLMapAnimationOptionsWithoutPreviousState());
            } else {
              dispatch(
                resetGLMapAnimationOptionsWithPreviousState(previousMapState)
              );
            }
            updateProjectsInViewport();
          },
          glMapState?.animation?.duration && glMapState?.animation?.delay
            ? glMapState?.animation?.duration + glMapState?.animation?.delay
            : MAP_ANIMATION_DURATION_SECONDS * 2000
        );
      }
    }
  }, [glMapState?.animation]);

  useEffect(() => {
    // This keeps mapState in sync with local state variables limited to only 1/second
    if (
      !map.current ||
      !lat ||
      !lng ||
      !zoom ||
      parcelView ||
      !!glMapState?.animation?.duration
    ) {
      return;
    }
    const time = new Date().getTime();
    if (
      time - lastCoordinateUpdateTime >
      MAP_ANIMATION_DURATION_SECONDS * 1000
    ) {
      dispatch(
        setGLMapState(
          Object.assign({}, glMapState, {
            zoom: Number(map.current.getZoom().toFixed(2)),
            mLat: Number(map.current.getCenter()?.lat?.toFixed(18)),
            mLng: Number(map.current.getCenter()?.lng?.toFixed(18)),
          })
        )
      );
      updateProjectsInViewport();
      setLastCoordinateUpdateTime(time);
    }
  }, [zoom, lat, lng, parcelView, loadedStyle]);
  useEffect(() => {
    if (!map.current || !ENABLE_SATELLITE_IN_3D) {
      return;
    }
    setLoadedStyle(false);
    if (glMapState?.satelliteEnabled) {
      map.current.setStyle("mapbox://styles/mapbox/satellite-v9");
    } else {
      // TODO change soon
      map.current.setStyle(
        "mapbox://styles/jackconnolly415/cl8aacu6n002415qppbmydjoy"
      );
    }
  }, [map.current, glMapState?.satelliteEnabled]);
  useEffect(() => {
    if (ENABLE_SATELLITE_IN_3D) {
      if (zoom > SATELLITE_THRESHOLD && !glMapState?.satelliteEnabled) {
        dispatch(setGLMapSatelliteEnabled(true));
      }
      if (zoom < SATELLITE_THRESHOLD && glMapState?.satelliteEnabled) {
        dispatch(setGLMapSatelliteEnabled(false));
      }
    }
  }, [zoom]);

  return (
    <>
      <div
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <MapViewContainer ref={mapContainer} className="map-container" />
      </div>
    </>
  );
};

export default AreaMap;
