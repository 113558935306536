import {createSlice} from "@reduxjs/toolkit";
import {IAdminStudioManagerSlice} from "./interfaces";

const getInitialState = (): IAdminStudioManagerSlice => {
  return {
    editingCategory: undefined,
    editingModifier: undefined,
    editingModifierGroup: undefined,
    editingModifierGroupOverrides: undefined,
    editingModifierOverrides: undefined,
    editingProduct: undefined,
    isEditingConfigurationPage: false,
    isEditingOptionKeys: false,
    isEditingOptionValues: false,
    isEditingProduct: false,
  };
};

const adminStudioManagerSlice = createSlice({
  name: "adminStudioManager",
  initialState: getInitialState(),
  reducers: {
    clearEditing: (state) => {
      state.editingModifier = null;
      state.editingModifierGroup = null;
      state.editingModifierGroupOverrides = null;
      state.editingModifierOverrides = null;
      state.editingProduct = null;
      state.editingCategory = null;
    },
    editModifier: (state, action) => {
      state.editingModifier = action.payload;
    },
    editModifierGroup: (state, action) => {
      state.editingModifierGroup = action.payload;
    },
    editModifierGroupOverrides: (state, action) => {
      state.editingModifierGroupOverrides = action.payload;
    },
    editModifierOverrides: (state, action) => {
      state.editingModifierOverrides = action.payload;
    },
    editCategory: (state, action) => {
      state.editingCategory = action.payload;
    },
    editingOptionKeys: (state, action) => {
      state.isEditingOptionKeys = action.payload;
    },
    editProduct: (state, action) => {
      state.editingProduct = action.payload;
    },
    editingOptionValues: (state, action) => {
      state.isEditingOptionValues = action.payload;
    },
  },
});

export const adminStudioManagerReducer = adminStudioManagerSlice.reducer;
export const {
  clearEditing,
  editCategory,
  editModifier,
  editModifierGroup,
  editModifierGroupOverrides,
  editModifierOverrides,
  editProduct,
  editingOptionKeys,
  editingOptionValues,
} = adminStudioManagerSlice.actions;
