import React, {useEffect, useMemo, useState} from "react";
import {NatCartItemRowLink, NatCartItemRowLinks} from "../styles";
import {ICartItem} from "@natomas-org/core";
import useActivitySliceDispatcher from "../../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import {CartRowContainer} from "../../../styles";

interface CartSectionItemLinksProps {
  isMobile?: boolean;
  cartItem: ICartItem;
  editDesign: {
    show: boolean;
    label: string;
    event: () => void;
  };
  move: {
    show: boolean;
    color: string;
    label: string;
    event: () => void;
  };
  copyUnit: {
    show: boolean;
    label: string;
    event: () => void;
  };
  editUnit: {
    show: boolean;
    label: string;
    event: () => void;
  };
  previewItem: (cartItem: ICartItem) => void;
  QuantityComponent: React.ReactNode | undefined;
}

const CartSectionItemLinks = (props: CartSectionItemLinksProps) => {
  const {
    isMobile,
    cartItem,
    copyUnit,
    editUnit,
    move,
    previewItem,
    editDesign,
    QuantityComponent,
  } = props;
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (processing) {
      setTimeout(() => {
        setProcessing(false);
      }, 2000);
    }
  }, [processing]);

  const LinksComponent = useMemo(() => {
    return (
      <NatCartItemRowLinks isMobile={isMobile}>
        <NatCartItemRowLink
          hidden={!editDesign.show}
          onClick={() => {
            setActiveCartItemById(cartItem?.id);
            previewItem(cartItem);
          }}
        >
          {editDesign.label}
        </NatCartItemRowLink>
        <NatCartItemRowLink
          hidden={!editUnit.show}
          onClick={() => editUnit.event()}
        >
          {editUnit.label}
        </NatCartItemRowLink>
        <NatCartItemRowLink
          onClick={() => {
            if (!processing) {
              setProcessing(true);
              copyUnit.event();
            }
          }}
          hidden={!copyUnit.show}
        >
          {processing ? "Duplicating..." : copyUnit.label}
        </NatCartItemRowLink>
        <NatCartItemRowLink
          onClick={() => move.event()}
          hidden={!move.show}
          color={move.color}
        >
          {move.label}
        </NatCartItemRowLink>
      </NatCartItemRowLinks>
    );
  }, [
    cartItem,
    copyUnit,
    editDesign.label,
    editDesign.show,
    editUnit,
    isMobile,
    move,
    previewItem,
    processing,
    setActiveCartItemById,
  ]);

  if (QuantityComponent) {
    return (
      <CartRowContainer
        alternateDirection={"column"}
        alternateGap={8}
        style={{alignItems: "flex-end"}}
      >
        {LinksComponent}
        {QuantityComponent}
      </CartRowContainer>
    );
  }

  return <>{LinksComponent}</>;
};

export default CartSectionItemLinks;
