import React from "react";

export interface FactoryInventoryTextBoxInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: string;
}

const FactoryInventoryTextBoxInput = (
  props: FactoryInventoryTextBoxInputProps
) => {
  const {id, onChange, initialValue} = props;
  return (
    <textarea
      id={id}
      name={id}
      onChange={(e) => {
        const value: string = e?.target?.value as unknown as string;
        onChange(value);
      }}
      value={initialValue}
      spellCheck={true}
      rows={5}
      cols={50}
    />
  );
};

export default FactoryInventoryTextBoxInput;
