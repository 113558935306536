import React, {useMemo} from "react";
import {
  IProjectStage,
  IProjectTeamMemberContactInfo,
  Person,
  TeamMemberRole,
} from "@natomas-org/core";
import {
  HCCardContainer,
  HCCardImage,
  HCCardInfoWrapper,
  HCCardLink,
  HCCardLinkContainer,
  HCCardLinkWrapper,
  HCCardSubTitle,
  HCCardTitle,
  HCFooter,
} from "./styles";
import {AiOutlineCalendar, AiOutlineMail, AiOutlinePhone} from "react-icons/ai";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import logo from "../../../../../assets/logos/villa-logo-white.png";
import {VILLA_SLATE} from "../../../../_shared/colors";
import TeamMemberPhoto from "../../../../../0-app/1-container/team-member-photo";

export type TeamMemberCardLayoutStyle = "details" | "full-details" | "compact";

export const DefaultProfilePicture = (
  <img
    style={{
      backgroundColor: VILLA_SLATE,
      height: "100%",
      width: "100%",
      padding: "10%",
    }}
    src={logo}
    alt={"Villa Homes"}
  />
);
export const TeamMemberCard = (props: {
  teamContact?: IProjectTeamMemberContactInfo | null;
  launchCalendly?: () => void;
  shouldLookMobile: boolean;
  layout: TeamMemberCardLayoutStyle;
}) => {
  const {teamContact, launchCalendly, shouldLookMobile, layout} = props;
  const {info} = useActiveProject();
  const cardText = useMemo(() => {
    if (info?.status_details?.stage === IProjectStage.PRE_PSA) {
      return `Your Home Consultant will be introduced in 24 hours.`;
    }
    return "Your Villa Representative will be introduced in 24 hours.";
  }, [info?.status_details?.stage]);
  if (teamContact == null) {
    if (layout === "compact") {
      return null;
    }
    return (
      <HCCardContainer shouldLookMobile={shouldLookMobile} layout={layout}>
        <HCCardImage>
          <TeamMemberPhoto email={undefined} />
        </HCCardImage>
        <HCCardInfoWrapper>
          <HCCardSubTitle>{cardText}</HCCardSubTitle>
          <HCFooter>Keep an eye out for an email!</HCFooter>
        </HCCardInfoWrapper>
      </HCCardContainer>
    );
  }

  const {personInfo, role} = teamContact;

  switch (layout) {
    case "compact": {
      return (
        <HCCardContainer shouldLookMobile={shouldLookMobile} layout={layout}>
          <HCCardImage>
            <TeamMemberPhoto email={personInfo?.contact?.email ?? undefined} />
          </HCCardImage>
          <HCCardInfoWrapper>
            <HCCardTitle>{Person.getFullName(personInfo)}</HCCardTitle>
            <HCCardSubTitle>
              {role === TeamMemberRole.UNKNOWN ? "" : role}
            </HCCardSubTitle>
          </HCCardInfoWrapper>
        </HCCardContainer>
      );
    }
  }

  return (
    <HCCardContainer shouldLookMobile={shouldLookMobile} layout={layout}>
      <HCCardImage>
        <TeamMemberPhoto email={personInfo?.contact?.email ?? undefined} />
      </HCCardImage>
      <HCCardInfoWrapper>
        <HCCardTitle>{Person.getFullName(personInfo)}</HCCardTitle>
        <HCCardSubTitle>{role}</HCCardSubTitle>
        <HCCardLinkContainer>
          <HCCardLinkWrapper hidden={true}>
            <HCCardLink
              hidden={!launchCalendly}
              href={"#"}
              onClick={launchCalendly}
              data-tip
              data-for={"tooltip-schedule-call"}
            >
              <AiOutlineCalendar /> Schedule a call with{" "}
              {Person.getFirstName(personInfo)}
            </HCCardLink>
          </HCCardLinkWrapper>
          <HCCardLinkWrapper>
            <HCCardLink
              hidden={!Person.getContactEmail(personInfo)}
              href={`mailto:${Person.getContactEmail(personInfo)}`}
              data-tip
              data-for={"tooltip-email"}
            >
              <AiOutlineMail /> Email {Person.getFirstName(personInfo)}
            </HCCardLink>
          </HCCardLinkWrapper>
          <HCCardLinkWrapper>
            <HCCardLink
              hidden={!Person.getPrimaryContactPhoneNumber(personInfo)}
              href={`tel:${Person.getPrimaryContactPhoneNumber(personInfo)}`}
              data-tip
              data-for={"tooltip-call"}
            >
              <AiOutlinePhone /> Call {Person.getFirstName(personInfo)}
            </HCCardLink>
          </HCCardLinkWrapper>
        </HCCardLinkContainer>
      </HCCardInfoWrapper>
    </HCCardContainer>
  );
};
