import React from "react";
import {StudioCategory} from "../index";
import {StudioCategoryProps} from "./helper";

export const StudioCategoryShell = (props: StudioCategoryProps) => {
  const {step} = props;

  return (
    <div
      id={"category_step_container_" + step}
      style={{
        minHeight: "45vh",
        position: "relative",
      }}
    >
      <StudioCategory {...props} />
    </div>
  );
};
