import {useCatalogSession} from "./useCatalogSession/useCatalogSession";
import {useSelectedFactoryLine} from "./useSelectedFactoryLine";

export const useSelectedProduct = () => {
  const {selectedProductId} = useCatalogSession();
  const {products, inventory} = useSelectedFactoryLine();
  return selectedProductId
    ? inventory?.[selectedProductId] ?? products?.[selectedProductId]
    : undefined;
};
