import {Elements, useStripe} from "@stripe/react-stripe-js";
// TODO Failed import from Stripe?
import {getStripeElementPromise} from "../stripe";
import {NatButton, NatomasButtonProps} from "./button";
import {formNewURL} from "../navigation/_helpers";
import {THANK_YOU_PAGE_ID} from "../../portal/_shared/navigation/constants";
import {CustomerJourneyStepName, useTracking} from "../hooks/useTracking";
import {ICreateCheckoutSessionRequest} from "@natomas-org/core";
import React from "react";
import {createStripeSession} from "../../../database/firebase/api/stripe";
import {URL_PARAM_KEY_PAGE_ID} from "@natomas-org/service";
import {IUTMType} from "../slices/types/UTMSliceType";

export interface IStripeCheckoutButtonOptions {
  buttonOptions: NatomasButtonProps;
  checkoutRequest: ICreateCheckoutSessionRequest;
}

export const StripeCheckoutButton = (props: IStripeCheckoutButtonOptions) => {
  return (
    <Elements stripe={getStripeElementPromise()}>
      <StripeButton {...props} />
    </Elements>
  );
};

export const successfulPaymentURL = () => {
  return formNewURL({
    params: [
      {key: "proposalFeePaid", value: "true"},
      {key: URL_PARAM_KEY_PAGE_ID, value: THANK_YOU_PAGE_ID},
      {key: IUTMType.utm_medium, value: "deposit"},
      {key: IUTMType.utm_campaign, value: "project_proposal"},
      {key: IUTMType.utm_source, value: "stripe"},
    ],
    useAbsoluteURL: true,
  });
};

export const canceledPaymentURL = () => {
  return formNewURL({
    params: [{key: "proposalFeePaid", value: "false"}],
    useAbsoluteURL: true,
  });
};

const StripeButton = (props: IStripeCheckoutButtonOptions) => {
  const stripe = useStripe();
  const {client_reference_id, line_item_ids, email, cancel_url, success_url} =
    props.checkoutRequest;
  const {trackJourneyStep} = useTracking();

  return (
    <NatButton
      spinnerEnabled={true}
      {...props.buttonOptions}
      clickEvent={async () => {
        trackJourneyStep(CustomerJourneyStepName.PAYMENT_APPROACHED);

        const session = await createStripeSession(props.checkoutRequest);

        if (session.sessionId) {
          return stripe
            ?.redirectToCheckout({
              sessionId: session.sessionId,
            })
            .catch((e: any) => {
              console.error(e);
              return null;
            });
        }

        return stripe
          ?.redirectToCheckout({
            lineItems:
              line_item_ids?.map((lineItemId: string) => {
                return {price: lineItemId, quantity: 1};
              }) ?? [],
            clientReferenceId: client_reference_id,
            customerEmail: email,
            mode: "payment",
            successUrl: success_url,
            cancelUrl: cancel_url,
          })
          .catch((e: any) => {
            console.error(e);
            return null;
          });
      }}
    />
  );
};
