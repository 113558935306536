import {Address} from "@natomas-org/core";
import React, {useMemo} from "react";
import {
  ProjectCardContainer,
  ProjectCardContentContainer,
  Title,
} from "./styles";
import {useProject} from "../../../../_shared/hooks/useProject/useProject";
import {useCartItems} from "../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import CartItemProductInfo from "./CartItemProductInfo";

export const ProjectSelectionCard = (props: {
  active: boolean;
  projectId: string | undefined;
  handleClick: () => void;
}) => {
  // TODO Update this to new structure
  const {handleClick, active, projectId} = props;
  const {address, cartItemIds, cartItemCount, info} = useProject(projectId);
  const cartItems = useCartItems(cartItemIds);

  const settings = useMemo(() => {
    return {
      count: `Products (${cartItemCount})`,
    };
  }, [cartItemCount]);

  return (
    <ProjectCardContainer active={active} onClick={handleClick}>
      <ProjectCardContentContainer>
        <Title>
          {address ? Address.getStreetAndCity(address) : "Unknown Address"}
        </Title>
        <div hidden={!settings.count}>{settings.count}</div>
        <div>
          {cartItems?.map((item, index) => {
            if (index > 4) {
              return null;
            } else if (index === 4) {
              return "and more...";
            } else {
              return <CartItemProductInfo item={item} key={index} />;
            }
          })}
        </div>
      </ProjectCardContentContainer>
    </ProjectCardContainer>
  );
};
