import React, {useCallback, useEffect} from "react";
import {PORTAL_UNIT_VIEW_ID} from "../HCPTitleNav/navigation_ids";
import {IHCPViewProps} from "../interfaces";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {DesignConfirmationStatement} from "../styles";
import {DesignSelections} from "./DesignSelections";
import {BiLockAlt} from "react-icons/bi";
import {CONTAINER_BACKGROUND_COLOR, GRANITE} from "../../../../_shared/colors";
import {CTADetailsSection} from "../../SSDDashboardView/components/ANDY-components/CTADetailsSection";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {UnitTitle} from "./SectionSubtitle";
import {PortalSection} from "../../../_shared/components/PortalSection";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import useActivitySlice from "../../../../_shared/hooks/useActivity/useActivitySlice";
import useCartLibrary from "../../../../_shared/hooks/useCart/useCartLibrary";
import {useProject} from "../../../../_shared/hooks/useProject/useProject";
import {useCartItems} from "../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import {ICartItem} from "@natomas-org/core";
import VCL from "@natomas-org/villa-component-library";
import {StudioDisclaimerContent} from "../../../../studio/_shared/components/StudioDisclaimerModal/constant";

export const HCPUnitView = (props: IHCPViewProps) => {
  const {cartItemIds} = useActiveProject();
  if (cartItemIds.length < 1) {
    return <HCPUnitViewNoUnit {...props} />;
  }
  return (
    <HCPUnitViewWrapper viewProps={props}>
      <HCPUnitViewSingleUnit {...props} />
    </HCPUnitViewWrapper>
  );
};

const HCPUnitViewWrapper = (props: {
  children: React.ReactNode;
  viewProps: IHCPViewProps;
}) => {
  return (
    <PortalSection
      headerTitle={"Home & Design"}
      width={props.viewProps.width}
      sectionId={PORTAL_UNIT_VIEW_ID}
      ignorePageElement={true}
    >
      {props.children}
      <PageContainer>
        <VCL.Components.Atoms.Text
          text={StudioDisclaimerContent}
          style={{color: GRANITE, cursor: "default"}}
        />
      </PageContainer>
    </PortalSection>
  );
};

const HCPUnitViewNoUnit = (props: IHCPViewProps) => {
  const {to} = useNavigation();
  return (
    <PortalSection
      headerTitle={"Home & Design"}
      width={props.width}
      sectionId={PORTAL_UNIT_VIEW_ID}
      ignorePageElement={true}
    >
      <PageContainer backgroundColor={CONTAINER_BACKGROUND_COLOR}>
        <CTADetailsSection />
        <NatButton
          id={"design-selections-catalog-cta"}
          label={"View all homes"}
          type={"button"}
          option={StyleOption.PRIMARY}
          clickEvent={() => {
            to(NavigationPaths.CATALOG);
          }}
        />
        <br />
        <br />
      </PageContainer>
    </PortalSection>
  );
};

const HCPUnitViewSingleUnit = React.memo((props: IHCPViewProps) => {
  const {activeCartItemId} = useActivitySlice();
  const {activeProjectCartItemIds, projectId} = useCartLibrary();
  const {isProjectLocked} = useProject(projectId);
  const cartItems = useCartItems(activeProjectCartItemIds);
  const [selectedCartItem, setSelectedCartItem] = React.useState<
    ICartItem | undefined
  >(undefined);

  const setCartItemById = useCallback(
    (cartItemId: string) => {
      const activeCartItem = cartItems.find(
        (cartItem) => cartItem.id === cartItemId
      );
      if (activeCartItem) {
        setSelectedCartItem(activeCartItem);
      } else {
        setSelectedCartItem(cartItems[0]);
      }
    },
    [cartItems]
  );

  const setCartItemByDesignId = useCallback(
    (cartItemId: string) => {
      const activeCartItem = cartItems.find(
        (cartItem) => cartItem?.details?.configurationId === cartItemId
      );
      if (activeCartItem) {
        setSelectedCartItem(activeCartItem);
      } else {
        setSelectedCartItem(cartItems[0]);
      }
    },
    [cartItems]
  );

  useEffect(() => {
    if (!selectedCartItem) {
      if (cartItems?.length > 0) {
        if (activeCartItemId) {
          setCartItemById(activeCartItemId);
        } else {
          setSelectedCartItem(cartItems[0]);
        }
      }
    } else if (!activeProjectCartItemIds?.includes(selectedCartItem?.id)) {
      const firstProjectCartItemId = activeProjectCartItemIds?.[0];
      if (firstProjectCartItemId) {
        setCartItemById(firstProjectCartItemId);
      }
    }
  }, [
    cartItems,
    activeCartItemId,
    selectedCartItem,
    setCartItemById,
    activeProjectCartItemIds,
  ]);

  const designId = selectedCartItem?.details?.configurationId;
  if (!designId || !selectedCartItem?.id) {
    return null;
  }
  return (
    <React.Fragment>
      <PageContainer>
        <PageElement size={props.width} height={"auto"}>
          <UnitTitle
            isProjectLocked={isProjectLocked}
            designId={designId}
            setDesignId={(designId: string) => {
              setCartItemByDesignId(designId);
            }}
            cartItemId={selectedCartItem?.id}
          />
          {isProjectLocked && (
            <DesignConfirmationStatement
              id={"design-selections-locked-disclaimer"}
            >
              <BiLockAlt /> Your design selections have been confirmed, and can
              no longer be edited.
            </DesignConfirmationStatement>
          )}
        </PageElement>
      </PageContainer>
      <DesignSelections
        width={props.width}
        designId={designId}
        cartItemId={selectedCartItem?.id}
      />
    </React.Fragment>
  );
});
