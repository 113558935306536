import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import {
  CONTAINER_BACKGROUND_COLOR,
  DEFAULT_SHADOW,
  GRAY,
} from "../../../_shared/colors";

export const EmployeeCardContainer = styled.tr`
  // display: flex;
  // flex-direction: column;
  // background-color: ${CONTAINER_BACKGROUND_COLOR};
  // border-radius: 0.5rem;
  // padding: 1rem;
  // max-width: 20rem;
  // width: fit-content;
  // height: fit-content;
  // min-height: 151px;
  // min-width: 151px;
  // transition: all 120ms ease-in-out;
  // &:hover {
  //   box-shadow: ${DEFAULT_SHADOW};
  // }
`;

export const EmployeeCardContentDivider = styled.div`
  height: 1px;
  background-color: ${GRAY};
  margin: 0.5rem 0;
`;

export const EmployeeCardDisplayName = styled.td`
  font-family: ${FONT_FAMILY_MEDIUM};
  // font-size: 1.25rem;
  // user-select: none;
`;

export const EmployeeCardEmail = styled.td`
  font-family: ${FONT_FAMILY_REGULAR};
  // font-size: 1rem;
`;

export const EmployeeCardAccessSummary = styled.td`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 1rem;
  color: ${GRAY};
  margin-bottom: 0.5rem;
  user-select: none;
`;
