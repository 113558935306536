import styled from "styled-components";
import {DIVIDER_COLOR} from "../../../../../../_shared/colors";

export const EditFieldContainer = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid ${DIVIDER_COLOR};
  }
`;
