import React from "react";
import {
  SHOW_COLOR_SWATCHES_PROPERTY_KEY,
  SHOW_FULL_TEXT_PROPERTY_KEY,
} from "../../constants";
import {IModifier, isBlankString} from "@natomas-org/core";
import {StudioDescriptionCheckmark} from "../StudioDescriptionCheckmark";
import {ModifierGroupDescription} from "./styles";
import {ModifierSubtitle} from "../StudioModifier/styles";
import {getDescriptionForModifier} from "../StudioModifier/helper";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../_shared/generics/label/NatLabel";

export const getSubtitleForSelectedModifier = (
  selectedModifiers: IModifier[] | null,
  fullModifierGroup: any
): JSX.Element | null => {
  if (selectedModifiers == null || selectedModifiers.length !== 1) {
    return null;
  }
  // TODO: allow for multiple subtitles when multiple selected modifiers?
  const selectedModifier = selectedModifiers[0];

  let title =
    selectedModifier.configurationButtonTitle != null
      ? selectedModifier.configurationButtonTitle
      : selectedModifier.title;
  if (title == null) {
    return null;
  }

  if (
    fullModifierGroup.properties != null &&
    fullModifierGroup.properties[SHOW_COLOR_SWATCHES_PROPERTY_KEY] === true &&
    fullModifierGroup.properties[SHOW_FULL_TEXT_PROPERTY_KEY] !== true
  ) {
    const description = getDescriptionForModifier(selectedModifier);

    return (
      <ModifierSubtitle>
        <NatLabel
          label={title + (description ? ":" : "")}
          type={NatLabelType.P4}
          weight={NatLabelWeight.BOLD}
        />{" "}
        <NatLabel
          label={getDescriptionForModifier(selectedModifier)}
          type={NatLabelType.P4}
        />
      </ModifierSubtitle>
    );
  }

  return null;
};

export function getModifierGroupDescription(fullModifierGroup: any) {
  let description: string | null | undefined = null;
  if (
    fullModifierGroup != null &&
    !isBlankString(fullModifierGroup.prettyDescription)
  ) {
    description = fullModifierGroup.prettyDescription;
  }

  // if (selectedModifiers != null && selectedModifiers.length > 0) {
  //   if (selectedModifiers.length === 1) {
  //     const modifierDescription = getDescriptionForModifier(
  //       selectedModifiers[0]
  //     );
  //     if (description == null) {
  //       description = modifierDescription;
  //     } else if (!isBlankString(modifierDescription)) {
  //       description += "\n\n" + modifierDescription;
  //     }
  //   } else {
  //     selectedModifiers.forEach((modifier: any) => {
  //       const modifierDescription = getDescriptionForModifier(modifier);
  //       if (!isBlankString(modifierDescription)) {
  //         if (description == null) {
  //           description = "";
  //         } else {
  //           description += "\n\n";
  //         }
  //
  //         description += "• " + modifier.title + "\n" + modifierDescription;
  //       }
  //     });
  //   }
  // }

  if (isBlankString(description)) {
    return null;
  }

  return <ModifierGroupDescription>{description}</ModifierGroupDescription>;
}

export function breakDescriptionIntoParts(description: any) {
  const parts = [];

  const bullets = description.split("•");
  const firstText = (
    <pre
      style={{whiteSpace: "pre-line"}}
      key={"parts_0"}
      className="hidden-desc-text"
    >
      {bullets[0]}
    </pre>
  );

  parts.push(firstText);

  if (bullets.length > 1) {
    const subParts = [];
    for (let index = 1; index < bullets.length; index++) {
      const element = bullets[index];
      subParts.push(
        <div className="tooltip-item" key={"bullet_" + index}>
          <StudioDescriptionCheckmark />
          <p>{element}</p>
        </div>
      );
    }
    parts.push(
      <div className="tooltip-desc" key={"parts_1"}>
        {subParts}
      </div>
    );
  }
  return parts;
}

export const getSelectedModifiers = (fullModifierGroup: any) => {
  if (
    fullModifierGroup != null &&
    fullModifierGroup.selectedModifiers != null &&
    fullModifierGroup.selectedModifiers.length > 0
  ) {
    return fullModifierGroup.selectedModifiers;
  }
  return [];
};
