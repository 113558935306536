import {NatModal, NatModalProps} from "./NatModal";
import React, {useState} from "react";

export const useModal = (modalProps: NatModalProps) => {
  const [show, setShow] = useState(false);

  const closeModal = () => {
    if (modalProps.handleClose) {
      modalProps.handleClose();
    }
    setShow(false);
  };

  return {
    Modal: (
      <NatModal
        {...modalProps}
        content={React.cloneElement(modalProps.content, {
          closeModal: closeModal,
        })}
        show={modalProps.show || show}
        handleClose={closeModal}
      />
    ),
    setShow,
    openModal: () => {
      setShow(true);
    },
    closeModal: closeModal,
  };
};
