import {PROJECT_COLLECTION_KEY} from "./constants";
import {Utilities} from "..";
import {
  CustomerProjectInfo,
  ICustomerInfo,
  IProjectInfo,
  PhoneNumber,
  Project,
} from "@natomas-org/core";
import {updateProjectAddress} from "../api/project";

export const saveProjectInfo = async (
  configurationId: string,
  projectInfo: IProjectInfo
) => {
  // The interface for the database structure is CustomerProjectInfo
  const address = Project.getAddress(projectInfo);
  let cpi: CustomerProjectInfo = {
    customer_contact: {
      first_name: Project.getClientFirstName(projectInfo),
      last_name: Project.getClientLastName(projectInfo),
      phone: PhoneNumber.getNumber(Project.getClientPhone(projectInfo)),
    },
    // project_address: addressDetailsNatToFS(address),
  };
  await updateProjectAddress(configurationId, address);
  let doc = await Utilities.collection(PROJECT_COLLECTION_KEY).doc(
    configurationId
  );
  return Utilities.updateOrCreateDocumentInDB(doc, cpi);
};

export enum ProjectFetchMode {
  VISIBLE = 1,
  ARCHIVED = 2,
  ALL = 3,
}

export const getProjectIds = (
  customer?: ICustomerInfo,
  mode?: ProjectFetchMode
): string[] => {
  if (!customer) return [];
  if (mode === ProjectFetchMode.ALL) {
    return (
      (customer?.project_ids ?? []).concat(
        customer?.archived_project_ids ?? []
      ) ?? []
    );
  } else if (mode === ProjectFetchMode.ARCHIVED) {
    return customer?.archived_project_ids ?? [];
  } else {
    return customer?.project_ids ?? [];
  }
};

// TODO Should be deprecated
// export const fetchProjectsForCustomer = (
//   customer: ICustomerInfo,
//   mode: ProjectFetchMode = ProjectFetchMode.VISIBLE
// ) => {
//   const projectIds = getProjectIds(customer, mode);
//   return Promise.all(
//     projectIds?.map((projectId: string) => {
//       const project = Utilities.getDocData(
//         Utilities.collection(PROJECT_COLLECTION_KEY).doc(projectId)
//       );
//       const project_opp = Utilities.getDocData(
//         Utilities.collection(PROJECT_OPPORTUNITY_COLLECTION_KEY).doc(projectId)
//       );
//       const configuration = Utilities.getDocData(
//         Utilities.collection(CONFIGURATION_DESIGN_DB_KEY).doc(projectId)
//       );
//       return Promise.all([project, project_opp, configuration, projectId]);
//     })
//   ).then((results: any[]) => {
//     return results?.map((result) => {
//       const [project, project_opp, configuration, projectId] = result;
//       const projectInfo = project as CustomerProjectInfo;
//       const projectOppInfo = project_opp as CustomerProjectInfo;
//       return {
//         project: mergeProjectInfoAndProjectOpportunityInfo(
//           projectInfo,
//           projectOppInfo
//         ),
//         configuration: configuration,
//         id: projectId,
//       };
//     });
//   });
// };
