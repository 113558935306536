import {IFactoryInventoryStudioEditorPopupOptions} from "../Editor/PopupContent/interface";
import {
  StudioEditorActionType,
  StudioEditorFieldType,
} from "../Editor/_shared/StudioEditorFieldAddButton";
import {IFactoryInventoryStudioEditorCategory} from "../Editor/CategorySection/interface";
import {IFactoryInventoryStudioEditorModifierGroup} from "../Editor/ModifierGroupSection/interface";
import {IFactoryInventoryStudioEditorModifier} from "../Editor/ModifierSection/interface";
import {IFactoryInventoryStudioEditorCategoryImageGroup} from "../Editor/CategorySection/CategoryImagesEditor/interface";

export enum StudioEditorSwapDirection {
  UP = -1,
  DOWN = 1,
}

export const swapTarget = (
  formik: any,
  options: IFactoryInventoryStudioEditorPopupOptions
) => {
  const {path, type, action} = options;

  const {categoryId, modifierId, modifierGroupId, imageGroupId} = path ?? {};
  const direction: StudioEditorSwapDirection =
    action === StudioEditorActionType.MOVE_UP
      ? StudioEditorSwapDirection.UP
      : StudioEditorSwapDirection.DOWN;
  if (type === StudioEditorFieldType.CATEGORY && categoryId) {
    swapCategories(formik, direction, categoryId);
  }
  if (
    type === StudioEditorFieldType.MODIFIER_GROUP &&
    categoryId &&
    modifierGroupId
  ) {
    swapModifierGroups(formik, direction, categoryId, modifierGroupId);
  }
  if (
    type === StudioEditorFieldType.MODIFIER &&
    categoryId &&
    modifierGroupId &&
    modifierId
  ) {
    swapModifiers(formik, direction, categoryId, modifierGroupId, modifierId);
  }
  if (type === StudioEditorFieldType.RENDERING && categoryId && imageGroupId) {
    swapCategoryImageGroups(formik, direction, categoryId, imageGroupId);
  }
};

const swapCategories = (
  formik: any,
  direction: StudioEditorSwapDirection,
  categoryId: string
) => {
  const categories: IFactoryInventoryStudioEditorCategory[] = Object.values(
    formik?.values ?? {}
  );
  const category = categories?.find(
    (category: IFactoryInventoryStudioEditorCategory) =>
      category?.id === categoryId
  );
  if (!category) {
    console.error("Couldn't find base category");
    return;
  }
  const baseIndex = category?.index;
  const targetIndex = baseIndex + direction;
  const target = categories?.find(
    (category: IFactoryInventoryStudioEditorCategory) =>
      category?.index === targetIndex
  );
  if (!target) {
    console.error("Couldn't find target category to swap with");
    return;
  }
  formik?.setFieldValue(`${category?.id}`, {...category, index: targetIndex});
  formik?.setFieldValue(`${target?.id}`, {...target, index: baseIndex});
};

const swapModifierGroups = (
  formik: any,
  direction: StudioEditorSwapDirection,
  categoryId: string,
  modifierGroupId: string
) => {
  const category: IFactoryInventoryStudioEditorCategory =
    formik?.values[categoryId];
  if (!category) {
    console.error("Couldn't find base category");
    return;
  }
  const modifierGroups: IFactoryInventoryStudioEditorModifierGroup[] =
    Object.values(category?.modifierGroups ?? {});
  const modifierGroup = modifierGroups?.find(
    (modGroup: IFactoryInventoryStudioEditorModifierGroup) =>
      modGroup?.id === modifierGroupId
  );
  if (!modifierGroup) {
    console.error("Couldn't find base modifier group");
    return;
  }
  const baseIndex = modifierGroup?.index;
  const targetIndex = baseIndex + direction;
  const target = modifierGroups?.find(
    (modGroup: IFactoryInventoryStudioEditorModifierGroup) =>
      modGroup?.index === targetIndex
  );
  if (!target) {
    console.error("Couldn't find target modifier group to swap with");
    return;
  }
  formik?.setFieldValue(`${category?.id}.modifierGroups.${modifierGroup?.id}`, {
    ...modifierGroup,
    index: targetIndex,
  });
  formik?.setFieldValue(`${category?.id}.modifierGroups.${target?.id}`, {
    ...target,
    index: baseIndex,
  });
};

const swapCategoryImageGroups = (
  formik: any,
  direction: StudioEditorSwapDirection,
  categoryId: string,
  imageGroupId: string
) => {
  const category: IFactoryInventoryStudioEditorCategory =
    formik?.values[categoryId];
  if (!category) {
    console.error("Couldn't find base category");
    return;
  }
  const imageGroups: IFactoryInventoryStudioEditorCategoryImageGroup[] =
    Object.values(category?.images ?? {});
  const imageGroup = imageGroups?.find(
    (group: IFactoryInventoryStudioEditorCategoryImageGroup) =>
      group?.id === imageGroupId
  );
  if (!imageGroup) {
    console.error("Couldn't find base image group");
    return;
  }
  const baseIndex = imageGroup?.index;
  const targetIndex = baseIndex + direction;
  const target = imageGroups?.find(
    (group: IFactoryInventoryStudioEditorCategoryImageGroup) =>
      group?.index === targetIndex
  );
  if (!target) {
    console.error("Couldn't find target modifier group to swap with");
    return;
  }
  formik?.setFieldValue(`${category?.id}.images.${imageGroup?.id}`, {
    ...imageGroup,
    index: targetIndex,
  });
  formik?.setFieldValue(`${category?.id}.images.${target?.id}`, {
    ...target,
    index: baseIndex,
  });
};

const swapModifiers = (
  formik: any,
  direction: StudioEditorSwapDirection,
  categoryId: string,
  modifierGroupId: string,
  modifierId: string
) => {
  const category: IFactoryInventoryStudioEditorCategory =
    formik?.values[categoryId];
  if (!category || !category?.modifierGroups) {
    console.error("Couldn't find base category");
    return;
  }
  const modifierGroup: IFactoryInventoryStudioEditorModifierGroup =
    category?.modifierGroups?.[modifierGroupId];
  if (!modifierGroup) {
    console.error("Couldn't find base modifier group");
    return;
  }
  const modifiers: IFactoryInventoryStudioEditorModifier[] = Object.values(
    modifierGroup?.modifiers ?? {}
  );
  const modifier = modifiers?.find(
    (mod: IFactoryInventoryStudioEditorModifier) => mod?.id === modifierId
  );
  if (!modifier) {
    console.error("Couldn't find base modifier");
    return;
  }
  const baseIndex = modifier?.index;
  const targetIndex = baseIndex + direction;
  const target = modifiers?.find(
    (mod: IFactoryInventoryStudioEditorModifier) => mod?.index === targetIndex
  );
  if (!target) {
    console.error("Couldn't find target modifier to swap with");
    return;
  }
  formik?.setFieldValue(
    `${category?.id}.modifierGroups.${modifierGroup?.id}.modifiers.${modifier?.id}`,
    {
      ...modifier,
      index: targetIndex,
    }
  );
  formik?.setFieldValue(
    `${category?.id}.modifierGroups.${modifierGroup?.id}.modifiers.${target?.id}`,
    {
      ...target,
      index: baseIndex,
    }
  );
};
