import React from "react";
import {
  NatModal,
  NatModalSize,
} from "../../../../_shared/generics/modal/NatModal";
import {StudioDisclaimerContent} from "./constant";

const StudioDisclaimerModal = (props: {show: boolean; close: () => void}) => {
  return (
    <NatModal
      size={NatModalSize.NORMAL}
      show={props.show}
      handleClose={props.close}
      header={"Disclaimer"}
      content={<p>{StudioDisclaimerContent}</p>}
    />
  );
};

export default StudioDisclaimerModal;
