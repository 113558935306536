import {Image, Page, View} from "@react-pdf/renderer";
import {styles} from "./styles";
import React from "react";

interface ISitePlansPageProps {
  imageBase64: string[];
  getFooter: (title: string) => JSX.Element;
}

const SitePlansPage = (props: ISitePlansPageProps) => {
  if (!props.imageBase64) {
    return null;
  } else {
    return (
      <Page orientation={"landscape"} wrap key={"site-plans-page"}>
        {props.imageBase64?.map((imageData: string, index: number) => {
          const title: string = `Site Plan ${index + 1}`;
          return (
            <View key={`site-plan-${index}`} style={styles.view} wrap={false}>
              {props.getFooter(title)}
              <Image
                src={imageData}
                key={`site-plan-image-${index}`}
                style={{
                  objectFit: "scale-down",
                  minWidth: "100%",
                  minHeight: "300px",
                }}
              />
            </View>
          );
        })}
      </Page>
    );
  }
};

export default SitePlansPage;
