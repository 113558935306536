import React, {useContext} from "react";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {FOOTER_SPLASH_TEXT} from "../../../../HCPDashboardLayout/HCPPresentationView/B2B/components/constants";
import {GRANITE, VILLA_SLATE, WHITE} from "../../../../../../_shared/colors";
import {IMAGE_Z_INDEX} from "../../../../../../_shared/styles";
import {DateUtils} from "@natomas-org/core";
import {
  NatJustifyContent,
  NatVerticalAlign,
} from "../../../../../../_shared/generics/_shared";
import {
  FULL_SCREEN_HEIGHT_REM,
  FULL_SCREEN_WIDTH_REM,
} from "../../../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";
import {PresentationContainer} from "../../../../HCPDashboardLayout/HCPPresentationView/styles";
import {Wrapper} from "../../../../HCPDashboardLayout/HCPPresentationView/B2B/components/styles";
import {PROPOSAL_GENERATE_PDF} from "../../../../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";

const CLOSING_SLIDE_SCALED =
  "https://firebasestorage.googleapis.com/v0/b/natomas-staging.appspot.com/o/assets%2Fportal%2Fpresentation%2FB2BVilla2.jpg?alt=media";
export const B2BFinalSlide = (props: any) => {
  const date = new Date();
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);

  return (
    <PresentationContainer
      fadeIn={false}
      style={
        isGeneratingPDF
          ? {
              alignItems: "flex-start",
              minHeight: `${FULL_SCREEN_HEIGHT_REM}rem`,
              maxHeight: `${FULL_SCREEN_HEIGHT_REM}rem`,
              minWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
              maxWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
            }
          : {maxWidth: "99vw", overflow: "hidden", minHeight: "50rem"}
      }
    >
      <Wrapper>
        <img
          width={"3840px"}
          src={CLOSING_SLIDE_SCALED}
          alt={"start project"}
          height={"2967px"}
          style={{
            objectFit: "cover",
            objectPosition: "0 0%",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
        <NatFlex
          FULL_WIDTH
          ROW
          NOWRAP
          VERTICAL_ALIGN={NatVerticalAlign.CENTER}
          FULL_HEIGHT
          style={{position: "absolute"}}
        >
          <div
            style={{
              width: "100%",
              padding: "2rem 6rem",
              color: VILLA_SLATE,
              height: "100%",
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0",
            }}
          >
            <NatLabel
              label={"We look forward to working with you!"}
              type={NatLabelType.H1}
              style={{
                zIndex: IMAGE_Z_INDEX + 1,
                position: "absolute",
                left: "52%",
                top: "14%",
                color: WHITE,
                lineHeight: "36px",
                fontSize: "36px",
              }}
            />
          </div>
        </NatFlex>
        <NatFlex
          ROW
          JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
          style={{
            padding: "1rem 2rem",
            color: GRANITE,
            position: "absolute",
            bottom: "20px",
            flexWrap: "nowrap",
            width: "100%",
          }}
        >
          <NatLabel
            label={FOOTER_SPLASH_TEXT}
            type={NatLabelType.H3}
            style={{
              color: WHITE,
              zIndex: IMAGE_Z_INDEX + 1,
              fontSize: "18px",
              lineHeight: "18px",
            }}
          />
          <NatLabel
            style={{
              color: WHITE,
              zIndex: IMAGE_Z_INDEX + 1,
              fontSize: "18px",
              lineHeight: "18px",
            }}
            label={DateUtils.getFullDate(date, {showYear: true})}
            type={NatLabelType.H3}
          />
        </NatFlex>
      </Wrapper>
    </PresentationContainer>
  );
};
