import React, {Fragment, useState} from "react";
import {
  Address,
  convertStringCase,
  IAddressDetails,
  IJurisdictionData,
  IPropertyData,
} from "@natomas-org/core";
import {NatModal} from "../../../../_shared/generics/modal/NatModal";
import {
  JurisdictionInformationTable,
  PropertyInformationTable,
} from "./PropertyInformationTable";
import {NatLink} from "../../../../_shared/generics/button";
import {PublicAppSearchBar} from "../PublicAppSearchBar";
import {ADDRESS_BAR_STYLE} from "../PublicAppSearchBar/constants";
import {FeasibilityContextBold} from "../../../../catalog/views/MainView/styles";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {MapCatalogBar} from "../../../../catalog/_shared/components/CatalogBar/MapCatalogBar";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import styled from "styled-components";
import {usePropertyDataWithAddressId} from "../../../../_shared/hooks/usePropertyData/usePropertyData";
import {GRANITE} from "../../../../_shared/colors";
import {usePage} from "../../../../_shared/hooks/usePage";

export interface ISmartCatalogHeading {
  address?: IAddressDetails;
  allowEditingAddress?: boolean;
  includeProductSelectionPrompt?: boolean;
  includeFilterAndSortBanner?: boolean;
  includeJurisdictionText?: boolean;
  hideAddress?: boolean;
  hideSort?: boolean;
  hideFilter?: boolean;
  hideHr?: boolean;
  isSticky?: boolean;
  verticalSpacingRem?: number;
  offset?: string;
}

export const SmartCatalogHeading = (props: ISmartCatalogHeading) => {
  const {isNatMobile} = usePage();
  const [showModal, setShowModal] = useState(false);
  const {address, includeFilterAndSortBanner} = props;
  const propertyData = usePropertyDataWithAddressId(Address.getId(address));
  const [showAHJInfo, setShowAHJInfo] = useState<boolean>(false);
  const jurisdictionName = propertyData?.jurisdictionData?.jurisdiction
    ?.jurisdictionName
    ? convertStringCase(
        propertyData?.jurisdictionData?.jurisdiction?.jurisdictionName?.toLowerCase(),
        {
          upperCaseFirstLetter: true,
          applyToAllWords: true,
        }
      )
    : null;
  const maxAduSize =
    propertyData?.jurisdictionData?.productConstraints?.maxADUSqFt;
  let InteractiveSmartText =
    !props.includeProductSelectionPrompt || isNatMobile ? null : (
      <>Select an ADU to begin designing</>
    );
  if (props.allowEditingAddress) {
    InteractiveSmartText = (
      <InstructionTextContainer>
        {InteractiveSmartText}
      </InstructionTextContainer>
    );
  }
  if (jurisdictionName) {
    InteractiveSmartText = (
      <div style={{fontSize: "0.875rem", color: GRANITE, display: "inline"}}>
        <NatLink
          clickEvent={() => {
            setShowAHJInfo(false);
            setShowModal(true);
          }}
          label={Address.getStreetNumberAndStreet(address)}
          style={{padding: "0 0", fontSize: "inherit"}}
        />
        {" is in "}
        <NatLink
          clickEvent={() => {
            setShowAHJInfo(true);
            setShowModal(true);
          }}
          label={jurisdictionName}
          style={{padding: "0 0", fontSize: "inherit"}}
        />
        {maxAduSize ? `, which allows ADUs under ${maxAduSize} Sq. Ft.` : null}
      </div>
    );
  }
  if (props.allowEditingAddress) {
    InteractiveSmartText = (
      <Fragment>
        <FeasibilityContextBold>
          <PublicAppSearchBar addressBarStyle={ADDRESS_BAR_STYLE.PIN_INLINE} />
        </FeasibilityContextBold>
      </Fragment>
    );
    if (maxAduSize && jurisdictionName) {
      InteractiveSmartText = (
        <Fragment>
          <FeasibilityContextBold>
            <PublicAppSearchBar
              addressBarStyle={ADDRESS_BAR_STYLE.PIN_INLINE}
            />
          </FeasibilityContextBold>
          <span>in </span>
          <FeasibilityContextBold
            style={{
              cursor: "pointer",
              padding: "0 0.5rem",
              fontFamily: FONT_FAMILY_BOLD,
            }}
            onClick={() => {
              setShowAHJInfo(true);
              setShowModal(true);
            }}
          >
            {jurisdictionName}
          </FeasibilityContextBold>
          <span>is eligible for an ADU!</span>
        </Fragment>
      );
    }
  }
  const getProductSelectionPrompt = () => {
    if (!props.includeProductSelectionPrompt || isNatMobile) {
      return null;
    }
    if (props.isSticky) {
      return (
        <PageContainer>
          <ProductSelectionPrompt />
        </PageContainer>
      );
    }
    return <ProductSelectionPrompt />;
  };
  const modal = (
    <ParcelInfoModal
      jurisdictionData={propertyData?.jurisdictionData}
      propertyData={propertyData ?? undefined}
      displayOnlyJurisdictionData={showAHJInfo}
      setShowParcelInfoModal={setShowModal}
      showParcelInfoModal={showModal}
    />
  );
  if (props.isSticky) {
    InteractiveSmartText = (
      <PageContainer>
        <div>{InteractiveSmartText}</div>
      </PageContainer>
    );
  }
  return (
    <span style={{fontSize: "1.25rem"}}>
      {!props.allowEditingAddress && includeFilterAndSortBanner && (
        <MapCatalogBar
          offset={props.offset}
          hideFilter={props.hideFilter}
          hideSort={props.hideSort}
          isSticky={props.isSticky}
        />
      )}
      {!props.hideAddress && InteractiveSmartText}
      {getProductSelectionPrompt()}
      {props.allowEditingAddress && includeFilterAndSortBanner && (
        <MapCatalogBar
          offset={props.offset}
          hideFilter={props.hideFilter}
          hideSort={props.hideSort}
          isSticky={props.isSticky}
        />
      )}
      {InteractiveSmartText && !props.hideHr && <hr />}
      {modal}
    </span>
  );
};

export const ParcelInfoModal = (props: {
  jurisdictionData?: IJurisdictionData;
  propertyData?: IPropertyData;
  displayOnlyJurisdictionData?: boolean;
  setShowParcelInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  showParcelInfoModal: boolean;
}) => {
  const {
    setShowParcelInfoModal,
    showParcelInfoModal,
    propertyData,
    jurisdictionData,
    displayOnlyJurisdictionData,
  } = props;
  if (!propertyData) {
    return null;
  }
  return (
    <NatModal
      handleClose={() => {
        setShowParcelInfoModal(false);
      }}
      show={showParcelInfoModal}
      customSize={{width: "26rem", height: "100%"}}
      centeredContent={false}
      onlyShowContent={true}
      content={
        <>
          {!displayOnlyJurisdictionData || !jurisdictionData ? (
            <PropertyInformationTable propertyData={propertyData} />
          ) : (
            <JurisdictionInformationTable jurisdictionData={jurisdictionData} />
          )}
        </>
      }
    />
  );
};

export const ProductSelectionPrompt = () => {
  return (
    <InstructionTextContainer>
      Select an ADU to begin designing
    </InstructionTextContainer>
  );
};
const InstructionTextContainer = styled.div`
  font-size: 0.875rem;
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${GRANITE};
  display: inline;
`;
