import styled from "styled-components";
import {VILLA_BLUE} from "../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {HEADER_Z_INDEX} from "../../../../_shared/styles";

export const MobilePricingWrapper = styled.div`
  color: ${VILLA_BLUE};
  margin-left: 4px;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const StudioMobileHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${HEADER_Z_INDEX};
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LogoContainer = styled.img`
   {
    cursor: pointer;
    height: 1.5rem;
  }
`;
