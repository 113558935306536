import React, {useState} from "react";
//Shared
//Steps
import {ProductDiscoveryStep} from "./components/ProductDiscoveryStep";
//Styles
import {DiscoveryForm} from "./styles";
import {ProductRecommendations} from "./components/ProductRecommendations";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {CatalogPath} from "../../_shared/components/CatalogPath";
import {useAddress} from "../../../_shared/hooks/useAddress";

export const ProductDiscoveryView = (props: {
  completeDiscovery: () => void;
}) => {
  const {completeDiscovery} = props;
  const {publicAddress, projectAddress} = useAddress();
  const [formComplete, setFormComplete] = useState<boolean>(false);

  const viewRecommendations = () => {
    setFormComplete(true);
  };

  return (
    <>
      {formComplete && (
        <CatalogPath
          recommendedProducts={true}
          allADUCallback={completeDiscovery}
        />
      )}
      <PageContainer>
        {(!!publicAddress || !!projectAddress) &&
          (!formComplete ? (
            <DiscoveryForm>
              <ProductDiscoveryStep
                seeRecommendations={viewRecommendations}
                completeDiscovery={completeDiscovery}
              />
            </DiscoveryForm>
          ) : (
            <ProductRecommendations completeDiscovery={completeDiscovery} />
          ))}
      </PageContainer>
    </>
  );
};
