import {Utilities} from "./database/firebase";
import {store} from "./store";
import {
  enrichConfig,
  setAppVersion,
} from "./components/_shared/slices/GlobalSlice";
import {IAppVersionInfo} from "./components/_shared/slices/Global";

export interface IConfigItem {
  value: any;
  value_fallback: any;
  percentage: number;
}

let initialVersion: IAppVersionInfo | null = null;

export const fetchWebAppConfig = () => {
  const configCollection = Utilities.collection("config");

  Utilities.subscribeWithCallback(
    configCollection.doc("web_app_version"),
    (data) => {
      const updatedVersion = data as IAppVersionInfo;
      if (updatedVersion) {
        return handleVersionUpdate(updatedVersion);
      }
    }
  );

  return Utilities.getDocData(configCollection.doc("web_app")).then(
    (result) => {
      return Utilities.getDocData(configCollection.doc("web_app_v2")).then(
        (result2) => {
          if (result) {
            const parseResults = parseV2Config(result2);
            const finalResults = Object.assign(result, parseResults);
            return store.dispatch(enrichConfig(finalResults));
          }
        }
      );
    }
  );
};

const handleVersionUpdate = (updatedVersion: IAppVersionInfo) => {
  store.dispatch(setAppVersion(updatedVersion));
  const latestVersionReq = updatedVersion.lowest_supported_version;

  // Only store the app version once
  if (!initialVersion) {
    initialVersion = updatedVersion;
  } else if (initialVersion.current_version && latestVersionReq) {
    // If we have already stored the app version
    // And the lowest supported version is higher than our current version
    // Reload the page, so that the app has the latest important changes
    if (initialVersion.current_version < latestVersionReq) {
      // @ts-ignore
      const serviceWorker = window.newServiceWorker;
      if (serviceWorker) {
        serviceWorker.postMessage({type: "SKIP_WAITING"});
      } else {
        window.location.reload();
      }
    }
  }
};

const parseV2Config = (rawConfig: any) => {
  const results: any = {};
  Object.keys(rawConfig).forEach((key) => {
    const configItem = rawConfig[key];
    const {type} = configItem;
    let value;
    switch (type) {
      case "progressive":
        value = evaluateProgressiveConfigItem(key, configItem);
        break;
      default:
        break;
    }
    if (value) {
      results[key] = value;
    }
  });
  return results;
};

export const evaluateProgressiveConfigItem = (
  key: string,
  configItem: IConfigItem
) => {
  try {
    const {value, value_fallback, percentage} = configItem;
    const storageKey = `config_${key}`;
    const onDeviceNumber = window.localStorage.getItem(storageKey);
    let coinFlip;
    if (onDeviceNumber) {
      coinFlip = parseInt(onDeviceNumber);
    } else {
      coinFlip = randomIntFromInterval(0, 100);
      window.localStorage.setItem(storageKey, `${coinFlip}`);
    }

    return coinFlip < percentage ? value : value_fallback;
  } catch (e) {
    return null;
  }
};

function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
