import React from "react";
import {Font, StyleSheet, Text, View} from "@react-pdf/renderer";
import {PDFProposal} from "@natomas-org/core";
// @ts-ignore
import InterSrc from "../../../../assets/fonts/Inter/Inter-Regular.ttf";
// @ts-ignore
import InterBoldSrc from "../../../../assets/fonts/Inter/Inter-Bold.ttf";
// @ts-ignore
import InterMediumSrc from "../../../../assets/fonts/Inter/Inter-Medium.ttf";
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/700.css";
import {RPDFTable} from "./RPDFTable";

export const secondaryStyles = StyleSheet.create({
  table: {
    padding: "0 0",
    margin: "0 0",
    display: "flex",
    justifyContent: "flex-start",
    maxWidth: "100%",
    wordBreak: "break-word",
  },
  row: {
    margin: "0 0",
    flexDirection: "row",
    width: "100%",
  },
  cell: {
    flexGrow: 1,
    padding: 6,
    fontSize: 8,
    fontFamily: "Inter",
    fontWeight: 300,
    breakInside: "avoid",
    whiteSpace: "no-wrap",
  },
  boldCell: {
    flexGrow: 1,
    fontWeight: 600,
    padding: 6,
    fontSize: 8,
    fontFamily: "Inter",
    breakInside: "avoid",
    whiteSpace: "no-wrap",
  },
  header: {
    maxHeight: 0,
    opacity: 0,
    display: "none",
  },
});

export const RPDFMiniTable = (props: {
  data: PDFProposal.Nat_RPDF_Table_Text_Data_Row[];
  title?: string;
}) => {
  const {data} = props;
  return <RPDFTable data={data} secondary={true} />;
};
