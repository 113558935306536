import React from "react";
import {IFactoryInventoryStudioEditorModifier} from "../../../ModifierSection/interface";
import ModifierConditionalFieldEditor from "./ModifierConditionalFieldEditor";

interface ModifierAdvancedInfoProps {
  id: string;
  updateEdits: any;
  initialValue?: IFactoryInventoryStudioEditorModifier;
  edits?: IFactoryInventoryStudioEditorModifier;
}

const ModifierAdvancedInfo = (props: ModifierAdvancedInfoProps) => {
  const {id, initialValue, edits, updateEdits} = props;

  return (
    <ModifierConditionalFieldEditor
      id={id}
      updateEdits={updateEdits}
      edits={edits}
      initialValue={initialValue}
    />
  );
};

export default ModifierAdvancedInfo;
