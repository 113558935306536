import React from "react";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import styled from "styled-components";

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;
const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const LoadingSpinner = (props?: {
  isFullScreen?: boolean;
  style?: React.CSSProperties;
}) => {
  const {isFullScreen} = props ?? {};
  if (isFullScreen) {
    return (
      <SpinnerContainer>
        <Spin indicator={antIcon} style={props?.style} />
      </SpinnerContainer>
    );
  } else {
    return <Spin indicator={antIcon} style={props?.style} />;
  }
};
