export const getItemFromLocalCache = (key: string): string | null | any => {
  if (!window.localStorage) {
    return null;
  }
  const retrievedItem = window.localStorage.getItem(key);
  if (!retrievedItem) {
    return null;
  }
  try {
    return JSON.parse(retrievedItem);
  } catch (e) {
    return retrievedItem;
  }
};

export const storeItemInLocalCache = (key: string, item: any): void => {
  if (!window.localStorage) {
    return;
  }
  if (typeof item === "object") {
    item = JSON.stringify(item);
  }
  window.localStorage.setItem(key, item);
};
