import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK} from "../../../../../_shared/colors";

export const studioImageHeight = 240;

export const CurrentStepTitle = styled.div`
  align-items: center;
  background-color: white;
  bottom: 1px;
  color: ${BLACK};
  display: flex;
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 2rem;
  justify-content: space-between;
  text-align: left;
  width: 100%;
`;

export const CurrentStepSectionTitle = styled.div`
  font-size: 1.2rem;
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${BLACK};
  margin-left: 10px;
`;

export const CategoryHeaderContainer = styled.div<{isMobile: boolean}>`
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
`;

export const InformationIconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  font-size: 24px;
  right: 50px;
  z-index: 100000;
`;
