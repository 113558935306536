import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../styles/globals";
import {VILLA_APPLE_GREEN} from "../../../../../../../_shared/colors";

export const BannerContainer = styled.div`
  background-color: ${VILLA_APPLE_GREEN + "20"};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.75rem;
  min-height: 273px;
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const SurveyComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SurveyTitleContainer = styled.div`
font-family: ${FONT_FAMILY_MEDIUM};
  font-size: "1rem;
`;
