import React, {useState} from "react";
import {SortDropdownValues} from "@natomas-org/core";
import {IconProps, StyleOption} from "../../../../../_shared/generics/_shared";
import {NatContentListProps} from "../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {setProductSort} from "../../../../../_shared/slices/CatalogSlice/CatalogSessionSlice";
import {useCatalogSession} from "../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useDispatch} from "react-redux";
import {NatDropdown} from "../../../../../_shared/generics/popup/dropdown/NatDropdown";
import {
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../../../_shared/generics/popup/dropdown/constants";
import {SelectionItemContainer} from "./styles";
import {DropdownValues} from "./constants";

export const isAscending = (
  value?: SortDropdownValues
): boolean | undefined => {
  if (value === undefined) {
    return undefined;
  }
  return (
    value === SortDropdownValues.PRICE_ASC ||
    value === SortDropdownValues.SQUARE_FOOTAGE_ASC ||
    value === SortDropdownValues.BED_ASC ||
    value === SortDropdownValues.BATH_ASC
  );
};

export const SortDropdown = React.memo(() => {
  const dispatch = useDispatch();
  const {sortBy} = useCatalogSession();
  const [show, setShow] = useState(false);
  const options = [
    DropdownValues[SortDropdownValues.PRICE_ASC],
    DropdownValues[SortDropdownValues.PRICE_DESC],
    DropdownValues[SortDropdownValues.SQUARE_FOOTAGE_ASC],
    DropdownValues[SortDropdownValues.SQUARE_FOOTAGE_DESC],
    DropdownValues[SortDropdownValues.BED_ASC],
    DropdownValues[SortDropdownValues.BATH_ASC],
  ];
  const popupProps: NatContentListProps = {
    contentItemArray: options?.map((option) => {
      let sortLabel = option.label;
      if (option.ascending) {
        sortLabel += " (Low to High)";
      } else {
        sortLabel += " (High to Low)";
      }
      return {
        component: (
          <SelectionItem
            selected={sortBy === option.value}
            label={sortLabel}
            onSelect={() => {
              dispatch(setProductSort(option.value));
              setShow(false);
            }}
          />
        ),
        id: option.value,
        label: option.label,
      };
    }),
    show: show,
    handleClose: () => {
      setShow(false);
    },
    style: {
      TYPE: NatPopupType.HIDE,
      ADDITIONAL_STYLE_OPTIONS: [
        NatPopupStyleOptions.NO_PADDING,
        NatPopupStyleOptions.CLOSE_ON_SELECT,
      ],
      DROPDOWN_BUTTON_STYLE: show
        ? StyleOption.FILTERS_HIGHLIGHTED
        : StyleOption.FILTERS,
      popupCSS: {
        borderRadius: "10px",
        width: "17rem",
        padding: "0 0",
      },
    },
  };

  return (
    <NatDropdown
      {...popupProps}
      show={show}
      setShow={setShow}
      toggleButtonOrLabel={DropdownValues[sortBy].abbreviatedLabel}
      icon={DropdownValues[sortBy].icon}
    />
  );
});

const SelectionItem = (props: {
  label: string;
  onSelect: () => void;
  selected: boolean;
  icon?: IconProps;
}) => {
  return (
    <SelectionItemContainer onClick={props.onSelect} selected={props.selected}>
      <div hidden={!props.icon}>{props.icon?.icon}</div>
      <div>{props.label}</div>
    </SelectionItemContainer>
  );
};
