import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useState,
} from "react";
import {MapContainer, ZoomControl} from "react-leaflet";
import {MapContainerWrapper, MapViewContainer} from "./styled";
import "leaflet.pattern";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import "leaflet-path-drag";
import "leaflet-path-transform";
import "leaflet-imageoverlay-rotated";
import "leaflet-measure-path";
import "leaflet-measure-path/leaflet-measure-path.css";
import {UserFeedback} from "./UserFeedback";
import {colors, MAP_STYLES} from "../../theme/constants";
import {
  Address,
  DEFAULT_JURISDICTION_DATA,
  IAddressDetails,
  IProductAndFactoryID,
  IUnitPlacement,
  IUserFeedbackData,
  Product,
  UserFeedbackType,
} from "@natomas-org/core";
import {Utilities} from "../../../../../database/firebase";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {usePage} from "../../../../_shared/hooks/usePage";
import {
  setGLMapAnimationOptions,
  setLeafletMapIsStatic,
  setLeafletMapLastSavedCoordinates,
} from "../../../../map/slices/mapSlice";
import {useDispatch} from "react-redux";
import {Map as LeafletMap} from "leaflet";
import {InnerMap} from "./InnerMap";
import {IMappingDisplayProps} from "../../../constants";
import {isMobile} from "../../../../_shared/navigation";
import {useDeepEffect} from "../../../../_shared/hooks/useDeepEffect";
import {useElementSize} from "usehooks-ts";
import {useMap} from "../../../../_shared/hooks/useMap";
import {IModalPayload} from "../ProjectDetailsModal/constants";
import {GeoJsonObject} from "geojson";
import {
  activateSatellite,
  addBuildings,
  deActivateSatellite,
  getConfigurationMapRef,
  getConfigurationSiteRef,
  getCornersFromLatLngs,
  getSanitizedParcelGeometryData,
  getStripesPattern,
  getUpdatedFeasibleZone,
  getUpdatedRestrictedZone,
  getViewPortCenter,
  resetADULayer,
  resetUnitPlacementToLastSave,
  setAduDragRotateHandlers,
  setPopOverHandlers,
  setUnitLatLngs,
  setUnitTransformAndRotation,
  updateDimensions,
  verifyAduPlacement,
} from "./helpers";
import {
  getADUFromMapLayers,
  getADUImageFromMapLayers,
  UNIT_BASE_LAYER_NAME,
  UNIT_IMAGE_LAYER_NAME,
} from "../../helpers/structures";
import {useProductFitsOnLot} from "../../../../_shared/hooks/useProductParcelData";
import {getAduPolygon, hasSavedPlacement} from "@natomas-org/service";
import {fetchImage} from "../../../../../database/firebase/images/fetching";
import {useProduct} from "../../../../_shared/hooks/useProductCatalog/useProduct";
import {BBox} from "@turf/turf";

const L = window.L;
export const DEFAULT_RESTRICTED_ZONE_COLOR = "grey";

interface IMapProps {
  displayProps: IMappingDisplayProps;
  address: IAddressDetails | null | undefined;
  unitPlacement: IUnitPlacement | null | undefined;
  projectIdToSave: string | null | undefined;
  productToView: IProductAndFactoryID | null | undefined;
  aHJModalPayload?: IModalPayload;
  setAHJModalPayload?: React.Dispatch<
    React.SetStateAction<IModalPayload | undefined>
  >;
}

const Map = (props: IMapProps, ref: any) => {
  const initialState = {mapLayers: {}};
  const DEFAULT_RESTRICTED_ZONE_COLOR = "grey";
  function reducer(state: any, action: any) {
    switch (action.type) {
      case "ADD_OR_UPDATE_LAYER":
        return {
          mapLayers: {...state.mapLayers, ...action.payload},
        };
      default:
        throw new Error();
    }
  }
  const [state, dispatchLayer] = useReducer(reducer, initialState);
  const {mapLayers} = state;
  const {mapPropertyData, leafletMapState} = useMap();
  const {
    address,
    projectIdToSave,
    displayProps,
    aHJModalPayload,
    setAHJModalPayload,
    productToView: productParameter,
    unitPlacement,
  } = props;

  const {product: productToPreview} = useProduct(
    productParameter?.productId && productParameter?.factoryId
      ? productParameter?.productId
      : leafletMapState?.unitToPreview?.productId,
    productParameter?.productId && productParameter?.factoryId
      ? productParameter?.factoryId
      : leafletMapState?.unitToPreview?.factoryId
  );
  const {fitsOnLotData} = useProductFitsOnLot({
    productId: productToPreview?.id,
  });
  const maxHeight = 16;
  const unitSizeFeedback: IUserFeedbackData = {
    message: `ADU ≤ ${
      mapPropertyData?.jurisdictionData?.productConstraints?.maxADUSqFt ??
      DEFAULT_JURISDICTION_DATA.productConstraints.maxADUSqFt
    } sqft`,
    type:
      !!productToPreview &&
      Product.getSqFtValue(productToPreview) <=
        (mapPropertyData?.jurisdictionData?.productConstraints?.maxADUSqFt ??
          DEFAULT_JURISDICTION_DATA.productConstraints.maxADUSqFt)
        ? UserFeedbackType.SUCCESS
        : !!productToPreview
        ? UserFeedbackType.WARNING
        : UserFeedbackType.NEUTRAL,
    icon: true,
  };
  const unitHeightFeedback: IUserFeedbackData = {
    message: `ADU ≤ ${maxHeight} ft tall`,
    type: !!productToPreview
      ? UserFeedbackType.SUCCESS
      : UserFeedbackType.NEUTRAL,
    icon: true,
  };
  const {columnCount} = usePage();
  const [squareRef, {width, height}] = useElementSize();
  const dispatch = useDispatch();
  const [map, setMap] = useState<null | LeafletMap>(null);
  useImperativeHandle(ref, () => ({
    updateUnitPlacement: setUnitLatLngs,
  }));
  const viewPortCenter = getViewPortCenter(mapPropertyData);
  const [viewport] = useState({
    latitude: viewPortCenter ? viewPortCenter[1] : 0,
    longitude: viewPortCenter ? viewPortCenter[0] : 0,
  });
  const sanitizedPropertyGeometry = useMemo(() => {
    return getSanitizedParcelGeometryData(mapPropertyData?.propertyGeometry);
  }, [mapPropertyData?.propertyGeometry]);

  // Initializes map
  useDeepEffect(() => {
    function initializeMap() {
      // Clear previous layers, if they exist
      if (mapLayers?.feasibleZone) {
        if (map?.hasLayer(mapLayers?.feasibleZone)) {
          map.removeLayer(mapLayers?.feasibleZone);
        }
        mapLayers?.feasibleZone.clearLayers();
      }
      if (mapLayers?.restrictedZone) {
        if (map?.hasLayer(mapLayers?.restrictedZone)) {
          map.removeLayer(mapLayers?.restrictedZone);
        }
        mapLayers?.restrictedZone.clearLayers();
      }
      if (mapLayers?.buildings) {
        if (map?.hasLayer(mapLayers?.buildings)) {
          map.removeLayer(mapLayers?.buildings);
        }
        mapLayers.buildings.clearLayers();
      }
      if (map) {
        map.eachLayer(function (layer) {
          map.removeLayer(layer);
        });
        if (leafletMapState.bounds?.isValid()) {
          map.fitBounds(leafletMapState.bounds);
        }
      }
      dispatchLayer({
        type: "ADD_OR_UPDATE_LAYER",
        payload: {},
      });
      initializeMapTileLayer();
      initializeBuildingsLayer();
      initializeParcelLayer();
    }
    function initializeMapTileLayer() {
      if (map) {
        const streets = L.tileLayer(
          displayProps?.mapTileLayer ?? MAP_STYLES.PLAIN,
          {
            id: "streets-map",
            tileSize: 512,
            zoomOffset: -1,
            detectRetina: true,
            maxZoom: 23,
            minZoom: 17,
            attribution:
              ' © <a target="_blank" rel="noreferrer" href="https://www.mapbox.com/">Mapbox</a>',
          }
        ).addTo(map);
        dispatchLayer({
          type: "ADD_OR_UPDATE_LAYER",
          payload: {streets: streets},
        });
      }
    }
    function initializeBuildingsLayer() {
      if (map && sanitizedPropertyGeometry) {
        const buildingsLayer = L.featureGroup().addTo(map);
        dispatchLayer({
          type: "ADD_OR_UPDATE_LAYER",
          payload: {buildings: buildingsLayer},
        });
        setPopOverHandlers(
          leafletMapState,
          sanitizedPropertyGeometry,
          map,
          buildingsLayer
        );
      }
    }
    function initializeParcelLayer() {
      if (!map || !sanitizedPropertyGeometry) {
        return;
      }
      const parcelMapLayer = L.geoJson(
        sanitizedPropertyGeometry?.parcelGeometry as GeoJsonObject,
        {
          style: {
            color: colors.propertyLineGrey,
            fillColor: colors.propertyLineGrey,
            fillOpacity: leafletMapState?.satelliteEnabled ? 0 : 1,
            weight: 1.5,
            className: "parcel",
          },
        }
      ).addTo(map);
      dispatchLayer({
        type: "ADD_OR_UPDATE_LAYER",
        payload: {parcel: parcelMapLayer},
      });
      setPopOverHandlers(
        leafletMapState,
        sanitizedPropertyGeometry,
        map,
        parcelMapLayer
      );
    }
    if (map && leafletMapState && leafletMapState.bounds?.isValid()) {
      initializeMap();
      map.fitBounds(leafletMapState.bounds);
    }
  }, [
    map,
    leafletMapState.satelliteEnabled,
    sanitizedPropertyGeometry?.parcelGeometry,
  ]);

  // Handles satellite view toggle
  useEffect(() => {
    if (
      mapLayers?.feasibleZone == null ||
      mapLayers?.parcel == null ||
      leafletMapState?.satelliteEnabled === undefined ||
      !sanitizedPropertyGeometry
    ) {
      return;
    }
    if (leafletMapState?.satelliteEnabled) {
      activateSatellite(
        mapLayers,
        leafletMapState,
        sanitizedPropertyGeometry,
        map,
        dispatchLayer
      );
    } else {
      deActivateSatellite(mapLayers, leafletMapState, map);
    }
  }, [
    leafletMapState?.satelliteEnabled,
    map,
    mapLayers?.feasibleZone,
    mapLayers?.parcel,
  ]);

  // Handles adding buildings
  useEffect(() => {
    if (!mapLayers?.buildings || !map) {
      return;
    }
    if (sanitizedPropertyGeometry?.buildingsGeometry) {
      addBuildings(mapLayers, leafletMapState, sanitizedPropertyGeometry, map);
    }
    if (!map?.hasLayer(mapLayers.buildings)) {
      mapLayers.buildings.addTo(map);
    }
  }, [mapLayers.buildings, sanitizedPropertyGeometry?.buildingsGeometry]);

  // Handles setting restricted/feasible zones
  useEffect(() => {
    if (!mapLayers || !map || !mapPropertyData || !sanitizedPropertyGeometry) {
      return;
    }
    const restrictedZone = getUpdatedRestrictedZone(
      mapPropertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometry
    );
    const feasibleZone = getUpdatedFeasibleZone(
      mapPropertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometry
    );
    if (!restrictedZone || !feasibleZone) {
      return;
    }
    const feasibleZoneLayer = L.geoJson(feasibleZone, {
      // @ts-ignore
      weight: 0,
      fillOpacity: 1,
      fillPattern: leafletMapState?.satelliteEnabled ? getStripesPattern() : "",
      fillColor: leafletMapState?.satelliteEnabled
        ? "transparent"
        : colors.feasibleZoneGrey,
      className: "feasibleZone",
    });
    const restrictedZoneLayer = L.geoJson(restrictedZone, {
      style: {
        fillColor: DEFAULT_RESTRICTED_ZONE_COLOR,
        fillOpacity: 0.8,
        weight: 0,
        className: "restrictedZone",
      },
    });
    if (feasibleZoneLayer && restrictedZoneLayer) {
      if (mapLayers?.restrictedZone && map.hasLayer(mapLayers.restrictedZone)) {
        map.removeLayer(mapLayers.restrictedZone);
      }
      setPopOverHandlers(
        leafletMapState,
        sanitizedPropertyGeometry,
        map,
        restrictedZoneLayer
      );
      restrictedZoneLayer.addTo(map);
      dispatchLayer({
        type: "ADD_OR_UPDATE_LAYER",
        payload: {restrictedZone: restrictedZoneLayer},
      });
      if (mapLayers?.feasibleZone && map.hasLayer(mapLayers.feasibleZone)) {
        map.removeLayer(mapLayers.feasibleZone);
      }
      setPopOverHandlers(
        leafletMapState,
        sanitizedPropertyGeometry,
        map,
        feasibleZoneLayer
      );
      feasibleZoneLayer.addTo(map);
      dispatchLayer({
        type: "ADD_OR_UPDATE_LAYER",
        payload: {feasibleZone: feasibleZoneLayer},
      });
    }
  }, [
    mapPropertyData?.jurisdictionData,
    mapLayers.buildings,
    sanitizedPropertyGeometry?.buildingsGeometry,
    sanitizedPropertyGeometry,
    leafletMapState.satelliteEnabled,
  ]);

  // Handles ordering the layers so that they are in correct z-index
  useEffect(() => {
    if (
      mapLayers &&
      mapLayers.feasibleZone &&
      mapLayers.restrictedZone &&
      mapLayers.parcel
    ) {
      // Orient the different layers
      mapLayers.feasibleZone.bringToBack();
      mapLayers.restrictedZone.bringToBack();
      mapLayers.parcel.bringToBack();
      mapLayers.parcel.setStyle({fillOpacity: 0});
    }
  }, [mapLayers, leafletMapState.satelliteEnabled]);

  // Handles place product
  useEffect(() => {
    if (!productToPreview || !mapPropertyData || !sanitizedPropertyGeometry) {
      resetADULayer(
        mapLayers,
        leafletMapState,
        sanitizedPropertyGeometry ?? undefined,
        map
      );
      return;
    }
    const feasibleZone = getUpdatedFeasibleZone(
      mapPropertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometry
    );
    if (!feasibleZone) {
      return;
    }
    let aduPolygon = null;
    if (
      !(
        hasSavedPlacement(unitPlacement) &&
        (unitPlacement?.addressId === Address.getId(address ?? undefined) ||
          unitPlacement?.addressId ===
            Address.getDeprecatedId_before_03_23(address ?? undefined))
      ) &&
      fitsOnLotData &&
      fitsOnLotData?.productInfoAndPlacement?.productFits
    ) {
      aduPolygon = fitsOnLotData?.productInfoAndPlacement?.polygon;
    } else {
      try {
        if (!address) {
          return;
        }
        const aduPolygonResults = getAduPolygon(
          feasibleZone,
          unitPlacement ?? undefined,
          sanitizedPropertyGeometry ?? undefined,
          productToPreview,
          address,
          []
        );
        aduPolygon = aduPolygonResults?.polygon;
      } catch (e) {
        console.log(e);
        return;
      }
    }
    if (!aduPolygon) {
      return;
    }
    let opacity = 1;
    const aduLayers = L.geoJson(aduPolygon).getLayers();
    // @ts-ignore
    const bounds = aduLayers[0].getLatLngs();
    const corners = getCornersFromLatLngs(bounds);
    // dispatch(setLeafletMapLastSavedCoordinates(aduPolygon));
    resetADULayer(mapLayers, leafletMapState, sanitizedPropertyGeometry, map);
    if (aduLayers?.length < 1 || !mapLayers || !mapLayers?.buildings) {
      return;
    }
    const floorPlanImage = Product.getMappingFloorPlanImage(productToPreview);
    if (floorPlanImage) {
      opacity = 0.35;
      const imageId = floorPlanImage.imageId;
      fetchImage(imageId, true).then((image) => {
        const currentFloorPlanImage =
          Product.getMappingFloorPlanImage(productToPreview);
        if (image && map && currentFloorPlanImage?.imageId === imageId) {
          let pane = map.createPane("imagePane");
          if (pane?.style?.zIndex) {
            pane.style.zIndex = "401";
          }

          if (corners?.length > 2) {
            // @ts-ignore
            const imageLayer = L.imageOverlay.rotated(
              image["500"].url,
              corners[0],
              corners[1],
              corners[2],
              {
                interactive: false,
                className: UNIT_IMAGE_LAYER_NAME,
                pane: "imagePane",
              }
            );

            // Clear out any other images
            let existingADUImageLayer = getADUImageFromMapLayers(mapLayers);
            while (existingADUImageLayer) {
              mapLayers.buildings.removeLayer(existingADUImageLayer);
              if (map.hasLayer(existingADUImageLayer)) {
                map.removeLayer(existingADUImageLayer);
              }
              existingADUImageLayer = getADUImageFromMapLayers(mapLayers);
            }

            // Add the new image layer
            imageLayer.addTo(mapLayers.buildings);
          }
        }
      });
    }
    const aduLayer = L.polygon(bounds, {
      // @ts-ignore
      draggable: true,
      transform: {
        rotation: !leafletMapState.isStatic && !isMobile(),
        scaling: false,
      },
    });
    aduLayer.setStyle({
      fillColor: "000",
      fillOpacity: opacity,
      weight: 0,
      className: UNIT_BASE_LAYER_NAME,
    });
    aduLayer?.addTo(mapLayers?.buildings);
    setAduDragRotateHandlers(
      mapPropertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometry,
      aduLayer,
      productToPreview
    );
    updateDimensions(leafletMapState, sanitizedPropertyGeometry, aduLayer);
    verifyAduPlacement(
      mapPropertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometry,
      productToPreview,
      aduLayer
    );
    setUnitTransformAndRotation(
      mapPropertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometry,
      productToPreview,
      aduLayer
    );
    const coordinates: {lat: any; lng: any}[] = [];
    bounds[0].forEach((latLng: any) => {
      if (!!latLng.lat && !!latLng.lng) {
        coordinates.push({
          lat: latLng.lat,
          lng: latLng.lng,
        });
      }
    });
    dispatch(setLeafletMapLastSavedCoordinates(coordinates));
  }, [productToPreview, mapLayers?.feasibleZone, fitsOnLotData]);

  // Handles toggling panning
  useEffect(() => {
    if (map) {
      if (displayProps?.panningDisabled) {
        map.dragging.disable();
      } else {
        map.dragging.enable();
      }
    }
  }, [displayProps?.panningDisabled, map]);

  // Handles setting transform/rotate handlers and ability based on static state
  useEffect(() => {
    if (!mapPropertyData || !sanitizedPropertyGeometry || !productToPreview) {
      return;
    }
    setUnitTransformAndRotation(
      mapPropertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometry,
      productToPreview,
      getADUFromMapLayers(mapLayers)
    );
  }, [leafletMapState.isStatic, mapPropertyData]);

  // Handles resizing window
  useEffect(() => {
    if (map) {
      map.invalidateSize();
    }
  }, [width, height, map]);

  // Handles centering unit
  useEffect(() => {
    if (leafletMapState.bounds?.isValid() && map) {
      map.fitBounds(leafletMapState.bounds);
    }
  }, [leafletMapState.bounds, map]);

  // Handles resetting state values where appropriate on unmount
  // Also sets the area map if linking directly to here
  useEffect(() => {
    if (displayProps.fullExperience) {
      const west = leafletMapState.bounds?.getWest();
      const south = leafletMapState.bounds?.getSouth();
      const east = leafletMapState.bounds?.getEast();
      const north = leafletMapState.bounds?.getNorth();
      const bounds: BBox = [west, south, east, north];
      if (leafletMapState.bounds?.isValid() && bounds) {
        dispatch(
          setGLMapAnimationOptions({
            bounds: bounds,
            pitch: 0,
            bearing: 0,
            duration: 50,
          })
        );
      }
    }
    return () => {
      dispatch(setLeafletMapIsStatic(false));
    };
  }, []);

  return (
    <div style={{width: "100%", height: "100%"}} ref={squareRef}>
      <MapViewContainer id={"real-map-container"}>
        <MapContainerWrapper isListExpanded={false}>
          <MapContainer
            style={{
              height: "100%",
              width: "100%",
            }}
            center={[viewport.latitude, viewport.longitude]}
            bounds={
              leafletMapState.bounds && leafletMapState.bounds.isValid()
                ? leafletMapState.bounds?.pad(0)
                : undefined
            }
            maxZoom={22}
            zoom={20}
            minZoom={17}
            zoomControl={false}
            scrollWheelZoom={displayProps?.fullExperience && !isMobile()}
            id={"map-container"}
            dragging={!isMobile()}
          >
            <InnerMap setMap={setMap} />
            {(!displayProps?.fullExperience || true) &&
              !displayProps?.minimal && (
                <ZoomControl
                  position={
                    displayProps?.fullExperience ? "bottomleft" : "bottomright"
                  }
                />
              )}
          </MapContainer>
          {leafletMapState.unitPlacementFeedback && (
            <UserFeedback
              userFeedback={[
                unitSizeFeedback,
                unitHeightFeedback,
                leafletMapState.unitPlacementFeedback,
              ]}
              minimal={
                !displayProps?.fullScreen ||
                columnCount <= 12 ||
                displayProps.panningDisabled === true
              }
              options={displayProps}
              saveAction={() => {
                const addressId = Address.getId(address ?? undefined);
                if (!addressId || !productToPreview || !productToPreview?.id) {
                  return;
                }

                const unitPlacementToSave: IUnitPlacement = {
                  coordinates: leafletMapState.lastCoordinates,
                  addressId: addressId,
                  product: productToPreview,
                  productId: productToPreview?.id,
                };
                const sitePayload = {
                  unitPlacement: unitPlacementToSave,
                  // Might be able to deprecate unit in the future and instead
                  //  use unitPlacement.product/productId
                  unit: productToPreview,
                };
                dispatch(
                  setLeafletMapLastSavedCoordinates(
                    leafletMapState.lastCoordinates
                  )
                );
                dispatch(setLeafletMapIsStatic(true));
                if (projectIdToSave) {
                  const configurationMapRef =
                    getConfigurationMapRef(projectIdToSave);
                  const configurationSiteRef =
                    getConfigurationSiteRef(projectIdToSave);
                  Utilities.setAndMergeDocumentInDB(
                    configurationMapRef,
                    sitePayload
                  );
                  return Utilities.setAndMergeDocumentInDB(
                    configurationSiteRef,
                    sitePayload
                  );
                }
              }}
              ahj={
                mapPropertyData?.jurisdictionData?.jurisdiction
                  ?.jurisdictionName ?? null
              }
              placementStatus={leafletMapState.unitPlacementFeedback}
              isEditing={!leafletMapState.isStatic}
              productId={productToPreview?.id ?? undefined}
              editButton={
                displayProps?.fullExperience &&
                hasSavedPlacement(unitPlacement) &&
                (unitPlacement?.addressId ===
                  Address.getId(address ?? undefined) ||
                  unitPlacement?.addressId ===
                    Address.getDeprecatedId_before_03_23(
                      address ?? undefined
                    )) ? (
                  <>Select an ADU to map.</>
                ) : (
                  <NatButton
                    label={
                      leafletMapState.isStatic ? "Edit placement" : "Cancel"
                    }
                    type={"button"}
                    size={NatSize.XSMALL}
                    option={
                      leafletMapState.isStatic
                        ? StyleOption.PRIMARY_BLACK
                        : StyleOption.SECONDARY_ALT
                    }
                    hidden={isMobile()}
                    style={{pointerEvents: "all"}}
                    clickEvent={(e: any) => {
                      const isNowStatic = !leafletMapState.isStatic;
                      if (
                        isNowStatic &&
                        mapPropertyData &&
                        sanitizedPropertyGeometry
                      ) {
                        resetUnitPlacementToLastSave(
                          mapPropertyData,
                          mapLayers,
                          sanitizedPropertyGeometry,
                          leafletMapState
                        );
                      }
                      dispatch(setLeafletMapIsStatic(isNowStatic));
                      e?.stopPropagation();
                    }}
                  />
                )
              }
              aHJModalPayload={aHJModalPayload}
              setAHJModalPayload={setAHJModalPayload}
              style={
                displayProps?.fullExperience
                  ? {
                      marginTop:
                        !!productParameter && !!productToPreview
                          ? "0rem"
                          : "3rem",
                      transition: "all 150ms ease-in-out",
                    }
                  : {}
              }
            />
          )}
        </MapContainerWrapper>
      </MapViewContainer>
    </div>
  );
};

export default forwardRef(Map);
