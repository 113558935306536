import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {DTCPresentationView} from "./DTC/DTCPresentationView";
import React from "react";
import {B2BPresentationView} from "./B2B/B2BPresentationView";

export const PortalPresentationRoot = (props: {}) => {
  const {isDevelopmentByVilla} = useActiveProject();
  if (isDevelopmentByVilla) {
    return <B2BPresentationView />;
  }
  return <DTCPresentationView />;
};
