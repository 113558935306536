import {
  Address,
  convertStringCase,
  IAddressDetails,
  IAreaDetails,
  isBlankString,
} from "@natomas-org/core";
import {
  CompletedProjectsAppInfo,
  PropertyMapAppInfo,
} from "../../../_shared/hooks/useNavigation/AppSwitcher";

export const getPageTitle = (
  parcelView?: boolean,
  property_search?: IAddressDetails,
  areaSearch?: IAreaDetails
): string => {
  const city = Address.getCity(areaSearch?.address ?? property_search);
  const state = Address.getState(areaSearch?.address ?? property_search);
  const fullAddress = Address.getFullAddress(property_search);
  let pageTitle = "Enter Address";
  if (
    !Address.isInvalidAddress(property_search) &&
    !isBlankString(fullAddress)
  ) {
    pageTitle = fullAddress;
  } else if (!isBlankString(city) && !isBlankString(state)) {
    pageTitle = `${city}, ${state}`;
  }
  return `Villa ${convertStringCase(
    (parcelView
      ? PropertyMapAppInfo.title
      : CompletedProjectsAppInfo.title
    ).toLowerCase(),
    {
      upperCaseFirstLetter: true,
      applyToAllWords: true,
    }
  )} | ${pageTitle}`;
};
export function elementInViewport(el: any) {
  if (!el) {
    return false;
  }
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.scrollY + window.innerHeight &&
    left < window.scrollX + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}
