import React from "react";
import {
  NatCheckCircleFill,
  NatCheckCircleHollow,
  NatCheckCircleIncompleteHollow,
  NatCheckCircleInProgress,
} from "../icon/icons";
import {VILLA_APPLE_GREEN} from "../colors";

export const CheckMark = (props: {
  size?: any;
  incomplete?: boolean;
  inProgress?: boolean;
  fillCircle?: boolean;
}) => {
  let checkCircle = <NatCheckCircleHollow />;
  if (props.fillCircle) {
    checkCircle = <NatCheckCircleFill />;
  }

  if (props.inProgress) {
    checkCircle = <NatCheckCircleInProgress />;
  } else if (props.incomplete) {
    checkCircle = <NatCheckCircleIncompleteHollow />;
  }
  return (
    <div
      style={{
        color: VILLA_APPLE_GREEN,
        fontSize: props.size ?? 20,
      }}
    >
      {checkCircle}
    </div>
  );
};
