import {usePage} from "./usePage";
import {useCustomerStatus} from "./useCustomerStatus";
import {useCurrentUser} from "./useCurrentUser";
import {useIsPublicApp} from "./useIsPublicApp";

export const useProgressBar = () => {
  const {isNatMobile} = usePage();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {loggedInUser} = useCurrentUser();
  const isPublicApp = useIsPublicApp();
  return {
    showProgressBar:
      !isNatMobile &&
      isCustomerPreDeposit &&
      loggedInUser != null &&
      !isPublicApp,
  };
};
