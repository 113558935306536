import React from "react";
import Banner from "../../../../../0-app/2-component/Banner";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";

const IntakeQuoteB2BBanner = () => {
  const {customer} = useCurrentCustomer();

  if (!customer?.used_b2b_intake) {
    return null;
  }

  return (
    <Banner
      id={"b2b-instant-quote-banner"}
      label={"Price doesn’t include your discount"}
      textProps={{
        text: "We offer discounts on projects with multiple units. This price does not reflect those discounts. Please contact our team to learn more.",
        links: [
          {
            replacementKey: "Please contact our team to learn more.",
            href: "https://www.villahomes.com/contact-us",
            target: "_blank",
          },
        ],
      }}
    />
  );
};

export default IntakeQuoteB2BBanner;
