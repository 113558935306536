import {mergeObjects} from "@natomas-org/core";
import {FactoryInventoryCatalogEditorKey} from "./interfaces";
import {updateInventoryUnit} from "../../../../../../../../../../database/firebase/api/catalog";

export const saveCatalogEditorChangesToProduct = (
  product: any,
  values: any
) => {
  const formattedValues = formatValues(values);
  const toSave = mergeObjects(product, formattedValues);
  return updateInventoryUnit(toSave);
};

const formatValues = (values: any) => {
  return {
    title: values?.[FactoryInventoryCatalogEditorKey.TITLE],
    displayDescription: values?.[FactoryInventoryCatalogEditorKey.BASE_DESC],
    interiorDescription:
      values?.[FactoryInventoryCatalogEditorKey.INTERIOR_DESC],
    exteriorDescription:
      values?.[FactoryInventoryCatalogEditorKey.EXTERIOR_DESC],
    tagline: values?.[FactoryInventoryCatalogEditorKey.TAGLINE],
    images: values?.[FactoryInventoryCatalogEditorKey.IMAGES],
    imageId: values?.[FactoryInventoryCatalogEditorKey.DEFAULT_IMAGE_ID],
    timeline: values?.[FactoryInventoryCatalogEditorKey.TIMELINE],
    productDetails: {
      squareFeet: values?.[FactoryInventoryCatalogEditorKey.SQUARE_FOOTAGE],
      bathrooms: values?.[FactoryInventoryCatalogEditorKey.BATHROOM_COUNT],
      bedrooms: values?.[FactoryInventoryCatalogEditorKey.BEDROOM_COUNT],
      dimensions: values?.[FactoryInventoryCatalogEditorKey.DIMENSIONS],
    },
  };
};
