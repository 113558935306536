import React, {useContext} from "react";
import CostPlusIcon from "./icons/CostPlus.svg";
import DesignConfirmationIcon from "./icons/DesignConfirmation.svg";
import PermitApprovalIcon from "./icons/PermitApproval.svg";
import PlanSetIcon from "./icons/PlanSet.svg";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {
  Phase1AndNextStepsDescription,
  Phase1AndNextStepsIcon,
  Phase1AndNextStepsSectionContainer,
  Phase1AndNextStepsSectionContentContainer,
  Phase1AndNextStepsSectionTitle,
} from "./styles";
import {NatHorizontalAlign} from "../../../../../../_shared/generics/_shared";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";
import {PROPOSAL_GENERATE_PDF} from "../../../../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {FULL_SCREEN_HEIGHT_REM} from "../../../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";

export const ProposalPhase1AndNextSteps = (props: {
  presentationView?: boolean;
}) => {
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  return (
    <div
      style={{
        minHeight: `${FULL_SCREEN_HEIGHT_REM * 0.9}rem`,
        maxHeight: `${FULL_SCREEN_HEIGHT_REM * 0.9}rem`,
        breakInside: "avoid",
        pageBreakInside: "avoid",
        pageBreakAfter: "avoid",
        breakAfter: "avoid",
      }}
    >
      <NatLabel
        label={"Introducing Phase 1 and Next Steps"}
        type={NatLabelType.H2}
        style={{
          width: "100%",
          textAlign: "center",
          paddingBottom: "1rem",
          pageBreakBefore: "avoid",
          breakBefore: "avoid",
        }}
      />
      <NatFlex FULL_WIDTH COLUMN HORIZONTAL_ALIGN={NatHorizontalAlign.CENTER}>
        <div style={{width: isGeneratingPDF ? "60%" : "60%"}}>
          <NatLabel
            label={
              "Once we begin Phase 1, our preconstruction team conducts a comprehensive feasibility study and obtains your permits. Concluding this phase, we will present an updated project estimate."
            }
            type={NatLabelType.P2}
          />
        </div>
        <hr style={{width: isGeneratingPDF ? "100%" : "80%"}} />
        <NatFlex COLUMN HORIZONTAL_ALIGN={NatHorizontalAlign.CENTER}>
          <Phase1AndNextStepsSectionContentContainer
            style={{width: isGeneratingPDF ? "60%" : undefined}}
          >
            <NatFlex
              COLUMN
              style={{
                position: "relative",
                left: "-82px",
                width: `calc(100% + 82px)`,
                gap: isGeneratingPDF ? "1rem" : "3rem",
              }}
            >
              <Phase1AndNextStepsSection
                description={
                  "We will guide you through our curated recommendations for materials and finishes, assisting you in finalizing your floor plan layout and making informed design choices."
                }
                icon={
                  <Phase1AndNextStepsIcon
                    src={CostPlusIcon}
                    alt={"cost-plus-icon"}
                  />
                }
                title={"Design confirmation"}
              />
              <Phase1AndNextStepsSection
                description={
                  "Our team will build and submit a robust plan set to the property's local jurisdiction for permit approval. We will coordinate site visits with subcontractors and vendors, as well as order any utility and feasibility reports necessary for a thorough analysis."
                }
                icon={
                  <Phase1AndNextStepsIcon
                    src={DesignConfirmationIcon}
                    alt={"design-confirmation-icon"}
                  />
                }
                title={"Building a plan set"}
              />
              <Phase1AndNextStepsSection
                description={
                  "Our permitting team will take charge of submitting the plan set and working closely with the local jurisdiction to move your permit through the review process and secure approval. Your dedicated project manager will keep you well-informed by providing regular status updates."
                }
                icon={
                  <Phase1AndNextStepsIcon
                    src={PermitApprovalIcon}
                    alt={"permit-approval-icon"}
                  />
                }
                title={"Permit approval"}
              />
              <Phase1AndNextStepsSection
                description={
                  "This is a cost plus proposal - in the current market, costs for goods, materials, and labor in construction are constantly in flux. We are locking in an O&P rate that will not change, to ensure you always get the best total price for your project. Final budget numbers will be presented to you in a document called the Authorization to Build (ATB), which will be your final construction contract."
                }
                icon={
                  <Phase1AndNextStepsIcon
                    src={PlanSetIcon}
                    alt={"plan-set-icon"}
                  />
                }
                title={"Cost Plus"}
              />
            </NatFlex>
          </Phase1AndNextStepsSectionContentContainer>
        </NatFlex>
      </NatFlex>
    </div>
  );
};

const Phase1AndNextStepsSection = (props: {
  icon: JSX.Element;
  title: string;
  description: string;
}) => {
  return (
    <Phase1AndNextStepsSectionContainer>
      {props.icon}
      <NatFlex FULL_WIDTH NOGAP>
        <Phase1AndNextStepsSectionTitle>
          {props.title}
        </Phase1AndNextStepsSectionTitle>
        <Phase1AndNextStepsDescription>
          {props.description}
        </Phase1AndNextStepsDescription>
      </NatFlex>
    </Phase1AndNextStepsSectionContainer>
  );
};
