import styled from "styled-components";

const Label = styled.label`
  color: #807d7a;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0;
  margin: 0;
`;
const Input = styled.input`
  width: 100%;
  outline: 0;
  font-size: 16px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--black-10);
  font-family: Inter-Regular, serif;
  font-weight: 400;
  background: #fff;
  &:focus {
    border-color: var(--semantic-informational);
  }
  &:disabled {
    cursor: not-allowed;
    background: #f1f3f5;
    border-color: #acacac;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex-grow: 1;
`;

const Error = styled.p`
  color: orangered;
  font-size: 12px;
  font-family: Inter-Regular, serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
  white-space: pre-wrap;
`;

export {Container, Input, Label, Error};
