import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {IDocumentDetails, IGCloudFileStorageDetails} from "@natomas-org/core";
import {NatImage} from "../../image/NatImage";
import {Page} from "react-pdf";
import {Document} from "react-pdf/dist/esm/entry.webpack";
import {ISmartImageProps} from "../../image/SmartImage";
import {DocumentDownloadIcon} from "./index";
import {NatSwiper} from "../../swiper";
import {NatPresentation} from "../../presentation";
import styled from "styled-components";
import NatLabel, {NatLabelType} from "../../label/NatLabel";
import {NatButton} from "../../button";
import {IconPosition, NatSize, StyleOption} from "../../_shared";
import {NatImageIcon} from "../../../icon/icons";
import {GrDocumentPdf} from "react-icons/gr";
import {storage} from "../../../../../database/firebase";
import useActiveProject from "../../../hooks/useProject/useActiveProject";

const DEFAULT_FILE_PREVIEW_STYLE = {
  width: "50px",
  height: "50px",
  borderRadius: "5px",
  overflow: "hidden",
};

const DEFAULT_THUMBNAIL_PREVIEW_STYLE = {
  width: "100px",
  // height: "100px",
  borderRadius: "5px",
  overflow: "hidden",
};

const getSortedDocuments = (
  currentDocument: IDocumentDetails,
  documents: IDocumentDetails[]
) => {
  // We can't specify a starting index, but we can re-arrange the images to work around this
  const offsetEntries: IDocumentDetails[] = [];
  const wrappedEntries: IDocumentDetails[] = [];
  let foundEntry = false;
  documents.forEach((entry, index) => {
    if (entry?.document_id === currentDocument?.document_id || foundEntry) {
      offsetEntries.push(entry);
      foundEntry = true;
    } else {
      wrappedEntries.push(entry);
    }
  });

  return [...offsetEntries, ...wrappedEntries];
};

export const DocumentFilePreviewer = (props: IDocumentFilePreviewerProps) => {
  const {
    documentList,
    document,
    imageId,
    imageURL,
    style,
    type,
    hidden,
    presentation,
    subType,
    enablePresentation,
    fileFetchTitle,
  } = props;
  const {id: projectId} = useActiveProject();
  const [slideIndex, setSlideIndex] = useState(0);
  const [isPresentationOpen, setIsPresentationOpen] = useState(false);
  const [fileURL, setFileURL] = useState<string | null>(null);
  const allDocuments = documentList ?? [document];
  const documents = getSortedDocuments(document, allDocuments);
  const currentDocument = documents[slideIndex];
  const loadedDocuments = useRef<{[key: string]: boolean}>({});
  const [pagesPerDocument, setPagesPerDocument] = useState<{
    [key: string]: number;
  }>({});
  const presentationSlides = useMemo(() => {
    return documents.map((docDetails) => {
      if (docDetails?.details?.mimeType === "application/pdf") {
        let content = null;

        // keep track of loaded documents, so we don't reload them
        // We don't want to render the document until it is seen for the first time
        if (
          currentDocument?.document_id === docDetails?.document_id ||
          loadedDocuments.current[docDetails?.document_id]
        ) {
          const pages = pagesPerDocument[docDetails.document_id] ?? 1;

          // for loop to get the number of pages in the document
          const pageDivs = [];
          for (let i = 1; i <= pages; i++) {
            pageDivs.push(
              <Page
                key={"document_previewer_" + document?.document_id + i}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                loading={"Loading PDF"}
                pageNumber={i}
              />
            );
          }
          content = (
            <Document
              onLoadError={(error) => {
                console.log(error);
              }}
              file={fileURL ?? imageURL}
              loading={"Loading PDF"}
              onLoadSuccess={(proxy) => {
                setPagesPerDocument((prev) => ({
                  ...prev,
                  [docDetails?.document_id]: proxy.numPages,
                }));
              }}
            >
              {pageDivs}
            </Document>
          );
        }

        return {
          content: (
            <DocumentScrollDiv>
              <NatLabel
                style={{color: "white"}}
                label={docDetails.title}
                type={NatLabelType.H4}
              />
              {content}
            </DocumentScrollDiv>
          ),
          width: "100%",
        };
      }
      return {
        content: (
          <img
            src={fileURL ?? imageURL}
            alt={docDetails?.title}
            style={{maxHeight: "90vh"}}
          />
        ),
        width: "100%",
      };
    });
  }, [
    currentDocument?.document_id,
    document?.document_id,
    documents,
    fileURL,
    imageURL,
    pagesPerDocument,
  ]);

  useEffect(() => {
    if (
      currentDocument?.details &&
      "storageRefPath" in currentDocument?.details &&
      !!(currentDocument?.details as IGCloudFileStorageDetails)?.storageRefPath
    ) {
      (storage as any)
        .ref(
          `${
            (currentDocument?.details as IGCloudFileStorageDetails)
              ?.storageRefPath
          }`
        )
        .getDownloadURL()
        .then((url: any) => {
          setFileURL(url);
          console.log(url);
        });
    }
  }, [
    currentDocument?.details,
    currentDocument?.filename,
    fileFetchTitle,
    projectId,
  ]);
  if ((!currentDocument && !imageId && !imageURL) || hidden) {
    return null;
  }
  const onClickOnPreview = () => {
    if (enablePresentation) {
      setIsPresentationOpen(true);
    }
  };

  const enrichedStyle =
    subType === "file"
      ? DEFAULT_FILE_PREVIEW_STYLE
      : DEFAULT_THUMBNAIL_PREVIEW_STYLE;

  const fullStyle: CSSProperties = {
    ...enrichedStyle,
    ...style,
    cursor: enablePresentation ? "pointer" : "default",
    zIndex: 0,
    fontSize: "2.5rem",
    textAlign: "center",
  };

  if (subType === "file" && !isPresentationOpen) {
    return (
      <NatButton
        label={document?.title}
        size={NatSize.XSMALL}
        type={"button"}
        clickEvent={onClickOnPreview}
        option={StyleOption.SECONDARY}
        style={{border: "1px solid rgba(0,0,0,0.3)"}}
        icon={{icon: <NatImageIcon />, iconPosition: IconPosition.LEFT}}
      />
    );
  } else if ((type === "thumbnail" || !presentation) && !isPresentationOpen) {
    if (currentDocument?.details?.mimeType === "application/pdf") {
      // return null;
      return (
        <div style={fullStyle} onClick={onClickOnPreview}>
          <GrDocumentPdf />
        </div>
      );
      return (
        <div style={fullStyle} onClick={onClickOnPreview}>
          <Document file={currentDocument.url} loading={""}>
            <Page loading={""} pageNumber={1} />
          </Document>
        </div>
      );
    }

    const imageProps: ISmartImageProps = {
      style: {objectFit: "cover"},
    };

    return (
      <div style={fullStyle} onClick={onClickOnPreview}>
        <NatImage
          style={fullStyle}
          imageURL={currentDocument?.url ?? imageURL}
          imageId={imageId}
          imageProps={imageProps}
        />
      </div>
    );
  }
  const close = () => {
    presentation?.close();
    setIsPresentationOpen(false);
  };

  return (
    <NatPresentation
      show={presentation?.show || isPresentationOpen}
      close={close}
      additionalActions={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            minHeight: "2rem",
          }}
        >
          <NatLabel
            hidden={currentDocument.details?.mimeType === "application/pdf"}
            style={{color: "white", margin: 0, padding: 0}}
            label={currentDocument.title}
            type={NatLabelType.H4}
          />
          <DocumentDownloadIcon document={currentDocument} />
        </div>
      }
    >
      <NatSwiper
        autoFormatSlides={false}
        slides={presentationSlides}
        navigation={false}
        alternativeNavigationButtons={true}
        zoom={true}
        onSlideClick={(clickTarget, index) => {
          if (clickTarget === "background") {
            setTimeout(close, 200);
          }
        }}
        onSlideChangeAction={(index) => {
          setSlideIndex(index);
          const document = documents[index];
          loadedDocuments.current[document?.document_id] = true;
        }}
        isKeyboardEnabled={true}
      />
    </NatPresentation>
  );
};

interface IDocumentFilePreviewerProps {
  document: IDocumentDetails;
  documentList?: IDocumentDetails[];
  imageId?: string;
  imageURL?: string;
  style?: React.CSSProperties;
  type: "thumbnail" | "full-screen";
  subType?: "file" | "preview";
  hidden?: boolean;
  fileFetchTitle?: string;
  enablePresentation?: boolean;
  presentation?: {
    show: boolean;
    close: () => void;
  };
}

const DocumentScrollDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;
