import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";
import {VILLA_APPLE_GREEN, VILLA_BLUE, WHITE} from "../../colors";

const narrowHeight = "2rem";
export const NatomasSwitch = styled.div<{build: boolean}>`
  border-radius: ${(props) => (props.build ? "2.25rem" : "3rem")};
  height: ${(props) => (props.build ? narrowHeight : "2.5rem")};
  width: fit-content;
  min-width: 18rem;
  flex-direction: row;
  overflow: hidden;
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0,
    rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
  span {
    height: ${narrowHeight};
    input {
      display: none;
      & + label {
        font-size: ${(props) => (props.build ? "0.875rem" : "1rem")};
        cursor: pointer;
        position: relative;
        height: ${(props) => (props.build ? narrowHeight : "2.5rem")};
        padding: 0 1rem;
        line-height: ${(props) => (props.build ? narrowHeight : "2.5rem")};
      }
      &:checked + label {
        cursor: default;
        user-select: none;
        color: ${WHITE};
        background-color: ${VILLA_BLUE};
        transition: color, background-color 200ms;
        border-radius: ${(props) => (props.build ? "2.25rem" : "3rem")};
      }
    }
  }
`;

export const NatSwitchLabel = styled.label<{selected: boolean}>`
  font-family: ${FONT_FAMILY_MEDIUM};
  width: 50%;
  text-align: center;
  user-select: none;
`;

export const NatMicroSwitch = styled.label<{
  largeDisplay?: boolean;
  color?: string;
}>`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.largeDisplay ? "68px" : "40px")};
  height: ${(props) => (props.largeDisplay ? "36px" : "20px")};
  margin: 0;
  padding: 0;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      background-color: ${(props) =>
        props.color ?? props.largeDisplay ? VILLA_APPLE_GREEN : "#2196f3"};
    }
    &:focus {
      box-shadow: 0 0 1px
        ${(props) =>
          props.color ?? props.largeDisplay ? VILLA_APPLE_GREEN : "#2196f3"};
    }
  }
  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.largeDisplay ? "rgba(0, 0, 0, 0.1)" : "#ccc"};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: ${(props) => (props.largeDisplay ? "24px" : "16px")};
    &:before {
      border-radius: 50%;
      position: absolute;
      content: "";
      height: ${(props) => (props.largeDisplay ? "30px" : "16px")};
      width: ${(props) => (props.largeDisplay ? "30px" : "16px")};
      left: ${(props) => (props.largeDisplay ? "3px" : "2px")};
      bottom: ${(props) => (props.largeDisplay ? "3px" : "2px")};
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input:checked + span {
    background-color: ${(props) =>
      props.color ?? props.largeDisplay ? VILLA_APPLE_GREEN : "#2196f3"};
  }
  input:checked + span:before {
    -webkit-transform: translateX(
      ${(props) => (props.largeDisplay ? "32px" : "20px")}
    );
    -ms-transform: translateX(
      ${(props) => (props.largeDisplay ? "32px" : "20px")}
    );
    transform: translateX(${(props) => (props.largeDisplay ? "32px" : "20px")});
  }
`;
