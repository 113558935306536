import {
  IDesignPriceSummaryForMode,
  priceTextFromMicros,
} from "@natomas-org/core";

export const getTotalProposalPriceInMicros = (
  pricing: IDesignPriceSummaryForMode | null,
  totalSiteWorkCostInMicros: number | null
) => {
  if (
    !pricing ||
    pricing?.totalDesignPriceMicros === 0 ||
    totalSiteWorkCostInMicros == null
  ) {
    return 0;
  }

  return pricing.totalDesignPriceMicros + totalSiteWorkCostInMicros;
};

export const getTotalProposalPrice = (
  pricing: IDesignPriceSummaryForMode | null,
  totalSiteWorkCostInMicros: any
) => {
  if (
    !pricing ||
    pricing?.totalDesignPriceMicros === 0 ||
    totalSiteWorkCostInMicros == null
  ) {
    return "---,---";
  }

  return priceTextFromMicros(
    pricing.totalDesignPriceMicros + totalSiteWorkCostInMicros,
    "min"
  );
};
