import styled from "styled-components";
import {GRAY} from "../../../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const QuickInfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuickInfoDisclaimer = styled.div`
  font-size: 0.675rem;
  color: ${GRAY};
  font-style: italic;

  &:before {
    content: "Disclaimer: ";
  }
`;

export const QuickInfoSectionTitle = styled.div`
  border-top: 1px solid ${GRAY};
  font-size: 1.5rem;
  padding: 0.5rem 0;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const QuickInfoSectionSubTitle = styled.div`
  border-top: 1px dotted rgba(0, 0, 0, 0.3);
  font-size: 1.2rem;
  padding-top: 1rem;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const QuickInfoSectionDescription = styled.div`
  font-size: 0.9rem;
  font-family: ${FONT_FAMILY_REGULAR};
  padding-bottom: 0.5rem;
`;

export const QuickInfoTitle = styled.div`
  font-size: 1.1rem;
  font-style: italic;
  font-family: ${FONT_FAMILY_MEDIUM};
`;
