import React from "react";
import styled from "styled-components";

export const EditableCellLinkStyle: React.CSSProperties = {
  minWidth: "max-content",
  whiteSpace: "nowrap",
};
export const NonEditableCellDiv = styled.div`
  min-width: ${EditableCellLinkStyle.minWidth};
  white-space: ${EditableCellLinkStyle.whiteSpace};
`;
