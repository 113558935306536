import {
  CLUSTER_LAYER_NAME,
  MAP_JSON_ACCENT_COLOR_DARK,
  PARCELS_MIN_ZOOM,
  PROJECTS_MAX_ZOOM,
  PROJECTS_MAX_ZOOM_TOOLTIP,
  PROJECTS_ZOOM_ON_CLICK,
} from "./constants";
import React, {Dispatch} from "react";
import polylabel from "polylabel";
import {visuallyLoadNewParcel} from "./renderHelpers";
import {MAP_ANIMATION_DURATION_SECONDS} from "../../theme/constants";
import mapboxgl, {LngLatLike, MapboxGeoJSONFeature} from "mapbox-gl";
import {
  getKStringFromMicros,
  IPublicProjectInfo,
  PublicProjectDisplayType,
} from "@natomas-org/core";
import {Feature, Geometry} from "geojson";
import {
  setGLMapAnimationOptions,
  setGLMapAnimationOptionsWithoutPreviousState,
  setGLMapProjectToView,
} from "../../../../map/slices/mapSlice";
import {store} from "../../../../../store";
import {NearbyProjectDetails} from "../../../../map/_shared/data";
import {getAllParcels, PublicProjectInfo} from "@natomas-org/service";
import {
  CLUSTER_PROJECT_COLOR_LOGIC,
  SINGLE_PROJECT_BORDER_COLOR_LOGIC,
  SINGLE_PROJECT_CENTER_COLOR_LOGIC,
  SINGLE_PROJECT_CENTER_DEFAULT_OPACITY,
  SINGLE_PROJECT_CENTER_HOVER_OPACITY,
} from "../../../../map/constants";
import {IPublicProjectInfoModalPayload} from "../ProjectDetailsModal/constants";

let hoveredStateId: number;
let popupIsProject: boolean;
let popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
});

export const blankGeoJSON = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {name: ""},
      geometry: {
        type: "Point",
        coordinates: [0, 0],
      },
    },
  ],
};

export function addMapLayers(
  map: React.MutableRefObject<any>,
  allProjects: any,
  parcels: any
) {
  !map.current.getSource("parcels") &&
    map.current.addSource("parcels", {
      type: "geojson",
      data: parcels,
      generateId: true,
    });
  // filters for classifying projects into enriched and not
  const featuresContainEnrichedDataPoint = [
    "any",
    ["==", ["get", "displayType"], PublicProjectDisplayType.COMPLETED_ENRICHED],
    true,
  ];

  !map.current.getSource("clusters") &&
    map.current.addSource("clusters", {
      type: "geojson",
      data: allProjects,
      generateId: true,
      cluster: true,
      clusterMaxZoom: 15,
      clusterRadius: 0,
      clusterProperties: {
        // keep separate counts for each magnitude category in a cluster
        hasEnrichedData: featuresContainEnrichedDataPoint,
      },
    });
  map.current.addLayer({
    id: "projects-count",
    type: "symbol",
    visibility: "visible",
    source: "clusters",
    filter: ["has", "point_count"],
    layout: {
      "text-field": "{point_count_abbreviated}",
      "text-size": 12,
    },
  });
  map.current.addLayer(
    {
      id: CLUSTER_LAYER_NAME,
      source: "clusters",
      visibility: "visible",
      type: "circle",
      filter: ["has", "point_count"],
      paint: {
        "circle-stroke-width": {
          base: 1,
          stops: [
            [1, 1],
            [10, 2],
            [15, 3],
            [16, 4],
          ],
        },
        "circle-stroke-opacity": 1,
        "circle-stroke-color": CLUSTER_PROJECT_COLOR_LOGIC,
        "circle-color": CLUSTER_PROJECT_COLOR_LOGIC,
        "circle-radius": ["step", ["get", "point_count"], 20, 7, 26, 12, 32],
        "circle-opacity": 0.5,
      },
    },
    "projects-count"
  );

  map.current.addLayer(
    {
      id: "projects-point",
      maxzoom: PROJECTS_MAX_ZOOM,
      type: "circle",
      visibility: "visible",
      source: "clusters",
      filter: ["!", ["has", "point_count"]],

      paint: {
        "circle-color": SINGLE_PROJECT_CENTER_COLOR_LOGIC,
        "circle-stroke-color": SINGLE_PROJECT_BORDER_COLOR_LOGIC,
        "circle-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          SINGLE_PROJECT_CENTER_HOVER_OPACITY,
          SINGLE_PROJECT_CENTER_DEFAULT_OPACITY,
        ],
        "circle-stroke-width": {
          base: 1,
          stops: [
            [1, 1],
            [10, 2],
            [15, 3],
            [16, 4],
          ],
        },
        "circle-radius": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          //  Interpolate exponentially between zoom level 10 and 18
          10, // At zoom level 10
          7, // Display this radius
          18, // At zoom level 18
          600, // Display this radius
        ],
      },
    },
    CLUSTER_LAYER_NAME
  );
  map.current.addLayer(
    {
      id: "parcels-fill",
      source: "parcels",
      visibility: "visible",
      minzoom: PARCELS_MIN_ZOOM,
      type: "fill",
      layout: {},
      paint: {
        "fill-color": "hsl(360, 100%, 100%)",
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.6,
          0.1,
        ],
        "fill-outline-color": MAP_JSON_ACCENT_COLOR_DARK, //"hsla(200, 80%,50%, 0.25)",
      },
    },
    "projects-point"
  );
  map.current.addLayer(
    {
      id: "parcels-line",
      source: "parcels",
      visibility: "visible",
      minzoom: PARCELS_MIN_ZOOM,
      type: "line",
      paint: {
        "line-width": 2,
        "line-dasharray": [2, 1],
        "line-color": "white",
      },
    },
    "projects-point"
  );
}

export function mouseLeaveMap(
  map: React.MutableRefObject<any>,
  dispatch: Dispatch<any>,
  e?: any
) {
  if (hoveredStateId) {
    map.current.setFeatureState(
      {source: "parcels", id: hoveredStateId},
      {hover: false}
    );
  }
  if (e?.point) {
    const features = map.current.getLayer("projects-point")
      ? map.current.queryRenderedFeatures(e.point, {
          layers: ["projects-point"],
        })
      : undefined;
    const projectFeatures = map.current.getLayer(CLUSTER_LAYER_NAME)
      ? map.current.queryRenderedFeatures(e.point, {
          layers: [CLUSTER_LAYER_NAME],
        })
      : undefined;
    const parcelFeatures = map.current.getLayer("parcels-fill")
      ? map.current.queryRenderedFeatures(e.point, {
          layers: ["parcels-fill"],
        })
      : undefined;
    const noProjectFeatures =
      (!features || features?.length < 1) &&
      (!projectFeatures || projectFeatures?.length < 1);
    const noParcelFeatures = !parcelFeatures && parcelFeatures?.length < 1;
    if (noProjectFeatures) {
      mouseLeaveProjects(map);
    }
    if (noParcelFeatures) {
      mouseLeaveParcels(map);
    }
    let matchingIds: boolean = false;
    projectFeatures?.forEach((feature: any) => {
      if (feature?.id === hoveredStateId) {
        matchingIds = true;
      }
    });
    if (matchingIds) {
    } else {
      hoveredStateId = -1;
      map.current.getCanvas().style.cursor = "";
      popup.remove();
    }
  } else {
    map.current.getCanvas().style.cursor = "";
    hoveredStateId = -1;
    popup.remove();
  }
}

function mouseLeaveParcels(map: React.MutableRefObject<any>) {
  if (hoveredStateId) {
    map.current.setFeatureState(
      {source: "parcels", id: hoveredStateId},
      {hover: false}
    );
  }
  return;
}

export function mouseLeaveProjects(map: React.MutableRefObject<any>) {
  if (
    !map.current.getLayer(CLUSTER_LAYER_NAME) ||
    !map.current.getLayer("projects-point")
  ) {
    return;
  }
  if (hoveredStateId) {
    map.current.setFeatureState(
      {source: "clusters", id: hoveredStateId},
      {hover: false}
    );
  }
  return;
}

export function projectPointHoverAction(
  projectPointLayerFeature: Feature<any> & {
    layer: mapboxgl.Layer;
    source: string;
    sourceLayer: string;
    state: {[p: string]: any};
    id: number;
  },
  map: React.MutableRefObject<any>
) {
  if (
    projectPointLayerFeature?.id == null ||
    projectPointLayerFeature?.properties?.displayType ===
      PublicProjectDisplayType.TARGET
  ) {
    return;
  }
  const nearbyProjectDetails: IPublicProjectInfo = JSON.parse(
    projectPointLayerFeature?.properties?.projectDetails
  );
  if (hoveredStateId !== projectPointLayerFeature.id) {
    map.current.setFeatureState(
      {source: "clusters", id: hoveredStateId},
      {hover: false}
    );
  }
  hoveredStateId = projectPointLayerFeature.id;
  map.current.setFeatureState(
    {source: "clusters", id: hoveredStateId},
    {hover: true}
  );
  map.current.getCanvas().style.cursor = "pointer";
  popup
    .setLngLat((projectPointLayerFeature.geometry as any).coordinates.slice())
    .setHTML(NearbyProjectDetails.getEnrichedPopupContent(nearbyProjectDetails))
    .addTo(map.current);
  popupIsProject = true;
  map.current.setFeatureState(
    {source: "clusters", id: hoveredStateId},
    {hover: true}
  );
}

export function projectPointClickAction(
  projectPointLayer: Feature<any> & {
    layer: mapboxgl.Layer;
    source: string;
    sourceLayer: string;
    state: {[p: string]: any};
    id: number;
  },
  isAdmin: boolean,
  map: React.MutableRefObject<any>,
  dispatch: Dispatch<any>,
  setProjectToViewOnClick: boolean,
  setShowEnrichedMapProjectModal: React.Dispatch<
    React.SetStateAction<IPublicProjectInfoModalPayload | null>
  >,
  isNatMobile?: boolean
) {
  if (isNatMobile) {
    return projectPointHoverAction(projectPointLayer, map);
  }
  if (
    projectPointLayer.id === undefined ||
    projectPointLayer?.properties?.displayType ===
      PublicProjectDisplayType.TARGET
  ) {
    return;
  }
  if (projectPointLayer.geometry.coordinates?.length > 1) {
    const nearbyProjectDetails: IPublicProjectInfo | undefined =
      !!projectPointLayer?.properties?.projectDetails
        ? JSON.parse(projectPointLayer?.properties?.projectDetails)
        : null;
    const shouldShowModal =
      !!nearbyProjectDetails &&
      PublicProjectInfo.hasEnrichedData(nearbyProjectDetails);
    const showProjectOnMapAction = () => {
      const doNotSetProjectToViewOnClick =
        store.getState()?.map?.glMapState?.projectToPreview?.fullView === true;
      if (doNotSetProjectToViewOnClick) {
        dispatch(
          setGLMapAnimationOptionsWithoutPreviousState({
            lat: projectPointLayer.geometry.coordinates[1],
            lng: projectPointLayer.geometry.coordinates[0],
            zoom: PROJECTS_ZOOM_ON_CLICK,
            pitch: 0,
            bearing: 0,
            duration: MAP_ANIMATION_DURATION_SECONDS * 1000,
            doNotSavePreviousState: doNotSetProjectToViewOnClick,
          })
        );
      } else {
        dispatch(
          setGLMapAnimationOptions({
            lat: projectPointLayer.geometry.coordinates[1],
            lng: projectPointLayer.geometry.coordinates[0],
            zoom: PROJECTS_ZOOM_ON_CLICK,
            pitch: 0,
            bearing: 0,
            duration: MAP_ANIMATION_DURATION_SECONDS * 1000,
          })
        );
      }
    };
    if (shouldShowModal && !!nearbyProjectDetails) {
      const modalPayload: IPublicProjectInfoModalPayload = {
        mapProjectDetails: nearbyProjectDetails,
        viewOnMapAction: showProjectOnMapAction,
      };
      setShowEnrichedMapProjectModal(modalPayload);
    } else {
      // Only zoom on click action if no modal is shown
      showProjectOnMapAction();
    }

    if (!!projectPointLayer.properties && setProjectToViewOnClick) {
      dispatch(
        setGLMapProjectToView(projectPointLayer.properties?.projectDetails)
      );
    }
  }
  return;
}

export function projectClusterHoverAction(
  projectClusterLayerFeature: Feature<Geometry> & {
    layer: mapboxgl.Layer;
    source: string;
    sourceLayer: string;
    state: {[p: string]: any};
    id: number;
  },
  map: React.MutableRefObject<any>
) {
  map.current.getCanvas().style.cursor = "pointer";
  if (
    projectClusterLayerFeature?.id === undefined ||
    hoveredStateId !== projectClusterLayerFeature?.id
  ) {
    map.current.setFeatureState(
      {source: "clusters", id: hoveredStateId},
      {hover: false}
    );
  }
  const clusterId = projectClusterLayerFeature?.properties?.cluster_id;
  const point_count = projectClusterLayerFeature?.properties?.point_count;
  let durationLow: number | undefined = undefined;
  let durationHigh: number | undefined = undefined;
  let productPriceLow: number | undefined = undefined;
  let productPriceHigh: number | undefined = undefined;
  let siteWorkLow: number | undefined = undefined;
  let siteWorkHigh: number | undefined = undefined;
  let numberOfProjects: number | undefined = undefined;
  map.current
    .getSource("clusters")
    ?.getClusterLeaves(
      clusterId,
      point_count,
      0,
      function (err: any, aFeatures: Feature<Geometry>[]) {
        numberOfProjects = aFeatures?.length;
        aFeatures?.forEach((feature) => {
          const projectDetails = feature?.properties?.projectDetails;
          const duration =
            PublicProjectInfo.getProjectDurationMonths(projectDetails);
          const productPrice =
            PublicProjectInfo.getProductPrice(projectDetails);
          const siteWorkPrice =
            PublicProjectInfo.getTotalSiteWorkPrice(projectDetails);
          if (duration) {
            if (
              durationLow === undefined ||
              (!!durationLow && durationLow > duration)
            ) {
              durationLow = duration;
            }
            if (
              durationHigh === undefined ||
              (!!durationHigh && durationHigh < duration)
            ) {
              durationHigh = duration;
            }
          }
          if (productPrice) {
            if (
              productPriceLow === undefined ||
              (!!productPriceLow && productPriceLow > productPrice)
            ) {
              productPriceLow = Math.floor(productPrice);
            }
            if (
              productPriceHigh === undefined ||
              (!!productPriceHigh && productPriceHigh < productPrice)
            ) {
              productPriceHigh = Math.floor(productPrice);
            }
          }
          if (siteWorkPrice) {
            if (
              siteWorkLow === undefined ||
              (!!siteWorkLow && siteWorkLow > siteWorkPrice)
            ) {
              siteWorkLow = Math.floor(siteWorkPrice);
            }
            if (
              siteWorkHigh === undefined ||
              (!!siteWorkHigh && siteWorkHigh < siteWorkPrice)
            ) {
              siteWorkHigh = Math.floor(siteWorkPrice);
            }
          }
        });
        const totalProductPriceDisplay =
          siteWorkLow && siteWorkHigh && productPriceLow && productPriceHigh
            ? `<h6 class="h6">${getKStringFromMicros(
                Math.floor(siteWorkLow + productPriceLow)
              )}  -  ${getKStringFromMicros(
                Math.floor(siteWorkHigh + productPriceHigh)
              )}</h6>`
            : "";
        popup
          .setLngLat(
            (projectClusterLayerFeature.geometry as any).coordinates.slice()
          )
          .setHTML(
            `<div><h3 class="h3">${numberOfProjects} Projects</h3>${totalProductPriceDisplay}</div>`
          )
          .addTo(map.current);
        popupIsProject = true;
        hoveredStateId = projectClusterLayerFeature.id;
        map.current.setFeatureState(
          {source: "clusters", id: hoveredStateId},
          {hover: true}
        );
      }
    );
}

export function projectClusterClickAction(
  projectClusterLayer: Feature<any> & {
    layer: mapboxgl.Layer;
    source: string;
    sourceLayer: string;
    state: {[p: string]: any};
    id: number;
  },
  isAdmin: boolean,
  map: React.MutableRefObject<any>,
  dispatch: Dispatch<any>
) {
  const clusterId = projectClusterLayer?.properties?.cluster_id;
  popup.remove();
  return (
    clusterId &&
    map.current
      .getSource("clusters")
      .getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
        if (err) return;
        if (projectClusterLayer.geometry.coordinates?.length > 1) {
          dispatch(
            setGLMapAnimationOptions({
              lat: projectClusterLayer.geometry.coordinates[1],
              lng: projectClusterLayer.geometry.coordinates[0],
              zoom: zoom + 0.5,
              pitch: 0,
              bearing: 0,
              duration: MAP_ANIMATION_DURATION_SECONDS * 1000,
            })
          );
        }
      })
  );
}

export function addMapMouseLayerEffects(
  map: React.MutableRefObject<any>,
  loadedAddressDetails: React.MutableRefObject<any>,
  dispatch: Dispatch<any>,
  isAdmin: boolean,
  PAST_PROJECTS_NAV: React.MutableRefObject<boolean>,
  setShowEnrichedMapProjectModal: React.Dispatch<
    React.SetStateAction<IPublicProjectInfoModalPayload | null>
  >,
  isNatMobile: boolean
) {
  // All Mouse Enter Events
  map.current.on("mouseenter", [CLUSTER_LAYER_NAME], (e: any) => {
    const features = map.current.queryRenderedFeatures(e.point, {
      layers: [CLUSTER_LAYER_NAME],
    });
    if (!features || features?.length < 1) {
      return;
    }
    const projectsLayerFeature = features?.find(
      (feature: MapboxGeoJSONFeature) => {
        return feature?.layer?.id === CLUSTER_LAYER_NAME;
      }
    );
    if (
      projectsLayerFeature &&
      map.current.getZoom() <= PROJECTS_MAX_ZOOM_TOOLTIP
    ) {
      return projectClusterHoverAction(projectsLayerFeature, map);
    }
    return;
  });
  map.current.on("mouseenter", ["projects-point"], (e: any) => {
    const features = map.current.queryRenderedFeatures(e.point, {
      layers: ["projects-point"],
    });
    if (!features || features?.length < 1) {
      return;
    }
    const projectPointLayerFeature = features?.find(
      (feature: MapboxGeoJSONFeature) => {
        return feature?.layer?.id === "projects-point";
      }
    );
    if (
      projectPointLayerFeature &&
      map.current.getZoom() <= PROJECTS_MAX_ZOOM_TOOLTIP
    ) {
      return projectPointHoverAction(projectPointLayerFeature, map);
    }
    return;
  });
  // All Mouse Move Events
  map.current.on("mousemove", (e: any) => {
    const features = map.current.getLayer("projects-point")
      ? map.current.queryRenderedFeatures(e.point, {
          layers: ["projects-point"],
        })
      : undefined;
    const parcelFeatures = map.current.getLayer("parcels-fill")
      ? map.current.queryRenderedFeatures(e.point, {
          layers: ["parcels-fill"],
        })
      : undefined;
    if (!!features && features?.length > 0) {
      map.current.getCanvas().style.cursor = "pointer";
      const projectPointLayerFeature = features?.find(
        (feature: MapboxGeoJSONFeature) => {
          return feature?.layer?.id === "projects-point";
        }
      );
      if (
        hoveredStateId !== projectPointLayerFeature?.id &&
        projectPointLayerFeature &&
        map.current.getZoom() <= PROJECTS_MAX_ZOOM_TOOLTIP
      ) {
        mouseLeaveMap(map, dispatch, e);
        return projectPointHoverAction(projectPointLayerFeature, map);
      }
      // } else if (!!projectFeatures && projectFeatures?.length > 0) {
      //   map.current.getCanvas().style.cursor = "pointer";
      //   // const projectFeature = projectFeatures?.find(
      //   //   (feature: MapboxGeoJSONFeature) => {
      //   //     return feature?.layer?.id === CLUSTER_LAYER_NAME;
      //   //   }
      //   // );
      //   // if (
      //   //   hoveredStateId !== projectFeature?.id &&
      //   //   projectFeature &&
      //   //   map.current.getZoom() <= PROJECTS_MAX_ZOOM_TOOLTIP
      //   // ) {
      //   //   mouseLeaveMap(map, dispatch, e);
      //   //   return projectClusterHoverAction(projectFeature, map);
      //   // }
    } else if (!!parcelFeatures && parcelFeatures?.length > 0) {
      map.current.getCanvas().style.cursor = "pointer";
      if (map.current.getZoom() <= PARCELS_MIN_ZOOM || isNatMobile) {
        return;
      }
      const parcelLayerFeature = parcelFeatures?.find(
        (feature: MapboxGeoJSONFeature) => {
          return feature?.layer?.id === "parcels-fill";
        }
      );
      if (!parcelLayerFeature?.id) {
        return;
      }
      if (hoveredStateId) {
        map.current.setFeatureState(
          {source: "parcels", id: hoveredStateId},
          {hover: false}
        );
      }
      hoveredStateId = parcelLayerFeature.id;
      map.current.setFeatureState(
        {source: "parcels", id: hoveredStateId},
        {hover: true}
      );
      if (map.current?.getCanvas()?.style?.cursor) {
        map.current.getCanvas().style.cursor = "pointer";
      }
      if (
        !parcelLayerFeature.properties.SA_SITE_HOUSE_NBR ||
        !parcelLayerFeature.properties.SA_SITE_STREET_NAME ||
        !parcelLayerFeature.properties.SA_SITE_SUF ||
        isNatMobile
      ) {
        return;
      }
      const fullStreetAddress = `${parcelLayerFeature.properties.SA_SITE_HOUSE_NBR} ${parcelLayerFeature.properties.SA_SITE_STREET_NAME} ${parcelLayerFeature.properties.SA_SITE_SUF}`;
      const arr = fullStreetAddress.toLowerCase().split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");

      const centerCoords: LngLatLike = polylabel(
        parcelLayerFeature.geometry.coordinates,
        1.0
      ) as unknown as LngLatLike;
      if (
        parcelLayerFeature.properties.SA_SITE_HOUSE_NBR &&
        parcelLayerFeature.properties.SA_SITE_STREET_NAME &&
        parcelLayerFeature.properties.SA_SITE_HOUSE_NBR.trim() !== "" &&
        parcelLayerFeature.properties.SA_SITE_STREET_NAME.trim() !== "" &&
        str2.trim() !== ""
      ) {
        const city = `${parcelLayerFeature.properties.SA_SITE_CITY.trim()},`;
        const cityZip = `${city} ${parcelLayerFeature.properties.SA_SITE_ZIP}`;
        const arr2 = cityZip.toLowerCase().split(" ");
        for (let i = 0; i < arr2.length; i++) {
          arr2[i] = arr2[i].charAt(0).toUpperCase() + arr2[i].slice(1);
        }
        const str3 = arr2.join(" ");
        popup
          .setLngLat(centerCoords)
          .setHTML(`<h3 class="h3">${str2}</h3><h6 class="h6">${str3}</h6>`)
          .addTo(map.current);
        popupIsProject = false;
      } else {
      }
      return;
    } else {
      mouseLeaveMap(map, dispatch, e);
    }
  });

  // All Map Click Events
  map.current.on("click", (e: any) => {
    const projectPointFeatures = map.current.queryRenderedFeatures(e.point, {
      layers: ["projects-point"],
    });
    const projectFeatures = map.current.queryRenderedFeatures(e.point, {
      layers: [CLUSTER_LAYER_NAME],
    });
    const parcelFillFeatures = map.current.queryRenderedFeatures(e.point, {
      layers: ["parcels-fill"],
    });
    if (!!projectPointFeatures && projectPointFeatures?.length > 0) {
      const projectPointLayer = projectPointFeatures?.find(
        (feature: MapboxGeoJSONFeature) => {
          return feature?.layer?.id === "projects-point";
        }
      );
      if (!!projectPointLayer && map.current.getZoom() <= PROJECTS_MAX_ZOOM) {
        return projectPointClickAction(
          projectPointLayer,
          isAdmin,
          map,
          dispatch,
          true,
          setShowEnrichedMapProjectModal,
          isNatMobile
        );
      }
    } else if (!!projectFeatures && projectFeatures?.length > 0) {
      const projectsLayerFeature = projectFeatures?.find(
        (feature: MapboxGeoJSONFeature) => {
          return feature?.layer?.id === CLUSTER_LAYER_NAME;
        }
      );
      if (projectsLayerFeature && map.current.getZoom() <= PROJECTS_MAX_ZOOM) {
        return projectClusterClickAction(
          projectsLayerFeature,
          isAdmin,
          map,
          dispatch
        );
      }
    } else if (!!parcelFillFeatures && parcelFillFeatures?.length > 0) {
      const parcelLayerFeature = parcelFillFeatures?.find(
        (feature: MapboxGeoJSONFeature) => {
          return feature?.layer?.id === "parcels-fill";
        }
      );
      if (parcelLayerFeature && map.current.getZoom() > PARCELS_MIN_ZOOM) {
        visuallyLoadNewParcel(
          parcelLayerFeature,
          map,
          loadedAddressDetails,
          dispatch
        );
      }
    } else {
    }
  });
  // All Map Leave Events
  map.current.on(
    "mouseleave",
    [CLUSTER_LAYER_NAME, "projects-point", "parcels-fill"],
    (e: any) => {
      if (hoveredStateId) {
        map.current.setFeatureState(
          {source: "parcels", id: hoveredStateId},
          {hover: false}
        );
      }
      popup.remove();
    }
  );
  map.current.on("zoom", (e: mapboxgl.MapBoxZoomEvent) => {
    if (map.current.getZoom() > PROJECTS_MAX_ZOOM && popupIsProject) {
      popup.remove();
    }
  });
}

export const updateParcelData = (
  signal: AbortSignal,
  map: React.MutableRefObject<any>,
  loadedMap: boolean,
  allProjects: any,
  zoom: number,
  lastQueryTime: number,
  setLastQueryTime: React.Dispatch<React.SetStateAction<number>>,
  setParcelData: React.Dispatch<React.SetStateAction<any>>
) => {
  const time = new Date().getTime();
  if (
    map.current &&
    loadedMap &&
    zoom > PARCELS_MIN_ZOOM &&
    !signal.aborted &&
    time - lastQueryTime > 100
  ) {
    const center = map.current.getCenter();
    if (!signal.aborted) {
      setLastQueryTime(time);
      getAllParcels("1=1", center.toArray().flat(), 0, signal)
        .then((localParcels: any) => {
          if (localParcels) {
            setParcelData(localParcels);
          }
        })
        .catch(() => {});
    }
  }
};
