import React, {Dispatch, SetStateAction} from "react";
import {Row} from "react-table";
import {InfoSetItemTableRow} from "../../table";
import {ARCHIVED_KEY} from "../../../shared/constants";
import {
  NatCopyIcon,
  NatDocAddIcon,
  NatDocCopyIcon,
  NatDocRemoveIcon,
  NatMinusIcon,
  NatPlusIcon,
  NatUndoIcon,
} from "../../../../_shared/icon/icons";
import {
  addItemSet,
  addOptionRow,
  changeArchiveStatusOfItemSet,
  changeArchiveStatusOfOption,
  copyItemSet,
  copyOptionRow,
  RowContextMenuItem,
} from "../../actions";

export const getRowContextMenuActions = (
  setData: Dispatch<SetStateAction<InfoSetItemTableRow[]>>,
  resetMenu: () => void,
  targetRow?: Row<InfoSetItemTableRow>,
  originalRow?: InfoSetItemTableRow
): RowContextMenuItem[] => {
  const actions: RowContextMenuItem[] = [];
  if (!!targetRow && !!targetRow?.original) {
    const archiveStatus = targetRow?.original[ARCHIVED_KEY];
    actions.push(
      {
        group: 2,
        label: "New Option",
        action: () => {
          addOptionRow(setData, targetRow);
          resetMenu();
        },
        icon: <NatPlusIcon />,
      },
      {
        group: 2,
        label: "Copy Option",
        action: () => {
          copyOptionRow(setData, targetRow);
          resetMenu();
        },
        icon: <NatCopyIcon />,
      },
      {
        group: 1,
        label: "New Key",
        action: () => {
          addItemSet(setData);
          resetMenu();
        },
        icon: <NatDocAddIcon />,
      },
      {
        group: 1,
        label: "Copy Key",
        action: () => {
          copyItemSet(setData, targetRow);
          resetMenu();
        },
        icon: <NatDocCopyIcon />,
      },
      {
        group: 2,
        label: `${archiveStatus ? "Undo " : ""}Remove Option`,
        action: () => {
          changeArchiveStatusOfOption(setData, targetRow, !archiveStatus);
          resetMenu();
        },
        icon: archiveStatus ? <NatUndoIcon /> : <NatMinusIcon />,
      },
      {
        group: 1,
        label: `${archiveStatus ? "Undo " : ""}Remove Key`,
        action: () => {
          changeArchiveStatusOfItemSet(setData, targetRow, !archiveStatus);
          resetMenu();
        },
        icon: archiveStatus ? <NatUndoIcon /> : <NatDocRemoveIcon />,
      }
    );
  }
  return actions;
};
