import React from "react";
import {
  NavigationButton,
  NavigationContainer,
  NavigationOption,
  NavigationOptionsContainer,
  NavigationTitle,
} from "./styles";
import {SectionNavigationButton} from "./SectionNavigationButton";
import {
  NatLeftCaratIcon,
  NatRightCaratIcon,
} from "../../../../_shared/icon/icons";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";

export interface IStudioFooterStep {
  id: string;
  title: string;
}

export interface StudioFooterNavigationProps {
  hidden: boolean;
  steps: IStudioFooterStep[];
  currentStepIndex: number;
  navigateStep: (i: number) => void;
  productTitle: string;
  productId: string;
  factoryId: string;
}

export const SectionNavigation = (props: StudioFooterNavigationProps) => {
  const {to} = useNavigation();
  const {
    hidden,
    navigateStep,
    steps,
    currentStepIndex,
    productTitle,
    productId,
    factoryId,
  } = props;
  const allSteps: IStudioFooterStep[] = [
    ...steps,
    {id: "summary-section", title: "Summary"},
  ];

  return (
    <NavigationContainer>
      {currentStepIndex !== 0 && (
        <NavigationOption
          direction={"left"}
          onClick={() => navigateStep(currentStepIndex - 1)}
        >
          <NatLeftCaratIcon size={14} />
        </NavigationOption>
      )}
      <NavigationOptionsContainer
        hidden={hidden}
        key={"section-navigation-container"}
      >
        {currentStepIndex !== 0 ? (
          <SectionNavigationButton
            step={allSteps[currentStepIndex - 1]}
            isSelected={false}
            index={currentStepIndex - 1}
            navigate={navigateStep}
          />
        ) : (
          <NavigationTitle
            onClick={() =>
              to(NavigationPaths.CATALOG, false, factoryId, productId)
            }
          >
            {productTitle}
          </NavigationTitle>
        )}
        <SectionNavigationButton
          step={allSteps[currentStepIndex]}
          isSelected={true}
          index={currentStepIndex}
          navigate={navigateStep}
        />
        {currentStepIndex !== allSteps.length - 1 ? (
          <SectionNavigationButton
            step={allSteps[currentStepIndex + 1]}
            isSelected={false}
            index={currentStepIndex + 1}
            navigate={navigateStep}
          />
        ) : (
          <NavigationButton selected={false} />
        )}
      </NavigationOptionsContainer>
      {currentStepIndex !== allSteps.length - 1 && (
        <NavigationOption
          direction={"right"}
          onClick={() => navigateStep(currentStepIndex + 1)}
        >
          <NatRightCaratIcon size={14} />
        </NavigationOption>
      )}
    </NavigationContainer>
  );
};
