import {IFactoryInventoryStudioEditorPopupOptions} from "../Editor/PopupContent/interface";
import {StudioEditorFieldType} from "../Editor/_shared/StudioEditorFieldAddButton";
import {IFactoryInventoryStudioEditorCategoryImageGroup} from "../Editor/CategorySection/CategoryImagesEditor/interface";
import {IFactoryInventoryStudioEditorCategory} from "../Editor/CategorySection/interface";
import {IFactoryInventoryStudioEditorModifierGroup} from "../Editor/ModifierGroupSection/interface";
import {IFactoryInventoryStudioEditorModifier} from "../Editor/ModifierSection/interface";

export const deleteTarget = (
  formik: any,
  options: IFactoryInventoryStudioEditorPopupOptions
) => {
  const {path, type} = options;
  const {categoryId, modifierId, modifierGroupId, imageGroupId} = path ?? {};

  if (type === StudioEditorFieldType.CATEGORY && categoryId) {
    deleteCategory(formik, categoryId);
  } else if (
    type === StudioEditorFieldType.MODIFIER_GROUP &&
    categoryId &&
    modifierGroupId
  ) {
    deleteModifierGroup(formik, categoryId, modifierGroupId);
  } else if (
    type === StudioEditorFieldType.MODIFIER &&
    categoryId &&
    modifierGroupId &&
    modifierId
  ) {
    deleteModifier(formik, categoryId, modifierGroupId, modifierId);
  } else if (
    type === StudioEditorFieldType.RENDERING &&
    categoryId &&
    imageGroupId
  ) {
    deleteImageGroup(formik, categoryId, imageGroupId);
  }
};

const deleteModifier = (
  formik: any,
  categoryId: string,
  modifierGroupId: string,
  modifierId: string
) => {
  const values: {
    [id: string]: IFactoryInventoryStudioEditorModifier;
  } = {
    ...formik?.values?.[categoryId]?.modifierGroups?.[modifierGroupId]
      ?.modifiers,
  };

  const index = values?.[modifierId].index;

  Object.values(values ?? {})?.forEach(
    (mod: IFactoryInventoryStudioEditorModifier) => {
      if (mod?.index > index) {
        values[mod.id] = {
          ...mod,
          index: mod.index - 1,
        };
      }
    }
  );

  delete values?.[modifierId];

  formik.setFieldValue(
    `${categoryId}.modifierGroups.${modifierGroupId}.modifiers`,
    values
  );
};

const deleteModifierGroup = (
  formik: any,
  categoryId: string,
  modifierGroupId: string
) => {
  const values: {
    [id: string]: IFactoryInventoryStudioEditorModifierGroup;
  } = {...formik?.values?.[categoryId]?.modifierGroups};

  const index = values?.[modifierGroupId].index;

  Object.values(values ?? {})?.forEach(
    (mg: IFactoryInventoryStudioEditorModifierGroup) => {
      if (mg?.index > index) {
        values[mg.id] = {
          ...mg,
          index: mg.index - 1,
        };
      }
    }
  );

  delete values?.[modifierGroupId];

  formik.setFieldValue(`${categoryId}.modifierGroups`, values);
};

const deleteCategory = (formik: any, categoryId: string) => {
  const values: {
    [id: string]: IFactoryInventoryStudioEditorCategory;
  } = {...formik?.values};

  const index = values?.[categoryId]?.index;

  Object.values(values ?? {})?.forEach(
    (cat: IFactoryInventoryStudioEditorCategory) => {
      if (cat?.index > index) {
        values[cat.id] = {
          ...cat,
          index: cat.index - 1,
        };
      }
    }
  );
  delete values?.[categoryId];
  formik.setValues(values);
};

const deleteImageGroup = (
  formik: any,
  categoryId: string,
  imageGroupId: string
) => {
  const values: {
    [id: string]: IFactoryInventoryStudioEditorCategoryImageGroup;
  } = {...formik?.values?.[categoryId]?.images};

  const index = values?.[imageGroupId]?.index;

  // Truncate image indexes
  Object.values(values ?? {})?.forEach(
    (imageGroup: IFactoryInventoryStudioEditorCategoryImageGroup) => {
      if (imageGroup?.index > index) {
        values[imageGroup.id] = {
          ...imageGroup,
          index: imageGroup.index - 1,
        };
      }
    }
  );

  delete values?.[imageGroupId];

  formik.setFieldValue(`${categoryId}.images`, values);
};
