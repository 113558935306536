import {ReadOnlyFactoryInfoSet} from "./ReadOnlyFactoryInfoSet";
import React from "react";
import {useCatalogSession} from "../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useAdminFactoryLine} from "../../_shared/hooks/admin-only-hooks/useAdminFactoryLine";
import {useNavigation} from "../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../_shared/hooks/useNavigation/paths";
import _ from "lodash";
import {EmptyPage} from "../../_shared/generics/empty-page";

export const ReadOnlyFactoryInfo = () => {
  const {selectedProductGroupId} = useCatalogSession();
  const {to} = useNavigation();
  const factory = useAdminFactoryLine(selectedProductGroupId ?? undefined);
  if (!selectedProductGroupId) {
    to(NavigationPaths.ADMIN);
  }

  if (_.isEmpty(factory?.infoSet)) {
    return (
      <EmptyPage
        title={`This InfoSet is currently unavailable.\nOur Product Team is working to populate this information.`}
      />
    );
  }

  return <ReadOnlyFactoryInfoSet factory={factory} />;
};
