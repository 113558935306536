import React from "react";
import styled from "styled-components";
import {NatLink} from "../../../../_shared/generics/button";

const HELPFUL_LINKS = [
  {
    label: "What is our process here at Villa?",
    url: "https://villahomes.com/howitworks/",
  },
  {
    label: "What are my financing options?",
    url: "https://villahomes.com/financing/",
  },
  {
    label: "Can I visit a Villa model home?",
    url: "https://calendly.com/villa-model-homes",
  },
  {
    label: "What are California ADU regulations?",
    url: "https://villahomes.com/permits-regulations/",
  },
  {
    label: "I’m a multifamily investor. What can Villa do for me?",
    url: "https://villahomes.com/multifamily-investors",
  },
  {
    label: "What are the standard features of a Villa ADU?",
    url: "https://villahomes.com/standard-features/",
  },
  {
    label: "What are some upcoming Villa events I can attend?",
    url: "https://villahomes.com/events/",
  },
  {
    label: "Can you link me to Villa’s FAQ page?",
    url: "https://villahomes.com/faq/",
  },
  {
    label: "What construction materials do we use?",
    url: "https://villahomes.com/standard-features-details/",
  },
  {label: "What is ReadyBuilt?", url: "https://villahomes.com/readybuilt/"},
];

export const HelpfulLinks = (props: IHelpfulLinksProps) => {
  return (
    <Container>
      {HELPFUL_LINKS.map((linkDetails) => {
        return (
          <NatLink
            key={linkDetails.url}
            label={linkDetails.label}
            link={linkDetails.url}
            openInNewTab={true}
            style={{
              margin: 0,
              flexGrow: 1,
              width: "40%",
              minWidth: "300px",
              textAlign: "left",
              paddingLeft: 0,
            }}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
`;

interface IHelpfulLinksProps {}
