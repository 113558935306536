import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {PageContainer} from "../../../../../../_shared/generics/page/components/PageContainer";
import {MatrixTableWrapper} from "../../styles";
import {LoadingPanel} from "../../../../../../_shared/generics/loading-panel";
import {useAdminProductCatalog} from "../../../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {ProductModifierTable} from "./tables/ProductModifierTable";
import {ProductInfoTable} from "./tables/ProductInfoTable";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixModes,
} from "../../../../../../_shared/slices/types/Store";

export interface MatrixTableProps {
  matrixEdits: any;
  setMatrixEdits: Dispatch<SetStateAction<any>>;
  loading: boolean;
  searchText: string | undefined;
  heightOffset?: string;
  setTranspose: Dispatch<SetStateAction<boolean>>;
  transpose: boolean;
}

export interface MatrixTablePageProps {
  itemsPerPage: number;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<any>>;
  transpose: boolean;
  setTranspose: Dispatch<SetStateAction<boolean>>;
  itemTotal?: number;
}

export const MatrixTable = (props: MatrixTableProps) => {
  const {
    matrixEdits,
    setMatrixEdits,
    loading,
    searchText,
    heightOffset,
    transpose,
    setTranspose,
  } = props;

  const {matrixFilters, matrixMode} = useAdminProductCatalog();
  const {filterBy} = matrixFilters;

  const [pageNumber, setPageNumber] = useState<number>(0);

  const itemsPerPage: number =
    filterBy === AdminProductCatalogMatrixFilterByOptions.PRODUCT ? 50 : 10;

  useEffect(() => {
    setPageNumber(0);
  }, [matrixFilters, matrixMode, searchText]);

  if (loading) {
    return (
      <PageContainer overridePadding={true}>
        <MatrixTableWrapper heightOffset={heightOffset}>
          <LoadingPanel show={true} relativePositioning={true} />
        </MatrixTableWrapper>
      </PageContainer>
    );
  }

  const standardTableProps: MatrixTablePageProps = {
    itemsPerPage: itemsPerPage,
    pageNumber: pageNumber,
    setPageNumber: setPageNumber,
    transpose: transpose,
    setTranspose: setTranspose,
  };

  const tableProps = {
    heightOffset,
    loading,
    matrixEdits,
    searchText,
    setMatrixEdits,
    ...standardTableProps,
  };

  if (
    matrixMode === AdminProductCatalogMatrixModes.PRICE_SET ||
    matrixMode === AdminProductCatalogMatrixModes.DESCRIPTIONS
  ) {
    return <ProductModifierTable {...tableProps} />;
  }
  if (matrixMode === AdminProductCatalogMatrixModes?.PRODUCT_INFO) {
    return <ProductInfoTable {...tableProps} />;
  }

  return null;
};
