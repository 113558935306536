import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {
  ADMIN_EDIT,
  VILLA_BLUE,
} from "../../../../../../../../../_shared/colors";
const getBackgroundColor = (isEdited: boolean): string => {
  if (isEdited) {
    return ADMIN_EDIT;
  } else {
    return "";
  }
};
export const InputContainer = styled.div<{
  isEdited: boolean;
}>`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: fit-content;
  padding: 1rem;
  border-radius: 1rem;
  background-color: ${(props) => getBackgroundColor(props.isEdited)};
  transition: background-color ease-in-out 200ms;
`;
export const InputLabel = styled.label`
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${VILLA_BLUE};
  font-size: 1rem;
`;

export const InputDescription = styled.p`
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${VILLA_BLUE};
  font-size: 12px;
  margin: 0;
  padding: 0 0 8px 0;
`;

export const InputLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
`;
