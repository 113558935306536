import React from "react";
import Form from "../../2-component/Form/form";
import {FormProps} from "../../2-component/Form/interface";

const DirectCustomerForm = (props: FormProps) => {
  return (
    <Form
      key={"confirm-customer-form"}
      inputs={props.inputs}
      onSubmit={props.onSubmit}
      buttons={[
        ...(props.buttons ?? []),
        {
          id: "direct-customer-form-confirm",
          label: "Confirm",
          type: "submit",
        },
      ]}
    />
  );
};

export default DirectCustomerForm;
