import {useProjectSlice} from "./useProjectSlice";
import {ICustomerInfo} from "@natomas-org/core";
import {IProject} from "../../slices/ProjectSlice/ProjectSlice";
import {useMemo} from "react";
import {useCustomer} from "../useCustomer/useCustomer";

export interface ICustomerProjects {
  projectIds?: string[];
  projects?: {[pid: string]: IProject};
}

const useCustomerProjects = (customerId?: string): ICustomerProjects => {
  const {info} = useCustomer(customerId);
  const {project_ids} = (info ?? {}) as ICustomerInfo;
  const projects = useProjectSlice();

  const customerProjects: {[pid: string]: IProject} = useMemo(() => {
    let p: {[pid: string]: IProject} = {};
    project_ids?.forEach((pid: string) => {
      if (pid && !!projects?.[pid]) {
        p[pid] = projects?.[pid];
      }
    });
    return p;
  }, [projects, project_ids]);

  return {
    projectIds: Object.keys(customerProjects),
    projects: customerProjects,
  };
};

export default useCustomerProjects;
