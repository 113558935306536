import {
  ERROR_COLOR,
  NEUTRAL_COLOR,
  VILLA_APPLE_GREEN,
} from "../../../../../_shared/colors";
import React from "react";
import {
  NatCheckCircleHollow,
  NatInfoIcon,
  NatWarningCircle,
} from "../../../../../_shared/icon/icons";
import {UserFeedbackType} from "@natomas-org/core";

export const getIconFromFeedbackType = (props: {
  userFeedbackType?: UserFeedbackType;
  size?: string;
}): JSX.Element | null => {
  const {userFeedbackType, size} = props;
  let icon: null | JSX.Element = null;
  switch (userFeedbackType) {
    case UserFeedbackType.SUCCESS:
      icon = <NatCheckCircleHollow color={VILLA_APPLE_GREEN} size={size} />;
      break;
    case UserFeedbackType.NEUTRAL:
      icon = <NatInfoIcon color={NEUTRAL_COLOR} size={size} />;
      break;
    case UserFeedbackType.WARNING:
      // icon = <NatWarningTriangle color={ERROR_COLOR} size={size} />;
      icon = <NatWarningCircle color={ERROR_COLOR} size={size} />;
      break;
    default:
      break;
  }
  return icon;
};
