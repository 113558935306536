import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {ICompletedProjectsDictionary} from "@natomas-org/core";
import {useMemo} from "react";

export const useCompletedProjects = () => {
  const {allProjects, propertyData} = useSelector(
    (state: IStore) => state.completedProjects
  );
  const displayCompletedProjectsPrice = useSelector(
    (state: IStore) => state.session.displayCompletedProjectsPrice
  );
  const completedProjects: ICompletedProjectsDictionary = useMemo(() => {
    const localDict: ICompletedProjectsDictionary = {};
    if (allProjects) {
      Object.values(allProjects)?.forEach((project) => {
        if (project?.code && project?.published) {
          localDict[project?.code] = project;
        }
      });
    }
    if (propertyData) {
      Object.values(propertyData)?.forEach((project) => {
        if (project?.code) {
          localDict[project?.code] = project;
        }
      });
    }
    console.log(localDict);
    return localDict;
  }, [allProjects, propertyData]);
  return {
    allProjects: allProjects,
    completedProjects: completedProjects,
    displayCompletedProjectsPrice: displayCompletedProjectsPrice,
  };
};

export const NO_PRICE_PLACEHOLDER = ""; //"$---,---"
export const useDisplayCompletedProjectsPrice = () => {
  return useSelector(
    (state: IStore) => state.session.displayCompletedProjectsPrice
  );
};
