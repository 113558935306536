import {Product, ProjectPriceMode} from "@natomas-org/core";

export const getBedBathString = (product: any, styling?: string) => {
  if (product != null) {
    const bedrooms = product?.productDetails?.bedrooms ?? 0;
    const bathrooms = product?.productDetails?.bathrooms ?? 0;
    const bedString = bedrooms > 1 ? "beds" : "bed";
    const bathString = bathrooms > 1 ? "baths" : "bath";
    let separator = ", ";
    if (styling === "line") {
      separator = "  |  ";
    }
    return `${bedrooms} ${bedString}${separator}${bathrooms} ${bathString}`;
  }
  return "";
};

export const getSqFtString = (product: any) => {
  if (product != null) {
    const squareFeet = product?.productDetails?.squareFeet ?? "Unknown";
    return squareFeet + " sq. ft.";
  }
  return "";
};

export const getLengthAndWidthString = (product: any) => {
  if (
    product != null &&
    product.productDetails != null &&
    product.productDetails.dimensions != null
  ) {
    const dimensions = product.productDetails.dimensions;
    return (
      getLengthFromDimension(dimensions.frontLength) +
      " x " +
      getLengthFromDimension(dimensions.sideLength)
    );
  }
  return "";
};

export const getLengthFromDimension = (dimension: any) => {
  if (dimension == null) {
    return "";
  }

  let feetString = "" + dimension.feet + "'";
  let inchesString = "";
  if (dimension.inches > 0) {
    inchesString = " " + dimension.inches + '"';
  }
  return feetString + inchesString;
};

export const getBasePriceFromProduct = (
  product: any,
  priceMode: ProjectPriceMode
) => {
  if (product == null) {
    return 0;
  }
  if (priceMode === ProjectPriceMode.CostPlus) {
    return Product.getUnitCost(product, {excludeInventoryUpgrades: true});
  }
  return Product.getUnitPrice(product, {excludeInventoryUpgrades: true});
};
