import styled from "styled-components";

export const AppSplashImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const AppSplashImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
export const AppTileContainer = styled.div<{
  isMobile: boolean;
  backgroundColor: string;
  textColor: string;
}>`
  width: ${(props) => (props.isMobile ? "100%" : "50%")};
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  color: ${(props) => props.textColor};
  justify-content: space-between;
  padding: 2.5rem 1rem;
`;
