import styled from "styled-components";

export const CompletedProjectsImageManagerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  column-gap: 1rem;
`;
export const CompletedProjectsImageManagerContainer = styled.div`
  width: 100%;
  height: 100%;
`;
