import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const ProjectNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

export const ProjectNotesTabContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const ProjectNotesTabTitle = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  font-size: 2rem;
  font-family: ${FONT_FAMILY_BOLD};
  justify-content: flex-start;
  align-items: center;
`;
