import React, {useEffect, useMemo, useState} from "react";
// Core
// Interfaces + Types
import {StudioState} from "../_shared/slices/types/Store";
import {
  IStudioOptions,
  StudioContentProps,
} from "./_shared/components/StudioContent";
// Subcomponents
import {BlankStudio} from "./BlankStudio";
import {Designer} from "./Designer";
import {Showcase} from "./Showcase";
import {LoadingPanel} from "../_shared/generics/loading-panel";
import {StudioEditingConfirmation} from "./_shared/components/StudioEditingConfirmation";
import {StudioSavingModal} from "./_shared/components/StudioSavingModal";
// Styles + Generics
import {PROGRESS_BAR_HEIGHT} from "../_shared/generics/progress-bar/ProgressBar";
import {StudioRootContainer, StudioWrapper} from "./styles";
// Hooks
import {useDynamicValue} from "../_shared/hooks/useDynamicValue";
import {useFormik} from "formik";
import {useScrollPageToTopOnMount} from "../_shared/hooks/usePage";
import {useStudioEngine} from "../_shared/hooks/useStudio/StudioEngine/useStudioEngine";
import {useStudioSettings} from "../_shared/hooks/useStudio/useStudioSettings";
import {useStudioSmartScroll} from "../_shared/hooks/useStudio/useStudioSmartScroll";
import {useProgressBar} from "../_shared/hooks/useProgressBar";
import {useDispatch} from "react-redux";
import {setStudioStep} from "../_shared/slices/StudioSlice";
import {ProjectPriceMode} from "@natomas-org/core";
import useActiveProject from "../_shared/hooks/useProject/useActiveProject";
import {useCurrentUser} from "../_shared/hooks/useCurrentUser";
import {useRegistration} from "../_shared/hooks/useRegistration";
import {useAdminSettings} from "../_shared/hooks/admin-only-hooks/useAdminSettings";
import StudioDisclaimerModal from "./_shared/components/StudioDisclaimerModal";
import useLeadExists from "../../api/Lead/useLeadExists";

const Studio = (props: {
  modeOverride?: StudioState;
  productOverride?: any;
  options?: IStudioOptions;
}) => {
  useScrollPageToTopOnMount();
  const {modeOverride, productOverride, options} = props;
  const {step, section} = useStudioSettings();
  const {loggedInUser, uid} = useCurrentUser();
  const {data: lead} = useLeadExists(uid);
  const {visible: isRegistrationModalVisible} = useRegistration();
  const {projectPriceMode, isProjectLocked} = useActiveProject();
  const {canDemo, isAdmin, demoPricingMode} = useAdminSettings();
  const {structure, summary, mode} = useStudioEngine(
    modeOverride,
    productOverride
  );
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const dispatch = useDispatch();

  const priceMode: ProjectPriceMode = useMemo(() => {
    // Only support demo pricing on Design mode and canDemo
    if (canDemo && mode === StudioState.DESIGNER) {
      return demoPricingMode;
    }
    return projectPriceMode;
  }, [mode, canDemo, demoPricingMode, projectPriceMode]);

  useEffect(() => {
    return () => {
      dispatch(setStudioStep(0));
    };
  }, [dispatch]);

  let stackedMode = useDynamicValue({
    forFour: true,
    forEight: true,
    forTwelve: false,
    forSixteen: false,
  });
  if (options?.stackedMode !== undefined) {
    stackedMode = options?.stackedMode;
  }

  const {showProgressBar} = useProgressBar();

  const height = useMemo(() => {
    if (options?.headless) {
      return "100vh";
    }
    const calculateHeight = () => {
      let navBarHeight = stackedMode ? "0px" : "4rem";
      let progressBarHeight =
        showProgressBar && !stackedMode ? PROGRESS_BAR_HEIGHT + "px" : "0px";
      if (mode === StudioState.BUILDER) {
        return `calc(100vh - ${navBarHeight})`;
      } else
        return `calc(100vh - ${navBarHeight} - ${progressBarHeight} - 2px)`;
    };
    return calculateHeight();
  }, [stackedMode, showProgressBar, mode, options?.headless]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const {smartScroll, atBottom} = useStudioSmartScroll({
    isMobile: stackedMode,
    hasProgressBar: !options?.headless && showProgressBar && !stackedMode,
    hasHeader: !options?.headless && !stackedMode,
    length: summary?.structure?.length ?? 0,
  });

  const contentProps: StudioContentProps = useMemo(() => {
    let price = summary?.totalUnitPrice?.price
      ? summary?.totalUnitPrice?.price / 100
      : 0;
    if (priceMode === ProjectPriceMode.CostPlus) {
      price = summary?.totalUnitPrice?.cost
        ? summary?.totalUnitPrice?.cost / 100
        : 0;
    }
    console.log("ContentProps", structure);
    return {
      formik: formik,
      mode: mode,
      height: height,
      product: summary?.product,
      price: price,
      section: section,
      stackedMode: stackedMode,
      step: step,
      structure: structure,
      summary: summary,
      options: {...options, showDisclaimer: () => setShowDisclaimer(true)},
      smartScroll: smartScroll,
      atBottom: atBottom,
      priceMode: priceMode,
    };
  }, [
    smartScroll,
    atBottom,
    formik,
    height,
    section,
    stackedMode,
    mode,
    step,
    structure,
    summary,
    options,
    priceMode,
  ]);

  const content = useMemo(() => {
    if (mode === StudioState.SHOWCASE) {
      return (
        <StudioWrapper overflowHidden={stackedMode}>
          <Showcase {...contentProps} />
        </StudioWrapper>
      );
      // } else if (mode === StudioState.BUILDER) {
      //   return (
      //     <StudioWrapper overflowHidden={stackedMode}>
      //       <Builder {...contentProps} />
      //     </StudioWrapper>
      //   );
    } else if (mode === StudioState.DESIGNER) {
      return (
        <StudioWrapper overflowHidden={stackedMode}>
          <Designer {...contentProps} />
        </StudioWrapper>
      );
    } else if (mode === StudioState.BLANK) {
      return (
        <StudioWrapper overflowHidden={stackedMode}>
          <BlankStudio />
        </StudioWrapper>
      );
    } else {
      return <></>;
    }
  }, [stackedMode, mode, contentProps]);

  return (
    <StudioRootContainer>
      <LoadingPanel show={mode === StudioState.UNKNOWN} />
      {content}
      <StudioEditingConfirmation
        mode={mode}
        product={summary?.product}
        isProjectLocked={isProjectLocked}
      />
      <StudioSavingModal />
      <StudioDisclaimerModal
        show={showDisclaimer}
        close={() => setShowDisclaimer(false)}
      />
    </StudioRootContainer>
  );
};

export default Studio;
