import React from "react";
import {
  MenuItemContentWrapper,
  MenuItemTitle,
  SideBarContainer,
  SideBarHeader,
  SideBarMenuItemContainer,
} from "../../styles/AdminStyles";
import {toPage} from "../../../../portal/_shared/navigation/PageNavigation";
import {AdminPage} from "../../constants/AdminPage";
import {useSelector} from "react-redux";
import {IStore} from "../../../../_shared/slices/types/Store";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";

const SideBarMenuItem = (props: any) => {
  const {title, onClick, enabled, selected} = props;
  return (
    <SideBarMenuItemContainer
      onClick={onClick}
      selected={selected}
      className={"unselectable"}
      enabled={enabled}
      id={"admin-sidebar-" + title?.toLowerCase()}
    >
      <MenuItemContentWrapper>
        <MenuItemTitle>{title}</MenuItemTitle>
      </MenuItemContentWrapper>
    </SideBarMenuItemContainer>
  );
};

export interface AdminSideBarProps {
  widthRems: number;
  tabs: AdminPage[];
  headerContent?: JSX.Element;
  footerContent?: JSX.Element;
  preTabContent?: JSX.Element;
}

export const AdminSideBar = (props: AdminSideBarProps) => {
  const {tabs, headerContent, preTabContent, footerContent, widthRems} = props;
  const selectedTab = useSelector(
    (state: IStore) => state.administration?.page
  );
  const {resetAdminProCatFilters, isFetching} = useAdminProductCatalog();

  return (
    <SideBarContainer widthRems={widthRems}>
      <SideBarHeader>{headerContent}</SideBarHeader>
      {preTabContent}
      {tabs.map((item: AdminPage) => {
        const enabled = isFetching[item.id] !== true;
        return (
          <SideBarMenuItem
            key={item.id}
            {...item}
            selected={selectedTab === item.id}
            enabled={enabled}
            title={item.title}
            onClick={() => {
              if (item?.id === "matrix") {
                resetAdminProCatFilters();
              }
              toPage(item.id);
            }}
          />
        );
      })}
      {footerContent}
    </SideBarContainer>
  );
};
