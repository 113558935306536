import React, {Fragment, useMemo} from "react";
import {ProposalSummarySection} from "./ProposalSummarySection";
import {priceTextFromMicros} from "../../../../../../../studio/_shared/helper";
import {SEE_BREAKDOWN_SLIDE_TEXT} from "./constants";
import {SpanBold} from "./styles";
import {
  fullPriceTextFromMicros,
  UNIT_PERMIT_FEE_PHASE_2_MICROS,
} from "@natomas-org/core";
import {useProjectBudget} from "../../../../../../../_shared/hooks/useProjectBudget/useProjectBudget";

export const ProposalSummarySectionPhase2 = () => {
  const {projectBudget, totalUnitQuantity} = useProjectBudget();
  const permitFeeBudgetEstimate = useMemo(() => {
    const totalPermitPrice = projectBudget
      ?.getPreconPermitsCostPlus()
      ?.getTotalPrice();
    if (
      !totalPermitPrice ||
      totalPermitPrice?.price === undefined ||
      totalPermitPrice?.price === null
    ) {
      return "-";
    }
    return priceTextFromMicros(totalPermitPrice?.price ?? 0, "min");
  }, [projectBudget]);

  return (
    <ProposalSummarySection
      title={"2nd Phase - Permit Approval"}
      highlighted={false}
      lineItems={[
        {
          label: (
            <Fragment>
              <span>1. Villa Phase 2 Payment - </span>
              <SpanBold>paid at ATB/Permit Approval</SpanBold>
              <span>
                {` -
                ${fullPriceTextFromMicros(
                  projectBudget?.getPhase2Micros() ??
                    UNIT_PERMIT_FEE_PHASE_2_MICROS,
                  {
                    roundToInteger: true,
                  }
                )}
                /unit *`}
              </span>
            </Fragment>
          ),
          value: priceTextFromMicros(
            (projectBudget?.getPhase2Micros() ??
              UNIT_PERMIT_FEE_PHASE_2_MICROS) * totalUnitQuantity,
            "min"
          ),
        },
        {
          label: (
            <Fragment>
              <span>2. Permit Fee Budget Estimate - </span>
              <SpanBold>paid at ATB/Permit Approval</SpanBold>
              <span> - {SEE_BREAKDOWN_SLIDE_TEXT}</span>
            </Fragment>
          ),
          value: permitFeeBudgetEstimate,
        },
      ]}
    />
  );
};
