import React from "react";
import styled from "styled-components";
import {
  NatHorizontalAlign,
  NatJustifyContent,
  NatVerticalAlign,
} from "../_shared";
import {valuesIn} from "lodash";

export const NatFlex = styled.div<INatFlexProps>`
  display: flex;
  gap: ${(props) => (props.NOGAP ? "0" : "1rem")};
  flex-direction: ${(props) =>
    props.COLUMN ? "column" : props.ROW ? "row" : undefined};
  width: ${(props) => (props.FULL_WIDTH ? "100%" : undefined)};
  height: ${(props) => (props.FULL_HEIGHT ? "100%" : undefined)};
  align-items: ${(props) =>
    props?.COLUMN === true
      ? props?.HORIZONTAL_ALIGN === NatHorizontalAlign.LEFT
        ? "flex-start"
        : props?.HORIZONTAL_ALIGN === NatHorizontalAlign.RIGHT
        ? "flex-end"
        : props?.HORIZONTAL_ALIGN === NatHorizontalAlign.CENTER
        ? "center"
        : undefined
      : props?.VERTICAL_ALIGN === NatVerticalAlign.TOP
      ? "flex-start"
      : props?.VERTICAL_ALIGN === NatVerticalAlign.BOTTOM
      ? "flex-end"
      : props?.VERTICAL_ALIGN === NatVerticalAlign.CENTER
      ? "center"
      : undefined};
  justify-content: ${(props) =>
    props?.JUSTIFY_CONTENT &&
    valuesIn(NatJustifyContent).includes(props.JUSTIFY_CONTENT)
      ? props?.JUSTIFY_CONTENT
      : props?.HORIZONTAL_ALIGN === NatHorizontalAlign.RIGHT
      ? "flex-end"
      : props?.HORIZONTAL_ALIGN === NatHorizontalAlign.LEFT
      ? "flex-start"
      : undefined};
  flex-wrap: ${(props) => (props.NOWRAP ? "no-wrap" : "wrap")};
  ${(props) => (props?.style ? {...props.style} : "")};
`;

interface INatFlexProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  FULL_WIDTH?: boolean;
  FULL_HEIGHT?: boolean;
  ROW?: boolean;
  COLUMN?: boolean;
  NOWRAP?: boolean;
  NOGAP?: boolean;
  JUSTIFY_CONTENT?: NatJustifyContent;
  VERTICAL_ALIGN?:
    | NatVerticalAlign.TOP
    | NatVerticalAlign.BOTTOM
    | NatVerticalAlign.CENTER;
  HORIZONTAL_ALIGN?:
    | NatHorizontalAlign.CENTER
    | NatHorizontalAlign.LEFT
    | NatHorizontalAlign.RIGHT;
}
