import React from "react";
import {InstantQuoteCTA} from "./InstantQuoteCTA";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";

export const InstantQuoteB2BPlaceholder = (props: {id: string}) => {
  const width = useDynamicValue(fullColumnWidths);
  return (
    <PageContainer>
      <PageElement size={width} height={"auto"}>
        <NatLabel label={"Instant quote unavailable"} type={NatLabelType.H2} />
        <InstantQuoteCTA {...props} title={"Continue to Proposal"} />
      </PageElement>
    </PageContainer>
  );
};
