import React from "react";
import styled from "styled-components";
import {DesignButton} from "./styles";

export const RadioButton = (props: any) => {
  const {buttonId, onClick, children} = props;
  return (
    <SmallButton
      id={buttonId}
      onClick={onClick}
      disabled={props.isDisabled}
      active={props.isSelected}
    >
      <SmallButtonContent>{children}</SmallButtonContent>
    </SmallButton>
  );
};

const SmallButton = styled(DesignButton)`
  width: 47%;
  align-items: center;
  box-sizing: border-box;
  margin-top: 18px;

  &:first-child {
    margin-right: 6%;
  }
  display: flex;
`;

const SmallButtonContent = styled.div`
  width: 100%;
  text-align: center;
  margin: auto;
`;
