import styled from "styled-components";
import {
  CONTAINER_BACKGROUND_COLOR,
  DEFAULT_SHADOW,
} from "../../../_shared/colors";
import {FONT_FAMILY_REGULAR} from "../../../portal/views/SSDDashboardView/styles/globals";

export const AddEmployeeCardContainer = styled.div<{expanded: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background-color: ${CONTAINER_BACKGROUND_COLOR};
  border-radius: 0.5rem;
  padding: 1rem;
  height: 151px;
  min-width: 151px;
  max-width: 20rem;
  overflow: hidden;
  width: ${(props) => (props.expanded ? "fit-content" : "151px")};
  transition: all 120ms ease-in-out;
  &:hover {
    box-shadow: ${DEFAULT_SHADOW};
  }
`;

export const AddEmployeeIconContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AddEmployeeButtonRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
`;

export const AddEmployeeEmailSearch = styled.input`
  font-family: ${FONT_FAMILY_REGULAR};
  width: 15rem;
  height: 3rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
`;
