import React from "react";
import VCL from "@natomas-org/villa-component-library";
import {LoadingSpinner} from "../../../../mapping/views/components/Common/LoadingSpinner";
import {
  Container,
  SpinnerContainer,
  SubtitleWithLink,
  TitleContainer,
} from "./styles";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {NatButton} from "../../../../_shared/generics/button";
import styled from "styled-components";

const ContentContainer = (props: {
  header: string;
  subHeader: string | TrustedHTML;
  content?: React.ReactNode;
  back?: {
    label: string;
    onClick: () => void;
  };
}) => {
  const {header, subHeader, content} = props;
  return (
    <Container id={"instant-quote-continue-modal-content-container"}>
      <TitleContainer>
        <VCL.Components.Atoms.Header
          properties={{
            children: header,
            style: {fontWeight: "500", fontSize: "24px"},
          }}
        />
        {/*TODO Add this to VCL*/}
        <SubtitleWithLink dangerouslySetInnerHTML={{__html: subHeader}} />
      </TitleContainer>
      <Content id={"instant-quote-continue-modal-content"}>
        <SpinnerContainer hidden={!!content}>
          <LoadingSpinner isFullScreen={false} />
        </SpinnerContainer>
        {content}
        <NatButton
          style={{
            fontFamily: "Inter",
            fontWeight: 500,
            lineHeight: "16px",
            padding: 0,
            fontSize: "16px",
          }}
          hidden={!props?.back}
          label={props?.back?.label ?? "Back"}
          option={StyleOption.PRIMARY_LINK}
          clickEvent={props?.back?.onClick}
        />
      </Content>
    </Container>
  );
};

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export default ContentContainer;
