import {usePage} from "../../../../../_shared/hooks/usePage";
import React, {Fragment} from "react";
import {
  NatJustifyContent,
  StyleOption,
} from "../../../../../_shared/generics/_shared";
import {
  CatalogSectionDescription,
  CatalogSectionTitle,
} from "../../../../views/styles";
import {NatLink} from "../../../../../_shared/generics/button";
import styled from "styled-components";
import {NatFlex} from "../../../../../_shared/generics/flex/NatFlex";
import {isBlankString} from "@natomas-org/core";

const StyledNatFlex = styled(NatFlex)`
  margin-top: ${(props) => (props.theme?.Page?.isNatMobile ? "0" : "1rem")};
  margin-bottom: ${(props) => (props.theme?.Page?.isNatMobile ? "1rem" : "0")};
`;
export const SectionTitle = (props: {
  title: string | null;
  description: string | null;
  linkText: string | null;
  linkEvent?: () => void;
}) => {
  const {description, title, linkText, linkEvent} = props;
  const {isNatMobile} = usePage();
  if (!title || isBlankString(title)) {
    return null;
  }
  if (isNatMobile) {
    return (
      <Fragment>
        <StyledNatFlex
          ROW
          NOGAP
          JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
        >
          <CatalogSectionTitle
            style={{textDecoration: "underline", cursor: "pointer"}}
            onClick={linkEvent}
          >
            {title}
          </CatalogSectionTitle>
        </StyledNatFlex>
        <CatalogSectionDescription hidden={!props.description}>
          {description}
        </CatalogSectionDescription>
      </Fragment>
    );
  }
  return (
    <div style={{paddingTop: "0", paddingBottom: "0"}}>
      <CatalogSectionTitle>{title}</CatalogSectionTitle>
      <StyledNatFlex
        ROW
        NOGAP
        JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
        style={{paddingBottom: "1rem"}}
      >
        <CatalogSectionDescription hidden={!props.description}>
          {description}
        </CatalogSectionDescription>
        {linkText && (
          <NatLink
            style={{padding: "0 0"}}
            option={StyleOption.PRIMARY_LINK_ALT}
            label={linkText}
            clickEvent={linkEvent}
          />
        )}
      </StyledNatFlex>
    </div>
  );
};
