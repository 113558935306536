import {getModifierButton} from "./ModifierButtonFactory";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import React, {useCallback, useMemo} from "react";
import {ModifierDescription} from "./styles";
import {getDescriptionForModifier} from "./helper";
import {getModifierGroupType} from "../../helper";
import {SHOW_COLOR_SWATCHES_PROPERTY_KEY} from "../../constants";
import {ProjectPriceMode} from "@natomas-org/core";

export interface StudioModifierProps {
  modifier: any;
  modifierGroup: any;
  clickEvent: any;
  projectPriceMode: ProjectPriceMode;
  isMobile?: boolean;
}

export const StudioModifier = (props: StudioModifierProps) => {
  const {modifierGroup, modifier, clickEvent, isMobile, projectPriceMode} =
    props;

  const {selections, isEditing} = useStudioSettings();

  const isSelected = useMemo(() => {
    return selections?.[modifier?.id] === true;
  }, [selections, modifier]);

  const type = useMemo(() => {
    return getModifierGroupType(modifierGroup);
  }, [modifierGroup]);

  const onClick = useCallback(() => {
    clickEvent(modifier, modifierGroup);
  }, [modifier, clickEvent, modifierGroup]);

  const description = useMemo(() => {
    if (modifier == null) {
      return null;
    }
    const d = getDescriptionForModifier(modifier);
    if (!!d && d?.length > 0) {
      // Check type
      if (type === SHOW_COLOR_SWATCHES_PROPERTY_KEY) {
        // TODO Override swatch descriptions right now
        return null;
      }
      return <ModifierDescription show={isSelected}>{d}</ModifierDescription>;
    } else {
      return null;
    }
  }, [isSelected, modifier, type]);

  const button = useMemo(() => {
    if (modifier == null) {
      return null;
    }
    return getModifierButton(
      modifier,
      modifierGroup,
      onClick,
      isSelected,
      isEditing,
      isMobile ?? false,
      type,
      projectPriceMode
    );
  }, [
    modifier,
    modifierGroup,
    onClick,
    isSelected,
    isEditing,
    isMobile,
    type,
    projectPriceMode,
  ]);

  if (!button && !description) {
    return null;
  }

  return (
    <>
      {button}
      {description}
    </>
  );
};
