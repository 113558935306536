import React, {useMemo} from "react";
import CountUp from "react-countup";
import usePrevious from "../../../../_shared/hooks/usePrevious";
import {UnitInfoCardIndentedRowGroup} from "./styles";
import {getMicrosForPriceMode, ProjectPriceMode} from "@natomas-org/core";

export const StudioUnitCardCategoryRow = (props: {
  category: any;
  selectedModifiers: any;
  pricingMode: ProjectPriceMode;
}) => {
  const {category, selectedModifiers, pricingMode} = props;
  const {selectedModifierGroups, title, id} = category ?? {};

  const price = useMemo(() => {
    const validModifierGroups = selectedModifierGroups.filter(
      (modifierGroup: any) => modifierGroup.id != null
    );
    let total = 0;
    validModifierGroups.forEach((modifierGroup: any) => {
      if (!modifierGroup) {
        return null;
      }
      const {fullModifiers} = modifierGroup;

      const selected = fullModifiers?.filter((modifier: any) =>
        selectedModifiers.find((m: any) => m?.id === modifier?.id)
      );
      selected?.forEach((m: any) => {
        total += getMicrosForPriceMode(m, pricingMode);
      });
    });
    return total / 100;
  }, [pricingMode, selectedModifierGroups, selectedModifiers]);

  const prevPrice = usePrevious(price);

  return (
    <UnitInfoCardIndentedRowGroup id={`review-${id}`}>
      {title}
      <CountUp
        start={prevPrice}
        end={price}
        separator=","
        prefix="+$"
        suffix=""
        duration={0.5}
      />
    </UnitInfoCardIndentedRowGroup>
  );
};
