import React from "react";
import {useProduct} from "../../../../../../_shared/hooks/useProductCatalog/useProduct";

interface ProductInfoCellProps {
  factoryId?: string;
  productId?: string;
}

const ProductInfoCell = (props: ProductInfoCellProps) => {
  const {productId, factoryId} = props;
  const {product} = useProduct(productId, factoryId);
  if (!product || !productId || !factoryId) return <div>Loading...</div>;
  return <div>{product?.title}</div>;
};

export default ProductInfoCell;
