import React, {useMemo} from "react";
import {ICartItem} from "@natomas-org/core";
import CartSectionContainer from "./CartSectionContainer";
import CartItemRow from "../CartSectionItem";
import {CartSectionByUnitProps} from "./CartSectionByUnits";
import {useProduct} from "../../../../../_shared/hooks/useProductCatalog/useProduct";
import {shouldShowDivider, sortCartItemsById} from "../helper";
import {Divider} from "../../../../../_shared/generics/divider";

const CartSectionByUnit = (props: CartSectionByUnitProps) => {
  const {factoryId, productId, items, getCartItemRow, isMobile} = props;
  const {product} = useProduct(productId, factoryId);
  const {inventory_info, title: productTitle} = product ?? {};

  const title: string = useMemo(() => {
    const showCount = !inventory_info;
    let t: string;
    if (!productTitle) {
      t = `Unknown Item`;
    } else {
      t = productTitle;
    }
    if (showCount) {
      t = `${t} (${items?.length})`;
    }
    return t;
  }, [inventory_info, items?.length, productTitle]);

  if (!product) {
    return null;
  } else {
    return (
      <CartSectionContainer label={title ?? ""} key={`cart-${productId}}`}>
        {sortCartItemsById(items).map((item: ICartItem, index: number) => {
          return (
            <>
              {shouldShowDivider(index, items.length) && <Divider />}
              <CartItemRow
                {...getCartItemRow(item)}
                isMobile={isMobile}
                key={`cart-${title}-item-${index}`}
              />
            </>
          );
        })}
      </CartSectionContainer>
    );
  }
};

export default CartSectionByUnit;
