import React, {useMemo, useState} from "react";
import {IImageDetails} from "@natomas-org/core";
import {ProductImageContainer} from "../../../../catalog/views/ProductView/styles";
import {useDynamicValue} from "../../../hooks/useDynamicValue";
import {useSpringCarousel, FixedWithLoop} from "react-spring-carousel";
import {NatButton} from "../../../generics/button";
import {BLACK, TRANSPARENT_BLACK, WHITE} from "../../../colors";
import {IconPosition, NatSize, StyleOption} from "../../../generics/_shared";
import {NatFlex} from "../../../generics/flex/NatFlex";
import {CarouselSlide} from "./carouselSlide";
import {NatLeftCaratIcon, NatRightCaratIcon} from "../../../icon/icons";

export const ImageCarousel = (props: {
  images: IImageDetails[];
  setFullScreenImage?: (id: string) => void;
  hideFullScreenModal?: () => void;
}) => {
  const {images, setFullScreenImage} = props;
  const productImageHeight = useDynamicValue({
    forFour: "40vh",
    forEight: "18rem",
    forTwelve: "24rem",
    forSixteen: "36rem",
  });
  const SPACE_BETWEEN_IMAGES_PX = 16;
  const [currentSlide, setCurrentSlide] = useState(
    images?.length > 0 ? images[0].imageId : "1"
  );

  // @ts-ignore
  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    slideToItem,
    useListenToCustomEvent,
  } = useSpringCarousel({
    items:
      images?.length > 0
        ? images?.map((image: IImageDetails) => {
            return {
              key: `${image.imageId}`,
              id: `${image.imageId}`,
              renderItem: (
                <CarouselSlide
                  image={image}
                  showSelectedImage={setFullScreenImage}
                  hideLoading={false}
                />
              ),
            };
          })
        : [{id: "1", renderItem: <div />}],
    withLoop: true,
    itemsPerSlide: 1,
  } as FixedWithLoop);
  useListenToCustomEvent((event) => {
    if (event.eventName === "onSlideStartChange") {
      setCurrentSlide(event?.nextItem?.id);
    }
  });
  const indicators = useMemo(() => {
    return images?.map((image) => {
      return (
        <div
          key={image.imageId}
          onClick={() => {
            slideToItem(image.imageId);
          }}
          style={{
            position: "relative",
            height: "12px",
            width: "12px",
            borderRadius: "50%",
            backgroundColor:
              currentSlide === image.imageId ? WHITE : TRANSPARENT_BLACK,
            border: `1px solid ${BLACK}`,
            zIndex: 100,
          }}
        />
      );
    });
  }, [currentSlide, images, slideToItem]);

  return (
    <ProductImageContainer
      style={{
        marginTop: "16px",
        height: productImageHeight,
        overflow: "hidden",
        borderRadius: "16px",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      <NatButton
        label={""}
        clickEvent={() => {
          slideToPrevItem();
        }}
        style={{
          zIndex: 100,
          opacity: 0.8,
          position: "absolute",
          left: `16px`,
          top: `calc((${productImageHeight} - 16px) / 2)`,
          width: "min-content",
        }}
        hidden={images.length <= 1}
        size={NatSize.SMALL}
        icon={{icon: <NatLeftCaratIcon />, iconPosition: IconPosition.ONLY}}
        option={StyleOption.SECONDARY}
      />
      <NatButton
        label={""}
        clickEvent={() => {
          slideToNextItem();
        }}
        style={{
          zIndex: 100,
          opacity: 0.8,
          position: "absolute",
          right: `16px`,
          top: `calc((${productImageHeight} - 16px) / 2)`,
          width: "min-content",
        }}
        hidden={images.length <= 1}
        size={NatSize.SMALL}
        icon={{icon: <NatRightCaratIcon />, iconPosition: IconPosition.ONLY}}
        option={StyleOption.SECONDARY}
      />
      <NatFlex
        ROW
        style={{
          padding: `0 ${SPACE_BETWEEN_IMAGES_PX}px`,
          position: "absolute",
          bottom: "6px",
          height: "1rem",
          width: "100%",
          justifyContent: "center",
          zIndex: 100,
        }}
        hidden={images.length <= 1}
      >
        {indicators}
      </NatFlex>
      <div
        style={{
          margin: `0 0`,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        {carouselFragment}
      </div>
    </ProductImageContainer>
  );
};
