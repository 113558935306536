import {ISiteWorkBucket} from "../../portal/views/InstantQuoteView/interface";
import {
  DesignStudioModifier,
  getMicrosForPriceMode,
  priceTextFromMicros,
  ProjectPriceMode,
} from "@natomas-org/core";

export interface IUpgradeListDetailAlt {
  title: string;
  price: number;
}

interface IUpgradeListDetail extends ISiteWorkBucket {
  sectionTitle?: string;
}

export const getUpgradesList = (
  snapshot: any,
  priceMode: ProjectPriceMode,
  alternativeFormat?: boolean
): ISiteWorkBucket[] => {
  let listToReturn: ISiteWorkBucket[] = [];
  snapshot?.snapshot.forEach(
    (page: {
      selectedModifierGroups: any;
      title: string;
      description: string;
    }) => {
      const modifierGroups = page.selectedModifierGroups;
      let totalPriceInMicros = 0;
      let description: any[] = [];
      let alternativePoints: IUpgradeListDetailAlt[] = [];
      modifierGroups.forEach((modifierGroup: {selectedModifiers: any}) => {
        const modifiers = modifierGroup.selectedModifiers;
        modifiers.forEach((modifier: DesignStudioModifier) => {
          if (alternativeFormat) {
            const detail: IUpgradeListDetailAlt = {
              title: modifier?.title,
              price: modifier?.adjustedPriceMicros ?? 0,
            };
            if (priceMode === ProjectPriceMode.CostPlus) {
              detail.price = modifier?.adjustedPrice?.cost ?? 0;
            }
            description.push(modifier?.title);
            alternativePoints.push(detail);
          } else {
            description.push(
              modifier?.title +
                ": " +
                priceTextFromMicros(
                  getMicrosForPriceMode(modifier, priceMode) ?? 0,
                  "accurate"
                )
            );
          }
          totalPriceInMicros += getMicrosForPriceMode(modifier, priceMode) ?? 0;
        });
      });

      if (description?.length > 0) {
        listToReturn.push({
          title: page?.title ?? "",
          priceText: priceTextFromMicros(totalPriceInMicros ?? 0, "accurate"),
          bulletPoints: description ?? [],
          alternativePoints: alternativePoints,
        });
      }
    }
  );
  return listToReturn;
};
