import React from "react";
import {FactoryViewContainer} from "./styles";
import {FactoryLineGlobalValues} from "./FactoryLineGlobalValues/FactoryLineGlobalValues";
import {FactoryLineStudioBlock} from "./FactoryLineStudioBlock";
import {FactoryLineBaseBlock} from "./FactoryLineBaseBlock";
import {IFactorySummary} from "../../../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import FactoryLineInventoryBlock from "./FactoryLineInventoryBlock";
import {NatMasonryLayout} from "../../../../../../_shared/generics/layout/masonry/NatMasonryLayout";

export const FactoryInfoView = (props: {factorySummary: IFactorySummary}) => {
  const {factorySummary} = props;
  const {details, info} = factorySummary;

  if (!details) {
    return <FactoryViewContainer />;
  }
  const items: JSX.Element[] = [
    <FactoryLineGlobalValues info={info} />,
    <FactoryLineBaseBlock
      details={details}
      isPublishedVersion={details?.id === info?.publishedVersionId}
    />,
    <FactoryLineStudioBlock details={details} />,
    <FactoryLineInventoryBlock id={info?.id} />,
  ];
  return <NatMasonryLayout componentWidthPx={375} components={items} />;
};
