import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CustomerProjectInfo, IConfiguration} from "@natomas-org/core";
import {
  ProjectCarouselMobileContainer,
  ProjectSummaryContainer,
  ProjectSummaryTextContainer,
  ProjectSummaryTextSubtitle,
} from "./styles";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {PRODUCT_HERO_URL} from "../../constants";
import {useImagePreloader} from "../../../_shared/hooks/useImage";
import {usePage} from "../../../_shared/hooks/usePage";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {HOME_PAGE_ID} from "../../../portal/_shared/navigation/constants";
import {PageElementWrapper} from "../../../_shared/generics/page/components/PageElementWrapper";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {setCurrentProject} from "../../../_shared/project";
import {ProjectSelectionCarousel} from "./components/ProjectSelectionCarousel";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {SplashScreenWelcomeText} from "./components/SplashScreenWelcomeText";
import {SplashScreenProjectCTA} from "./components/SplashScreenProjectCTA";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";

export const SplashScreenProjectSummary = (props: {
  projectArray:
    | {
        project: CustomerProjectInfo;
        configuration: IConfiguration;
      }[]
    | null;
  setShowLoadingScreen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  useImagePreloader(PRODUCT_HERO_URL);
  const {customer} = useCurrentCustomer();
  const {id} = useActiveProject();
  const fullWidth = useDynamicValue(fullColumnWidths);
  const {projectArray, setShowLoadingScreen} = props;
  const {to} = useNavigation();
  const {isNatMobile} = usePage();
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    projectArray ? projectArray[0]?.configuration?.id : null
  );
  const handleSelect = useCallback(
    (index: number) => {
      if (!projectArray) {
        return null;
      }
      setSelectedProjectId(projectArray[index]?.configuration?.id);
    },
    [projectArray]
  );
  const projectOnCarousel = useMemo(() => {
    return projectArray?.find((project) => {
      return project?.configuration?.id === selectedProjectId;
    });
  }, [projectArray, selectedProjectId]);
  const viewPortalAction = useCallback(() => {
    if (selectedProjectId !== id && selectedProjectId) {
      setShowLoadingScreen(true);
      setCurrentProject(selectedProjectId).then(() => {
        setShowLoadingScreen(false);
      });
    }
    to(NavigationPaths.PORTAL, {
      page: HOME_PAGE_ID,
      pid: selectedProjectId,
    });
  }, [id, selectedProjectId, setShowLoadingScreen, to]);
  useEffect(() => {
    handleSelect(0);
  }, [handleSelect]);

  if (!customer) {
    return null;
  }

  if (isNatMobile) {
    return (
      <PageContainer overridePadding={false}>
        <PageElement size={fullWidth} height={"auto"}>
          <br />
          <SplashScreenWelcomeText customer={customer} />
          <ProjectCarouselMobileContainer>
            <ProjectSelectionCarousel
              handleSelect={handleSelect}
              projectArray={projectArray}
            />
          </ProjectCarouselMobileContainer>
          <SplashScreenProjectCTA
            configuration={projectOnCarousel?.configuration}
            viewPortalAction={viewPortalAction}
          />
          <br />
        </PageElement>
      </PageContainer>
    );
  }
  return (
    <ProjectSummaryContainer>
      <br />
      <PageElementWrapper>
        <PageElement size={fullWidth / 2} height={"18rem"}>
          <ProjectSummaryTextContainer>
            <SplashScreenWelcomeText customer={customer} />
            <ProjectSummaryTextSubtitle>
              Visit your portal to view and continue your project.
            </ProjectSummaryTextSubtitle>
            <SplashScreenProjectCTA
              configuration={projectOnCarousel?.configuration}
              viewPortalAction={viewPortalAction}
            />
          </ProjectSummaryTextContainer>
        </PageElement>
        <PageElement size={fullWidth / 2} height={"18rem"}>
          <ProjectSelectionCarousel
            handleSelect={handleSelect}
            projectArray={projectArray}
          />
        </PageElement>
      </PageElementWrapper>
      <br />
    </ProjectSummaryContainer>
  );
};
