import {
  IEstimateProjectRange,
  IEstimateProjectRangeBucket,
  IEstimateRangeAdu,
  IEstimateRangeItem,
} from "../../../api/Estimate/EstimateService";
import {TableRowProps} from "../StaticTable";
import {
  convertEstimateAduItemToTableRow,
  convertEstimateRangeItemToTableRow,
  convertEstimateSummaryToTableRow,
} from "./convert";

export const convertLatestEstimateResponseToTableRows = (
  range: IEstimateProjectRange | undefined,
  bucketKey: string,
  options?: {
    label?: string;
    hideEmptyPrices?: boolean;
    hideSelections?: boolean;
  }
): TableRowProps<any>[] => {
  let bucket: IEstimateProjectRangeBucket | null = getBucket(range, bucketKey);
  let line_item_rows: any[] = [];
  if (!bucket || bucket?.line_items?.length === 0) return [];

  // Add the ADU information to the existing line_item_rows array in the proper format
  if (bucket?.adus) {
    bucket?.adus.forEach((adu) => {
      line_item_rows.push(
        ...convertEstimateAduItemToTableRow(adu, {
          hideEmptyPrices: options?.hideEmptyPrices ?? false,
          hideSelections: options?.hideSelections ?? false,
        })
      );
    });
  }
  if (bucket?.line_items) {
    // Get bucket line-items
    bucket.line_items.forEach((lineItem: IEstimateRangeItem) => {
      if (
        options?.hideEmptyPrices &&
        lineItem.price_low === 0 &&
        lineItem.price_high === 0
      )
        return;
      const row = convertEstimateRangeItemToTableRow(lineItem);
      line_item_rows.push(row);
    });
    // Generate the label for the summary row
  }
  const label = options?.label ?? "Project Estimate";

  // Generate the summary row
  const summaryRow = convertEstimateSummaryToTableRow(bucket.summary, label);
  // Add subtotal row
  // @ts-ignore
  return [...line_item_rows, summaryRow];
};

const getBucket = (
  range: IEstimateProjectRange | undefined,
  bucketKey: string
): IEstimateProjectRangeBucket | null => {
  if (!range) return null;

  // Get the entire summary and all items
  if (bucketKey === "all") {
    return getAllBucket(range);
  }
  // Get the summary and items for a single bucket
  return range?.buckets?.[bucketKey] ?? null;
};

const getAllBucket = (range: IEstimateProjectRange) => {
  const {summary} = range;
  return {
    summary,
    line_items: getLineItemsFromAllBuckets(range),
    adus: getADUsFromAllBuckets(range),
  };
};

const getLineItemsFromAllBuckets = (
  range: IEstimateProjectRange | undefined
): IEstimateRangeItem[] => {
  const buckets = Object.values(range?.buckets ?? {});
  return buckets.flatMap((bucket) => bucket?.line_items ?? []);
};

const getADUsFromAllBuckets = (
  range: IEstimateProjectRange | undefined
): IEstimateRangeAdu[] => {
  const buckets = Object.values(range?.buckets ?? {});
  return buckets.flatMap((bucket) => bucket?.adus ?? []);
};
