import React from "react";
import {
  NatDownChevron,
  NatUpChevron,
} from "../../../../../../../../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../_shared/generics/_shared";
import {NatButton} from "../../../../../../../../../../../_shared/generics/button";
import {StudioEditorSwapDirection} from "../../formik/move";

interface MoveButtonProps {
  direction: StudioEditorSwapDirection;
  action: (e: any) => void;
  size: NatSize;
}

const MoveButton = (props: MoveButtonProps) => {
  const {direction, action, size} = props;

  return (
    <NatButton
      label={`Move ${direction}`}
      disabled={!action}
      clickEvent={action}
      option={StyleOption.ADMIN_SOLID}
      size={size}
      icon={{
        icon:
          direction === StudioEditorSwapDirection.UP ? (
            <NatUpChevron />
          ) : (
            <NatDownChevron />
          ),
        iconPosition: IconPosition.ONLY,
      }}
    />
  );
};

export default MoveButton;
