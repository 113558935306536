import {NatButton} from "../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import React from "react";
import {MdKeyboardBackspace} from "react-icons/md";
import {SideBarHeaderTitle} from "../../styles/AdminStyles";

export const AdminHeaderTitle = (props: {
  clickEvent: () => void;
  title?: string;
}) => {
  const {title, clickEvent} = props;
  return (
    <>
      <div style={{position: "absolute", left: 6, top: 6}}>
        <NatButton
          label={"Back"}
          trackingId={"admin-title-back-button"}
          type={"button"}
          clickEvent={clickEvent}
          size={NatSize.XSMALL}
          option={StyleOption.SECONDARY_ALT}
          icon={{
            icon: <MdKeyboardBackspace />,
            iconPosition: IconPosition.ONLY,
          }}
        />
      </div>
      {title && <SideBarHeaderTitle>{title}</SideBarHeaderTitle>}
    </>
  );
};
