import styled from "styled-components";
import {WHITE} from "../../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const CustomerInfoSideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 0.25rem;
`;

export const CustomerInfoSideBarTitle = styled.div`
  font-size: 0.875rem;
  font-family: ${FONT_FAMILY_BOLD};
  color: ${WHITE};
`;

export const CustomerInfoSideBarFieldLabel = styled.div`
  font-size: 0.875rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${WHITE};
`;

export const CustomerInfoSideBarFieldInfo = styled.div`
  font-size: 0.75rem;
  color: ${WHITE};
`;
