import styled from "styled-components";
import {BLACK, GRAY} from "../../_shared/colors";
import {isMobile} from "../../_shared/navigation";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../portal/views/SSDDashboardView/styles/globals";

const CatalogContainer = styled.div`
  height: inherit;
`;

const NotServiceableBlurb = styled.div`
  text-align: center;
  max-width: 24rem;
  margin: auto;
  white-space: pre-wrap;
  font-size: 1rem;
  color: ${GRAY};
  font-family: ${FONT_FAMILY_REGULAR};
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const ProductLinesContainer = styled.div``;

const ProductCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow-scrolling: auto;
`;

const ProductListContainer = styled.div<{removeBottomMargin?: boolean}>`
  margin-bottom: ${(props) => (props.removeBottomMargin ? "0" : "3rem")};
  margin-top: 1rem;
`;

const CatalogTitle = styled.div`
  font-size: ${isMobile() ? "1.5" : "2"}rem;
  color: ${BLACK};
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-top: 1.5rem;
`;

const FeasibilityTitle = styled.div<{isMobile: boolean}>`
  padding-left: ${(props) => (props.isMobile ? "0" : "0")};
  padding-right: ${(props) => (props.isMobile ? "0" : "0")};
  font-size: ${isMobile() ? "1.25" : "1.5"}rem;
  color: ${BLACK};
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-top: 1.5rem;
  padding-bottom: 0;
`;

const FeasibilitySubText = styled.div`
  font-size: ${isMobile() ? "1" : "1"}rem;
  color: ${BLACK};
`;

const CatalogSectionTitle = styled.div`
  font-size: ${isMobile() ? "1" : "1.25"}rem;
  padding-top: 1.5rem;
  color: ${BLACK};
  font-family: ${FONT_FAMILY_BOLD};
`;
const CatalogSectionDescription = styled.div`
  font-size: 1rem;
  font-weight: normal;
  color: ${GRAY};
  display: flex;
  margin-bottom: ${(props) => (props.theme?.Page?.isNatMobile ? "1rem" : "0")};
  flex-direction: row;
  column-gap: 0.5rem;
`;

export {
  CatalogContainer,
  CatalogSectionDescription,
  CatalogSectionTitle,
  CatalogTitle,
  FeasibilitySubText,
  FeasibilityTitle,
  NotServiceableBlurb,
  ProductCardsContainer,
  ProductLinesContainer,
  ProductListContainer,
};
