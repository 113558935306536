import React, {useEffect, useMemo, useState} from "react";
import CountUp from "react-countup";
import usePrevious from "../../../../_shared/hooks/usePrevious";
import {
  UnitInfoCard,
  UnitInfoCardCategoryReviewContainer,
  UnitInfoCardColumnGroup,
  UnitInfoCardIndentedRowGroup,
  UnitInfoCardPrice,
  UnitInfoCardPriceDescription,
  UnitInfoCardReviewContainer,
  UnitInfoCardRowGroup,
} from "./styles";
import {NatButton} from "../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {NatCloseIcon} from "../../../../_shared/icon/icons";
import {StudioUnitCardCategoryRow} from "./StudioUnitCardCategoryRow";
import {priceTextFromMicros} from "../../helper";
import {getBasePriceFromProduct} from "../../product";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {ProjectPriceMode} from "@natomas-org/core";

export interface StudioUnitCardProps {
  price: number;
  product: any;
  structure: any[];
  selectedModifiers: any;
  controller: JSX.Element;
  atBottom: boolean;
  isEditing: boolean;
  priceMode: ProjectPriceMode;
  stackedMode?: boolean;
  additionLineItemTotalInMicros?: number;
}

export const StudioUnitCard = (props: StudioUnitCardProps) => {
  const {
    isEditing,
    price,
    product,
    structure,
    selectedModifiers,
    controller,
    stackedMode,
    atBottom,
    priceMode,
    additionLineItemTotalInMicros,
  } = props;
  const prevPrice = usePrevious(price);
  const [reviewing, setReviewing] = useState<boolean>(false);
  const {loggedInUser} = useCurrentUser();
  useEffect(() => {
    setReviewing(atBottom);
  }, [atBottom]);
  console.log("StudioUnitCard", structure);

  const label = useMemo(() => {
    let descriptor: string;
    switch (priceMode) {
      case ProjectPriceMode.CostPlus:
        descriptor = "cost";
        break;
      default:
        descriptor = "price";
        break;
    }
    return `Base unit ${descriptor} + selections`;
  }, [priceMode]);

  return (
    <UnitInfoCard id={"design-unit-info-card"} stackedMode={stackedMode}>
      <UnitInfoCardRowGroup>
        <UnitInfoCardColumnGroup>
          <UnitInfoCardPrice>
            <CountUp
              start={prevPrice === 0 ? price : prevPrice}
              end={price}
              separator=","
              prefix="$"
              suffix=""
              duration={0.5}
            />
          </UnitInfoCardPrice>
          <UnitInfoCardPriceDescription>{label}</UnitInfoCardPriceDescription>
          {/*<VersionSelectionDropdown styleOption={StyleOption.PRIMARY_ALT} />*/}
        </UnitInfoCardColumnGroup>
        <UnitInfoCardColumnGroup>
          <NatButton
            label={"View breakdown"}
            icon={
              reviewing
                ? {
                    icon: <NatCloseIcon />,
                    iconPosition: IconPosition.ONLY,
                  }
                : undefined
            }
            size={stackedMode ? NatSize.SMALL : NatSize.NORMAL}
            option={StyleOption.PRIMARY_ALT}
            trackingId={
              !!loggedInUser
                ? "account-design-studio-review"
                : "pa-design-studio-review"
            }
            clickEvent={() => setReviewing(!reviewing)}
          />
        </UnitInfoCardColumnGroup>
      </UnitInfoCardRowGroup>
      <UnitInfoCardReviewContainer show={reviewing}>
        <UnitInfoCardCategoryReviewContainer>
          <UnitInfoCardIndentedRowGroup id={`review-${product?.id}`}>
            {product?.title}
            <div>
              {priceTextFromMicros(
                getBasePriceFromProduct(product, priceMode) ?? 0,
                "accurate"
              )}
            </div>
          </UnitInfoCardIndentedRowGroup>
          {structure?.map((category: any) => {
            console.log("StudioUnitCard-Category", category);
            return (
              <StudioUnitCardCategoryRow
                key={category?.id}
                category={category}
                selectedModifiers={selectedModifiers}
                pricingMode={priceMode}
              />
            );
          })}
          {/*<UnitInfoCardIndentedRowGroup*/}
          {/*  hidden={*/}
          {/*    !additionLineItemTotalInMicros ||*/}
          {/*    additionLineItemTotalInMicros === 0*/}
          {/*  }*/}
          {/*  id={`review-additional-line-items`}*/}
          {/*>*/}
          {/*  Additional Items*/}
          {/*  <CountUp*/}
          {/*    start={0}*/}
          {/*    end={(additionLineItemTotalInMicros ?? 0) / 100}*/}
          {/*    separator=","*/}
          {/*    prefix="+$"*/}
          {/*    suffix=""*/}
          {/*    duration={0.5}*/}
          {/*  />*/}
          {/*</UnitInfoCardIndentedRowGroup>*/}
        </UnitInfoCardCategoryReviewContainer>
        {controller}
      </UnitInfoCardReviewContainer>
    </UnitInfoCard>
  );
};
