import {MatrixTablePageProps} from "../../index";
import React, {Dispatch, SetStateAction, useMemo} from "react";
import {useAdminProductCatalog} from "../../../../../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {getProductTable} from "../../../table";
import {sortProducts} from "../../../../../../../../_shared/_legacy/logic/ProductGroupUtils";
import {GenericMatrixTable} from "../_shared/GenericMatrixTable";
import {getChanges, sanitizeMatrixCellProps} from "../_shared/helper";
import {ProductInfoTableCell} from "./ProductInfoTableCell";
import {AdminProductCatalogMatrixFilterByOptions} from "../../../../../../../../_shared/slices/types/Store";
import {useSelectedFactoryLine} from "../../../../../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {IProduct} from "@natomas-org/core";

export interface ProductInfoTableProps extends MatrixTablePageProps {
  loading: boolean;
  matrixEdits: any;
  searchText: string | undefined;
  setMatrixEdits: Dispatch<SetStateAction<any>>;
  heightOffset?: string;
}

export const ProductInfoTable = (props: ProductInfoTableProps) => {
  const {
    matrixEdits,
    transpose,
    pageNumber,
    itemsPerPage,
    heightOffset,
    setMatrixEdits,
  } = props;
  const {products: allProducts} = useSelectedFactoryLine();
  const {matrixFilters, matrixMode, matrixSubMode} = useAdminProductCatalog();
  const {filterIds, filterBy} = matrixFilters;

  // Memoized Values
  const products = useMemo(() => {
    return (Object.values(allProducts ?? {}) as IProduct[]).filter((p: any) => {
      if (filterBy === AdminProductCatalogMatrixFilterByOptions.PRODUCT) {
        return filterIds?.includes(p?.id);
        // } else if (include === AdminProductCatalogMatrixIncludeOptions.ALL) {
        //   return true;
        // } else if (include === AdminProductCatalogMatrixIncludeOptions.MODELS) {
        //   return !p.inventory_info;
        // } else if (
        //   include === AdminProductCatalogMatrixIncludeOptions.INVENTORY
        // ) {
        //   return p.inventory_info;
      } else {
        return !p.inventory_info;
      }
    });
  }, [allProducts, filterBy, filterIds]);

  const table = useMemo(() => {
    return getProductTable(sortProducts(products), matrixEdits, {
      matrixMode,
      matrixSubMode,
      transpose,
    });
  }, [products, matrixEdits, matrixMode, matrixSubMode, transpose]);

  const productPageProps: MatrixTablePageProps = {
    ...props,
    itemTotal: 2,
  };

  return (
    <GenericMatrixTable
      transpose={transpose}
      pageNumber={pageNumber}
      itemsPerPage={itemsPerPage}
      table={table}
      heightOffset={heightOffset}
      cellRenderer={(cellProps: any) => {
        return (
          <ProductInfoTableCell
            customProps={sanitizeMatrixCellProps(cellProps, matrixEdits)}
            pageProps={productPageProps}
          />
        );
      }}
      onChange={(changes: any) => {
        const newMatrixEdits = getChanges(
          matrixMode,
          matrixSubMode,
          changes,
          matrixEdits
        );
        setMatrixEdits(newMatrixEdits);
      }}
    />
  );
};
