import styled from "styled-components";
import {GRAY} from "../../../../_shared/colors";

export const ModifierDescription = styled.p<{show: boolean}>`
  max-height: ${(props) => (props.show ? "10rem" : "0")};
  overflow-y: hidden;
  margin: ${(props) => (props.show ? "0.5rem 0 0.25rem 0" : "0")};
  padding: 0;
  font-size: 0.75rem;
  color: ${GRAY};
  transition: all 350ms ease-in-out;
  :hover {
    overflow-y: ${(props) => (props.show ? "auto" : "hidden")};
  }
`;

export const ModifierSubtitle = styled.p`
  font-size: 0.875rem;
  color: ${GRAY};
  margin: 0;
  padding: 0 1rem;
  display: flex;
  gap: 0.25rem;
`;
