import {
  CatalogStateOptions,
  IFetchingDocumentType,
  IFetchingStatus,
} from "./helper";
import {
  CatalogQuizAnswers,
  IEligibleFeatures,
  ProjectPriceMode,
  SortDropdownValues,
  SurveyType,
} from "@natomas-org/core";
import {FilterType} from "../../catalog/filter/types";

export interface IOptionsSlice {
  [CatalogStateOptions.OPTION_VALUES]: {[id: string]: any};
  [CatalogStateOptions.OPTION_KEYS]: {[id: string]: any};
  fetchingStatus: {
    [key in IFetchingDocumentType]?: {
      [key2: string]: IFetchingStatus;
    };
  };
}

export interface ICatalogSessionSlice {
  adminCatalogMode: boolean;
  eligibility: IEligibleFeatures | undefined;
  filters: any[];
  bedroomFilterStrictMode: boolean;
  selectedProductGroupId: string | null;
  selectedProductGroupVersionId: string | null;
  selectedProductId: string | null;
  showFiltersModal: boolean;
  showFiltersDropdown: FilterType | null;
  sortBy: SortDropdownValues;
  allInEstimateCatalog: boolean;
  survey: {[key in SurveyType]: CatalogQuizAnswers};
  priceMode: ProjectPriceMode;
}

// Interface for a session using the Admin Studio Manager (AdminProCat)
export interface IAdminStudioManagerSlice {
  editingCategory: any;
  editingModifier: any;
  editingModifierGroup: any;
  editingModifierGroupOverrides: any;
  editingModifierOverrides: any;
  editingProduct: any;
  isEditingConfigurationPage: boolean;
  isEditingOptionKeys: boolean;
  isEditingOptionValues: boolean;
  isEditingProduct: boolean;
}

export enum AdminFactoryInventoryEditorView {
  CATALOG = "Catalog",
  STUDIO = "Studio",
  PRICING = "Pricing",
  INFO = "Information",
}

export interface IAdminFactoryInventoryEditorSlice {
  view: AdminFactoryInventoryEditorView | null;
  isEditing: boolean;
  editingProduct: any;
  editingConfiguration: any;
}
