import React, {useEffect, useState} from "react";
import {NatButton} from "../../../../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../../../../_shared/generics/_shared";
import {updateProjectArchiveStatus} from "../../../../../../../../../database/firebase/api/project";
import {useCurrentCustomer} from "../../../../../../../../_shared/hooks/useCurrentCustomer";
import useActiveDesign from "../../../../../../../../_shared/hooks/useDesign/useActiveDesign";
import useActiveProject from "../../../../../../../../_shared/hooks/useProject/useActiveProject";

export const ArchiveProjectAction = () => {
  const {customer} = useCurrentCustomer();
  const {id} = useActiveProject();
  const {configuration} = useActiveDesign();
  const [spin, setSpin] = useState<boolean>(true);
  const updateArchiveStatus = () => {
    if (!customer) {
      return;
    }
    updateProjectArchiveStatus(
      customer?.user_id,
      id,
      !configuration?.archived
    ).then(() => {
      setSpin(false);
    });
  };

  const getButtonLabel = () => {
    const appendingLabel = " project";
    if (configuration?.archived) {
      return "Un-archive" + appendingLabel;
    } else {
      return "Archive" + appendingLabel;
    }
  };

  const getTooltipText = () => {
    const v1 = configuration?.archived ? "show" : "hide";
    const v2 = configuration?.archived ? "to" : "from";
    return "Will " + v1 + " the project " + v2 + " the customer";
  };

  useEffect(() => {
    setSpin(true);
  }, [configuration?.archived]);

  return (
    <NatButton
      style={{minWidth: "20rem", width: "100%"}}
      id={"archive-project-button"}
      label={getButtonLabel()}
      option={StyleOption.DESTRUCTIVE}
      type={"button"}
      spinnerEnabled={spin}
      tooltip={getTooltipText()}
      clickEvent={() => updateArchiveStatus()}
    />
  );
};
