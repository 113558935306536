export const ProductOptionKeyStructure = {
  name: "",
};

export const ProductOptionValueStructure = {
  name: "",
  value: "",
  optionKey: "",
  optionKeys: "",
};
