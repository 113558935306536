import {savePayloadToCustomer} from "../../../database/firebase/user/base";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import {registrationToCustomer} from "../../../components/intake-form/logic/registrationToCustomer";
import {customerInfoToNatomasCustomer} from "../../../database/firebase/adapters";
import {IAddressDetails} from "@natomas-org/core";
import {getProjectService} from "../../../api/project/ProjectService";

/*
  These were originally invoked as a side effect of the form submission
  to the firebase cloud function, registration

  Purpose is to create the necessary documents for a new user account to function properly:
  - user doc
  - project doc
  - design doc
*/

export const createUserAccount = async (
  lead: CreateLeadSchema,
  address: IAddressDetails
) => {
  const {auth_user_id} = lead;
  if (!auth_user_id) throw new Error("No auth user id");
  const uid = auth_user_id as string;

  const customerInfo = registrationToCustomer(
    uid,
    lead?.first_name,
    lead?.last_name,
    lead?.email,
    (lead?.phone_number as string) ?? "",
    address
  );
  const natUser = customerInfoToNatomasCustomer({
    ...customerInfo,
    used_b2b_intake: lead?.lead_segment === "b2b",
  });

  try {
    await savePayloadToCustomer(uid, {
      ...natUser,
      lead_submission: lead,
    });
  } catch (error) {
    throw error;
  }

  try {
    const service = await getProjectService();
    const r = await service.createNewProject(lead, address);
    await savePayloadToCustomer(uid, {project_ids: [r?.data?.project_id]});
    return r?.data?.project_id;
  } catch (error) {
    throw error;
  }
};
