import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {toProposal} from "../../../../_shared/hooks/useNavigation/AvatarDropdown/navigation";
import React from "react";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";

export const InstantQuoteCTA = (props: {
  id: string;
  title?: string;
  contactAction?: () => void;
}) => {
  const {to} = useNavigation();
  return (
    <div
      className={"vcl-container-primary-16"}
      style={{justifyContent: "center", width: "300px"}}
    >
      <NatButton
        label={props.title ?? "Continue"}
        type={"button"}
        id={"ssd-continue-from-instant-quote"}
        trackingId={"ssd-continue-from-instant-quote"}
        size={NatSize.NORMAL}
        option={StyleOption.PRIMARY_BLACK}
        style={{width: "100%", padding: "10px 24px"}}
        clickEvent={() => {
          // TODO: Should we not support this? It is currently only hit by B2B
          toProposal(to, props.id);
        }}
        disabled={false}
      />
    </div>
  );
};
