import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const UserFeedbackElementContainer = styled.div`
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem 1rem;
`;

export const UserFeedbackElementSubtitle = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.67);
`;
export const UserFeedbackElementListContainer = styled.div`
  font-size: 1.5rem;
  padding: 1rem 1rem;
`;

export const UserFeedbackElementTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
`;

export const UserFeedbackRowContainer = styled.div`
  display: flex;
  column-gap: 0.35rem;
  align-items: flex-start;
  align-content: center;
`;
