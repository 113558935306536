

export const FactoryLineGlobalValuesStructure = {
  globalTitle: {
    name: "Global Title",
    type: "text",
    index: 0,
  },
  name: {
    name: "Factory Name",
    type: "text",
    index: 1,
  },
  address: {
    name: "Factory Location",
    type: "address",
    index: 2,
  },
};
