import {
  SHOW_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_COLOR_SWATCHES_PROPERTY_KEY,
  SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_TEXT_DISPLAY_KEY,
  SHOW_FULL_TEXT_PROPERTY_KEY,
  SHOW_MULTI_SECENT_DISPLAY_KEY,
  SHOW_MULTI_SELECT_PROPERTY_KEY,
} from "./constants";

export const getSelectionMap = (selectedModifiers: any[]) => {
  let selectionMap: {[key: string]: boolean} = {};
  selectedModifiers?.forEach((modifier: any) => {
    if (modifier?.id) {
      selectionMap[modifier.id] = modifier;
    }
  });
  return selectionMap;
};

export const getModifierGroupType = (modifierGroup: any) => {
  const properties = modifierGroup?.properties;
  if (properties == null) {
    return "default";
  } else if (properties[SHOW_MULTI_SELECT_PROPERTY_KEY]) {
    return SHOW_MULTI_SECENT_DISPLAY_KEY;
  } else if (properties[SHOW_COLOR_SWATCHES_PROPERTY_KEY]) {
    if (properties[SHOW_FULL_TEXT_PROPERTY_KEY]) {
      return SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY;
    }
    return SHOW_COLOR_SWATCHES_DISPLAY_KEY;
  } else if (properties[SHOW_FULL_TEXT_PROPERTY_KEY]) {
    return SHOW_FULL_TEXT_DISPLAY_KEY;
  }

  return "default";
};

export const priceTextFromMicros = (micros?: number, option?: string) => {
  if (micros == null || micros === 0) {
    if (option === "integer-sign") {
      return "+$0";
    }
    if (option === "accurate") {
      return "$0";
    }
    return "Included";
  }
  let sign = "";
  if (option !== "minimal") {
    sign = "+";
  }
  if (micros < 0) {
    sign = "-";
  }

  if (option === "min" || option === "accurate") {
    sign = micros < 0 ? "-" : "";
    return (
      sign +
      "$" +
      numberWithCommasWithoutDecimal(Math.round(microsToReadablePrice(micros)))
    );
  }

  if (option === "min-sign" || option === "integer-sign") {
    return (
      sign +
      "$" +
      numberWithCommasWithoutDecimal(Math.round(microsToReadablePrice(micros)))
    );
  }

  return sign + "$" + numberWithCommas(microsToReadablePrice(micros));
};

function microsToReadablePrice(micros: number, currency?: string) {
  return Math.abs(micros / 100);
}

function numberWithCommas(x: number) {
  return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function numberWithCommasWithoutDecimal(x: number) {
  return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
