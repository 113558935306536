import React, {useState} from "react";
import {RowStatusType, TabColumnRow, TabColumnRowSection} from "../index";
import {RowStatusContainer, RowStatusTitle} from "../styles";
import {StatusHasWarning} from "../../RowStatusIcons";
import {
  FirstColumnContent,
  SectionCollapseIconContainer,
  SectionContainer,
  SectionHeaderRow,
  SectionRowContainer,
  TabColumnRowContainer,
} from "../../../../_shared/styles/AdminStyles";
import {containsSearchText} from "../../../logic/helpers";
import {NatDownChevron} from "../../../../../_shared/icon/icons";

export interface TabColumnSectionContainerProps {
  sections: TabColumnRowSection[];
  searchMode: "internal" | "studio";
  searchText?: string;
}

interface TabColumnSectionProps {
  section: TabColumnRowSection;
  searchMode: "internal" | "studio";
  searchText?: string;
}

const getRowStatusIcons = (statuses: RowStatusType | undefined) => {
  if (statuses?.hasWarning) {
    return <RowStatusContainer>{StatusHasWarning}</RowStatusContainer>;
  } else if (statuses?.customStatuses) {
    return <RowStatusContainer>{statuses.customStatuses}</RowStatusContainer>;
  } else {
    return <></>;
  }
};

const getTabColumnRows = (
  rows: TabColumnRow[],
  searchText?: string,
  searchMode?: "internal" | "studio"
): (JSX.Element | undefined)[] => {
  const key = searchMode === "internal" ? "internal_title" : "title";
  return rows
    .sort((a: any, b: any) => {
      return a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1;
    })
    ?.map((r: TabColumnRow) => {
      const target = r[key];
      let customColor = r?.statuses?.hasWarning ? "red" : undefined;
      if (containsSearchText(target, searchText)) {
        return (
          <TabColumnRowContainer
            key={r.key}
            onClick={() => r?.action?.(r?.data)}
            active={r?.active}
            customColor={customColor}
            id={"admin-tab-sidebar-" + r?.title}
          >
            {getRowStatusIcons(r?.statuses)}
            <RowStatusTitle>{r[key]}</RowStatusTitle>
          </TabColumnRowContainer>
        );
      }
      return undefined;
    });
};

export const TabColumnSection = (props: TabColumnSectionProps) => {
  const {section, searchText, searchMode} = props;
  const [collapsed, setCollapsed] = useState<boolean>(false);
  return (
    <FirstColumnContent noScroll={collapsed} key={"section-" + section?.title}>
      {section?.title && (
        <SectionHeaderRow onClick={() => setCollapsed(!collapsed)}>
          {section?.title}
          <SectionCollapseIconContainer
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          >
            <NatDownChevron />
          </SectionCollapseIconContainer>
        </SectionHeaderRow>
      )}
      <SectionRowContainer collapsed={collapsed}>
        {getTabColumnRows(section?.rows, searchText, searchMode)}
      </SectionRowContainer>
    </FirstColumnContent>
  );
};

export const TabColumnSectionContainer = (
  props: TabColumnSectionContainerProps
) => {
  const {sections, searchText, searchMode} = props;

  return (
    <SectionContainer>
      {sections?.map((s: TabColumnRowSection, index: number) => (
        <TabColumnSection
          key={"tab-column-row-section-" + index}
          section={s}
          searchText={searchText}
          searchMode={searchMode}
        />
      ))}
    </SectionContainer>
  );
};
