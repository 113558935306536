import {InfoSetItem} from "../../shared/interfaces";
import {shouldShowCategoryLink} from "./helper";
import {
  ReadOnlyFactoryInfoSetCategoryLink,
  ReadOnlyFactoryInfoSetItemLink,
  ReadOnlyFactoryInfoSetItemLinks,
  ReadOnlyFactoryInfoSetItemLinksSearch,
} from "./styles";
import {ID_KEY, INTERNAL_CATEGORY_KEY, LABEL_KEY} from "../../shared/constants";
import React, {useMemo, useState} from "react";
import {HeaderSearchInput} from "../../../admin/_shared/styles/AdminStyles";
import {containsSearchText} from "../../../admin/ProductLineView/logic/helpers";
import {NatButton} from "../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../_shared/generics/_shared";

export const ReadOnlyFactoryInfoSetDirectory = (props: {
  items: InfoSetItem[];
}) => {
  const {items} = props;
  const [searchText, setSearchText] = useState<string>();
  const filteredItems: InfoSetItem[] = useMemo(() => {
    if (!searchText) {
      return items;
    }
    return items?.filter((item: InfoSetItem) => {
      return containsSearchText(item[LABEL_KEY], searchText);
    });
  }, [searchText, items]);
  if (!items || items?.length === 0) {
    return null;
  }
  return (
    <>
      <ReadOnlyFactoryInfoSetItemLinksSearch>
        <HeaderSearchInput
          id={`read-only-factory-info-set-item-search-input`}
          style={{width: "100%"}}
          spellCheck={false}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={"Search..."}
        />
        <NatButton
          hidden={!searchText}
          clickEvent={() => {
            setSearchText(undefined);
            let e: HTMLInputElement | null = document.getElementById(
              "read-only-factory-info-set-item-search-input"
            ) as HTMLInputElement;
            if (e) {
              e.value = "";
            }
          }}
          label={"Clear"}
          option={StyleOption.PRIMARY_BLACK}
          size={NatSize.XSMALL}
        />
      </ReadOnlyFactoryInfoSetItemLinksSearch>
      <ReadOnlyFactoryInfoSetItemLinks>
        {filteredItems?.map((item: InfoSetItem, index: number) => {
          let categoryLink = null;
          if (shouldShowCategoryLink(item, filteredItems[index - 1])) {
            categoryLink = (
              <ReadOnlyFactoryInfoSetCategoryLink
                href={`#${item[INTERNAL_CATEGORY_KEY]}`}
              >
                {item[INTERNAL_CATEGORY_KEY]}
              </ReadOnlyFactoryInfoSetCategoryLink>
            );
          }
          return (
            <>
              {categoryLink}
              <ReadOnlyFactoryInfoSetItemLink href={`#${item[ID_KEY]}`}>
                {item[LABEL_KEY]}
              </ReadOnlyFactoryInfoSetItemLink>
            </>
          );
        })}
      </ReadOnlyFactoryInfoSetItemLinks>
    </>
  );
};
