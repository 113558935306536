import {useQuery} from "@tanstack/react-query";
import {getTeamService} from "../../../api/Team";

interface ProfilePicture {
  url: string;
}

const overwriteProviderImageSize = (url: string) => {
  return url.replace("=s96-c", "=s300-c");
};

const fetchProfilePicture = async (email: string): Promise<ProfilePicture> => {
  const response = await getTeamService().then((teamService) => {
    return teamService.getMemberPhoto(email);
  });
  const resize = overwriteProviderImageSize(response);
  return {url: resize};
};

function useTeamMemberPhoto(email?: string) {
  return useQuery<ProfilePicture, Error>(
    ["team-member-photo", email],
    () => fetchProfilePicture(email as string),
    {enabled: !!email}
  );
}

export default useTeamMemberPhoto;
