import React, {useMemo, useState} from "react";
import {Address} from "@natomas-org/core";
import {useAddress} from "../../../../_shared/hooks/useAddress";
import {usePropertyDataWithAddressId} from "../../../../_shared/hooks/usePropertyData/usePropertyData";
import {getCatalogPropertyInfo, ICatalogPropertyInfo} from "./helper";
import styled from "styled-components";
import {
  NatModal,
  NatModalProps,
} from "../../../../_shared/generics/modal/NatModal";
import {SearchBar} from "../../../../map/_shared/components/PublicAppSearchBar";
import {LOCATION_SEARCH_AUTOCOMPLETE_TYPE} from "../../../../map/_shared/components/PublicAppSearchBar/helpers";
import {
  CatalogAddressControllerBlurb,
  CatalogAddressControllerBlurbAddress,
  CatalogAddressControllerBlurbLabel,
} from "./styles";
import {NatLocationPin} from "../../../../_shared/icon/icons";
import {VILLA_APPLE_GREEN} from "../../../../_shared/colors";
import {useColumnCount} from "../../../../_shared/hooks/usePage";
import useActivitySlice from "../../../../_shared/hooks/useActivity/useActivitySlice";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";

const CatalogAddressControllerWrapper = styled.div``;

// TODO: Want to migrate ParcelInfo address management here
const CatalogAddressController = () => {
  const {catalogAddress, projectAddress} = useAddress();
  const address = catalogAddress ?? projectAddress;
  const {activeProjectId} = useActivitySlice();
  const {isNatMobile} = useColumnCount();
  const {to} = useNavigation();
  const [showModal, setShowModal] = useState(false);
  const propertyData = usePropertyDataWithAddressId(
    Address.getId(address ?? undefined)
  );
  const info: ICatalogPropertyInfo | null = useMemo(() => {
    if (!propertyData) {
      return null;
    }
    return getCatalogPropertyInfo(propertyData);
  }, [propertyData]);

  const isEditable = useMemo(() => {
    return !activeProjectId;
  }, [activeProjectId]);

  const blurbLabel = useMemo(() => {
    if (info?.jurisdictionName) {
      return info?.jurisdictionName;
    } else if (address) {
      return Address.getCityStateZip(address);
    } else {
      return "";
    }
  }, [info, address]);

  const modalProps: NatModalProps = useMemo(() => {
    if (isEditable) {
      return {
        content: (
          <>
            <SearchBar
              address={address ?? undefined}
              autoFocus={true}
              searchType={LOCATION_SEARCH_AUTOCOMPLETE_TYPE.STREET_ADDRESS}
              isEnabled={true}
            />
            <div hidden={!isNatMobile} style={{paddingBottom: "64px"}} />
          </>
        ),
      };
    } else {
      return {
        content: (
          <>
            <div>Would you like to edit your project address?</div>
            <div>Current: {Address.getFullAddress(address ?? undefined)}</div>
          </>
        ),
        footerButtons: [
          {
            label: "Edit address",
            option: StyleOption.PRIMARY,
            clickEvent: () => {
              to(NavigationPaths.PROFILE);
            },
            size: NatSize.SMALL,
          },
        ],
      };
    }
  }, [address, isEditable, isNatMobile, to]);

  if (!address) {
    return null;
  }

  return (
    <CatalogAddressControllerWrapper>
      <CatalogAddressControllerBlurb>
        <CatalogAddressControllerBlurbLabel>
          Showing eligible homes in:
        </CatalogAddressControllerBlurbLabel>
        <CatalogAddressControllerBlurbAddress
          onClick={() => {
            setShowModal((current) => !current);
          }}
        >
          <NatLocationPin size={20} color={VILLA_APPLE_GREEN} />
          {blurbLabel}
        </CatalogAddressControllerBlurbAddress>
      </CatalogAddressControllerBlurb>
      <NatModal
        {...modalProps}
        show={showModal}
        handleClose={() => setShowModal(false)}
      />
    </CatalogAddressControllerWrapper>
  );
};

export default CatalogAddressController;
