import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixIncludeOptions,
  AdminProductCatalogMatrixModes,
  AdminProductCatalogMatrixSubModes,
  AdminProductCatalogMatrixTitleModes,
  IAdminProductCatalogMatrixFilterOptions,
  IAdminProductCatalogSlice,
  IAdminSlice,
} from "./types/Store";
import {IFactoryInfo, ProjectPriceMode} from "@natomas-org/core";

const getInitialProductCatalogMatrixState =
  (): IAdminProductCatalogMatrixFilterOptions => {
    return {
      include: AdminProductCatalogMatrixIncludeOptions.ALL,
      filterBy: AdminProductCatalogMatrixFilterByOptions.NONE,
      filterIds: [],
    };
  };

const getInitialProductCatalogState = (): IAdminProductCatalogSlice => {
  return {
    demoPricingMode: ProjectPriceMode.DTC,
    matrixFilters: getInitialProductCatalogMatrixState(),
    matrixMode: AdminProductCatalogMatrixModes.PRICE_SET,
    matrixSubMode: AdminProductCatalogMatrixSubModes.PRICE,
    matrixTitleMode: AdminProductCatalogMatrixTitleModes.INTERNAL,
  };
};

const getInitialState = (): IAdminSlice => {
  return {
    page: null,
    view: null,
    productCatalog: getInitialProductCatalogState(),
    factoryLines: {},
    isFetching: {},
  };
};

const adminSlice = createSlice({
  name: "administration",
  initialState: getInitialState(),
  reducers: {
    setAdminPage: (state, action: PayloadAction<string | null>) => {
      state.page = action.payload;
    },
    setAdminView: (state, action: PayloadAction<string | null>) => {
      state.view = action.payload;
    },
    setAdminProductCatalogDemoPricingMode: (
      state,
      action: PayloadAction<ProjectPriceMode>
    ) => {
      state.productCatalog = {
        ...state.productCatalog,
        demoPricingMode: action.payload,
      };
    },
    setAdminProductCatalogMatrixFilters: (
      state,
      action: PayloadAction<IAdminProductCatalogMatrixFilterOptions>
    ) => {
      state.productCatalog = {
        ...state.productCatalog,
        matrixFilters: action.payload,
      };
    },
    setAdminProductCatalogMatrixMode: (
      state,
      action: PayloadAction<AdminProductCatalogMatrixModes>
    ) => {
      state.productCatalog = {
        ...state.productCatalog,
        matrixMode: action.payload,
      };
    },
    setAdminProductCatalogMatrixSubMode: (
      state,
      action: PayloadAction<AdminProductCatalogMatrixSubModes>
    ) => {
      state.productCatalog = {
        ...state.productCatalog,
        matrixSubMode: action.payload,
      };
    },
    setAdminProductCatalogMatrixTitleMode: (
      state,
      action: PayloadAction<AdminProductCatalogMatrixTitleModes>
    ) => {
      state.productCatalog = {
        ...state.productCatalog,
        matrixTitleMode: action.payload,
      };
    },
    setAdminFactoryLineInfo: (state, action: PayloadAction<IFactoryInfo>) => {
      const {id} = action.payload;
      state.factoryLines[id] = action.payload;
    },
    setIsFetching: (
      state,
      action: PayloadAction<{id: string; isFetching: boolean}>
    ) => {
      const {id, isFetching} = action.payload;
      state.isFetching[id] = isFetching;
    },
  },
});

export const adminReducer = adminSlice.reducer;
export const {
  setAdminPage,
  setAdminView,
  setAdminProductCatalogDemoPricingMode,
  setAdminProductCatalogMatrixFilters,
  setAdminProductCatalogMatrixMode,
  setAdminProductCatalogMatrixSubMode,
  setAdminProductCatalogMatrixTitleMode,
  setAdminFactoryLineInfo,
  setIsFetching,
} = adminSlice.actions;
