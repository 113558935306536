export const INVENTORY_UNIT_PREFIX = "ReadyBuilt";
export const INVENTORY_UNIT_DESCRIPTION =
  "Pre-designed, built, and ready to be delivered in as little as 30 days after permit approval";
export const INVENTORY_SERIES_IMAGE_ID = "3cqXh1SHvaqpSnLws39w";
export const SITE_WORK_PHRASE = "instant quote";
export const SITE_WORK_PHRASE_FIRST_WORD_CAPITAL = "Instant quote";
export const SITE_WORK_PHRASE_CAPITALIZED = "Instant Quote";
export const VILLA_MAP = "Villa Map";
export const FITS_ON_LOT = "Fits on lot";
export const INVENTORY_UNIT_TAG_TEXT = "Quickest delivery";
export const FITS_ON_LOT_TAG_TEXT = "Fits on lot";
