import styled from "styled-components";
import {
  ADMIN_BUTTON_ACTIVE,
  SIDEBAR_COLOR,
  SIDEBAR_COLOR_DARK,
  SIDEBAR_COLOR_LIGHT,
  VILLA_BLUE,
  WHITE,
} from "../../../colors";
import {SIDEBAR_NAV_Z_INDEX} from "../../../styles";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

const borderStyle = `1px solid ${WHITE}`;
const altBorderStyle = `1px solid ${SIDEBAR_COLOR_LIGHT}`;
const hoverStyle = `
  &:hover {
    background-color: ${VILLA_BLUE};
  }
`;

const textFormatting = `
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
`;

export const SideBarContainer = styled.div<{expanded: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${SIDEBAR_COLOR};
  color: ${WHITE};
  width: ${(props) => (props.expanded ? 300 : 34)}px;
  transition: all 150ms ease;
  z-index: ${SIDEBAR_NAV_Z_INDEX};
  border-right: ${borderStyle};
`;

export const SideBarContentContainer = styled.div<{expanded: boolean}>`
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${SIDEBAR_COLOR_DARK};
  color: ${WHITE};
  width: ${(props) => (props.expanded ? 300 : 34)}px;
  transition: all 150ms ease;
  z-index: ${SIDEBAR_NAV_Z_INDEX};
  border-right: ${borderStyle};
`;

export const SideBarExpandSelector = styled.div<{expanded: boolean}>`
  padding: 10px;
  cursor: pointer;
  svg {
    transform: rotate(${(props) => (props?.expanded ? 180 : 0)}deg);
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  border-bottom: ${borderStyle};
  background-color: ${SIDEBAR_COLOR};
  position: sticky;
  top: 0;
  left: 0;
  ${hoverStyle};
`;

export const SideBarTitle = styled.h1`
  font-size: 14px;
  padding: 0;
  margin: 0;
  line-height: 16px;
  width: auto;
  font-family: ${FONT_FAMILY_BOLD};
  ${textFormatting};
`;

export const SideBarItemLabel = styled.div<{
  isEditing: boolean;
  canHover: boolean;
  bold: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: ${(props) =>
    props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_MEDIUM};
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.isEditing ? ADMIN_BUTTON_ACTIVE : WHITE)};
  &:hover {
    color: ${(props) => props.canHover && ADMIN_BUTTON_ACTIVE};
    cursor: ${(props) => (props.canHover ? "pointer" : "default")};
  }
`;

export const SideBarItemContainer = styled.div<{
  expanded: boolean;
  active: boolean;
  level: number;
  isLast: boolean;
}>`
  margin-left: ${(props) => props?.level ?? 0}rem;
  border-left: ${(props) => (props?.level !== 0 ? altBorderStyle : undefined)};
  border-bottom: ${(props) =>
    props.level === 0
      ? altBorderStyle
      : props.expanded && !props.isLast && altBorderStyle};

  padding: ${(props) => (props.level === 0 ? "10px 0" : "10px 0 10px 10px")};
  display: ${(props) => (props.expanded ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => (props.active ? VILLA_BLUE : SIDEBAR_COLOR)};
  ${textFormatting};
`;

export const SideBarItemWrapper = styled.div<{
  expanded: boolean;
}>`
  border-bottom: ${(props) => props.expanded && altBorderStyle};
`;

export const SideBarItemContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;
