import {useUrl} from "./useUrl";
import {
  CATALOG_ROOT_PATH,
  EMPTY_PATH,
  MAP_PATH,
  STUDIO_PATH,
} from "../../../paths";
import {useCurrentUser} from "./useCurrentUser";

// This returns true if the page is a part of the public app, false otherwise
export const useIsPublicApp = (): boolean => {
  const {getURLPath, pid} = useUrl();
  const urlPath = getURLPath();
  const {loggedInUser} = useCurrentUser();

  // The following pages are all considered part of the public app
  const onMapPage = urlPath.includes(MAP_PATH);
  const onSplashScreen = urlPath === EMPTY_PATH;
  const onPublicCatalog =
    urlPath.includes(CATALOG_ROOT_PATH) && (!pid || !loggedInUser);
  const onPublicDesignStudio =
    urlPath.includes(STUDIO_PATH) && (!pid || !loggedInUser);

  return onMapPage || onSplashScreen || onPublicCatalog || onPublicDesignStudio;
};
