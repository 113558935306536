import {
  CatalogQuizAnswers,
  ICatalogTag,
  IProduct,
  IProductGroupDetails,
  IQuizResults,
  ListingStatusDetails,
  Product,
  ProductGroup,
  SurveyType,
} from "@natomas-org/core";
import {useCatalogSession} from "../useCatalogSession";
import {useMemo} from "react";
import {useAllPublishedAndRoutedProductsByFactoryLine} from "../../useFactoryLines/useFactoryLines";
import {useFactoryLine} from "../../useFactoryLine/useFactoryLine";
import {useCurrentUser} from "../../../useCurrentUser";
import {MAX_RECOMMENDED_PRODUCTS} from "./constants";
import {INVENTORY_UNIT_PREFIX} from "../../../../constants/labels";
import {useNavigation} from "../../../useNavigation";
import {NavigationPaths} from "../../../useNavigation/paths";
import {factoryLineTags} from "../../../../../admin/_models/factoryLine";
import {QuizContextTitle} from "./QuizContextTitle";
import useActiveDesign from "../../../useDesign/useActiveDesign";
import {useCatalogSessionDispatcher} from "../useCatalogSessionDispatcher";

export const useCatalogBannerProducts = (): {
  quiz: IQuizResults | null; // If null, quiz has not been taken
  quizDisabled: boolean;
  general: {
    factoryLines: IProductGroupDetails[];
  };
} => {
  const {to} = useNavigation();
  const {isAdmin} = useCurrentUser();
  const {id: currentConfigurationId} = useActiveDesign();
  const catalogProducts = useAllPublishedAndRoutedProductsByFactoryLine();
  const {survey} = useCatalogSession();
  const {selectFactoryById} = useCatalogSessionDispatcher();

  const catalogQuizAnswers: CatalogQuizAnswers | undefined =
    survey[SurveyType.ADU_QUIZ];

  const quizComplete: boolean = useMemo(() => {
    return (
      !!catalogQuizAnswers?.factoryLineTag &&
      catalogQuizAnswers?.submitted === true
    );
  }, [catalogQuizAnswers?.factoryLineTag, catalogQuizAnswers?.submitted]);

  const quizFactoryId: string | null = useMemo(() => {
    if (!quizComplete) {
      // incomplete quiz
      return null;
    }
    let toReturn: string | null = null;
    if (Object.values(catalogProducts)?.length > 0) {
      if (!!catalogQuizAnswers?.factoryLineTag) {
        if (
          // If special ReadyBuilt case
          catalogQuizAnswers.factoryLineTag === ICatalogTag.QUICKEST_DELIVERY
        ) {
          toReturn = ICatalogTag.QUICKEST_DELIVERY;
        } else {
          // If factory line match via tag
          toReturn =
            Object.values(catalogProducts)?.find((detailsAndProducts) => {
              return ProductGroup.containsTag(
                detailsAndProducts.productGroupDetails,
                catalogQuizAnswers.factoryLineTag
              );
            })?.productGroupDetails?.id ?? null;
        }
      }
      if (!toReturn) {
        // If no factory line match, return first factory line id
        toReturn = Object.values(catalogProducts)[0]?.productGroupDetails?.id;
      }
    }
    return toReturn;
  }, [catalogProducts, catalogQuizAnswers?.factoryLineTag, quizComplete]);
  const {products: quizFactoryLineProducts, details: quizFactoryLineInfo} =
    useFactoryLine({
      factoryId:
        quizFactoryId && quizFactoryId !== ICatalogTag.QUICKEST_DELIVERY
          ? quizFactoryId
          : undefined,
    });
  const filteredQuizFactoryLineProducts: IProduct[] | null = useMemo(() => {
    let productArray: IProduct[] = [];
    if (quizFactoryId === ICatalogTag.QUICKEST_DELIVERY) {
      Object.values(catalogProducts)?.forEach((factoryAndProducts) => {
        productArray = productArray.concat(
          factoryAndProducts?.products?.filter((product) => {
            return Product.isInventory(product);
          })
        );
      });
    } else if (quizFactoryId && quizFactoryLineProducts) {
      productArray = Object.values(quizFactoryLineProducts);
    }
    return productArray.filter((product) => {
      return product?.status === ListingStatusDetails.LISTED;
    });
  }, [catalogProducts, quizFactoryId, quizFactoryLineProducts]);

  const quizRecommendedProducts: IProduct[] | null = useMemo(() => {
    let toReturn: IProduct[] = [];
    // Recommended products never include products from the matching factory line/series
    Object.values(catalogProducts).forEach((factoryAndProducts) => {
      let excludeReadyBuilt = false;
      if (
        catalogQuizAnswers?.factoryLineTag === ICatalogTag.QUICKEST_DELIVERY
      ) {
        excludeReadyBuilt = true;
      }
      factoryAndProducts?.products
        ?.filter((product) => {
          const notExistingDisplayedProduct =
            !filteredQuizFactoryLineProducts?.includes(product);
          const factoryLineTag = catalogQuizAnswers?.factoryLineTag;
          const productGroupDetails = factoryAndProducts?.productGroupDetails;
          let notFactoryLineTag =
            !!factoryLineTag &&
            !!productGroupDetails &&
            !ProductGroup.containsTag(productGroupDetails, factoryLineTag);
          if (excludeReadyBuilt) {
            notFactoryLineTag = !Product.isInventory(product);
          }
          return notExistingDisplayedProduct && notFactoryLineTag;
        })
        ?.forEach((product) => {
          toReturn.push(product);
        });
    });
    return toReturn.slice(0, MAX_RECOMMENDED_PRODUCTS);
  }, [
    catalogProducts,
    catalogQuizAnswers?.factoryLineTag,
    filteredQuizFactoryLineProducts,
  ]);
  const quizDisabled: boolean = useMemo(() => {
    return isAdmin && !currentConfigurationId;
  }, [currentConfigurationId, isAdmin]);
  const quizResults: IQuizResults | null = useMemo(() => {
    if (quizDisabled) {
      return null;
    }
    let title: string | undefined;
    let clickEvent: (() => void) | undefined;
    let preference: string | undefined = factoryLineTags
      ?.find((valueAndLabel) => {
        return catalogQuizAnswers?.factoryLineTag === valueAndLabel?.value;
      })
      ?.label?.toLowerCase();
    if (quizFactoryId === ICatalogTag.QUICKEST_DELIVERY) {
      title = `a ${INVENTORY_UNIT_PREFIX} home`;
      clickEvent = () => {
        to(NavigationPaths.CATALOG_INVENTORY);
      };
    } else if (quizFactoryLineInfo?.title) {
      title = quizFactoryLineInfo.title;
      clickEvent = () => {
        selectFactoryById(quizFactoryLineInfo?.id);
      };
    }
    const element =
      clickEvent && preference
        ? QuizContextTitle({
            title,
            clickEvent,
            preference,
          })
        : null;
    if (
      element &&
      quizComplete &&
      quizFactoryLineInfo &&
      filteredQuizFactoryLineProducts &&
      quizRecommendedProducts
    ) {
      return {
        contextTitle: element,
        seriesProducts: filteredQuizFactoryLineProducts,
        mixedProducts: quizRecommendedProducts,
      };
    }
    return null;
  }, [
    catalogQuizAnswers?.factoryLineTag,
    filteredQuizFactoryLineProducts,
    quizComplete,
    quizDisabled,
    quizFactoryId,
    quizFactoryLineInfo,
    quizRecommendedProducts,
    selectFactoryById,
    to,
  ]);
  return {
    quiz: quizResults,
    quizDisabled: quizDisabled,
    general: {
      factoryLines: Object.values(catalogProducts).reduce(
        (acc, detailsAndProducts) => {
          let productGroupDetails: IProductGroupDetails =
            detailsAndProducts.productGroupDetails;
          const nonInventoryProducts = detailsAndProducts?.products?.filter(
            (product) => {
              return !Product.isInventory(product);
            }
          );
          if (nonInventoryProducts?.length > 0) {
            acc.push(productGroupDetails);
          }
          return acc;
        },
        [] as IProductGroupDetails[]
      ),
    },
  };
};
