import React, {useMemo, useState} from "react";
import {NatButton} from "../../../_shared/generics/button";
import {executeRequest} from "../../../../database/firebase/api";
import {DatePickerInput} from "../../ProductLineView/components/DatePickerInput";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../_shared/generics/label/NatLabel";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";
import {
  NatSizeType,
  NatVerticalAlign,
  StyleOption,
} from "../../../_shared/generics/_shared";
import {NatDropdown} from "../../../_shared/generics/popup/dropdown/NatDropdown";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../../_shared/generics/popup/dropdown/constants";

export const NatomasDataExport = (props: any) => {
  return (
    <NatFlex style={{padding: "2rem 2rem"}} FULL_WIDTH COLUMN>
      <NatLabel label={"Property Data CSV"} type={NatLabelType.H2} />
      <p>
        This CSV exporter tool generates a CSV file of all property addresses
        searched within our app. It requires a date range and a record limit
        parameter.
      </p>
      <PropertyDataExport />
    </NatFlex>
  );
};
export const PropertyDataExport = (props: any) => {
  const [date1, setDate1] = useState<Date>(
    new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 31)
  );
  const [date2, setDate2] = useState<Date>(new Date());
  const [resultLimit, setResultLimit] = useState<number>(50000);

  const handleDownload = async (date1: Date, date2: Date, limit: number) => {
    try {
      const response = await executeRequest(
        "/parcel/v1/property",
        {
          date1: date1.valueOf(),
          date2: date2.valueOf(),
          limit: limit,
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response], {type: "text/csv"});
      console.log(blob);

      // Create a Blob URL
      const url = window.URL.createObjectURL(blob);

      // Create a download link and click it
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "data.csv";
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Download failed:", error);
    }
  };
  const limitSelectorValues = useMemo(() => {
    return [100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000].map((val) => {
      return {
        id: val.toString(),
        label: val.toString(),
        callback: () => {
          setResultLimit(val);
        },
      };
    });
  }, []);
  return (
    <NatFlex
      FULL_WIDTH
      VERTICAL_ALIGN={NatVerticalAlign.CENTER}
      style={{padding: "1rem 1rem"}}
    >
      <DatePickerInput
        type={NatLabelType.P3}
        value={date1}
        fieldInfo={{
          key: "start-date",
          name: "Start",
          type: "date-picker",
          showTime: false,
          startTime: new Date(0),
        }}
        initialValue={date1}
        onChange={(newDate) => {
          return setDate1(newDate);
        }}
      />
      <DatePickerInput
        type={NatLabelType.P3}
        value={date2}
        fieldInfo={{
          key: "end-date",
          name: "End",
          type: "date-picker",
          showTime: false,
          startTime: new Date(0),
        }}
        initialValue={date1}
        onChange={(newDate) => {
          console.log(newDate);
          return setDate2(newDate);
        }}
      />
      <NatFlex COLUMN NOGAP>
        <NatLabel
          type={NatLabelType.P3}
          label={"Limit Record Count"}
          weight={NatLabelWeight.MEDIUM}
        />
        <NatDropdown
          style={NatDropdownStyles[DropdownStyles.PRIMARY][NatSizeType.SMALL]}
          selectedValue={{
            id: resultLimit.toString(),
            label: resultLimit.toString(),
          }}
          contentItemArray={limitSelectorValues}
          toggleButtonOrLabel={resultLimit.toString()}
          trackingId={`result-limit-selector`}
        />
      </NatFlex>
      <NatButton
        option={StyleOption.PRIMARY_ALT}
        clickEvent={() => {
          return handleDownload(date1, date2, resultLimit);
        }}
        style={{marginLeft: "2rem"}}
        label={"Generate & Download CSV"}
        spinnerEnabled={true}
      />
    </NatFlex>
  );
};
