import {InputProps} from "../../interface";
// Local styles
import {AdditionalContent, Container, Input, Label} from "./style";
import React from "react";
import {DefaultError} from "../../generic/DefaultError";

const Checkbox = (props: InputProps) => {
  const {id, label, error, setError, additionalContent, onChange, ...input} =
    props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (input.required && !e.target.checked) {
      setError?.("Required");
    } else if (error) {
      setError?.(undefined);
    }
    onChange?.(e);
  };

  return (
    <Container key={`${id}-container`}>
      <Input
        {...input}
        onChange={handleChange}
        id={id}
        key={id}
        name={id}
        data-private
      />
      <AdditionalContent>
        <Label htmlFor={id} id={`${id}-label`} hidden={!label}>
          {label}
        </Label>
        {additionalContent}
        <DefaultError id={id} error={error} />
      </AdditionalContent>
    </Container>
  );
};

export default Checkbox;
