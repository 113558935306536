import styled from "styled-components";
import {
  DIVIDER_COLOR,
  WHITE,
} from "../../../../../../../../../../../_shared/colors";

export const StudioEditorPopupContentContainer = styled.div`
  min-height: 300px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: ${WHITE};
`;

export const StudioEditorPopupControllerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
  background-color: ${DIVIDER_COLOR};
  border-top: 1px solid #9c9c9c;
  padding: 8px;
`;
