import React from "react";
import {NatButton} from "../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../_shared/generics/_shared";
import {NatLeftArrowIcon, NatRightArrowIcon} from "../../../_shared/icon/icons";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";

const TOTAL_PAGE_TABS_SHOWN = 4;

export const ProjectNoteStepper = (props: IProjectNoteStepperProps) => {
  const {currentPage, totalPages, onChange} = props;
  const nonZeroIndexedCurrentPage = currentPage + 1;
  if (totalPages < 2) {
    return null;
  }
  const pageNumbers = getPageIndices(nonZeroIndexedCurrentPage, totalPages);

  return (
    <NatFlex style={{gap: "0.5rem"}}>
      <NatButton
        option={StyleOption.ICON_ONLY}
        size={NatSize.SMALL}
        icon={{icon: <NatLeftArrowIcon />, iconPosition: IconPosition.ONLY}}
        label={""}
        clickEvent={() => onChange(currentPage - 1)}
        disabled={currentPage === 0}
      />
      {pageNumbers.map((pageNumber, index) => {
        if (pageNumber === -1) {
          return (
            <NatButton
              key={pageNumber + "_" + index}
              option={StyleOption.ICON_ONLY}
              size={NatSize.SMALL}
              label={`...`}
              disabled={true}
              clickEvent={() => {}}
            />
          );
        }

        return (
          <NatButton
            key={pageNumber + "_" + index}
            option={
              nonZeroIndexedCurrentPage === pageNumber
                ? StyleOption.ICON_ONLY_DECORATED_SELECTED
                : StyleOption.ICON_ONLY_DECORATED_NOT_SELECTED
            }
            size={NatSize.SMALL}
            label={`${pageNumber}`}
            clickEvent={() => onChange(pageNumber - 1)}
          />
        );
      })}
      <NatButton
        option={StyleOption.ICON_ONLY}
        size={NatSize.SMALL}
        icon={{icon: <NatRightArrowIcon />, iconPosition: IconPosition.ONLY}}
        label={""}
        clickEvent={() => onChange(currentPage + 1)}
        disabled={nonZeroIndexedCurrentPage === totalPages}
      />
    </NatFlex>
  );
};

function getPageIndices(currentIndex: number, totalPages: number) {
  let result = [];

  // We want to keep the number of buttons in the page stepper consistent.
  // If the current page is close to the beginning or end of the total pages,
  // we need to add extra buttons to the other side to keep the total number
  // of buttons consistent.
  const extraBefore = Math.max(
    TOTAL_PAGE_TABS_SHOWN / 2 - (totalPages - currentIndex - 2),
    0
  );
  const startRange = currentIndex - TOTAL_PAGE_TABS_SHOWN / 2 - extraBefore;

  const extraAfter = Math.max(TOTAL_PAGE_TABS_SHOWN / 2 - startRange + 1, 0);
  const endRange = currentIndex + TOTAL_PAGE_TABS_SHOWN / 2 + extraAfter;

  // Add numbers before currentIndex
  for (let i = startRange; i < currentIndex; i++) {
    if (i < 1) continue;
    result.push(i);
  }

  // Add currentIndex
  result.push(currentIndex);

  // Add numbers after currentIndex
  for (let i = currentIndex + 1; i <= endRange && i <= totalPages; i++) {
    result.push(i);
  }

  // add ellipses if needed
  if (!result.includes(2)) {
    result.unshift(-1);
  }
  if (!result.includes(totalPages - 1)) {
    result.push(-1);
  }

  // add first and last page if needed
  if (!result.includes(1)) {
    result.unshift(1);
  }
  if (!result.includes(totalPages)) {
    result.push(totalPages);
  }

  return result;
}

interface IProjectNoteStepperProps {
  currentPage: number;
  totalPages: number;
  onChange: (pageNumber: number) => void;
}
