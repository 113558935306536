import React, {useCallback, useEffect, useState} from "react";
import {IFactoryInventoryStudioEditorCategory} from "../../CategorySection/interface";
import {
  FactoryInventoryInput,
  FactoryInventoryInputType,
} from "../../../../../_shared/FactoryInventoryInput";
import {NatButton} from "../../../../../../../../../../../../_shared/generics/button";
import {
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../../_shared/generics/_shared";
import {DefaultFieldEditorProps} from "../FieldEditor";
import {StudioEditorPopupControllerContainer} from "../styles";

interface CategoryFieldEditorProps extends DefaultFieldEditorProps {
  initialValue?: IFactoryInventoryStudioEditorCategory;
  currentValue?: IFactoryInventoryStudioEditorCategory;
}

const CategoryFieldEditor = (props: CategoryFieldEditorProps) => {
  const {initialValue, currentValue, reset, setValue} = props;
  const {id} = currentValue ?? {};
  const [edits, setEdits] = useState(currentValue);

  useEffect(() => {
    setEdits(currentValue);
  }, [currentValue]);

  const updateCategory = useCallback(() => {
    if (id) {
      setValue(edits);
      reset();
    }
  }, [edits, id, reset, setValue]);

  const updateEdits = useCallback(
    (fieldKey: string, value: any) => {
      setEdits((current: any) => {
        return {
          ...current,
          [fieldKey]: value,
        };
      });
    },
    [setEdits]
  );
  return (
    <>
      <FactoryInventoryInput
        id={`${id}-title`}
        key={`${id}-title`}
        label={"Title"}
        type={FactoryInventoryInputType.TEXT}
        value={edits?.title}
        initialValue={initialValue?.title}
        onChange={(value: any) => {
          updateEdits("title", value);
        }}
        resetChange={() =>
          // @ts-ignore
          setEdits((current) => {
            return {
              ...current,
              title: initialValue?.title,
            };
          })
        }
      />
      <FactoryInventoryInput
        id={`${id}-description`}
        key={`${id}-description`}
        label={"Description"}
        type={FactoryInventoryInputType.TEXT_BOX}
        value={edits?.description}
        initialValue={initialValue?.description}
        onChange={(value: any) => {
          updateEdits("description", value);
        }}
        resetChange={() =>
          // @ts-ignore
          setEdits((current) => {
            return {
              ...current,
              description: initialValue?.description,
            };
          })
        }
      />
      <StudioEditorPopupControllerContainer>
        <NatButton
          label={"Save Changes"}
          clickEvent={updateCategory}
          size={NatSize.SMALL}
          option={StyleOption.ADMIN_SOLID}
        />
      </StudioEditorPopupControllerContainer>
    </>
  );
};

export default CategoryFieldEditor;
