import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const StudioSummaryUnit = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  padding: 1.25rem;
`;

export const StudioSummaryUnitTitle = styled.h4`
  padding: 0 0 1rem 0;
  margin: 0;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const StudioSummaryUnitLine = styled.p`
  font-family: ${FONT_FAMILY_REGULAR};
  padding: 0 0 1rem 0;
  margin: 0;
`;
