import styled from "styled-components";
import {GRANITE} from "../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../SSDDashboardView/styles/globals";

export const ProjectCostContainer = styled.div<{
  isNatMobile: boolean;
  removeBorder?: boolean;
}>`
  background-color: white;
  margin-top: ${(props) => (props.removeBorder ? undefined : "20px;")};
  max-width: 100%;
  overflow-x: auto;
  border: ${(props) =>
    props.removeBorder ? undefined : "1px solid rgba(0, 0, 0, 0.3)"};
  border-radius: 1rem;
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.isNatMobile ? "row" : undefined)};
  align-items: ${(props) => (props.removeBorder ? "flex-start" : "center")};
  ${(props) => (props.isNatMobile ? "flex-wrap: wrap;" : undefined)};
  ${(props) => (props.isNatMobile ? "height: max-content;" : "height : 160px")};
  ${(props) => (props.isNatMobile ? undefined : "width : 100%")};
  ${(props) => (props.isNatMobile ? "padding: 1rem 0rem;" : undefined)};
  ${(props) => (props.isNatMobile ? "padding: 1rem 0rem;" : undefined)};
  padding-right: 1rem;
  margin: ${(props) => (props.removeBorder ? "0 0" : undefined)};
  flex-direction: ${(props) => (props.isNatMobile ? "row" : undefined)};
  height: ${(props) => (props.isNatMobile ? "14rem" : undefined)};
  justify-content: ${(props) => (props.isNatMobile ? "flex-start" : undefined)};
`;

export const ProjectTotalCostContainer = styled.div<{
  isNatMobile: boolean;
  removeMarginAndBorder?: boolean;
}>`
  position: relative;
  ${(props) =>
    props.isNatMobile ? "padding-left: 20px;" : "padding-left: 60px;"};
  ${(props) => (props.isNatMobile ? "text-align: left;" : undefined)};
  ${(props) => (props.isNatMobile ? undefined : "width: 33%")};
  line-height: 1.9;
  min-width: fit-content;
  padding-right: 1rem;
  margin: ${(props) => (props.removeMarginAndBorder ? "0 0" : undefined)};
  height: ${(props) =>
    props.removeMarginAndBorder ? "min-content" : undefined};
  padding-left: ${(props) => (props.isNatMobile ? "20px" : undefined)};
`;

export const ProjectCostBreakdownContainer = styled.div<{isNatMobile: boolean}>`
  position: relative;
  flex-grow: 2;
  ${(props) =>
    props.isNatMobile
      ? undefined
      : "border-left: rgba(0, 0, 0, 0.3) solid 1px;"};
  ${(props) =>
    props.isNatMobile ? "padding-left: 20px;" : "padding-left: 50px;"};
`;

export const UnitSiteBreakdownContainer = styled.div<{isNatMobile: boolean}>`
  position: relative;
  display: flex;
  margin-top: 8px;
  column-gap: 2rem;
  row-gap: ${(props) => (props.isNatMobile ? "0.5rem" : undefined)};
  ${(props) => (props.isNatMobile ? "flex-direction: row" : undefined)};
  ${(props) => (props.isNatMobile ? "flex-wrap: wrap;" : undefined)};
=
`;

export const BreakdownContainer = styled.div<{isNatMobile: boolean}>`
  flex-grow: 2;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  ${(props) => (props.isNatMobile ? "height: 3.5rem;" : "height: 5.5rem")};
`;

export const BreakdownTitle = styled.div<{isNatMobile: boolean}>`
  color: ${GRANITE};
  font-size: 14px;
  ${(props) => (props.isNatMobile ? undefined : "width: max-content")};
`;

export const BreakdownValue = styled.div<{displayLarge?: boolean}>`
  font-size: ${(props) => (props.displayLarge ? "32px" : "22px")};
  font-family: ${(props) =>
    props.displayLarge ? FONT_FAMILY_BOLD : FONT_FAMILY_MEDIUM};
  height: 3rem;
  justify-content: ${(props) =>
    props.displayLarge ? "flex-start" : "flex-end"};
  display: flex;
  flex-direction: column;
`;
export const ToggleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
`;

export const CostTabToggleContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-width: fit-content;
  overflow-x: auto;
`;

export const CostTabToggle = styled.div<{active: boolean}>`
  line-height: 30px;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  min-width: fit-content;
  color: ${(props) => (props.active ? "rgba(130, 194, 117, 1)" : "none")};
  border-bottom: ${(props) => (props.active ? `#82C275 solid 2px` : "none")};
  font-family: ${(props) =>
    props.active ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  cursor: pointer;
`;

export const BreakdownPageContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 60px;
`;
export const Tab = styled.div<{active: boolean}>`
  height: 100%;
  margin-right: 40px;
  min-width: fit-content;
  flex-shrink: 0;
  border-bottom: ${(props) => (props.active ? "3px solid #82c275" : "none")};
  font-family: ${(props) =>
    props.active ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  position: relative;
  cursor: pointer;
`;

export const Container = styled.div`
  height: calc(100% - 60px);
  width: 100%;
  padding-left: 0;
  overflow: auto;
  position: relative;
`;

export const Title = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 6px;
`;

export const ProjectCostCardWrapper = styled.div<{removeBorder?: boolean}>`
  border-radius: 1rem;
  padding-bottom: ${(props) => (props.removeBorder ? undefined : "2rem")};
  width: ${(props) => (props.removeBorder ? undefined : "100%")};
`;
export const NexusProjectCostCardWrapper = styled.div`
  border-radius: 1rem;
  padding-top: 1rem;
`;
