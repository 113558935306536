import styled from "styled-components";
import {WHITE} from "../../../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {HEADER_Z_INDEX} from "../../../../../../../../_shared/styles";

export const InventoryPricingTableElement = styled.table`
  border-spacing: 0;
  border: 1px solid black;
  border-collapse: separate; /* Don't collapse */
  thead {
    position: sticky;
    top: 52px;
    z-index: ${HEADER_Z_INDEX};
  }
  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    font-size: 12px;
    :last-child {
      border-right: 0;
    }
  }
`;
export const InventoryPricingEditorContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
`;

export const InventoryPricingEditorColumnSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 16px;
`;

export const InventoryPricingInfoContainer = styled.div`
  background-color: ${WHITE};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-width: 300px;
`;

export const InventoryPricingInfoRowContainer = styled.div<{bold?: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: ${(props) => props?.bold && FONT_FAMILY_MEDIUM};
`;
