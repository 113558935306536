import {IProduct} from "@natomas-org/core";
import {
  FactoryInventoryInformationEditorKey,
  IFactoryInventoryInformationEditor,
} from "./interfaces";

export const flattenProductToInformationEditor = (
  p?: IProduct
): IFactoryInventoryInformationEditor => {
  return {
    [FactoryInventoryInformationEditorKey.INTERNAL_TITLE]:
      p?.internal_title ?? "",
    [FactoryInventoryInformationEditorKey.INTERNAL_NOTES]:
      p?.inventory_info?.internalNotes ?? "",
    [FactoryInventoryInformationEditorKey.LISTING_STATUS]: p?.status,
    [FactoryInventoryInformationEditorKey.SERIAL_NUMBER]:
      p?.inventory_info?.serialNumber ?? "",
    [FactoryInventoryInformationEditorKey.STORAGE_DATE_TIME]:
      p?.inventory_info?.storageDateTime,
    [FactoryInventoryInformationEditorKey.PRODUCTION_DATE_TIME]:
      p?.inventory_info?.productionDateTime,
    [FactoryInventoryInformationEditorKey.BLACKLINES_URL]:
      p?.inventory_info?.blacklinesUrl,
    [FactoryInventoryInformationEditorKey.CUSTOMER_NAME]:
      p?.inventory_info?.sold?.customerName,
    [FactoryInventoryInformationEditorKey.CUSTOMER_PORTAL_LINK]:
      p?.inventory_info?.sold?.customerPortalLink,
    [FactoryInventoryInformationEditorKey.EMPLOYEE_NAME]:
      p?.inventory_info?.sold?.employeeName,
    [FactoryInventoryInformationEditorKey.SOLD_DATE_TIME]:
      p?.inventory_info?.sold?.soldDateTime,
  };
};
