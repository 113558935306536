import React, {useState} from "react";
import {useProject} from "../../../../../_shared/hooks/useProject/useProject";
import {useCartItems} from "../../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import {
  CartProjectSectionChevron,
  CartProjectSectionCollapsibleContainer,
  CartProjectSectionHeader,
} from "./styles";
import {Address} from "@natomas-org/core";
import CartSection from "../CartSection/CartSection";
import {NatRightCaratIcon} from "../../../../../_shared/icon/icons";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {CartActionsAvailable, CartSectionSortBy} from "../../interfaces";

interface CartProjectSectionProps {
  actionsAvailable: CartActionsAvailable;
  projectId: string;
}

const CartProjectSection = (props: CartProjectSectionProps) => {
  const {isNatMobile} = usePage();
  const {actionsAvailable, projectId} = props;
  const {cartItemIds, address} = useProject(projectId);
  const cartItems = useCartItems(cartItemIds);
  const [isOpen, setIsOpen] = useState(false);

  const toggleContainer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CartProjectSectionCollapsibleContainer isOpen={isOpen}>
      <CartProjectSectionHeader onClick={toggleContainer}>
        <CartProjectSectionChevron isRotated={isOpen}>
          <NatRightCaratIcon size={16} />
        </CartProjectSectionChevron>
        {Address.getFullAddress(address ?? undefined)}
      </CartProjectSectionHeader>
      {isOpen && (
        <CartSection
          isMobile={isNatMobile}
          sortBy={CartSectionSortBy.LIST}
          actionsAvailable={actionsAvailable}
          cartItems={cartItems}
        />
      )}
    </CartProjectSectionCollapsibleContainer>
  );
};

export default CartProjectSection;
