import React, {useMemo} from "react";
import {IHCPViewProps} from "../interfaces";
import {ProposalBudgetSummaryView} from "../../ProposalBudgetView/ProposalBudgetSummaryView";
import {PORTAL_BUDGET_ID} from "../HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {ProjectPriceMode} from "@natomas-org/core";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import CustomerEstimateTable from "../../../../estimate/CustomerEstimate/CustomerEstimateTable";

export const HCPBudget = (props: IHCPViewProps) => {
  const {width} = props;
  const {site, budgetHidden, projectPriceMode} = useActiveProject();

  const content = useMemo(() => {
    if (projectPriceMode === ProjectPriceMode.DTC) {
      if (budgetHidden) {
        return null;
      } else {
        return <CustomerEstimateTable />;
      }
    }

    // Return legacy budget view
    return (
      <ProposalBudgetSummaryView
        hideCostCard={projectPriceMode === ProjectPriceMode.CostPlus}
        hideCostSummary={!site?.visible}
        hideCostCardDescription={!site?.visible}
        presentationView={true}
      />
    );
  }, [budgetHidden, projectPriceMode, site?.visible]);

  if (!site?.visible || !content) {
    return null;
  }
  return (
    <PortalSection
      headerTitle={"Project Budget"}
      width={width}
      sectionId={PORTAL_BUDGET_ID}
    >
      {content}
    </PortalSection>
  );
};
