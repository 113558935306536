import React from "react";
import styled from "styled-components";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../_shared/generics/label/NatLabel";

export const ModelHomeCard = (props: IModelHomeCardProps) => {
  const {title, subtitle, imageURL, clickEvent} = props;
  return (
    <MainWrapper onClick={clickEvent} clickable={clickEvent != null}>
      <ImageWrapper style={{backgroundImage: `url(${imageURL})`}} />
      <div
        style={{
          zIndex: 15,
          position: "relative",
          color: "white",
          textAlign: "center",
          flexGrow: 1,
        }}
      >
        <NatLabel
          label={title}
          type={NatLabelType.H3}
          weight={NatLabelWeight.BOLD}
        />
        <NatLabel label={subtitle} type={NatLabelType.P2} />
      </div>
    </MainWrapper>
  );
};

const MainWrapper = styled.div<{clickable: boolean}>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

const ImageWrapper = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
  filter: brightness(60%);
  background-size: cover;
  background-position: center;
`;

interface IModelHomeCardProps {
  title: string;
  subtitle: string;
  imageURL: string;
  clickEvent?: () => void;
}
