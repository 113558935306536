import React from "react";
import {AccountLoginEditor} from "./AccountLoginEditor";
import {
  CustomerAccountManagementContainer,
  CustomerAccountManagementTitle,
} from "./styles";

export const CustomerAccountView = () => {
  return (
    <CustomerAccountManagementContainer>
      <CustomerAccountManagementTitle>
        Customer Account Management
      </CustomerAccountManagementTitle>
      <AccountLoginEditor />
    </CustomerAccountManagementContainer>
  );
};
