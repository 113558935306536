import {useCallback} from "react";
import LogRocket from "logrocket";

export interface IRequestHandlerResult {
  response: any;
  error: any;
  tryNumber: number;
}

export interface IRequestHandler {
  fetch: (...args: any[]) => Promise<IRequestHandlerResult>;
}
export interface IRequestHandlerProps {
  method: (...args: any[]) => Promise<any>;
  maxTry?: number;
}
export const useRequestHandler = (
  props: IRequestHandlerProps
): IRequestHandler => {
  const {method, maxTry} = props;
  const _fetch = useCallback(
    async (args: any[], currentTry: number): Promise<IRequestHandlerResult> => {
      let result: IRequestHandlerResult;
      // Will update state after fetch resolves
      try {
        // Update try count and attempt fetch
        let response;
        if (args.length > 0) {
          response = await method(...args);
        } else {
          response = await method();
        }
        result = {
          response,
          error: null,
          tryNumber: currentTry,
        };
      } catch (error) {
        result = {
          response: null,
          error,
          tryNumber: currentTry,
        };
        LogRocket.error("useRequestHandler.fetch", error);
      }
      return result;
    },
    [method]
  );

  const fetch = useCallback(
    async (...args: any[]): Promise<IRequestHandlerResult> => {
      let maxTryCount = maxTry || 1;
      let currentTry = 0;
      let result: IRequestHandlerResult = {
        response: null,
        error: null,
        tryNumber: 0,
      };
      let shouldTry = true;
      while (shouldTry) {
        currentTry++;
        shouldTry = result?.error && currentTry <= maxTryCount;
        console.log(
          "useRequestHandler.fetch",
          `currentTry: ${currentTry}`,
          `maxTryCount: ${maxTryCount}`
        );
        result = await _fetch(args, currentTry);
      }
      return result;
    },
    [_fetch, maxTry]
  );

  return {
    fetch,
  };
};
