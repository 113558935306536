import React, {useMemo, useState} from "react";
import {useProjectDocuments} from "../../../hooks/useProject/useProjectDocuments";
import {
  ALL_PDF_DOCUMENT_INFO,
  BLACKLINES_DOCUMENT_ID,
  IDocumentDetails,
  IMimeType,
  SITE_PLAN_DOCUMENT_ID,
  SPECIALIZED_PDF_DOCUMENT_INFO,
} from "@natomas-org/core";
import {useModal} from "../../modal/useModal";
import {ProjectDocumentUploadForm} from "./ProjectDocumentUploadForm";
import {ProjectDocumentUploadButton} from "./ProjectDocumentUploadButton";
import {DocumentDetailsPreview} from "./DocumentDetailsPreview";
import {NatModalSize} from "../../modal/NatModal";

export const DocumentList = (props: {projectId: string | undefined}) => {
  const {projectId} = props;
  const {projectDocuments} = useProjectDocuments(projectId);
  const [editingDocumentId, setEditingDocumentId] = useState<
    string | undefined
  >(undefined);

  const currentDocuments: IDocumentDetails[] = useMemo(() => {
    const filesToOmit = [
      ...ALL_PDF_DOCUMENT_INFO,
      ...SPECIALIZED_PDF_DOCUMENT_INFO,
    ];

    const filteredDocuments = projectDocuments?.filter((file) => {
      return (
        !!file?.document_id &&
        !filesToOmit.find(
          (fileToOmit) =>
            fileToOmit?.document_id &&
            fileToOmit?.document_id === file?.document_id
        )
      );
    });
    return filteredDocuments?.map((doc) => {
      let allowedFileTypes: IMimeType[] | undefined;
      if (
        doc?.document_id &&
        [SITE_PLAN_DOCUMENT_ID, BLACKLINES_DOCUMENT_ID].includes(
          doc?.document_id
        )
      ) {
        allowedFileTypes = [IMimeType.PDF];
      }
      return {
        ...doc,
        allowedFileTypes,
      };
    });
  }, [projectDocuments]);

  const documentInfo = useMemo(() => {
    if (!editingDocumentId) {
      return undefined;
    }
    return projectDocuments?.find((doc) => {
      return doc?.document_id === editingDocumentId;
    });
  }, [editingDocumentId, projectDocuments]);

  const {Modal, openModal} = useModal({
    content: (
      <ProjectDocumentUploadForm
        projectId={projectId ?? ""}
        documentInfo={documentInfo}
        onClose={() => {}}
      />
    ),
    size: NatModalSize.LARGE,
  });

  if (!projectId) {
    return null;
  }
  return (
    <>
      {Modal}
      <ProjectDocumentUploadButton
        documentTitle={undefined}
        documentId={undefined}
        projectId={projectId}
        allowMultiple={true}
        onComplete={() => {}}
        disabled={false}
      />
      {currentDocuments?.map((document, index) => {
        return (
          <DocumentDetailsPreview
            onHide={() => {}}
            document={document}
            documents={currentDocuments}
            key={`${document?.document_id}${index}`}
            projectId={projectId}
            onDelete={() => {}}
            onEdit={() => {
              setEditingDocumentId(document?.document_id);
              openModal();
            }}
          />
        );
      })}
    </>
  );
};
