import {IDesign} from "@natomas-org/core";
import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useMemo} from "react";

export const useDesigns = (ids?: string[]): {[key: string]: IDesign} => {
  // TODO move memo logic inside selector
  // Move to a new file
  const designs = useSelector((state: IStore) => {
    return state.design;
  });
  return useMemo(() => {
    let toReturn: {[key: string]: IDesign} = {};
    if (ids) {
      ids.forEach((id) => {
        if (designs[id]) {
          toReturn[id] = designs[id];
        }
      });
    }
    return toReturn;
  }, [designs, ids]);
};
