import React, {useState} from "react";
import {IProjectNoteDetails, ProjectNoteType} from "@natomas-org/core";
import {ProjectNoteForm} from "./ProjectNoteForm";
import {NatButton} from "../../../../../../_shared/generics/button";
import {GoPlus} from "react-icons/go";
import {
  IconPosition,
  NatJustifyContent,
  NatSize,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {ProjectNotesTabContainer, ProjectNotesTabTitle} from "./styles";
import {ProjectNotesList} from "../../../../../../portal/views/ProjectNotes/ProjectNotesList";
import {useActiveProjectNotes} from "../../../../../../_shared/hooks/useProject/useActiveProjectNotes";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {useProjectTasksList} from "../../../../../../portal/views/ProjectTasks/ProjectTasksList";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";

export const NotesTab = () => {
  const {id} = useActiveProject();
  const {projectNotes} = useActiveProjectNotes();

  const [showNoteForm, setShowNoteForm] = useState<boolean>(false);
  const [editingNote, setEditingNote] = useState<IProjectNoteDetails>();
  const [listType, setListType] = useState<ProjectNoteType>(
    ProjectNoteType.NOTE
  );

  const setEditingNoteById = (note: IProjectNoteDetails) => {
    setEditingNote(note);
    setShowNoteForm(true);
  };
  const {TaskList, ToggleElement} = useProjectTasksList({
    public_facing: false,
    onEditTask: (note: IProjectNoteDetails) => {
      setEditingNoteById(note);
    },
  });

  return (
    <>
      <ProjectNotesTabContainer hidden={showNoteForm}>
        <ProjectNotesTabTitle>
          Project Notes & Tasks
          <NatButton
            id={"create_new_project_note"}
            tooltip={"Create new note"}
            label={"Note"}
            type={"button"}
            size={NatSize.SMALL}
            option={StyleOption.PRIMARY_ALT}
            icon={{icon: <GoPlus />, iconPosition: IconPosition.ONLY}}
            clickEvent={() => setShowNoteForm(true)}
          />
        </ProjectNotesTabTitle>
        <NatFlex FULL_WIDTH JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}>
          <NatFlex>
            <NatButton
              label={"Notes & Updates"}
              option={
                listType === ProjectNoteType.NOTE
                  ? StyleOption.SELECTED_TOGGLE_ON
                  : StyleOption.SELECTED_TOGGLE_OFF
              }
              size={NatSize.SMALL}
              clickEvent={() => {
                setListType(ProjectNoteType.NOTE);
              }}
            />
            <NatButton
              label={"Tasks"}
              option={
                listType === ProjectNoteType.TASK
                  ? StyleOption.SELECTED_TOGGLE_ON
                  : StyleOption.SELECTED_TOGGLE_OFF
              }
              size={NatSize.SMALL}
              clickEvent={() => {
                setListType(ProjectNoteType.TASK);
              }}
            />
          </NatFlex>
          {listType === ProjectNoteType.TASK && ToggleElement}
        </NatFlex>
        {listType === ProjectNoteType.TASK ? (
          <NatFlex FULL_WIDTH COLUMN>
            {TaskList}
          </NatFlex>
        ) : (
          <ProjectNotesList
            public_facing={false}
            projectNotes={projectNotes}
            onNoteClick={(note) => {
              setEditingNoteById(note);
            }}
          />
        )}
      </ProjectNotesTabContainer>
      {showNoteForm && (
        <ProjectNotesTabContainer>
          <ProjectNoteForm
            note={editingNote}
            resetNote={() => setEditingNote(undefined)}
            show={showNoteForm}
            setShow={setShowNoteForm}
            initialNoteType={listType}
            projectId={id}
          />
        </ProjectNotesTabContainer>
      )}
    </>
  );
};
