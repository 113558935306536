import React, {useCallback} from "react";
import {IOrderFormTableCellValue, OrderFormTableCellType} from "../interfaces";
import {OrderFormTableCellCustomInput} from "./OrderFormTableCellCustomInput";
import {
  CONDITIONS_KEY,
  ID_KEY,
  VALUE_KEY,
} from "../../../../../../../../factory-info-set/shared/constants";
import {
  NatLockedIcon,
  NatWarningDiamondIcon,
} from "../../../../../../../../_shared/icon/icons";
import {
  OrderFormTableCellIconContainer,
  OrderFormTableCellInfoContainer,
  OrderFormTableCellInfoItem,
  OrderFormTableCellLabel,
  OrderFormTableCellValueContainer,
} from "./styles";
import {OrderFormTableCellSelectionInput} from "./OrderFormTableCellSelectionInput";
import ReactTooltip from "react-tooltip";

const getDerivedValue = (
  cell: IOrderFormTableCellValue,
  modifiers: any
): {label: string; info?: string[]; color?: string} => {
  const hasSatisfiedModifiers =
    cell?.satisfiedModifiers && cell?.satisfiedModifiers?.length > 0;
  const hasSatisfiedByProduct = cell?.satisfiedByProduct;

  if (hasSatisfiedModifiers) {
    const modifiersReferenced = cell?.satisfiedModifiers?.map(
      (modifierId: string) => {
        return modifiers[modifierId]?.title ?? "Loading...";
      }
    );
    const label = hasSatisfiedByProduct ? "Product + Design" : "Design";
    return {
      label: label,
      info: modifiersReferenced,
    };
  } else if (hasSatisfiedByProduct) {
    return {
      label: "Product",
    };
  }
  return {
    label: "Default",
  };
};

export const OrderFormTableCell = (tableProps: any) => {
  const {updateCellValue, modifiers, value: cell, row, column} = tableProps;
  const {original: rowValues, index} = row;
  const {id: columnKey} = column;
  const updateCell = useCallback(
    (arg: string) => {
      updateCellValue(rowValues[ID_KEY], arg);
    },
    [rowValues, updateCellValue]
  );

  if (columnKey === CONDITIONS_KEY) {
    let label;
    let tooltip: string = "";
    const id = `tooltip-${columnKey}-${index}`;
    const deriveInfo = getDerivedValue(cell, modifiers);
    switch (cell?.type) {
      case OrderFormTableCellType.CUSTOM:
        label = "Internal Custom";
        tooltip = "Configured and saved by Villa staff\n(By custom text)";
        break;
      case OrderFormTableCellType.INTERNAL:
        label = "Internal Selection";
        tooltip =
          "Configured and saved by Villa staff\n(By dropdown selection)\nIf there are no options to select, the Product Team should be notified.";
        break;
      case OrderFormTableCellType.UNDETERMINED:
        label = `Inconclusive`;
        tooltip =
          "Multiple answers were found, but requires a single selection.\nThis is an issue with the InfoSet, and the Product Team should be notified.";
        break;
      case OrderFormTableCellType.EMPTY:
        label = "Empty";
        tooltip =
          "The value may require a Studio selection, and no selections could be found.\nOpen the customer's Studio and select the appropriate selection for their product.\nIf you have, and it is still labeled as such, the Product Team should be notified.";
        break;
      case OrderFormTableCellType.UNKNOWN:
        label = "Unknown";
        tooltip =
          "No options were available, or the value was not derivable;\nthe Product Team should be notified.";
        break;
      default:
        label = deriveInfo?.label;
        tooltip = "Source of how the answer was derived.";
        break;
    }
    return (
      <OrderFormTableCellValueContainer color={deriveInfo?.color}>
        <OrderFormTableCellLabel data-tip data-for={id}>
          {label}
        </OrderFormTableCellLabel>
        <ReactTooltip
          id={id}
          className={"opaque-shadowed-tooltip"}
          effect={"solid"}
          delayShow={200}
          type={"info"}
          place={"top"}
          multiline={true}
          getContent={() => tooltip}
        />
        <OrderFormTableCellInfoContainer>
          {deriveInfo?.info?.map((s: string) => {
            return <OrderFormTableCellInfoItem>{s}</OrderFormTableCellInfoItem>;
          })}
        </OrderFormTableCellInfoContainer>
      </OrderFormTableCellValueContainer>
    );
  }

  if (columnKey === VALUE_KEY) {
    if (cell.type === OrderFormTableCellType.CUSTOM) {
      return (
        <OrderFormTableCellCustomInput cell={cell} setValue={updateCell} />
      );
    } else if (
      cell.type === OrderFormTableCellType.UNDETERMINED ||
      cell.type === OrderFormTableCellType.UNKNOWN
    ) {
      return (
        <OrderFormTableCellValueContainer>
          {"~"}
          <NatWarningDiamondIcon color={"red"} size={20} />
        </OrderFormTableCellValueContainer>
      );
    } else if (cell.type === OrderFormTableCellType.INTERNAL) {
      return (
        <OrderFormTableCellSelectionInput cell={cell} setValue={updateCell} />
      );
    } else if (cell.type === OrderFormTableCellType.EMPTY) {
      return (
        <OrderFormTableCellValueContainer>
          {"~"}
          <NatWarningDiamondIcon color={"purple"} size={20} />
        </OrderFormTableCellValueContainer>
      );
    }
    return (
      <OrderFormTableCellValueContainer>
        {cell.value}
        <OrderFormTableCellIconContainer>
          {cell.locked && <NatLockedIcon color={"grey"} size={20} />}
        </OrderFormTableCellIconContainer>
      </OrderFormTableCellValueContainer>
    );
  }

  if (typeof cell === "string") {
    return (
      <OrderFormTableCellValueContainer>
        {cell}
      </OrderFormTableCellValueContainer>
    );
  } else {
    return (
      <OrderFormTableCellValueContainer>
        {JSON.stringify(cell)}
      </OrderFormTableCellValueContainer>
    );
  }
};
