import React from "react";
import {MatrixTableHeaderRow, MatrixTableWrapper} from "../../../../styles";
import ReactDataSheet from "react-datasheet";
import {PageContainer} from "../../../../../../../../_shared/generics/page/components/PageContainer";
import {getValue} from "./helper";
import RowRendererProps = ReactDataSheet.RowRendererProps;
import CellsChangedArgs = ReactDataSheet.CellsChangedArgs;
import CellRendererProps = ReactDataSheet.CellRendererProps;

export interface GenericMatrixTableProps {
  transpose: boolean;
  pageNumber: number;
  itemsPerPage: number;
  table: any[][];
  cellRenderer: (...arg: any) => any;
  onChange: (...arg: any) => any;
  heightOffset?: string;
}

export const GenericMatrixTable = (props: GenericMatrixTableProps) => {
  const {
    heightOffset,
    table,
    itemsPerPage,
    pageNumber,
    transpose,
    cellRenderer,
    onChange,
  } = props;
  return (
    <PageContainer overridePadding={true}>
      <MatrixTableWrapper heightOffset={heightOffset}>
        <ReactDataSheet
          data={table}
          disablePageClick={true}
          valueRenderer={(cell: any) => {
            return getValue(cell);
          }}
          cellRenderer={(cellProps: CellRendererProps<any>) => {
            if (
              cellProps.col !== 0 &&
              transpose &&
              (cellProps.col <= pageNumber * itemsPerPage ||
                cellProps.col > (pageNumber + 1) * itemsPerPage)
            ) {
              return null;
            }
            return cellRenderer(cellProps);
          }}
          rowRenderer={(rowProps: RowRendererProps<any>) => {
            if (
              rowProps.row !== 0 &&
              !transpose &&
              (rowProps.row <= pageNumber * itemsPerPage ||
                rowProps.row > (pageNumber + 1) * itemsPerPage)
            ) {
              return null;
            }
            if (rowProps.row === 0) {
              return (
                <MatrixTableHeaderRow>{rowProps.children}</MatrixTableHeaderRow>
              );
            }
            return <tr>{rowProps.children}</tr>;
          }}
          onCellsChanged={(changes: CellsChangedArgs<any>) => {
            return onChange(changes);
          }}
        />
      </MatrixTableWrapper>
    </PageContainer>
  );
};
