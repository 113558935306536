import React from "react";
import {
  ImageCardContainer,
  LoadMoreImageCardContent,
} from "../ImageCard/styles";
import {useImageFinder} from "../../../../../hooks/useImageFinder";

export interface ActionCardProps {
  label: string;
  action: any;
  icon?: any;
}

export const ActionCard = (props: ActionCardProps) => {
  const {label, icon, action} = props;
  const {zoomModifier} = useImageFinder();
  return (
    <ImageCardContainer
      selected={false}
      zoomModifier={zoomModifier}
      onClick={() => action()}
    >
      <LoadMoreImageCardContent>
        {icon}
        {label}
      </LoadMoreImageCardContent>
    </ImageCardContainer>
  );
};
