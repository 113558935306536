import React, {useState} from "react";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {Button, JSONContainer, Pre} from "./styles";

interface CurrentUserDetailsProps {}

const CurrentUserDetails = (props: CurrentUserDetailsProps) => {
  const user = useCurrentUser();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const keys = Object.keys(user);

  return (
    <>
      <Button onClick={() => setShowDetails((current) => !current)}>
        {showDetails ? "Hide" : "Show"} User Details
      </Button>
      <JSONContainer hidden={!showDetails}>
        {keys.map((key, index) => {
          return (
            <Pre key={`current-user-detail-${key}`}>
              {/* @ts-ignore*/}
              {key}: {JSON.stringify(user[key], null, 2)}
            </Pre>
          );
        })}
      </JSONContainer>
    </>
  );
};

export default CurrentUserDetails;
