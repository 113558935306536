import styled from "styled-components";
import {GRAY} from "../../colors";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";
import {CSSProperties} from "react";

export const CollapsableContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const CollapsableContainerLabel = styled.h1<{expand?: boolean}>`
  // Formatting
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  gap: 8px;
  margin: 0;
  padding: 0;
  width: ${(props) => (props?.expand ? "100%" : "fit-content")};
  // Other styles
  cursor: pointer;
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 16px;
  user-select: none;
`;

export const CollapsableContainerContent = styled.div<{
  show: boolean;
}>`
  display: ${(props) => (props.show ? "default" : "none")};
  overflow: hidden;
`;

export const CollapsableContainerIcon = (show: boolean): CSSProperties => {
  return {
    color: GRAY,
    transform: show ? "rotate(90deg)" : "rotate(0deg)",
    transition: "all 0.2s ease-in-out",
  };
};
