import React, {useMemo} from "react";
import {
  InfoSetItemOptionCondition,
  InfoSetItemOptionConditionType,
} from "../../../../../shared/interfaces";
import {InfoSetItemTableRow} from "../../../../table";
import {NatButton} from "../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {NatPlusIcon} from "../../../../../../_shared/icon/icons";
import {ConditionLabel, FormattingContainer} from "./styles";
import {
  CONDITIONS_KEY,
  ID_KEY,
  LABEL_KEY,
  VALUE_KEY,
} from "../../../../../shared/constants";
import {ConditionCellRow} from "./ConditionCellRow";

export const ConditionCell = (props: {
  otherRows: InfoSetItemTableRow[];
  currentRow: InfoSetItemTableRow;
  value: InfoSetItemOptionCondition[];
  onCommitChange: any;
  categories: any;
  modifierGroups: any;
  modifierOptions: any;
  productOptions: any;
  isRowTargeted: boolean;
}) => {
  const {
    otherRows,
    currentRow,
    value,
    onCommitChange,
    categories,
    modifierGroups,
    modifierOptions,
    productOptions,
    isRowTargeted,
  } = props;

  const ConditionStatements = useMemo(() => {
    if (value.length > 0) {
      return value.map((condition: any, index: number) => (
        <>
          {index > 0 && (
            <ConditionLabel key={`${currentRow[ID_KEY]}-or-label-${index}`}>
              or
            </ConditionLabel>
          )}
          <ConditionCellRow
            save={(condition: InfoSetItemOptionCondition) => {
              let conditions = [...value];
              conditions[index] = condition;
              onCommitChange(conditions);
            }}
            remove={() => {
              let conditions = [...value];
              conditions.splice(index, 1);
              onCommitChange(conditions);
            }}
            key={`${currentRow[ID_KEY]}-${currentRow[VALUE_KEY]}-condition-row-${index}`}
            title={`${currentRow?.[LABEL_KEY]} - ${
              currentRow?.[VALUE_KEY]?.length > 0
                ? currentRow?.[VALUE_KEY]
                : "Custom"
            }`}
            condition={condition}
            categories={categories}
            modifierGroups={modifierGroups}
            modifierOptions={modifierOptions}
            productOptions={productOptions}
          />
        </>
      ));
    } else if (otherRows.length > 0) {
      // If there is another row that has empty conditions, it is now an internal input
      if (
        otherRows.find(
          (r: InfoSetItemTableRow) => r[CONDITIONS_KEY].length === 0
        )
      ) {
        return (
          <ConditionLabel key={`${currentRow[ID_KEY]}-INTERNAL`}>
            Internal
          </ConditionLabel>
        );
      }
    }
    return (
      <ConditionLabel key={`${currentRow[ID_KEY]}-DEFAULT`}>
        Default
      </ConditionLabel>
    );
  }, [
    categories,
    currentRow,
    modifierGroups,
    modifierOptions,
    onCommitChange,
    otherRows,
    productOptions,
    value,
  ]);

  return (
    <FormattingContainer key={"condition-cell-content"}>
      {ConditionStatements}
      {isRowTargeted && (
        <NatButton
          label={"New"}
          clickEvent={() => {
            onCommitChange([...value, newCondition()]);
          }}
          icon={{
            icon: <NatPlusIcon />,
            iconPosition: IconPosition.ONLY,
          }}
          option={StyleOption.SELECTED_TOGGLE_OFF}
          size={NatSize.SMALL}
        />
      )}
    </FormattingContainer>
  );
};

const newCondition = (): InfoSetItemOptionCondition => {
  return {
    type: InfoSetItemOptionConditionType.ALL,
    requiredModifiers: {},
    targets: [],
  };
};
