import {Utilities} from "../../index";
import {CONFIGURATION_COLLECTION_KEY} from "@natomas-org/core";

export const updateOrderFormSelections = async (
  id: string,
  orderFormSelections: any
) => {
  const doc = await Utilities.collection(CONFIGURATION_COLLECTION_KEY).doc(id);
  return Utilities.updateOrCreateDocumentInDB(doc, orderFormSelections);
};
