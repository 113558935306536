import {IFactoryInventoryStudioEditorCategory} from "../Editor/CategorySection/interface";
import {IFactoryInventoryStudioEditorModifierGroup} from "../Editor/ModifierGroupSection/interface";
import {IFactoryInventoryStudioEditorModifier} from "../Editor/ModifierSection/interface";
import {
  IFactoryInventoryStudioEditorCategoryImage,
  IFactoryInventoryStudioEditorCategoryImageGroup,
} from "../Editor/CategorySection/CategoryImagesEditor/interface";

export const sortValuesByIndex = (values: any[]) => {
  return values?.sort((a: any, b: any) => {
    return a?.index - b?.index;
  });
};

export const convertEditorToPreview = (values: any, pricingOverrides?: any) => {
  if (!values) {
    return [];
  }
  const categories: IFactoryInventoryStudioEditorCategory[] =
    Object.values(values);
  return sortValuesByIndex(categories)?.map(
    (category: IFactoryInventoryStudioEditorCategory) => {
      return getStudioCategory(category, pricingOverrides);
    }
  );
};

export const getAllModifiers = (studioSnapshot: any) => {
  let modifiers: any[] = [];
  studioSnapshot?.forEach((category: any) => {
    category?.selectedModifierGroups?.forEach((modifierGroup: any) => {
      modifiers.push(...modifierGroup?.fullModifiers);
    });
  });
  return modifiers;
};

const getStudioCategory = (
  editorCategory: IFactoryInventoryStudioEditorCategory,
  pricingOverrides?: any
) => {
  const {id, modifierGroups, title, description, images} = editorCategory;
  const modifierGroupsArray: IFactoryInventoryStudioEditorModifierGroup[] =
    sortValuesByIndex(Object.values(modifierGroups ?? {}));
  const imageGroupsArray: IFactoryInventoryStudioEditorCategoryImageGroup[] =
    sortValuesByIndex(Object.values(images ?? {}));
  const modifierGroupIds: string[] = modifierGroupsArray?.map(
    (mG: any) => mG?.id
  );
  const studioModifierGroups: any[] =
    sortValuesByIndex(modifierGroupsArray)?.map(
      (modifierGroup: IFactoryInventoryStudioEditorModifierGroup) => {
        return getStudioModifierGroup(modifierGroup, pricingOverrides);
      }
    ) ?? [];
  const activeImages = imageGroupsArray?.map(
    (imageGroup: IFactoryInventoryStudioEditorCategoryImageGroup) => {
      const {images} = imageGroup;
      const baseImage = images[0];
      const overlayImages = images;
      return {
        ...baseImage,
        overlayImages,
        configurationPageId: editorCategory?.id,
      };
    }
  );
  let renderings: {[id: string]: any} = {};
  imageGroupsArray?.forEach(
    (imageGroup: IFactoryInventoryStudioEditorCategoryImageGroup) => {
      imageGroup?.images?.forEach(
        (image: IFactoryInventoryStudioEditorCategoryImage) => {
          const {id} = image;
          if (id) {
            renderings[id] = {
              ...image,
              requiredModifiers: [],
              overlayGroupId: imageGroup?.id,
              overlayGroupPosition: image?.index,
            };
          }
        }
      );
    }
  );
  return {
    id: id,
    title: title,
    displayDescription: description,
    modifierGroups: modifierGroupIds,
    selectedModifierGroups: studioModifierGroups,
    fullModifierGroups: studioModifierGroups,
    activeImages: activeImages,
    renderings: renderings,
  };
};

const getStudioModifierGroup = (
  editorModifierGroup: IFactoryInventoryStudioEditorModifierGroup,
  pricingOverrides?: any
) => {
  const {id, modifiers, title, description, properties} = editorModifierGroup;
  const modifiersArray = sortValuesByIndex(Object.values(modifiers ?? {}));
  const modifierIds: string[] = modifiersArray?.map((mG: any) => mG?.id);
  const studioModifiers: any[] =
    modifiersArray?.map((modifier: IFactoryInventoryStudioEditorModifier) => {
      return getStudioModifier(
        modifier,
        pricingOverrides?.[modifier?.id]?.priceInMicros
      );
    }) ?? [];
  return {
    id: id,
    title: title,
    properties: properties,
    displayDescription: description,
    modifiers: modifierIds ?? [],
    fullModifiers: studioModifiers ?? [],
    selectedModifiers: studioModifiers ?? [],
  };
};

const getStudioModifier = (
  editorModifier: IFactoryInventoryStudioEditorModifier,
  pricingOverride: number
) => {
  const {
    id,
    imageId,
    priceInMicros,
    description,
    title,
    color,
    secondaryColor,
  } = editorModifier;
  const price = {price: pricingOverride ?? priceInMicros, cost: 0};
  return {
    adjustedPriceMicros: pricingOverride ?? priceInMicros,
    defaultPriceMicros: pricingOverride ?? priceInMicros,
    id: id,
    title: title,
    configurationButtonTitle: title,
    color: color,
    secondaryColor: secondaryColor,
    displayDescriptionDefault: description,
    imageId: imageId,
    price_set: [
      {
        type: "baseline",
        condition: "default",
        // Inventory Manager does not support cost management, workflow calls for total cost input on Pricing tab
        // This is because the total cost is known, as the order has been finalized at this point in the process.
        price: price,
      },
    ],
    adjustedPrice: price,
  };
};
