import React, {useMemo} from "react";
import Select, {createFilter} from "react-select";
import {IProductGroupDetails} from "@natomas-org/core";
import {useFactoryLines} from "../../../../../../_shared/hooks/useProductCatalog/useFactoryLines/useFactoryLines";
import {useCatalogSession} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {MODAL_Z_INDEX} from "../../../../../../_shared/styles";
import {NatPopupItem} from "../../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {useCatalogSessionDispatcher} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";

export const FactorySelector = () => {
  const {selectedProductGroupId} = useCatalogSession();
  const {factoryLines} = useFactoryLines();
  const {selectFactoryById} = useCatalogSessionDispatcher();

  const options: NatPopupItem[] = useMemo(() => {
    return factoryLines
      .map((factory: IProductGroupDetails) => {
        return {
          id: factory?.id,
          label: factory?.internal_title ?? factory?.title,
        };
      })
      .sort((a: NatPopupItem, b: NatPopupItem) => {
        if (a?.label && b?.label) {
          return a?.label?.localeCompare(b?.label);
        }
        return 0;
      });
  }, [factoryLines]);

  const filterConfig = useMemo(() => {
    return {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: "any" as const,
    };
  }, []);

  return (
    <Select
      isSearchable
      filterOption={createFilter(filterConfig)}
      styles={{
        menuPortal: (styles) => ({
          ...styles,
          zIndex: MODAL_Z_INDEX,
        }),
        container: (styles) => ({
          ...styles,
          minWidth: "300px",
          zIndex: MODAL_Z_INDEX,
          position: "relative",
        }),
      }}
      value={options?.find(
        (o: NatPopupItem) => o.id === selectedProductGroupId
      )}
      getOptionValue={(option) => option.id}
      isDisabled={false}
      onChange={(value) => {
        const option = value as NatPopupItem;
        selectFactoryById(option.id);
      }}
      placeholder={"Factories"}
      options={options}
    />
  );
};
