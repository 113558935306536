import React from "react";
import styled from "styled-components";
import {IProjectInfo, Project} from "@natomas-org/core";
import {TeamMemberManagerCard} from "./TeamMemberManagerCard";
import {ControlPanelSectionWrapper} from "../ControlPanelSectionWrapper";

export const ProjectTeamMemberManager = (
  props: IProjectTeamMemberManagerProps
) => {
  const {projectSummary, newAssignedPrimaryContactEmail, onAssignmentChange} =
    props;

  if (!projectSummary) {
    return null;
  }

  const assignedPrimaryContactEmail =
    projectSummary.metadata.assignedPrimaryTeamContactEmail;

  const teamMembers = Project.getTeamMembers(projectSummary);
  if (!teamMembers || teamMembers?.length < 1) {
    return null;
  }

  return (
    <ControlPanelSectionWrapper title={"Project Team Members"}>
      <TeamMemberWrapper>
        {teamMembers.map((teamMember, index) => {
          return (
            <TeamMemberManagerCard
              assignedPrimaryContactEmail={assignedPrimaryContactEmail}
              newAssignedPrimaryContactEmail={newAssignedPrimaryContactEmail}
              teamMember={teamMember}
              key={"team_member" + index}
              onAssignmentChange={onAssignmentChange}
            />
          );
        })}
      </TeamMemberWrapper>
    </ControlPanelSectionWrapper>
  );
};

const Container = styled.div`
  margin: 2rem 0;
`;

const TeamMemberWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

interface IProjectTeamMemberManagerProps {
  projectSummary?: IProjectInfo | null;
  newAssignedPrimaryContactEmail: string | undefined;
  onAssignmentChange: (email: string) => void;
}
