import {CUSTOMER_STATUS} from "../../useCustomerStatus";
import {HOME_PAGE_ID} from "../../../../portal/_shared/navigation/constants";
import {IDropdownList} from "./dropdownItems";

export const shouldShowGreenDotOnAvatar = (
  toDoOptions: string[],
  DROPDOWN_TODO_ITEMS: IDropdownList,
  pageId: string | null,
  urlPath: string | null
): boolean => {
  const determineOptionValue = determineSelectedOption(
    toDoOptions,
    pageId,
    urlPath
  );

  return determineOptionValue
    ? !getCurrentSteps(toDoOptions, DROPDOWN_TODO_ITEMS).includes(
        determineOptionValue
      )
    : false;
};

//  returns an array of page id(s) for what current step the user should be on
export const getCurrentSteps = (
  toDoOptions: string[],
  DROPDOWN_TODO_ITEMS: IDropdownList
): string[] => {
  const arrayToReturn: string[] = [];
  toDoOptions.forEach((optionId: string) => {
    if (DROPDOWN_TODO_ITEMS[optionId].status === CUSTOMER_STATUS.IN_PROGRESS) {
      arrayToReturn.push(DROPDOWN_TODO_ITEMS[optionId].value);
    }
  });
  return arrayToReturn;
};

export const determineSelectedOption = (
  options: string[],
  pageId: string | null,
  urlPath: string | null
) => {
  let cleanedUrlPath = urlPath?.replace("/", "") ?? "";
  if (cleanedUrlPath === "") {
    return HOME_PAGE_ID;
  }
  let optionMatches = options.filter((option) =>
    option.includes(cleanedUrlPath)
  );
  if (optionMatches.length > 0) {
    return optionMatches[0];
  }
  if (!pageId) {
    return null;
  }
  optionMatches = options.filter((option) => option.includes(pageId ?? ""));
  if (optionMatches.length > 0) {
    return optionMatches[0];
  }

  return null;
};
