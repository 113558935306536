import React from "react";
import {FilterInlineModal} from "../../generics/popup/dropdown/specializedDropdowns/filter/FilterInlineDropdown";
import {FilterOrder} from "../../../catalog/_shared/components/CatalogBar/MapCatalogBar";

export const FiltersModal = () => {
  return (
    <FilterInlineModal
      filterDisplayTypes={FilterOrder}
      title={"Filters"}
      rightAlign={false}
    />
  );
};
