import React from "react";
import {
  AdminBlankScreenContainer,
  AdminBlankScreenDescription,
  AdminBlankScreenTitle,
} from "./styles";

interface AdminBlankScreenProps {
  title?: string;
  description?: string;
  content?: JSX.Element;
}
const AdminBlankScreen = (props: AdminBlankScreenProps) => {
  const {title, description, content} = props;
  return (
    <AdminBlankScreenContainer>
      {title && <AdminBlankScreenTitle>{title}</AdminBlankScreenTitle>}
      {description && (
        <AdminBlankScreenDescription>{description}</AdminBlankScreenDescription>
      )}
      {content}
    </AdminBlankScreenContainer>
  );
};

export default AdminBlankScreen;
