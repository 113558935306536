import styled from "styled-components";
import {MODAL_Z_INDEX} from "../../styles";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK, GRANITE, WHITE} from "../../colors";
import Close from "./Close";

interface INatomasModal {
  width: string;
  height: string;
  opacity: number;
  show: boolean;
  animationSpeed: number;

  // These two props are used to enforce the height and width of the modal
  // This is useful when you want to have a static height and width regardless of the content
  enforceHeight: boolean; // defaults to false
  enforceWidth: boolean; // defaults to true
  backgroundColor?: string;
}
export const NatomasModalWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${MODAL_Z_INDEX};
  align-items: center;
  justify-content: center;
`;

export const NatomasModal = styled.div<INatomasModal>`
  position: relative;
  pointer-events: ${(props) => (props.opacity ? "auto" : "none")};
  background-color: ${(props) => props.backgroundColor ?? WHITE};
  border-radius: 1rem;

  width: ${(props) => (props.enforceWidth ? props.width : "auto")};
  max-width: ${(props) => props.height};

  height: ${(props) => (props.enforceHeight ? props.height : "auto")};
  max-height: ${(props) => props.height};

  overflow-y: auto;
  overflow-x: hidden;

  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: ${(props) =>
    `max-height ${props.animationSpeed}ms ease-in-out, opacity 0.2s ease-out`};
`;

export const NatomasModalContainer = styled.div<{
  customPadding?: string;
}>`
  height: 100%;
  width: 100%;
  padding: ${(props) =>
    props.customPadding ? props.customPadding : "0.5rem 2rem"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NatomasModalContentContainer = styled.div<{
  centered: boolean;
  isMobile?: boolean;
}>`
  flex-grow: 1;
  color: black;
  margin: ${(props) => (props.isMobile ? "0 0" : "1rem 0")};
  ${(props) =>
    props.centered &&
    `
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

export const NatomasModalHeader = styled.h1<{isMobile: boolean}>`
  font-size: ${(props) => (props.isMobile ? 1.25 : 1.5)}rem;
  text-align: left;
  margin: 0;
  padding: 1rem 2rem;
  font-family: ${FONT_FAMILY_BOLD};
  color: black;
  position: sticky;
  background-color: white;
  top: 0;
  border-bottom: 1px solid black;
`;

export const NatomasModalClose = Close;

export const NatomasFilterModalClose = styled.div<{sticky?: boolean}>`
  text-align: right;
  padding: 1rem 0.5rem;
  position: absolute;
  z-index: calc(${MODAL_Z_INDEX} + 100);
  cursor: pointer;
  top: 0;
  right: 0;
  svg {
    border-radius: 100%;
    height: 1.25rem;
    width: 1.25rem;
    color: ${(props) => (props.theme?.Page?.isNatMobile ? BLACK : GRANITE)};
    background-color: white;
    &:hover {
      color: ${(props) => (props.theme?.Page?.isNatMobile ? GRANITE : BLACK)};
    }
  }
`;

export const NatomasModalFooterButtonContainer = styled.div<{
  columnButtons: boolean;
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.columnButtons ? "column-reverse" : "row"};
  justify-content: flex-end;
  padding-top: 0.5rem;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
`;

export const NatomasModalBackdrop = styled.div<{
  opacity: number;
  backgroundColor?: string;
}>`
  position: fixed;
  pointer-events: ${(props) => (props.opacity ? "auto" : "none")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "rgba(0, 0, 0, 0.4)"};
  opacity: ${(props) => props.opacity};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  backdrop-filter: blur(15px); /* Gaussian blur effect */
  transition: all 0.3s;
`;

export const NatomasBottomSheet = styled.div<{
  show: boolean;
  animationSpeed: number;
  height: string;
  width: string;
  opacity: number;
  backgroundColor: string;
}>`
  pointer-events: ${(props) => (props.opacity ? "auto" : "none")};
  background-color: ${(props) => props.backgroundColor ?? WHITE};
  position: fixed;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  bottom: ${(props) => (props.show ? "0" : "-100vh")};
  left: 0;
  margin-left: calc((100vw - (${(props) => props.width})) / 2);
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  z-index: ${MODAL_Z_INDEX};
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  transition: ${(props) => `bottom ${props.animationSpeed}ms ease-in-out`};
`;
