import {StudioEditorFieldType} from "../../_shared/StudioEditorFieldAddButton";
import {getValue} from "../helper";
import {IFactoryInventoryStudioEditorPopupPathOptions} from "../interface";
import {importCategory, importModifier, importModifierGroup} from "./import";

export const getTargetIndex = (
  formikValues: any,
  type?: StudioEditorFieldType,
  path?: IFactoryInventoryStudioEditorPopupPathOptions
) => {
  let value = undefined;
  if (!type) {
    return value;
  }
  if (type === StudioEditorFieldType.MODIFIER) {
    const parentValue = getValue(formikValues, path);
    value = Object.keys(parentValue?.modifiers)?.length ?? 0;
  } else if (type === StudioEditorFieldType.MODIFIER_GROUP) {
    const parentValue = getValue(formikValues, path);
    value = Object.keys(parentValue?.modifierGroups)?.length ?? 0;
  } else if (type === StudioEditorFieldType.CATEGORY) {
    const categories = Object.keys(formikValues);
    value = categories?.length ?? 0;
  }
  return value;
};

export const getSelectedImportValue = (
  type?: StudioEditorFieldType,
  selectedId?: string,
  targetIndex?: number,
  activeLibrary?: any,
  modifierGroups?: any,
  modifiers?: any
) => {
  if (!selectedId || !type) {
    return undefined;
  }
  const rawValue = activeLibrary?.[selectedId] ?? undefined;
  if (!rawValue) {
    return undefined;
  }
  if (type === StudioEditorFieldType.MODIFIER) {
    return importModifier(rawValue, targetIndex);
  } else if (type === StudioEditorFieldType.MODIFIER_GROUP) {
    return importModifierGroup(rawValue, modifiers, targetIndex);
  } else if (type === StudioEditorFieldType.CATEGORY) {
    return importCategory(rawValue, modifierGroups, modifiers, targetIndex);
  }
  return undefined;
};

export const getFormikPathForImport = (
  selectedValueId?: string,
  type?: StudioEditorFieldType,
  path?: IFactoryInventoryStudioEditorPopupPathOptions
) => {
  let value = undefined;
  if (type === StudioEditorFieldType.MODIFIER) {
    value = {modifierId: selectedValueId};
  } else if (type === StudioEditorFieldType.MODIFIER_GROUP) {
    value = {modifierGroupId: selectedValueId};
  } else if (type === StudioEditorFieldType.CATEGORY) {
    value = {categoryId: selectedValueId};
  }
  return value ? {...path, ...value} : undefined;
};
