import styled from "styled-components";
import {
  ADMIN_BUTTON_ACTIVE,
  BLACK,
  GRAY,
  VILLA_BLUE,
  WHITE,
} from "../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../portal/views/SSDDashboardView/styles/globals";
import {HEADER_Z_INDEX} from "../../_shared/styles";

export const InfoSetContainer = styled.div`
  margin-left: 200px;
  margin-bottom: 16rem;
  transition: all 100ms ease-in-out;
`;

export const InfoSetTableElement = styled.table`
  border-spacing: 0;
  border: 1px solid black;
  border-collapse: separate; /* Don't collapse */
  thead {
    position: sticky;
    top: 0;
    z-index: ${HEADER_Z_INDEX};
  }
  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    font-size: 12px;
    :last-child {
      border-right: 0;
    }
  }
`;
export const InfoSetTableRow = styled.tr<{
  selected?: boolean;
  toBeArchived?: boolean;
}>`
  background-color: ${(props) =>
    props.toBeArchived ? "#ff9292" : props.selected && "#d8e1ff"};
  &:hover {
    background-color: ${(props) =>
      props.toBeArchived ? "#f87878" : "#d8e1ff"};
  }
`;

export const InfoSetTableRowCell = styled.td<{
  changed?: boolean;
  toBeArchived?: boolean;
  borderType?: "dashed" | "solid" | "none";
}>`
  background-color: ${(props) =>
    !props.toBeArchived && props.changed ? "#ffe99e" : undefined};
  border-bottom: ${(props) =>
    props.borderType === "none"
      ? `0px`
      : props.borderType === "dashed"
      ? `1px dashed ${GRAY}`
      : `1px solid ${BLACK}`} !important;
  &:hover {
    background-color: ${(props) =>
      !props.toBeArchived && props.changed ? "#f3d87b" : undefined};
  }
`;

export const InfoSetColumnToggle = styled.label<{selected: boolean}>`
  margin: 0;
  height: auto;
  font-size: 12px;
  padding: 6px;
  color: ${WHITE};
  font-family: ${FONT_FAMILY_MEDIUM};
  user-select: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${(props) =>
    props.selected ? ADMIN_BUTTON_ACTIVE : VILLA_BLUE};
  :before {
    content: "- ";
  }
`;

export const InfoSetColumnToggleGroup = styled.div`
  margin: 0;
  height: auto;
  font-size: 12px;
  padding: 6px;
  color: ${WHITE};
  font-family: ${FONT_FAMILY_MEDIUM};
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const InfoSetTableController = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 6px;
  background-color: #3a3a3a;
  position: fixed;
  height: 100%;
  width: 200px;
  z-index: 99999999999;
  top: 0;
  left: 0;
`;
export const InfoSetControlContainer = styled.div`
  padding: 6px;
  background-color: ${VILLA_BLUE};
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: 100%;
  height: auto;
  border-top: 1px solid ${BLACK};
`;

export const InfoSetColumnToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: clip;
`;

export const InfoSetColumnToggleSectionContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
  background-color: ${VILLA_BLUE};
`;

export const InfoSetColumnToggleContainerLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  height: 35px;
  border-bottom: 1px solid ${BLACK};
  background-color: ${VILLA_BLUE};
  color: ${WHITE};
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const ReactTableHeaderRowCell = styled.th<{
  backgroundColor?: string;
  sticky?: boolean;
}>`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-weight: normal !important;
  font-size: 1rem;
  user-select: none;
  background-color: ${(props) => props.backgroundColor ?? "#e6e6e6"};
`;

export const ReactTableHeaderRowCellContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
`;
