import React, {useMemo} from "react";
import {useUTMInputs} from "../../../../components/intake-form/hooks/useUTMInputs";
import {PardotIntakeForm} from "../../../../components/intake-form/components/PardotIntakeForm";
import {
  Address,
  IAddressDetails,
  IPardotIntakeFormInput,
} from "@natomas-org/core";
import {
  PARDOT_GENERAL_INTAKE_FORM_URL,
  PARDOT_MULTI_FAMILY_INTAKE_FORM_URL,
} from "./config";

export interface IRegistrationPardotFormProps {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdFrom: string;
  leadSegment: string;
  propertyType: string;
  company: string;
  marketingChannel: string;
  marketingConsent: boolean;
  address: IAddressDetails;
  nexusLeadId: string;
}

const RegistrationPardotForm = (props: {
  info: IRegistrationPardotFormProps | null;
}) => {
  const {
    id,
    firstName,
    lastName,
    email,
    address,
    phoneNumber,
    company,
    propertyType,
    leadSegment,
    createdFrom,
    nexusLeadId,
    marketingChannel,
    marketingConsent,
  } = props?.info ?? {};
  const utms = useUTMInputs();
  const formValues: IPardotIntakeFormInput[] = [
    {
      inputType: "text",
      inputName: "marketing_channel",
      inputValue: marketingChannel ?? "",
    },
    {
      inputType: "text",
      inputName: "Marketing_Opt_In__c",
      inputValue: marketingConsent ? "true" : "false",
    },
    {
      inputType: "text",
      inputName: "tags",
      inputValue: "account_origin_build",
    },
    {
      inputType: "text",
      inputName: "first_name",
      inputValue: firstName ?? "",
    },
    {
      inputType: "text",
      inputName: "last_name",
      inputValue: lastName ?? "",
    },
    {inputType: "email", inputName: "email", inputValue: email ?? ""},
    {
      inputType: "tel",
      inputName: "phone",
      inputValue: phoneNumber ?? "",
    },
    {
      inputType: "text",
      inputName: "street",
      inputValue: Address.getStreetNumberAndStreet(address),
    },
    {
      inputType: "text",
      inputName: "city",
      inputValue: Address.getCity(address),
    },
    {
      inputType: "text",
      inputName: "state",
      inputValue: Address.getState(address),
    },
    {
      inputType: "text",
      inputName: "zip",
      inputValue: Address.getZip(address),
    },
    {
      inputType: "text",
      inputName: "county",
      inputValue: Address.getCounty(address),
    },
    {
      inputType: "number",
      inputName: "latitude",
      inputValue: Address.getLatitude(address) ?? "",
    },
    {
      inputType: "number",
      inputName: "longitude",
      inputValue: Address.getLongitude(address) ?? "",
    },
    {
      inputType: "text",
      inputName: "property_type",
      inputValue: propertyType ?? "",
    },
    {
      inputType: "text",
      inputName: "company",
      inputValue: company ?? "",
    },
    {
      inputType: "text",
      inputName: "Customer_Segment",
      inputValue: leadSegment === "b2b" ? "SMB B2B" : "D2C",
    },
    {
      inputType: "text",
      inputName: "Project_Type",
      inputValue:
        leadSegment === "b2b" ? "Multifamily ADU" : "Single Family Home ADU",
    },
    {inputType: "text", inputName: "reason", inputValue: null},
    {inputType: "text", inputName: "timeline", inputValue: null},
    {inputType: "text", inputName: "multiFamily", inputValue: null},
    {
      inputType: "text",
      inputName: "unit_type",
      inputValue: null,
    },
  ];
  const values: IPardotIntakeFormInput[] = useMemo(() => {
    return [
      ...utms,
      ...formValues,
      {
        inputName: "created_from",
        inputType: "text",
        inputValue: createdFrom ?? "",
      },
      {
        inputName: "Villa_Nexus_Lead_UUID__c",
        inputType: "text",
        inputValue: nexusLeadId ?? "",
      },
    ];
  }, [utms, formValues, createdFrom, nexusLeadId]);

  return (
    <PardotIntakeForm
      formValues={values}
      customId={id}
      pardotURL={
        leadSegment === "b2b"
          ? PARDOT_MULTI_FAMILY_INTAKE_FORM_URL
          : PARDOT_GENERAL_INTAKE_FORM_URL
      }
    />
  );
};

export default RegistrationPardotForm;
