import {
  useFactoryInventoryEditor
} from "../../../../../../../../../_shared/hooks/admin-only-hooks/useFactoryInventoryEditor";
import {useDispatch} from "react-redux";
import {NatToggleSelectorOption} from "../../../../../../../../../_shared/generics/toggle-selector/interfaces";
import React, {useMemo} from "react";
import {
  setIsEditingInInventoryEditor
} from "../../../../../../../../../_shared/slices/CatalogSlice/AdminFactoryInventoryEditorSlice";
import {NatToggleSelector} from "../../../../../../../../../_shared/generics/toggle-selector";

export const FactoryInventoryEditingToggle = () => {
  const {isEditing} = useFactoryInventoryEditor();
  const dispatch = useDispatch();

  const options: NatToggleSelectorOption[] =
    useMemo((): NatToggleSelectorOption[] => {
      return [
        {
          id: "editor",
          label: "Editor",
          isSelected: isEditing,
          clickEvent: () => {
            if (!isEditing) {
              dispatch(setIsEditingInInventoryEditor(true));
            }
          },
        },
        {
          id: "preview",
          label: "Preview",
          isSelected: !isEditing,
          clickEvent: () => {
            if (isEditing) {
              dispatch(setIsEditingInInventoryEditor(false));
            }
          },
        },
      ];
    }, [dispatch, isEditing]);

  return <NatToggleSelector options={options} />;
};
