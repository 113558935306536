import React, {useMemo, useState} from "react";
import {
  ImageCardButtonContainer,
  ImageCardContainer,
  ImageCardDetailsContainer,
  ImageCardInfoContainer,
} from "./styles";
import {SmartImage} from "../../../SmartImage";
import {useImage} from "../../../../../hooks/useImage";
import {useImageFinder} from "../../../../../hooks/useImageFinder";
import {getDateString} from "../../../../../../admin/_shared/_helpers";
import {NatButton} from "../../../../button";
import {NatSize} from "../../../../_shared";
import {useTags} from "../../../../../hooks/useTags/useTags";
import {TagType} from "../../../../../hooks/useTags/tags";
import {ImageDownloadButton} from "../ImageDownloadButton";
import {ImageDetailsEditor} from "../../../ImageDetailsEditor";

export interface ImageCardProps {
  id: string;
  showEditDetails: boolean;
  selectImage: ((id: string) => any) | null;
}

export const ImageCard = (props: ImageCardProps) => {
  const {id, showEditDetails, selectImage} = props;
  const data = useImage(id);
  const {zoomModifier, currentImageFinderImageId, setImageFinderImageId} =
    useImageFinder();
  const {getTagTitleById} = useTags(TagType.PRODUCT_CATALOG);

  const [showEdit, setShowEdit] = useState<boolean>(false);

  const clickHandler = () => {
    setImageFinderImageId(id);
  };

  const getTags = () => {
    if (data?.tags && data?.tags?.length > 0) {
      return data.tags.map((tagId: string) => getTagTitleById(tagId));
    }
    return null;
  };

  const showDetails = useMemo(() => {
    return (
      !!currentImageFinderImageId && !!id && currentImageFinderImageId === id
    );
  }, [currentImageFinderImageId]);

  return (
    <ImageCardContainer zoomModifier={zoomModifier} selected={showDetails}>
      <SmartImage imageId={data?.id} onClick={() => clickHandler()} />
      {showDetails && (
        <ImageCardDetailsContainer>
          <ImageCardInfoContainer onClick={() => clickHandler()}>
            <div>{"Title: " + (data?.title ?? "None")}</div>
            <div>{"Uploaded: " + getDateString(data?.timestamp)}</div>
            <div>{"Tags: " + (getTags() ?? "None")}</div>
          </ImageCardInfoContainer>
          <ImageCardButtonContainer>
            {selectImage && (
              <NatButton
                label={"Select"}
                type={"button"}
                size={NatSize.SMALL}
                clickEvent={() => {
                  selectImage(id);
                }}
              />
            )}
            {showEditDetails && (
              <>
                <ImageDetailsEditor
                  show={showEdit}
                  setShow={setShowEdit}
                  imageId={id}
                />
                <NatButton
                  label={"Edit Details"}
                  type={"button"}
                  size={NatSize.SMALL}
                  clickEvent={() => {
                    setShowEdit(true);
                  }}
                />
              </>
            )}
            <ImageDownloadButton id={id} />
          </ImageCardButtonContainer>
        </ImageCardDetailsContainer>
      )}
    </ImageCardContainer>
  );
};
