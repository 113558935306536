import {executeRequest} from "../APIExecutor";

export const fetchAndSaveCalendlyEvent = (
  eventId: string,
  eventURI: string,
  projectId: string,
  originalCalendlyLink: string
) => {
  return executeRequest("/event/v1/saveCalendlyEvent", {
    projectId: projectId,
    eventURI: eventURI,
    eventId: eventId,
    originalCalendlyLink: originalCalendlyLink,
  });
};
