import {getADUCartItem} from "../../../../components/_shared/slices/CartSlice/helper";
import {CONFIGURATION_DESIGN_DB_KEY} from "../../configuration/configuration";
import {Utilities} from "../../index";
import {IProduct, ICartItem} from "@natomas-org/core";

export const getCartItemFromStudio = (
  product: IProduct,
  designId?: string,
  currentCartItem?: ICartItem
): ICartItem => {
  const ref = Utilities.collection(CONFIGURATION_DESIGN_DB_KEY).doc(designId);
  return getADUCartItem(product, ref.id, currentCartItem);
};
