import {
  ASSEMBLY_KEY,
  CONDITIONS_KEY,
  DETAILS_KEY,
  EXTERNAL_CATEGORY_KEY,
  EXTERNAL_DESCRIPTION_KEY,
  ID_KEY,
  INCLUDED_ORDER_FORM_KEY,
  INTERNAL_CATEGORY_KEY,
  INTERNAL_NOTES_KEY,
  LABEL_KEY,
  MULTI_OPTION_KEY,
  OMIT_KEY,
  PRICING_PACKAGE_KEY,
  SOURCE_KEY,
  VALUE_KEY,
  VARIANTS_KEY,
} from "../../../shared/constants";
import {InfoSetItemOptionCondition} from "../../../shared/interfaces";
import {IProduct} from "@natomas-org/core";

export const hasReadOnlyVariant = (columnKey: string) => {
  switch (columnKey) {
    case CONDITIONS_KEY:
      return false;
    case ID_KEY:
    case LABEL_KEY:
    case INTERNAL_CATEGORY_KEY:
    case OMIT_KEY:
    case SOURCE_KEY:
    case ASSEMBLY_KEY:
    case PRICING_PACKAGE_KEY:
    case VALUE_KEY:
    case INCLUDED_ORDER_FORM_KEY:
    case MULTI_OPTION_KEY:
    case DETAILS_KEY:
    case VARIANTS_KEY:
    case EXTERNAL_CATEGORY_KEY:
    case EXTERNAL_DESCRIPTION_KEY:
    case INTERNAL_NOTES_KEY:
    default:
      return true;
  }
};

export const getReadOnlyValue = (
  value: any,
  columnKey: string
): string | null => {
  switch (columnKey) {
    case ID_KEY:
    case LABEL_KEY:
    case INTERNAL_CATEGORY_KEY:
    case OMIT_KEY:
    case SOURCE_KEY:
    case ASSEMBLY_KEY:
    case PRICING_PACKAGE_KEY:
    case DETAILS_KEY:
    case VARIANTS_KEY:
    case EXTERNAL_CATEGORY_KEY:
    case EXTERNAL_DESCRIPTION_KEY:
    case INTERNAL_NOTES_KEY:
      return value?.length > 0 ? `${value}` : null;
    case VALUE_KEY:
      return value?.length > 0 ? `${value}` : "{Custom}";
    case INCLUDED_ORDER_FORM_KEY:
    case MULTI_OPTION_KEY:
      return value ? "✔" : "-";
    default:
      return JSON.stringify(value);
  }
};

export const isRowValueValidByKey = (
  columnKey: string,
  rowValues: any
): boolean => {
  const isSelfValid = isRowCellValid(columnKey, rowValues);
  if (!isSelfValid) {
    // We do not want to hide populated data with a warning
    switch (columnKey) {
      case EXTERNAL_CATEGORY_KEY:
        return !isRowCellValid(EXTERNAL_DESCRIPTION_KEY, rowValues);
      case EXTERNAL_DESCRIPTION_KEY:
        return !isRowCellValid(EXTERNAL_CATEGORY_KEY, rowValues);
      default:
        return true;
    }
  }
  return true;
};

const isRowCellValid = (columnKey: string, rowValues: any): boolean => {
  switch (columnKey) {
    case EXTERNAL_CATEGORY_KEY:
      return !!rowValues[columnKey];
    case EXTERNAL_DESCRIPTION_KEY:
      return !!rowValues[columnKey]?.length;
    default:
      return true;
  }
};
export const isRichTextValue = (columnKey: string): boolean => {
  switch (columnKey) {
    case EXTERNAL_DESCRIPTION_KEY:
    case INTERNAL_NOTES_KEY:
      return true;
    default:
      return false;
  }
};

export const getConditionLabel = (
  condition: InfoSetItemOptionCondition,
  productOptions: any[],
  modifierOptions: any[]
) => {
  let conditionString = "";
  let type = condition?.type;

  let selections = Object.keys(condition?.requiredModifiers ?? {});
  let products = condition?.targets ?? [];

  const hasSelections = selections?.length > 0;
  const hasProducts = products?.length > 0;

  if (hasProducts) {
    let count = 0;
    products.forEach((targetId: string) => {
      const productTitle = productOptions.find(
        (product: IProduct) => targetId === product?.id
      )?.title;
      if (productTitle) {
        conditionString = conditionString.concat(
          `${count > 0 ? ", " : ``}${productTitle}`
        );
        count++;
      }
    });
  }
  if (hasProducts && hasSelections) {
    conditionString = conditionString.concat(` / `);
  }
  if (hasSelections) {
    let count = 0;
    let prefix = `${type} - `;
    let selectionsString = ``;
    selections.forEach((modifierId: string) => {
      const modifierLabel = modifierOptions.find(
        (modifierOption: any) => modifierId === modifierOption?.value
      )?.label;
      if (modifierLabel) {
        selectionsString = selectionsString.concat(
          `${count > 0 ? ", " : ``}${modifierLabel}`
        );
        count++;
      }
    });
    if (count > 1) {
      selectionsString = `${prefix}${selectionsString}`;
    }
    conditionString = conditionString.concat(selectionsString);
  }
  return conditionString;
};
