import React, {Fragment, useState} from "react";
import {
  ActionContainer,
  FactoryViewContainer,
  ProductLineInfoBlockInfo,
  ProductLineInfoBlockInfoRow,
  ProductLineInfoBlockTitle,
} from "../styles";
import {INVENTORY_UNIT_PREFIX} from "../../../../../../../_shared/constants/labels";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../../../_shared/generics/_shared";
import {migrateInventory} from "../../../../../../../../database/firebase/api/admin";
import {setAdminView} from "../../../../../../../_shared/slices/AdminSlice";
import {useDispatch} from "react-redux";
import {FactoryView} from "../../../_shared/FactoryViewSelector";

const FactoryLineInventoryBlock = (props: {id?: string}) => {
  const {id} = props;
  const dispatch = useDispatch();
  const [migrated, setMigrated] = useState(false);
  if (!id) {
    return <FactoryViewContainer>Loading...</FactoryViewContainer>;
  }
  return (
    <Fragment>
      <ProductLineInfoBlockInfo>
        <ProductLineInfoBlockTitle>
          {INVENTORY_UNIT_PREFIX}s
        </ProductLineInfoBlockTitle>
        <ProductLineInfoBlockInfoRow>
          <ActionContainer>
            <NatButton
              // TODO Execute this on the production app
              hidden={true}
              disabled={migrated}
              spinnerEnabled={true}
              label={"Migrate All Inventory"}
              option={StyleOption.DESTRUCTIVE}
              clickEvent={() => {
                return migrateInventory().then(() => setMigrated(true));
              }}
            />
            <NatButton
              label={"View Readybuilt Manager"}
              option={StyleOption.PRIMARY_BLACK}
              clickEvent={() => {
                dispatch(setAdminView(FactoryView.INVENTORY));
              }}
            />
          </ActionContainer>
        </ProductLineInfoBlockInfoRow>
      </ProductLineInfoBlockInfo>
    </Fragment>
  );
};

export default FactoryLineInventoryBlock;
