import {ControlPanelSectionWrapper} from "../ControlPanelSectionWrapper";
import {ControlPanelStyledForm} from "../../../../../../_shared/styles/AdminStyles";
import {LOCK_DESIGN_KEY, ProjectStructure} from "../project_model";
import NatLabel, {
  NatLabelType,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {DateUtils} from "@natomas-org/core";
import {AdminInput} from "../../../../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import React from "react";
import {FormikProps} from "formik";
import {getFormStructure} from "../../../../../../../_shared/application";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";

export const ProjectSettingsSection = (props: {
  formik: FormikProps<any>;
  initialValues: any;
}) => {
  // TODO B2B Update this to work for DTC
  return (
    <ControlPanelSectionWrapper title={"Project Settings"}>
      <YesNoToggles {...props} />
    </ControlPanelSectionWrapper>
  );
};

const YesNoToggles = (props: {
  formik: FormikProps<any>;
  initialValues: any;
}) => {
  const {mergedCustomerProjectInfos: mergedProjectInfos, cartItemCount} =
    useActiveProject();
  const projectStructure = getFormStructure(ProjectStructure);
  const {formik, initialValues} = props;
  if (!formik || !initialValues) {
    return (
      <ControlPanelSectionWrapper title={"Project Settings"}>
        Loading...
      </ControlPanelSectionWrapper>
    );
  }
  if (cartItemCount < 1) {
    return (
      <NatLabel
        label={"Customer has not selected a product."}
        type={NatLabelType.P3}
      />
    );
  }
  return (
    <ControlPanelStyledForm onSubmit={formik.handleSubmit}>
      {projectStructure.map((keyInfo) => {
        const {key} = keyInfo;
        let value = formik.values[key];
        let initial = initialValues[key];
        let subtitle: string | null = null;
        if (keyInfo?.description) {
          subtitle = keyInfo?.description;
        }
        if (
          key === LOCK_DESIGN_KEY &&
          mergedProjectInfos?.settings?.design_lock?.is_locked
        ) {
          const {edited_timestamp, edited_by_email} =
            mergedProjectInfos.settings.design_lock;
          subtitle = `${edited_by_email} +
                      " updated the lock on " +
                      ${DateUtils.getFullDate(new Date(edited_timestamp), {
                        showTime: true,
                      })}`;
        }
        return (
          <AdminInput
            key={key}
            value={value}
            fieldInfo={{...keyInfo, subtitle: subtitle}}
            initialValue={initial}
            onChange={(value: any) => {
              formik.setFieldValue(key, value);
            }}
          />
        );
      })}
    </ControlPanelStyledForm>
  );
};
