import React from "react";
import {
  formatPhoneNumber,
  IAddressDetails,
  IPardotIntakeFormInput,
  PhoneNumberFormatType,
} from "@natomas-org/core";
import {UTMSliceType} from "../../../../../../_shared/slices/types/UTMSliceType";
import {PARDOT_GENERAL_INTAKE_FORM_URL} from "../../../../../../intake-form/constants";
import {PardotIntakeForm} from "../../../../../../intake-form/components/PardotIntakeForm";
import {NatButton} from "../../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../../_shared/generics/_shared";
import {isProd} from "../../../../../../_shared/application";
import {
  CUSTOMER_SEGMENT_KEY,
  PROJECT_TYPE_KEY,
} from "../../../../../../_shared/forms/FormModels";

export const GeneralIntakeFormSubmitter = (
  props: IIntakeFormSubmitterProps
) => {
  const {intakeInfo, showSubmitButton} = props;
  const {address, utm, lastName, phoneNumber, firstName, email} = intakeInfo;
  const {
    page_source,
    utm_source,
    utm_adgroup,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_placement,
    utm_term,
    key,
  } = utm;

  const phone = formatPhoneNumber(phoneNumber, PhoneNumberFormatType.RAW);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const information: IPardotIntakeFormInput[] = [
    {
      inputType: "text",
      inputName: "first_name",
      inputValue: firstName,
    },
    {
      inputType: "text",
      inputName: "last_name",
      inputValue: lastName,
    },
    {inputType: "email", inputName: "email", inputValue: email},
    {
      inputType: "tel",
      inputName: "phone",
      inputValue: phone,
    },
    {
      inputType: "text",
      inputName: "street",
      inputValue: address?.street_number + " " + address?.street,
    },
    {inputType: "text", inputName: "city", inputValue: address?.city},
    {inputType: "text", inputName: "state", inputValue: address?.state},
    {inputType: "text", inputName: "zip", inputValue: address?.zip},
    {inputType: "text", inputName: "county", inputValue: address?.county},
    {inputType: "text", inputName: "reason", inputValue: ""},
    {inputType: "text", inputName: "timeline", inputValue: ""},
    {inputType: "text", inputName: "multiFamily", inputValue: ""},
    {inputType: "text", inputName: CUSTOMER_SEGMENT_KEY, inputValue: ""},
    {inputType: "text", inputName: PROJECT_TYPE_KEY, inputValue: ""},
    {
      inputType: "text",
      inputName: "unit_type",
      inputValue: "",
    },
    {inputType: "number", inputName: "latitude", inputValue: address?.latitude},
    {
      inputType: "number",
      inputName: "longitude",
      inputValue: address?.longitude,
    },
    {inputType: "text", inputName: "page_source", inputValue: page_source},
    {inputType: "text", inputName: "utm_adgroup", inputValue: utm_adgroup},
    {inputType: "text", inputName: "utm_campaign", inputValue: utm_campaign},
    {inputType: "text", inputName: "utm_content", inputValue: utm_content},
    {inputType: "text", inputName: "utm_medium", inputValue: utm_medium},
    {inputType: "text", inputName: "utm_placement", inputValue: utm_placement},
    {inputType: "text", inputName: "utm_source", inputValue: utm_source},
    {inputType: "text", inputName: "utm_term", inputValue: utm_term},
    {inputType: "text", inputName: "key", inputValue: key},
  ];

  return (
    <>
      <PardotIntakeForm
        formValues={information}
        pardotURL={PARDOT_GENERAL_INTAKE_FORM_URL}
      />
      <NatButton
        size={NatSize.SMALL}
        hidden={!showSubmitButton}
        label={"Trigger Intake Form"}
        option={isSubmitted ? StyleOption.SECONDARY : StyleOption.PRIMARY}
        disabled={isSubmitted}
        clickEvent={() => {
          if (isProd) {
            // @ts-ignore
            document.querySelector("#hidden-submit-button")?.click();
          } else {
            console.log("avoid triggering intake form in dev");
          }
          setIsSubmitted(true);
        }}
      />
    </>
  );
};

interface IIntakeFormSubmitterProps {
  intakeInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: IAddressDetails;
    utm: UTMSliceType;
  };
  showSubmitButton: boolean;
}
