import {useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {useCurrentUser} from "./useCurrentUser";
import {getParameter} from "../cookies";
import {useUrl} from "./useUrl";
import {useMemo} from "react";

export const useGlobalConfiguration = () => {
  const {isAdmin} = useCurrentUser();
  const configFlags = useSelector((state: IStore) => state.global.config);
  const {url} = useUrl();

  return useMemo(() => {
    const enrichedFlags = Object.assign({}, configFlags);
    if (isAdmin) {
      Object.keys(configFlags).forEach((key) => {
        let paramValue: any = getParameter(key);
        if (paramValue != null) {
          // Convert string params to booleans or numerics
          if (paramValue.toLowerCase() === "true") {
            paramValue = true;
          } else if (paramValue.toLowerCase() === "false") {
            paramValue = false;
          } else if (isNumeric(paramValue)) {
            paramValue = parseFloat(paramValue);
          }

          // Remember the override config for when the remote config is fetched
          enrichedFlags[key] = paramValue;
        }
      });
    }

    return enrichedFlags;
  }, [configFlags, url, isAdmin]);
};

function isNumeric(n: string) {
  return !isNaN(parseFloat(n));
}
