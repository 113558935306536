import styled from "styled-components";
import {GRAY} from "../../../_shared/colors";
import {isMobile} from "../../../_shared/navigation";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";
import {NatomasSlider} from "../../../_shared/generics/slider/styles";

export const HelpText = styled.div`
  font-size: 0.85rem;
  color: ${GRAY};
  margin: ${isMobile() ? 0 : 1}rem 0;
  text-align: center;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 2rem 0;
`;

export const FilterList = styled.div<{
  isMobile: boolean;
  overridePadding?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 0.65rem;
  padding: ${(props) => (props.overridePadding ? "0 1rem" : "00")};
  justify-content: ${(props) =>
    props.isMobile && !props.overridePadding ? "flex-start" : "flex-start"};
  ${NatomasSlider} {
    &:last-child {
      margin-right: 0;
    }
  }
  margin-top: 0.5rem;
`;

export const AddressBarContainer = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  align-items: ${(props) => (props.isMobile ? "flex-start" : "center")};
  gap: 1rem;
  height: 5rem;
`;

export const FeasibilityContextTitle = styled.span`
  font-size: 1.25rem;
  padding-bottom: 0.5rem;
`;

export const FeasibilityContextBold = styled.span`
  font-family: ${FONT_FAMILY_BOLD};
  display: inline;
`;

export const FeasibilityContextSubtitle = styled.div`
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.75rem;
`;
