import {ICartItem} from "@natomas-org/core";
import useCartLibrary from "../useCartLibrary";
import {useMemo} from "react";
import {useFactoryInfo} from "../../useProductCatalog/useFactoryLine/useFactoryInfo";
import useActiveProject from "../../useProject/useActiveProject";
import {useProduct} from "../../useProductCatalog/useProduct";

export enum CartItemSource {
  UNKNOWN = "unknown",
  PROJECT_CART = "project-cart",
  CART_LIBRARY = "cart-library",
}
interface ICartItemMetadata {
  source: CartItemSource;
  isUpToDate: boolean;
  isLocked: boolean;
}

export const useCartItemMetadata = (item?: ICartItem): ICartItemMetadata => {
  const {customerCartItemIds, activeProjectCartItemIds} = useCartLibrary();
  const {isProjectLocked, isCartEnabled} = useActiveProject();
  const {details} = item ?? {};
  const {factoryId, versionId, productId} = details ?? {};
  const {publishedVersionId} = useFactoryInfo(factoryId) ?? {};
  const {product} = useProduct(productId, factoryId);
  const {inventory_info} = product ?? {};

  const isUpToDate = useMemo(() => {
    console.log(
      "useCartItemMetadata: isUpToDate",
      versionId,
      publishedVersionId
    );
    if (inventory_info) {
      // Inventory items are always up-to-date
      return true;
    }
    return versionId === publishedVersionId;
  }, [versionId, publishedVersionId]);

  const source = useMemo(() => {
    const id = item?.id;
    if (!isCartEnabled) {
      return CartItemSource.PROJECT_CART;
    }
    if (!id) {
      return CartItemSource.UNKNOWN;
    }
    const inProjectCart: boolean = activeProjectCartItemIds.includes(id);
    const inCustomerLibrary: boolean = customerCartItemIds.includes(id);
    if (inProjectCart) {
      return CartItemSource.PROJECT_CART;
    }
    if (inCustomerLibrary) {
      return CartItemSource.CART_LIBRARY;
    }
    return CartItemSource.UNKNOWN;
  }, [item?.id, isCartEnabled, activeProjectCartItemIds, customerCartItemIds]);

  const isLocked = useMemo(() => {
    // Only lock project items
    if (source === CartItemSource.PROJECT_CART) {
      return isProjectLocked ?? false;
    }
    return false;
  }, [source, isProjectLocked]);

  return {
    source,
    isUpToDate,
    isLocked,
  };
};
