import React from "react";
import {IconProps} from "../_shared";
import {ITextOverflow, NatLabelWrapper} from "./styles";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import {usePage} from "../../hooks/usePage";
import ReactTooltip from "react-tooltip";
import {NEUTRAL_COLOR} from "../../colors";
import {NatInfoIcon} from "../../icon/icons";

export enum NatLabelType {
  H1 = "2rem",
  H2 = "1.5625rem",
  H3 = "1.3125rem",
  H4 = "1.3125rem",
  P1 = "1.125rem",
  P2 = "1rem",
  P3 = "0.875rem",
  P4 = "0.75rem",
}

export const NatLabelStyles: {[key in NatLabelType]: React.CSSProperties} = {
  [NatLabelType.H1]: {
    lineHeight: "30.26px",
  },
  [NatLabelType.H2]: {
    lineHeight: "30.26px",
  },
  [NatLabelType.H3]: {},
  [NatLabelType.P1]: {
    lineHeight: "22px",
    margin: "1rem 0",
  },
  [NatLabelType.P2]: {
    margin: "0.5rem 0",
  },
  [NatLabelType.P3]: {
    margin: "0.5rem 0",
  },
  [NatLabelType.P4]: {
    margin: "0.25rem 0",
  },
};

export enum NatLabelWeight {
  REGULAR,
  MEDIUM,
  BOLD,
}

export interface INatLabel {
  label: string | JSX.Element;
  type: NatLabelType;
  icon?: IconProps | undefined;
  weight?: NatLabelWeight;
  style?: React.CSSProperties;
  hidden?: boolean;
  id?: string;
  tooltip?: any;
  tooltipIcon?: boolean;
  tooltipPlacement?: "bottom" | "top";
  textOverflow?: ITextOverflow;
  ignoreMargin?: boolean;
  className?: string;
  onClick?: () => void;
  hoverColor?: string;
  color?: string;
  dataCy?: string;
}

const NatLabel = (props: INatLabel) => {
  const {
    dataCy,
    textOverflow,
    hidden,
    label,
    weight,
    ignoreMargin,
    style: forcedStyle,
    color,
    hoverColor,
    tooltip,
  } = props;
  let fontSize: string = props.type;
  let fontFamily = FONT_FAMILY_REGULAR;
  let style = NatLabelStyles[props.type];
  const styleOverride = forcedStyle ?? {};
  const {isNatMobile} = usePage();

  if (isNatMobile) {
    fontSize = `calc(${fontSize} / 1.2)`;
  }
  switch (weight) {
    case NatLabelWeight.REGULAR:
      fontFamily = FONT_FAMILY_REGULAR;
      break;
    case NatLabelWeight.MEDIUM:
      fontFamily = FONT_FAMILY_MEDIUM;
      break;
    case NatLabelWeight.BOLD:
      fontFamily = FONT_FAMILY_BOLD;
      break;
  }

  if (ignoreMargin) {
    style = {...style, margin: 0};
  }

  let innerContent = (
    <NatLabelWrapper
      data-cy={dataCy}
      id={dataCy}
      className={props.className}
      onClick={props.onClick}
      textOverflow={textOverflow}
      hidden={hidden}
      color={color}
      hoverColor={hoverColor}
      style={{...style, fontSize, fontFamily, ...styleOverride}}
    >
      {label}
    </NatLabelWrapper>
  );
  if (tooltip) {
    return (
      <NatTooltip
        tooltip={tooltip}
        tooltipPlacement={props.tooltipPlacement}
        displayIcon={props.tooltipIcon}
      >
        {innerContent}
      </NatTooltip>
    );
  }
  return innerContent;
};

const NatTooltip = (props: {
  children: React.ReactNode;
  tooltip?: string;
  displayIcon?: boolean;
  tooltipPlacement?: "top" | "right" | "bottom" | "left";
}) => {
  if (props.tooltip) {
    // If there are no children, we just want to return the tooltip triggered by default info icon
    let innerContent: React.ReactNode = <NatInfoIcon color={NEUTRAL_COLOR} />;
    if (props.children) {
      // If there are children, we want to wrap the children in a div with the tooltip
      if (!props.displayIcon) {
        innerContent = props.children;
      } else {
        innerContent = (
          <div
            style={{
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              columnGap: "0.25rem",
            }}
          >
            <NatInfoIcon color={NEUTRAL_COLOR} />
            {props.children}
          </div>
        );
      }
    }
    let randomId = Math.floor(Math.random() * 1000000);
    let toolTipId = `tooltip-element-${randomId}`;
    return (
      <React.Fragment>
        <div data-tip data-for={toolTipId} style={{cursor: "help"}}>
          {innerContent}
        </div>
        <ReactTooltip
          id={toolTipId}
          effect="solid"
          place={props.tooltipPlacement}
        >
          {props.tooltip}
        </ReactTooltip>
      </React.Fragment>
    );
  }

  return null;
};

export default NatLabel;
