export const getSurveySummary = (survey: any) => {
  if (!survey) return "No survey answers found.";
  else {
    let r = "";
    // Map keys nicely, but dump whatever the key value is after
    Object.keys(survey).forEach((key: string) => {
      r +=
        key.charAt(0).toUpperCase() +
        key.slice(1).toLowerCase() +
        ": " +
        survey[key] +
        "\n";
    });
    return r;
  }
};
