import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {DESTRUCTIVE_FILL} from "../../../../colors";

export const AvatarDropdownWarningContainer = styled.div`
  padding: 1.5rem 1.5rem 0.25rem 1.5rem;
  font-size: 0.75rem;
  white-space: pre-wrap;
`;

export const AvatarDropdownWarningLabel = styled.div`
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${DESTRUCTIVE_FILL};
  white-space: pre-wrap;
`;
