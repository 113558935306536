import React from "react";
import {RichTextDiv} from "../../../../../../../../_shared/generics/rich-text-editor/RichTextDiv";
import SSCBlockContainer from "../SSCBlockContainer";
import {FONT_FAMILY_REGULAR} from "../../../../../../../../portal/views/SSDDashboardView/styles/globals";

interface SSCRichTextDivProps {
  content: string;
}

const SSCRichTextDiv = (props: SSCRichTextDivProps) => {
  const {content} = props;

  return (
    <SSCBlockContainer>
      <RichTextDiv
        html={content}
        style={{fontSize: "14px", fontFamily: FONT_FAMILY_REGULAR}}
      />
    </SSCBlockContainer>
  );
};

export default SSCRichTextDiv;
