import React from "react";
import Form from "../../2-component/Form/form";
import {InputLibrary} from "./library/inputs";
import {FormProps} from "../../2-component/Form/interface";
import {InputProps} from "../../2-component/Input/interface";

const getInputs = (resetPassword?: () => void): InputProps[] => {
  return [
    InputLibrary.emailInput,
    {
      ...InputLibrary.passwordInput,
      textProps: {
        text: "Reset password\n\nBy signing in, you agree that you have read, understood and agree to the Terms of Use and have read and acknowledge the Privacy Policy.",
        links: [
          {
            theme: "action",
            replacementKey: "Reset password",
            onClick: resetPassword,
            hidden: !resetPassword,
          },
          {
            replacementKey: "Privacy Policy",
            target: "_blank",
            theme: "bold",
            href: "https://villahomes.com/privacy-policy/",
          },
          {
            replacementKey: "Terms of Use",
            target: "_blank",
            theme: "bold",
            href: "https://villahomes.com/terms-of-use/",
          },
        ],
      },
    },
  ];
};

interface LoginWithEmailFormProps extends FormProps {
  resetPassword?: () => void;
}

const LoginWithEmailForm = (props: LoginWithEmailFormProps) => {
  return (
    <>
      <Form
        inputs={getInputs(props.resetPassword)}
        onSubmit={props.onSubmit}
        buttons={[...(props.buttons ?? [])]}
      />
    </>
  );
};

export default LoginWithEmailForm;
