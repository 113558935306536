import {getPriceSet, productGroupLevelKey} from "../utilities";
import {Dispatch, SetStateAction} from "react";
import {setDeepValue} from "../../../../../../_shared/Utils";
import {AdminProductCatalogMatrixModes} from "../../../../../../_shared/slices/types/Store";

export const saveProductChanges = (
  matrixEdits: any,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setMatrixEdits: Dispatch<SetStateAction<any>>,
  update: (id: string, updates: any) => void
) => {
  setLoading(true);
  const dbUpdates = getProductMatrixChanges(matrixEdits);
  const productIds = Object.keys(dbUpdates);
  productIds?.forEach((pid: string) => {
    // @ts-ignore
    return update(pid, dbUpdates[pid]);
  });
  setMatrixEdits(null);
};

export const saveModifierChanges = (
  matrixEdits: any,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setMatrixEdits: Dispatch<SetStateAction<any>>,
  update: (updates: any) => Promise<void>
) => {
  const dbUpdates = getProductModifierMatrixChanges(matrixEdits);
  update(dbUpdates)?.then(() => {
    setMatrixEdits(null);
  });
};

export const getProductMatrixChanges = (edits: any): any => {
  const productBreakdown = {};
  Object.keys(edits).forEach((key) => {
    let editMap = edits[key];
    const [destination, id] = key.split("_");
    // @ts-ignore
    if (productBreakdown[destination] == null) {
      // @ts-ignore
      productBreakdown[destination] = {};
    }
    Object.keys(editMap).forEach((editKey) => {
      let editValue = editMap[editKey];
      // @ts-ignore
      setDeepValue(productBreakdown[destination], id, editValue);
    });
  });
  return productBreakdown;
};

export const getProductModifierMatrixChanges = (edits: any): any => {
  const productBreakdown = {};
  Object.keys(edits).forEach((key) => {
    let editMap = edits[key];
    const [destination, id] = key.split("_");
    // @ts-ignore
    if (productBreakdown[destination] == null) {
      // @ts-ignore
      productBreakdown[destination] = {};
    }
    Object.keys(editMap).forEach((editKey) => {
      let editValue = editMap[editKey];
      let fieldKey = editKey;
      if (editKey === AdminProductCatalogMatrixModes.PRICE_SET) {
        const priceSet = getPriceSet(editValue);
        // @ts-ignore
        productBreakdown[destination][id] = {
          ["price_set"]: priceSet,
        };
      } else if (editKey === AdminProductCatalogMatrixModes.DESCRIPTIONS) {
        fieldKey =
          destination === productGroupLevelKey
            ? "displayDescriptionDefault"
            : "displayDescription";
        // @ts-ignore
        productBreakdown[destination][id] = {
          [fieldKey]: editValue,
        };
      }
    });
  });
  return productBreakdown;
};
