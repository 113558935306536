import {DesignStudioModifier, IProduct} from "@natomas-org/core";
import {
  IModifierDependency,
  IModifierPricingReview,
  IModifierPricingReviewState,
  ReviewStatus,
  ReviewWarningStatus,
} from "./interface";

const DEV_LOGGING = false;

export const getBadProductModifiers = (
  productModifiers?: {
    [id: string]: any;
  },
  products?: {[id: string]: any},
  modifiers?: {
    [id: string]: DesignStudioModifier;
  },
  edits?: {[id: string]: any}
): IModifierPricingReview[] => {
  const productIds = Object.keys(productModifiers ?? {});
  return productIds
    .map((id) => {
      const product = products?.[id];
      const productModifierDictionary = productModifiers?.[id];
      const productModifierArray: DesignStudioModifier[] = Object.values(
        productModifierDictionary ?? {}
      );
      const toAnalyse: {
        [id: string]: DesignStudioModifier;
      } = {};
      productModifierArray.forEach((pm: any) => {
        const modifier = modifiers?.[pm?.id];
        if (!modifier) {
          return;
        }
        toAnalyse[pm.id] = {
          ...modifiers?.[pm?.id],
          ...pm,
        };
      });
      return analyzeModifiers(toAnalyse, product, edits);
    })
    .flat();
};

export const getBadModifiers = (
  modifiers?: {
    [id: string]: DesignStudioModifier;
  },
  edits?: {[id: string]: any}
): IModifierPricingReview[] => {
  return analyzeModifiers(modifiers ?? {}, undefined, edits);
};

function getReviewPriceInfo(price: number | null, cost: number | null) {
  const isPriceNull = price === null;
  const isCostNull = cost === null;
  if (isPriceNull && isCostNull) {
    return {
      severity: ReviewStatus.WARNING,
      status: ReviewWarningStatus.EMPTY,
      message: "PRICE and COST are NOT SET",
    };
  }
  if (isPriceNull) {
    if (isCostNull) {
      return {
        severity: ReviewStatus.WARNING,
        status: ReviewWarningStatus.EMPTY,
        message: "PRICE and COST are NOT SET",
      };
    } else {
      return {
        severity: ReviewStatus.ERROR,
        message: "COST is SET, PRICE is NOT SET",
      };
    }
  }
  if (isCostNull) {
    if (isPriceNull) {
      return {
        severity: ReviewStatus.WARNING,
        status: ReviewWarningStatus.EMPTY,
        message: "PRICE and COST are NOT SET",
      };
    } else {
      return {
        severity: ReviewStatus.ERROR,
        message: "PRICE is SET, COST is NOT SET",
      };
    }
  }
  if (price === 0 && cost === 0) {
    return {
      severity: ReviewStatus.WARNING,
      status: ReviewWarningStatus.ZERO,
      message: "PRICE and COST are SET to ZERO",
    };
  }
  if (cost === 0) {
    return {
      severity: ReviewStatus.WARNING,
      status: ReviewWarningStatus.COST_ZERO,
      message: "COST is SET to ZERO, PRICE is SET != ZERO",
    };
  }
  if (price === 0) {
    return {
      severity: ReviewStatus.WARNING,
      status: ReviewWarningStatus.PRICE_ZERO,
      message: "PRICE is ZERO, COST is SET != ZERO",
    };
  }
  if (cost > price) {
    return {
      severity: ReviewStatus.WARNING,
      status: ReviewWarningStatus.COST_EXCEEDS_PRICE,
      message: "PRICE and COST are SET, COST is greater than PRICE",
    };
  }
  if (cost === price) {
    return {
      severity: ReviewStatus.WARNING,
      status: ReviewWarningStatus.COST_EQUALS_PRICE,
      message: "PRICE and COST are SET, COST is EQUAL to PRICE",
    };
  }
  if (price > cost) {
    return {
      severity: ReviewStatus.SUCCESS,
      message: "PRICE and COST are SET, PRICE is greater than COST",
    };
  }
  return undefined;
}

const analyzeModifiers = (
  modifiers: {
    [id: string]: DesignStudioModifier;
  },
  product?: IProduct,
  edits?: {[id: string]: any}
): IModifierPricingReview[] => {
  const reviews: IModifierPricingReview[] = [];
  const mods = Object.values(modifiers ?? {});
  mods.forEach((modifier) => {
    const editKey = `${product ? product?.id : "productGroup"}_${modifier?.id}`;
    const editPrices = edits?.[editKey]?.price_set ?? null;
    if (editPrices) {
      DEV_LOGGING && console.log("Found edit prices", editKey, editPrices);
    }
    const savedPrices = modifier?.price_set;
    DEV_LOGGING &&
      console.log("Saved prices", modifier?.internal_title, savedPrices);
    if (!savedPrices && !editPrices) {
      return;
    }
    const prices: IModifierPricingReviewState[] = !!editPrices
      ? editPrices?.map((p: any) => {
          DEV_LOGGING && console.log(p);
          return {
            price: p?.priceMicros ?? null,
            cost: p?.costMicros ?? null,
            condition: p?.condition ?? null,
            type: p?.type ?? null,
            dependencies: getModifierDependencies(
              modifiers,
              p?.requiredModifiers
            ),
          };
        })
      : savedPrices?.map((p: any) => {
          DEV_LOGGING && console.log(p);
          return {
            price: p?.price?.price ?? null,
            cost: p?.price?.cost ?? null,
            condition: p?.condition ?? null,
            type: p?.type ?? null,
            dependencies: getModifierDependencies(
              modifiers,
              p?.dependentModifiers
            ),
          };
        });
    prices?.forEach((modifierPrice: IModifierPricingReviewState) => {
      const cost = modifierPrice.cost;
      const price = modifierPrice.price;
      const priceInfo = getReviewPriceInfo(price, cost);
      if (priceInfo) {
        // No price info
        DEV_LOGGING &&
          console.log(modifier, "Warning", modifierPrice, priceInfo);
        reviews.push({
          modifier,
          status: priceInfo.severity,
          warningStatus: priceInfo.status,
          message: priceInfo.message,
          state: modifierPrice,
          product: product,
          isEdit: !!editPrices,
        });
      } else {
        reviews.push({
          modifier,
          status: ReviewStatus.WARNING,
          warningStatus: ReviewWarningStatus.UNKNOWN,
          message: "Unknown pricing issue, failed to review",
          state: modifierPrice,
          product: product,
          isEdit: !!editPrices,
        });
      }
    });
  });
  return reviews;
};

export const getModifierDependencies = (
  modifiers: {[id: string]: DesignStudioModifier},
  requiredModifiers?: {[id: string]: boolean}
): IModifierDependency[] | null => {
  const ids = Object.keys(requiredModifiers ?? {});
  const deps = ids.map((id) => {
    return getModifierDependency(modifiers, id);
  });
  if (!deps || deps?.length === 0) {
    return null;
  } else {
    return deps;
  }
};
export const getModifierDependency = (
  modifiers: {[id: string]: DesignStudioModifier},
  dependencyModifierId: string
): IModifierDependency => {
  const dependencyModifier = modifiers?.[dependencyModifierId];
  return {
    title:
      dependencyModifier?.internal_title ??
      dependencyModifier?.title ??
      "Unknown",
    id: dependencyModifier?.id ?? "Unknown",
  };
};
