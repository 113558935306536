import React, {useMemo} from "react";
import {ListingStatusDetails} from "@natomas-org/core";
import {SectionTitle, SectionTitleContainer} from "../_shared/styles";
import ProductLinePageContainer from "../_shared/ProductLinePageContainer";
import {useFactoryLines} from "../../../../../_shared/hooks/useProductCatalog/useFactoryLines/useFactoryLines";
import {useSelectedFactoryLineIds} from "../../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {SeriesSummaryColumns} from "./styles";
import SSCBreakdownAdapter from "./SeriesBreakdownAdapter";

/*
 Oct. 2023
 Due to a migration from 2023,
 there is an active factory with only inventory units; jjc415
 Here we can force that factory to be excluded
 */
const permanentlyHiddenFactories = ["jjc415"];

export interface FactorySelectionOption {
  id: string;
  title: string;
}

const SeriesComparisons = () => {
  const {selectedId: selectedFactoryId} = useSelectedFactoryLineIds();

  const {factoryLines} = useFactoryLines({
    // Only listed factories
    listingStatus: {
      [ListingStatusDetails.UNLISTED]: false,
      [ListingStatusDetails.LISTED]: true,
      [ListingStatusDetails.HIDDEN]: false,
      [ListingStatusDetails.NULL]: false,
    },
    // Only eligible factories
    onlyEligible: true,
    excludeFactories: selectedFactoryId
      ? [selectedFactoryId, ...permanentlyHiddenFactories]
      : [...permanentlyHiddenFactories],
  });

  const otherFactorySelections: FactorySelectionOption[] = useMemo(() => {
    return factoryLines?.map((factory): FactorySelectionOption => {
      return {
        id: factory.id,
        title: factory.title,
      };
    });
  }, [factoryLines]);

  // Only show comparator if there is a selected factory and other factories to compare to
  if (!selectedFactoryId || otherFactorySelections?.length === 0) {
    return null;
  }

  return (
    <ProductLinePageContainer>
      <SectionTitleContainer>
        <SectionTitle style={{padding: 0}}>Series Comparisons</SectionTitle>
      </SectionTitleContainer>
      <SeriesSummaryColumns>
        <SSCBreakdownAdapter
          primaryId={selectedFactoryId}
          options={otherFactorySelections}
        />
      </SeriesSummaryColumns>
    </ProductLinePageContainer>
  );
};

export default SeriesComparisons;
