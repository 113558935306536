import React from "react";
import {FullScreenDesignSelections} from "../HCPUnitView/DesignSelections/FullScreenDesignSelections";
import {SitePlanView} from "../../FPDashboardLayout/FPSiteMapView/SitePlanView";
import {ProposalBudgetSummaryView} from "../../ProposalBudgetView/ProposalBudgetSummaryView";
import {ProposalTeamView} from "../../ProposalTeamView/ProposalTeamView";
import {Phase1View} from "../../Phase1View/Phase1View";
import {B2BPresentationSplash} from "../HCPPresentationView/B2B/components/B2BPresentationSplash";
import {DTCPresentationSplash} from "../HCPPresentationView/DTC/components/DTCPresentationSplash";
import {WARM_NEUTRAL} from "../../../../_shared/colors";
import {B2BProposalSummary} from "../../ProposalBudgetView/components/Presentation/ProposalSummary/B2BProposalSummary";
import {ProposalPhase1AndNextSteps} from "../../ProposalBudgetView/components/Presentation/ProposalPhase1AndNextSteps/ProposalPhase1AndNextSteps";
import {QualificationsAndAssumptions} from "../../ProposalBudgetView/components/Presentation/QualificationsAndAssumptions/QualificationsAndAssumptions";
import {B2BFinalSlide} from "../../ProposalBudgetView/components/Presentation/B2BFinalSlide/B2BFinalSlide";
import {B2BTimelineSlide} from "../../ProposalBudgetView/components/Presentation/B2BTimelineSlide/B2BTimelineSlide";
import {DTCTimelineSlide} from "../../ProposalBudgetView/components/Presentation/DTCTimelineSlide/DTCTimelineSlide";
import CustomerEstimateTable from "../../../../estimate/CustomerEstimate/CustomerEstimateTable";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {ProjectPriceMode} from "@natomas-org/core";

export const PORTAL_GREETING_ID = "HCP_GREETING";
export const PORTAL_OVERVIEW_ID = "FP_OVERVIEW";
export const DTC_PORTAL_NEXT_STEPS_ID = "HCP_NEXT_STEPS";
export const PORTAL_TASKS_AND_DOCUMENTS_ID = "FP_TASKS_AND_DOCUMENTS";
export const PORTAL_TEAM_ID = "FP_TEAM";
export const PORTAL_B2B_TIMELINE = "B2B_TIMELINE";
export const PORTAL_B2B_PHASE1_AND_NEXT_STEPS = "B2B_PHASE1_AND_NEXT_STEPS";
export const PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS =
  "B2B_QUALIFICATIONS_AND_ASSUMPTIONS";
export const PORTAL_B2B_CLOSING_SLIDE = "B2B_CLOSING_SLIDE";
export const PORTAL_DOCUMENTS_ID = "HCP_DOCUMENTS";
export const PORTAL_TOUR_ID = "HCP_TOUR";
export const PORTAL_LINKS_ID = "HCP_LINKS";
export const PORTAL_CALENDAR_ID = "HCP_CALENDAR";
export const PORTAL_UNIT_VIEW_ID = "HCP_UNIT_VIEW";
export const PORTAL_SITE_MAP_ID = "HCP_SITE_MAP";
export const PORTAL_BUDGET_OVERVIEW_ID = "HCP_BUDGET_OVERVIEW";
export const PORTAL_BUDGET_ID = "HCP_BUDGET";
export const PORTAL_NOTES_ID = "FP_NOTES";
export const DTC_PORTAL_TIMELINE_ID = "FP_TIMELINE";

export const FP_SECTIONS = [
  {label: "Overview", id: PORTAL_OVERVIEW_ID},
  {label: "Tasks and Documents", id: PORTAL_TASKS_AND_DOCUMENTS_ID},
  {label: "Project Notes", id: PORTAL_NOTES_ID},
  {label: "Home Design", id: PORTAL_UNIT_VIEW_ID},
  {label: "Site Plan", id: PORTAL_SITE_MAP_ID},
  {label: "Budget", id: PORTAL_BUDGET_ID},
];

export const HCP_SECTIONS = [
  {label: "Overview", id: PORTAL_GREETING_ID},
  {label: "Home & Design", id: PORTAL_UNIT_VIEW_ID},
  {label: "Site Plan", id: PORTAL_SITE_MAP_ID},
  {label: "Project Budget", id: PORTAL_BUDGET_ID},
  {label: "Calendar", id: PORTAL_CALENDAR_ID},
  {label: "Schedule Tour", id: PORTAL_TOUR_ID},
  {label: "Project Schedule", id: DTC_PORTAL_TIMELINE_ID},
  {label: "Documents", id: PORTAL_DOCUMENTS_ID},
  {label: "Useful Links", id: PORTAL_LINKS_ID},
];
export const PROJECT_BREAKDOWN_TITLE = "Project Proposal Breakdown";
export const usePresentationSections = (): {
  label: string;
  value: string;
  component: JSX.Element;
  background?: string;
  hideWhenGeneratingPDF?: boolean;
  formatWithPageSystem?: boolean;
}[] => {
  const {isDevelopmentByVilla, projectPriceMode} = useActiveProject();
  if (isDevelopmentByVilla || projectPriceMode === ProjectPriceMode.CostPlus) {
    return B2B_PRESENTATION_SECTIONS;
  }
  let sections = [
    {
      label: "",
      value: PORTAL_GREETING_ID,
      component: <DTCPresentationSplash />,
      background:
        "linear-gradient(246.89deg, rgba(130, 194, 117, 0.3) -2.82%, rgba(173, 214, 194, 0.3) 104.09%)",
      hideWhenGeneratingPDF: false,
    },
    {
      label: "",
      value: PORTAL_UNIT_VIEW_ID,
      component: <FullScreenDesignSelections />,
    },
    {
      label: "Your Site Plan",
      value: PORTAL_SITE_MAP_ID,
      component: <SitePlanView />,
    },
  ];
  sections.push({
    label: PROJECT_BREAKDOWN_TITLE,
    value: PORTAL_BUDGET_ID,
    component: <CustomerEstimateTable />,
  });
  sections.push(
    ...[
      {
        label: "Project Schedule",
        value: DTC_PORTAL_TIMELINE_ID,
        component: <DTCTimelineSlide />,
      },
      {
        label: "Phase 1",
        value: DTC_PORTAL_NEXT_STEPS_ID,
        component: <Phase1View />,
      },
      {
        label: "Meet the preconstruction team",
        value: PORTAL_TEAM_ID,
        component: <ProposalTeamView />,
      },
    ]
  );
  return sections;
};

const B2B_PRESENTATION_SECTIONS: {
  label: string;
  value: string;
  component: JSX.Element;
  background?: string;
  hideWhenGeneratingPDF?: boolean;
  formatWithPageSystem?: boolean;
  hideLineSeparator?: boolean;
}[] = [
  {
    label: "",
    value: PORTAL_GREETING_ID,
    component: <B2BPresentationSplash />,
    background: WARM_NEUTRAL,
    hideWhenGeneratingPDF: false,
    formatWithPageSystem: false,
    hideLineSeparator: true,
  },
  {
    label: "Your Site Plan",
    value: PORTAL_SITE_MAP_ID,
    component: <SitePlanView />,
    formatWithPageSystem: true,
  },
  {
    label: "Project Proposal Summary",
    value: PORTAL_BUDGET_OVERVIEW_ID,
    component: <B2BProposalSummary presentationView={true} />,
    formatWithPageSystem: true,
  },
  {
    label: PROJECT_BREAKDOWN_TITLE,
    value: PORTAL_BUDGET_ID,
    formatWithPageSystem: true,
    component: (
      <ProposalBudgetSummaryView
        hideCostCardDescription={true}
        hideCostCard={true}
        presentationView={true}
      />
    ),
  },
  {
    label: "",
    value: PORTAL_UNIT_VIEW_ID,
    formatWithPageSystem: true,
    component: <FullScreenDesignSelections />,
  },
  {
    label: "Project Schedule",
    value: PORTAL_B2B_TIMELINE,
    formatWithPageSystem: true,
    component: (
      <B2BTimelineSlide
        displayOptions={{
          showSectionTitles: true,
          showLabelsOnBars: false,
          showTooltip: true,
          showLegend: true,
          displayQuarters: true,
        }}
      />
    ),
  },
  {
    label: "",
    value: PORTAL_B2B_PHASE1_AND_NEXT_STEPS,
    component: <ProposalPhase1AndNextSteps presentationView={true} />,
    formatWithPageSystem: false,
  },
  {
    label: "",
    value: PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS,
    component: <QualificationsAndAssumptions />,
    formatWithPageSystem: false,
  },
  {
    label: "",
    value: PORTAL_B2B_CLOSING_SLIDE,
    component: <B2BFinalSlide />,
    hideWhenGeneratingPDF: false,
    formatWithPageSystem: false,
    hideLineSeparator: true,
  },
];
