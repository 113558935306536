import React from "react";
import styled from "styled-components";
import {NatStarFillIcon} from "../../../../_shared/icon/icons";
import {BLACK} from "../../../../_shared/colors";

export interface ICatalogBadge {
  label: string;
  color: string;
  isMobile?: boolean;
}

const Badge = styled.div<{color: string; isMobile?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  background-color: ${(props) => props.color};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  ${(props) =>
    props?.isMobile &&
    `
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 11px;
  `}
`;

const CatalogBadge = (props: ICatalogBadge) => {
  const {label, color, isMobile} = props;

  return (
    <Badge color={color} isMobile={isMobile}>
      <NatStarFillIcon color={BLACK} />
      {label}
    </Badge>
  );
};

export default CatalogBadge;
