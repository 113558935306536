import {
  IFactoryInventoryCategoryMap,
  IFactoryInventoryModifierGroupMap,
  IFactoryInventoryModifierMap,
} from "./interfaces";
import {
  FactoryInventoryPricingEditorKey,
  IFactoryInventoryPricingEditor,
} from "./formik/interfaces";

export const getConfigurationData = (
  configuration: any
): {
  categories: IFactoryInventoryCategoryMap;
  modifierGroups: IFactoryInventoryModifierGroupMap;
  modifiers: IFactoryInventoryModifierMap;
} => {
  let categories: IFactoryInventoryCategoryMap = {};
  let modifierGroups: IFactoryInventoryModifierGroupMap = {};
  let modifiers: IFactoryInventoryModifierMap = {};

  configuration?.configurationSnapshot?.forEach((category: any) => {
    if (category?.id) {
      category?.fullModifierGroups?.forEach((modifierGroup: any) => {
        if (modifierGroup?.id) {
          modifierGroup?.fullModifiers?.forEach((modifier: any) => {
            if (modifier?.id) {
              modifiers[modifier.id] = {
                id: modifier?.id,
                title: modifier?.title,
                categoryId: category?.id,
                modifierGroupId: modifierGroup?.id,
                priceInMicros: getBaselineDefaultPriceInMicros(
                  modifier?.price_set
                ),
              };
            }
          });
          modifierGroups[modifierGroup?.id] = {
            id: modifierGroup.id,
            title: modifierGroup?.title,
          };
        }
      });
      categories[category?.id] = {
        id: category.id,
        title: category?.title,
      };
    }
  });
  return {categories, modifierGroups, modifiers};
};

const getBaselineDefaultPriceInMicros = (priceSet: any) => {
  const defaultPricing = priceSet?.find(
    (priceObj: any) =>
      priceObj?.type === "baseline" && priceObj.condition === "default"
  );
  return defaultPricing?.price?.price ?? 0;
};

export const getPricingSummary = (
  product: IFactoryInventoryPricingEditor,
  upgradeTotal: number
) => {
  const basePrice = product?.[FactoryInventoryPricingEditorKey.UNIT_PRICE] ?? 0;
  const baseCost = product?.[FactoryInventoryPricingEditorKey.UNIT_COST] ?? 0;
  const salesTaxCost =
    product?.[FactoryInventoryPricingEditorKey.SALES_TAX] ?? 0;
  const totalPrice = basePrice + upgradeTotal;
  const totalCost = baseCost + salesTaxCost;
  const marginAmount = totalPrice - totalCost;
  const marginPercentage =
    Math.round((marginAmount / totalPrice) * 1000000) / 10000;
  return {
    upgradeTotal,
    basePrice,
    baseCost,
    salesTaxCost,
    totalPrice,
    totalCost,
    marginAmount,
    marginPercentage,
  };
};
