import styled from "styled-components";
import {ButtonTheme} from "./interface";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  row-gap: 8px;
`;

const Button = styled.button<{
  theme: ButtonTheme;
}>`
  height: 42px;
  line-height: 16px;
  font-size: 16px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  min-width: 200px;
  text-align: center;
  ${(props) => {
    switch (props.theme) {
      case "tertiary":
        return `
          background-color: #FFFFFF;
          border: 1px solid #FFFFFF;
          color: #368836;
        `;
      case "secondary":
        return `
          background-color: #FFFFFF;
          border: 1px solid #D0D5DD;
          color: #000000;
        `;
      case "primary":
      default:
        return `
          background-color: #368836;
          border: 1px solid #368836;
          color: #FFFFFF;
        `;
    }
  }}

  font-family: Inter-Regular, serif;
  font-weight: 400;

  // Focus Override - Accessibility
  &:focus {
    border-color: var(--semantic-informational);
  }
  // Disabled Override
  &:disabled {
    background-color: #ffffff;
    border: 1px solid #d0d5dd;
    color: #d0d5dd;
    cursor: not-allowed;
  }

  // Transition
  transition: color 275ms, font-size 275ms, background-color 275ms,
    border-color 275ms;
`;

const Error = styled.p`
  color: orangered;
  font-size: 12px;
  font-family: Inter-Regular, serif;
  font-weight: 400;
  padding: 0;
  margin: 0;
`;

export {Container, Button, Error};
