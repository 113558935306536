import React, {Fragment, useMemo} from "react";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {useDesigns} from "../../../../../_shared/hooks/useDesign/useDesigns";
import {useCartItems} from "../../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import useActiveDesign from "../../../../../_shared/hooks/useDesign/useActiveDesign";
import useActivitySlice from "../../../../../_shared/hooks/useActivity/useActivitySlice";
import {ProductScreenDesignSelections} from "./ProductDesignSelections";
import {isBlankString} from "@natomas-org/core";

export const FullScreenDesignSelections = React.memo(() => {
  const {activeCartItemId} = useActivitySlice();
  const {cartItemIds, isCartEnabled} = useActiveProject();
  const activeDesign = useActiveDesign();
  const cartItems = useCartItems(cartItemIds);
  const designsHook = useDesigns(cartItemIds);
  const designs = useMemo(() => {
    if (!isCartEnabled) {
      // If cart not enabled
      // Check if the active cart item id exists is within the project's cartItemIds
      if (!activeCartItemId || !cartItemIds?.includes(activeCartItemId)) {
        // If it's not, use the first available item within cartItemIds
        if (cartItemIds?.length > 0) {
          return {[cartItemIds[0]]: designsHook[cartItemIds[0]]};
        } else {
          // If there are no cart items, return an empty object
          return {};
        }
      } else {
        // If the active cart item is valid, but no cart enabled just return the active design
        return {[activeCartItemId]: activeDesign};
      }
    }
    // If cart is enabled, return the useDesigns(cartItemIds) hook values
    return designsHook;
  }, [activeCartItemId, activeDesign, cartItemIds, designsHook, isCartEnabled]);
  let previousDesignBlank = false;
  return (
    <Fragment>
      {Object.values(designs).map((design, idx) => {
        const title = isCartEnabled
          ? `${cartItems[idx]?.details?.display_code} (x${cartItems[idx]?.quantity})`
          : `Your Home`;
        const pageBreakBefore =
          previousDesignBlank && false ? "always" : "avoid";
        if (!design?.id || !design?.snapshot || isBlankString(title)) {
          previousDesignBlank = true;
          return null;
        }
        return (
          <Fragment>
            {idx > 0 ? (
              <hr
                style={{
                  width: "100%",
                  height: previousDesignBlank ? "0" : "4px",
                  margin: "0 0",
                  pageBreakBefore: pageBreakBefore,
                  breakBefore: pageBreakBefore,
                }}
              />
            ) : null}
            <ProductScreenDesignSelections
              index={idx}
              title={title}
              designId={design.id}
              key={`${design.id}${idx}`}
              cartItemId={cartItemIds[idx]}
            />
          </Fragment>
        );
      })}
    </Fragment>
  );
});
