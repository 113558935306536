import {IAdminProduct} from "../_shared/hooks/admin-only-hooks/useAdminProducts";
import {IAdminFactoryLine} from "../_shared/hooks/admin-only-hooks/useAdminFactoryLine/useAdminFactoryLines";
import {NatomasMapFeature, NatomasMapFeatureType} from "@natomas-org/core";
import {getNatomasMapFeature} from "../_shared/generics/feature-map/FeatureMapSideBar/helper";

export const getAdminProductFeatureLabel = (p: IAdminProduct): string => {
  return getFeatureLabel(
    "Unnamed Product",
    p?.details?.internal_title,
    p?.details?.title
  );
};

export const getAdminFactoryLineFeatureLabel = (
  f: IAdminFactoryLine
): string => {
  return getFeatureLabel(
    "Unnamed Factory Line",
    f?.info?.globalTitle,
    f?.info?.name
  );
};

export const getFeatureLabel = (
  defaultTitle: string,
  firstOption?: string | null,
  secondOption?: string | null
): string => {
  return firstOption && firstOption?.length > 0
    ? firstOption
    : secondOption && secondOption?.length > 0
    ? secondOption
    : defaultTitle;
};

export const getAdminProductNatomasMapFeature = (
  adminProduct: IAdminProduct
): NatomasMapFeature => {
  return {
    id: adminProduct?.id,
    label: getAdminProductFeatureLabel(adminProduct),
    type: NatomasMapFeatureType.PRODUCT,
    properties: {factoryId: adminProduct?.details?.productGroupId ?? "UNKNOWN"},
  };
};

export const getAdminFactoryLineNatomasMapFeature = (
  adminFactoryLine: IAdminFactoryLine
): NatomasMapFeature => {
  return {
    id: adminFactoryLine?.id,
    label: getAdminFactoryLineFeatureLabel(adminFactoryLine),
    type: NatomasMapFeatureType.FACTORY_LINE,
  };
};

export const getInitialAdminProductNatomasMapFeature = (
  id: string,
  factoryId: string,
  label: string
): NatomasMapFeature => {
  return {
    ...getNatomasMapFeature(id, label, NatomasMapFeatureType.PRODUCT),
    properties: {factoryId: factoryId},
  };
};

export const getInitialAdminFactoryLineNatomasMapFeature = (
  id: string,
  label: string
): NatomasMapFeature => {
  return getNatomasMapFeature(id, label, NatomasMapFeatureType.FACTORY_LINE);
};
