import React from "react";
import {StyledSSCBlockContainer} from "./styles";
import {useColumnCount} from "../../../../../../../../_shared/hooks/usePage";

interface SSCBlockContainerProps {
  children: React.ReactNode;
}

const SSCBlockContainer = (props: SSCBlockContainerProps) => {
  const {children} = props;
  const {isNatMobile} = useColumnCount();
  return (
    <StyledSSCBlockContainer isMobile={isNatMobile}>
      {children}
    </StyledSSCBlockContainer>
  );
};

export default SSCBlockContainer;
