import React from "react";
import {useCartItemSnapshot} from "../../../../../_shared/hooks/useCart/useCartItem/useCartItemSnapshot";
import {ICartItem} from "@natomas-org/core";
import {
  NatModal,
  NatModalSize,
} from "../../../../../_shared/generics/modal/NatModal";
import useActivitySliceDispatcher from "../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import CartItemDesignPreview from "./CartItemDesignPreview/CartItemDesignPreview";
import CartItemProductPreview from "./CartItemProductPreview";
import {Divider} from "../../../../../_shared/generics/divider";
import {CartItemPreviewContainer} from "./styles";
import CartItemPreviewPricing from "./CartItemPreviewPricing";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";
import {NatButton} from "../../../../../_shared/generics/button";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import {StyleOption} from "../../../../../_shared/generics/_shared";

const CartItemPreview = (props: {
  cartItem: ICartItem | undefined;
  onClose: () => void;
}) => {
  const {to} = useNavigation();
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const {cartItem, onClose} = props;
  const {product, snapshot, pricing, projectPriceMode} = useCartItemSnapshot(
    cartItem ?? ({} as ICartItem)
  );

  const {details, id: cartItemId} = cartItem ?? {};
  const {display_code} = details ?? {};

  return (
    <NatModal
      show={!!cartItem}
      handleClose={() => {
        onClose();
      }}
      header={display_code}
      size={NatModalSize.FULLSCREEN}
      content={
        <CartItemPreviewContainer>
          <CartItemProductPreview product={product} />
          <CartItemPreviewPricing
            unitPrice={pricing?.baseUnitPriceMicros ?? 0}
            upgradePrice={pricing?.upgradesPriceMicros ?? 0}
            totalPrice={pricing?.unitAndUpgradesPriceMicros ?? 0}
          />
          <NatButton
            disabled={product?.disableCustomization}
            label={
              product?.disableCustomization
                ? "Customization Unavailable"
                : "Edit Design"
            }
            clickEvent={() => {
              setActiveCartItemById(cartItemId);
              to(NavigationPaths.STUDIO, {cartItemId: cartItemId});
            }}
            option={StyleOption.PRIMARY_BLACK}
          />
          <Divider />
          <CartItemDesignPreview
            snapshot={snapshot}
            pricingMode={projectPriceMode}
          />
        </CartItemPreviewContainer>
      }
    />
  );
};

export default CartItemPreview;
