import {
  BUDGET_SECTION_ADDITIONAL,
  BUDGET_SECTION_UNIT,
  BUDGET_SUMMARY_SECTION_TYPE,
  PDFProposal,
  ProjectPriceMode,
  TBudgetSection,
} from "@natomas-org/core";
import {Page, Text} from "@react-pdf/renderer";
import {styles} from "./styles";
import {RPDFTable} from "../../../_shared/generics/pdf/RPDFTable";
import React from "react";
import {IPageProps} from "./template";

interface IBudgetSummaryPagesProps extends IPageProps {
  priceMode?: ProjectPriceMode;
  tables?: any;
  nexus?: boolean;
}

const BudgetSummaryPages = (props: IBudgetSummaryPagesProps) => {
  const {priceMode, tables, footer} = props;
  if (!props.nexus) {
    return (
      <>
        {[
          ...Object.keys(BUDGET_SUMMARY_SECTION_TYPE),
          BUDGET_SECTION_UNIT,
          BUDGET_SECTION_ADDITIONAL,
        ].map((key, index) => {
          const section = key;
          if (
            priceMode === ProjectPriceMode.CostPlus &&
            ![
              BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_COSTPLUS,
              BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DESIGN_COSTPLUS,
              BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_PERMITS_COSTPLUS,
              BUDGET_SECTION_UNIT,
              BUDGET_SECTION_ADDITIONAL,
            ].includes(section)
          ) {
            return null;
          } else if (
            priceMode === ProjectPriceMode.DTC &&
            ![
              BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_DTC,
              BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DTC,
              BUDGET_SECTION_UNIT,
              BUDGET_SECTION_ADDITIONAL,
            ].includes(section)
          ) {
            return null;
          }
          const data: PDFProposal.Nat_RPDF_Table_Text_Data_Row[] =
            tables?.[section];
          if (data?.length < 2) {
            return null;
          }
          let title = getReadableSectionTitle(
            section as unknown as TBudgetSection
          );
          if (section === BUDGET_SECTION_UNIT) {
            title = "Home Design";
          }
          return (
            <Page
              orientation={"landscape"}
              wrap
              key={`budget-${index}`}
              style={styles.page}
            >
              <Text style={styles.title}>{title}</Text>
              <RPDFTable data={data} />
              {footer}
            </Page>
          );
        })}
      </>
    );
  }
  // If not nexus, return without filtering by section
  return (
    <>
      {Object.entries(tables).map(([title, data], index) => {
        let sanitizedTitle = title;
        if (
          [
            BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_COSTPLUS,
            BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DESIGN_COSTPLUS,
            BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_PERMITS_COSTPLUS,
            BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DTC,
            BUDGET_SUMMARY_SECTION_TYPE.HOME_DESIGN_COSTPLUS,
            BUDGET_SECTION_UNIT,
            BUDGET_SECTION_ADDITIONAL,
          ].includes(sanitizedTitle)
        ) {
          sanitizedTitle = getReadableSectionTitle(
            title as unknown as TBudgetSection
          );
        }
        return (
          <Page
            orientation={"landscape"}
            wrap
            key={`budget-${index}`}
            style={styles.page}
          >
            <Text style={styles.title}>{sanitizedTitle}</Text>
            <RPDFTable
              data={data as PDFProposal.Nat_RPDF_Table_Text_Data_Row[]}
            />
            {footer}
          </Page>
        );
      })}
    </>
  );
};

function getReadableSectionTitle(key: TBudgetSection): string {
  switch (key) {
    case BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_COSTPLUS:
    case BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_DTC:
      return "Site Work";
    case BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DESIGN_COSTPLUS:
      return "Preconstruction - Design";
    case BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_PERMITS_COSTPLUS:
      return "Preconstruction - Permits";
    case BUDGET_SUMMARY_SECTION_TYPE.HOME_DESIGN_COSTPLUS:
      return "Home Design";
    case BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DTC:
      return "Preconstruction";
    case BUDGET_SECTION_ADDITIONAL:
      return "Additional";
    case BUDGET_SECTION_UNIT:
      return "Home Design";
    default:
      return "";
  }
}

export default BudgetSummaryPages;
