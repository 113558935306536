import React, {useEffect, useMemo, useState} from "react";
import GoogleMapReact from "google-map-react";
import pin from "../../../components/admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/QuickInfoTab/GeneralInfoSection/assets/pin.png";
import VCL from "@natomas-org/villa-component-library";
import {
  InstantQuoteMapPin,
  InstantQuoteMapPinContainer,
  InstantQuoteMapPinLabel,
} from "./styles";
import {FONT_FAMILY_MEDIUM} from "../../../components/portal/views/SSDDashboardView/styles/globals";

// TODO Remove this
const GOOGLE_MAPS_API_KEY = "AIzaSyCwZWAlda8aZcqdbNqTaU_AqZ2Dqj7-vMM";

interface MapLocation {
  latitude: number;
  longitude: number;
  label: string;
}
const defaultZoom = 1;
const MultiPointMap = (props: {
  points: MapLocation[];
  edit: (lat: number, lng: number) => void;
}) => {
  const {points, edit} = props;
  const center = useMemo(() => getCenter(points), [points]);
  const [zoom, setZoom] = useState<number>(defaultZoom);
  const [coordinates, setCoordinates] = useState<{lat: number; lng: number}>({
    lat: center.latitude,
    lng: center.longitude,
  });

  useEffect(() => {
    setCoordinates({lat: center.latitude, lng: center.longitude});
    setZoom(defaultZoom);
  }, [center.latitude, center.longitude]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{key: GOOGLE_MAPS_API_KEY}}
      defaultCenter={{
        lat: 37.7749,
        lng: -122.4194,
      }}
      center={coordinates}
      defaultZoom={defaultZoom}
      zoom={zoom}
      onClick={(value: any) => {
        const {lat, lng} = value;
        setCoordinates({lat, lng});
        setZoom(1);
      }}
    >
      {points.map((point, index) => (
        <InstantQuoteMapPinComponent
          key={index}
          // @ts-ignore GoogleMapReact uses child lat lng ?
          lat={point.latitude}
          lng={point.longitude}
          text={point.label}
          select={() => {
            setCoordinates({lat: point.latitude, lng: point.longitude});
            setZoom(12);
          }}
          edit={() => edit(point.latitude, point.longitude)}
          isSelected={
            coordinates.lat === point.latitude &&
            coordinates.lng === point.longitude
          }
        />
      ))}
    </GoogleMapReact>
  );
};

const InstantQuoteMapPinComponent = (props: {
  text: string;
  edit: () => void;
  select: () => void;
  isSelected: boolean;
}) => {
  const {text, select, edit, isSelected} = props;
  return (
    <>
      <InstantQuoteMapPinContainer>
        <InstantQuoteMapPin
          src={pin}
          alt="pin"
          onClick={() => {
            select();
          }}
        />
        <InstantQuoteMapPinLabel
          style={{
            justifyContent: "center",
            visibility: isSelected ? "visible" : "hidden",
            alignItems: "center",
            fontSize: "14px",
            color: "#2d2d2d",
            gap: "12px",
            fontFamily: FONT_FAMILY_MEDIUM,
          }}
          className={"vcl-container-row-spaced"}
        >
          {text}
          <VCL.Components.Atoms.Button
            label={"Edit"}
            onClick={edit}
            theme={"secondary"}
            size={"small"}
          />
        </InstantQuoteMapPinLabel>
      </InstantQuoteMapPinContainer>
    </>
  );
};

function getCenter(locations: MapLocation[]): {
  latitude: number;
  longitude: number;
} {
  if (locations.length === 0) {
    return {
      latitude: 0,
      longitude: 0,
    };
  }

  let totalLatitude = 0;
  let totalLongitude = 0;

  for (const location of locations) {
    totalLatitude += location.latitude;
    totalLongitude += location.longitude;
  }

  const centerLatitude = totalLatitude / locations.length;
  const centerLongitude = totalLongitude / locations.length;

  return {latitude: centerLatitude, longitude: centerLongitude};
}

export default MultiPointMap;
