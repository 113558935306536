import {
  GenericBudgetSummary,
  IGenericBudgetSummaryProps,
} from "../../generic/GenericBudgetSummary";
import React from "react";

export const DTCProposalSummary = (props: IGenericBudgetSummaryProps) => {
  return (
    <GenericBudgetSummary
      displayType={null}
      isAdmin={props.isAdmin}
      viewOnly={props.viewOnly}
      isFullView={props.isFullView}
      {...props}
    />
  );
};
