import styled from "styled-components";

export const SitePlanTitleComponent = styled.div<{alignCenter?: boolean}>`
  text-align: ${(props) => (props.alignCenter ? "center" : "left")};
  margin-bottom: 25px;
`;

export const SitePlanTitle = styled.div`
  text-align: left;
  font-size: 25px;
  flex-grow: 2;
  min-width: 102px;
`;

export const SitePlanAddress = styled.div`
  font-size: 18px;
`;

export const PDFWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  position: relative;
  //width: 100%;
  //height: 100%;
  cursor: pointer;
`;
