import React from "react";
import {PDFProposal} from "@natomas-org/core";
import {Text, View} from "@react-pdf/renderer";
import {styles} from "./styles";

interface SitePlanPageFooterProps {
  company: PDFProposal.ICompanyInfo;
  generatedAt: number;
}

const SitePlanPageHeader = (props: SitePlanPageFooterProps) => {
  const {company, generatedAt} = props;
  if (!company) {
    return (title: string) => <></>;
  }
  const companyInfo = `${company.title} | ${company.address} | CSLB#${company.CSLB} | HCD#${company.HCD}`;
  const dateTime = `${new Date(generatedAt).toLocaleDateString()} ${new Date(
    generatedAt
  ).toLocaleTimeString()}`;
  return (title: string) => (
    <View style={styles.sitePlanHeader}>
      <View style={styles.flexSpacedContainer}>
        <Text>{companyInfo}</Text>
      </View>
      <View style={styles.flexSpacedContainer}>
        <Text>{title}</Text>
        <Text>{dateTime}</Text>
        <Text
          render={({pageNumber, totalPages}) =>
            `Page ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </View>
  );
};

export default SitePlanPageHeader;
