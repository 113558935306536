import React from "react";
import {useImage} from "../../../../../hooks/useImage";
import {NatButton} from "../../../../button";
import {downloadFileToDesktop} from "../../../../../../portal/views/SitePlanView/logic";
import {IconPosition, NatSize, StyleOption} from "../../../../_shared";
import {FiDownload} from "react-icons/fi";

export const ImageDownloadButton = (props: {
  id: string;
  iconOnly?: boolean;
}) => {
  const {id, iconOnly} = props;
  const data = useImage(id);
  return (
    <NatButton
      label={"Download"}
      icon={
        iconOnly
          ? {
              icon: <FiDownload />,
              iconPosition: IconPosition.ONLY,
            }
          : undefined
      }
      type={"button"}
      option={StyleOption.PRIMARY_ALT}
      size={NatSize.SMALL}
      clickEvent={() => {
        const url = data?.original?.url;
        if (url && data?.id) {
          if (data?.title) {
            downloadFileToDesktop(url, data.title + ".png");
          } else {
            downloadFileToDesktop(url, "photo_" + data.id + ".png");
          }
        } else {
          window.alert("Failed to download image.");
        }
      }}
    />
  );
};
