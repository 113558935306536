import styled from "styled-components";
import {HEADER_Z_INDEX} from "../../../../../../../_shared/styles";

export const InventoryTable = styled.table<{stickyHeight?: number}>`
  border-spacing: 0;
  border: 1px solid black;
  border-collapse: separate; /* Don't collapse */
  thead {
    position: sticky;
    top: ${(props) => props?.stickyHeight ?? 0}px;
    z-index: ${HEADER_Z_INDEX};
  }
  th,
  td {
    max-width: 300px;
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    font-size: 12px;
    :last-child {
      border-right: 0;
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const ColumnControllerWrapper = styled.div<{stickyHeight?: number}>`
  min-width: 200px;
  width: 200px;
  position: sticky;
  top: ${(props) => props.stickyHeight ?? 0}px;
  height: fit-content;
`;

export const ValueContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  column-gap: 8px;
`;
