import {
  checkboxColumn,
  Column,
  createTextColumn,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import {
  SelectComponent,
  SelectOptions,
} from "../../portal/views/ProposalBudgetView/components/generic/EditorComponents/SelectColumn";
import {IBucketConfig} from "./buckets";
import {fullPriceTextFromMicros} from "@natomas-org/core";
import {BudgetColumns} from "../../portal/views/ProposalBudgetView/components/generic/GenericBudgetEditor";
import onPaste from "./paste";
import {BudgetEntryCellProps} from "./EstimateEditorTable";

const genericColumnProps = {
  minWidth: 250,
  disabled: (data: {
    rowData: BudgetEntryCellProps | any;
    rowIndex: number;
    columnId?: string;
  }) => {
    return (
      ["Home Design"].includes(data?.rowData?.bucket) && data?.rowData?.locked
    );
  },
  cellClassName: (data: {
    rowData: BudgetEntryCellProps | any;
    rowIndex: number;
    columnId?: string;
  }) => {
    let baseClass = "cell";
    switch (data.columnId) {
      case "bucket":
        baseClass = "bucket-cell";
        break;
      case "name":
        baseClass = "title-cell";
        break;
      case "notes":
        baseClass = "notes-cell";
        break;
      case "price_low":
        baseClass = "price-cell-low";
        break;
      case "price_high":
        baseClass = "price-cell-high";
        break;
      case "cost_low":
        baseClass = "cost-cell-low";
        break;
      case "cost_high":
        baseClass = "cost-cell-high";
        break;
      case "gp_low":
        baseClass = "gp-cell-low";
        break;
      case "gp_high":
        baseClass = "gp-cell-high";
        break;
      case "gm_low":
        baseClass = "gm-cell-low";
        break;
      case "gm_high":
        baseClass = "gm-cell-high";
        break;
    }
    if (
      data.columnId &&
      data.rowData?.modifiedFields?.includes(data.columnId)
    ) {
      baseClass += " cell-modified";
    }

    return baseClass;
  },
};
export const getColumns = (
  keys: string[],
  options?: {
    bucketConfig?: IBucketConfig; // This will prepend the bucket column
  }
): Column[] => {
  console.log("Keys: ", keys, "Options: ", options);
  const optionalColumns = getOptionalColumns(keys);
  if (keys.includes("bucket")) {
    const keysBeforeBucket = keys.slice(0, keys.indexOf("bucket"));
    const keysAfterBucket = keys.slice(keys.indexOf("bucket") + 1);
    const bucketColumn = options?.bucketConfig
      ? getBucketColumn(options.bucketConfig)
      : null;
    return [
      ...keysBeforeBucket.map((key) => getOptionalColumn(key)),
      bucketColumn,
      ...keysAfterBucket.map((key) => getOptionalColumn(key)),
    ];
  }
  const bucketColumn = options?.bucketConfig
    ? getBucketColumn(options.bucketConfig)
    : null;
  const selectColumn = getSelectColumn();
  return [
    selectColumn,
    ...(bucketColumn ? [bucketColumn] : []),
    ...optionalColumns,
  ];
};
const formatNumber = (value: number) => {
  const truncate = parseFloat(value.toFixed(2));
  const micros = truncate * 100;
  return fullPriceTextFromMicros(micros, {
    showPositiveSign: false,
    hideCommas: false,
    hideCurrency: false,
    roundToInteger: false,
  });
};
const parseNumber = (value: string) => {
  const containsNegative = value.includes("-");
  const parsedValue = parseFloat(value.replace(/[^0-9.]/g, ""));
  if (isNaN(parsedValue)) {
    return formatNumber(0);
  }
  const formatted = formatNumber(parsedValue);
  return containsNegative ? "-" + formatted : formatted;
  // return parsedValue;
};
const prePasteValue = (values: string[]): any[] | Promise<any[]> => {
  return values;
};
const getOptionalColumns = (keys: string[]): Column[] => {
  let columns: Column[] = [];
  keys.forEach((key) => {
    const column = getOptionalColumn(key);
    if (!column) {
      console.warn(`No column found for key: ${key}`);
    } else {
      columns.push(getOptionalColumn(key) as Column);
    }
  });
  return columns;
};

const getSelectColumn = (): Column => {
  return {
    ...keyColumn("selected", checkboxColumn),
    title: "",
    headerClassName: "select-cell-header",
    disabled: isRowLocked,
    maxWidth: 50,
  };
};

const getBucketColumn = (bucketConfig: IBucketConfig): Column => {
  const buckets = Object.keys(bucketConfig);
  return {
    ...keyColumn(
      "bucket",
      selectColumn({
        choices: [
          ...buckets.map((bucketKey) => {
            const bucket = bucketConfig[bucketKey];
            return {
              value: bucket.value,
              label: bucket.label,
            };
          }),
        ],
      })
    ),
    headerClassName: "bucket-cell-header",
    title: "Edit Bucket",
    ...genericColumnProps,
  };
};
const SELECT_COLUMN = {
  ...keyColumn("selected", checkboxColumn),
  title: "",
  ...genericColumnProps,
  maxWidth: 35,
  minWidth: 35,
  headerClassName: "select-cell-header",
};
const TITLE_COLUMN = {
  ...keyColumn("name", textColumn),
  title: "Title",
  headerClassName: "title-cell-header",
  ...genericColumnProps,
};
const DESCRIPTION_COLUMN = {
  ...keyColumn("notes", textColumn),
  title: "Notes & Comments",
  headerClassName: "notes-cell-header",
  ...genericColumnProps,
};
const PRICE_LOW_COLUMN = {
  ...keyColumn(
    "price_low",
    createTextColumn<string>({
      parseUserInput: parseNumber,
    })
  ),
  title: "Price (Low)",
  pasteValue: (data: any) => onPaste(BudgetColumns.PRICE_LOW, data),
  headerClassName: "price-cell-low-header",
  ...genericColumnProps,

  prePasteValue: prePasteValue,
};
const PRICE_HIGH_COLUMN = {
  ...keyColumn(
    "price_high",
    createTextColumn<string>({
      parseUserInput: parseNumber,
    })
  ),
  title: "Price (High)",
  pasteValue: (data: any) => onPaste(BudgetColumns.PRICE_HIGH, data),
  headerClassName: "price-cell-high-header",
  prePasteValue: prePasteValue,
  ...genericColumnProps,
};
const COST_LOW_COLUMN = {
  ...keyColumn(
    "cost_low",
    createTextColumn<string>({
      parseUserInput: parseNumber,
    })
  ),
  title: "COGS (Low)",
  pasteValue: (data: any) => onPaste(BudgetColumns.COST_LOW, data),
  prePasteValue: prePasteValue,
  headerClassName: "cost-cell-low-header",
  ...genericColumnProps,
};
const COST_HIGH_COLUMN = {
  ...keyColumn(
    "cost_high",
    createTextColumn<string>({
      parseUserInput: parseNumber,
    })
  ),
  title: "COGS (High)",
  pasteValue: (data: any) => onPaste(BudgetColumns.COST_HIGH, data),
  prePasteValue: prePasteValue,
  headerClassName: "cost-cell-high-header",
  ...genericColumnProps,
};
const GP_LOW_COLUMN = {
  ...keyColumn("gp_low", textColumn),
  title: "GP (Low)",
  headerClassName: "gp-cell-low-header",
  ...genericColumnProps,
};
const GP_HIGH_COLUMN = {
  ...keyColumn("gp_high", textColumn),
  title: "GP (High)",
  headerClassName: "gp-cell-high-header",
  ...genericColumnProps,
};
const GM_LOW_COLUMN = {
  ...keyColumn("gm_low", textColumn),
  title: "GM% (Low)",
  headerClassName: "gm-cell-low-header",
  ...genericColumnProps,
};
const GM_HIGH_COLUMN = {
  ...keyColumn("gm_high", textColumn),
  title: "GM% (High)",
  headerClassName: "gm-cell-high-header",
  ...genericColumnProps,
};

const getOptionalColumn = (key: string): any | null => {
  switch (key) {
    case "selected":
      return SELECT_COLUMN;
    case "name":
      return TITLE_COLUMN;
    case "price_low":
      return PRICE_LOW_COLUMN;
    case "price_high":
      return PRICE_HIGH_COLUMN;
    case "cost_low":
      return COST_LOW_COLUMN;
    case "cost_high":
      return COST_HIGH_COLUMN;
    case "notes":
      return DESCRIPTION_COLUMN;
    case "gp_low":
      return GP_LOW_COLUMN;
    case "gp_high":
      return GP_HIGH_COLUMN;
    case "gm_low":
      return GM_LOW_COLUMN;
    case "gm_high":
      return GM_HIGH_COLUMN;
    default:
      return null;
  }
};

const selectColumn = (
  options: SelectOptions
): Column<string | null, SelectOptions> => ({
  // @ts-ignore
  component: SelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  disabled: options.disabled,
  deleteValue: () => null,
  copyValue: ({rowData}) =>
    options.choices.find((choice) => choice.value === rowData)?.label ?? null,
  pasteValue: ({value}) =>
    options.choices.find((choice) => choice.label === value)?.value ?? null,
});

const isRowLocked = (row: any) => {
  const {rowData} = row;
  // Disable the cell if the row is a unit
  return rowData?.locked;
};
