import {ICartSlice} from "./CartSlice";
import {
  CartItemType,
  ICartItem,
  ICartItemDetailsADU,
  IProduct,
} from "@natomas-org/core";

export const getCartItemById = (state: ICartSlice, id: string): ICartItem => {
  return state[id] ?? {id: id};
};

export const getADUCartItem = (
  product: IProduct,
  configurationId: string,
  currentCartItem?: ICartItem
): ICartItem => {
  const {id, versionId, productGroupId} = product ?? {};
  const sku = `${productGroupId}-${versionId}-${id}`;
  return {
    id: configurationId,
    sku: sku,
    type: CartItemType.ADU,
    details: {
      productId: id,
      factoryId: productGroupId,
      versionId: versionId,
      configurationId: configurationId,
      code: currentCartItem?.details?.code ?? 1,
      display_code: currentCartItem?.details?.display_code ?? `Unit #1`,
    } as ICartItemDetailsADU,
    quantity: currentCartItem?.quantity ?? 1,
  };
};
