import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";

export const NatTag = styled.div<{
  color: string;
  backgroundColor: string;
  size: string;
}>`
  border: 2px solid ${(props) => props.color};
  border-radius: 0.25rem;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  font-family: ${FONT_FAMILY_BOLD};
  letter-spacing: 1px;
  font-size: ${(props) => props.size};
  padding: 0.25rem 0.25rem 0.125rem 0.25rem;
  width: fit-content;
  cursor: default;
`;
