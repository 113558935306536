
export const ALL_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export type TIMELINE_INFO_TYPE =
  | "overall"
  | "proposal"
  | "permitting"
  | "ATB"
  | "manufacturing"
  | "installation";

export interface IStepMilestone {
  milestone_index: number;
  title: string;
  description: string;
  footer: string;
  offset_percentage: number;
  top_offset_percentage?: number;
}

export interface INextStepInfo {
  id: TIMELINE_INFO_TYPE;
  title: string;
  subtitle: string;
  details: string;
  color: string;
  startDate: Date;
  endDate: Date;
  completion_percent?: number;
  phase?: {
    title: string;
    phaseEndDate: Date;
    additionalSteps: number;
    endColor: string;
  };
  milestones?: IStepMilestone[];
  progressCircle?: {
    width?: number;
  };
}

export interface IProjectTimelineProps {
  expandMilestones?: boolean;
  displayQuarters?: boolean;
  pdfRenderFormat?: boolean;
}

export interface ITimelineProps extends IProjectTimelineProps {
  startDate: Date;
  totalMonths: number;
  nextStepsInfo: any[];
  overallEndDate: Date;
  actualTimeline: boolean;
}