import React from "react";

interface LinkInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: string;
}

const LinkInput = (props: LinkInputProps) => {
  const {id, onChange, initialValue} = props;
  return (
    <>
      <input
        id={id}
        name={id}
        type="url"
        onChange={(e) => {
          const value: string = e?.target?.value as unknown as string;
          onChange(value);
        }}
        value={initialValue}
        spellCheck={true}
      />
      <a
        hidden={!initialValue}
        href={initialValue}
        target={"_blank"}
        rel="noreferrer"
      >
        Link
      </a>
    </>
  );
};

export default LinkInput;
