import {
  getPricingForUnitAndPriceMode,
  makeNounPlural,
  priceTextFromMicros,
} from "@natomas-org/core";
import {getBedBathString} from "../../../../_shared/_legacy/logic/ProductUtils";
import {
  DesignListContainer,
  DesignListHeader,
  DesignListHeaderNatPage,
  DesignListItemContainer,
  DesignListItemContainerNatPage,
} from "../styles";
import {OneLine} from "../../../../_shared/_legacy/one-line";
import React from "react";
import {scrollToTop} from "../../../../_shared/navigation/_helpers";
import {usePage} from "../../../../_shared/hooks/usePage";
import {isMobile} from "../../../../_shared/navigation";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {FONT_FAMILY_BOLD} from "../../SSDDashboardView/styles/globals";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";

const ConfigurationPageRow = (props) => {
  const {configurationPage, index, selectedPage, setSelectedPage, useNatPage} =
    props;
  const modifierGroups = configurationPage.selectedModifierGroups;
  let totalPriceInMicros = 0;
  let upgradeCount = 0;
  modifierGroups.forEach((modifierGroup) => {
    const modifiers = modifierGroup.selectedModifiers;
    modifiers.forEach((modifier) => {
      totalPriceInMicros += modifier.adjustedPriceMicros;
      if (modifier.adjustedPriceMicros !== 0) {
        upgradeCount += 1;
      }
    });
  });

  const upgradeCountString = `${upgradeCount} upgrade${makeNounPlural(
    upgradeCount
  )}`;
  const isRowSelected =
    selectedPage != null && selectedPage.id === configurationPage.id;

  return (
    <DesignListRow
      isSelected={isRowSelected}
      onClick={() => {
        setSelectedPage(configurationPage);
        scrollToTop(true);
      }}
      title={`${index}. ${configurationPage.title}`}
      price={priceTextFromMicros(totalPriceInMicros, "integer-sign")}
      subtitle={upgradeCountString}
      useNatPage={useNatPage}
    />
  );
};

const DesignListRow = (props) => {
  const {isSelected, onClick, title, price, subtitle, useNatPage} = props;
  if (useNatPage) {
    return (
      <DesignListItemContainerNatPage
        isMobile={isMobile()}
        selected={isSelected}
        onClick={onClick}
      >
        <div style={{margin: "auto"}}>
          <div style={{fontFamily: FONT_FAMILY_BOLD, fontSize: 18}}>
            <OneLine leftText={title} rightText={price} />
          </div>
          <div style={{fontSize: 16}}>{subtitle}</div>
        </div>
      </DesignListItemContainerNatPage>
    );
  }
  return (
    <DesignListItemContainer
      isMobile={isMobile()}
      selected={isSelected}
      onClick={onClick}
    >
      <div style={{fontFamily: FONT_FAMILY_BOLD, fontSize: 18}}>
        <OneLine leftText={title} rightText={price} />
      </div>
      <div style={{fontSize: 16}}>{subtitle}</div>
    </DesignListItemContainer>
  );
};

const DesignListRowHeader = (props) => {
  const {title, price, useNatPage} = props;
  if (useNatPage) {
    return (
      <DesignListHeaderNatPage
        isMobile={isMobile()}
        style={{padding: "0.25rem 1rem"}}
      >
        <OneLine leftText={title} rightText={price} />
      </DesignListHeaderNatPage>
    );
  }
  return (
    <DesignListHeader isMobile={isMobile()}>
      <OneLine leftText={title} rightText={price} />
    </DesignListHeader>
  );
};

export const DesignSummaryList = (props) => {
  const {
    configurationSnapshot,
    configuration,
    currentProduct,
    selectedPage,
    setSelectedPage,
    useNatPage,
  } = props;
  const {isNatMobile} = usePage();
  const {priceMode} = useCatalogSession();
  const pricing = getPricingForUnitAndPriceMode(configuration, priceMode, {
    addSalesTax: true,
  });
  const unitSubtitle = `${currentProduct.title}  |  ${getBedBathString(
    currentProduct,
    "line"
  )}`;

  const rowSelector = useDynamicValue({
    forFour: 4,
    forEight: 3,
    forTwelve: 4,
    forSixteen: 4,
  });
  if (useNatPage) {
    return (
      <PageElement size={rowSelector} height={"auto"}>
        <DesignListContainer isMobile={isNatMobile}>
          <DesignListRowHeader
            title={"My Home"}
            price={priceTextFromMicros(
              pricing.unitBasePriceInMicros + pricing.unitUpgradePriceInMicros,
              "accurate"
            )}
            useNatPage={true}
          />
          <DesignListRow
            isSelected={selectedPage == null}
            onClick={() => {
              setSelectedPage(null);
              scrollToTop(true);
              const mainContentElement = document.getElementById(
                "main-content-wrapper"
              );
              mainContentElement.scrollIntoView({behavior: "smooth"});
            }}
            title={"1. Home"}
            price={priceTextFromMicros(
              pricing.unitBasePriceInMicros,
              "accurate"
            )}
            subtitle={unitSubtitle}
            useNatPage={true}
          />
          {configurationSnapshot.snapshot.map((page, index) => {
            return (
              <div
                onClick={() => {
                  const mainContentElement = document.getElementById(
                    "main-content-wrapper"
                  );
                  mainContentElement.scrollIntoView({behavior: "smooth"});
                }}
              >
                <ConfigurationPageRow
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  configurationPage={page}
                  index={index + 2}
                  key={page.id}
                  useNatPage={true}
                />
              </div>
            );
          })}
        </DesignListContainer>
      </PageElement>
    );
  }

  return (
    <DesignListContainer isMobile={isNatMobile}>
      <DesignListRowHeader
        title={"My Home"}
        price={priceTextFromMicros(
          pricing.unitBasePriceInMicros + pricing.unitUpgradePriceInMicros,
          "accurate"
        )}
      />
      <DesignListRow
        isSelected={selectedPage == null}
        onClick={() => {
          setSelectedPage(null);
          scrollToTop(true);
          const mainContentElement = document.getElementById(
            "main-content-wrapper"
          );
          mainContentElement.scrollIntoView({behavior: "smooth"});
        }}
        title={"1. Home"}
        price={priceTextFromMicros(pricing.unitBasePriceInMicros, "accurate")}
        subtitle={unitSubtitle}
      />
      {configurationSnapshot.snapshot.map((page, index) => {
        return (
          <div
            onClick={() => {
              const mainContentElement = document.getElementById(
                "main-content-wrapper"
              );
              mainContentElement.scrollIntoView({behavior: "smooth"});
            }}
          >
            <ConfigurationPageRow
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              configurationPage={page}
              index={index + 2}
              key={page.id}
            />
          </div>
        );
      })}
    </DesignListContainer>
  );
};
