import styled from "styled-components";
import {calculateBorder, INatStyle} from "../_shared";
import {DROPDOWN_Z_INDEX, NatStyle} from "../../styles";
import {
  DEFAULT_SHADOW,
  GRAY_BORDER,
  SLIDER_RAIL,
  VILLA_APPLE_GREEN,
  WHITE,
} from "../../colors";
import {isMobile} from "../../navigation";
import {Slider} from "@mui/material";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";

export const NatomasSlider = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  text-align: center;
  margin-bottom: 0.25rem;
  width: inherit;
  z-index: 4; // One more than hidden div from NatPopup
`;

export const SliderLabel = styled.button<INatStyle>`
  ${NatStyle};
  border: ${(props) => calculateBorder(props.initialBorderColor)};
  border-radius: 3rem;
  &:hover {
    border: ${(props) => calculateBorder(props.hoverBorderColor)};
  }
`;

export const SliderContentContainer = styled.div<{show: boolean}>`
  display: ${(props) => (props.show ? "flex" : "none")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: ${isMobile() ? "fixed" : "absolute"};
  left: ${isMobile() && "0.5rem"};
  right: ${isMobile() && "0.5rem"};
  top: ${(props) => props.show && !isMobile() && "125%"};
  bottom: ${(props) => props.show && isMobile() && "0.5rem"};
  background-color: ${WHITE};
  box-shadow: ${DEFAULT_SHADOW};
  border-radius: 0.5rem;
  z-index: ${DROPDOWN_Z_INDEX};
  transition: all 2000ms ease-in-out;
`;
export const SliderContent = styled.div<{widthRem?: number}>`
  text-align: ${isMobile() ? "center" : "left"};
  background-color: white;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.25rem 1.25rem 1.25rem 1.25rem;
  min-width: ${(props) =>
    isMobile() ? "100%" : props.widthRem ? `${props.widthRem} rem` : "100%"};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  border-radius: 0.5rem;
  button {
    margin-top: 1rem;
  }
`;

export const SliderButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  *:only-child {
    margin-left: auto !important;
  }
`;

export const RangeSlider = styled(Slider)<{mobile: string}>`
  color: #52af77;
  height: ${(props) => (props.mobile === "true" ? "16px" : "8px")};
  margin-top: ${(props) => (props.mobile === "true" ? "-0.5rem" : undefined)};
  & .MuiSlider-track {
    background-color: ${VILLA_APPLE_GREEN};
    border: none;
    height: ${(props) => (props.mobile === "true" ? "1rem" : "0.75rem")};
  }
  & .MuiSlider-rail {
    background-color: ${SLIDER_RAIL};
    opacity: 1;
    border: none;
    height: ${(props) => (props.mobile === "true" ? "1rem" : "0.75rem")};
  }
  & .MuiSlider-thumb {
    height: 24px;
    width: 16px;
    background-color: ${WHITE};
    border-color: ${GRAY_BORDER};
    border-width: 1px;
    border-style: solid;
    &:focus,
    &:hover,
    &.Mui-active,
    &.Mui-focusVisible {
      box-shadow: inherit;
    }
    &:before {
      display: none;
    }
    border-radius: 4px;
  }
  & .MuiSlider-valueLabel {
    line-height: 1.2px;
    font-family: ${(props) =>
      props.mobile === "true" ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
    font-size: 14px;
    background: unset;
    padding: 0;
    width: 52px;
    background-color: white;
    color: black;
    transform-origin: bottom left;
    top: 3.25rem;
    &:before {
      display: none;
    }
  }
`;
