import {useMemo} from "react";
import {
  IProduct,
  IProductGroupDetails,
  ListingStatusDetails,
} from "@natomas-org/core";
import {
  getFactoryLineDetails,
  getFactoryLinePublishedVersionDetails,
} from "./helper";
import {useFactorySlice} from "../useFactorySlice";
import {useAllPublishedAndRoutedProducts} from "../useProducts";
import {useCatalogSession} from "../useCatalogSession/useCatalogSession";

export const useFactoryLines = (props?: {
  listingStatus?: {
    [ListingStatusDetails.UNLISTED]?: boolean;
    [ListingStatusDetails.LISTED]?: boolean;
    [ListingStatusDetails.HIDDEN]?: boolean;
    [ListingStatusDetails.NULL]?: boolean;
  };
  onlyEligible?: boolean;
  excludeFactories?: string[];
}) => {
  const {listingStatus, onlyEligible, excludeFactories} = props ?? {};
  const factorySlice = useFactorySlice();
  const {eligibility} = useCatalogSession();

  const factoryLines = useMemo(() => {
    return getFactoryLineDetails(factorySlice, {
      listingStatuses: listingStatus,
      eligibility: onlyEligible ? eligibility : undefined,
      excludeFactories: excludeFactories,
    });
  }, [
    factorySlice,
    listingStatus,
    onlyEligible,
    eligibility,
    excludeFactories,
  ]);

  return {
    factoryLines,
    factoryIds: factoryLines?.map((factory) => factory.id),
    unfilteredFactoryLineDictionary: factorySlice,
  };
};

export const useAllPublishedAndRoutedProductsByFactoryLine = () => {
  const products = useAllPublishedAndRoutedProducts();
  const factorySlice = useFactorySlice();
  return products.reduce((acc, product) => {
    let productGroupDetails: IProductGroupDetails | null = null;
    if (product?.productGroupId) {
      productGroupDetails = getFactoryLinePublishedVersionDetails(
        factorySlice,
        product?.productGroupId
      );
    }
    if (product?.productGroupId && productGroupDetails) {
      if (
        !acc[product?.productGroupId] ||
        !acc[product?.productGroupId]?.products ||
        !acc[product?.productGroupId]?.productGroupDetails
      ) {
        acc[product?.productGroupId] = {
          products: [],
          productGroupDetails: productGroupDetails,
        };
      }
      acc[product?.productGroupId].products?.push(product);
    }
    return acc;
  }, {} as {[key: string]: {productGroupDetails: IProductGroupDetails; products: IProduct[]}});
};
