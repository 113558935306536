import styled from "styled-components";
import {DIVIDER_COLOR, VILLA_BLUE, WHITE} from "../../_shared/colors";
import {NatomasButton} from "../../_shared/generics/button/styles";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../portal/views/SSDDashboardView/styles/globals";

export const FullScreenCenteredContent = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  background-color: ${DIVIDER_COLOR};
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${NatomasButton} {
    margin-bottom: 1rem;
  }
  position: fixed;
`;
export const LandingText = styled.div`
  text-align: center;
  color: ${VILLA_BLUE};
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 1.75rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;

export const LandingTextDescription = styled.div`
  text-align: center;
  color: ${VILLA_BLUE};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 1.25rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;

export const AccountInfoText = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-right: 1rem;
`;

export const LogoutButtonContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 2rem;
  background-color: ${WHITE};
  ${NatomasButton} {
    margin-right: 1rem;
  }
  bottom: 1rem;
  left: 1rem;
`;
