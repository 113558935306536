// Create an editable cell renderer
import React, {useEffect, useMemo, useState} from "react";
import {
  ASSEMBLY_KEY,
  CONDITIONS_KEY,
  DETAILS_KEY,
  EXTERNAL_CATEGORY_KEY,
  EXTERNAL_DESCRIPTION_KEY,
  ID_KEY,
  INCLUDED_ORDER_FORM_KEY,
  INTERNAL_CATEGORY_KEY,
  INTERNAL_NOTES_KEY,
  LABEL_KEY,
  MULTI_OPTION_KEY,
  OMIT_KEY,
  PRICING_PACKAGE_KEY,
  SOURCE_KEY,
  VALUE_KEY,
  VARIANTS_KEY,
} from "../../../shared/constants";
import {InfoSetItemTableRow} from "../../table";
import {EnumSelect} from "./CellTypes/EnumSelect";
import {
  InfoSetItemInternalCategory,
  InfoSetItemOptionAssembly,
  InfoSetItemOptionExternalCategory,
  InfoSetItemOptionOmit,
  InfoSetItemOptionPricingPackage,
  InfoSetItemOptionSource,
} from "../../../shared/interfaces";
import {RowLabel} from "./CellTypes/RowLabel";
import {RowOptionInput} from "./CellTypes/RowOptionInput";
import {ConditionCell} from "./CellTypes/ConditionCell";
import _ from "lodash";
import {ImCheckboxChecked, ImCheckboxUnchecked} from "react-icons/im";
import {
  getReadOnlyValue,
  hasReadOnlyVariant,
  isRichTextValue,
  isRowValueValidByKey,
} from "./helper";
import {RowStringInput} from "./CellTypes/RowStringInput";
import {RichTextEditor} from "../../../../_shared/generics/rich-text-editor/RichTextEditor";
import {NatButton} from "../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {
  NatEditIcon,
  NatWarningDiamondIcon,
} from "../../../../_shared/icon/icons";
import {WHITE} from "../../../../_shared/colors";
import {RichTextCell} from "./styles";

export const InfoSetEditableCell = (tableProps: any) => {
  const {
    editingTargetId,
    setRichTextEditorParameters,
    updateCellValue,
    value,
    row,
    column,
    data: tableRows,
    categories,
    modifierGroups,
    modifierOptions,
    productOptions,
  } = tableProps;
  const {index: rowIndex, original: rowValues} = row;
  const {id: columnKey} = column;
  // We need to keep and update the state of the cell normally
  const [cellValue, setCellValue] = useState(value);

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setCellValue(value);
  }, [value]);

  const isCellValid: boolean = useMemo(() => {
    return isRowValueValidByKey(columnKey, rowValues);
  }, [rowValues, columnKey]);

  const isRowTargeted: boolean =
    !!editingTargetId && editingTargetId === rowValues.key;

  if (!isRowTargeted) {
    if (!isCellValid) {
      return (
        <NatButton
          id={`warning-button-${columnKey}-${rowIndex}`}
          option={StyleOption.DESTRUCTIVE}
          label={"Warning"}
          size={NatSize.SMALL}
          tooltip={"Required for functionality"}
          tooltipPlacement={"top"}
          icon={{
            icon: <NatWarningDiamondIcon />,
            iconPosition: IconPosition.ONLY,
          }}
        />
      );
    }
    if (hasReadOnlyVariant(columnKey)) {
      if (isRichTextValue(columnKey)) {
        return !!value ? (
          <RichTextEditor
            value={value}
            readOnly={true}
            onChange={() => {}}
            containerProps={{
              style: {
                height: "auto",
                fontSize: "12px",
                width: "100%",
                backgroundColor: WHITE,
                resize: "none",
                minHeight: "0px",
                minWidth: "320px",
              },
            }}
          />
        ) : null;
      }
      const readOnlyValue = getReadOnlyValue(value, columnKey);
      return <div style={{whiteSpace: "pre-wrap"}}>{readOnlyValue}</div>;
    }
  }

  const onChange = (e: {target: {value: any}}) => {
    setCellValue(e.target.value);
  };

  const onCommitExternalChange = (v: string) => {
    if (!_.isEqual(v, value)) {
      updateCellValue(rowIndex, columnKey, v);
      setCellValue(v);
    }
  };

  const onCommitChange = () => {
    if (!_.isEqual(cellValue, value)) {
      updateCellValue(rowIndex, columnKey, cellValue);
      setCellValue(cellValue);
    }
  };
  if (
    columnKey === EXTERNAL_DESCRIPTION_KEY ||
    columnKey === INTERNAL_NOTES_KEY
  ) {
    return (
      <RichTextCell>
        {!!value && (
          <RichTextEditor
            value={value}
            readOnly={true}
            onChange={() => {}}
            containerProps={{
              style: {
                height: "auto",
                fontSize: "12px",
                width: "100%",
                backgroundColor: WHITE,
                resize: "none",
                minHeight: "0px",
                minWidth: "287px",
              },
            }}
          />
        )}
        <NatButton
          size={NatSize.SMALL}
          label={"Edit"}
          icon={{icon: <NatEditIcon />, iconPosition: IconPosition.ONLY}}
          option={StyleOption.PRIMARY_BLACK}
          clickEvent={() => {
            setRichTextEditorParameters({
              key: columnKey,
              index: rowIndex,
            });
          }}
        />
      </RichTextCell>
    );
  }

  if (columnKey === EXTERNAL_CATEGORY_KEY) {
    return (
      <EnumSelect
        enumTarget={InfoSetItemOptionExternalCategory}
        onChange={setCellValue}
        onCommitChange={onCommitChange}
        value={cellValue}
      />
    );
  }
  if (columnKey === INCLUDED_ORDER_FORM_KEY || columnKey === MULTI_OPTION_KEY) {
    return (
      <div
        style={{cursor: "pointer"}}
        onClick={() => {
          setCellValue(!value);
          updateCellValue(rowIndex, columnKey, !value);
        }}
      >
        {value ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
      </div>
    );
  }
  if (columnKey === INTERNAL_CATEGORY_KEY) {
    return (
      <EnumSelect
        enumTarget={InfoSetItemInternalCategory}
        onChange={setCellValue}
        onCommitChange={onCommitChange}
        value={cellValue}
      />
    );
  }
  if (columnKey === DETAILS_KEY || columnKey === VARIANTS_KEY) {
    return (
      <RowStringInput
        placeholder={`Empty`}
        isValidChange={() => true}
        onChange={onChange}
        onCommitChange={onCommitChange}
        originalValue={rowValues[columnKey]}
        otherRows={[]}
        value={cellValue}
        type={"textarea"}
      />
    );
  }
  if (columnKey === OMIT_KEY) {
    return (
      <EnumSelect
        enumTarget={InfoSetItemOptionOmit}
        onChange={setCellValue}
        onCommitChange={onCommitChange}
        value={cellValue}
      />
    );
  }
  if (columnKey === ASSEMBLY_KEY) {
    return (
      <EnumSelect
        enumTarget={InfoSetItemOptionAssembly}
        onChange={setCellValue}
        onCommitChange={onCommitChange}
        value={cellValue}
      />
    );
  }
  if (columnKey === PRICING_PACKAGE_KEY) {
    return (
      <EnumSelect
        enumTarget={InfoSetItemOptionPricingPackage}
        onChange={setCellValue}
        onCommitChange={onCommitChange}
        value={cellValue}
      />
    );
  }
  if (columnKey === SOURCE_KEY) {
    return (
      <EnumSelect
        enumTarget={InfoSetItemOptionSource}
        onChange={setCellValue}
        onCommitChange={onCommitChange}
        value={cellValue}
      />
    );
  }
  if (columnKey === LABEL_KEY) {
    let otherRows = tableRows.filter(
      (row: InfoSetItemTableRow) =>
        rowValues?.[ID_KEY].localeCompare(row?.[ID_KEY]) !== 0
    );
    return (
      <RowLabel
        onChange={onChange}
        onCommitChange={onCommitExternalChange}
        originalValue={rowValues[columnKey]}
        otherRows={otherRows}
        value={cellValue}
      />
    );
  }
  if (columnKey === VALUE_KEY) {
    let otherRows = [...tableRows];
    otherRows.splice(rowIndex, 1);
    return (
      <RowOptionInput
        currentRow={rowValues}
        onChange={onChange}
        onCommitChange={onCommitExternalChange}
        originalValue={rowValues[columnKey]}
        otherRows={otherRows}
        value={cellValue}
      />
    );
  }
  if (columnKey === CONDITIONS_KEY) {
    let otherRows = [...tableRows];
    otherRows.splice(rowIndex, 1);
    const otherOptionRows = otherRows.filter(
      (r: InfoSetItemTableRow) => r[LABEL_KEY] === rowValues[LABEL_KEY]
    );
    return (
      <ConditionCell
        isRowTargeted={isRowTargeted}
        otherRows={otherOptionRows}
        currentRow={rowValues}
        value={cellValue}
        onCommitChange={onCommitExternalChange}
        categories={categories}
        modifierGroups={modifierGroups}
        modifierOptions={modifierOptions}
        productOptions={productOptions}
      />
    );
  }
  return <div>{JSON.stringify(value)}</div>;
};
