import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import {isEmpty} from "@natomas-org/core";
import {
  AddEmployeeButtonRowContainer,
  AddEmployeeCardContainer,
  AddEmployeeEmailSearch,
  AddEmployeeIconContainer,
} from "./styles";
import {NatButton} from "../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../_shared/generics/_shared";
import {VILLA_BLUE} from "../../../_shared/colors";
import {getUserAuthInfo} from "../../../../database/firebase/api/admin";
import {
  EmployeeCardContentDivider,
  EmployeeCardDisplayName,
  EmployeeCardEmail,
} from "../_shared/styles";
import {changeUserPermissions} from "../../../../database/firebase/api/admin/permissions";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {isAdmin} from "../../../_shared/user/UserUtils";
import {NatSearchIcon} from "../../../_shared/icon/icons";

export const AddEmployeeCard = () => {
  const {uid} = useCurrentUser();
  const [expanded, setExpanded] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [searchResult, setSearchResult] = useState<any | null>(null);

  const resetSearch = useCallback(() => {
    setFetching(false);
    setSearchText("");
    setSearchResult(null);
    setExpanded(false);
  }, []);

  const findEmployee = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      setFetching(true);
      getUserAuthInfo(undefined, searchText).then((r) => {
        setFetching(false);
        if (!!r) {
          setSearchResult(r);
        } else {
          setSearchResult({});
        }
      });
    },
    [searchText]
  );

  const addEmployee = useCallback(() => {
    changeUserPermissions(searchResult?.uid, {
      productLines: false,
      customers: false,
      developer: false,
      superCatalog: false,
      completedProjects: false,
    }).then(() => {
      console.log("Request complete.");
      resetSearch();
    });
  }, [resetSearch, searchResult?.uid]);

  const CancelButton: JSX.Element = useMemo(() => {
    return (
      <NatButton
        label={"Cancel"}
        type={"button"}
        option={StyleOption.SECONDARY_ALT}
        size={NatSize.SMALL}
        clickEvent={() => {
          resetSearch();
        }}
      />
    );
  }, [resetSearch]);

  // User is attempting to manage themselves
  if (searchResult && searchResult?.uid === uid) {
    return (
      <AddEmployeeCardContainer expanded={expanded}>
        Clever clever, but you can't manage yourself,{" "}
        {searchResult?.displayName}...
        <EmployeeCardContentDivider />
        {CancelButton}
      </AddEmployeeCardContainer>
    );
  }

  // User could not be found
  if (searchResult && isEmpty(searchResult)) {
    return (
      <AddEmployeeCardContainer expanded={expanded}>
        Could not find {searchText}
        <EmployeeCardContentDivider />
        {CancelButton}
      </AddEmployeeCardContainer>
    );
  }

  // Searching for a employee
  if (fetching) {
    return (
      <AddEmployeeCardContainer expanded={expanded}>
        {expanded && fetching && <>Searching for {searchText}...</>}
      </AddEmployeeCardContainer>
    );
  }

  if (!fetching && searchResult && !isAdmin(searchResult)) {
    return (
      <AddEmployeeCardContainer expanded={expanded}>
        You can only add employees of Villa. {searchResult?.email} is not.
        <EmployeeCardContentDivider />
        {CancelButton}
      </AddEmployeeCardContainer>
    );
  }

  // To add a potential employee
  if (!fetching && searchResult && isAdmin(searchResult)) {
    return (
      <AddEmployeeCardContainer expanded={expanded}>
        <EmployeeCardDisplayName>
          {searchResult?.displayName}
        </EmployeeCardDisplayName>
        <EmployeeCardEmail>{searchResult?.email}</EmployeeCardEmail>
        <EmployeeCardContentDivider />
        <AddEmployeeButtonRowContainer>
          {CancelButton}
          <NatButton
            label={"Add Employee"}
            type={"button"}
            option={StyleOption.PRIMARY_ALT}
            size={NatSize.SMALL}
            clickEvent={() => addEmployee()}
          />
        </AddEmployeeButtonRowContainer>
      </AddEmployeeCardContainer>
    );
  }

  // Default state - query for an employee
  return (
    <AddEmployeeCardContainer expanded={expanded}>
      {!expanded && (
        <AddEmployeeIconContainer onClick={() => setExpanded(true)}>
          <NatSearchIcon size={32} color={VILLA_BLUE} />
        </AddEmployeeIconContainer>
      )}
      {expanded && !fetching && !searchResult && (
        <form onSubmit={(e: FormEvent<HTMLFormElement>) => findEmployee(e)}>
          <AddEmployeeEmailSearch
            placeholder={"Email"}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSearchText(e.target.value)
            }
          />
          <AddEmployeeButtonRowContainer>
            {CancelButton}
            <NatButton
              label={"Find Employee"}
              type={"submit"}
              option={StyleOption.PRIMARY_ALT}
              size={NatSize.SMALL}
            />
          </AddEmployeeButtonRowContainer>
        </form>
      )}
    </AddEmployeeCardContainer>
  );
};
