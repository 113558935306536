import {Environment, IDatabase} from "../types";

export const PRODUCTION_CONFIG: IDatabase = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const STAGING_CONFIG: IDatabase = {
  apiKey: "AIzaSyBSDoLpJ_wQWQdAucwKXugHhecy1RSM53I",
  authDomain: "natomas-staging.firebaseapp.com",
  databaseURL: "https://natomas-staging-default-rtdb.firebaseio.com",
  projectId: "natomas-staging",
  storageBucket: "natomas-staging.appspot.com",
  messagingSenderId: "965085942315",
  appId: "1:965085942315:web:5506a146bbc7522d05906e",
  measurementId: "G-SNHR6S485C",
};

export const DEVELOPMENT_CONFIG: IDatabase = {
  apiKey: "AIzaSyAQZNWMm3PIBRSJnRMfJx9rLfp7_86zgFA",
  authDomain: "natomas-qa.firebaseapp.com",
  databaseURL: "https://natomas-qa-default-rtdb.firebaseio.com",
  projectId: "natomas-qa",
  storageBucket: "natomas-qa.appspot.com",
  messagingSenderId: "786679922300",
  appId: "1:786679922300:web:6cfe1a5a65a74f5cb313f9",
  measurementId: "G-E632VELXQP",
};

export const getFirebaseConfiguration = (mode: Environment) => {
  if (mode === Environment.PRODUCTION) {
    return PRODUCTION_CONFIG;
  } else if (mode === Environment.STAGING) {
    return STAGING_CONFIG;
  } else if (mode === Environment.DEVELOPMENT) {
    return DEVELOPMENT_CONFIG;
  } else {
    throw new Error("Unknown environment.");
  }
};
