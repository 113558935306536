import React, {useEffect, useMemo, useState} from "react";
import {
  NatModal,
  NatModalSize,
} from "../../../../_shared/generics/modal/NatModal";
import {Row} from "react-table";
import {InfoSetItemTableRow} from "../../table";
import {RichTextEditor} from "../../../../_shared/generics/rich-text-editor/RichTextEditor";
import {
  EXTERNAL_DESCRIPTION_KEY,
  INTERNAL_NOTES_KEY,
} from "../../../shared/constants";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import _ from "lodash";
import {WHITE} from "../../../../_shared/colors";

interface InfoSetCellRichTextEditorModalProps {
  row?: Row<InfoSetItemTableRow>;
  originalRow?: InfoSetItemTableRow;
  field?: string;
  handleSave: (v: string | undefined) => void;
}

const InfoSetCellRichTextEditorModal = (
  props: InfoSetCellRichTextEditorModalProps
) => {
  const {row, originalRow, field, handleSave} = props;
  const [cellValue, setCellValue] = useState<string | undefined>("");

  useEffect(() => {
    if (!field) {
      return;
    }
    setCellValue(row?.values[field] ?? "");
  }, [row?.values, field]);

  const canSave = useMemo(() => {
    if (!field || !row) {
      return false;
    }
    // @ts-ignore
    return !_.isEqual(cellValue, originalRow[field]);
  }, [cellValue, row, field]);

  const canAbandon = useMemo(() => {
    if (!field || !row) {
      return false;
    }
    // @ts-ignore
    return !_.isEqual(cellValue, row?.values[field]);
  }, [cellValue, row, field]);

  const handleReset = () => {
    if (!field || !row) {
      return false;
    }
    // @ts-ignore
    handleSave(originalRow[field]);
  };
  const handleAbandon = () => {
    if (!field || !row) {
      return false;
    }
    // @ts-ignore
    handleSave(row?.values[field]);
  };

  const title = useMemo(() => {
    switch (field) {
      case EXTERNAL_DESCRIPTION_KEY:
        return "Description";
      case INTERNAL_NOTES_KEY:
        return "Internal Notes";
      default:
        return undefined;
    }
  }, [field]);

  return (
    <NatModal
      header={`Editing ${title}`}
      size={NatModalSize.LARGE}
      content={
        <RichTextEditor
          value={cellValue ?? ""}
          onChange={(v: string) => {
            if (v?.length === 0) {
              setCellValue(undefined);
            } else {
              setCellValue(v);
            }
          }}
          containerProps={{
            style: {
              height: "auto",
              width: "100%",
              backgroundColor: WHITE,
              resize: "none",
              minHeight: "0px",
              minWidth: "320px",
            },
          }}
        />
      }
      show={!!field && !!row && !!title}
      handleClose={handleAbandon}
      footerButtons={[
        {
          label: "Reset to Original",
          clickEvent: handleReset,
          size: NatSize.SMALL,
          option: StyleOption.DESTRUCTIVE,
          hidden: !canSave,
        },
        {
          label: "Abandon Edits",
          clickEvent: handleAbandon,
          size: NatSize.SMALL,
          option: StyleOption.PRIMARY_BLACK,
          hidden: !canAbandon,
        },
        {
          label: "Save Edits",
          clickEvent: () => handleSave(cellValue),
          size: NatSize.SMALL,
          option: StyleOption.PRIMARY,
          disabled: !canSave,
        },
      ]}
    />
  );
};

export default InfoSetCellRichTextEditorModal;
