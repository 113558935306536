import React, {createContext, useEffect, useMemo, useState} from "react";
import {
  BreakdownPageContainer,
  CostTabToggle,
  CostTabToggleContainer,
  ToggleContainer,
} from "./styles";
import {ProjectCostCard} from "./components/ProjectCostCard";
import {usePage} from "../../../_shared/hooks/usePage";
import {NatDropdown} from "../../../_shared/generics/popup/dropdown/NatDropdown";
import {NatPopupItemElement} from "../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {NatHorizontalAlign, NatSize} from "../../../_shared/generics/_shared";
import {
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../_shared/generics/popup/dropdown/constants";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import {
  ADDITIONAL_SITE_WORK_COST_TAB_NAME,
  OVERALL_COST_TAB_ID,
  OVERALL_COST_TAB_NAME,
  PHASE_1_DESIGN_TAB_NAME,
  PHASE_1_PERMIT_TAB_NAME,
  PHASE_1_TAB_NAME,
  PROJECT_CONTINGENCY_TAB_NAME,
  SITE_WORK_COST_TAB_NAME,
  UNIT_COST_TAB_NAME,
} from "./constants/tabsTemplates";
import {isBlankString, ProjectPriceMode} from "@natomas-org/core";
import NatLabel, {NatLabelType} from "../../../_shared/generics/label/NatLabel";
import {useIsAdminSystem} from "../../../_shared/hooks/useIsAdminSystem";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";
import {Phase1BudgetSummaryDTC} from "./components/Phase1/Phase1BudgetSummaryDTC";
import {SiteBudgetSummary} from "./components/SiteWork/SiteBudgetSummary";
import {UnitBudgetSummary} from "./components/UnitDisplay/UnitBudgetSummary";
import {AdditionalSiteCostsSummary} from "./components/Additional/AdditionalSiteCostsSummary";
import {OverallBudgetSummary} from "./components/OverallBudgetSummary";
import {Phase1DesignBudgetSummary} from "./components/Phase1/Phase1DesignBudgetSummary";
import {Phase1PermitBudgetSummary} from "./components/Phase1/Phase1PermitBudgetSummary";
import {useProjectBudget} from "../../../_shared/hooks/useProjectBudget/useProjectBudget";
import {BudgetColumns} from "./components/generic/GenericBudgetEditor";

export const BudgetAdminContext = createContext(false);

export const ProposalBudgetSummaryView = (props: {
  hideCostCardDescription: boolean;
  hideCostCard: boolean;
  hideCostSummary?: boolean;
  presentationView?: boolean;
  isAdmin?: boolean;
}) => {
  const {site, projectPriceMode} = useActiveProject();
  const {isNatMobile} = usePage();
  const isAdminSystem = useIsAdminSystem();
  const [mobileDropdownLabel, setMobileDropdownLabel] = useState(
    OVERALL_COST_TAB_NAME
  );
  const {projectBudget, refreshBudget, clearBudget} = useProjectBudget();
  const [tab, setTab] = useState<NatPopupItemElement | undefined>();
  const tabs = useMemo(() => {
    const tabs = [];

    if (projectPriceMode === ProjectPriceMode.CostPlus) {
      tabs.push({
        id: OVERALL_COST_TAB_ID,
        label: OVERALL_COST_TAB_NAME,
        component: (
          <OverallBudgetSummary
            isFullView={true}
            refreshBudget={refreshBudget}
            clearBudget={clearBudget}
            budgetEntries={projectBudget?.entries}
            columns={[
              BudgetColumns.TITLE,
              BudgetColumns.PRICE,
              BudgetColumns.DESCRIPTION,
            ]}
          />
        ),
      });
      tabs.push({
        id: "phase1Design",
        label: PHASE_1_DESIGN_TAB_NAME,
        component: (
          <Phase1DesignBudgetSummary
            isFullView={true}
            budgetEntries={projectBudget
              ?.getPreconDesignCostPlus()
              ?.getFullBudgetEntries()}
            refreshBudget={refreshBudget}
            clearBudget={clearBudget}
            columns={[
              BudgetColumns.TITLE,
              BudgetColumns.PRICE,
              BudgetColumns.DESCRIPTION,
            ]}
          />
        ),
      });

      tabs.push({
        id: "phase1Permits",
        label: PHASE_1_PERMIT_TAB_NAME,
        component: (
          <Phase1PermitBudgetSummary
            isFullView={true}
            budgetEntries={projectBudget
              ?.getPreconPermitsCostPlus()
              ?.getFullBudgetEntries()}
            refreshBudget={refreshBudget}
            clearBudget={clearBudget}
            columns={[
              BudgetColumns.TITLE,
              BudgetColumns.PRICE,
              BudgetColumns.DESCRIPTION,
            ]}
          />
        ),
      });
    } else {
      tabs.push({
        id: "phase1",
        label: PHASE_1_TAB_NAME,
        component: (
          <Phase1BudgetSummaryDTC
            isFullView={true}
            budgetEntries={projectBudget
              ?.getPreconDTC()
              ?.getFullBudgetEntries()}
            refreshBudget={refreshBudget}
            clearBudget={clearBudget}
            columns={[
              BudgetColumns.TITLE,
              BudgetColumns.PRICE,
              BudgetColumns.DESCRIPTION,
            ]}
          />
        ),
      });
    }
    tabs.push(
      ...[
        {
          id: "unit",
          label: UNIT_COST_TAB_NAME,
          component: (
            <UnitBudgetSummary
              isFullView={true}
              budgetEntries={projectBudget?.getHomeDesign()?.getEntries()}
              refreshBudget={refreshBudget}
              clearBudget={clearBudget}
              columns={[
                BudgetColumns.TITLE,
                BudgetColumns.PRICE,
                BudgetColumns.COST,
                BudgetColumns.DESCRIPTION,
              ]}
            />
          ),
        },
        {
          id: "siteWork",
          label: SITE_WORK_COST_TAB_NAME,
          component: (
            <SiteBudgetSummary
              isFullView={true}
              budgetEntries={projectBudget
                ?.getSiteWork()
                ?.getFullBudgetEntries()}
              refreshBudget={refreshBudget}
              clearBudget={clearBudget}
              columns={[
                BudgetColumns.TITLE,
                BudgetColumns.PRICE,
                BudgetColumns.DESCRIPTION,
              ]}
            />
          ),
        },
        {
          id: "additional",
          label:
            projectPriceMode === ProjectPriceMode.CostPlus
              ? PROJECT_CONTINGENCY_TAB_NAME
              : ADDITIONAL_SITE_WORK_COST_TAB_NAME,
          component: (
            <AdditionalSiteCostsSummary
              isFullView={true}
              budgetEntries={projectBudget?.getAdditional()?.getEntries()}
              refreshBudget={refreshBudget}
              clearBudget={clearBudget}
              columns={[BudgetColumns.TITLE, BudgetColumns.PRICE]}
            />
          ),
        },
      ]
    );
    return tabs.map((tab: NatPopupItemElement) => {
      return {
        ...tab,
        component: (
          <div
            onClick={(e) => {
              setTab(tab);
              setMobileDropdownLabel(tab.label);
            }}
            key={tab.id}
          >
            {isNatMobile ? (
              <NatLabel label={tab.label} type={NatLabelType.H4} />
            ) : (
              tab.component
            )}
          </div>
        ),
        displayComponent: tab.component,
      };
    });
  }, [
    clearBudget,
    isNatMobile,
    projectBudget,
    projectPriceMode,
    refreshBudget,
  ]);
  useEffect(() => {
    if (!tab && tabs.length > 0) {
      setTab(tabs[0]);
    }
  }, [tab, tabs]);
  const {presentationView} = props;
  useEffect(() => {
    const id = tab?.id ?? null;
    if (!id || isBlankString(id)) {
      setTab(tabs[0]);
    }
  }, [tab?.id, tabs]);
  if (isNatMobile || (!site?.visible && !isAdminSystem)) {
    return (
      <NatFlex
        HORIZONTAL_ALIGN={NatHorizontalAlign.CENTER}
        FULL_WIDTH
        style={{
          padding: isAdminSystem ? "0 2rem" : undefined,
          justifyContent: "center",
        }}
      >
        <ProjectCostCard hideDescription={false} />
      </NatFlex>
    );
  }
  const TabBar = (props: {
    staticSelectedId?: string;
    style?: React.CSSProperties;
  }) => {
    const noEditing = !!props.staticSelectedId;
    if (isNatMobile) {
      return (
        <div style={props.style}>
          <br />
          <NatDropdown
            selectedValue={tab}
            toggleButtonOrLabel={mobileDropdownLabel}
            contentItemArray={tabs}
            trackingId={"budget-tab-dropdown"}
            disabled={noEditing}
            style={{
              TYPE: NatPopupType.HIDE,
              DROPDOWN_BUTTON_SIZE: NatSize.LARGE,
              ADDITIONAL_STYLE_OPTIONS: [NatPopupStyleOptions.CLOSE_ON_SELECT],
            }}
          />
          <br />
        </div>
      );
    } else {
      return (
        <div
          style={{display: "flex", justifyContent: "center", ...props.style}}
        >
          <ToggleContainer>
            <CostTabToggleContainer>
              {tabs.map((tabInfo, idx) => {
                let displayLabel = tabInfo.label;
                if (
                  presentationView &&
                  projectPriceMode === ProjectPriceMode.CostPlus
                ) {
                  displayLabel = `${idx + 1}. ${tabInfo.label}`;
                }
                return (
                  <CostTabToggle
                    onClick={
                      !noEditing
                        ? () => {
                            setTab(tabInfo);
                          }
                        : undefined
                    }
                    active={
                      noEditing
                        ? props.staticSelectedId === tabInfo.id
                        : tab?.id === tabInfo.id
                    }
                    key={tabInfo.id}
                  >
                    {displayLabel}
                  </CostTabToggle>
                );
              })}
            </CostTabToggleContainer>
          </ToggleContainer>
        </div>
      );
    }
  };

  return (
    <BudgetAdminContext.Provider value={props.isAdmin ?? false}>
      <div
        style={{
          display: "flex",
          justifyContent:
            projectPriceMode === ProjectPriceMode.CostPlus
              ? "center"
              : "flex-start",
          paddingBottom:
            projectPriceMode === ProjectPriceMode.CostPlus ? "1rem" : "0",
        }}
        hidden={props.hideCostCard}
      >
        <ProjectCostCard
          hideDescription={props.hideCostCardDescription}
          removeMarginAndBorder={isAdminSystem}
        />
      </div>

      {tabs.map(
        (
          tabInfo: NatPopupItemElement & {displayComponent?: JSX.Element},
          idx
        ) => {
          return (
            <div key={tabInfo.id}>
              <BreakdownPageContainer
                key={tabInfo.id}
                hidden={tab?.id !== tabInfo.id}
                style={{
                  pageBreakInside: "auto",
                  breakInside: "auto",
                }}
              >
                <TabBar />
                {isNatMobile ? tabInfo.displayComponent : tabInfo.component}
              </BreakdownPageContainer>
            </div>
          );
        }
      )}
    </BudgetAdminContext.Provider>
  );
};
