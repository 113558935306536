import {
  ADMIN_BUTTON_ACTIVE,
  APP_SWITCHER_BACKGROUND,
  AVATAR_PILL_HOVER_COLOR,
  BLACK,
  DEFAULT_SHADOW,
  DESTRUCTIVE_FILL,
  DESTRUCTIVE_FILL_HOVER,
  DEVELOPER_FILL,
  DEVELOPER_FILL_HOVER,
  ERROR_FILL,
  ERROR_TEXT,
  FOCUS_SHADOW,
  GRANITE,
  GRAY,
  GRAY_BORDER,
  GRAY_BORDER_LIGHT,
  PRIMARY_BLACK,
  PRIMARY_BLACK_HOVER,
  PRIMARY_BUTTON_COLOR,
  PRIMARY_BUTTON_HOVER,
  PRIMARY_LINK_ALT,
  PRIMARY_LINK_ALT_HOVER,
  PRIMARY_LINK_HOVER,
  SECONDARY_BLACK_HOVER,
  TOGGLE_BORDER_COLOR,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
  VILLA_APPLE_GREEN_LIGHT_2,
  VILLA_BLUE,
  WARNING_FILL,
  WARNING_TEXT,
  WHITE,
} from "../../colors";
import React from "react";
import {isMobile} from "../../navigation";
import {FONT_FAMILY_REGULAR} from "../../../portal/views/SSDDashboardView/styles/globals";

enum StyleOption {
  DEVELOPER = 1,
  DESTRUCTIVE,
  PRIMARY,
  PRIMARY_BLACK,
  PRIMARY_BLACK_SUCCESS,
  PRIMARY_ALT,
  PRIMARY_LINK_SUCCESS,
  PRIMARY_LINK,
  PRIMARY_LINK_ALT,
  SECONDARY_LINK,
  SECONDARY,
  SECONDARY_ALT,
  SHADOW_TOGGLE,
  SHADOW_TOGGLE_BLUE,
  SELECTED_TOGGLE_ON,
  SELECTED_TOGGLE_OFF,
  SIMPLE_ACTION,
  SIMPLE_BLACK,
  MAP_BUTTONS,
  ADMIN,
  ADMIN_INVERSE,
  ADMIN_SOLID,
  APP_SWITCHER,
  PRIMARY_DROPDOWN,
  FILTERS,
  FILTERS_ACTIVE,
  FILTERS_HIGHLIGHTED,
  ICON_ONLY,
  WARNING,
  WARNING_INVERSE,
  ERROR,
  ERROR_INVERSE,
  ICON_ONLY_DECORATED_NOT_SELECTED,
  ICON_ONLY_DECORATED_SELECTED,
}

interface INatStyle {
  initialBackgroundColor: string | null;
  initialTextColor: string | null;
  initialTextDecoration: any | null;
  initialBorderColor: string | null;
  hoverBackgroundColor: string | null;
  hoverTextColor: string | null;
  hoverTextDecoration: any | null;
  hoverBorderColor: any | null;
  borderSize?: any | null;
  size: any | null;
  enabledBackgroundColor: string | null;
  enabledBorderColor: string | null;
  enabled?: boolean;
  equalizePadding?: boolean;
  noPadding?: boolean;
  width?: string;
  fontFamily?: string;
  boxShadow?: string;
  hoverBoxShadow?: string;
  additionalStyling?: React.CSSProperties;
  additionalTextStyling?: React.CSSProperties;
}

interface ITextStyle {
  fontSize: NatSize;
  hasLabel?: boolean;
  iconPosition?: IconPosition;
  additionalTextStyling?: React.CSSProperties;
}

export enum NatSizeType {
  XSMALL = "XSMALL",
  SMALL = "SMALL",
  NORMAL = "NORMAL",
  LARGE = "LARGE",
  XLARGE = "XLARGE",
}

enum NatSize {
  XSMALL = "0.625rem", // 10px per rem
  SMALL = "0.8125rem", // 13px per rem
  NORMAL = "1rem", // 16px per rem
  LARGE = "1.25rem", // 20px per rem
  XLARGE = "1.5rem", // 24px per rem
}

export enum NatHorizontalAlign {
  LEFT,
  CENTER,
  RIGHT,
}

export enum NatVerticalAlign {
  TOP = "TOP",
  CENTER = "CENTER",
  BOTTOM = "BOTTOM",
}

export enum NatJustifyContent {
  SPACE_BETWEEN = "space-between",
  EVENLY = "space-evenly",
  AROUND = "space-around",
}

export enum IconPosition {
  LEFT,
  RIGHT,
  TOP,
  ONLY,
}

const DROPDOWN_RADIUS = "0.1725rem";

export interface IconProps {
  icon: any;
  iconPosition: IconPosition;
}

export const getTextContent = (
  icon: any,
  label: string,
  spinning?: boolean
) => {
  if (icon?.iconPosition === IconPosition.TOP) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: spinning ? "2rem" : undefined,
        }}
      >
        {icon?.icon}
        {label}
      </div>
    );
  }
  const iconComponent = icon?.icon && (
    <div style={{display: "flex"}}>{icon?.icon}</div>
  );

  return (
    <>
      {icon?.iconPosition === IconPosition.LEFT && iconComponent}
      {label}
      {icon?.iconPosition === IconPosition.RIGHT && iconComponent}
    </>
  );
};

const PRIMARY_BUTTON_STYLE: INatStyle = {
  initialBackgroundColor: PRIMARY_BUTTON_COLOR,
  initialTextColor: BLACK,
  initialTextDecoration: null,
  initialBorderColor: PRIMARY_BUTTON_COLOR,
  hoverBackgroundColor: PRIMARY_BUTTON_HOVER,
  hoverTextColor: BLACK,
  hoverTextDecoration: null,
  hoverBorderColor: PRIMARY_BUTTON_HOVER,
  size: null,
  enabledBackgroundColor: null,
  enabledBorderColor: null,
};

const PRIMARY_ALT_STYLE: INatStyle = {
  initialBackgroundColor: PRIMARY_BLACK,
  initialTextColor: WHITE,
  initialTextDecoration: null,
  initialBorderColor: PRIMARY_BLACK,
  hoverBackgroundColor: isMobile() ? PRIMARY_BLACK : PRIMARY_BLACK_HOVER,
  hoverTextColor: WHITE,
  hoverTextDecoration: null,
  hoverBorderColor: isMobile() ? PRIMARY_BLACK : PRIMARY_BLACK_HOVER,
  size: null,
  enabledBackgroundColor: null,
  enabledBorderColor: null,
};

const SECONDARY_BUTTON_STYLE: INatStyle = {
  // Initial State
  initialBackgroundColor: WHITE,
  initialTextColor: BLACK,
  initialTextDecoration: null,
  initialBorderColor: BLACK,
  // Hover State
  hoverBackgroundColor: SECONDARY_BLACK_HOVER,
  hoverTextColor: BLACK,
  hoverTextDecoration: null,
  hoverBorderColor: BLACK,
  // Other
  size: null,
  enabledBackgroundColor: null,
  enabledBorderColor: null,
};
const FILTERS_BUTTON_STYLE: INatStyle = {
  // Initial State
  initialBackgroundColor: WHITE,
  initialTextColor: BLACK,
  initialTextDecoration: null,
  initialBorderColor: GRAY_BORDER,
  // Hover State
  hoverBackgroundColor: WHITE,
  hoverTextColor: BLACK,
  hoverTextDecoration: null,
  borderSize: 1,
  hoverBorderColor: `${BLACK}80`,
  // Other
  size: null,
  enabledBackgroundColor: null,
  enabledBorderColor: null,

  // Additional general styling
  additionalStyling: {
    borderStyle: "solid",
    height: "100%",
    width: "100%",
    padding: "0 0.5rem",
    alignItems: "center",
    borderRadius: DROPDOWN_RADIUS,
    borderWidth: "1px",
    textAlign: "center",
    fontFamily: FONT_FAMILY_REGULAR,
  } as React.CSSProperties,
};

export const StyleOptions: {[key: number]: INatStyle} = {
  // -1 will never be taken, and can act as a default
  [-1]: PRIMARY_BUTTON_STYLE,
  [StyleOption.ERROR_INVERSE]: {
    // Initial State
    initialBackgroundColor: ERROR_TEXT,
    initialTextColor: ERROR_FILL,
    initialTextDecoration: null,
    initialBorderColor: ERROR_TEXT,
    // Hover State
    hoverBackgroundColor: ERROR_FILL,
    hoverTextColor: ERROR_TEXT,
    hoverTextDecoration: null,
    hoverBorderColor: ERROR_FILL,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.ERROR]: {
    // Initial State
    initialBackgroundColor: ERROR_FILL,
    initialTextColor: ERROR_TEXT,
    initialTextDecoration: null,
    initialBorderColor: ERROR_FILL,
    // Hover State
    hoverBackgroundColor: ERROR_TEXT,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: ERROR_TEXT,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.WARNING_INVERSE]: {
    // Initial State
    initialBackgroundColor: WARNING_TEXT,
    initialTextColor: WARNING_FILL,
    initialTextDecoration: null,
    initialBorderColor: WARNING_TEXT,
    // Hover State
    hoverBackgroundColor: WARNING_FILL,
    hoverTextColor: WARNING_TEXT,
    hoverTextDecoration: null,
    hoverBorderColor: WARNING_FILL,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.WARNING]: {
    // Initial State
    initialBackgroundColor: WARNING_FILL,
    initialTextColor: WARNING_TEXT,
    initialTextDecoration: null,
    initialBorderColor: WARNING_FILL,
    // Hover State
    hoverBackgroundColor: WARNING_TEXT,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: WARNING_TEXT,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.ICON_ONLY]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: null,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: null,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.ICON_ONLY_DECORATED_NOT_SELECTED]: {
    // Initial State
    initialBackgroundColor: WHITE,
    initialTextColor: null,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: GRAY_BORDER_LIGHT,
    hoverTextColor: null,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.ICON_ONLY_DECORATED_SELECTED]: {
    // Initial State
    initialBackgroundColor: VILLA_APPLE_GREEN_LIGHT_1,
    initialTextColor: null,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: GRAY_BORDER_LIGHT,
    hoverTextColor: null,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.DEVELOPER]: {
    // Initial State
    initialBackgroundColor: DEVELOPER_FILL,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: DEVELOPER_FILL,
    // Hover State
    hoverBackgroundColor: DEVELOPER_FILL_HOVER,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: DEVELOPER_FILL_HOVER,
    // Other
    size: NatSize.SMALL,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.DESTRUCTIVE]: {
    // Initial State
    initialBackgroundColor: DESTRUCTIVE_FILL,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: DESTRUCTIVE_FILL,
    // Hover State
    hoverBackgroundColor: DESTRUCTIVE_FILL_HOVER,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: DESTRUCTIVE_FILL_HOVER,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.PRIMARY]: PRIMARY_BUTTON_STYLE,
  [StyleOption.PRIMARY_ALT]: {
    ...PRIMARY_ALT_STYLE,

    // Old primary alt
    // // Initial State
    // initialBackgroundColor: VILLA_BLUE,
    // initialTextColor: WHITE,
    // initialTextDecoration: null,
    // initialBorderColor: VILLA_BLUE,
    // // Hover State
    // hoverBackgroundColor: BLACK,
    // hoverTextColor: WHITE,
    // hoverTextDecoration: null,
    // hoverBorderColor: BLACK,
    // // Other
    // size: null,
    // enabledBackgroundColor: null,
    // enabledBorderColor: null,
  },
  [StyleOption.PRIMARY_LINK]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: BLACK,
    initialTextDecoration: "underline",
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: PRIMARY_LINK_HOVER,
    hoverTextDecoration: "underline",
    hoverBorderColor: null,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    noPadding: true,
  },
  [StyleOption.PRIMARY_LINK_ALT]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: PRIMARY_LINK_ALT,
    initialTextDecoration: "underline",
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: PRIMARY_LINK_ALT_HOVER,
    hoverTextDecoration: "underline",
    hoverBorderColor: null,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    noPadding: true,
  },
  [StyleOption.SECONDARY_LINK]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: PRIMARY_BUTTON_HOVER,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    noPadding: true,
  },
  [StyleOption.ADMIN]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: ADMIN_BUTTON_ACTIVE,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    noPadding: true,
  },
  [StyleOption.ADMIN_INVERSE]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: ADMIN_BUTTON_ACTIVE,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    noPadding: true,
  },
  [StyleOption.ADMIN_SOLID]: {
    // Initial State
    initialBackgroundColor: VILLA_BLUE,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: VILLA_BLUE,
    // Hover State
    hoverBackgroundColor: ADMIN_BUTTON_ACTIVE,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: ADMIN_BUTTON_ACTIVE,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    noPadding: true,
  },
  [StyleOption.PRIMARY_LINK_SUCCESS]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: VILLA_BLUE,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: PRIMARY_BUTTON_COLOR,
    hoverTextDecoration: "underline",
    hoverBorderColor: null,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    noPadding: true,
  },
  [StyleOption.PRIMARY_BLACK]: PRIMARY_ALT_STYLE,
  [StyleOption.PRIMARY_BLACK_SUCCESS]: {
    initialBackgroundColor: PRIMARY_BLACK,
    initialTextColor: WHITE,
    initialTextDecoration: null,
    initialBorderColor: PRIMARY_BLACK,
    hoverBackgroundColor: PRIMARY_BUTTON_COLOR,
    hoverTextColor: WHITE,
    hoverTextDecoration: null,
    hoverBorderColor: PRIMARY_BUTTON_COLOR,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.SECONDARY]: SECONDARY_BUTTON_STYLE,
  [StyleOption.SECONDARY_ALT]: {
    ...SECONDARY_BUTTON_STYLE,

    // 6/23/22 Old version commented below, now is always black
    // // Initial State
    // initialBackgroundColor: WHITE,
    // initialTextColor: VILLA_BLUE,
    // initialTextDecoration: null,
    // initialBorderColor: VILLA_BLUE,
    // // Hover State
    // hoverBackgroundColor: WHITE,
    // hoverTextColor: BLACK,
    // hoverTextDecoration: null,
    // hoverBorderColor: BLACK,
    // // Other
    // size: NatSize.SMALL,
    // enabledBackgroundColor: null,
    // enabledBorderColor: null,
  },
  [StyleOption.SELECTED_TOGGLE_OFF]: {
    // Initial State
    initialBackgroundColor: WHITE,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: TOGGLE_BORDER_COLOR,
    // Hover State
    hoverBackgroundColor: WHITE,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: AVATAR_PILL_HOVER_COLOR,
    // Other
    size: null,
    enabledBackgroundColor: TOGGLE_BORDER_COLOR,
    enabledBorderColor: TOGGLE_BORDER_COLOR,
    additionalStyling: {
      fontFamily: FONT_FAMILY_REGULAR,
    } as React.CSSProperties,
  },

  [StyleOption.SIMPLE_ACTION]: {
    // Initial State
    initialBackgroundColor: null,
    initialTextColor: GRAY,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: BLACK,
    hoverTextDecoration: "underline",
    hoverBorderColor: null,
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.APP_SWITCHER]: {
    // Initial State
    initialBackgroundColor: APP_SWITCHER_BACKGROUND,
    initialTextColor: PRIMARY_LINK_ALT,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: APP_SWITCHER_BACKGROUND,
    hoverTextColor: PRIMARY_LINK_ALT,
    hoverTextDecoration: "underline",
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    // Additional styling
    additionalStyling: {
      fontFamily: FONT_FAMILY_REGULAR,
      borderRadius: "1rem",
    } as React.CSSProperties,
  },
  [StyleOption.SIMPLE_BLACK]: {
    // Initial State
    initialBackgroundColor: "rgba(0, 0, 0, 0)",
    initialTextColor: "black",
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: null,
    hoverTextColor: BLACK,
    hoverTextDecoration: "underline",
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.SHADOW_TOGGLE]: {
    // Initial State
    initialBackgroundColor: WHITE,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: WHITE,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    boxShadow: DEFAULT_SHADOW,
    hoverBoxShadow: FOCUS_SHADOW,
  },
  [StyleOption.PRIMARY_DROPDOWN]: {
    // Initial State
    initialBackgroundColor: WHITE,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: WHITE,
    hoverTextColor: `${BLACK}70`,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    additionalStyling: {fontFamily: FONT_FAMILY_REGULAR, width: "100%"},
  },
  [StyleOption.SHADOW_TOGGLE_BLUE]: {
    // Initial State
    initialBackgroundColor: WHITE,
    initialTextColor: VILLA_BLUE,
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: WHITE,
    hoverTextColor: VILLA_BLUE,
    hoverTextDecoration: null,
    hoverBorderColor: null,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
    boxShadow: DEFAULT_SHADOW,
    hoverBoxShadow: FOCUS_SHADOW,
  },
  [StyleOption.SELECTED_TOGGLE_ON]: {
    // Initial State
    initialBackgroundColor: `${VILLA_APPLE_GREEN}40`,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: VILLA_APPLE_GREEN,
    // Hover State
    hoverBackgroundColor: `${VILLA_APPLE_GREEN}40`,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: VILLA_APPLE_GREEN,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: VILLA_APPLE_GREEN,
    additionalStyling: {
      fontFamily: FONT_FAMILY_REGULAR,
    } as React.CSSProperties,
  },
  [StyleOption.MAP_BUTTONS]: {
    // Initial State
    initialBackgroundColor: "white",
    initialTextColor: "#7e7e7e",
    initialTextDecoration: null,
    initialBorderColor: null,
    // Hover State
    hoverBackgroundColor: VILLA_APPLE_GREEN, //VILLA2_BLUE,
    hoverTextColor: "white",
    hoverTextDecoration: null,
    hoverBorderColor: VILLA_APPLE_GREEN, //VILLA2_BLUE,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,
  },
  [StyleOption.FILTERS]: {
    ...FILTERS_BUTTON_STYLE,
  },
  [StyleOption.FILTERS_ACTIVE]: {
    // Initial State
    initialBackgroundColor: VILLA_APPLE_GREEN_LIGHT_1,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: VILLA_APPLE_GREEN,
    // Hover State
    hoverBackgroundColor: VILLA_APPLE_GREEN_LIGHT_2,
    borderSize: 1,
    hoverTextColor: BLACK,
    hoverTextDecoration: null,
    hoverBorderColor: VILLA_APPLE_GREEN,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,

    // Additional general styling
    additionalStyling: FILTERS_BUTTON_STYLE.additionalStyling,
  },
  [StyleOption.FILTERS_HIGHLIGHTED]: {
    // Initial State
    initialBackgroundColor: `${GRANITE}20`,
    initialTextColor: BLACK,
    initialTextDecoration: null,
    initialBorderColor: GRAY_BORDER,
    // Hover State
    borderSize: 1,
    hoverBackgroundColor: null,
    hoverTextColor: null,
    hoverTextDecoration: null,
    hoverBorderColor: `${BLACK}80`,
    // Other
    size: null,
    enabledBackgroundColor: null,
    enabledBorderColor: null,

    // Additional general styling
    additionalStyling: FILTERS_BUTTON_STYLE.additionalStyling,
  },
};

const getInitialBackgroundColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialBackgroundColor;
};
const getInitialTextColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialTextColor;
};
const getInitialTextDecoration = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialTextDecoration;
};
const getInitialBorderColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].initialBorderColor;
};
const getHoverBackgroundColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverBackgroundColor;
};
const getHoverTextColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverTextColor;
};
const getHoverTextDecoration = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverTextDecoration;
};
const getHoverBorderSize = (option?: StyleOption) => {
  return StyleOptions[option || -1].borderSize;
};
const getHoverBorderColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverBorderColor;
};
const getEnabledBorderColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].enabledBorderColor;
};
const getEnabledBackgroundColor = (option?: StyleOption) => {
  return StyleOptions[option || -1].enabledBackgroundColor;
};
const getBoxShadow = (option?: StyleOption) => {
  return StyleOptions[option || -1].boxShadow;
};
const getHoverBoxShadow = (option?: StyleOption) => {
  return StyleOptions[option || -1].hoverBoxShadow;
};
const getTextSize = (size?: NatSize) => {
  if (size) return size;
  else return NatSize.NORMAL;
};
const getAdditionalTextStylingFromButtonStyle = (
  option?: StyleOption
): React.CSSProperties | undefined => {
  return StyleOptions[option || -1].additionalTextStyling;
};
const getAdditionalStyling = (
  option?: StyleOption
): React.CSSProperties | undefined => {
  return StyleOptions[option || -1].additionalStyling;
};

export {
  StyleOption,
  NatSize,
  getBoxShadow,
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getHoverBackgroundColor,
  getHoverBorderColor,
  getHoverBoxShadow,
  getHoverTextColor,
  getHoverTextDecoration,
  getInitialBackgroundColor,
  getInitialBorderColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getTextSize,
  getAdditionalStyling,
  getAdditionalTextStylingFromButtonStyle,
  getHoverBorderSize,
};
export type {INatStyle, ITextStyle};
