import {IUserAccess, UserAccessTypes} from "@natomas-org/core";
import React from "react";
import {AccessTag, SuperAdminTag} from "../styles";
import {UserAccessTitle} from "../EmployeeAccessControlView";

export const AccessSummary = (props: {
  isFetching: boolean;
  anyAccess: boolean;
  fullAccess: boolean;
  access: IUserAccess;
}) => {
  const {isFetching, anyAccess, fullAccess, access} = props;
  if (isFetching) {
    return <div>Loading...</div>;
  } else if (!anyAccess) {
    return <div>No access</div>;
  } else if (fullAccess) {
    return <SuperAdminTag>Full access</SuperAdminTag>;
  } else {
    let r = "";

    let count = 0;
    Object.entries(access)?.forEach((accessType) => {
      const type = accessType[0] as UserAccessTypes;
      if (accessType[1]) {
        if (count > 0) {
          r += ", ";
        }
        r += UserAccessTitle[type];
        count++;
      }
    });
    return <AccessTag>{r}</AccessTag>;
  }
};
