import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK} from "../../../../_shared/colors";

export const RowLabelContextMenuItem = styled.div<{
  italics?: boolean;
  bold?: boolean;
  borderTop?: boolean;
}>`
  padding: 8px 12px;
  color: #393939;
  cursor: pointer;
  font-size: ${(props) => (props?.bold ? 16 : 14)}px;
  font-style: ${(props) => props?.italics && "italic"};
  font-family: ${(props) => props?.bold && FONT_FAMILY_MEDIUM};
  border-top: ${(props) => props.borderTop && `1px solid ${BLACK}`};
  &:hover {
    background: #ffffff;
  }
`;

export const RowLabelContextMenuHeader = styled.div<{
  italics?: boolean;
  bold?: boolean;
}>`
  padding: 8px 12px;
  color: #393939;
  cursor: pointer;
  font-size: ${(props) => (props?.bold ? 16 : 16)}px;
  font-style: ${(props) => props?.italics && "italic"};
  font-family: ${(props) => props?.bold && FONT_FAMILY_MEDIUM};
  svg {
    height: 16px;
    width: 16px;
  }
`;
