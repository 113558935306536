import React, {Dispatch, SetStateAction} from "react";
import {NatomasToggle} from "./styles";
import {
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getHoverBackgroundColor,
  getHoverBorderColor,
  getHoverTextColor,
  getHoverTextDecoration,
  getInitialBackgroundColor,
  getInitialBorderColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getTextContent,
  getTextSize,
  IconProps,
  NatSize,
  StyleOption,
} from "../_shared";
import {TextContainer} from "../button/styles";

export interface INatToggle {
  label: string;
  enabled: boolean;
  setEnabled: Dispatch<SetStateAction<boolean>>;
  trackingId: string;
  size?: NatSize;
  option?: StyleOption;
  icon?: IconProps | undefined;
  id?: string;
}

export const NatToggle = (props: INatToggle): JSX.Element => {
  const {option, size, label, setEnabled, enabled, trackingId, icon, id} =
    props;

  const toggle = () => {
    setEnabled(!enabled);
  };

  return (
    <NatomasToggle
      id={id}
      initialBackgroundColor={getInitialBackgroundColor(option)}
      initialTextColor={getInitialTextColor(option)}
      initialTextDecoration={getInitialTextDecoration(option)}
      initialBorderColor={getInitialBorderColor(option)}
      hoverBackgroundColor={
        enabled
          ? getEnabledBackgroundColor(option)
          : getHoverBackgroundColor(option)
      }
      hoverTextColor={getHoverTextColor(option)}
      hoverTextDecoration={getHoverTextDecoration(option)}
      hoverBorderColor={getHoverBorderColor(option)}
      enabled={enabled}
      enabledBorderColor={getEnabledBorderColor(option)}
      enabledBackgroundColor={getEnabledBackgroundColor(option)}
      size={getTextSize(size)}
      onClick={toggle}
      name={trackingId}
    >
      <TextContainer
        fontSize={getTextSize(size)}
        iconPosition={icon?.iconPosition}
      >
        {getTextContent(icon, label)}
      </TextContainer>
    </NatomasToggle>
  );
};
