import {InputProps} from "./interface";
import Input from "./input";
import React from "react";
import {InputContainer} from "../Form/style";

const InputRow = (props: {id: string; inputs: InputProps[]}) => {
  const {id, inputs} = props;
  const hasGap = inputs.length > 1;
  return (
    <InputContainer key={id} gap={hasGap ? "16px" : 0}>
      {inputs.map((input, index) => (
        <Input {...input} key={`${id}-${index}`} />
      ))}
    </InputContainer>
  );
};

export const Inputs = (props: {inputs: InputProps[]}) => {
  const {inputs} = props;
  const rows = groupByRow(inputs);
  return (
    <>
      {rows
        .filter((row) => row.length > 0)
        .map((group, index) => (
          <InputRow
            inputs={group}
            id={`row-${index}`}
            key={`input-row-${index}`}
          />
        ))}
    </>
  );
};

const groupByRow = (list: InputProps[]): InputProps[][] => {
  // Initialize an object to store the grouped items
  const rows: {[row: number]: InputProps[]} = {};
  // Initialize an array to store items without a row
  let withoutRow: InputProps[] = [];

  // Iterate over each item in the list
  list.forEach((item) => {
    // Check if the item has a row
    if (item.row !== undefined) {
      // Get the row number
      const row = item.row;

      // If the row number doesn't exist in the grouped object, create an empty array
      if (!rows[row]) {
        rows[row] = [];
      }

      // Push the item into the array corresponding to its row number
      rows[row].push(item);
    } else {
      // If item doesn't have a row, push it to the array withoutRow
      withoutRow.push(item);
    }
  });

  // Convert the grouped object into an array of arrays
  const result = Object.values(rows);

  // Append the array of items without a row to the end
  if (withoutRow.length > 0) {
    withoutRow.forEach((item) => {
      result.push([item]);
    });
  }

  return result;
};
