import React, {FormEvent} from "react";
import {CustomerAccountView} from "../CustomerAccountView";
import {CustomerProjectsTable} from "../CustomerProjectsTable";
import {
  getAdminBudgetSummaryManagerTab,
  getAdminCartItemsTab,
  getAdminControlPanelTab,
  getAdminCustomerOrderFormManagerTab,
  getAdminCustomerProposalViewerEditorTab,
  getAdminDocumentsManagerTab,
  getAdminNotesTab,
  getAdminQuickInfoTab,
  getAdminSiteManagerTab,
} from "./tabs/AdminCustomerTabs";
import {ProjectPriceMode} from "@natomas-org/core";

export const CustomerAccountManagementId = "account";
export const CustomerProjectsTableId = "projects";
export const CustomerQuickInfoId = "project-info";
export const ProjectCartTabId = "project-cart";
export const CustomerControlPanelId = "project-control-panel";
export const CustomerSitePlanManagerId = "project-site-plan-manager";
export const CustomerBudgetSummaryManagerId = "project-budget-summary-manager";
export const CustomerProposalViewerEditorId =
  "project-proposal-viewer-editor-manager";
export const CustomerDocusignManagerId = "project-docusign-manager";
export const CustomerProjectNotesManagerId = "project-notes-manager";
export const CustomerOrderFormManagerId = "project-order-form";

export interface IAdminCustomerTab {
  component: JSX.Element;
  id: string;
  title: string;
}

export interface CustomerProjectTabProps {
  setTab?: any;
  deleteAction?: any;
  invalidSettingsWarning: string | null;
  discardAction?: () => void;
  saveAction?: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  isUpToDate?: boolean;
  additionalActions?: JSX.Element;
}

export const ProjectViewTabsB2B: {[key: string]: IAdminCustomerTab} = {
  [CustomerQuickInfoId]: getAdminQuickInfoTab(),
  [ProjectCartTabId]: getAdminCartItemsTab(),
  [CustomerControlPanelId]: getAdminControlPanelTab(),
  [CustomerSitePlanManagerId]: getAdminSiteManagerTab(),
  [CustomerBudgetSummaryManagerId]: getAdminBudgetSummaryManagerTab("b2b"),
  [CustomerProposalViewerEditorId]: getAdminCustomerProposalViewerEditorTab(),
  [CustomerProjectNotesManagerId]: getAdminNotesTab(),
  [CustomerDocusignManagerId]: getAdminDocumentsManagerTab(),
  [CustomerOrderFormManagerId]: getAdminCustomerOrderFormManagerTab(),
};

export const ProjectViewTabsDTC: {[key: string]: IAdminCustomerTab} = {
  [CustomerQuickInfoId]: getAdminQuickInfoTab(),
  [ProjectCartTabId]: getAdminCartItemsTab(),
  [CustomerControlPanelId]: getAdminControlPanelTab(),
  [CustomerSitePlanManagerId]: getAdminSiteManagerTab(),
  [CustomerBudgetSummaryManagerId]: getAdminBudgetSummaryManagerTab("dtc"),
  [CustomerProposalViewerEditorId]: getAdminCustomerProposalViewerEditorTab(),
  [CustomerProjectNotesManagerId]: getAdminNotesTab(),
  [CustomerDocusignManagerId]: getAdminDocumentsManagerTab(),
  [CustomerOrderFormManagerId]: getAdminCustomerOrderFormManagerTab(),
};

export const CustomerViewTabs: {[key: string]: IAdminCustomerTab} = {
  [CustomerProjectsTableId]: {
    component: <CustomerProjectsTable />,
    id: CustomerProjectsTableId,
    title: "Projects",
  },
  [CustomerAccountManagementId]: {
    component: <CustomerAccountView />,
    id: CustomerAccountManagementId,
    title: "Account Management",
  },
};

export const getCustomerProjectTabs = (projectPriceMode: ProjectPriceMode) => {
  if (projectPriceMode === ProjectPriceMode.CostPlus) {
    return ProjectViewTabsB2B;
  }
  return ProjectViewTabsDTC;
};

export const getCustomerTabs = () => {
  return CustomerViewTabs;
};
