import React, {useEffect, useMemo} from "react";
import StudioStaticView from "../../../../../../../../../../studio/Static";
import {convertEditorToPreview, getAllModifiers} from "../formik/preview";
import {StudioPreviewWrapper} from "./styles";
import {useDispatch} from "react-redux";
import {setStudioSelections} from "../../../../../../../../../../_shared/slices/StudioSlice";
import {getSummaryPricing} from "@natomas-org/service";
import {ProjectPriceMode} from "@natomas-org/core";

interface StudioEditorPreviewProps {
  priceMode: ProjectPriceMode;
  product?: any;
  formik?: any;
}

const StudioEditorPreview = (props: StudioEditorPreviewProps) => {
  const {product, formik, priceMode} = props;
  const dispatch = useDispatch();

  const snapshot = useMemo(() => {
    const studioSnapshot = convertEditorToPreview(formik?.values);
    const selectedModifiers = getAllModifiers(studioSnapshot);
    const pricing = getSummaryPricing(product, selectedModifiers, 0, priceMode);

    return {
      summary: {
        ...pricing,
        product: product,
        selectedModifiers: selectedModifiers,
        structure: studioSnapshot,
      },
    };
  }, [formik?.values, priceMode, product]);

  useEffect(() => {
    const selectionIds: string[] =
      snapshot?.summary?.selectedModifiers?.map(
        (modifier: any) => modifier?.id
      ) ?? [];
    let selections: {[id: string]: boolean} = {};
    selectionIds?.forEach((id: string) => {
      selections[id] = true;
    });
    dispatch(setStudioSelections(selections));
  }, [dispatch, snapshot]);

  return (
    // <div>Studio Preview currently unavailable</div>
    <StudioPreviewWrapper>
      <StudioStaticView
        product={product}
        snapshot={snapshot}
        priceMode={priceMode}
      />
    </StudioPreviewWrapper>
  );
};

export default StudioEditorPreview;
