import React, {Fragment, useEffect} from "react";
import {AddressSliceManager} from "./AddressSliceManager";
import {CatalogSessionEligibilityManager} from "./CatalogSessionEligibilityManager/CatalogSessionEligibilityManager";
import {CatalogSliceManager} from "./CatalogSliceManager";
import {FiltersModal} from "../components/_shared/modals/FiltersModal/FiltersModal";
import {PropertyDataManager} from "../components/_shared/generics/PropertyDataManager";
import {useSelector} from "react-redux";
import {IStore} from "../components/_shared/slices/types/Store";
import {usePage} from "../components/_shared/hooks/usePage";
import {ThemeProvider} from "styled-components";
import {AssessmentModal} from "../components/_shared/modals/AssessmentModal/AssessmentModal";
import {SurveysInSlicesManager} from "./SurveyManager/SurveysInSlicesManager";
import {SessionSliceManager} from "./SessionSliceManager";
import {ProjectSliceManager} from "./ProjectSliceManager/ProjectSliceManager";
import ActivitySliceManager from "./ActivitySliceManager";
import {unsubscribeFromAllSubscriptions} from "./subscriptions";
import URLManager from "./URLManager";
import CustomerManager from "./CustomerManager";
import CartSliceManager from "./CartSliceManager";
import {BudgetSliceManager} from "./BudgetSliceManager/BudgetSliceManager";
import AuthenticationModal from "../0-app/0-modal/authentication";

export const RootManagement = () => {
  const {isNatMobile} = usePage();

  useEffect(() => {
    return () => {
      unsubscribeFromAllSubscriptions();
    };
  }, []);

  function getAuthenticationModal(path: string) {
    if (path !== "/login" && path !== "/start") {
      return <AuthenticationModal />;
    }

    return null; // or return a default value or handle accordingly
  }

  return (
    <Fragment>
      {/*Data managers*/}
      <ActivitySliceManager />
      <AddressSliceManager />
      <BudgetSliceManager />
      <CartSliceManager />
      <CatalogSliceManager />
      <CatalogSessionEligibilityManager />
      <CustomerManager />
      <PropertyDataManager />
      <ProjectSliceManager />
      <SessionSliceManager />
      <SurveysInSlicesManager />
      <URLManager />
      {/*Modals*/}
      {getAuthenticationModal(window.location.pathname)}
      <FiltersModal />
      <ThemeProvider
        theme={{
          Page: {
            isNatMobile: isNatMobile,
            gutterWidthPercentage:
              useSelector((state: IStore) => state.page.gutterWidthPercentage) /
              2,
          },
        }}
      >
        <AssessmentModal />
      </ThemeProvider>
    </Fragment>
  );
};
