import React, {useMemo} from "react";
import {
  ImageContainer,
  ImageCountLabel,
  ImageEditorContainer,
  ImagePreviewContainer,
} from "./styles";
import {IFactoryInventoryStudioEditorCategoryImageGroup} from "./interface";
import {StudioEditorFieldType} from "../../_shared/StudioEditorFieldAddButton";
import {IFactoryInventoryStudioEditorPopupOptions} from "../../PopupContent/interface";
import RenderingPreview from "./RenderingPreview";
import StudioEditorFieldConditionalController from "../../_shared/StudioEditorFieldButton";
import {StudioEditorMode} from "../../../../index";

interface CategoryImagesEditorProps {
  id: string;
  imageGroup: IFactoryInventoryStudioEditorCategoryImageGroup;
  setActionEvent?: (
    e: any,
    options?: IFactoryInventoryStudioEditorPopupOptions
  ) => void;
  categoryId?: string;
  mode?: StudioEditorMode;
}

const CategoryImagesEditor = (props: CategoryImagesEditorProps) => {
  const {id, imageGroup, setActionEvent, categoryId, mode} = props;
  const {images, id: imageGroupId} = imageGroup ?? {};

  const studioCarouselImage = useMemo(() => {
    return {
      overlayImages: images,
      orientation: images?.[0]?.orientation,
      objectFit: images?.[0]?.objectFit,
    };
  }, [images]);

  return (
    <ImageEditorContainer id={`${id}-renderings`}>
      {mode && setActionEvent && (
        <StudioEditorFieldConditionalController
          path={{
            categoryId,
            imageGroupId: imageGroupId,
          }}
          mode={mode}
          type={StudioEditorFieldType.RENDERING}
          setActionEvent={setActionEvent}
        />
      )}
      <ImageContainer>
        <ImageCountLabel>{images?.length}</ImageCountLabel>
        <ImagePreviewContainer>
          <RenderingPreview image={studioCarouselImage} />
        </ImagePreviewContainer>
      </ImageContainer>
    </ImageEditorContainer>
  );
};

export default CategoryImagesEditor;
