import React, {Dispatch, SetStateAction} from "react";
import {getProductGroupTiles} from "../helper";
import {SearchAllStateOptions} from "../index";
import {TileContainer} from "../styles";
import {useFactoryLines} from "../../../../../hooks/useProductCatalog/useFactoryLines/useFactoryLines";

export interface SearchAllRootProps {
  setMode: Dispatch<SetStateAction<SearchAllStateOptions>>;
  setProductGroupId: Dispatch<SetStateAction<string | undefined>>;
}

export const SearchAllRoot = (props: SearchAllRootProps) => {
  const {setProductGroupId, setMode} = props;
  const {factoryLines} = useFactoryLines();

  const selectProductGroup = (id: string) => {
    setMode(SearchAllStateOptions.PRODUCT_GROUP);
    setProductGroupId(id);
  };

  return (
    <TileContainer>
      {getProductGroupTiles(factoryLines, selectProductGroup)}
    </TileContainer>
  );
};
