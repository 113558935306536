import React from "react";
import SSCRowTemplate from "../../_shared/SSCRowTemplate";
import SSCPricing from "./SSCPricingComponent";

interface SSCPricingRowProps {
  primaryPricing: string;
  otherPricing?: string;
}

const SSCPricingRow = (props: SSCPricingRowProps) => {
  const {primaryPricing, otherPricing} = props;

  return (
    <SSCRowTemplate label={"Base Price From"}>
      <SSCPricing pricing={primaryPricing} />
      {otherPricing && <SSCPricing pricing={otherPricing} />}
    </SSCRowTemplate>
  );
};

export default SSCPricingRow;
