import React from "react";
import {ListingStatus, ListingStatusDetails} from "@natomas-org/core";
import {NatTag} from "../../../../../_shared/generics/tag";
import {
  BLACK,
  STATUS_HIDDEN,
  STATUS_HIDDEN_ALT,
  STATUS_LISTED,
  STATUS_LISTED_ALT,
  STATUS_UNLISTED,
  STATUS_UNLISTED_ALT,
  WHITE,
} from "../../../../../_shared/colors";
import ReactTooltip from "react-tooltip";

export const ListingStatusTag = (props: {status?: ListingStatusDetails}) => {
  const {status} = props;
  // Get color
  let color = BLACK;
  let backgroundColor = WHITE;
  let tooltip = "";

  // Determine listing status
  if (ListingStatus.isListed(status)) {
    color = STATUS_LISTED;
    backgroundColor = STATUS_LISTED_ALT;
    tooltip = "Discoverable by customers";
  } else if (ListingStatus.isUnlisted(status)) {
    color = STATUS_UNLISTED;
    backgroundColor = STATUS_UNLISTED_ALT;
    tooltip = "Linkable to customers";
  } else if (ListingStatus.isHidden(status)) {
    color = STATUS_HIDDEN;
    backgroundColor = STATUS_HIDDEN_ALT;
    tooltip = "Restricted to customers";
  }

  if (!status) return <></>;
  else {
    return (
      <>
        <ReactTooltip id={`tooltip-${status}`} effect="solid" place={"bottom"}>
          {tooltip}
        </ReactTooltip>
        <NatTag
          data-tip
          data-for={`tooltip-${status}`}
          color={color}
          backgroundColor={backgroundColor}
          size={"0.75rem"}
        >
          {status.toUpperCase()}
        </NatTag>
      </>
    );
  }
};
