import {createSlice} from "@reduxjs/toolkit";

const authSlice = createSlice({
	name: "auth",
	initialState: {
		showLogin: false,
	},
	reducers: {
		setShowLogin: (state, action) => {
			state.showLogin = action.payload;
		},
	},
});

export const authReducer = authSlice.reducer;
export const {setShowLogin} = authSlice.actions;
