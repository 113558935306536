import {useCatalogSession} from "../useCatalogSession";
import {
  useProductFitsOnLot,
  useProductSiteWork,
} from "../../../useProductParcelData";
import {useFactorySlice} from "../../useFactorySlice";
import {
  CatalogQuizAnswers,
  ICatalogTag,
  Product,
  ProductGroup,
  SurveyType,
} from "@natomas-org/core";
import {getFactoryLinePublishedVersionDetails} from "../../useFactoryLines/helper";
import {curveNumber, reduceMap} from "./helpers";
import {useCatalogBannerProducts} from "./useCatalogBannerProducts";
import {
  MAX_RECOMMENDED_PRODUCTS,
  SCORE_LOWER_BOUND,
  SCORE_UPPER_BOUND,
} from "./constants";
import {INVENTORY_UNIT_TAG_TEXT} from "../../../../constants/labels";
import {factoryLineTags} from "../../../../../admin/_models/factoryLine";

export enum MatchLabels {
  BED = "Bed Count",
  BATH = "Bath Count",
  PRICE = "Price Range",
}
export const useProductRecommendations = (): {
  [productId: string]: {
    percentScore?: number;
    matches?: (MatchLabels | string)[];
  };
} => {
  const {quiz} = useCatalogBannerProducts();
  const products = [
    ...(quiz?.mixedProducts ?? []),
    ...(quiz?.seriesProducts ?? []),
  ];
  const {survey} = useCatalogSession();
  const {productFitsOnLotData} = useProductFitsOnLot({productId: undefined});
  const {productInstantQuoteData} = useProductSiteWork({productId: undefined});
  const factorySlice = useFactorySlice();
  let toReturn: {
    [productId: string]: {
      percentScore?: number;
      matches?: (MatchLabels | string)[];
    };
  } = {};
  const catalogQuizAnswers: CatalogQuizAnswers | undefined =
    survey[SurveyType.ADU_QUIZ];
  if (!products || !catalogQuizAnswers) {
    return toReturn;
  }
  let baseValues: {[productId: string]: number} = {};
  let matchStrings: {[productId: string]: (string | MatchLabels)[]} = {};
  let questionCountToBaseScoreOnTest: number | undefined = Object.values(
    catalogQuizAnswers
  )?.filter((value) => value !== undefined)?.length;
  if (!questionCountToBaseScoreOnTest || questionCountToBaseScoreOnTest === 0) {
    return toReturn;
  }
  let questionCountToBaseScoreOn: number = questionCountToBaseScoreOnTest;
  questionCountToBaseScoreOn += 1; // Factory line question is worth 2 pts.
  products
    ?.filter((product) => {
      return !!product?.id;
    })
    ?.forEach((product) => {
      const matchesArray: (MatchLabels | string)[] = [];
      let matchingQuestionCount: number = 0;
      Object.keys(catalogQuizAnswers).forEach((key) => {
        if (catalogQuizAnswers.hasOwnProperty(key) && product.id) {
          if (!product.id || !product.productGroupId) {
            return;
          }
          const productGroupDetails = getFactoryLinePublishedVersionDetails(
            factorySlice,
            product.productGroupId
          );
          if (!productGroupDetails) {
            return;
          }
          switch (key) {
            case "factoryLineTag":
              if (
                catalogQuizAnswers.factoryLineTag ===
                ICatalogTag.QUICKEST_DELIVERY
              ) {
                if (Product.isInventory(product)) {
                  matchingQuestionCount += 2;
                  matchesArray.push(INVENTORY_UNIT_TAG_TEXT);
                }
              } else if (
                ProductGroup.containsTag(
                  productGroupDetails,
                  catalogQuizAnswers.factoryLineTag
                )
              ) {
                const label: string | undefined = factoryLineTags?.find(
                  (valueAndLabel) =>
                    valueAndLabel?.value === catalogQuizAnswers.factoryLineTag
                )?.label;
                if (label) {
                  matchesArray.push(label);
                }
                matchingQuestionCount += 2;
              }
              break;
            case "minBedroomCount":
              if (!catalogQuizAnswers.minBedroomCount) {
                const bedCountUnansweredAndFitsOnLot: boolean =
                  (productFitsOnLotData &&
                    productFitsOnLotData[product.id]?.productInfoAndPlacement
                      ?.productFits) ??
                  false;
                if (bedCountUnansweredAndFitsOnLot) {
                  matchingQuestionCount++;
                }
                break;
              }
              const bedCountMatches: boolean =
                product?.productDetails?.bedrooms >=
                catalogQuizAnswers.minBedroomCount;
              if (bedCountMatches) {
                matchesArray.push(MatchLabels.BED);
                matchingQuestionCount++;
              } else {
                // If min bedroom count is greater, take the faction of the difference
                // matchingQuestionCount +=
                //   product?.productDetails?.bedrooms /
                //   catalogQuizAnswers.minBedroomCount;
              }
              break;
            case "minBathroomCount":
              if (!catalogQuizAnswers.minBathroomCount) {
                const bathCountUnansweredAndFitsOnLot: boolean =
                  (productFitsOnLotData &&
                    productFitsOnLotData[product.id]?.productInfoAndPlacement
                      ?.productFits) ??
                  false;
                if (bathCountUnansweredAndFitsOnLot) {
                  matchingQuestionCount++;
                }
                break;
              }
              if (
                product?.productDetails?.bathrooms >=
                catalogQuizAnswers.minBathroomCount
              ) {
                matchesArray.push(MatchLabels.BATH);
                matchingQuestionCount++;
              } else {
                // If min bedroom count is greater, take the faction of the difference
                // matchingQuestionCount +=
                //   product?.productDetails?.bathrooms /
                //   catalogQuizAnswers.minBathroomCount;
              }
              break;
            case "priceRange":
              if (!catalogQuizAnswers.priceRange) {
                break;
              }
              let priceDollars: number | undefined;
              if (productInstantQuoteData && product?.price?.price) {
                const siteWorkPriceMicros =
                  productInstantQuoteData[product.id]
                    ?.totalSiteWorkCostInMicros;
                const productPriceMicros = product.price.price;
                if (siteWorkPriceMicros && productPriceMicros) {
                  priceDollars =
                    (siteWorkPriceMicros + productPriceMicros) / 100000;
                }
              }
              if (!priceDollars) {
                break;
              }
              if (
                priceDollars >= catalogQuizAnswers.priceRange[0] &&
                priceDollars <= catalogQuizAnswers.priceRange[1]
              ) {
                matchesArray.push(MatchLabels.PRICE);
                matchingQuestionCount++;
              } else {
                if (priceDollars > catalogQuizAnswers.priceRange[1]) {
                  matchingQuestionCount +=
                    catalogQuizAnswers.priceRange[1] / priceDollars;
                } else if (priceDollars < catalogQuizAnswers.priceRange[0]) {
                  matchingQuestionCount +=
                    priceDollars / catalogQuizAnswers.priceRange[0];
                }
              }
          }
          baseValues[product.id] =
            (matchingQuestionCount / questionCountToBaseScoreOn) * 100;
          matchStrings[product.id] = matchesArray;
        }
      });
    });
  const reducedArray = reduceMap(baseValues, MAX_RECOMMENDED_PRODUCTS);
  Object.entries(reducedArray).forEach(([productId, score]) => {
    toReturn[productId] = {
      percentScore: curveNumber(
        score,
        SCORE_LOWER_BOUND,
        SCORE_UPPER_BOUND,
        Object.values(reducedArray)
      ),
      matches: matchStrings[productId] ?? [],
    };
  });
  return toReturn;
};
