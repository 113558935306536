import {
  fullPriceTextFromMicros,
  IAddressDetails,
  IProduct,
  Product,
  ProjectPriceMode,
} from "@natomas-org/core";
import React, {useMemo} from "react";
import {useCatalogProductSelectionButtons} from "../../../../_shared/hooks/useCatalogProductSelectionButtons";
import {
  NatHorizontalAlign,
  NatJustifyContent,
  NatVerticalAlign,
} from "../../../../_shared/generics/_shared";
import {NatFlex} from "../../../../_shared/generics/flex/NatFlex";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {useSelector} from "react-redux";
import {IStore} from "../../../../_shared/slices/types/Store";
import {PriceModelTag} from "../../../views/ProductView/styles";
import {JUST_UNIT_PRICE_DESCRIPTOR} from "../TotalEstimateToggle/constants";
import {GRANITE} from "../../../../_shared/colors";
import VCL from "@natomas-org/villa-component-library";

export const usePriceCard = (props: {
  product?: IProduct;
  address?: IAddressDetails;
  hideLinks?: boolean;
  headerButtonAction?: () => void;
}): {
  headerElement: JSX.Element;
  bodyElement: JSX.Element;
  modals: React.ReactNode;
} => {
  const {product} = props;
  const {designButton, selectButton, modals} =
    useCatalogProductSelectionButtons();
  const priceMode = useSelector(
    (store: IStore) => store.catalogSession?.priceMode
  );

  const priceCostMicros = useMemo(() => {
    if (priceMode === ProjectPriceMode.CostPlus) {
      return Product.getUnitCost(product);
    } else {
      return Product.getUnitPrice(product);
    }
  }, [priceMode, product]);

  return {
    headerElement: (
      <NatFlex
        ROW
        NOWRAP
        VERTICAL_ALIGN={NatVerticalAlign.CENTER}
        HORIZONTAL_ALIGN={NatHorizontalAlign.LEFT}
        FULL_WIDTH
        style={{
          gap: priceMode === ProjectPriceMode.CostPlus ? 0 : "0.5rem",
          paddingBottom: "0.5rem",
          minHeight: "4rem",
        }}
      >
        {priceCostMicros && (
          <NatLabel
            label={fullPriceTextFromMicros(priceCostMicros)}
            type={NatLabelType.H2}
            ignoreMargin
          />
        )}
        <div />
        <PriceModelTag
          data-cy="price-card-component-label"
          style={{color: GRANITE}}
        >
          {JUST_UNIT_PRICE_DESCRIPTOR}
        </PriceModelTag>
      </NatFlex>
    ),
    bodyElement: (
      <NatFlex
        COLUMN
        FULL_WIDTH
        NOWRAP
        NOGAP={priceMode === ProjectPriceMode.CostPlus}
        JUSTIFY_CONTENT={NatJustifyContent.EVENLY}
      >
        <NatFlex COLUMN>
          {designButton}
          {selectButton}
        </NatFlex>
        <VCL.Components.Atoms.Disclaimer
          html={
            [
              "This pricing is an estimate only for the cost of the home.\n" +
                "Additional fees and costs for site work and other services will vary based on further inspection.",
            ] as unknown as TrustedHTML[]
          }
        />
      </NatFlex>
    ),
    modals: modals,
  };
};
