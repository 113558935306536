import {
  IImageDetails,
  ImageCategory,
  ImageStatus,
  IProduct,
  IProductImage,
} from "@natomas-org/core";
import {useMemo} from "react";
import {fetchImage} from "../../../../database/firebase/images/fetching";
import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useImageCarousel} from "./useImageCarousel";

export const useProductImages = (product?: IProduct) => {
  const allImages = useSelector((state: IStore) => state.global.images);
  const productImages = useMemo(() => {
    return product?.images?.filter((imageDetails: IProductImage) => {
      // Only get listed images
      if (imageDetails.status !== ImageStatus.UNLISTED) {
        // Make sure image is fetched
        fetchImage(imageDetails.imageId, false);
        return imageDetails;
      }
    });
  }, [product]);

  const visibleImages = useMemo(() => {
    let carouselProductImages =
      productImages
        ?.filter(
          (productImage: IProductImage) => allImages[productImage.imageId]
        )
        .map((productImage: IProductImage) => {
          const imageDetails = allImages[productImage.imageId];
          return {
            imageId: productImage.imageId,
            url: imageDetails.original.url,
            title: imageDetails.title ?? "",
          };
        }) ?? [];

    // Add default image to carousel
    if (product?.imageId && allImages[product?.imageId] && product?.title) {
      return [
        {
          url: allImages[product?.imageId].original.url,
          title: product.title,
          imageId: product?.imageId,
        },
        ...carouselProductImages,
      ];
    } else {
      return carouselProductImages;
    }
  }, [allImages, product?.imageId, product?.title, productImages]);

  const images = useMemo((): IImageDetails[] => {
    if (!product) {
      return [];
    }
    let a: IImageDetails[] = [];
    if (product?.imageId) {
      const productImage: IImageDetails = {
        categories: [ImageCategory.HERO],
        status: ImageStatus.LISTED,
        description: product?.title,
        imageId: product.imageId,
      };
      a.push(productImage);
    }
    if (product?.images) {
      const additionalImages: IImageDetails[] = product?.images
        ? (product?.images?.filter((image: IProductImage) => {
            return image?.status === "listed" && image?.imageId;
          }) as IImageDetails[])
        : [];
      a.push(...additionalImages);
    }

    return a;
  }, [product]);

  const imageCarousel = useImageCarousel(visibleImages);

  return {
    images,
    visibleImages,
    ...imageCarousel,
  };
};
