import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {B2BTimelineSlide} from "../B2BTimelineSlide/B2BTimelineSlide";
import {DTCTimelineSlide} from "../DTCTimelineSlide/DTCTimelineSlide";
import React from "react";
import {ProjectTimelineLayout} from "../../../../ProposalView/ProjectTimelineLayout";

export const SmartProposalTimeline = (props: {fullSlide?: boolean}) => {
  const {isDevelopmentByVilla} = useActiveProject();
  if (props.fullSlide) {
    if (isDevelopmentByVilla) {
      return (
        <B2BTimelineSlide
          displayOptions={{
            showSectionTitles: true,
            showLabelsOnBars: false,
            showTooltip: true,
            showLegend: true,
            displayQuarters: true,
          }}
        />
      );
    }
    return <DTCTimelineSlide />;
  }
  return (
    <ProjectTimelineLayout pdfRenderFormat={true} expandMilestones={true} />
  );
};
