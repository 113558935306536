import {SitePlanButtonContainer, SitePlanComponentContainer} from "../styles";
import React, {useContext, useState} from "react";
import {useSelector} from "react-redux";
import {useImage} from "../../../../_shared/hooks/useImage";
import {Address, isBlankString, Project} from "@natomas-org/core";
import {downloadFileToDesktop} from "../logic";
// @ts-ignore
import Lightbox from "react-awesome-lightbox";
import {SitePlanContent} from "./SitePlanContent";
import {LightboxContainer} from "../../../../catalog/views/ProductView/components/styles";
import {NatButton} from "../../../../_shared/generics/button";
import {usePage} from "../../../../_shared/hooks/usePage";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {FiDownload, FiZoomIn} from "react-icons/fi";
import {
  ImageWrapper,
  SitePlanAddress,
  SitePlanTitle,
  SitePlanTitleComponent,
} from "./styles";
import {IStore} from "../../../../_shared/slices/types/Store";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {PROPOSAL_GENERATE_PDF} from "../../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../SSDDashboardView/styles/globals";
import {FULL_SCREEN_HEIGHT_REM} from "../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";

export const SitePlan = (props: {
  sitePlanImageId: string | null;
  index: number;
  admin?: boolean;
  numberOfSitePlans: number;
}) => {
  const {sitePlanImageId, index, admin, numberOfSitePlans} = props;
  const adminMode = useSelector((state: IStore) => state.portal.adminMode);
  const user = useSelector((state: IStore) => state.global.user);
  const [fullScreenImage, setFullScreenImage] = useState(false);
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const sitePlanImage = useImage(sitePlanImageId ?? "");

  const {info, isCartEnabled} = useActiveProject();
  let addressText = "";
  let streetAndCity = "";
  if (info) {
    const address = Project.getAddress(info);
    addressText = Address.getFullAddress(address);
    streetAndCity = Address.getStreetAndCity(address);
  }

  const filename =
    (isBlankString(addressText) ? "" : addressText.split(" ").join("_") + "_") +
    "SitePlan.png";
  const url = sitePlanImage == null ? "" : sitePlanImage.original.url;
  const {isNatMobile} = usePage();

  const getSitePlanTitle = (index: number) => {
    if (isCartEnabled && numberOfSitePlans > 1) {
      return `Project Site Plan • Location ${index}`;
    } else if (sitePlanImage?.title) {
      return sitePlanImage?.title;
    } else {
      return `Site Plan ${index}`;
    }
  };

  return (
    <SitePlanComponentContainer
      isGeneratingPDF={isGeneratingPDF}
      breakBefore={false}
      style={{
        minHeight: isGeneratingPDF
          ? `${FULL_SCREEN_HEIGHT_REM * 0.95}rem`
          : undefined,
        maxHeight: isGeneratingPDF
          ? `${FULL_SCREEN_HEIGHT_REM * 0.9}rem`
          : undefined,
        height: isGeneratingPDF
          ? `${FULL_SCREEN_HEIGHT_REM * 0.95}rem`
          : undefined,
      }}
    >
      <SitePlanTitleComponent>
        <SitePlanTitle>
          <SitePlanTitleComponent
            alignCenter={isGeneratingPDF}
            hidden={numberOfSitePlans < 2 && !isGeneratingPDF}
          >
            {getSitePlanTitle(index)}
          </SitePlanTitleComponent>
          <SitePlanAddress
            style={{
              fontSize: isGeneratingPDF ? "16px" : "18px",
              fontFamily: isGeneratingPDF
                ? FONT_FAMILY_BOLD
                : FONT_FAMILY_REGULAR,
            }}
          >
            {streetAndCity}
          </SitePlanAddress>
        </SitePlanTitle>
        <SitePlanButtonContainer
          hideElement={sitePlanImage == null || isGeneratingPDF}
        >
          <NatButton
            type={"button"}
            clickEvent={() => {
              const url = sitePlanImage.original.url;
              downloadFileToDesktop(url, filename);
            }}
            label={isNatMobile ? "Save" : "Download"}
            option={StyleOption.SECONDARY_ALT}
            icon={{
              iconPosition: IconPosition.LEFT,
              icon: <FiDownload />,
            }}
            size={NatSize.SMALL}
          />
          <NatButton
            type={"button"}
            clickEvent={() => {
              setFullScreenImage(true);
            }}
            label={isNatMobile ? "Zoom" : "Full Screen"}
            option={StyleOption.SECONDARY_ALT}
            icon={{
              iconPosition: IconPosition.LEFT,
              icon: <FiZoomIn />,
            }}
            size={NatSize.SMALL}
          />
        </SitePlanButtonContainer>
      </SitePlanTitleComponent>
      <ImageWrapper
        onClick={() => {
          if (sitePlanImage != null && !adminMode && !admin) {
            setFullScreenImage(true);
          }
        }}
      >
        <SitePlanContent
          sitePlanImageId={sitePlanImageId}
          user={user}
          editing={adminMode || admin}
        />
      </ImageWrapper>
      <LightboxContainer hidden={!fullScreenImage}>
        <Lightbox
          image={url}
          onClose={() => {
            setFullScreenImage(false);
          }}
        />
      </LightboxContainer>
    </SitePlanComponentContainer>
  );
};
