import {Utilities} from "../../index";
import {editProduct} from "../../../../components/_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {store} from "../../../../store";
import {getFactoryLineVersionProductRef} from "../refs";
import {IProduct} from "@natomas-org/core";

export const saveProductInformation = async (
  factoryLineId: string,
  versionId: string,
  productId: string,
  payload: any
) => {
  // Get doc ref
  const ref = getFactoryLineVersionProductRef(
    factoryLineId,
    versionId,
    productId
  );
  if (!ref) {
    return null;
  }
  // @ts-ignore
  const product =
    store?.getState()?.factory?.[factoryLineId]?.versions?.[versionId]
      ?.products?.[productId];

  if (!product) {
    throw new Error("Product not found");
  }

  const initialPrice = product?.price;
  const {price: newPrice, ...rest} = payload;
  // Create new info object
  const info: IProduct = {
    ...rest,
    price: {
      ...initialPrice,
      ...newPrice,
      currency: "USD",
    },
    id: ref?.id,
    productGroupId: factoryLineId,
    inventory_info: undefined,
  };
  // Set object to state
  store.dispatch(editProduct(info));

  if (payload.inventory_info) {
    await Utilities.setDocWithMerge(ref, {
      inventory_info: payload.inventory_info,
    });
  }

  // Set doc to merge
  return Utilities.updateOrCreateDocumentInDB(ref, info);
};

export const archiveProduct = (
  factoryLineId: string,
  versionId: string,
  productId: string
) => {
  return saveProductInformation(factoryLineId, versionId, productId, {
    visible: false,
  });
};

export const undoArchiveProduct = (
  factoryLineId: string,
  versionId: string,
  productId: string
) => {
  return saveProductInformation(factoryLineId, versionId, productId, {
    visible: true,
  });
};
