import {InstantQuoteApi} from "@natomas-org/villa-nexus-client";
import {AxiosInstance} from "axios";
import {getAxiosInstance} from "../setup";

export class InstantQuoteService {
  private axiosInstance;
  private client;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new InstantQuoteApi(undefined, undefined, this.axiosInstance);
  }

  async get(productId: string, latitude: number, longitude: number) {
    return this.client
      .getClosestInstantQuoteLocationItemsByProductId(
        productId,
        latitude,
        longitude
      )
      .then((res) => {
        return res?.data;
      });
  }
}

export const getInstantQuoteService = async () => {
  // InstantQuoteService does not require authentication to use
  const axiosInstance = await getAxiosInstance();
  return new InstantQuoteService(axiosInstance);
};
