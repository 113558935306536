import {
  IEligibleFeatures,
  NatomasMapFeature,
  NatomasMapFeatureQueryRequest,
} from "@natomas-org/core";
import {executeRequest} from "../api";

export const queryMapFeatures = (
  mapFeatureRequest: NatomasMapFeatureQueryRequest
): Promise<IEligibleFeatures> => {
  return executeRequest("/map/v1/query-mapping-features", mapFeatureRequest);
};

export const queryFullMapFeatures = (
  mapFeatureRequest: NatomasMapFeatureQueryRequest
): Promise<NatomasMapFeature[]> => {
  return executeRequest("/map/v1/query-mapping-features", {
    full: true,
    ...mapFeatureRequest,
  });
};
