import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";

export const AppTilesContainer = styled.div<{isMobile: boolean}>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;

export const SplashScreenLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  margin-top: 2.5rem;
  padding-bottom: 3rem;
`;

export const SplashScreenHelpfulLinksWrapper = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
`;
