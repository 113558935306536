import React, {useCallback, useMemo} from "react";

import {useDispatch, useSelector} from "react-redux";
import {editModifier} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {EditProductGroupModifier} from "./EditProductGroupModifier";
import {ColumnContainer, MainColumn} from "../../../_shared/styles/AdminStyles";
import {
  RowStatusType,
  TabColumn,
  TabColumnRowSection,
} from "../../components/TabColumn";
import {IStore} from "../../../../_shared/slices/types/Store";
import {getModifierGroupsWithModifier} from "../../components/TabColumn/helper";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {getInternalTitle, getTitle} from "../../logic/helpers";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {getConvertedModifierPricingStructure} from "@natomas-org/service";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

export const ModifierTab = (props: {widthRems: number}) => {
  const {modifiers, modifierGroups} = useSelectedFactoryLine();
  const {editingEnabled} = useAdminProductCatalog();
  const currentModifier = useSelector(
    (state: IStore) => state.adminStudioManager.editingModifier
  );
  const dispatch = useDispatch();
  const title = "Modifiers";
  const newModifier = () => {
    dispatch(
      editModifier({
        title: "New Modifier",
        price_set: getConvertedModifierPricingStructure(null),
      })
    );
  };
  const changeModifier = useCallback(
    (modifier: any) => {
      dispatch(editModifier(modifier));
    },
    [dispatch]
  );

  const modifierArray = useMemo(
    () => Object.values(modifiers ?? {})?.filter((m) => !!m),
    [modifiers]
  );

  const getStatuses = useCallback(
    (modifier: any): RowStatusType => {
      const modifierGroupsWithModifier = getModifierGroupsWithModifier(
        modifier,
        Object.values(modifierGroups ?? {})
      );
      return {
        hasWarning: modifierGroupsWithModifier?.length === 0,
      };
    },
    [modifierGroups]
  );

  const getModifierRow = useCallback(
    (modifier: any) => {
      return {
        title: getTitle(modifier),
        internal_title: getInternalTitle(modifier),
        key: modifier.id,
        data: modifier,
        action: changeModifier,
        active: currentModifier?.id === modifier.id,
        statuses: getStatuses(modifier),
      };
    },
    [changeModifier, currentModifier?.id, getStatuses]
  );

  const RecentlyArchivedModifierRowSection: TabColumnRowSection | undefined =
    useMemo(() => {
      const modifiers = modifierArray?.filter((modifier) =>
        isArchived(modifier)
      );
      if (modifiers?.length === 0) {
        return undefined;
      }
      return {
        title: editingEnabled ? "Recently Archived" : "Archived",
        rows: modifiers.map((modifier: any) => {
          return getModifierRow(modifier);
        }),
      };
    }, [modifierArray, editingEnabled, getModifierRow]);

  const ModifierGroupRowSection: TabColumnRowSection = useMemo(() => {
    const modifiers = modifierArray?.filter(
      (modifier) => !isArchived(modifier)
    );
    return {
      title: RecentlyArchivedModifierRowSection ? "Active" : undefined,
      rows: modifiers.map((modifier: any) => {
        return getModifierRow(modifier);
      }),
    };
  }, [modifierArray, getModifierRow, RecentlyArchivedModifierRowSection]);

  const sections: TabColumnRowSection[] = useMemo(() => {
    const s = [ModifierGroupRowSection];
    if (RecentlyArchivedModifierRowSection) {
      return [...s, RecentlyArchivedModifierRowSection];
    }
    return s;
  }, [ModifierGroupRowSection, RecentlyArchivedModifierRowSection]);

  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={props.widthRems + "rem"}
        headerAddAction={editingEnabled ? newModifier : undefined}
        sections={sections}
      />
      <MainColumn>
        <EditProductGroupModifier />
      </MainColumn>
    </ColumnContainer>
  );
};
