import React from "react";
import {useCurrentCustomer} from "../../../../../../../../_shared/hooks/useCurrentCustomer";
import {updateProjectLinksInSalesforce} from "../../../../../../../../../database/firebase/api/admin";
import {InfoDescription} from "../../../../../../_shared/InfoItem/styles";
import {
  NatButton,
  NatLink,
} from "../../../../../../../../_shared/generics/button";
import {
  NatSize,
  StyleOption,
} from "../../../../../../../../_shared/generics/_shared";
import useActiveProject from "../../../../../../../../_shared/hooks/useProject/useActiveProject";

const getOpportunityLink = (opportunityId: string) => {
  return `https://villa.lightning.force.com/lightning/r/Opportunity/${opportunityId}/view`;
};

export const UpdateLinksInSalesforceAction = () => {
  const {user_id} = useCurrentCustomer();
  const {id, mergedCustomerProjectInfos} = useActiveProject();

  if (!mergedCustomerProjectInfos?.opportunity_id) {
    return null;
  }
  return (
    <>
      <NatButton
        label={"Update Opportunity Values"}
        type={"button"}
        spinnerEnabled={true}
        style={{minWidth: "20rem", width: "100%"}}
        clickEvent={() => {
          return updateProjectLinksInSalesforce(user_id, id);
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {
          <InfoDescription>
            {"Update project values for this"}
            <NatLink
              style={{marginLeft: "0.25rem"}}
              label={"Opportunity"}
              link={getOpportunityLink(
                mergedCustomerProjectInfos.opportunity_id
              )}
              option={StyleOption.PRIMARY_LINK}
              size={NatSize.XSMALL}
              openInNewTab={true}
            />
          </InfoDescription>
        }
      </div>
    </>
  );
};
