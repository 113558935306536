import React, {useMemo, useState} from "react";
import {DesignStudioModifier, IProduct} from "@natomas-org/core";
import {NatMicroModal} from "../../_shared/generics/micro-modal";
import {ProductHelperRow} from "./ProductHelperRow";
import {validateProducts} from "../InfoSetTable/components/_shared/productVerification";
import {
  IModifierValidation,
  IProductsValidation,
} from "../InfoSetTable/components/_shared/interfaces";
import {NatButton} from "../../_shared/generics/button";
import {NatSize, StyleOption} from "../../_shared/generics/_shared";
import {
  ItemList,
  ItemListHeader,
  MissingModifierRowContainer,
  ProductHelperKey,
  ProductHelperTable,
  ProductHelperTableHeader,
} from "./styles";
import {InfoSetItemTableRow} from "../InfoSetTable/table";
import {ID_KEY, LABEL_KEY} from "../shared/constants";
import {sortInfoSetTableRowsByLabel} from "./helper";

export const ProductHelper = (props: {
  infoSetData: any;
  products: IProduct[];
  categories: any;
  modifierGroups: any;
  modifiers: {[key: string]: DesignStudioModifier};
}) => {
  const {infoSetData, products, categories, modifierGroups, modifiers} = props;
  const [clickEvent, setClickEvent] = useState<any>(undefined);
  const [childModalInfo, setChildModalInfo] = useState<
    | {
        event: any;
        product: IProduct;
        invalidModifiers: IModifierValidation[];
        missingKeys: InfoSetItemTableRow[];
      }
    | undefined
  >(undefined);

  const validate: IProductsValidation = useMemo(() => {
    return validateProducts(
      infoSetData,
      products,
      categories,
      modifierGroups,
      modifiers
    );
  }, [infoSetData, products, categories, modifierGroups, modifiers]);

  return (
    <>
      <NatButton
        clickEvent={setClickEvent}
        label={"Product Helper"}
        option={StyleOption.PRIMARY_ALT}
        size={NatSize.SMALL}
      />
      <NatMicroModal
        title={"Product Helper"}
        event={clickEvent}
        id={"product-design-checker-modal"}
        draggable={true}
      >
        <ProductHelperTable>
          <ProductHelperTableHeader>
            <tr>
              <th>Product</th>
              <th>InfoSet</th>
              <th>Design Studio</th>
            </tr>
          </ProductHelperTableHeader>
          <tbody>
            {products?.map((p: IProduct, index: number) => {
              return (
                <ProductHelperRow
                  key={`product-design-checker-row-${index}`}
                  infoSetData={infoSetData}
                  validationDetails={validate[p?.id ?? "NULL"]}
                  setChildModalInfo={setChildModalInfo}
                />
              );
            })}
          </tbody>
        </ProductHelperTable>
        <ProductHelperKey>
          ✔ - All possible selections are represented in the InfoSet
        </ProductHelperKey>
      </NatMicroModal>
      <NatMicroModal
        title={`${childModalInfo?.product?.title}`}
        id={"product-missing-selections-modal"}
        draggable={true}
        event={childModalInfo?.event}
      >
        <ProductHelperKey>
          {(childModalInfo?.invalidModifiers?.length ?? 0) > 0
            ? "* Below selections are missing in the InfoSet"
            : "✔ - All selections are represented in the InfoSet"}
        </ProductHelperKey>
        <ItemList>
          <ItemListHeader>
            Missing Studio Selections (
            {childModalInfo?.invalidModifiers?.length})
          </ItemListHeader>
          {childModalInfo?.invalidModifiers?.map(
            (modifierValidation: IModifierValidation) => {
              return (
                <MissingModifierRowContainer
                  key={`${childModalInfo?.product?.id}-${modifierValidation.modifier?.id}-invalids`}
                >
                  <td>{modifierValidation.modifier?.title}</td>
                </MissingModifierRowContainer>
              );
            }
          )}
        </ItemList>
        {(childModalInfo?.missingKeys?.length ?? 0) > 0 ? (
          <>
            <ProductHelperKey>
              * Below items are uncertain in the InfoSet
            </ProductHelperKey>
            <ItemList>
              <ItemListHeader>
                Uncertain InfoSet Items ({childModalInfo?.missingKeys?.length})
              </ItemListHeader>
              {sortInfoSetTableRowsByLabel(
                childModalInfo?.missingKeys ?? []
              )?.map((row: InfoSetItemTableRow) => {
                return (
                  <MissingModifierRowContainer
                    key={`${childModalInfo?.product?.id}-${row[ID_KEY]}-missing`}
                  >
                    <td>{row[LABEL_KEY]}</td>
                  </MissingModifierRowContainer>
                );
              })}
            </ItemList>
          </>
        ) : (
          <ProductHelperKey>✔ - No uncertain InfoSet items</ProductHelperKey>
        )}
      </NatMicroModal>
    </>
  );
};
