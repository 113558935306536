import styled from "styled-components";
import {DROPDOWN_Z_INDEX} from "../../../styles";
import {
  BLACK,
  DEFAULT_SHADOW,
  DROPDOWN_ACCENT_COLOR,
  WHITE,
} from "../../../colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const DropdownButtonContainer = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme?.Filters?.filterButtonStyling};
`;

export const NatomasDropdown = styled.div<{
  marginOverride?: string;
}>`
  position: relative;
  display: flex;
  cursor: pointer;
  //text-align: center;
  color: ${BLACK};
  margin: ${(props) => props.marginOverride ?? "0 0.5rem 0.25rem 0"};
`;

export const BuildDropdownOptionsContainer = styled.div<{
  show: boolean;
}>`
  display: ${(props) => (props.show ? "flex" : "none")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  flex-direction: column;
  align-items: flex-start;
  //text-align: left;
  width: fit-content;
  background-color: ${WHITE};
  box-shadow: ${DEFAULT_SHADOW};
  border-radius: 0.5rem;
  z-index: ${DROPDOWN_Z_INDEX};
  transition: all 2000ms ease-in-out;
  position: fixed;
  left: inherit;
  top: auto;
`;

export const DropdownOption = styled.button<{
  bold?: boolean;
  isMobile: boolean;
  useComponent: boolean;
}>`
  //text-align: ${(props) => (props.isMobile ? "center" : "left")};
  background-color: white;
  color: inherit;
  border: none;
  border-bottom: 1px solid ${DROPDOWN_ACCENT_COLOR};
  font-family: ${(props) =>
    props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR} !important;
  cursor: pointer;
  outline: inherit;
  padding: ${(props) => (props.useComponent ? 0 : "0.5rem")};
  width: 100%;
  white-space: nowrap;

  &:disabled {
    border: none !important;
    border-bottom: 1px solid ${DROPDOWN_ACCENT_COLOR} !important;
  }
  &:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
    border: 0;
  }
  &:only-child {
    border-radius: 0.5rem;
  }
  &:hover {
    filter: brightness(96%);
  }
`;

export const JSXDropdownOption = styled.button<{active?: boolean}>`
  //text-align: left;
  background-color: white;
  color: inherit;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 44px;
  // border-bottom: 1px solid ${DROPDOWN_ACCENT_COLOR};
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  white-space: nowrap;
  font-size: 16px;
  font-family: ${(props) =>
    props.active ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  &:disabled {
    opacity: 1;
    color: rgba(0, 0, 0, 0.4);
    border: none !important;
    // border-bottom: 1px solid ${DROPDOWN_ACCENT_COLOR} !important;
    &:hover {
      background-color: white;
    }
  }
  &:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
    border: 0;
  }
  &:only-child {
    border-radius: 0.5rem;
  }
  &:hover {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.05);
    //filter: brightness(96%);
  }
  //svg {
  //  margin-right: 6px;
  //}
`;

export const JSXDropdownContainer = styled.div<{widthOffset?: number}>`
  position: relative;
  display: flex;
  cursor: pointer;
  //text-align: center;
  color: ${BLACK};
  justify-content: flex-end;
`;

export const JSXDropdownOptionsContainer = styled.div<{
  show: boolean;
  widthOffset?: number;
  isMobile: boolean;
}>`
  display: ${(props) => (props.show ? "flex" : "none")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  flex-direction: column;
  align-items: flex-start;
  //text-align: left;
  position: ${(props) => (props.isMobile ? "fixed" : "absolute")};
  left: ${(props) => props.isMobile && "0.5rem"};
  right: ${(props) => props.isMobile && "0.5rem"};
  top: ${(props) => props.show && !props.isMobile && "125%"};
  bottom: ${(props) => props.show && props.isMobile && "0.5rem"};
  background-color: ${WHITE};
  //border: 2px solid ${DROPDOWN_ACCENT_COLOR};
  border-radius: 0.5rem;
  z-index: ${DROPDOWN_Z_INDEX};
  transition: all 2000ms ease-in-out;
  ${(props) =>
    props?.widthOffset && !props.isMobile
      ? "left: -" + (props.widthOffset - 36) + "px"
      : ""};
  ${(props) =>
    props?.widthOffset && !props.isMobile
      ? "width: " + props.widthOffset + "px"
      : ""};
  box-shadow: 0 0 8px rgb(0 0 0 / 40%);
`;
