import styled from "styled-components";
import {StandardElement} from "../../../../_shared/generics/page/components/PageElement/styles";

export const RecommendedCardContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${StandardElement} {
    margin-bottom: 2rem;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;
