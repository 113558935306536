import {executeRequest} from "../APIExecutor";
import {Utilities} from "../../index";
import {getDocData} from "../../utilities";
import {ISaveInventoryOptions} from "@natomas-org/core";

const duplicateInventoryUnit = "/catalog/v1/duplicateInventoryUnit";
const updateInventoryUnitUpgrades = "/catalog/v1/saveInventoryUnitUpgrades";
const updateInventoryUnitPath = "/catalog/v1/saveInventoryUnit";

export const duplicateInventoryUnitFromExisting = async (
  productGroupId: string,
  productId: string
): Promise<string | null> => {
  return await executeRequest(duplicateInventoryUnit, {
    productGroupId: productGroupId,
    productId: productId,
  })
    .then((r: string) => {
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const updateInventoryUnitUpgradeSelections = async (
  orderSummary: any
) => {
  const payload: ISaveInventoryOptions = {
    configurationSnapshot: orderSummary.configurationSnapshot,
    images: orderSummary.images,
    productGroupId: orderSummary.product.productGroupId,
    productId: orderSummary.product.id,
    selectedModifiers: orderSummary.selectedModifiers,
    upgradePrice: orderSummary.upgradePrice,
    upgradesInMicros: orderSummary.upgradesInMicros,
    builderVersion: orderSummary?.builderVersion,
  } as ISaveInventoryOptions;
  return await executeRequest(updateInventoryUnitUpgrades, payload);
};

export const updateInventoryUnit = async (product: any) => {
  const payload = {
    productGroupId: product.productGroupId,
    productId: product.id,
    payload: product,
  };
  return await executeRequest(updateInventoryUnitPath, payload);
};

export const loadInventoryUnitConfiguration = async (id: string) => {
  const docRef = Utilities.collection("natomas/v1/inventory").doc(id);
  const configuration = await getDocData(docRef);
  if (configuration != null) {
    return configuration;
  } else return undefined;
};

export const hasInventoryUnitConfiguration = async (id: string) => {
  const docRef = Utilities.collection("natomas/v1/inventory").doc(id);
  const configuration = await getDocData(docRef);
  return !!configuration;
};
