import {useQuery} from "@tanstack/react-query";
import {getInstantQuoteService} from "./index";

const fetchInstantQuote = async (
  productId?: string,
  latitude?: number,
  longitude?: number
): Promise<any> => {
  if (!productId) throw new Error("Product ID is required");
  if (!latitude) throw new Error("Latitude is required");
  if (!longitude) throw new Error("Longitude is required");
  try {
    return getInstantQuoteService().then((service) => {
      return service.get(productId, latitude, longitude);
    });
  } catch (error) {
    console.error("fetchInstantQuote", error);
    return null;
  }
};

function useInstantQuote(
  productId?: string,
  latitude?: number,
  longitude?: number
) {
  return useQuery<any[], Error>(
    ["instant-quote", productId, latitude, longitude],
    () => fetchInstantQuote(productId, latitude, longitude),
    {cacheTime: 0}
  );
}

export default useInstantQuote;
