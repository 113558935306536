import {FieldType} from "../../_shared/generics/table/TableTypes";
import {statusField} from "./fields";
import {SITE_WORK_AND_PRE_CON_LINE_ITEMS} from "@natomas-org/core";

export const ProductStructure = {
  title: {
    name: "Title",
    type: "input",
    alwaysEnabled: true,
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    type: "input",
    index: 0,
  },
  factoryPrintNumber: {
    name: "Factory Print Number",
    type: "input",
    subType: "string",
    index: 0,
  },
  moduleCount: {
    name: "Module Count",
    type: "number",
    index: 0,
  },
  status: statusField,
  tagline: {
    name: "Tagline",
    index: 1,
    alwaysEnabled: true,
    type: "input",
  },
  displayDescription: {
    name: "General Description",
    index: 1,
    type: "textarea",
    alwaysEnabled: true,
    subType: "full-width",
  },
  interiorDescription: {
    name: "Interior Description",
    index: 1,
    alwaysEnabled: true,
    type: "textarea",
  },
  exteriorDescription: {
    name: "Exterior Description",
    index: 1,
    alwaysEnabled: true,
    type: "textarea",
  },
  matterport: {
    name: "Matterport Link",
    index: 1,
    alwaysEnabled: true,
    type: "iframe-link",
    placeholder: `https://my.matterport.com/show/?m={MATTERPORT_ID}`,
  },
  video: {
    name: "Video Link",
    index: 1,
    alwaysEnabled: true,
    type: "iframe-link",
    placeholder: "https://player.vimeo.com/video/{VIDEO_ID}",
  },
  bathrooms: {
    path: "productDetails.bathrooms",
    name: "Bathroom Quantity",
    defaultValue: 0,
    type: "number",
    index: 6,
  },
  bedrooms: {
    path: "productDetails.bedrooms",
    name: "Bedroom Quantity",
    defaultValue: 0,
    type: "number",
    index: 5,
  },
  price: {
    path: "price.price",
    name: "Price",
    type: "number",
    subType: "price",
    alwaysEnabled: true,
    index: 3,
  },
  cost: {
    path: "price.cost",
    name: "Cost",
    type: "number",
    subType: "price",
    alwaysEnabled: true,
    index: 3,
  },
  squareFeet: {
    path: "productDetails.squareFeet",
    name: "SQFT",
    defaultValue: 0,
    type: "number",
    index: 9,
  },
  dimensions: {
    path: "productDetails.dimensions",
    name: "Dimensions",
    defaultValue: {
      frontLength: {
        feet: 10,
        inches: 0,
      },
      sideLength: {
        feet: 10,
        inches: 0,
      },
    },
    type: "dimensions",
    index: 10,
  },
  configuratorPages: {
    name: "Pages / Categories",
    defaultValue: [],
    index: 15,
  },
  // optionValues: {
  //   type: "optionKeyValues",
  //   defaultValue: {},
  //   index: 20,
  // },
  imageId: {
    name: "Default Image",
    type: "image",
    alwaysEnabled: true,
    index: 12,
  },
  images: {
    name: "Images",
    type: "product-image-manager",
    alwaysEnabled: true,
    defaultValue: [],
    index: 14,
  },
  default_site_work_costs: {
    name: "Site-work Costs",
    type: "table",
    alwaysEnabled: true,
    tableStructure: {
      rowCount: SITE_WORK_AND_PRE_CON_LINE_ITEMS?.length,
      columns: [
        {
          dataField: "id",
          primary_key: true,
          name: "Site Work Item ID",
          type: FieldType.STRING,
        },
        {
          dataField: "name",
          name: "Name",
          type: FieldType.STRING,
        },
        {
          dataField: "basePriceMicros",
          name: "Price",
          type: FieldType.PRICE,
        },
        {
          dataField: "note",
          name: "Notes / Description",
          type: FieldType.STRING,
        },
      ],
      rigidValues: SITE_WORK_AND_PRE_CON_LINE_ITEMS.map((lineItem) => [
        lineItem.lineItemId,
        lineItem.title,
      ]),
    },
    index: 11,
  },
  disableCustomization: {
    name: "Disable Customization",
    type: "yes-no",
    index: 99,
    defaultValue: false,
  },
};

// @ts-ignore
export const getProductMatrixModel = ({price, cost}) => ({
  price,
  cost,
});
