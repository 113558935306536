import React from "react";
import {FactoryInfoFormControllerWrapper} from "./styles";
import {NatButton} from "../../_shared/generics/button";
import {StyleOption} from "../../_shared/generics/_shared";

interface FactoryInfoFormControllerProps {
  canSave: boolean;
  onSave: () => void;
  onReset: (e: any) => void;
}

const FactoryInfoFormController = (props: FactoryInfoFormControllerProps) => {
  const {canSave, onReset, onSave} = props;

  return (
    <FactoryInfoFormControllerWrapper>
      <NatButton
        label={"Save"}
        option={StyleOption.PRIMARY_BLACK}
        clickEvent={onSave}
        disabled={!canSave}
      />
      <NatButton
        label={"Abandon"}
        option={StyleOption.DESTRUCTIVE}
        clickEvent={onReset}
        hidden={!canSave}
      />
    </FactoryInfoFormControllerWrapper>
  );
};

export default FactoryInfoFormController;
