import {Utilities} from "../../index";
import {PROJECT_COLLECTION_KEY} from "../constants";
import {ICartItem} from "@natomas-org/core";

export const savePayloadToProject = async (projectId: string, payload: any) => {
  let doc = await Utilities.collection(PROJECT_COLLECTION_KEY).doc(projectId);
  return Utilities.updateOrCreateDocumentInDB(doc, payload);
};

export const saveProjectCart = async (
  projectId: string,
  cartItems: ICartItem[]
) => {
  return savePayloadToProject(projectId, {cart: cartItems});
};
