import React from "react";
import {PortalHeaderButtonContainer} from "./styles";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {AvatarDropdown} from "../../../../_shared/hooks/useNavigation/AvatarDropdown/AvatarDropdown";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useDispatch} from "react-redux";
import {setRegistrationModalState} from "../../../../_shared/slices/SessionSlice/SessionSlice";
import CartButton from "../../../../_shared/generics/cart/CartButton";

const SignUpButton = (props: {clickEvent: () => void}) => {
  const {isNatMobile} = usePage();
  return (
    <NatButton
      label={"Build your Villa"}
      size={isNatMobile ? NatSize.XSMALL : NatSize.SMALL}
      option={StyleOption.PRIMARY}
      type={"button"}
      clickEvent={props.clickEvent}
      clickEventParam={null}
      key={"menu-bar-sign-up"}
      id={"menu-bar-sign-up"}
      trackingId={"menu-bar-sign-up"}
    />
  );
};

const LogInButton = (props: {clickEvent: () => void}) => {
  return (
    <NatButton
      label={"Log In"}
      size={NatSize.SMALL}
      option={StyleOption.PRIMARY_LINK}
      type={"button"}
      clickEvent={props.clickEvent}
      clickEventParam={null}
      key={"menu-bar-log-in"}
      id={"menu-bar-log-in"}
      trackingId={"menu-bar-log-in"}
    />
  );
};
export const SignUpInComponent = () => {
  const {isNatMobile} = usePage();
  const {loggedInUser} = useCurrentUser();
  const dispatch = useDispatch();
  if (loggedInUser) {
    return (
      <PortalHeaderButtonContainer isMobile={isNatMobile}>
        <CartButton />
        <AvatarDropdown />
      </PortalHeaderButtonContainer>
    );
  } else {
    if (isNatMobile) {
      return (
        <PortalHeaderButtonContainer isMobile={isNatMobile}>
          <SignUpButton
            clickEvent={() => {
              dispatch(
                setRegistrationModalState({
                  visible: true,
                  signUp: true,
                  redirectType: undefined,
                })
              );
            }}
          />
        </PortalHeaderButtonContainer>
      );
    }
    return (
      <PortalHeaderButtonContainer isMobile={isNatMobile}>
        <LogInButton
          clickEvent={() => {
            dispatch(
              setRegistrationModalState({
                visible: true,
                signUp: false,
                redirectType: undefined,
              })
            );
          }}
        />
        <SignUpButton
          clickEvent={() => {
            dispatch(
              setRegistrationModalState({
                visible: true,
                signUp: true,
                redirectType: undefined,
              })
            );
          }}
        />
      </PortalHeaderButtonContainer>
    );
  }
};
