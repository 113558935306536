import React from "react";
import {ImageProps} from "./interface";

const Image = (props: ImageProps) => {
  if (!props.alt || props.alt === "") {
    console.error("Image must have an alt prop");
    return null;
  }
  return <img {...props} alt={props.alt} />;
};

export default Image;
