import React, {useState} from "react";
import {NatMicroSwitch} from "./styles";

const getRandomId = (): string => {
  const possibleChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < 6; i++) {
    // Generate a random index between 0 and the length of possibleChars
    const randomIndex = Math.floor(Math.random() * possibleChars.length);
    // Add the character at the random index to the string
    randomString += possibleChars[randomIndex];
  }

  return randomString;
};

export const MicroSwitch = (props: {
  isChecked: boolean;
  setChecked: (toggle: boolean) => void;
  displayLarge?: boolean;
  color?: string;
}) => {
  const {isChecked, setChecked} = props;
  const [id] = useState<string>(getRandomId());

  return (
    <NatMicroSwitch
      htmlFor={id}
      largeDisplay={props.displayLarge}
      color={props.color}
    >
      <input
        value={isChecked ? 1 : 0}
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={() => {
          setChecked(!isChecked);
        }}
      />
      <span />
    </NatMicroSwitch>
  );
};
