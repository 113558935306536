import React, {useEffect} from "react";
import {ProjectDocumentUploadButton} from "./ProjectDocumentUploadButton";
import {NatButton} from "../../button";
import {StyleOption} from "../../_shared";
import {IDocumentDetails} from "@natomas-org/core";
import {useForm} from "react-hook-form";
import {updateProjectDocumentInfo} from "../../../../../database/firebase/project/documents";
import {DocumentDetailsPreview} from "./DocumentDetailsPreview";
import NatLabel, {NatLabelType, NatLabelWeight} from "../../label/NatLabel";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// @ts-ignore
const schema: yup.ObjectSchema<any, yup.AnyObject, any, ""> = yup
  .object({
    documentId: yup.string().required(),
    documentTitle: yup.string().required(),
  })
  .required();

export const ProjectDocumentUploadForm = (props: {
  projectId: string;
  onClose: (documentId: string | null) => void;
  documentInfo?: IDocumentDetails;
  closeModal?: () => void;
}) => {
  const {projectId, closeModal, documentInfo} = props;
  const {register, formState, watch, setValue, handleSubmit, trigger} = useForm(
    {
      defaultValues: getInitialValues(documentInfo),
      resolver: yupResolver(schema),
    }
  );
  const documentId = watch("documentId");
  const documentTitle = watch("documentTitle");
  const documentDetails = watch("documentDetails");

  useEffect(() => {
    if (documentInfo) {
      setValue("documentDetails", documentInfo);
    }
  }, [documentInfo]);

  const documentPreview = documentDetails ? (
    <div>
      <NatLabel
        label={"Preview"}
        type={NatLabelType.H4}
        weight={NatLabelWeight.MEDIUM}
      />
      <DocumentDetailsPreview
        document={documentDetails}
        projectId={projectId}
        onHide={() => {}}
      />
      <hr />
    </div>
  ) : null;

  return (
    <form
      onSubmit={handleSubmit((values, event) => {
        if (!values.documentId || !values.documentTitle) {
          return null;
        }
        return updateProjectDocumentInfo(projectId, values.documentId, {
          title: values.documentTitle,
          document_id: values.documentId,
        }).then(() => {
          closeModal && closeModal();
          return;
        });
      })}
    >
      {documentPreview}

      {!documentDetails ? (
        <ProjectDocumentUploadButton
          documentTitle={documentTitle}
          documentId={documentId}
          projectId={projectId}
          allowedFileTypes={documentInfo?.allowedFileTypes}
          allowMultiple={true}
          onComplete={(documentDetails) => {
            const latestDocumentId = watch("documentId");
            if (!latestDocumentId) {
              setValue("documentId", documentDetails?.document_id ?? null);
              setValue("documentTitle", documentDetails?.title ?? "");
              setValue("documentDetails", documentDetails);

              // validate title after setting it
              trigger("documentTitle", {shouldFocus: true});
            }
          }}
          disabled={false}
        />
      ) : (
        <br />
      )}

      <div hidden={!documentId}>
        <NatLabel
          label={"Document title"}
          type={NatLabelType.H4}
          weight={NatLabelWeight.MEDIUM}
        />
        <input
          {...register("documentTitle", {required: true})}
          placeholder={"Document title"}
        />
        {Object.values(formState.errors).map((error) => {
          // @ts-ignore
          return <div style={{color: "red"}}>{error?.message}</div>;
        })}
      </div>

      <br />
      <div style={{display: "flex", gap: "1rem"}} hidden={!documentDetails}>
        <NatButton
          label={"Cancel"}
          type={"button"}
          option={StyleOption.SECONDARY}
          clickEvent={() => {
            closeModal && closeModal();
          }}
        />
        <NatButton
          label={"Delete"}
          type={"button"}
          hidden={!documentId}
          clickEvent={() => {
            if (documentId) {
              return updateProjectDocumentInfo(projectId, documentId, {
                deleted: true,
              }).then(() => {
                closeModal && closeModal();
                return;
              });
            }
          }}
          option={StyleOption.DESTRUCTIVE}
        />
        <NatButton
          label={"Save"}
          disabled={!formState.isValid}
          type={"submit"}
        />
      </div>
    </form>
  );
};

const getInitialValues = (documentDetails?: IDocumentDetails) => {
  return {
    documentTitle: documentDetails?.title ?? "",
    documentId: documentDetails?.document_id ?? null,
    documentDetails: documentDetails ?? null,
  };
};
