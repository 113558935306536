import {useMemo} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {
  GenericAccess,
  isEmpty,
  IUserAccess,
  UserAccessTypes,
} from "@natomas-org/core";

export interface IManagingEmployees {
  [key: string]: boolean;
}

export interface IPermissions {
  isFetching: boolean;
  access: IUserAccess;
  fullAccess: boolean;
  anyAccess: boolean;
  uid: string;
  managedEmployees?: string[];
}

const isSuperAdmin = (permissions: any): boolean => {
  return permissions?.role === GenericAccess.ADMIN;
};

const getManagedEmployees = (permissions: any): string[] => {
  if (!isEmpty(permissions?.employees)) {
    const employeeLedger: IManagingEmployees = permissions.employees;
    return Object.keys(employeeLedger)?.filter(
      (eid: string) => employeeLedger[eid]
    );
  }
  return permissions?.employees;
};

const getAccessMap = (permissions: any): IUserAccess => {
  if (isSuperAdmin(permissions)) {
    const superAccessMap: {[key: string]: boolean} = {};
    Object.values(UserAccessTypes)?.forEach((value) => {
      superAccessMap[value] = true;
    });
    return superAccessMap as any as IUserAccess;
  }

  return {
    [UserAccessTypes.COMPLETED_PROJECTS]:
      permissions?.completedProjects?.role === GenericAccess.ADMIN ?? false,
    [UserAccessTypes.CUSTOMERS]:
      permissions?.customers?.role === GenericAccess.ADMIN ?? false,
    [UserAccessTypes.DEVELOPER]:
      permissions?.developer?.role === GenericAccess.ADMIN ?? false,
    [UserAccessTypes.PRODUCT_LINES]:
      permissions?.productLines?.role === GenericAccess.ADMIN ?? false,
    [UserAccessTypes.SUPER_CATALOG]:
      permissions?.superCatalog?.role === GenericAccess.ADMIN ?? false,
  };
};

export const usePermissions = (uid?: string): IPermissions => {
  const loggedInUser = useSelector((state: IStore) => state.global.user);
  const userPermissionsMap = useSelector(
    (state: IStore) => state.user.userPermissions
  );

  const userId = uid ?? loggedInUser?.uid;

  const userPermissions = userId
    ? userPermissionsMap[userId] ?? undefined
    : null;

  const access: IUserAccess = getAccessMap(userPermissions);

  const managedEmployees: string[] = getManagedEmployees(userPermissions);

  return {
    uid: userId as string,
    isFetching: userPermissions === undefined,
    access: access, // Undefined until there is a response from server
    fullAccess: isSuperAdmin(userPermissions),
    anyAccess: Object.values(access).filter((a: boolean) => a).length > 0,
    managedEmployees: managedEmployees,
  };
};

export const useAllPermissions = (): IPermissions[] => {
  const userPermissionsMap = useSelector(
    (state: IStore) => state.user.userPermissions
  );

  const uids = useMemo(() => {
    return Object.keys(userPermissionsMap);
  }, [userPermissionsMap]);

  return useMemo(() => {
    return uids?.map((uid: string) => {
      const userPermissions = uid
        ? userPermissionsMap[uid] ?? undefined
        : undefined;

      const access: IUserAccess = getAccessMap(userPermissions);

      const managedEmployees: string[] = getManagedEmployees(userPermissions);

      return {
        uid: uid,
        isFetching: userPermissions === undefined,
        access: access, // Undefined until there is a response from server
        fullAccess: isSuperAdmin(userPermissions),
        anyAccess: Object.values(access).filter((a: boolean) => a).length > 0,
        managedEmployees: managedEmployees,
      };
    });
  }, [uids, userPermissionsMap]);
};
