import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import React from "react";
import {SmartImage} from "../../../../_shared/generics/image/SmartImage";
import {
  BannerContainer,
  BannerImageContainer,
  BannerContentContainer,
  BannerTitle,
  BannerDescription,
  BannerContentWrapper,
  BannerBadgeContainer,
  MobileBannerContainer,
  MobileBannerImageContainer,
} from "./styles";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import CatalogBadge, {ICatalogBadge} from "../CatalogBadge";
import {useColumnCount} from "../../../../_shared/hooks/usePage";
import {SmartCatalogHeading} from "../../../../map/_shared/components/ParcelInfo";
import SeriesBannerAddress from "../../../views/ProductLineView/components/SeriesBanner/SeriesBannerAddress";

interface BannerProps {
  imageId?: string;
  title?: string;
  description?: string;
  badges?: ICatalogBadge[];
}

export const ProductLineViewBanner = (props: BannerProps) => {
  const {title, description, badges, imageId} = props;
  const width = useDynamicValue(fullColumnWidths);
  const {isNatMobile} = useColumnCount();

  if (isNatMobile) {
    return (
      <>
        <PageContainer overridePadding={true}>
          <MobileBannerImageContainer>
            <SmartImage imageId={imageId} />
          </MobileBannerImageContainer>
        </PageContainer>
        <PageContainer>
          <MobileBannerContainer>
            <BannerTitle isMobile={isNatMobile} hidden={!title}>
              {title}
            </BannerTitle>
            <BannerDescription isMobile={isNatMobile} hidden={!description}>
              {description}
            </BannerDescription>
            <BannerBadgeContainer>
              {badges?.map((badge, index) => (
                <CatalogBadge
                  key={`badge-${index}`}
                  label={badge.label}
                  color={badge.color}
                  isMobile={isNatMobile}
                />
              ))}
            </BannerBadgeContainer>
            <SeriesBannerAddress />
          </MobileBannerContainer>
        </PageContainer>
      </>
    );
  } else {
    return (
      <PageContainer>
        <PageElement size={width} height={`${24}rem`}>
          <BannerContainer height={24}>
            <BannerContentWrapper>
              <BannerContentContainer>
                <BannerTitle isMobile={isNatMobile} hidden={!title}>
                  {title}
                </BannerTitle>
                <BannerDescription isMobile={isNatMobile} hidden={!description}>
                  {description}
                </BannerDescription>
                <BannerBadgeContainer>
                  {badges?.map((badge, index) => (
                    <CatalogBadge
                      key={`badge-${index}`}
                      label={badge.label}
                      color={badge.color}
                      isMobile={isNatMobile}
                    />
                  ))}
                </BannerBadgeContainer>
                <SeriesBannerAddress />
              </BannerContentContainer>
            </BannerContentWrapper>
            <BannerImageContainer hidden={!imageId}>
              <SmartImage imageId={imageId} forceHeight={`${24}rem`} />
            </BannerImageContainer>
          </BannerContainer>
        </PageElement>
      </PageContainer>
    );
  }
};
