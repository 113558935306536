import {useDispatch, useSelector} from "react-redux";
import {editingOptionKeys} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {ProductOptionKeyStructure} from "../../../_models/productOption";
import {saveOptionKeyToDB} from "./ProductOptionHelper";

export const EditProductOptionKeys = () => {
  const isEditingOptionKeys = useSelector(
    (state) => state.adminStudioManager.isEditingOptionKeys
  );
  const optionKeys = useSelector((state) => state.options.optionKeys);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(editingOptionKeys(false));
  };

  const [currentOptionKey, setCurrentOptionKey] = useState(null);

  const initialValues = {};
  Object.keys(ProductOptionKeyStructure).forEach((key) => {
    initialValues[key] = "";
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (currentOptionKey != null) {
        const valueToSave = Object.assign({id: currentOptionKey.id}, values);
        saveOptionKeyToDB(valueToSave);
        setCurrentOptionKey(null);
      }
    },
  });

  const sortedOptionKeys = Object.values(optionKeys).sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });

  useEffect(() => {
    if (currentOptionKey == null) {
      formik.resetForm();
    } else {
      for (const key in ProductOptionKeyStructure) {
        if (currentOptionKey[key] != null) {
          formik.setFieldValue(key, currentOptionKey[key]);
        } else {
          formik.setFieldValue(key, "");
        }
      }
    }
  }, [currentOptionKey]);

  if (isEditingOptionKeys !== true) {
    return null;
  }

  return (
    <div id="myModal">
      <div
        className="fullDiv"
        onClick={() => {
          close();
        }}
      />
      <div style={{maxHeight: "80vh", overflow: "scroll"}}>
        <div
          className="close"
          onClick={() => {
            close();
          }}
        >
          &times;
        </div>
        <div style={{verticalAlign: "top", height: "100%"}}>
          <div
            style={{
              width: "50%",
              display: "inline-block",
              height: "100%",
              overflow: "scroll",
            }}
          >
            <div
              className="clickable"
              onClick={() => {
                setCurrentOptionKey({});
              }}
            >
              New Option Key
            </div>
            {optionKeys == null
              ? null
              : sortedOptionKeys.map((optionKey) => {
                  return (
                    <div
                      className="clickable"
                      onClick={() => {
                        setCurrentOptionKey(optionKey);
                      }}
                      key={optionKey.id}
                    >
                      {optionKey.name}
                    </div>
                  );
                })}
          </div>
          <div
            style={{
              width: "50%",
              display: currentOptionKey == null ? "none" : "inline-block",
              verticalAlign: "top",
            }}
          >
            {currentOptionKey == null ? null : currentOptionKey.id}
            <form onSubmit={formik.handleSubmit}>
              {Object.keys(ProductOptionKeyStructure).map((key) => {
                return (
                  <div key={key}>
                    <label htmlFor={key} style={{marginRight: "20px"}}>
                      {key}:
                    </label>
                    <input
                      id={key}
                      name={key}
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values[key]}
                    />
                    <br />
                  </div>
                );
              })}

              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
