import {
  FilterDropdownDisplayType,
  FilterType,
} from "../../../../../catalog/filter/types";
import {deleteFiltersByTypes} from "../../../../../catalog/filter/logic";
import {setAllInEstimateCatalog} from "../../../../../slices/CatalogSlice/CatalogSessionSlice";
import {store} from "../../../../../../../store";

export interface IFilterDisplayAttributes {
  contentTitle: string;
  unsetLabel: string;
  style?: React.CSSProperties;
}
const baseStyle = {minWidth: "19rem"};
export const FilterDisplayAttributes: {
  [type in FilterDropdownDisplayType]: IFilterDisplayAttributes;
} = {
  [FilterDropdownDisplayType.UnitPrice]: {
    contentTitle: "Set Base Unit Price Range",
    unsetLabel: "Price",
    style: {...baseStyle, minWidth: "18rem", maxWidth: "19rem"},
  },
  [FilterDropdownDisplayType.SquareFoot]: {
    contentTitle: "Set Sq. Ft. Range",
    unsetLabel: "Sq. Ft.",
    style: {...baseStyle, minWidth: "18rem"},
  },
  [FilterDropdownDisplayType.BedAndBath]: {
    contentTitle: "Bed & Bath",
    unsetLabel: "Bed & Bath",
    style: {...baseStyle, maxWidth: "19rem"},
  },
  [FilterDropdownDisplayType.Toggles]: {
    contentTitle: "More",
    unsetLabel: "More",
    style: {...baseStyle, minWidth: "19rem"},
  },
};

export const resetFiltersByDisplayType = (
  displayType: FilterDropdownDisplayType
) => {
  deleteFiltersByTypes(getFilterArrayFromFilterDisplayType(displayType));
  if (displayType === FilterDropdownDisplayType.Toggles) {
    store.dispatch(setAllInEstimateCatalog(false));
  }
};

const getFilterArrayFromFilterDisplayType = (
  displayType: FilterDropdownDisplayType
) => {
  switch (displayType) {
    case FilterDropdownDisplayType.Toggles:
      return [FilterType.ProductParcelFit];
    case FilterDropdownDisplayType.BedAndBath:
      return [FilterType.Bathroom, FilterType.Bedroom];
    case FilterDropdownDisplayType.SquareFoot:
      return [FilterType.SquareFootage];
    case FilterDropdownDisplayType.UnitPrice:
      return [FilterType.UnitPriceRange];
    default:
      return [];
  }
};
