import {IProduct} from "@natomas-org/core";
import {
  FactoryInventoryPricingEditorKey,
  IFactoryInventoryPricingEditorValues,
} from "./interfaces";

export const initializeInventoryPricingEditor = (
  product?: IProduct,
  initialModifiers?: any
): IFactoryInventoryPricingEditorValues => {
  const salesTaxCost = product?.inventory_info?.salesTaxPrice?.cost ?? 0;
  const totalCost = product?.price?.cost ?? 0;
  return {
    modifiers: initialModifiers,
    product: {
      [FactoryInventoryPricingEditorKey.UNIT_COST]: totalCost - salesTaxCost,
      [FactoryInventoryPricingEditorKey.UNIT_PRICE]: product?.price?.price ?? 0,
      [FactoryInventoryPricingEditorKey.SALES_TAX]: salesTaxCost,
    },
  };
};
