import React from "react";
import {Dispatch} from "@reduxjs/toolkit";
import {
  animateMapLeaveHome,
  resetLeafletMap,
  setGLMapAnimationOptions,
  setGLMapState,
} from "../../../slices/mapSlice";
import {
  Address,
  Area,
  IAddressDetails,
  IAreaDetails,
  IGLMapState,
  IMapBounds,
  IMapStateAnimationOptions,
} from "@natomas-org/core";
import {
  setAreaAddress,
  setSearchAddress,
} from "../../../../_shared/slices/AddressSlice";
import {MAP_ANIMATION_DURATION_SECONDS} from "../../../../mapping/views/theme/constants";

interface IAutoComplete {
  addListener: (arg0: string, arg1: () => void) => void;
  setTypes: (input: any) => any;
  getPlace: () => any;
}
export enum LOCATION_SEARCH_AUTOCOMPLETE_TYPE {
  AREA = "geocode",
  STREET_ADDRESS = "address",
}
//handle when the script is loaded we will assign autoCompleteRef with google maps autocomplete
//were passing in setQuery as updateQuery, the ref of the input and passing in dispatch function, also passing setAddressError so if incomplete address is sent, we can display error message
//passing in nextStep to handle the next step when someone chooses a verified address
export function handleBuildSearchScriptLoad(
  updateQuery: React.Dispatch<React.SetStateAction<string | undefined>>,
  autoCompleteRef: React.MutableRefObject<null>,
  dispatch: Dispatch<any>,
  setVerifiedAddress: React.Dispatch<React.SetStateAction<boolean | undefined>>,
  locationSearchType: LOCATION_SEARCH_AUTOCOMPLETE_TYPE,
  onSubmit?: () => void
): (setSearchType: LOCATION_SEARCH_AUTOCOMPLETE_TYPE) => any {
  const autoComplete: IAutoComplete =
    // @ts-ignore
    new window.google.maps.places.Autocomplete(
      //set the type of data we want and the fields associated with that type
      autoCompleteRef.current,
      {
        types: [locationSearchType],
        componentRestrictions: {country: "us"},
      }
    );

  function setupClickListener(
    setSearchType: LOCATION_SEARCH_AUTOCOMPLETE_TYPE
  ) {
    autoComplete.setTypes([setSearchType]);
    //Everytime user add/remove a character, call handlePlaceSelect to update predictions
    autoComplete.addListener("place_changed", () => {
      return dispatchBuildSearch(
        updateQuery,
        dispatch,
        setVerifiedAddress,
        autoComplete,
        setSearchType,
        onSubmit
      );
    });
  }
  return setupClickListener;
}

async function dispatchBuildSearch(
  updateQuery: {
    (value: React.SetStateAction<string | undefined>): void;
    (arg0: any): void;
  },
  dispatch: Dispatch<any>,
  setVerifiedAddress: {
    (value: React.SetStateAction<boolean | undefined>): void;
    (arg0: boolean): void;
  },
  autoComplete: IAutoComplete,
  searchType: LOCATION_SEARCH_AUTOCOMPLETE_TYPE,
  onSubmit?: () => void
) {
  //when user selects a prediction, grab the data from that selection and save it
  const addressObject = autoComplete.getPlace();
  const areaDetails: IAreaDetails | null =
    Area.autoCompleteToAreaDetails(addressObject);
  const propertyAddressDetails: IAddressDetails | null =
    Address.autoCompleteToPropertyAddressDetails(addressObject);

  const validAreaSearch =
    !!areaDetails && searchType === LOCATION_SEARCH_AUTOCOMPLETE_TYPE.AREA;
  if (!validAreaSearch && !propertyAddressDetails) {
    return setVerifiedAddress(false);
  }

  //set verified address to true
  setVerifiedAddress(true);
  !!onSubmit && onSubmit();

  if (!!propertyAddressDetails) {
    updateQuery(Address.getFullAddress(propertyAddressDetails ?? undefined));
    return dispatchBuildAddress(dispatch, propertyAddressDetails);
  }

  // The only requirement for a valid address in this case is latitude and longitude
  if (validAreaSearch && areaDetails) {
    //using the setQuery function passed on as updateQuery to update local state
    updateQuery(Address.getFullAddress(areaDetails?.address));
    const animationOptions: IMapStateAnimationOptions = {
      padding: 0,
      duration: MAP_ANIMATION_DURATION_SECONDS * 3000,
      delay: MAP_ANIMATION_DURATION_SECONDS * 1000,
      pitch: 0,
      bearing: 0,
    };
    const areaDetailsBounds = areaDetails?.bounds;
    if (!!areaDetailsBounds) {
      animationOptions.bounds = Area.convertIMapBoundsToBBox(areaDetailsBounds);
    } else {
      animationOptions.lat =
        Address.getLatitude(areaDetails?.address) ?? undefined;
      animationOptions.lng =
        Address.getLongitude(areaDetails?.address) ?? undefined;
    }
    dispatch(setGLMapAnimationOptions(animationOptions));
    dispatch(setAreaAddress(areaDetails));
    return dispatch(animateMapLeaveHome({bounds: areaDetails?.bounds}));
  }
}

export function dispatchBuildAddress(
  dispatch: Dispatch<any>,
  addressDetails: IAddressDetails,
  bounds?: IMapBounds
) {
  dispatch(setAreaAddress({address: addressDetails, bounds: bounds}));
  dispatch(
    setGLMapState({
      mLng: addressDetails.longitude,
      mLat: addressDetails.latitude,
      zoom: 18,
      pitch: 0,
      bearing: 0,
      satelliteEnabled: true,
    } as IGLMapState)
  );
  if (!addressDetails?.longitude || !addressDetails?.latitude) {
    return;
  }
  dispatch(resetLeafletMap());
  dispatch(setSearchAddress(addressDetails));
}
