import React from "react";
import Button from "../../2-component/Button";

const Email = (props: {id: string; label: string; onClick: () => void}) => {
  const {id, label, onClick} = props;

  return <Button id={id} label={label} theme={"primary"} onClick={onClick} />;
};

export default Email;
