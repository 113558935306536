import {createSlice} from "@reduxjs/toolkit";

const portalSlice = createSlice({
	name: "portal",
	initialState: {
		adminMode: false,
	},
	reducers: {
		setAdminMode: (state, action) => {
			state.adminMode = action.payload;
		},
	},
});

export const portalReducer = portalSlice.reducer;
export const {setAdminMode} = portalSlice.actions;
