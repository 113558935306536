import useActiveCustomer from "../../components/_shared/hooks/useCustomer/useActiveCustomer";
import useActiveProject from "../../components/_shared/hooks/useProject/useActiveProject";
import useActivitySlice from "../../components/_shared/hooks/useActivity/useActivitySlice";
import useActivitySliceDispatcher from "../../components/_shared/hooks/useCustomer/useActivitySliceDispatcher";
import {ProjectUserType} from "@natomas-org/core";
import {getProjectIds, ProjectFetchMode} from "../../database/firebase/project";
import {ProjectIdSource, subscribeToProject} from "./project";
import {useEffect, useMemo} from "react";
import {findUserOfProject} from "./users";
import {useCurrentUser} from "../../components/_shared/hooks/useCurrentUser";
import {useLocation} from "react-router-dom";

export const ProjectSliceManager = (): null => {
  const {isAdmin} = useCurrentUser();
  const {id: customerId, info: customerInfo} = useActiveCustomer();
  const location = useLocation();
  const {info: projectInfo} = useActiveProject();
  const {activeProjectId} = useActivitySlice();
  const {setActiveCustomerById, setActiveProjectById} =
    useActivitySliceDispatcher();

  const customerProjectIds = useMemo(() => {
    return getProjectIds(customerInfo, ProjectFetchMode.ALL);
  }, [customerInfo]);

  useEffect(() => {
    if (!activeProjectId && customerProjectIds?.length) {
      // Default project if the user is not an admin
      // If we are not in the admin system, default to the first project
      if (location.pathname.includes("admin")) {
        console.log("Skipping default project for admin");
      } else {
        console.log("ProjectSliceManager: Defaulting project");
        setActiveProjectById(customerProjectIds[0]);
      }
    }
  }, [
    activeProjectId,
    customerProjectIds,
    isAdmin,
    location,
    setActiveCustomerById,
    setActiveProjectById,
  ]);

  // Initialize user from project id
  useEffect(() => {
    if (projectInfo?.users) {
      const ownerId = findUserOfProject(
        projectInfo.users,
        ProjectUserType.OWNER
      );
      if (ownerId && ownerId !== customerId) {
        return setActiveCustomerById(ownerId);
      }
    }
  }, [customerId, projectInfo, setActiveCustomerById]);

  // Ensure that project doc is subscribed to
  useEffect((): any => {
    if (activeProjectId) {
      return subscribeToProject({projectId: activeProjectId});
    }
  }, [activeProjectId]);

  // Preemptively subscribe to all projects
  useEffect((): any => {
    customerProjectIds?.forEach((projectId) => {
      return subscribeToProject({
        projectId: projectId,
        projectIdSource: ProjectIdSource.CUSTOMER,
        projectIdSourceId: customerId,
      });
    });
  }, [customerId, customerProjectIds]);

  return null;
};
