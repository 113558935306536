import React from "react";
import LoginForm from "../../1-container/forms/LoginForm";
import {ISetSchema} from "../../../api/Auth/Login/handleCredential";
import styled from "styled-components";

const LoginContent = (props: {
  setSchema: ISetSchema;
  toSignUp: () => void;
  toResetPassword: () => void;
}) => {
  return (
    <Container>
      <LoginForm
        toSignUp={props.toSignUp}
        setSchema={props.setSchema}
        resetPassword={props.toResetPassword}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

export default LoginContent;
