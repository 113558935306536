import React from "react";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../_shared/generics/_shared";
import {NatEditIcon} from "../../../../../../../../../../../_shared/icon/icons";
import {NatButton} from "../../../../../../../../../../../_shared/generics/button";

interface StudioEditorFieldEditButtonProps {
  clickEvent?: (value: any) => void;
}

const StudioEditorFieldEditButton = (
  props: StudioEditorFieldEditButtonProps
) => {
  const {clickEvent} = props;

  return (
    <NatButton
      disabled={!clickEvent}
      clickEvent={clickEvent}
      label={"edit"}
      option={StyleOption.ADMIN_SOLID}
      size={NatSize.SMALL}
      icon={{icon: <NatEditIcon />, iconPosition: IconPosition.ONLY}}
    />
  );
};

export default StudioEditorFieldEditButton;
