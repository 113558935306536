import {IUpgradeListDetailAlt} from "../../../_shared/snapshot";
import {
  LocationPermitPlansLabelSlug,
  LocationSitePreparationFoundationLabelSlug,
  LocationStandardFinishingLabelSlug,
  LocationUtilitiesLabelSlug,
  LocationVillaProjectManagementLabelSlug,
} from "../../../../0-app/1-container/admin-product-instant-quote-page/EditLocation";

export interface ISiteWorkBucket {
  title: string;
  priceText: string;
  bulletPoints: string[];
  alternativePoints?: IUpgradeListDetailAlt[];
}

export const bulletPoints: {[key: string]: string[]} = {
  [LocationPermitPlansLabelSlug]: [
    "Permit ready site, floor, foundation, utility, and structural plans",
    "Permit research, submission, and issuance fees",
  ],
  [LocationSitePreparationFoundationLabelSlug]: [
    "Site clearing and basic grading",
    "Construction of a permanent foundation",
  ],
  [LocationUtilitiesLabelSlug]: [
    "Power, sewer, and water hookups",
    "Electric panel upgrades may be needed",
  ],
  [LocationVillaProjectManagementLabelSlug]: [
    "Phase 1 fee for preconstruction services to obtain permit",
    "Villa's construction management services after permits are approved",
  ],
  [LocationStandardFinishingLabelSlug]: [
    "This includes transportation from our manufacturing partner to your property, securing your unit to the foundation and finishing requirements for final inspection.",
  ],
};
