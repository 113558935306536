import React from "react";
import {PRODUCT_CATALOG_TABS} from "../_shared/constants/AdminPage";
import {AdminView} from "../_shared/components/AdminView";
import {AdminHeaderTitle} from "../_shared/components/AdminHeaderTitle";
import {useNavigation} from "../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../_shared/hooks/useNavigation/paths";

export const AdminProductCatalogView = () => {
  const {to} = useNavigation();

  const title = "Product Catalog";

  return (
    <AdminView
      headerContent={
        <AdminHeaderTitle
          title={title}
          clickEvent={() => to(NavigationPaths.ADMIN)}
        />
      }
      title={title}
      tabs={PRODUCT_CATALOG_TABS}
    />
  );
};
