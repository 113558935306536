import styled from "styled-components";

export const StudioEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 16px;
`;

export const StudioEditorCategorySeparator = styled.div`
  height: 1px;
  width: 100%;
  background: #939393;
  margin: 36px 0;
`;
