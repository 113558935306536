import {NatomasMapFeature, NatomasMapFeatureType} from "@natomas-org/core";
import {IFeatureMapSidebarItem} from "./sidebarItem";

export const getNatomasMapFeature = (
  id: string,
  label: string,
  type: NatomasMapFeatureType
): NatomasMapFeature => {
  return {
    id: id,
    label: label,
    type: type,
    zones: undefined,
  };
};

export const getFeatureMapSideBarItem = (
  id: string,
  label: string,
  hasFeature: boolean,
  children?: IFeatureMapSidebarItem[]
): IFeatureMapSidebarItem => {
  return {
    id: id,
    label: label,
    hasFeature: hasFeature,
    children: children ?? undefined,
  };
};
