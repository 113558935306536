import React, {useCallback, useEffect, useMemo, useState} from "react";

export interface FactoryInventoryNumberInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: number;
  range?: [number, number];
  label?: string;
}

const NumberInput = (props: FactoryInventoryNumberInputProps) => {
  const {id, onChange, label, initialValue, range} = props;

  const rangeProps = useMemo(() => {
    if (range) {
      return {
        min: range[0],
        max: range[1],
      };
    }
    return undefined;
  }, [range]);

  const [val, setVal] = useState(0);
  const [strVal, setStrVal] = useState("0"); // To keep a reference of the real input value

  const handleBlur = useCallback(
    (evt: any) => {
      setStrVal(val.toString());
    },
    [val]
  );

  const value = useMemo(() => {
    return initialValue ?? 0;
  }, [initialValue]);

  useEffect(() => {
    setStrVal(value?.toString());
    setVal(value);
  }, [value]);

  return (
    <>
      {label}
      <input
        id={id}
        name={id}
        type={"number"}
        onChange={(e) => {
          const value: string | undefined = e?.target?.value;
          let num: number = Number(value);
          if (rangeProps) {
            if (rangeProps?.min > num) {
              num = rangeProps?.min;
            }
            if (rangeProps?.max < num) {
              num = rangeProps?.max;
            }
          }
          setStrVal(num?.toString());
          setVal(num);
          onChange(num);
        }}
        onBlur={handleBlur}
        value={strVal}
        {...rangeProps}
      />
    </>
  );
};

export default NumberInput;
