import {Utilities} from "../index";
import {
  CONFIGURATION_DESIGN_DB_KEY,
  CONFIGURATION_SNAPSHOTS_DB_KEY,
} from "./configuration";

export async function getConfiguration(configurationId: any) {
  const configurationsRef = Utilities.collection(CONFIGURATION_DESIGN_DB_KEY);
  const docData = await Utilities.getDocData(
    configurationsRef.doc(configurationId)
  );
  console.log("Getting Configuration: " + configurationId);
  console.log(docData);
  return docData;
}

export const resetConfigurationSnapshot = (configurationId: string) => {
  const configurationsRef = Utilities.collection(
    CONFIGURATION_SNAPSHOTS_DB_KEY
  );
  return configurationsRef.doc(configurationId).delete();
};
