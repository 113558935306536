import styled from "styled-components";
import {GenericInputContentContainer} from "../../../_shared/styles/AdminStyles";
import {SIDEBAR_COLOR} from "../../../../_shared/colors";

export const ImageManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin: 1rem 0;
  width: 100%;
`;
export const NewImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  margin: 1rem 0;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${GenericInputContentContainer} {
    border-bottom: 1px solid ${SIDEBAR_COLOR};
  }
  & > * {
    margin: 0 0.5rem 0 0.5rem;
    padding: 0;
  }
`;

export const ImageDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${SIDEBAR_COLOR};
  margin-bottom: 1rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
