import {LinkIconContainer} from "../DragAndDropEditor/styles";
import {BsFolderSymlink} from "react-icons/bs";
import React from "react";

export const BackLinkIcon = (props: {action: () => void}) => {
  return (
    <LinkIconContainer onClick={() => props.action()}>
      <BsFolderSymlink />
    </LinkIconContainer>
  );
};
