import React, {Dispatch, SetStateAction} from "react";
import {SearchAllStateOptions} from "../index";
import {getProductTiles} from "../helper";
import {TileContainer} from "../styles";
import {useFactoryLine} from "../../../../../hooks/useProductCatalog/useFactoryLine/useFactoryLine";

export interface SearchProductGroupProductsProps {
  setMode: Dispatch<SetStateAction<SearchAllStateOptions>>;
  setProductId: Dispatch<SetStateAction<string | undefined>>;
  productGroupId?: string;
}

export const SearchProductGroupProducts = (
  props: SearchProductGroupProductsProps
) => {
  const {setProductId, setMode, productGroupId} = props;
  const {products} = useFactoryLine({factoryId: productGroupId});

  const selectProduct = (id: string) => {
    setMode(SearchAllStateOptions.PRODUCT);
    setProductId(id);
  };

  if (!productGroupId) {
    return null;
  }
  return (
    <TileContainer>
      {getProductTiles(Object.values(products ?? {}), selectProduct)}
    </TileContainer>
  );
};
