import {useActiveCartItem} from "../useCart/useCartItem/useActiveCartItem";
import useDesign from "./useDesign";
import {IDesign} from "@natomas-org/core";

const useActiveDesign = () => {
  const {details} = useActiveCartItem() ?? {};
  const {configurationId} = details ?? {};
  return useDesign(configurationId) as IDesign;
};

export default useActiveDesign;
