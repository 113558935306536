import React, {ChangeEvent, useState} from "react";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import VCL from "@natomas-org/villa-component-library";
import {getLeadService} from "../../../api/Lead";
import {isProd} from "../../../components/_shared/application";
import ComfortLineInterestPardotForm from "./pardot";
import FormRedirect from "./redirect";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";

const captureInterest = async (schema: CreateLeadSchema) => {
  const leadService = await getLeadService();
  try {
    // Register the lead
    await leadService.create(schema);
    // Submit the form to Pardot
    if (isProd) {
      // @ts-ignore
      document.querySelector("#hidden-submit-button")?.click();
    } else {
      console.warn(
        "InterestForm/Pardot",
        "Pardot form submission is disabled outside of production"
      );
    }

    window.location.href = "https://villahomes.com/thank-you";
  } catch (error) {
    window.alert("An error occurred. Please try again.");
  }
};

export const InterestForm = () => {
  // State to manage form data
  const [formData, setFormData] = useState<{[key: string]: any}>({});
  const [phoneNumber, setPhoneNumber] = useState("");
  // Function to handle form input changes
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = (): Promise<void> => {
    // Call the onSubmit function with the form data
    return captureInterest({
      first_name: formData["first_name"],
      last_name: formData["last_name"],
      email: formData["email"],
      phone_number: phoneNumber,
      created_from: "interest/comfort-line",
      lead_segment: "wait-list",
    });
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputPhoneNumber = event.target.value;
    // Remove non-numeric characters
    const cleanedPhoneNumber = inputPhoneNumber.replace(/\D/g, "");
    // Format the phone number
    const formattedPhoneNumber = formatPhoneNumber(cleanedPhoneNumber);
    // Update the state
    setPhoneNumber(formattedPhoneNumber);
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    // Remove all non-numeric characters
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Apply the format
    return cleanedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  };

  let form = (
    <VCL.Components.Organisms.Form
      handleChange={handleChange}
      handleSubmit={() => handleSubmit()}
      disclaimer={[
        "*$5,000.00 in free upgrades applies only to the Comfort Series and does not apply to the base cost of the unit. Available only to first 20 customers that join the Comfort Series waitlist by April 9, 2024. Offer expires April 9, 2024. Other terms and limitations may apply.",
        `By submitting this form, you are opting in to receive email and text message marketing communications from Villa. Visit our <a target="_blank" style="text-decoration: underline" href="https://villahomes.com/privacy-policy/">Privacy Policy</a> to learn about our information practices and your privacy rights. To opt out of future marketing and advertising communications at any time, please use the unsubscribe button at the bottom of our email communications or reply STOP to stop receiving text messages. Message and data rates may apply.`,
      ]}
      fields={[
        {
          input: {
            id: "first_name",
            placeholder: "",
            required: true,
            type: "text",
            autoComplete: "on",
          },
          label: "First Name",
        },
        {
          input: {
            id: "last_name",
            placeholder: "",
            required: true,
            type: "text",
            autoComplete: "on",
          },
          label: "Last Name",
        },
        {
          input: {
            id: "email",
            placeholder: "",
            required: true,
            type: "email",
            autoComplete: "on",
          },
          label: "Email",
        },
        {
          input: {
            id: "phone_number",
            pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
            type: "tel",
            maxLength: 12,
            required: true,
            autoComplete: "on",
            value: phoneNumber,
            onChange: handlePhoneChange,
          },
          label: "Phone",
        },
      ]}
      subTitle="Get early access and become eligible for $5K in free upgrades!*"
      submit="Submit"
      title="Join the waitlist"
    />
  );

  // Is the form still active?
  if (new Date() > new Date(2024, 3, 9)) {
    form = <FormRedirect context={"The waitlist has closed."} />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh" /* Ensure at least full viewport height */,
        width: "100%",
        padding: "16px",
        gap: "32px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <ComfortLineInterestPardotForm
        firstName={formData["first_name"]}
        lastName={formData["last_name"]}
        email={formData["email"]}
        phoneNumber={phoneNumber}
      />
      <ErrorBoundary>{form}</ErrorBoundary>
    </div>
  );
};
