import {IMimeType} from "@natomas-org/core";

export async function downloadFileToDesktop(url, filename, mime, isMobile) {
  let mimeType = mime ?? IMimeType.PDF;
  try {
    const response = await fetch(url, {type: mimeType});
    const blob = await response.blob();
    if (isMobile) {
      window.open("data:application/pdf" + encodeURIComponent(blob));
    } else {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.style.display = "none";
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}
