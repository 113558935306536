import React from "react";
import {
  PageContainer,
  PageContainerOptionalProps,
} from "../../../../../_shared/generics/page/components/PageContainer";
import {SectionWrapper} from "./styles";

interface ProductLinePageContainerProps {
  children: React.ReactNode;
  containerProps?: PageContainerOptionalProps;
}

const ProductLinePageContainer = (props: ProductLinePageContainerProps) => {
  return (
    <PageContainer {...props.containerProps}>
      <SectionWrapper>{props.children}</SectionWrapper>
    </PageContainer>
  );
};

export default ProductLinePageContainer;
