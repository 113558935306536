import {IStore} from "../../slices/types/Store";
import {useSelector} from "react-redux";

export const useStudioSettings = () => {
  const {
    selections,
    editingSelections,
    saving,
    section,
    showEditingConfirmation,
    showSavingConfirmation,
    showOverwriteProjectWarning,
    step,
    hash,
  } = useSelector((state: IStore) => state.studio);

  return {
    isEditing: editingSelections,
    isSaving: saving,
    isShowingEditingConfirmation: showEditingConfirmation,
    isShowingSavingConfirmation: showSavingConfirmation,
    hash,
    section,
    step,
    selections,
  };
};
