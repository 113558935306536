import {SSDDashboardView} from "../../../../portal/views/SSDDashboardView/components/ANDY-components/SSDDashboardView";
import {AdminLandingView} from "../../../../admin/LandingViews/AdminLandingView";
import {SplashScreenProjectSummary} from "../../../components/SplashScreenProjectSummary";
import React from "react";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";

export const CustomerProjectBanner = (props: {
  isCustomerPreDeposit: boolean;
  customerProjects: any | null;
  setShowLoadingScreen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {loggedInUser, isAdmin} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const {isCustomerPreDeposit, customerProjects} = props;
  const {isNatMobile} = usePage();
  const horizontalRule = !!customer ? <hr /> : null;
  if (!loggedInUser) {
    return null;
  }
  if (isCustomerPreDeposit) {
    return (
      <div>
        <div style={isNatMobile ? {padding: "0 0"} : {padding: "0 2rem"}}>
          <SSDDashboardView justProjectInfo={true} />
        </div>
        {isAdmin && horizontalRule}
        <AdminLandingView contentElement={true} />
      </div>
    );
  } else {
    return (
      <div>
        <SplashScreenProjectSummary
          projectArray={customerProjects}
          setShowLoadingScreen={props.setShowLoadingScreen}
        />
        {horizontalRule}
        <AdminLandingView contentElement={true} />
      </div>
    );
  }
};
