import React, {useMemo} from "react";
import {IPDFDocumentInfo} from "@natomas-org/core";
import {
  DocumentWrapper,
  PDFButtonsContainer,
  PDFDateContainer,
  PDFTitle,
  PDFTitleContainer,
} from "./styles";
import {useProjectDocuments} from "../../hooks/useProject/useProjectDocuments";
import {
  getDocusignEventFromDocumentInfo,
  getDocusignStatusFromDocumentInfo,
} from "./helpers";
import {
  DocusignButton,
  DocusignStatus,
  DocusignStatusLabel,
  NewDocusignStatusLabel,
} from "./components";
import {useProject} from "../../hooks/useProject/useProject";
import {DocumentDetailsPreview} from "./components/DocumentDetailsPreview";
import {ProjectDocumentUploadButton} from "./components/ProjectDocumentUploadButton";

export const PDFDocumentInfoPDFManager = (props: {
  projectId: string | undefined;
  documentTemplateInfo: IPDFDocumentInfo;
}) => {
  const {projectId, documentTemplateInfo} = props;
  const {info} = useProject(projectId);
  const {projectDocuments} = useProjectDocuments(projectId);
  const currentDocument = useMemo(() => {
    return projectDocuments?.find((doc) => {
      return doc?.document_id === documentTemplateInfo?.document_id;
    });
  }, [documentTemplateInfo?.document_id, projectDocuments]);
  const docusignEvent = getDocusignEventFromDocumentInfo(
    info,
    documentTemplateInfo
  );
  const docusignStatus = getDocusignStatusFromDocumentInfo(
    info,
    documentTemplateInfo
  );

  if (!projectId) {
    return null;
  }

  return (
    <DocumentWrapper>
      <div style={{flexGrow: 1}}>
        <PDFTitleContainer>
          {currentDocument ? (
            <DocumentDetailsPreview
              document={currentDocument}
              projectId={projectId}
              onHide={() => {}}
              onDelete={() => {}}
            />
          ) : (
            <PDFTitle>{documentTemplateInfo?.title}</PDFTitle>
          )}
          <DocusignStatusLabel status={docusignStatus} />
          <NewDocusignStatusLabel
            docToDisplay={currentDocument ?? null}
            event={docusignEvent}
          />
        </PDFTitleContainer>
        <PDFDateContainer>
          <DocusignStatus event={docusignEvent} />
        </PDFDateContainer>
      </div>
      <PDFButtonsContainer>
        {documentTemplateInfo.docusign_enabled && currentDocument ? (
          <DocusignButton
            projectId={projectId}
            docusignStatus={docusignStatus}
            currentDocument={currentDocument}
          />
        ) : (
          <ProjectDocumentUploadButton
            allowedFileTypes={documentTemplateInfo?.allowedFileTypes}
            documentTitle={documentTemplateInfo.title}
            documentId={documentTemplateInfo?.document_id}
            projectId={projectId}
            onComplete={() => {}}
          />
        )}
      </PDFButtonsContainer>
    </DocumentWrapper>
  );
};
