import React, {FormEvent, useState} from "react";
import {
  CustomerHeaderContainer,
  CustomerHeaderSearchContent,
  CustomerHeaderTitle,
  CustomerResultsBlurb,
  CustomerTableContainer,
} from "./styles";
import {
  Table,
  TableBody,
} from "../../../../_shared/components/AdminTable/styles";
import {
  TableColumnHeaders,
  TableRowContainer,
} from "../../../../_shared/components/AdminTable";
import {
  ITableHeader,
  TableHeaderCategory,
  useTable,
} from "../../../../_shared/components/AdminTable/table";
import {
  HeaderSearchInput,
  NewTabIconContainer,
} from "../../../../_shared/styles/AdminStyles";
import {NatButton} from "../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../_shared/generics/_shared";
import {
  findUsersForAdmin,
  searchUsersWithSearchText,
} from "../../../../../../database/firebase/api/admin";
import {isEmpty, mergeObjects, SearchUsersRequest} from "@natomas-org/core";
import {sortUsers, UserRowInfo} from "./helpers";
import {getDaysAgo} from "../../../../_shared/_helpers";
import {MdKeyboardBackspace, MdOpenInNew} from "react-icons/md";
import {AdminHelpIcon} from "../../../../_shared/components/AdminHelpIcon";
import {DOC_LINK_ROOT} from "../../../../_shared/constants/links";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";
import {TiFilter} from "react-icons/ti";
import {useModal} from "../../../../../_shared/generics/modal/useModal";
import {SearchFilters} from "../FilterModal";
import {NatModalSize} from "../../../../../_shared/generics/modal/NatModal";
import {ADMIN_CUSTOMER_PATH} from "../../../../../../paths";
import useMountEffect from "../../../../../_shared/hooks/useMountEffect";

const customerTableHeaders: ITableHeader[] = [
  {
    label: "",
    column: "open_in_new_tab",
    category: TableHeaderCategory.NULL,
  },
  {
    label: "First",
    column: "first_name",
    category: TableHeaderCategory.STRING,
  },
  {
    label: "Last",
    column: "last_name",
    category: TableHeaderCategory.STRING,
  },
  {
    label: "Email",
    column: "email",
    category: TableHeaderCategory.STRING,
  },
  {
    label: "Last Sign-in",
    column: "last_login_timestamp",
    category: TableHeaderCategory.NUMBER,
  },
  {
    label: "Joined",
    column: "created_timestamp",
    category: TableHeaderCategory.NUMBER,
  },
  {
    label: "ID",
    column: "user_id",
    category: TableHeaderCategory.STRING,
  },
];

export const CustomersTable = () => {
  const {sortingDetails, sort} = useTable(customerTableHeaders);
  const [searchRequest, setSearchRequest] = useState<SearchUsersRequest>({
    searchText: "",
  });
  const [lastSearch, setLastSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserRowInfo[]>();
  const {to} = useNavigation();

  const viewCustomerSummary = (userId: string, newTab: boolean) => {
    window.open(
      window.location.origin + ADMIN_CUSTOMER_PATH + "?uid=" + userId
    );
  };

  const searchUsers = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setLastSearch(searchRequest.searchText);
    searchUsersWithSearchText(searchRequest).then((r: any) => {
      setLoading(false);
      if (!isEmpty(r)) {
        const results = Object.values(r) as any;
        console.log(results.length);
        setUsers(results);
      } else {
        setUsers([]);
      }
    });
  };

  useMountEffect(() => {
    findUsersForAdmin().then((r: any) => {
      console.log(r);
      if (!isEmpty(r)) {
        const results = Object.values(r) as any;
        console.log(results.length);
        setUsers(results);
      } else {
        setUsers([]);
      }
    });
  });

  const getTableBody = () => {
    if (!loading && !!users && users?.length > 0) {
      return (
        <TableBody>
          {sortUsers(sortingDetails, users).map((user: UserRowInfo) => {
            return (
              <TableRowContainer
                key={"customer-" + user.user_id}
                keyId={"customer-" + user.user_id + "-container-"}
                cells={[
                  {
                    clickEvent: () => viewCustomerSummary(user.user_id, true),
                    content: (
                      <NewTabIconContainer>
                        <MdOpenInNew size={16} />
                      </NewTabIconContainer>
                    ),
                    center: true,
                  },
                  {
                    content: user.first_name,
                    clickEvent: () => viewCustomerSummary(user.user_id, false),
                  },
                  {
                    content: user.last_name,
                    clickEvent: () => viewCustomerSummary(user.user_id, false),
                  },
                  {
                    content: user.email,
                    clickEvent: () => viewCustomerSummary(user.user_id, false),
                  },
                  {
                    content: getDaysAgo(user.last_login_timestamp),
                    clickEvent: () => viewCustomerSummary(user.user_id, false),
                  },
                  {
                    clickEvent: () => viewCustomerSummary(user.user_id, false),
                    content: getDaysAgo(user.created_timestamp),
                  },
                  {
                    content: user.user_id,
                    bold: true,
                    clickEvent: () => viewCustomerSummary(user.user_id, false),
                  },
                ]}
              />
            );
          })}
        </TableBody>
      );
    }
  };

  const getCustomerResultsBlurb = () => {
    if (loading) {
      return (
        <CustomerResultsBlurb>Searching '{lastSearch}'...</CustomerResultsBlurb>
      );
    } else if (users && users?.length === 0) {
      return (
        <CustomerResultsBlurb>
          No results for '{lastSearch}'
        </CustomerResultsBlurb>
      );
    } else if (!users) {
      return (
        <CustomerResultsBlurb>
          Find users by searching above
        </CustomerResultsBlurb>
      );
    }
  };

  const {Modal, openModal} = useModal({
    content: (
      <SearchFilters
        publishFilters={setSearchRequest}
        initialFilters={searchRequest}
      />
    ),
    size: NatModalSize.SMALL,
    enforceHeight: true,
  });

  return (
    <>
      <CustomerHeaderContainer>
        <NatButton
          label={"Back"}
          trackingId={"admin-title-back-button"}
          type={"button"}
          clickEvent={() => to(NavigationPaths.ADMIN)}
          size={NatSize.NORMAL}
          option={StyleOption.SECONDARY_ALT}
          icon={{
            icon: <MdKeyboardBackspace />,
            iconPosition: IconPosition.ONLY,
          }}
        />
        <CustomerHeaderSearchContent onSubmit={searchUsers}>
          <HeaderSearchInput
            spellCheck={false}
            value={searchRequest.searchText}
            id={"admin-customer-search-input"}
            onChange={(e) => {
              const newFilters = {
                searchText: e.target.value,
              } as SearchUsersRequest;
              const mergedFilters = mergeObjects(searchRequest, newFilters);
              setSearchRequest(mergedFilters);
            }}
            placeholder={
              "Search for customers by first name, last name, or email..."
            }
          />
          {users && !loading && users?.length > 0 && false && (
            <CustomerHeaderTitle>
              {users?.length + " customers found"}
            </CustomerHeaderTitle>
          )}
          <NatButton
            label={"Filters"}
            icon={{icon: <TiFilter />, iconPosition: IconPosition.ONLY}}
            type={"button"}
            option={StyleOption.PRIMARY_ALT}
            size={NatSize.NORMAL}
            clickEvent={() => {
              openModal();
            }}
          />
          <NatButton
            label={"Search"}
            id={"admin-customer-search-button"}
            type={"submit"}
            option={StyleOption.PRIMARY_ALT}
            size={NatSize.SMALL}
          />
        </CustomerHeaderSearchContent>
        <AdminHelpIcon link={DOC_LINK_ROOT} />
      </CustomerHeaderContainer>
      <CustomerTableContainer>
        <Table
          fitToWidth={true}
          columnWidths={"3rem 12rem 12rem 1fr 10rem 10rem 1fr"}
        >
          <TableColumnHeaders
            headers={customerTableHeaders}
            sortingOption={{
              details: sortingDetails,
              setSorting: sort,
            }}
          />
          {getTableBody()}
        </Table>
        {getCustomerResultsBlurb()}
      </CustomerTableContainer>
      {Modal}
    </>
  );
};
