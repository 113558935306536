import {isProd} from "./components/_shared/application";

export const DEV_LOGGER = !isProd;
export const SHOW_DEV_TOOLS = false;

export const errorLogger = (e: Error | any) => {
  if (DEV_LOGGER) {
    console.error(e?.message);
  }
};
