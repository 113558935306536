import styled from "styled-components";
import {NatomasButton} from "../../../../../../_shared/generics/button/styles";
import {ICTAOrientationType} from "./index";
import {FONT_FAMILY_MEDIUM, FONT_FAMILY_REGULAR} from "../../../styles/globals";

export const CallToActionSectionContainer = styled.div<{
  mobile?: boolean;
  orientation?: ICTAOrientationType;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.orientation === "left" ? "left" : "center"};
  align-items: ${(props) =>
    props.orientation === "left" ? "start" : "center"};
  text-align: ${(props) => (props.orientation === "left" ? "left" : "center")};
`;

export const CallToActionButtonContainer = styled.div<{
  mobile?: boolean;
  orientation?: ICTAOrientationType;
}>`
  display: flex;
  row-gap: 1rem;
  column-gap: 1rem;
  flex-wrap: wrap-reverse;
  width: 100%;
  margin-bottom: 1rem;
  align-items: ${(props) => (props.orientation === "left" ? "left" : "center")};
  justify-content: ${(props) =>
    props.orientation === "left" ? "left" : "center"};
  ${NatomasButton} {
    width: ${(props) => (props.mobile ? "auto" : "auto")};
  }
`;

export const CallToActionFooterContainer = styled.div<{
  mobile?: boolean;
  orientation?: ICTAOrientationType;
}>`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  width: ${(props) => (props.mobile ? "100%" : "75%")};
  font-size: 0.875rem;
  font-family: ${FONT_FAMILY_REGULAR};
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
  ${NatomasButton} {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CallToActionTitles = styled.div<{
  mobile?: boolean;
  orientation?: ICTAOrientationType;
}>`
  margin: 2rem 0 1rem 0;
  font-size: ${(props) => (props.mobile ? "1rem" : "1.25rem")};
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const FooterText = styled.div<{
  mobile?: boolean;
  orientation?: ICTAOrientationType;
}>`
  margin: 0 0 1rem 0;
  font-size: ${(props) => (props.mobile ? "1rem" : "1rem")};
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const OutOfServiceSelectionStyledDiv = styled.div`
  a {
    color: black;
    text-decoration: underline;
  }
  margin-bottom: 2rem;
`;
