import React from "react";
import SSCRowTemplate from "../SSCRowTemplate";
import SSCRichTextDiv from "./index";
import SSCBlockContainer from "../SSCBlockContainer";

interface SSCRichTextDivRowProps {
  primaryContent: string;
  label?: string;
  otherContent?: string;
}

const SSCRichTextDivRow = (props: SSCRichTextDivRowProps) => {
  const {label, otherContent, primaryContent} = props;

  return (
    <SSCRowTemplate label={label}>
      <SSCRichTextDiv content={primaryContent} />
      {otherContent && <SSCRichTextDiv content={otherContent} />}
    </SSCRowTemplate>
  );
};

export default SSCRichTextDivRow;
