import React, {useMemo} from "react";
import {
  MatrixPageControllerContent,
  MatrixPageControllerSubContent,
  MatrixPageStepperLabel,
} from "./styles";
import {NatButton} from "../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {
  NatGoBackIcon,
  NatLeftCaratIcon,
  NatRightCaratIcon,
} from "../../../../../../_shared/icon/icons";
import {MatrixTablePageProps} from "../MatrixTable";

export const MatrixTableController = (props: MatrixTablePageProps) => {
  const {pageNumber, itemsPerPage, itemTotal, setPageNumber, transpose} = props;

  const leftEnabled = useMemo(() => {
    return pageNumber > 0;
  }, [pageNumber]);

  const rightEnabled = useMemo(() => {
    if (!itemTotal) {
      return false;
    }
    return (pageNumber + 1) * itemsPerPage < itemTotal;
  }, [pageNumber, itemsPerPage, itemTotal]);

  const goBackEnabled = useMemo(() => {
    return pageNumber !== 0 && !rightEnabled;
  }, [pageNumber, rightEnabled]);

  const label: string = useMemo(() => {
    const axis = transpose ? "Cols" : "Rows";
    const total = itemTotal ?? 0;
    if (total === 0) {
      return `No ${axis} Found`;
    }
    let start = pageNumber * itemsPerPage + 1;
    let end = (pageNumber + 1) * itemsPerPage;
    if (end > total) {
      end = total;
    }
    if (start > total) {
      start = total;
    }
    return `${axis} ${start} to ${end} of ${total}`;
  }, [itemTotal, pageNumber, itemsPerPage, transpose]);

  return (
    <MatrixPageControllerContent>
      <MatrixPageControllerSubContent>
        <NatButton
          disabled={!leftEnabled}
          size={NatSize.SMALL}
          label={"left"}
          type={"button"}
          option={StyleOption.PRIMARY_ALT}
          icon={{icon: <NatLeftCaratIcon />, iconPosition: IconPosition.ONLY}}
          clickEvent={() => setPageNumber(pageNumber - 1)}
        />
        <MatrixPageStepperLabel>{label}</MatrixPageStepperLabel>
        {rightEnabled ? (
          <NatButton
            size={NatSize.SMALL}
            label={"right"}
            type={"button"}
            option={StyleOption.PRIMARY_ALT}
            clickEvent={() => setPageNumber(pageNumber + 1)}
            icon={{
              icon: <NatRightCaratIcon />,
              iconPosition: IconPosition.ONLY,
            }}
          />
        ) : (
          <NatButton
            disabled={!goBackEnabled}
            size={NatSize.SMALL}
            label={"go back"}
            type={"button"}
            option={StyleOption.PRIMARY_ALT}
            clickEvent={() => setPageNumber(0)}
            icon={{icon: <NatGoBackIcon />, iconPosition: IconPosition.ONLY}}
          />
        )}
      </MatrixPageControllerSubContent>
    </MatrixPageControllerContent>
  );
};
