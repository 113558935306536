import {
  SHOW_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_COLOR_SWATCHES_PROPERTY_KEY,
  SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_TEXT_DISPLAY_KEY,
  SHOW_FULL_TEXT_PROPERTY_KEY,
  SHOW_MULTI_SECENT_DISPLAY_KEY,
  SHOW_MULTI_SELECT_PROPERTY_KEY,
} from "../../../../../../../../../../../../studio/_shared/constants";

export const getModifierGroupType = (modifierGroup: any) => {
  const properties = modifierGroup?.properties;
  if (!properties) {
    return "default";
  } else if (properties[SHOW_MULTI_SELECT_PROPERTY_KEY]) {
    return SHOW_MULTI_SECENT_DISPLAY_KEY;
  } else if (properties[SHOW_COLOR_SWATCHES_PROPERTY_KEY]) {
    if (properties[SHOW_FULL_TEXT_PROPERTY_KEY]) {
      return SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY;
    }
    return SHOW_COLOR_SWATCHES_DISPLAY_KEY;
  } else if (properties[SHOW_FULL_TEXT_PROPERTY_KEY]) {
    return SHOW_FULL_TEXT_DISPLAY_KEY;
  }
  return "default";
};

export const processTypeChanges = (
  option: string,
  updateEdits: (field: string, value?: any) => void
) => {
  if (option === "default") {
    updateEdits("properties", undefined);
  }
  if (option === "title-and-price") {
    updateEdits("properties", {
      [SHOW_FULL_TEXT_PROPERTY_KEY]: true,
    });
  }
  if (option === "swatch") {
    updateEdits("properties", {[SHOW_COLOR_SWATCHES_PROPERTY_KEY]: true});
  }
  if (option === "embedded-swatch") {
    updateEdits("properties", {
      [SHOW_FULL_TEXT_PROPERTY_KEY]: true,
      [SHOW_COLOR_SWATCHES_PROPERTY_KEY]: true,
    });
  }
  if (option === "multi-select") {
    updateEdits("properties", {
      [SHOW_MULTI_SELECT_PROPERTY_KEY]: true,
      [SHOW_FULL_TEXT_PROPERTY_KEY]: true,
    });
  }
};
