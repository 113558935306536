import {LEGEND_CIRCLE_DIV} from "../constants";
import {
  VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT,
  VILLA_MAP_RED,
} from "../../../../../map/constants";
import {
  NatPopup,
  NatPopupItemElement,
} from "../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import React, {useMemo} from "react";
import {useCurrentUser} from "../../../../../_shared/hooks/useCurrentUser";
import {isMobile} from "../../../../../_shared/navigation";
import styled from "styled-components";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../../../_shared/generics/popup/dropdown/constants";

const LineItemHeight = styled.div`
  font-size: 0.92rem;
`;
export const AreaMapLegend = () => {
  const {isAdmin} = useCurrentUser();
  const {isNatMobile} = usePage();
  const legendContent: NatPopupItemElement[] = useMemo(() => {
    const legendItems = [
      {id: "title", label: "Completed Villa Homes"},
      {
        id: "completed-home",
        label: "Completed home",
        component: (
          <>
            <LEGEND_CIRCLE_DIV
              color={VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT}
              borderColor={VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT}
            />
            <LineItemHeight>Completed home</LineItemHeight>
          </>
        ),
      },
    ];
    if (isAdmin) {
      legendItems.push({
        id: "target-projects",
        label: "Target projects",
        component: (
          <>
            <LineItemHeight>Target projects</LineItemHeight>
            <LEGEND_CIRCLE_DIV
              color={VILLA_MAP_RED}
              borderColor={VILLA_MAP_RED}
            />
          </>
        ),
      });
    }
    return legendItems;
  }, [isAdmin]);
  if (isMobile() || !isAdmin || true) {
    // Disabling the legend for now
    return <></>;
  }
  return (
    <NatPopup
      contentItemArray={legendContent}
      handleClose={() => {}}
      show={false}
      style={{
        TYPE: NatPopupType.MINIMIZE,
        ADDITIONAL_STYLE_OPTIONS: [
          NatPopupStyleOptions.NO_PADDING,
          NatPopupStyleOptions.INITIALLY_MINIMIZED,
          NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE,
        ],
        POPUP_HEIGHT: "12rem",
        popupCSS: {
          width: "247px",
          marginTop: isNatMobile ? "1rem" : "8rem",
          left: "1rem",
        },
      }}
    />
  );
};
