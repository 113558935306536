import React, {useMemo} from "react";
import {NatDropdown} from "../../../../../../../../../../../_shared/generics/popup/dropdown/NatDropdown";
import {getCreateActionProps} from "./helper";
import {IFactoryInventoryStudioEditorPopupPathOptions} from "../PopupContent/interface";
import {
  NatPopupItem,
  NatPopupItemText,
} from "../../../../../../../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {NatSizeType} from "../../../../../../../../../../../_shared/generics/_shared";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../../../../../../../../../../_shared/generics/popup/dropdown/constants";

export enum StudioEditorFieldType {
  MODIFIER = "Modifier",
  MODIFIER_GROUP = "Modifier Group",
  CATEGORY = "Category",
  RENDERING = "Rendering",
}

export enum StudioEditorActionType {
  IMPORT = "Import",
  EDIT = "Edit",
  ADD = "Add",
  DELETE = "Delete",
  MOVE_UP = "Move Up",
  MOVE_DOWN = "Move Down",
}

interface StudioEditorFieldAddButtonProps {
  type: StudioEditorFieldType;
  rootPath: IFactoryInventoryStudioEditorPopupPathOptions;
  setActionEvent?: (e: any, value: any) => void;
}

const StudioEditorFieldAddButton = (props: StudioEditorFieldAddButtonProps) => {
  const {type, rootPath, setActionEvent} = props;
  const fieldCreateOption: NatPopupItem = {
    id: "create",
    label: "Create",
  };

  const fieldImportOption: NatPopupItem = {
    id: "import",
    label: "Import",
  };

  const dropdownOptionCallback = (option: NatPopupItem) => {
    if (setActionEvent) {
      if (option?.label === fieldCreateOption.label && createActionProps) {
        setActionEvent(
          {},
          {
            path: createActionProps,
            type: type,
            action: StudioEditorActionType.ADD,
          }
        );
      } else if (option?.label === fieldImportOption.label) {
        setActionEvent(
          {},
          {
            path: rootPath,
            type: type,
            action: StudioEditorActionType.IMPORT,
          }
        );
      }
    }
  };

  const createActionProps:
    | IFactoryInventoryStudioEditorPopupPathOptions
    | undefined = useMemo(() => {
    return getCreateActionProps(type, rootPath);
  }, [rootPath, type]);

  let dropdownOptions: NatPopupItemText[] = [
    {...fieldCreateOption, callback: dropdownOptionCallback},
    {...fieldImportOption, callback: dropdownOptionCallback},
  ];
  if (type === StudioEditorFieldType.RENDERING) {
    // Renderings are coupled with categories!
    // There is no renderings import without category import
    dropdownOptions = [
      {...fieldCreateOption, callback: dropdownOptionCallback},
    ];
  }

  return (
    <NatDropdown
      style={NatDropdownStyles[DropdownStyles.PRIMARY][NatSizeType.SMALL]}
      toggleButtonOrLabel={`Add ${type}`}
      contentItemArray={dropdownOptions}
      trackingId={`add-${type}-menu`}
    />
  );
};

export default StudioEditorFieldAddButton;
