import React, {Dispatch} from "react";
import {MapboxGeoJSONFeature} from "mapbox-gl";
import * as turf from "@turf/turf";
import {BBox} from "@turf/turf";
import polylabel from "polylabel";
import {
  IAddressDetails,
  ICoordinatesRequest,
  IGLMapState,
  shallowEquals,
} from "@natomas-org/core";
import {animateMapHome, setGLMapState} from "../../../../map/slices/mapSlice";
import {executeRequest} from "../../../../../database/firebase/api";
import {setSearchAddress} from "../../../../_shared/slices/AddressSlice";

export const curve = 2;

export const visuallyLoadNewParcel = (
  feature: MapboxGeoJSONFeature,
  map: React.MutableRefObject<any>,
  loadedAddressDetails: React.MutableRefObject<any>,
  dispatch: Dispatch<any>
) => {
  if (!feature) {
    return;
  }
  const bounds = turf.bbox(feature);
  if (!feature.geometry || !(feature as any).geometry.coordinates) {
    return;
  }
  const centerCoordinates = polylabel(
    (feature as any).geometry.coordinates,
    1.0
  );
  if (centerCoordinates.length > 1) {
    const request: ICoordinatesRequest = {
      lat: centerCoordinates[1].toString(),
      long: centerCoordinates[0].toString(),
    };
    return executeRequest("/mapping/v1/reverse-geocode", request).then(
      (address: IAddressDetails | null) => {
        if (address) {
          visuallySearchNewParcelFromAddress(
            map,
            dispatch,
            address,
            bounds,
            loadedAddressDetails
          ).then((r) => {});
        }
      }
    );
  }
};
export const visuallySearchNewParcelFromAddress = async (
  map: React.MutableRefObject<any>,
  dispatch: Dispatch<any>,
  address: IAddressDetails,
  bounds: BBox,
  loadedAddressDetails?: React.MutableRefObject<any>
) => {
  if (
    loadedAddressDetails &&
    shallowEquals(loadedAddressDetails.current, address)
  ) {
    dispatch(animateMapHome({}));
  } else {
    if (!address) {
      return;
    }
    dispatch(
      setSearchAddress({
        full_address: address.full_address,
        street_number: address.street_number,
        street: address.street,
        city: address.city,
        state: address.state,
        zip: address.zip,
        county: address.county,
        country: address.country,
        latitude: Number(address.latitude),
        longitude: Number(address.longitude),
        geohash: undefined,
      })
    );
    dispatch(
      setGLMapState({
        mLat: Number(address.latitude),
        mLng: Number(address.longitude),
      } as IGLMapState)
    );
  }
};
