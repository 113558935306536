import React from "react";
import {useFactoryLine} from "../../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {FactoryViewWrapper} from "./FactoryInfoView/styles";
import FactoryManagementHeader from "../_shared/FactoryManagementHeader";
import {LoadingPanel} from "../../../../../_shared/generics/loading-panel";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";
import {FactoryView} from "../_shared/FactoryViewSelector";
import {FactoryInfoView} from "./FactoryInfoView";
import FactoryInventoryView from "./FactoryInventoryView";
import {ProjectPriceMode} from "@natomas-org/core";

interface FactoryViewsProps {
  priceMode: ProjectPriceMode;
  id?: string;
}

const FactoryViews = (props: FactoryViewsProps) => {
  const {id, priceMode} = props;
  const adminView = useSelector((state: IStore) => state.administration.view);
  const factorySummary = useFactoryLine({factoryId: id});

  const title =
    factorySummary?.info?.name ??
    factorySummary?.info?.globalTitle ??
    factorySummary?.details?.internal_title;

  if (!factorySummary) {
    return <LoadingPanel show={true} relativePositioning={true} />;
  }
  return (
    <FactoryViewWrapper>
      {!adminView && (
        <>
          <FactoryManagementHeader />
          <FactoryInfoView factorySummary={factorySummary} />
        </>
      )}
      {adminView === FactoryView.INVENTORY && (
        <FactoryInventoryView
          priceMode={priceMode}
          inventory={factorySummary?.inventory}
          factoryId={factorySummary?.info?.id}
          factoryTitle={title}
        />
      )}
    </FactoryViewWrapper>
  );
};

export default FactoryViews;
