import React, {useEffect, useRef, useState} from "react";
import {BLACK} from "../../../../../../_shared/colors";
import {InfoSetItemTableRow} from "../../../../table";
import {RowStringInputBox, RowStringTextAreaBox} from "./styles";

export const RowStringInput = (props: {
  onCommitChange: (arg: string) => void;
  onChange: any;
  otherRows: InfoSetItemTableRow[];
  isValidChange: any;
  value: string;
  originalValue: string;
  placeholder?: string;
  type?: "input" | "textarea";
}) => {
  const {
    placeholder,
    onCommitChange,
    onChange,
    isValidChange,
    otherRows,
    originalValue,
    value,
    type,
  } = props;
  const inputRef = useRef(null);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (!isValidChange(value)) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [value, otherRows, isValidChange]);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      // @ts-ignore
      inputRef.current.blur();
    }
  };

  const saveLocalChange = () => {
    if (hasError) {
      onCommitChange(originalValue);
    } else {
      onCommitChange(value);
    }
  };

  if (type === "textarea") {
    return (
      <RowStringTextAreaBox
        cols={25}
        rows={2}
        placeholder={placeholder}
        autoCorrect={"off"}
        autoComplete={"on"}
        autoCapitalize={"on"}
        spellCheck={true}
        ref={inputRef}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onBlur={saveLocalChange}
      />
    );
  }
  return (
    <RowStringInputBox
      placeholder={placeholder}
      autoCorrect={"off"}
      autoComplete={"on"}
      autoCapitalize={"on"}
      spellCheck={true}
      ref={inputRef}
      style={{
        color: hasError ? "red" : BLACK,
      }}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      onBlur={saveLocalChange}
    />
  );
};

export const sanitizeString = (v: string) => {
  return v?.trim();
};
