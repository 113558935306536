import {
  FilterType,
  ICatalogFilter,
} from "../../../../_shared/catalog/filter/types";
import {
  changeFilter,
  deleteFilterByType,
  getBedroomFilter,
  getProductParcelFitFilter,
  getSquareFootageFilter,
  tryFilter,
} from "../../../../_shared/catalog/filter/logic";

export const applyDefaultFilters = (
  county: string,
  state: string,
  filters: ICatalogFilter[],
  bedroomCount?: number,
  maxSqFt?: number
) => {
  //  Remove existing
  deleteFilterByType(FilterType.SquareFootage);
  const defaultFilters = getDefaultFilters(
    county,
    state,
    bedroomCount,
    maxSqFt
  );
  defaultFilters?.forEach((filter) => {
    if (
      filter.type === FilterType.SquareFootage &&
      filters.find(
        (filter: ICatalogFilter) => filter.type === FilterType.SquareFootage
      )
    ) {
      changeFilter(filter);
    } else {
      tryFilter(filter, filters);
    }
  });
};

export const getDefaultFilters = (
  county: string,
  state: string,
  bedroomCount?: number,
  maxSqFt?: number
): ICatalogFilter[] => {
  const arrayToReturn: ICatalogFilter[] = [];

  //  Attempt to add bedroom filters
  if (bedroomCount) {
    for (let i = 1; i <= bedroomCount; i++) {
      const bedroomString = i === 1 ? "bedroom" : "bedrooms";
      arrayToReturn.push(getBedroomFilter(i, i + " " + bedroomString));
    }
    // Max bedrooms with den (n + 1)
    if (bedroomCount < 3) {
      arrayToReturn.push(
        getBedroomFilter(bedroomCount + 1, bedroomCount + 1 + " bedrooms")
      );
    }
  }
  //  Add square foot filter
  if (maxSqFt) {
    arrayToReturn.push(
      getSquareFootageFilter("up to " + maxSqFt + " ft²", {
        low: 0,
        high: maxSqFt + 1,
      })
    );
  }
  return arrayToReturn;
};

export const toggleProductParcelFitFilter = (
  filters: ICatalogFilter[],
  label: string
) => {
  const newFilter = getProductParcelFitFilter(label, filters);
  if (!newFilter.value) {
    deleteFilterByType(newFilter.type);
  } else {
    tryFilter(newFilter, filters);
  }
};

export const isDefaultHidden = (f: ICatalogFilter) => {
  return (
    f.type === FilterType.ProductLine || f.type === FilterType.ProductCategory
  );
};
