import React from "react";
import {
  ILinkButton,
  NatButton,
  NatLink,
  NatomasButtonProps,
} from "../../../../../../_shared/generics/button";
import {
  CallToActionButtonContainer,
  CallToActionFooterContainer,
  CallToActionSectionContainer,
  CallToActionTitles,
  FooterText,
} from "./styles";
import {NatSize, StyleOption} from "../../../../../../_shared/generics/_shared";
import {usePage} from "../../../../../../_shared/hooks/usePage";
import {useDynamicValue} from "../../../../../../_shared/hooks/useDynamicValue";

export type ICTAOrientationType = "center" | "left";
export interface ITextComponent {
  text: string;
  style: any;
}

export interface CallToActionSectionProps {
  titles: string[];
  titleComponents?: ITextComponent[];
  buttons: NatomasButtonProps[];
  footerTexts: JSX.Element[];
  footerLinks: ILinkButton[];
  orientation?: ICTAOrientationType;
}

const CallToActionSectionTitles = (props: {
  titles: string[];
  titleComponents?: ITextComponent[];
  orientation?: ICTAOrientationType;
}) => {
  const {titles, orientation, titleComponents} = props;
  const {isNatMobile} = usePage();
  if (titleComponents && titleComponents?.length > 0) {
    return (
      <CallToActionTitles mobile={isNatMobile} orientation={orientation}>
        {titleComponents.map((e, index) => (
          <div style={e.style} key={"dashboard-cta-section-title-" + index}>
            {e.text}
          </div>
        ))}
      </CallToActionTitles>
    );
  } else if (!titles || titles.length === 0) {
    return <></>;
  } else {
    return (
      <CallToActionTitles mobile={isNatMobile} orientation={orientation}>
        {!isNatMobile
          ? titles.map((t: string, index: number) => (
              <div key={"dashboard-cta-section-title-" + index}>{t}</div>
            ))
          : titles.join(" ")}
      </CallToActionTitles>
    );
  }
};

const CallToActionSectionButtons = (props: {
  buttons: NatomasButtonProps[];
  orientation?: ICTAOrientationType;
}) => {
  const maxWidth = useDynamicValue({
    forFour: "100vw",
    forEight: "100vw",
    forTwelve: "35vw",
    forSixteen: "25vw",
  });
  const {buttons, orientation} = props;
  const {isNatMobile} = usePage();
  if (!buttons || buttons.length === 0) return <></>;
  else {
    const size = isNatMobile ? NatSize.SMALL : NatSize.NORMAL;
    return (
      <CallToActionButtonContainer
        mobile={isNatMobile}
        orientation={orientation}
      >
        {buttons.map((b: NatomasButtonProps, index: number) => {
          if (b.component) {
            return b.component;
          }
          return (
            <NatButton
              style={{
                maxWidth,
              }}
              key={"dashboard-cta-section-button-" + index}
              {...b}
              size={size}
            />
          );
        })}
      </CallToActionButtonContainer>
    );
  }
};

const CallToActionSectionFooter = (props: {
  footerTexts: JSX.Element[];
  footerLinks: ILinkButton[];
  orientation?: ICTAOrientationType;
}) => {
  const {footerTexts, footerLinks, orientation} = props;
  const {isNatMobile} = usePage();

  return (
    <CallToActionFooterContainer mobile={isNatMobile} orientation={orientation}>
      {footerTexts &&
        footerTexts.length > 0 &&
        footerTexts.map((t: JSX.Element, index: number) => (
          <FooterText key={"dashboard-cta-section-footer-" + index}>
            {t}
          </FooterText>
        ))}
      {footerLinks &&
        footerLinks.length > 0 &&
        footerLinks.map((l: ILinkButton, index: number) => (
          <NatLink
            size={NatSize.SMALL}
            option={StyleOption.PRIMARY_LINK}
            key={"dashboard-cta-section-link-" + index}
            {...l}
          />
        ))}
    </CallToActionFooterContainer>
  );
};

export const CallToActionSection = (props: CallToActionSectionProps) => {
  const {
    titles,
    buttons,
    footerTexts,
    footerLinks,
    orientation,
    titleComponents,
  } = props;
  return (
    <CallToActionSectionContainer orientation={orientation}>
      <CallToActionSectionTitles
        titles={titles}
        titleComponents={titleComponents}
        orientation={orientation}
      />
      <CallToActionSectionButtons buttons={buttons} orientation={orientation} />
      <CallToActionSectionFooter
        orientation={orientation}
        footerTexts={footerTexts}
        footerLinks={footerLinks}
      />
    </CallToActionSectionContainer>
  );
};
