import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Address, IAddressDetails, IAreaDetails} from "@natomas-org/core";
import {AddressSliceType, getInitialAddressState} from "./helper";
import {ILocationFromIP} from "@natomas-org/service";

const addressSlice = createSlice({
  name: "address",
  initialState: getInitialAddressState(),
  reducers: {
    setIPAddress: (state, action: PayloadAction<ILocationFromIP>) => {
      const {
        security,
        city,
        postal_code,
        latitude,
        longitude,
        region_iso_code,
      } = action.payload;
      // Do not store if they are using VPN - Unreliable
      if (!security?.is_vpn) {
        state.ip = Address.dataToAddress({
          city: city,
          zip: postal_code,
          latitude: latitude,
          longitude: longitude,
          state: region_iso_code,
        });
      }
    },
    setSearchAddress: (
      state,
      action: PayloadAction<IAddressDetails | undefined>
    ) => {
      state[AddressSliceType.PROPERTY_SEARCH] = action.payload;
    },
    setAreaAddress: (
      state,
      action: PayloadAction<IAreaDetails | undefined>
    ) => {
      state[AddressSliceType.AREA_SEARCH] = action.payload;
    },
    setProjectAddress: (
      state,
      action: PayloadAction<IAddressDetails | undefined>
    ) => {
      state.project = action.payload;
    },
  },
});

export const addressReducer = addressSlice.reducer;
export const {
  setIPAddress,
  setAreaAddress,
  setSearchAddress,
  setProjectAddress,
} = addressSlice.actions;
