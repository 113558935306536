import React, {Dispatch, SetStateAction, useMemo} from "react";
import {Breadcrumb, BreadcrumbItem} from "../../../../path/styles";
import {SearchAllStateOptions} from "../index";
import {useImageFinder} from "../../../../../hooks/useImageFinder";
import {SearchAllBreadcrumbWrapper} from "../styles";
import {useProduct} from "../../../../../hooks/useProductCatalog/useProduct";

export interface SearchAllBreadcrumbProps {
  mode: SearchAllStateOptions;
  setMode: Dispatch<SetStateAction<SearchAllStateOptions>>;
  setProductGroupId: Dispatch<SetStateAction<string | undefined>>;
  productGroupId?: string;
  productId?: string;
  categoryId?: string;
}

export const SearchAllBreadcrumb = (props: SearchAllBreadcrumbProps) => {
  const {
    mode,
    setMode,
    categoryId,
    productGroupId,
    productId,
    setProductGroupId,
  } = props;
  const {factoryDetails, product} = useProduct(productId, productGroupId);
  const {getCategoryLabel} = useImageFinder();

  const label = useMemo(() => {
    return getCategoryLabel(categoryId);
  }, [categoryId, getCategoryLabel]);

  return (
    <SearchAllBreadcrumbWrapper>
      <Breadcrumb isMobile={false} separator={"/"}>
        <BreadcrumbItem
          clickable={true}
          onClick={() => {
            setMode(SearchAllStateOptions.ROOT);
            setProductGroupId(undefined);
          }}
        >
          Product Groups
        </BreadcrumbItem>
        {!!factoryDetails && (
          <BreadcrumbItem
            clickable={true}
            onClick={() => {
              setMode(SearchAllStateOptions.PRODUCT_GROUP);
            }}
          >
            {factoryDetails?.internal_title ?? factoryDetails?.title}
          </BreadcrumbItem>
        )}
        {(mode === SearchAllStateOptions.PRODUCTS ||
          mode === SearchAllStateOptions.PRODUCT) && (
          <BreadcrumbItem
            clickable={true}
            onClick={() => {
              setMode(SearchAllStateOptions.PRODUCTS);
            }}
          >
            Products
          </BreadcrumbItem>
        )}
        {(mode === SearchAllStateOptions.CATEGORIES ||
          mode === SearchAllStateOptions.CATEGORY) && (
          <BreadcrumbItem
            clickable={true}
            onClick={() => {
              setMode(SearchAllStateOptions.CATEGORIES);
            }}
          >
            Categories
          </BreadcrumbItem>
        )}
        {mode === SearchAllStateOptions.MODIFIERS && (
          <BreadcrumbItem
            clickable={true}
            onClick={() => {
              setMode(SearchAllStateOptions.MODIFIERS);
            }}
          >
            Modifiers
          </BreadcrumbItem>
        )}
        {mode === SearchAllStateOptions.PRODUCT && (
          <BreadcrumbItem
            clickable={true}
            onClick={() => {
              setMode(SearchAllStateOptions.PRODUCT);
            }}
          >
            {!!product && product?.title}
          </BreadcrumbItem>
        )}
        {mode === SearchAllStateOptions.CATEGORY && (
          <BreadcrumbItem
            clickable={true}
            onClick={() => {
              setMode(SearchAllStateOptions.CATEGORY);
            }}
          >
            {label}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </SearchAllBreadcrumbWrapper>
  );
};
