import React from "react";
import {CategoryHeaderContainer, CurrentStepTitle} from "./styles";
import {StudioPageTopProps} from "../StudioCategoryShell/helper";

export const StudioCategoryTop = (props: StudioPageTopProps) => {
  const {isMobile, section, title, step, structure, product} = props;

  return (
    <CategoryHeaderContainer isMobile={isMobile}>
      <CurrentStepTitle>
        <div>{title}</div>
      </CurrentStepTitle>
    </CategoryHeaderContainer>
  );
};
