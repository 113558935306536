import {ICustomerInfo} from "@natomas-org/core";
import useActiveCustomer from "./useCustomer/useActiveCustomer";

export const useCurrentCustomer = (): {
  user_id: string;
  actions: any;
  customer: ICustomerInfo | null;
} => {
  const {id, info, actions} = useActiveCustomer();
  return {
    customer: info ?? null,
    actions: actions,
    user_id: id ?? "",
  };
};
