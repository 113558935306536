import {
  useAllPublishedAndRoutedProducts,
  useCatalogProducts,
} from "../../../../_shared/hooks/useProductCatalog/useProducts";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {hardResetFilters} from "../../../../_shared/slices/CatalogSlice/CatalogSessionSlice";
import {HelpText} from "../styles";
import React from "react";
import {useDispatch} from "react-redux";
import no_results_house from "../../../assets/no-results-house.svg";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../_shared/generics/label/NatLabel";
import {
  BLACK,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
} from "../../../../_shared/colors";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {motion} from "framer-motion";

export const NoHomesResetFiltersCTA = (props: {
  imageHeightRem?: number;
  factoryId?: string;
  style?: React.CSSProperties;
}) => {
  const filteredProducts = useCatalogProducts({onlyFactory: props.factoryId});
  const unfilteredCatalogProducts = useAllPublishedAndRoutedProducts();
  const {eligibility} = useCatalogSession();
  const dispatch = useDispatch();
  if (!eligibility || unfilteredCatalogProducts?.length < 1) {
    return (
      <HelpText style={props.style}>
        <Loader />
      </HelpText>
    );
  }
  if (filteredProducts?.length > 0) {
    return null;
  }
  return (
    <HelpText style={props.style}>
      <img
        src={no_results_house}
        style={{height: `${props.imageHeightRem ?? 12}rem`}}
        alt={"Site-Work-Complete"}
      />
      <br />
      <NatLabel
        label={"We couldn't find matching results"}
        type={NatLabelType.H3}
        weight={NatLabelWeight.MEDIUM}
        style={{color: BLACK}}
      />
      <NatLabel
        label={"Try editing or resetting your filters to see more homes!"}
        type={NatLabelType.P1}
      />
      <NatButton
        size={NatSize.NORMAL}
        label={"Reset all filters"}
        clickEvent={() => {
          dispatch(hardResetFilters());
        }}
        option={StyleOption.PRIMARY_ALT}
        style={{width: "fit-content"}}
      />
    </HelpText>
  );
};

const loadingContainer = {
  width: "4rem",
  height: "4rem",
  display: "flex",
  justifyContent: "space-around",
};
const loadingCircle = {
  display: "block",
  width: "1rem",
  height: "1rem",
  border: `${VILLA_APPLE_GREEN} 1px solid`,
  backgroundColor: VILLA_APPLE_GREEN_LIGHT_1,
  borderRadius: "0.5rem",
};

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: "0%",
  },
  end: {
    y: "60%",
  },
};
const loadingCircleTransition = {
  duration: 0.4,
  yoyo: Infinity,
  ease: "easeInOut",
};

const Loader = () => {
  return (
    <div>
      <div className="fixed  w-full min-h-screen z-50 bg-black opacity-30" />
      <div className="flex fixed w-full justify-center items-center h-screen">
        <motion.div
          style={loadingContainer}
          variants={loadingContainerVariants}
          initial="start"
          animate="end"
        >
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
          <motion.span
            style={loadingCircle}
            variants={loadingCircleVariants}
            transition={loadingCircleTransition}
          />
        </motion.div>
      </div>
    </div>
  );
};
