import {executeRequest} from "../../../../../database/firebase/api";
import {
  BUDGET_SUMMARY_SECTION_TYPE,
  BudgetEntry,
  BudgetEntryProps,
} from "@natomas-org/core";

export const fetchEditingBudgetTable = async (props: {
  projectId: string;
  section: BUDGET_SUMMARY_SECTION_TYPE | string;
}): Promise<null | BudgetEntry[]> => {
  return fetchGenericBudgetTable({
    ...props,
    editing: true,
  });
};
export const fetchVisualBudgetTable = async (props: {
  projectId: string;
  section: BUDGET_SUMMARY_SECTION_TYPE | string | null;
}): Promise<null | BudgetEntry[]> => {
  return fetchGenericBudgetTable({
    ...props,
    editing: false,
  });
};
const fetchGenericBudgetTable = async (props: {
  projectId: string;
  section: BUDGET_SUMMARY_SECTION_TYPE | string | null;
  editing: boolean;
}): Promise<null | BudgetEntry[]> => {
  const endpoint = props.editing
    ? "/price/v1/admin-budget"
    : "/price/v1/budget";
  return executeRequest(endpoint, {
    ...props,
    includeTotal: true,
  })
    .then((response: {data: BudgetEntryProps[]}) => {
      console.log("response", response);
      return response?.data?.map((entry: BudgetEntryProps) => {
        return new BudgetEntry(entry);
      });
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
