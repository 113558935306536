import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IPropertyData} from "@natomas-org/core";

export interface IPropertyDataSlice {
  publicPropertyData: IPropertyData | undefined | null;
  projectPropertyData: IPropertyData | undefined | null;
}
const getInitialState = (): IPropertyDataSlice => {
  return {
    projectPropertyData: undefined,
    publicPropertyData: undefined,
  };
};

const propertyDataSlice = createSlice({
  name: "propertyData",
  initialState: getInitialState(),
  reducers: {
    // Property data
    setPublicPropertyData: (state, action: PayloadAction<IPropertyData>) => {
      state.publicPropertyData = action.payload;
    },
    resetPublicPropertyData: (state) => {
      state.publicPropertyData = undefined;
    },
    setProjectPropertyData: (state, action: PayloadAction<IPropertyData>) => {
      state.projectPropertyData = action.payload;
    },
    resetProjectPropertyData: (state) => {
      state.projectPropertyData = undefined;
    },
  },
});

export const propertyDataReducer = propertyDataSlice.reducer;
export const {setPublicPropertyData, setProjectPropertyData} =
  propertyDataSlice.actions;
