import React, {useMemo} from "react";
import {ProjectsTableContainer} from "./styles";
import {
  TableColumnHeaders,
  TableRowContainer,
} from "../../../../_shared/components/AdminTable";
import {
  Table,
  TableBody,
} from "../../../../_shared/components/AdminTable/styles";
import {
  ITableHeader,
  ITableSortingDetails,
  TableHeaderCategory,
  useTable,
} from "../../../../_shared/components/AdminTable/table";
import {sortProjects} from "../../../CustomerDatabaseView/tabs/CustomersTable/helpers";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
import {
  Address,
  ICustomerInfo,
  ProjectPriceMode,
  USER_COLLECTION_KEY,
} from "@natomas-org/core";
import {setCurrentProject} from "../../../../../_shared/project";
import {NewTabIconContainer} from "../../../../_shared/styles/AdminStyles";
import {MdOpenInNew} from "react-icons/md";
import {ADMIN_CUSTOMER_PATH} from "../../../../../../paths";
import {ERROR_PRIMARY} from "../../../../../_shared/colors";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import {AiFillStar, AiOutlineStar} from "react-icons/ai";
import {Utilities} from "../../../../../../database/firebase";
import useCustomerProjects from "../../../../../_shared/hooks/useProject/useCustomerProjects";
import useActivitySliceDispatcher from "../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import CustomerProjectProductCell from "./CustomerProjectProductCell";
import {useProject} from "../../../../../_shared/hooks/useProject/useProject";

// TODO B2B Archiveability has not been adapted for the B2B changes as of now it is deprecated
// Sorting on these columns is not implemented
const projectsTableHeaders: ITableHeader[] = [
  {
    label: "",
    column: "new_tab",
    category: TableHeaderCategory.NULL,
  },
  // {
  //   label: "",
  //   column: "is_archived",
  //   category: TableHeaderCategory.NULL,
  // },
  {
    label: "Default",
    column: "is_default",
    category: TableHeaderCategory.NULL,
  },
  {
    label: "Address",
    column: "address",
    category: TableHeaderCategory.STRING,
  },
  {
    label: "Product(s)",
    column: "products",
    category: TableHeaderCategory.NULL,
  },
  {
    label: "Pricing Mode",
    column: "price_mode",
    category: TableHeaderCategory.NULL,
  },
  {
    label: "Cart",
    column: "cart",
    category: TableHeaderCategory.NULL,
  },
  {
    label: "Locked",
    column: "locked",
    category: TableHeaderCategory.NULL,
  },
];

function TableRows(props: {
  sortingDetails: ITableSortingDetails;
  projects: any[];
  viewCustomerProject: (pid: string, newTab?: boolean) => void;
  customer: ICustomerInfo;
  user_id: string;
}) {
  const {sortingDetails, customer, user_id, viewCustomerProject, projects} =
    props;
  return (
    <TableBody>
      {sortProjects(sortingDetails, projects).map(
        (project: any, index: number) => {
          return (
            <TableRow
              key={"project-row-" + project.id}
              projectId={project.id}
              userId={user_id}
              viewCustomerProject={viewCustomerProject}
              index={index}
              customer={customer}
            />
          );
        }
      )}
    </TableBody>
  );
}

const TableRow = (props: {
  projectId: string;
  viewCustomerProject: (pid: string, newTab?: boolean) => void;
  customer: ICustomerInfo;
  userId: string;
  index: number;
}) => {
  const {projectId, customer, userId, viewCustomerProject, index} = props;
  const {isCartEnabled, address, isProjectLocked, projectPriceMode} =
    useProject(projectId);
  const cells = useMemo(() => {
    return [
      {
        clickEvent: () => viewCustomerProject(projectId, true),
        content: (
          <NewTabIconContainer>
            <MdOpenInNew size={16} />
          </NewTabIconContainer>
        ),
        center: true,
      },
      // {
      //   clickEvent: () => {
      //     window.alert(
      //       project?.isArchived
      //         ? "This project is currently archived, and hidden from the customer."
      //         : "This project is currently NOT archived, and is visible to the customer."
      //     );
      //   },
      //   content: (
      //     <NewTabIconContainer>
      //       {project?.isArchived ? (
      //         <BsArchiveFill color={DESTRUCTIVE_FILL} size={16} />
      //       ) : (
      //         <BsArchive size={16} />
      //       )}
      //     </NewTabIconContainer>
      //   ),
      //   center: true,
      // },
      {
        clickEvent: () => {
          const userRef = Utilities.collection(USER_COLLECTION_KEY);
          return Utilities.setDocumentWithMerge(userRef, userId, {
            default_project_id: projectId,
          });
        },

        content: (
          <NewTabIconContainer>
            {customer.default_project_id === projectId ? (
              <AiFillStar color={ERROR_PRIMARY} size={16} />
            ) : (
              <AiOutlineStar size={16} />
            )}
          </NewTabIconContainer>
        ),
        center: true,
      },
      {
        clickEvent: () => viewCustomerProject(projectId),
        content: address ? Address.getFullAddress(address) : "Unknown",
      },
      {
        clickEvent: () => viewCustomerProject(projectId),
        content: <CustomerProjectProductCell projectId={projectId} />,
      },
      {
        clickEvent: () => viewCustomerProject(projectId),
        content:
          projectPriceMode === ProjectPriceMode.DTC
            ? "DTC"
            : projectPriceMode === ProjectPriceMode.CostPlus
            ? "CostPlus"
            : "Unknown",
      },
      {
        clickEvent: () => viewCustomerProject(projectId),
        content: isCartEnabled ? "Yes" : "No",
        center: true,
      },
      {
        clickEvent: () => viewCustomerProject(projectId),
        content: isProjectLocked ? "Yes" : "No",
        center: true,
      },
    ];
  }, [
    customer.default_project_id,
    projectId,
    address,
    isCartEnabled,
    isProjectLocked,
    viewCustomerProject,
    userId,
  ]);
  return (
    <TableRowContainer
      key={"project-row-" + index}
      keyId={"project-row-" + index + "-container-"}
      cells={cells}
    />
  );
};

export const CustomerProjectsTable = () => {
  const {customer, user_id} = useCurrentCustomer();
  const {setActiveProjectById} = useActivitySliceDispatcher();
  const {projects} = useCustomerProjects(user_id);
  const {sortingDetails, sort} = useTable(projectsTableHeaders);
  const {to} = useNavigation();

  const viewCustomerProject = (pid: string, newTab?: boolean) => {
    if (newTab) {
      window.open(
        window.location.origin +
          ADMIN_CUSTOMER_PATH +
          "?uid=" +
          user_id +
          "&pid=" +
          pid
      );
    } else {
      setCurrentProject(pid);
      setActiveProjectById(pid);
      to(NavigationPaths.ADMIN_CUSTOMER_PROJECT, user_id, pid);
    }
  };

  const projectArray = useMemo(() => Object.values(projects ?? {}), [projects]);

  if (!customer) {
    return null;
  }

  return (
    <ProjectsTableContainer>
      <Table fitToWidth={true} columnWidths={"3rem 5rem 2fr 1fr 1fr 4rem 5rem"}>
        <TableColumnHeaders
          headers={projectsTableHeaders}
          sortingOption={{
            details: sortingDetails,
            setSorting: sort,
          }}
        />
        <TableRows
          sortingDetails={sortingDetails}
          projects={projectArray}
          viewCustomerProject={viewCustomerProject}
          customer={customer}
          user_id={user_id}
        />
      </Table>
    </ProjectsTableContainer>
  );
};
