import {Utilities} from "../../index";
import {getFactoryLineVersionRef} from "../refs";

export const saveFactoryLineInformation = (
  factoryLineId: string,
  versionId: string,
  payload: any
) => {
  const docRef = getFactoryLineVersionRef(factoryLineId, versionId);
  if (docRef) {
    return Utilities.updateOrCreateDocumentInDB(docRef, payload);
  }
};
