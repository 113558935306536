import {useFactoryLine} from "./useFactoryLine/useFactoryLine";
import {useCatalogSession} from "./useCatalogSession/useCatalogSession";
import {useMemo} from "react";

export const useProduct = (productId?: string, factoryId?: string) => {
  const {selectedProductGroupId, selectedProductGroupVersionId} =
    useCatalogSession();
  const versionId: string | undefined = useMemo(() => {
    return selectedProductGroupId === factoryId
      ? selectedProductGroupVersionId ?? undefined
      : undefined;
  }, [selectedProductGroupId, factoryId, selectedProductGroupVersionId]);

  const {details, products, inventory} = useFactoryLine({factoryId, versionId});
  const product =
    products?.[productId ?? "NULL"] ?? inventory?.[productId ?? "NULL"];
  return {
    factoryDetails: details,
    product,
  };
};
