import React from "react";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../../_shared/generics/page/components/PageContainer";
import {PATH_Z_INDEX} from "../../../../_shared/styles";
import {GRANITE, WHITE} from "../../../../_shared/colors";
import {FilterDropdownDisplayType} from "../../../../_shared/catalog/filter/types";
import {SortDropdown} from "../Filters/SortDropdown/SortDropdown";
import {FiltersRow} from "../Filters/FiltersRow";
import styled from "styled-components";
import {CatalogPath} from "../CatalogPath";

export const FilterOrder = [
  FilterDropdownDisplayType.UnitPrice,
  FilterDropdownDisplayType.SquareFoot,
  FilterDropdownDisplayType.BedAndBath,
  FilterDropdownDisplayType.Toggles,
];
const FilterBarContainer = styled.div`
  height: ${(props) => props.theme?.Filters?.filterHeight ?? "2.75rem"};
  margin-top: ${(props) => props.theme?.Filters?.marginPaddingTop ?? undefined};
  margin-bottom: ${(props) =>
    props.theme?.Filters?.marginPaddingBottom ?? undefined};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

// TODO Extract this to a separate component
export const Filters = (props: {
  filtersCountToDisplay?: number;
  RIGHT_ALIGN_LAST_FILTER?: boolean;
}) => {
  return (
    <FilterBarContainer>
      <SortDropdown />
      <SortFilterDividerBreak />
      <FiltersRow
        filterTypes={FilterOrder}
        filtersCountToDisplay={props.filtersCountToDisplay}
        rightAlignLastFilter={props.RIGHT_ALIGN_LAST_FILTER}
      />
    </FilterBarContainer>
  );
};

export const MapCatalogBar = (props: {
  offset?: string;
  hideSort?: boolean;
  hideFilter?: boolean;
  isSticky?: boolean;
  filtersCountToDisplay?: number;
  RIGHT_ALIGN_LAST_FILTER?: boolean;
}) => {
  const {isSticky} = props;
  if (!isSticky) {
    return <CatalogPath showMainView={true} border={false} inventory={false} />;
  } else {
    return (
      <PageContainer
        backgroundColor={WHITE}
        stickyOptions={{
          offset: props.offset,
          mode: PageContainerStickyMode.MODAL,
          zIndex: PATH_Z_INDEX,
        }}
      >
        {!props.hideFilter && <Filters {...props} />}
      </PageContainer>
    );
  }
};

const SortFilterDividerBreak = styled.div`
  color: ${GRANITE};
  padding-left: ${(props) =>
    props.theme?.Filters?.gapBetweenFilters ?? "0.75rem"};
`;
