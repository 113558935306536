import {useSelector} from "react-redux";
import {IStore} from "../../_shared/slices/types/Store";
import {IPardotIntakeFormInput} from "@natomas-org/core";

export const useUTMInputs = () => {
  const utm = useSelector((state: IStore) => state.utm);
  const {
    page_source,
    utm_source,
    utm_adgroup,
    utm_term,
    utm_placement,
    utm_medium,
    utm_campaign,
    utm_content,
    key,
  } = utm;

  return [
    {inputType: "text", inputName: "page_source", inputValue: page_source},
    {inputType: "text", inputName: "utm_adgroup", inputValue: utm_adgroup},
    {inputType: "text", inputName: "utm_campaign", inputValue: utm_campaign},
    {inputType: "text", inputName: "utm_content", inputValue: utm_content},
    {inputType: "text", inputName: "utm_medium", inputValue: utm_medium},
    {inputType: "text", inputName: "utm_placement", inputValue: utm_placement},
    {inputType: "text", inputName: "utm_source", inputValue: utm_source},
    {inputType: "text", inputName: "utm_term", inputValue: utm_term},
    {inputType: "text", inputName: "key", inputValue: key},
  ] as IPardotIntakeFormInput[];
};
