import React, {useEffect, useState} from "react";
import Select from "react-select";
import {GenericInputContentContainer} from "../../admin/_shared/styles/AdminStyles";
import {ValueAndLabel} from "../../admin/_shared/components/AdminComponentFactory/constants";
import {HEADER_Z_INDEX} from "../styles";
import styled from "styled-components";

function convertOptionsToMap(options: ValueAndLabel[]) {
  if (options == null || options.length < 1) {
    return null;
  }

  let propertyObject: any = {};
  options.forEach((option) => {
    propertyObject[option.value] = option.label;
  });

  return propertyObject;
}

function cleanUpImportedValue(
  value: ValueAndLabel[],
  options: ValueAndLabel[]
) {
  const optionMap = convertOptionsToMap(options);

  return value.map((entry) => {
    if (entry.label == null) {
      return {
        value: entry.value,
        label: optionMap[entry.value],
      };
    }
    return entry;
  });
}

export const SelectionOptionsInput = (props: {
  value: ValueAndLabel[];
  handleChange: (value: any) => void;
  options: ValueAndLabel[];
  enabled: boolean;
}) => {
  const {value, handleChange, options, enabled} = props;
  const cleanedUpValues = cleanUpImportedValue(value, options);
  const [values, setValues] = useState(cleanedUpValues);

  useEffect(() => {
    setValues(cleanedUpValues);
  }, [values]);

  return (
    <GenericInputContentContainer>
      <Select
        isDisabled={!enabled}
        value={cleanedUpValues}
        isMulti
        onChange={handleChange}
        options={options}
      />
    </GenericInputContentContainer>
  );
};

function convertOptionArrayForSelect(options: ValueAndLabel[] | string[]) {
  if (options == null || options.length < 1) {
    return [];
  }
  if (typeof options[0] === "string") {
    return options.map((option: any) => {
      return {
        value: option as string,
        label: option as string,
      };
    });
  }

  return options as ValueAndLabel[];
}

function sanitizeMultiSelectValue(value: string[], options: ValueAndLabel[]) {
  if (value == null || value.length < 1) {
    return [];
  }

  return value.map((valueId) => {
    return options.find((option) => option.value === valueId);
  });
}

export const SelectionOptionsInputWithArrays = (props: {
  value: string[];
  handleChange: (value: string[]) => void;
  options: ValueAndLabel[] | string[];
  disabled: boolean;
  limitHeight: boolean;
  isSingleSelect: boolean;
}) => {
  const {value, handleChange, options, disabled, limitHeight, isSingleSelect} =
    props;
  const cleanedOptions = convertOptionArrayForSelect(options);
  const cleanedValue = sanitizeMultiSelectValue(value, cleanedOptions);

  return (
    <GenericInputContentContainer>
      <SelectionWrapper>
        <Select
          isDisabled={disabled}
          value={cleanedValue}
          isMulti={!isSingleSelect}
          onChange={(selections) => {
            if (selections == null) {
              handleChange([]);
              return;
            }
            if (!(selections instanceof Array)) {
              handleChange([selections.value]);
              return;
            }
            const sanitizedSelectionIds = selections
              .map((selection) => selection?.value)
              .filter((entry) => {
                return entry != null;
              });

            handleChange(sanitizedSelectionIds as string[]);
          }}
          options={cleanedOptions}
          maxMenuHeight={limitHeight ? 48 : undefined}
        />
      </SelectionWrapper>
    </GenericInputContentContainer>
  );
};

const SelectionWrapper = styled.div`
  div {
    div {
      div {
        div {
          div {
            z-index: ${HEADER_Z_INDEX - 1};
          }
        }
      }
    }
  }
`;
