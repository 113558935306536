// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  FloatingDevRow,
  FloatingDivContainer,
  NatomasMapContainer,
  NatomasMapWrapper,
} from "./styles";
import {MAP_BOX_TOKEN} from "../../../mapping/views/theme/constants";
import _ from "lodash";
import {
  getPointFeature,
  getPolygonFeature,
  getPolygonSetFeature,
  NatomasMapFeatureState,
} from "./formatting";
import {getOtherFeaturesPolygons, initializeMap} from "./initialize";
import {NatomasMapDebugWindow} from "./debug";
import {NatomasMapFeature, NatomasMappingFeatureZones} from "@natomas-org/core";
import {Position} from "geojson";

mapboxgl.accessToken = MAP_BOX_TOKEN;

const getCoordinates = (e: any): Position => {
  const longitude: number = Number(e.lngLat.lng.toFixed(4));
  const latitude: number = Number(e.lngLat.lat.toFixed(4));
  return [longitude, latitude];
};

const removeIndexedElement = (
  elements: any[],
  indexToRemove: number
): any[] => {
  return elements.filter((c: [number, number], i) => indexToRemove !== i);
};

/* Notes:
 * feature requires an id to edit and save
 * feature always requires label
 * feature heavily recommend requiring type
 * saveFeature always required
 * */
export const NatomasMap = (props: {
  feature: NatomasMapFeature;
  saveFeature?: (f: NatomasMapFeature) => void;
  allowEditing?: boolean;
  otherFeatures?: NatomasMapFeature[];
}) => {
  const {allowEditing, feature, saveFeature, otherFeatures} = props;
  const {zones, label, id} = feature;
  const savedZones: NatomasMappingFeatureZones | null = zones ?? null;
  // Map
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-122);
  const [lat, setLat] = useState(37.5);
  const [zoom, setZoom] = useState(5.5);
  const [defaultCursor, setDefaultCursor] = useState<"grab" | "crosshair">(
    "grab"
  );
  const [cursor, setCursor] = useState<
    "grab" | "crosshair" | "move" | "grabbing"
  >("grab");
  // Edit mode
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditingLabel, setIsEditingLabel] = useState<boolean>(false);
  const [localLabel, setLocalLabel] = useState<string>(
    label ?? "Unnamed Feature"
  );
  const [polygonIndex, setPolygonIndex] = useState<number | null>(null);
  const [polygonSet, setPolygonSet] = useState<Position[][][]>(
    savedZones?.coordinates ?? []
  );
  // Create Mode
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<Position[]>([]);
  const [target, setTarget] = useState<any>(null);
  const [debugValue, setDebugValue] = useState<NatomasMapFeature | null>(null);

  const isSavePossible = useMemo(() => {
    if (!savedZones?.coordinates && polygonSet?.length === 0) {
      return false;
    }
    return (
      allowEditing &&
      isEditing &&
      !isCreating &&
      id &&
      !_.isEqual(savedZones?.coordinates, polygonSet)
    );
  }, [
    allowEditing,
    id,
    isCreating,
    isEditing,
    polygonSet,
    savedZones?.coordinates,
  ]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });
    // DO NOT ADD DEPENDENCIES!
    // eslint-disable-next-line
  }, []);

  const reset = () => {
    exitCreateMode();
    toggleEditingMode(false);
    setPolygonSet(savedZones?.coordinates ?? []);
  };

  const reinsert = (arr: any, index: number, newItem: any) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index),
  ];

  const savePolygon = () => {
    if (polygonIndex !== null) {
      setPolygonSet((current) => {
        const r = reinsert(current, polygonIndex, [
          [...coordinates, coordinates[0]],
        ]);
        return r;
      });
      setPolygonIndex(null);
    } else {
      setPolygonSet(
        (current) =>
          [...current, [[...coordinates, coordinates[0]]]] as Position[][][]
      );
    }
    exitCreateMode();
  };

  const save = () => {
    if (saveFeature) {
      const f: NatomasMapFeature = {
        ...feature,
        zones: {
          type: "MultiPolygon",
          coordinates: polygonSet,
        },
      };
      saveFeature(f);
      setDebugValue(f);
    }
  };

  const discardPolygon = () => {
    setCoordinates([]);
  };

  const toggleEditingMode = (forceState?: boolean) => {
    if (!allowEditing) {
      return;
    }
    setIsEditing((isCurrentlyEditing) => {
      if (isCurrentlyEditing && isCreating) {
        setIsCreating(false);
        setCoordinates([]);
      }
      return forceState || !isEditing;
    });
  };

  const exitCreateMode = () => {
    setIsCreating(false);
    setTarget(null);
    setDefaultCursor("grab");
    setCursor("grab");
    discardPolygon();
  };

  const enterCreateMode = () => {
    setIsCreating(true);
    setTarget(null);
    setDefaultCursor("crosshair");
    setCursor("crosshair");
  };

  const captureCoordinates = useCallback((coordinates: Position): void => {
    setCoordinates((current) => [...current, coordinates] as Position[]);
  }, []);

  const getCanvas = useCallback(() => {
    // @ts-ignore
    return map.current.getCanvasContainer();
  }, []);

  const findTarget = useCallback(
    (e: any) => {
      if (target) {
        return target;
      }
      // Set a UI indicator for dragging.
      // @ts-ignore
      const features = map.current
        .queryRenderedFeatures(e.point, {
          layers: ["bound-points"],
        })
        .filter((feature: any) => feature?.properties?.id === "bound-point");
      if (features?.length > 0) {
        // Only get first feature
        const t = features[0];
        if (t) {
          setTarget(t);
          // @ts-ignore
          map.current.setFeatureState(
            {source: "polygon-coordinates", id: t.id},
            {hover: true}
          );
          return t;
        }
      }
      return null;
    },
    [target]
  );

  const onCreatePolygonPoint = useCallback(
    (e: any) => {
      captureCoordinates(getCoordinates(e));
    },
    [captureCoordinates]
  );

  const onMapDrag = useCallback(() => {
    // @ts-ignore
    setLng(map.current.getCenter().lng.toFixed(4));
    // @ts-ignore
    setLat(map.current.getCenter().lat.toFixed(4));
    // @ts-ignore
    setZoom(map.current.getZoom().toFixed(2));
  }, []);

  const onMapDragStart = useCallback(() => {
    setCursor("grabbing");
  }, []);

  const onMapDragEnd = useCallback(() => {
    setCursor(defaultCursor);
  }, [defaultCursor]);

  const onMouseEnterPoint = useCallback(() => {
    setCursor("move");
  }, []);

  const onMouseLeavePoint = useCallback(() => {
    if (!target) {
      setCursor(defaultCursor);
    }
  }, [defaultCursor, target]);

  const onMovePoint = useCallback((e: any, target: any) => {
    if (target) {
      const mouseCoordinates: Position = getCoordinates(e);
      // Only move one at a time
      const featureIndex = target?.properties?.index ?? null;
      if (featureIndex !== null) {
        setCoordinates((current) => {
          const newCoordinates: Position[] = [...current];
          newCoordinates[featureIndex] = mouseCoordinates;
          return newCoordinates;
        });
      }
    }
  }, []);

  const onMouseUp = useCallback(
    (moveFunction: any, target: any) => {
      // @ts-ignore
      setCursor(defaultCursor);
      // Unbind mouse/touch events
      // @ts-ignore
      map.current.setFeatureState(
        {source: "polygon-coordinates", id: target.id},
        {hover: false}
      );
      setTarget(null);
      // @ts-ignore
      map.current.off("mousemove", moveFunction);
    },
    [defaultCursor]
  );

  const onMouseDown = useCallback(
    (e: any) => {
      // Prevent the default map drag behavior.
      e.preventDefault();
      const target = findTarget(e);
      if (target) {
        const moveFunction = (e: any) => {
          onMovePoint(e, target);
        };
        // @ts-ignore
        map.current.on("mousemove", moveFunction);
        // @ts-ignore
        map.current.once("mouseup", () => {
          onMouseUp(moveFunction, target);
        });
      }
    },
    [findTarget, onMouseUp, onMovePoint]
  );

  // Drawing the cursor correctly
  useEffect(() => {
    getCanvas().style.cursor = cursor;
  }, [cursor, getCanvas]);

  // Getting GeoJson

  useEffect(() => {
    if (!map.current) {
      return;
    } // wait for map to initialize
    else {
      // @ts-ignore
      map.current.on("move", onMapDrag);
      // @ts-ignore
      map.current.on("movestart", onMapDragStart);
      // @ts-ignore
      map.current.on("moveend", onMapDragEnd);
      if (isCreating) {
        // @ts-ignore
        map.current.on("click", onCreatePolygonPoint);
        // @ts-ignore
        map.current.on("mouseenter", "bound-points", onMouseEnterPoint);
        // @ts-ignore
        map.current.on("mouseleave", "bound-points", onMouseLeavePoint);
        // @ts-ignore
        map.current.on("mousedown", "bound-points", onMouseDown);
        // @ts-ignore
        map.current.on("mouseup", "bound-points", () => {
          // @ts-ignore
          map.current.off("mousedown", "bound-points", onMouseDown);
        });
      }
    }
    // @ts-ignore
    return () => {
      // @ts-ignore
      map.current.off("move", onMapDrag);
      // @ts-ignore
      map.current.off("movestart", onMapDragStart);
      // @ts-ignore
      map.current.off("moveend", onMapDragEnd);
      // @ts-ignore
      map.current.off("click", onCreatePolygonPoint);
      // @ts-ignore
      map.current.off("mouseenter", "bound-points", onMouseEnterPoint);
      // @ts-ignore
      map.current.off("mouseleave", "bound-points", onMouseLeavePoint);
      // @ts-ignore
      map.current.off("mousedown", "bound-points", onMouseDown);
      // @ts-ignore
      map.current.off("mouseup", "bound-points", () => {
        // @ts-ignore
        map.current.off("mousedown", "bound-points", onMouseDown);
      });
    };
  }, [
    isCreating,
    captureCoordinates,
    findTarget,
    getCanvas,
    onCreatePolygonPoint,
    onMapDrag,
    onMouseDown,
    onMouseEnterPoint,
    onMouseLeavePoint,
    onMouseUp,
    onMovePoint,
    onMapDragStart,
    onMapDragEnd,
  ]);

  useEffect(() => {
    const onLoad = () => initializeMap(map, savedZones, otherFeatures);
    // @ts-ignore
    map.current.on("load", onLoad);
    return () => {
      // @ts-ignore
      map.current.off("load", onLoad);
    };
    // DO NOT ADD TO DEPENDENCY ARRAY - this runs once on load regardless of dependency changes
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPolygonSet(!!zones ? zones?.coordinates : []);
  }, [zones]);

  useEffect(() => {
    setLocalLabel(label ?? "Unnamed Feature");
  }, [label]);

  useEffect(() => {
    // @ts-ignore
    const target = map.current.getSource("polygon-coordinates");
    if (target) {
      let features = [];
      const points = isCreating
        ? coordinates?.map((coordinate: Position, index: number) => {
            return getPointFeature(coordinate, index);
          })
        : [];
      if (points?.length > 0) {
        features.push(...points);
      }
      const createModePolygon =
        isCreating && coordinates?.length > 0
          ? getPolygonFeature(
              [[...coordinates, coordinates[0]]],
              NatomasMapFeatureState.CREATING
            )
          : null;
      if (createModePolygon) {
        features.push(createModePolygon);
      }
      if (polygonSet) {
        const polygons = getPolygonSetFeature(
          {coordinates: polygonSet},
          NatomasMapFeatureState.SAVED
        );
        features.push(...polygons);
      }
      if (otherFeatures) {
        const others = getOtherFeaturesPolygons(otherFeatures);
        features.push(...others);
      }
      target.setData({
        type: "FeatureCollection",
        features: [...features],
      });
    }
  }, [coordinates, isCreating, polygonSet, otherFeatures]);

  return (
    <NatomasMapWrapper>
      {!!feature && !isCreating && (
        <FloatingDivContainer position={"top-right"}>
          {!isEditingLabel && (
            <FloatingDevRow>
              {localLabel?.length > 0 ? localLabel : ""}
            </FloatingDevRow>
          )}
          {isEditingLabel && (
            <FloatingDevRow>
              <input
                onChange={(e) => setLocalLabel(e.target.value)}
                defaultValue={localLabel}
                placeholder={"Feature Name"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingLabel(false);
                  }
                }}
              />
            </FloatingDevRow>
          )}
          {allowEditing && (
            <FloatingDevRow
              hasAction={allowEditing}
              onClick={() => toggleEditingMode()}
            >
              Mode - {isEditing ? "Edit" : "View"}
            </FloatingDevRow>
          )}
          {allowEditing &&
            isEditing &&
            !isCreating &&
            id &&
            polygonSet?.map((polygon, index) => {
              return (
                <FloatingDevRow
                  key={`polygon-row-${index}`}
                  hasAction={true}
                  onClick={() => {
                    setPolygonIndex(index);
                    setPolygonSet((current) =>
                      removeIndexedElement(current, index)
                    );
                    // @ts-ignore
                    map.current.setFeatureState(
                      {source: "polygon-coordinates", id: 1000 + index},
                      {hover: false}
                    );
                    /*
                   We automatically add in the last element,
                   as the end and start are the same in GeoJSON.
                   So before we 'import' the polygon into edit mode,
                   remove the last.
                   */
                    setCoordinates(polygon[0].slice(0, -1));
                    enterCreateMode();
                  }}
                  onMouseEnter={() => {
                    // @ts-ignore
                    map.current.setFeatureState(
                      {source: "polygon-coordinates", id: 1000 + index},
                      {hover: true}
                    );
                  }}
                  onMouseLeave={() => {
                    // @ts-ignore
                    map.current.setFeatureState(
                      {source: "polygon-coordinates", id: 1000 + index},
                      {hover: false}
                    );
                  }}
                >
                  - Section {index + 1}, ({polygon.length - 1} Vertices)
                </FloatingDevRow>
              );
            })}
          {allowEditing && isEditing && !isCreating && id && (
            <FloatingDevRow hasAction={true} onClick={() => enterCreateMode()}>
              Create New Section
            </FloatingDevRow>
          )}
          {isSavePossible && (
            <>
              <FloatingDevRow hasAction={true} onClick={save}>
                Confirm Changes
              </FloatingDevRow>
              <FloatingDevRow hasAction={true} onClick={reset}>
                Abandon Changes
              </FloatingDevRow>
            </>
          )}
        </FloatingDivContainer>
      )}

      {isCreating && (
        <FloatingDivContainer position={"bottom-right"}>
          <FloatingDevRow>
            Coordinate Count: {coordinates?.length}
          </FloatingDevRow>
          <FloatingDevRow hasAction={true} onClick={reset}>
            Abandon Changes
          </FloatingDevRow>
          {coordinates?.length > 2 && (
            <FloatingDevRow hasAction={true} onClick={() => savePolygon()}>
              Save Polygon
            </FloatingDevRow>
          )}
          {coordinates?.length > 0 && (
            <FloatingDevRow hasAction={true} onClick={() => setCoordinates([])}>
              Clear All Points
            </FloatingDevRow>
          )}
          <FloatingDevRow hasAction={true} onClick={() => exitCreateMode()}>
            {coordinates?.length > 0 ? "Delete Polygon" : "Cancel"}
          </FloatingDevRow>
          {coordinates?.map((coordinate: Position, index: number) => {
            return (
              <FloatingDevRow
                key={`row-${index}`}
                hasAction={true}
                onClick={() =>
                  setCoordinates((current) =>
                    removeIndexedElement(current, index)
                  )
                }
                onMouseEnter={() => {
                  // @ts-ignore
                  map.current.setFeatureState(
                    {source: "polygon-coordinates", id: index},
                    {hover: true}
                  );
                }}
                onMouseLeave={() => {
                  // @ts-ignore
                  map.current.setFeatureState(
                    {source: "polygon-coordinates", id: index},
                    {hover: false}
                  );
                }}
              >
                - {index + 1}: Longitude: {coordinate[0]} | Latitude:{" "}
                {coordinate[1]}
              </FloatingDevRow>
            );
          })}
        </FloatingDivContainer>
      )}
      <NatomasMapDebugWindow
        cursor={cursor}
        debugValue={debugValue}
        defaultCursor={defaultCursor}
        isCreating={isCreating}
        isEditing={isEditing}
        feature={feature}
        lat={lat}
        lng={lng}
        zoom={zoom}
      />
      <NatomasMapContainer ref={mapContainer} />
    </NatomasMapWrapper>
  );
};
