import {
  BreakdownContainer,
  BreakdownTitle,
  BreakdownValue,
  ProjectCostBreakdownContainer,
  ProjectCostCardWrapper,
  ProjectCostContainer,
  ProjectTotalCostContainer,
  UnitSiteBreakdownContainer,
} from "../styles";
import React, {useMemo} from "react";
import {usePage} from "../../../../_shared/hooks/usePage";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {
  CartItemType,
  getPriceCostText,
  priceTextFromMicros,
  ProjectPriceMode,
} from "@natomas-org/core";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {useCartItems} from "../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {useIsAdminSystem} from "../../../../_shared/hooks/useIsAdminSystem";
import {useProjectBudget} from "../../../../_shared/hooks/useProjectBudget/useProjectBudget";
import {NatButton} from "../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {NatRefreshIcon} from "../../../../_shared/icon/icons";

export const ProjectCostCard = (props: {
  hideDescription?: boolean;
  removeMarginAndBorder?: boolean;
}) => {
  const {isNatMobile} = usePage();
  const {site, projectPriceMode, cartItemIds} = useActiveProject();
  const cartItems = useCartItems(cartItemIds);
  const {isAdmin} = useCurrentUser();
  const isAdminSystem = useIsAdminSystem();
  const {projectBudget, totalUnitQuantity, refreshBudget} = useProjectBudget();
  let isVisible = site?.visible ?? false;
  const loadingString = "-";
  isVisible = isVisible || (isAdmin && isAdminSystem);
  let label =
    "We are currently working on your project proposal and will have it ready for you as soon as possible.";
  if (isVisible) {
    label = `Presenting your Project Proposal! This total estimated budget was
        assembled by our expert team and is broken into three different parts:
        Preconstruction, Site Work, and unit cost. Assuming there are no
        changes to design, we’ll spend the duration of this call discussing
        Preconstruction and Site Work. As a reminder, this is strictly a cost
        estimate based on historical data and real projects in your area. We’ll
        cover how we go about firming up these estimates as we go through the
        budget and timeline. Let’s get started!`;
  }

  const breakdownItems: {title: string; value: string}[] | null =
    useMemo(() => {
      const totalSiteWorkMicros = projectBudget?.site_work_total?.price;
      const totalSiteWorkLabel = "Site Work";
      const totalSiteWorkValueString =
        totalSiteWorkMicros !== undefined && totalSiteWorkMicros !== null
          ? priceTextFromMicros(totalSiteWorkMicros, "accurate")
          : loadingString;
      const priceCostText = getPriceCostText(projectPriceMode);
      let totalUnitCostLabel = `Unit ${priceCostText}`;
      if (totalUnitQuantity > 1) {
        totalUnitCostLabel = `${totalUnitQuantity} Units ${priceCostText}`;
      }
      const totalUnitCostValueString =
        projectBudget?.home_design_total?.price !== undefined &&
        projectBudget?.home_design_total?.price !== null
          ? priceTextFromMicros(
              projectBudget?.home_design_total?.price,
              "accurate"
            )
          : loadingString;
      let totalPreConLabel = "Preconstruction";
      const totalPreConValueString =
        projectBudget?.precon_total?.price !== undefined &&
        projectBudget?.precon_total?.price !== null
          ? priceTextFromMicros(projectBudget?.precon_total?.price, "accurate")
          : loadingString;
      const dtcArray = [
        {title: totalPreConLabel, value: totalPreConValueString},
        {
          title: totalSiteWorkLabel,
          value: totalSiteWorkValueString,
        },
        {title: totalUnitCostLabel, value: totalUnitCostValueString},
      ];
      if (projectPriceMode === ProjectPriceMode.DTC) {
        return dtcArray;
      }
      let totalPhase1AndPhaseTwoMicros = 0;
      cartItems?.forEach((cartItem) => {
        if (
          !cartItem?.id ||
          cartItem?.type !== CartItemType.ADU ||
          !cartItem?.quantity
        ) {
          return;
        }
        const phase1Payment =
          (projectBudget?.phase_1_micros ?? 0) * cartItem.quantity;
        const phase2Payment =
          (projectBudget?.phase_2_micros ?? 0) * cartItem.quantity;
        totalPhase1AndPhaseTwoMicros += phase1Payment;
        totalPhase1AndPhaseTwoMicros += phase2Payment;
      });
      const totalPhase1AndPhaseTwoValueString = priceTextFromMicros(
        totalPhase1AndPhaseTwoMicros,
        "accurate"
      );
      return [
        ...dtcArray,
        {
          title: "Villa Phase 1 & Phase 2",
          value: totalPhase1AndPhaseTwoValueString,
        },
      ];
    }, [
      cartItems,
      projectBudget?.home_design_total?.price,
      projectBudget?.phase_1_micros,
      projectBudget?.phase_2_micros,
      projectBudget?.precon_total?.price,
      projectBudget?.site_work_total?.price,
      projectPriceMode,
      totalUnitQuantity,
    ]);
  if (!breakdownItems) {
    return <></>;
  }
  let totalCostTitle = "Total Estimated Budget";
  if (projectPriceMode === ProjectPriceMode.CostPlus) {
    totalCostTitle = "Total Project Estimate";
  }
  const totalBudgetString =
    projectBudget?.total?.price !== undefined &&
    projectBudget?.total?.price !== null
      ? priceTextFromMicros(projectBudget?.total?.price, "accurate")
      : loadingString;
  const removeBorder = props.removeMarginAndBorder || isAdminSystem;
  return (
    <ProjectCostCardWrapper removeBorder={removeBorder}>
      <NatLabel
        hidden={props.hideDescription || isVisible}
        type={NatLabelType.P1}
        label={label}
      />
      <ProjectCostContainer
        removeBorder={removeBorder}
        isNatMobile={isNatMobile}
        hidden={!isVisible}
      >
        <ProjectTotalCostContainer
          removeMarginAndBorder={removeBorder}
          isNatMobile={isNatMobile}
        >
          <BreakdownContainer isNatMobile={isNatMobile}>
            <BreakdownTitle isNatMobile={isNatMobile}>
              {totalCostTitle}
            </BreakdownTitle>
            <BreakdownValue displayLarge>{totalBudgetString}</BreakdownValue>
          </BreakdownContainer>
        </ProjectTotalCostContainer>
        <ProjectCostBreakdownContainer isNatMobile={isNatMobile}>
          <UnitSiteBreakdownContainer isNatMobile={isNatMobile}>
            {breakdownItems.map((item, index) => {
              return (
                <BreakdownContainer isNatMobile={isNatMobile} key={index}>
                  <BreakdownTitle isNatMobile={isNatMobile}>
                    {item.title}
                  </BreakdownTitle>
                  <BreakdownValue>{item.value}</BreakdownValue>
                </BreakdownContainer>
              );
            })}
          </UnitSiteBreakdownContainer>
        </ProjectCostBreakdownContainer>
        {isAdmin && isAdminSystem && (
          <div
            style={{
              width: "fit-content",
              flexWrap: "nowrap",
            }}
          >
            <NatButton
              label={"Refresh Budget"}
              icon={{
                icon: <NatRefreshIcon />,
                iconPosition: IconPosition.ONLY,
              }}
              clickEvent={refreshBudget}
              spinnerEnabled={true}
              size={NatSize.XSMALL}
              option={StyleOption.SECONDARY}
            />
          </div>
        )}
      </ProjectCostContainer>
    </ProjectCostCardWrapper>
  );
};
