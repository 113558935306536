import React, {useEffect, useState} from "react";
import {loadInventoryUnitConfiguration} from "../../../../../../database/firebase/api/catalog";
import {getUpgradesList} from "../../../../../_shared/snapshot";
import {InventoryUnitDetailsContainer} from "./styles";
import {InstantQuotePriceCard} from "../../../../../portal/views/InstantQuoteView/components/InstantQuotePriceCard";
import {NatButton} from "../../../../../_shared/generics/button";
import {viewInventoryUnitInStudio} from "../../../../../_shared/hooks/useStudio/helper";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {
  getPricingForUnitAndPriceMode,
  priceTextFromMicros,
  ProjectPriceMode,
} from "@natomas-org/core";
import {ProductInfoContainer} from "../../styles";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import {useSelectedProduct} from "../../../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import InventoryProgramDetails from "../../../../../_shared/catalog/views/product/inventoryProgramDetails";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";

export const InventoryUpgradeDetails = (props: {
  priceMode: ProjectPriceMode;
}) => {
  const product = useSelectedProduct();
  const {to} = useNavigation();
  const {projectOverheadProfit} = useActiveProject();
  const [upgradeDetails, setUpgradeDetails] = useState<any[] | undefined>(
    undefined
  );
  const [upgradePriceString, setUpgradePrice] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (!!product?.id && !!product?.inventory_info && !upgradeDetails) {
      loadInventoryUnitConfiguration(product?.id).then((configuration: any) => {
        setUpgradeDetails(
          getUpgradesList(
            {
              snapshot: configuration.configurationSnapshot,
            },
            props.priceMode
          )
        );
        setUpgradePrice(
          priceTextFromMicros(
            getPricingForUnitAndPriceMode(configuration, props.priceMode, {
              addSalesTax: true,
              opFraction: projectOverheadProfit,
            })?.upgradesPriceMicros ?? 0,
            "accurate"
          )
        );
      });
    }
  }, [product, props.priceMode, upgradeDetails]);

  if (!!upgradeDetails && !!upgradePriceString) {
    return (
      <ProductInfoContainer id={"inventory-unit-upgrades-section"}>
        <InventoryUnitDetailsContainer>
          <InventoryProgramDetails />
          <InstantQuotePriceCard
            price={upgradePriceString}
            priceDescription={"upgrade value"}
            lineItems={upgradeDetails}
            button={
              <NatButton
                label={"View included upgrades"}
                id={"nat-button-view-included-upgrades"}
                clickEvent={() => viewInventoryUnitInStudio(to, product)}
                type={"button"}
                option={
                  upgradeDetails?.length === 0
                    ? StyleOption.PRIMARY
                    : StyleOption.SECONDARY_ALT
                }
              />
            }
            displayCheck={false}
            noPadding={true}
          />
        </InventoryUnitDetailsContainer>
      </ProductInfoContainer>
    );
  } else {
    return <></>;
  }
};
