import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUserSlice} from "./types/Store";
import firebase from "firebase/app";

const getInitialState = (): IUserSlice => {
  return {
    userLeadInfo: {},
    userActions: {},
    userPermissions: {},
    userAuthInfo: {},
  };
};

const userSlice = createSlice({
  name: "user",
  initialState: getInitialState(),
  reducers: {
    storeUserLeadInfo: (state, action) => {
      const {userInfo, uid} = action.payload;
      state.userLeadInfo[uid] = userInfo;
    },
    storeUserActions: (state, action) => {
      const {actions, uid} = action.payload;
      if (state.userActions[uid] == null) {
        state.userActions[uid] = {};
      }

      Object.keys(actions).forEach((eventId) => {
        state.userActions[uid][eventId] = actions[eventId];
      });
    },
    storeUserPermissions: (state, action) => {
      const {permissions, uid} = action.payload;
      state.userPermissions[uid] = permissions ?? {};
    },
    storeBulkUserPermissions: (state, action) => {
      state.userPermissions = Object.assign(
        {},
        state.userPermissions,
        action.payload
      );
    },
    storeUserAuthInfo: (
      state,
      action: PayloadAction<{uid: string; authInfo: firebase.UserInfo}>
    ) => {
      const {authInfo, uid} = action.payload;
      state.userAuthInfo[uid] = authInfo ?? {};
    },
  },
});

export const userReducer = userSlice.reducer;
export const {
  storeUserLeadInfo,
  storeUserActions,
  storeUserPermissions,
  storeBulkUserPermissions,
  storeUserAuthInfo,
} = userSlice.actions;
