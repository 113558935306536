import {Utilities} from "../../index";
import {NOTES_COLLECTION, ProjectDestination} from "@natomas-org/core";

export const toggleProjectNoteVisibility = async (
  projectId: string,
  noteId: string
) => {
  const notesRef = getProjectNoteRef(projectId, noteId);
  const notesDoc = await Utilities.getDocData(notesRef);

  const data = {
    hidden: notesDoc?.hidden ? !notesDoc.hidden : true,
  };
  return Utilities.setDocWithMerge(notesRef, data);
};

const getProjectNoteRef = (projectId: string, noteId?: string) => {
  if (noteId != null) {
    return Utilities.collection(ProjectDestination.NATOMAS)
      .doc(projectId)
      .collection(NOTES_COLLECTION)
      .doc(noteId);
  } else {
    return Utilities.collection(ProjectDestination.NATOMAS)
      .doc(projectId)
      .collection(NOTES_COLLECTION)
      .doc();
  }
};
