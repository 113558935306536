import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {useEffect} from "react";
import {setShowConfirmation} from "../../../../_shared/slices/ConfigurationSlice";
import {useDispatch} from "react-redux";

export const StudioWarningModal = (props: {canSave?: boolean}) => {
  const {canSave} = props;
  const {hasWarning, resetWarning, setWarning, getWarningModal} =
    useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (canSave && !hasWarning) {
      dispatch(setShowConfirmation(false));
      setWarning({
        header: "Want to save your design first?",
        description:
          "Looks like you've made some changes, would you like to save your design before you leave?",
        customCancelLabel: "Cancel",
        customContinueLabel: "Continue without saving",
      });
    } else if (!canSave && hasWarning) {
      resetWarning();
    }
  }, [canSave, hasWarning]);

  return getWarningModal();
};
