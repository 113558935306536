import React, {useMemo} from "react";
import {HCPComponentTitle} from "../../../styles";
import {DocumentDetailsPreview} from "../../../../../../_shared/generics/documents/components/DocumentDetailsPreview";
import {useActiveProjectDocuments} from "../../../../../../_shared/hooks/useProject/useActiveProjectDocuments";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {
  IDocumentDetails,
  SPECIALIZED_PDF_DOCUMENT_INFO,
} from "@natomas-org/core";
import {StyleOption} from "../../../../../../_shared/generics/_shared";

export const PortalDocumentsView = () => {
  const {projectDocuments} = useActiveProjectDocuments();
  const {id} = useActiveProject();

  const documents = useMemo(() => {
    return projectDocuments?.filter((doc) => {
      return (
        [...SPECIALIZED_PDF_DOCUMENT_INFO].find((info) => {
          return (
            info?.document_id &&
            doc?.document_id &&
            info?.document_id === doc?.document_id
          );
        }) == null
      );
    });
  }, [projectDocuments]);
  const allDocumentsHidden = useMemo(() => {
    let allHidden = true;
    projectDocuments?.forEach((document: IDocumentDetails) => {
      if (!document?.hidden) {
        allHidden = false;
      }
    });
    return allHidden;
  }, [projectDocuments]);
  if (
    !projectDocuments ||
    projectDocuments?.length === 0 ||
    allDocumentsHidden
  ) {
    return (
      <div style={{textAlign: "left", marginTop: "2rem"}}>
        <HCPComponentTitle includePadding={true}>Documents</HCPComponentTitle>
        <h4>No documents yet.</h4>
      </div>
    );
  }

  return (
    <>
      <div style={{textAlign: "center"}}>
        <NatLabel
          label={"No documents uploaded"}
          type={NatLabelType.P1}
          hidden={projectDocuments?.length > 0}
        />
      </div>
      {documents.map((document: IDocumentDetails, index: any) => {
        if (document?.hidden || !document) {
          return null;
        }
        return (
          <DocumentDetailsPreview
            styleOption={StyleOption.PRIMARY}
            hidePreview={true}
            document={document}
            documents={documents}
            key={`${document?.document_id}${index}`}
            projectId={id}
          />
        );
      })}
    </>
  );
};
