import {IFactoryInventoryEditorStructure} from "../../FactoryInventoryCatalogEditor/formik/structure";
import {FactoryInventoryPricingEditorKey} from "./interfaces";
import {FactoryInventoryInputType} from "../../_shared/FactoryInventoryInput";

export const FactoryInventoryPricingEditorStructure: IFactoryInventoryEditorStructure =
  {
    [FactoryInventoryPricingEditorKey.UNIT_PRICE]: {
      label: "Unit Price",
      type: FactoryInventoryInputType.PRICE,
    },
    [FactoryInventoryPricingEditorKey.UNIT_COST]: {
      label: "Factory Cost",
      type: FactoryInventoryInputType.PRICE,
    },
    [FactoryInventoryPricingEditorKey.SALES_TAX]: {
      label: "Sales Tax",
      type: FactoryInventoryInputType.PRICE,
    },
  };
