// LOGIC CONSTANTS
import {PublicProjectDisplayType} from "@natomas-org/core";
import {VILLA_APPLE_GREEN} from "../_shared/colors";

export const MAP_MAX_LOAD_DURATION_MS = 3000;

// Determines whether or not to show 'Completed Projects' in the choose ADU sidebar
export const AREA_MAP_PAST_PROJECTS_NAV = true;

// STYLE CONSTANTS
export const MAP_SIDE_PADDING = "1rem";
export const FEASIBILITY_BANNER_HEIGHT = "6rem";

export const VILLA_WARNING_YELLOW = "#FFBF00";
export const INFO_TABLE_WIDTH = "50%";
export const INFO_TABLE_MAX_WIDTH = "50%";
export const INFO_TABLE_MIN_WIDTH = "30rem";
export const SUBTITLE_GRAY = "#626262";
export const MAP_SEARCH_AND_FILTERS_HEIGHT = "3rem";
export const APP_HEADER_HEIGHT = `3.9rem`;
export const MAP_ANIMATION_SPEED = 250;

// MAPPING STYLES
export const VILLA_MAP_GOLD = "#E8D560";
export const VILLA_MAP_RED = "#ff5959";
export const VILLA_MAP_BLUE = "#4ba7e1";

export const VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT = VILLA_APPLE_GREEN; // VILLA_MAP_BLUE;
export const VILLA_MAP_CLUSTER_COLOR_CLUSTER_ENRICHED = VILLA_APPLE_GREEN;
export const VILLA_MAP_CLUSTER_COLOR_CLUSTER_DEFAULT = VILLA_MAP_BLUE;

export const SINGLE_PROJECT_CENTER_HOVER_OPACITY = 0.67;
export const SINGLE_PROJECT_CENTER_DEFAULT_OPACITY = 0.27;

// Logic-Driven Styling
export const SINGLE_PROJECT_BORDER_COLOR_LOGIC = [
  "case",
  ["==", ["get", "displayType"], PublicProjectDisplayType.COMPLETED_ENRICHED],
  VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT,
  ["==", ["get", "displayType"], PublicProjectDisplayType.COMPLETED],
  VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT,
  VILLA_MAP_RED,
];
export const SINGLE_PROJECT_CENTER_COLOR_LOGIC = [
  "case",
  ["==", ["get", "displayType"], PublicProjectDisplayType.COMPLETED_ENRICHED],
  VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT,
  ["==", ["get", "displayType"], PublicProjectDisplayType.COMPLETED],
  VILLA_MAP_CLUSTER_COLOR_SINGLE_DEFAULT,
  VILLA_MAP_RED,
];
export const CLUSTER_PROJECT_COLOR_LOGIC = [
  "case",
  [
    "boolean",
    ["==", ["get", "displayType"], PublicProjectDisplayType.COMPLETED],
    true,
  ],
  VILLA_MAP_CLUSTER_COLOR_CLUSTER_ENRICHED,
  VILLA_MAP_CLUSTER_COLOR_CLUSTER_DEFAULT,
];

// Completed projects behavior
export const DEFAULT_NEARBY_PROJECTS_ZOOM = 9;
