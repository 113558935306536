import React, {useCallback, useEffect, useMemo} from "react";
import {Helmet} from "react-helmet-async";
import {
  StudioContent,
  StudioContentProps,
} from "../_shared/components/StudioContent";
import {ShowcaseController} from "./components/ShowcaseFooter";
import {useDispatch} from "react-redux";
import {
  setEditingSelections,
  setSaving,
  setShowSavingConfirmation,
} from "../../_shared/slices/StudioSlice";
import {updateUserProductSelection} from "../../../database/firebase/api/user";
import {useStudioSettings} from "../../_shared/hooks/useStudio/useStudioSettings";
import {StudioUnitCard} from "../_shared/components/StudioUnitCard";
import {StudioNavigationBar} from "../_shared/components/StudioNavigationBar";
import useActiveDesign from "../../_shared/hooks/useDesign/useActiveDesign";
import useActivitySlice from "../../_shared/hooks/useActivity/useActivitySlice";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;

interface ShowcaseContentProps extends StudioContentProps {}

export const Showcase = (props: ShowcaseContentProps) => {
  const {
    stackedMode,
    atBottom,
    height,
    summary,
    structure,
    product,
    price,
    options,
    priceMode,
  } = props;
  const {activeProjectId, activeCartItemId, activeCustomerId} =
    useActivitySlice();
  const {configuration} = useActiveDesign();
  const {product: activeProduct} = configuration ?? {};
  const {isEditing} = useStudioSettings();
  const dispatch = useDispatch();

  useEffect(() => {
    // If editing is ever enabled, disable it!
    if (isEditing) {
      dispatch(setEditingSelections(false));
    }
  }, [dispatch, isEditing]);

  // Allow saving when the current inventory unit is not assigned to project
  const canSave = activeProduct?.id !== product?.id;

  const save = useCallback(async () => {
    dispatch(setSaving(true));
    dispatch(setShowSavingConfirmation(true));
    await updateUserProductSelection({
      customerId: activeCustomerId,
      projectId: activeProjectId,
      cartItemId: activeCartItemId,
      product: product,
    });
    dispatch(setSaving(false));
  }, [activeCartItemId, activeCustomerId, activeProjectId, dispatch, product]);

  const controller = useMemo(() => {
    return (
      <ShowcaseController
        title={product?.title}
        canSave={canSave}
        save={save}
        product={product}
      />
    );
  }, [canSave, product, save]);

  return (
    <>
      <Helmet>
        <title>Villa | Design Studio</title>
        <meta name="description" content="Design your dream ADU" />
      </Helmet>
      <StudioNavigationBar
        stackedMode={stackedMode}
        height={"4rem"}
        studioPrice={price}
        displayOptions={options}
      />
      <StudioContent
        {...props}
        product={product}
        summary={summary}
        structure={structure}
        height={height}
        eligibleCategories={structure}
      />
      <StudioUnitCard
        priceMode={priceMode}
        stackedMode={stackedMode}
        isEditing={false}
        atBottom={atBottom ?? false}
        price={price}
        product={product}
        structure={structure}
        selectedModifiers={summary?.selectedModifiers}
        controller={controller}
      />
    </>
  );
};
