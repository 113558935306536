import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const StudioItemLineContainer = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  line-height: 0.85rem;
  padding-bottom: 0.5rem;
`;
