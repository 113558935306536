import {ProjectPriceMode} from "@natomas-org/core";
import {fetchVisualBudgetTable} from "../../components/portal/views/ProposalBudgetView/api/fetchBudget";
import {setBudget} from "../../components/_shared/slices/BudgetSlice";
import useActiveProject from "../../components/_shared/hooks/useProject/useActiveProject";
import {useCartItems} from "../../components/_shared/hooks/useCart/useCartItem/useCartItems";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, useEffect, useRef} from "react";
import {IStore} from "../../components/_shared/slices/types/Store";

export const BudgetSliceManager = () => {
  const {
    projectPriceMode,
    cartItemIds,
    id,
    isCartEnabled,
    phase1Micros,
    phase2Micros,
  } = useActiveProject();
  const cartItems = useCartItems(cartItemIds);
  const budgets = useSelector((state: IStore) => state.budget);
  const dispatch = useDispatch();
  const hasFetched = useRef(false);
  useEffect(() => {
    if (hasFetched.current) {
      return;
    }
    let errMsg: string | undefined = undefined;
    if (!id) {
      errMsg = "No project id";
    }
    if (!projectPriceMode) {
      errMsg = "No price mode";
    }
    if (!cartItems) {
      errMsg = "No cart items";
    }
    if (budgets[id] === null) {
      errMsg = "Budget is being fetched";
    }
    if (isCartEnabled === undefined) {
      errMsg = "Cart enabled is undefined";
    }
    if (
      projectPriceMode === ProjectPriceMode.CostPlus &&
      (phase1Micros === undefined || phase2Micros === undefined)
    ) {
      errMsg = "phase micros undefined";
    }
    if (errMsg) {
      console.warn(errMsg);
      return;
    }
    hasFetched.current = true;
    refreshBudget(id, dispatch).then((r) => {
      console.log(r);
    });
  }, [
    budgets,
    cartItems,
    dispatch,
    id,
    isCartEnabled,
    phase1Micros,
    phase2Micros,
    projectPriceMode,
  ]);

  return null;
};

export const refreshBudget = async (
  projectId: string,
  dispatch: Dispatch<any>
): Promise<string> => {
  const id = projectId;
  console.log("Refreshing budget for project " + id);
  dispatch(
    setBudget({
      projectId: id,
      entries: null,
    })
  );
  return fetchVisualBudgetTable({
    projectId: id,
    section: null,
  })
    .then((entries) => {
      dispatch(
        setBudget({
          projectId: id,
          entries,
        })
      );
    })
    .catch((e) => {
      console.error(e);
      dispatch(
        setBudget({
          projectId: id,
          entries: [],
        })
      );
      return e;
    });
};
