import React, {Fragment, useMemo} from "react";
import {ProposalSummarySection} from "./ProposalSummarySection";
import {priceTextFromMicros} from "../../../../../../../studio/_shared/helper";
import {SEE_BREAKDOWN_SLIDE_TEXT} from "./constants";
import {SpanBold} from "./styles";
import {
  fullPriceTextFromMicros,
  UNIT_PERMIT_FEE_PHASE_1_MICROS,
} from "@natomas-org/core";
import {useProjectBudget} from "../../../../../../../_shared/hooks/useProjectBudget/useProjectBudget";
import {GenericLoadingComponent} from "../../../generic/GenericLoadingComponent";

export const ProposalSummarySectionPhase1 = () => {
  const {totalUnitQuantity, projectBudget} = useProjectBudget();
  const designPhaseBudgetEstimate = useMemo(() => {
    const totalDesignPhasePrice = projectBudget
      ?.getPreconDesignCostPlus()
      ?.getTotalPrice();
    if (
      !totalDesignPhasePrice ||
      totalDesignPhasePrice?.price === undefined ||
      totalDesignPhasePrice?.price === null
    ) {
      return "-";
    }
    return priceTextFromMicros(totalDesignPhasePrice?.price ?? 0, "min");
  }, [projectBudget]);
  if (!projectBudget) {
    return <GenericLoadingComponent />;
  }
  return (
    <ProposalSummarySection
      highlighted={true}
      title={"1st Phase - Preconstruction Feasibility Study"}
      lineItems={[
        {
          label: (
            <Fragment>
              <span>1. Villa Phase 1 Payment - </span>
              <SpanBold>paid at PSA</SpanBold>
              <span>{` - ${fullPriceTextFromMicros(
                projectBudget?.getPhase1Micros() ??
                  UNIT_PERMIT_FEE_PHASE_1_MICROS
              )} / unit *`}</span>
            </Fragment>
          ),
          value: priceTextFromMicros(
            (projectBudget?.getPhase1Micros() ??
              UNIT_PERMIT_FEE_PHASE_1_MICROS) * totalUnitQuantity,
            "min"
          ),
        },
        {
          label: (
            <Fragment>
              <span>2. Design Phase Budget Estimate - </span>
              <SpanBold>paid during Preconstruction</SpanBold>
              <span> - {SEE_BREAKDOWN_SLIDE_TEXT}</span>
            </Fragment>
          ),
          value: designPhaseBudgetEstimate,
        },
      ]}
    />
  );
};
