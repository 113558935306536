import {usePage} from "./usePage";
import {useMemo} from "react";

export interface IDynamicValue {
  forFour: any;
  forEight: any;
  forTwelve: any;
  forSixteen: any;
}

export const fullColumnWidths: IDynamicValue = {
  forFour: 4,
  forEight: 8,
  forTwelve: 12,
  forSixteen: 16,
};

export const useDynamicValue = (props: IDynamicValue) => {
  const {columnCount} = usePage();

  return useMemo(() => {
    switch (columnCount) {
      case 4:
        return props.forFour;
      case 8:
        return props.forEight;
      case 12:
        return props.forTwelve;
      case 16:
        return props.forSixteen;
      default:
        return props.forFour;
    }
  }, [
    columnCount,
    props.forFour,
    props.forEight,
    props.forTwelve,
    props.forSixteen,
  ]);
};
