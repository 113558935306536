import React from "react";
import {FactorySelector} from "../FactorySelector";
import {
  FactoryManagementChildrenContainer,
  FactoryManagementHeaderContainer,
} from "./styles";
import FactoryViewSelector from "../FactoryViewSelector";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../../../../_shared/generics/page/components/PageContainer";
import {
  DROPDOWN_Z_INDEX,
} from "../../../../../../_shared/styles";
import {WHITE} from "../../../../../../_shared/colors";

const FactoryManagementHeader = (props: {title?: string; children?: any}) => {
  const {children} = props;
  if (!children) {
    return (
      <FactoryManagementHeaderContainer>
        <FactorySelector />
        <FactoryViewSelector />
      </FactoryManagementHeaderContainer>
    );
  } else {
    return (
      <PageContainer
        backgroundColor={WHITE}
        border={true}
        stickyOptions={{
          height: "52px",
          mode: PageContainerStickyMode.HEADER,
          zIndex: DROPDOWN_Z_INDEX,
        }}
      >
        <FactoryManagementChildrenContainer>
          {props?.title}
          {props?.children}
        </FactoryManagementChildrenContainer>
      </PageContainer>
    );
  }
};

export default FactoryManagementHeader;
