import React from "react";
import {IProduct, Product} from "@natomas-org/core";
import {MAP_PATH} from "../../../../../paths";
import {ProductFlexList} from "../../../_shared/components/ProductList/styles";
import {ProductCard} from "../../../_shared/components/ProductCard/ProductCard";
import {isProductEligible} from "../../../../_shared/hooks/useMappingFeatures/helper";
import {ProductListContainer} from "../../styles";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useUrl} from "../../../../_shared/hooks/useUrl";
import {
  INVENTORY_UNIT_DESCRIPTION,
  INVENTORY_UNIT_PREFIX,
} from "../../../../_shared/constants/labels";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {
  useProductFitsOnLot,
  useProductSiteWork,
} from "../../../../_shared/hooks/useProductParcelData";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {useProductRecommendations} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useProductRecommendations";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {SectionTitle} from "../../../_shared/components/ProductList/components/SectionTitle";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

export const InventoryProductList = (props: {
  products: IProduct[];
  setProductPreviewOnHover: boolean;
}) => {
  const percentRecommendation = useProductRecommendations();
  const {products, setProductPreviewOnHover} = props;
  const {isAdmin} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {allInEstimateCatalog, eligibility, adminCatalogMode} =
    useCatalogSession();
  const {projectPriceMode} = useActiveProject();
  const {to} = useNavigation();
  const {productFitsOnLotData} = useProductFitsOnLot({productId: undefined});
  const {productInstantQuoteData} = useProductSiteWork({productId: undefined});
  const inventoryProducts = products?.filter((product) => {
    return Product.isInventory(product);
  });
  const {getURLPath} = useUrl();
  const {isNatMobile} = usePage();
  if (inventoryProducts?.length < 1) {
    return null;
  }
  return (
    <ProductListContainer
      removeBottomMargin={isNatMobile || getURLPath()?.includes(MAP_PATH)}
    >
      <SectionTitle
        title={INVENTORY_UNIT_PREFIX}
        description={INVENTORY_UNIT_DESCRIPTION}
        linkText={`View ${INVENTORY_UNIT_PREFIX} Page`}
        linkEvent={() => {
          to(NavigationPaths.CATALOG_INVENTORY);
        }}
      />
      <ProductFlexList>
        {inventoryProducts?.map((product: IProduct) => {
          const cardProps = {
            product: product,
            allInEstimate:
              allInEstimateCatalog && product?.id
                ? productInstantQuoteData?.[product?.id]
                : undefined,
            key: "product-card-" + product.id,
            productFitsOnLotData: product?.id
              ? productFitsOnLotData?.[product?.id]
              : undefined,
            setProductOnHover: setProductPreviewOnHover,
          };
          return (
            <ProductCard
              isEligible={
                isAdmin && adminCatalogMode
                  ? isProductEligible(
                      eligibility,
                      product?.productGroupId,
                      product?.id
                    )
                  : undefined
              }
              percentRecommendation={
                percentRecommendation[product.id ?? -1]?.percentScore ?? -1
              }
              isCustomerPreDeposit={isCustomerPreDeposit || !customer}
              {...cardProps}
            />
          );
        })}
      </ProductFlexList>
    </ProductListContainer>
  );
};
