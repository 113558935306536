import React from "react";
import {NatToggleSelectorOption} from "./interfaces";
import {
  NatToggleSelectorColorProps,
  NatToggleSelectorOptionContainer,
  NatToggleSelectorOptionsContainer,
} from "./styles";
import {
  DIVIDER_COLOR,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
  WHITE,
} from "../../colors";

export interface NatToggleSelectorProps {
  options: NatToggleSelectorOption[];
  locked?: boolean;
  colorProps?: NatToggleSelectorColorProps;
  style?: React.CSSProperties;
  spinnerEnabled?: boolean;
  hidden?: boolean;
}

const defaultColorProps: NatToggleSelectorColorProps = {
  isSelected: VILLA_APPLE_GREEN,
  isLocked: DIVIDER_COLOR,
  isHovering: VILLA_APPLE_GREEN_LIGHT_1,
  default: WHITE,
};

export const NatToggleSelector = (props: NatToggleSelectorProps) => {
  const {options, locked, colorProps, hidden} = props;

  return (
    <NatToggleSelectorOptionsContainer
      hidden={hidden}
      style={props.style}
      borderColor={colorProps?.isSelected ?? defaultColorProps?.isSelected}
    >
      {options.map((option: NatToggleSelectorOption) => {
        return (
          <NatToggleSelectorOptionContainer
            id={option.id}
            key={`nat-toggle-selector-option-${option.id}`}
            isSelected={option.isSelected}
            isLocked={locked ?? false}
            colorProps={colorProps ?? defaultColorProps}
            onClick={!locked ? option.clickEvent : undefined}
          >
            {option.label}
          </NatToggleSelectorOptionContainer>
        );
      })}
    </NatToggleSelectorOptionsContainer>
  );
};
