import {store} from "../../../../store";
import {
  getFactoryLineInfoSetRef,
  getFactoryLineInventoryRef,
  getFactoryLineRef,
  getFactoryLineVersionCategoriesRef,
  getFactoryLineVersionModifierGroupsRef,
  getFactoryLineVersionModifiersRef,
  getFactoryLineVersionProductCustomModifiersRef,
  getFactoryLineVersionProductsRef,
  getFactoryLineVersionRef,
} from "../refs";
import {
  subscribeToCollectionWithCallback,
  subscribeWithCallback,
} from "../../utilities";
import {errorLogger} from "../../../../developerSettings";
import {
  FactoryVersionField,
  ISetFactoryPayloadOptions,
  mergeFactoryVersionValue,
  removeFactoryVersionValue,
  setFactoryInfo,
  setFactoryInfoSet,
  setFactoryInventory,
  updateFactoryVersionValue,
} from "../../../../components/_shared/slices/FactorySlice";
import {INatFactoryLine, IProduct} from "@natomas-org/core";
import {INVENTORY_UNIT_PREFIX} from "../../../../components/_shared/constants/labels";
import {fetchImage} from "../../images/fetching";
import {InfoSetItem} from "../../../../components/factory-info-set/shared/interfaces";
import {
  ARCHIVED_KEY,
  ID_KEY,
} from "../../../../components/factory-info-set/shared/constants";
import {processOptions} from "../../../../components/_shared/slices/FactorySlice/helpers";
import {
  convertFactoryVersionDocToCategory,
  convertFactoryVersionDocToModifier,
  convertFactoryVersionDocToModifierGroup,
  convertFactoryVersionDocToProductModifier,
} from "./formatter";
import {IFetchingReturn} from "../../../../root/subscriptions";

const getFactoryLineInfo = (id: string, info: INatFactoryLine) => {
  return {
    id: id,
    publishedVersionId: info?.published_id,
    draftVersionId: info?.draft_id,
    globalTitle: info?.globalTitle,
    name: info?.name,
    address: info?.address,
    marketing: info?.marketing,
  };
};

export const fetchFactoryLineInfo = async (
  id: string,
  options?: ISetFactoryPayloadOptions
): Promise<IFetchingReturn> => {
  const factoryLineRef = getFactoryLineRef(id);
  if (!factoryLineRef) {
    errorLogger("Factory Line: " + id + ", not found.");
    return {
      unsubscribe: null,
    };
  }
  const unsubscribe = await subscribeWithCallback(
    factoryLineRef,
    async (data: any) => {
      if (!data?.id || !data?.published_id || !data?.draft_id) {
        errorLogger("Error getting factory line data, " + id);
      } else {
        const info = getFactoryLineInfo(id, data);
        store.dispatch(
          setFactoryInfo({
            factoryId: id,
            payload: info,
            options,
          })
        );
        processOptions(info, options ?? {});
      }
    }
  );
  return {
    unsubscribe: unsubscribe,
  };
};

export const fetchFactoryLineInfoSet = async (
  id: string
): Promise<IFetchingReturn> => {
  const ref = getFactoryLineInfoSetRef(id);
  let unsubscribe = null;
  if (!ref) {
    errorLogger("Factory Line InfoSet: " + id + ", not found.");
    return {
      unsubscribe: unsubscribe,
    };
  }
  if (ref) {
    unsubscribe = await subscribeToCollectionWithCallback(ref, (items: any) => {
      if (items) {
        const infoSet: {[key: string]: InfoSetItem} = {};
        (Object.values(items) as InfoSetItem[])?.forEach(
          (item: InfoSetItem) => {
            if (!item[ARCHIVED_KEY]) {
              const key = item[ID_KEY];
              infoSet[key] = item;
            }
          }
        );
        store.dispatch(
          setFactoryInfoSet({
            factoryId: id,
            payload: infoSet,
          })
        );
      }
    });
  }
  return {
    unsubscribe: unsubscribe,
  };
};

export const fetchFactoryLineDetails = async (
  id: string,
  versionId: string
): Promise<IFetchingReturn> => {
  // Reference getter
  const docRef = getFactoryLineVersionRef(id, versionId);

  // Reference verification
  if (!docRef) {
    errorLogger("Reference not found.");
    return {
      unsubscribe: null,
    };
  }

  // Get Document Data
  const unsubscribe = await subscribeWithCallback(docRef, async (data: any) => {
    if (data) {
      store.dispatch(
        mergeFactoryVersionValue({
          factoryId: id,
          versionId: versionId,
          type: FactoryVersionField.DETAILS,
          payload: {...data, id: id, versionId: versionId},
        })
      );
    }
  });
  return {
    unsubscribe: unsubscribe,
  };
};

export const fetchFactoryLineInventory = async (
  id: string
): Promise<IFetchingReturn> => {
  // Reference getter
  const docRef = getFactoryLineInventoryRef(id);

  // Reference verification
  if (!docRef) {
    errorLogger("Reference not found.");
    return {
      unsubscribe: null,
    };
  }
  // Get Document Data
  const inventoryCollectionUnsubscribe =
    await subscribeToCollectionWithCallback(
      docRef,
      async (data: any) => {
        if (data) {
          store.dispatch(
            setFactoryInventory({
              factoryId: id,
              payload: data,
            })
          );
          // Object.keys(data ?? {}).forEach((pid: any) => {
          //   if (data[pid]) {
          //     fetchFactoryLineProductModifiers(id, versionId, pid).then(() => {});
          //   }
          // });
        }
      },
      (docs: any) => {
        const products: {[key: string]: any} = {};
        docs.forEach((c: any) => {
          const product: IProduct = c.doc.data();
          product.id = c.doc.id;
          product.productGroupId = id;
          if (!product?.price) {
            product.price = {
              // @ts-ignore
              price: product?.productDetails?.unitPriceMicros ?? null,
              cost: null,
              currency: "USD",
            };
          }
          product.title = INVENTORY_UNIT_PREFIX + " " + product.title;
          if (product.imageId != null) {
            fetchImage(product.imageId, false);
          }
          products[c.doc.id] = product;
        });
        return products;
      }
    );
  // unsubscribe ensures that the collection and sub-collections are unsubscribed from
  return {
    unsubscribe: inventoryCollectionUnsubscribe,
  };
};

export const fetchFactoryLineProducts = async (
  id: string,
  versionId: string
): Promise<IFetchingReturn> => {
  // Reference getter
  const docRef = getFactoryLineVersionProductsRef(id, versionId);

  // Reference verification
  if (!docRef) {
    errorLogger("Reference not found.");
    return {
      unsubscribe: null,
    };
  }
  // Get Document Data
  const productCollectionUnsubscribe = await subscribeToCollectionWithCallback(
    docRef,
    async (data: any) => {
      if (data) {
        store.dispatch(
          mergeFactoryVersionValue({
            factoryId: id,
            versionId: versionId,
            type: FactoryVersionField.PRODUCTS,
            payload: data,
          })
        );
        Object.keys(data ?? {}).forEach((pid: any) => {
          if (data[pid]) {
            fetchFactoryLineProductModifiers(id, versionId, pid).then(() => {});
          }
        });
      }
    },
    (docs: any) => {
      const products: {[key: string]: any} = {};
      docs.forEach((c: any) => {
        if (c?.type === "removed") {
          store.dispatch(
            removeFactoryVersionValue({
              factoryId: id,
              versionId: versionId,
              type: FactoryVersionField.PRODUCTS,
              id: c?.doc?.id,
            })
          );
        } else {
          const product: IProduct = c.doc.data();
          // Product is visible and not an inventory unit
          if (isInvalidDocument(product) || product.inventory_info) {
            return;
          }
          product.id = c.doc.id;
          product.productGroupId = id;
          product.versionId = versionId;
          if (!product?.price) {
            product.price = {
              // @ts-ignore
              price: product?.productDetails?.unitPriceMicros ?? null,
              cost: null,
              currency: "USD",
            };
          }
          // Rename inventory units locally
          if (!!product.inventory_info) {
            product.title = INVENTORY_UNIT_PREFIX + " " + product.title;
          }

          if (product.imageId != null) {
            fetchImage(product.imageId, false);
          }
          products[c.doc.id] = product;
        }
      });
      return products;
    }
  );

  // unsubscribe ensures that the collection and sub-collections are unsubscribed from
  return {
    unsubscribe: productCollectionUnsubscribe,
  };
};

export const fetchFactoryLineCategories = async (
  id: string,
  versionId: string
): Promise<IFetchingReturn> => {
  // Reference getter
  const docRef = getFactoryLineVersionCategoriesRef(id, versionId);

  // Reference verification
  if (!docRef) {
    errorLogger("Reference not found.");
    return {
      unsubscribe: null,
    };
  }
  // Get Document Data
  const unsubscribe = await subscribeToCollectionWithCallback(
    docRef,
    async (data: any) => {
      if (data) {
        store.dispatch(
          mergeFactoryVersionValue({
            factoryId: id,
            versionId: versionId,
            type: FactoryVersionField.CATEGORIES,
            payload: data,
          })
        );
      }
    },
    (docs: any) => {
      const categories: {[key: string]: any} = {};
      if (docs) {
        docs.forEach((c: any) => {
          if (c?.type === "removed") {
            store.dispatch(
              removeFactoryVersionValue({
                factoryId: id,
                versionId: versionId,
                type: FactoryVersionField.CATEGORIES,
                id: c?.doc?.id,
              })
            );
          } else {
            const category = convertFactoryVersionDocToCategory(
              id,
              versionId,
              c.doc
            );
            if (!!category) {
              if (c?.type === "modified") {
                store.dispatch(
                  updateFactoryVersionValue({
                    factoryId: id,
                    versionId: versionId,
                    type: FactoryVersionField.CATEGORIES,
                    id: category.id,
                    payload: category,
                  })
                );
              } else {
                categories[category.id] = category;
              }
            }
          }
        });
      }
      return categories;
    }
  );
  return {
    unsubscribe: unsubscribe,
  };
};

export const fetchFactoryLineModifierGroups = async (
  id: string,
  versionId: string
): Promise<IFetchingReturn> => {
  // Reference getter
  const docRef = getFactoryLineVersionModifierGroupsRef(id, versionId);

  // Reference verification
  if (!docRef) {
    errorLogger("Reference not found.");
    return {
      unsubscribe: null,
    };
  }
  // Get Document Data
  const unsubscribe = await subscribeToCollectionWithCallback(
    docRef,
    async (data: any) => {
      if (data) {
        store.dispatch(
          mergeFactoryVersionValue({
            factoryId: id,
            versionId: versionId,
            type: FactoryVersionField.MODIFIER_GROUPS,
            payload: data,
          })
        );
      }
    },
    (docs: any) => {
      const modifierGroups: {[key: string]: any} = {};
      docs.forEach((c: any) => {
        if (c?.type === "removed") {
          store.dispatch(
            removeFactoryVersionValue({
              factoryId: id,
              versionId: versionId,
              type: FactoryVersionField.MODIFIER_GROUPS,
              id: c?.doc?.id,
            })
          );
        } else {
          const modifierGroup = convertFactoryVersionDocToModifierGroup(
            id,
            versionId,
            c.doc
          );
          if (!!modifierGroup) {
            if (c?.type === "modified") {
              store.dispatch(
                updateFactoryVersionValue({
                  factoryId: id,
                  versionId: versionId,
                  type: FactoryVersionField.MODIFIER_GROUPS,
                  id: modifierGroup.id,
                  payload: modifierGroup,
                })
              );
            } else {
              modifierGroups[modifierGroup.id] = modifierGroup;
            }
          }
        }
      });
      return modifierGroups;
    }
  );
  return {
    unsubscribe: unsubscribe,
  };
};

export const fetchFactoryLineModifiers = async (
  id: string,
  versionId: string
): Promise<IFetchingReturn> => {
  // Reference getter
  const docRef = getFactoryLineVersionModifiersRef(id, versionId);

  // Reference verification
  if (!docRef) {
    errorLogger("Reference not found.");
    return {
      unsubscribe: null,
    };
  }
  // Get Document Data
  const unsubscribe = await subscribeToCollectionWithCallback(
    docRef,
    async (data: any) => {
      if (data) {
        store.dispatch(
          mergeFactoryVersionValue({
            factoryId: id,
            versionId: versionId,
            type: FactoryVersionField.MODIFIERS,
            payload: data,
          })
        );
      }
    },
    (docs: any) => {
      const modifiers: {[key: string]: any} = {};
      docs.forEach((c: any) => {
        if (c?.type === "removed") {
          store.dispatch(
            removeFactoryVersionValue({
              factoryId: id,
              versionId: versionId,
              type: FactoryVersionField.MODIFIERS,
              id: c?.doc?.id,
            })
          );
        } else {
          const modifier = convertFactoryVersionDocToModifier(
            id,
            versionId,
            c.doc
          );
          if (!!modifier) {
            if (c?.type === "modified") {
              store.dispatch(
                updateFactoryVersionValue({
                  factoryId: id,
                  versionId: versionId,
                  type: FactoryVersionField.MODIFIERS,
                  id: modifier.id,
                  payload: modifier,
                })
              );
            } else {
              modifiers[modifier.id] = modifier;
            }
          }
        }
      });
      return modifiers;
    }
  );
  return {
    unsubscribe: unsubscribe,
  };
};

export const fetchFactoryLineProductModifiers = async (
  id: string,
  versionId: string,
  productId: string
) => {
  // Reference getter
  const docRef = getFactoryLineVersionProductCustomModifiersRef(
    id,
    versionId,
    productId
  );

  // Reference verification
  if (!docRef) {
    errorLogger("Reference not found.");
    return {
      unsubscribe: null,
    };
  }
  // Get Document Data
  return subscribeToCollectionWithCallback(
    docRef,
    async (data: any) => {
      if (data) {
        store.dispatch(
          mergeFactoryVersionValue({
            factoryId: id,
            versionId: versionId,
            type: FactoryVersionField.PRODUCT_MODIFIERS,
            payload: {[productId]: data},
          })
        );
      }
    },
    (docs: any) => {
      let modifierObjects: {[key: string]: any} = {};
      docs.forEach((c: any) => {
        // id: `${productId}.${c.doc.id}`,
        const productModifierId = `${productId}.${c.doc.id}`;
        if (c?.type === "removed") {
          store.dispatch(
            removeFactoryVersionValue({
              factoryId: id,
              versionId: versionId,
              type: FactoryVersionField.PRODUCT_MODIFIERS,
              id: productModifierId,
            })
          );
        } else {
          const productModifier = convertFactoryVersionDocToProductModifier(
            id,
            versionId,
            productId,
            c.doc
          );
          if (!!productModifier) {
            if (c?.type === "modified") {
              store.dispatch(
                updateFactoryVersionValue({
                  factoryId: id,
                  versionId: versionId,
                  type: FactoryVersionField.PRODUCT_MODIFIERS,
                  id: productId,
                  payload: {[c.doc.id]: productModifier},
                })
              );
            } else {
              modifierObjects[productModifier.id] = productModifier;
            }
          }
        }
      });
      return modifierObjects;
    }
  );
};

export const isInvalidDocument = (doc: any) => {
  return doc == null;
};

export const isArchived = (value: any) => {
  return value?.visible != null && value?.visible === false;
};
