import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {useAllPublishedAndRoutedProducts} from "../../../../_shared/hooks/useProductCatalog/useProducts";
import {useCatalogFilteredProducts} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogFilteredProducts";
import {useFactoryLines} from "../../../../_shared/hooks/useProductCatalog/useFactoryLines/useFactoryLines";
import {ListingStatusDetails, Product} from "@natomas-org/core";
import {ProductList} from "../../../_shared/components/ProductList";
import React from "react";
import {InventoryProductList} from "./InventoryProductList";

export const ProductCards = (props: {
  hideLink?: boolean;
  setProductOnHover?: boolean;
}) => {
  const {adminCatalogMode, selectedProductGroupId} = useCatalogSession();
  const products = useAllPublishedAndRoutedProducts();
  const filteredProducts = useCatalogFilteredProducts(products);
  const {factoryLines} = useFactoryLines({
    listingStatus: adminCatalogMode
      ? {
          [ListingStatusDetails.LISTED]: true,
          [ListingStatusDetails.HIDDEN]: true,
          [ListingStatusDetails.UNLISTED]: true,
          [ListingStatusDetails.NULL]: true,
        }
      : {[ListingStatusDetails.LISTED]: true},
  });

  return (
    <>
      {factoryLines?.map((factory, idx) => {
        if (selectedProductGroupId && factory?.id !== selectedProductGroupId) {
          return null;
        }
        return (
          <ProductList
            key={`factory-${idx}`}
            setProductOnHover={props.setProductOnHover}
            hideLink={props.hideLink}
            factory={factory}
            products={filteredProducts?.filter((product) => {
              return (
                product?.productGroupId === factory?.id &&
                !Product.isInventory(product)
              );
            })}
          />
        );
      })}
      <InventoryProductList
        products={filteredProducts}
        setProductPreviewOnHover={props.setProductOnHover ?? false}
      />
    </>
  );
};
