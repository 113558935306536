import React, {useState} from "react";
import {
  Chevron,
  FAQContainer,
  FAQItemContainer,
  FAQItemContent,
  FAQItemTitle,
  FAQLineAndArrowContainer,
  FAQTitle,
} from "./styles";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {Customer} from "@natomas-org/core";

interface IFAQItem {
  title: string;
  content: JSX.Element;
}

export const FAQs = (props: {defaultExpanded: boolean}) => {
  const {isNatMobile} = usePage();
  const {customer} = useCurrentCustomer();

  const FAQ_ARRAY: IFAQItem[] = [
    {
      title: "Can I talk to someone?",
      content: (
        <>
          Yes!{" "}
          <a
            href={"https://villahomes.com/contact-us"}
            target={"_blank"}
            rel="noreferrer"
          >
            Reach out to us anytime.
          </a>
        </>
      ),
    },
    {
      title: "What's included in your costs?",
      content: (
        <>
          At Villa, we believe in comprehensive, transparent pricing. Your
          instant quote is a high-level estimate that includes your selected
          home model cost, permitting, project management, plus some assumptions
          about the site work your property requires. Once you’re in the
          Feasibility Study phase, we will do a deep dive into your property’s
          specific needs and provide a detailed line-by-line estimate.
        </>
      ),
    },
    {
      title: "What is a Feasibility Study?",
      content: (
        <>
          Our Feasibility Study gives you all the information you need to make a
          confident decision. Your expert Home Consultant will assess your
          property in detail and create a custom site map and utility plan.
          We’ll also review local permitting requirements, walk you through
          design selections, and present you with a refined estimate and project
          timeline. A Feasibility Study costs just $500 and is packed with
          educational materials about building an ADU on your property. Whether
          you build with Villa or not, all the materials are yours to keep!
          Learn more about what’s included{" "}
          <a
            href={"https://villahomes.com/feasibility-study/"}
            target={"_blank"}
            rel="noreferrer"
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      title: "I’d like to move fast. What’s the next step?",
      content: (
        <>
          Great! To accelerate the process, you can prepay for your Feasibility
          Study{" "}
          <a
            href={
              customer
                ? "https://buy.stripe.com/6oEg2tcyT6GWaLS28b?prefilled_email=" +
                  Customer.getContactEmail(customer)
                : "https://buy.stripe.com/6oEg2tcyT6GWaLS28b"
            }
            target={"_blank"}
            rel="noreferrer"
          >
            here
          </a>
          . Shortly after payment, a Home Consultant will contact you to set up
          a call to review your Feasibility Study.
        </>
      ),
    },
    {
      title: "How long until my ADU is complete?",
      content: (
        <>
          On average, projects take about 11 months from contract to move-in.
          Timelines will vary by jurisdiction and property specifics. If you
          have specific timeline needs, mention them to a Villa expert and we’ll
          assess what we can do!
        </>
      ),
    },
  ];

  return (
    <FAQContainer style={{paddingBottom: isNatMobile ? "3rem" : ""}}>
      <FAQTitle>Frequently asked questions</FAQTitle>
      <div
        className={"vcl-container-primary-16"}
        style={{
          width: "100%",
          borderRadius: "10px",
          padding: "24px 16px",
          backgroundColor: "#F7F5F2",
        }}
      >
        {FAQ_ARRAY.map((item) => {
          return (
            <FAQItem
              key={item.title}
              title={item.title}
              content={item.content}
              startExpanded={props.defaultExpanded}
            />
          );
        })}
      </div>
    </FAQContainer>
  );
};

const FAQItem = (props: {
  title: string;
  content: JSX.Element;
  startExpanded: boolean;
}) => {
  const [expanded, setExpanded] = useState(props.startExpanded);
  const {isNatMobile} = usePage();
  return (
    <FAQItemContainer>
      <FAQLineAndArrowContainer
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <Chevron isActive={expanded} />
        <FAQItemTitle>{props.title}</FAQItemTitle>
      </FAQLineAndArrowContainer>
      <FAQItemContent expanded={expanded} isMobile={isNatMobile}>
        {props.content}
      </FAQItemContent>
    </FAQItemContainer>
  );
};
