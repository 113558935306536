import React, {useMemo, useState} from "react";
import {useSpringCarousel} from "react-spring-carousel";
import {BLACK, GRANITE} from "../../../../../../_shared/colors";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {
  IconPosition,
  NatHorizontalAlign,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../_shared/generics/label/NatLabel";
import {NatButton} from "../../../../../../_shared/generics/button";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import {CollectionContainer} from "../styles";
import {dummyElement} from "../OurSeries";

const getButtonStyling = (buttonsDisabled: boolean) => {
  return {
    color: buttonsDisabled ? GRANITE : BLACK,
    border: `1px solid ${buttonsDisabled ? GRANITE : BLACK}`,
  };
};
export const ScrollableCardList = React.memo(
  (props: {
    title: string | JSX.Element | null;
    items: {id: string; renderItem: JSX.Element}[];
    productLineCardsPerRow: number;
    hideArrows?: boolean;
  }) => {
    // console.log("Rerender ScrollableCardList");
    const {productLineCardsPerRow} = props;
    const items = Object.values(props.items);
    if (items?.length < props.productLineCardsPerRow) {
      for (
        let i = 0;
        i < props.productLineCardsPerRow - props.items?.length;
        i++
      ) {
        items.push(dummyElement);
      }
    }
    // @ts-ignore
    const {
      carouselFragment,
      slideToPrevItem,
      slideToNextItem,
      useListenToCustomEvent,
      getIsActiveItem,
    } = useSpringCarousel({
      itemsPerSlide: Math.min(items?.length ?? 0, productLineCardsPerRow),
      withLoop: false,
      items: items,
    });

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    useListenToCustomEvent((event) => {
      try {
        // Triggered during drag gestures
        if (event.eventName === "onDrag") {
          // Do something...
        }
        // Triggered when the slide is about to slide
        else if (event.eventName === "onSlideStartChange") {
          // Do something...
          for (let i = 0; i < items.length; i++) {
            if (getIsActiveItem(`${i}`)) {
              setActiveItemIndex(i);
            }
          }
        }
        // Triggered when the slide animation is completed
        else if (event.eventName === "onSlideChange") {
          // Do something...
        }
        // Triggered on fullscreen change
        else if (event.eventName === "onFullscreenChange") {
        }
      } catch (e) {
        console.log(e);
      }
    });

    const previousButtonDisabled = useMemo(() => {
      return activeItemIndex === 0;
    }, [activeItemIndex]);
    const nextButtonDisabled = useMemo(() => {
      return activeItemIndex + productLineCardsPerRow > items?.length - 1;
    }, [activeItemIndex, items?.length, productLineCardsPerRow]);
    const previousButtonStyling = useMemo(() => {
      return getButtonStyling(previousButtonDisabled);
    }, [previousButtonDisabled]);
    const nextButtonStyling = useMemo(() => {
      return getButtonStyling(nextButtonDisabled);
    }, [nextButtonDisabled]);

    return (
      <div>
        <NatFlex
          ROW
          FULL_WIDTH
          NOGAP
          HORIZONTAL_ALIGN={NatHorizontalAlign.RIGHT}
          VERTICAL_ALIGN={NatVerticalAlign.CENTER}
          style={{paddingBottom: "1rem"}}
        >
          {props.title ? (
            <NatLabel
              label={props.title}
              type={NatLabelType.P2}
              weight={NatLabelWeight.MEDIUM}
              style={{flexGrow: 1, textAlign: "left"}}
            />
          ) : null}
          <NatFlex
            ROW
            NOWRAP
            VERTICAL_ALIGN={NatVerticalAlign.CENTER}
            hidden={items?.length < productLineCardsPerRow}
            style={{display: props.hideArrows ? "none" : undefined}}
          >
            <NatButton
              icon={{
                icon: <AiOutlineArrowLeft />,
                iconPosition: IconPosition.ONLY,
              }}
              label={"Previous"}
              style={previousButtonStyling}
              option={StyleOption.ICON_ONLY}
              clickEvent={slideToPrevItem}
              hidden={items?.length <= productLineCardsPerRow}
              disabled={previousButtonDisabled}
            />
            <NatButton
              icon={{
                icon: <AiOutlineArrowRight />,
                iconPosition: IconPosition.ONLY,
              }}
              label={"Next"}
              style={nextButtonStyling}
              option={StyleOption.ICON_ONLY}
              clickEvent={slideToNextItem}
              hidden={items?.length <= productLineCardsPerRow}
              disabled={nextButtonDisabled}
            />
          </NatFlex>
        </NatFlex>
        <div
          key={`product-flex`}
          style={{
            width: "102%",
            position: "relative",
            left: "-1%",
            right: "-1%",
          }}
        >
          <CollectionContainer
            productLineCardsPerRow={productLineCardsPerRow}
            activeItemIndex={activeItemIndex ?? 0}
          >
            {carouselFragment}
          </CollectionContainer>
        </div>
      </div>
    );
  }
);
