import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {isBlankString} from "@natomas-org/core";
import {
  AdminInput,
  ModifierSelect,
} from "../../admin/_shared/components/AdminComponentFactory/AdminComponentFactory";
import {NatButton} from "../generics/button";
import {NatSize, StyleOption} from "../generics/_shared";
import {getFormInitialValues, getFormStructure} from "../application";
import {ImageAssignmentManager} from "../generics/image/ImageAssignmentManager";
import {
  deleteRenderingFromCategory,
  saveRenderingToCategory,
} from "../../../database/firebase/catalog/saving";
import {useAdminProductCatalog} from "../hooks/admin-only-hooks/useAdminProductCatalog";
import {useSelectedFactoryLine} from "../hooks/useProductCatalog/useSelectedFactoryLine";
import styled from "styled-components";

const renderingProperties = {
  overlayGroupId: {
    name: "Overlay Group",
    type: "input",
    index: 10,
  },
  zIndex: {
    name: "Z-Index",
    defaultValue: 0,
    type: "number",
    index: 11,
  },
  orientation: {
    name: "Orientation",
    index: 1,
    type: "dropdown",
    defaultValue: "",
    options: {
      normal: {label: "None", value: ""},
      xFlipped: {label: "Flip Horizontally", value: "xFlipped"},
      // yFlipped: {label: "Flip Vertically", value: "yFlipped"},
    },
  },
  objectFit: {
    name: "Image Fit",
    index: 0,
    type: "dropdown",
    defaultValue: "",
    options: {
      normal: {label: "None", value: ""},
      cover: {label: "Fill container", value: "cover"},
      contain: {label: "Fit image inside container", value: "contain"},
    },
  },
};

// const findModifierIDsForFileName = (fileName, allModifiers) => {
//   const fileNameParts = fileName.toLowerCase().split("_");
//   const modifierMap = {};
//   Object.keys(allModifiers)
//     .filter((modifierId) => {
//       const modifier = allModifiers[modifierId];
//       let modifierTitle = modifier.title;
//       if (modifierTitle == null) {
//         return false;
//       }
//       modifierTitle = modifierTitle.toLowerCase();
//
//       if (modifierTitle.length > 8) {
//         modifierTitle = modifierTitle.substr(0, 8);
//       }
//       for (const fileNamePart of fileNameParts) {
//         if (
//           modifierTitle.startsWith(fileNamePart) ||
//           fileNamePart.startsWith(modifierTitle)
//         ) {
//           return true;
//         }
//       }
//       return false;
//     })
//     .forEach((modifierId) => {
//       modifierMap[modifierId] = true;
//     });
//   return modifierMap;
// };

const getInitialValues = (obj) => {
  const initialValues = getFormInitialValues(renderingProperties);
  if (obj != null) {
    for (const key in renderingProperties) {
      // @ts-ignore
      const keyInfo = renderingProperties[key];
      const value = obj[key];
      let initialValue = value;
      if (keyInfo.type === "number") {
        initialValue = "" + value;
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditRenderingForCategory = (props) => {
  const {rendering, renderingImageId, configurationPage, leftOverModifiers} =
    props;
  const {details} = useSelectedFactoryLine();
  const {editingEnabled} = useAdminProductCatalog();
  const [imageId, setImageId] = useState(renderingImageId);
  const [showMore, setShowMore] = useState(false);

  let initialModifiers = {};
  if (leftOverModifiers != null) {
    leftOverModifiers.forEach((modifierId) => {
      initialModifiers[modifierId] = true;
    });
  } else if (rendering.requiredModifiers != null) {
    initialModifiers = rendering.requiredModifiers;
  }
  const [newSelections, setNewSelections] = useState(initialModifiers);
  const initialValues = getInitialValues(rendering);
  const formStructure = getFormStructure(renderingProperties);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      const valuesToSave = {};
      valuesToSave.zIndex = isBlankString(values.zIndex)
        ? null
        : parseInt(values.zIndex);
      valuesToSave.overlayGroupId = isBlankString(values.overlayGroupId)
        ? null
        : values.overlayGroupId;
      valuesToSave.orientation = isBlankString(values.orientation)
        ? null
        : values.orientation;
      valuesToSave.objectFit = isBlankString(values.objectFit)
        ? null
        : values.objectFit;
      return saveRendering(valuesToSave);
    },
  });

  useEffect(() => {
    setNewSelections(initialModifiers);
    formik.resetForm();
    for (const key in renderingProperties) {
      if (key === "zIndex" && rendering[key] != null) {
        formik.setFieldValue(key, "" + rendering[key]);
      } else if (rendering[key] != null) {
        formik.setFieldValue(key, rendering[key]);
      }
    }
  }, [rendering]);

  useEffect(() => {
    setImageId(renderingImageId);
  }, [renderingImageId]);

  const onSelectionChange = (value) => {
    setNewSelections(value);
  };

  const saveRendering = (valuesToSave) => {
    const updatedRendering = {
      ...valuesToSave,
      imageId: imageId,
      requiredModifiers: newSelections,
      id: rendering.id,
    };
    return saveRenderingToCategory(
      details?.id,
      details?.versionId,
      configurationPage,
      updatedRendering
    );
  };

  const deleteRendering = () => {
    return deleteRenderingFromCategory(
      details?.id,
      details?.versionId,
      configurationPage,
      rendering.id
    );
  };

  let moreDetailsContainer = null;
  if (showMore === true) {
    moreDetailsContainer = formStructure.map((keyInfo) => {
      const {key} = keyInfo;
      // @ts-ignore
      let value = formik.values[key];
      // @ts-ignore
      let initial = initialValues[key];

      return (
        <AdminInput
          key={key}
          value={value}
          fieldInfo={keyInfo}
          initialValue={initial}
          onChange={(value) => {
            formik.setFieldValue(key, value);
          }}
        />
      );
    });
  }

  return (
    <RenderingContainer>
      <ImageAssignmentContainer>
        <ImageAssignmentManager
          key={imageId}
          imageIdValue={imageId}
          onComplete={(value) => {
            setImageId(value);
          }}
          fileNameCallback={() => {
            // if (false) {
            //   setNewSelections(
            //     findModifierIDsForFileName(name, currentGroupModifiers)
            //   );
            // }
          }}
        />
      </ImageAssignmentContainer>
      <ModifierSelect value={newSelections} onChange={onSelectionChange} />
      <RenderingFormContainer onSubmit={formik.handleSubmit}>
        {moreDetailsContainer}
        <NatButton
          label={showMore ? "Hide" : "Show More"}
          option={StyleOption.PRIMARY_ALT}
          size={NatSize.SMALL}
          clickEvent={() => {
            setShowMore(!showMore);
          }}
        />
        {editingEnabled && (
          <>
            <NatButton
              label={"Save Changes"}
              type={"submit"}
              option={StyleOption.PRIMARY_ALT}
              size={NatSize.SMALL}
              style={{marginRight: "0.5rem"}}
            />
            <NatButton
              label={"Delete Rendering"}
              option={StyleOption.DESTRUCTIVE}
              size={NatSize.SMALL}
              clickEvent={() => {
                return deleteRendering();
              }}
            />
          </>
        )}
      </RenderingFormContainer>
    </RenderingContainer>
  );
};

const RenderingContainer = styled.div`
  min-height: 400px;
  padding: 1rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const ImageAssignmentContainer = styled.div`
  min-height: 400px;
  height: 400px;
`;

const RenderingFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: fit-content;
`;
