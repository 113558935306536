import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";

export const CompletedProjectGalleryContainer = styled.div<{height: string}>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: ${(props) => props.height};
  width: 100%;
`;

export const CompletedProjectMainImageWrapper = styled.div<{height: string}>`
  text-align: center;
  height: ${(props) => props.height};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  width: 100%;
  box-sizing: content-box;
`;
export const CompletedProjectMainCarouselWrapper = styled.div`
  width: 100%;
  position: absolute;
`;
export const SmartImageContainer = styled.div<{isNatMobile?: boolean}>`
  flex-grow: 1;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isNatMobile ? "95%" : "100%")};
  margin: 0 0;
  box-sizing: content-box;
  transition: opacity 0.3s ease-in-out;
`;
export const CompletedProjectsThumbnailContainer = styled.div<{
  previousCard: boolean;
  height: string;
  width: string;
  isNatMobile: boolean;
}>`
  width: 100%;
  padding: 0 0;
  position: absolute;
  top: 0;
  left: ${(props) => (props.previousCard ? "calc(-50%" : undefined)});
  right: ${(props) => (props.previousCard ? undefined : "calc(-50%")});
  height: calc(${(props) => props.height} * 1);
  border-radius: 1rem;
  opacity: 0.5;
  pointer-events: all;
  cursor: pointer;
  ${(props) => (props.isNatMobile ? "display: none !important;" : undefined)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  margin: 0 10%;
`;

export const SubtitleContainer = styled.div`
  padding: 0 1rem;
`;
export const TitleContainer = styled.div`
  font-size: 1.5rem;
  font-family: ${FONT_FAMILY_BOLD};
  padding: 0 1rem;
`;
export const SlidesComponentContainer = styled.div<{isNatMobile: boolean}>`
  width: 100%;
  border-radius: 1rem !important;
  background-color: white;
  height: 100%;
  overflow: hidden !important;
  .slide.carousel.completed-project-main-slide .carousel-control-prev {
    z-index: 99;
    box-sizing: border-box;
    height: 3.25rem;
    width: 3.25rem;
    top: calc(50% - 1.6125rem);
    background-color: black !important;
    border-radius: 50%;
    left: 12%;
  }
  .slide.carousel.completed-project-main-slide .carousel-item {
    opacity: 1;
    z-index: 14;
    .active {
      opacity: 0;
      z-index: 0;
    }
  }
  .slide.carousel.completed-project-main-slide .carousel-control-next {
    z-index: 99;
    top: calc(50% - 1.6125rem);
    height: 3.25rem;
    width: 3.25rem;
    background-color: black !important;
    border-radius: 50%;
    right: 12%;
  }

  .carousel-indicators {
    max-height: 1rem;
    position: absolute;
  }
  .carousel-indicators li {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: white;
    opacity: 0.5;
    height: 5px;
    width: 20px;
  }

  .carousel-indicators .active {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: white;
    opacity: 1;
    height: 5px;
    width: 20px;
  }
  .use-spring-carousel-main-wrapper {
    width: ${(props) =>
      props.isNatMobile ? "200% !important" : "140% !important"};
    left: ${(props) =>
      props.isNatMobile ? "-50% !important" : "-20% !important"};
  }
`;
