import React, {useEffect, useState} from "react";
import SSCBreakdown from "./SeriesBreakdown";
import {FactorySelectionOption} from "../index";

export interface SeriesSummaryComparisonProps {
  primaryId: string;
  options?: FactorySelectionOption[];
}

export interface SeriesSummaryComparisonSecondary {
  setOptionId: (id: string) => void;
  id?: string;
  options?: FactorySelectionOption[];
}

export interface SeriesSummaryComparisonRowProps {
  primaryId: string;
  secondary: SeriesSummaryComparisonSecondary;
}

const SSCBreakdownAdapter = (props: SeriesSummaryComparisonProps) => {
  const {primaryId, options} = props;
  const [targetId, setTargetId] = useState<string | undefined>();

  useEffect(() => {
    if (!options || !options.length) {
      if (targetId) {
        // If there is no otherId, and targetId exists, set targetId to undefined
        setTargetId(undefined);
      }
    }

    if (options && options.length) {
      if (!targetId) {
        // If there is no targetId, set it to the first otherId
        setTargetId(options?.[0]?.id);
      } else if (!options.find((option) => option.id === targetId)) {
        // If there is a targetId, but it is not included in the otherIds, set it to the first otherId
        setTargetId(options?.[0]?.id);
      }
    }
  }, [options, targetId]);

  if (!primaryId) {
    return null;
  }

  const rowProps: SeriesSummaryComparisonRowProps = {
    primaryId,
    secondary: {
      options,
      setOptionId: setTargetId,
      id: targetId,
    },
  };

  return <SSCBreakdown {...rowProps} />;
};

export default SSCBreakdownAdapter;
