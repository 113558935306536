import {useEffect, useMemo} from "react";
import {useCartSlice} from "../../components/_shared/hooks/useCart/useCartSlice";
import {
  subscribeToDesign,
  subscribeToDesignSnapshotDoc,
} from "../ProjectSliceManager/configuration";

const CartSliceManager = () => {
  const cartSlice = useCartSlice();
  // Unused right now, but this is where to manage the cart slice
  const ids = useMemo(() => {
    return Object.keys(cartSlice ?? {});
  }, [cartSlice]);

  useEffect(() => {
    ids?.forEach((id: string) => {
      const item = cartSlice[id];
      const configurationId = item?.details?.configurationId;
      if (configurationId) {
        subscribeToDesign(configurationId);
        subscribeToDesignSnapshotDoc(configurationId);
      }
    });
  }, [ids]);

  return null;
};

export default CartSliceManager;
