import React from "react";
import {NatButton, NatomasButtonProps} from "../button";
import {NatomasModalFooterButtonContainer} from "./styles";

export const FooterButtons = (props: {
  footerButtons: NatomasButtonProps[];
  columned: boolean;
}) => {
  const {footerButtons, columned} = props;
  return (
    <NatomasModalFooterButtonContainer columnButtons={columned}>
      {footerButtons?.map((button: NatomasButtonProps, index: number) => {
        return (
          <NatButton key={"nat-modal-footer-button-" + index} {...button} />
        );
      })}
    </NatomasModalFooterButtonContainer>
  );
};
