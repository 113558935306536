import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  RenderingFieldEditorBaseImageContainer,
  RenderingFieldEditorContainer,
  RenderingFieldEditorController,
  RenderingFieldEditorLayerButtonController,
  RenderingFieldEditorLayerContainer,
  RenderingFieldEditorLayerController,
  RenderingFieldEditorLayerImageContainer,
  RenderingFieldEditorLayersContainer,
  RenderingFieldEditorLayersWrapper,
  RenderingFieldEditorLayerWrapper,
  RenderingFieldEditorPreview,
  RenderingFieldEditorPreviewContainer,
  RenderingFieldEditorPreviewWrapper,
} from "./styles";
import {
  IFactoryInventoryStudioEditorCategoryImage,
  IFactoryInventoryStudioEditorCategoryImageGroup,
} from "../../CategorySection/CategoryImagesEditor/interface";
import {ImageAssignmentManager} from "../../../../../../../../../../../../_shared/generics/image/ImageAssignmentManager";
import RenderingPreview from "../../CategorySection/CategoryImagesEditor/RenderingPreview";
import {NatButton} from "../../../../../../../../../../../../_shared/generics/button";
import {sortImages} from "./helper";
import {
  NatDownChevron,
  NatResetIcon,
  NatSaveIcon,
  NatTrashIcon,
  NatUpChevron,
} from "../../../../../../../../../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../../_shared/generics/_shared";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import ImageFitSelector from "./ImageFitSelector";
import ImageOrientationSelector from "./ImageOrientationSelector";
import {DefaultFieldEditorProps} from "../FieldEditor";

interface RenderingFieldEditorProps extends DefaultFieldEditorProps {
  initialValue?: IFactoryInventoryStudioEditorCategoryImageGroup;
  currentValue?: IFactoryInventoryStudioEditorCategoryImageGroup;
}

const RenderingFieldEditor = (props: RenderingFieldEditorProps) => {
  const {reset, initialValue, currentValue, setValue} = props;
  const {id, index, images: imageMap} = currentValue ?? {};
  const [images, setImages] = useState<
    IFactoryInventoryStudioEditorCategoryImage[]
  >(sortImages(Object.values(imageMap ?? {})));
  const [orientation, setOrientation] = useState(images?.[0]?.orientation);
  const [objectFit, setObjectFit] = useState(images?.[0]?.objectFit);

  const currentImageArray = useMemo(() => {
    return sortImages(Object.values(imageMap ?? {}));
  }, [imageMap]);

  const initialImageArray = useMemo(() => {
    return sortImages(Object.values(initialValue?.images ?? {}));
  }, [initialValue?.images]);

  useEffect(() => {
    setImages(currentImageArray);
    setOrientation(currentImageArray?.[0]?.orientation);
    setObjectFit(currentImageArray?.[0]?.objectFit);
  }, [currentImageArray]);

  const isDifferentFromInitial = useMemo(
    () =>
      !_.isEqual(images, initialImageArray) ||
      images?.[0]?.orientation !== orientation ||
      images?.[0]?.objectFit !== objectFit,
    [images, initialImageArray, objectFit, orientation]
  );

  const isDifferentFromCurrent = useMemo(
    () =>
      !_.isEqual(images, currentImageArray) ||
      images?.[0]?.orientation !== orientation ||
      images?.[0]?.objectFit !== objectFit,
    [images, currentImageArray, orientation, objectFit]
  );

  const updateImageGroup = useCallback(() => {
    if (id) {
      setValue({
        id,
        index,
        images: images?.map(
          (
            image: IFactoryInventoryStudioEditorCategoryImage,
            index: number
          ) => {
            return {
              ...image,
              index,
              orientation: orientation,
              objectFit: objectFit,
            };
          }
        ),
      });
      reset();
    }
  }, [id, setValue, index, images, reset, orientation, objectFit]);

  const moveImagePosition = useCallback(
    (index: number, direction: "down" | "up") => {
      setImages((current: IFactoryInventoryStudioEditorCategoryImage[]) => {
        if (!current) {
          return [];
        }
        let original: any[] = [...current];
        let siblingIndex = direction === "down" ? index - 1 : index + 1;
        if (siblingIndex < 0 || siblingIndex >= original?.length) {
          return current;
        }
        let targetPos = original?.findIndex((i: any) => i?.index === index);
        let siblingPos = original?.findIndex(
          (i: any) => i?.index === siblingIndex
        );
        if (targetPos === undefined || siblingPos === undefined) {
          return current;
        }
        original[targetPos] = {...original[targetPos], index: siblingIndex};
        original[siblingPos] = {...original[siblingPos], index: index};
        return sortImages(original);
      });
    },
    []
  );

  const removeImage = useCallback((index: number) => {
    setImages((current: IFactoryInventoryStudioEditorCategoryImage[]) => {
      if (!current) {
        return [];
      }
      let original: any[] = [...current]
        ?.filter(
          (i: IFactoryInventoryStudioEditorCategoryImage) => i?.index !== index
        )
        ?.map(
          (i: IFactoryInventoryStudioEditorCategoryImage, index: number) => {
            return {...i, index};
          }
        );
      return sortImages(original);
    });
  }, []);

  const changeImageField = useCallback(
    (index: number, field: string, value?: any) => {
      setImages((current: IFactoryInventoryStudioEditorCategoryImage[]) => {
        if (!current) {
          return [];
        }
        let original: any[] = [...current];
        let imageIndex = original?.findIndex(
          (i: IFactoryInventoryStudioEditorCategoryImage) => i?.index === index
        );
        original[imageIndex] = {...original[imageIndex], [field]: value};
        return sortImages(original);
      });
    },
    []
  );

  const addImage = useCallback((imageId: string | null) => {
    if (imageId === null) {
      return;
    }
    setImages((current: IFactoryInventoryStudioEditorCategoryImage[]) => {
      if (!current) {
        return [];
      }
      let newImage: IFactoryInventoryStudioEditorCategoryImage = {
        imageId: imageId,
        index: current?.length ?? 0,
        id: uuidv4(),
        orientation: undefined,
        objectFit: undefined,
      };
      return sortImages([...current, newImage]);
    });
  }, []);

  return (
    <RenderingFieldEditorContainer>
      <RenderingFieldEditorLayersWrapper>
        <RenderingFieldEditorLayersContainer>
          <RenderingFieldEditorLayerWrapper>
            <RenderingFieldEditorLayerContainer>
              <RenderingFieldEditorLayerImageContainer>
                <ImageAssignmentManager
                  key={`rendering-image-manager-new`}
                  imageIdValue={null}
                  onComplete={(value) => addImage(value)}
                  forceAllow={true}
                  hideImageDetailsEditor={true}
                  resetAfterUpload={true}
                />
              </RenderingFieldEditorLayerImageContainer>
            </RenderingFieldEditorLayerContainer>
          </RenderingFieldEditorLayerWrapper>
          {sortImages(images)
            ?.reverse()
            ?.map((image: IFactoryInventoryStudioEditorCategoryImage) => {
              return (
                <RenderingFieldEditorLayerWrapper>
                  <RenderingFieldEditorLayerContainer>
                    <RenderingFieldEditorLayerImageContainer>
                      <ImageAssignmentManager
                        hideImageDetailsEditor={true}
                        key={`rendering-image-manager-${image?.index}`}
                        imageIdValue={image?.imageId}
                        onComplete={(value) =>
                          changeImageField(0, "imageId", value)
                        }
                        forceAllow={true}
                      />
                    </RenderingFieldEditorLayerImageContainer>
                  </RenderingFieldEditorLayerContainer>
                  <RenderingFieldEditorLayerController>
                    <div>Layer {image?.index}</div>
                    <RenderingFieldEditorLayerButtonController>
                      <NatButton
                        label={"Move up"}
                        clickEvent={() => moveImagePosition(image?.index, "up")}
                        icon={{
                          icon: <NatUpChevron />,
                          iconPosition: IconPosition.ONLY,
                        }}
                        option={StyleOption.PRIMARY_BLACK}
                      />
                      <NatButton
                        label={"Move down"}
                        clickEvent={() =>
                          moveImagePosition(image?.index, "down")
                        }
                        icon={{
                          icon: <NatDownChevron />,
                          iconPosition: IconPosition.ONLY,
                        }}
                        option={StyleOption.PRIMARY_BLACK}
                      />
                      <NatButton
                        label={"Remove"}
                        clickEvent={() => removeImage(image?.index)}
                        icon={{
                          icon: <NatTrashIcon />,
                          iconPosition: IconPosition.ONLY,
                        }}
                        option={StyleOption.DESTRUCTIVE}
                      />
                    </RenderingFieldEditorLayerButtonController>
                  </RenderingFieldEditorLayerController>
                </RenderingFieldEditorLayerWrapper>
              );
            })}
        </RenderingFieldEditorLayersContainer>
        <RenderingFieldEditorBaseImageContainer>
          {images.length > 0 && (
            <>
              <ImageFitSelector
                id={`images-fit`}
                updateImageFit={(v?: string) => setObjectFit(v)}
                currentValue={objectFit}
              />
              <ImageOrientationSelector
                id={`images-orientation`}
                updateImageOrientation={(v?: string) => setOrientation(v)}
                currentValue={orientation}
              />
            </>
          )}
        </RenderingFieldEditorBaseImageContainer>
      </RenderingFieldEditorLayersWrapper>
      <RenderingFieldEditorPreview>
        <RenderingFieldEditorController>
          <NatButton
            label={"Save"}
            clickEvent={updateImageGroup}
            icon={{
              icon: <NatSaveIcon />,
              iconPosition: IconPosition.ONLY,
            }}
            size={NatSize.SMALL}
            disabled={!isDifferentFromCurrent}
            option={StyleOption.ADMIN_SOLID}
          />
          <NatButton
            label={"Reset"}
            clickEvent={() => setImages(initialValue?.images ?? [])}
            icon={{
              icon: <NatResetIcon />,
              iconPosition: IconPosition.ONLY,
            }}
            size={NatSize.SMALL}
            disabled={!isDifferentFromInitial}
            option={StyleOption.DESTRUCTIVE}
          />
        </RenderingFieldEditorController>
        <RenderingFieldEditorPreviewWrapper>
          <RenderingFieldEditorPreviewContainer>
            <RenderingPreview
              image={{
                overlayImages: sortImages(images),
                orientation: orientation,
                objectFit: objectFit,
              }}
            />
          </RenderingFieldEditorPreviewContainer>
        </RenderingFieldEditorPreviewWrapper>
      </RenderingFieldEditorPreview>
    </RenderingFieldEditorContainer>
  );
};

export default RenderingFieldEditor;
