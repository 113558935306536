import _ from "lodash";
import {Address, IAddressDetails, IAreaDetails} from "@natomas-org/core";
import {getURLParameter} from "../../cookies";
import {getItemFromLocalCache} from "../../cache";
import {
  addressLocalStorageKeyCity,
  addressLocalStorageKeyCountry,
  addressLocalStorageKeyCounty,
  addressLocalStorageKeyFullAddress,
  addressLocalStorageKeyLatitude,
  addressLocalStorageKeyLongitude,
  addressLocalStorageKeyState,
  addressLocalStorageKeyStreet,
  addressLocalStorageKeyStreetNumber,
  addressLocalStorageKeyZip,
} from "../../cache/constants";

export enum AddressSliceType {
  IP = "ip",
  CACHE = "cache",
  URL = "url",
  PROJECT = "project",
  PROPERTY_SEARCH = "property_search",
  AREA_SEARCH = "areaSearch",
}

export interface IAddressSlice {
  [AddressSliceType.CACHE]: IAddressDetails;
  [AddressSliceType.URL]?: IAddressDetails;
  [AddressSliceType.IP]?: IAddressDetails;
  [AddressSliceType.PROJECT]?: IAddressDetails;
  [AddressSliceType.PROPERTY_SEARCH]?: IAddressDetails;
  [AddressSliceType.AREA_SEARCH]?: IAreaDetails;
}

export const parseCoordinate = (c: string | null) => {
  if (c) return parseFloat(c);
  else return null;
};

const getURLParameters = (): IAddressDetails | undefined => {
  const blank: IAddressDetails = Address.dataToAddress();
  const url = Address.dataToAddress({
    full_address: getURLParameter("address") ?? undefined,
    street_number: getURLParameter("street_number") ?? undefined,
    street: getURLParameter("street") ?? undefined,
    city: getURLParameter("city") ?? undefined,
    state: getURLParameter("state") ?? undefined,
    zip: getURLParameter("postal_code") ?? undefined,
    county: getURLParameter("county") ?? undefined,
    country: getURLParameter("country") ?? undefined,
    latitude: parseCoordinate(getURLParameter("vex") ?? getURLParameter("lat")),
    longitude: parseCoordinate(
      getURLParameter("vey") ?? getURLParameter("lng")
    ),
  });
  if (_.isEqual(blank, url)) {
    return undefined;
  } else {
    return url;
  }
};

export const getCacheParameters = (): IAddressDetails => {
  return Address.dataToAddress({
    full_address:
      getItemFromLocalCache(addressLocalStorageKeyFullAddress) ?? undefined,
    street_number:
      getItemFromLocalCache(addressLocalStorageKeyStreetNumber) ?? undefined,
    street: getItemFromLocalCache(addressLocalStorageKeyStreet) ?? undefined,
    city: getItemFromLocalCache(addressLocalStorageKeyCity) ?? undefined,
    state: getItemFromLocalCache(addressLocalStorageKeyState) ?? undefined,
    zip: getItemFromLocalCache(addressLocalStorageKeyZip) ?? undefined,
    county: getItemFromLocalCache(addressLocalStorageKeyCounty) ?? undefined,
    country: getItemFromLocalCache(addressLocalStorageKeyCountry) ?? undefined,
    latitude: parseCoordinate(
      getItemFromLocalCache(addressLocalStorageKeyLatitude)
    ),
    longitude: parseCoordinate(
      getItemFromLocalCache(addressLocalStorageKeyLongitude)
    ),
  });
};

export const getInitialAddressState = (): IAddressSlice => {
  return {
    cache: getCacheParameters(),
    url: getURLParameters(),
  };
};
