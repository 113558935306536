import styled from "styled-components";
import {NatomasButton} from "../../../../_shared/generics/button/styles";
import {VILLA_BLUE} from "../../../../_shared/colors";

export const AssociationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const AssociationSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
`;

export const AssociationSectionContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;
  margin-left: 0.5rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-radius: 0 0 0 0.5rem;
  border-left: 1px solid ${VILLA_BLUE};
  border-bottom: 1px solid ${VILLA_BLUE};
  ${NatomasButton} {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;
