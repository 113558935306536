import {ICartItem, IProduct, UserRegistrationRequest} from "@natomas-org/core";
import {executeRequest} from "../APIExecutor";

export const syncUserInfoWithSalesForce = (user_id: string) => {
  return executeRequest("/user/v1/synchronize", {
    user_id,
  }).then((data) => {
    console.log(data);
  });
};

export const updateUserProductSelection = async (props: {
  customerId?: string;
  projectId?: string;
  cartItemId?: string;
  product?: IProduct;
}): Promise<ICartItem | null> => {
  const {cartItemId, customerId, product, projectId} = props;
  const {productGroupId, versionId, id} = product ?? {};
  console.log(customerId, projectId, product, cartItemId);
  return executeRequest("/project/v1/updateProductSelection", {
    customerId,
    projectId,
    productId: id,
    factoryId: productGroupId,
    versionId,
    cartItemId,
  }).then(
    (
      data: {
        cartItem: ICartItem;
        projectId: string;
      } | null
    ) => {
      console.log(data);
      if (data) {
        return data.cartItem;
      } else {
        return null;
      }
    }
  );
};

export const updateUserDesignConfiguration = (
  projectId: string,
  configuration: any
) => {
  console.log(projectId, configuration);
  return executeRequest("/project/v1/updateDesignConfiguration", {
    projectId: projectId,
    configuration: configuration,
  })
    .then((success) => {
      if (success.projectId) {
        return success.projectId;
      }
      return null;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export const registerUser = (
  request: UserRegistrationRequest
): Promise<any> => {
  return executeRequest("/user/v1/registration", request);
};
