import styled from "styled-components";
import {STEPH_SHADOW, WHITE} from "../../../../../../_shared/colors";
import {MODAL_Z_INDEX} from "../../../../../../_shared/styles";

export const FactoryManagementHeaderContainer = styled.div`
  height: 52px;
  position: sticky;
  top: 0;
  z-index: ${MODAL_Z_INDEX};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 8px;
  padding: 8px;
  justify-content: flex-start;
  align-items: center;
  box-shadow: ${STEPH_SHADOW};
  background-color: ${WHITE};
`;

export const FactoryManagementChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 8px;
  column-gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;
