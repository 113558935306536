import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {SUBTITLE_GRAY} from "../../../../map/constants";
import {GRANITE} from "../../../../_shared/colors";

export const PriceDisclaimerText = styled.div`
  color: ${GRANITE};
  padding-top: 0.5rem;
`;

export const MapProjectDetailsModalContainer = styled.div`
  height: fit-content;
  max-height: 90vh;
  width: 100%;
`;
export const MapProductImageContainer = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  overflow: hidden;
`;

export const MapProductInfoContainer = styled.div`
  padding: 1rem 2rem;
`;

export const MapProductTitleContainer = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  color: black;
  font-size: 1.75rem;
  padding-bottom: 0.5rem;
`;
export const MapProductSubtitleContainer = styled.div<{isMobile?: boolean}>`
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${SUBTITLE_GRAY};
  font-size: ${(props) => (props.isMobile ? "0.95" : "1.15rem;")};
  padding-bottom: 0.5rem;
`;

export const MapProductTotalPriceContainer = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  color: black;
  font-size: 1.15rem;
  padding-bottom: 0.5rem;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PriceBreakdownItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
  align-items: center;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const SiteWorkLineItemEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 2rem;
  font-size: 0.85rem;
  align-items: center;
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${SUBTITLE_GRAY};
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
`;
export const SiteWorkLineItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0;
  padding-left: 1rem;
`;
