import React, {useEffect} from "react";
import {
  NatModal,
  NatModalSize,
} from "../../../components/_shared/generics/modal/NatModal";
import AuthenticationContent from "./../../0-page/auth-content";
import {useRegistration} from "../../../components/_shared/hooks/useRegistration";
import {
  EventType,
  useTracking,
} from "../../../components/_shared/hooks/useTracking";
import {useCurrentUser} from "../../../components/_shared/hooks/useCurrentUser";
import {usePage} from "../../../components/_shared/hooks/usePage";
import {useLocation} from "react-router-dom";

// Implements NatModal and the content is AuthenticationContent
const AuthenticationModal = () => {
  const {pathname} = useLocation();
  const {isSignedIn, isAdmin} = useCurrentUser();
  const {visible, close} = useRegistration();
  const {trackEvent} = useTracking();

  const {isNatMobile} = usePage();

  useEffect(() => {
    if (visible) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
    return () => {
      window.document.body.style.overflow = "auto";
    };
  }, [visible]);

  useEffect(() => {
    if (visible && trackEvent) {
      trackEvent(EventType.REGISTRATION_PROMPT, {
        type: pathname,
      });
    }
  }, [trackEvent, visible]);

  if (isAdmin) {
    return null;
  }

  return (
    <NatModal
      customPadding={isNatMobile ? "16px" : "0"}
      handleClose={isSignedIn ? undefined : close}
      hideCloseIcon={isSignedIn}
      onlyShowContent={true}
      size={NatModalSize.NORMAL}
      show={visible}
      content={<AuthenticationContent />}
    />
  );
};

export default AuthenticationModal;
