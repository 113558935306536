import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {
  IDocumentDetails,
  IProjectNote,
  IProjectNoteDetails,
  IProjectTaskInfo,
  IProjectTaskStatus,
  IProjectTaskType,
  ProjectNoteType,
} from "@natomas-org/core";
import {FormType, getFinalTaskPresetList} from "./note_structure";
import {AdminInput} from "../../../../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {NatStyledForm} from "../../../../../../_shared/styles/AdminStyles";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {
  ProjectNoteFormContainer,
  ProjectNoteFormFooter,
  ProjectNoteFormTitle,
  ProjectNoteFormWrapper,
} from "./styles";
import {useForm} from "react-hook-form";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {RichTextEditor} from "../../../../../../../_shared/generics/rich-text-editor/RichTextEditor";
import {ProjectDocumentUploadButton} from "../../../../../../../_shared/generics/documents/components/ProjectDocumentUploadButton";
import {DocumentDetailsPreview} from "../../../../../../../_shared/generics/documents/components/DocumentDetailsPreview";
import {IUploadProjectFileOptions} from "../../../../../../../_shared/generics/documents/helpers";
import {
  createOrUpdateProjectNote,
  deleteProjectNoteId,
} from "../../../../../../../../database/firebase/api/project";
import {NatFlex} from "../../../../../../../_shared/generics/flex/NatFlex";
import {DatePickerInputProps} from "../../../../../../_shared/components/AdminComponentFactory/AdminInputProps";
import {useActiveProjectDocuments} from "../../../../../../../_shared/hooks/useProject/useActiveProjectDocuments";
import {useCurrentCustomer} from "../../../../../../../_shared/hooks/useCurrentCustomer";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";

// Import the Slate components and React plugin.

export interface ProjectNoteFormProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  resetNote?: () => void;
  note?: IProjectNoteDetails;
  projectId: string;
  initialNoteType?: ProjectNoteType;
}

interface IFormState {
  timestamp: number | null;
  type: ProjectNoteType | null;
  title: string;
  content: string;
  content_html: string;
  notify: boolean;
  documentIds: string[];
  details?: IProjectNoteDetails["details"];
}

export const getInitialRichTextEditorValues = (
  note: IProjectNoteDetails | undefined,
  initialNoteType?: ProjectNoteType
): IFormState => {
  if (note == null) {
    return {
      timestamp: new Date().getTime(),
      type: initialNoteType ?? null,
      title: "",
      content: "",
      content_html: "",
      notify: true,
      documentIds: [],
    };
  }

  return {
    timestamp: note.timestamp ?? new Date().getTime(),
    type: note.type ?? null,
    title: note.title ?? "",
    content: note.content ?? "",
    content_html: note.content_html ?? "",
    notify: note.notify_customer ?? true,
    documentIds: note.documentIds ?? [],
    details: note.details ?? undefined,
  };
};

export function sanitizeHTMLToText(value: string) {
  let textContent = value.replace(/&nbsp;/g, " ");

  // divs and lis are new lines
  textContent = textContent.replace(/<h[^>]*>?/gm, "\n");
  textContent = textContent.replace(/<p[^>]*>?/gm, "\n");
  textContent = textContent.replace(/<div[^>]*>?/gm, "\n");
  textContent = textContent.replace(/<li[^>]*>?/gm, "\n");

  // the rest of the tags are removed
  textContent = textContent.replace(/<[^>]*>?/gm, "");

  // never allow more than 2 consecutive new lines
  return textContent.replace(/\n{3,}/g, "\n\n");
}

export const ProjectNoteForm = (props: ProjectNoteFormProps) => {
  const {show, setShow, note, resetNote, projectId, initialNoteType} = props;
  const {info, mergedCustomerProjectInfos} = useActiveProject();
  const {user_id} = useCurrentCustomer();
  // This needs to be updated to use the note/task project documents
  const {projectDocuments} = useActiveProjectDocuments();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const initialValues = getInitialRichTextEditorValues(note, initialNoteType);
  const {register, watch, setValue, reset, handleSubmit} = useForm({
    defaultValues: initialValues,
  });
  const documentIds: string[] = watch("documentIds") as string[];

  const documents: IDocumentDetails[] = useMemo(() => {
    return projectDocuments?.filter((document) => {
      return documentIds.includes(document?.document_id);
    });
  }, [documentIds, projectDocuments]);
  const saveProjectNote = (values: IFormState) => {
    if (!values.type) {
      return;
    }

    setSubmitting(true);
    const noteTimestamp =
      values.type === ProjectNoteType.TASK || !values.timestamp
        ? new Date().getTime()
        : values.timestamp;

    const infoToSave: IProjectNote = {
      timestamp: noteTimestamp,
      title: values.title,
      content: values.content,
      content_html: values.content_html,
      documentIds: values.documentIds,
      hidden: false,
      type: values.type,
      details: values.details,
      notify_customer: values.notify,
    };

    if (values.type !== ProjectNoteType.TASK) {
      infoToSave.details = undefined;
    }

    if (projectId) {
      return createOrUpdateProjectNote({
        projectId,
        userId: user_id,
        noteId: note?.source_id,
        note: infoToSave,
      })
        .then(() => {
          resetForm();
        })
        .finally(() => {
          setShow(false);
        });
    } else {
      setSubmitting(false);
      setShow(false);
    }
  };

  const resetForm = () => {
    reset();
    resetNote?.();
    setShow(false);
    setSubmitting(false);
  };

  const deleteNote = () => {
    if (note?.source_id) {
      return deleteProjectNoteId(projectId, note.source_id).then(() => {
        return resetForm();
      });
    }
    return null;
  };

  if (!show) {
    return <></>;
  }

  if (submitting) {
    return <div>Submitting...</div>;
  }

  const formType = watch("type");
  const timestampTitle =
    formType === ProjectNoteType.TASK ? "Completed date (optional)" : "Date";
  const timestampKey =
    formType === ProjectNoteType.TASK
      ? "details.completion_timestamp"
      : "timestamp";
  const timestamp = watch(timestampKey);
  const taskKey = watch("details.task_key") as string;

  const isNewNote = note == null;

  return (
    <ProjectNoteFormWrapper>
      <ProjectNoteFormTitle>
        {isNewNote ? "New note" : "Edit note"}
      </ProjectNoteFormTitle>
      <ProjectNoteFormContainer>
        <NatStyledForm onSubmit={handleSubmit(saveProjectNote)}>
          {FormType.map((keyInfo: any) => {
            const {key} = keyInfo;
            const value = watch(key);

            return (
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                onChange={(value: any) => {
                  setValue(key, value);
                }}
              />
            );
          })}
          <div hidden={!formType}>
            <hr />
            <div>
              <NatFlex VERTICAL_ALIGN={NatVerticalAlign.TOP}>
                <div
                  style={{flexGrow: 1, maxWidth: "600px", position: "relative"}}
                >
                  <NatLabel
                    hidden={formType === ProjectNoteType.TASK}
                    label={"Title"}
                    type={NatLabelType.H4}
                    weight={NatLabelWeight.MEDIUM}
                  />
                  <div hidden={formType !== ProjectNoteType.TASK}>
                    <AdminInput
                      onChange={(projectNoteTemplate: IProjectNote) => {
                        const details = watch("details");
                        const newDetails: IProjectTaskInfo = {
                          ...details,
                          type:
                            projectNoteTemplate.details?.type ??
                            IProjectTaskType.GENERIC,
                          task_key: projectNoteTemplate.details?.task_key ?? "",
                          completion_timestamp: details?.completion_timestamp,
                          status:
                            details?.status ??
                            projectNoteTemplate.details?.status ??
                            IProjectTaskStatus.UNKNOWN,
                          task_details:
                            projectNoteTemplate.details?.task_details,
                        };

                        setValue("details", newDetails);
                        setValue("details.task_key", newDetails.task_key ?? "");
                        setValue(
                          "details.completion_timestamp",
                          details?.completion_timestamp
                        );
                        setValue(
                          "details.status",
                          details?.status ?? IProjectTaskStatus.UNKNOWN
                        );
                        setValue("title", projectNoteTemplate.title);

                        if (projectNoteTemplate.content_html) {
                          setValue(
                            "content",
                            sanitizeHTMLToText(projectNoteTemplate.content_html)
                          );
                          setValue(
                            "content_html",
                            projectNoteTemplate.content_html
                          );
                        }
                      }}
                      value={taskKey}
                      fieldInfo={getFinalTaskPresetList(
                        mergedCustomerProjectInfos?.opportunity_id,
                        mergedCustomerProjectInfos?.code
                      )}
                    />
                  </div>

                  <input
                    {...register("title")}
                    hidden={
                      formType === ProjectNoteType.TASK && taskKey !== "generic"
                    }
                    placeholder={"Title"}
                    style={{width: "100%", height: "2.5rem"}}
                  />
                </div>
                <div style={{minWidth: "200px"}}>
                  <AdminInput
                    onChange={(value: number | null) => {
                      setValue(timestampKey, value);
                      if (formType === ProjectNoteType.TASK) {
                        if (!value) {
                          setValue(
                            "details.status",
                            IProjectTaskStatus.UNKNOWN
                          );
                        } else {
                          setValue(
                            "details.status",
                            IProjectTaskStatus.COMPLETED
                          );
                        }
                      }
                    }}
                    value={timestamp}
                    fieldInfo={
                      {
                        key: "start-date",
                        name: timestampTitle,
                        type: "date-picker",
                        showYearDropdown: false,
                        showTime: true,
                        resetType:
                          formType === ProjectNoteType.TASK ? "clear" : "reset",
                        onChangeSanitizer: (value: Date | null) => {
                          if (!value) {
                            return null;
                          }
                          return value.getTime();
                        },
                        valueSanitizer: (value: number | null) => {
                          if (!value) {
                            return null;
                          }
                          return new Date(value);
                        },
                      } as DatePickerInputProps["fieldInfo"]
                    }
                  />
                </div>
              </NatFlex>
            </div>

            <div>
              <NatLabel
                label={"Description"}
                type={NatLabelType.H4}
                weight={NatLabelWeight.MEDIUM}
              />
              <RichTextEditor
                value={watch("content_html")}
                onChange={(value) => {
                  const textContent = sanitizeHTMLToText(value);
                  setValue("content_html", value);
                  setValue("content", textContent);
                }}
              />
              <br />
              <div hidden={!!note}>
                <NatFlex VERTICAL_ALIGN={NatVerticalAlign.CENTER}>
                  <input
                    {...register("notify")}
                    type={"checkbox"}
                    style={{height: "1rem", width: "1rem"}}
                  />
                  <NatLabel
                    label={`Notify customer of ${formType} (optional)`}
                    type={NatLabelType.P2}
                  />
                </NatFlex>
                <NatLabel
                  label={`Customer will be notified via email that this ${formType} has been posted on their portal.`}
                  type={NatLabelType.P2}
                  style={{color: "#807D7A"}}
                />
              </div>
              <NatLabel
                label={"Attachments (optional)"}
                type={NatLabelType.H4}
                weight={NatLabelWeight.MEDIUM}
              />
              {documents.map((document, index: number) => {
                if (projectDocuments == null || !info) {
                  return <></>;
                }

                return (
                  <DocumentDetailsPreview
                    document={document}
                    documents={documents}
                    key={`${document?.document_id}${index}`}
                    projectId={projectId}
                    onHide={() => {}}
                    onDelete={() => {
                      const newDocumentIds = documentIds.filter(
                        (id) => id !== document?.document_id
                      );
                      setValue("documentIds", newDocumentIds as never[]);
                    }}
                  />
                );
              })}

              <ProjectDocumentUploadButton
                projectId={projectId}
                onComplete={(documentDetails) => {
                  if (documentDetails?.document_id != null) {
                    const latestDocumentIds = watch("documentIds") as string[];
                    setValue("documentIds", [
                      ...latestDocumentIds,
                      documentDetails?.document_id,
                    ] as never[]);
                  }
                }}
                documentId={undefined}
                documentTitle={undefined}
                allowMultiple={true}
                buttonName={"Add attachment(s)"}
                uploadOptions={
                  {
                    defaultHiddenInProject: true,
                  } as IUploadProjectFileOptions
                }
              />
            </div>
          </div>
          <ProjectNoteFormFooter>
            <NatButton
              label={"Cancel"}
              type={"button"}
              clickEvent={resetForm}
              option={StyleOption.SECONDARY}
            />
            <NatButton
              hidden={isNewNote}
              spinnerEnabled={true}
              label={`Delete ${formType}`}
              type={"button"}
              clickEvent={deleteNote}
              option={StyleOption.DESTRUCTIVE}
            />
            <NatButton
              disabled={!formType}
              label={`${!isNewNote ? "Save" : "Create"} ${formType ?? ""}`}
              type={"submit"}
            />
          </ProjectNoteFormFooter>
        </NatStyledForm>
      </ProjectNoteFormContainer>
    </ProjectNoteFormWrapper>
  );
};
