import {useQuery} from "@tanstack/react-query";
import {getLeadService} from "./index";

const fetchLeadBy = async (): Promise<boolean> => {
  try {
    return getLeadService().then(async (service) => {
      return service.getLeadExists().then((response) => {
        return response;
      });
    });
  } catch (error) {
    console.error("LeadServiceError", error);
    return true;
  }
};

const useLeadExists = (auth_user_id?: string) => {
  const id = `lead-exists-check-${auth_user_id ?? "undefined"}`;
  return useQuery<boolean, Promise<boolean>>([id], fetchLeadBy);
};

export default useLeadExists;
