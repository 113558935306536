import React, {useCallback} from "react";
import {ImageCategory, ImageStatus, IProductImage} from "@natomas-org/core";
import {ImageAssignmentManager} from "../../../../../../../../../_shared/generics/image/ImageAssignmentManager";
import Select from "react-select";
import styled from "styled-components";
import {GRAY} from "../../../../../../../../../_shared/colors";

const ImageInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;
  min-height: 300px;
  height: 300px;
  min-width: 816px;
  width: 100%;
`;

const ImageInputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: flex-start;
  justify-content: center;
  min-width: 300px;
`;

const statusOptions = [
  {label: "Unlisted", value: ImageStatus.UNLISTED as string},
  {label: "Listed", value: ImageStatus.LISTED as string},
];
const categoryOptions = Object.values(ImageCategory).map((o: string) => {
  return {label: o, value: o};
});

interface CatalogImageInputProps {
  id: string;
  onChange: (value: IProductImage | null) => void;
  initialValue:
    | IProductImage
    | {imageId: null; status: ImageStatus.UNLISTED; categories: undefined};
}

const CatalogImageInput = (props: CatalogImageInputProps) => {
  const {id, onChange, initialValue} = props;

  const updateImage = useCallback(
    (field: "imageId" | "status" | "categories", value: any) => {
      let image = {...initialValue};
      image[field] = value;
      if (image.imageId !== null) {
        onChange(image);
      } else {
        onChange(null);
      }
    },
    [initialValue, onChange]
  );

  return (
    <ImageInputContainer>
      <ImageAssignmentManager
        key={`catalog-image-manager-${id}`}
        imageIdValue={initialValue?.imageId}
        onComplete={(value) => {
          updateImage("imageId", value);
        }}
        forceAllow={true}
      />
      {initialValue?.imageId && (
        <ImageInputFieldContainer>
          <Select
            onChange={(option) => {
              updateImage("status", option?.value);
            }}
            placeholder={"Status"}
            options={statusOptions}
            value={
              statusOptions.find((o) => o.value === initialValue?.status) ??
              undefined
            }
          />
          <Select
            isMulti={true}
            onChange={(options) => {
              updateImage(
                "categories",
                options?.map((o) => o?.value)
              );
            }}
            placeholder={"Groups"}
            options={categoryOptions}
            value={initialValue?.categories?.map((value) =>
              categoryOptions.find((o) => o.value === value)
            )}
          />
          <p style={{fontSize: "11px", color: GRAY}}>
            Note: Floor Plan images will always be Fitted, regardless of their
            crop setting. However, if the image is also used for a different
            section, it will utilize the crop setting.
          </p>
        </ImageInputFieldContainer>
      )}
    </ImageInputContainer>
  );
};

export default CatalogImageInput;
