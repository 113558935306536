import {NatomasMapFeatureReadRequest} from "@natomas-org/core";
import {executeRequest} from "../api";
import {store} from "../../../store";
import {storeMapFeature} from "../../../components/_shared/slices/NatomasMapSlice/NatomasMapSlice";

let fetched: {[id: string]: boolean} = {};

export const fetchMappingFeature = (id?: string) => {
  if (!id || fetched[id]) {
    return;
  } else {
    fetched[id] = true;
  }
  if (id) {
    return executeRequest("/map/v1/find-mapping-feature", {
      id: id,
    } as NatomasMapFeatureReadRequest).then((r) => {
      if (r?.id) {
        store.dispatch(
          storeMapFeature({
            id: r?.id,
            mapping: r,
          })
        );
      } else {
        store.dispatch(
          storeMapFeature({
            id: id,
            mapping: null,
          })
        );
      }
    });
  }
};
