import {useCurrentCustomer} from "../../useCurrentCustomer";
import {Customer} from "@natomas-org/core";
import React, {useState} from "react";
import {useCurrentUser} from "../../useCurrentUser";
import {
  AvatarBreak,
  AvatarDropdownContent,
  AvatarDropdownHeader,
  AvatarDropdownHeaderText,
  AvatarDropdownHeaderWrapper,
  AvatarEmail,
  AvatarName,
} from "./styles";
import {ReferralRock} from "../../../../portal/_shared/components/ReferralRock";
import {useUrl} from "../../useUrl";
import {useCustomerStatus} from "../../useCustomerStatus";
import {useGlobalConfiguration} from "../../useGlobalConfiguration";
import {ProjectSwitcherModal} from "../../../../portal/_shared/components/ProjectSwitcherModal";
import {
  getAllDropdownResourceItems,
  getAllDropdownTodoItems,
  getDashboardOptions,
  getSignedInAdminToDoOptions,
  getSignedInResourcesOptions,
  getSignedInToDoOptions,
  getSignedOutResourcesOptions,
  getSignedOutToDoOptions,
} from "./dropdownItems";
import {determineSelectedOption} from "./helpers";
import {toProfile} from "./navigation";
import {
  Avatar,
  AvatarComponentMapping,
  AvatarPill,
  ProgressSVG,
} from "./components";
import {AvatarDropdownWarning} from "./AvatarDropdownWarning";
import {WARNING_PROJECT_IS_ARCHIVED} from "./AvatarDropdownWarning/constants";
import {useNavigation} from "../index";
import {NatDropdown} from "../../../generics/popup/dropdown/NatDropdown";
import {usePage} from "../../usePage";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
} from "../../../generics/popup/dropdown/constants";
import {NatSizeType, StyleOption} from "../../../generics/_shared";
import useActiveProject from "../../useProject/useActiveProject";
import {JSXDropdownOption} from "../../../generics/popup/dropdown/styles";
import {NavigationPaths} from "../paths";

export const AvatarDropdown = () => {
  const [showReferralRock, setShowReferralRock] = useState(false);
  const [isSwitchingProjects, setIsSwitchingProjects] = useState(false);
  const [show, setShow] = useState(false);
  const {customer} = useCurrentCustomer();
  const {isNatMobile} = usePage();
  // TODO implement archive flag in projects
  const isArchived = false;
  const {address: projectAddress, id: projectId} = useActiveProject();
  const {status, customerStatus, isCustomerPreDeposit} = useCustomerStatus();
  const {IS_REFERRAL_ENABLED} = useGlobalConfiguration();
  const {to} = useNavigation();
  const {isAdmin, loggedInUser} = useCurrentUser();
  const {pageId, getURLPath} = useUrl();
  const shouldDisplayPostDepositPortalV2 = !isCustomerPreDeposit;
  const width = customer ? 270 : 200;
  let initials = "";
  let firstName = "";
  let lastName = "";
  if (customer) {
    firstName = Customer.getFirstName(customer);
    lastName = Customer.getLastName(customer);
  }
  if (firstName) {
    initials += firstName.substring(0, 1).toUpperCase();
  }
  if (lastName) {
    initials += lastName.substring(0, 1).toUpperCase();
  }
  const DROPDOWN_TODO_ITEMS = getAllDropdownTodoItems(
    status,
    customerStatus,
    to,
    projectAddress,
    projectId
  );
  const DROPDOWN_RESOURCES_ITEMS = getAllDropdownResourceItems(
    () => {
      setShowReferralRock(!showReferralRock);
    },
    () => {
      setIsSwitchingProjects(true);
    },
    to,
    projectId,
    isAdmin ? loggedInUser?.email ?? null : null
  );

  const DropdownContent = (props: {
    style?: React.CSSProperties;
  }): JSX.Element => {
    const {id, isCartEnabled} = useActiveProject();
    // Dashboard options (top of dropdown)
    const dashboardOptionList = getDashboardOptions(
      isAdmin,
      shouldDisplayPostDepositPortalV2,
      isCustomerPreDeposit
    );
    const selectedDashboardOption = determineSelectedOption(
      dashboardOptionList,
      pageId,
      getURLPath()
    );
    const JSXDashboardOptions = AvatarComponentMapping(
      dashboardOptionList,
      DROPDOWN_TODO_ITEMS,
      selectedDashboardOption,
      30
    );

    // Avatar checklist (center of dropdown, where applicable)
    const checklistOptions = isAdmin
      ? getSignedInAdminToDoOptions(
          !!customer,
          isCustomerPreDeposit,
          shouldDisplayPostDepositPortalV2
        )
      : loggedInUser
      ? getSignedInToDoOptions(
          isCustomerPreDeposit,
          shouldDisplayPostDepositPortalV2
        )
      : getSignedOutToDoOptions();
    const selectedChecklistOption = determineSelectedOption(
      checklistOptions,
      pageId,
      getURLPath()
    );
    const JSXToDoList = AvatarComponentMapping(
      checklistOptions,
      DROPDOWN_TODO_ITEMS,
      selectedChecklistOption
    );

    //Extra options (bottom section of dropdown)
    const extraOptions =
      isAdmin || loggedInUser
        ? getSignedInResourcesOptions(
            shouldDisplayPostDepositPortalV2,
            isCustomerPreDeposit,
            customer,
            IS_REFERRAL_ENABLED,
            isArchived
          )
        : getSignedOutResourcesOptions(IS_REFERRAL_ENABLED);
    const selectedExtraOption = determineSelectedOption(
      extraOptions,
      pageId,
      getURLPath()
    );
    const JSXExtras = AvatarComponentMapping(
      extraOptions,
      DROPDOWN_RESOURCES_ITEMS,
      selectedExtraOption,
      30
    );
    return (
      <AvatarDropdownContent
        width={width - 4}
        id={"avatar-dropdown-content"}
        style={props.style}
      >
        {isArchived && (
          <AvatarDropdownWarning warning={WARNING_PROJECT_IS_ARCHIVED} />
        )}
        {customer && (
          <AvatarDropdownHeaderWrapper
            width={width - 4}
            onClick={() => {
              toProfile(to, id);
            }}
            id={"account-information"}
          >
            <AvatarDropdownHeader>
              <Avatar
                size={40}
                isLarge={true}
                initials={initials}
                isAdmin={isAdmin}
                isArchived={isArchived}
                customer={customer}
                loggedInUser={loggedInUser}
              />
              <AvatarDropdownHeaderText>
                <AvatarName>
                  {firstName} {lastName}
                </AvatarName>
                <AvatarEmail>{Customer.getContactEmail(customer)}</AvatarEmail>
              </AvatarDropdownHeaderText>
            </AvatarDropdownHeader>
          </AvatarDropdownHeaderWrapper>
        )}
        {JSXDashboardOptions}
        {dashboardOptionList.length > 0 && <AvatarBreak />}
        {ProgressSVG(checklistOptions, DROPDOWN_TODO_ITEMS)}
        {JSXToDoList}
        {checklistOptions.length > 0 && <AvatarBreak />}
        {isCartEnabled && (
          <JSXDropdownOption
            key={"nat-dropdown-" + id}
            disabled={false}
            onClick={() => {
              to(NavigationPaths.PORTAL, {page: "cart"});
            }}
            active={"cart" === pageId}
          >
            {"Cart"}
          </JSXDropdownOption>
        )}
        {JSXExtras}
      </AvatarDropdownContent>
    );
  };

  return (
    <>
      <NatDropdown
        contentItemArray={[
          {
            id: "avatar-dropdown",
            component: (
              <div style={isNatMobile ? {justifyContent: "center"} : undefined}>
                <DropdownContent />
              </div>
            ),
            label: "Avatar Dropdown",
            style: {justifyContent: "center"},
          },
        ]}
        handleClose={() => {
          setShow(false);
        }}
        style={{
          ...NatDropdownStyles[DropdownStyles.SINGLE_ELEMENT][
            NatSizeType.NORMAL
          ],
          DROPDOWN_BUTTON_STYLE: StyleOption.ICON_ONLY,
          ADDITIONAL_STYLE_OPTIONS: [
            NatPopupStyleOptions.NO_ITEMS_BORDER,
            NatPopupStyleOptions.RIGHT_ALIGNED,
            NatPopupStyleOptions.NO_PADDING,
            NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
            NatPopupStyleOptions.CLOSE_ON_SELECT,
          ],
          popupCSS: {justifyContent: "center"},
        }}
        show={show}
        setShow={setShow}
        toggleButtonOrLabel={
          <AvatarPill
            initials={initials}
            shouldDisplayPostDepositPortalV2={shouldDisplayPostDepositPortalV2}
            onClick={() => {
              setShow(!show);
            }}
          />
        }
      />
      <ReferralRock show={showReferralRock} setShow={setShowReferralRock} />

      <ProjectSwitcherModal
        show={isSwitchingProjects}
        handleClose={() => {
          setIsSwitchingProjects(false);
        }}
      />
    </>
  );
};
