import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK, CONTAINER_BACKGROUND_COLOR} from "../../../../_shared/colors";

export const AdminBlankScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  background-color: ${CONTAINER_BACKGROUND_COLOR};
`;

export const AdminBlankScreenTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: ${BLACK}
  font-family: ${FONT_FAMILY_BOLD};
`;

export const AdminBlankScreenDescription = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${BLACK};
`;
