import useActivitySlice from "../../components/_shared/hooks/useActivity/useActivitySlice";
import {useEffect} from "react";
import useActivitySliceDispatcher from "../../components/_shared/hooks/useCustomer/useActivitySliceDispatcher";
import {useDispatch} from "react-redux";
import {useProject} from "../../components/_shared/hooks/useProject/useProject";
import {setProjectAddress} from "../../components/_shared/slices/AddressSlice";
import {useUrl} from "../../components/_shared/hooks/useUrl";
import useCartLibrary from "../../components/_shared/hooks/useCart/useCartLibrary";
import {Utilities} from "../../database/firebase";
import {isBlankString, USER_COLLECTION_KEY} from "@natomas-org/core";
import {useCurrentUser} from "../../components/_shared/hooks/useCurrentUser";
import {replaceURLQueryKeyValue} from "../../components/_shared/navigation/_helpers";
import {URL_PARAM_KEY_PROJECT_ID_DEPRECATED} from "@natomas-org/service";

const ActivitySliceManager = () => {
  const {activeCustomerId, activeCartItemId, activeProjectId} =
    useActivitySlice();
  const {isAdmin} = useCurrentUser();
  const {uid: urlUid, pid: urlPid, cid: urlCid, item: urlItemId} = useUrl();

  const {address, isCartEnabled} = useProject(activeProjectId);
  const {
    allAvailableCartItemIds,
    allCustomerCartItemIds,
    activeProjectCartItemIds,
  } = useCartLibrary();
  const dispatch = useDispatch();
  const {setActiveCustomerById, setActiveCartItemById, setActiveProjectById} =
    useActivitySliceDispatcher();

  /**
   * Initialize the active cart item id
   * - If there is no active item, but a URL cid, and it is available => use it
   * - If there is no active item, and no URL cid, and cart is not enabled => use the first available [DTC]
   * - Otherwise, do not default the active cart item id
   */
  useEffect(() => {
    if (!activeCartItemId) {
      if (urlItemId && allAvailableCartItemIds?.includes(urlItemId)) {
        setActiveCartItemById(urlItemId);
      } else if (!isCartEnabled && activeProjectCartItemIds?.length > 0) {
        setActiveCartItemById(activeProjectCartItemIds[0]);
      }
    }
  }, [
    activeCartItemId,
    allAvailableCartItemIds,
    isCartEnabled,
    activeProjectCartItemIds,
    setActiveCartItemById,
    urlItemId,
  ]);

  /**
   * Managing the active cart item id (it is assigned)
   * 1. If no active cart item, do nothing
   * 2. If active cart item
   *  a. If cart is enabled
   *    i. If the active cart item exists under the customer (library, active/other projects), do nothing
   *    ii. Otherwise, clear the active cart item id
   *  b. If cart is not enabled
   *    i. If the active cart item exists under the active project, do nothing
   *    ii. Otherwise, set the active cart item id to the first available project cart item id or undefined
   */
  useEffect(() => {
    if (activeCartItemId) {
      if (isCartEnabled) {
        if (allCustomerCartItemIds?.includes(activeCartItemId)) {
          return;
        } else {
          setActiveCartItemById(undefined);
        }
      } else {
        if (activeProjectCartItemIds?.includes(activeCartItemId)) {
          return;
        } else {
          setActiveCartItemById(activeProjectCartItemIds?.[0] ?? undefined);
        }
      }
    }
  }, [
    activeCartItemId,
    activeProjectCartItemIds,
    allCustomerCartItemIds,
    isCartEnabled,
    setActiveCartItemById,
  ]);

  if (urlCid) {
    setActiveProjectById(urlCid);
    replaceURLQueryKeyValue(URL_PARAM_KEY_PROJECT_ID_DEPRECATED, null);
  }

  // Initialize the active customer id with the URL uid
  useEffect(() => {
    if (urlUid && !activeCustomerId) {
      setActiveCustomerById(urlUid);
    } else if (!urlUid && activeCustomerId) {
      setActiveCustomerById(undefined);
    }
  }, [activeCustomerId, setActiveCustomerById, urlUid]);

  useEffect(() => {
    if (address) {
      dispatch(setProjectAddress(address));
    }
  }, [activeProjectId, address, dispatch]);

  // Initialize the active project id with the URL pid or clear it
  useEffect(() => {
    if (urlPid && !activeProjectId) {
      setActiveProjectById(urlPid);
    } else if (!urlPid && activeProjectId) {
      setActiveProjectById(activeProjectId);
    }
  }, [activeCustomerId, activeProjectId, setActiveProjectById, urlPid]);

  // Always make sure the uid is correct if a pid is set and no uid is set
  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    const pid = urlPid ?? activeProjectId;
    let uid = urlUid ?? activeCustomerId;
    if (pid && !uid) {
      console.log("Getting uid from pid", pid);
      Utilities.collection(USER_COLLECTION_KEY)
        .where("project_ids", "array-contains", pid)
        .get()
        .then((queryResults) => {
          const retrievedUID: string | undefined = queryResults?.docs[0]?.id;
          if (retrievedUID && !isBlankString(retrievedUID)) {
            uid = retrievedUID;
            console.log(`Setting uid (${uid}) from pid (${pid})`);
            setActiveCustomerById(uid);
            setActiveProjectById(pid);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    activeCustomerId,
    activeProjectId,
    isAdmin,
    setActiveCustomerById,
    setActiveProjectById,
  ]);

  return null;
};

export default ActivitySliceManager;
