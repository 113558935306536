import {useEffect} from "react";
import {
  Address,
  DateUtils,
  IAddressDetails,
  IPropertyData,
  PROPERTY_DATA_COLLECTION_KEY,
} from "@natomas-org/core";
import {useAddress} from "../hooks/useAddress";
import {useIsPublicApp} from "../hooks/useIsPublicApp";
import {useCurrentUser} from "../hooks/useCurrentUser";
import {Utilities} from "../../../database/firebase";
import {store} from "../../../store";
import {useQuery} from "@tanstack/react-query";
import {executeRequest} from "../../../database/firebase/api";
import {
  setProjectPropertyData,
  setPublicPropertyData,
} from "../slices/PropertyDataSlice/PropertyDataSlice";
import {setSearchAddress} from "../slices/AddressSlice";
import {useDispatch} from "react-redux";

/*
 * ------------------------------
 *  Public Property Data manager
 * ------------------------------
 * This is the react query data manager that is responsible for fetching
 * property information for the catalog and Map.
 * */
let lastPublicSubscriptionUnsubscribe: any = null;
let lastProjectSubscriptionUnsubscribe: any = null;

export const PropertyDataManager = () => {
  const {propertySearch, publicAddress, projectAddress} = useAddress();
  const isPublicApp = useIsPublicApp();
  const {loggedInUser} = useCurrentUser();
  const dispatch = useDispatch();
  useFetchPropertyData(publicAddress ?? undefined);
  useFetchPropertyData(projectAddress ?? undefined);

  // -------------------------
  // - DERIVE ADDRESS TO USE -
  // -------------------------
  let addressToSearch: IAddressDetails | undefined = undefined;
  if (isPublicApp) {
    addressToSearch = propertySearch ?? undefined;
  }
  // If the user is using a public app (Map or Public Catalog) and there is
  //  no value in propertySearch, we check to see if a valid property address
  //  exists in publicAddress and, if so, we search for that property.
  //  We do this because publicAddress uses cache and url as potential sources.
  if (
    isPublicApp &&
    !!publicAddress &&
    !Address.isInvalidAddress(publicAddress) &&
    addressToSearch === undefined &&
    !loggedInUser // If directly linking to public app, the !loggedInUser check
    //  doesn't function as designed since the user will load after this is executed.
    //  This is okay.
  ) {
    dispatch(setSearchAddress(publicAddress));
  }

  // PUBLIC COLLECTION SUBSCRIPTION
  let publicAddressId: string | null = Address.getId(addressToSearch);
  useEffect(() => {
    if (lastPublicSubscriptionUnsubscribe) {
      console.log(`Unsubscribing from previous public address collection.`);
      lastPublicSubscriptionUnsubscribe();
    }
    if (!publicAddressId) {
      return;
    }
    const documentData = Utilities.collection(PROPERTY_DATA_COLLECTION_KEY).doc(
      publicAddressId
    );
    lastPublicSubscriptionUnsubscribe = documentData.onSnapshot(
      (snapshot) => {
        console.log(
          `Subscribing to public address collection (ID: ${publicAddressId}`
        );
        if (snapshot.exists) {
          store.dispatch(
            setPublicPropertyData(snapshot.data() as IPropertyData)
          );
        }
      },
      (e) => {
        console.log(
          `Error on public collection subscription (ID: ${publicAddressId}`
        );
        console.log(e);
      }
    );
  }, [publicAddressId]);

  // PROJECT COLLECTION SUBSCRIPTION
  let projectAddressId: string | null = null;
  if (!!loggedInUser && !Address.isInvalidAddress(projectAddress)) {
    projectAddressId = Address.getId(projectAddress);
  }
  useEffect(() => {
    if (lastProjectSubscriptionUnsubscribe) {
      console.log(`Unsubscribing from previous project address collection.`);
      lastProjectSubscriptionUnsubscribe();
    }
    if (!projectAddressId) {
      return;
    }

    const documentData = Utilities.collection(PROPERTY_DATA_COLLECTION_KEY).doc(
      projectAddressId
    );
    lastProjectSubscriptionUnsubscribe = documentData.onSnapshot(
      (snapshot) => {
        console.log(
          `Project subscribing to address collection (ID: ${projectAddressId}`
        );
        if (snapshot.exists) {
          store.dispatch(
            setProjectPropertyData(snapshot.data() as IPropertyData)
          );
        }
      },
      (e) => {
        console.log(
          `Error on project collection subscription (ID: ${projectAddressId}`
        );
        console.log(e);
      }
    );
  }, [projectAddressId]);

  return null;
};

const useFetchPropertyData = (address: IAddressDetails | undefined) => {
  // HANDLES REQUEST WHEN NEEDED
  const addressId = Address.getId(address);
  const propertyDataQueryKeys = ["get-property-data", addressId ?? ""];
  const propertyDataRequestDisabled =
    Address.isInvalidAddress(address) || !addressId;
  useQuery({
    queryKey: propertyDataQueryKeys,
    keepPreviousData: true,
    staleTime: DateUtils.convertDaysToMilliseconds(1),
    queryFn: () => {
      if (propertyDataRequestDisabled) {
        return null;
      }
      return executeRequest("/property/v1/getPropertyData", address)
        .then((fetchedPropertyData: IPropertyData | null) => {
          console.log(
            "Issued request for project property data for address id " +
              Address.getId(address)
          );
          if (fetchedPropertyData) {
            console.log(fetchedPropertyData);
            return fetchedPropertyData;
          } else {
            console.log("No data returned");
            return null;
          }
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
    },
  });
};
