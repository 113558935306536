import React from "react";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";

export const SaveButton = (props: {saveAction: () => void}) => {
  const {saveAction} = props;

  return (
    <NatButton
      label={"Save placement"}
      type={"button"}
      clickEvent={(e: any) => {
        saveAction();
        e?.stopPropagation();
      }}
      option={StyleOption.PRIMARY}
      size={NatSize.XSMALL}
    />
  );
};
