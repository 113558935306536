import React from "react";
import {NatButton} from "../../../../_shared/generics/button";
import {DeveloperViewContainer} from "../_shared/DeveloperViewContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {ElementContainer} from "../../styles";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {BLACK, VILLA_BLUE, WHITE} from "../../../../_shared/colors";
import {keysIn} from "lodash";

const Buttons = (props: {backgroundColor: string}) => {
  return (
    <PageElement size={4} height={"auto"}>
      <ElementContainer backgroundColor={props.backgroundColor}>
        {keysIn(StyleOption)
          .filter((k) => {
            return isNaN(k as unknown as number);
          })
          ?.filter((j) => {
            return ![
              "FILTERS",
              "FILTERS_HIGHLIGHTED",
              "FILTERS_ACTIVE",
              "PRIMARY_DROPDOWN",
              "PRIMARY_DROPDOWN_ALT",
              "APP_SWITCHER",
            ].includes(j);
          })
          .map((key) => {
            // @ts-ignore
            const o: StyleOption = StyleOption?.[key];
            return (
              <NatButton
                label={StyleOption[o]}
                type={"button"}
                trackingId={"null"}
                option={o}
              />
            );
          })}
      </ElementContainer>
    </PageElement>
  );
};

export const NatButtonSection = () => {
  return (
    <DeveloperViewContainer title={"NatButtons"}>
      <Buttons backgroundColor={WHITE} />
      <Buttons backgroundColor={BLACK} />
      <Buttons backgroundColor={VILLA_BLUE} />
    </DeveloperViewContainer>
  );
};
