import React from "react";
import {StudioMobileHeader} from "./styles";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {IStudioOptions} from "../StudioContent";
import {NatButton} from "../../../../_shared/generics/button";
import {NatLeftArrowIcon} from "../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {PortalHeader} from "../../../../portal/_shared/components/PortalHeader";
import {SignUpInComponent} from "../../../../map/_shared/components/MainHeader";
import {useActiveCartItem} from "../../../../_shared/hooks/useCart/useCartItem/useActiveCartItem";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

interface StudioNavigationBarProps {
  height: string;
  studioPrice: number;
  stackedMode: boolean;
  displayOptions?: IStudioOptions;
}
export const StudioNavigationBar = (props: StudioNavigationBarProps) => {
  const {stackedMode, displayOptions} = props;
  const {navigateBack} = useNavigation();
  const {details} = useActiveCartItem() ?? {};
  const {cartItemCount} = useActiveProject();
  const {display_code} = details ?? {};
  const isPublicApp = useIsPublicApp();

  if (stackedMode) {
    return (
      <StudioMobileHeader>
        <NatButton
          label={"Back"}
          icon={{
            icon: <NatLeftArrowIcon />,
            iconPosition: IconPosition.LEFT,
          }}
          size={NatSize.SMALL}
          option={StyleOption.SELECTED_TOGGLE_OFF}
          clickEvent={
            displayOptions?.backButtonOptions?.onClick ?? navigateBack
          }
        />
        <SignUpInComponent />
      </StudioMobileHeader>
    );
  }

  if (displayOptions?.headless || isPublicApp) {
    return null;
  }

  return (
    <PortalHeader
      showAppSwitcher={isPublicApp}
      title={
        !stackedMode ? (
          <>
            {display_code && cartItemCount > 1
              ? `Designing ${display_code}`
              : "View upgrades"}
          </>
        ) : (
          <></>
        )
      }
      rightHandComponent={
        <>
          <SignUpInComponent />
        </>
      }
    />
  );
};
