import {Utilities} from "../../../../../../../../../database/firebase";
import {ICustomerInfo} from "@natomas-org/core";

export const fetchWelcomeEmail = (
  setWelcomeEmailAlreadySent: (v: boolean) => void,
  customer?: ICustomerInfo,
  currentConfigurationId?: string
) => {
  Utilities.getDocData(
    Utilities.collection("sentEmailTemplateIds").doc(customer?.user_id)
  ).then((result) => {
    const alreadySentEmail =
      result != null &&
      result[currentConfigurationId + "_d-cb5f81b10a284125875b846d338117c7"] ===
        true;
    setWelcomeEmailAlreadySent(alreadySentEmail);
  });
};
