import React, {Dispatch, SetStateAction} from "react";
import {ModifierDependencyTable} from "../../../../../_shared/components/ModifierDependencyTable";
import {
  NatModal,
  NatModalSize,
} from "../../../../../../_shared/generics/modal/NatModal";

export interface DependenciesDataProps {
  title: string;
  setEdit: any;
  id: string;
  type: string;
  field: string;
  info: any[];
}

export interface DependenciesProps {
  show: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  data: DependenciesDataProps | undefined;
}

export const DependenciesModal = (props: DependenciesProps) => {
  const {show, handleClose, data} = props;
  const handleChange = (d: any) => {
    if (data) {
      const newEdit = {
        [data.id]: {[data.field]: d},
      };
      data.setEdit(newEdit);
    }
  };

  const close = () => {
    handleClose(false);
  };

  // Require that data is populated
  return (
    <>
      {data && (
        <NatModal
          show={show}
          handleClose={close}
          header={data?.title}
          size={NatModalSize.LARGE}
          content={
            <>
              <ModifierDependencyTable
                value={data?.info}
                handleChange={handleChange}
                name={""}
                type={data?.type}
              />
            </>
          }
        />
      )}
    </>
  );
};
