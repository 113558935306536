import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface ICartSessionSlice {
  selectedCartItemIds: string[];
}

const getInitialState = (): ICartSessionSlice => {
  return {
    selectedCartItemIds: [],
  };
};

const cartSessionSlice = createSlice({
  name: "cartSession",
  initialState: getInitialState(),
  reducers: {
    setSelectedCartItemIds: (state, action: PayloadAction<string[]>) => {
      const {payload} = action;
      state.selectedCartItemIds = payload;
    },
    selectCartItemId: (state, action: PayloadAction<string>) => {
      const {payload} = action;
      const others = state.selectedCartItemIds.filter(
        (id: string) => id !== payload
      );
      state.selectedCartItemIds = [...others, payload];
    },
    selectCartItemIds: (state, action: PayloadAction<string[]>) => {
      const {payload} = action;
      const others = state.selectedCartItemIds.filter(
        (id: string) => !payload.includes(id)
      );
      state.selectedCartItemIds = [...others, ...payload];
    },
    unselectCartItemId: (state, action: PayloadAction<string>) => {
      const {payload} = action;
      state.selectedCartItemIds = state.selectedCartItemIds.filter(
        (id: string) => id !== payload
      );
    },
    unselectCartItemIds: (state, action: PayloadAction<string[]>) => {
      const {payload} = action;
      state.selectedCartItemIds = state.selectedCartItemIds.filter(
        (id: string) => !payload.includes(id)
      );
    },
  },
});

export const cartSessionReducer = cartSessionSlice.reducer;
export const {
  selectCartItemId,
  selectCartItemIds,
  unselectCartItemIds,
  unselectCartItemId,
  setSelectedCartItemIds,
} = cartSessionSlice.actions;
