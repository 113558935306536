import {ICatalogTag} from "@natomas-org/core";
import {ICatalogBadge} from "./index";
import {factoryLineTags} from "../../../../admin/_models/factoryLine";
import {VILLA_VANILLA} from "../../../../_shared/colors";

export const getBadgesFromCatalogTags = (tags: ICatalogTag[]) => {
  let badges: ICatalogBadge[] = [];
  // Oct. 2023 Marketing Request -> Filter our few design choices
  tags
    ?.filter((tag) => {
      return tag !== ICatalogTag.FEW_DESIGN_CHOICES;
    })
    .forEach((tag) => {
      let t = factoryLineTags?.find((item) => item.value === tag);
      if (t?.label) {
        badges.push({label: t.label, color: VILLA_VANILLA});
      }
    });
  return badges;
};
