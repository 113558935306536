import {ColumnContainer, MainColumn} from "../../../_shared/styles/AdminStyles";
import React from "react";
import {EditProductGroup} from "./EditProductGroup";

export const OverviewTab = () => {
  return (
    <ColumnContainer>
      <MainColumn>
        <EditProductGroup />
      </MainColumn>
    </ColumnContainer>
  );
};
