import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import {
  DuplicateFactoryLineElementRequestType,
  getPriceMicrosFromTextToSave,
  getTextFromPriceMicros,
  isBlankString,
} from "@natomas-org/core";
import {
  AdditionalAdminModifierStructure,
  AdminModifierStructure,
} from "../../../_models/modifier";
import {editModifier} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {
  AdminForm,
  MainContent,
  MainView,
  NatStyledForm,
} from "../../../_shared/styles/AdminStyles";
import {AdminInput} from "../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {AdminTabHeader} from "../../components/Header/AdminTabHeader";

import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixIncludeOptions,
  IStore,
} from "../../../../_shared/slices/types/Store";
import {AssociationsContainer} from "../../components/AssociationsContainer";
import {
  getCategoriesWithModifierGroups,
  getDistinctProductWithCategories,
} from "../../components/TabColumn/helper";
import {
  getCategorySection,
  getModifierGroupSection,
  getProductSection,
} from "../../components/AssociationsContainer/predefinedSections";
import {
  archiveModifier,
  undoArchiveModifier,
} from "../../../../../database/firebase/catalog/saving";
import {DuplicateFactoryLineElementButton} from "../ProductsTab/DuplicateFactoryLineElementButton";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {
  getPriceSet,
  getProductModifierUpdates,
} from "../MatrixTab/_shared/utilities";
import {MatrixTab} from "../MatrixTab";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

const tabs = [
  {id: "details", title: "Basic Info"},
  {id: "other", title: "Advanced Details"},
  {id: "matrix", title: "Matrix"},
];

const getInitialValues = (obj: any) => {
  const initialValues = getFormInitialValues(AdminModifierStructure);
  if (obj != null) {
    for (const key in AdminModifierStructure) {
      // @ts-ignore
      const keyInfo = AdminModifierStructure[key];
      const value = obj[key];
      let initialValue = value;
      if (keyInfo.subType === "price") {
        initialValue = getTextFromPriceMicros(value);
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProductGroupModifier = () => {
  const editingModifier = useSelector(
    (state: IStore) => state.adminStudioManager.editingModifier
  );
  const {
    categories,
    modifierGroups,
    products: allProducts,
    details,
    modifiers,
  } = useSelectedFactoryLine();
  const {
    editingEnabled,
    updateModifier,
    updateModifiersInBulk,
    updateAdminProCatFilters,
  } = useAdminProductCatalog();
  const modifier =
    modifiers != null && modifiers[editingModifier?.id] != null
      ? modifiers[editingModifier?.id]
      : editingModifier;

  const dispatch = useDispatch();
  const [tab, setTab] = useState(tabs[0].id);
  const [matrixEdits, setMatrixEdits] = useState<any>(null);

  const close = useCallback(() => {
    dispatch(editModifier(null));
  }, [dispatch]);

  const deleteModifierFromGroupAction = (e: any) => {
    e.preventDefault();
    if (details && modifier?.id) {
      return archiveModifier(details?.id, details?.versionId, modifier?.id);
    } else {
      close();
    }
  };

  const undoDeleteModifierFromGroupAction = (e: any) => {
    e.preventDefault();
    if (details && modifier?.id) {
      return undoArchiveModifier(details?.id, details?.versionId, modifier?.id);
    }
  };

  const initialValues = getInitialValues(modifier);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      if (matrixEdits) {
        saveMatrixEdits();
      }
      const modifierToSave = Object.assign({}, values);
      modifierToSave.id = modifier.id;
      if (isBlankString(modifierToSave.imageId)) {
        modifierToSave.imageId = null;
      }
      modifierToSave.defaultPriceMicros = getPriceMicrosFromTextToSave(
        modifierToSave.defaultPriceMicros
      );
      if (
        !!modifierToSave?.price_set?.[0] &&
        "priceMicros" in modifierToSave?.price_set?.[0]
      ) {
        modifierToSave.price_set = getPriceSet(modifierToSave.price_set);
      }
      return updateModifier(modifierToSave?.id, modifierToSave);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
    if (modifier) {
      updateMatrixFilters();
    }
  }, [modifier]);

  if (modifier == null) {
    return null;
  }

  const modifierStructureForForm = getFormStructure(AdminModifierStructure);

  const getSections = () => {
    const filteredModifierGroups = Object.values(modifierGroups ?? {}).filter(
      (mG: any) => {
        return mG?.modifiers?.includes(editingModifier.id);
      }
    );

    if (filteredModifierGroups?.length === 0) {
      return [];
    }

    const categoriesWithModifierGroups = getCategoriesWithModifierGroups(
      filteredModifierGroups,
      Object.values(categories ?? {})
    );

    const productsWithCategories = getDistinctProductWithCategories(
      categoriesWithModifierGroups,
      Object.values(allProducts ?? {})
    );

    return [
      getModifierGroupSection(filteredModifierGroups),
      getCategorySection(categoriesWithModifierGroups),
      getProductSection(productsWithCategories),
    ];
  };

  const updateMatrixFilters = () => {
    updateAdminProCatFilters({
      include: AdminProductCatalogMatrixIncludeOptions.ALL,
      filterBy: AdminProductCatalogMatrixFilterByOptions.MODIFIER,
      filterIds: [modifier?.id],
    });
  };

  const resetMatrixEdits = () => {
    setMatrixEdits(null);
  };

  const saveMatrixEdits = () => {
    const dbUpdates = getProductModifierUpdates(matrixEdits);
    updateModifiersInBulk(dbUpdates)?.then(() => {
      resetMatrixEdits();
    });
  };

  let mainContent;
  if (tab === "matrix") {
    mainContent = (
      <MatrixTab
        subTabMode={true}
        setMatrixEdits={setMatrixEdits}
        matrixEdits={matrixEdits}
      />
    );
  } else if (tab === "details") {
    mainContent = (
      <MainContent>
        <NatStyledForm onSubmit={formik.handleSubmit}>
          {modifierStructureForForm.map((keyInfo) => {
            const {key} = keyInfo;
            // @ts-ignore
            const value = formik.values[key];
            // @ts-ignore
            if (AdditionalAdminModifierStructure[key] != null) {
              return null;
            }
            // @ts-ignore
            let initial = initialValues[key];
            return (
              <AdminInput
                key={key}
                value={value}
                initialValue={initial}
                fieldInfo={keyInfo}
                disabled={!keyInfo?.alwaysEnabled && !editingEnabled}
                onChange={(value: any) => {
                  formik.setFieldValue(key, value);
                }}
              />
            );
          })}
        </NatStyledForm>
        <AssociationsContainer sections={getSections()} />
      </MainContent>
    );
  } else {
    mainContent = (
      <MainContent>
        <AdminForm onSubmit={formik.handleSubmit}>
          {modifierStructureForForm.map((keyInfo) => {
            const {key} = keyInfo;
            // @ts-ignore
            const value = formik.values[key];
            // @ts-ignore
            if (AdditionalAdminModifierStructure[key] == null) {
              return null;
            }

            return (
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                disabled={!keyInfo?.alwaysEnabled && !editingEnabled}
                onChange={(value: any) => {
                  formik.setFieldValue(key, value);
                }}
              />
            );
          })}
        </AdminForm>
      </MainContent>
    );
  }

  return (
    <MainView>
      <AdminTabHeader
        tabs={tabs}
        setTab={setTab}
        isUpToDate={isDeepEqual(formik.values, initialValues) && !matrixEdits}
        deleteAction={
          !isArchived(editingModifier) && editingEnabled
            ? deleteModifierFromGroupAction
            : undefined
        }
        undoDeleteAction={
          isArchived(editingModifier) && editingEnabled
            ? undoDeleteModifierFromGroupAction
            : undefined
        }
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        saveAction={() => {
          return formik.submitForm();
        }}
        additionalActions={
          <>
            {editingEnabled && details && modifier && (
              <DuplicateFactoryLineElementButton
                factoryLineId={details?.id}
                requestId={modifier?.id}
                requestType={DuplicateFactoryLineElementRequestType.MODIFIER}
                resetAction={close}
              />
            )}
          </>
        }
      />
      {mainContent}
    </MainView>
  );
};
