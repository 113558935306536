import {
  bulkUpdateProductGroupModifiers,
  saveCategoryInformation,
  saveFactoryLineInformation,
  saveModifierGroupInformation,
  saveModifierInformation,
  saveProductInformation,
} from "../../../../../database/firebase/catalog/saving";
import {IFactoryInfo} from "@natomas-org/core";

export const updateCategoryInformation = (
  factoryLine: IFactoryInfo,
  isLive: boolean,
  categoryId: string,
  payload: any
) => {
  if (!factoryLine || !factoryLine?.draftVersionId) {
    console.log("Failed to update category. Unknown factory line.");
    return;
  }
  // Always update the draft version
  saveCategoryInformation(
    factoryLine?.id,
    factoryLine?.draftVersionId,
    categoryId,
    payload
  );
  // If the current version is live + update is called, update it too.
  if (isLive && factoryLine?.publishedVersionId) {
    saveCategoryInformation(
      factoryLine?.id,
      factoryLine?.publishedVersionId,
      categoryId,
      payload
    );
  }
};

export const updateProductInformation = (
  factoryLine: IFactoryInfo,
  isLive: boolean,
  productId: string,
  payload: any
) => {
  if (!factoryLine || !factoryLine?.draftVersionId) {
    console.log("Failed to update product. Unknown factory line.");
    return;
  }
  // Always update the draft version
  saveProductInformation(
    factoryLine?.id,
    factoryLine?.draftVersionId,
    productId,
    payload
  );
  // If the current version is live + update is called, update it too.
  if (isLive && factoryLine?.publishedVersionId) {
    saveProductInformation(
      factoryLine?.id,
      factoryLine?.publishedVersionId,
      productId,
      payload
    );
  }
};

export const updateFactoryLineInformation = (
  factoryLine: IFactoryInfo,
  isLive: boolean,
  payload: any
) => {
  if (!factoryLine || !factoryLine?.draftVersionId) {
    console.log("Failed to update factory line. Unknown factory line.");
    return;
  }
  // Always update the draft version
  saveFactoryLineInformation(
    factoryLine?.id,
    factoryLine?.draftVersionId,
    payload
  );
  // If the current version is live + update is called, update it too.
  if (isLive && factoryLine?.publishedVersionId) {
    saveFactoryLineInformation(
      factoryLine?.id,
      factoryLine?.publishedVersionId,
      payload
    );
  }
};

export const updateModifierInformation = (
  factoryLine: IFactoryInfo,
  isLive: boolean,
  modifierId: string,
  payload: any,
  productId?: string
) => {
  if (!factoryLine || !factoryLine?.draftVersionId) {
    console.log("Failed to update modifier. Unknown factory line.");
    return;
  }
  // Always update the draft version
  saveModifierInformation(
    factoryLine?.id,
    factoryLine?.draftVersionId,
    modifierId,
    payload,
    productId
  );
  // If the current version is live + update is called, update it too.
  if (isLive && factoryLine?.publishedVersionId) {
    saveModifierInformation(
      factoryLine?.id,
      factoryLine?.publishedVersionId,
      modifierId,
      payload,
      productId
    );
  }
};

export const updateModifierGroupInformation = (
  factoryLine: IFactoryInfo,
  isLive: boolean,
  modifierGroupId: string,
  payload: any
) => {
  if (!factoryLine || !factoryLine?.draftVersionId) {
    console.log("Failed to update category. Unknown factory line.");
    return;
  }
  // Always update the draft version
  saveModifierGroupInformation(
    factoryLine?.id,
    factoryLine?.draftVersionId,
    modifierGroupId,
    payload
  );
  // If the current version is live + update is called, update it too.
  if (isLive && factoryLine?.publishedVersionId) {
    saveModifierGroupInformation(
      factoryLine?.id,
      factoryLine?.publishedVersionId,
      modifierGroupId,
      payload
    );
  }
};

export const updateModifierInformationInBulk = async (
  factoryLine: IFactoryInfo | undefined,
  isLive: boolean,
  payload: any
) => {
  if (!factoryLine || !factoryLine?.draftVersionId) {
    console.log("Failed to update category. Unknown factory line.");
    return;
  }
  // Always update the draft version
  await bulkUpdateProductGroupModifiers(
    factoryLine?.id,
    factoryLine?.draftVersionId,
    payload
  );
  // If the current version is live + update is called, update it too.
  if (isLive && factoryLine?.publishedVersionId) {
    await bulkUpdateProductGroupModifiers(
      factoryLine?.id,
      factoryLine?.publishedVersionId,
      payload
    );
  }
};
