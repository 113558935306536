import {useMemo} from "react";
import useActivitySlice from "../useActivity/useActivitySlice";
import {useCurrentUser} from "../useCurrentUser";
import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";

export const useAdminSettings = () => {
  const {isAdmin} = useCurrentUser();
  const {activeCustomerId, activeProjectId, activeCartItemId} =
    useActivitySlice();
  const demoPricingMode = useSelector(
    (state: IStore) => state.administration.productCatalog.demoPricingMode
  );

  const canDemo: boolean = useMemo(() => {
    if (!isAdmin) {
      return false;
    }
    return !activeCustomerId && !activeProjectId && !activeCartItemId;
  }, [isAdmin, activeCartItemId, activeCustomerId, activeProjectId]);

  return {
    isAdmin,
    canDemo,
    demoPricingMode,
  };
};
