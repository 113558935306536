import * as Yup from "yup";
import {Address} from "@natomas-org/core";
import {IFormInputType} from "../hooks/useRegistrationForm/types";

export const FIRST_NAME_KEY = "firstName";
export const LAST_NAME_KEY = "lastName";
export const EMAIL_KEY = "email";
export const PASSWORD_KEY = "password";
export const PHONE_NUMBER_KEY = "phoneNumber";
export const COMPANY_KEY = "company";
export const PROPERTY_TYPE_KEY = "property_type";
export const MARKETING_CHANNEL_KEY = "marketing_channel";
export const PROJECT_TYPE_KEY = "Project_Type";
export const CUSTOMER_SEGMENT_KEY = "Customer_Segment";
export const ADDRESS_KEY = "address";
export const CONSENT_KEY = "consent";

const FIRST_NAME_VALUE: IFormInputType = {
  key: FIRST_NAME_KEY,
  placeholder: "First Name*",
  type: "text",
  index: 0,
  required: true,
};

const LAST_NAME_VALUE: IFormInputType = {
  key: LAST_NAME_KEY,
  placeholder: "Last Name*",
  type: "text",
  required: true,
  index: 1,
};

const COMPANY_VALUE: IFormInputType = {
  key: COMPANY_KEY,
  placeholder: "Company",
  type: "text",
  index: 2,
};

const PROPERTY_TYPE_VALUE: IFormInputType = {
  key: PROPERTY_TYPE_KEY,
  placeholder: "Property Type",
  type: "property_type",
  index: 2,
};

const MARKETING_CHANNEL_VALUE: IFormInputType = {
  key: MARKETING_CHANNEL_KEY,
  placeholder: "Last question! How did you hear about us? (optional)",
  type: "marketing_channel",
  index: 2,
};

const ADDRESS_VALUE: IFormInputType = {
  key: ADDRESS_KEY,
  placeholder: "Street Address*",
  type: "IAddressDetails",
  required: true,
  index: 4,
};
const VALIDATE_ADDRESS_VALUE: IFormInputType = {
  key: ADDRESS_KEY,
  placeholder: "Street Address*",
  type: "IAddressDetails",
  required: true,
  validateAddress: true,
  index: 4,
};

const EMAIL_VALUE: IFormInputType = {
  key: EMAIL_KEY,
  placeholder: "Email*",
  autoComplete: "username",
  required: true,
  type: "email",
  index: 2,
};

const PHONE_NUMBER_VALUE: IFormInputType = {
  key: PHONE_NUMBER_KEY,
  placeholder: "Phone Number*",
  required: true,
  type: "tel",
  index: 3,
};

const PASSWORD_VALUE: IFormInputType = {
  key: PASSWORD_KEY,
  placeholder: "Password*",
  type: "password",
  required: true,
  autoComplete: "new-password",
  index: 4,
};

const CONSENT_VALUE: IFormInputType = {
  key: CONSENT_KEY,
  defaultValue: false,
  placeholder: "",
  type: CONSENT_KEY,
  required: true,
  index: 3,
};

const GENERAL_INTAKE_FORM_ARRAY: IFormInputType[] = [
  FIRST_NAME_VALUE,
  LAST_NAME_VALUE,
  PHONE_NUMBER_VALUE,
  EMAIL_VALUE,
];

const CUSTOMER_DETAILS_FORM_ARRAY: IFormInputType[] = [
  ...GENERAL_INTAKE_FORM_ARRAY,
  ADDRESS_VALUE,
];

const PROJECT_DETAILS_FORM_ARRAY: IFormInputType[] = [
  FIRST_NAME_VALUE,
  LAST_NAME_VALUE,
  PHONE_NUMBER_VALUE,
  ADDRESS_VALUE,
];

const mapFromFormInputArray = (
  formInputArray: IFormInputType[]
): Map<any, any> => {
  return new Map(
    formInputArray?.map((item) => {
      return [item.key, item];
    })
  );
};

export const PROJECT_DETAILS_FORM: Map<any, any> = mapFromFormInputArray(
  PROJECT_DETAILS_FORM_ARRAY
);

export const CUSTOMER_DETAILS_FORM: Map<any, any> = mapFromFormInputArray(
  CUSTOMER_DETAILS_FORM_ARRAY
);

export const GENERAL_INTAKE_FORM_WITH_ADDRESS_AND_PASSWORD =
  mapFromFormInputArray([
    {...VALIDATE_ADDRESS_VALUE, includeHr: true},
    ...GENERAL_INTAKE_FORM_ARRAY,
    {...PASSWORD_VALUE, includeHr: true},
    {...CONSENT_VALUE, required: true},
    MARKETING_CHANNEL_VALUE,
  ]);

export const MULTI_FAMILY_INTAKE_FORM = mapFromFormInputArray([
  FIRST_NAME_VALUE,
  LAST_NAME_VALUE,
  PHONE_NUMBER_VALUE,
  EMAIL_VALUE,
  PASSWORD_VALUE,
  VALIDATE_ADDRESS_VALUE,
  PROPERTY_TYPE_VALUE,
  {...COMPANY_VALUE, includeHr: true},
  {...CONSENT_VALUE, required: true},
  MARKETING_CHANNEL_VALUE,
]);

// export interface MULTI_FAMILY_INTAKE_FORM_SCHEMA_SECTION_ROW {
//   title: string;
//   description: string;
//   inputs: any[];
// }
//
// export interface MULTI_FAMILY_INTAKE_FORM_SCHEMA_SECTION {
//   title: string;
//   description: string;
//   rows: MULTI_FAMILY_INTAKE_FORM_SCHEMA_SECTION_ROW[];
// }
//
// export const MULTI_FAMILY_INTAKE_FORM_SCHEMA: MULTI_FAMILY_INTAKE_FORM_SCHEMA_SECTION[] =
//   [];

export const NON_ADDRESS_INTAKE_FORM = mapFromFormInputArray([
  FIRST_NAME_VALUE,
  LAST_NAME_VALUE,
  EMAIL_VALUE,
  {...PHONE_NUMBER_VALUE, includeHr: true},
  {...CONSENT_VALUE, required: true},
]);

// This is the form structure for Nexus
export const formatPhoneNumber = (preformatted?: string): string => {
  if (!preformatted) {
    return "";
  }
  // Remove all non-numeric characters
  const cleanedPhoneNumber = preformatted?.replace(/\D/g, "") ?? "";
  if (cleanedPhoneNumber.length < 10) {
    return "";
  }
  if (cleanedPhoneNumber.length > 11) {
    return "";
  }
  // Get the last 10 digits (remove the country code)
  const phoneNumber = cleanedPhoneNumber.substring(
    cleanedPhoneNumber.length - 10
  );
  // Apply the format
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

export const COMPLETE_VALIDATION_STRUCTURE: any = {
  [FIRST_NAME_KEY]: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Enter a first name"),
  [LAST_NAME_KEY]: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Enter a last name"),
  [EMAIL_KEY]: Yup.string().email("Invalid email").required("Enter an email"),
  [PHONE_NUMBER_KEY]: Yup.string()
    .test("valid-number", "Phone number is not valid.", (value: any) => {
      const formattedPhoneNumber = formatPhoneNumber(value);
      return formattedPhoneNumber?.length === 12;
    })
    .required("Enter a phone number"),
  [PASSWORD_KEY]: Yup.string()
    .min(6, "Password strength: Weak")
    .required("Enter a password"),
  [ADDRESS_KEY]: Address.addressSchema,
  [CONSENT_KEY]: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms of Use and Privacy Policy to continue."
  ),
};
