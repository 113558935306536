import {useEffect, useState} from "react";

export const useDelay = (props: IUseDelayProps) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReady(true);
    }, props.milliseconds);
    return () => {
      setReady(false);
    };
  }, []);

  return {ready};
};

interface IUseDelayProps {
  milliseconds: number;
}
