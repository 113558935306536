import {usePage} from "../../../../../_shared/hooks/usePage";
import React, {Fragment, useContext, useEffect, useMemo, useState} from "react";
import {
  EMBEDDED_PROPOSAL,
  PROPOSAL_GENERATE_PDF,
} from "../../HCPPresentationView/DTC/components/constants";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../_shared/hooks/useDesign/useActiveDesign";
import {useDesigns} from "../../../../../_shared/hooks/useDesign/useDesigns";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {getUpgradesList} from "../../../../../_shared/snapshot";
import {ISiteWorkBucket} from "../../../InstantQuoteView/interface";
import NatLabel, {
  NatLabelType,
} from "../../../../../_shared/generics/label/NatLabel";
import {
  getAllSelectedModifiersFromSnapshot,
  getRenderings,
  isBlankString,
  ProjectPriceMode,
  shallowEquals,
} from "@natomas-org/core";
import {NatFlex} from "../../../../../_shared/generics/flex/NatFlex";
import {
  NatJustifyContent,
  NatVerticalAlign,
} from "../../../../../_shared/generics/_shared";
import {
  DesignSelectionsCarousel,
  DesignSelectionsCarouselWrapper,
  DesignSelectionsPagePrice,
  DesignSelectionsPageTitle,
  DesignSelectionsSlideInfo,
} from "./styles";
import {StudioCarousel} from "../../../../../studio/_shared/components/StudioCarousel";
import {StudioSectionButton} from "./StudioSectionButton";
import {PageDetailsDropdown} from "./PageDetailsDropdown";
import {UnitTitle} from "../SectionSubtitle";
import {FULL_SCREEN_HEIGHT_REM, FULL_SCREEN_WIDTH_REM} from "./constants";
import {StudioDisclaimerContent} from "../../../../../studio/_shared/components/StudioDisclaimerModal/constant";
import {GRANITE} from "../../../../../_shared/colors";
import VCL from "@natomas-org/villa-component-library";

const PAGE_BREAK_AFTER_NON_FULLSCREEN_SLIDE_COUNT = 3;
const FULL_SCREEN_SLIDE_HEIGHT_REM = (FULL_SCREEN_HEIGHT_REM ?? 1) * 0.67;
const PRESENTATION_NON_FULL_SCREEN_SLIDE_HEIGHT_REM =
  (FULL_SCREEN_SLIDE_HEIGHT_REM * 0.9) /
  PAGE_BREAK_AFTER_NON_FULLSCREEN_SLIDE_COUNT;
export const ProductScreenDesignSelections = (props: {
  title?: string;
  designId?: string;
  presentationView?: boolean;
  index?: number;
  cartItemId?: string;
}) => {
  const {pageWidth: statePageWidth, isNatMobile} = usePage();
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const embeddedProposal = useContext(EMBEDDED_PROPOSAL);
  const {
    isProjectLocked,
    projectPriceMode,
    cartItemIds,
    info,
    isDevelopmentByVilla,
    isCartEnabled,
  } = useActiveProject();
  const {id: activeDesignId} = useActiveDesign();
  const fixedDesign = !!props.designId;
  const [locallySelectedDesignId, setLocallySelectedDesignId] = useState<
    string | undefined
  >(fixedDesign ? props.designId : activeDesignId);
  const designs = useDesigns(cartItemIds);
  const {index: cartEnabledIndex} = props;
  const locallyActiveDesign = locallySelectedDesignId
    ? designs[locallySelectedDesignId]
    : undefined;
  let snapshot = locallyActiveDesign?.snapshot;
  let configuration = locallyActiveDesign?.configuration;
  const designId: string | undefined = useMemo(() => {
    if (fixedDesign) {
      return props.designId;
    } else {
      return locallySelectedDesignId;
    }
  }, [fixedDesign, locallySelectedDesignId, props.designId]);
  useEffect(() => {
    if (
      designId === locallySelectedDesignId &&
      locallySelectedDesignId &&
      !cartItemIds?.includes(locallySelectedDesignId)
    ) {
      if (cartItemIds?.length > 0) {
        setLocallySelectedDesignId(cartItemIds[0]);
      } else {
        setLocallySelectedDesignId(undefined);
      }
    }
  }, [cartItemIds, designId, fixedDesign, locallySelectedDesignId]);
  const {product} = configuration ?? {};
  let pageWidthPx: number = statePageWidth;

  // pageWidthPx = `calc(${FULL_SCREEN_WIDTH_REM}rem - 2rem)`;

  const allSelectedModifiers = useMemo(() => {
    if (!snapshot) {
      return [];
    }
    const allSelectedModifiersArray = getAllSelectedModifiersFromSnapshot(
      snapshot?.snapshot
    );
    const allSelectedModifiersObject = {};
    allSelectedModifiersArray.forEach((modifier: any) => {
      // @ts-ignore
      allSelectedModifiersObject[modifier.id] = modifier;
    });
    return allSelectedModifiersObject;
  }, [snapshot]);
  const baseCarouselSize: number =
    pageWidthPx *
    useDynamicValue({
      forFour: 1,
      forEight: 0.6,
      forTwelve: 0.675,
      forSixteen: 0.75,
    });
  const upgradeList = useMemo(() => {
    return getUpgradesList(snapshot, projectPriceMode, true);
  }, [projectPriceMode, snapshot]);
  const upgradeListFullScreen = useMemo(() => {
    if (!isDevelopmentByVilla) {
      return [];
    }
    return upgradeList.filter((item) => {
      return (
        designId &&
        isDevelopmentByVilla &&
        item?.title &&
        !!info?.proposalSettings?.displayFullScreenDesignSelections &&
        !!info?.proposalSettings?.displayFullScreenDesignSelections[designId] &&
        info?.proposalSettings?.displayFullScreenDesignSelections[designId][
          item.title
        ]
      );
    });
  }, [
    designId,
    isDevelopmentByVilla,
    info?.proposalSettings?.displayFullScreenDesignSelections,
    upgradeList,
  ]);
  const upgradeListNotFullScreen = useMemo(() => {
    return upgradeList.filter((item) => {
      return (
        upgradeListFullScreen.length < 1 ||
        upgradeListFullScreen.find((fullScreenItem) => {
          return fullScreenItem.title !== item.title;
        })
      );
    });
  }, [upgradeList, upgradeListFullScreen]);
  const getImages = (
    index: number
    // contextSelectedModifiers: ISiteWorkBucket[]
  ) => {
    if (!snapshot?.snapshot?.[index]) {
      return [];
    }
    const images = getRenderings(
      null,
      snapshot?.snapshot?.[index],
      allSelectedModifiers,
      snapshot?.snapshot?.[index]?.selectedModifierGroups
    ).images;
    if (images?.length === 0) {
      return [
        {
          imageId: product?.imageId,
        },
      ];
    } else {
      return images;
    }
  };

  const DesignTitleElement = (props: {
    hidden?: boolean;
    index?: number;
    style?: React.CSSProperties;
    hideHR?: boolean;
  }) => {
    if (props.hidden) {
      return null;
    }
    let labelString = "Designs Continued";
    if (upgradeListFullScreen.length === 0 && props.index === 0) {
      labelString = `Designs`;
    }
    if (propsTitle) {
      labelString = `${propsTitle}: ${labelString}`;
    }
    if (!isDevelopmentByVilla && !isGeneratingPDF) {
      return (
        <NatLabel
          label={labelString}
          type={NatLabelType.H2}
          style={{
            width: "100%",
            textAlign: "center",
            ...props.style,
          }}
        />
      );
    }
    if (upgradeListNotFullScreen.length < 1 || isBlankString(labelString)) {
      return null;
    } else {
      return (
        <Fragment>
          <hr
            style={{
              width: "100%",
            }}
            hidden={props.hideHR}
          />
          <NatLabel
            label={labelString}
            type={NatLabelType.H2}
            style={{
              width: "100%",
              textAlign: "center",
              ...props.style,
            }}
          />
        </Fragment>
      );
    }
  };

  function getSlidesFromUpgradeList(
    localUpgradeList: ISiteWorkBucket[],
    formatFullScreen: boolean,
    fullScreenDesignCount: number
  ) {
    return localUpgradeList.map((item, index: number) => {
      let pageBreakAfter = false;
      let hideSection = false;
      let categoryString: string | null = null;
      if (isDevelopmentByVilla) {
        categoryString =
          formatFullScreen && item.title ? item.title : `Design Selections`;
        if (props.title) {
          categoryString = `${props.title}: ${categoryString}`;
        }
        pageBreakAfter = formatFullScreen && index < localUpgradeList?.length;

        if (!isDevelopmentByVilla && index > 0) {
          pageBreakAfter =
            index % PAGE_BREAK_AFTER_NON_FULLSCREEN_SLIDE_COUNT === 0 ||
            index === localUpgradeList.length;
        }
      } else {
      }

      // FULL_SCREEN_SLIDE_HEIGHT_REM *= 0.87;
      let relativeSlideHeightRem: number | undefined = formatFullScreen
        ? FULL_SCREEN_SLIDE_HEIGHT_REM
        : PRESENTATION_NON_FULL_SCREEN_SLIDE_HEIGHT_REM * 0.87;
      let carouselSizePx: number = baseCarouselSize;
      let yOffsetPx;
      let IMAGE_STRETCH_FACTOR = 1;
      if (isGeneratingPDF || formatFullScreen) {
        carouselSizePx = FULL_SCREEN_WIDTH_REM * 16 * 0.65;
        if (formatFullScreen) {
          relativeSlideHeightRem = relativeSlideHeightRem / 1.6;
        }
        carouselSizePx = relativeSlideHeightRem * 16 * 1.6;
        pageWidthPx = FULL_SCREEN_WIDTH_REM * 16;
        if (!formatFullScreen) {
          carouselSizePx *= IMAGE_STRETCH_FACTOR;
          const stretchedHeightPx = carouselSizePx / 1.6;
          const imageFractionNotShown =
            (IMAGE_STRETCH_FACTOR - 1) / IMAGE_STRETCH_FACTOR;
          const imageFractionTopNotShown = imageFractionNotShown / 2;
          yOffsetPx = stretchedHeightPx * imageFractionTopNotShown;
        } else if (isDevelopmentByVilla) {
          yOffsetPx = undefined;
          relativeSlideHeightRem = undefined;
        }
      } else {
        carouselSizePx *= 0.45;
      }
      const modifierIndexFromAllSelectedModifiers = upgradeList.findIndex(
        (modifier: ISiteWorkBucket) => {
          return shallowEquals(modifier, item);
        }
      );
      let pageBreakBefore;
      if (formatFullScreen) {
        pageBreakAfter = true;
        pageBreakBefore = false;
      } else if (fullScreenDesignCount === 0) {
        pageBreakBefore =
          index / PAGE_BREAK_AFTER_NON_FULLSCREEN_SLIDE_COUNT > 0;
        if (isCartEnabled) {
          if (isDevelopmentByVilla) {
            pageBreakBefore =
              pageBreakBefore ||
              index === 0 ||
              (cartEnabledIndex && cartEnabledIndex >= 0) ||
              index > 0;
          } else {
            pageBreakBefore =
              pageBreakBefore || cartEnabledIndex !== 0 || index > 0;
          }
        } else {
          if (isDevelopmentByVilla) {
            pageBreakBefore = pageBreakBefore || cartEnabledIndex === 0;
          }
        }
      } else {
        if (index > 0) {
          pageBreakBefore = index <= localUpgradeList?.length - 1;
        } else {
          if (formatFullScreen) {
            pageBreakBefore = cartEnabledIndex === 0;
          } else {
            pageBreakBefore = true;
          }
        }
      }
      // if (formatFullScreen) {
      //   pageBreakBefore = !!cartEnabledIndex && cartEnabledIndex === 0;
      // }
      let hidden: boolean = index > 0 || formatFullScreen;
      if (isGeneratingPDF) {
        hidden =
          formatFullScreen ||
          index % PAGE_BREAK_AFTER_NON_FULLSCREEN_SLIDE_COUNT !== 0;
      }

      return (
        <Fragment>
          <DesignTitleElement
            hidden={hidden}
            index={index / PAGE_BREAK_AFTER_NON_FULLSCREEN_SLIDE_COUNT}
            hideHR={true}
            style={{
              pageBreakBefore: pageBreakBefore && false ? "always" : "avoid",
              breakBefore: pageBreakBefore && false ? "always" : "avoid",
            }}
          />
          <NatFlex
            style={{
              width: formatFullScreen ? `${FULL_SCREEN_WIDTH_REM}rem` : "100%",
              maxWidth: formatFullScreen
                ? `${FULL_SCREEN_WIDTH_REM}rem`
                : "100%",
              pageBreakAfter: pageBreakAfter ? "always" : "avoid",
              breakAfter: pageBreakAfter ? "always" : "avoid",
              breakInside: "avoid",
              pageBreakInside: "avoid",
              pageBreakBefore: "avoid",
              breakBefore: "avoid",
              height: formatFullScreen
                ? `${FULL_SCREEN_HEIGHT_REM * 0.87}rem`
                : "min-content",
              overflow: "hidden",
            }}
            JUSTIFY_CONTENT={
              formatFullScreen ? NatJustifyContent.AROUND : undefined
            }
            VERTICAL_ALIGN={formatFullScreen ? NatVerticalAlign.TOP : undefined}
            COLUMN
            FULL_WIDTH
          >
            {categoryString && (
              <NatLabel
                label={categoryString}
                type={NatLabelType.H2}
                style={{
                  width: "100%",
                  textAlign: "center",
                  pageBreakBefore:
                    formatFullScreen && pageBreakBefore ? "always" : "avoid",
                  breakBefore:
                    formatFullScreen && pageBreakBefore ? "always" : "avoid",
                }}
                hidden={!formatFullScreen}
              />
            )}
            <DesignSelectionsCarousel
              style={{
                overflow: "hidden",
                height: formatFullScreen ? "unset" : "inherit",
                alignItems: formatFullScreen ? "flex-start" : undefined,
              }}
              carouselHeightRem={relativeSlideHeightRem}
              noBorder={formatFullScreen}
              hidden={hideSection}
              carouselSize={carouselSizePx}
              columnMode={isNatMobile}
              formatFullScreen={formatFullScreen}
              key={`design-selection-${index}`}
              pageBreakAfter={pageBreakAfter}
              presentationMode={isGeneratingPDF}
              multipleSlides={false}
            >
              <DesignSelectionsCarouselWrapper
                style={{
                  minHeight: formatFullScreen ? "37rem" : "unset", //!!yOffsetPx ? `${carouselSizePx / 1.6}px` : "unset",
                  width: formatFullScreen ? "100%" : undefined,
                }}
              >
                <StudioCarousel
                  images={getImages(modifierIndexFromAllSelectedModifiers)}
                  animate={true}
                  mobileVersion={false}
                  alwaysHideCaptions={true}
                  positionAbsolute={false}
                  yOffsetPx={formatFullScreen ? 1 : yOffsetPx}
                  heightPx={!!yOffsetPx ? carouselSizePx / 1.6 : undefined}
                />
              </DesignSelectionsCarouselWrapper>
              <DesignSelectionsSlideInfo
                width={pageWidthPx}
                style={{
                  maxHeight: `${relativeSlideHeightRem}rem`,
                  overflow: "hidden",
                  width: "100%",
                  flexGrow: 1,
                }}
              >
                <DesignSelectionsPageTitle>
                  {item.title}
                  {!isProjectLocked && (
                    <StudioSectionButton
                      step={index}
                      cartItemId={props.cartItemId}
                    />
                  )}
                </DesignSelectionsPageTitle>
                <DesignSelectionsPagePrice>
                  {item.priceText + " in upgrades"}
                </DesignSelectionsPagePrice>
                <PageDetailsDropdown
                  maxColumnsAllowed={
                    formatFullScreen ? 1 : isGeneratingPDF ? 3 : 2
                  }
                  alwaysExpanded={true}
                  hideShowMore={isGeneratingPDF || formatFullScreen}
                  details={item.alternativePoints ?? []}
                />
              </DesignSelectionsSlideInfo>
            </DesignSelectionsCarousel>
          </NatFlex>
        </Fragment>
      );
    });
  }

  if (!product || !snapshot || !designId) {
    return null;
  }
  const {title: mainTitle} = props;
  const MainTitleElement = () => {
    if (
      projectPriceMode === ProjectPriceMode.CostPlus ||
      embeddedProposal ||
      isGeneratingPDF
    ) {
      return null;
    } else if (mainTitle) {
      return (
        <Fragment>
          <NatLabel
            label={mainTitle}
            type={NatLabelType.H2}
            style={{width: "100%", textAlign: "center"}}
          />
          {designId && (
            <UnitTitle
              isProjectLocked={isProjectLocked}
              designId={designId}
              setDesignId={fixedDesign ? undefined : setLocallySelectedDesignId}
              cartItemId={props.cartItemId}
            />
          )}
        </Fragment>
      );
    }
    return null;
  };
  const propsTitle = props.title;

  const MainContentElement = () => {
    const nonFullScreenSlides = getSlidesFromUpgradeList(
      upgradeListNotFullScreen,
      false,
      upgradeListFullScreen.length
    );
    let nonFullScreenSlidesByPage: React.JSX.Element[][] = [];
    let itemsAddedCount = 0;
    for (
      let nonFullScreenPageCounter = 0;
      nonFullScreenPageCounter < Math.ceil(nonFullScreenSlides.length / 3);
      nonFullScreenPageCounter++
    ) {
      let endIndex: undefined | number;
      let startIndex = nonFullScreenPageCounter;
      if (nonFullScreenPageCounter > 0) {
        startIndex = (nonFullScreenPageCounter + 1) * 3;
      }
      if (
        nonFullScreenPageCounter <
        Math.ceil(nonFullScreenSlides.length / 3) - 1
      ) {
        if (nonFullScreenPageCounter > 0) {
          endIndex = nonFullScreenPageCounter * 3;
        } else {
          endIndex = 3;
        }
      } else {
        startIndex = itemsAddedCount;
      }
      const itemsToAdd = nonFullScreenSlides.slice(startIndex, endIndex);
      nonFullScreenSlidesByPage.push(itemsToAdd);
      itemsAddedCount += itemsToAdd.length;
      // console.log(
      //   "nonFullScreenSlidesByPage",
      //   startIndex,
      //   endIndex ?? nonFullScreenSlides.length,
      //   nonFullScreenPageCounter
      // );
    }
    return (
      <Fragment>
        {getSlidesFromUpgradeList(
          upgradeListFullScreen,
          true,
          upgradeListFullScreen.length
        )}
        <div
          style={{
            width: "100%",
            pageBreakAfter:
              upgradeListNotFullScreen.length > 0 && false ? "always" : "avoid",
            breakAfter:
              upgradeListNotFullScreen.length > 0 && false ? "always" : "avoid",
            // height: "100%",
          }}
        >
          {nonFullScreenSlidesByPage.map((pageContent) => {
            return (
              <div
                style={
                  isGeneratingPDF
                    ? {
                        pageBreakInside: "avoid",
                        breakInside: "avoid",
                        minHeight: `${FULL_SCREEN_HEIGHT_REM * 0.9}rem`,
                        maxHeight: `${FULL_SCREEN_HEIGHT_REM * 0.9}rem`,
                        pageBreakAfter: "avoid",
                        breakAfter: "avoid",
                      }
                    : undefined
                }
              >
                {pageContent}
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  };
  return (
    <div style={{width: "100%", padding: "0 4rem"}}>
      <MainTitleElement />
      <MainContentElement />
      <VCL.Components.Atoms.Text
        text={StudioDisclaimerContent}
        style={{color: GRANITE, cursor: "default"}}
      />
    </div>
  );
};
