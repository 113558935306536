import React from "react";
import {useNavigation} from "../../../../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../../../../_shared/hooks/useNavigation/paths";
import {SSCSelectorExploreLink} from "./styles";
import {scrollToTop} from "../../../../../../../../_shared/navigation/_helpers";

interface SscViewSeriesComponentProps {
  id?: string;
}

const SSCViewSeriesComponent = (props: SscViewSeriesComponentProps) => {
  const {id} = props;
  const {to} = useNavigation();

  if (!id) {
    return null;
  }

  return (
    <SSCSelectorExploreLink
      onClick={() => {
        to(NavigationPaths.UNIT, undefined, id);
        scrollToTop(true);
      }}
    >
      View Series
    </SSCSelectorExploreLink>
  );
};

export default SSCViewSeriesComponent;
