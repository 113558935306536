import React, {useState} from "react";
import Select from "react-select";
import styled from "styled-components";
import {AiOutlinePlus} from "react-icons/ai";
import {ColorPickerComp} from "../../../../_shared/_legacy/ColorPicker";
import {FeetInchesInput} from "../../../../_shared/_legacy/FeetInchesInput";
import {GenericTable} from "../../../../_shared/generics/table/GenericTable";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {ImageAssignmentManager} from "../../../../_shared/generics/image/ImageAssignmentManager";
import {ImageManager} from "../../../ProductLineView/components/ImageManager";
import {ModifierDependencyTable} from "../ModifierDependencyTable";
import {NatButton} from "../../../../_shared/generics/button";
import {ProductOptionKeyToValueTable} from "../ProductOptions/ProductOptionKeyToValueTable";
import {SelectionOptionsInputWithArrays} from "../../../../_shared/_legacy/SelectionOptionsInput";
import {
  convertModifiersToSelectionOptions,
  DesignStudioModifier,
  fullPriceTextFromMicros,
  isBlankString,
} from "@natomas-org/core";
import {isDeepEqual} from "../../../ProductLineView/logic/AdminEditorHelper";

import {
  GenericInputContentContainer,
  InfoDescription,
  InputContainer,
  InputImageContainer,
  InputImageWrapper,
  InputTextArea,
  InputTextAreaContainer,
  InputTextField,
  InputTitle,
  YesNoSelector,
} from "../../styles/AdminStyles";
import {DatePickerInput} from "../../../ProductLineView/components/DatePickerInput";
import {AdminComponentProps, DatePickerInputProps} from "./AdminInputProps";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";

const Break = styled.div`
  width: 100%;
`;
const ColorCircle = styled.div<{bgColor: string}>`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-left: 10px;
  background-color: ${(props: any) => "" + props.bgColor};
`;

export const ChangeHighlight = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: 0;
  pointer-events: none;
  background-color: rgba(255, 165, 0, 0.15);
`;

function cleanUpValue(value: any, options: any) {
  return options[value] != null ? options[value] : {label: value, value: value};
}

function cleanUpImportedValue(valueDict: any, library: any) {
  return Object.keys(valueDict).map((key) => {
    return {
      value: key,
      label: library[key] != null ? library[key].title : key,
    };
  });
}

export const ModifierSelect = (props: AdminComponentProps) => {
  const {value, onChange} = props;
  const {modifiers} = useSelectedFactoryLine();
  const sanitizedModifiers: {[p: string]: DesignStudioModifier} =
    modifiers ?? {};
  return (
    <Select
      isMulti
      value={cleanUpImportedValue(value, sanitizedModifiers)}
      onChange={(values) => {
        const newSelectionValue: any = {};
        values.forEach((val) => {
          newSelectionValue[val.value] = true;
        });
        onChange(newSelectionValue);
      }}
      placeholder={"Modifiers"}
      options={convertModifiersToSelectionOptions(sanitizedModifiers)}
    />
  );
};

const DropdownSaveToArray = (props: AdminComponentProps) => {
  // Takes in an array and saves to an array
  const {value, fieldInfo, onChange, changeHighlight, enabled} = props;
  const {name, options, subType} = fieldInfo;
  return (
    <InputContainer fullWidth={true}>
      {changeHighlight}
      <InputTitle bold={true} enabled={enabled} title={name} />
      <SelectionOptionsInputWithArrays
        disabled={!enabled}
        options={options}
        handleChange={(value: any) => {
          onChange(value);
        }}
        value={value}
        limitHeight={false}
        isSingleSelect={subType === "single"}
      />
    </InputContainer>
  );
};

export const DropdownSelect = (props: AdminComponentProps) => {
  const {value, onChange, fieldInfo, changeHighlight, enabled} = props;
  const {name, options, index} = fieldInfo;

  return (
    <InputContainer fullWidth={true} style={{zIndex: 100 - (index ?? 0)}}>
      {changeHighlight}
      <InputTitle bold={true} enabled={enabled} title={name} />
      <GenericInputContentContainer>
        <Select
          isDisabled={!enabled}
          value={cleanUpValue(value, options)}
          onChange={(value) => {
            onChange(value.value);
          }}
          placeholder={name}
          options={Object.values(options)}
        />
      </GenericInputContentContainer>
    </InputContainer>
  );
};

export const YesNoInput = (props: AdminComponentProps) => {
  const {value, onChange, fieldInfo, changeHighlight, enabled} = props;
  const {name} = fieldInfo ?? {};
  const v = value ?? false;
  let subtitle: JSX.Element | null = null;
  if (fieldInfo?.subtitle) {
    subtitle = (
      <NatLabel
        label={fieldInfo?.subtitle}
        type={NatLabelType.P3}
        style={{paddingBottom: "0.5rem"}}
      />
    );
  }
  return (
    <InputContainer fullWidth={true} style={{zIndex: 100}}>
      {changeHighlight}
      <InputTitle bold={true} enabled={enabled} title={name} />
      {subtitle}
      <GenericInputContentContainer>
        <YesNoSelector
          disabled={!enabled}
          type={"button"}
          value={"No"}
          onClick={(value) => {
            onChange(false);
          }}
          selected={v === false}
        />
        <YesNoSelector
          disabled={!enabled}
          type={"button"}
          value={"Yes"}
          onClick={(value) => {
            onChange(true);
          }}
          selected={v === true}
        />
      </GenericInputContentContainer>
    </InputContainer>
  );
};

export const TableInput = (props: AdminComponentProps) => {
  const {value, onChange, fieldInfo, changeHighlight, enabled} = props;
  const {name, tableStructure} = fieldInfo;

  return (
    <>
      <Break />
      <InputContainer fullWidth={true}>
        {changeHighlight}
        <InputTitle bold={true} enabled={enabled} title={name} />
        <GenericInputContentContainer>
          <GenericTable
            enabled={enabled}
            currentValue={value}
            tableStructure={tableStructure}
            onChange={onChange}
          />
        </GenericInputContentContainer>
      </InputContainer>
      <Break />
    </>
  );
};

const ColorInput = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, changeHighlight, enabled} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  const [selectingColor, setSelectingColor] = useState(false);
  if (value || selectingColor) {
    return (
      <InputContainer fullWidth={true}>
        {changeHighlight}
        <InputTitle bold={true} enabled={enabled} title={name} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            zIndex: 11,
          }}
        >
          {enabled && (
            <ColorPickerComp
              key={key}
              keyName={key}
              value={value}
              onChange={(value: any) => {
                onChange(value.hex);
              }}
            />
          )}
          <ColorCircle bgColor={!isBlankString(value) ? value : "white"} />
        </div>
      </InputContainer>
    );
  } else {
    return (
      <InputContainer fullWidth={true}>
        {enabled ? (
          <NatButton
            icon={{
              icon: <AiOutlinePlus />,
              iconPosition: IconPosition.LEFT,
            }}
            label={name ?? ""}
            type={"button"}
            clickEvent={() => setSelectingColor(true)}
            size={NatSize.SMALL}
            option={StyleOption.SECONDARY_ALT}
          />
        ) : (
          "No " + name
        )}
      </InputContainer>
    );
  }
};

const PriceTable = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, changeHighlight, enabled} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  return (
    <InputContainer>
      {changeHighlight}
      <InputTitle bold={true} enabled={enabled} title={name} />
      {/*<ProductPriceToModifiersTable*/}
      {/*  name={name}*/}
      {/*  prices={value}*/}
      {/*  handleChange={onChange}*/}
      {/*/>*/}
    </InputContainer>
  );
};

const ModifierDependencyTableInput = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, changeHighlight, enabled} = props;
  const {key, name, type, defaultValue, subType} = fieldInfo;
  const [show, setShow] = useState<boolean>(false);
  const getValue = () => {
    if (!value || value?.length === 0) {
      return [];
    }
    // If the value has no edits - it will be in DB format
    if (!!value?.[0] && "price" in value?.[0]) {
      return value?.map((v: any) => {
        return {
          priceMicros: v?.price?.price,
          costMicros: v?.price?.cost,
          requiredModifiers: v?.dependentModifiers,
          condition: v?.condition,
          type: v?.type,
        };
      });
    } else {
      return value;
    }
  };
  return (
    <>
      <InputContainer fullWidth={true}>
        {changeHighlight}
        <InputTitle bold={true} enabled={enabled} title={name} />
        <NatButton
          label={show ? "Hide" : "Show"}
          type={"button"}
          clickEvent={() => setShow(!show)}
          option={StyleOption.PRIMARY_ALT}
          size={NatSize.SMALL}
        />
        {show && (
          <ModifierDependencyTable
            name={""}
            value={getValue()}
            handleChange={onChange}
            type={subType}
          />
        )}
      </InputContainer>
    </>
  );
};

const DimensionInput = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, changeHighlight, enabled} = props;
  return (
    <InputContainer fullWidth={true} style={{display: "flex"}}>
      {changeHighlight}
      <div style={{marginRight: "40px"}}>
        <InputTitle bold={true} enabled={enabled} title={"Front/Long Length"} />
        <FeetInchesInput
          enabled={enabled}
          feet={value["frontLength"].feet}
          inches={value["frontLength"].inches}
          setOutput={(values: any) => {
            const newDimensions = {
              frontLength: values,
              sideLength: value.sideLength,
            };
            onChange(newDimensions);
          }}
        />
      </div>
      <div>
        <InputTitle bold={true} enabled={enabled} title={"Side/Short Length"} />
        <FeetInchesInput
          enabled={enabled}
          feet={value["sideLength"].feet}
          inches={value["sideLength"].inches}
          setOutput={(values: any) => {
            const newDimensions = {
              frontLength: value.frontLength,
              sideLength: values,
            };
            onChange(newDimensions);
          }}
        />
      </div>
    </InputContainer>
  );
};

const ImageInput = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, changeHighlight, enabled} = props;
  const {key, name, type, defaultValue} = fieldInfo;
  return (
    <>
      <Break />
      <InputImageContainer key={key}>
        {changeHighlight}
        <InputTitle bold={true} enabled={enabled} title={name} />
        <InputImageWrapper>
          <ImageAssignmentManager
            forceAllow={enabled}
            key={key}
            imageIdValue={value}
            onComplete={onChange}
          />
        </InputImageWrapper>
      </InputImageContainer>
    </>
  );
};
/*
 * Multi- Image Input
 * Returns: Array of image ids
 * */
export const MultiImageInput = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, changeHighlight, enabled} = props;
  const {key, name, type, defaultValue} = fieldInfo;

  const updateImageIdArray = (id: string, index: number) => {
    let newArray = [...value];
    if (!!id) {
      if (index >= 0 && index < newArray?.length) {
        // Mutate the id at the index
        newArray[index] = id;
      } else {
        // Add the id to the array
        newArray = [...newArray, id];
      }
    } else {
      if (index < newArray?.length) {
        // Delete at index
        newArray.splice(index, 1);
      }
    }
    onChange(newArray);
  };

  return (
    <div style={{display: "flex", gap: "1rem", flexWrap: "wrap"}}>
      {value?.map((value: any, index: number) => {
        return (
          <InputImageContainer key={key + "-container-" + value}>
            {changeHighlight}
            <InputTitle
              bold={true}
              enabled={enabled}
              title={name + " " + (index + 1)}
            />
            <InputImageWrapper>
              <ImageAssignmentManager
                forceAllow={enabled}
                key={key + "-container-" + index}
                imageIdValue={value}
                onComplete={(v: any) => updateImageIdArray(v, index)}
              />
            </InputImageWrapper>
          </InputImageContainer>
        );
      })}
      <InputImageContainer key={key + "-container-" + (value?.length + 1)}>
        {changeHighlight}
        <InputTitle bold={true} enabled={enabled} title={"Add image"} />
        <InputImageWrapper>
          <ImageAssignmentManager
            key={key + "-container-" + (value?.length + 1)}
            forceAllow={enabled}
            imageIdValue={null}
            onComplete={(v: any) => updateImageIdArray(v, value?.length)}
            resetAfterUpload={true}
          />
        </InputImageWrapper>
      </InputImageContainer>
    </div>
  );
};

const TextAreaInput = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, changeHighlight, height, id, enabled} =
    props;
  const {key, name, type, subType, defaultValue} = fieldInfo;
  return (
    <InputTextAreaContainer key={key} fullWidth={true}>
      {changeHighlight}
      <InputTitle bold={true} enabled={enabled} title={name} />
      <InputTextArea
        disabled={!enabled}
        style={{minHeight: height ? height : "auto"}}
        id={id != null ? id : key}
        placeholder={
          isBlankString(defaultValue) ? "Empty " + name : "" + defaultValue
        }
        name={key}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={value}
        spellCheck={true}
      />
    </InputTextAreaContainer>
  );
};

const TextFieldInput = (props: AdminComponentProps) => {
  const {value, fieldInfo, onChange, id, changeHighlight, enabled} = props;
  const {key, name, type, subType, defaultValue, placeholder} = fieldInfo;
  const t = type ?? "text";
  return (
    <InputContainer key={key} fullWidth={true}>
      {changeHighlight}
      <InputTitle bold={true} enabled={enabled} title={name} />
      <InputTextField
        disabled={!enabled}
        id={id != null ? id : key}
        placeholder={
          placeholder ??
          (isBlankString(defaultValue) ? name : "" + defaultValue)
        }
        name={key}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={value}
        type={t}
        onWheel={(e: any) => e?.target?.blur()}
        spellCheck={true}
      />
      {subType === "price" && (
        <InfoDescription>
          {fullPriceTextFromMicros(value * 100)}
        </InfoDescription>
      )}
      {type === "iframe-link" && value && (
        <iframe
          title={"iframe-link" + key}
          className="frame"
          src={value}
          allowFullScreen
          allow="xr-spatial-tracking"
        />
      )}
      {(type === "iframe-link" || type === "video") && !value && (
        <InfoDescription>
          No preview available. Please enter a valid link.
        </InfoDescription>
      )}
    </InputContainer>
  );
};

const OptionKeyToValueInput = (props: AdminComponentProps) => {
  const {value, onChange, changeHighlight, enabled} = props;
  return (
    <div style={{width: "90%"}}>
      {changeHighlight}
      <InputTitle bold={true} enabled={enabled} title={"Option Values"} />
      <ProductOptionKeyToValueTable
        enabled={enabled}
        optionEntries={value}
        handleChange={(newData: any) => {
          const optionValueMap: any = {};
          newData.forEach((entry: any) => {
            optionValueMap[entry.key] = entry.value;
          });
          onChange(optionValueMap);
        }}
      />
    </div>
  );
};

export const AdminInput = (props: AdminComponentProps) => {
  const {fieldInfo, disabled} = props;
  let input;
  let changeHighlight = null;
  if (
    props.initialValue != null &&
    !isDeepEqual(props.initialValue, props.value)
  ) {
    changeHighlight = <ChangeHighlight />;
  }

  const enhancedProps: AdminComponentProps = {
    ...props,
    value: fieldInfo.valueSanitizer
      ? fieldInfo.valueSanitizer(props.value)
      : props.value,
    changeHighlight,
    enabled: !(disabled ?? false),
    onChange: (v: any) => {
      let value = v;
      if (fieldInfo.onChangeSanitizer) {
        value = fieldInfo.onChangeSanitizer(v);
      }
      if (props.onChange) {
        props.onChange(value);
      }
    },
  };

  switch (fieldInfo?.type) {
    case "color":
      input = <ColorInput {...enhancedProps} />;
      break;
    case "dropdown-array":
      input = <DropdownSaveToArray {...enhancedProps} />;
      break;
    case "priceTable":
      input = <PriceTable {...enhancedProps} />;
      break;
    case "optionKeyValues":
      input = <OptionKeyToValueInput {...enhancedProps} />;
      break;
    case "dimensions":
      input = <DimensionInput {...enhancedProps} />;
      break;
    case "image":
      input = <ImageInput {...enhancedProps} />;
      break;
    case "multi-image":
      input = <MultiImageInput {...enhancedProps} />;
      break;
    case "product-image-manager":
      input = <ImageManager {...enhancedProps} />;
      break;
    case "textarea":
      input = <TextAreaInput {...enhancedProps} />;
      break;
    case "modifier-dependency":
      input = <ModifierDependencyTableInput {...enhancedProps} />;
      break;
    case "dropdown":
      input = <DropdownSelect {...enhancedProps} />;
      break;
    case "table":
      input = <TableInput {...enhancedProps} />;
      break;
    case "yes-no":
      input = <YesNoInput {...enhancedProps} />;
      break;
    case "date-picker":
      input = <DatePickerInput {...(enhancedProps as DatePickerInputProps)} />;
      break;
    case "iframe-link":
      input = <TextFieldInput {...enhancedProps} />;
      break;
    default:
      input = <TextFieldInput {...enhancedProps} />;
      break;
  }

  return input;
};
