import React, {useState} from "react";
import {Tab} from "./styles";
import {AdminTabHeader} from "../../../ProductLineView/components/Header/AdminTabHeader";
import {OptionKeyTab} from "./OptionKeyTab";
import {OptionValueTab} from "./OptionValueTab";

export const ProductOptionTab = () => {
  const subTabs = [
    {id: "keys", title: "Keys"},
    {id: "values", title: "Values"},
  ];
  const [tab, setTab] = useState<string>(subTabs[0].id);
  return (
    <Tab>
      <AdminTabHeader tabs={subTabs} setTab={setTab} />
      {tab === "keys" && <OptionKeyTab />}
      {tab === "values" && <OptionValueTab />}
    </Tab>
  );
};
