import {TagType} from "./tags";
import {useSelector} from "react-redux";
import {IStore, ITag} from "../../slices/types/Store";
import {useEffect, useMemo} from "react";
import {isEmpty} from "@natomas-org/core";
import {fetchProductCatalogTags} from "../../../../database/firebase/admin/tags";

export const useTags = (type: TagType) => {
  const allTags = useSelector((state: IStore) => state?.tags);

  useEffect(() => {
    if (isEmpty(allTags)) {
      fetchProductCatalogTags().then(() => {
        console.log("Fetched product catalog tags");
      });
    }
  }, [allTags]);

  const tagIds = useMemo(() => {
    if (allTags && allTags?.[type]) {
      return Object.keys(allTags[type]);
    }
    return [];
  }, [allTags]);

  const tagDictionary = useMemo(() => {
    if (allTags && allTags?.[type]) {
      return allTags[type];
    }
    return {};
  }, [allTags]);

  const getTagTitleById = (tagId: string): string | null => {
    const tag: ITag | undefined = allTags?.[type]?.[tagId];
    return tag?.title?.toUpperCase() ?? null;
  };

  const getTagAuthorIdById = (tagId: string): string | null => {
    const tag: ITag | undefined = allTags?.[type]?.[tagId];
    return tag?.author ?? null;
  };

  const getTagTimestampById = (tagId: string): number | null => {
    const tag: ITag | undefined = allTags?.[type]?.[tagId];
    return tag?.timestamp ?? null;
  };

  const getTagIdByTitle = (title: string): string => {
    const s = title.toLowerCase();
    const tagId: string | undefined = tagIds?.find(
      (tagId: string) => allTags?.[type]?.[tagId]?.title === s
    );
    return tagId ?? "";
  };

  return {
    tagIds,
    tagDictionary,
    getTagTitleById,
    getTagAuthorIdById,
    getTagTimestampById,
    getTagIdByTitle,
  };
};
