import {createSlice} from "@reduxjs/toolkit";
import {
  CatalogStateOptions,
  IFetchingDocumentType,
  IFetchingStatus,
} from "./helper";
import {IOptionsSlice} from "./interfaces";

const getInitialState = () => {
  return {
    [CatalogStateOptions.OPTION_KEYS]: {},
    [CatalogStateOptions.OPTION_VALUES]: {},
    fetchingStatus: {},
  } as IOptionsSlice;
};

const optionsSlice = createSlice({
  name: "options",
  initialState: getInitialState(),
  reducers: {
    setFetchingStatus: (
      state,
      action: {
        payload: {
          documentType: IFetchingDocumentType;
          id: string;
          status: IFetchingStatus;
        };
      }
    ) => {
      const {documentType, id, status} = action.payload;
      const currentFetchingType = state.fetchingStatus[documentType] ?? {};
      currentFetchingType[id] = status;
      state.fetchingStatus[documentType] = currentFetchingType;
    },
    setOptionValues: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.optionValues[key] = action.payload[key];
      });
    },
    setOptionKeys: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.optionKeys[key] = action.payload[key];
      });
    },
  },
});

export const optionsReducer = optionsSlice.reducer;
export const {setOptionValues, setOptionKeys, setFetchingStatus} =
  optionsSlice.actions;
