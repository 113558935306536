import styled from "styled-components";
import {VILLA_FOREST_GREEN} from "../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../SSDDashboardView/styles/globals";

export const Phase1AndNextStepsIcon = styled.img`
  width: 100px;
  height: 100px;
`;
export const Phase1AndNextStepsSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  width: 100%;
`;
export const Phase1AndNextStepsSectionTitle = styled.div`
  color: ${VILLA_FOREST_GREEN};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 18px;
  width: 100%;
`;

export const Phase1AndNextStepsDescription = styled.div`
  font-size: 14px;
  width: 100%;
`;

export const Phase1AndNextStepsSectionContentContainer = styled.div`
  box-shadow: 0 4px 17px rgba(6, 60, 99, 0.17);
  width: 70%;
  padding: 2rem 2rem;
  border-radius: 0.5rem;
`;
