import {IProductAssociationDetails} from "../../../../_shared/interfaces";
import {InfoSetItemOptionConditionType} from "../../../../../../shared/interfaces";

export const getRatioNumerator = (
  conditionType: InfoSetItemOptionConditionType,
  conditionRequirementCount: number,
  validatedProducts: IProductAssociationDetails[]
) => {
  let numerator = 0;
  if (conditionRequirementCount === 0) {
    return numerator;
  }
  if (conditionType === InfoSetItemOptionConditionType.ALL) {
    numerator = validatedProducts.filter((vP: IProductAssociationDetails) => {
      return vP.contains.length === conditionRequirementCount;
    }).length;
  } else if (conditionType === InfoSetItemOptionConditionType.ANY) {
    numerator = validatedProducts.filter((vP: IProductAssociationDetails) => {
      return vP.contains.length > 0;
    }).length;
  }
  return numerator;
};
