import React, {useEffect, useState} from "react";
import {isBlankString} from "@natomas-org/core";
// @ts-ignore
import {firestore, storage} from "../../../../../database/firebase";
import Compressor from "compressorjs";
import {fetchImage} from "../../../../../database/firebase/images/fetching";
import {ImageAssignmentOptions} from "./ImageAssignmentOptions";
import {useCurrentUser} from "../../../hooks/useCurrentUser";

export const ImageAssignmentManager = (props: {
  forceAllow?: any;
  onComplete: (imageId: string | null) => void;
  imageIdValue?: any;
  fileNameCallback?: any;
  replaceImageReference?: any;
  resetAfterUpload?: any;
  ignoreRemoveWarning?: boolean;
  hideImageDetailsEditor?: boolean;
}) => {
  const {uid} = useCurrentUser();
  // @ts-ignore
  const imageStorageBaseRef = storage.ref("images");
  const imageFirestoreBaseRef = firestore.collection("images");
  const {
    forceAllow,
    onComplete,
    imageIdValue,
    fileNameCallback,
    replaceImageReference,
    resetAfterUpload,
    ignoreRemoveWarning,
    hideImageDetailsEditor,
  } = props;
  const [imageId, setImageId] = useState(imageIdValue);
  const allowImageReUpload = replaceImageReference === true;

  useEffect(() => {
    setImageId(imageIdValue);
  }, [imageIdValue]);

  const upload = (file: any) => {
    if (file) {
      if (fileNameCallback != null) {
        fileNameCallback(file.name);
      }
      let imageIdForUpload = imageId;
      if (isBlankString(imageIdForUpload) || !allowImageReUpload) {
        imageIdForUpload = imageFirestoreBaseRef.doc().id;
        setImageId(imageIdForUpload);
      }
      uploadFileForAllSizes(file, imageIdForUpload);
    }
  };

  const removeImage = () => {
    setImageId(null);
    onComplete(null);
  };

  const uploadFileForAllSizes = (file: any, imageIdForUpload: any) => {
    uploadFile(file, imageIdForUpload, "original");
    uploadFile(file, imageIdForUpload, "100");
    uploadFile(file, imageIdForUpload, "300");
    uploadFile(file, imageIdForUpload, "500");
    uploadFile(file, imageIdForUpload, "750");
    uploadFile(file, imageIdForUpload, "1000");
    uploadFile(file, imageIdForUpload, "1500");
    uploadFile(file, imageIdForUpload, "2500");
  };

  const uploadFile = (file: any, imageIdForUpload: any, size: any) => {
    let maxWidth;

    if (size !== "original") {
      maxWidth = parseInt(size);
    }

    new Compressor(file, {
      maxWidth: maxWidth,
      success(result) {
        const uploadTask = imageStorageBaseRef
          .child(size)
          .child(imageIdForUpload)
          .put(result);
        uploadTask.on(
          "state_changed",
          () => {
            // progress function ...
          },
          (error: any) => {
            // Error function ...
            console.log(error);
          },
          () => {
            // complete function ...
            uploadTask.snapshot.ref.getDownloadURL().then((url: any) => {
              imageFirestoreBaseRef
                .doc(imageIdForUpload)
                .set(
                  {
                    [size]: {
                      url,
                    },
                    id: imageIdForUpload,
                    author: uid,
                    timestamp: Date.now(),
                  },
                  {merge: true}
                )
                .then(() => {
                  if (size === "original") {
                    fetchImage(imageIdForUpload, true);
                    if (!allowImageReUpload) {
                      onComplete(imageIdForUpload);
                      if (resetAfterUpload) {
                        setImageId(null);
                      }
                    }
                  }
                });
            });
          }
        );
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  return (
    <ImageAssignmentOptions
      hideImageDetailsEditor={hideImageDetailsEditor}
      forceAllow={forceAllow ?? false}
      removeImage={removeImage}
      upload={upload}
      assign={onComplete}
      imageId={imageId}
      ignoreRemoveWarning={ignoreRemoveWarning ?? false}
    />
  );
};
