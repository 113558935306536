import {InfoSetItemTableRow} from "../InfoSetTable/table";
import {IProduct} from "@natomas-org/core/";
import {CONDITIONS_KEY, ID_KEY} from "../shared/constants";
import {InfoSetItemOptionCondition} from "../shared/interfaces";

export const sortInfoSetTableRowsByLabel = (rows: InfoSetItemTableRow[]) => {
  return rows.sort((a: InfoSetItemTableRow, b: InfoSetItemTableRow) => {
    return a.label.localeCompare(b.label);
  });
};

const hasNoConditions = (row: InfoSetItemTableRow) => {
  return row[CONDITIONS_KEY]?.length === 0;
};

export const isConstant = (rows: InfoSetItemTableRow[]) => {
  if (rows?.length === 1) {
    const row = rows[0];
    return hasNoConditions(row);
  }
  return false;
};

export const isInternalSelection = (rows: InfoSetItemTableRow[]) => {
  return rows.every((row: InfoSetItemTableRow) => hasNoConditions(row));
};

export const containsValidProductCondition = (
  rows: InfoSetItemTableRow[],
  product: IProduct
) => {
  const id = product?.id;
  if (!id) {
    return false;
  }
  return rows.some((row: InfoSetItemTableRow) => {
    const conditions: InfoSetItemOptionCondition[] = row[CONDITIONS_KEY];
    return conditions.some((condition: InfoSetItemOptionCondition) => {
      return condition?.targets?.includes(id);
    });
  });
};

export const isItemUncertainForProduct = (
  rows: InfoSetItemTableRow[],
  product: IProduct
): boolean => {
  return (
    !isConstant(rows) &&
    !isInternalSelection(rows) &&
    !containsValidProductCondition(rows, product)
  );
};

export const getUncertainKeysForProduct = (
  allRows: InfoSetItemTableRow[],
  product: IProduct
) => {
  if (!product || !allRows || allRows.length === 0) {
    return [];
  }
  const allKeys = [
    ...new Set(allRows.map((row: InfoSetItemTableRow) => row[ID_KEY])),
  ];

  return allKeys.filter((key: string) => {
    const rowsOfKey = allRows.filter(
      (row: InfoSetItemTableRow) => row[ID_KEY] === key
    );
    return isItemUncertainForProduct(rowsOfKey, product);
  });
};
