import styled from "styled-components";

export const PageRoot = styled.div<{
  enabled: boolean;
  padding: string;
}>`
  padding: ${(props) => props.padding};
  width: 100%;
  transition: ${(props) => (props.padding ? "padding 400ms ease-in-out" : "")};
  //overflow-y: auto;
  //overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;
