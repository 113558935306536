import React from "react";
import styled from "styled-components";
import {
  MobileStickyBottomContainer,
  MobileStickyCallToActionButtonContainer,
  MobileStickyDescriptionSuffixText,
  MobileStickyDescriptionText,
  MobileStickyFooterBumper,
} from "./styles/mobileStickyBottom";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";
import {NatVerticalAlign} from "../../../_shared/generics/_shared";

interface StickyBottomProps {
  callToActionButton: JSX.Element | {text: string; clickAction?: () => void};
  descriptionText: string;
  descriptionSuffixText: string;
  descriptionClickAction?: () => void;
}

const StickyBottomContainer = styled.div`
  display: ${(props) =>
    props.theme.Page.columnCount === 4 &&
    props.theme?.ProductViewContent?.showPriceCard !== false
      ? undefined
      : "none"};
`;

export const MobileStickyBottom = (props: StickyBottomProps) => {
  const {callToActionButton, descriptionText, descriptionSuffixText} = props;
  if ("text" in callToActionButton) {
    return null;
  }
  return (
    <StickyBottomContainer>
      <MobileStickyFooterBumper />
      <MobileStickyBottomContainer>
        <NatFlex ROW VERTICAL_ALIGN={NatVerticalAlign.BOTTOM}>
          {descriptionText && (
            <MobileStickyDescriptionText onClick={props.descriptionClickAction}>
              {descriptionText}
            </MobileStickyDescriptionText>
          )}
          {descriptionSuffixText && (
            <MobileStickyDescriptionSuffixText>
              {descriptionSuffixText}
            </MobileStickyDescriptionSuffixText>
          )}
        </NatFlex>
        <MobileStickyCallToActionButtonContainer
          center={!descriptionText && !descriptionSuffixText}
        >
          {callToActionButton}
        </MobileStickyCallToActionButtonContainer>
      </MobileStickyBottomContainer>
    </StickyBottomContainer>
  );
};

export const MobileStickyBottomCenteredButton = (props: StickyBottomProps) => {
  const {callToActionButton} = props;
  if ("text" in callToActionButton) {
    return null;
  }
  return (
    <>
      <MobileStickyFooterBumper />
      <MobileStickyBottomContainer style={{justifyContent: "center"}}>
        {callToActionButton}
      </MobileStickyBottomContainer>
    </>
  );
};
