import {
  Address,
  ICompletedProjectsDictionary,
  PublicProjectDisplayType,
  shallowEquals,
} from "@natomas-org/core";
import {PublicProjectInfo} from "@natomas-org/service";

export const getFeatures = (
  completedProjects: ICompletedProjectsDictionary
) => {
  const featureArray: any[] = [];
  const array = Object.values(completedProjects);
  array.forEach((projectDetails) => {
    if (!projectDetails?.published) {
      return;
    }
    const latitude = Address.getLatitude(
      Address.dataToAddress(projectDetails?.project_address)
    );
    const longitude = Address.getLongitude(
      Address.dataToAddress(projectDetails?.project_address)
    );
    if (!latitude || !longitude) {
      // Could use a geocoder here
      /*
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            "address": inputAddress
        }, function(results) {
            console.log(results[0].geometry.location); //LatLng
        });
      */
      return;
    }
    const randomizedCoordinates =
      PublicProjectInfo.getRandomizedCoordinates(projectDetails);
    let displayGeometry: {type: string; coordinates: number[]} | undefined =
      undefined;
    if (
      !!randomizedCoordinates &&
      !!randomizedCoordinates?.lat &&
      !!randomizedCoordinates?.lng
    ) {
      displayGeometry = {
        type: "Point",
        coordinates: [randomizedCoordinates?.lng, randomizedCoordinates?.lat],
      };
    }
    const alreadyExists = featureArray.find((feature) => {
      return shallowEquals(
        feature.properties?.projectDetails?.address,
        projectDetails?.project_address
      );
    });
    if (!alreadyExists || alreadyExists?.length < 1) {
      const hasEnrichedData = PublicProjectInfo.hasEnrichedData(projectDetails);
      featureArray.push({
        type: "Feature",
        properties: {
          cluster: false,
          title: projectDetails?.title,
          code: projectDetails?.code,
          projectDetails: projectDetails,
          displayAsEnriched: hasEnrichedData,
          displayType:
            projectDetails?.displayType === PublicProjectDisplayType.TARGET
              ? PublicProjectDisplayType.TARGET
              : hasEnrichedData
              ? PublicProjectDisplayType.COMPLETED_ENRICHED
              : PublicProjectDisplayType.COMPLETED,
        },
        geometry: displayGeometry,
      });
    }
  });
  console.log("featureArray", completedProjects);

  return featureArray;
};
