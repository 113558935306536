import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {addressReducer} from "./components/_shared/slices/AddressSlice";
import {adminReducer} from "./components/_shared/slices/AdminSlice";
import {authReducer} from "./components/_shared/slices/AuthSlice";
import {mapReducer} from "./components/map/slices/mapSlice";
import {configurationReducer} from "./components/_shared/slices/ConfigurationSlice";
import {globalReducer} from "./components/_shared/slices/GlobalSlice";
import {imageFinderReducer} from "./components/_shared/slices/ImageFinderSlice";
import {intakeFormReducer} from "./components/intake-form/slices/IntakeFormSlice";
import {navReducer} from "./components/_shared/slices/NavigationSlice";
import {pageReducer} from "./components/_shared/slices/PageSlice";
import {portalReducer} from "./components/portal/_shared/slices/ProjectSummarySlice";
import {studioReducer} from "./components/_shared/slices/StudioSlice";
import {tagsReducer} from "./components/_shared/slices/TagsSlice";
import {userReducer} from "./components/_shared/slices/UserSlice";
import {utmReducer} from "./components/_shared/slices/UTMSlice";
import {isE2ERunning} from "./components/_shared/application";
import LogRocket from "logrocket";
import {natomasMapReducer} from "./components/_shared/slices/NatomasMapSlice/NatomasMapSlice";
import {getInitialAddressState} from "./components/_shared/slices/AddressSlice/helper";
import {adminStudioManagerReducer} from "./components/_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {
  catalogSessionReducer,
  getInitialCatalogSessionState,
} from "./components/_shared/slices/CatalogSlice/CatalogSessionSlice";
import {factoryReducer} from "./components/_shared/slices/FactorySlice";
import {optionsReducer} from "./components/_shared/slices/CatalogSlice/CatalogSlice";
import {sessionReducer} from "./components/_shared/slices/SessionSlice/SessionSlice";
import {propertyDataReducer} from "./components/_shared/slices/PropertyDataSlice/PropertyDataSlice";
import {completedProjectsReducer} from "./components/_shared/slices/CompletedProjectsSlice/CompletedProjectsSlice";
import {adminFactoryInventoryEditorReducer} from "./components/_shared/slices/CatalogSlice/AdminFactoryInventoryEditorSlice";
import {projectReducer} from "./components/_shared/slices/ProjectSlice/ProjectSlice";
import {cartReducer} from "./components/_shared/slices/CartSlice/CartSlice";
import {activityReducer} from "./components/_shared/slices/ActivitySlice/ActivitySlice";
import {designReducer} from "./components/_shared/slices/DesignSlice";
import {cartSessionReducer} from "./components/_shared/slices/CartSessionSlice";
import {budgetReducer} from "./components/_shared/slices/BudgetSlice";

export const store = configureStore({
  preloadedState: {
    address: getInitialAddressState(),
    catalogSession: getInitialCatalogSessionState(),
  },
  reducer: {
    activity: activityReducer,
    address: addressReducer,
    budget: budgetReducer,
    administration: adminReducer,
    auth: authReducer,
    adminStudioManager: adminStudioManagerReducer,
    adminFactoryInventoryEditor: adminFactoryInventoryEditorReducer,
    cart: cartReducer,
    cartSession: cartSessionReducer,
    catalogSession: catalogSessionReducer,
    completedProjects: completedProjectsReducer,
    configuration: configurationReducer,
    design: designReducer,
    factory: factoryReducer,
    global: globalReducer,
    intakeForm: intakeFormReducer,
    imageFinder: imageFinderReducer,
    natomasMap: natomasMapReducer,
    navigation: navReducer,
    options: optionsReducer,
    page: pageReducer,
    portal: portalReducer,
    project: projectReducer,
    propertyData: propertyDataReducer,
    studio: studioReducer,
    tags: tagsReducer,
    user: userReducer,
    utm: utmReducer,
    map: mapReducer,
    session: sessionReducer,
  },
  middleware: [
    ...getDefaultMiddleware({immutableCheck: false, serializableCheck: false}),
    LogRocket.reduxMiddleware(),
  ],
});

export const getStore = () => {
  return store;
};

if (isE2ERunning) {
  // @ts-ignore
  window.store = store;
}
