import React from "react";
import {DeveloperViewContainer} from "../_shared/DeveloperViewContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {ElementContainer} from "../../styles";
import {NatSizeType} from "../../../../_shared/generics/_shared";
import {BLACK, VILLA_BLUE, WHITE} from "../../../../_shared/colors";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import {
  DropdownStyles,
  INatDropdownStyleOptions,
  NatDropdownStyles,
} from "../../../../_shared/generics/popup/dropdown/constants";

const Dropdowns = (props: {backgroundColor: string}) => {
  return (
    <PageElement size={4} height={"auto"}>
      <ElementContainer backgroundColor={props.backgroundColor}>
        {Object.keys(DropdownStyles)
          .filter((k: any) => isNaN(k))
          ?.map((key: string) => {
            const o: INatDropdownStyleOptions =
              //@ts-ignore
              NatDropdownStyles?.[DropdownStyles?.[key]][NatSizeType.NORMAL];
            if (key === "POPUP_BUBBLE") {
              return null;
            }
            return (
              <NatDropdown
                style={o}
                contentItemArray={[
                  {id: "id 1", label: "Item 1"},
                  {id: "id 2", label: "Item 2"},
                  {id: "id 3", label: "Item 3"},
                ]}
                toggleButtonOrLabel={key}
              />
            );
          })}
      </ElementContainer>
    </PageElement>
  );
};

export const NatDropdownSection = () => {
  return (
    <DeveloperViewContainer title={"NatDropdowns"}>
      <Dropdowns backgroundColor={WHITE} />
      <Dropdowns backgroundColor={BLACK} />
      <Dropdowns backgroundColor={VILLA_BLUE} />
    </DeveloperViewContainer>
  );
};
