import React from "react";
import {PageContainer} from "../../../../../_shared/generics/page/components/PageContainer";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
// CTA
import {FAQAndScheduleAssessment} from "./FAQAndScheduleAssessment";
import {useGlobalConfiguration} from "../../../../../_shared/hooks/useGlobalConfiguration";
import {PageElementWrapper} from "../../../../../_shared/generics/page/components/PageElementWrapper";
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {useMapping} from "../../../../../mapping/views/UseMapping";
import {WelcomeCTA} from "./SSDHomePage/WelcomeCTA";
import {
  usePage,
  useScrollPageToTopOnMount,
} from "../../../../../_shared/hooks/usePage";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../_shared/hooks/useDesign/useActiveDesign";

export const SSDDashboardView = (props: {justProjectInfo?: boolean}) => {
  const {address, info} = useActiveProject();
  const {map, configuration} = useActiveDesign();
  const {product} = configuration ?? {};
  const {customer} = useCurrentCustomer();
  const {SHOW_FAQ_EVERYWHERE} = useGlobalConfiguration();
  const {isNatMobile} = usePage();

  useScrollPageToTopOnMount();

  const elementWidth = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forSixteen: 8,
    forTwelve: 6,
  });

  const {mappingPreview, modal} = useMapping({
    displayProps: {
      readOnly: false,
      panningDisabled: isNatMobile,
      minimal: isNatMobile,
      showSideBar: false,
      showSaveButton: !isNatMobile,
      hideDisclaimer: true,
      hideAddress: false,
      hideAHJ: false,
      displayAHJAsModal: isNatMobile,
    },
    unitPlacement: map?.unitPlacement,
    saveToProjectId: configuration?.id,
    product: product,
    address: address,
  });

  const getProjectInfo = () => (
    <>
      <PageElementWrapper reverseWrap={true}>
        <PageElement size={elementWidth} height={"auto"}>
          <br />
          {!!customer && (
            <WelcomeCTA
              projectSummary={info}
              customer={customer}
              product={product}
            />
          )}
        </PageElement>
        {mappingPreview && (
          <PageElement
            size={elementWidth}
            height={isNatMobile ? "40vh" : "30rem"}
            style={{borderRadius: 16, overflow: "hidden"}}
          >
            {mappingPreview}
          </PageElement>
        )}
      </PageElementWrapper>
      {modal}
    </>
  );

  if (props.justProjectInfo) {
    if (!customer) {
      return null;
    }
    return (
      <div style={isNatMobile ? {padding: "1rem 0"} : {padding: "2rem 0"}}>
        {getProjectInfo()}
      </div>
    );
  }

  return (
    <>
      <br />
      <PageContainer>{getProjectInfo()}</PageContainer>
      {SHOW_FAQ_EVERYWHERE && (
        <FAQAndScheduleAssessment defaultExpanded={false} />
      )}
    </>
  );
};
