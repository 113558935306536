import React from "react";
import {
  HCPCalendarBottom,
  HCPCalendarDivider,
  HCPCalendarEventContainer,
  HCPCalendarHeader,
  HCPCalendarStatusAndTitle,
  HCPCalendarTextContainer,
  HCPCalendarTop,
} from "./styles";
import {ProgressCircleForStatus} from "../../../../../_shared/hooks/useNavigation/AvatarDropdown/components";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../_shared/generics/label/NatLabel";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {NatLink} from "../../../../../_shared/generics/button";
import {NatSize} from "../../../../../_shared/generics/_shared";
import {usePage} from "../../../../../_shared/hooks/usePage";

export enum HCPCalendarEventStatus {
  not_scheduled = "disabled",
  scheduled_incomplete = "calendar-in-progress",
  scheduled_complete = "completed",
}
export interface IHCPCalendarEvent {
  titleText: string;
  whenText: string;
  whereText: string;
  withText: string;
  agendaText: string;
  status: HCPCalendarEventStatus;
  email: string;
}
export const HCPCalendarEvent = (props: IHCPCalendarEvent) => {
  let content = <div>To be scheduled...</div>;
  const eventTopHalfHeight = useDynamicValue({
    forFour: "unset",
    forEight: "9.5rem",
    forTwelve: "8.25rem",
    forSixteen: "7rem",
  });
  const isScheduled = props.status !== HCPCalendarEventStatus.not_scheduled;

  const eventBottomHalfHeight = useDynamicValue({
    forFour: "unset",
    forEight: "9rem",
    forTwelve: "9rem",
    forSixteen: "9rem",
  });
  const {isNatMobile} = usePage();

  if (props.status !== HCPCalendarEventStatus.not_scheduled) {
    content = (
      <>
        <NatLabel
          label={`Meeting with ${props.withText} on ${props.whenText} via ${props.whereText}`}
          type={NatLabelType.P3}
        />
        <NatLabel
          label={"Check your email for more information"}
          type={NatLabelType.P3}
        />
        <NatLink
          size={isNatMobile ? NatSize.XSMALL : NatSize.SMALL}
          label={"Need to reschedule? Email your home consultant"}
          link={`mailto:${props.email}`}
        />
      </>
    );
  }

  return (
    <HCPCalendarEventContainer disabled={!isScheduled}>
      <HCPCalendarTop style={{minHeight: eventTopHalfHeight}}>
        <HCPCalendarHeader>
          <HCPCalendarStatusAndTitle
            id={props.titleText + " calendarStatusAndTitle"}
          >
            <NatLabel
              label={props.titleText}
              weight={NatLabelWeight.MEDIUM}
              type={NatLabelType.P1}
              style={{margin: 0}}
            />
            <div hidden={!isScheduled}>
              <ProgressCircleForStatus
                status={props.status}
                description={props.titleText}
              />
            </div>
          </HCPCalendarStatusAndTitle>
          <NatLabel
            hidden={!isScheduled}
            label={props.whenText}
            type={NatLabelType.P3}
          />
        </HCPCalendarHeader>
        <NatLabel label={props.agendaText} type={NatLabelType.P3} />
      </HCPCalendarTop>
      <HCPCalendarBottom style={{minHeight: eventBottomHalfHeight}}>
        <HCPCalendarDivider />
        <HCPCalendarTextContainer>{content}</HCPCalendarTextContainer>
      </HCPCalendarBottom>
    </HCPCalendarEventContainer>
  );
};
