import {EstimateTableConfigurationOptions} from "../_shared/table";

export const EstimateTables: EstimateTableConfigurationOptions = {
  PC: {
    key: "Preconstruction",
    label: "Preconstruction",
    description:
      "This category includes costs related to surveys and reports that are generally required to build in California.",
  },
  JR: {
    key: "Jurisdictional Requirements",
    label: "Jurisdictional Requirements",
    description:
      "This category includes costs related to items that are specifically required by your jurisdictions for permitting. For example, some areas require an arborist report, soils report, or a grading and drainage plan.",
  },
  HD: {
    key: "Home Design",
    label: "Home Design",
    description:
      "This phase includes selecting any upgrades or customizations during the design process.",
  },
  SW: {
    key: "Site Work",
    label: "Site Work",
    description:
      "This phase includes delivery of your unit, preparing your building site, and installation.",
  },
  CC: {
    key: "Contingent Costs",
    label: "Contingent Costs",
    description:
      "This category includes any additional costs that are specific to your property’s needs, such as water meter upgrades or sewer hookups.",
  },
};
