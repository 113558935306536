import {IProduct, SortDropdownValues} from "@natomas-org/core";
import {PricingMode} from "../../slices/GlobalSlice";
import {getProductPricingInfo} from "../../_legacy/logic/ProductUtils";

const getSortingMethod = (
  pricingMode: PricingMode,
  sortBy: SortDropdownValues
): ((a: IProduct, b: IProduct) => number) => {
  switch (sortBy) {
    case SortDropdownValues.PRICE_ASC:
      return (a: IProduct, b: IProduct) =>
        getProductPricingInfo(pricingMode, a)?.price -
        getProductPricingInfo(pricingMode, b)?.price;
    case SortDropdownValues.PRICE_DESC:
      return (a: IProduct, b: IProduct) =>
        getProductPricingInfo(pricingMode, b)?.price -
        getProductPricingInfo(pricingMode, a)?.price;
    case SortDropdownValues.SQUARE_FOOTAGE_ASC:
      return (a: IProduct, b: IProduct) =>
        a.productDetails.squareFeet - b.productDetails.squareFeet;
    case SortDropdownValues.SQUARE_FOOTAGE_DESC:
      return (a: IProduct, b: IProduct) =>
        b.productDetails.squareFeet - a.productDetails.squareFeet;
    case SortDropdownValues.BED_DESC:
      return (a: IProduct, b: IProduct) =>
        b.productDetails.bedrooms - a.productDetails.bedrooms;
    case SortDropdownValues.BATH_DESC:
      return (a: IProduct, b: IProduct) =>
        b.productDetails.bathrooms - a.productDetails.bathrooms;
    case SortDropdownValues.BED_ASC:
      return (a: IProduct, b: IProduct) =>
        a.productDetails.bedrooms - b.productDetails.bedrooms;
    case SortDropdownValues.BATH_ASC:
      return (a: IProduct, b: IProduct) =>
        a.productDetails.bathrooms - b.productDetails.bathrooms;
    case SortDropdownValues.TITLE_ASC:
      return (a: IProduct, b: IProduct) => a.title.localeCompare(b.title);
    default:
      return () => 0;
  }
};

export const sortProducts = (
  products: IProduct[],
  sortBy: SortDropdownValues | null
): IProduct[] => {
  if (!sortBy) {
    return products;
  }
  // TODO B2B Pricing Mode
  const pricingMode = PricingMode.UNIT;
  const primarySortingMethod = getSortingMethod(pricingMode, sortBy);
  const secondSortingMethod = getSortingMethod(
    pricingMode,
    SortDropdownValues.PRICE_ASC
  );
  const thirdSortingMethod = getSortingMethod(
    pricingMode,
    SortDropdownValues.TITLE_ASC
  );
  return products?.sort((a: IProduct, b: IProduct) => {
    if (!a?.productDetails) {
      return 1;
    }
    if (!b?.productDetails) {
      return -1;
    }
    const primarySort = primarySortingMethod(a, b);
    const secondSort = secondSortingMethod(a, b);
    const thirdSort = thirdSortingMethod(a, b);
    if (primarySort !== 0) {
      return primarySort;
    } else if (secondSort !== 0) {
      return secondSort;
    } else {
      return thirdSort;
    }
  });
};
