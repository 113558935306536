import styled from "styled-components";
import {DIVIDER_COLOR, GRAY} from "../../colors";

export const AdminControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${DIVIDER_COLOR};
  border: 1px solid ${GRAY};
  border-radius: 4px;
  padding: 4px 6px;
`;

export const AdminControllerLabel = styled.p`
  font-family: "Consolas", "Menlo", "Lucida Console", "Courier New", monospace;
  font-size: 13px;
  padding: 0;
  margin: 0;
`;
