import {StudioController} from "../../../_shared/components/StudioController";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {NatomasButtonProps} from "../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import React from "react";
import {
  CustomerJourneyStepName,
  useTracking,
} from "../../../../_shared/hooks/useTracking";
import {NatResetIcon} from "../../../../_shared/icon/icons";
import {IStudioOptions} from "../../../_shared/components/StudioContent";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {useDispatch} from "react-redux";
import {setRegistrationModalState} from "../../../../_shared/slices/SessionSlice/SessionSlice";
import {buildConfigurationObject} from "../../helper";
import useLeadExists from "../../../../../api/Lead/useLeadExists";
import {INTAKE_FORM_REDIRECT_TYPE} from "../../../../intake-form/constants";

export interface DesignerControllerProps {
  formik: any;
  summary: any;
  minimize: boolean;
  canSave: boolean;
  save: any;
  reset: any;
  options?: IStudioOptions;
}

export const DesignerController = (props: DesignerControllerProps) => {
  const {formik, summary, save, canSave, reset, options} = props;
  const {loggedInUser, uid} = useCurrentUser();
  const {data: lead} = useLeadExists(uid);
  const {isCustomerLoaded} = useCustomerStatus();
  const {isEditing, isSaving} = useStudioSettings();
  const {to} = useNavigation();
  const {trackJourneyStep} = useTracking();
  const isPublicApp = useIsPublicApp();
  const dispatch = useDispatch();
  const saveAction = () => {
    if (!loggedInUser || !lead) {
      // If no user logged in and a public app save is attempted, present the registration modal
      const configuration = buildConfigurationObject(summary);
      dispatch(
        setRegistrationModalState({
          visible: true,
          signUp: true,
          registrationValues: {
            configuration: configuration,
            unitPlacement: undefined,
          },
          redirectType: INTAKE_FORM_REDIRECT_TYPE.INSTANT_ESTIMATE,
        })
      );
    }
    // Only save if a customer is loaded and the user is logged in and not on the public app
    else if (!!loggedInUser && lead && isCustomerLoaded && !isPublicApp) {
      if (canSave && !!save) {
        trackJourneyStep(CustomerJourneyStepName.UNIT_SELECTION_COMPLETED);
        return save(formik, summary);
      } else {
        to(NavigationPaths.PORTAL);
      }
    }
  };

  const attemptSave = (event: any) => {
    event?.preventDefault();
    saveAction();
  };

  const attemptReset = (event: any) => {
    event?.preventDefault();
    reset();
  };

  const buttonSize = NatSize.NORMAL;
  const saveDesignButton: NatomasButtonProps = {
    hidden: !isEditing || (isPublicApp && !!loggedInUser),
    clickEvent: attemptSave,
    disabled: isPublicApp && !!loggedInUser,
    id: "next",
    label: !canSave
      ? "Continue"
      : isPublicApp && !loggedInUser
      ? `Create account to save design`
      : !lead
      ? `Complete account to save design`
      : `Save design`,
    trackingId: !canSave
      ? "studio-continue"
      : isPublicApp && !loggedInUser
      ? `studio-create`
      : !lead
      ? `studio-complete`
      : `studio-save`,
    option: !canSave ? StyleOption.PRIMARY_ALT : StyleOption.PRIMARY,
    size: buttonSize,
    spinnerEnabled: isEditing && isSaving && lead,
    type: "button",
  };

  const designResetButton: NatomasButtonProps = {
    hidden: !isEditing,
    disabled: isSaving,
    clickEvent: attemptReset,
    id: "reset-design",
    label: "Revert",
    option: StyleOption.PRIMARY_ALT,
    size: buttonSize,
    type: "button",
    tooltip: "Revert changes to last saved state",
    icon: {
      icon: <NatResetIcon />,
      iconPosition: IconPosition.ONLY,
    },
  };

  return <StudioController buttons={[designResetButton, saveDesignButton]} />;
};
