import styled from "styled-components";
import {
  ADMIN_EDIT,
  BUTTON_FILL_COLOR_GRAY_2,
  SIDEBAR_COLOR,
  WHITE,
} from "../../../../../../_shared/colors";
import {LinkIconContainer} from "../../../../components/DragAndDropEditor/styles";
import {FONT_FAMILY_MEDIUM} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const MatrixHeaderCell = styled.div`
  padding: 1rem;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #ddd;
  font-family: ${FONT_FAMILY_MEDIUM};
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  height: 100%;
  width: 100%;
`;

export const MatrixTableCell = styled.td<{
  backgroundColor?: string;
  centered?: boolean;
  horizontalSticky?: boolean;
  width?: string;
  selected?: boolean;
  edited?: boolean;
  positive?: boolean;
  negative?: boolean;
  padding?: string;
}>`
  padding: ${(props) => props?.padding ?? 0};
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  overflow-wrap: anywhere;

  height: 2rem !important;
  max-width: ${(props) => props.width} !important;
  width: ${(props) => props.width} !important;
  min-width: ${(props) => props.width} !important;

  background-color: ${(props) =>
    props?.selected
      ? "#b9edff"
      : props?.edited
      ? "#ffe38d"
      : props?.positive
      ? "#acf8bc"
      : props?.negative
      ? "#fd9a9a"
      : props.backgroundColor ?? WHITE};

  vertical-align: middle !important;
  text-align: ${(props) => (props?.centered ? "center" : "left")} !important;
  ${MatrixHeaderCell} {
    display: flex;
    align-items: center !important;
    justify-content: ${(props) =>
      props?.centered ? "center" : "flex-start"} !important;
  }
  ${(props) =>
    props.horizontalSticky &&
    `
    left: 0;
    position: sticky;
    z-index: 1;
    background-color: #f3f3f3;
  `}
  ${LinkIconContainer} {
    position: absolute;
    top: 3px;
    right: 3px;
  }
  input {
    height: 100%;
    width: 100%;
  }
`;

export const MatrixTableCellIconContainer = styled.div<{
  hasCurrent?: boolean;
  hasEdits?: boolean;
}>`
  position: absolute;
  right: 0;
  top: 0;
  height: 1rem;
  width: 1rem;
  display: flex;
  svg {
    height: 1rem;
    width: 1rem;
    padding: 0;
    margin: 0;
    color: ${(props) =>
      props.hasEdits
        ? ADMIN_EDIT
        : props.hasCurrent
        ? SIDEBAR_COLOR
        : BUTTON_FILL_COLOR_GRAY_2};
    &:hover {
      color: lightskyblue;
    }
  }
`;

// .horizontal-sticky {
//   left: 0;
//   width: 300px !important;
//   position: sticky;
//   z-index: 1;
// }

// .centered-cell {
//   vertical-align: middle !important;
//   text-align: center !important;
// }

// .positive-cell {
//   background-color: #acf8bc !important;
// }
//
// .negative-cell {
//   background-color: #fd9a9a !important;
// }
//
// .edited-cell {
//   background-color: # background-color: #ffe38d !important; !important;
// }
