import {executeRequest} from "../api";
import {multiPolygon, rewind} from "@turf/turf";
import {
  NatomasMapFeatureDeleteRequest,
  NatomasMapFeatureType,
  NatomasMapFeatureWriteRequest,
  NatomasMapFeature,
} from "@natomas-org/core";
import {storeMapFeature} from "../../../components/_shared/slices/NatomasMapSlice/NatomasMapSlice";
import {store} from "../../../store";

export const updateMappingDoc = async (
  id: string,
  mapping: NatomasMapFeature
) => {
  const polygons = mapping?.zones?.coordinates ?? [];
  // @ts-ignore
  const poly = multiPolygon(polygons?.map((p) => p));
  // Only care about the geometry
  const rewindPolygons = rewind(poly)?.geometry;

  if (rewindPolygons?.coordinates?.length > 0) {
    const r: NatomasMapFeature = {
      id: id,
      type: mapping.type ?? NatomasMapFeatureType.UNKNOWN,
      zones: rewindPolygons,
      properties: mapping?.properties ?? undefined,
    };
    return await updateMapFeature({id: id, map_feature: r}).then((r) => {
      if (r?.ok) {
        store.dispatch(storeMapFeature({id: r?.value?.id, mapping: r?.value}));
        return true;
      } else {
        return false;
      }
    });
  } else {
    return await removeMapFeature({id: id}).then(
      (r: {acknowledged: boolean; deletedCount: number}) => {
        if (r?.acknowledged && r?.deletedCount === 1) {
          store.dispatch(storeMapFeature({id: id, mapping: null}));
          return true;
        } else {
          return false;
        }
      }
    );
  }
};

const updateMapFeature = (mapFeatureRequest: NatomasMapFeatureWriteRequest) => {
  return executeRequest("/map/v1/save-mapping-feature", mapFeatureRequest);
};
const removeMapFeature = (
  mapFeatureRequest: NatomasMapFeatureDeleteRequest
) => {
  return executeRequest("/map/v1/delete-mapping-feature", mapFeatureRequest);
};

// const testMapQuery = (
//   mapFeatureRequest: NatomasMapFeatureFindByTypeRequest
// ) => {
//   return executeRequest("/map/v1/query-mapping-features", mapFeatureRequest);
// };

// const test1 = await testMapQuery({
//   coordinates: {
//     longitude: -119.78,
//     latitude: 39.45,
//   },
// });
// console.log("Coordinates, Expect -> [aduV2]:", test1);
//
// const test2 = await testMapQuery({
//   types: [NatomasMapFeatureType.FACTORY_LINE],
// });
// console.log("Types, Expect -> [aduV2]:", test2);
//
// const test3 = await testMapQuery({
//   types: [NatomasMapFeatureType.FACTORY_LINE],
//   coordinates: {
//     longitude: -119.78,
//     latitude: 39.45,
//   },
// });
// console.log("Both, Expect -> [aduV2]:", test3);
//
// const test4 = await testMapQuery({
//   coordinates: {
//     longitude: 0,
//     latitude: 0,
//   },
// });
// console.log("Coordinates, Expect -> []:", test4);
//
// const test5 = await testMapQuery({
//   types: [NatomasMapFeatureType.PRODUCT],
// });
// console.log("Types, Expect -> []:", test5);
//
// const test6 = await testMapQuery({
//   types: [NatomasMapFeatureType.FACTORY_LINE],
//   coordinates: {
//     longitude: 0,
//     latitude: 0,
//   },
// });
// console.log("Both, Expect -> []:", test6);
// const test7 = await testMapQuery({
//   types: [NatomasMapFeatureType.PRODUCT],
//   coordinates: {
//     longitude: -119.78,
//     latitude: 39.45,
//   },
// });
// console.log("Both, Expect -> []:", test7);
