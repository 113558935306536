import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  AdminModifierGroupStructure,
  EditModifierGroupStructure,
} from "../../../_models/modifierGroup";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {editModifierGroup} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {useFormik} from "formik";
import {DNDEditor} from "../../components/DragAndDropEditor/DNDEditor";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {getBlankItemSection} from "../ProductsTab/EditProduct";
import {
  InputContainer,
  InputTitle,
  MainContent,
  MainView,
  NatStyledForm,
} from "../../../_shared/styles/AdminStyles";
import {AdminInput} from "../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {SelectionOptionsInput} from "../../../../_shared/_legacy/SelectionOptionsInput";
import {
  DuplicateFactoryLineElementRequestType,
  SHOW_COLOR_SWATCHES_PROPERTY_KEY,
  SHOW_FULL_TEXT_PROPERTY_KEY,
  SHOW_MULTI_SELECT_PROPERTY_KEY,
} from "@natomas-org/core";
import styled from "styled-components";
import {EditModifierOverrides} from "../../../../_shared/_legacy/EditModifierOverrides";
import {AdminTabHeader} from "../../components/Header/AdminTabHeader";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixIncludeOptions,
  IStore,
} from "../../../../_shared/slices/types/Store";
import {AssociationsContainer} from "../../components/AssociationsContainer";
import {
  getCategorySection,
  getProductSection,
} from "../../components/AssociationsContainer/predefinedSections";
import {getDistinctProductWithCategories} from "../../components/TabColumn/helper";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {getProductModifierUpdates} from "../MatrixTab/_shared/utilities";
import {
  archiveModifierGroup,
  undoArchiveModifierGroup,
} from "../../../../../database/firebase/catalog/saving";
import {DuplicateFactoryLineElementButton} from "../ProductsTab/DuplicateFactoryLineElementButton";
import {MatrixTab} from "../MatrixTab";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

const Break = styled.div`
  width: 100%;
`;

const convertModifierGroupPropertiesToOptions = (properties: any) => {
  return Object.keys(properties).map((property) => {
    return {
      value: property,
    };
  });
};
const convertOptionsToModifierGroupProperties = (options: any) => {
  if (options == null || options?.length < 1) {
    return null;
  }
  let propertyObject = {};
  options.forEach((property: any) => {
    // @ts-ignore
    propertyObject[property.value] = true;
  });
  return propertyObject;
};

const tabs = [
  {id: "details", title: "Basic Info"},
  {id: "structure", title: "Structure"},
  {id: "matrix", title: "Matrix"},
];

const options = [
  {value: SHOW_COLOR_SWATCHES_PROPERTY_KEY, label: "Show Color Swatches"},
  {value: SHOW_FULL_TEXT_PROPERTY_KEY, label: "Show Full Text"},
  {value: SHOW_MULTI_SELECT_PROPERTY_KEY, label: "Allow Multi Select"},
];

const getInitialValues = (obj: any) => {
  const structure = EditModifierGroupStructure;
  const initialValues = getFormInitialValues(structure);
  if (obj != null) {
    for (const key in structure) {
      let initialValue = obj[key];
      if (key === "properties" && initialValue != null) {
        initialValue = convertModifierGroupPropertiesToOptions(initialValue);
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProductGroupModifierGroup = () => {
  const editingModifierGroup = useSelector(
    (state: IStore) => state.adminStudioManager.editingModifierGroup,
    shallowEqual
  );
  const {
    modifierGroups,
    categories,
    products: allProducts,
    modifiers,
    details,
  } = useSelectedFactoryLine();
  const {
    updateAdminProCatFilters,
    updateModifierGroup,
    updateModifiersInBulk,
    editingEnabled,
  } = useAdminProductCatalog();
  const modifierGroup = useMemo(() => {
    return modifierGroups != null &&
      modifierGroups[editingModifierGroup?.id] != null
      ? modifierGroups[editingModifierGroup?.id]
      : editingModifierGroup;
  }, [editingModifierGroup, modifierGroups]);

  const [tab, setTab] = useState(tabs[0].id);

  const [lastModifierGroupId, setLastModifierGroupId] = useState("");
  const [matrixEdits, setMatrixEdits] = useState<any>(null);

  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(editModifierGroup(null));
  }, [dispatch]);

  const deleteModifierGroupAction = useCallback(() => {
    if (details?.id && details?.versionId && modifierGroup?.id) {
      return archiveModifierGroup(
        details?.id,
        details?.versionId,
        modifierGroup?.id
      );
    } else {
      close();
    }
  }, [close, details?.id, details?.versionId, modifierGroup?.id]);

  const undoDeleteModifierGroupAction = useCallback(() => {
    if (details?.id && details?.versionId && modifierGroup?.id) {
      return undoArchiveModifierGroup(
        details?.id,
        details?.versionId,
        modifierGroup?.id
      );
    }
  }, [details?.id, details?.versionId, modifierGroup?.id]);

  const updateMatrixFilters = () => {
    updateAdminProCatFilters({
      include: AdminProductCatalogMatrixIncludeOptions.ALL,
      filterBy: AdminProductCatalogMatrixFilterByOptions.MODIFIER_GROUP,
      filterIds: [modifierGroup?.id],
    });
  };

  const resetMatrixEdits = () => {
    setMatrixEdits(null);
  };

  const saveMatrixEdits = () => {
    const dbUpdates = getProductModifierUpdates(matrixEdits);
    updateModifiersInBulk(dbUpdates)?.then(() => {
      resetMatrixEdits();
    });
  };

  const changeTab = (id: string) => {
    if (id === "matrix") {
      updateMatrixFilters();
    }
    setTab(id);
  };

  const initialValues = getInitialValues(modifierGroup);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      const modifierGroupToSave = Object.assign({}, values);
      if (matrixEdits) {
        saveMatrixEdits();
      }
      modifierGroupToSave.id = modifierGroup.id;
      // modifierGroupToSave.parentModifiers = convertFromTagsToObjects(
      // 	values.parentModifiers
      // );
      modifierGroupToSave.properties = convertOptionsToModifierGroupProperties(
        values.properties
      );
      updateModifierGroup(modifierGroupToSave?.id, modifierGroupToSave);
    },
  });

  useEffect(() => {
    if (modifierGroup != null && lastModifierGroupId !== modifierGroup.id) {
      formik.resetForm();
      setLastModifierGroupId(modifierGroup.id);
      formik.setValues(initialValues);
      updateMatrixFilters();
    }
    // DO NOT ADD FORMIK AS A DEPENDENCY
  }, [modifierGroup]);

  if (modifierGroup == null) {
    return null;
  }

  const formStructure = getFormStructure(AdminModifierGroupStructure);

  const getSections = () => {
    const filteredCategories = Object.values(categories ?? {}).filter(
      (c: any) => {
        return c?.modifierGroups?.includes(editingModifierGroup.id);
      }
    );

    const products = getDistinctProductWithCategories(
      filteredCategories,
      Object.values(allProducts ?? {})
    );

    return filteredCategories.length > 0
      ? [getCategorySection(filteredCategories), getProductSection(products)]
      : [];
  };

  let mainContent;
  if (tab === "details") {
    mainContent = (
      <MainContent>
        <NatStyledForm onSubmit={formik.handleSubmit}>
          {formStructure.map((keyInfo) => {
            const {key, name} = keyInfo;
            // @ts-ignore
            const value = formik.values[key];
            if (key === "modifiers") {
              return null;
            } else if (key === "properties") {
              return (
                <React.Fragment key={key}>
                  <Break />
                  <InputContainer fullWidth={true} key={key}>
                    <InputTitle
                      bold={true}
                      title={name}
                      enabled={editingEnabled}
                    />
                    <SelectionOptionsInput
                      enabled={editingEnabled}
                      key={key}
                      options={options}
                      handleChange={(value: any) => {
                        formik.setFieldValue(key, value);
                      }}
                      value={value}
                    />
                  </InputContainer>
                </React.Fragment>
              );
            }
            // @ts-ignore
            let initial = initialValues[key];
            return (
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                disabled={!keyInfo?.alwaysEnabled && !editingEnabled}
                onChange={(value: any) => {
                  formik.setFieldValue(key, value);
                }}
                initialValue={initial}
              />
            );
          })}
        </NatStyledForm>
        <AssociationsContainer sections={getSections()} />
      </MainContent>
    );
  } else if (tab === "matrix") {
    mainContent = (
      <MatrixTab
        subTabMode={true}
        setMatrixEdits={setMatrixEdits}
        matrixEdits={matrixEdits}
      />
    );
  } else {
    const key = "modifiers";
    // @ts-ignore
    const value = formik.values[key];
    const libraryIds = Object.keys(modifiers ?? {});
    mainContent = (
      <MainContent>
        <div style={{padding: "1rem 0"}}>
          <DNDEditor
            sections={getBlankItemSection(value)}
            fieldKey={key}
            formik={formik}
            savedIds={value}
            libraryIds={libraryIds}
            type={"modifiers"}
            callback={(newSavedIds: any) => {
              formik.setFieldValue(key, newSavedIds);
            }}
          />
        </div>
      </MainContent>
    );
  }

  return (
    <MainView>
      <AdminTabHeader
        tabs={tabs}
        setTab={changeTab}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
          resetMatrixEdits();
        }}
        isUpToDate={isDeepEqual(formik.values, initialValues) && !matrixEdits}
        deleteAction={
          !isArchived(modifierGroup) && editingEnabled
            ? deleteModifierGroupAction
            : null
        }
        undoDeleteAction={
          isArchived(modifierGroup) && editingEnabled
            ? undoDeleteModifierGroupAction
            : null
        }
        saveAction={formik.handleSubmit}
        additionalActions={
          <>
            {editingEnabled && modifierGroup?.id && details?.id && (
              <DuplicateFactoryLineElementButton
                factoryLineId={details?.id}
                requestId={modifierGroup?.id}
                requestType={
                  DuplicateFactoryLineElementRequestType.MODIFIER_GROUP
                }
                resetAction={close}
              />
            )}
          </>
        }
      />
      {mainContent}
      <EditModifierOverrides
        callback={(valuesChanged: any) => {
          Object.keys(valuesChanged).forEach((key) => {
            formik.setFieldValue(key, valuesChanged[key]);
          });
        }}
      />
    </MainView>
  );
};
