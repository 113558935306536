import React from "react";
import styled from "styled-components";
import {
  DateUtils,
  IProjectNoteDetails,
  IProjectTaskStatus,
} from "@natomas-org/core";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";
import {NatLink} from "../../../_shared/generics/button";
import {
  IconPosition,
  NatJustifyContent,
  NatVerticalAlign,
  StyleOption,
} from "../../../_shared/generics/_shared";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../_shared/generics/label/NatLabel";
import {EditButtonWithIcon} from "../../../_shared/generics/documents/components";
import {HideItemButton} from "../../../admin/CustomerDatabase/CustomerManagerView/sections/_shared/HideItemButton";
import {toggleProjectNoteVisibility} from "../../../../database/firebase/project/notes";
import {useModal} from "../../../_shared/generics/modal/useModal";
import {ProjectNoteFullView} from "../ProjectNotes/ProjectNoteFullView";
import {NatModalSize} from "../../../_shared/generics/modal/NatModal";
import {
  GRAY_BORDER_LIGHT,
  VILLA_APPLE_GREEN,
  VILLA_PERIWINKLE,
} from "../../../_shared/colors";
import {NatCheckCircleHollow} from "../../../_shared/icon/icons";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";

export const ProjectTaskListItem = (props: IProjectTaskListItemProps) => {
  const {task, onEditTask, public_facing, projectId} = props;
  const taskId = task.id;
  const {timestamp, title} = task;
  const {isAdmin} = useCurrentUser();

  const dateString = DateUtils.getMonthAndDayShortHand(new Date(timestamp));
  const header = (
    <NatFlex style={{gap: "0.5rem"}}>
      <NatLabel
        label={dateString}
        type={NatLabelType.P2}
        weight={NatLabelWeight.BOLD}
      />
      <NatLabel label={title} type={NatLabelType.P2} />
    </NatFlex>
  );
  const {openModal, Modal} = useModal({
    content: <ProjectNoteFullView {...task} />,
    header: header,
    size: NatModalSize.LARGE,
    removeFixedPlacementLogic: true,
  });
  if (!taskId) {
    return null;
  }

  const isTaskComplete =
    !!task.details?.completion_timestamp ||
    task.details?.status === IProjectTaskStatus.COMPLETED;

  let completionDateText = "";
  if (isTaskComplete && task.details?.completion_timestamp) {
    completionDateText = DateUtils.getMonthAndDayShortHand(
      new Date(task.details.completion_timestamp)
    );
  }
  if (!isAdmin && task.hidden) {
    return null;
  }
  return (
    <>
      {Modal}
      <TaskAndDocumentContainer isHidden={task.hidden ?? false}>
        <NatFlex JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}>
          <NatLink
            label={task.title}
            option={StyleOption.PRIMARY_LINK}
            clickEvent={() => {
              openModal();
            }}
          />
          <NatFlex VERTICAL_ALIGN={NatVerticalAlign.CENTER}>
            <StatusContainer status={isTaskComplete}>
              <NatLabel
                label={
                  isTaskComplete ? (
                    <NatFlex style={{gap: "0.25rem"}}>
                      {completionDateText}
                      <NatCheckCircleHollow />
                    </NatFlex>
                  ) : (
                    "Due"
                  )
                }
                type={NatLabelType.P1}
                weight={NatLabelWeight.BOLD}
                icon={{
                  icon: <NatCheckCircleHollow />,
                  iconPosition: IconPosition.LEFT,
                }}
                ignoreMargin={true}
              />
            </StatusContainer>

            {!!onEditTask && !public_facing && (
              <EditButtonWithIcon
                onClick={() => {
                  onEditTask(task);
                }}
                tooltipTitle={"Edit note"}
              />
            )}
            {projectId && !public_facing && (
              <HideItemButton
                clickEvent={() => {
                  return Promise.resolve(
                    toggleProjectNoteVisibility(projectId, taskId)
                  );
                }}
                hidden={task.hidden ?? false}
                itemId={task.id}
                tooltipTitle={"task"}
              />
            )}
          </NatFlex>
        </NatFlex>
      </TaskAndDocumentContainer>
    </>
  );
};

export const TaskAndDocumentContainer = styled.div<{isHidden: boolean}>`
  border: 1px solid ${GRAY_BORDER_LIGHT};
  border-radius: 10px;
  padding: 1rem;
  opacity: ${(props) => (props?.isHidden ? 0.5 : 1)};
  margin-bottom: 1rem;
`;

const StatusContainer = styled.div<{status: boolean}>`
  background-color: ${(props) =>
    props?.status ? VILLA_APPLE_GREEN : VILLA_PERIWINKLE};
  padding: 0.5rem 1rem;
  color: white;
  text-transform: uppercase;
  border-radius: 10px;
`;

interface IProjectTaskListItemProps {
  task: IProjectNoteDetails;
  public_facing: boolean;
  onEditTask?: (note: IProjectNoteDetails) => void;
  projectId: string;
}
