import React from "react";
import {ActionButtonContainer} from "../../../../_shared/styles";
import {EditEmailSetting} from "./EditEmailSetting";
import {EditPasswordSetting} from "./EditPasswordSetting";
import {GeneralIntakeFormTrigger} from "./GeneralIntakeFormTrigger";

export const AccountLoginEditor = () => {
  return (
    <>
      <ActionButtonContainer>
        <EditEmailSetting />
        <EditPasswordSetting />
        <GeneralIntakeFormTrigger />
      </ActionButtonContainer>
    </>
  );
};
