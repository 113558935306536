import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const CustomerAccountManagementContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: flex-start;
`;

export const CustomerAccountManagementTitle = styled.h1`
  padding: 0;
  margin: 0;
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_BOLD};
`;
