import React, {Fragment} from "react";
import {ProposalSummarySection} from "./ProposalSummarySection";
import {priceTextFromMicros} from "../../../../../../../studio/_shared/helper";
import {SpanBold} from "./styles";
import {fullPriceTextFromMicros} from "@natomas-org/core";
import {SEE_BREAKDOWN_SLIDE_TEXT} from "./constants";
import {useProjectBudget} from "../../../../../../../_shared/hooks/useProjectBudget/useProjectBudget";

export const ProposalSummarySectionPhase3 = () => {
  const {projectBudget} = useProjectBudget();
  const totalAllUnitPriceMicros = projectBudget?.home_design_total?.price ?? 0;
  const totalSiteWorkMicros = projectBudget?.site_work_total?.price ?? 0;

  return (
    <ProposalSummarySection
      title={"3rd Phase - Final Construction Agreement"}
      highlighted={false}
      lineItems={[
        {
          label: (
            <Fragment>
              <span>1. Unit Budget Estimate - </span>
              <SpanBold>paid at ATB</SpanBold>
              <span> - {SEE_BREAKDOWN_SLIDE_TEXT}</span>
            </Fragment>
          ),
          value: fullPriceTextFromMicros(totalAllUnitPriceMicros, {
            roundToInteger: true,
          }),
        },
        {
          label: (
            <Fragment>
              <span>2. Site Work Budget Estimate - </span>
              <SpanBold>paid at ATB</SpanBold>
              <span> - {SEE_BREAKDOWN_SLIDE_TEXT}</span>
            </Fragment>
          ),
          value: priceTextFromMicros(totalSiteWorkMicros, "min"),
        },
      ]}
    />
  );
};
