import {MutableRefObject, useCallback, useEffect, useState} from "react";
import {throttleCallback} from "../../Utils";

export const useMicroModalResize = (ref: MutableRefObject<any>) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const smartSize: any = useCallback(
    throttleCallback((ref: any) => {
      setWidth(ref?.current?.offsetWidth ?? 0);
      setHeight(ref?.current?.offsetHeight ?? 0);
    }, 100),
    []
  );

  useEffect(() => {
    if (!!ref.current) {
      smartSize(ref);
    }
  }, [ref?.current]);

  return {width, height};
};
