import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICartItem, mergeObjects} from "@natomas-org/core";
import {getCartItemById} from "./helper";

export interface ICartSlice {
  [key: string]: ICartItem;
}

const getInitialState = (): ICartSlice => {
  return {};
};

interface UpdateCartItemProps {
  id: string;
  cartItem: ICartItem;
}

interface UpdateCartItemsProps {
  cartItems: ICartItem[];
}

const cartSlice = createSlice({
  name: "cart",
  initialState: getInitialState(),
  reducers: {
    updateCartItem: (state, action: PayloadAction<UpdateCartItemProps>) => {
      const {id, cartItem} = action.payload;
      const item = getCartItemById(state, id);
      state[id] = mergeObjects(item, cartItem);
    },
    updateCartItems: (state, action: PayloadAction<UpdateCartItemsProps>) => {
      const {cartItems} = action.payload;
      cartItems.forEach((cartItem) => {
        const {id} = cartItem;
        const item = getCartItemById(state, id);
        state[id] = mergeObjects(item, cartItem);
      });
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const {updateCartItem, updateCartItems} = cartSlice.actions;
