import React, {useCallback} from "react";
import {Button, DetailsContainer, Pre, Span} from "./styles";
import {
  IRequestHandlerProps,
  IRequestHandlerResult,
  useRequestHandler,
} from "./useRequestDebugger";

interface INexusEndpointController extends IRequestHandlerProps {
  label: string;
  methodArgs?: any[];
}

const NexusEndpointController = (props: INexusEndpointController) => {
  const {method, methodArgs, label} = props;
  const {fetch} = useRequestHandler({
    method,
  });
  const [show, setShow] = React.useState(false);
  const [showArgs, setShowArgs] = React.useState(false);
  const [result, setResult] = React.useState<
    IRequestHandlerResult | undefined
  >();

  const fetchEndpoint = useCallback(() => {
    if (!fetch) {
      return;
    }
    if (!methodArgs) {
      return fetch().then((res) => setResult(res));
    } else {
      return fetch(...methodArgs).then((res) => setResult(res));
    }
  }, [fetch, methodArgs]);

  if (result === undefined) {
    return (
      <DetailsContainer>
        <Button onClick={fetchEndpoint}>{label}</Button>
      </DetailsContainer>
    );
  }

  return (
    <DetailsContainer>
      <Span>{label}</Span>
      <Button onClick={() => setShowArgs((current) => !current)}>
        {!showArgs ? "Show Args" : "Hide Args"}
      </Button>
      <div hidden={!methodArgs || !showArgs}>
        Args:
        {methodArgs?.map((arg, index) => {
          return (
            <Pre key={`method-arg-${index}`}>
              {JSON.stringify(arg, null, 2)}
            </Pre>
          );
        })}
      </div>
      <Pre hidden={!result.error}>Error: Check console</Pre>
      <Button onClick={fetchEndpoint}>{!!result ? "Re-fetch" : "Fetch"}</Button>
      <Button onClick={() => setShow((current) => !current)}>
        {!show ? "Show Result" : "Hide Result"}
      </Button>
      <Pre hidden={!result || !show}>
        Response: {JSON.stringify(result.response, null, 2)}
      </Pre>
    </DetailsContainer>
  );
};

export default NexusEndpointController;
