import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnNumberedList,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";
import React from "react";

export const RichTextEditor = (props: IRichTextEditProps) => {
  const {value, onChange, readOnly} = props;

  function onValueChange(e: any) {
    let v = e.target.value;
    onChange?.(v);
  }

  const containerPropStyle = props.containerProps?.style ?? {};
  const containerStyle: React.CSSProperties = {
    resize: "vertical",
    overflowY: "auto",
    minHeight: "160px",
    color: "black",
    fontSize: "16px",
    fontFamily: "Arial",
    lineHeight: "1.2rem",
    fontWeight: "normal",
    ...containerPropStyle,
  };

  return (
    <EditorProvider>
      <Editor
        disabled={!!readOnly}
        value={value}
        onChange={onValueChange}
        containerProps={{
          style: containerStyle,
        }}
        onPaste={(e) => {
          e.preventDefault();
          const text = e.clipboardData.getData("text/html");
          document.execCommand("insertHTML", false, sanitize(text));
        }}
        onKeyDown={(e) => {
          if (e.key === "Tab") {
            e.preventDefault();
            document.execCommand("insertHTML", false, "&emsp;");
          }
        }}
      >
        <Toolbar hidden={!!readOnly} style={{textAlign: "center"}}>
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <BtnBulletList />
          <BtnNumberedList />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
};

const replacements = [
  {
    regex: /(^|[^b])background-color\s*:\s*[^;]*;|color\s*:\s*[^;]*;/g,
    replacement: "color: black;",
  }, // color
  {
    regex: /background-color\s*:\s*[^;]*;/g,
    replacement: "background-color: white;",
  }, // background-color
  {regex: /font-size\s*:\s*[^;]*;/g, replacement: "font-size: 16px;"}, // font-size
  {regex: /font-family\s*:\s*[^;]*;/g, replacement: "font-family: Arial;"}, // font-family
  {regex: /line-height\s*:\s*[^;]*;/g, replacement: "line-height: 1.5rem;"}, // font-family
  {regex: /margin-bottom\s*:\s*[^;]*;/g, replacement: "margin-bottom: 0rem;"}, // font-family
  {regex: /margin-top\s*:\s*[^;]*;/g, replacement: "margin-top: 0rem;"}, // font-family
];

const sanitize = (v: string) => {
  let value = v;
  replacements.forEach((replacement) => {
    value = value.replaceAll(replacement.regex, replacement.replacement);
  });
  return value;
};

interface IRichTextEditProps {
  value: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  containerProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
}
