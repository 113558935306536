import styled from "styled-components";

export const CustomerProjectViewContainer = styled.div`
  height: inherit;
  width: auto;
  overflow-y: auto;
`;

export const CustomerProjectViewContentContainer = styled.div`
  padding: 0 1rem;
`;
