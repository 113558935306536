import {AdminHelpContentRow} from "../../styles";
import React from "react";
import {StatusHasWarning} from "../../../RowStatusIcons";
import {BsFolderSymlink, BsGear, BsGearFill} from "react-icons/bs";

export const DefaultHelp = () => {
  return (
    <>
      <AdminHelpContentRow>
        {StatusHasWarning}- no assigned associations.
      </AdminHelpContentRow>
      <AdminHelpContentRow>
        <BsFolderSymlink /> - back link to the association.
      </AdminHelpContentRow>
      <AdminHelpContentRow>
        <BsGear /> - open visibility dependencies.
      </AdminHelpContentRow>
      <AdminHelpContentRow>
        <BsGearFill /> - open visibility dependencies (has at least one
        currently).
      </AdminHelpContentRow>
    </>
  );
};
