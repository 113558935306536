import LogRocket from "logrocket";

export enum TrackingCategory {
  BUTTON = "Button",
  INTERPRETED = "Interpreted",
}

export enum EventType {
  SUBMISSION = "Submission",
  CANCEL = "Cancel",
  NAVIGATION = "Navigation",
  SELECT = "Select",
  DESELECT = "Deselect",
  DOWNLOAD = "Download",
  PAGE_VISIT = "PAGE_VISIT",
  REGISTRATION_PROMPT = "Registration Prompt",
  REGISTRATION_SUBMIT = "Registration Submit",
  LOGIN = "Login",
  CLICK = "CLICK",
  JOURNEY = "JOURNEY",
}

export enum InterpretedAction {
  Journey = "Journey",
}

export enum CustomerJourneyStepName {
  STARTED = "SSD Started",
  INFO_SUBMITTED = "SSD Info Submitted",
  UNIT_SELECTION_COMPLETED = "SSD Unit Selected",
  PAYMENT_APPROACHED = "SSD Proposal Fee Approached",
  PAYMENT_SUBMITTED = "SSD Proposal Fee Submitted",
  PAYMENT_ABANDON = "SSD Proposal Fee Abandoned",
}

const getURLParameters = () => {
  const location = window.location;

  return {
    path: location.pathname,
    host: location.host,
    url: location.href,
    search: location.search,
  };
};

export const trackPageView = () => {
  return trackEvent(EventType.PAGE_VISIT);
};

export const trackButtonClick = (
  buttonTitle: string,
  buttonId?: string,
  trackingId?: string
) => {
  const buttonTrackingId = trackingId ?? buttonId ?? "";
  const button = document.createElement("button");
  button.innerText = buttonTitle;
  button.id = buttonTrackingId;
  button.type = "button";
  button.style.display = "none";
  button.className = buttonId ?? "";
  document.body.appendChild(button);

  setTimeout(() => {
    button?.click();
    document.body.removeChild(button);
  }, 300);

  return trackEvent(EventType.CLICK, {
    id: buttonId ?? trackingId ?? "",
    clickTrackingId: buttonTrackingId,
    content: buttonTitle,
    subtype: "button",
  });
};

const trackEvent = (
  eventName: EventType | string,
  payload?: any,
  retryCount: number = 0
) => {
  LogRocket.track(eventName, {...payload, ...getURLParameters()});
};

const trackJourneyStep = (step: CustomerJourneyStepName) => {
  return trackEvent(EventType.JOURNEY, {step: step});
};

export const useTracking = () => {
  return {
    trackEvent: trackEvent,
    trackJourneyStep: trackJourneyStep,
  };
};
