import React, {useCallback, useMemo} from "react";
import {ICartItem, ProjectPriceMode} from "@natomas-org/core";
import {SmartImage} from "../../../../../_shared/generics/image/SmartImage";
import {
  NatCartItemCheckbox,
  NatCartItemQuantity,
  NatCartItemQuantityController,
  NatCartItemQuantityControllerButton,
  NatCartItemRow,
  NatCartItemRowImage,
  NatCartItemRowInfo,
} from "./styles";
import {useCartItemSnapshot} from "../../../../../_shared/hooks/useCart/useCartItem/useCartItemSnapshot";
import {priceTextFromMicros} from "../../../../../studio/_shared/helper";
import {useCartItemMetadata} from "../../../../../_shared/hooks/useCart/useCartItem/useCartItemMetadata";
import useCatalogCartController from "../../../../../_shared/hooks/useCart/Controllers/useCatalogCartController";
import CartSectionItemLinks from "./components/CartSectionItemLinks";
import CartSectionItemInfoHeader from "./components/CartSectionItemInfoHeader";
import CartSectionItemInfoContent from "./components/CartSectionItemInfoContent";
import CartSectionItemNotification from "./components/CartSectionItemNotification";

export interface CartSectionItemProps {
  isMobile?: boolean;
  cartItem: ICartItem;
  select: {show: boolean; isSelected: boolean; event: () => void};
  move: {
    show: boolean;
    color: string;
    label: string;
    event: () => void;
  };
  editDesign: {
    show: boolean;
    label: string;
    event: () => void;
  };
  editUnit: {
    show: boolean;
    label: string;
    event: () => void;
  };
  previewItem: (cartItem: ICartItem) => void;
  editDisplayCodeItem: (cartItem: ICartItem) => void;
  copyUnit: {
    show: boolean;
    label: string;
    event: () => void;
  };
  setQuantity?: {
    show: boolean;
  };
}

const CartSectionItem = (props: CartSectionItemProps) => {
  const {
    isMobile,
    cartItem,
    select,
    move,
    editDesign,
    editUnit,
    copyUnit,
    setQuantity,
    previewItem,
    editDisplayCodeItem,
  } = props;
  const {product, factory, isInventory, pricing, projectPriceMode, hasDesign} =
    useCartItemSnapshot(cartItem);
  const {source, isUpToDate, isLocked} = useCartItemMetadata(cartItem);
  const {updateCartItemQuantity} = useCatalogCartController();
  const {details, quantity} = cartItem;
  const {display_code} = details ?? {};

  const changeQuantity = useCallback(
    (newQuantity: number) => {
      if (newQuantity > 0) {
        return updateCartItemQuantity(newQuantity, cartItem, source);
      }
    },
    [cartItem, source, updateCartItemQuantity]
  );

  const info = useMemo(() => {
    let designDetails: string;
    if (!hasDesign) {
      designDetails = "Not Designed";
    } else {
      const upgradePrice: string = priceTextFromMicros(
        pricing?.upgradesPriceMicros,
        "accurate"
      );
      let descriptor: string;
      switch (projectPriceMode) {
        case ProjectPriceMode.CostPlus:
          descriptor = "Cost";
          break;
        default:
          descriptor = "Price";
          break;
      }
      designDetails = `Upgrade ${descriptor}: ${upgradePrice}`;
    }
    return {
      unitPrice: priceTextFromMicros(
        pricing?.unitAndUpgradesPriceMicros,
        "accurate"
      ),
      upgradePrice: designDetails,
      label: `${display_code}`,
    };
  }, [
    display_code,
    pricing?.totalDesignPriceMicros,
    pricing?.upgradesPriceMicros,
    projectPriceMode,
    hasDesign,
  ]);

  const QuantityComponent = useMemo(() => {
    if (!setQuantity?.show || isInventory) {
      return <div>Quantity: {quantity}</div>;
    }
    return (
      <NatCartItemQuantityController>
        <NatCartItemQuantityControllerButton
          disabled={quantity <= 1}
          onClick={() => changeQuantity(quantity - 1)}
        >
          -
        </NatCartItemQuantityControllerButton>
        <NatCartItemQuantity>{quantity}</NatCartItemQuantity>
        <NatCartItemQuantityControllerButton
          disabled={false}
          onClick={() => changeQuantity(quantity + 1)}
        >
          +
        </NatCartItemQuantityControllerButton>
      </NatCartItemQuantityController>
    );
  }, [isInventory, setQuantity?.show, quantity, changeQuantity]);

  const ImageComponent = useMemo(() => {
    return (
      <NatCartItemRowImage onClick={select.show ? select.event : undefined}>
        <SmartImage
          imageId={product?.imageId ?? undefined}
          style={{
            width: "100%",
            objectFit: "cover",
            height: "100%",
          }}
        />
      </NatCartItemRowImage>
    );
  }, [select.show, select.event, product?.imageId]);

  return (
    <NatCartItemRow isMobile={isMobile}>
      <NatCartItemCheckbox
        hidden={!select.show}
        type="checkbox"
        checked={select.isSelected}
        onChange={select.event}
      />
      {!isMobile && ImageComponent}
      <NatCartItemRowInfo>
        <CartSectionItemInfoHeader
          productTitle={product?.title ?? "Loading product..."}
          factoryInternalTitle={factory?.internal_title ?? "Loading factory..."}
          unitPrice={info?.unitPrice}
        />
        <CartSectionItemInfoContent
          cartItem={cartItem}
          label={info?.label}
          upgradePrice={info?.upgradePrice}
          previewItem={previewItem}
          editDisplayCodeItem={editDisplayCodeItem}
          QuantityComponent={!isMobile && QuantityComponent}
        />
        <CartSectionItemNotification
          isUpToDate={isUpToDate}
          isLocked={isLocked}
        />
        <CartSectionItemLinks
          isMobile={isMobile}
          cartItem={cartItem}
          copyUnit={copyUnit}
          editDesign={editDesign}
          move={move}
          editUnit={editUnit}
          previewItem={previewItem}
          QuantityComponent={isMobile && QuantityComponent}
        />
      </NatCartItemRowInfo>
    </NatCartItemRow>
  );
};

export default CartSectionItem;
