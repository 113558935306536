import React, {useMemo} from "react";
import {usePage} from "../../../hooks/usePage";
import {
  ProductDetails,
  ProductDetailsWrapper,
} from "../../../../catalog/views/ProductView/styles";
import {IoBedSharp} from "react-icons/io5";
import {FaBath, FaRulerCombined} from "react-icons/fa";
import {IoMdResize} from "react-icons/io";
import {BsFillClockFill} from "react-icons/bs";
import {StrikethroughText} from "../../../../catalog/_shared/styles";

export interface ICatalogUnitDetails {
  bathroomCount: number;
  bedroomCount: number;
  dimensions: string;
  factoryTimelineTotal: number;
  squareFootage: number;
  unitTimelineTotal?: number;
}

interface CatalogUnitDetailsProps {
  details: ICatalogUnitDetails;
  showIcons?: boolean;
  showTimeline?: boolean;
}

const CatalogUnitDetails = (props: CatalogUnitDetailsProps) => {
  const {details, showIcons, showTimeline} = props;
  const {
    bedroomCount,
    bathroomCount,
    dimensions,
    factoryTimelineTotal,
    squareFootage,
    unitTimelineTotal,
  } = details ?? {};
  const {isNatMobile} = usePage();

  const timeline: JSX.Element = useMemo(() => {
    if (!factoryTimelineTotal) {
      return <></>;
    } else if (!unitTimelineTotal && factoryTimelineTotal) {
      return <>{`${factoryTimelineTotal} month timeline`}</>;
    } else {
      return (
        <>
          <StrikethroughText>{`${factoryTimelineTotal} month`}</StrikethroughText>
          {`${unitTimelineTotal} month timeline`}
        </>
      );
    }
  }, [unitTimelineTotal, factoryTimelineTotal]);

  return (
    <ProductDetailsWrapper mobile={isNatMobile}>
      <ProductDetails>
        {showIcons && <IoBedSharp />}
        {bedroomCount} bed
      </ProductDetails>
      {!showIcons && "•"}
      <ProductDetails>
        {showIcons && <FaBath />} {bathroomCount} bath
      </ProductDetails>
      {!showIcons && "•"}
      <ProductDetails>
        {showIcons && <IoMdResize />} {squareFootage} sq. ft.
      </ProductDetails>
      {!showIcons && "•"}
      <ProductDetails>
        {showIcons && <FaRulerCombined />}
        {dimensions}
      </ProductDetails>
      {showTimeline && (
        <>
          {!showIcons && "•"}
          <ProductDetails>
            {showIcons && <BsFillClockFill />}
            {timeline}
          </ProductDetails>
        </>
      )}
    </ProductDetailsWrapper>
  );
};

export default CatalogUnitDetails;
