import React, {useCallback, useEffect, useState} from "react";
import TimelinePeriodInput, {ITimelinePeriod} from "./TimelinePeriodInput";
import {NatButton} from "../../../../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../_shared/generics/_shared";
import styled from "styled-components";
import {NatPlusIcon} from "../../../../../../../../../_shared/icon/icons";

const TimelinePeriodInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
`;

const DefaultTimeline: ITimelinePeriod[] = [
  {
    stage: "Proposal",
    months: 1,
  },
  {
    stage: "Permitting",
    months: 1,
  },
  {
    stage: "Authorization to Build",
    months: 1,
  },
  {
    stage: "Manufacturing",
    months: 1,
  },
  {
    stage: "Installation",
    months: 1,
  },
];

interface TimelineInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: ITimelinePeriod[];
}

const TimelineInput = (props: TimelineInputProps) => {
  const {id, onChange, initialValue} = props;
  const [periods, setPeriods] = useState<ITimelinePeriod[]>(initialValue);

  useEffect(() => {
    setPeriods(initialValue);
  }, [initialValue]);

  const updatePeriod = useCallback(
    (index: number, value: ITimelinePeriod) => {
      let p = [...periods];
      p[index] = value;
      onChange(p);
    },
    [onChange, periods]
  );

  if (!periods || periods?.length === 0) {
    return (
      <NatButton
        label={"Custom Timeline"}
        size={NatSize.SMALL}
        option={StyleOption.ADMIN_SOLID}
        icon={{
          icon: <NatPlusIcon />,
          iconPosition: IconPosition.LEFT,
        }}
        clickEvent={() => {
          onChange(DefaultTimeline);
        }}
      />
    );
  }

  return (
    <div>
      {periods?.map((period: ITimelinePeriod, index: number) => {
        return (
          <TimelinePeriodInputContainer>
            <TimelinePeriodInput
              id={`${id}-${index}-input`}
              key={`${id}-${index}-input`}
              onChange={(value: ITimelinePeriod) => updatePeriod(index, value)}
              initialValue={period}
            />
          </TimelinePeriodInputContainer>
        );
      })}
      <TimelinePeriodInputContainer>
        {periods?.reduce((total, {months}) => total + months, 0)} months total
      </TimelinePeriodInputContainer>
    </div>
  );
};

export default TimelineInput;
