import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BudgetEntry} from "@natomas-org/core";
import {getInitialBudgetSliceState} from "./helper";

const budgetSlice = createSlice({
  name: "budget",
  initialState: getInitialBudgetSliceState(),
  reducers: {
    setBudget: (
      state,
      action: PayloadAction<
        | {
            projectId: string;
            entries: BudgetEntry[] | null;
          }
        | undefined
      >
    ) => {
      const {projectId, entries} = action.payload ?? {};
      if (projectId) {
        state[projectId] = entries ? entries : null;
      }
    },
    clearBudget: (state, action: PayloadAction<{projectId: string}>) => {
      const {projectId} = action.payload;
      if (projectId) {
        delete state[projectId];
      }
    },
  },
});

export const budgetReducer = budgetSlice.reducer;
export const {setBudget, clearBudget} = budgetSlice.actions;
