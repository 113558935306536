import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {NatTableTransposeIcon} from "../../../../../../_shared/icon/icons";
import React, {Dispatch, SetStateAction} from "react";
import {NatButton} from "../../../../../../_shared/generics/button";

export interface MatrixTableTransposeButtonProps {
  transpose: boolean;
  setTranspose: Dispatch<SetStateAction<boolean>>;
}

export const MatrixTableTransposeButton = (
  props: MatrixTableTransposeButtonProps
) => {
  const {transpose, setTranspose} = props;
  return (
    <NatButton
      tooltip={"Transpose the matrix"}
      tooltipPlacement={"bottom"}
      id={"transpose"}
      size={NatSize.SMALL}
      option={StyleOption.PRIMARY_ALT}
      icon={{
        icon: <NatTableTransposeIcon />,
        iconPosition: IconPosition.ONLY,
      }}
      label={"transpose"}
      type={"button"}
      clickEvent={() => setTranspose(!transpose)}
    />
  );
};
