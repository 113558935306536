export const statusField = {
  name: "Status",
  index: 0,
  type: "dropdown",
  alwaysEnabled: true,
  options: {
    unlisted: {label: "Unlisted", value: "unlisted"},
    listed: {label: "Listed", value: "listed"},
    hidden: {label: "Hidden", value: "hidden"},
  },
};
