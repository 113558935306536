import styled from "styled-components";
import {DIVIDER_COLOR} from "../../colors";

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${DIVIDER_COLOR};
`;

export const VerticalDivider = styled.div`
  // Should be used inside of a flex container that stretches the height
  // align-items: stretch; <-- this
  width: 1px;
  background-color: ${DIVIDER_COLOR};
`;
