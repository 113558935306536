import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {ImageFinder} from "../../../ImageFinder";
import {NatMicroModal} from "../../../../micro-modal";
import {WHITE} from "../../../../../colors";

export const IMAGE_FINDER_MODAL_ID = "image-finder";
export const ImageFinderModal = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  assign: (imageId: string) => any;
  showEditDetails: boolean;
}) => {
  const {show, setShow, assign, showEditDetails} = props;
  const [event, setEvent] = useState(show ? {event: true} : undefined);

  useEffect(() => {
    if (show) {
      setEvent({event: true});
    } else {
      setEvent(undefined);
    }
  }, [show]);

  const selectImage = (imageId: string) => {
    if (imageId) {
      assign(imageId);
      handleClose();
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  return (
    <NatMicroModal
      event={event}
      id={IMAGE_FINDER_MODAL_ID}
      draggable={true}
      startInCenter={true}
      maxWidthPx={1000}
      additionalReset={() => handleClose()}
    >
      <div style={{backgroundColor: WHITE}}>
        <ImageFinder
          selectImage={selectImage}
          showEditDetails={showEditDetails}
        />
      </div>
    </NatMicroModal>
  );
};
