import {InfoSetItemOptionCondition} from "../shared/interfaces";
import {useMemo} from "react";

export const useInfoSetItemOptionCondition = (
  condition: InfoSetItemOptionCondition
) => {
  const requiredModifiers: string[] = useMemo(() => {
    if (!condition) {
      return [];
    } else return Object.keys(condition.requiredModifiers) ?? [];
  }, [condition.requiredModifiers]);

  return {
    type: condition.type,
    required: requiredModifiers,
    targets: condition.targets,
  };
};
