import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {LoadingSpinner} from "../../../../../../mapping/views/components/Common/LoadingSpinner";
import {NatomasButtonProps} from "../../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../../_shared/generics/_shared";
import {NatModal} from "../../../../../../_shared/generics/modal/NatModal";
import {duplicateFactoryLineElement} from "../../../../../../../database/firebase/api/catalog";
import {DuplicateFactoryLineElementRequestType} from "@natomas-org/core";
import {useFactoryLine} from "../../../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";

export interface CopyFactoryLineWarningModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  factoryLineId: string;
  isPublished: boolean;
}

export const CopyFactoryLineWarningModal = (
  props: CopyFactoryLineWarningModalProps
) => {
  const {show, setShow, factoryLineId, isPublished} = props;
  const {details} = useFactoryLine({factoryId: factoryLineId});
  const {internal_title, title} = details ?? {};
  const [saving, setSaving] = useState<boolean>(false);

  const copyFactoryLine = async () => {
    setSaving(true);
    duplicateFactoryLineElement(
      factoryLineId,
      DuplicateFactoryLineElementRequestType.FACTORY_LINE,
      isPublished ? "published" : "draft"
    ).then(() => {
      setSaving(false);
      setShow(false);
    });
  };

  const content = useMemo(() => {
    if (saving) {
      return (
        <div>
          <LoadingSpinner isFullScreen={false} />
        </div>
      );
    }
    return (
      <>
        <div>
          Copying the factory line, {internal_title} ({title}), will create a
          new factory line from it.
        </div>
        <div>Please note that all archived information will NOT be copied.</div>
      </>
    );
  }, [saving, title, internal_title]);

  const footerButtons: NatomasButtonProps[] | undefined = useMemo(() => {
    if (!saving) {
      return [
        {
          label: "Abort",
          type: "button",
          option: StyleOption.DESTRUCTIVE,
          clickEvent: () => setShow(false),
        },
        {
          label: "Copy " + internal_title ?? title,
          type: "button",
          option: StyleOption.PRIMARY_ALT,
          clickEvent: () => copyFactoryLine(),
        },
      ];
    }
    return undefined;
  }, [saving, internal_title, title, setShow, copyFactoryLine]);

  const header = useMemo(() => {
    let t = "Copy the ";
    t += isPublished ? "published" : "draft";
    t += " version of " + internal_title + " (" + title + ")?";
    return t;
  }, [isPublished, internal_title, title]);

  return (
    <NatModal
      show={show}
      centeredContent={true}
      content={content}
      header={<>{header}</>}
      handleClose={() => setShow(false)}
      footerButtons={footerButtons}
    />
  );
};
