import styled from "styled-components";
import {StandardElement} from "../PageElement/styles";

export type IJustifyContentValue =
  | "space-between"
  | "space-around"
  | "flex-start"
  | "center";
export type IAlignItemsValue = "flex-start" | "center";
export type IFlexDirection = "row" | "column";

export const PageElementWrapperContainer = styled.div<{
  direction: IFlexDirection;
  justifyContent?: IJustifyContentValue;
  alignItems?: IAlignItemsValue;
  reverseWrap?: boolean;
}>`
  display: flex;
  flex-wrap: ${(props) =>
    props.reverseWrap
      ? "wrap-reverse"
      : props.direction === "column"
      ? "nowrap"
      : "wrap"};
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.alignItems ?? "center"};
  justify-content: ${(props) => props.justifyContent ?? "center"};
  max-width: 100%;
  column-gap: 2%;
  row-gap: 1rem;
  ${StandardElement} {
    margin-right: 0;
  }
`;
