import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../../_shared/generics/path/styles";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../../_shared/generics/page/components/PageContainer";
import {usePage} from "../../../../_shared/hooks/usePage";
import {WHITE} from "../../../../_shared/colors";
import {PATH_Z_INDEX} from "../../../../_shared/styles";
import {INVENTORY_UNIT_PREFIX} from "../../../../_shared/constants/labels";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {useProgressBar} from "../../../../_shared/hooks/useProgressBar";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {useSelectedProduct} from "../../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {useProductCatalog} from "../../../../_shared/hooks/useProductCatalog";
import {useUrl} from "../../../../_shared/hooks/useUrl";
import {MAP_PATH} from "../../../../../paths";
import {useCatalogSessionDispatcher} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSessionDispatcher";

export const CatalogPath = (props: {
  showMainView?: boolean;
  inventory?: boolean;
  recommendedProducts?: boolean;
  allADUCallback?: () => void;
  border?: boolean;
}) => {
  const {recommendedProducts, inventory, allADUCallback} = props;
  const separator = "/";
  const {getBreadcrumbStickyOffset} = useProductCatalog();
  const {isNatMobile} = usePage();
  const {getURLPath} = useUrl();
  const {to} = useNavigation();
  const product = useSelectedProduct();
  const factoryLine = useSelectedFactoryLine();
  const {selectProductById, resetCatalogSessionSelections} =
    useCatalogSessionDispatcher();

  const {showProgressBar} = useProgressBar();
  const isPublicApp = useIsPublicApp();
  const stickyOffset = getBreadcrumbStickyOffset(showProgressBar);

  const getProductGroupsBreadcrumb = () => {
    const label = "Our homes";
    return (
      <BreadcrumbItem
        data-cy={"breadcrumb-all-homes"}
        clickable={!!factoryLine || inventory}
        onClick={() => {
          resetCatalogSessionSelections();
          if (getURLPath().includes(MAP_PATH)) {
          } else if (isPublicApp) {
            to(NavigationPaths.PUBLIC_CATALOG);
          } else {
            to(NavigationPaths.CATALOG);
          }
          if (allADUCallback) allADUCallback();
        }}
      >
        {label}
      </BreadcrumbItem>
    );
  };

  const getProductGroupBreadcrumb = () => {
    if (factoryLine?.details?.title) {
      return (
        <BreadcrumbItem
          data-cy={"breadcrumb-collection"}
          clickable={!!product}
          onClick={() => {
            if (product) {
              selectProductById(null);
            }
          }}
        >
          {factoryLine?.details?.title}
        </BreadcrumbItem>
      );
    }
    return null;
  };

  const getInventoryProgramBreadcrumb = () => {
    return (
      <BreadcrumbItem
        data-cy={"breadcrumb-inventory-collection"}
        clickable={!!product}
        onClick={() => {
          resetCatalogSessionSelections();
        }}
      >
        {INVENTORY_UNIT_PREFIX}
      </BreadcrumbItem>
    );
  };

  const getProductBreadcrumb = () => {
    if (product) {
      return (
        <BreadcrumbItem data-cy={"breadcrumb-product"}>
          {product?.title}
        </BreadcrumbItem>
      );
    }

    return null;
  };

  if (recommendedProducts) {
    return (
      <PageContainer
        border={true}
        overridePadding={!!getURLPath()?.includes(MAP_PATH)}
      >
        <Breadcrumb isMobile={isNatMobile} separator={separator}>
          {getProductGroupsBreadcrumb()}
          <BreadcrumbItem>Recommended ADUs</BreadcrumbItem>
        </Breadcrumb>
      </PageContainer>
    );
  }

  return (
    <PageContainer
      stickyOptions={{
        mode: PageContainerStickyMode.MODAL,
        offset: stickyOffset + "px",
        zIndex: PATH_Z_INDEX,
      }}
      border={props.border !== false}
      backgroundColor={WHITE}
      overridePadding={!!getURLPath()?.includes(MAP_PATH)}
    >
      <Breadcrumb isMobile={isNatMobile} separator={separator}>
        {getProductGroupsBreadcrumb()}
        {inventory
          ? getInventoryProgramBreadcrumb()
          : getProductGroupBreadcrumb()}
        {getProductBreadcrumb()}
      </Breadcrumb>
    </PageContainer>
  );
};
