import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import {isBlankString} from "@natomas-org/core";
import {SmartImage} from "../../SmartImage";
import {NatButton} from "../../../button";
import {
  IconPosition,
  NatSize,
  NatSizeType,
  StyleOption,
} from "../../../_shared";
import {
  ImageAssigmentOptionContainer,
  ImageDisplayContainer,
  ImageDropZoneContainer,
  ImageDropZoneDetails,
  ImageDropZoneEditButtonContainer,
  ImageEmptyAssignmentContainer,
  ImageRemoveWarningContainer,
  ImageRemoveWarningDescription,
  ImageRemoveWarningLabel,
  ImageSearchOptionContainer,
  ImageUploadOptionContainer,
} from "./styles";
import {BiSearchAlt, BiUpload} from "react-icons/bi";
import {MdOutlineRemove} from "react-icons/md";
import {ImageAssignmentFields} from "./ImageAssignmentFields";
import {ImageDownloadButton} from "../../ImageFinder/_shared/ImageDownloadButton";
import {useAdminProductCatalog} from "../../../../hooks/admin-only-hooks/useAdminProductCatalog";
import {IMAGE_FINDER_MODAL_ID, ImageFinderModal} from "./ImageFinderModal";
import {NAT_MICRO_MODAL_ID_PREFIX} from "../../../micro-modal";
import {NatDropdown} from "../../../popup/dropdown/NatDropdown";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
} from "../../../popup/dropdown/constants";
import {NatEditIcon, NatEditOffIcon} from "../../../../icon/icons";

export const ImageAssignmentOptions = (props: any) => {
  const {
    imageId,
    assign,
    upload,
    removeImage,
    forceAllow,
    ignoreRemoveWarning,
    hideImageDetailsEditor,
  } = props;
  const {editingEnabled} = useAdminProductCatalog();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [showImageDetails, setShowImageDetails] = useState<boolean>(false);
  const [showRemoveWarning, setShowRemoveWarning] = useState<boolean>(false);

  const editable = forceAllow || editingEnabled;

  const onDrop = useCallback(
    (acceptedFiles: any[]) => {
      upload(acceptedFiles[0]);
    },
    [upload]
  );

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  const getAssignmentOptions = () => {
    if (!isBlankString(imageId)) {
      return (
        <SmartImage
          imageId={imageId}
          style={{
            width: "100%",
            objectFit: "contain",
            height: "100%",
          }}
        />
      );
    } else if (editable) {
      return (
        <ImageAssigmentOptionContainer>
          <ImageUploadOptionContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <NatButton
              label={"Drag & Drop or Upload"}
              type={"button"}
              option={StyleOption.PRIMARY_ALT}
              icon={{icon: <BiUpload />, iconPosition: IconPosition.LEFT}}
              // clickEvent={} The handling of this click event is managed by the useDropzone hook
            />
          </ImageUploadOptionContainer>
          <ImageSearchOptionContainer>
            <NatButton
              label={"Search"}
              type={"button"}
              option={StyleOption.PRIMARY_ALT}
              clickEvent={() => {
                setShowSearch(true);
                document
                  .getElementById(
                    `${NAT_MICRO_MODAL_ID_PREFIX}${IMAGE_FINDER_MODAL_ID}`
                  )
                  ?.scrollIntoView({behavior: "smooth"});
              }}
              icon={{icon: <BiSearchAlt />, iconPosition: IconPosition.LEFT}}
            />
          </ImageSearchOptionContainer>
        </ImageAssigmentOptionContainer>
      );
    }
    return (
      <ImageEmptyAssignmentContainer>
        No image assigned
      </ImageEmptyAssignmentContainer>
    );
  };
  return (
    <>
      <ImageDropZoneContainer>
        {showRemoveWarning ? (
          <ImageRemoveWarningContainer>
            <ImageRemoveWarningLabel>Remove Image?</ImageRemoveWarningLabel>
            <ImageRemoveWarningDescription>
              Removing this image assignment will allow another to be assigned.
            </ImageRemoveWarningDescription>
            <NatButton
              label={"Remove"}
              option={StyleOption.DESTRUCTIVE}
              size={NatSize.SMALL}
              clickEvent={() => {
                removeImage();
                setShowRemoveWarning(false);
              }}
            />
            <NatButton
              label={"Cancel"}
              option={StyleOption.PRIMARY_BLACK}
              size={NatSize.SMALL}
              clickEvent={() => {
                setShowRemoveWarning(false);
              }}
            />
          </ImageRemoveWarningContainer>
        ) : (
          !!imageId && (
            <ImageDropZoneEditButtonContainer>
              {!hideImageDetailsEditor && editable && (
                <NatDropdown
                  style={{
                    ...NatDropdownStyles[DropdownStyles.SINGLE_ELEMENT][
                      NatSizeType.NORMAL
                    ],
                    ADDITIONAL_STYLE_OPTIONS: [
                      NatPopupStyleOptions.NO_ITEMS_BORDER,
                      NatPopupStyleOptions.NO_PADDING,
                      NatPopupStyleOptions.RIGHT_ALIGNED,
                      NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
                    ],
                    popupCSS: {justifyContent: "center", overflow: "unset"},
                  }}
                  handleClose={() => {
                    setShowImageDetails(false);
                  }}
                  contentItemArray={[
                    {
                      id: "image-details",
                      label: "Image Details",
                      style: {
                        overflow: "unset",
                      },
                      component: (
                        <ImageDropZoneDetails>
                          <ImageAssignmentFields imageId={imageId} />
                        </ImageDropZoneDetails>
                      ),
                    },
                  ]}
                  toggleButtonOrLabel={
                    <NatButton
                      label={"Edit"}
                      type={"button"}
                      option={StyleOption.PRIMARY_ALT}
                      size={NatSize.SMALL}
                      clickEvent={() => setShowImageDetails(!showImageDetails)}
                      icon={{
                        icon: showImageDetails ? (
                          <NatEditOffIcon />
                        ) : (
                          <NatEditIcon />
                        ),
                        iconPosition: IconPosition.ONLY,
                      }}
                    />
                  }
                />
              )}
              <ImageDownloadButton id={imageId} iconOnly={true} />
              {editable && (
                <NatButton
                  label={"Remove"}
                  type={"button"}
                  option={StyleOption.DESTRUCTIVE}
                  size={NatSize.SMALL}
                  clickEvent={() => {
                    if (!ignoreRemoveWarning) {
                      setShowRemoveWarning(true);
                    } else {
                      removeImage();
                    }
                  }}
                  icon={{
                    icon: <MdOutlineRemove />,
                    iconPosition: IconPosition.ONLY,
                  }}
                />
              )}
            </ImageDropZoneEditButtonContainer>
          )
        )}
        <ImageDisplayContainer
          style={showRemoveWarning ? {display: "none"} : undefined}
        >
          {getAssignmentOptions()}
        </ImageDisplayContainer>
      </ImageDropZoneContainer>
      <ImageFinderModal
        show={showSearch}
        setShow={setShowSearch}
        assign={assign}
        showEditDetails={false}
      />
      {/*<ImageRemoveWarningModal*/}
      {/*  show={showRemoveWarning}*/}
      {/*  setShow={setShowRemoveWarning}*/}
      {/*  removeAction={removeImage}*/}
      {/*/>*/}
    </>
  );
};
