import styled from "styled-components";
import {
  ADMIN_BUTTON_ACTIVE,
  ADMIN_BUTTON_INACTIVE,
  BUTTON_FILL_COLOR_GRAY_2,
} from "../../../../_shared/colors";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const RowContainer = styled.div<{marginBottom?: any}>`
  margin-bottom: ${(props) =>
    props.marginBottom != null ? props.marginBottom : "10px"};
  width: fit-content;
`;
export const ModifierGroupHeader = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export const ModifierPreviewStyle = styled.div`
  padding-left: 30px;
`;
export const DroppableContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Flex = styled.div`
  display: flex;
`;

export const ConditionalSection = styled.div<{isOverridePopulated: boolean}>`
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  color: ${(props) =>
    props.isOverridePopulated ? ADMIN_BUTTON_ACTIVE : ADMIN_BUTTON_INACTIVE};
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;
export const MainColumn = styled.div`
  flex-grow: 2;
  overflow-y: auto;
`;
export const LibraryColumn = styled.div`
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.7);
  overflow-y: auto;
`;

export const LibraryColumnWrapper = styled.div`
  padding: 10px 20px;
`;

export const LibrarySearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  width: 80%;
  margin: 15px auto;
  position: sticky;
  top: 15px;
  z-index: 1000;

  background-color: #ffffff;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
`;

export const LibrarySearchBar = styled.input`
  border: none;
  padding: 12px 12px;
  background-color: transparent;
  width: 90%;
`;

export const ModifierContainer = styled.div`
  max-width: 100%;
  min-width: 300px;
  display: flex;
  align-items: center;
`;

export const SectionActionButton = styled.div`
  cursor: pointer;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 20px;
`;

export const AddNewSectionContainer = styled.div`
  margin-top: 20px;
  padding-top: 30px;
  border-top: 1px dotted rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const LinkIconContainer = styled.div`
  margin-left: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  svg {
    color: ${BUTTON_FILL_COLOR_GRAY_2};
    &:hover {
      color: lightskyblue;
    }
  }
`;
