import styled from "styled-components";
import React from "react";
import {FONT_FAMILY_REGULAR} from "../../SSDDashboardView/styles/globals";
import {
  NatButton,
  NatomasButtonProps,
} from "../../../../_shared/generics/button";

export const InstantQuoteEmptySiteWorkCard = (props: {
  title: string;
  content?: string;
  button?: NatomasButtonProps;
}) => {
  const {title, content, button} = props;
  return (
    <InstantQuotePriceCardContainer noPadding={false}>
      <TotalPriceContainer>
        <TotalPriceText>{title}</TotalPriceText>
      </TotalPriceContainer>
      <TotalPriceDescription hidden={!content}>{content}</TotalPriceDescription>
      {button && <NatButton {...button} />}
    </InstantQuotePriceCardContainer>
  );
};

export const InstantQuotePriceCardContainer = styled.div<{noPadding: boolean}>`
  background-color: white;
  border-radius: 10px;
  padding: ${(props) => (props.noPadding ? 0 : 1)}rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  min-height: 516px;
  height: fit-content;
  width: 100%;
`;

export const TotalPriceText = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 24px;
`;

export const TotalPriceDescription = styled.div`
  color: #4d4d4d;
  font-size: 16px;
`;

export const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;
