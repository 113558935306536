import React, {Dispatch, SetStateAction, useMemo} from "react";
import {getCategoryTiles} from "../helper";
import {TileContainer} from "../styles";
import {SearchAllStateOptions} from "../index";
import {useImageFinder} from "../../../../../hooks/useImageFinder";

export interface SearchProductGroupCategoriesProps {
  setMode: Dispatch<SetStateAction<SearchAllStateOptions>>;
  setCategoryId: Dispatch<SetStateAction<string | undefined>>;
  productGroupId?: string;
}

export const SearchProductGroupCategories = (
  props: SearchProductGroupCategoriesProps
) => {
  const {productGroupId, setCategoryId, setMode} = props;
  const {getCategoryLabel, searchCategories} = useImageFinder();

  const productGroupCategories = useMemo(() => {
    if (!searchCategories || !productGroupId) {
      return [];
    }
    return Object.values(searchCategories);
  }, [searchCategories, productGroupId]);

  const setCategory = (id: string) => {
    setCategoryId(id);
    setMode(SearchAllStateOptions.CATEGORY);
  };

  return (
    <TileContainer>
      {getCategoryTiles(productGroupCategories, setCategory, getCategoryLabel)}
    </TileContainer>
  );
};
