import React from "react";
import styled from "styled-components";
import {BLACK, GRANITE} from "../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../portal/views/SSDDashboardView/styles/globals";

interface EstimateTableHeaderProps {
  showHeader?: boolean;
}

const EstimateTableHeader = (props: EstimateTableHeaderProps) => {
  const {showHeader} = props;
  return (
    <>
      <Header hidden={!showHeader}>Project Budget Estimate</Header>
      <Description>
        This is an estimate of your total project cost, based on our experience
        and what we currently know about your property. You can expect this
        budget to fluctuate as it is finalized. Before construction begins,
        you'll sign off on a final price once our due diligence is complete.
      </Description>
    </>
  );
};

const Header = styled.h2`
  color: ${BLACK};
  margin: 0;
  /* Heading/H2/Bold */
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Description = styled.p`
  color: ${GRANITE};
  margin: 0;

  /* Paragraph/P1/Regular */
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
`;

export default EstimateTableHeader;
