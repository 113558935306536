import React from "react";
import styled from "styled-components";
import {IProjectTeamMemberContactInfo} from "@natomas-org/core";
import NatLabel, {
  NatLabelType,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {
  NatStarFillIcon,
  NatStarHollowIcon,
} from "../../../../../../../_shared/icon/icons";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import TeamMemberPhoto from "../../../../../../../../0-app/1-container/team-member-photo";

export const TeamMemberManagerCard = (props: ITeamMemberManagerCardProps) => {
  const {
    teamMember,
    onAssignmentChange,
    newAssignedPrimaryContactEmail,
    assignedPrimaryContactEmail,
  } = props;
  const personInfo = teamMember.personInfo;
  const {contact, first_name, last_name} = personInfo;
  const email = contact?.email;
  const isNewlySelected = newAssignedPrimaryContactEmail === email;
  const isNewlyRemoved =
    !isNewlySelected && assignedPrimaryContactEmail === email;

  return (
    <Container>
      <CircleContainer>
        <TeamMemberPhoto email={email} />
      </CircleContainer>
      <NameContainer>
        <NatLabel
          label={`${first_name} ${last_name}`}
          type={NatLabelType.P2}
          style={{margin: 0}}
        />
        <NatLabel
          label={`${teamMember.role}`}
          type={NatLabelType.P3}
          style={{margin: 0}}
        />
      </NameContainer>

      <NatButton
        label={""}
        size={NatSize.LARGE}
        option={StyleOption.ICON_ONLY}
        icon={{
          icon: isNewlySelected ? <NatStarFillIcon /> : <NatStarHollowIcon />,
          iconPosition: IconPosition.ONLY,
        }}
        clickEvent={() => {
          onAssignmentChange(email);
        }}
        style={{
          color: isNewlyRemoved ? "red" : "gold",
          backgroundColor: "transparent",
        }}
        tooltip={"Assign as primary contact"}
        id={"assign_primary_contact"}
      />
    </Container>
  );
};

const CircleContainer = styled.div`
  width: 56px;
  height: 56px;
  position: relative;
  flex-shrink: 0;
`;

const NameContainer = styled.div`
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 88px;
  width: 312px;
  column-gap: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
`;

interface ITeamMemberManagerCardProps {
  teamMember: IProjectTeamMemberContactInfo;
  onAssignmentChange: (email: string) => void;
  assignedPrimaryContactEmail: string | undefined;
  newAssignedPrimaryContactEmail: string | undefined;
}
