import React from "react";
import {NatModalProps} from "./NatModal";
import {
  NatomasBottomSheet,
  NatomasModalBackdrop,
  NatomasModalContainer,
  NatomasModalContentContainer,
  NatomasModalHeader,
} from "./styles";
import {FooterButtons} from "./components";
import {MODAL_Z_INDEX} from "../../styles";
import {WHITE} from "../../colors";
import Close from "./Close";

export interface NatBottomSheetProps extends NatModalProps {
  close: () => void;
}

export const NatBottomSheet = (props: NatBottomSheetProps) => {
  const {
    show,
    handleClose,
    hideCloseIcon,
    content,
    backgroundColor,
    header,
    footer,
    footerButtons,
    size,
    centeredContent,
    customSize,
    customPadding,
    modalBackgroundColor,
    close,
  } = props;

  const width = "100vw";
  const opacity = show ? 1 : 0;
  const height = customSize?.height;

  // return <NatWrappedBottomSheet {...props} />;
  return (
    <>
      <NatomasModalBackdrop
        backgroundColor={backgroundColor}
        opacity={opacity}
        onClick={close}
        style={{zIndex: MODAL_Z_INDEX - 1}}
      />
      <NatomasBottomSheet
        animationSpeed={250}
        show={!!show}
        width={width}
        height={"auto"}
        opacity={opacity}
        backgroundColor={modalBackgroundColor ?? WHITE}
      >
        {header && (
          <NatomasModalHeader
            hidden={!handleClose || hideCloseIcon}
            isMobile={true}
          >
            {header}
          </NatomasModalHeader>
        )}
        <NatomasModalContainer customPadding={customPadding}>
          <NatomasModalContentContainer
            centered={centeredContent ?? false}
            style={height ? {minHeight: height} : {}}
            isMobile={true}
          >
            <Close
              hidden={!handleClose || hideCloseIcon || !!header}
              onClick={close}
            />
            {content}
          </NatomasModalContentContainer>
          {footer && <div>{footer}</div>}
          {footerButtons && (
            <FooterButtons footerButtons={footerButtons} columned={true} />
          )}
        </NatomasModalContainer>
      </NatomasBottomSheet>
    </>
  );
};
