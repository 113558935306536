import React from "react";
import SSCRowTemplate from "../../_shared/SSCRowTemplate";
import SSCService from "./index";

interface SSCServiceRowProps {
  primaryService: string;
  otherService?: string;
}

const SSCServiceRow = (props: SSCServiceRowProps) => {
  const {primaryService, otherService} = props;

  return (
    <SSCRowTemplate label={"Full Turnkey Service"}>
      <SSCService service={primaryService} />
      {otherService && <SSCService service={otherService} />}
    </SSCRowTemplate>
  );
};

export default SSCServiceRow;
