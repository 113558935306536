export const filterByKeys = (object: any, filteringObject: any) => {
  return Object.keys(object)
    .filter((key) => filteringObject.hasOwnProperty(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: object[key],
      };
    }, {});
};
