import React from "react";
import "react-awesome-lightbox/build/style.css";
import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../SSDDashboardView/styles/globals";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import {DocumentList} from "../../../_shared/generics/documents/components/DocumentList";
import {
  ALL_PDF_DOCUMENT_INFO,
  IPDFDocumentInfo,
  SPECIALIZED_PDF_DOCUMENT_INFO,
} from "@natomas-org/core";
import {PDFDocumentInfoPDFManager} from "../../../_shared/generics/documents";

const Header = styled.div`
  font-size: 1.5rem;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const DocumentsView = () => {
  const {id: pid} = useActiveProject();

  if (!pid) {
    return null;
  }

  return (
    <div style={{paddingTop: "1rem"}}>
      <Header>Core Documents</Header>
      {ALL_PDF_DOCUMENT_INFO.filter((documentInfo) => {
        return !SPECIALIZED_PDF_DOCUMENT_INFO.includes(documentInfo);
      }).map((DocumentInfo: IPDFDocumentInfo) => {
        return (
          <div key={DocumentInfo.document_id}>
            <PDFDocumentInfoPDFManager
              projectId={pid}
              documentTemplateInfo={DocumentInfo}
            />
          </div>
        );
      })}
      <hr />
      <div style={{display: "flex", alignItems: "center"}}>
        <Header>Generic Documents</Header>
      </div>
      <DocumentList projectId={pid} />
      <br />
    </div>
  );
};
