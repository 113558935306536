import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";

const useActiveCustomerUser = (userId?: string) => {
  return useSelector((state: IStore) => {
    if (userId) {
      return state.global.users[userId];
    } else {
      return undefined;
    }
  });
};

export default useActiveCustomerUser;
