import React from "react";
import {
  NatCartItemRowInfoHeader,
  NatCartItemRowInfoHeaderContainer,
  NatCartItemRowInfoHeaderSubtext,
  NatCartItemRowInfoRow,
} from "../styles";
import {useCurrentUser} from "../../../../../../_shared/hooks/useCurrentUser";

interface CartSectionInfoHeaderProps {
  productTitle: string;
  factoryInternalTitle: string;
  unitPrice: string;
}

const CartSectionItemInfoHeader = (props: CartSectionInfoHeaderProps) => {
  const {isAdmin} = useCurrentUser();
  const {productTitle, factoryInternalTitle, unitPrice} = props;

  return (
    <NatCartItemRowInfoRow>
      <NatCartItemRowInfoHeader>{productTitle}</NatCartItemRowInfoHeader>
      {isAdmin && (
        <NatCartItemRowInfoHeader>
          {factoryInternalTitle}
        </NatCartItemRowInfoHeader>
      )}
      <NatCartItemRowInfoHeaderContainer>
        <NatCartItemRowInfoHeader>{unitPrice}</NatCartItemRowInfoHeader>
        <NatCartItemRowInfoHeaderSubtext>
          unit price
        </NatCartItemRowInfoHeaderSubtext>
      </NatCartItemRowInfoHeaderContainer>
    </NatCartItemRowInfoRow>
  );
};

export default CartSectionItemInfoHeader;
