export enum AuthenticationFormState {
  COMPLETE = "complete",
  CONFIRM_BUSINESS = "confirm-business",
  CONFIRM_CUSTOMER = "confirm-customer",
  CREATE_CONTACT = "create-contact",
  LOGGING_OUT = "logging-out",
  LOGIN_LANDING = "login-landing",
  SELECT_CUSTOMER_TYPE = "select-customer-type",
  SIGN_UP_LANDING = "sign-up-landing",
  MARKETING_LANDING = "marketing-landing",
  RESET_PASSWORD = "reset-password",
}
