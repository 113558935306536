import {Address} from "@natomas-org/core";
import {useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {
  usePropertyData,
  usePropertyDataWithAddressId,
} from "./usePropertyData/usePropertyData";
import {useAddress} from "./useAddress";

export const useMap = () => {
  const {glMapState, leafletMapState, parcelView, infoModalViewed, addressId} =
    useSelector((state: IStore) => state.map);
  const {catalogAddress} = useAddress();
  const {catalogPropertyData} = usePropertyData();
  const propertyData = usePropertyDataWithAddressId(addressId ?? null);
  const propertyLoading = propertyData?.propertyGeometry === undefined;
  const jurisdictionLoading = propertyData?.jurisdictionData === undefined;
  const loading: boolean =
    Address.getId(catalogPropertyData?.address) !==
    Address.getId(catalogAddress ?? undefined);
  const parcelViewAvailable: boolean =
    !!propertyData &&
    !!propertyData?.propertyGeometry &&
    Address.getId(propertyData?.address) === addressId;

  return {
    isLoading: loading,
    isPropertyLoading: propertyLoading,
    isJurisdictionLoading: jurisdictionLoading,
    parcelViewAvailable: parcelViewAvailable,
    mapPropertyData: propertyData,
    infoModalViewed,
    leafletMapState,
    parcelView,
    glMapState,
    displayingAddressID: addressId,
  };
};
