import React from "react";
import {VILLA_COOL_GRAY, VILLA_SLATE} from "../../../../../_shared/colors";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";

interface INatIFrameContainerProps {
  url: string;
  title: string;
}

const NatIFrameContainer = (props: INatIFrameContainerProps) => {
  const {url, title} = props;
  // Format to make the containers fit product page
  const height = useDynamicValue({
    forFour: "18rem",
    forEight: "18rem",
    forTwelve: "24rem",
    forSixteen: "36rem",
  });
  if (!url) {
    return null;
  }

  return (
    <iframe
      title={title}
      className="frame"
      src={url}
      frameBorder={0}
      style={{
        marginTop: "10px",
        border: `1px solid ${VILLA_COOL_GRAY}`,
        borderRadius: "10px",
        width: "100%",
        minHeight: height,
        backgroundColor: VILLA_SLATE,
      }}
      allowFullScreen
      allow="xr-spatial-tracking"
    />
  );
};

export default NatIFrameContainer;
