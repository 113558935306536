import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK, DESTRUCTIVE_FILL} from "../../../../../colors";

export const AddNewImageTagModalErrorMessage = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${DESTRUCTIVE_FILL};
`;

export const SanitizedTagTitle = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${BLACK};
`;
