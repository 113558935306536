import React, {useMemo} from "react";
import {
  priceTextFromMicros,
  Product,
  ProjectPriceMode,
} from "@natomas-org/core";
import styled from "styled-components";
import {BsCheckCircleFill} from "react-icons/bs";
import {SmartImage} from "../../../../_shared/generics/image/SmartImage";
import {
  StandardFinishingDescription,
  StandardFinishingPriceText,
  TotalPriceContainer,
  TotalPriceDescription,
  TotalPriceText,
} from "./InstantQuotePriceCard";
import {getBedBathString} from "../../../../_shared/_legacy/logic/ProductUtils";
import {BLACK, PRIMARY_BUTTON_COLOR, WHITE} from "../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../SSDDashboardView/styles/globals";
import {
  JUST_UNIT_COST_DESCRIPTOR,
  JUST_UNIT_PRICE_DESCRIPTOR,
} from "../../../../catalog/_shared/components/TotalEstimateToggle/constants";
import {NatInfoIcon} from "../../../../_shared/icon/icons";
import ReactTooltip from "react-tooltip";
import {bulletPoints} from "../interface";
import {
  getLocationLabelFromKey,
  LocationStandardFinishingLabelSlug,
} from "../../../../../0-app/1-container/admin-product-instant-quote-page/EditLocation";

export const InstantQuoteHomeCard = (props: {
  product: any;
  address: any;
  button: any;
  priceMode: ProjectPriceMode;
  homeFinishing?: {slug: string; price: number};
}) => {
  const description = useMemo(() => {
    if (props.priceMode === ProjectPriceMode.CostPlus) {
      return JUST_UNIT_COST_DESCRIPTOR;
    }
    return JUST_UNIT_PRICE_DESCRIPTOR;
  }, [props.priceMode]);
  const unitMicros = useMemo(() => {
    if (props.priceMode === ProjectPriceMode.CostPlus) {
      return (
        Product.getUnitCost(props.product, {
          excludeInventoryUpgrades: true,
        }) ?? 0
      );
    }
    return (
      Product.getUnitPrice(props.product, {
        excludeInventoryUpgrades: true,
      }) ?? 0
    );
  }, [props.priceMode, props.product]);
  return (
    <InstantQuoteHomeCardContainer>
      <TotalPriceContainer style={{marginBottom: "12px"}}>
        <TotalPriceText>
          {priceTextFromMicros(unitMicros, "min")}
        </TotalPriceText>
        <TotalPriceDescription>{description}</TotalPriceDescription>
      </TotalPriceContainer>
      <TotalPriceContainer
        hidden={!props.homeFinishing || props.homeFinishing?.price === 0}
      >
        <StandardFinishingPriceText>
          {priceTextFromMicros(props.homeFinishing?.price, "integer-sign")}
        </StandardFinishingPriceText>
        <StandardFinishingDescription>
          {getLocationLabelFromKey(props.homeFinishing?.slug)}
        </StandardFinishingDescription>
        <ReactTooltip
          id={"tooltip-id-standard-finishing"}
          effect="solid"
          place={"top"}
          backgroundColor={WHITE}
          textColor={BLACK}
          multiline={true}
        >
          {bulletPoints[LocationStandardFinishingLabelSlug]}
        </ReactTooltip>
        <NatInfoIcon
          data-for={"tooltip-id-standard-finishing"}
          data-tip
          style={{
            color: "var(--semantic-link)",
          }}
        />
      </TotalPriceContainer>
      <HR />
      <div className={"vcl-container-primary-16"} style={{padding: "8px 0"}}>
        <UnitTitleDiv>{props.product?.title}</UnitTitleDiv>
        <UnitBedBath>
          {getBedBathString(props.product, "line")} |{" "}
          {props.product?.productDetails?.squareFeet ?? "-"} sq.ft.
        </UnitBedBath>
        <ADUImage>
          <SmartImage imageId={props.product?.imageId} />
        </ADUImage>
        <Feasability>
          <BsCheckCircleFill
            size={"20px"}
            color={PRIMARY_BUTTON_COLOR}
            style={{maxWidth: "20px", maxHeight: "20px"}}
          />
          <div>{props.address}</div>
        </Feasability>
      </div>
      <ButtonContainer>{props.button}</ButtonContainer>
    </InstantQuoteHomeCardContainer>
  );
};

const ButtonContainer = styled.div`
  margin-top: auto;
`;
const InstantQuoteHomeCardContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  min-height: 516px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ADUImage = styled.div`
  height: 8rem;
  width: 100%;
  position: relative;
  overflow: hidden;

  .fullDiv {
    border-radius: 1rem;
  }
`;
const UnitTitleDiv = styled.div`
  font-size: 18px;
  font-family: ${FONT_FAMILY_MEDIUM};
`;
const UnitBedBath = styled.div``;
const Feasability = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  padding-top: 1rem;
  padding-bottom: 2rem;
`;
const HR = styled.hr`
  width: 100%;
`;
