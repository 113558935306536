import styled from "styled-components";
import {GRAY, WHITE} from "../../../../../../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const StudioEditorCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;
export const StudioEditorCategoryImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 600px;
  width: calc(50% - 0.5rem);
`;

export const StudioEditorCategorySelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(50% - 0.5rem);
  max-width: 600px;
  border: 1px solid ${GRAY};
  border-radius: 1rem;
  padding: 24px;
  background-color: ${WHITE};
`;

export const StudioEditorCategoryTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  width: 100%;
`;

export const StudioEditorCategoryTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

export const StudioEditorCategoryTitle = styled.div`
  font-size: 24px;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const StudioEditorCategoryDescription = styled.div`
  font-size: 12px;
  color: ${GRAY};
`;
