import React from "react";
import {StudioModifierGroup} from "../../StudioModifierGroup";
import {StudioState} from "../../../../../_shared/slices/types/Store";
import {ProjectPriceMode} from "@natomas-org/core";

const getModifierGroup = (
  formik: any,
  modifierGroup: any,
  configurationPage: any,
  index: any,
  modifierGroups: any,
  inSection: any,
  mobileVersion: any,
  clickEvent: any,
  mode: StudioState,
  projectPriceMode: ProjectPriceMode
) => {
  return (
    <StudioModifierGroup
      formik={formik}
      modifierGroup={modifierGroup}
      key={"modifier_group" + modifierGroup.id}
      id={"modifier_group_" + modifierGroup.id}
      hideUnderline={index === modifierGroups.length - 1 || true}
      inSection={inSection}
      clickEvent={clickEvent}
      mode={mode}
      isMobile={mobileVersion}
      projectPriceMode={projectPriceMode}
    />
  );
};

export const StudioCategorySections = (props: {
  formik: any;
  isMobile: boolean;
  categoryInfo: any;
  sections: any;
  index: number;
  clickEvent: any;
  mode: StudioState;
  priceMode: ProjectPriceMode;
}) => {
  const {
    categoryInfo,
    sections,
    formik,
    isMobile,
    index,
    clickEvent,
    mode,
    priceMode,
  } = props;

  let modifierGroups = categoryInfo?.selectedModifierGroups?.filter(
    (e: any) => e.id != null
  );

  if (!!sections) {
    const shouldShowTitles = sections?.length > 1;
    return sections?.map((section: any) => {
      const sectionModifierGroups = modifierGroups.filter(
        (e: any) => section.items != null && section.items.includes(e.id)
      );
      const showTitle = !!section?.title && shouldShowTitles;
      if (sectionModifierGroups?.length === 0) {
        return <div key={section.id} />;
      } else {
        const sectionDivs = sectionModifierGroups.map((modifierGroup: any) =>
          getModifierGroup(
            formik,
            modifierGroup,
            categoryInfo,
            index,
            modifierGroups,
            showTitle,
            isMobile,
            clickEvent,
            mode,
            priceMode
          )
        );
        return (
          <div key={section.id}>
            {/*{showTitle && <SectionHeader>{section.title}</SectionHeader>}*/}
            {sectionDivs}
            <div style={{height: "40px"}} />
          </div>
        );
      }
    });
  } else if (modifierGroups) {
    if (modifierGroups?.length === 0) {
      return null;
    }
    return modifierGroups.map((modifierGroup: any) =>
      getModifierGroup(
        formik,
        modifierGroup,
        categoryInfo,
        index,
        modifierGroups,
        false,
        isMobile,
        clickEvent,
        mode,
        priceMode
      )
    );
  }
};
