import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";
import {WHITE} from "../../colors";

export const MicroModalContainer = styled.div<{
  show: boolean;
  animationTimeMs: number;
  maxWidthPx?: number;
}>`
  cursor: default;
  position: fixed;
  z-index: 99999999999;
  min-width: 150px;
  max-width: ${(props) => props.maxWidthPx ?? 700}px;
  background: #d8e1ff;
  border: 1px solid #9c9c9c;
  box-shadow: 0 1px 10px 4px rgb(143 143 143 / 50%);
  transform-origin: top left;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transform: scale(${(props) => (props.show ? 1 : 0)});
  transition: opacity ${(props) => props.animationTimeMs}ms,
    transform ${(props) => props.animationTimeMs}ms;
  //overflow: hidden;
`;

export const MicroModalContainerHeader = styled.div`
  width: auto;
  padding: 0.25rem;
  border-bottom: 1px solid #393939;
  display: flex;
  height: 2rem;
  column-gap: 0.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
  background-color: #393939;
`;

export const DraggingPanel = styled.div<{isDraggable: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 2rem;
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  line-height: 0.75rem;
  flex-grow: 1;
  cursor: ${(props) => (props.isDraggable ? "move" : "default")};
  padding-right: 2rem;
  color: ${WHITE};
`;
