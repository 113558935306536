import React from "react";
import SSCProductsComponent, {
  SSCProductsComponentProps,
} from "./SSCProductsComponent";
import SSCRowTemplate from "../../_shared/SSCRowTemplate";

export interface SSCProductsRowProps {
  primary: SSCProductsComponentProps;
  other: SSCProductsComponentProps;
}

const SSCProductsRow = (props: SSCProductsRowProps) => {
  const {primary, other} = props;

  return (
    <SSCRowTemplate label={"Floor Plans"}>
      <SSCProductsComponent {...primary} />
      {other && <SSCProductsComponent {...other} />}
    </SSCRowTemplate>
  );
};

export default SSCProductsRow;
