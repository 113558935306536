import {IPublicProjectInfo} from "@natomas-org/core";

export const getInitialValues = (
  projectDetails: IPublicProjectInfo | undefined,
  defaultValues: any
) => {
  let values = defaultValues;
  if (projectDetails != null) {
    values["images"] = projectDetails?.imageIds ?? [];
  } else {
    values["images"] = [];
  }
  return values;
};
