import React, {useMemo} from "react";
import {Container, Input as StyledInput, Label} from "../style";
import {InputProps} from "../../interface";
import {validatePassword} from "./validate";
import Validation from "./Validation";

const CreatePassword = (props: InputProps) => {
  const {id, label, error, setError, additionalContent, ...input} = props;

  const conditions = useMemo(() => {
    if (!input.value) {
      return [];
    }
    const feedback = validatePassword(input.value as string);
    if (feedback.length > 0) {
      setError?.("Password is invalid");
    } else if (error) {
      setError?.(undefined);
    }
    return feedback;
  }, [input.value, setError]);

  return (
    <Container key={`${id}-container`}>
      <Label htmlFor={id} id={`${id}-label`} hidden={!label}>
        {label}
      </Label>
      <StyledInput {...input} id={id} key={id} name={id} data-private />
      {additionalContent}
      <Validation conditions={conditions} />
    </Container>
  );
};

export default CreatePassword;
