import React from "react";
import {WarningTagContainer, WarningText} from "./styles";

export const WarningTag = (props: {
  show: boolean;
  title: string | JSX.Element;
}) => {
  const {title, show} = props;
  if (show && title) {
    return (
      <WarningTagContainer>
        <WarningText>{title}</WarningText>
      </WarningTagContainer>
    );
  } else return <></>;
};
