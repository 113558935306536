import React, {useMemo} from "react";
import {BottomBarContentContainer, BottomBarContentWrapper} from "../styles";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {setRegistrationModalState} from "../../../../_shared/slices/SessionSlice/SessionSlice";
import {useSelectedProduct} from "../../../../_shared/hooks/useProductCatalog/useSelectedProduct";
import {useDispatch} from "react-redux";

type CTAContent = {
  ctaLeftText: string | null;
  ctaTitleText: string | undefined;
  trackingId: string | undefined;
};

const useCTAContent = (
  loggedInUser: any,
  selectedProduct: any,
  parcelView: boolean,
  shouldShowProjectMap: boolean
): CTAContent => {
  const ctaLeftText = useMemo(() => {
    if (!selectedProduct?.id) {
      return loggedInUser
        ? "Have questions about your future Villa home?"
        : "Ready to unlock an instant quote? Sign up!";
    }
    return null;
  }, [loggedInUser, selectedProduct]);

  const [ctaTitleText, trackingId] = useMemo(() => {
    if (parcelView) {
      return [loggedInUser ? "Contact Us" : "Sign Up", undefined];
    }
    return shouldShowProjectMap
      ? ["List view", undefined]
      : ["Map view", undefined];
  }, [loggedInUser, parcelView, shouldShowProjectMap]);

  return {ctaLeftText, ctaTitleText, trackingId};
};

const getBarStyle = (
  isNatMobile: boolean,
  shouldShowProjectMap: boolean,
  parcelView: boolean
): React.CSSProperties => {
  if (isNatMobile && !parcelView) {
    return {
      position: shouldShowProjectMap ? "absolute" : "fixed",
      backgroundColor: "transparent",
      background: "transparent",
    };
  }
  return {};
};

interface MapBottomBarContentProps {
  shouldLowerZIndex: boolean;
  show: () => void;
  parcelView: boolean;
  shouldShowProjectMap: boolean;
  setShouldShowProjectMap: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MapBottomBarContent: React.FC<MapBottomBarContentProps> = ({
  shouldLowerZIndex,
  show,
  parcelView,
  shouldShowProjectMap,
  setShouldShowProjectMap,
}) => {
  const selectedProduct = useSelectedProduct();
  const dispatch = useDispatch();
  const {loggedInUser} = useCurrentUser();
  const {isNatMobile} = usePage();
  const {ctaLeftText, ctaTitleText, trackingId} = useCTAContent(
    loggedInUser,
    selectedProduct,
    parcelView,
    shouldShowProjectMap
  );
  const barStyle = getBarStyle(isNatMobile, shouldShowProjectMap, parcelView);
  if (selectedProduct) {
    return null;
  }

  const handleCTAClick = () => {
    if (parcelView) {
      if (!loggedInUser) {
        dispatch(
          setRegistrationModalState({
            visible: true,
            signUp: true,
            redirectType: undefined,
          })
        );
      } else {
        window.location.href = "https://villahomes.com/contact-us";
      }
    } else {
      setShouldShowProjectMap(!shouldShowProjectMap);
    }
  };

  return (
    <BottomBarContentWrapper
      shouldLowerZIndex={shouldLowerZIndex}
      isMobile={isNatMobile}
      hidden={!isNatMobile && !parcelView}
      style={barStyle}
    >
      <BottomBarContentContainer
        displayingUnit={false}
        isMobile={isNatMobile}
        style={!ctaLeftText ? {justifyContent: "space-evenly"} : undefined}
      >
        <div
          style={{fontSize: "1rem", flexGrow: 1}}
          hidden={isNatMobile || !ctaLeftText}
        >
          {ctaLeftText}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: ctaLeftText ? "flex-end" : "space-evenly",
            minWidth: "100px",
            gap: "1rem",
            width: ctaLeftText || isNatMobile ? "auto" : "100%",
          }}
        >
          <NatButton
            hidden
            label="Live chat"
            style={{flexGrow: 1, marginRight: "0.75rem"}}
            option={StyleOption.SECONDARY}
            trackingId="build-main-start"
            clickEvent={show}
          />
          <NatButton
            hidden={!ctaTitleText}
            disabled={false}
            option={parcelView ? StyleOption.PRIMARY : StyleOption.PRIMARY_ALT}
            label={ctaTitleText ?? ""}
            style={{flexGrow: 1, minWidth: "fit-content"}}
            size={parcelView ? NatSize.SMALL : NatSize.NORMAL}
            trackingId={trackingId}
            clickEvent={handleCTAClick}
          />
        </div>
      </BottomBarContentContainer>
    </BottomBarContentWrapper>
  );
};
