import React from "react";
import Text from "../../2-component/Text";

const LoggedInAs = (props: {email?: string; logOut: () => void}) => {
  if (!props.email) {
    return null;
  }
  return (
    <Text
      text={`${props.email} Log out`}
      style={{textAlign: "center", marginBottom: "16px"}}
      links={[
        {
          replacementKey: "Log out",
          onClick: props.logOut,
        },
      ]}
    />
  );
};

export default LoggedInAs;
