import React, {useEffect, useState} from "react";
import {IPublicProjectInfo} from "@natomas-org/core";
import {NatStyledForm} from "../../../_shared/styles/AdminStyles";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {useFormik} from "formik";
import {CompletedProjectsImageStructure} from "./completedProjectsImageStructure";
import {getInitialValues} from "./helpers";
import {AdminInput} from "../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {
  CompletedProjectsImageManagerButtonContainer,
  CompletedProjectsImageManagerContainer,
} from "./styles";
import {NatImage} from "../../../../_shared/generics/image/NatImage";

export const CompletedProjectsImageManagerModalContent = (props: {
  readonly: boolean;
  selectedProject: IPublicProjectInfo | undefined;
  setImageIds: (imageIds: string[]) => void;
  handleClose: () => void;
}) => {
  const {selectedProject, setImageIds, handleClose, readonly} = props;
  const selectedProjectStructure = getFormStructure(
    CompletedProjectsImageStructure
  );
  const [initialValues, setInitialValues] = useState(
    getInitialValues(
      selectedProject,
      getFormInitialValues(CompletedProjectsImageStructure)
    )
  );
  useEffect(() => {
    const newInitialValues = getInitialValues(
      selectedProject,
      getFormInitialValues(CompletedProjectsImageStructure)
    );
    setInitialValues(newInitialValues);
    formik.setValues(newInitialValues);
  }, [selectedProject]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      if (!!values?.images) {
        setImageIds(values?.images);
      }
      handleClose();
    },
  });

  if (!selectedProject) {
    return null;
  }
  let content: JSX.Element | null;
  if (readonly) {
    content = (
      <>
        {selectedProject.imageIds?.map((imageId, idx) => {
          return (
            <NatImage
              size={{width: "100%", height: "auto"}}
              style={{aspectRatio: "16/9"}}
              imageId={imageId}
              key={idx}
            />
          );
        })}
      </>
    );
  } else {
    content = (
      <>
        {selectedProjectStructure.map((keyInfo: any, idx) => {
          const {key} = keyInfo;
          let value = formik.values[key];
          let initial = initialValues[key];
          return (
            <div style={{zIndex: 5}} key={key}>
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                initialValue={initial}
                onChange={(value: any) => {
                  formik.setFieldValue(key, value);
                }}
              />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <CompletedProjectsImageManagerContainer>
      <div>{selectedProject?.title}</div>
      <NatStyledForm onSubmit={formik.handleSubmit}>
        {readonly}
        {content}

        <CompletedProjectsImageManagerButtonContainer>
          <NatButton
            label={readonly ? "Dismiss" : "Cancel"}
            type={"button"}
            clickEvent={() => {
              formik.setValues(
                getInitialValues(
                  selectedProject,
                  getFormInitialValues(CompletedProjectsImageStructure)
                )
              );
              handleClose();
            }}
            option={StyleOption.SECONDARY}
          />
          <NatButton
            hidden={readonly}
            label={"Save"}
            type={"button"}
            clickEvent={formik.handleSubmit}
            option={StyleOption.PRIMARY_ALT}
          />
        </CompletedProjectsImageManagerButtonContainer>
      </NatStyledForm>
    </CompletedProjectsImageManagerContainer>
  );
};
