import {
  MAP_POLYGON_ACTIVE_FILL,
  MAP_POLYGON_ACTIVE_OUTLINE,
  MAP_POLYGON_FILL,
  MAP_POLYGON_HOVER_FILL,
  MAP_POLYGON_HOVER_OUTLINE,
  MAP_POLYGON_OUTLINE,
  MAP_POLYGON_POINT_FILL,
  MAP_POLYGON_POINT_HOVER_FILL,
  MAP_POLYGON_POINT_OUTLINE,
} from "../../colors";

export const getAllLayers = () => {
  return [
    getPolygonFillLayer(),
    getPolygonOutlineLayer(),
    getPolygonCoordinatesLayer(),
    getSavedPolygonFillLayer(),
    getSavedPolygonOutlineLayer(),
    getPolygonLabelLayer(),
    getOtherPolygonFillLayer(),
    getOtherPolygonOutlineLayer(),
  ];
};

export const getPolygonCoordinatesLayer = () => {
  return {
    id: "bound-points",
    type: "circle",
    layout: {},
    source: "polygon-coordinates",
    paint: {
      "circle-radius": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        18,
        6,
      ],
      "circle-color": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        MAP_POLYGON_POINT_HOVER_FILL,
        MAP_POLYGON_POINT_FILL,
      ],
      "circle-stroke-color": MAP_POLYGON_POINT_OUTLINE,
      "circle-stroke-width": 2,
    },
    filter: ["==", "$type", "Point"],
  };
};

export const getPolygonFillLayer = () => {
  return {
    id: "polygon-fill",
    type: "fill",
    source: "polygon-coordinates", // reference the data source
    layout: {},
    paint: {
      "fill-color": MAP_POLYGON_ACTIVE_FILL,
      "fill-opacity": 0.4,
    },
    filter: ["==", "state", "creating"],
  };
};

export const getPolygonOutlineLayer = () => {
  return {
    id: "outline",
    type: "line",
    source: "polygon-coordinates",
    layout: {},
    paint: {
      "line-color": MAP_POLYGON_ACTIVE_OUTLINE,
      "line-width": 2,
    },
    filter: ["==", "state", "creating"],
  };
};
export const getSavedPolygonFillLayer = () => {
  return {
    id: "multi-polygon-fill",
    type: "fill",
    source: "polygon-coordinates", // reference the data source
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        MAP_POLYGON_HOVER_FILL,
        MAP_POLYGON_FILL,
      ],
      "fill-opacity": 0.75,
    },
    filter: ["==", "state", "saved"],
  };
};

export const getSavedPolygonOutlineLayer = () => {
  return {
    id: "multi-polygon-outline",
    type: "line",
    source: "polygon-coordinates",
    layout: {},
    paint: {
      "line-color": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        MAP_POLYGON_HOVER_OUTLINE,
        MAP_POLYGON_OUTLINE,
      ],
      "line-width": 2,
    },
    filter: ["==", "state", "saved"],
  };
};

export const getPolygonLabelLayer = () => {
  return {
    id: "multi-polygon-labels",
    type: "symbol",
    source: "polygon-coordinates",
    layout: {
      "text-size": 12,
      "text-field": ["get", "description"],
      "text-anchor": "center",
      "text-radial-offset": 0.5,
      "text-justify": "center",
    },
    paint: {
      "text-color": "#fff",
    },
    filter: ["all", ["==", "state", "creating"], ["==", "$type", "Polygon"]],
  };
};

export const getOtherPolygonFillLayer = () => {
  return {
    id: "other-polygon-fill",
    type: "fill",
    source: "polygon-coordinates", // reference the data source
    layout: {},
    paint: {
      "fill-color": "#1c1c1c",
      "fill-opacity": 0.1,
    },
    filter: ["==", "state", "other"],
  };
};

export const getOtherPolygonOutlineLayer = () => {
  return {
    id: "other-polygon-outline",
    type: "line",
    source: "polygon-coordinates",
    layout: {},
    paint: {
      "line-color": "#1c1c1c",
      "line-width": 1,
      "line-opacity": 0.25,
    },
    filter: ["==", "state", "other"],
  };
};
