import {getMatrixModeTitle} from "../../tabs/MatrixTab/_shared/helper";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import React, {useEffect, useMemo} from "react";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {AdminProductCatalogMatrixModes} from "../../../../_shared/slices/types/Store";
import {NatPopupItemText} from "../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {NatPopupType} from "../../../../_shared/generics/popup/dropdown/constants";

const getAllMatrixModeOptions = () => {
  return Object.keys(
    AdminProductCatalogMatrixModes
  ) as (keyof typeof AdminProductCatalogMatrixModes)[];
};

const getBaseMatrixModeOptions = () => {
  return [
    "PRICE_SET",
    "DESCRIPTIONS",
  ] as (keyof typeof AdminProductCatalogMatrixModes)[];
};

export const MatrixModeDropdown = (props: {
  hasEdits: boolean;
  subTabMode?: boolean;
}) => {
  const {matrixMode, updateAdminProCatMatrixMode} = useAdminProductCatalog();

  const options = useMemo(() => {
    return props?.subTabMode
      ? getBaseMatrixModeOptions()
      : getAllMatrixModeOptions();
  }, [props?.subTabMode]);

  const isAvailable = useMemo(() => {
    return options.find(
      (value) => matrixMode === AdminProductCatalogMatrixModes[value]
    );
  }, [options, matrixMode]);

  useEffect(() => {
    if (!isAvailable) {
      updateAdminProCatMatrixMode(AdminProductCatalogMatrixModes[options[0]]);
    }
  }, [isAvailable, options, updateAdminProCatMatrixMode]);

  return (
    <NatDropdown
      disabled={props?.hasEdits}
      style={{
        TYPE: NatPopupType.HIDE,
        DROPDOWN_BUTTON_STYLE: StyleOption.PRIMARY_ALT,
        popupCSS: {fontFamily: FONT_FAMILY_BOLD, margin: "0 0", padding: "0 0"},
        dropdownButtonCSS: {height: "unset"},
      }}
      toggleButtonOrLabel={
        getMatrixModeTitle(matrixMode) +
        (props?.hasEdits ? " (Changes In Progress)" : "")
      }
      contentItemArray={options?.map(
        (
          key: keyof typeof AdminProductCatalogMatrixModes
        ): NatPopupItemText => {
          return {
            label:
              getMatrixModeTitle(AdminProductCatalogMatrixModes[key]) ??
              "Unknown",
            id: AdminProductCatalogMatrixModes[key],
            callback: (option: NatPopupItemText) => {
              updateAdminProCatMatrixMode(AdminProductCatalogMatrixModes[key]);
            },
          };
        }
      )}
      trackingId={"admin-matrix-table-views"}
    />
  );
};
