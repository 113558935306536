import styled from "styled-components";
import {GRAY_BORDER_LIGHT} from "../../../../_shared/colors";

export const FPMeetingNotesContainer = styled.div`
  border-radius: 0.5rem;
  position: relative;
`;

export const PortalDocumentContainer = styled.div`
  margin-bottom: 1rem;
`;

export const FPMeetingNotesListContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  padding: var(--spacing-32, 0.75rem) var(--spacing-24, 1.5rem);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-32, 2rem);
  align-self: stretch;
  border-radius: var(--radius-medium, 0.625rem);
  border: 1px solid var(--color-greyscale-black-30, ${GRAY_BORDER_LIGHT});
  padding-bottom: 2.5rem;
`;

export const PortalTabSectionButtonAndContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 0.75rem;
  width: 100%;
  column-gap: 0.75rem;
  flex-wrap: wrap;
  padding-bottom: 1.5rem;
  justify-content: space-between;
`;
