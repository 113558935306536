import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";

export const getTooltip = (
  option: StyleOption,
  size: NatSize,
  extension: string
): {id: string; label: string} => {
  const o = StyleOption?.[option].toString();
  // @ts-ignore
  const sKey = Object.values(NatSize).indexOf(size as unknown as NatSize);
  const s = Object.keys(NatSize)[sKey];
  return {
    id: o + "_" + s + "_" + extension,
    label: o + " / " + s + " / " + extension,
  };
};
