import React, {useMemo} from "react";
import {useCustomerStatus} from "../../../../../_shared/hooks/useCustomerStatus";
import {PageContainer} from "../../../../../_shared/generics/page/components/PageContainer";
import {ScrollableCardList} from "./memoizedComponents/ScrollableCardList";
import {RecommendedContent} from "./memoizedComponents/RecommendedContent";
import {useCatalogBannerProducts} from "../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useCatalogBannerProducts";
import {useCurrentUser} from "../../../../../_shared/hooks/useCurrentUser";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {useCatalogSession} from "../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {CollectionItemWrapper} from "./styles";
import {ProductLineCard} from "../../../../_shared/components/ProductCard/useCases/ProductLineCard";
import {InventoryCard} from "../../../../_shared/components/ProductCard/useCases/InventoryCard";
import {WARM_NEUTRAL} from "../../../../../_shared/colors";

export const dummyElement = {
  id: `default`,
  renderItem: <div />,
};

export const OurSeries = React.memo(() => {
  const {general} = useCatalogBannerProducts();
  const {adminCatalogMode, eligibility} = useCatalogSession();

  const {isCustomerPreDeposit} = useCustomerStatus();
  const {isAdmin} = useCurrentUser();

  const productLineCardsPerRow = useDynamicValue({
    forFour: 1,
    forEight: 2,
    forTwelve: 3,
    forSixteen: 4,
  });
  const seriesCardsToDisplay = useMemo(() => {
    const seriesCardStyling: React.CSSProperties = {
      width: "100%",
      padding: "0 0",
    };
    const mappedLines = general?.factoryLines?.map((factoryLine) => {
      return (
        <ProductLineCard
          key={`product-card`}
          productLine={factoryLine}
          style={seriesCardStyling}
        />
      );
    });
    mappedLines.push(<InventoryCard style={seriesCardStyling} />);
    return mappedLines;
  }, [general?.factoryLines]);

  const seriesCards = useMemo(() => {
    let toReturn = [];
    seriesCardsToDisplay.forEach((element, idx) => {
      toReturn.push({
        id: `${idx}`,
        renderItem: (
          <CollectionItemWrapper
            className={`item`}
            productLineCardsPerRow={productLineCardsPerRow}
          >
            {element}
          </CollectionItemWrapper>
        ),
      });
    });
    if (toReturn.length < productLineCardsPerRow) {
      for (
        let i = 0;
        toReturn.length < productLineCardsPerRow || toReturn.length === 1;
        i += 1
      ) {
        toReturn.push(dummyElement);
      }
    }
    if (toReturn.length < 1) {
      toReturn.push(dummyElement);
    }
    return toReturn;
  }, [productLineCardsPerRow, seriesCardsToDisplay]);
  const recommended = useMemo(() => {
    return (
      <RecommendedContent
        productLineCardsPerRow={productLineCardsPerRow}
        isCustomerPreDeposit={isCustomerPreDeposit}
        isAdmin={isAdmin}
        adminCatalogMode={adminCatalogMode}
        eligibility={eligibility}
      />
    );
  }, [
    adminCatalogMode,
    eligibility,
    isAdmin,
    isCustomerPreDeposit,
    productLineCardsPerRow,
  ]);

  const mainContent = useMemo(() => {
    return (
      <ScrollableCardList
        title={null}
        items={seriesCards}
        productLineCardsPerRow={productLineCardsPerRow}
      />
    );
  }, [productLineCardsPerRow, recommended, seriesCards]);
  if (general?.factoryLines?.length < 1) {
    return null;
  }
  return (
    <PageContainer backgroundColor={WARM_NEUTRAL} overflow={"hidden"}>
      {mainContent}
    </PageContainer>
  );
});
