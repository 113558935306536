import React, {useMemo} from "react";
import {DesignCTABlurbContainer, DesignCTAText} from "./styles";
import adu from "../../../../../../../assets/images/trimmed/450_HUD_GABLED_LAPPED.png";
import {NatButton} from "../../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../../_shared/generics/_shared";
import {PageContainer} from "../../../../../../_shared/generics/page/components/PageContainer";
import image from "../../../../../../../assets/images/photos/exteriorFeatures2.jpg";
import {PageElement} from "../../../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../../../_shared/hooks/useDynamicValue";
import {NavigationPaths} from "../../../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../../../_shared/hooks/useNavigation";
import useActiveDesign from "../../../../../../_shared/hooks/useDesign/useActiveDesign";
import {useCartItem} from "../../../../../../_shared/hooks/useCart/useCartItem/useCartItem";
import useDesign from "../../../../../../_shared/hooks/useDesign/useDesign";
import useActivitySliceDispatcher from "../../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";

export const DesignCTABlurb = (props: {cartItemId?: string}) => {
  const {cartItemId} = props;
  const cartItem = useCartItem(cartItemId);
  const {configuration: activeConfiguration} = useActiveDesign();
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const {configuration: propsDesign} = useDesign(
    cartItem?.details?.configurationId
  );
  const product = useMemo(() => {
    if (props.cartItemId && propsDesign?.product) {
      return propsDesign.product;
    }
    return activeConfiguration?.product;
  }, [activeConfiguration?.product, props.cartItemId, propsDesign.product]);
  const size = useDynamicValue(fullColumnWidths);
  const {to} = useNavigation();
  const getLabel = () => {
    if (!!product) {
      return "Customize " + product?.title;
    } else {
      return "Design your home";
    }
  };

  return (
    <PageContainer
      backgroundImage={{image: image, height: "25rem"}}
      overflow={"hidden"}
    >
      <PageElement size={size} height={"25rem"}>
        <DesignCTABlurbContainer>
          <img src={adu} alt={""} />
          <>
            <DesignCTAText size={"1.25rem"} bold={true} align={"center"}>
              Design Studio
            </DesignCTAText>
            <DesignCTAText
              hidden={product?.disableCustomization}
              size={"1.05rem"}
              align={"center"}
            >
              Configure and upgrade your dream home
            </DesignCTAText>
            <DesignCTAText
              hidden={!product?.disableCustomization}
              size={"14px"}
              align={"center"}
            >
              Customization is not available for this product, yet.
            </DesignCTAText>
            <NatButton
              hidden={product?.disableCustomization}
              label={getLabel()}
              trackingId={"your-unit-to-design-studio"}
              type={"button"}
              option={StyleOption.PRIMARY_ALT}
              clickEvent={() => {
                setActiveCartItemById(cartItemId);
                to(NavigationPaths.STUDIO, {
                  productId: product?.id,
                  productGroupId: product?.productGroupId,
                  cartItemId: cartItemId,
                });
              }}
              spinnerEnabled={false}
            />
          </>
        </DesignCTABlurbContainer>
      </PageElement>
    </PageContainer>
  );
};
