import {ADUQuiz_ClientTypes} from "@natomas-org/core";

export const PARDOT_GENERAL_INTAKE_FORM_URL =
  "https://go.villahomes.com/l/909732/2021-02-02/2j8";

export const PARDOT_MULTI_FAMILY_INTAKE_FORM_URL =
  "https://go.villahomes.com/l/909732/2021-10-25/56w5q";

export const PARDOT_NON_ACCOUNT_INTAKE_FORM_URL =
  "https://go.villahomes.com/l/909732/2023-02-13/qgj3j";

export enum INTAKE_FORM_REDIRECT_TYPE {
  CATALOG = "catalog",
  CATALOG_INVENTORY = "catalog-i",
  DASHBOARD = "dashboard",
  STUDIO = "studio",
  MAP = "map",
  INSTANT_ESTIMATE = "instant-estimate",
}
export const ADU_HERO_IMAGE_URL =
  "https://firebasestorage.googleapis.com/v0/b/natomas-staging.appspot.com/o/assets%2Fregistration%2FCOMPRESSED_ADU_HERO.jpeg?alt=media";

export enum IntakeFormType {
  D2C = "d2c",
  INVESTOR = "investor",
  REAL_ESTATE_AGENT = "real_estate_agent",
  UNKNOWN = "unknown",
}
export enum CustomerSegment {
  D2C = "D2C",
  INSTITUTIONAL_B2B = "Institutional B2B",
  SMB_B2B = "SMB B2B",
  LARGE_PROJECTS = "Large Projects",
  OTHER = "",
}

export enum ProjectType {
  SFH_ADU = "Single Family Home ADU",
  MULTI_FAMILY_ADU = "Multifamily ADU",
  NEW_MULTIFAMILY_DEVELOPMENT = "New Multifamily Development",
  NEW_SFH_PRIMARY = "New Single Family Primary Home",
  SFH_DEVELOPMENT = "Single Family Home Development",
  OTHER = "",
}

export interface IIntakeFormCategoryInfo {
  buttonText: string;
  type: IntakeFormType;
  customerSegment?: CustomerSegment;
  projectType?: ProjectType;
  clientType: ADUQuiz_ClientTypes;
}

export const INTAKE_FORM_CATEGORIES: IIntakeFormCategoryInfo[] = [
  {
    buttonText: "Homeowner",
    type: IntakeFormType.D2C,
    projectType: ProjectType.SFH_ADU,
    customerSegment: CustomerSegment.D2C,
    clientType: ADUQuiz_ClientTypes.Homeowner,
  },
  {
    buttonText: "Future homeowner",
    type: IntakeFormType.D2C,
    projectType: ProjectType.SFH_ADU,
    customerSegment: CustomerSegment.D2C,
    clientType: ADUQuiz_ClientTypes.Future_Homeowner,
  },
  {
    buttonText: "Investor looking to build multiple units",
    type: IntakeFormType.INVESTOR,
    projectType: ProjectType.OTHER,
    customerSegment: CustomerSegment.SMB_B2B,
    clientType: ADUQuiz_ClientTypes.Investor_Multiple_Units,
  },
  {
    buttonText: "Investor looking to build  a single unit",
    type: IntakeFormType.D2C,
    projectType: ProjectType.OTHER,
    customerSegment: CustomerSegment.D2C,
    clientType: ADUQuiz_ClientTypes.Investor_Single_Unit,
  },
  {
    buttonText: "Parcel owner looking to build a primary residence",
    type: IntakeFormType.D2C,
    projectType: ProjectType.NEW_SFH_PRIMARY,
    customerSegment: CustomerSegment.D2C,
    clientType: ADUQuiz_ClientTypes.Single_Family_Home,
  },
  {
    buttonText: "Real estate agent",
    type: IntakeFormType.REAL_ESTATE_AGENT,
    projectType: ProjectType.OTHER,
    customerSegment: CustomerSegment.D2C,
    clientType: ADUQuiz_ClientTypes.Real_Estate_Agent,
  },
  {
    buttonText: "Other",
    type: IntakeFormType.D2C,
    projectType: ProjectType.OTHER,
    customerSegment: CustomerSegment.D2C,
    clientType: ADUQuiz_ClientTypes.Other,
  },
];
