import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {GRAY, VILLA_BLUE, WHITE} from "../../../../../../../../_shared/colors";

export const OrderFormTableCellValueContainer = styled.div<{color?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  column-gap: 1rem;
  background-color: ${(props) => props.color};
`;

export const OrderFormTableCellLabel = styled.div<{color?: string}>`
  font-family: ${FONT_FAMILY_MEDIUM};
  max-width: fit-content;
  cursor: help;
`;

export const OrderFormTableCellTooltip = styled.div`
  background-color: ${VILLA_BLUE};
  color: ${WHITE};
  white-space: pre-wrap;
  padding: 1rem;
  border-radius: 1rem;
`;

export const OrderFormTableCellInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const OrderFormTableCellInfoItem = styled.div`
  text-align: right;
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 0.75rem;
  line-height: 0.75rem;
  border-bottom: 1px solid #bbb;
  padding: 0.25rem 0;
  &:last-child {
    border: 0;
  }
`;

export const OrderFormTableCellIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  text-align: end;
`;

export const OrderFormTableCellEditorInfoContainer = styled.div`
  font-weight: normal;
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 0.7rem;
  color: ${GRAY};
  font-style: italic;
`;

export const OrderFormTableCellCustomInputElement = styled.input`
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 1px;
  &:focus {
    margin: 0;
    outline: none !important;
    border: 2px solid rgb(38, 132, 255);
  }
  transition: all 75ms ease;
`;
