import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {MatrixTabHeader} from "./_shared/MatrixTabHeader";
import {MatrixTabTable} from "./_shared/MatrixTabTable";
import {errorLogger} from "../../../../../developerSettings";
import usePricingReview from "./_shared/MatrixTablePricingReview/usePricingReview";
import MatrixTablePricingReview from "./_shared/MatrixTablePricingReview";
import {useSelector} from "react-redux";
import {IStore} from "../../../../_shared/slices/types/Store";

export interface MatrixTabProps {
  matrixEdits?: any;
  setMatrixEdits?: Dispatch<SetStateAction<any>>;
  subTabMode?: boolean;
}

export interface MatrixPriceReviewSummary {
  errors: number;
  warnings: number;
  successes: number;
  edits: number;
}

export const MatrixTab = (props: MatrixTabProps) => {
  const {subTabMode, matrixEdits, setMatrixEdits} = props;
  // The matrix tab has the ability to locally manage edits
  const [localMatrixEdits, setLocalMatrixEdits] = useState(null);
  const edits = !!setMatrixEdits ? matrixEdits : localMatrixEdits;
  const setEdits = !!setMatrixEdits ? setMatrixEdits : setLocalMatrixEdits;
  const {matrixMode} = useSelector(
    (state: IStore) => state?.administration?.productCatalog
  );
  // Always locally managed table settings
  const [searchText, setSearchText] = useState<string | undefined>();
  const [localLoading, setLocalLoading] = useState(false);
  const [transpose, setTranspose] = useState<boolean>(false);
  const [showWarningPanel, setShowWarningPanel] = useState<boolean>(false);
  const pricingReview = usePricingReview(edits);

  const warningSummary: MatrixPriceReviewSummary = useMemo(() => {
    return {
      successes: pricingReview?.success?.length,
      errors: pricingReview?.error?.length,
      warnings: pricingReview?.warning?.length,
      edits: pricingReview?.edit?.length,
    };
  }, [pricingReview]);

  /*
   * MatrixTab
   * Generic table that allows mass field manipulation
   * The 'subTabMode' has reduced functionality:
   * - reduced table modes,
   * - reduced filter ability
   * - requires external matrix edit management
   * */

  // SubTab mode requires external edit management.
  if (subTabMode && !setMatrixEdits) {
    errorLogger(
      "MatrixTab requires external edit management while in 'subTab' mode."
    );
    return null;
  }
  return (
    <>
      <MatrixTabHeader
        matrixEdits={edits}
        setLoading={setLocalLoading}
        setMatrixEdits={setEdits}
        setSearchText={setSearchText}
        setTranspose={setTranspose}
        setShowWarningPanel={setShowWarningPanel}
        showingWarningPanel={showWarningPanel}
        warningSummary={warningSummary}
        subTabMode={subTabMode ?? false}
        transpose={transpose}
      />
      {!showWarningPanel && (
        <MatrixTabTable
          loading={localLoading}
          matrixEdits={edits}
          searchText={searchText}
          setMatrixEdits={setEdits}
          setTranspose={setTranspose}
          subTabMode={subTabMode ?? false}
          transpose={transpose}
        />
      )}
      {showWarningPanel && (
        <MatrixTablePricingReview
          review={pricingReview}
          removePriceByIds={(edit: any) => {
            setEdits(() => {
              return {
                ...edits,
                edit,
              };
            });
          }}
        />
      )}
    </>
  );
};
