import React from "react";
import "react-datasheet/lib/react-datasheet.css";
import {BUDGET_SUMMARY_SECTION_TYPE} from "@natomas-org/core";
import {
  GenericBudgetSummary,
  IGenericBudgetSummaryProps,
} from "../generic/GenericBudgetSummary";

export const Phase1BudgetSummaryDTC = (props: IGenericBudgetSummaryProps) => {
  const type = BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DTC;
  return (
    <GenericBudgetSummary
      displayType={type}
      isAdmin={props.isAdmin}
      viewOnly={props.viewOnly}
      isFullView={props.isFullView}
      {...props}
    />
  );
};
