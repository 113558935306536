import {ICartItem} from "@natomas-org/core";

export const sortCartItemsById = (cartItems: ICartItem[]) => {
  return cartItems.sort((a, b) => a.id.localeCompare(b.id));
};

export const shouldShowDivider = (
  currentIndex: number,
  numberOfItems: number
) => {
  return currentIndex !== numberOfItems && currentIndex !== 0;
};
