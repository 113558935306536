import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/performance";
import * as Api from "./api";
import * as Authentication from "./authentication";
import * as Configuration from "./configuration";
import * as Email from "./email";
import * as Sitework from "./sitework";
import * as Types from "./types";
import * as Utilities from "./utilities";
import * as Users from "./user";
import {fetchUserActions, fetchUserPermissions} from "./user";
// TODO invert this dependency
import {
  currentUser,
  initializeFirebase,
  resetUser,
} from "../../components/_shared/slices/GlobalSlice";
// TODO Invert this dependency
import {
  backendConfig,
  functionsHost,
  functionsPort,
  isDev,
} from "../../components/_shared/application";
// TODO address nav concerns
import {getStore, store} from "../../store";
// TODO invert this dependency
import {
  getFirstAndLastFromFullName,
  isAdmin,
} from "../../components/_shared/user/UserUtils";
import {resetConfiguration} from "../../components/_shared/slices/ConfigurationSlice";
import {hardResetFilters} from "../../components/_shared/slices/CatalogSlice/CatalogSessionSlice";
import {resetIntakeForm} from "../../components/intake-form/slices/IntakeFormSlice";
import {clearAllURLParams} from "../../components/_shared/hooks/useUrl";
import {setRegistrationModalState} from "../../components/_shared/slices/SessionSlice/SessionSlice";

let database: firebase.app.App;
let firestore: firebase.firestore.Firestore;
let storage: firebase.storage.Storage;
let functions: firebase.functions.Functions;
let auth: firebase.auth.Auth;
let analytics: firebase.analytics.Analytics;
let performance: firebase.performance.Performance;

const initializeFirebaseApps = (config: any) => {
  if (config != null && !firebase.apps?.length) {
    database = firebase.initializeApp(config);
  } else {
    database = firebase.app();
  }
  firebase.firestore().settings({ignoreUndefinedProperties: true});
  firestore = firebase.firestore();
  storage = firebase.storage();
  functions = firebase.functions();
  auth = firebase.auth();
  auth.onAuthStateChanged(onAuthChange);
  analytics = firebase.analytics();
  performance = firebase.performance();

  // firestore.enablePersistence({synchronizeTabs: true});

  if (functionsHost) {
    const port = functionsPort ? Number.parseInt(functionsPort) : undefined;
    // Default to port 5001
    functions.useEmulator("0.0.0.0", port ?? 5001);
  }

  getStore().dispatch(initializeFirebase());
};

if (!firebase.apps?.length) {
  if (isDev && backendConfig) {
    initializeFirebaseApps(backendConfig);
  } else {
    fetch("/__/firebase/init.json").then(async (response) => {
      initializeFirebaseApps(await response.json());
    });
  }
} else {
  initializeFirebaseApps(null);
}

export function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then()
    .catch((error) => {
      console.log(error);
      return firebase
        .auth()
        .signInWithRedirect(provider)
        .then()
        .catch((error) => {
          console.log(error);
        });
    });
}

export function logOut(options?: {preventPageClearOut: boolean}) {
  return firebase
    .auth()
    .signOut()
    .then(() => {
      store.dispatch(resetUser());
      store.dispatch(resetConfiguration());
      store.dispatch(hardResetFilters());
      store.dispatch(resetIntakeForm());
      store.dispatch(setRegistrationModalState({visible: false, signUp: true}));
      if (!options?.preventPageClearOut) {
        clearAllURLParams();
      }
    });
}

function onAuthChange(user: firebase.User | null) {
  if (user == null) {
    return getStore()?.dispatch(resetUser());
  }

  const {firstName, lastName} = getFirstAndLastFromFullName(user.displayName);
  const stateUser = {
    firstName: firstName,
    lastName: lastName,
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    uid: user.uid,
  };

  fetchUserActions(user.uid);
  if (isAdmin(user)) {
    fetchUserPermissions(user.uid);
  }
  return getStore().dispatch(currentUser(stateUser));
}

export {
  auth,
  analytics,
  database,
  firestore,
  functions,
  performance,
  storage,
  Api,
  Authentication,
  Configuration,
  Email,
  Sitework,
  Types,
  Users,
  Utilities,
};
