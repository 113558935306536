import React, {useMemo} from "react";
import {StudioModifierGroupSummary} from "./StudioModifierGroupSummary";
import {StudioCategorySummarySectionTitle} from "./styles";
import {ResultUnderline} from "../styles";
import {ProjectPriceMode} from "@natomas-org/core";

export interface StudioCategorySummaryProps {
  priceMode: ProjectPriceMode;
  category: any;
  selectedModifiers: any;
  index: number;
}

export const StudioCategorySummary = (props: StudioCategorySummaryProps) => {
  const {category, selectedModifiers, priceMode} = props;
  const {selectedModifierGroups, title} = category;

  const selections = useMemo(() => {
    const groupSelectionArray = selectedModifierGroups
      .filter((modifierGroup: any) => modifierGroup.id != null)
      .map((modifierGroup: any) => {
        const {fullModifiers} = modifierGroup;

        const groupSelections = fullModifiers?.filter((modifier: any) =>
          selectedModifiers.find((m: any) => m?.id === modifier?.id)
        );
        if (groupSelections?.length > 0) {
          return {id: modifierGroup?.id, selections: groupSelections};
        }
        return null;
      })
      ?.filter((groupSelection: any) => !!groupSelection);
    if (groupSelectionArray?.length > 0) {
      return groupSelectionArray;
    }
    return null;
  }, [selectedModifierGroups, selectedModifiers]);
  if (!selections) {
    return null;
  }

  return (
    <>
      <div style={{padding: "0 1.25rem"}}>
        <StudioCategorySummarySectionTitle>
          {title}
        </StudioCategorySummarySectionTitle>
        <div className="description-wrapper">
          {selections?.map((groupSelection: any) => {
            return (
              <div key={"summary" + groupSelection?.id}>
                <StudioModifierGroupSummary
                  priceMode={priceMode}
                  id={groupSelection?.id}
                  selectedModifiers={groupSelection?.selections}
                />
              </div>
            );
          })}
        </div>
      </div>
      <ResultUnderline />
    </>
  );
};
