import {IHCPViewProps} from "../interfaces";
import React from "react";
import {NextStepsComponent} from "./components/NextStepsComponent";
import {DTC_PORTAL_NEXT_STEPS_ID} from "../HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../_shared/hooks/useDesign/useActiveDesign";

export const HCPNextSteps = (props: IHCPViewProps) => {
  const {width} = props;
  const {site, mergedCustomerProjectInfos, isDevelopmentByVilla} =
    useActiveProject();
  const {configuration} = useActiveDesign();

  if (!configuration?.product || !site?.visible) {
    return null;
  }

  if (isDevelopmentByVilla) {
    return null;
  }

  return (
    <PortalSection
      headerTitle={"Next Steps"}
      width={width}
      sectionId={DTC_PORTAL_NEXT_STEPS_ID}
    >
      <NextStepsComponent step={2} />
    </PortalSection>
  );
};
