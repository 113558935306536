import {isProd} from "../../application";
import {useCallback, useMemo} from "react";
import ReactGA from "react-ga4";

// Reporting to Google Analytics 4 (GA4)
const useAnalytics = () => {
  const canReport: boolean = useMemo(() => isProd, [isProd]);
  const reportEvent = useCallback(
    (props: {category: string; action: string; label: string}) => {
      if (canReport) {
        ReactGA.event(props);
      } else {
        console.log("[react-ga4-local]", props);
      }
    },
    [canReport]
  );
  return {
    reportEvent,
  };
};

export default useAnalytics;
