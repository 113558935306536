import React, {useState} from "react";
import {ILightboxPhoto} from "../../generics/image/types";
import {ImageCarousel} from "../../generics/image/ImageCarousel/ImageCarousel";

export const useImageCarousel = (images: ILightboxPhoto[]) => {
  const [selectedPhotoID, setSelectedPhotoID] = useState<string | null>(null);
  const [show, setShow] = useState(false);

  const open = () => {
    setShow(true);
  };
  const close = () => {
    setShow(false);
  };
  const showSelectedImage = (imageId: string | null) => {
    setSelectedPhotoID(imageId);
    if (imageId) {
      open();
    } else {
      close();
    }
  };

  // We can't specify a starting index, but we can re-arrange the images to work around this
  const offsetImages: ILightboxPhoto[] = [];
  const wrappedImages: ILightboxPhoto[] = [];
  let foundImage = false;
  images.forEach((image, index) => {
    if (image.imageId === selectedPhotoID || foundImage) {
      offsetImages.push(image);
      foundImage = true;
    } else {
      wrappedImages.push(image);
    }
  });

  const sortedImages = [...offsetImages, ...wrappedImages];

  return {
    photoModal: (
      <ImageCarousel images={sortedImages} show={show} close={close} />
    ),
    showPhotoModal: open,
    hidePhotoModal: close,
    showSelectedImage,
  };
};
