import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK} from "../../../../colors";

export const ImageGalleryLayout = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0.5rem;
`;

export const ImageFinderHeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  height: 50px;
  font-family: ${FONT_FAMILY_BOLD};
  padding: 0.5rem;
  min-width: 1000px;
  border-bottom: 1px solid ${BLACK};
`;

export const ImageFinderHeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
`;
