import React, {useContext, useMemo} from "react";
import {SitePlan} from "../../SitePlanView/components/SitePlan";
import {useMapping} from "../../../../mapping/views/UseMapping";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {Address} from "@natomas-org/core";
import {SitePlanContainer} from "../../SitePlanView/styles";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../_shared/hooks/useDesign/useActiveDesign";
import {PROPOSAL_GENERATE_PDF} from "../../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";

export const SitePlanView = (props: IFullScreenSitePlanProps) => {
  const {admin} = props ?? {};
  const {isAdmin} = useCurrentUser();
  const {info, address, site} = useActiveProject();
  const {map, configuration} = useActiveDesign();
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const {mappingPreview} = useMapping({
    displayProps: {
      readOnly: false,
      minimal: false,
      showSideBar: false,
      showSaveButton: true,
      hideDisclaimer: false,
      fullExperience: false,
    },
    unitPlacement: map?.unitPlacement,
    saveToProjectId: configuration?.id,
    product: configuration?.product,
    address: address,
  });

  let content: any;

  const sitePlans = useMemo(() => {
    return site?.sitePlan?.images?.filter((imageId) => !!imageId) ?? [];
  }, [site?.sitePlan?.images]);

  const numberOfSitePlans = sitePlans?.length ?? 0;

  if (admin && isAdmin) {
    const sitePlanViews =
      sitePlans?.map((sitePlanImageId, index) => {
        return (
          <SitePlan
            admin={admin}
            numberOfSitePlans={numberOfSitePlans}
            key={`${sitePlanImageId}-${index}`}
            sitePlanImageId={sitePlanImageId}
            index={index + 1}
          />
        );
      }) ?? [];

    sitePlanViews.push(
      <SitePlan
        numberOfSitePlans={numberOfSitePlans}
        admin={admin}
        key={"newSitePlanImage" + numberOfSitePlans}
        sitePlanImageId={null}
        index={numberOfSitePlans + 1}
      />
    );

    return (
      <SitePlanContainer heightInherit={!isGeneratingPDF}>
        {sitePlanViews}
      </SitePlanContainer>
    );
  }

  if (numberOfSitePlans && info) {
    content = sitePlans?.map((sitePlanImageId, index) => {
      return (
        <SitePlan
          numberOfSitePlans={numberOfSitePlans}
          key={`${sitePlanImageId}-${index}`}
          sitePlanImageId={sitePlanImageId}
          index={index + 1}
        />
      );
    });
  } else if (!isGeneratingPDF) {
    content = (
      <div>
        <NatLabel
          label={address ? Address.getStreetAndCity(address) : "Your property"}
          type={NatLabelType.P1}
        />
        <div
          style={{
            height: "700px",
            width: "100%",
            borderRadius: "0.5rem",
            overflow: "hidden",
          }}
        >
          {mappingPreview}
        </div>
      </div>
    );
  }

  return <React.Fragment>{content}</React.Fragment>;
};

interface IFullScreenSitePlanProps {
  admin?: boolean;
}
