import {Utilities} from "../index";

const IMAGES_COLLECTION_KEY = "images";

const getImageCollectionRef = () => {
  return Utilities.collection(IMAGES_COLLECTION_KEY);
};

export const queryImagesByAuthor = (
  limit: number,
  uid?: string,
  startAfter?: any
) => {
  // Return null if no uid
  if (!uid) {
    return null;
  }

  // Initial query
  if (!startAfter) {
    return getImageCollectionRef()
      .where("author", "==", uid)
      .orderBy("timestamp", "desc")
      .limit(limit)
      .get();
  } else {
    return getImageCollectionRef()
      .where("author", "==", uid)
      .orderBy("timestamp", "desc")
      .startAfter(startAfter)
      .limit(limit)
      .get();
  }
};

export const queryImagesByTag = (
  limit: number,
  tags?: string[],
  startAfter?: any
) => {
  // Return null if no tags
  if (!tags || tags?.length === 0) {
    return null;
  }

  // Initial query
  if (!startAfter) {
    return getImageCollectionRef()
      .where("tags", "array-contains-any", tags)
      .limit(limit)
      .get();
  } else {
    return getImageCollectionRef()
      .where("tags", "array-contains-any", tags)
      .startAfter(startAfter)
      .limit(limit)
      .get();
  }
};
