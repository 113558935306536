import {useEffect} from "react";
import {useSelector} from "react-redux";
import {store} from "../../../store";
import {setHeight, setWidth} from "../slices/GlobalSlice";

let initialized = false;
let lastUpdate = null;
const updateWindowSize = () => {
  store.dispatch(setWidth(window.innerWidth));
  store.dispatch(setHeight(window.innerHeight));
};

const SMOOTH = true;
function handleResize() {
  if (SMOOTH) {
    if (lastUpdate) {
      window.clearTimeout(lastUpdate);
    }
    lastUpdate = setTimeout(updateWindowSize, 60);
  } else {
    updateWindowSize();
  }
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const width = useSelector((state) => state.global.windowWidth);
  const height = useSelector((state) => state.global.windowHeight);

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      window.removeEventListener("resize", handleResize);
      window.addEventListener("resize", handleResize);
      updateWindowSize();
    }
  }, []); // Empty array ensures that effect is only run on mount

  return {
    width,
    height,
  };
};
