import React from "react";
import Form from "./form";
import {InstantQuoteProductLocationSchema} from "@natomas-org/villa-nexus-client";
import {getAdminInstantQuoteService} from "../../../api/Admin/InstantQuote";

const createProductLocationInstantQuotePoint = async (
  productId: string,
  schema: InstantQuoteProductLocationSchema
): Promise<boolean> => {
  const service = await getAdminInstantQuoteService();
  let response = false;
  try {
    await service.create(productId, schema).then((res) => {
      response = true;
    });
  } catch (error) {
    window.alert("Error creating instant quote location");
  }
  return response;
};

const CreateLocation = (props: {
  productId: string;
  cancel: () => void;
  afterSubmission: () => void;
}) => {
  const {productId, cancel, afterSubmission} = props;

  return (
    <div>
      <Form
        softLocked={false}
        hidden={false}
        hide={() => cancel()}
        action={async (schema: InstantQuoteProductLocationSchema) => {
          const response = await createProductLocationInstantQuotePoint(
            productId,
            schema
          );
          if (response) {
            afterSubmission();
          }
          return response;
        }}
        initialFormData={undefined}
      />
    </div>
  );
};

export default CreateLocation;
