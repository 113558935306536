import styled, {css} from "styled-components";
import {calculatePadding, INatStyle} from "../generics/_shared";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../portal/views/SSDDashboardView/styles/globals";

export const NatStyle = css<INatStyle>`
  align-items: center;
  background-color: ${(props: INatStyle) =>
    props.initialBackgroundColor ?? "transparent"};
  border-radius: 50%;
  color: ${(props: INatStyle) => props.initialTextColor};
  svg {
    color: ${(props: INatStyle) => props.initialTextColor};
    path {
      stroke: ${(props: INatStyle) => props.initialTextColor};
    }
  }
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  font-size: ${(props: INatStyle) => props.size};
  box-shadow: ${(props: INatStyle) => props.boxShadow};
  padding: ${(props: INatStyle) =>
    calculatePadding(props.size, props.equalizePadding, props.noPadding)};
  text-decoration: ${(props: INatStyle) => props.initialTextDecoration};
  transition: all 150ms ease;
  &:active,
  &:hover,
  &:hover:focus {
    text-decoration: ${(props: INatStyle) => props.hoverTextDecoration};
    background-color: ${(props: INatStyle) => props.hoverBackgroundColor};
    color: ${(props: INatStyle) => props.hoverTextColor};
    box-shadow: ${(props: INatStyle) => props.hoverBoxShadow};
    outline: none;
    transition: all 150ms ease;
    svg {
      color: ${(props: INatStyle) => props.hoverTextColor};
      path {
        stroke: ${(props: INatStyle) => props.hoverTextColor};
      }
    }
  }

  &:active,
  &:focus {
    outline: none;
    text-decoration: ${(props: INatStyle) => props.initialTextDecoration};
    //background-color: ${(props: INatStyle) => props.initialBackgroundColor};
    color: ${(props: INatStyle) => props.initialTextColor};
    box-shadow: ${(props: INatStyle) => props.boxShadow};
    transition: all 150ms ease;
    svg {
      color: ${(props: INatStyle) => props.initialTextColor};
      path {
        stroke: ${(props: INatStyle) => props.initialTextColor};
      }
    }
  }
`;

export const NatLinkStyle = css<INatStyle>`
  align-items: center;
  background-color: ${(props: INatStyle) => props.initialBackgroundColor};
  border-radius: 50%;
  color: ${(props: INatStyle) => props.initialTextColor};
  cursor: pointer;
  font-size: ${(props: INatStyle) => props.size};
  box-shadow: ${(props: INatStyle) => props.boxShadow};
  padding: 0.5rem 0;
  text-align: center;
  text-decoration: ${(props: INatStyle) => props.initialTextDecoration};
  transition: background-color 150ms ease;
  &:hover {
    text-decoration: ${(props: INatStyle) => props.hoverTextDecoration};
    background-color: ${(props: INatStyle) => props.hoverBackgroundColor};
    color: ${(props: INatStyle) => props.hoverTextColor};
  }
`;
// Calendly Z-Index -> main/src/components/portal/views/SSDDashboardView/styles/calendlyStyles.css (10,000,000)
export const DEVELOPER_TOOLS_Z_INDEX = 9999999;
export const LOGIN_PANEL_Z_INDEX = 9000000;
export const LOADING_PANEL_Z_INDEX = 8000000;
export const MODAL_Z_INDEX = 7900000;
export const NAT_POPUP_Z_INDEX = MODAL_Z_INDEX - 1;
export const LIGHTBOX_Z_INDEX = 7000000;
export const MAPPING_Z_INDEX = 6900000;

export const DROPDOWN_Z_INDEX = 6600100;
export const DROPDOWN_PANEL_Z_INDEX = 6600000;
export const POPUP_OVERRIDE_Z_INDEX = 108;

export const SIDEBAR_NAV_Z_INDEX = 6500200;
export const SIDEBAR_CONTAINER_Z_INDEX = 6500150;
export const SIDEBAR_MOBILE_ICON_Z_INDEX = 6500100;

export const HEADER_Z_INDEX = 5000000;
export const FOOTER_Z_INDEX = 4900000;
export const POPOUT_Z_INDEX = 4800000;
export const SUB_POPOUT_Z_INDEX = 4700000;

export const IMAGE_OVERLAY_Z_INDEX = 550000;
export const IMAGE_Z_INDEX = 500000;
export const PATH_Z_INDEX = 500000;
export const BUTTON_Z_INDEX = 400000;

export const BoldText = styled.span`
  font-family: ${FONT_FAMILY_BOLD};
`;

export const MediumText = styled.span`
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const RegText = styled.span`
  font-family: ${FONT_FAMILY_REGULAR};
`;
