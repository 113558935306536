import React, {useState} from "react";
import {ImageTagsTableContainer} from "./styles";
import {useTags} from "../../../../_shared/hooks/useTags/useTags";
import {TagType} from "../../../../_shared/hooks/useTags/tags";
import {AddNewImageTagModal} from "../../../../_shared/generics/image/ImageAssignmentManager/ImageAssignmentOptions/AddNewImageTagModal";
import {NatButton} from "../../../../_shared/generics/button";
import {IconPosition, StyleOption} from "../../../../_shared/generics/_shared";
import {AiOutlinePlus} from "react-icons/ai";
import {
  TableHeaderCategory,
  useTable,
} from "../../../_shared/components/AdminTable/table";
import {Table, TableBody} from "../../../_shared/components/AdminTable/styles";
import {TableColumnHeaders} from "../../../_shared/components/AdminTable";
import {ImageTagsTableRow} from "./ImageTagsTableRow";
import {AdminTabHeader} from "../../../ProductLineView/components/Header/AdminTabHeader";
import {sortTags} from "./helper";

const imageTagsColumnHeaders = [
  {label: "Tag", column: "title", category: TableHeaderCategory.STRING},
  {
    label: "Create Date",
    column: "timestamp",
    category: TableHeaderCategory.NUMBER,
  },
  {label: "Author", column: "author", category: TableHeaderCategory.NULL},
];

export const ImageTagsTab = () => {
  const {
    tagDictionary,
    getTagTitleById,
    getTagAuthorIdById,
    getTagTimestampById,
  } = useTags(TagType.PRODUCT_CATALOG);
  const {sort, sortingDetails} = useTable(imageTagsColumnHeaders);
  const [showAddNewTag, setShowAddNewTag] = useState<boolean>(false);

  return (
    <>
      <AdminTabHeader
        title={" "}
        additionalActions={
          <NatButton
            clickEvent={() => setShowAddNewTag(true)}
            option={StyleOption.PRIMARY_ALT}
            label={"Create New Tag"}
            type={"button"}
            icon={{
              icon: <AiOutlinePlus />,
              iconPosition: IconPosition.LEFT,
            }}
          />
        }
      />
      <AddNewImageTagModal show={showAddNewTag} setShow={setShowAddNewTag} />
      <ImageTagsTableContainer>
        <Table fitToWidth={true} columnWidths={"1fr 1fr 2fr"}>
          <TableColumnHeaders
            headers={imageTagsColumnHeaders}
            sortingOption={{
              details: sortingDetails,
              setSorting: sort,
            }}
          />
          <TableBody>
            {sortTags(sortingDetails, tagDictionary)?.map(
              (tagId: string, index: number) => {
                return (
                  <ImageTagsTableRow
                    key={"tag-row-" + index}
                    tagInfo={{
                      title: getTagTitleById(tagId),
                      authorId: getTagAuthorIdById(tagId),
                      timestamp: getTagTimestampById(tagId),
                    }}
                  />
                );
              }
            )}
          </TableBody>
        </Table>
      </ImageTagsTableContainer>
    </>
  );
};
