import {IStore} from "../../slices/types/Store";
import {IProject} from "../../slices/ProjectSlice/ProjectSlice";

export const getProjectRoot = (state: IStore, projectId?: string): IProject => {
  if (projectId) {
    return state.project?.[projectId] ?? {};
  } else {
    return {} as IProject;
  }
};

export const getProjectDocuments = (state: IStore, projectId?: string) => {
  return getProjectRoot(state, projectId)?.documents ?? {};
};

export const getProjectNotes = (state: IStore, projectId?: string) => {
  return getProjectRoot(state, projectId)?.notes ?? {};
};

export const getSitePlanImages = (site: any): string[] => {
  if (site?.sitePlan?.images != null) {
    return [...site?.sitePlan?.images];
  } else if (site?.sitePlan?.imageId != null) {
    return [site?.sitePlan?.imageId];
  }
  return [];
};
