import React, {useMemo} from "react";
import {ICartItem} from "@natomas-org/core";
import {CartSectionItemProps} from "../CartSectionItem";
import CartSectionByUnit from "./CartSectionByUnit";
import {CartSectionSoftContainer} from "./styles";
import {Divider} from "../../../../../_shared/generics/divider";
import {shouldShowDivider} from "../helper";

interface CartSectionByUnitsProps {
  cartItems: ICartItem[];
  getCartItemRow: (item: ICartItem) => CartSectionItemProps;
  isMobile?: boolean;
}

export interface CartSectionByUnitProps {
  factoryId: string;
  productId: string;
  items: ICartItem[];
  getCartItemRow: (item: ICartItem) => CartSectionItemProps;
  isMobile?: boolean;
}

interface CartSectionUnitInfo {
  factoryId: string;
  productId: string;
  items: ICartItem[];
}

interface IBasicProductInfo {
  id: string;
  factoryId: string;
  productId: string;
}

const CartSectionByUnits = (props: CartSectionByUnitsProps) => {
  const {cartItems, getCartItemRow, isMobile} = props;
  const units: CartSectionUnitInfo[] = useMemo(() => {
    return getUniqueProductsFromCartItems(cartItems ?? []);
  }, [cartItems]);

  return (
    <CartSectionSoftContainer>
      {units.map((info: CartSectionUnitInfo, index: number) => {
        return (
          <>
            {shouldShowDivider(index, units.length) && <Divider />}
            <CartSectionByUnit
              {...info}
              isMobile={isMobile}
              getCartItemRow={getCartItemRow}
              key={`cart-section-${info.factoryId}-${info.productId}`}
            />
          </>
        );
      })}
    </CartSectionSoftContainer>
  );
};

const getUniqueProductsFromCartItems = (
  cart: ICartItem[]
): CartSectionUnitInfo[] => {
  const productDictionary = cart.reduce(
    (uniqueValues: IBasicProductInfo[], item: ICartItem) => {
      const factoryId = item.details?.factoryId ?? "";
      const productId = item.details?.productId ?? "";
      const id = `${factoryId}-${productId}`;
      if (!uniqueValues.find((value) => value.id === id)) {
        uniqueValues.push({id, factoryId, productId});
      }
      return uniqueValues;
    },
    []
  );

  const result: CartSectionUnitInfo[] = [];

  productDictionary.forEach((info: IBasicProductInfo) => {
    const items = cart.reduce((accumulator: ICartItem[], item: ICartItem) => {
      if (
        item.details?.productId === info.productId &&
        item.details?.factoryId === info.factoryId
      ) {
        accumulator.push(item);
      }
      return accumulator;
    }, []);
    result.push({
      factoryId: info.factoryId,
      productId: info.productId,
      items: items?.sort((a: ICartItem, b: ICartItem) =>
        a.id.localeCompare(b.id)
      ),
    });
  });

  return result.sort((a: CartSectionUnitInfo, b: CartSectionUnitInfo) => {
    return a.items.length > b.items.length ? -1 : 1;
  });
};

export default CartSectionByUnits;
