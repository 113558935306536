import React from "react";
import {NatModal} from "../../../../../../../../_shared/generics/modal/NatModal";
import {StyleOption} from "../../../../../../../../_shared/generics/_shared";

interface ChangePricingModeWarningProps {
  description: string;
  handleCancel: () => void;
  handleConfirm: () => void;
  title: string;
  show: boolean;
}

const ChangePricingModeWarning = (props: ChangePricingModeWarningProps) => {
  const {show, description, title, handleConfirm, handleCancel} = props;

  return (
    <NatModal
      content={<p>{description}</p>}
      header={title}
      handleClose={handleCancel}
      show={show}
      footerButtons={[
        {
          label: "Cancel",
          clickEvent: handleCancel,
          option: StyleOption.PRIMARY_BLACK,
        },
        {
          label: "Confirm",
          clickEvent: handleConfirm,
          option: StyleOption.PRIMARY,
        },
      ]}
    />
  );
};

export default ChangePricingModeWarning;
