import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ProductStructure} from "../../../_models/product";
import {
  DuplicateFactoryLineElementRequestType,
  getIntegerValue,
  getPriceMicrosFromText,
  getTextFromPriceMicros,
  IProduct,
  isBlankString,
} from "@natomas-org/core";
import {readDeepValue, setDeepValue} from "../../../../_shared/Utils";
import {DNDEditor} from "../../components/DragAndDropEditor/DNDEditor";

import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {
  DesignStudioContainer,
  MainContent,
  MainView,
  NatStyledForm,
} from "../../../_shared/styles/AdminStyles";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixIncludeOptions,
  IStore,
  StudioState,
} from "../../../../_shared/slices/types/Store";
import {AdminInput} from "../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {editProduct} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {AdminTabHeader} from "../../components/Header/AdminTabHeader";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import {
  archiveProduct,
  undoArchiveProduct,
} from "../../../../../database/firebase/catalog/saving";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {DuplicateFactoryLineElementButton} from "./DuplicateFactoryLineElementButton";
import {getProductModifierUpdates} from "../MatrixTab/_shared/utilities";
import {MatrixTab} from "../MatrixTab";
import Studio from "../../../../studio";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../../../_shared/generics/popup/dropdown/constants";
import {NatSizeType} from "../../../../_shared/generics/_shared";

export const getBlankItemSection = (items: any) => {
  return {
    initial: {
      items: items,
      index: 0,
      title: "General",
    },
  };
};

const tabs = [{id: "details", title: "Basic Info"}];

const modelTabs = [
  ...tabs,
  {id: "structure", title: "Product Structure"},
  {id: "matrix", title: "Matrix"},
];

const productStructureForForm = getFormStructure(ProductStructure);

const getInitialValues = (obj: any) => {
  const structure = ProductStructure;
  const initialValues = getFormInitialValues(structure);
  if (obj != null) {
    for (const key in structure) {
      // @ts-ignore
      const keyInfo = structure[key];
      let initialValue = obj[key];
      if (keyInfo.path != null) {
        initialValue = readDeepValue(obj, keyInfo.path);
      }

      if (initialValue != null) {
        if (keyInfo.subType === "price") {
          initialValue = getTextFromPriceMicros(initialValue);
        } else if (typeof initialValue === "number") {
          initialValue = "" + initialValue;
        }
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProduct = () => {
  const editingProduct = useSelector(
    (state: IStore) => state.adminStudioManager.editingProduct
  );
  const {categories, products, details} = useSelectedFactoryLine();
  const {
    editingEnabled,
    updateModifiersInBulk,
    updateAdminProCatFilters,
    updateProduct,
  } = useAdminProductCatalog();
  const [matrixEdits, setMatrixEdits] = useState<any>(null);

  let currentProduct = editingProduct;
  if (products?.[currentProduct?.id]) {
    currentProduct = products?.[currentProduct?.id];
  }

  const [tab, setTab] = useState(modelTabs[0].id);

  const changeTab = (id: string) => {
    if (id === "matrix") {
      updateMatrixFilters();
    }
    setTab(id);
  };

  const dispatch = useDispatch();
  const close = () => {
    dispatch(editProduct(null));
  };

  const deleteAction = () => {
    if (details) {
      if (currentProduct?.id == null) {
        close();
      } else {
        return archiveProduct(
          details?.id,
          details?.versionId,
          currentProduct.id
        );
      }
    }
  };

  const undoDeleteAction = () => {
    if (details) {
      return undoArchiveProduct(
        details?.id,
        details?.versionId,
        currentProduct.id
      );
    }
  };

  const updateMatrixFilters = () => {
    updateAdminProCatFilters({
      include: AdminProductCatalogMatrixIncludeOptions.ALL,
      filterBy: AdminProductCatalogMatrixFilterByOptions.PRODUCT,
      filterIds: [currentProduct?.id],
    });
  };

  const resetMatrixEdits = () => {
    setMatrixEdits(null);
  };

  const saveMatrixEdits = () => {
    const dbUpdates = getProductModifierUpdates(matrixEdits);
    updateModifiersInBulk(dbUpdates)?.then(() => {
      resetMatrixEdits();
    });
  };

  const initialValues = getInitialValues(currentProduct);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (matrixEdits) {
        saveMatrixEdits();
      }
      // @ts-ignore
      let valueToSave: IProduct = {};
      for (const key in values) {
        // @ts-ignore
        let value = values[key];
        // @ts-ignore
        const keyInfo = ProductStructure[key];
        if (keyInfo?.subType === "price") {
          value = getPriceMicrosFromText(value);
        } else if (keyInfo?.type === "number") {
          value = getIntegerValue(value);
        }

        if (keyInfo?.path != null) {
          setDeepValue(valueToSave, keyInfo.path, value);
        } else {
          // @ts-ignore
          valueToSave[key] = value;
        }
      }

      if (isBlankString(valueToSave?.imageId)) {
        valueToSave["imageId"] = null;
      }
      valueToSave["inventory_info"] = null;

      return updateProduct(currentProduct.id, valueToSave);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
    if (currentProduct) {
      updateMatrixFilters();
    }
    // If tabs does not contain selected tab
    // This is so bad - really bad - definitely TODO
    // This is duplicated from GenericNavBar.js, both are independently tracking tabs
    // changeTab(modelTabs[0].id);
  }, [currentProduct]);

  const libraryIds = Object.keys(categories ?? {});

  const validateChange = async (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const getMainContent = () => {
    if (currentProduct == null) {
      return <></>;
    } else if (tab === "matrix") {
      return (
        <MatrixTab
          subTabMode={true}
          setMatrixEdits={setMatrixEdits}
          matrixEdits={matrixEdits}
        />
      );
    } else if (tab === "details") {
      return (
        <MainContent>
          <NatStyledForm onSubmit={formik.handleSubmit}>
            {productStructureForForm.map((keyInfo) => {
              const {key} = keyInfo;
              // @ts-ignore
              let value = formik.values[key];
              // @ts-ignore
              let initial = initialValues[key];
              if (key === "configuratorPages") {
                return null;
              }

              return (
                <AdminInput
                  key={key}
                  value={value}
                  fieldInfo={keyInfo}
                  initialValue={initial}
                  disabled={
                    keyInfo?.disabled ||
                    (!keyInfo?.alwaysEnabled && !editingEnabled)
                  }
                  onChange={(value: any) => {
                    validateChange(key, value);
                  }}
                />
              );
            })}
          </NatStyledForm>
        </MainContent>
      );
    } else if (tab === "structure") {
      const key = "configuratorPages";
      // @ts-ignore
      const value = formik.values[key];
      return (
        <MainContent>
          <div style={{padding: "1rem 0"}}>
            <DNDEditor
              sections={getBlankItemSection(value)}
              fieldKey={key}
              formik={formik}
              savedIds={value}
              libraryIds={libraryIds}
              type={"categories"}
              callback={(newCategories: any) => {
                formik.setFieldValue(key, newCategories);
              }}
            />
          </div>
        </MainContent>
      );
    } else if (tab === "upgrades") {
      return (
        <MainContent noPadding={true}>
          <DesignStudioContainer>
            <Studio
              modeOverride={StudioState.BUILDER}
              productOverride={currentProduct}
              options={{
                hideBack: true,
              }}
            />
          </DesignStudioContainer>
        </MainContent>
      );
    } else {
      return <></>;
    }
  };

  const viewProductInCatalog = useCallback(() => {
    window.open(
      window.location.origin +
        "/products?unit=" +
        currentProduct?.id +
        "&gid=" +
        currentProduct?.productGroupId
    );
  }, [currentProduct?.id, currentProduct?.productGroupId]);

  const viewProductInStudio = useCallback(() => {
    window.open(
      window.location.origin +
        "/studio?unit=" +
        currentProduct?.id +
        "&gid=" +
        currentProduct?.productGroupId
    );
  }, [currentProduct?.id, currentProduct?.productGroupId]);

  const dropdownViews = useMemo(() => {
    return [
      {id: "catalog", label: "Catalog", callback: viewProductInCatalog},
      {id: "studio", label: "Studio", callback: viewProductInStudio},
    ];
  }, [viewProductInCatalog, viewProductInStudio]);

  if (currentProduct == null) {
    return null;
  }

  return (
    <MainView>
      <AdminTabHeader
        tabs={modelTabs}
        setTab={changeTab}
        isUpToDate={isDeepEqual(formik.values, initialValues) && !matrixEdits}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
          resetMatrixEdits();
        }}
        additionalActions={
          <>
            {editingEnabled && currentProduct && (
              <>
                <DuplicateFactoryLineElementButton
                  factoryLineId={currentProduct?.productGroupId}
                  requestId={currentProduct?.id}
                  requestType={DuplicateFactoryLineElementRequestType.PRODUCT}
                  resetAction={close}
                />
              </>
            )}
            <NatDropdown
              style={
                NatDropdownStyles[DropdownStyles.PRIMARY][NatSizeType.SMALL]
              }
              toggleButtonOrLabel={"Views"}
              contentItemArray={dropdownViews}
              trackingId={"admin-tab-options"}
            />
          </>
        }
        deleteAction={
          !isArchived(currentProduct) && editingEnabled
            ? deleteAction
            : undefined
        }
        undoDeleteAction={
          isArchived(currentProduct) && editingEnabled
            ? undoDeleteAction
            : undefined
        }
        saveAction={formik.handleSubmit}
      />

      {getMainContent()}
    </MainView>
  );
};
