import {InfoSetItemTableRow, rowOptionValueAlreadyExists} from "../../../table";
import {RowStringInput, sanitizeString} from "./RowStringInput";
import React from "react";
import {ID_KEY} from "../../../../shared/constants";

export const RowOptionInput = (props: {
  onCommitChange: (arg: string) => void;
  onChange: any;
  currentRow: InfoSetItemTableRow;
  otherRows: InfoSetItemTableRow[];
  value: string;
  originalValue: string;
}) => {
  const {
    currentRow,
    onCommitChange,
    onChange,
    otherRows,
    originalValue,
    value,
  } = props;

  const isValidChange = (value: string) => {
    const sanitizedString = sanitizeString(value);
    const isInvalid = rowOptionValueAlreadyExists(
      otherRows,
      currentRow[ID_KEY],
      sanitizedString
    );
    return !isInvalid;
  };

  return (
    <RowStringInput
      isValidChange={isValidChange}
      onChange={onChange}
      onCommitChange={onCommitChange}
      originalValue={originalValue}
      otherRows={otherRows}
      placeholder={"{Custom}"}
      value={value}
    />
  );
};
