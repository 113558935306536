import React from "react";
import {ProductLinesContainer} from "./styles";
import AdminBlankScreen from "../../../_shared/components/AdminBlankScreen";
import {FactorySelector} from "./_shared/FactorySelector";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import FactoryViews from "./FactoryViews";
import {ProjectPriceMode} from "@natomas-org/core";

export const FactoriesTab = () => {
  const {selectedProductGroupId} = useCatalogSession();
  const priceMode = ProjectPriceMode.DTC;
  return (
    <ProductLinesContainer>
      {!selectedProductGroupId && (
        <AdminBlankScreen
          title={"Factory Manager"}
          description={"Select a factory to start editing"}
          content={<FactorySelector />}
        />
      )}
      {selectedProductGroupId && (
        <FactoryViews id={selectedProductGroupId} priceMode={priceMode} />
      )}
    </ProductLinesContainer>
  );
};
