import React from "react";
import {MatrixTableCustomCellProps} from "../../_shared/interfaces";
import {MatrixHeaderCell, MatrixTableCell} from "../../../styles";
import {CONTAINER_BACKGROUND_COLOR} from "../../../../../../../../../_shared/colors";
import {BackLinkIcon} from "../../../../../../../components/BackLinkIcon";
import {viewModifier, viewProduct} from "../../../../../../../logic/helpers";
import {MatrixTableController} from "../../../../MatrixTableController";

export const DescriptionTableCell = (props: MatrixTableCustomCellProps) => {
  const {customProps, pageProps} = props;
  const key = customProps.col + "_" + customProps.row;
  const target = customProps?.cell?.value;
  const getBackLinkAction = () => {
    if (pageProps?.transpose) {
      if (customProps.row === 0) {
        return () => viewModifier(target);
      } else {
        return () => viewProduct(target);
      }
    } else {
      if (customProps.row === 0) {
        return () => viewProduct(target);
      } else {
        return () => viewModifier(target);
      }
    }
  };
  if (customProps.col === 0 && customProps.row === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        horizontalSticky={true}
        width={"15rem"}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          <MatrixTableController {...pageProps} />
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  if (customProps.col === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        horizontalSticky={true}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          {target?.title || "Default"}
          {target?.title && <BackLinkIcon action={getBackLinkAction()} />}
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  if (customProps.row === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        width={!pageProps?.transpose ? "15rem" : undefined}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          {target?.title || "Default"}
          {target?.title && <BackLinkIcon action={getBackLinkAction()} />}
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  return (
    <MatrixTableCell key={key} {...customProps}>
      {customProps?.children}
    </MatrixTableCell>
  );
};
