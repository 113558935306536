import styled from "styled-components";
import {GRAY} from "../../../../../../_shared/colors";

export const FactoryInventoryViewDescription = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: ${GRAY};
`

export const FactoryInventoryViewTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 2rem;
  color: ${GRAY};
`
