import React, {useState} from "react";
import {ProductOptionKey} from "../../../../_shared/constants/ProductOption";
import {OptionKeyList} from "./OptionKeyList";
import {OptionKeyEditor} from "./OptionKeyEditor";
import {OptionView} from "../styles";

export const OptionKeyTab = () => {
  const [currentKey, setCurrentKey] = useState<ProductOptionKey | null>(null);
  return (
    <OptionView>
      <OptionKeyList currentKey={currentKey} setCurrentKey={setCurrentKey} />
      {currentKey && (
        <OptionKeyEditor
          currentKey={currentKey}
          setCurrentKey={setCurrentKey}
        />
      )}
    </OptionView>
  );
};
