import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../portal/views/SSDDashboardView/styles/globals";
import {ADMIN_BUTTON_ACTIVE, BLACK} from "../../../../../../_shared/colors";
import {useAdminProductCatalog} from "../../../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {AdminProductCatalogMatrixTitleModes} from "../../../../../../_shared/slices/types/Store";
import {MicroSwitch} from "../../../../../../_shared/generics/switch/microSwitch";
import React from "react";

export const MatrixTitleModeLabel = styled.div<{selected: boolean}>`
  font-size: 0.85rem;
  font-family: ${(props) =>
    props.selected ? FONT_FAMILY_MEDIUM : FONT_FAMILY_REGULAR};
  color: ${(props) => (props.selected ? ADMIN_BUTTON_ACTIVE : BLACK)};
`;

export const MatrixTitleModeContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;

export const MatrixTitleModeSelector = () => {
  const {matrixTitleMode, updateAdminProCatMatrixTitleMode} =
    useAdminProductCatalog();
  const isInternal =
    matrixTitleMode === AdminProductCatalogMatrixTitleModes.INTERNAL;
  return (
    <MatrixTitleModeContainer>
      <MatrixTitleModeLabel selected={isInternal}>
        Internal
      </MatrixTitleModeLabel>
      <MicroSwitch
        key={"matrix-sub-mode-switch"}
        isChecked={!isInternal}
        setChecked={() => {
          if (isInternal) {
            updateAdminProCatMatrixTitleMode(
              AdminProductCatalogMatrixTitleModes.TITLE
            );
          } else {
            updateAdminProCatMatrixTitleMode(
              AdminProductCatalogMatrixTitleModes.INTERNAL
            );
          }
        }}
      />
      <MatrixTitleModeLabel selected={!isInternal}>Studio</MatrixTitleModeLabel>
    </MatrixTitleModeContainer>
  );
};
