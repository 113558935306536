import styled from "styled-components";
import {DIVIDER_COLOR, WHITE} from "../../../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const CustomerProjectTabHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid ${DIVIDER_COLOR};
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;
`;

export const CustomerProjectTabHeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const CustomerTabMenuItem = styled.div<{selected: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: ${(props) =>
    props.selected ? FONT_FAMILY_BOLD : FONT_FAMILY_MEDIUM};
  padding: 1rem;
  background-color: ${(props) =>
    props.selected ? "rgba(255,255,255, 0.1)" : "rgba(255,255,255, 0.0)"};
  color: ${WHITE};
  border-bottom: 1px solid ${DIVIDER_COLOR};
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;

export const CustomerTabMenu = styled.div`
  display: flex;
  flex-direction: column;
`;
