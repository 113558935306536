import React, {useMemo} from "react";
import {
  NatSortDownIcon,
  NatSortUpIcon,
  NatUnsortedIcon,
} from "../../../../_shared/icon/icons";
import {ColumnHeaderContainer} from "./styles";

export const ReactTableColumnHeader = (props: {
  column: any;
  onClick: any;
}): JSX.Element | null => {
  const {column, onClick} = props;
  const {canSort, isSorted, isSortedDesc} = column;

  const sortButton = useMemo(() => {
    if (canSort) {
      return isSorted ? (
        isSortedDesc ? (
          <NatSortUpIcon size={12} />
        ) : (
          <NatSortDownIcon size={12} />
        )
      ) : (
        <NatUnsortedIcon size={12} />
      );
    }
    return undefined;
  }, [canSort, isSorted, isSortedDesc]);

  return (
    <ColumnHeaderContainer onClick={canSort ? onClick : undefined}>
      {column.render("Header")}
      {sortButton}
    </ColumnHeaderContainer>
  );
};
