import {useMap} from "react-leaflet";
import {useEffect} from "react";

export const InnerMap = (props: {setMap: (map: any) => void}) => {
  const map = useMap();

  useEffect(() => {
    props.setMap(map);
  }, [map]);

  return null;
};
