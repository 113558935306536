import {useEffect} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../../slices/types/Store";
import {fetchMappingFeature} from "../../../../../database/firebase/mapping/fetching";
import {useFactoryLine} from "../../useProductCatalog/useFactoryLine/useFactoryLine";
import {tryFetchingCatalogFactory} from "../../../../../root/FetchingManager";

export const useAdminFactoryLine = (
  factoryLineId?: string,
  versionId?: string
) => {
  const factoryLine = useFactoryLine({
    factoryId: factoryLineId,
    versionId: versionId,
  });
  const {info, infoSet} = factoryLine;
  const mapping = useSelector(
    (state: IStore) => state.natomasMap.features[info?.id ?? "NULL"]
  );

  useEffect(() => {
    if (info && info?.id && !infoSet) {
      tryFetchingCatalogFactory(info?.id, {
        fullFetch: true,
      })?.then(() =>
        console.log("useFactoryLine: Fetched FactoryLine InfoSet")
      );
    }
    if (info && info?.id && !mapping) {
      fetchMappingFeature(info?.id);
    }
  }, [info, infoSet, mapping]);

  return factoryLine;
};
