import React from "react";
import {IHCPViewProps} from "../interfaces";
import {PortalDocumentsView} from "./components/FPDocumentsView";
import {PORTAL_TASKS_AND_DOCUMENTS_ID} from "../../HCPDashboardLayout/HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {
  FPMeetingNotesContainer,
  PortalDocumentContainer,
  PortalTabSectionButtonAndContentContainer,
} from "../FPMeetingNotes/styles";
import {NatFlex} from "../../../../_shared/generics/flex/NatFlex";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useProjectTasksList} from "../../ProjectTasks/ProjectTasksList";

export const FPDocumentsAndTasksView = (props: IHCPViewProps) => {
  const [tab, setTab] = React.useState<"tasks" | "documents">("tasks");
  const {isNatMobile} = usePage();
  const {TaskList, ToggleElement} = useProjectTasksList({
    public_facing: true,
    showCompletedTasks: undefined,
  });
  return (
    <PortalSection
      sectionId={PORTAL_TASKS_AND_DOCUMENTS_ID}
      headerTitle={"Tasks & Documents"}
    >
      <FPMeetingNotesContainer>
        <PortalTabSectionButtonAndContentContainer>
          <NatFlex>
            <NatButton
              label={"View tasks"}
              option={
                tab === "tasks"
                  ? StyleOption.SELECTED_TOGGLE_ON
                  : StyleOption.SELECTED_TOGGLE_OFF
              }
              size={NatSize.SMALL}
              clickEvent={() => {
                setTab("tasks");
              }}
              style={{width: "fit-content"}}
            />
            <NatButton
              label={"View documents"}
              option={
                tab === "documents"
                  ? StyleOption.SELECTED_TOGGLE_ON
                  : StyleOption.SELECTED_TOGGLE_OFF
              }
              style={{width: "fit-content"}}
              size={NatSize.SMALL}
              clickEvent={() => {
                setTab("documents");
              }}
            />
          </NatFlex>
          {!isNatMobile && tab === "tasks" && ToggleElement}
        </PortalTabSectionButtonAndContentContainer>
        <PortalDocumentContainer>
          {tab === "tasks" && TaskList}
          {tab === "documents" && <PortalDocumentsView />}
        </PortalDocumentContainer>
        {isNatMobile && tab === "tasks" && ToggleElement}
      </FPMeetingNotesContainer>
    </PortalSection>
  );
};
