export const verifyFactoryLineVersionState = (state: any, factoryId: string, versionId: string) => {
  if (!state[factoryId]) {
    state[factoryId] = {versions: {[versionId]: {}}}
  }
  if (!state[factoryId].versions[versionId]) {
    state[factoryId].versions[versionId] = {}
  }
}

export const verifyFactoryLineState = (state: any, factoryId: string,) => {
  if (!state[factoryId]) {
    state[factoryId] = {versions: {}}
  }
}
