import React from "react";
import {
  IAlignItemsValue,
  IJustifyContentValue,
  PageElementWrapperContainer,
} from "./styles";
import {useDynamicValue} from "../../../../hooks/useDynamicValue";

/*
An easy to use flex container for page elements
 */

export const PageElementWrapper = (props: {
  children: any;
  justifyContent?: IJustifyContentValue;
  alignItems?: IAlignItemsValue;
  reverseWrap?: boolean;
}) => {
  const flexDirection = useDynamicValue({
    forFour: props.reverseWrap ? "row" : "column",
    forEight: "row",
    forTwelve: "row",
    forSixteen: "row",
  });
  return (
    <PageElementWrapperContainer
      direction={flexDirection}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      reverseWrap={props.reverseWrap}
    >
      {props.children}
    </PageElementWrapperContainer>
  );
};
