import React from "react";
import Text from "../../2-component/Text";
import {ContentWrapper} from "./style";
import DirectCustomerForm from "../../1-container/forms/DirectCustomerForm";
import {FormProps} from "../../2-component/Form/interface";
import {IAddressDetails} from "@natomas-org/core";
import {InputLibrary} from "../../1-container/forms/library/inputs";
import {useAddress} from "../../../components/_shared/hooks/useAddress";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";

interface ConfirmCustomerContentProps extends FormProps {
  schema?: CreateLeadSchema;
}

const getInputs = (
  defaultFirst?: string,
  defaultLast?: string,
  defaultAddress?: IAddressDetails
) => {
  return [
    {...InputLibrary.firstNameInput, row: 1, defaultValue: defaultFirst},
    {...InputLibrary.lastNameInput, row: 1, defaultValue: defaultLast},
    InputLibrary.phoneInput,
    {...InputLibrary.addressInput, defaultValue: defaultAddress?.full_address},
    // InputLibrary.marketingConsentCheckbox,
  ];
};

const ConfirmCustomerContent = (props: ConfirmCustomerContentProps) => {
  const {schema, ...form} = props;
  const {url, publicAddress} = useAddress();
  const inputs = getInputs(
    schema?.["first_name"],
    schema?.["last_name"],
    url ?? publicAddress ?? undefined
  );
  return (
    <ContentWrapper>
      <Text
        text={
          "A free account lets you save your Villa design, see how it fits on your lot and get an instant quote."
        }
        style={{textAlign: "center"}}
      />
      <DirectCustomerForm {...form} inputs={inputs} />
    </ContentWrapper>
  );
};

export default ConfirmCustomerContent;
