import React, {useState} from "react";
import Button from "../../2-component/Button";
import firebase from "firebase";
import {AuthButtonLabelContainer} from "./style";
import Logo from "./google.svg";
import LogRocket from "logrocket";

const Google = (props: {
  id: string;
  label: string;
  onSubmit: (values: any) => void;
}) => {
  const {id, label, onSubmit} = props;
  const [error, setError] = useState(undefined);
  const handleClick = () => {
    // Sign in with Google auth has been clicked
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    // Sign in with Google auth has been clicked
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        onSubmit(result);
      })
      .catch((error) => {
        LogRocket.error(error.message);
      });
  };

  return (
    <Button
      id={id}
      label={
        <AuthButtonLabelContainer>
          <img id={"google-logo"} src={Logo} alt={"Google"} />
          {label}
        </AuthButtonLabelContainer>
      }
      error={error}
      onClick={handleClick}
      theme={"secondary"}
    />
  );
};

export default Google;
