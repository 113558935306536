import styled from "styled-components";
import {LOADING_PANEL_Z_INDEX} from "../../styles";
import {VILLA_BLUE} from "../../colors";

export const LoadingPanelContainer = styled.div<{
  hide: boolean;
  opacity: number;
  position?: "fixed";
}>`
  z-index: ${LOADING_PANEL_Z_INDEX};
  background-color: ${VILLA_BLUE};
  position: ${(props) => props.position};
  height: 100%;
  width: 100%;
  display: ${(props) => (props.hide ? "none" : "flex")};
  opacity: ${(props) => props.opacity};
  transition: opacity 250ms linear;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white !important;
`;

export const ImageContainer = styled.div`
  height: 5rem;
  width: auto;

  img {
    height: 5rem;
    width: auto;
  }

  margin-bottom: 2rem;
`;

export const LoadingPanelSpinner = styled.div<{size: string}>`
  height: ${(props) => props.size};
  width: ${(props) => props.size};

  svg {
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
    animation: rotation 2s infinite linear;
    vertical-align: unset !important;
  }
`;

export const SpinnerContainer = styled.div`
  height: 2rem;
  width: 2rem;
`;
