import {ImageCard} from "../ImageCard";
import {ActionCard} from "../ActionCard";
import {ImageGalleryLayout} from "../styles";
import React, {useMemo} from "react";
import {AiOutlineCloudDownload} from "react-icons/ai";

export interface ImageGalleryProps {
  showEditDetails: boolean;
  imageIds?: string[];
  selectImage?: any;
  queryImages?: any;
  isEnd?: boolean;
}

export const ImageGallery = (props: ImageGalleryProps) => {
  const {showEditDetails, imageIds, selectImage, queryImages, isEnd} = props;

  const images = useMemo(() => {
    return imageIds?.map((id: string, index: number) => {
      return (
        <ImageCard
          key={"image-finder-card-" + index}
          id={id}
          selectImage={selectImage}
          showEditDetails={showEditDetails}
        />
      );
    });
  }, [imageIds, selectImage]);

  return (
    <ImageGalleryLayout>
      {isEnd && (!imageIds || imageIds?.length === 0) && <>No results</>}
      {images}
      {!isEnd && (
        <ActionCard
          icon={<AiOutlineCloudDownload />}
          label={"More..."}
          action={queryImages}
        />
      )}
    </ImageGalleryLayout>
  );
};
