import React, {useContext} from "react";
import {NatButton} from "../../../../../../_shared/generics/button";
import {scrollToElementWithOffset} from "../../../../../../_shared/navigation/_helpers";
import {PORTAL_UNIT_VIEW_ID} from "../../../HCPTitleNav/navigation_ids";
import {NatSize} from "../../../../../../_shared/generics/_shared";
import small_home from "../../../../../../../assets/images/graphics/small_home.svg";
import {Address} from "@natomas-org/core";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";
import {useDelay} from "../../../../../../_shared/hooks/useDelay";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {Wrapper} from "./styles";
import {
  PresentationContainer,
  PresentationSubtitle,
  PresentationTitle,
} from "../../styles";
import {
  FULL_SCREEN_HEIGHT_REM,
  FULL_SCREEN_WIDTH_REM,
} from "../../../HCPUnitView/DesignSelections/constants";
import {EMBEDDED_PROPOSAL, PROPOSAL_GENERATE_PDF} from "./constants";

export const DTCPresentationSplash = () => {
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const embeddedProposal = useContext(EMBEDDED_PROPOSAL);

  const {address} = useActiveProject();
  const fadeIn = useDelay({milliseconds: 300}).ready;
  if (!address) {
    return null;
  }

  return (
    <PresentationContainer
      fadeIn={fadeIn}
      style={
        isGeneratingPDF
          ? {
              alignItems: "flex-start",
              minHeight: `${FULL_SCREEN_HEIGHT_REM}rem`,
              maxHeight: `${FULL_SCREEN_HEIGHT_REM}rem`,
              minWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
              maxWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
              pageBreakAfter: "avoid",
              pageBreakInside: "avoid",
              breakAfter: "avoid",
              breakInside: "avoid",
            }
          : {maxWidth: "99vw", overflow: "hidden"}
      }
    >
      <Wrapper>
        <img width={"300px"} src={small_home} alt={"start project"} />
        <PresentationTitle>
          <NatLabel
            label={Address.getStreetNumberAndStreet(address)}
            type={NatLabelType.H1}
          />
        </PresentationTitle>
        <PresentationSubtitle>
          <NatLabel
            label={`Your project proposal is here and it’s packed with everything you need to know about your upcoming project. Get ready to turn your dream into a reality.`}
            type={NatLabelType.H3}
          />
        </PresentationSubtitle>
        <NatButton
          size={NatSize.XLARGE}
          hidden={isGeneratingPDF || embeddedProposal}
          label={"Begin proposal"}
          clickEvent={() => {
            scrollToElementWithOffset(PORTAL_UNIT_VIEW_ID, {top: 0}, true);
          }}
        />
      </Wrapper>
    </PresentationContainer>
  );
};
