import React, {useState} from "react";
import {AppTile} from "../../components/AppTile";
import {usePage} from "../../../_shared/hooks/usePage";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {BLACK, VILLA_BLUE, WARM_NEUTRAL, WHITE} from "../../../_shared/colors";
import {CompletedProjectsBanner} from "../../components/CompletedProjectsBanner";
import {HelpfulLinks} from "../../../portal/_shared/components/HelpfulLinks";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {LoadingPanel} from "../../../_shared/generics/loading-panel";
import {useTimeout} from "usehooks-ts";
import {Helmet} from "react-helmet-async";
import {Page} from "../../../_shared/generics/page";
import {
  AppTilesContainer,
  SplashScreenHelpfulLinksWrapper,
  SplashScreenLinksContainer,
} from "./styles";
import {useCustomerStatus} from "../../../_shared/hooks/useCustomerStatus";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {SplashScreenHeader} from "./components/SplashScreenHeader";
import {CustomerProjectBanner} from "./components/CustomerProjectBanner";

const SplashScreen = () => {
  const {isNatMobile} = usePage();
  const {to} = useNavigation();
  const {loggedInUser} = useCurrentUser();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const fullWidth = useDynamicValue({
    forFour: 4,
    forEight: 8,
    forTwelve: 12,
    forSixteen: 16,
  });
  const [showSplash, setShowSplash] = useState(true);
  useTimeout(() => {
    setShowSplash(false);
  }, 250);

  return (
    <Page>
      <Helmet>
        <title>Villa Homes</title>
        {/*TODO Ask marketing for 'content' here*/}
        <meta
          name="Welcome to Villa"
          content="Want to build a backyard home/accessory dwelling unit in CA? We handle everything from permits to final inspection. Get a free property assessment today."
        />
      </Helmet>
      <LoadingPanel show={showSplash} />
      <PageContainer overridePadding={true}>
        <SplashScreenHeader />
      </PageContainer>
      <PageContainer overridePadding={false}>
        <CustomerProjectBanner
          customerProjects={[]}
          isCustomerPreDeposit={isCustomerPreDeposit}
          setShowLoadingScreen={setShowSplash}
        />
      </PageContainer>
      <PageContainer overridePadding={true}>
        <PageElement size={fullWidth} height={"auto"}>
          <AppTilesContainer isMobile={isNatMobile}>
            <AppTile
              ctaAction={() => {
                to(NavigationPaths.PROPERTY_MAP);
              }}
              ctaText={"Get started"}
              backgroundColor={VILLA_BLUE}
              imageId={
                "https://firebasestorage.googleapis.com/v0/b/natomas-staging.appspot.com/o/assets%2Fsplash%2FVillaMapSat.png?alt=media"
              }
              title={"Explore how ADUs fit on your property"}
              trackingId={
                loggedInUser
                  ? "account-splash-screen-map-cta"
                  : "pa-splash-screen-map-cta"
              }
              textColor={WHITE}
            />
            <AppTile
              ctaAction={() => {
                to(NavigationPaths.PUBLIC_CATALOG);
              }}
              ctaText={"Get Started"}
              backgroundColor={WARM_NEUTRAL}
              imageId={
                "https://firebasestorage.googleapis.com/v0/b/natomas-staging.appspot.com/o/assets%2Fsplash%2Fdesign-studio-2.png?alt=media"
              }
              title={"Select and customize your dream ADU"}
              trackingId={
                loggedInUser
                  ? "account-splash-screen-catalog-cta"
                  : "pa-splash-screen-catalog-cta"
              }
              textColor={BLACK}
            />
          </AppTilesContainer>
        </PageElement>
        <br />
      </PageContainer>
      <CompletedProjectsBanner />
      <PageContainer overridePadding={true}>
        <SplashScreenLinksContainer>
          <PageContainer>
            <PageElement size={fullWidth} height={"auto"}>
              <SplashScreenHelpfulLinksWrapper>
                Useful links
              </SplashScreenHelpfulLinksWrapper>
              <HelpfulLinks />
            </PageElement>
          </PageContainer>
        </SplashScreenLinksContainer>
      </PageContainer>
    </Page>
  );
};

export default SplashScreen;
