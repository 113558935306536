import React from "react";
import {
  HCPCalendarEvent,
  HCPCalendarEventStatus,
} from "./components/HCPCalendarEvent";
import {useDynamicValue} from "../../../../_shared/hooks/useDynamicValue";
import {
  DateUtils,
  IEventId,
  IEventInfo,
  Person,
  Project,
  TeamMemberRole,
} from "@natomas-org/core";
import {PORTAL_CALENDAR_ID} from "../HCPTitleNav/navigation_ids";
import {convertSalesforceDateToLocalTime} from "../HCPGreetingView/logic";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {IHCPViewProps} from "../interfaces";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {PageElementWrapper} from "../../../../_shared/generics/page/components/PageElementWrapper";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

export const HCPCalendar = (props: IHCPViewProps) => {
  const {width} = props;
  const {info, isDevelopmentByVilla} = useActiveProject();
  const teamMember = Project.getTeamMemberForRole(info, TeamMemberRole.HC);
  let teamMemberFullName = "TBD";
  let teamMemberEmail = "";
  if (teamMember != null) {
    const {personInfo} = teamMember;
    teamMemberFullName = Person.getFullName(personInfo);
    teamMemberEmail = Person.getContactEmail(personInfo);
  }

  // Kick off event
  const kickOffDate = Project.getEvent(info, "kick-off");
  const kickOffStatus = getDateStatus(kickOffDate);
  const kickOffDateString = getDateString(kickOffDate);

  // Design session event
  const designSessionDate = Project.getEvent(info, IEventId.DESIGN_SESSION);
  const designSessionStatus = getDateStatus(designSessionDate);
  const designSessionDateString = getDateString(designSessionDate);

  // Proposal event
  const proposalDate = Project.getEvent(info, "proposal");
  const proposalStatus = getDateStatus(proposalDate);
  const proposalDateString = getDateString(proposalDate);

  const calendarEventSize = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
    forSixteen: 8,
  });

  // const slides = useMemo(() => {
  //   const content: NatSwiperSlide[] = [];
  //   content.push({
  //     title: "",
  //     width: pageWidth / 2 + "px",
  //     content: (
  //       <HCPCalendarEvent
  //         email={teamMemberEmail}
  //         titleText={"Project kick-off call"}
  //         whenText={kickOffDateString}
  //         whereText={"Google Meet"}
  //         withText={teamMemberFullName}
  //         agendaText={
  //           "This is an opportunity for us to discuss site feasibility, floor plans, utilities, infrastructure, site placement, initial pricing, and design. We can’t wait to get started!"
  //         }
  //         status={kickOffStatus}
  //       />
  //     ),
  //   });
  //
  //   if (designSessionDate) {
  //     content.push({
  //       title: "",
  //       width: pageWidth / 2 + "px",
  //       content: (
  //         <HCPCalendarEvent
  //           email={teamMemberEmail}
  //           titleText={"Design session call"}
  //           whenText={designSessionDateString}
  //           whereText={"Google Meet"}
  //           withText={teamMemberFullName}
  //           agendaText={
  //             "We’ll review initial design selections ranging from floor plan layouts and bathroom choices to kitchen color schemes and exterior finishes. We’ll discuss pricing of upgrades and make recommendations based on our design expertise!"
  //           }
  //           status={designSessionStatus}
  //         />
  //       ),
  //     });
  //   }
  //
  //   content.push({
  //     title: "",
  //     width: pageWidth / 2 + "px",
  //     content: (
  //       <HCPCalendarEvent
  //         email={teamMemberEmail}
  //         titleText={"Project proposal call"}
  //         whenText={proposalDateString}
  //         whereText={"Google Meet"}
  //         withText={teamMemberFullName}
  //         agendaText={
  //           "Get ready for your ADU project to take shape! On this call, we will present a detailed line-by-line estimate of all site work costs and unit design. Let’s get started and make your ADU dreams a reality!"
  //         }
  //         status={proposalStatus}
  //       />
  //     ),
  //   });
  //
  //   return content;
  // }, [proposalDate, designSessionDate, kickOffDate, pageWidth]);

  if (!kickOffDate) {
    return null;
  }

  if (isDevelopmentByVilla) {
    return null;
  }

  return (
    <PortalSection
      headerTitle={"Calendar"}
      width={width}
      sectionId={PORTAL_CALENDAR_ID}
    >
      {/*<NatSwiper*/}
      {/*  autoFormatSlides={true}*/}
      {/*  slides={slides}*/}
      {/*  navigation={true}*/}
      {/*  spaceBetween={32}*/}
      {/*  alternativeNavigationButtons={true}*/}
      {/*/>*/}
      <PageElementWrapper justifyContent={"space-around"}>
        <PageElement size={calendarEventSize} height={"auto"}>
          <HCPCalendarEvent
            email={teamMemberEmail}
            titleText={"Project kick-off call"}
            whenText={kickOffDateString}
            whereText={"Google Meet"}
            withText={teamMemberFullName}
            agendaText={
              "This is an opportunity for us to discuss site feasibility, floor plans, utilities, infrastructure, site placement, initial pricing, and design. We can’t wait to get started!"
            }
            status={kickOffStatus}
          />
        </PageElement>
        {designSessionDate && (
          <PageElement size={calendarEventSize} height={"auto"}>
            <HCPCalendarEvent
              email={teamMemberEmail}
              titleText={"Design session call"}
              whenText={designSessionDateString}
              whereText={"Google Meet"}
              withText={teamMemberFullName}
              agendaText={
                "We’ll review initial design selections ranging from floor plan layouts and bathroom choices to kitchen color schemes and exterior finishes. We’ll discuss pricing of upgrades and make recommendations based on our design expertise!"
              }
              status={designSessionStatus}
            />
          </PageElement>
        )}
        <PageElement size={calendarEventSize} height={"auto"}>
          <HCPCalendarEvent
            email={teamMemberEmail}
            titleText={"Presentation of PSA"}
            whenText={proposalDateString}
            whereText={"Google Meet"}
            withText={teamMemberFullName}
            agendaText={
              "Get ready for your home project to take shape! This call is a chance to learn about your estimated project range, estimated project timeline, and Villa's preconstruction services. Let's get started and make your home dreams a reality!"
            }
            status={proposalStatus}
          />
        </PageElement>
      </PageElementWrapper>
    </PortalSection>
  );
};

export const getDateStatus = (date: IEventInfo | null) => {
  const todayTimestamp = new Date().getTime();
  return date === null
    ? HCPCalendarEventStatus.not_scheduled
    : todayTimestamp < date.timestamp
    ? HCPCalendarEventStatus.scheduled_incomplete
    : HCPCalendarEventStatus.scheduled_complete;
};

const getDateString = (date: IEventInfo | null) => {
  return date === null
    ? "TBD"
    : DateUtils.getCalendarMonthAndDayAndSuffix(
        new Date(
          convertSalesforceDateToLocalTime(date.timestamp) ?? date.timestamp
        ),
        {abbreviatedMonth: true}
      );
};
