import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITag, ITagsSlice} from "./types/Store";

const getInitialState = (): ITagsSlice => {
  return {
    product_catalog_tags: {},
  };
};

const tagsSlice = createSlice({
  name: "administration",
  initialState: getInitialState(),
  reducers: {
    setProductCatalogTags: (
      state,
      action: PayloadAction<{[key: string]: ITag}>
    ) => {
      const newTags = action.payload;
      if (newTags) {
        Object.keys(newTags)?.forEach((id: string) => {
          state.product_catalog_tags[id] = newTags[id];
        });
      }
    },
  },
});

export const tagsReducer = tagsSlice.reducer;
export const {setProductCatalogTags} = tagsSlice.actions;
