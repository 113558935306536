import {NatSlider} from "../../../../_shared/generics/slider/NatSlider";
import {getKStringFromMicros} from "@natomas-org/core";
import {
  changeFilter,
  deleteFilterByType,
} from "../../../../_shared/catalog/filter/logic";
import {
  FilterType,
  ICatalogFilter,
} from "../../../../_shared/catalog/filter/types";
import React, {useMemo} from "react";
import {useUnfilteredCatalogProducts} from "../../../../_shared/hooks/useProductCatalog/useProducts";
import {useCatalogSession} from "../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";

export const PriceSlider = React.memo(
  (props: {style?: React.CSSProperties}) => {
    const {info} = useUnfilteredCatalogProducts();
    const {filters} = useCatalogSession();

    const activePriceFilter = useMemo(() => {
      const foundFilter = filters.find(
        (f: ICatalogFilter) => f.type === FilterType.UnitPriceRange
      );
      if (foundFilter) {
        return foundFilter;
      }
      return {
        type: FilterType.UnitPriceRange,
        value: {
          low: info?.lowestPrice,
          high: info?.highestPrice,
          label: `${info?.lowestPrice} - ${info?.highestPrice}`,
        },
      };
    }, [filters, info?.highestPrice, info?.lowestPrice]);

    if (!info?.lowestPrice || !info?.highestPrice) {
      return null;
    }
    return (
      <div style={{width: "100%", ...props.style}}>
        <NatSlider
          minDistanceBetweenThumbPercentInteger={20}
          label={"Unit Price"}
          bounds={[info?.lowestPrice, info?.highestPrice]}
          values={[
            activePriceFilter?.value?.low,
            activePriceFilter?.value?.high,
          ]}
          valueTextFormatter={(value) => {
            return getKStringFromMicros(value);
          }}
          disabled={false}
          setValues={(values: number[]) => {
            changeFilter({
              type: FilterType.UnitPriceRange,
              value: {
                low: values[0],
                high: values[1],
              },
              label: "Price",
            });
          }}
          resetValues={() => {
            deleteFilterByType(FilterType.UnitPriceRange);
          }}
        />
      </div>
    );
  }
);
