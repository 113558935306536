import React from "react";
import {IFactoryMarketingInfo} from "@natomas-org/core";
import {
  FeatureCollapsableContainer,
  FeatureContainer,
  FeatureContent,
  FeaturesWrapper,
} from "./styles";
import {SectionTitle, SubsectionTitle} from "../_shared/styles";
import ThemedRichTextDiv from "../_shared/ThemedRichTextDiv";
import ProductLinePageContainer from "../_shared/ProductLinePageContainer";
import {useColumnCount} from "../../../../../_shared/hooks/usePage";
import NatCollapsableContainer from "../../../../../_shared/generics/collapsable-container/NatCollapsableContainer";
import {
  Divider,
  VerticalDivider,
} from "../../../../../_shared/generics/divider";

interface SeriesSpecificationsProps {
  marketing?: IFactoryMarketingInfo;
}

const SeriesSpecifications = (props: SeriesSpecificationsProps) => {
  const {marketing} = props;
  const {isNatMobile} = useColumnCount();

  if (!marketing?.standardFeatures) {
    return null;
  }
  if (!marketing?.customFeatures) {
    return null;
  }

  if (isNatMobile) {
    return (
      <ProductLinePageContainer containerProps={{overridePadding: true}}>
        <SectionTitle style={{padding: "16px"}}>
          Series Specifications
        </SectionTitle>
        <FeatureCollapsableContainer>
          <NatCollapsableContainer
            label={"Standard Features"}
            options={{align: "right", expand: true}}
          >
            <ThemedRichTextDiv html={marketing?.standardFeatures} />
          </NatCollapsableContainer>
        </FeatureCollapsableContainer>
        <Divider />
        <FeatureCollapsableContainer>
          <NatCollapsableContainer
            label={"Custom Features"}
            options={{align: "right", expand: true}}
          >
            <ThemedRichTextDiv html={marketing?.customFeatures} />
          </NatCollapsableContainer>
        </FeatureCollapsableContainer>
      </ProductLinePageContainer>
    );
  } else {
    return (
      <ProductLinePageContainer>
        <SectionTitle>Series Specifications</SectionTitle>
        <FeaturesWrapper>
          <FeatureContainer>
            <FeatureContent>
              <SubsectionTitle>Standard Features</SubsectionTitle>
              <ThemedRichTextDiv html={marketing?.standardFeatures} />
            </FeatureContent>
          </FeatureContainer>
          <VerticalDivider />
          <FeatureContainer>
            <FeatureContent>
              <SubsectionTitle>Custom Features</SubsectionTitle>
              <ThemedRichTextDiv html={marketing?.customFeatures} />
            </FeatureContent>
          </FeatureContainer>
        </FeaturesWrapper>
      </ProductLinePageContainer>
    );
  }
};

export default SeriesSpecifications;
