import {IStore} from "../../slices/types/Store";
import {useSelector} from "react-redux";
import {useIsPublicApp} from "../useIsPublicApp";
import {Address, IPropertyData} from "@natomas-org/core";

export const usePropertyData = () => {
  const isPublicApp = useIsPublicApp();
  const {publicPropertyData, projectPropertyData} = useSelector(
    (state: IStore) => state.propertyData
  );
  return {
    publicPropertyData,
    projectPropertyData,
    catalogPropertyData: isPublicApp ? publicPropertyData : projectPropertyData,
  };
};

export const usePropertyDataWithAddressId = (
  addressId: string | null
): IPropertyData | null | undefined => {
  const {projectPropertyData, publicPropertyData} = usePropertyData();
  if (!addressId) {
    return null;
  }
  const projectPropertyDataAddressId = Address.getId(
    projectPropertyData?.address
  );
  if (projectPropertyDataAddressId === addressId) {
    return projectPropertyData;
  }
  const publicPropertyDataAddressId = Address.getId(
    publicPropertyData?.address
  );
  if (publicPropertyDataAddressId === addressId) {
    return publicPropertyData;
  }
  return null;
};
