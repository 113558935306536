import styled from "styled-components";
import {NatomasDropdown} from "../../../../_shared/generics/popup/dropdown/styles";
import {RowStatusIcon} from "../TabColumn/styles";
import {FONT_FAMILY_REGULAR} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const Container = styled.div`
  ${NatomasDropdown} {
    margin: 0 0.5rem 0 0.5rem !important;
  }
`;

export const AdminHelpContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  div,
  svg {
    margin-right: 0.5rem;
  }
`;

export const AdminHelpContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 0;
`;

export const AdminHelpBlurb = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 2rem;
  font-family: ${FONT_FAMILY_REGULAR};
  ${RowStatusIcon} {
    padding: 0 !important;
    margin: 0.5rem 1rem !important;
  }
  svg {
    padding: 0 !important;
    margin: 0.5rem 1rem !important;
  }
`;
