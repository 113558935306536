import React, {useMemo, useState} from "react";
import {IProduct} from "@natomas-org/core/";
import {ConditionAssociationsAccess} from "../ConditionAssociationsAccess";
import {ConditionAssociationsTable} from "../ConditionAssociationsTable";
import {IProductAssociationDetails} from "../../../../../_shared/interfaces";
import {InfoSetItemOptionCondition} from "../../../../../../../shared/interfaces";
import {NatMicroModal} from "../../../../../../../../_shared/generics/micro-modal";
import {useInfoSetItemOptionCondition} from "../../../../../../useInfoSetItemOptionCondition";

export const ConditionProductAssociations = (props: {
  condition: InfoSetItemOptionCondition;
  productOptions: IProduct[];
  title?: string;
}) => {
  const {condition, title, productOptions} = props;
  const {targets} = useInfoSetItemOptionCondition(condition);
  const [event, setEvent] = useState(undefined);
  const validProducts: IProductAssociationDetails[] = useMemo(() => {
    if (!productOptions || productOptions?.length === 0) {
      return [];
    }
    let valid: IProductAssociationDetails[] = [];

    targets?.map((productId: string) => {
      const product = productOptions.find((p: IProduct) => p.id === productId);
      if (product) {
        valid.push({
          product: product,
          contains: [],
          isValid: true,
        });
      }
    });
    return valid;
  }, [productOptions, targets]);

  return (
    <>
      <ConditionAssociationsAccess
        eligibleCount={validProducts?.length}
        clickEvent={(e: any) => {
          setEvent(e);
        }}
        totalCount={productOptions?.length}
      />
      <NatMicroModal
        id={`condition-${title}`}
        draggable={true}
        title={title}
        event={event}
      >
        <ConditionAssociationsTable
          products={productOptions}
          modifiers={[]}
          validProducts={validProducts}
        />
      </NatMicroModal>
    </>
  );
};
