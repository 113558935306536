import styled from "styled-components";
import {StandardElement} from "../../../_shared/generics/page/components/PageElement/styles";

export const DiscoveryForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${StandardElement} {
    margin-top: 1rem;
    text-align: center;
    margin-right: 0;
  }
`;
