import React, {useState} from "react";
import {
  DesignSelectionsPageDetail,
  DesignSelectionsPageDetailsContainer,
  DesignSelectionsPageDetailsDropdown,
  DesignSelectionsPageDetailTitle,
} from "./styles";
import {IUpgradeListDetailAlt} from "../../../../../_shared/snapshot";
import {GRAY} from "../../../../../_shared/colors";
import {priceTextFromMicros} from "@natomas-org/core";
import {useCurrentUser} from "../../../../../_shared/hooks/useCurrentUser";

const DETAIL_ROW_LIMIT = 3;
export const PageDetailsDropdown = (props: {
  details: IUpgradeListDetailAlt[];
  alwaysExpanded?: boolean;
  maxColumnsAllowed?: number;
  hideShowMore?: boolean;
}) => {
  const {details, alwaysExpanded, maxColumnsAllowed} = props;
  const {isAdmin} = useCurrentUser();
  const [expanded, setExpanded] = useState<boolean>(
    isAdmin && !!alwaysExpanded
  );
  let detailItemLimit = DETAIL_ROW_LIMIT;
  if (maxColumnsAllowed) {
    detailItemLimit = DETAIL_ROW_LIMIT * (maxColumnsAllowed - 1);
  }

  const getDetail = (title: string, index: number, price?: string) => {
    const label = title + (price ? ": " + price : "");
    return (
      <DesignSelectionsPageDetail
        key={"upgrade-detail-" + index}
        maxColumnCount={maxColumnsAllowed ?? 1}
      >
        {label}
      </DesignSelectionsPageDetail>
    );
  };

  const upgradeLineItems = details.filter(
    (d: IUpgradeListDetailAlt) => d.price > 0
  );
  const includedLineItems = details.filter(
    (d: IUpgradeListDetailAlt) => d.price === 0
  );

  const getSlideDetails = (
    title: string,
    d: IUpgradeListDetailAlt[],
    hidePrice?: boolean,
    previousItems?: number
  ) => {
    if (d.length === 0) {
      return null;
    }

    // Adjusting to base 0
    const detailLimit = expanded
      ? Infinity
      : previousItems
      ? detailItemLimit - (previousItems ?? 0) - (maxColumnsAllowed ?? 1) + 1
      : detailItemLimit;
    const count = d.length;
    // Determining when we need to put shortened description
    const isShortened = count > detailLimit;

    return (
      <>
        <DesignSelectionsPageDetailTitle>
          {title}
        </DesignSelectionsPageDetailTitle>

        <>
          {d.map((upgrade: IUpgradeListDetailAlt, index: number) => {
            if (
              index < detailLimit ||
              (index === detailLimit && !isShortened)
            ) {
              return getDetail(
                upgrade.title ? `\u2022 ${upgrade.title}` : "",
                index,
                !hidePrice
                  ? priceTextFromMicros(upgrade?.price, "accurate")
                  : undefined
              );
            } else if (index === detailLimit && isShortened) {
              return (
                <DesignSelectionsPageDetail
                  maxColumnCount={1}
                  color={GRAY}
                  key={"upgrade-detail-end"}
                >
                  and {d.length - detailLimit} more...
                </DesignSelectionsPageDetail>
              );
            } else {
              return null;
            }
          })}
        </>
      </>
    );
  };

  const getSlideUpgradedDetails = () => {
    return getSlideDetails("Upgrades", upgradeLineItems);
  };

  const getSlideIncludedDetails = () => {
    return getSlideDetails(
      "Included",
      includedLineItems,
      true,
      upgradeLineItems.length
    );
  };
  return (
    <>
      <DesignSelectionsPageDetailsContainer expanded={!!alwaysExpanded}>
        {getSlideUpgradedDetails()}
        {getSlideIncludedDetails()}
      </DesignSelectionsPageDetailsContainer>
      {upgradeLineItems.length + includedLineItems.length > detailItemLimit && (
        <DesignSelectionsPageDetailsDropdown
          onClick={() => setExpanded(!expanded)}
          expanded={expanded}
          hidden={props.hideShowMore}
        >
          {expanded ? "Show less" : "Show more"}
        </DesignSelectionsPageDetailsDropdown>
      )}
    </>
  );
};
