import React, {useMemo} from "react";
import {BudgetEntry} from "@natomas-org/core";
import {
  GRAY_BORDER,
  VILLA_APPLE_GREEN_LIGHT_2,
  WHITE,
} from "../../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../SSDDashboardView/styles/globals";
import {TableRow} from "./TableRow";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {RowContainer, TableContainer} from "../../styles/SiteWorkTableStyles";
import {GenericLoadingComponent} from "./GenericLoadingComponent";

interface GenericBudgetRowsProps {
  includeDescription: boolean;
  visibleTable?: BudgetEntry[];
}

const GenericBudgetRows = (props: GenericBudgetRowsProps) => {
  const {visibleTable, includeDescription} = props;
  const {isNatMobile} = usePage();

  const rows = useMemo(() => {
    if (!visibleTable) return null;
    return visibleTable?.map((row: BudgetEntry, index: number) => {
      if (!row || !row.price?.price || row?.price?.price === 0) {
        return <></>;
      }
      const style: React.CSSProperties = {
        backgroundColor: row.isTotal()
          ? VILLA_APPLE_GREEN_LIGHT_2
          : row.isHeader() || row.isSubtotal()
          ? GRAY_BORDER
          : WHITE,
        fontFamily: row.isBold() ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR,
      };

      return (
        <RowContainer
          key={"row-" + index}
          isNatMobile={isNatMobile}
          style={style}
        >
          <TableRow
            data={row}
            style={style}
            displayProps={{displayDescription: includeDescription}}
          />
        </RowContainer>
      );
    });
  }, [includeDescription, isNatMobile, visibleTable]);

  if (!visibleTable || !rows) {
    return <GenericLoadingComponent />;
  } else {
    return (
      <TableContainer>
        {rows?.map((row: JSX.Element, index: number) => {
          return <React.Fragment key={"row-" + index}>{row}</React.Fragment>;
        })}
      </TableContainer>
    );
  }
};

export default GenericBudgetRows;
