import React, {useCallback, useMemo, useState} from "react";
import {IFactoryInventoryStudioEditorPopupPathOptions} from "../interface";
import {StudioEditorFieldType} from "../../_shared/StudioEditorFieldAddButton";
import {useFactoryLine} from "../../../../../../../../../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {
  ImportConfirmationContainer,
  ImportLibraryContainer,
  ImportLibraryList,
  ImportLibraryListContainer,
  ImportLibraryListItem,
  ImportLibraryListItemPreview,
  ImportLibraryListItemPreviewContainer,
  ImportLibraryListSection,
  ImportLibraryListSectionController,
} from "./styles";
import {getFormikPath} from "../helper";
import {NatButton} from "../../../../../../../../../../../../_shared/generics/button";
import {
  getFormikPathForImport,
  getSelectedImportValue,
  getTargetIndex,
} from "./helper";
import ImportPreview from "./ImportPreview";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../../_shared/generics/_shared";
import {NatEditIcon} from "../../../../../../../../../../../../_shared/icon/icons";
import {
  getInternalTitle,
  getTitle,
} from "../../../../../../../../../../../ProductLineView/logic/helpers";
import {NatToggleSelector} from "../../../../../../../../../../../../_shared/generics/toggle-selector";

interface FactoryImportLibraryProps {
  reset: () => void;
  path?: IFactoryInventoryStudioEditorPopupPathOptions;
  type?: StudioEditorFieldType;
  formik?: any;
  factoryId?: string;
}

const FactoryImportLibrary = (props: FactoryImportLibraryProps) => {
  const {type, path, formik, factoryId, reset} = props;

  const {categories, modifierGroups, modifiers} = useFactoryLine({factoryId});

  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [byInternalTitle, setByInternalTitle] = useState<boolean>(false);

  const setCurrentValue = useCallback(
    (importPath: IFactoryInventoryStudioEditorPopupPathOptions, value: any) => {
      const currentPath = getFormikPath(importPath);
      formik.setFieldValue(currentPath, value);
      reset();
    },
    [formik, reset]
  );

  const activeLibrary = useMemo(() => {
    if (type === StudioEditorFieldType.MODIFIER) {
      return modifiers;
    } else if (type === StudioEditorFieldType.MODIFIER_GROUP) {
      return modifierGroups;
    } else if (type === StudioEditorFieldType.CATEGORY) {
      return categories;
    } else {
      return {};
    }
  }, [type, modifiers, modifierGroups, categories]);

  const items = useMemo(() => {
    return Object.values(activeLibrary ?? {})
      ?.map((value: any) => {
        return {
          id: value?.id,
          title: getTitle(value),
          internalTitle: getInternalTitle(value),
        };
      })
      ?.sort((a, b) => {
        if (byInternalTitle) {
          return a?.internalTitle?.localeCompare(b?.internalTitle);
        } else {
          return a?.title?.localeCompare(b?.title);
        }
      });
  }, [activeLibrary, byInternalTitle]);

  const targetIndex = useMemo(() => {
    return getTargetIndex(formik.values, type, path);
  }, [formik.values, type, path]);

  const selectedValue = useMemo(() => {
    return getSelectedImportValue(
      type,
      selectedId,
      targetIndex,
      activeLibrary,
      modifierGroups,
      modifiers
    );
  }, [modifierGroups, modifiers, selectedId, targetIndex, type, activeLibrary]);

  const importPath = useMemo(() => {
    return getFormikPathForImport(selectedValue?.id, type, path);
  }, [path, selectedValue?.id, type]);

  return (
    <ImportLibraryContainer>
      <ImportLibraryListSection>
        <ImportLibraryListSectionController>
          <NatToggleSelector
            options={[
              {
                id: "internal",
                label: "Internal",
                isSelected: byInternalTitle,
                clickEvent: () => setByInternalTitle(true),
              },
              {
                id: "display",
                label: "Display",
                isSelected: !byInternalTitle,
                clickEvent: () => setByInternalTitle(false),
              },
            ]}
          />
        </ImportLibraryListSectionController>
        <ImportLibraryListContainer>
          <ImportLibraryList>
            {items?.map((item: any, index: number) => {
              return (
                <ImportLibraryListItem
                  isSelected={item?.id === selectedId}
                  key={`list-item-${index}`}
                  onClick={() => setSelectedId(item?.id)}
                >
                  {byInternalTitle ? item?.internalTitle : item?.title}
                </ImportLibraryListItem>
              );
            })}
          </ImportLibraryList>
        </ImportLibraryListContainer>
      </ImportLibraryListSection>
      <ImportLibraryListItemPreviewContainer>
        <ImportLibraryListItemPreview hidden={!selectedValue}>
          <ImportPreview value={selectedValue} type={type} />
        </ImportLibraryListItemPreview>
      </ImportLibraryListItemPreviewContainer>
      <ImportConfirmationContainer>
        <NatButton
          label={`Import`}
          size={NatSize.SMALL}
          option={StyleOption.ADMIN_SOLID}
          icon={{
            icon: <NatEditIcon />,
            iconPosition: IconPosition.LEFT,
          }}
          clickEvent={() => {
            if (importPath) {
              setCurrentValue(importPath, selectedValue);
            }
          }}
          hidden={!importPath || !selectedValue}
        />
      </ImportConfirmationContainer>
    </ImportLibraryContainer>
  );
};

export default FactoryImportLibrary;
