import React, {useEffect, useMemo, useState} from "react";
import {LoadingPanel} from "../loading-panel";
import Logo from "../../../../assets/logos/villa-logo-white.png";
import {NatButton, NatomasButtonProps} from "../button";
import {
  EmptyPageActions,
  EmptyPageContentContainer,
  EmptyPageTitle,
} from "./styles";

export const EmptyPage = (props: {
  title?: string;
  buttons?: NatomasButtonProps[];
  children?: React.ReactNode;
}) => {
  const {title, buttons, children} = props;
  const [wait, setWait] = useState(true);

  // Pseudo wait for initializing
  // Rather than immediately showing CTA to catalog,
  // wait a bit and see if we truly need to!

  useEffect(() => {
    setTimeout(() => setWait(false), 3000);
  }, []);

  const pageTitle = useMemo(() => {
    return title ?? "404";
  }, [title]);

  if (wait) {
    return <LoadingPanel show={true} />;
  }

  return (
    <>
      <EmptyPageContentContainer>
        <img src={Logo} style={{height: "5rem", width: "auto"}} alt={"Villa"} />
        <EmptyPageTitle>{pageTitle}</EmptyPageTitle>
        <EmptyPageActions>
          {buttons?.map((button: NatomasButtonProps) => {
            return <NatButton {...button} />;
          })}
        </EmptyPageActions>
        {children}
      </EmptyPageContentContainer>
    </>
  );
};
