import React, {useMemo} from "react";
import useCustomerProjects from "../../../../../_shared/hooks/useProject/useCustomerProjects";
import useActivitySlice from "../../../../../_shared/hooks/useActivity/useActivitySlice";
import CartProjectSection from "./index";
import CartSectionWrapper from "../CartSection/CartSectionWrapper";
import {Divider} from "../../../../../_shared/generics/divider";
import {CartActionsAvailable} from "../../interfaces";

const CartItemProjectSections = (props: {
  actionsAvailable: CartActionsAvailable;
}) => {
  const {actionsAvailable} = props;
  const {activeCustomerId, activeProjectId} = useActivitySlice();
  const {projectIds} = useCustomerProjects(activeCustomerId);

  const otherProjectIds = useMemo(() => {
    return projectIds?.filter((id) => id !== activeProjectId) ?? [];
  }, [projectIds, activeProjectId]);

  if (!otherProjectIds?.length) {
    return null;
  }

  return (
    <>
      <Divider />
      <CartSectionWrapper willExpand={true} hasFrame={true}>
        {otherProjectIds?.map((pid: string, index: number) => {
          return (
            <>
              <CartProjectSection
                actionsAvailable={actionsAvailable}
                projectId={pid}
                key={`cart-project-section-${index}`}
              />
            </>
          );
        })}
      </CartSectionWrapper>
    </>
  );
};

export default CartItemProjectSections;
