export const SHOW_COLOR_SWATCHES_PROPERTY_KEY = "showColorSwatches";
export const SHOW_COLOR_SWATCHES_DISPLAY_KEY = SHOW_COLOR_SWATCHES_PROPERTY_KEY;

export const SHOW_MULTI_SELECT_PROPERTY_KEY = "multiSelectKey";
export const SHOW_MULTI_SECENT_DISPLAY_KEY = SHOW_MULTI_SELECT_PROPERTY_KEY;

export const SHOW_FULL_TEXT_PROPERTY_KEY = "showFullText";
export const SHOW_FULL_TEXT_DISPLAY_KEY = SHOW_FULL_TEXT_PROPERTY_KEY;

export const SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY = "showFullColor";
export const SUMMARY_STEP_ID = "summary";
