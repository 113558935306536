import React from "react";
import {NatButton} from "../../../../_shared/generics/button";
import {DeveloperViewContainer} from "../_shared/DeveloperViewContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {ElementContainer, ElementContainerRow} from "../../styles";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {BLACK, VILLA_BLUE, WHITE} from "../../../../_shared/colors";
import {HiEmojiHappy} from "react-icons/hi";
import {getTooltip} from "../_shared/helper";

const ButtonRows = (props: {option: StyleOption}) => {
  return (
    <ElementContainerRow>
      {Object.keys(NatSize).map((key: string) => {
        // @ts-ignore
        const size: NatSize = NatSize?.[key];
        const tooltip = getTooltip(props.option, size, "icon");
        return (
          <NatButton
            label={""}
            icon={{
              icon: <HiEmojiHappy />,
              iconPosition: IconPosition.ONLY,
            }}
            id={tooltip?.id}
            tooltip={tooltip?.label}
            size={size}
            type={"button"}
            trackingId={"null"}
            option={props.option}
          />
        );
      })}
    </ElementContainerRow>
  );
};

const Buttons = (props: {backgroundColor: string}) => {
  return (
    <PageElement size={4} height={"auto"}>
      <ElementContainer backgroundColor={props.backgroundColor}>
        {Object.keys(StyleOption)
          .filter((k: any) => isNaN(k))
          .map((key: any) => {
            // @ts-ignore
            return <ButtonRows option={StyleOption?.[key]} />;
          })}
      </ElementContainer>
    </PageElement>
  );
};

export const NatIconButtonSection = () => {
  return (
    <DeveloperViewContainer title={"NatButtons (Icon Only)"}>
      <Buttons backgroundColor={WHITE} />
      <Buttons backgroundColor={BLACK} />
      <Buttons backgroundColor={VILLA_BLUE} />
    </DeveloperViewContainer>
  );
};
