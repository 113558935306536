import React, {useMemo} from "react";
import {NatFlex} from "../../../../_shared/generics/flex/NatFlex";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {VILLA_APPLE_GREEN} from "../../../../_shared/colors";
import {NatVerticalAlign} from "../../../../_shared/generics/_shared";
import {TIMELINE_PDF_SCALAR} from "../../ProposalBudgetView/components/Presentation/B2BTimelineSlide/B2BTimelineSlide";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {
  B2B_TIMELINE_INFO,
  GENERIC_TIMELINE_INFO,
} from "../constants/TimelineInfoConstants";
import {IStepMilestone} from "../constants/TimelineTypes";
import {chunkArray} from "../constants/timelineHelpers";

export const PaymentScheduleInfo = (props: {pdfRenderFormat?: boolean}) => {
  let counter = 0;
  const {isCartEnabled} = useActiveProject();
  const dataToDisplay = useMemo(() => {
    let baseData = GENERIC_TIMELINE_INFO;
    if (isCartEnabled) {
      baseData = B2B_TIMELINE_INFO;
    }
    const milestones: IStepMilestone[] = [];
    baseData.forEach((nextStepInfo, index) => {
      const subMilestones = nextStepInfo.milestones;
      if (subMilestones) {
        subMilestones.forEach((subMilestone, subIndex) => {
          milestones.push(subMilestone);
        });
      }
    });
    const reducedArray: {title: string; info: string; greenText: string}[] = [];
    for (let i = 0; i < milestones.length; i++) {
      reducedArray.push({
        title: milestones[i].title,
        info: milestones[i].description,
        greenText: milestones[i].footer,
      });
    }
    return chunkArray(reducedArray) as {
      title: string;
      info: string;
      greenText: string;
    }[][];
  }, [isCartEnabled]);

  const {pdfRenderFormat} = props;
  return (
    <NatFlex
      style={{
        width: `${100 / TIMELINE_PDF_SCALAR}%`,
        backgroundColor: "rgb(250, 250, 248)",
        padding: "2rem 2rem",
        transform: pdfRenderFormat
          ? `scale(${TIMELINE_PDF_SCALAR})`
          : undefined,
        transformOrigin: pdfRenderFormat ? "top left" : undefined,
        // width: pdfRenderFormat ? `${100 / TIMELINE_PDF_SCALAR}%` : "100%",
        height: "100%",
      }}
      ROW
    >
      <NatLabel label={"Payment Schedule"} type={NatLabelType.H2} />
      {dataToDisplay.map((nextStepInfo, index) => {
        return (
          <NatFlex FULL_WIDTH ROW NOWRAP>
            {nextStepInfo.map((item, idx) => {
              counter++;
              return (
                <NatFlex
                  ROW
                  NOWRAP
                  FULL_WIDTH
                  VERTICAL_ALIGN={NatVerticalAlign.TOP}
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <div
                    style={{
                      marginTop: "0.25rem",
                      backgroundColor: VILLA_APPLE_GREEN,
                      borderRadius: "50%",
                      minHeight: "1.5rem",
                      minWidth: "1.5rem",
                      maxHeight: "1.5rem",
                      maxWidth: "1.5rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {counter}
                  </div>
                  <NatFlex
                    FULL_WIDTH
                    COLUMN
                    style={{rowGap: pdfRenderFormat ? "0.15rem" : "0.5rem"}}
                  >
                    <NatLabel
                      label={item.title}
                      type={NatLabelType.H4}
                      style={{margin: "0 0"}}
                    />
                    <NatLabel
                      style={{margin: "0 0"}}
                      label={item.info}
                      type={NatLabelType.P3}
                    />
                    <NatLabel
                      style={{margin: "0 0", color: VILLA_APPLE_GREEN}}
                      label={item.greenText}
                      type={NatLabelType.P3}
                    />
                  </NatFlex>
                </NatFlex>
              );
            })}
          </NatFlex>
        );
      })}
    </NatFlex>
  );
};
