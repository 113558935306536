import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {NatModal} from "../../../../../_shared/generics/modal/NatModal";
import {LoadingSpinner} from "../../../../../mapping/views/components/Common/LoadingSpinner";
import {publishFactoryLineDraft} from "../../../../../../database/firebase/api/admin";
import {NatomasButtonProps} from "../../../../../_shared/generics/button";
import {useSelectedFactoryLine} from "../../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";

export interface PublishWarningModalProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const PublishWarningModal = (props: PublishWarningModalProps) => {
  const {show, setShow} = props;
  const {details} = useSelectedFactoryLine();
  const [saving, setSaving] = useState<boolean>(false);

  const content = useMemo(() => {
    if (saving) {
      return (
        <div>
          <LoadingSpinner isFullScreen={false} />
        </div>
      );
    }
    return (
      <>
        <div>
          Publishing will update the {details?.internal_title} ({details?.title}
          ) to the match the current draft.
        </div>
        <div>
          Ensure that you have made all the changes you would like to, otherwise
          continue making edits to the draft version.
        </div>
        <div>
          Please be aware that all archived products, categories, modifiers, and
          modifier groups will be permanently deleted upon publishing!
        </div>
      </>
    );
  }, [saving, details]);

  const footerButtons: NatomasButtonProps[] | undefined = useMemo(() => {
    if (!saving && details?.id) {
      return [
        {
          label: "Abort",
          type: "button",
          option: StyleOption.DESTRUCTIVE,
          clickEvent: () => setShow(false),
        },
        {
          label: `Yes, Publish v${details?.version} to the Catalog`,
          type: "button",
          option: StyleOption.PRIMARY_ALT,
          clickEvent: () => {
            setSaving(true);
            return publishFactoryLineDraft(details?.id).then(() => {
              setSaving(false);
              setShow(false);
              return null;
            });
          },
        },
      ];
    }
    return undefined;
  }, [saving, details?.version, details?.id, setShow]);

  const label = useMemo(() => {
    let label = `${details?.internal_title} v${details?.version}`;
    if (saving) {
      return `Publishing ${label}...`;
    } else {
      return `Publish ${label}?`;
    }
  }, [details, saving]);

  return (
    <NatModal
      show={show}
      centeredContent={true}
      content={content}
      header={<>{label}</>}
      footerButtons={footerButtons}
    />
  );
};
