import React from "react";
import {IDocumentDetails} from "@natomas-org/core";
import {ISendDocusignProps} from "../../constants";
import {
  getPDFResolutionString,
  isDocusignDocumentDetailsResolutionValid,
} from "../../helpers";
import {AiFillCheckCircle, AiFillExclamationCircle} from "react-icons/ai";
import {ResolutionValidityTagContainer} from "./styles";

export const ResolutionValidityTag = (props: {
  documentDetails: IDocumentDetails;
  initialProps: ISendDocusignProps;
}) => {
  const {documentDetails, initialProps} = props;
  const pdfResolutionString = getPDFResolutionString(documentDetails);
  if (!pdfResolutionString) {
    // If info is unknown, don't render component
    return <></>;
  }
  const isResolutionValid = isDocusignDocumentDetailsResolutionValid(
    documentDetails,
    initialProps
  );

  if (isResolutionValid) {
    return (
      <ResolutionValidityTagContainer>
        <AiFillCheckCircle style={{color: "#82C275"}} />
        {`${pdfResolutionString} fits expected resolution.`}
      </ResolutionValidityTagContainer>
    );
  }

  return (
    <ResolutionValidityTagContainer>
      <AiFillExclamationCircle style={{color: "red"}} />
      {`${pdfResolutionString} does not fit expected resolution.`}
    </ResolutionValidityTagContainer>
  );
};
