import React from "react";
import {IHCPViewProps} from "../interfaces";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {PageElementWrapper} from "../../../../_shared/generics/page/components/PageElementWrapper";
import {PortalDocumentsView} from "../../FPDashboardLayout/FPDocumentsAndTasksView/components/FPDocumentsView";
import {PORTAL_DOCUMENTS_ID} from "../HCPTitleNav/navigation_ids";
import {HCPComponentHR} from "../styles";
import {useActiveProjectDocuments} from "../../../../_shared/hooks/useProject/useActiveProjectDocuments";

export const HCPDocumentsAndTasksView = (props: IHCPViewProps) => {
  const {projectDocuments} = useActiveProjectDocuments();
  const {width} = props;

  if (!projectDocuments) {
    return null;
  }
  if (projectDocuments?.length === 0) {
    return null;
  }

  return (
    <PageContainer>
      <div id={PORTAL_DOCUMENTS_ID} />
      <HCPComponentHR />
      <PageElementWrapper alignItems={"flex-start"}>
        <PageElement size={width} height={"auto"}>
          <PortalDocumentsView />
        </PageElement>
      </PageElementWrapper>
    </PageContainer>
  );
};
