import {
  CreateLeadSchema,
  LeadsApi,
  UpdateLeadQuestionsSchema,
} from "@natomas-org/villa-nexus-client";
import {AxiosInstance} from "axios";
import {getAxiosInstance} from "../setup";
import LogRocket from "logrocket";

export class LeadService {
  private axiosInstance;
  private client;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new LeadsApi(undefined, undefined, this.axiosInstance);
  }

  async getLeadExists() {
    try {
      // Will error with status 409 if lead exists
      await this.client.checkIfLeadExists();
      return false;
    } catch (error) {
      // @ts-ignore - Lead exists with collision check returns a 409 status code
      if (error?.response?.status === 409) {
        return true;
        // @ts-ignore - Failed authenticated
      } else if (error?.response?.status === 401) {
        LogRocket.error("LeadServiceError", error);
      }
      return false;
    }
  }

  async create(schema: CreateLeadSchema) {
    if (!schema.auth_user_id) {
      throw new Error("createLead requires auth_user_id");
    }
    const formatPhoneNumber = (preformatted: string): string => {
      // Remove all non-numeric characters
      const cleanedPhoneNumber = preformatted.replace(/\D/g, "");
      // Get the last 10 digits (remove the country code)
      const phoneNumber = cleanedPhoneNumber.substring(
        cleanedPhoneNumber.length - 10
      );
      // Apply the format
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    };
    return this.client.createLead({
      first_name: schema.first_name,
      last_name: schema.last_name,
      email: schema.email,
      auth_user_id: schema.auth_user_id,
      created_from: schema.created_from,
      lead_segment: schema.lead_segment,
      phone_number: formatPhoneNumber(schema.phone_number as string),
    });
  }

  async update(schema: UpdateLeadQuestionsSchema) {
    return this.client.updateLeadQuestions(schema);
  }
}

export const getLeadService = async () => {
  // LeadService does not require authentication to use
  const axiosInstance = await getAxiosInstance({skipAuth: false});
  return new LeadService(axiosInstance);
};
