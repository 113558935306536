import {Address, ProjectPriceMode} from "@natomas-org/core";
import React, {useContext, useMemo, useState} from "react";
import styled from "styled-components";
import {NatButton, NatLink} from "../../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../../_shared/generics/_shared";
import {HiOutlineChevronDown, HiOutlineChevronUp} from "react-icons/hi";
import {
  PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS,
  PORTAL_BUDGET_ID,
  PORTAL_BUDGET_OVERVIEW_ID,
  usePresentationSections,
} from "../../../HCPTitleNav/navigation_ids";
import {useEventHandler} from "../../../../../../_shared/hooks/useEventHandler";
import {scrollToTop} from "../../../../../../_shared/navigation/_helpers";
import {FOOTER_Z_INDEX} from "../../../../../../_shared/styles";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";
import {usePage} from "../../../../../../_shared/hooks/usePage";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../../_shared/hooks/useDesign/useActiveDesign";
import {EMBEDDED_PROPOSAL} from "./constants";

export const HCPPresentationFooter = (props: IHcpPresentationFooterProps) => {
  const {readyToPrint, setReadyToPrint} = props;
  const {
    address,
    projectPriceMode,
    isDevelopmentByVilla,
    mergedCustomerProjectInfos,
    site,
  } = useActiveProject();
  const {configuration} = useActiveDesign();
  const {product} = configuration ?? {};
  let filteredSections = usePresentationSections();
  const sections = useMemo(() => {
    if (isDevelopmentByVilla) {
      filteredSections = filteredSections.filter((section) => {
        if (
          !mergedCustomerProjectInfos?.metadata?.qualificationsAndAssumptions
        ) {
          return section.value !== PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS;
        } else {
          return !(
            !site?.visible && section.value === PORTAL_BUDGET_OVERVIEW_ID
          );
        }
      });
    }
    return filteredSections.filter((section) => {
      return !(!site?.visible && section.value === PORTAL_BUDGET_ID);
    });
  }, [
    isDevelopmentByVilla,
    mergedCustomerProjectInfos?.metadata?.qualificationsAndAssumptions,
    site?.visible,
  ]);
  const {isNatMobile} = usePage();
  const embeddedProposal = useContext(EMBEDDED_PROPOSAL);

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const getCurrentIndex = () => {
    const headerHeight = 64;
    let minDistance = Infinity;
    let newIndex = 0;
    sections?.forEach((option, index: number) => {
      const doc = document
        .getElementById(option?.value)
        ?.getBoundingClientRect();
      if (doc) {
        let docDistance = Math.abs(doc.y - headerHeight);
        if (docDistance < minDistance) {
          newIndex = index;
          minDistance = docDistance;
        }
      }
    });
    setCurrentIndex(newIndex);
  };
  useEventHandler("scroll", getCurrentIndex);

  const navigateToSection = (offset: number) => {
    const newIndex = Math.max(
      Math.min(currentIndex + offset, sections.length - 1),
      0
    );
    const option = sections[newIndex];

    if (option?.value === undefined) {
      scrollToTop(true);
    } else {
      props.scrollToSection(option?.value);
    }
  };

  if (!address) {
    return null;
  }

  return (
    <Container hidden={readyToPrint || embeddedProposal}>
      <LeftContent>
        <NatLabel
          hidden={projectPriceMode === ProjectPriceMode.CostPlus}
          label={product?.title ?? ""}
          type={NatLabelType.P1}
          style={{margin: 0}}
        />
        <NatLabel
          label={
            isNatMobile
              ? Address.getStreetNumberAndStreet(address)
              : Address.getStreetAndCity(address)
          }
          type={NatLabelType.P1}
          style={{margin: 0}}
        />
      </LeftContent>
      <RightContent>
        <NatButton
          hidden={true} // Disabling for now
          size={NatSize.SMALL}
          option={StyleOption.SECONDARY}
          label={"Save PDF"}
          clickEvent={() => {
            // this will clean up the UI and prepare the page for printing
            setReadyToPrint(true);

            // images are lazily loaded, so we need to scroll throughout the page before printing
            const totalTime = 2000;
            const totalScrolls = 15;

            // this will scroll at regular intervals to the bottom of the page
            for (let i = 0; i < totalScrolls; i++) {
              const top =
                (document.documentElement.scrollHeight * i) / totalScrolls;
              setTimeout(() => {
                window.scrollTo({
                  top: top,
                });
              }, (totalTime * i) / totalScrolls);
            }

            // this will print the page after the scrolling is done
            setTimeout(() => {
              try {
                if (!document.execCommand("print", false, undefined)) {
                  window.print();
                }
              } catch {
                window.print();
              }
              setReadyToPrint(false);
            }, totalTime);
          }}
        />
        <NatButton
          size={NatSize.SMALL}
          option={StyleOption.PRIMARY}
          {...getFooterButtonProps(
            currentIndex,
            props.scrollToSection,
            projectPriceMode
          )}
        />
        <Stepper>
          <NatLink
            clickEvent={() => {
              navigateToSection(-1);
            }}
            label={""}
            icon={<HiOutlineChevronUp />}
          />
          <div>
            {currentIndex + 1} / {sections.length}
          </div>
          <NatLink
            clickEvent={() => {
              navigateToSection(1);
            }}
            label={""}
            icon={<HiOutlineChevronDown />}
          />
        </Stepper>
      </RightContent>
    </Container>
  );
};

const getFooterButtonProps = (
  currentIndex: number,
  scrollToSection: (sectionId: string) => void,
  priceMode: ProjectPriceMode
) => {
  if (priceMode === ProjectPriceMode.CostPlus || currentIndex >= 3) {
    return {hidden: true, label: ""};
  }
  return {
    label: "Skip to proposal",
    clickEvent: () => {
      scrollToSection(PORTAL_BUDGET_ID);
    },
  };
};

const Container = styled.div`
  padding: 1rem 2rem;
  background-color: white;
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: ${FOOTER_Z_INDEX};
  height: 80px;
`;

const LeftContent = styled.div`
  color: gray;
  font-size: 1.2rem;
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
`;

const Stepper = styled.div`
  text-align: center;
`;

interface IHcpPresentationFooterProps {
  readyToPrint: boolean;
  setReadyToPrint: (readyToPrint: boolean) => void;
  scrollToSection: (sectionId: string) => void;
  pagesToPrint?: number;
}
