import styled from "styled-components";
import {
  VILLA_APPLE_GREEN,
  VILLA_FOREST_GREEN,
} from "../../../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const SSCSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  flex-grow: 1;
`;

export const SSCSelectorExploreLink = styled.div`
  color: ${VILLA_FOREST_GREEN};
  text-decoration: underline;
  cursor: pointer;
  font-family: ${FONT_FAMILY_MEDIUM};
  &:hover {
    color: ${VILLA_APPLE_GREEN};
  }
`;

export const SSCTitleDiv = styled.div`
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 16px;
`;
