import styled from "styled-components";
import {GRAY} from "../../../../../../../_shared/colors";
import {FONT_FAMILY_BOLD} from "../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const ProjectNoteFormWrapper = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const ProjectNoteFormContainer = styled.div`
  border: 1px solid ${GRAY};
  border-radius: 1rem;
  padding: 1rem;
`;

export const ProjectNoteFormTitle = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 1.5rem;
`;

export const ProjectNoteFormFooter = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  position: sticky;
  bottom: -1rem;
  background-color: white;
  border-top: 1px solid ${GRAY};
  padding: 1rem 0;
`;
