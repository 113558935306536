import styled from "styled-components";

export const GoogleMapsContainer = styled.div`
  height: 24rem;
  max-width: 40rem;
  min-width: 14rem;
  padding-bottom: 1rem;
`;
export const GoogleMapsPinContainer = styled.div`
  position: absolute;
  top: calc(100% + 4rem);
  left: 50%;
  transform: translate(-50%, -100%);
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  justify-items: center;
`;
export const GoogleMapsPinLabel = styled.div`
  width: 18rem;
  height: 4rem;
  background-color: white;
  padding: 1rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
`;
export const GoogleMapsPin = styled.img`
  cursor: pointer;
  width: 36px;
  height: 40px;
  padding-bottom: 4px;
`;
