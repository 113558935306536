import {CardTag} from "../styles";
import React from "react";
import {INVENTORY_UNIT_TAG_TEXT} from "../../../../../_shared/constants/labels";
import {VILLA_SLATE} from "../../../../../_shared/colors";

export const InventoryTag: React.FC = () => {
  return (
    <CardTag
      key={`inventory-tag`}
      style={{backgroundColor: VILLA_SLATE, color: "white"}}
    >
      {INVENTORY_UNIT_TAG_TEXT}
    </CardTag>
  );
};
