import React from "react";
import {QuickInfoSectionTitle} from "../styles";
import {InfoItem} from "../../../../../_shared/InfoItem";
import {IPropertyData} from "@natomas-org/core";

export const ProjectParcelDataSection = (props: {
  propertyData: IPropertyData | undefined;
}) => {
  const {propertyData} = props;
  const getValue = (value?: any | null) => {
    if (value === null || value === "") {
      return "Unknown";
    } else if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    }
    return value;
  };

  if (!propertyData?.propertyDetails) {
    return (
      <>
        <QuickInfoSectionTitle>Feasibility</QuickInfoSectionTitle>
        <InfoItem
          label={"Status"}
          value={"No parcel data determined."}
          description={
            "If possible, parcel data is fetched when customer starts project journey."
          }
        />
      </>
    );
  } else {
    return (
      <>
        <QuickInfoSectionTitle>Parcel Data</QuickInfoSectionTitle>
        <InfoItem
          label={"Building Size (SqFt)"}
          value={getValue(propertyData?.propertyDetails?.buildingSqFt)}
          description={"Retrieved record of building footprint size."}
        />
        <InfoItem
          label={"Total Land Size (SqFt)"}
          value={getValue(propertyData?.propertyDetails?.assessorLotSqFt)}
          description={"Assessor record of lot square footage."}
        />
        <InfoItem
          label={"Year Built"}
          value={getValue(propertyData?.propertyDetails?.yearBuilt)}
          description={"Record of when primary residence was built."}
        />
        <InfoItem
          label={"Number of Parking Space"}
          value={getValue(propertyData?.propertyDetails?.parkingSpaceCount)}
          description={"Record of parking space count."}
        />
        <InfoItem
          label={"Number of Stories"}
          value={getValue(propertyData?.propertyDetails?.numberOfStories)}
          description={"Record of primary building story count."}
        />
        <InfoItem
          label={"Owner Name"}
          value={`${getValue(
            propertyData?.propertyDetails?.ownerFirstName
          )} ${getValue(propertyData?.propertyDetails?.ownerLastName)}`}
          description={`Owner name on record as of ${propertyData?.propertyDetails?.createdDate}.`}
        />
        <InfoItem
          label={"Owner Occupied"}
          value={getValue(propertyData?.propertyDetails?.ownerOccupied)}
          description={`Do the mailing address and site address match?`}
        />
        <InfoItem
          label={"Date of Last Sale"}
          value={`${getValue(propertyData?.propertyDetails?.dateTransfer)}`}
          description={`Last recorded sale date before ${propertyData?.propertyDetails?.createdDate}.`}
        />
        <InfoItem
          label={"Assessor Parcel Number"}
          value={getValue(propertyData?.propertyDetails?.assessorParcelNumber)}
          description={"Record of APN."}
        />
      </>
    );
  }
};
