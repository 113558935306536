import React from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";
import {ProductOptionKey} from "../../../../_shared/constants/ProductOption";
import {
  NewOptionContainer,
  OptionList,
  OptionListContainer,
  OptionRow,
} from "../styles";
import {NatButton} from "../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";

export const OptionKeyList = (props: {
  currentKey: ProductOptionKey | null;
  setCurrentKey: any;
}) => {
  const {setCurrentKey, currentKey} = props;
  const optionKeys = useSelector((state: IStore) => state.options.optionKeys);
  const formattedKeys: ProductOptionKey[] = Object.values(optionKeys);
  const sortedOptionKeys = formattedKeys.sort(
    (a: ProductOptionKey, b: ProductOptionKey) => {
      let nameA = a.name.toLowerCase();
      let nameB = b.name.toLowerCase();
      return nameA.localeCompare(nameB);
    }
  );
  return (
    <OptionListContainer>
      <NewOptionContainer>
        <NatButton
          label={"Create New Key"}
          trackingId={"admin-product-options-create-key"}
          type={"button"}
          option={StyleOption.SECONDARY_ALT}
          clickEvent={() => setCurrentKey({})}
          size={NatSize.SMALL}
        />
      </NewOptionContainer>
      <OptionList>
        {sortedOptionKeys.map((k: ProductOptionKey) => {
          return (
            <OptionRow
              active={currentKey?.id === k.id}
              onClick={() => {
                setCurrentKey(k);
              }}
              key={k.id}
            >
              {k.name}
            </OptionRow>
          );
        })}
      </OptionList>
    </OptionListContainer>
  );
};
