import {a, useSpring} from "@react-spring/web";
import React from "react";
import {NatomasButtonProps} from "../NatButtonProps";
import {NatButton} from "../NatButton";
import {StyleOptions} from "../../_shared";

// NLuxButton is a wrapper around NatButton
// Uses NLux prefix (Natomas Labs ux) to denote it is an animated component
export const NLuxButton = (
  props: NatomasButtonProps & {
    transitionPercent: number;
  }
) => {
  const animateHide = useSpring(
    props?.option !== undefined
      ? {
          transformOrigin: "center",
          duration: 500,
          config: {mass: 5, tension: 500, friction: 80},
        }
      : {
          transformOrigin: "center",
          duration: 500,
          config: {mass: 5, tension: 500, friction: 80},
          ...StyleOptions[props.option ?? -1],
        }
  );
  return (
    <a.div style={animateHide}>
      <NatButton {...props} hidden={false} />
    </a.div>
  );
};
