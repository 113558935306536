import React from "react";
import SSCRichTextDivRow from "../../_shared/SSCRichTextDivRow/SSCRichTextDivRow";

interface SSCCustomOptionsRowProps {
  primaryContent: string;
  otherContent?: string;
}

const SSCCustomOptionsRow = (props: SSCCustomOptionsRowProps) => {
  const {otherContent, primaryContent} = props;

  return (
    <SSCRichTextDivRow
      label={"Customization Options"}
      primaryContent={primaryContent}
      otherContent={otherContent}
    />
  );
};

export default SSCCustomOptionsRow;
