import React, {Dispatch, SetStateAction} from "react";
import {SearchAllStateOptions} from "../index";
import {TileContainer} from "../styles";
import {ImageCard} from "../../_shared/ImageCard";
import {ActionCard} from "../../_shared/ActionCard";
import {FaFolderOpen} from "react-icons/fa";
import {useFactoryLine} from "../../../../../hooks/useProductCatalog/useFactoryLine/useFactoryLine";

export interface SearchAllProductGroupProps {
  setMode: Dispatch<SetStateAction<SearchAllStateOptions>>;
  selectImage: ((id: string) => any) | null;
  setEditDetails: boolean;
  productGroupId?: string;
}

export const SearchAllProductGroup = (props: SearchAllProductGroupProps) => {
  const {productGroupId, setEditDetails, setMode, selectImage} = props;
  const {details} = useFactoryLine({factoryId: productGroupId});

  return (
    <TileContainer>
      {details?.imageId && (
        <ImageCard
          id={details?.imageId}
          showEditDetails={setEditDetails}
          selectImage={selectImage}
        />
      )}
      <ActionCard
        icon={<FaFolderOpen />}
        label={"Products\n(Catalog Images)"}
        action={() => setMode(SearchAllStateOptions.PRODUCTS)}
      />
      <ActionCard
        icon={<FaFolderOpen />}
        label={"Categories\n(Design Studio Renderings)"}
        action={() => setMode(SearchAllStateOptions.CATEGORIES)}
      />
      <ActionCard
        icon={<FaFolderOpen />}
        label={"Modifiers\n(Design Studio Renderings)"}
        action={() => setMode(SearchAllStateOptions.MODIFIERS)}
      />
    </TileContainer>
  );
};
