import { Header } from "../../../_shared/generics/header";
import { LogoContainer } from "../../views/styles";
import Logo from "../../../../assets/logos/villa-logo-slate.png";
import React from "react";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import { NavigationPaths } from "../../../_shared/hooks/useNavigation/paths";
import { usePage } from "../../../_shared/hooks/usePage";
import {
  PortalHeaderButtonContainer,
  PortalHeaderLogoContainer,
} from "../../../map/_shared/components/MainHeader/styles";
import { SignUpInComponent } from "../../../map/_shared/components/MainHeader";
import { AppSwitcher } from "../../../_shared/hooks/useNavigation/AppSwitcher";
import { useProgressBar } from "../../../_shared/hooks/useProgressBar";
import { useNavigation } from "../../../_shared/hooks/useNavigation";

export const PortalHeader = (props: {
  title?: JSX.Element | string;
  showAppSwitcher: boolean;
  hideProgressBar?: boolean;
  rightHandComponent?: JSX.Element;
}) => {
  const { title, showAppSwitcher } = props;
  const { showProgressBar } = useProgressBar();
  const width = useDynamicValue(fullColumnWidths);
  const { isNatMobile } = usePage();

  if (isNatMobile) {
    if (!showAppSwitcher) {
      return (
        <>
          <Header
            hasBorder={true}
            height={"4rem"}
            width={width}
            hideProgressBar={props.hideProgressBar}
            logo={
              <PortalHeaderLogoContainer isMobile={isNatMobile}>
                <VillaLogo />
              </PortalHeaderLogoContainer>
            }
          >
            {props.rightHandComponent ? (
              props.rightHandComponent
            ) : (
              <SignUpInComponent />
            )}
          </Header>
        </>
      );
    }
    return (
      <>
        <Header
          hasBorder={true}
          height={"4rem"}
          width={width}
          hideProgressBar={props.hideProgressBar}
          titleChildren={
            <PortalHeaderLogoContainer isMobile={isNatMobile}>
              <VillaLogo />
            </PortalHeaderLogoContainer>
          }
          title={<AppSwitcher />}
          titleStyle={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            height: "2rem",
          }}
        >
          {props.rightHandComponent ? (
            props.rightHandComponent
          ) : (
            <SignUpInComponent />
          )}
        </Header>
      </>
    );
  }

  return (
    <Header
      hasBorder={true}
      height={"4rem"}
      width={width}
      title={title}
      hideProgressBar={!showProgressBar || props.hideProgressBar}
      logo={
        <PortalHeaderLogoContainer isMobile={isNatMobile}>
          <VillaLogo />
          {showAppSwitcher && <AppSwitcher />}
        </PortalHeaderLogoContainer>
      }
    >
      <PortalHeaderButtonContainer isMobile={isNatMobile}>
        {props.rightHandComponent ? (
          props.rightHandComponent
        ) : (
          <SignUpInComponent />
        )}
      </PortalHeaderButtonContainer>
    </Header>
  );
};

export const VillaLogo = () => {
  const { to } = useNavigation();
  return (
    <LogoContainer
      src={Logo}
      alt="logo"
      onClick={() => {
        to(NavigationPaths.SPLASH_SCREEN);
      }}
    />
  );
};
