import React from "react";
import {Font, StyleSheet, Text, View} from "@react-pdf/renderer";
import {PDFProposal} from "@natomas-org/core";
// @ts-ignore
import InterSrc from "../../../../assets/fonts/Inter/Inter-Regular.ttf";
// @ts-ignore
import InterBoldSrc from "../../../../assets/fonts/Inter/Inter-Bold.ttf";
// @ts-ignore
import InterMediumSrc from "../../../../assets/fonts/Inter/Inter-Medium.ttf";
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/700.css";
import {secondaryStyles} from "./RPDFMiniTable";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: InterSrc,
      fontWeight: 400,
    },
    {
      src: InterSrc,
      fontWeight: "normal",
    },
    {
      src: InterBoldSrc,
      fontWeight: "bold",
    },
    {
      src: InterMediumSrc,
      fontWeight: 700,
    },
  ],
});
const primaryStyles = StyleSheet.create({
  table: {
    padding: "0 0",
    margin: "0 0",
    display: "flex",
    justifyContent: "flex-start",
    justifyItems: "flex-start",
    alignItems: "flex-start",
    alignContent: "flex-start",
    maxWidth: "100%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderBottomWidth: 0,
    backgroundColor: "#eaeee7",
    wordBreak: "break-word",
  },
  row: {
    margin: "0 0",
    flexDirection: "row",
    width: "100%",
  },
  cell: {
    flexGrow: 1,
    // width: "20%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderTopWidth: 0,
    padding: 8,
    fontSize: 10,
    fontFamily: "Inter",
    fontWeight: 400,
    breakInside: "avoid",
    whiteSpace: "no-wrap",
    wordBreak: "break-word",
  },

  boldCell: {
    flexGrow: 1,
    // width: "20%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    fontWeight: 700,
    borderTopWidth: 0,
    padding: 8,
    fontSize: 10,
    fontFamily: "Inter",
    breakInside: "avoid",
    whiteSpace: "no-wrap",
  },
  header: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: "Inter",
    breakInside: "avoid",
  },
});

// Takes in a 2D array of data elements. Each row is an array of data elements.
export const RPDFTable = (props: {
  data: PDFProposal.Nat_RPDF_Table_Text_Data_Row[];
  secondary?: boolean;
  title?: string;
}) => {
  const {data} = props;
  let styles = primaryStyles;
  if (props.secondary) {
    styles = secondaryStyles as any;
  }
  return (
    <View style={styles.table} wrap>
      {data?.map((row, rowIndex) => {
        const disclaimer = row?.disclaimer ?? null;
        const subtitle = row?.subtitle ?? null;
        return (
          <View
            key={rowIndex}
            style={{...styles.row}}
            wrap={false}
            break={false}
          >
            {row?.data?.map((cell, cellIndex) => {
              const width =
                cellIndex === 0
                  ? "300px"
                  : (cellIndex === 2 && row.data.length === 3) ||
                    (cellIndex === 3 && row.data.length === 4)
                  ? "500px"
                  : row.data.length > 2
                  ? "150px"
                  : undefined;
              const baseViewStyle = cell.bold ? styles.boldCell : styles.cell;
              const baseTextStyle = cell.header
                ? styles.header
                : {
                    whiteSpace: "no-wrap",
                    wordBreak: "break-word",
                  };
              return (
                <View
                  key={cellIndex}
                  style={{
                    ...baseViewStyle,
                    backgroundColor: cell.bgColor || "white",
                    width: width,
                    maxWidth: width,
                  }}
                >
                  <Text
                    wrap
                    style={{
                      ...baseTextStyle,
                      color: cell.textColor || "black",
                    }}
                  >
                    {cell.text}
                  </Text>
                  {subtitle && cellIndex === 2 && (
                    <Text style={{fontSize: 8}}>{subtitle}</Text>
                  )}
                  {disclaimer && cellIndex === 2 && (
                    <Text style={{fontSize: 8, color: "red"}}>
                      {disclaimer}
                    </Text>
                  )}
                </View>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};
