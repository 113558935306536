import {
  ICreateCheckoutSessionRequest,
  ICreateCheckoutSessionResponse,
} from "@natomas-org/core";
import {executeRequest} from "../APIExecutor";

export const createStripeSession = (
  sessionParameters: ICreateCheckoutSessionRequest
): Promise<ICreateCheckoutSessionResponse> => {
  return executeRequest("/stripe/v1/createCheckoutSession", sessionParameters);
};
