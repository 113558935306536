import {executeRequest} from "../APIExecutor";
import {IImageInfo, IMAGES_COLLECTION} from "@natomas-org/core";
import {Utilities} from "../../index";
import {setDocWithMerge} from "../../utilities";
import {fetchImage} from "../../images/fetching";

const updateImageTagsPath = "/images/v1/changeImageTags";
const updateImageTitlePath = "/images/v1/changeImageTitle";

export const updateImageTags = (id: string, tags: string[]) => {
  return executeRequest(updateImageTagsPath, {
    imageId: id,
    tags: tags,
  }).then((data) => {
    console.log(data);
  });
};

export const updateImageTitle = (id: string, title: string) => {
  return executeRequest(updateImageTitlePath, {
    imageId: id,
    title: title,
  }).then((data) => {
    console.log(data);
  });
};

export const updateImageInfo = (id: string, info: IImageInfo) => {
  const imageRef = Utilities.collection(IMAGES_COLLECTION).doc(id);
  return setDocWithMerge(imageRef, info).then(() => {
    console.log("Saved image details");

    return fetchImage(id, true).then(() => {
      console.log("Refreshed image cache");
      return null;
    });
  });

  // Can be migrated to an API eventually if needed
  // return executeRequest(updateImageTitlePath, {
  //   imageId: id,
  //   imageInfo: info,
  // }).then((data) => {
  //   console.log(data);
  // });
};
