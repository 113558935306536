import design_graphic from "../../../../assets/images/graphics/design_graphic.png";
import React from "react";
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";

const DesignContainerWaitingContent = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 360px;
  text-align: center;
  --webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const DesignContainerRelative = styled.div`
  position: relative;
  text-align: center;
  width: 428px;
  max-width: 90vw;
  margin: auto;
`;

export const WaitingForDesign = (props) => {
  const history = useHistory();
  const {isCartEnabled} = useActiveProject();
  if (isCartEnabled) {
    return null;
  }
  const content = (
    <>
      <img src={design_graphic} alt={"start design"} width={"240px"} />
      <br />
      <div className={"large-text bold"} style={{marginBottom: "10px"}}>
        Design Time!
      </div>
      <div style={{marginBottom: "24px"}}>
        It's our favorite part... It's time to design your ADU! Customize your
        floor plan layout, fixtures and finishes.
      </div>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        to={{pathname: "/design", search: history.location.search}}
        className={"link_button bold"}
        style={{width: "100%", maxWidth: "360px", margin: "auto"}}
      >
        Start Design
      </Link>
    </>
  );
  if (props.relative === true) {
    return <DesignContainerRelative>{content}</DesignContainerRelative>;
  }
  return (
    <DesignContainerWaitingContent>{content}</DesignContainerWaitingContent>
  );
};
