import {useMemo, useState} from "react";
import {throttleCallback} from "../../Utils";
import {PROGRESS_BAR_HEIGHT} from "../../generics/progress-bar/ProgressBar";
import {useDispatch} from "react-redux";
import {setStudioSection, setStudioStep} from "../../slices/StudioSlice";
import {useStudioSettings} from "./useStudioSettings";

interface StudioSmartScrollProps {
  isMobile: boolean;
  hasProgressBar: boolean;
  hasHeader: boolean;
  length: number;
}

export const useStudioSmartScroll = (props: StudioSmartScrollProps) => {
  const {isMobile, hasProgressBar, length} = props;

  const {step} = useStudioSettings();
  const dispatch = useDispatch();
  const [atBottom, setAtBottom] = useState<boolean>(false);

  const smartScroll = useMemo(() => {
    return throttleCallback((e: any) => {
      if (e?.type === "scroll") {
        const scrollHeight =
          e?.target?.scrollHeight ?? e?.target?.scrollingElement?.scrollHeight;
        const top =
          e?.target?.scrollTop ?? e?.target?.scrollingElement?.scrollTop;
        const clientHeight =
          e?.target?.clientHeight ?? e?.target?.scrollingElement?.clientHeight;
        const relativePosition = top + clientHeight;
        // Is the scroll container NOT fit to the screen width
        if (top !== 0 && clientHeight !== scrollHeight) {
          // Is the scroll container approaching the bottom
          if (scrollHeight - relativePosition < 225) {
            setAtBottom(true);
          } else {
            setAtBottom(false);
          }
        }
      }

      const determineStep = () => {
        const windowHeight = window.innerHeight;
        let lowThreshold = isMobile ? windowHeight * 0.3 : 300;
        let highThreshold = isMobile ? windowHeight * 0.5 : 400;

        if (!isMobile && hasProgressBar) {
          lowThreshold += PROGRESS_BAR_HEIGHT;
          highThreshold += PROGRESS_BAR_HEIGHT;
        }

        let newStep = -1;
        for (let i = 0; i < length + 1; i++) {
          const doc = document.getElementById("category_step_container_" + i);
          const rect = doc?.getBoundingClientRect();
          const top = rect?.y ?? 0;
          const sectionHeight = rect?.height ?? 0;
          const bottom = top + sectionHeight;
          if (top && top <= lowThreshold && bottom >= highThreshold) {
            newStep = i;
          }
        }
        if (newStep >= 0 && newStep !== step) {
          dispatch(setStudioStep(newStep));
        }
      };
      const determineSection = () => {
        const sectionHeaders = document.querySelectorAll(".section_header");
        let lowThreshold = isMobile ? -1 : 160;
        let highThreshold = isMobile ? 300 : 200;

        if (!isMobile && hasProgressBar) {
          lowThreshold += PROGRESS_BAR_HEIGHT;
          highThreshold += PROGRESS_BAR_HEIGHT;
        }

        let newSection = null;
        sectionHeaders?.forEach((doc) => {
          const top = doc?.getBoundingClientRect().y;
          if (top > lowThreshold && top < highThreshold) {
            newSection = doc?.getAttribute("name");
          }
        });
        dispatch(setStudioSection(newSection));
      };

      determineStep();
      determineSection();
    }, 300);
  }, [dispatch, length, isMobile, step, hasProgressBar]);

  return {
    smartScroll,
    atBottom,
  };
};
