import {IUserAuthInfo} from "../../../../../../../_shared/slices/types/Store";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {getEnhancedTeamMemberFromEmail} from "@natomas-org/core";
import React from "react";
import {usePage} from "../../../../../../../_shared/hooks/usePage";
import TeamMemberPhoto from "../../../../../../../../0-app/1-container/team-member-photo";

const MOBILE_SIZE = `1.5rem`;
const DESKTOP_SIZE = `2rem`;
export const AuthorIcon = (props: {
  allAuthInfo: IUserAuthInfo;
  text?: string;
  hoverText: string;
  id: string;
}) => {
  const {isNatMobile} = usePage();
  const icon = getAuthorIcon(props.allAuthInfo, props.id, props.text);
  const size = isNatMobile ? MOBILE_SIZE : DESKTOP_SIZE;
  return (
    <NatButton
      key={props.id}
      style={{
        width: size,
        height: size,
        position: "relative",
      }}
      size={NatSize.XSMALL}
      label={""}
      option={StyleOption.ICON_ONLY}
      icon={{icon: icon, iconPosition: IconPosition.ONLY}}
      tooltip={props.hoverText}
      tooltipPlacement={"bottom"}
      id={"email-" + props.id}
    />
  );
};

const getAuthorIcon = (
  allAuthInfo: IUserAuthInfo,
  authorId?: string,
  text?: string
) => {
  // TODO Fix how we fetch the image URL of team members / authors
  // let fallbackComp;
  // let imageURL = "";
  //
  let email = undefined;
  if (!!authorId) {
    const a = allAuthInfo?.[authorId];
    console.warn("getAuthorIcon -> No email found.", authorId);
    email = a?.email ?? undefined;
  }
  return <TeamMemberPhoto email={email} />;
};

export const getAuthorDisplayName = (
  allAuthInfo: IUserAuthInfo,
  authorId?: string
) => {
  if (!!authorId && allAuthInfo?.[authorId]) {
    const email = allAuthInfo?.[authorId]?.email;
    const enrichedTeamContact = getEnhancedTeamMemberFromEmail(email ?? "");
    if (enrichedTeamContact) {
      return enrichedTeamContact.first_name;
    }

    return allAuthInfo?.[authorId]?.displayName;
  }
  return "Villa Team";
};
