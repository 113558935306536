import {EditRenderingForCategory} from "../../../../_shared/_legacy/EditRenderingForCategory";
import React, {useMemo} from "react";

import {InputTitle} from "../../../_shared/styles/AdminStyles";
import {sortByAlphabeticalKey} from "@natomas-org/core";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {RenderingsColumnList} from "./styles";

function cartesian(...args: any) {
  let r: any = [],
    max = args?.length - 1;
  function helper(arr: any, i: any) {
    for (let j = 0, l = args?.[i]?.length; j < l; j++) {
      let a = arr.slice(0); // clone arr
      a.push(args[i][j]);
      if (i === max) r.push(a);
      else helper(a, i + 1);
    }
  }
  helper([], 0);
  return r;
}

export const RenderingsTab = (props: any) => {
  const {category, renderings, modifierGroupIds} = props;
  const renderingToShow =
    category.renderings != null
      ? sortByAlphabeticalKey(Object.values(category?.renderings ?? {}), "id")
      : [];
  const {modifierGroups} = useSelectedFactoryLine();

  const remainingRenderings = useMemo(() => {
    if (modifierGroupIds != null) {
      const listOfModifierGroups = modifierGroupIds?.map(
        (modifierGroupId: any) => {
          return modifierGroups?.[modifierGroupId];
        }
      );

      let listOfListsOfModifiers: any = [];
      listOfModifierGroups?.forEach((modifierGroup: any) => {
        if (modifierGroup?.modifiers) {
          listOfListsOfModifiers = [
            ...listOfListsOfModifiers,
            modifierGroup?.modifiers,
          ];
        }
      });
      const allPermutationsOfModifiersForPage = cartesian(
        ...listOfListsOfModifiers
      );
      const remaining: any = [];
      const renderingToIterateThrough =
        renderings != null ? Object.values(renderings) : [];
      allPermutationsOfModifiersForPage.forEach((modifierList: any) => {
        const match = renderingToIterateThrough.some((rendering: any) => {
          return modifierList.every(
            (modifierId: any) => rendering.requiredModifiers[modifierId]
          );
        });
        if (!match) {
          remaining.push(modifierList);
        }
      });
      if (remaining?.length > 10) {
        return remaining.slice(0, 10);
      }
      return remaining;
    }
    return [];
  }, [modifierGroups, modifierGroupIds, renderings]);

  return (
    <div
      className={"left-right"}
      style={{
        maxHeight: "100%",
        overflow: "auto",
        alignItems: "baseline",
      }}
    >
      <RenderingsColumnList
        style={{width: "50%", paddingTop: "1rem", textAlign: "center"}}
      >
        <InputTitle bold={true} title={"Assigned"} enabled={true} />
        {renderingToShow.map((rendering: any, index: number) => {
          return (
            <EditRenderingForCategory
              key={rendering.imageId + index}
              renderingImageId={rendering.imageId}
              rendering={rendering}
              configurationPage={category}
            />
          );
        })}
      </RenderingsColumnList>
      <RenderingsColumnList>
        <InputTitle bold={true} title={"Unassigned"} enabled={true} />
        {remainingRenderings.map((rendering: any) => {
          return (
            <EditRenderingForCategory
              key={rendering.toString()}
              renderingImageId={null}
              leftOverModifiers={rendering}
              rendering={{}}
              configurationPage={category}
            />
          );
        })}
        <EditRenderingForCategory
          rendering={{}}
          renderingImageId={null}
          configurationPage={category}
        />
      </RenderingsColumnList>
    </div>
  );
};
