import styled from "styled-components";
import {LINK_COLOR, LINK_HOVER_COLOR} from "../../../../../_shared/colors";
import {FONT_FAMILY_BOLD} from "../../../SSDDashboardView/styles/globals";

export const DashboardLink = styled.a`
  font-family: ${FONT_FAMILY_BOLD};
  cursor: pointer;
  color: ${LINK_COLOR};
  &:hover {
    color: ${LINK_HOVER_COLOR};
  }
`;

export const WarningContainer = styled.div`
  margin: 1rem 0;
  div {
    padding-bottom: 0.5rem;
    &:last-child {
      padding-bottom: 0;
    }
  }
`;
