import {
  CustomerProjectInfo,
  firestoreProjectToNatomasProject,
  IProjectInfo,
  mergeProjectInfoAndProjectOpportunityInfo,
} from "@natomas-org/core";
import {IProject} from "./ProjectSlice";

export const getProjectInfo = (
  project: IProject
): IProjectInfo | null | undefined => {
  const {natomasProjectInfo, salesforceProjectInfo, smartsheetProjectInfo} =
    project;
  if (
    natomasProjectInfo !== undefined &&
    salesforceProjectInfo !== undefined &&
    smartsheetProjectInfo !== undefined
  ) {
    const summary = mergeCustomerProjectInfos(project);
    // Both dependencies are null
    if (!summary) {
      return null;
    }

    return firestoreProjectToNatomasProject(summary);
  } else return undefined;
};

export const mergeCustomerProjectInfos = (
  project: IProject
): CustomerProjectInfo | null => {
  const {natomasProjectInfo, salesforceProjectInfo, smartsheetProjectInfo} =
    project;
  return mergeProjectInfoAndProjectOpportunityInfo(
    natomasProjectInfo,
    salesforceProjectInfo,
    smartsheetProjectInfo
  );
};
