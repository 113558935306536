import React from "react";
import styled from "styled-components";
import {VILLA_SLATE, WHITE} from "../../../components/_shared/colors";
import logo from "../../../assets/logos/villa-logo-white.png";
import {LoadingPanelSpinner} from "../../../components/_shared/generics/loading-panel/styles";
import {AiOutlineLoading3Quarters} from "react-icons/ai";

export const DefaultProfilePicture = (
  <img
    data-cy={"default-profile-picture"}
    style={{
      backgroundColor: VILLA_SLATE,
      height: "100%",
      width: "100%",
      objectFit: "contain",
      padding: "10%",
    }}
    src={logo}
    alt={"Villa Homes"}
  />
);

export const SpinnerContainer = styled.div`
  background-color: ${VILLA_SLATE};
  height: 100%;
  width: 100%;
  padding: 10%;
`;

const CircleImageWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapper = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
export const Photo = (props: {url?: string; loading?: boolean}) => {
  const {url, loading} = props;
  if (loading) {
    return (
      <CircleImageWrapper>
        <SpinnerContainer>
          <LoadingPanelSpinner size={"100%"}>
            <AiOutlineLoading3Quarters color={WHITE} />
          </LoadingPanelSpinner>
        </SpinnerContainer>
      </CircleImageWrapper>
    );
  }
  if (!url) {
    return <CircleImageWrapper>{DefaultProfilePicture}</CircleImageWrapper>;
  }

  return (
    <CircleImageWrapper>
      <ImageWrapper
        data-cy={"url-profile-picture"}
        src={url}
        height={"100%"}
        width={"100%"}
      />
    </CircleImageWrapper>
  );
};

export default Photo;
