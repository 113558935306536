import React from "react";
import {NatImage} from "../../../../../../../../_shared/generics/image/NatImage";
import {VILLA_APPLE_GREEN} from "../../../../../../../../_shared/colors";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";
import {useDynamicValue} from "../../../../../../../../_shared/hooks/useDynamicValue";

interface SSCImageProps {
  imageId: string;
  showBorder?: boolean;
}

const SSCImage = (props: SSCImageProps) => {
  const {imageId, showBorder} = props;

  const format = useDynamicValue({
    forFour: {
      size: {
        height: "120px",
        width: "100%",
      },
      radius: "12px",
    },
    forEight: {
      size: {
        height: "160px",
        width: "100%",
      },
      radius: "16px",
    },
    forTwelve: {
      size: {
        height: "200px",
        width: "100%",
      },
      radius: "20px",
    },
    forSixteen: {
      size: {
        height: "240px",
        width: "100%",
      },
      radius: "24px",
    },
  });

  return (
    <SSCBlockContainer>
      <NatImage
        imageId={imageId}
        size={format.size}
        roundCorners={true}
        style={{
          borderRadius: format.radius,
          border: showBorder ? `3px solid ${VILLA_APPLE_GREEN}` : undefined,
        }}
      />
    </SSCBlockContainer>
  );
};

export default SSCImage;
