import {isInvalidDocument} from "./factoryLine";
import {fetchImage} from "../../images/fetching";
import {
  getConvertedModifierPricingStructure,
  getConvertedProductModifierPricingStructure,
} from "@natomas-org/service";

export const convertFactoryVersionDocToCategory = (
  id: string,
  versionId: string,
  doc: any
) => {
  const category = doc.data();
  const categoryId = doc.id;
  if (!isInvalidDocument(category) && categoryId) {
    category.productGroupId = id;
    category.versionId = versionId;
    category.id = categoryId;
    return category;
  }
  return null;
};

export const convertFactoryVersionDocToModifierGroup = (
  id: string,
  versionId: string,
  doc: any
) => {
  const modifierGroup = doc.data();
  const modifierGroupId = doc.id;
  if (!isInvalidDocument(modifierGroup) && modifierGroupId) {
    modifierGroup.productGroupId = id;
    modifierGroup.versionId = versionId;
    modifierGroup.id = modifierGroupId;
    if (
      modifierGroup.modifiers == null ||
      modifierGroup.modifiers?.length === 0
    ) {
      modifierGroup.modifiers = [];
    }
    return modifierGroup;
  }
  return null;
};

export const convertFactoryVersionDocToModifier = (
  id: string,
  versionId: string,
  doc: any
) => {
  const modifier = doc.data();
  const modifierId = doc.id;
  if (!isInvalidDocument(modifier) && modifierId) {
    modifier.productGroupId = id;
    modifier.versionId = versionId;
    modifier.id = modifierId;
    if (modifier.imageId != null) {
      fetchImage(modifier.imageId, false);
    }
    // Sept. 7th 2022 - Reformatting pricing
    if (!modifier?.price_set || modifier?.price_set?.length === 0) {
      modifier.price_set = getConvertedModifierPricingStructure(modifier);
    }
    return modifier;
  }
  return null;
};
export const convertFactoryVersionDocToProductModifier = (
  id: string,
  versionId: string,
  productId: string,
  doc: any
) => {
  const m = doc.data();
  m.id = doc.id;
  m.productId = productId;
  m.productGroupId = id;
  m.versionId = versionId;
  // Product Modifiers should NOT have custom titles currently
  let {title, internal_title, configurationButtonTitle, ...modifier} = m;

  if (!modifier?.price_set) {
    modifier.price_set = getConvertedProductModifierPricingStructure(modifier);
  }
  return modifier;
};
