import {
  IconPosition,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";

import {NatButton} from "../../../../../../_shared/generics/button";
import React from "react";
import {
  NatInvisibleIcon,
  NatVisibleIcon,
} from "../../../../../../_shared/icon/icons";

export const HideItemButton = (props: {
  clickEvent: () => Promise<any>;
  itemId: string | null | undefined;
  hidden: boolean | undefined;
  tooltipTitle: string;
}) => {
  const {clickEvent, hidden, itemId, tooltipTitle} = props;
  if (!itemId) {
    return <></>;
  }
  return (
    <NatButton
      label={""}
      type={"button"}
      clickEvent={clickEvent}
      option={StyleOption.ICON_ONLY}
      style={{
        border: "1px solid " + (hidden ? "red" : "black"),
        color: hidden ? "red" : "black",
      }}
      icon={{
        icon: hidden ? <NatInvisibleIcon /> : <NatVisibleIcon />,
        iconPosition: IconPosition.ONLY,
      }}
      tooltip={hidden ? `Show ${tooltipTitle}` : `Hide ${tooltipTitle}`}
      id={itemId + "_visible_icon"}
    />
  );
};
