import React, {useCallback, useMemo} from "react";
import {FactoryInventoryTable} from "../FactoryInventoryTable";
import {
  FactoryInventoryViewDescription,
  FactoryInventoryViewTitle,
} from "../styles";
import {FactoryViewContainer} from "../../FactoryInfoView/styles";
import {INVENTORY_UNIT_PREFIX} from "../../../../../../../_shared/constants/labels";
import {IProduct} from "@natomas-org/core";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {FactoryInventoryViewProps} from "../index";
import FactoryManagementHeader from "../../../_shared/FactoryManagementHeader";
import {useDispatch} from "react-redux";
import {setProductInInventoryEditor} from "../../../../../../../_shared/slices/CatalogSlice/AdminFactoryInventoryEditorSlice";
import {v4 as uuidv4} from "uuid";
import {StyleOption} from "../../../../../../../_shared/generics/_shared";
import {IFactoryInventoryTableItem} from "../FactoryInventoryTable/interfaces";
import {getFactoryInventoryTableItem} from "../FactoryInventoryTable/helper";

const FactoryInventoryDefaultView = (props: FactoryInventoryViewProps) => {
  const {inventory, factoryTitle, factoryId} = props;
  const dispatch = useDispatch();

  const description: string = useMemo(() => {
    return `View or edit a ${INVENTORY_UNIT_PREFIX} unit, duplicate from existing ${INVENTORY_UNIT_PREFIX} units, or create a ${INVENTORY_UNIT_PREFIX} from scratch. `;
  }, []);

  const inventoryArray: IFactoryInventoryTableItem[] = useMemo(() => {
    return Object.values(inventory ?? {})?.map((product: IProduct) => {
      return getFactoryInventoryTableItem(product, factoryTitle);
    });
  }, [factoryTitle, inventory]);

  const setEditingProduct = useCallback(
    (id: string) => {
      if (inventory) {
        dispatch(setProductInInventoryEditor(inventory[id]));
      }
    },
    [dispatch, inventory]
  );

  return (
    <>
      <FactoryManagementHeader />
      <FactoryViewContainer>
        <FactoryInventoryViewTitle>
          {factoryTitle} {INVENTORY_UNIT_PREFIX} Manager
        </FactoryInventoryViewTitle>
        <FactoryInventoryViewDescription>
          {description}
        </FactoryInventoryViewDescription>
        <NatButton
          label={`Create ${INVENTORY_UNIT_PREFIX} from scratch`}
          option={StyleOption.ADMIN_SOLID}
          clickEvent={() => {
            console.log("Creating ReadyBuilt from scratch");
            dispatch(
              setProductInInventoryEditor({
                id: uuidv4(),
                productGroupId: factoryId,
              })
            );
          }}
        />
        {inventoryArray && factoryId && factoryTitle && (
          <FactoryInventoryTable
            inventory={inventoryArray}
            setEditingProduct={setEditingProduct}
            options={{stickyHeight: 52}}
          />
        )}
      </FactoryViewContainer>
    </>
  );
};

export default FactoryInventoryDefaultView;
