import {FactoryInventoryCatalogEditorKey, IFactoryInventoryCatalogEditor} from "./interfaces";

export const getInitialInventoryCatalogEditorValues = (product: IFactoryInventoryCatalogEditor) => {
  const keys = Object.keys(FactoryInventoryCatalogEditorKey);
  const initialState: {[key: string]: any} = {};
  keys.forEach((key: string) => {
    let k = key as keyof typeof FactoryInventoryCatalogEditorKey
    initialState[FactoryInventoryCatalogEditorKey[k]] = product[FactoryInventoryCatalogEditorKey[k]] ?? "";
  });
  return initialState;
};
