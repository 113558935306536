import styled from "styled-components";
import {
  BLACK,
  GRANITE,
  GRAY_BORDER_LIGHT,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
  VILLA_COOL_GRAY,
  WHITE,
} from "src/components/_shared/colors";

export const BoldSubtitle = styled.div`
  color: ${BLACK};
  /* Paragraph/P1/Semibold */
  font-family: "Inter", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  p {
    margin: 16px 0;
  }
`;

export const InstantQuoteFormWrapper = styled.form``;

export const InstantQuoteFormGroup = styled.div`
  border-radius: var(--radius-medium, 10px);
  border: 1px solid ${GRAY_BORDER_LIGHT};
  background: ${VILLA_COOL_GRAY};
  overflow: auto;
  max-height: 288px;
`;

export const InstantQuoteFormLabel = styled.label`
  color: ${BLACK};
  font-family: "Inter", serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // Custom styles
  padding-bottom: 8px;
  margin: 0;
`;

export const InstantQuoteFormInput = styled.input`
  border-radius: var(--radius-medium, 10px);
  border: ${(props) =>
    props.theme.selected
      ? `1px solid ${VILLA_APPLE_GREEN}`
      : `1px solid ${GRAY_BORDER_LIGHT}`};
  background-color: ${(props) =>
    props.theme.selected ? VILLA_APPLE_GREEN_LIGHT_1 : WHITE};
  :hover {
    background-color: ${VILLA_APPLE_GREEN_LIGHT_1};
  }
  color: BLACK;
  // Custom Styles
  font-size: 16px;
  padding: 16px;
  margin-top: 8px;
  width: 100%;
  text-align: left;
`;

export const InstantQuoteFormInputDiv = styled.div`
  border-radius: var(--radius-medium, 10px);
  border: ${(props) =>
    props.theme.selected
      ? `1px solid ${VILLA_APPLE_GREEN}`
      : `1px solid ${GRAY_BORDER_LIGHT}`};
  background-color: ${(props) =>
    props.theme.selected ? VILLA_APPLE_GREEN_LIGHT_1 : WHITE};
  :hover {
    background-color: ${VILLA_APPLE_GREEN_LIGHT_1};
  }
  color: BLACK;
  // Custom Styles
  font-size: 16px;
  padding: 16px;
  margin-top: 8px;
  width: 100%;
  text-align: left;
  display: flex;
  img {
    scale: ${(props) => (props.theme.selected ? 1 : 0)};
    transition: scale 200ms ease-in-out;
  }
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const InstantQuoteFormTextArea = styled.textarea`
  color: ${BLACK};
  font-family: "Inter", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  border-radius: var(--radius-medium, 10px);
  border: 1px solid ${GRANITE};
  background: ${WHITE};
  &::placeholder {
    font-style: italic;
  }
  // Custom styles
  width: 100%;
  height: 10em;
  overflow: auto;
  padding: 16px;
  margin-top: 8px;
  resize: none;
`;

export const InstantQuoteQuestion = styled.div`
  padding: 16px;
`;
