import {EstimateTableConfigurationOptions} from "../_shared/table";
import {EstimateTables} from "../CustomerEstimate/tables";

export const AdminEstimateTables: EstimateTableConfigurationOptions = {
  ALL: {
    key: "all",
    label: "All",
    description: "Complete itemized view of the project estimate table.",
  },
  ...EstimateTables,
};
