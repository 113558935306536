import React from "react";
import {useDispatch} from "react-redux";
import {
  setEditingSelections,
  setShowEditingConfirmation,
} from "../../../../_shared/slices/StudioSlice";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {
  NatModal,
  NatModalSize,
} from "../../../../_shared/generics/modal/NatModal";
import {NatomasButtonProps} from "../../../../_shared/generics/button";
import {INVENTORY_UNIT_PREFIX} from "../../../../_shared/constants/labels";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import {StudioState} from "../../../../_shared/slices/types/Store";
import {useProductModeDescriptor} from "../../../../catalog/views/MainView/components/ProductModeDescriptor";

export const StudioEditingConfirmation = (props: {
  mode: StudioState;
  product: any;
  isProjectLocked?: boolean;
}) => {
  const {mode, product, isProjectLocked} = props;
  const {isShowingEditingConfirmation} = useStudioSettings();
  const {to} = useNavigation();
  const dispatch = useDispatch();
  const {inventory} = useProductModeDescriptor();

  const handleClose = () => {
    dispatch(setShowEditingConfirmation(false));
  };

  const startEditing = () => {
    dispatch(setShowEditingConfirmation(false));
    dispatch(setEditingSelections(true));
  };

  const getHeader = () => {
    if (isProjectLocked) {
      return "This design has been marked finalized.";
    } else if (mode === StudioState.BUILDER) {
      return "Are you sure you would like to edit this inventory unit?";
    } else if (mode === StudioState.SHOWCASE) {
      return "Want to make custom selections for this unit?";
    } else {
      return "Want to explore some changes?";
    }
  };

  const getContent = () => {
    if (isProjectLocked) {
      return (
        <>
          <br />
          Your design(s) have been finalized and confirmed by our team. If you
          would like to make changes, please contact your Villa representative.
          <br />
          <br />
        </>
      );
    } else if (mode === StudioState.BUILDER) {
      return <></>;
    } else if (mode === StudioState.SHOWCASE) {
      return (
        <>
          <br />
          {inventory}
          <br />
          <br />
        </>
      );
    } else {
      return (
        <>
          <br />
          Feel free to explore our options and upgrades. You can always abandon
          any changes you make, and revert to your previously saved design.
          <br />
          <br />
        </>
      );
    }
  };

  const viewOriginalProduct = () => {
    to(
      NavigationPaths.STUDIO,
      product?.inventory_info?.originalProductId,
      product?.productGroupId
    );
    handleClose();
  };

  const viewCatalog = () => {
    to(NavigationPaths.CATALOG);
    handleClose();
  };

  const inventoryDismissButton: NatomasButtonProps = {
    label: "Continue with " + INVENTORY_UNIT_PREFIX,
    option: StyleOption.SECONDARY,
    type: "button",
    clickEvent: handleClose,
    trackingId: "cancel-inventory-to-custom-design",
    id: "cancel-inventory-to-custom-design",
  };

  const designDismissButton: NatomasButtonProps = {
    label: "Dismiss",
    option: StyleOption.SECONDARY,
    type: "button",
    clickEvent: handleClose,
    trackingId: "cancel-edit-design",
    id: "cancel-edit-design",
  };

  const getFooterButtons = (): NatomasButtonProps[] => {
    if (isProjectLocked) {
      return [designDismissButton];
    } else if (mode === StudioState.SHOWCASE) {
      if (product?.inventory_info?.originalProductId) {
        return [
          inventoryDismissButton,
          {
            label: "Switch to custom order",
            type: "button",
            clickEvent: viewOriginalProduct,
            option: StyleOption.PRIMARY,
            id: "inventory-to-custom-design",
            trackingId: "start-editing-design",
          },
        ];
      } else {
        return [
          inventoryDismissButton,
          {
            label: "Explore more products",
            type: "button",
            clickEvent: viewCatalog,
            option: StyleOption.PRIMARY,
            id: "inventory-to-catalog",
            trackingId: "inventory-to-edit-attempt-to-catalog",
          },
        ];
      }
    } else {
      return [
        designDismissButton,
        {
          label: "Start designing",
          type: "button",
          clickEvent: startEditing,
          option: StyleOption.PRIMARY,
          id: "unlock-design-selections",
          trackingId: "start-editing-design",
        },
      ];
    }
  };

  return (
    <NatModal
      header={getHeader()}
      show={isShowingEditingConfirmation}
      handleClose={handleClose}
      centeredContent={false}
      content={getContent()}
      footerButtons={getFooterButtons()}
      size={NatModalSize.NORMAL}
    />
  );
};
