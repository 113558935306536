// To be called when dead tag fields are set (not null) and popup has not been shown before
import React from "react";
import {OutOfServiceSection} from "../../../../portal/views/SSDDashboardView/components/ANDY-components/CallToActionSection/sections";

interface IFeasibilityPopup {
  description: string;
  header: string;
  footer: JSX.Element;
}

export const defaultDescription =
  "It looks like we are unable to deliver to your address. This may be because you are outside of our delivery zone or because our research shows your property is infeasible for one of our homes. If you have questions about this we’re here to help!";
export const defaultHeader = "Thank you for your interest in Villa!";
export const defaultFooter = <OutOfServiceSection />;

const SFDEAD: {[name: string]: IFeasibilityPopup} = {
  "Lot too small": {
    description:
      "We’re sorry, our research found there is not enough space in your backyard to fit one of our ADUs.",
    header: "Lot too small",
    footer: (
      <div>
        If you believe this is an error please contact us at{" "}
        <a href="mailto: hello@villahomes.com" target={"__blank"}>
          hello@villahomes.com
        </a>
        .
      </div>
    ),
  },
  "Installation Logistics (Power Lines/Side Yard/Small Footprint)": {
    description:
      "We’re sorry, our research found your address has conditions which prevent installation.",
    header: "Unserviceable area",
    footer: (
      <div>
        If you believe this is an error please contact us at{" "}
        <a href="mailto: hello@villahomes.com" target={"__blank"}>
          hello@villahomes.com
        </a>
        .
      </div>
    ),
  },
  "Vacant Lot": {
    description:
      "We’re sorry, our research found no primary home at your address which is needed to add an ADU.",
    header: "No primary home found",
    footer: (
      <div>
        If you believe this is an error please contact us at{" "}
        <a href="mailto: hello@villahomes.com">hello@villahomes.com</a>.
      </div>
    ),
  },
  "Outside of serviceable area": {
    description:
      "We’re sorry, our research found your address is outside of our serviceable area.",
    header: "Outside delivery zone",
    footer: <OutOfServiceSection />,
  },
  "Bad Data/Incomplete Address": {
    description:
      "We’re sorry, our research found that the entered address is incomplete or incorrect.",
    header: "Check address",
    footer: <OutOfServiceSection />,
  },
};

export const getSFFeasibilityDescriptionText = (tag: string): string => {
  return SFDEAD[tag] ? SFDEAD[tag].description : defaultDescription;
};
export const getSFFeasibilityFooterText = (tag: string): JSX.Element => {
  return SFDEAD[tag] ? SFDEAD[tag]?.footer : defaultFooter;
};
export const getSFFeasibilityHeaderText = (tag: string): string => {
  return SFDEAD[tag] ? SFDEAD[tag].header : defaultHeader;
};
