import useActiveProject from "../_shared/hooks/useProject/useActiveProject";
import React, {useMemo, useState} from "react";
import NatSwitch from "../_shared/generics/switch";
import {Utilities} from "../../database/firebase";
import styled from "styled-components";
import {DESTRUCTIVE_FILL} from "../_shared/colors";
import {PROJECT_COLLECTION_KEY} from "@natomas-org/core";
import useActiveUser from "../_shared/hooks/useCustomer/useActiveUser";
import {useCurrentUser} from "../_shared/hooks/useCurrentUser";

export const ComplexPricingToggle = () => {
  const {loggedInUser} = useCurrentUser();
  const {id, projectPriceSettings} = useActiveProject();
  const [revealCosts, setRevealCosts] = useState<boolean>(
    projectPriceSettings?.complex_pricing_enabled === true
  );
  const options = useMemo(() => {
    return [
      {id: "true", label: "Yes"},
      {
        id: "false",
        label: "No",
        activeColor: DESTRUCTIVE_FILL,
      },
    ];
  }, []);
  return (
    <Container>
      <Label>Complex Pricing</Label>
      <NatSwitch
        options={options}
        activeOption={{
          id: revealCosts?.toString(),
          label: revealCosts ? "Yes" : "No",
        }}
        onOptionChange={(selectedOption) => {
          const result = selectedOption.id === "true";
          setRevealCosts(result);
          return Utilities.setDocumentWithMerge(
            Utilities.collection(PROJECT_COLLECTION_KEY),
            id,
            {
              settings: {
                pricing_mode: {
                  edited_timestamp: new Date().getTime(),
                  edited_by_email: loggedInUser?.email,
                  complex_pricing_enabled: result,
                },
              },
            }
          );
        }}
      />
    </Container>
  );
};

const Label = styled.h4`
  margin: 0;
  padding: 0 8px;
  white-space: nowrap;
  font-size: 16px;
`;

const Container = styled.div`
  margin: 0;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
