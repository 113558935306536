import React from "react";
import {ProposalViewerEditorContainer} from "../TimelineEditor/styles";
import {RichTextEditor} from "../../../../../../../_shared/generics/rich-text-editor/RichTextEditor";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import {useForm} from "react-hook-form";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {updateQualificationsAndAssumptions} from "../../../../../../../../database/firebase/api/project";
import {NatFlex} from "../../../../../../../_shared/generics/flex/NatFlex";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../../_shared/generics/label/NatLabel";
import {
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {deepEquals} from "@natomas-org/core";
import {
  ProposalEditorElementPreviewWrapper,
  QUALIFICATIONS_AND_ASSUMPTIONS_PREVIEW_TITLE,
} from "../QualificationsAndAssumptionsPreviewTitle";
import {QualificationsAndAssumptions} from "../../../../../../../portal/views/ProposalBudgetView/components/Presentation/QualificationsAndAssumptions/QualificationsAndAssumptions";

const DEFAULT_QA_TEXT = `
  &#8226; Villa will handle all aspects of local and state permitting and unit design responsibilities.<br/>
  &#8226; All units to be in compliance with the federal HUD code (not Modular Code or CBC).<br/>
  &#8226; Accessible compliant code design is not included in this proposal, requirement was unknown at the time of proposal.<br/>
  &#8226; Two standard exterior landings are included per unit in this proposal. Standard landings measure 3’-6”x3’-6” at both entrances. Landings are constructed out of redwood, do not include railings, and depending on grade elevation, will have 2-3 risers.<br/>
  &#8226; Decking boards shall be composite material.<br/>
  &#8226; No curb and gutter, paving, parking, landscaping, hardscaping, or any other sitework is included in this proposal.<br/>
  &#8226; Allowance is given as part of the project contingency.<br/>
  &#8226; This proposal assumes that Villa is both providing the units and the associated site services required to install the units. Villa will only supply the units if they are awarded both the contract for the supply of the units and the associated site and construction services.<br/>
  &#8226; This proposal makes no representation that these prices or costs will be available at the time of final construction contract.<br/>
  &#8226; The construction services and material markets are in constant fluctuation and final costs will be determined at the time of final construction contract after permits are approved.<br/>
  &#8226; All designs and images shown in this proposal are conceptual and not actual representations of the final designs.<br/>
  &#8226; All designs to be confirmed in Preconstruction.<br/>
  &#8226; Utilities to be confirmed in Preconstruction-Design.<br/>
  &#8226; Relocation and repair or irrigation lines excluded, if conflicting.<br/>
  &#8226; Villa will work with the Property Manager and Site Maintenance teams to make sure that the irrigation lines are capped during construction.<br/>
  &#8226; Local power company infrastructure upgrade, if required.<br/>
  `;
export const QualificationsAndAssumptionsEditor: React.FC = () => {
  const {id: projectId, mergedCustomerProjectInfos} = useActiveProject();
  const {metadata} = mergedCustomerProjectInfos ?? {};
  const initialValues = {
    content_html: metadata?.qualificationsAndAssumptions ?? "",
  };
  const {watch, setValue, getValues} = useForm({
    defaultValues: initialValues,
  });

  return (
    <NatFlex FULL_WIDTH COLUMN NOWRAP>
      <ProposalViewerEditorContainer>
        <NatFlex FULL_WIDTH COLUMN>
          <NatFlex
            FULL_WIDTH
            ROW
            NOWRAP
            VERTICAL_ALIGN={NatVerticalAlign.CENTER}
          >
            <NatLabel
              label={"Qualifications & Assumptions"}
              type={NatLabelType.H3}
              weight={NatLabelWeight.MEDIUM}
            />
            <NatButton
              style={{width: "fit-content"}}
              label={"Save"}
              size={NatSize.SMALL}
              spinnerEnabled={true}
              clickEvent={() => {
                return updateQualificationsAndAssumptions(
                  projectId,
                  getValues()?.content_html
                );
              }}
            />
            <NatButton
              style={{width: "fit-content"}}
              label={"Reset to Default"}
              option={StyleOption.DESTRUCTIVE}
              size={NatSize.SMALL}
              spinnerEnabled={false}
              disabled={deepEquals(getValues()?.content_html, DEFAULT_QA_TEXT)}
              clickEvent={() => {
                setValue("content_html", DEFAULT_QA_TEXT);
              }}
            />
          </NatFlex>
          <NatFlex FULL_WIDTH COLUMN NOWRAP>
            <RichTextEditor
              containerProps={{style: {width: "100%", height: "100%"}}}
              value={watch("content_html")}
              onChange={(value) => {
                setValue("content_html", value);
              }}
            />
          </NatFlex>
        </NatFlex>
      </ProposalViewerEditorContainer>

      <ProposalEditorElementPreviewWrapper
        title={QUALIFICATIONS_AND_ASSUMPTIONS_PREVIEW_TITLE}
      >
        <QualificationsAndAssumptions />
      </ProposalEditorElementPreviewWrapper>
    </NatFlex>
  );
};
