import React, {useContext, useMemo} from "react";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../_shared/generics/label/NatLabel";
import {priceTextFromMicros} from "../../../../../../studio/_shared/helper";
import {GRANITE} from "../../../../../../_shared/colors";
import {ProposalSummarySectionPhase1} from "./components/ProposalSummarySectionPhase1";
import {ProposalSummarySectionPhase2} from "./components/ProposalSummarySectionPhase2";
import {ProposalSummarySectionPhase3} from "./components/ProposalSummarySectionPhase3";
import {
  IconPosition,
  NatHorizontalAlign,
  NatSize,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {StyledBudgetSummaryHR} from "./components/styles";
import styled from "styled-components";
import {
  EMBEDDED_PROPOSAL,
  PROPOSAL_GENERATE_PDF,
} from "../../../../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../SSDDashboardView/styles/globals";
import {FULL_SCREEN_WIDTH_REM} from "../../../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";
import {ProposalHighlightNote} from "./components/ProposalHighlightNote";
import {ProjectCostCard} from "../../ProjectCostCard";
import {useUrl} from "../../../../../../_shared/hooks/useUrl";
import {useProjectBudget} from "../../../../../../_shared/hooks/useProjectBudget/useProjectBudget";
import {GenericLoadingComponent} from "../../generic/GenericLoadingComponent";
import {NatButton} from "../../../../../../_shared/generics/button";
import {NatRefreshIcon} from "../../../../../../_shared/icon/icons";
import {useCurrentUser} from "../../../../../../_shared/hooks/useCurrentUser";
import {useIsAdminSystem} from "../../../../../../_shared/hooks/useIsAdminSystem";

export const B2BProposalSummary = (props: {presentationView: boolean}) => {
  const {site} = useActiveProject();
  const embeddedProposal = useContext(EMBEDDED_PROPOSAL);
  const {
    totalUnitQuantity,
    projectBudget,
    refreshBudget,
    unitSelectionSummaryString,
  } = useProjectBudget();
  const {isAdmin} = useCurrentUser();
  const isAdminSystem = useIsAdminSystem();
  const {pathRoot} = useUrl();
  const totalProjectContingency = useMemo(() => {
    return projectBudget?.additionals_total?.price ?? 0;
  }, [projectBudget?.additionals_total?.price]);

  const totalProjectPriceMicros = useMemo(() => {
    const totalPrice = projectBudget?.getTotalProjectPrice();
    if (
      !totalPrice ||
      totalPrice?.price === undefined ||
      totalPrice?.price === null
    ) {
      return null;
    }
    return totalPrice?.price;
  }, [projectBudget]);
  const totalProjectPriceText = useMemo(() => {
    if (totalProjectPriceMicros === null) {
      return "-";
    }
    return priceTextFromMicros(totalProjectPriceMicros, "min");
  }, [totalProjectPriceMicros]);
  const totalProjectPriceWithContingencyText = useMemo(() => {
    if (totalProjectPriceMicros === null) {
      return "-";
    }
    return priceTextFromMicros(
      totalProjectPriceMicros + totalProjectContingency,
      "accurate"
    );
  }, [totalProjectContingency, totalProjectPriceMicros]);
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);

  // Show the cost card if summary is hidden via !site?.visible
  // But, don't hide if within the admin system and not presenting
  if (projectBudget === null || !projectBudget?.getTotalProjectPrice()) {
    return <GenericLoadingComponent />;
  }
  if (
    !site?.visible &&
    !(pathRoot === "admin" && !props.presentationView && !embeddedProposal)
  ) {
    return <ProjectCostCard />;
  }
  return (
    <ProposalSummaryWrapper
      presentationView={props.presentationView}
      style={
        isGeneratingPDF
          ? {width: `${FULL_SCREEN_WIDTH_REM * 0.95}rem`}
          : undefined
      }
    >
      <ProposalSummaryContent>
        <NatFlex FULL_WIDTH HORIZONTAL_ALIGN={NatHorizontalAlign.LEFT}>
          <NatLabel
            label={"Total Project Estimate: "}
            type={isGeneratingPDF ? NatLabelType.H3 : NatLabelType.H2}
          />
          <NatLabel
            label={totalProjectPriceText}
            type={isGeneratingPDF ? NatLabelType.H3 : NatLabelType.H2}
            weight={NatLabelWeight.BOLD}
          />
          {isAdmin && isAdminSystem && (
            <div
              style={{
                width: "fit-content",
                flexWrap: "nowrap",
              }}
            >
              <NatButton
                label={"Refresh Budget"}
                icon={{
                  icon: <NatRefreshIcon />,
                  iconPosition: IconPosition.ONLY,
                }}
                clickEvent={refreshBudget}
                spinnerEnabled={true}
                size={NatSize.XSMALL}
                option={StyleOption.SECONDARY}
              />
            </div>
          )}
        </NatFlex>
        {unitSelectionSummaryString && (
          <NatLabel
            label={unitSelectionSummaryString}
            type={isGeneratingPDF ? NatLabelType.P3 : NatLabelType.H4}
            weight={NatLabelWeight.REGULAR}
            style={{color: GRANITE, margin: "0 0"}}
          />
        )}
        <StyledBudgetSummaryHR />
        <ProposalSummarySectionPhase1 />
        <ProposalSummarySectionPhase2 />
        <ProposalSummarySectionPhase3 />
        <div style={{padding: "1.25rem 0"}}>
          <NatLabel
            style={{
              margin: "0 0",
              paddingTop: "0.75rem",
              paddingBottom: "0.5rem",
              paddingLeft: "0",
              paddingRight: "0",
            }}
            label={
              <span style={{fontFamily: FONT_FAMILY_MEDIUM}}>
                Project Contingency:{" "}
                <span style={{fontFamily: FONT_FAMILY_BOLD}}>
                  {priceTextFromMicros(totalProjectContingency, "accurate")}
                </span>
              </span>
            }
            weight={NatLabelWeight.BOLD}
            type={NatLabelType.P1}
          />
          <NatLabel
            style={{margin: "0 0", padding: "0 0", color: GRANITE}}
            label={
              <span>
                The project contingency figure is{" "}
                <span style={{fontFamily: FONT_FAMILY_BOLD}}>
                  {priceTextFromMicros(
                    totalProjectContingency / totalUnitQuantity,
                    "accurate"
                  )}
                </span>{" "}
                per unit.
              </span>
            }
            type={NatLabelType.P3}
          />
          <NatLabel
            style={{margin: "0 0", padding: "0 0", color: GRANITE}}
            label={
              <span>
                The total project budget including contingency would be{" "}
                <span style={{fontFamily: FONT_FAMILY_BOLD}}>
                  {totalProjectPriceWithContingencyText}
                </span>
                .
              </span>
            }
            type={NatLabelType.P3}
          />
        </div>
        <ProposalHighlightNote
          label={
            "*Villa Phase 1 Payment is the only payment required to start your project"
          }
          presentationStyling={true}
        />
      </ProposalSummaryContent>
    </ProposalSummaryWrapper>
  );
};

const ProposalSummaryWrapper = styled.div<{presentationView?: boolean}>`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  page-break-after: ${(props) =>
    props.presentationView && false ? "always" : undefined};
  break-after: ${(props) =>
    props.presentationView && false ? "always" : undefined};
`;
const ProposalSummaryContent = styled.div`
  @media print {
    padding: 0 2rem;
    width: 100%;
  }
  width: 100%;
  height: 100%;
`;
