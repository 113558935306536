import React from "react";
import {NavigationPaths} from "../../_shared/hooks/useNavigation/paths";
import {StyleOption} from "../../_shared/generics/_shared";
import {useNavigation} from "../../_shared/hooks/useNavigation";
import {EmptyPage} from "../../_shared/generics/empty-page";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {DesktopFixedAdminContainer} from "../_shared/components/StudioContent/styles";
import {AdminStudioController} from "../../_shared/generics/admin-controller/AdminStudioController";

export const BlankStudio = () => {
  const {to} = useNavigation();
  const {isAdmin} = useCurrentUser();

  if (isAdmin) {
    return (
      <>
        <DesktopFixedAdminContainer>
          <AdminStudioController />
        </DesktopFixedAdminContainer>
        <EmptyPage
          title={`Couldn't find product.`}
          buttons={[
            {
              label: "Explore Catalog",
              clickEvent: () => to(NavigationPaths.CATALOG),
              option: StyleOption.PRIMARY_BLACK,
            },
          ]}
        />
      </>
    );
  }

  return (
    <EmptyPage
      title={`Before you can start designing,\nchoose an ADU from our catalog.`}
      buttons={[
        {
          label: "Explore Catalog",
          clickEvent: () => to(NavigationPaths.CATALOG),
          option: StyleOption.PRIMARY_BLACK,
        },
      ]}
    />
  );
};
