import styled from "styled-components";

export const RowStringInputBox = styled.input`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem calc(0.75rem - 2px);
  line-height: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 1px;
  &:focus {
    margin: 0;
    outline: none !important;
    border: 2px solid rgb(38, 132, 255);
  }
  transition: all 75ms ease;
`;

export const RowStringTextAreaBox = styled.textarea`
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  margin: 1px;
  &:focus {
    margin: 0;
    outline: none !important;
    border: 2px solid rgb(38, 132, 255);
  }
  transition: margin, outline, border 75ms ease;
`;
