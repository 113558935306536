import {getPriceSetTable} from "./MatrixTable/tables/ProductModifierTable/PriceSetTable/helper";
import {getDescriptionTable} from "./MatrixTable/tables/ProductModifierTable/DescriptionTable/helper";
import {getProductInfoTable} from "./MatrixTable/tables/ProductInfoTable/helper";
import {
  AdminProductCatalogMatrixModes,
  AdminProductCatalogMatrixSubModes,
  AdminProductCatalogMatrixTitleModes,
} from "../../../../../_shared/slices/types/Store";
import {DesignStudioModifier} from "@natomas-org/core";

export const transposeTable = (m: any) =>
  m[0].map((x: any, i: number) => m.map((x: any) => x[i]));

const hasProducts = (products: any) => {
  return !(products?.length === 0);
};

export const getProductModifierTable = (
  products: any,
  modifiers: DesignStudioModifier[],
  productModifiers: any,
  matrixEdits: any,
  options: {
    matrixMode: AdminProductCatalogMatrixModes;
    matrixSubMode: AdminProductCatalogMatrixSubModes;
    matrixTitleMode: AdminProductCatalogMatrixTitleModes;
    transpose?: boolean;
  }
) => {
  const {matrixMode, matrixSubMode, matrixTitleMode, transpose} = options;
  let edits = matrixEdits ?? {};
  if (!hasProducts(products)) return [];
  let table = [];
  if (matrixMode === AdminProductCatalogMatrixModes.PRICE_SET) {
    table = getPriceSetTable(
      products,
      modifiers,
      productModifiers,
      edits,
      matrixSubMode,
      matrixTitleMode
    );
  }
  if (matrixMode === AdminProductCatalogMatrixModes.DESCRIPTIONS) {
    table = getDescriptionTable(products, modifiers, productModifiers, edits);
  }
  if (transpose) {
    return transposeTable(table);
  }
  return table;
};

export const getProductTable = (
  products: any,
  matrixEdits: any,
  options: {
    matrixMode: AdminProductCatalogMatrixModes;
    matrixSubMode: AdminProductCatalogMatrixSubModes;
    transpose?: boolean;
  }
) => {
  const {matrixMode, transpose} = options;
  let edits = matrixEdits ?? {};
  if (!hasProducts(products)) return [];
  let table: any[][] = [];
  if (matrixMode === AdminProductCatalogMatrixModes.PRODUCT_INFO) {
    table = getProductInfoTable(products, edits);
  }
  if (transpose) {
    return transposeTable(table);
  }
  return table;
};
