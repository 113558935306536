import React from "react";
import {isBlankString} from "@natomas-org/core";
import {
  CategoryHeader,
  StudioPageDisplayDescription,
  StudioPageWrapper,
} from "./styles";
import {StudioCategorySections} from "./StudioCategorySections";
import {StudioCategoryProps} from "./StudioCategoryShell/helper";
import {StudioCategoryTop} from "./StudioCategoryTop";

const getSections = (category: any) => {
  if (category?.sections) return getSortSections(category.sections);
  else {
    return null;
  }
};

const getSortSections = (sections: any) => {
  return Object.keys(sections)
    .map((key) => {
      return {...sections[key], id: key};
    })
    .sort(function (a, b) {
      return a.index > b.index ? 1 : -1;
    });
};

const CategoryDisplayDescription = (props: {category: any}) => {
  const {category} = props;
  if (!isBlankString(category.displayDescription)) {
    return (
      <StudioPageDisplayDescription>
        {category.displayDescription}
      </StudioPageDisplayDescription>
    );
  } else {
    return <></>;
  }
};

export const StudioCategory = (props: StudioCategoryProps) => {
  const {
    category,
    formik,
    index,
    isMobile,
    key,
    mode,
    selectModifier,
    step,
    product,
    structure,
    section,
    priceMode,
  } = props;

  if (!category) {
    console.log("Unknown studio category");
    return null;
  }
  return (
    <StudioPageWrapper isMobile={isMobile} key={key}>
      <div
        className="current-description-wrapper show-scroll"
        id="description-wrapper"
        style={{width: "100%"}}
      >
        <div className="custom-container" style={{padding: 0}}>
          <div id="top-anchor" />
          <div style={{paddingBottom: "0px"}}>
            <CategoryHeader>
              <StudioCategoryTop
                step={step}
                product={product}
                structure={structure}
                title={category?.title}
                section={section}
                isMobile={isMobile}
              />
            </CategoryHeader>
            <CategoryDisplayDescription category={category} />
            <form>
              <StudioCategorySections
                categoryInfo={category}
                sections={getSections(category)}
                formik={formik}
                isMobile={isMobile}
                index={index}
                clickEvent={selectModifier}
                mode={mode}
                priceMode={priceMode}
              />
            </form>
          </div>
        </div>
      </div>
    </StudioPageWrapper>
  );
};
