import styled from "styled-components";
import {DIVIDER_COLOR} from "../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../SSDDashboardView/styles/globals";

export const Title = styled.h1`
  font-size: 1.5rem;
  font-family: ${FONT_FAMILY_BOLD};
  padding: 2rem 0;
  border-bottom: ${DIVIDER_COLOR} solid 1px;
`;

export const Description = styled.h1`
  font-size: 1rem;
  padding: 2rem 0;
  border-bottom: ${DIVIDER_COLOR} solid 1px;
`;

export const TwoColumnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
`;

export const HelperText = styled.div<{
  size: string;
  bold?: boolean;
  align?: string;
}>`
  font-family: ${(props) =>
    props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  text-align: ${(props) => props.align};
  font-size: ${(props) => props.size};
  color: gray;
  margin-bottom: 0.85rem;
`;

export const DesignBlurb = styled.div`
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: 100%;
  }
`;

export const NoProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 22rem;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
`;

export const WaitingGraphic = styled.img`
  max-height: 20rem;
`;
