import styled from "styled-components";
import {ERROR_FILL, ERROR_PRIMARY} from "../../../../_shared/colors";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const WarningTagContainer = styled.div`
  background-color: ${ERROR_FILL};
  color: ${ERROR_PRIMARY};
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.25rem;
  border-radius: 1rem;
  padding: 0.25rem;
  margin: 0.5rem 0;
  width: 100%;
  cursor: default;
`;

export const WarningText = styled.p`
  margin: 0 0.25rem;
  font-variant: all-small-caps;
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 1.25rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  flex-direction: row;
  padding: 0;
`;
