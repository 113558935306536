import styled from "styled-components";
import {BLACK, GRAY, WHITE} from "../../../../../_shared/colors";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../SSDDashboardView/styles/globals";

const NAT_CART_ITEM_ROW_HEIGHT = "140px";

export const NatCartItemRow = styled.div<{isMobile?: boolean}>`
  align-items: flex-start;
  background-color: ${WHITE};
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  width: 100%;
  height: ${(props) => (props.isMobile ? "auto" : NAT_CART_ITEM_ROW_HEIGHT)};
`;

export const NatCartItemRowImage = styled.div`
  border-radius: 16px;
  height: inherit;
  min-width: 210px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
`;

export const NatCartItemRowInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: inherit;
`;

export const NatCartItemRowInfoRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
`;

export const NatCartItemRowLinks = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props?.isMobile ? "space-between" : "flex-start"};
  align-items: center;
  gap: ${(props) => (props?.isMobile ? "8px" : "16px")};
  width: 100%;
  flex-wrap: wrap;
  margin-top: auto;
  font-size: ${(props) => (props?.isMobile ? "11px" : "13px")};
  * {
    font-size: ${(props) => (props?.isMobile ? "11px" : "13px")};
  }
`;

export const NatCartItemRowInfoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: baseline;
`;
export const NatCartItemRowInfoHeader = styled.p`
  color: ${BLACK};
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 16px;
  margin: 0;
  padding: 0;
  user-select: none;
`;

export const NatCartItemRowInfoHeaderSubtext = styled.p`
  color: ${BLACK};
  font-family: ${FONT_FAMILY_REGULAR};
  margin: 0;
  padding: 0;
  font-size: 11px;
  user-select: none;
`;

export const NatCartItemRowLink = styled.a<{color?: string}>`
  color: ${(props) => props.color ?? BLACK};
  text-decoration: underline;
  text-decoration-color: ${(props) => props.color ?? BLACK};
  cursor: pointer;
  margin: 0;
  padding: 0;
  user-select: none;
`;

export const NatCartItemRowLinkSeparator = styled.p`
  color: ${BLACK};
  margin: 0;
  padding: 0;
  user-select: none;
`;

export const NatCartItemCheckbox = styled.input`
  cursor: pointer;
  margin: auto;
`;

export const NatCartItemQuantityController = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const NatCartItemQuantityControllerButton = styled.div<{
  disabled: boolean;
}>`
  user-select: none;
  * {
    user-select: none;
  }
  cursor: ${(props) => (props?.disabled ? "default" : "pointer")};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  color: ${(props) => (props?.disabled ? GRAY : BLACK)};
  border: 1px solid ${(props) => (props?.disabled ? GRAY : BLACK)};
  border-radius: 12px;
  font-size: 13px;
`;

export const NatCartItemQuantity = styled.div`
  user-select: none;
  * {
    user-select: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: 23px;
  font-size: 13px;
  color: ${BLACK};
  border: 1px solid ${BLACK};
  border-radius: 4px;
`;

export const NatCartNotification = styled.div<{color: string}>`
  user-select: none;
  * {
    user-select: none;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 2px 4px;
  width: fit-content;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  border-radius: 4px;
`;
