import {useDispatch, useSelector} from "react-redux";
import {INavigationWarning, IStore} from "../../slices/types/Store";
import {getRequestedPathFunction, NavigationPaths} from "./paths";
import {
  resetWarning,
  setLatestNavigationRequestArgs,
  setLatestNavigationRequestType,
  setShowWarning,
  setWarning,
} from "../../slices/NavigationSlice";
import {getNavigationWarningModal} from "./helper";
import {useEffect} from "react";
import {leaveMap} from "./PublicMap/helpers";
import {useIsPublicApp} from "../useIsPublicApp";

const FORCE_ALL_NAVIGATION = true;

export const useNavigation = () => {
  const dispatch = useDispatch();
  const {warning, show} = useSelector((state: IStore) => state.navigation);
  const isPublicApp = useIsPublicApp();
  // Returns boolean representing if it will immediately navigate
  const to = (request: NavigationPaths, ...requestProps: any[]): boolean => {
    if (FORCE_ALL_NAVIGATION) {
      return dangerouslyNavigateTo(request, ...requestProps);
    }

    console.log("navigate", request);
    const toRequest = getRequestedPathFunction(request);
    if (
      request !== NavigationPaths.PROPERTY_MAP &&
      request !== NavigationPaths.STUDIO &&
      request !== NavigationPaths.COMPLETED_PROJECTS_MAP
    ) {
      leaveMap(isPublicApp);
    }
    if (!!warning && !!toRequest) {
      console.log("warning for navigation!", request);
      dispatch(setShowWarning(true));
      dispatch(setLatestNavigationRequestType(request));
      dispatch(setLatestNavigationRequestArgs([...requestProps]));
      return false;
    } else if (!!toRequest) {
      console.log("Committing navigation", request);
      toRequest(...requestProps);
      return true;
    }
    return false;
  };

  // Ignores warnings, should be used carefully
  const dangerouslyNavigateTo = (
    request: NavigationPaths,
    ...requestProps: any[]
  ): boolean => {
    console.log("Force navigation to", request);
    const toRequest = getRequestedPathFunction(request);
    if (
      request !== NavigationPaths.PROPERTY_MAP &&
      request !== NavigationPaths.STUDIO &&
      request !== NavigationPaths.COMPLETED_PROJECTS_MAP
    ) {
      leaveMap(isPublicApp);
    }

    resetNavigationWarning();

    if (!!toRequest) {
      console.log("Committing navigation", request);
      toRequest(...requestProps);
      return true;
    }
    return false;
  };

  // Returns boolean representing if it will immediately navigate
  const navigateBack = (): boolean => {
    const toRequest = NavigationPaths.BACK;
    if (!!warning) {
      dispatch(setShowWarning(true));
      dispatch(setLatestNavigationRequestType(NavigationPaths.BACK));
      dispatch(setLatestNavigationRequestArgs([]));
      return false;
    } else if (toRequest) {
      to(NavigationPaths.BACK, []);
      return true;
    }
    return false;
  };

  const getNavigationWarning = (): JSX.Element | null => {
    return getNavigationWarningModal(show, warning);
  };

  const setNavigationWarning = (warning: INavigationWarning) => {
    dispatch(setWarning(warning));
  };

  const resetNavigationWarning = () => {
    dispatch(resetWarning());
  };

  useEffect(() => {
    window.addEventListener("beforeunload", resetNavigationWarning);

    return () => {
      window.removeEventListener("beforeunload", resetNavigationWarning);
    };
  }, []);

  return {
    to,
    navigateBack,
    resetWarning: resetNavigationWarning,
    setWarning: setNavigationWarning,
    getWarningModal: getNavigationWarning,
    dangerouslyNavigateTo,
    hasWarning: !!warning,
  };
};
