import React, {useEffect, useState} from "react";
import {useImageFinder} from "../../../../../hooks/useImageFinder";
import {ImageGallery} from "../../_shared/ImageGallery";

export interface SearchProductGroupModifiersProps {
  showEditDetails: boolean;
  selectImage: ((id: string) => any) | null;
}

export const SearchProductGroupModifiers = (
  props: SearchProductGroupModifiersProps
) => {
  const {showEditDetails, selectImage} = props;
  const {searchModifiers} = useImageFinder();
  const [imageIds, setImageIds] = useState<string[]>([]);

  useEffect(() => {
    getModifierImageIds();
  }, [searchModifiers]);

  const getModifierImageIds = () => {
    if (!searchModifiers) {
      return;
    }
    let ids: string[] = [];
    // Get all images from all modifiers
    Object.values(searchModifiers)?.forEach((modifier: any) => {
      if (modifier?.imageId && !ids?.includes(modifier?.imageId)) {
        ids.push(modifier?.imageId);
      }
    });
    setImageIds(ids);
  };

  return (
    <ImageGallery
      showEditDetails={showEditDetails}
      imageIds={imageIds}
      selectImage={selectImage}
      isEnd={true}
    />
  );
};
