export const ModifierConditionalBasicOption = {
  label: "Basic",
  value: "basic",
};

export const ModifierConditionalSingleColorOption = {
  label: "Single Color",
  value: "single-color",
};

export const ModifierConditionalTwoColorOption = {
  label: "Two Color",
  value: "two-color",
};

export const ModifierConditionalImageOption = {
  label: "Image",
  value: "image",
};

export const ModifierConditionalFieldOptions = [
  ModifierConditionalBasicOption,
  ModifierConditionalSingleColorOption,
  ModifierConditionalTwoColorOption,
  ModifierConditionalImageOption,
];
