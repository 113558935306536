import {Column, ColumnText} from "../../styles/SiteWorkTableStyles";
import React from "react";
import {
  BudgetEntry,
  fullPriceTextFromMicros,
  IPriceTextOptions,
  priceTextFromMicros,
} from "@natomas-org/core";

export interface ITableRowDisplayProps {
  displayCost?: boolean;
  displayDescription?: boolean;
  addDisclaimer?: string;
  disclaimerRed?: boolean;
  disclaimerGap?: boolean;
  priceDisplayOptions?: IPriceTextOptions;
  style?: React.CSSProperties;
}

export const TableRow = (props: {
  data: BudgetEntry | string[];
  displayProps?: ITableRowDisplayProps;
  onClick?: () => void;
  style?: React.CSSProperties;
}) => {
  const {data, displayProps, style} = props;
  return (
    <ColumnElements entry={data} displayProps={displayProps} style={style} />
  );
};

const ColumnElements = (props: {
  entry: BudgetEntry | string[];
  displayProps?: ITableRowDisplayProps;
  style?: React.CSSProperties;
}) => {
  const {entry, displayProps, style} = props;
  const priceDisplayOptions = displayProps?.priceDisplayOptions;
  const elements: JSX.Element[] = [];
  if (Array.isArray(entry)) {
    entry.forEach((element, index) => {
      elements.push(
        <ColumnElement
          text={element}
          key={`col-${index}`}
          columnStyle={style}
        />
      );
    });
    return (
      <React.Fragment>
        {elements.map((element) => {
          return element;
        })}
      </React.Fragment>
    );
  }
  // Element Name
  elements.push(
    <ColumnElement
      text={entry.displayTitle ?? ""}
      key={"col-title"}
      columnStyle={style}
    />
  );

  if (displayProps?.displayCost) {
    // Cost column
  }
  // Price column
  let priceText = priceTextFromMicros(entry.price?.price ?? undefined, "min");
  if (priceDisplayOptions != undefined && entry.price?.price) {
    priceText = fullPriceTextFromMicros(
      entry.price?.price,
      priceDisplayOptions
    );
  }
  elements.push(
    <ColumnElement text={priceText} key={"col-price"} columnStyle={style} />
  );
  if (displayProps?.displayDescription) {
    elements.push(
      <ColumnElement
        text={entry.note ?? null}
        key={"col-note"}
        columnStyle={style}
      />
    );
  }
  return (
    <React.Fragment>
      {elements.map((element) => {
        return element;
      })}
    </React.Fragment>
  );
};
const ColumnElement = (props: {
  text: string | string[] | null;
  columnStyle?: React.CSSProperties;
}) => {
  let text: JSX.Element | string = "";
  if (props.text) {
    if (Array.isArray(props.text)) {
      text = (
        <>
          {props.text.map((textEl) => {
            return <div>{textEl}</div>;
          })}
        </>
      );
    } else {
      text = props.text;
    }
  }
  return (
    <Column style={props?.columnStyle}>
      <ColumnText>{text}</ColumnText>
    </Column>
  );
};
