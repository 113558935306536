import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IConfiguration, mergeObjects} from "@natomas-org/core";

export interface IDesign {
  id: string;
  configuration: IConfiguration | any;
  snapshot: any;
  map: any;
}

interface UpdateDesignProps {
  id: string;
}

interface UpdateDesignConfigurationProps extends UpdateDesignProps {
  configuration: any;
}

interface UpdateDesignSnapshotProps extends UpdateDesignProps {
  snapshot: any;
}

interface UpdateDesignMapProps extends UpdateDesignProps {
  map: any;
}

export interface IDesignSlice {
  [designId: string]: IDesign;
}

const getInitialState = (): IDesignSlice => {
  return {};
};

const getDesignById = (state: IDesignSlice, id: string): IDesign => {
  return state[id] ?? {id: id};
};

const designSlice = createSlice({
  name: "design",
  initialState: getInitialState(),
  reducers: {
    updateDesignConfiguration: (
      state,
      action: PayloadAction<UpdateDesignConfigurationProps>
    ) => {
      const {id, configuration} = action.payload;
      const design = getDesignById(state, id);
      design.configuration = mergeObjects(design.configuration, configuration);
      design.configuration.selectedModifiers = configuration.selectedModifiers;
      state[id] = design;
    },
    updateDesignSnapshot: (
      state,
      action: PayloadAction<UpdateDesignSnapshotProps>
    ) => {
      const {id, snapshot} = action.payload;
      const design = getDesignById(state, id);
      if (!snapshot) {
        design.snapshot = undefined;
      } else {
        design.snapshot = mergeObjects(design.snapshot, snapshot);
      }
      state[id] = design;
    },
    updateDesignMap: (state, action: PayloadAction<UpdateDesignMapProps>) => {
      const {id, map} = action.payload;
      const design = getDesignById(state, id);
      design.map = mergeObjects(design.map, map);
      state[id] = design;
    },
  },
});

export const designReducer = designSlice.reducer;
export const {
  updateDesignConfiguration,
  updateDesignSnapshot,
  updateDesignMap,
} = designSlice.actions;
