const onPaste = (column: any, data: any) => {
  let newValue = data?.value;
  console.log("Pasted value: ", column, data);
  // if ([BudgetColumns.PRICE_LOW, BudgetColumns.COST_LOW].includes(column)) {
  //   const valueString = data?.rowData?.[column]?.toString();
  //   if (valueString) {
  //     newValue = getPriceMicrosFromText(valueString) / 100;
  //   }
  // }
  console.log("New value: ", newValue);
  data.rowData[column] = newValue;
  console.log(data);
  return data?.rowData;
};

export default onPaste;
