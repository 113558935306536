import React from "react";
import {NatCartNotification} from "../styles";
import {ERROR_COLOR, VALID_COLOR} from "../../../../../../_shared/colors";

const CartSectionItemNotification = (props: {
  isUpToDate: boolean;
  isLocked: boolean;
}) => {
  const {isLocked, isUpToDate} = props;

  if (isLocked) {
    return (
      <NatCartNotification color={VALID_COLOR}>
        Design finalized; contact your Villa representative to make changes
      </NatCartNotification>
    );
  }
  if (!isUpToDate) {
    return (
      <NatCartNotification color={ERROR_COLOR}>
        Outdated, new designs available
      </NatCartNotification>
    );
  }
  return null;
};

export default CartSectionItemNotification;
