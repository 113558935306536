export const getTableColumns = () => [
  {
    Header: `Category`,
    accessor: "categoryId",
  },
  {
    Header: "Modifier Group",
    accessor: "modifierGroupId",
  },
  {
    Header: "Modifier",
    accessor: "title",
  },
  {
    Header: "Price",
    accessor: "priceInMicros",
  },
];
