import React from "react";
import {SmartImage} from "../../generics/image/SmartImage";
import styled from "styled-components";
import {DesignButton, ImageContainer, TextContainer} from "./styles";

export const ImageTextButton = (props) => {
  return (
    <FullWidthImageContainer
      id={"modifier-" + props.buttonId}
      onClick={props.onClick}
      active={props.isSelected === true}
      disabled={props.isDisabled}
    >
      <ImageContainer>
        <SmartImage imageId={props.imageId} size={"300"} alt="stuff" />
      </ImageContainer>
      <TextContainer>
        <p>{props.buttonText}</p>
        <p>{props.price}</p>
      </TextContainer>
    </FullWidthImageContainer>
  );
};

const FullWidthImageContainer = styled(DesignButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 18px;
`;
