import {
  getCostPlusPricingForSite,
  getPreConTotalPrice,
  getSiteWorkAndPreConTotalPrice,
  getSiteWorkTotalPrice,
  IConfigurationPricingOptions,
  IConfigurationSite,
  ICostPlusSitePricing,
  ISitePricing,
  ISitePricingMicros,
  ProjectPriceMode,
} from "@natomas-org/core";

export const getPricingForSite = (
  configurationSite: IConfigurationSite,
  priceMode: ProjectPriceMode,
  priceOptions: IConfigurationPricingOptions
): ISitePricing | ICostPlusSitePricing => {
  if (priceMode === ProjectPriceMode.CostPlus) {
    return getCostPlusPricingForSite(configurationSite, priceOptions);
  }
  return getDTCPricingForSite(configurationSite, priceOptions);
};

const getDTCPricingForSite = (
  configurationSite: IConfigurationSite,
  priceOptions: IConfigurationPricingOptions
): ISitePricing => {
  const sanitizedPriceOptions = {...priceOptions, opFraction: undefined};
  const preConPrice = getPreConTotalPrice(
    configurationSite,
    ProjectPriceMode.DTC,
    sanitizedPriceOptions
  );
  const siteWorkPrice = getSiteWorkTotalPrice(
    configurationSite,
    ProjectPriceMode.DTC,
    sanitizedPriceOptions
  );
  const totalSiteWorkAndPreConPrice = getSiteWorkAndPreConTotalPrice(
    configurationSite,
    ProjectPriceMode.DTC,
    sanitizedPriceOptions
  );
  let priceMicros: ISitePricingMicros = {
    preConPriceMicros: preConPrice.price ?? 0,
    siteWorkPriceMicros: siteWorkPrice.price ?? 0,
    preConAndSiteWorkPriceMicros: totalSiteWorkAndPreConPrice.price ?? 0,
  };
  return {
    totalPreConPriceDetails: preConPrice,
    totalSiteWorkPriceDetails: siteWorkPrice,
    totalPreConAndSiteWorkDetails: totalSiteWorkAndPreConPrice,
    siteTotalMicros: priceMicros,
  };
};
