import React from "react";

export interface FactoryInventoryTextInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: string;
}

const FactoryInventoryTextInput = (props: FactoryInventoryTextInputProps) => {
  const {id, onChange, initialValue} = props;
  return (
    <input
      id={id}
      name={id}
      type="text"
      onChange={(e) => {
        const value: string = e?.target?.value as unknown as string;
        onChange(value);
      }}
      value={initialValue}
      spellCheck={true}
    />
  );
};

export default FactoryInventoryTextInput;
