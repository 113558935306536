import React from "react";
import {Page, Text, View} from "@react-pdf/renderer";
import {styles} from "./styles";
import {IPageProps} from "./template";

interface IQAAPageProps extends IPageProps {
  qaa?: string;
}
const QAAPage = (props: IQAAPageProps) => {
  if (!props.qaa) {
    return null;
  } else {
    console.log(props.qaa);
    return (
      <Page orientation={"landscape"} style={styles.page} key={"qaa-page"}>
        <Text style={styles.title}>Qualifications and Assumptions</Text>
        <View style={styles.view}>
          <Text style={styles.textSmall}>{props.qaa}</Text>
        </View>
        {props.footer}
      </Page>
    );
  }
};

export default QAAPage;
