import {TeamApi} from "@natomas-org/villa-nexus-client";
import {AxiosInstance} from "axios";
import {getAxiosInstance} from "../setup";

export class TeamService {
  private axiosInstance;
  private client;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new TeamApi(undefined, undefined, this.axiosInstance);
  }

  async getMemberPhoto(email: string) {
    const response = await this.client.getTeamMemberPhoto(email);
    if (response?.data) {
      return response.data;
    } else {
      return null;
    }
  }
}

export const getTeamService = async () => {
  const axiosInstance = await getAxiosInstance();
  return new TeamService(axiosInstance);
};
