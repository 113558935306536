import React, {useMemo} from "react";
import styled from "styled-components";
import {
  IProjectNoteDetails,
  IProjectTaskStatus,
  IProjectTaskType,
  Project,
  ProjectNoteType,
  TeamMemberRole,
} from "@natomas-org/core";
import {RichTextEditor} from "../../../_shared/generics/rich-text-editor/RichTextEditor";
import {DocumentFilePreviewer} from "../../../_shared/generics/documents/components/DocumentFilePreviewer";
import {ProjectNoteImages} from "../../../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/ProjectNotesTab/ProjectNote/styles";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../_shared/generics/label/NatLabel";
import {useActiveProjectDocuments} from "../../../_shared/hooks/useProject/useActiveProjectDocuments";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";
import {NatButton, NatLink} from "../../../_shared/generics/button";
import {createOrUpdateProjectNote} from "../../../../database/firebase/api/project";
import {createPopup} from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import {useActiveProjectNotes} from "../../../_shared/hooks/useProject/useActiveProjectNotes";
import ReactTooltip from "react-tooltip";
import {NatVerticalAlign} from "../../../_shared/generics/_shared";

export interface ProjectNoteFullViewProps extends IProjectNoteDetails {}

export const ProjectNoteFullView = (props: ProjectNoteFullViewProps) => {
  const {content_html, type, id, details} = props;
  const {id: pid, info} = useActiveProject();
  const {user_id} = useCurrentCustomer();
  const projectPrimaryContact =
    Project.getPrimaryContactTeamMember(info, {
      fallbackRole: TeamMemberRole.PC,
    }) ??
    Project.getPrimaryContactTeamMember(info, {
      fallbackRole: TeamMemberRole.HC,
    });
  const {projectDocuments} = useActiveProjectDocuments();
  const {getProjectNoteById} = useActiveProjectNotes();
  const documents = useMemo(() => {
    const notes: IProjectNoteDetails | null = id
      ? getProjectNoteById(id)
      : null;
    const documentIds = notes?.documentIds;
    return projectDocuments?.filter((documents) => {
      return documentIds?.includes(documents.document_id);
    });
  }, [getProjectNoteById, id, projectDocuments]);
  if (!pid) {
    return null;
  }

  const isTaskCompleted =
    details?.status === IProjectTaskStatus.COMPLETED ||
    !!details?.completion_timestamp;

  const markTaskAsComplete = () => {
    return createOrUpdateProjectNote({
      projectId: pid,
      noteId: id,
      userId: user_id,
      note: {
        details: {
          ...details,
          completion_timestamp: new Date().getTime(),
          status: IProjectTaskStatus.COMPLETED,
        },
      } as IProjectNoteDetails,
    });
  };

  const completeTitle = () => {
    let title: string;
    switch (details?.type) {
      case IProjectTaskType.TYPEFORM:
        title = "Complete";
        break;
      default:
        title = "Approve";
        break;
    }

    if (isTaskCompleted) {
      title = title + "d";
    }

    return title;
  };

  const completeAction = () => {
    switch (details?.type) {
      case IProjectTaskType.TYPEFORM:
        const {toggle} = createPopup(details?.task_details?.typeform_id ?? "", {
          onSubmit: (response) => {
            return markTaskAsComplete();
          },
        });
        return toggle();
      default:
        return markTaskAsComplete();
    }
  };

  return (
    <Container>
      <RichTextEditor
        readOnly={true}
        onChange={() => {}}
        containerProps={{style: {maxHeight: "50vh"}}}
        value={content_html}
      />
      <div hidden={documents.length === 0}>
        <NatLabel
          label={"Attachments"}
          type={NatLabelType.P1}
          weight={NatLabelWeight.MEDIUM}
        />
        <ProjectNoteImages>
          {documents?.map((document, index: number) => {
            return (
              <DocumentFilePreviewer
                enablePresentation={true}
                key={document?.document_id}
                document={document}
                documentList={documents}
                type={"thumbnail"}
              />
            );
          })}
        </ProjectNoteImages>
      </div>
      <div hidden={type !== ProjectNoteType.TASK}>
        <br />
        <NatFlex VERTICAL_ALIGN={NatVerticalAlign.CENTER}>
          <NatButton
            label={completeTitle()}
            disabled={isTaskCompleted}
            spinnerEnabled={details?.type === IProjectTaskType.GENERIC}
            clickEvent={() => {
              return completeAction();
            }}
          />
          {projectPrimaryContact?.personInfo?.contact?.email && (
            <div
              data-for={
                "tooltip-" + projectPrimaryContact?.personInfo?.contact?.email
              }
              data-tip
            >
              <NatLink
                label={
                  "Have a question/comment? Reach out to your Villa representative."
                }
                link={`mailto:${projectPrimaryContact?.personInfo?.contact?.email}`}
                hidden={!projectPrimaryContact?.personInfo?.contact?.email}
              />
              <ReactTooltip
                id={
                  "tooltip-" + projectPrimaryContact?.personInfo?.contact?.email
                }
                effect="solid"
                place={"right"}
                multiline={false}
                getContent={() => {
                  return (
                    <>{projectPrimaryContact?.personInfo?.contact?.email}</>
                  );
                }}
              />
            </div>
          )}
        </NatFlex>
      </div>
    </Container>
  );
};

const Container = styled.div``;
