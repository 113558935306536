import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IImageFinderSlice} from "./types/Store";

const getInitialState = (): IImageFinderSlice => {
  return {
    selectedImageId: undefined,
    searchCategories: {},
    searchModifiers: {},
    zoomModifier: 0,
  };
};

const imageFinderSlice = createSlice({
  name: "imageFinder",
  initialState: getInitialState(),
  reducers: {
    setSelectedImageId: (state, action: PayloadAction<string | undefined>) => {
      const targetId = action.payload;
      // If we are selecting the same image, unselect it instead.
      state.selectedImageId =
        state.selectedImageId !== targetId ? targetId : undefined;
    },
    setSearchCategories: (state, action: PayloadAction<any>) => {
      const categories = action.payload;
      if (categories) {
        Object.values(categories)?.forEach((c: any) => {
          if (c?.id) {
            state.searchCategories[c.id] = c;
          }
        });
      }
    },
    setSearchModifiers: (state, action: PayloadAction<any>) => {
      const modifiers = action.payload;
      if (modifiers) {
        Object.values(modifiers)?.forEach((m: any) => {
          if (m?.id) {
            state.searchModifiers[m.id] = m;
          }
        });
      }
    },
    setZoomModifier: (state, action: PayloadAction<number>) => {
      state.zoomModifier = action.payload;
    },
  },
});

export const imageFinderReducer = imageFinderSlice.reducer;
export const {
  setSelectedImageId,
  setZoomModifier,
  setSearchCategories,
  setSearchModifiers,
} = imageFinderSlice.actions;
