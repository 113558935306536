import React, {useEffect, useMemo, useState} from "react";
import {SearchAllContentWrapper} from "./styles";
import {SearchAllBreadcrumb} from "./SearchAllBreadcrumb";
import {SearchAllProductGroup} from "./SearchAllProductGroup";
import {SearchAllRoot} from "./SearchAllRoot";
import {SearchProductGroupProduct} from "./SearchProductGroupProduct";
import {SearchProductGroupProducts} from "./SearchProductGroupProducts";
import {SearchProductGroupModifiers} from "./SearchProductGroupModifiers";
import {SearchProductGroupCategories} from "./SearchProductGroupCategories";
import {SearchProductGroupCategory} from "./SearchProductGroupCategory";
// import {useImageFinder} from "../../../../hooks/useImageFinder";
import {ImageFinderSectionProps} from "../index";
import {useFactoryLines} from "../../../../hooks/useProductCatalog/useFactoryLines/useFactoryLines";
import {tryFetchingCatalogFactory} from "../../../../../../root/FetchingManager";
import {useFactoryLine} from "../../../../hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {useImageFinder} from "../../../../hooks/useImageFinder";

export enum SearchAllStateOptions {
  ROOT,
  PRODUCT_GROUP,
  PRODUCTS,
  PRODUCT,
  CATEGORIES,
  CATEGORY,
  MODIFIERS,
}

export const SearchAllSection = (props: ImageFinderSectionProps) => {
  const {selectImage, showEditDetails} = props;
  const {unfilteredFactoryLineDictionary} = useFactoryLines();
  const {setImageFinderCategories, setImageFinderModifiers} = useImageFinder();
  const [mode, setMode] = useState<SearchAllStateOptions>(
    SearchAllStateOptions.ROOT
  );

  const [productGroupId, setProductGroupId] = useState<string | undefined>(
    undefined
  );
  const [versionId, setVersionId] = useState<string | undefined>(undefined);
  const {categories, modifiers} = useFactoryLine({
    factoryId: productGroupId,
    versionId: versionId,
  });

  useEffect(() => {
    setImageFinderCategories(categories);
    setImageFinderModifiers(modifiers);
  }, [
    categories,
    modifiers,
    setImageFinderCategories,
    setImageFinderModifiers,
  ]);

  const [categoryId, setCategoryId] = useState<string | undefined>(undefined);
  const [productId, setProductId] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!productGroupId) {
      setVersionId(undefined);
      return;
    }
    const draftVersionId =
      unfilteredFactoryLineDictionary[productGroupId]?.info?.draftVersionId;
    if (!draftVersionId) {
      return;
    }
    tryFetchingCatalogFactory(productGroupId, {
      versionId: draftVersionId,
      fullFetch: true,
    });
    setVersionId(draftVersionId);
  }, [productGroupId]);

  const SearchAllSection = useMemo(() => {
    if (mode === SearchAllStateOptions.ROOT) {
      return (
        <SearchAllRoot
          setProductGroupId={setProductGroupId}
          setMode={setMode}
        />
      );
    } else if (mode === SearchAllStateOptions.PRODUCT_GROUP) {
      return (
        <SearchAllProductGroup
          setEditDetails={showEditDetails}
          productGroupId={productGroupId}
          setMode={setMode}
          selectImage={selectImage}
        />
      );
    } else if (mode === SearchAllStateOptions.PRODUCT) {
      if (!productId || !productGroupId) {
        return <></>;
      }
      return (
        <SearchProductGroupProduct
          showEditDetails={showEditDetails}
          productId={productId}
          productGroupId={productGroupId}
          selectImage={selectImage}
        />
      );
    } else if (mode === SearchAllStateOptions.PRODUCTS) {
      return (
        <SearchProductGroupProducts
          productGroupId={productGroupId}
          setMode={setMode}
          setProductId={setProductId}
        />
      );
    } else if (mode === SearchAllStateOptions.CATEGORY) {
      return (
        <SearchProductGroupCategory
          showEditDetails={showEditDetails}
          categoryId={categoryId}
          selectImage={selectImage}
        />
      );
    } else if (mode === SearchAllStateOptions.CATEGORIES) {
      return (
        <SearchProductGroupCategories
          setMode={setMode}
          setCategoryId={setCategoryId}
          productGroupId={productGroupId}
        />
      );
    } else if (mode === SearchAllStateOptions.MODIFIERS) {
      return (
        <SearchProductGroupModifiers
          showEditDetails={showEditDetails}
          selectImage={selectImage}
        />
      );
    }
    return <></>;
  }, [mode, categoryId, productGroupId, productId]);

  return (
    <>
      <SearchAllBreadcrumb
        mode={mode}
        setMode={setMode}
        productGroupId={productGroupId}
        setProductGroupId={setProductGroupId}
        productId={productId}
        categoryId={categoryId}
      />
      {/*This takes into account height of breadcrumb*/}
      <SearchAllContentWrapper>{SearchAllSection}</SearchAllContentWrapper>
    </>
  );
};
