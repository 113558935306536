import React, {useEffect, useState} from "react";
import {NatomasSlider, RangeSlider, SliderContent} from "./styles";
import {usePage} from "../../hooks/usePage";

export interface NatSliderProps {
  label: string;
  disabled: boolean;
  values: number[];
  bounds: number[];
  valueTextFormatter: (value: any) => string;
  setValues: (values: number[]) => void;
  minDistanceBetweenThumbPercentInteger?: number;
  resetValues?: () => void;
  widthRem?: number;
}

export const NatSlider = (props: NatSliderProps) => {
  const {
    label,
    disabled,
    valueTextFormatter,
    values,
    setValues,
    bounds,
    minDistanceBetweenThumbPercentInteger,
    resetValues,
  } = props;
  const minDistance = minDistanceBetweenThumbPercentInteger ?? 22;
  const {isNatMobile} = usePage();
  const [localValues, setLocalValues] = useState<number[]>(values);
  useEffect(() => {
    setLocalValues(values);
  }, [values]);
  const inputValueToPercent = (value: number): number => {
    const range = bounds[1] - bounds[0];
    const fractionOfTotalRange = (value - bounds[0]) / range;
    return fractionOfTotalRange * 100;
  };

  const percentToInputValue = (percent: number): number => {
    const range = bounds[1] - bounds[0];
    return bounds[0] + (percent / 100) * range;
  };

  const handleChange = (e: any, newValue: any, activeThumb: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      let valueToSet = percentToInputValue(newValue[0]);
      if (
        valueToSet >
        localValues[1] - (percentToInputValue(minDistance) - bounds[0])
      ) {
        valueToSet =
          localValues[1] - (percentToInputValue(minDistance) - bounds[0]);
      }
      setLocalValues([valueToSet, localValues[1]]);
    } else {
      let valueToSet = percentToInputValue(newValue[1]);
      if (
        valueToSet <
        localValues[0] + (percentToInputValue(minDistance) - bounds[0])
      ) {
        valueToSet =
          localValues[0] + (percentToInputValue(minDistance) - bounds[0]);
      }
      setLocalValues([localValues[0], valueToSet]);
    }
  };

  const onCommitChange = (
    event: React.SyntheticEvent | Event,
    value: number | number[]
  ) => {
    if (
      resetValues &&
      typeof value != "number" &&
      value?.length > 1 &&
      value[0] === 0 &&
      value[1] === 100
    ) {
      return resetValues();
    }
    setValues(localValues);
  };
  const formatter = (value: number) => {
    return valueTextFormatter(Math.round(percentToInputValue(value)));
  };

  return (
    <NatomasSlider>
      <SliderContent widthRem={props.widthRem}>
        <RangeSlider
          getAriaLabel={() => label}
          value={[
            inputValueToPercent(localValues[0]),
            inputValueToPercent(localValues[1]),
          ]}
          onChange={handleChange}
          onChangeCommitted={onCommitChange}
          valueLabelDisplay="on"
          valueLabelFormat={formatter}
          getAriaValueText={formatter}
          disabled={disabled}
          mobile={isNatMobile.toString()}
        />
      </SliderContent>
    </NatomasSlider>
  );
};
