import {usePage} from "../../../../../_shared/hooks/usePage";
import React, {useContext, useEffect, useState} from "react";
import {BudgetAdminContext} from "../../ProposalBudgetSummaryView";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";
import {
  BUDGET_SECTION_ADDITIONAL,
  BudgetEntry,
  getBreakdownTitleText,
  LineItemType,
  sumBudgetEntries,
  TBudgetSection,
} from "@natomas-org/core";

import {MainContainer, RowContainer} from "../../styles/SiteWorkTableStyles";
import {isAdmin} from "../../../../../_shared/user/UserUtils";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {TableRow} from "./TableRow";
import {TableEditorButtonContainer} from "./styles";
import {BudgetColumns, GenericBudgetSummaryEditor} from "./GenericBudgetEditor";
import {FONT_FAMILY_BOLD} from "../../../SSDDashboardView/styles/globals";
import {GRAY_BORDER} from "../../../../../_shared/colors";
import {saveBudgetChanges} from "../../../../../../database/firebase/api/project/budget";
import GenericBudgetRows from "./GenericBudgetRows";

export interface IGenericBudgetSummaryProps {
  columns: BudgetColumns[];
  viewOnly?: boolean;
  isFullView?: boolean;
  isAdmin?: boolean;
  budgetEntries?: BudgetEntry[];
  refreshBudget?: () => void;
  clearBudget?: () => void;
}

export const GenericBudgetSummary = (
  props: IGenericBudgetSummaryProps & {
    displayType: TBudgetSection | null;
  }
) => {
  const {isNatMobile} = usePage();
  const {viewOnly, isFullView, isAdmin: isAdminProp} = props;
  const adminContext = useContext(BudgetAdminContext);
  const admin = adminContext ?? isAdminProp;
  const {site, projectPriceMode, id: projectId} = useActiveProject();
  const currentUser = useSelector((state: IStore) => state.global.user);
  const [editing, setEditing] = useState(false);
  const type: TBudgetSection | null = props.displayType;
  const [visibleTable, setVisibleTable] = useState<BudgetEntry[] | undefined>(
    undefined
  );
  useEffect(() => {
    if (props.budgetEntries) {
      const propsData = props.budgetEntries.filter((entry) => {
        return entry?.price?.price && entry.price.price !== 0;
      });
      setVisibleTable([
        ...propsData,
        new BudgetEntry({
          displayTitle: `${
            type ? getBreakdownTitleText(type, projectPriceMode) : "Project"
          } Total`,
          note: ["ESTIMATE ONLY", "Final Price to be confirmed at ATB"],
          price: sumBudgetEntries(propsData),
          id: LineItemType.SECTION_TOTAL,
          bucket: type,
        }),
      ]);
      return;
    } else {
      setVisibleTable(undefined);
    }
  }, [projectId, type, props.budgetEntries, site, projectPriceMode]);

  if (isAdmin(currentUser) && editing) {
    return (
      <React.Fragment>
        <GenericBudgetSummaryEditor
          {...props}
          columns={props.columns}
          saveModifications={(entries, baseBudget) => {
            setEditing(false);
            setVisibleTable(undefined);
            return saveBudgetChanges(
              projectId,
              entries,
              baseBudget,
              () => {
                props.refreshBudget?.();
              },
              props.clearBudget
            );
          }}
        />
        <TableEditorButtonContainer>
          <NatButton
            clickEvent={() => {
              setEditing(false);
            }}
            label={"Discard Edits"}
            type={"button"}
            option={StyleOption.SECONDARY}
          />
        </TableEditorButtonContainer>
      </React.Fragment>
    );
  }
  const columns = ["Item", "Price"];
  if (type !== BUDGET_SECTION_ADDITIONAL) {
    columns.push("Description");
  }
  return (
    <MainContainer>
      {visibleTable && (
        <RowContainer key={"row-title"} isNatMobile={isNatMobile}>
          <TableRow
            data={columns}
            style={{
              backgroundColor: GRAY_BORDER,
              fontFamily: FONT_FAMILY_BOLD,
            }}
          />
        </RowContainer>
      )}
      <GenericBudgetRows
        visibleTable={visibleTable}
        includeDescription={type !== BUDGET_SECTION_ADDITIONAL}
      />
      {isAdmin(currentUser) && admin && isFullView && !viewOnly ? (
        <TableEditorButtonContainer>
          <NatButton
            clickEvent={() => {
              setEditing(true);
            }}
            label={`Edit ${
              type ? getBreakdownTitleText(type, projectPriceMode) : "Budget"
            } Line Items`}
            type={"button"}
            option={StyleOption.SECONDARY}
          />
        </TableEditorButtonContainer>
      ) : null}
    </MainContainer>
  );
};
