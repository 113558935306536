import styled from "styled-components";
import {StandardElement} from "../../../../_shared/generics/page/components/PageElement/styles";

export const ProductFlexList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  ${StandardElement} {
    margin-bottom: 1rem;
  }
  &:nth-child(
      ${(props) =>
          Math.floor(
            props?.theme?.Page?.columnCount / props.theme?.Card?.columnWidth
          )}n
    ) {
    margin-right: 0;
  }
`;

export const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 8px;
`;
