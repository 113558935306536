import React from "react";
import {PortalHeader} from "../../_shared/components/PortalHeader";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {
  CART_PAGE_ID,
  PROFILE_PAGE_ID,
} from "../../_shared/navigation/constants";
import {FPSiteMap} from "./FPSiteMapView/FPSiteMap";
import {FPBudget} from "./FPBudgetView/FPBudget";
import {FPUnitView} from "./FPUnitView/FPUnitView";
import {AccountView} from "../AccountView";
import {HCPTitleNav} from "./FPTitleNav";
import {FPDocumentsAndTasksView} from "./FPDocumentsAndTasksView/FPDocumentsAndTasksView";
import {FPMeetingNotes} from "./FPMeetingNotes";
import {FPOverviewView} from "./FPOverviewView/FPBudget";
import CartView from "../CartView";

export const FPDashboardLayout = (props: {pageId: string | null}) => {
  const fullWidth = useDynamicValue(fullColumnWidths);
  if (props.pageId === PROFILE_PAGE_ID) {
    return (
      <>
        <PortalHeader title={<>Dashboard</>} showAppSwitcher={false} />
        <AccountView />
      </>
    );
  }

  if (props.pageId === CART_PAGE_ID) {
    return (
      <>
        <PortalHeader title={<>Cart</>} showAppSwitcher={false} />
        <CartView />
      </>
    );
  }

  return (
    <>
      <PortalHeader title={<HCPTitleNav />} showAppSwitcher={false} />
      <FPOverviewView />
      <FPDocumentsAndTasksView width={fullWidth} />
      <FPMeetingNotes />
      <FPUnitView width={fullWidth} />
      <FPSiteMap width={fullWidth} />
      <FPBudget width={fullWidth} />
      <br />
      <br />
      <br />
    </>
  );
};
