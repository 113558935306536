import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";

export const EmployeeAccessControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

export const EmployeeAccessControl = styled.div`
  cursor: pointer;
  user-select: none;
  label {
    user-select: inherit;
    cursor: inherit;
    font-family: ${FONT_FAMILY_MEDIUM};
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
