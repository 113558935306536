import React from "react";
import {IDesignUpgradeItemInfo} from "@natomas-org/core";
import {CartItemDesignCategoryItem} from "./styles";
import {priceTextFromMicros} from "../../../../../../studio/_shared/helper";

interface CartItemDesignCategoryItemPreviewProps {
  item: IDesignUpgradeItemInfo;
}

const CartItemDesignCategoryItemPreview = (
  props: CartItemDesignCategoryItemPreviewProps
) => {
  const {item} = props;

  const itemPriceString = !item.price
    ? "Included"
    : priceTextFromMicros(item.price, "accurate");

  return (
    <CartItemDesignCategoryItem>
      <div>{item.title}</div>
      <div>{itemPriceString}</div>
    </CartItemDesignCategoryItem>
  );
};

export default CartItemDesignCategoryItemPreview;
