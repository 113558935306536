import React, {useEffect, useState} from "react";
import {useProduct} from "../../../../../hooks/useProductCatalog/useProduct";
import {ImageGallery} from "../../_shared/ImageGallery";

export interface SearchProductGroupProductProps {
  showEditDetails: boolean;
  selectImage: ((id: string) => any) | null;
  productId: string;
  productGroupId: string;
}

export const SearchProductGroupProduct = (
  props: SearchProductGroupProductProps
) => {
  const {showEditDetails, productId, productGroupId, selectImage} = props;
  const {product} = useProduct(productId, productGroupId);
  const [imageIds, setImageIds] = useState<string[]>([]);
  useEffect(() => {
    getProductImageIds();
  }, [product]);

  const getProductImageIds = () => {
    if (!product) {
      setImageIds([]);
      return;
    }
    let ids: string[] = [];

    // Add primary image
    if (product?.imageId) {
      ids.push(product?.imageId);
    }

    // Add catalog images
    if (product?.images && product?.images?.length > 0) {
      product?.images?.forEach((image: any) => {
        if (image?.imageId && !ids.includes(image.imageId)) {
          ids.push(image.imageId);
        }
      });
    }
    setImageIds(ids);
  };

  return (
    <ImageGallery
      showEditDetails={showEditDetails}
      imageIds={imageIds}
      selectImage={selectImage}
      isEnd={true}
    />
  );
};
