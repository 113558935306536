import React from "react";
import {CartRowContainer} from "../../../styles";
import {
  NatCartItemRowInfoRow,
  NatCartItemRowLink,
  NatCartItemRowLinkSeparator,
} from "../styles";
import useActivitySliceDispatcher from "../../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import {ICartItem} from "@natomas-org/core";

interface CartSectionItemInfoContentProps {
  cartItem: ICartItem;
  label: string;
  upgradePrice: string;
  previewItem: (cartItem: ICartItem) => void;
  editDisplayCodeItem: (cartItem: ICartItem) => void;
  QuantityComponent: React.ReactNode | undefined;
}

const CartSectionItemInfoContent = (props: CartSectionItemInfoContentProps) => {
  const {
    cartItem,
    label,
    upgradePrice,
    previewItem,
    editDisplayCodeItem,
    QuantityComponent,
  } = props;
  const {setActiveCartItemById} = useActivitySliceDispatcher();

  if (!QuantityComponent) {
    return (
      <CartRowContainer
        style={{
          justifyContent: "space-between",
          alignItems: "flex-end",
          width: "100%",
          fontSize: "11px",
        }}
      >
        <NatCartItemRowLink
          onClick={() => {
            setActiveCartItemById(cartItem?.id);
            editDisplayCodeItem(cartItem);
          }}
        >
          {label}
        </NatCartItemRowLink>
        <NatCartItemRowLink
          onClick={() => {
            setActiveCartItemById(cartItem?.id);
            previewItem(cartItem);
          }}
        >
          {upgradePrice}
        </NatCartItemRowLink>
      </CartRowContainer>
    );
  }

  return (
    <NatCartItemRowInfoRow>
      <CartRowContainer
        alternateGap={8}
        style={{
          fontSize: "13px",
        }}
      >
        <NatCartItemRowLink
          onClick={() => {
            setActiveCartItemById(cartItem?.id);
            editDisplayCodeItem(cartItem);
          }}
        >
          {label}
        </NatCartItemRowLink>
        <NatCartItemRowLinkSeparator>•</NatCartItemRowLinkSeparator>
        <NatCartItemRowLink
          onClick={() => {
            setActiveCartItemById(cartItem?.id);
            previewItem(cartItem);
          }}
        >
          {upgradePrice}
        </NatCartItemRowLink>
      </CartRowContainer>
      {QuantityComponent}
    </NatCartItemRowInfoRow>
  );
};

export default CartSectionItemInfoContent;
