import {ICartItem, ICustomerInfo} from "@natomas-org/core";
import {savePayloadToCustomer} from "./base";

export const updateCartLibrary = async (
  customer?: ICustomerInfo,
  cartItems?: ICartItem[]
): Promise<void> => {
  if (!customer || !cartItems || cartItems?.length === 0) {
    return;
  }
  const {user_id, cart_library} = customer;
  if (!user_id) {
    return;
  }
  const cartLibrary = cart_library?.filter((item: ICartItem) => {
    return !cartItems.filter((cartItem: ICartItem) => cartItem.id === item.id);
  });
  const payload = [...cartItems, ...(cartLibrary ?? [])];
  return savePayloadToCustomer(user_id, {cart_library: payload});
};

export const saveCartItemToCartLibrary = async (
  customer?: ICustomerInfo,
  cartItem?: ICartItem
): Promise<void> => {
  if (!customer || !cartItem) {
    return;
  }
  const {user_id, cart_library} = customer;
  if (!user_id) {
    return;
  }
  const payload = [...(cart_library ?? []), cartItem];
  return savePayloadToCustomer(user_id, {cart_library: payload});
};

export const saveCartItemsToCartLibrary = async (
  customer?: ICustomerInfo,
  cartItems?: ICartItem[]
): Promise<void> => {
  if (!customer || !cartItems || cartItems?.length === 0) {
    return;
  }
  const {user_id, cart_library} = customer;
  if (!user_id) {
    return;
  }
  const payload = [...(cart_library ?? []), ...cartItems];
  return savePayloadToCustomer(user_id, {cart_library: payload});
};

export const removeCartItemFromCartLibrary = async (
  customer?: ICustomerInfo,
  cartItemId?: string
): Promise<void> => {
  if (!customer || !cartItemId) {
    return;
  }
  const {user_id, cart_library} = customer;
  if (!user_id) {
    return;
  }
  const payload =
    cart_library?.filter((item: ICartItem) => item.id !== cartItemId) ?? [];
  return savePayloadToCustomer(user_id, {cart_library: payload});
};

export const removeCartItemsFromCartLibrary = async (
  customer?: ICustomerInfo,
  cartItemIds?: string[]
): Promise<void> => {
  if (!customer || !cartItemIds || cartItemIds?.length === 0) {
    return;
  }
  const {user_id, cart_library} = customer;
  if (!user_id) {
    return;
  }
  const payload =
    cart_library?.filter((item: ICartItem) => !cartItemIds.includes(item.id)) ??
    [];
  return savePayloadToCustomer(user_id, {cart_library: payload});
};
