import React from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../../../../_shared/slices/types/Store";
import {
  ProductOptionKey,
  ProductOptionValue,
} from "../../../../_shared/constants/ProductOption";
import {
  NewOptionContainer,
  OptionList,
  OptionListContainer,
  OptionRow,
} from "../styles";
import {NatButton} from "../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";

export const OptionValueList = (props: {
  currentValue: ProductOptionValue | null;
  setCurrentValue: any;
}) => {
  const {setCurrentValue, currentValue} = props;
  const optionValues = useSelector(
    (state: IStore) => state.options.optionValues
  );
  const formattedValues: ProductOptionValue[] = Object.values(optionValues);
  const sortedOptionKeys = formattedValues.sort(
    (a: ProductOptionValue, b: ProductOptionValue) => {
      let nameA = a.name.toLowerCase();
      let nameB = b.name.toLowerCase();
      return nameA.localeCompare(nameB);
    }
  );
  return (
    <OptionListContainer>
      <NewOptionContainer>
        <NatButton
          label={"Create New Value"}
          trackingId={"admin-product-options-create-key"}
          type={"button"}
          option={StyleOption.SECONDARY_ALT}
          clickEvent={() => setCurrentValue({})}
          size={NatSize.SMALL}
        />
      </NewOptionContainer>
      <OptionList>
        {sortedOptionKeys.map((k: ProductOptionKey) => {
          return (
            <OptionRow
              active={currentValue?.id === k.id}
              onClick={() => {
                setCurrentValue(k);
              }}
              key={k.id}
            >
              {k.name}
            </OptionRow>
          );
        })}
      </OptionList>
    </OptionListContainer>
  );
};
