import styled from "styled-components";

export const ConditionCellRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  column-gap: 0.5rem;
  transition: all 150ms;
`;

export const ConditionLabel = styled.div`
  font-style: italic;
  font-size: 0.8rem;
`;

export const FormattingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 0.5rem;
`;

export const ConditionActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.25rem;
  background-color: #8b9fe5;
  border-radius: 1rem;
`;

export const ConditionControllerContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
`;
