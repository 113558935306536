import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import {DESIGN_STUDIO_DISABLED_BUTTON} from "../../colors";

interface IDesignButtonProps {
  active: boolean;
  disabled: boolean;
}

const getBackgroundColor = (props: IDesignButtonProps) => {
  if (props.disabled) {
    return DESIGN_STUDIO_DISABLED_BUTTON;
  }
  if (props.active) {
    return "var(--accent-color-active)";
  }

  return "";
};

export const DesignButton = styled.div<IDesignButtonProps>`
  background: ${(props) => getBackgroundColor(props)};
  border: ${(props) =>
    props.active
      ? "2px solid var(--accent-color)"
      : "2px solid rgba(0, 0, 0, 0.2)"};
  border-radius: 10px;
  font-family: ${(props) =>
    props.active ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  //transition: 0.25s all;
  position: relative;
  min-height: 50px;

  &:hover {
    border: 2px solid var(--accent-color);
  }
`;

export const DesignSquareButton = styled.div<{active: boolean}>`
  cursor: pointer;
  border: ${(props) =>
    props.active ? "3px solid var(--accent-color)" : "3px solid #fff"};
  box-shadow: ${(props) => (props.active ? "0 0 0 2px #fff inset" : "")};
  border-radius: 10px;
`;

export const ImageContainer = styled.div<{width?: number}>`
  height: 100%;
  min-width: ${(props) => props.width ?? "50px"};
  position: relative;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  margin-top: 0;
  border: none;
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 16px;
  p {
    margin-bottom: 0;
  }

  p:first-child {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

export const MultiSelectCheckBox = styled.div<{active: boolean}>`
  min-height: unset;
  border: 1px solid #000000;
  box-sizing: content-box;
  border-radius: 2px;
  position: relative;
  height: 16px;
  width: 16px;
  top: 0;
  left: 10px;
  bottom: 0;
  margin: auto;
  margin-right: 8px;

  --background: #fff;
  --border: #d1d6ee;
  --border-active: #1e2235;
  --tick: #fff;
  transition: box-shadow 0.3s;
  text-align: center;

  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    position: relative;
    margin: auto;
    display: block;
    stroke: ${(props) =>
      props.active ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0)"};
    transition: stroke 0.3s ease-in-out;
  }

  box-shadow: ${(props) =>
    props.active ? "inset 0 0 0 11px black" : "inset 0 0 0 0 black"}; ;
`;
