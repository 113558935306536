import styled from "styled-components";
import {
  ADMIN_SELECTED,
  STEPH_SHADOW,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
  WHITE,
} from "../../../../../../../../../../../../_shared/colors";

const totalHeight = "598px";
const totalWidth = "1398px";
const listWidth = "300px";

export const ImportLibraryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: ${totalHeight};
  max-width: ${totalWidth};
  position: relative;
`;

export const ImportLibraryListSection = styled.div`
  width: ${listWidth};
  height: 100%;
  overflow-y: auto;
  position: relative;
`;
export const ImportLibraryListSectionController = styled.div`
  // Toggle is 36px height;
  padding: 8px;
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ImportLibraryListContainer = styled.div`
  height: calc(100% - 52px);
  width: 100%;
`;

export const ImportLibraryList = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ImportLibraryListItem = styled.div<{isSelected?: boolean}>`
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  padding: 8px;
  min-height: 28px;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${(props) => props.isSelected && VILLA_APPLE_GREEN};
  &:not(:last-child) {
    border-bottom: 1px solid #939393;
  }
  &:first-child {
    border-top: 1px solid #939393;
  }
  &:hover {
    background-color: ${(props) =>
      props.isSelected ? VILLA_APPLE_GREEN : VILLA_APPLE_GREEN_LIGHT_1};
  }
`;

export const ImportLibraryListItemPreviewContainer = styled.div`
  height: 100%;
  width: calc(${totalWidth} - ${listWidth});
  background-color: ${ADMIN_SELECTED};
  border-left: 1px solid #939393;
  overflow-y: auto;
`;

export const ImportLibraryListItemPreview = styled.div`
  padding: 24px;
  margin: 24px 24px 64px 24px;
  border-radius: 24px;
  background-color: ${WHITE};
`;

export const ImportConfirmationContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 8px;
  border-radius: 24px;
  background-color: ${WHITE};
  box-shadow: ${STEPH_SHADOW};
`;
