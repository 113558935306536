import React from "react";
import CustomerTypeForm from "../../1-container/forms/CustomerTypeForm";
import {ContentWrapper} from "./style";

const ConfirmCustomerTypeContent = (props: {
  setCustomerType: (value: string) => void;
}) => {
  return (
    <ContentWrapper>
      <CustomerTypeForm
        buttons={[]}
        onSubmit={(value) => {
          props.setCustomerType(value.form);
        }}
      />
    </ContentWrapper>
  );
};

export default ConfirmCustomerTypeContent;
