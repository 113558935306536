import {FactoryInventoryCatalogEditorKey} from "./interfaces";
import {FactoryInventoryInputType} from "../../_shared/FactoryInventoryInput";

export interface IFactoryInventoryEditorStructureValue {
  label: string;
  type: FactoryInventoryInputType;
}
export interface IFactoryInventoryEditorStructure {
  [id: string]: IFactoryInventoryEditorStructureValue;
}

export const FactoryInventoryCatalogEditorStructure: IFactoryInventoryEditorStructure =
  {
    [FactoryInventoryCatalogEditorKey.TITLE]: {
      label: "Unit Name",
      type: FactoryInventoryInputType.TEXT,
    },
    [FactoryInventoryCatalogEditorKey.TAGLINE]: {
      label: "Tagline",
      type: FactoryInventoryInputType.TEXT_BOX,
    },
    [FactoryInventoryCatalogEditorKey.BEDROOM_COUNT]: {
      label: "Bedroom Count",
      type: FactoryInventoryInputType.NUMBER,
    },
    [FactoryInventoryCatalogEditorKey.BATHROOM_COUNT]: {
      label: "Bathroom Count",
      type: FactoryInventoryInputType.NUMBER,
    },
    [FactoryInventoryCatalogEditorKey.SQUARE_FOOTAGE]: {
      label: "Square Footage",
      type: FactoryInventoryInputType.NUMBER,
    },
    [FactoryInventoryCatalogEditorKey.DIMENSIONS]: {
      label: "Dimensions",
      type: FactoryInventoryInputType.DIMENSIONS,
    },
    [FactoryInventoryCatalogEditorKey.BASE_DESC]: {
      label: "Base Description",
      type: FactoryInventoryInputType.TEXT_BOX,
    },
    [FactoryInventoryCatalogEditorKey.INTERIOR_DESC]: {
      label: "Interior Section Description",
      type: FactoryInventoryInputType.TEXT_BOX,
    },
    [FactoryInventoryCatalogEditorKey.EXTERIOR_DESC]: {
      label: "Exterior Section Description",
      type: FactoryInventoryInputType.TEXT_BOX,
    },
    [FactoryInventoryCatalogEditorKey.TIMELINE]: {
      label: "Timeline",
      type: FactoryInventoryInputType.TIMELINE,
    },
    [FactoryInventoryCatalogEditorKey.DEFAULT_IMAGE_ID]: {
      label: "Display Image",
      type: FactoryInventoryInputType.SINGLE_IMAGE,
    },
    [FactoryInventoryCatalogEditorKey.IMAGES]: {
      label: "Images",
      type: FactoryInventoryInputType.MULTI_GROUP_IMAGES,
    },
  };
