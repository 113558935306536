import React, {useMemo, useState} from "react";
import {ProjectSwitcherModal} from "../../../../_shared/components/ProjectSwitcherModal";
import {CartViewSwitchProjectsLink} from "../../styles";
import useActivitySlice from "../../../../../_shared/hooks/useActivity/useActivitySlice";
import useCustomerProjects from "../../../../../_shared/hooks/useProject/useCustomerProjects";

const CartViewProjectSwitcher = () => {
  const [isSwitchingProjects, setIsSwitchingProjects] = useState(false);
  const {activeCustomerId, activeProjectId} = useActivitySlice();
  const {projectIds} = useCustomerProjects(activeCustomerId);

  const otherProjectIds = useMemo(() => {
    return projectIds?.filter((id) => id !== activeProjectId) ?? [];
  }, [projectIds, activeProjectId]);

  if (!otherProjectIds?.length) {
    return null;
  }
  return (
    <>
      <CartViewSwitchProjectsLink
        onClick={() => setIsSwitchingProjects((current) => !current)}
      >
        Switch Projects
      </CartViewSwitchProjectsLink>
      <ProjectSwitcherModal
        show={isSwitchingProjects}
        handleClose={() => {
          setIsSwitchingProjects(false);
        }}
      />
    </>
  );
};

export default CartViewProjectSwitcher;
