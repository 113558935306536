import styled from "styled-components";
import {BLACK, GRAY, VILLA_BLUE, WHITE} from "../../colors";
import {FONT_FAMILY_REGULAR} from "../../../portal/views/SSDDashboardView/styles/globals";

export const NatomasSwiper = styled.div<{
  hasTitles?: boolean;
  borderRadius?: string;
  alternativeButtons?: boolean;
  isNatMobile?: boolean;
}>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${(props) => props.borderRadius || 0};
  .swiper-container {
    height: 100%;
    width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-disabled {
      display: none;
    }
    &:hover {
      transform: scale(1.1);
      transition: transform 125ms ease-out;
    }
    ${(props) =>
      props.alternativeButtons
        ? props.isNatMobile
          ? `padding: 1rem;
      background: ${VILLA_BLUE};
      border-radius: 1rem;
      width: 1rem;
      height: 1rem;
    `
          : `padding: 2rem;
      background: ${VILLA_BLUE};
      border-radius: 2rem;
      width: 2rem;
      height: 2rem;
    `
        : ``}
    &:after {
      color: ${WHITE};
      font-size: ${(props) =>
        props.isNatMobile ? "0.75rem" : "1.25rem"} !important;
      ${(props) =>
        !props.alternativeButtons &&
        `text-shadow: 0 0 0.25rem ${GRAY};
    `}
    }
  }
  .swiper-pagination-bullets-dynamic {
    height: auto;
    width: 100% !important;
    padding: 0.5rem 0;
  }
  .swiper-pagination-bullet {
    color: ${BLACK};
    ${(props) =>
      props.hasTitles &&
      `
      padding: 0.5rem 1rem;
      height: 2.375rem;
      font-size: 0.875rem;
      font-family: ${FONT_FAMILY_REGULAR};
      width: auto;
    `};
    border: 1px solid black;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
  }
  .swiper-pagination-bullet-active {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: ${(props) => (props.hasTitles ? VILLA_BLUE : WHITE)};
    color: ${WHITE};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.35);
  }
`;
