import {
  ICatalogTag,
  IProduct,
  IProductGroupDetails,
  Product,
  ProductGroup,
} from "@natomas-org/core";
import {PercentMatchTag} from "../../../../_shared/components/ProductCard/components/PercentMatchTag";
import {NatFlex} from "../../../../../_shared/generics/flex/NatFlex";
import React from "react";
import {
  MatchLabels,
  useProductRecommendations,
} from "../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useProductRecommendations";
import {getFactoryLinePublishedVersionDetails} from "../../../../../_shared/hooks/useProductCatalog/useFactoryLines/helper";
import {useFactorySlice} from "../../../../../_shared/hooks/useProductCatalog/useFactorySlice";
import {useProductFitsOnLot} from "../../../../../_shared/hooks/useProductParcelData";
import {InventoryTag} from "../../../../_shared/components/ProductCard/components/InventoryTag";
import {FitsOnLotTag} from "../../../../_shared/components/ProductCard/components/FitsOnLotTag";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../_shared/generics/label/NatLabel";
import {BLACK, VILLA_LILAC} from "../../../../../_shared/colors";
import {NatCheckNoCircle} from "../../../../../_shared/icon/icons";
import {NatVerticalAlign} from "../../../../../_shared/generics/_shared";
import {FONT_FAMILY_REGULAR} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const ProductViewTags: React.FC<{product: IProduct}> = ({product}) => {
  const percentRecommendations = useProductRecommendations();
  const factorySlice = useFactorySlice();
  const {productFitsOnLotData} = useProductFitsOnLot({productId: product?.id});

  let productGroupDetails: IProductGroupDetails | undefined;
  if (product?.productGroupId) {
    productGroupDetails =
      getFactoryLinePublishedVersionDetails(
        factorySlice,
        product?.productGroupId
      ) ?? undefined;
  }

  let percentRecommendation: number | undefined;
  let tagMatches: (MatchLabels | string)[] = [];
  if (product?.id && percentRecommendations[product?.id]) {
    percentRecommendation = percentRecommendations[product?.id]?.percentScore;
    tagMatches = percentRecommendations[product?.id]?.matches ?? [];
  }
  const tagsToDisplay: JSX.Element[] = [];
  if (percentRecommendation) {
    tagsToDisplay.push(
      <PercentMatchTag labels={[percentRecommendation]} position={"relative"} />
    );
  }
  if (
    Product.isInventory(product) ||
    (productGroupDetails &&
      ProductGroup.containsTag(
        productGroupDetails,
        ICatalogTag.QUICKEST_DELIVERY
      ))
  ) {
    tagsToDisplay.push(<InventoryTag />);
  }
  if (
    product?.id &&
    productFitsOnLotData &&
    productFitsOnLotData[product?.id]?.productInfoAndPlacement?.productFits
  ) {
    tagsToDisplay.push(<FitsOnLotTag />);
  }
  if (tagsToDisplay.length < 1) {
    return null;
  }
  return (
    <NatFlex COLUMN FULL_WIDTH FULL_HEIGHT style={{paddingTop: "0.5rem"}}>
      <NatFlex ROW FULL_WIDTH FULL_HEIGHT style={{paddingTop: "0.5rem"}}>
        {tagsToDisplay.map((tag) => {
          return tag;
        })}
        <NatFlex COLUMN FULL_WIDTH FULL_HEIGHT NOGAP>
          <NatLabel
            label={"This home matches your: "}
            type={NatLabelType.P2}
            weight={NatLabelWeight.MEDIUM}
            hidden={tagMatches?.length < 1}
            style={{margin: "0 0", paddingBottom: "12px"}}
          />
          <NatFlex ROW FULL_WIDTH FULL_HEIGHT>
            {tagMatches.map((matchString) => {
              let labelText = matchString;
              if (
                !Object.values(MatchLabels).includes(matchString as MatchLabels)
              ) {
                labelText = `${matchString} preference`;
              }
              return (
                <NatLabel
                  label={
                    <NatFlex
                      ROW
                      FULL_WIDTH
                      FULL_HEIGHT
                      NOGAP
                      VERTICAL_ALIGN={NatVerticalAlign.CENTER}
                      style={{gap: "6px"}}
                    >
                      <div
                        style={{
                          borderRadius: "50%",
                          backgroundColor: VILLA_LILAC,
                          height: "20px",
                          width: "20px",
                          overflow: "clip",
                          justifyContent: "center",
                          alignContent: "center",
                          display: "flex",
                        }}
                      >
                        <NatCheckNoCircle color={BLACK} size={20} />
                      </div>
                      <div
                        style={{
                          fontFamily: FONT_FAMILY_REGULAR,
                          fontSize: "14px",
                        }}
                      >
                        {labelText}
                      </div>
                    </NatFlex>
                  }
                  style={{margin: "0 0"}}
                  type={NatLabelType.P3}
                  weight={NatLabelWeight.MEDIUM}
                />
              );
            })}
          </NatFlex>
        </NatFlex>
      </NatFlex>
    </NatFlex>
  );
};
