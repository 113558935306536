import {getFactoryLineVersionModifierGroupRef} from "../refs";
import {
  BaseModifierGroupStructure,
  ProductModifierGroupStructure,
} from "../../../../components/admin/_models/modifierGroup";
import {Utilities} from "../../index";
import {store} from "../../../../store";
import {editModifierGroup} from "../../../../components/_shared/slices/CatalogSlice/AdminStudioManagerSlice";

export const saveModifierGroupInformation = (
  factoryLineId: string,
  versionId: string,
  modifierGroupId: string,
  payload: any
) => {
  const ref = getFactoryLineVersionModifierGroupRef(
    factoryLineId,
    versionId,
    modifierGroupId
  );

  const baseDocument = filterModifierGroupForDestination(payload, "base");

  const info = {...baseDocument, ...payload, id: ref?.id};

  store.dispatch(editModifierGroup(info));

  return Utilities.updateOrCreateDocumentInDB(ref, info);
};

export const archiveModifierGroup = (
  factoryLineId: string,
  versionId: string,
  modifierGroupId: string
) => {
  return saveModifierGroupInformation(
    factoryLineId,
    versionId,
    modifierGroupId,
    {
      visible: false,
    }
  );
};

export const undoArchiveModifierGroup = (
  factoryLineId: string,
  versionId: string,
  modifierGroupId: string
) => {
  return saveModifierGroupInformation(
    factoryLineId,
    versionId,
    modifierGroupId,
    {
      visible: true,
    }
  );
};

const filterModifierGroupForDestination = (
  payload: any,
  destination: "base" | "product"
) => {
  const filteringObject =
    destination === "base"
      ? BaseModifierGroupStructure
      : ProductModifierGroupStructure;

  return Object.keys(payload)
    .filter((key: string) => filteringObject.hasOwnProperty(key))
    .reduce((obj: any, key: string) => {
      return {
        ...obj,
        [key]: payload[key],
      };
    }, {});
};
