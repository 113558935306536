import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";
import React, {useState} from "react";
import {useGlobalConfiguration} from "../../../hooks/useGlobalConfiguration";
import {INVENTORY_UNIT_PREFIX} from "../../../constants/labels";
import {MdArrowForwardIos} from "react-icons/md";

const InventoryBlurbDropdown = styled.div<{expanded: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  svg {
    transform: ${(props) => (props.expanded ? "rotate(90deg)" : "")};
    transition: transform 75ms ease-in-out;
  }
`;

const InventoryBlurbDropdownLabel = styled.div`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-right: 1rem;
`;

const InventoryBlurbDropdownContent = styled.div`
  margin-bottom: 1rem;
`;

const InventoryProgramDetails = () => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const {INVENTORY_PROGRAM} = useGlobalConfiguration();

  return (
    <>
      <InventoryBlurbDropdown
        onClick={() => setExpanded(!expanded)}
        expanded={expanded}
      >
        <InventoryBlurbDropdownLabel>
          This ADU is {INVENTORY_UNIT_PREFIX}
        </InventoryBlurbDropdownLabel>
        <MdArrowForwardIos size={12} />
      </InventoryBlurbDropdown>
      <InventoryBlurbDropdownContent hidden={!expanded}>
        {INVENTORY_UNIT_PREFIX} units are pre-designed, built, and ready to be
        delivered to your backyard — all within an{" "}
        {INVENTORY_PROGRAM?.DELIVERY_MONTHS ?? 8}-month timeline! With our price
        lock assurance you’ll know your fixed home cost upfront and we’ll help
        you understand the site work cost unique to your lot. Save months on
        your project timeline by skipping the lengthy build process and get ADU
        living, quicker.
      </InventoryBlurbDropdownContent>
    </>
  );
};

export default InventoryProgramDetails;
