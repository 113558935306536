import {useEffect, useMemo} from "react";
import {useFactorySlice} from "../useFactorySlice";
import {tryFetchingCatalogFactory} from "../../../../../root/FetchingManager";
import {
  FactoryField,
  FactoryVersionField,
  IFactory,
  IFactoryInfoSet,
} from "../../../slices/FactorySlice";
import {getFactoryVersion} from "./helper";
import {
  DesignStudioModifier,
  IFactoryInfo,
  IProduct,
  IProductGroupDetails,
} from "@natomas-org/core";

export interface IFactorySummary {
  info?: IFactoryInfo;
  infoSet?: IFactoryInfoSet;
  inventory?: {[id: string]: IProduct};
  details?: IProductGroupDetails;
  products?: {[id: string]: IProduct};
  productModifiers?: {[id: string]: any};
  categories?: {[id: string]: any};
  modifierGroups?: {[id: string]: any};
  modifiers?: {[id: string]: DesignStudioModifier};
  allProducts?: IProduct[];
}
// Right now only does published version
// TODO Handle empty factory ids
export const useFactoryLine = (props: {
  factoryId?: string;
  versionId?: string;
}): IFactorySummary => {
  const {factoryId, versionId} = props ?? {};
  const factorySlice = useFactorySlice();
  const factory: IFactory | undefined = factoryId
    ? factorySlice?.[factoryId]
    : undefined;

  useEffect(() => {
    if (!factory && factoryId) {
      tryFetchingCatalogFactory(factoryId, {versionId: versionId});
    }
  }, [factory, factoryId, versionId]);

  const factoryVersion = useMemo(() => {
    if (factory) {
      return getFactoryVersion(factory, versionId);
    }
  }, [factory, versionId]);

  return useMemo(() => {
    return {
      info: factory?.[FactoryField.INFO] ?? {},
      infoSet: factory?.[FactoryField.INFO_SET],
      inventory: factory?.[FactoryField.INVENTORY] ?? {},
      details: factoryVersion?.[FactoryVersionField.DETAILS] ?? {},
      products: factoryVersion?.[FactoryVersionField.PRODUCTS] ?? {},
      productModifiers:
        factoryVersion?.[FactoryVersionField.PRODUCT_MODIFIERS] ?? {},
      categories: factoryVersion?.[FactoryVersionField.CATEGORIES] ?? {},
      modifierGroups:
        factoryVersion?.[FactoryVersionField.MODIFIER_GROUPS] ?? {},
      modifiers: factoryVersion?.[FactoryVersionField.MODIFIERS] ?? {},
    } as IFactorySummary;
  }, [factory, factoryVersion]);
};
