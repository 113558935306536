import MaterialTable from "material-table";
import React, {useEffect, useMemo, useState} from "react";
import {
  generateOutput,
  getDataFromEntries,
  ModifierSelection,
  PRICES_TYPE,
  VISIBILITY_HIDE_KEY,
  VISIBILITY_SHOW_KEY,
  VISIBILITY_TYPE,
} from "./ModifierDependencyTableHelper";
import Select from "react-select";

const typeOptions = [
  {label: "Baseline", value: "baseline"},
  {label: "Adjustment", value: "adjustment"},
];

const conditionOptions = [
  {label: "ALL selected", value: "all"},
  {label: "ANY selected", value: "any"},
];

const defaultOption = {label: "Default", value: "default"};

const getLeadingColumns = (type) => {
  switch (type) {
    case VISIBILITY_TYPE:
      return [
        {
          title: "Visibility",
          field: "visibility",
          initialEditValue: VISIBILITY_SHOW_KEY,
          lookup: {
            [VISIBILITY_SHOW_KEY]: "Show if...",
            [VISIBILITY_HIDE_KEY]: "Hide if...",
          },
        },
      ];
    case PRICES_TYPE:
      return [
        {
          title: "Price",
          field: "price",
          type: "currency",
          align: "left",
        },
        {
          title: "Cost",
          field: "cost",
          type: "currency",
          align: "left",
        },
        {
          align: "left",
          title: "Type",
          field: "type",
          render: (rowData) => {
            const o = [...typeOptions, defaultOption].find(
              (o) => o?.value === rowData?.type
            );
            return (
              <Select isDisabled={true} placeholder={o?.label} value={o} />
            );
          },
          editComponent: (props) => {
            const {rowData} = props;
            const disabled = rowData?.condition?.toString() === "default";
            const o = typeOptions.find((o) => o?.value === rowData?.type);
            return (
              <Select
                isDisabled={disabled}
                value={o}
                placeholder={o?.label}
                options={typeOptions}
                onChange={(o) => {
                  props.onChange(o?.value);
                }}
              />
            );
          },
        },
        {
          align: "left",
          title: "Condition",
          field: "condition",
          render: (rowData) => {
            const o = [...conditionOptions, defaultOption].find(
              (o) => o?.value === rowData?.condition
            );
            return (
              <Select isDisabled={true} placeholder={o?.label} value={o} />
            );
          },
          editComponent: (props) => {
            const {rowData} = props;
            const disabled = rowData?.condition?.toString() === "default";
            const o = [...conditionOptions, defaultOption].find(
              (o) => o?.value === rowData?.condition
            );
            return (
              <Select
                isDisabled={disabled}
                value={o}
                placeholder={o?.label}
                options={conditionOptions}
                onChange={(o) => {
                  props.onChange(o?.value);
                }}
              />
            );
          },
        },
      ];
    default:
      return [];
  }
};

// enum PricingTypes = {
//   BASELINE: "baseline",
//   ADJUSTMENT: "adjustment",
// }

export const ModifierDependencyTable = (props) => {
  const {value, handleChange, name, type} = props;
  const [data, setData] = useState(getDataFromEntries(value, type));
  const [show, setShow] = useState(true);

  const updateRow = (index, newData) => {
    const dataUpdate = [...data];
    dataUpdate[index] = newData;
    const newDataToSave = [...dataUpdate];
    setData(newDataToSave);
    handleChange(generateOutput(newDataToSave, type));
  };

  const columns = useMemo(() => {
    return [
      ...getLeadingColumns(type),
      {
        align: "left",
        title: "Selected Modifiers",
        field: "requiredModifiers",
        render: (rowData) => {
          const requiredModifiers = rowData.requiredModifiers;
          const condition = rowData.condition;
          if (condition === "default") {
            return null;
          }
          return (
            <ModifierSelection
              disabled={true}
              initialSelections={requiredModifiers}
              onSelectionChange={(value) => {
                const dict = {};
                value.forEach((modifier) => {
                  dict[modifier.value] = true;
                });
                const row = rowData.tableData.id;
                const newData = Object.assign({}, rowData);
                delete newData.tableData;
                newData.requiredModifiers = dict;
                updateRow(row, newData);
              }}
            />
          );
        },
        editComponent: (props) => {
          const rowData = props.rowData;
          const requiredModifiers = rowData.requiredModifiers;
          const condition = rowData.condition;
          if (condition === "default") {
            return null;
          }
          return (
            <ModifierSelection
              initialSelections={requiredModifiers}
              onSelectionChange={(value) => {
                const dict = {};
                value.forEach((modifier) => {
                  dict[modifier.value] = true;
                });
                props.onChange(dict);
              }}
            />
          );
        },
      },
    ];
  }, [value, name, type]);

  useEffect(() => {
    setData(getDataFromEntries(value, type));
  }, [value, type]);

  if (!show) {
    return (
      <div>
        <div
          className="clickable"
          onClick={() => {
            setShow(true);
          }}
        >
          {"Show " + name}
        </div>
      </div>
    );
  }

  return (
    <>
      <MaterialTable
        title={name}
        columns={columns}
        data={data}
        options={{
          search: false,
        }}
        editable={{
          isDeletable: (rowData) => rowData.condition !== "default",
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              const newDataToSave = [...data, newData];
              setData(newDataToSave);
              handleChange(generateOutput(newDataToSave, type));
              resolve();
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              const index = oldData.tableData.id;
              updateRow(index, newData);
              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              const newDataToSave = [...dataDelete];
              setData(newDataToSave);
              handleChange(generateOutput(newDataToSave, type));
              resolve();
            }),
        }}
      />
    </>
  );
};
