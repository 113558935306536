import React from "react";
import NatLibraryPreview from "./NatomasLibraryPreview";
import {Route} from "react-router-dom";
import AuthTokenView from "../AuthView/AuthTokenView";
import NexusMeApiDemo from "../NexusDemoView/NexusMeApiDemo";
import NexusEstimateDemo from "../NexusDemoView/NexusEstimateDemo";
import {LoadingPanel} from "../../_shared/generics/loading-panel";
import {ADMIN_DEVELOPER_PATH} from "../../../paths";
import styled from "styled-components";
import {firestoreExport} from "../../../database/firebase/api/admin";
import FirestoreExportTransferForm from "./FirestoreExportTransfer";
import TeamMemberPhotoDemo from "./TeamMemberPhotoDemoView";
import InstantQuoteContinue from "./InstantQuoteContinue";
import CreateContactForm from "../../../0-app/1-container/forms/CreateContactForm";
import LoginForm from "../../../0-app/1-container/forms/LoginForm";
import CustomerTypeForm from "../../../0-app/1-container/forms/CustomerTypeForm";
import Input from "../../../0-app/2-component/Input/input";

const DEVELOPER_AUTH_TOKEN_PATH = "/auth/token";
const DEVELOPER_NEXUS_PATH = "/nexus";
const DEVELOPER_FIRESTORE_PATH = "/firestore/export";
const DEVELOPER_FIRESTORE_TRANSFER_PATH = "/firestore/export/transfer";
const DEVELOPER_NEXUS_ESTIMATE_PATH = "/nexus/estimate";
const DEVELOPER_NEXUS_ME_PATH = "/nexus/me";
const DEVELOPER_LOADING_PATH = "/loading";
const DEVELOPER_LIBRARY_PATH = "/library";
const DEVELOPER_CONTACT_FORM_PATH = "/contact-form";
const DEVELOPER_LOGIN_FORM_PATH = "/login-form";
const DEVELOPER_CUSTOMER_TYPE_FORM_PATH = "/customer-type";
const DEVELOPER_TEAM_MEMBER_PHOTO_PATH = "/library/team-member-photo";
const DEVELOPER_LIBRARY_INSTANT_QUOTE_CONTINUE_PATH =
  "/library/instant-quote-continue";
const DEVELOPER_LIBRARY_INPUT_PATH = "/library/input";
const DeveloperPaths = {
  [DEVELOPER_AUTH_TOKEN_PATH]: <AuthTokenView />,
  [DEVELOPER_TEAM_MEMBER_PHOTO_PATH]: <TeamMemberPhotoDemo />,
  [DEVELOPER_NEXUS_PATH]: <>TBD</>,
  [DEVELOPER_NEXUS_ESTIMATE_PATH]: <NexusEstimateDemo />,
  [DEVELOPER_LIBRARY_INSTANT_QUOTE_CONTINUE_PATH]: <InstantQuoteContinue />,
  [DEVELOPER_FIRESTORE_TRANSFER_PATH]: <FirestoreExportTransferForm />,
  [DEVELOPER_NEXUS_ME_PATH]: <NexusMeApiDemo />,
  [DEVELOPER_LOADING_PATH]: <LoadingPanel show={true} />,
  [DEVELOPER_LIBRARY_PATH]: <NatLibraryPreview />,
  [DEVELOPER_CONTACT_FORM_PATH]: (
    <CreateContactForm
      buttons={[]}
      onSubmit={(value: string) => window.alert("ContactForm: " + value)}
    />
  ),
  [DEVELOPER_CUSTOMER_TYPE_FORM_PATH]: (
    <CustomerTypeForm
      buttons={[]}
      onSubmit={({form, selection}) =>
        window.alert(`\`CustomerType: ${form}, ${selection}`)
      }
    />
  ),
  [DEVELOPER_LOGIN_FORM_PATH]: <LoginForm />,
  [DEVELOPER_LIBRARY_INPUT_PATH]: (
    <Input
      id={"checkbox-example"}
      type={"checkbox"}
      label={"I agree to the Terms of Use and Privacy Policy"}
      error={"Error: This is an error example"}
      textProps={{
        text: "I have read, understood and agree to the Terms of Use, and I have read and acknowledge the Privacy Policy, and I wish to create my account.",
        links: [
          {
            replacementKey: "Privacy Policy",
            target: "_blank",
            theme: "bold",
            href: "https://villahomes.com/privacy-policy/",
          },
          {
            replacementKey: "Terms of Use",
            target: "_blank",
            theme: "bold",
            href: "https://villahomes.com/terms-of-use/",
          },
        ],
      }}
    />
  ),
  [DEVELOPER_FIRESTORE_PATH]: (
    <>
      <button onClick={() => firestoreExport()}>Export</button>
    </>
  ),
};

export const DeveloperView = () => {
  const getSubPath = (path: string) => `${ADMIN_DEVELOPER_PATH}${path}`;
  const paths = Object.keys(DeveloperPaths);
  return (
    <>
      <ListContainer>
        {paths.map((path) => (
          <ListItem>
            <Link href={getSubPath(path)}>{getSubPath(path)}</Link>
          </ListItem>
        ))}
      </ListContainer>
      {Object.entries(DeveloperPaths).map(([path, component]) => (
        <Route key={path} path={getSubPath(path)} exact>
          {component}
        </Route>
      ))}
    </>
  );
};

// Styled component for the list container
const ListContainer = styled.ul`
  list-style-type: disc;
  padding: 16px;
`;

// Styled component for list items (links)
const ListItem = styled.li`
  margin-bottom: 8px;
`;

// Styled component for the links
const Link = styled.a`
  color: #333;
  text-decoration: none;
  font-family: "JetBrains Mono", monospace;
  &:hover {
    text-decoration: underline;
  }
`;
