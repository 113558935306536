import React, {useMemo} from "react";
import {ImageCarousel} from "../../../../../../../../../../_shared/catalog/views/product/carousel";
import {IImageDetails, ImageCategory, ImageStatus} from "@natomas-org/core";
import {PageContainer} from "../../../../../../../../../../_shared/generics/page/components/PageContainer";
import {WHITE} from "../../../../../../../../../../_shared/colors";
import {
  FactoryInventoryCatalogEditorKey,
  IFactoryInventoryCatalogEditor,
} from "../../formik/interfaces";
import {INVENTORY_UNIT_PREFIX} from "../../../../../../../../../../_shared/constants/labels";
import {CatalogLabel} from "../../../../../../../../../../_shared/catalog/views/product/label";
import CatalogUnitDetails from "../../../../../../../../../../_shared/catalog/views/product/details";
import {DefaultDimensions} from "../../../_shared/FactoryInventoryInput/DimensionsInput";
import {getLengthAndWidthStringFromDimensions} from "../../../../../../../../../../_shared/_legacy/logic/ProductUtils";
import {ITimelinePeriod} from "../../../_shared/FactoryInventoryInput/TimelinePeriodInput";
import {ImageGallery} from "../../../../../../../../../../_shared/catalog/views/product/imageGallery";
import {PageElement} from "../../../../../../../../../../_shared/generics/page/components/PageElement";
import {useDynamicValue} from "../../../../../../../../../../_shared/hooks/useDynamicValue";
import {Section} from "../../../../../../../../../../_shared/catalog/views/product/section";
import MappingPreview from "./MappingPreview";
import InventoryProgramDetails from "../../../../../../../../../../_shared/catalog/views/product/inventoryProgramDetails";
import UpgradeDetailsPreview from "./UpgradeDetailsPreview";
import PriceCardPreview from "./PriceCardPreview";
import styled from "styled-components";

/* Preview mode of Catalog
 * TODO
 *  Images do not have a fullscreen option, this is because that is coupled with IProduct
 *  Factory timeline is not currently determined
 *  Unit timeline is NEW + doesnt take into account start month
 */

const InventoryPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
`;

export const InventoryCatalogPreview = (props: {
  product: IFactoryInventoryCatalogEditor;
}) => {
  const {product} = props;

  const title: string = useMemo(() => {
    const t = product[FactoryInventoryCatalogEditorKey.TITLE];
    return `${INVENTORY_UNIT_PREFIX} ${t}`;
  }, [product]);

  const images: IImageDetails[] = useMemo((): IImageDetails[] => {
    const i =
      (product[FactoryInventoryCatalogEditorKey.IMAGES] as IImageDetails[]) ??
      [];
    return i?.filter(
      (image: IImageDetails) => ImageStatus.LISTED === image.status
    );
  }, [product]);

  const interiorImages: IImageDetails[] = useMemo((): IImageDetails[] => {
    return images?.filter((image: IImageDetails) =>
      image?.categories?.includes(ImageCategory.INTERIOR)
    );
  }, [images]);

  const exteriorImages: IImageDetails[] = useMemo((): IImageDetails[] => {
    return images?.filter((image: IImageDetails) =>
      image?.categories?.includes(ImageCategory.EXTERIOR)
    );
  }, [images]);

  const floorPlanImages: IImageDetails[] = useMemo((): IImageDetails[] => {
    return images?.filter((image: IImageDetails) =>
      image?.categories?.includes(ImageCategory.FLOOR_PLAN)
    );
  }, [images]);

  const defaultImage: IImageDetails | null =
    useMemo((): IImageDetails | null => {
      const id = product[FactoryInventoryCatalogEditorKey.DEFAULT_IMAGE_ID];
      return id
        ? {
            imageId: id,
            status: ImageStatus.LISTED,
            categories: [ImageCategory.HERO],
            description: title,
          }
        : null;
    }, [product, title]);

  const allImages: IImageDetails[] = useMemo(() => {
    let i: IImageDetails[] = [];
    if (defaultImage) {
      i.push(defaultImage);
    }
    return [...i, ...images];
  }, [defaultImage, images]);

  const unitTimelineTotal = useMemo(() => {
    const timeline = product?.[FactoryInventoryCatalogEditorKey.TIMELINE] ?? [];
    if (timeline?.length > 0) {
      return timeline?.reduce(
        (sum, period: ITimelinePeriod) => sum + period?.months ?? 0,
        0
      );
    } else {
      return 8;
    }
  }, [product]);

  const leftSideSize = useDynamicValue({
    forFour: 4,
    forEight: 5,
    forTwelve: 8,
    forSixteen: 12,
  });

  if (!product) {
    return null;
  }

  return (
    <PageContainer backgroundColor={WHITE}>
      <ImageCarousel images={allImages} />
      <InventoryPageContainer>
        <PageElement size={leftSideSize} height={"auto"}>
          <CatalogLabel label={title} />
          <CatalogUnitDetails
            details={{
              bedroomCount:
                product?.[FactoryInventoryCatalogEditorKey.BEDROOM_COUNT] ?? 0,
              bathroomCount:
                product?.[FactoryInventoryCatalogEditorKey.BATHROOM_COUNT] ?? 0,
              dimensions: getLengthAndWidthStringFromDimensions(
                product?.[FactoryInventoryCatalogEditorKey.DIMENSIONS] ??
                  DefaultDimensions
              ),
              factoryTimelineTotal: 12,
              squareFootage:
                product?.[FactoryInventoryCatalogEditorKey.SQUARE_FOOTAGE] ?? 0,
              unitTimelineTotal: unitTimelineTotal,
            }}
            showTimeline={true}
            showIcons={true}
          />
          <Section>
            <InventoryProgramDetails />
            <UpgradeDetailsPreview />
          </Section>
          <Section
            label={
              product?.[FactoryInventoryCatalogEditorKey.TAGLINE] ??
              product?.[FactoryInventoryCatalogEditorKey.TITLE] ??
              "Missing Tagline & Title"
            }
            description={product?.[FactoryInventoryCatalogEditorKey.BASE_DESC]}
          >
            {!!floorPlanImages[0] && (
              <ImageGallery images={[floorPlanImages[0]]} />
            )}
            <MappingPreview />
          </Section>
          <Section
            label={"Exterior Features"}
            description={
              product?.[FactoryInventoryCatalogEditorKey.EXTERIOR_DESC]
            }
          >
            <ImageGallery images={exteriorImages} />
          </Section>
          <Section
            label={"Interior Features"}
            description={
              product?.[FactoryInventoryCatalogEditorKey.INTERIOR_DESC]
            }
          >
            <ImageGallery images={interiorImages} />
          </Section>
          <Section label={"Floor Plan"}>
            <ImageGallery images={floorPlanImages} />
          </Section>
        </PageElement>
        <PriceCardPreview />
      </InventoryPageContainer>
    </PageContainer>
  );
};
