import React from "react";
import {ConditionAssociationsLabel} from "./styles";

export const ConditionAssociationsAccess = (props: {
  clickEvent: any;
  eligibleCount: number;
  totalCount?: number;
}) => {
  const {clickEvent, eligibleCount, totalCount} = props;

  return (
    <ConditionAssociationsLabel
      isComplete={!!totalCount && eligibleCount === totalCount}
      isPartial={!!eligibleCount && eligibleCount > 0}
      onClick={clickEvent}
    >
      {eligibleCount}/{totalCount}
    </ConditionAssociationsLabel>
  );
};
