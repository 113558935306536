import styled from "styled-components";
import {GRAY} from "../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../SSDDashboardView/styles/globals";

export const CartItemProductPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CartItemProductPreviewDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CartItemProductPreviewTitle = styled.div`
  font-size: 20px;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const CartItemProductPreviewDescription = styled.p`
  font-size: 12px;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin: 0;
  padding: 0;
  color: ${GRAY};
`;

export const CartItemProductDetailsDescription = styled.p`
  font-size: 12px;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin: 0;
  padding: 0;
  color: ${GRAY};
  min-width: 128px;
  text-align: right;
`;
