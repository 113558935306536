import styled from "styled-components";
import {FONT_FAMILY_BOLD, FONT_FAMILY_MEDIUM} from "./globals";
import {StandardElement} from "../../../../_shared/generics/page/components/PageElement/styles";
import {GRAY} from "../../../../_shared/colors";

export const DepositSubmissionJourneyContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 1rem;
`;

export const CardContainer = styled.div<{
  height: string;
  rowCount?: number;
}>`
  height: ${(props) => props.height};
  width: 100%;
  ${StandardElement}:last-child {
    margin-bottom: 0;
  }
  ${StandardElement}:nth-child(n + ${(props) => props.rowCount}) {
    margin-right: 0;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SectionsWrapper = styled.div``;

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  margin-top: 1rem;
  font-size: 1.5rem;
  width: 100%;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const Subtitle = styled.div<{fontSize: string}>`
  margin-bottom: 1.5rem;
  font-size: ${(props) => props.fontSize};
  width: 100%;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${GRAY};
  display: flex;
  flex-direction: row;
  div {
    margin-right: 1rem;
  }
`;

export const SubtitleBold = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
`;

export const ButtonContainer = styled.div`
  gap: 10px;
  display: flex;
  width: 100%;
`;
