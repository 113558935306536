import React, {useEffect} from "react";
import {IFactoryInventoryStudioEditorPopupOptions} from "./interface";
import {StudioEditorPopupContentContainer} from "./styles";
import FieldEditor from "./FieldEditor";
import {IFactoryInventoryStudioEditor} from "../../../index";
import {StudioEditorActionType} from "../_shared/StudioEditorFieldAddButton";
import FactoryImportLibrary from "./ImportLibrary";

interface PopupContentProps {
  reset: () => void;
  initialValues: IFactoryInventoryStudioEditor;
  options?: IFactoryInventoryStudioEditorPopupOptions;
  formik?: any;
  factoryId?: any;
}

const PopupContent = (props: PopupContentProps) => {
  const {options, factoryId, formik, reset, initialValues} = props;
  const {type, action, path} = options ?? {};

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <StudioEditorPopupContentContainer>
      {action === StudioEditorActionType.IMPORT ? (
        <FactoryImportLibrary
          factoryId={factoryId}
          type={type}
          path={path}
          formik={formik}
          reset={reset}
        />
      ) : (
        <FieldEditor
          type={type}
          path={path}
          formik={formik}
          initialValues={initialValues}
          reset={reset}
        />
      )}
    </StudioEditorPopupContentContainer>
  );
};

export default PopupContent;
