import React from "react";
import SSCRowTemplate from "../../_shared/SSCRowTemplate";
import SSCTimeline from "./index";

interface SSCTimelineRowProps {
  primaryTimelineInMonths: number;
  otherTimelineInMonths: number;
}

const SSCTimelineRow = (props: SSCTimelineRowProps) => {
  const {primaryTimelineInMonths, otherTimelineInMonths} = props;

  return (
    <SSCRowTemplate label={"Build Timeline"}>
      <SSCTimeline timelineInMonths={primaryTimelineInMonths} />
      {otherTimelineInMonths && (
        <SSCTimeline timelineInMonths={otherTimelineInMonths} />
      )}
    </SSCRowTemplate>
  );
};

export default SSCTimelineRow;
