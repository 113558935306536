import React, {useEffect, useState} from "react";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {BLACK} from "../../../../../_shared/colors";

export const GenericLoadingComponent = (props: {hidden?: boolean}) => {
  const loadingTexts = [
    "Loading    ",
    "Loading.  ",
    "Loading.. ",
    "Loading...",
  ];
  const [loadingIndex, setLoadingIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setLoadingIndex((index) => (index + 1) % loadingTexts.length);
    }, 1000);

    return () => clearInterval(id);
  }, [loadingTexts.length]);

  return (
    <div
      hidden={props.hidden}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        flexDirection: "row",
        columnGap: "0.5rem",
      }}
    >
      <AiOutlineLoading3Quarters
        color={BLACK}
        style={{animation: "spin 2s linear infinite"}}
      />
      <div style={{width: "5rem", textAlign: "left"}}>
        {loadingTexts[loadingIndex]}
      </div>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};
