import styled from "styled-components";
import {BLACK} from "../../../../colors";

export const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 100%;
  column-gap: 1rem;
  row-gap: 1rem;
  overflow-y: auto;
  padding: 0.5rem;
`;

export const SearchAllBreadcrumbWrapper = styled.div`
  border-bottom: 1px solid ${BLACK};
  padding: 0 0.5rem;
  max-height: 49px;
`;

export const SearchAllContentWrapper = styled.div<{height?: string}>`
  height: calc(100% - 49px);
`;
