import React, {useMemo} from "react";
import {getTotalEstimateRange} from "../_shared/helper";
import {GenericLoadingComponent} from "../../portal/views/ProposalBudgetView/components/generic/GenericLoadingComponent";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../portal/views/SSDDashboardView/styles/globals";
import styled from "styled-components";
import {BLACK} from "../../_shared/colors";
import {IEstimateProjectRangeSummary} from "../../../api/Estimate/EstimateService";

interface EstimateTableTotalRangeProps {
  summary?: IEstimateProjectRangeSummary;
}

export const ESTIMATED_PROJECT_RANGE_LABEL = "Estimated Project Range";
export const ESTIMATED_PROJECT_PRICE_LABEL = "Estimated Project Price";
const EstimateTableTotalRange = (props: EstimateTableTotalRangeProps) => {
  const {summary} = props;

  const {result, isRange} = useMemo(() => {
    return getTotalEstimateRange(summary);
  }, [summary]);

  let RangeDisplay = null;
  if (!summary || !result) {
    RangeDisplay = <GenericLoadingComponent />;
  } else {
    RangeDisplay = <PriceRange>{result}</PriceRange>;
  }
  return (
    <Container>
      <Label>
        {isRange
          ? ESTIMATED_PROJECT_RANGE_LABEL
          : ESTIMATED_PROJECT_PRICE_LABEL}
      </Label>
      {RangeDisplay}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 32px;
`;

const Label = styled.p`
  color: #807d7a;
  margin: 0;

  /* Paragraph/P1/Regular */
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const PriceRange = styled.h1`
  color: ${BLACK};
  margin: 0;
  /* Heading/H1/Bold */
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export default EstimateTableTotalRange;
