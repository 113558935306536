import styled from "styled-components";

export const ElementContainer = styled.div<{backgroundColor: string}>`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  flex-wrap: wrap;
  padding: 1rem;
  border: 1px solid black;
  background-color: ${(props) => props.backgroundColor};
`;

export const ElementContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
`;

export const Title = styled.h1`
  margin: 2rem 0 1rem 0;
  font-size: 1.5rem;
`;
