import React from "react";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";
import {
  SSCComponentContainer,
  SSCComponentLabel,
  SSCComponentSubLabel,
} from "../../_shared/styles";

interface SSCPricingProps {
  pricing: string;
}

const SSCPricing = (props: SSCPricingProps) => {
  const {pricing} = props;

  return (
    <SSCBlockContainer>
      <SSCComponentContainer>
        <SSCComponentLabel>{pricing}*</SSCComponentLabel>
        <SSCComponentSubLabel>
          *Does not include site preparation costs
        </SSCComponentSubLabel>
      </SSCComponentContainer>
    </SSCBlockContainer>
  );
};

export default SSCPricing;
