import {useCatalogSession} from "./useCatalogSession";
import {
  FilterDropdownDisplayType,
  FilterType,
  ICatalogFilter,
} from "../../../catalog/filter/types";
import {useMemo} from "react";
import {FilterDisplayAttributes} from "../../../generics/popup/dropdown/specializedDropdowns/filter/constants";
import {getKStringFromMicros} from "@natomas-org/core";

const getActiveFilter = (filters: ICatalogFilter[], type: FilterType) => {
  return filters.find((f: ICatalogFilter) => f.type === type);
};
export const useDropdownFiltersState = (): {
  [type in FilterDropdownDisplayType]: {title: string; active?: boolean};
} => {
  const {
    bathroom_count: activeBathFilter,
    bedroom_count: activeBedFilter,
    square_footage: activeSqFtFilter,
    unit_price_range: activePriceFilter,
    product_parcel_fit: activeFitFilter,
  } = useActiveCatalogFilters();
  const {allInEstimateCatalog} = useCatalogSession();
  const bedBathTitle: string = useMemo(() => {
    if (activeBedFilter && activeBathFilter) {
      return `${activeBedFilter?.label} Bed, ${activeBathFilter?.label} Bath`;
    }
    if (activeBathFilter) {
      return `Any Bed, ${activeBathFilter?.label} Bath`;
    }
    if (activeBedFilter) {
      return `${activeBedFilter?.label} Bed, Any Bath`;
    }
    return FilterDisplayAttributes[FilterDropdownDisplayType.BedAndBath]
      .unsetLabel;
  }, [activeBedFilter, activeBathFilter]);

  const unitPriceTitle = useMemo(() => {
    const priceFilter = activePriceFilter as any;
    if (priceFilter?.value?.low && priceFilter?.value?.high) {
      return `${getKStringFromMicros(
        priceFilter?.value?.low
      )} - ${getKStringFromMicros(priceFilter?.value?.high)}`;
    }
    return FilterDisplayAttributes[FilterDropdownDisplayType.UnitPrice]
      .unsetLabel;
  }, [activePriceFilter]);

  const unitSqFtTitle = useMemo(() => {
    const sqFtFilter = activeSqFtFilter as any;
    if (sqFtFilter?.value?.low && sqFtFilter?.value?.high) {
      return `${Math.floor(sqFtFilter?.value?.low)} - ${Math.floor(
        sqFtFilter?.value?.high
      )} Sq. Ft.`;
    }
    return FilterDisplayAttributes[FilterDropdownDisplayType.SquareFoot]
      .unsetLabel;
  }, [activeSqFtFilter]);

  return {
    [FilterDropdownDisplayType.UnitPrice]: {
      title: unitPriceTitle,
      active: !!activePriceFilter,
    },
    [FilterDropdownDisplayType.Toggles]: {
      title:
        FilterDisplayAttributes[FilterDropdownDisplayType.Toggles].unsetLabel,
      active: allInEstimateCatalog || !!activeFitFilter?.value,
    },
    [FilterDropdownDisplayType.SquareFoot]: {
      title: unitSqFtTitle,
      active: !!activeSqFtFilter,
    },
    [FilterDropdownDisplayType.BedAndBath]: {
      title: bedBathTitle,
      active: !!activeBedFilter || !!activeBathFilter,
    },
  };
};

export const useActiveCatalogFilters = (): {
  [type in FilterType]: ICatalogFilter | undefined;
} => {
  const {filters} = useCatalogSession();
  const activeSqFtFilter: ICatalogFilter | undefined = useMemo(() => {
    return getActiveFilter(filters, FilterType.SquareFootage);
  }, [filters]);
  const activeBedFilter: ICatalogFilter | undefined = useMemo(() => {
    return getActiveFilter(filters, FilterType.Bedroom);
  }, [filters]);
  const activeBathFilter: ICatalogFilter | undefined = useMemo(() => {
    return getActiveFilter(filters, FilterType.Bathroom);
  }, [filters]);
  const activePriceFilter: ICatalogFilter | undefined = useMemo(() => {
    return getActiveFilter(filters, FilterType.UnitPriceRange);
  }, [filters]);
  const activeProductParcelFitFilter: ICatalogFilter | undefined =
    useMemo(() => {
      return getActiveFilter(filters, FilterType.ProductParcelFit);
    }, [filters]);

  return {
    [FilterType.ProductParcelFit]: activeProductParcelFitFilter,
    [FilterType.Bathroom]: activeBathFilter,
    [FilterType.Bedroom]: activeBedFilter,
    [FilterType.SquareFootage]: activeSqFtFilter,
    [FilterType.ProductCategory]: undefined,
    [FilterType.ProductLine]: undefined,
    [FilterType.UnitPriceRange]: activePriceFilter,
  };
};
