import {
  Address,
  ICompletedProjectsDictionary,
  IGLMapState,
  IPublicProjectInfo,
} from "@natomas-org/core";
import {IPublicProjectInfoModalPayload} from "../../../../../mapping/views/components/ProjectDetailsModal/constants";
import React, {Dispatch, SetStateAction, useMemo} from "react";
import {ViewportDialogue} from "../styles";
import {FONT_FAMILY_BOLD} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {ProductFlexList} from "../../ProductList/styles";
import {PublicProjectInfo} from "@natomas-org/service";
import {ProjectCard} from "../../ProjectCard";
import {setGLMapAnimationOptionsWithoutPreviousState} from "../../../../../map/slices/mapSlice";
import {
  DEFAULT_CA_VIEWPORT,
  PROJECTS_ZOOM_ON_CLICK,
} from "../../../../../mapping/views/components/AreaMap/constants";
import {MAP_ANIMATION_DURATION_SECONDS} from "../../../../../mapping/views/theme/constants";
import {NatButton} from "../../../../../_shared/generics/button";
import {NatSize} from "../../../../../_shared/generics/_shared";
import {
  COMPLETED_PROJECTS_FEW_HOMES_THRESHOLD,
  OUTSIDE_MAP_FEATURED_HOMES_COUNT,
  OUTSIDE_MAP_FEATURED_HOMES_COUNT_MOBILE,
} from "../constants";
import {usePage} from "../../../../../_shared/hooks/usePage";

const NearbyHomesNoMapHeader = (props: {dispatch: Dispatch<any>}) => {
  const {dispatch} = props;
  return (
    <>
      <ViewportDialogue
        style={{
          fontFamily: FONT_FAMILY_BOLD,
          paddingTop: "1rem",
          paddingBottom: "0",
        }}
      >
        No featured homes in current map area.
      </ViewportDialogue>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          padding: "1rem 0",
        }}
      >
        <NatButton
          label={"View all featured homes"}
          size={NatSize.LARGE}
          clickEvent={() => {
            dispatch(
              setGLMapAnimationOptionsWithoutPreviousState({
                zoom: DEFAULT_CA_VIEWPORT.zoom,
                lat: DEFAULT_CA_VIEWPORT.mLat,
                lng: DEFAULT_CA_VIEWPORT.mLng,
                duration: MAP_ANIMATION_DURATION_SECONDS * 1000,
              })
            );
          }}
        />
      </div>
      <hr />
    </>
  );
};

export const NearbyHomesNoMap = (props: {
  includeHeader: boolean;
  columnCount: number;
  width: any;
  relatedProjectsArray: IPublicProjectInfo[];
  showMore: number;
  dispatch: Dispatch<any>;
  title: string;
  mapState: IGLMapState;
  completedProjects?: ICompletedProjectsDictionary;
  formatDisplayForMap?: boolean;
  showAllCards?: boolean;
  cardHeight?: number;
  imageHeight?: number;
  setProjectModalPayload?: Dispatch<
    SetStateAction<IPublicProjectInfoModalPayload | null>
  > | null;

  showProjectMap?: boolean;
  setShowProjectMap?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    includeHeader,
    setProjectModalPayload,
    relatedProjectsArray,
    showMore,
    showAllCards,
    formatDisplayForMap,
    dispatch,
    title,
    mapState,
    completedProjects,
    showProjectMap,
    setShowProjectMap,
  } = props;
  const {isNatMobile} = usePage();
  const dontDisplayContent = useMemo(() => {
    return (
      relatedProjectsArray?.length > COMPLETED_PROJECTS_FEW_HOMES_THRESHOLD
    );
  }, [relatedProjectsArray?.length]);

  const nearestProjectsOutsideViewport: IPublicProjectInfo[] = useMemo(() => {
    if (dontDisplayContent) {
      // Only calculate if there are no homes in viewport and in map view mode
      return [];
    }
    return PublicProjectInfo.getNearbyHomesNoMap(
      mapState,
      completedProjects,
      isNatMobile
        ? OUTSIDE_MAP_FEATURED_HOMES_COUNT_MOBILE
        : OUTSIDE_MAP_FEATURED_HOMES_COUNT
    );
  }, [completedProjects, dontDisplayContent, isNatMobile, mapState]);
  if (dontDisplayContent) {
    return <></>;
  }
  return (
    <>
      {includeHeader && formatDisplayForMap && (
        <NearbyHomesNoMapHeader dispatch={dispatch} />
      )}
      <ViewportDialogue
        hidden={nearestProjectsOutsideViewport?.length < 1}
        style={{padding: "1rem 0", fontStyle: FONT_FAMILY_BOLD}}
      >
        {title}
      </ViewportDialogue>
      <ProductFlexList>
        {nearestProjectsOutsideViewport?.map(
          (project: IPublicProjectInfo, idx) => {
            if (idx >= showMore && !showAllCards) {
              return (
                <div key={`nearby-project-card-${idx}-off-map`}>No card</div>
              );
            }
            const randomizedCoordinates =
              PublicProjectInfo.getRandomizedCoordinates(project);
            return (
              <ProjectCard
                project={project}
                setStateOnHover={true}
                onClick={() => {
                  if (!!setProjectModalPayload) {
                    setProjectModalPayload({
                      mapProjectDetails: project,
                      viewOnMapAction: () => {
                        if (!!randomizedCoordinates) {
                          dispatch(
                            setGLMapAnimationOptionsWithoutPreviousState({
                              zoom: PROJECTS_ZOOM_ON_CLICK - 1,
                              lat: randomizedCoordinates?.lat,
                              lng: randomizedCoordinates?.lng,
                              duration: MAP_ANIMATION_DURATION_SECONDS * 1000,
                            })
                          );
                        } else {
                          dispatch(
                            setGLMapAnimationOptionsWithoutPreviousState({
                              zoom: PROJECTS_ZOOM_ON_CLICK - 1,
                              lat: Address.getLatitude(
                                Address.dataToAddress(project?.project_address)
                              ),
                              lng: Address.getLongitude(
                                Address.dataToAddress(project?.project_address)
                              ),
                              duration: MAP_ANIMATION_DURATION_SECONDS * 1000,
                            })
                          );
                        }
                        if (!showProjectMap && !!setShowProjectMap) {
                          setShowProjectMap(true);
                        }
                      },
                    } as IPublicProjectInfoModalPayload);
                  }
                }}
                key={`nearby-project-card-${idx}`}
              />
            );
          }
        )}
      </ProductFlexList>
    </>
  );
};
