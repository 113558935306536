import React from "react";
import {isBlankString} from "@natomas-org/core";
import styled from "styled-components";
import {DesignSquareButton} from "./styles";
import {SmartImage} from "../../../../../_shared/generics/image/SmartImage";

export const ColorButton = (props: any) => {
  let style: {
    background?: string;
    backgroundColor?: string;
    width?: string;
    height?: string;
  } = {};
  if (!isBlankString(props.firstColor) && !isBlankString(props.secondColor)) {
    // ts-ignore
    style.background = `linear-gradient(135deg, ${props.firstColor} 50%, ${props.secondColor} calc(50% + 1px))`;
  } else {
    // ts-ignore
    style.backgroundColor = props.firstColor ?? props.secondColor;
  }
  if (isBlankString(props.firstColor) && props.imageId) {
    return (
      <ColorTextButtonWrapper
        id={"modifier-" + props.buttonId}
        active={props.isSelected}
        // @ts-ignore
        disabled={props.isDisabled}
        onClick={props.onClick}
        style={style}
        isMobile={props.isMobile}
      >
        <SmartImage
          imageId={props.imageId}
          style={{padding: "2px", borderRadius: "10px"}}
        />
      </ColorTextButtonWrapper>
    );
  }

  return (
    <ColorTextButtonWrapper
      id={"modifier-" + props.buttonId}
      active={props.isSelected}
      // @ts-ignore
      disabled={props.isDisabled}
      onClick={props.onClick}
      isMobile={props.isMobile}
      style={style}
    />
  );
};
const ColorTextButtonWrapper = styled(DesignSquareButton)<{isMobile: boolean}>`
  width: ${(props) => (!props?.isMobile ? "195px" : "128px")};
  height: ${(props) => (!props?.isMobile ? "195px" : "128px")};
  outline: none;
  transition: all 0.35s;
  margin: 1rem 1rem 0 0;
  position: relative;
  overflow: hidden;
  user-select: none;
  img {
    overflow: hidden;
    pointer-events: none;
  }
`;
