import React, {useMemo, useState} from "react";
import Select from "react-select";
import {
  ModifierConditionalBasicOption,
  ModifierConditionalFieldOptions,
  ModifierConditionalImageOption,
  ModifierConditionalSingleColorOption,
  ModifierConditionalTwoColorOption,
} from "./ModifierConditionalFieldOptions";
import {IFactoryInventoryStudioEditorModifier} from "../../../ModifierSection/interface";
import {
  FactoryInventoryInput,
  FactoryInventoryInputType,
} from "../../../../../../_shared/FactoryInventoryInput";
import {ColorOptionContainer} from "../styles";
import {processTypeChange} from "./helper";
import _ from "lodash";

interface ModifierConditionalFieldEditorProps {
  id: string;
  updateEdits: any;
  initialValue?: IFactoryInventoryStudioEditorModifier;
  edits?: IFactoryInventoryStudioEditorModifier;
}

const ModifierConditionalFieldEditor = (
  props: ModifierConditionalFieldEditorProps
) => {
  const {id, updateEdits, initialValue, edits} = props;

  const initialOption = useMemo(() => {
    if (initialValue?.imageId) {
      return ModifierConditionalImageOption;
    }
    if (initialValue?.color && !initialValue?.secondaryColor) {
      return ModifierConditionalSingleColorOption;
    }
    if (initialValue?.secondaryColor) {
      return ModifierConditionalTwoColorOption;
    }
    return ModifierConditionalBasicOption;
  }, [initialValue]);

  const editOption = useMemo(() => {
    if (edits?.imageId) {
      return ModifierConditionalImageOption;
    }
    if (edits?.color && !edits?.secondaryColor) {
      return ModifierConditionalSingleColorOption;
    }
    if (edits?.secondaryColor) {
      return ModifierConditionalTwoColorOption;
    }
    return ModifierConditionalBasicOption;
  }, [edits]);

  const defaultOption = useMemo(() => {
    return !_.isEqual(editOption, initialOption) ? editOption : initialOption;
  }, [editOption, initialOption]);

  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const selector = useMemo(() => {
    return (
      <Select
        options={ModifierConditionalFieldOptions}
        defaultValue={initialOption}
        value={selectedOption}
        onChange={(option) => {
          if (!!option) {
            setSelectedOption(option);
            processTypeChange(option?.value, initialValue, updateEdits);
          }
        }}
      />
    );
  }, [initialOption, initialValue, selectedOption, updateEdits]);

  const conditionals = useMemo(() => {
    if (selectedOption === ModifierConditionalSingleColorOption) {
      return (
        <>
          <FactoryInventoryInput
            id={`${id}-color`}
            key={`${id}-color`}
            label={"Color"}
            type={FactoryInventoryInputType.COLOR}
            value={edits?.color}
            initialValue={initialValue?.color}
            onChange={(value: any) => {
              updateEdits("color", value);
            }}
            resetChange={() =>
              updateEdits("color", initialValue?.color ?? undefined)
            }
          />
        </>
      );
    }
    if (selectedOption === ModifierConditionalTwoColorOption) {
      return (
        <ColorOptionContainer>
          <FactoryInventoryInput
            id={`${id}-color`}
            key={`${id}-color`}
            label={"Color"}
            type={FactoryInventoryInputType.COLOR}
            value={edits?.color}
            initialValue={initialValue?.color}
            onChange={(value: any) => {
              updateEdits("color", value);
            }}
            resetChange={() =>
              updateEdits("color", initialValue?.color ?? undefined)
            }
          />
          <FactoryInventoryInput
            id={`${id}-secondary-color`}
            key={`${id}-secondary-color`}
            label={"Secondary Color"}
            type={FactoryInventoryInputType.COLOR}
            value={edits?.secondaryColor}
            initialValue={initialValue?.secondaryColor}
            onChange={(value: any) => {
              updateEdits("secondaryColor", value);
            }}
            resetChange={() =>
              updateEdits(
                "secondaryColor",
                initialValue?.secondaryColor ?? undefined
              )
            }
          />
        </ColorOptionContainer>
      );
    }
    if (selectedOption === ModifierConditionalImageOption) {
      return (
        <FactoryInventoryInput
          id={`${id}-image`}
          key={`${id}-image`}
          label={"Image"}
          type={FactoryInventoryInputType.SINGLE_IMAGE}
          value={edits?.imageId}
          initialValue={initialValue?.imageId}
          onChange={(value: any) => {
            updateEdits("imageId", value);
          }}
          resetChange={() =>
            updateEdits("imageId", initialValue?.imageId ?? undefined)
          }
        />
      );
    }
    return null;
  }, [
    selectedOption,
    id,
    edits?.color,
    edits?.secondaryColor,
    edits?.imageId,
    initialValue?.color,
    initialValue?.secondaryColor,
    initialValue?.imageId,
    updateEdits,
  ]);

  return (
    <>
      {selector}
      {conditionals}
    </>
  );
};

export default ModifierConditionalFieldEditor;
