import React from "react";
import {RadioButton} from "./buttons/RadioButton";
import {ColorButton} from "./buttons/ColorButton";
import {FullColorTextButton} from "./buttons/FullColorTextButton";
import {ImageTextButton} from "./buttons/ImageTextButton";
import {MultiSelectTextButton} from "./buttons/MultiSelectTextButton";
import {TextButton} from "./buttons/TextButton";
import {
  SHOW_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_TEXT_DISPLAY_KEY,
  SHOW_MULTI_SECENT_DISPLAY_KEY,
} from "../../constants";
import {priceTextFromMicros} from "../../helper";
import {getMicrosForPriceMode, ProjectPriceMode} from "@natomas-org/core";

export interface ModifierButtonCommonProps {
  buttonId: string;
  onClick: () => void;
  radioValue: string;
  key: string;
  isSelected: boolean;
  isDisabled: boolean;
  isMobile?: boolean;
}

export const getModifierButton = (
  modifier: any,
  modifierGroup: any,
  onClick: () => void,
  isSelected: boolean,
  editingSelections: boolean,
  isMobile: boolean,
  type: string,
  priceMode: ProjectPriceMode
) => {
  const disabled = !editingSelections && !isSelected;

  const commonProps: ModifierButtonCommonProps = {
    buttonId: modifier.id,
    onClick: onClick,
    radioValue: modifier.id,
    key: modifier.id,
    isSelected: isSelected,
    isDisabled: disabled,
    isMobile: isMobile,
  };

  switch (type) {
    case SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY:
      return getFullColor(commonProps, modifier, priceMode);
    case SHOW_MULTI_SECENT_DISPLAY_KEY:
      return getMultiSelectButton(commonProps, modifier, priceMode);
    case SHOW_COLOR_SWATCHES_DISPLAY_KEY:
      return getColorButton(commonProps, modifier);
    case SHOW_FULL_TEXT_DISPLAY_KEY:
      return getTextButton(commonProps, modifier, priceMode);
    default:
      return getButton(commonProps, modifier, priceMode);
  }
};

function getButton(
  commonProps: ModifierButtonCommonProps,
  modifier: any,
  priceMode: ProjectPriceMode
) {
  if (modifier.imageId != null) {
    return getImageButton(commonProps, modifier, priceMode);
  } else if (microsToText(getMicrosForPriceMode(modifier, priceMode))) {
    return getTextButton(commonProps, modifier, priceMode);
  }

  return (
    <RadioButton {...commonProps}>{getModifierTitle(modifier)}</RadioButton>
  );
}

function getMultiSelectButton(
  commonProps: ModifierButtonCommonProps,
  modifier: any,
  priceMode: ProjectPriceMode
) {
  return (
    <MultiSelectTextButton
      {...commonProps}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(
        getMicrosForPriceMode(modifier, priceMode),
        "min-sign"
      )}
    />
  );
}

export function getTextButton(
  commonProps: ModifierButtonCommonProps,
  modifier: any,
  priceMode: ProjectPriceMode
) {
  return (
    <TextButton
      {...commonProps}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(
        getMicrosForPriceMode(modifier, priceMode),
        "min-sign"
      )}
    />
  );
}

function getColorButton(commonProps: ModifierButtonCommonProps, modifier: any) {
  return (
    <ColorButton
      {...commonProps}
      firstColor={modifier.color}
      secondColor={modifier.secondaryColor}
      imageId={modifier.imageId}
    />
  );
}

function getFullColor(
  commonProps: ModifierButtonCommonProps,
  modifier: any,
  priceMode: ProjectPriceMode
) {
  return (
    <FullColorTextButton
      {...commonProps}
      firstColor={modifier.color}
      secondColor={modifier.secondaryColor}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(
        getMicrosForPriceMode(modifier, priceMode),
        "min-sign"
      )}
    />
  );
}

export function getImageButton(
  commonProps: ModifierButtonCommonProps,
  modifier: any,
  priceMode: ProjectPriceMode
) {
  return (
    <ImageTextButton
      {...commonProps}
      buttonText={getModifierTitle(modifier)}
      price={priceTextFromMicros(
        getMicrosForPriceMode(modifier, priceMode),
        "min-sign"
      )}
      imageId={modifier.imageId}
    />
  );
}

function microsToText(micros?: number) {
  if (micros == null || micros === 0) {
    return null;
  }
  let prefix = "+$";
  if (micros < 0) {
    prefix = "-$";
  }

  return prefix + Math.abs(micros / 100);
}

function isBlank(string?: string) {
  return string == null || string.length < 1;
}

function getModifierTitle(modifier: any) {
  if (!isBlank(modifier.configurationButtonTitle)) {
    return modifier.configurationButtonTitle;
  }
  return modifier.title;
}
