import React, {useCallback, useEffect, useState} from "react";
import {IFactoryInventoryStudioEditorModifier} from "../../ModifierSection/interface";
import {NatButton} from "../../../../../../../../../../../../_shared/generics/button";
import ModifierTabContainer from "./ModifierTabContainer";
import {StudioEditorPopupControllerContainer} from "../styles";
import {
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../../_shared/generics/_shared";
import {DefaultFieldEditorProps} from "../FieldEditor";

interface ModifierFieldEditorProps extends DefaultFieldEditorProps {
  initialValue?: IFactoryInventoryStudioEditorModifier;
  currentValue?: IFactoryInventoryStudioEditorModifier;

  path: {
    categoryId?: string;
    modifierGroupId?: string;
  };
}

const ModifierFieldEditor = (props: ModifierFieldEditorProps) => {
  const {initialValue, currentValue, path, reset, setValue} = props;
  const {id} = currentValue ?? {};

  const [edits, setEdits] = useState<
    IFactoryInventoryStudioEditorModifier | undefined
  >(currentValue);

  useEffect(() => {
    setEdits(currentValue);
  }, [currentValue]);

  const updateModifier = useCallback(() => {
    const categoryId = path?.categoryId;
    const modifierGroupId = path?.modifierGroupId;
    if (id && modifierGroupId && categoryId) {
      setValue(edits);
      reset();
    }
  }, [edits, id, path?.categoryId, path?.modifierGroupId, reset, setValue]);

  const updateEdits = useCallback(
    (fieldKey: string, value: any) => {
      setEdits((current: any) => {
        return {
          ...current,
          [fieldKey]: value,
        };
      });
    },
    [setEdits]
  );

  if (!edits || !id) {
    return null;
  }

  return (
    <>
      <ModifierTabContainer
        id={id}
        initialValue={initialValue}
        edits={edits}
        updateEdits={updateEdits}
      />
      <StudioEditorPopupControllerContainer>
        <NatButton
          label={"Save Changes"}
          size={NatSize.SMALL}
          clickEvent={updateModifier}
          option={StyleOption.ADMIN_SOLID}
        />
      </StudioEditorPopupControllerContainer>
    </>
  );
};

export default ModifierFieldEditor;
