import React, {Dispatch, SetStateAction, useMemo, useState} from "react";
import {
  getModifierGroupsByCategoryIds,
  getModifierGroupsByModifierGroupIds,
} from "../../helper";
import {containsSearchText} from "../../../../../../logic/helpers";
import {getProductModifierTable} from "../../../table";
import {sortProducts} from "../../../../../../../../_shared/_legacy/logic/ProductGroupUtils";
import {useAdminProductCatalog} from "../../../../../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {
  DependenciesDataProps,
  DependenciesModal,
} from "../../../DependenciesModal";
import {GenericMatrixTable} from "../_shared/GenericMatrixTable";
import {MatrixTablePageProps} from "../../index";
import {PriceSetTableCell} from "./PriceSetTable/PriceSetTableCell";
import {getChanges, sanitizeMatrixCellProps} from "../_shared/helper";
import {DescriptionTableCell} from "./DescriptionTable/DescriptionTableCell";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixModes,
} from "../../../../../../../../_shared/slices/types/Store";
import {DesignStudioModifier, IProduct} from "@natomas-org/core";
import {useSelectedFactoryLine} from "../../../../../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {getModifierTitle} from "./PriceSetTable/helper";

export interface ProductModifierTableProps extends MatrixTablePageProps {
  loading: boolean;
  matrixEdits: any;
  searchText: string | undefined;
  setMatrixEdits: Dispatch<SetStateAction<any>>;
  heightOffset?: string;
}

export const ProductModifierTable = (props: ProductModifierTableProps) => {
  const {
    setMatrixEdits,
    searchText,
    matrixEdits,
    transpose,
    pageNumber,
    itemsPerPage,
    heightOffset,
  } = props;
  const {
    categories: allCategories,
    modifiers: allModifiers,
    modifierGroups: allModifierGroups,
    products: allProducts,
    productModifiers: allProductModifiers,
  } = useSelectedFactoryLine();
  const {matrixFilters, matrixMode, matrixSubMode, matrixTitleMode} =
    useAdminProductCatalog();
  const {filterIds, filterBy} = matrixFilters;
  const [data, setData] = useState<DependenciesDataProps | undefined>(
    undefined
  );

  // Memoized Values
  const products: IProduct[] = useMemo(() => {
    return (Object.values(allProducts ?? {}) as IProduct[]).filter((p: any) => {
      if (filterBy === AdminProductCatalogMatrixFilterByOptions.PRODUCT) {
        return filterIds?.includes(p?.id);
        // } else if (include === AdminProductCatalogMatrixIncludeOptions.ALL) {
        //   return true;
        // } else if (include === AdminProductCatalogMatrixIncludeOptions.MODELS) {
        //   return !p.inventory_info;
        // } else if (
        //   include === AdminProductCatalogMatrixIncludeOptions.INVENTORY
        // ) {
        //   return p.inventory_info;
      } else {
        return !p.inventory_info;
      }
    });
  }, [allProducts, filterBy, filterIds]);

  const modifierGroups = useMemo(() => {
    if (!allModifierGroups || products?.length === 0) {
      return [];
    }
    if (filterBy === AdminProductCatalogMatrixFilterByOptions.PRODUCT) {
      return getModifierGroupsByCategoryIds(
        allCategories,
        allModifierGroups,
        // @ts-ignore
        products[0]?.configuratorPages
      );
    }
    if (filterBy === AdminProductCatalogMatrixFilterByOptions.CATEGORY) {
      return getModifierGroupsByCategoryIds(
        allCategories,
        allModifierGroups,
        filterIds
      );
    }
    if (filterBy === AdminProductCatalogMatrixFilterByOptions.MODIFIER_GROUP) {
      return getModifierGroupsByModifierGroupIds(allModifierGroups, filterIds);
    }
    return Object.values(allModifierGroups);
  }, [allModifierGroups, products, filterBy, allCategories, filterIds]);

  const modifiers: DesignStudioModifier[] = useMemo(() => {
    if (
      filterBy === AdminProductCatalogMatrixFilterByOptions.MODIFIER &&
      filterIds
    ) {
      const eligibleModifiers: DesignStudioModifier[] = [];

      filterIds?.forEach((id: string) => {
        const mod = allModifiers?.[id];
        if (mod) {
          // If a search term is present
          if (searchText) {
            // Check if title contains
            if (
              containsSearchText(
                getModifierTitle(mod, matrixTitleMode),
                searchText
              ) ||
              searchText === mod?.id
            ) {
              eligibleModifiers.push(mod);
            }
          }
          // If no search term, push to stack
          else {
            eligibleModifiers.push(mod);
          }
        }
      });
      return eligibleModifiers;
    } else {
      const eligibleModifierIds: string[] = [];
      modifierGroups?.forEach((modifierGroup: any) => {
        if (modifierGroup?.modifiers) {
          modifierGroup.modifiers?.forEach((modifier: string) => {
            if (!eligibleModifierIds?.includes(modifier)) {
              eligibleModifierIds.push(modifier);
            }
          });
        }
      });
      const eligibleModifiers: DesignStudioModifier[] = [];
      eligibleModifierIds?.forEach((id: string) => {
        const mod = allModifiers?.[id];
        if (mod) {
          // If a search term is present
          if (searchText) {
            // Check if title contains
            if (
              containsSearchText(
                getModifierTitle(mod, matrixTitleMode),
                searchText
              )
            ) {
              eligibleModifiers.push(mod);
            }
          }
          // If no search term, push to stack
          else {
            eligibleModifiers.push(mod);
          }
        }
      });

      return eligibleModifiers.sort((a: any, b: any) =>
        a?.title?.localeCompare(b?.title)
      );
    }
  }, [
    filterBy,
    filterIds,
    allModifiers,
    modifierGroups,
    searchText,
    matrixTitleMode,
  ]);

  const table = useMemo(() => {
    return getProductModifierTable(
      sortProducts(products),
      modifiers,
      allProductModifiers,
      matrixEdits,
      {
        matrixMode,
        matrixSubMode,
        matrixTitleMode,
        transpose,
      }
    );
  }, [
    products,
    modifiers,
    allProductModifiers,
    matrixEdits,
    matrixMode,
    matrixSubMode,
    matrixTitleMode,
    transpose,
  ]);

  const productModifierPageProps: MatrixTablePageProps = {
    ...props,
    itemTotal: modifiers?.length ?? 0,
  };

  const closePriceDependencies = () => {
    setData(undefined);
  };

  return (
    <>
      <GenericMatrixTable
        transpose={transpose}
        pageNumber={pageNumber}
        itemsPerPage={itemsPerPage}
        table={table}
        heightOffset={heightOffset}
        cellRenderer={(cellProps: any) => {
          if (matrixMode === AdminProductCatalogMatrixModes.PRICE_SET) {
            return (
              <PriceSetTableCell
                customProps={sanitizeMatrixCellProps(cellProps, matrixEdits)}
                matrixEdits={matrixEdits}
                setMatrixEdits={setMatrixEdits}
                setData={setData}
                pageProps={productModifierPageProps}
                subMode={matrixSubMode}
                titleMode={matrixTitleMode}
              />
            );
          } else if (
            matrixMode === AdminProductCatalogMatrixModes.DESCRIPTIONS
          ) {
            return (
              <DescriptionTableCell
                customProps={sanitizeMatrixCellProps(cellProps, matrixEdits)}
                pageProps={productModifierPageProps}
              />
            );
          }
        }}
        onChange={(changes: any) => {
          const newMatrixEdits = getChanges(
            matrixMode,
            matrixSubMode,
            changes,
            matrixEdits
          );
          setMatrixEdits(newMatrixEdits);
        }}
      />
      <DependenciesModal
        data={data}
        show={data !== undefined}
        handleClose={closePriceDependencies}
      />
    </>
  );
};
