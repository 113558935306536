import React from "react";

export const OneLine = (props) => {
  const {leftText, rightText, id, ...otherProps} = props;

  return (
    <div className="one-line" id={id}>
      <p {...otherProps}>{leftText}</p>
      <p>{rightText}</p>
    </div>
  );
};
