import {NatomasMappingFeatureZones} from "@natomas-org/core";
import {Position} from "geojson";

export enum NatomasMapFeatureState {
  SAVED = "saved",
  CREATING = "creating",
  OTHER = "other",
}

export const getPolygonSetFeature = (
  set: NatomasMappingFeatureZones | null,
  state: NatomasMapFeatureState
) => {
  if (!set || !set.coordinates) {
    return [];
  }
  return set?.coordinates?.map((polygon: Position[][], index: number) => {
    return getPolygonFeature(
      polygon,
      state,
      `Section ${index + 1}`,
      1000 + index
    );
  });
};

// Feature ids must be a number
export const getPolygonFeature = (
  polygon: Position[][],
  state: NatomasMapFeatureState,
  description?: string,
  id?: number
) => {
  return {
    id: id,
    type: "Feature",
    properties: {
      description: description,
      state: state,
    },
    geometry: {
      type: "Polygon",
      coordinates: polygon,
    },
  };
};

export const getPointFeature = (point: Position, id?: number) => {
  return {
    id: id,
    type: "Feature",
    properties: {index: id, id: `bound-point`},
    geometry: {
      type: "Point",
      coordinates: [...point],
    },
  };
};
