import React from "react";
import {PardotIntakeForm} from "../../../components/intake-form/components/PardotIntakeForm";
import {useUTMInputs} from "../../../components/intake-form/hooks/useUTMInputs";

const ComfortLineInterestPardotForm = (props: {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}) => {
  const {firstName, lastName, email, phoneNumber} = props;
  const utms = useUTMInputs();
  return (
    <PardotIntakeForm
      formValues={[
        ...utms,
        {
          inputName: "created_from",
          inputType: "text",
          inputValue: "interest/comfort-line",
        },
        {
          inputName: "first_name",
          inputType: "text",
          inputValue: firstName,
        },
        {
          inputName: "last_name",
          inputType: "text",
          inputValue: lastName,
        },
        {
          inputName: "email",
          inputType: "text",
          inputValue: email,
        },
        {
          inputName: "phone",
          inputType: "text",
          inputValue: phoneNumber,
        },
      ]}
      pardotURL={"https://go.villahomes.com/l/909732/2024-03-04/snc3f"}
    />
  );
};

export default ComfortLineInterestPardotForm;
