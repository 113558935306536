import {DesignStudioModifier, IProduct} from "@natomas-org/core";
import {
  InfoSetItemOptionCondition,
  InfoSetItemOptionConditionType,
} from "../../../shared/interfaces";
import {InfoSetItemTableRow} from "../../table";
import {CONDITIONS_KEY, ID_KEY} from "../../../shared/constants";
import {
  IModifierValidation,
  IProductsValidation,
  IProductValidationDetails,
} from "./interfaces";
import {getUncertainKeysForProduct} from "../../../ProductHelper/helper";

export const validateProducts = (
  infoSetData: InfoSetItemTableRow[],
  products: IProduct[],
  categories: any,
  modifierGroups: any,
  modifiers: {[key: string]: DesignStudioModifier}
): IProductsValidation => {
  if (!products || products?.length === 0 || !modifiers) {
    return {};
  }

  const previouslyValidatedModifiers: {
    [modifierId: string]: InfoSetItemTableRow[];
  } = {};

  const validatedProducts: {
    [productId: string]: IProductValidationDetails;
  } = {};

  // Determine valid products from eligible
  products.forEach((product: IProduct) => {
    if (!product?.id) {
      return;
    }
    let validation: IProductValidationDetails = {
      product: product,
      modifierValidation: {},
      missingInfoSetKeys: [],
    };
    let presentInfoSetKeys: string[] = [];
    // If there are no categories, exit immediately
    if (!!product?.configuratorPages && product.configuratorPages.length > 0) {
      // Categories
      product.configuratorPages.forEach((categoryId: string) => {
        const category = categories?.[categoryId];

        // If category is not found, or has no modifierGroups, exit immediately
        if (!category || category.modifierGroups?.length === 0) {
          return;
        }

        // ModifierGroups
        category.modifierGroups.forEach((modifierGroupId: string) => {
          const modifierGroup = modifierGroups?.[modifierGroupId];

          if (!modifierGroup || modifierGroup.modifiers?.length === 0) {
            return;
          }

          // Modifiers
          modifierGroup.modifiers?.forEach((modifierId: string) => {
            const modifier = modifiers?.[modifierId];
            if (!modifier) {
              return;
            }
            let value: IModifierValidation;
            if (!previouslyValidatedModifiers[modifierId]) {
              const r = infoSetContainsModifier(infoSetData, modifierId);
              previouslyValidatedModifiers[modifierId] = r;
              value = {
                appearances: r,
                modifier: modifier,
              };
            } else {
              value = {
                appearances: previouslyValidatedModifiers[modifierId],
                modifier: modifier,
              };
            }
            validation.modifierValidation[modifierId] = value;
            const uniqueRowKeys = [
              ...new Set(
                value.appearances?.map(
                  (row: InfoSetItemTableRow) => row[ID_KEY]
                )
              ),
            ];
            uniqueRowKeys?.forEach((key: string) => {
              if (!presentInfoSetKeys.includes(key)) {
                presentInfoSetKeys.push(key);
              }
            });
          });
        });
      });
    }
    // InfoKeys eligible for being classified as 'missing'
    const eligibleMissingInfoSetKeys = getUncertainKeysForProduct(
      infoSetData,
      product
    );
    validation.missingInfoSetKeys = eligibleMissingInfoSetKeys.filter(
      (key: string) => !presentInfoSetKeys.includes(key)
    );
    validatedProducts[product.id] = validation;
  });
  return validatedProducts;
};

export const infoSetContainsModifier = (
  infoSetData: InfoSetItemTableRow[],
  modifierId: string
) => {
  return infoSetData.filter((item: InfoSetItemTableRow) => {
    return item?.[CONDITIONS_KEY]?.some((c: InfoSetItemOptionCondition) => {
      return c?.requiredModifiers?.[modifierId];
    });
  });
};

export const isOptionPossibleUnderConditionForProduct = (
  conditionType: InfoSetItemOptionConditionType,
  conditionRequirementsCount: number,
  contains: string[]
) => {
  if (conditionType === InfoSetItemOptionConditionType.ALL) {
    return contains.length === conditionRequirementsCount;
  } else if (conditionType === InfoSetItemOptionConditionType.ANY) {
    return contains.length > 0;
  } else {
    return false;
  }
};
