import styled from "styled-components";
import {
  PRIMARY_BUTTON_COLOR,
  TRANSPARENT_BLACK,
  VALID_COLOR,
  VILLA_BLUE,
  WHITE,
} from "../../../../../colors";
import {MODAL_Z_INDEX} from "../../../../../styles";
import {FONT_FAMILY_BOLD} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const ImageCardContainer = styled.div<{
  selected: boolean;
  zoomModifier: number;
}>`
  height: calc(
    10rem + ${(props) => (props?.zoomModifier ? props.zoomModifier * 5 : 0)}rem
  );
  width: calc(
    10rem + ${(props) => (props?.zoomModifier ? props.zoomModifier * 5 : 0)}rem
  );
  font-size: calc(
    1rem +
      ${(props) => (props?.zoomModifier ? props.zoomModifier * 0.25 : 0)}rem
  );
  position: relative;
  border-radius: 0.5rem;
  border: 4px solid ${(props) => (props?.selected ? VALID_COLOR : VILLA_BLUE)};
  cursor: pointer;
  overflow: hidden;
  white-space: pre-wrap;
  text-align: center;
  &:hover {
    border-color: ${(props) =>
      props?.selected ? VALID_COLOR : PRIMARY_BUTTON_COLOR};
  }
  .fullDiv {
    overflow: hidden;
  }
  transition-property: height, width, border-color, font-size;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
`;

export const ImageCardDetailsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  background-color: ${TRANSPARENT_BLACK};
  z-index: ${MODAL_Z_INDEX} !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ImageCardInfoContainer = styled.div`
  color: ${WHITE};
  font-family: ${FONT_FAMILY_BOLD};
  flex-grow: 1;
  display: flex;
  font-size: 0.75rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  div {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
`;

export const ImageCardButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`;

export const LoadMoreImageCardContent = styled.div`
  user-select: none;
  padding: 1rem;

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 0.5rem;

  color: ${WHITE};
  background-color: ${VILLA_BLUE};
  font-family: ${FONT_FAMILY_BOLD};
`;
