import {isAdmin} from "../../../../_shared/user/UserUtils";
import {Utilities} from "../../../../../database/firebase";
import {SitePlanImagePreview, WaitingContent} from "../styles";
import waiting_graphic from "../../../../../assets/images/graphics/waiting_graphic.png";
import {SmartImage} from "../../../../_shared/generics/image/SmartImage";
import React, {useContext, useMemo} from "react";
import {isMobile} from "../../../../_shared/navigation";
import {ImageAssignmentManager} from "../../../../_shared/generics/image/ImageAssignmentManager";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {getSitePlanImages} from "../../../../_shared/hooks/useProject/helper";
import {PROPOSAL_GENERATE_PDF} from "../../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";

export const SitePlanContent = (props) => {
  const {user, editing, sitePlanImageId} = props;
  const {id, site} = useActiveProject();
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const sitePlanImages = useMemo(() => {
    return getSitePlanImages(site);
  }, [site]);
  // Show image uploader for admin
  if (isAdmin(user) && (editing || sitePlanImageId == null)) {
    return (
      <div>
        <div style={{width: "100%", height: "400px", minHeight: "400px"}}>
          <ImageAssignmentManager
            forceAllow={true}
            imageIdValue={sitePlanImageId}
            replaceImageReference={sitePlanImageId != null}
            onComplete={(imageId) => {
              let payload;
              if (imageId) {
                payload = {
                  sitePlan: {
                    images: [...sitePlanImages, imageId]?.filter((x) => !!x),
                    imageId: imageId,
                  },
                };
              } else {
                payload = {
                  sitePlan: {
                    images: sitePlanImages.filter(
                      (imageId) => imageId !== sitePlanImageId && !!imageId
                    ),
                  },
                };
              }
              return Utilities.saveOrUpdateDocument(
                Utilities.collection("configurationSites"),
                id,
                payload
              );
            }}
          />
        </div>
      </div>
    );
  }

  // Show waiting graphic for customer
  if (sitePlanImageId == null) {
    return (
      <WaitingContent>
        <img
          src={waiting_graphic}
          alt={"Site Plan Image Missing"}
          width={"240px"}
        />
        <br />
        <div className={"large-text bold"} style={{marginBottom: "10px"}}>
          Waiting for Site Plan
        </div>
        <div style={{marginBottom: "24px"}}>
          Our sales team will upload your site plan once it's ready.
        </div>
      </WaitingContent>
    );
  }

  // Show full site plan image
  return (
    <SitePlanImagePreview isMobile={isMobile()}>
      <SmartImage
        forceWidth={isGeneratingPDF ? "min-content" : undefined}
        style={{
          width: isGeneratingPDF ? "min-content" : "100%",
          objectFit: "contain",
          height: "100%",
          margin: "auto",
        }}
        imageId={sitePlanImageId}
      />
    </SitePlanImagePreview>
  );
};
