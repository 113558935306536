import React, {useEffect, useState} from "react";
import {StandardElement} from "./styles";
import {usePage} from "../../../../hooks/usePage";

export interface IPageElement {
  children: any;
  size: number;
  height: string;
  overrideAnimations?: boolean;
  style?: React.CSSProperties;
}
export const PageElement = (props: IPageElement) => {
  const {
    getWidth,
    gutterWidthPercentage,
    containerWidth,
    pageWidth,
    containerPadding,
  } = usePage();

  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const newWidth = getWidth(props.size);
    if (newWidth > 0 && newWidth !== width) {
      setWidth(newWidth);
    }
  }, [
    props.size,
    containerPadding,
    containerWidth,
    pageWidth,
    getWidth,
    width,
  ]);

  return (
    <>
      {width && props.height && gutterWidthPercentage && (
        <StandardElement
          overrideAnimations={props.overrideAnimations}
          height={props.height}
          width={width}
          gutterWidthPercentage={gutterWidthPercentage}
          style={props.style}
        >
          {props.children}
        </StandardElement>
      )}
    </>
  );
};
