import React from "react";
import Text from "../../2-component/Text";
import {ContentWrapper} from "./style";

const LoggingOutContent = () => {
  return (
    <ContentWrapper>
      <Text text={"Logging out."} style={{textAlign: "center"}} />
    </ContentWrapper>
  );
};

export default LoggingOutContent;
