import React, {useMemo} from "react";
import {Address, Customer, IAddressDetails, IProduct} from "@natomas-org/core";
import {CSVLink} from "react-csv";
import {
  INTERNAL_CATEGORY_KEY,
  LABEL_KEY,
  VALUE_KEY,
} from "../../../../../../../factory-info-set/shared/constants";
import {OrderFormTabItem} from "../index";
import {useCurrentCustomer} from "../../../../../../../_shared/hooks/useCurrentCustomer";
import {NatWarningDiamondIcon} from "../../../../../../../_shared/icon/icons";
import {OrderFormDownloaderContainer} from "./styles";
import {getOrderFormProjectInfoRows} from "./helper";
import {syncOrderFormToSmartsheet} from "../../../../../../../../database/firebase/api/project";

export const OrderFormDownloader = (props: {
  tableData: OrderFormTabItem[];
  isDownloadable: boolean;
  address: IAddressDetails;
  code: string;
  product?: IProduct;
  projectId?: string;
}) => {
  const {tableData, address, code, product, projectId, isDownloadable} = props;
  const {customer} = useCurrentCustomer();
  const projectInfoRows = useMemo(() => {
    return getOrderFormProjectInfoRows(code, address, product);
  }, [code, address, product]);

  const csvDataHeaders = useMemo(() => {
    return ["Category", "Options", "Selection"];
  }, []);

  // TODO Update this with multiple order forms
  const csvData = useMemo(() => {
    const orderFormRows = tableData.map((row: OrderFormTabItem) => {
      return [
        row[INTERNAL_CATEGORY_KEY],
        row[LABEL_KEY],
        `${row[VALUE_KEY]?.value?.replace(/"+/g, '""') ?? "ERROR"}`,
      ];
    });
    return [...projectInfoRows, ...orderFormRows];
  }, [projectInfoRows, tableData]);

  return (
    <OrderFormDownloaderContainer>
      {isDownloadable && customer ? (
        <>
          <CSVLink
            data={csvData}
            headers={csvDataHeaders}
            target={"_blank"}
            enclosingCharacter={'"'}
            onClick={() => {
              if (!!projectId && !!code) {
                syncOrderFormToSmartsheet(projectId, csvData).then(() =>
                  console.log("Finished syncing request.")
                );
              }
            }}
            filename={`${Customer.getLastName(
              customer
            )}-${Address.getStreetNumberAndStreet(address ?? undefined).replace(
              /\s+/g,
              "-"
            )}`}
          >
            {code ? "Download + Sync Order Form" : "Download Order Form"}
          </CSVLink>
        </>
      ) : (
        <>
          <div
            style={{
              color: "red",
            }}
          >
            Incomplete
          </div>
          <NatWarningDiamondIcon color={"red"} size={20} />
        </>
      )}
    </OrderFormDownloaderContainer>
  );
};
