import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import {PRIMARY_BUTTON_COLOR, VILLA_BLUE} from "../../colors";

export const DocumentWrapper = styled.div`
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  border-radius: 10px;
  padding: 0.25rem 0.25rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
`;

export const PDFTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 1.35rem;
  width: 100%;
`;
export const PDFTitle = styled.div`
  padding-left: 0.5rem;
  text-decoration: underline;
`;
export const PDFDateContainer = styled.div`
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
`;

export const ColoredStatusLabel = styled.div<{
  color: string;
}>`
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  color: white;
  text-decoration: none;
  height: 100%;
  font-family: ${FONT_FAMILY_BOLD};
  border-radius: 1rem;
  background-color: ${(props) => props.color};
`;

export const PDFButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  column-gap: 1rem;
`;
export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #000;
  text-align: center;
  row-gap: 0.5rem;
  position: relative;
`;
export const ProgressBarText = styled.div`
  width: 100%;
  text-align: center;
  color: black;
  border-radius: 8px;
  top: 0;
  left: 0;
  position: absolute;
`;
export const ProgressBarFill = styled.div<{
  percentage: number;
  complete: boolean;
}>`
  top: 0;
  left: 0;
  position: absolute;
  width: ${(props) => props.percentage}%;
  text-align: start;
  height: 20px;
  ${(props) =>
    props.complete
      ? `background-color: ${PRIMARY_BUTTON_COLOR}`
      : `background-color: ${VILLA_BLUE}`};
  border-radius: 8px;
`;
