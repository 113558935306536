import {
  BUDGET_SECTION_ADDITIONAL,
  BUDGET_SECTION_UNIT,
  BUDGET_SUMMARY_SECTION_TYPE,
  ProjectPriceMode,
} from "@natomas-org/core";

export interface IBucketConfig {
  [key: string]: {label: string; value: string};
}

export const d: IBucketConfig = {
  "Preconstruction": {
    label: "Preconstruction",
    value: "Preconstruction",
  },
  "Jurisdictional Requirements": {
    label: "Jurisdictional Requirements",
    value: "Jurisdictional Requirements",
  },
  "Home Design": {
    label: "Home Design",
    value: "Home Design",
  },
  "Site Work": {
    label: "Site Work",
    value: "Site Work",
  },
  "Contingent Costs": {
    label: "Contingent Costs",
    value: "Contingent Costs",
  },
};

export const b: IBucketConfig = {
  [BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DESIGN_COSTPLUS]: {
    label: "Preconstruction",
    value: BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DESIGN_COSTPLUS,
  },
  [BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_PERMITS_COSTPLUS]: {
    label: "Permits",
    value: BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_PERMITS_COSTPLUS,
  },
  [BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_DTC]: {
    label: "Site Work",
    value: BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_DTC,
  },
  [BUDGET_SECTION_UNIT]: {
    label: "Home Design",
    value: BUDGET_SECTION_UNIT,
  },
  [BUDGET_SECTION_ADDITIONAL]: {
    label: "Additional",
    value: BUDGET_SECTION_ADDITIONAL,
  },
};

export const getBucketConfig = (priceMode: ProjectPriceMode) => {
  switch (priceMode) {
    case ProjectPriceMode.DTC:
      return d;
    case ProjectPriceMode.CostPlus:
      return b;
    default:
      return d;
  }
};
