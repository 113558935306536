import React from "react";
import EstimateEditor from "./EstimateEditor";
import AdminEstimateTable from "./AdminEstimate";
import {DesignSelectionsSpreadsheet} from "../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/BudgetSummaryTab";
import {RevealCostsToggle} from "./RevealCostsToggle";
import styled from "styled-components";
import {NatButton} from "../_shared/generics/button";
import {NatSize, StyleOption} from "../_shared/generics/_shared";
import {ComplexPricingToggle} from "./ComplexPricingToggle";

interface TableControllerProps {}

const AdminEstimateTableView = (props: TableControllerProps) => {
  const [showEditor, setShowEditor] = React.useState<boolean>(false);

  return (
    <EstimateTableContainer>
      <AdminControllersContainer>
        <DesignSelectionsSpreadsheet />
        <AdminControllerContainer>
          <ComplexPricingToggle />
          <RevealCostsToggle />
          <NatButton
            size={NatSize.SMALL}
            style={{whiteSpace: "nowrap"}}
            label={showEditor ? "Close Editor" : "Open Editor"}
            option={showEditor ? StyleOption.DESTRUCTIVE : StyleOption.PRIMARY}
            clickEvent={() => setShowEditor(!showEditor)}
          />
        </AdminControllerContainer>
      </AdminControllersContainer>
      {showEditor ? (
        <EstimateEditor
          onSave={() => {
            setShowEditor(false);
          }}
        />
      ) : (
        <AdminEstimateTable />
      )}
    </EstimateTableContainer>
  );
};

const EstimateTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
`;

const AdminControllersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const AdminControllerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: fit-content;
`;

export default AdminEstimateTableView;
