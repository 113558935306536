import React, {useEffect, useMemo} from "react";
import {
  CartItemSectionAction,
  CartItemSectionHeader,
  CartItemSectionHeaderContainer,
  CartItemSectionHeaderSubtext,
} from "./styles";
import {useCurrentUser} from "../../../../../_shared/hooks/useCurrentUser";
import {CartSectionActions} from "../../interfaces";

interface CartSectionHeaderProps {
  label?: string; // {LABEL} Units
  count?: number;
  setSelectedCartItemIds?: (ids: string[]) => void;
  actions?: CartSectionActions;
}

const CartSectionHeader = (props: CartSectionHeaderProps) => {
  const {label, actions, count, setSelectedCartItemIds} = props;
  const {isAdmin} = useCurrentUser();

  useEffect(() => {
    // Reset selections everytime the cart items change
    setSelectedCartItemIds?.([]);
  }, [count, setSelectedCartItemIds]);

  const sectionInfo = useMemo(() => {
    if (!count) {
      return {
        hidden: !label,
        header: label,
        subtext: undefined,
      };
    }
    const subtext = `(${count ?? 0} ${count !== 1 ? "units" : "unit"})`;
    return {
      hidden: !label,
      header: label,
      subtext: subtext,
    };
  }, [count, label]);

  return (
    <CartItemSectionHeaderContainer>
      <CartItemSectionHeader hidden={sectionInfo.hidden}>
        {sectionInfo.header}
      </CartItemSectionHeader>
      <CartItemSectionHeaderSubtext
        hidden={sectionInfo.hidden || !sectionInfo.subtext}
      >
        {sectionInfo.subtext}
      </CartItemSectionHeaderSubtext>
      {actions && (
        <>
          <CartItemSectionAction
            hidden={!actions.select.show}
            onClick={actions.select.event}
          >
            {actions.select.label}
          </CartItemSectionAction>
          <div hidden={!actions.hasSelectedItems}>|</div>
          <CartItemSectionAction
            hidden={!actions.move.show}
            onClick={actions.move.event}
          >
            {actions.move.label}
          </CartItemSectionAction>
          <CartItemSectionAction
            hidden={!isAdmin || !actions.add.show}
            style={{marginLeft: "auto"}}
            onClick={actions.add.event}
          >
            {actions.add.label}
          </CartItemSectionAction>
        </>
      )}
    </CartItemSectionHeaderContainer>
  );
};

export default CartSectionHeader;
