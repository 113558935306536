import useActiveCustomer from "../useCustomer/useActiveCustomer";
import useActiveProject from "../useProject/useActiveProject";
import {useCartItems} from "./useCartItem/useCartItems";
import {ICartItem} from "@natomas-org/core";
import {useMemo} from "react";
import useCustomerProjects from "../useProject/useCustomerProjects";

const useCartLibrary = () => {
  const {id: customerId, info: customerInfo} = useActiveCustomer();
  const {projects} = useCustomerProjects(customerId);
  // Customer Library
  const customerCartItems: ICartItem[] = useMemo(() => {
    return customerInfo?.cart_library ?? [];
  }, [customerInfo?.cart_library]);
  const customerCartItemIds = useMemo(() => {
    return customerCartItems.map((cartItem) => cartItem.id);
  }, [customerCartItems]);
  // Active Project
  const {id: projectId, cartItemIds: activeProjectCartItemIds} =
    useActiveProject();
  const activeProjectCartItems: ICartItem[] = useCartItems(
    activeProjectCartItemIds
  );
  // Other Project Cart Items
  const otherProjectCartItemIds = useMemo(() => {
    return Object.values(projects ?? {})
      ?.filter((project) => project.id !== projectId)
      .map((project) => project.cartItemIds)
      .flat();
  }, [projectId, projects]);
  /*
   * All cart items available to the project
   * - Customer Library
   * - Active Project
   * */
  const allAvailableCartItemIds = useMemo(() => {
    return [...customerCartItemIds, ...activeProjectCartItemIds];
  }, [customerCartItemIds, activeProjectCartItemIds]);

  /*
   * All cart items available to the customer
   * - Customer Library
   * - Active Project
   * - Other Projects
   * */
  const allCustomerCartItemIds = useMemo(() => {
    return [...allAvailableCartItemIds, ...otherProjectCartItemIds];
  }, [allAvailableCartItemIds, otherProjectCartItemIds]);

  return {
    customerId,
    customerInfo,
    projectId,
    customerCartItems,
    customerCartItemIds,
    activeProjectCartItems,
    activeProjectCartItemIds,
    allAvailableCartItemIds,
    allCustomerCartItemIds,
    otherProjectCartItemIds,
  };
};

export default useCartLibrary;
