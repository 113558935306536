import React, {useMemo} from "react";
import {NatSizeType} from "../../_shared";
import {useDispatch} from "react-redux";
import {NatDropdown} from "../../popup/dropdown/NatDropdown";
import {clearEditing} from "../../../slices/CatalogSlice/AdminStudioManagerSlice";
import {setProductGroupVersionId} from "../../../slices/CatalogSlice/CatalogSessionSlice";
import {tryFetchingCatalogFactory} from "../../../../../root/FetchingManager";
import {IFactorySummary} from "../../../hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../popup/dropdown/constants";

export const VersionSelectionDropdown = (props: {factory: IFactorySummary}) => {
  const {factory} = props;
  const {info, details} = factory ?? {};
  const dispatch = useDispatch();

  const label = useMemo(() => {
    if (!info || !factory || !details?.versionId) {
      return "Loading...";
    }
    switch (details?.versionId) {
      case info?.publishedVersionId:
        return "Studio: Live (v" + details?.version + ")";
      case info?.draftVersionId:
        return "Studio: Draft (v" + details?.version + ")";
      default:
        return "Unknown version";
    }
  }, [details, factory, info]);

  const dropdownViews = useMemo(() => {
    const callback = (id: string) => {
      dispatch(clearEditing());
      dispatch(setProductGroupVersionId(id));
      tryFetchingCatalogFactory(info?.id, {
        versionId: id,
        fullFetch: true,
      });
    };
    let views = [];
    if (!info) {
      return [];
    }
    if (!!factory) {
      if (info?.publishedVersionId) {
        views.push({
          label: "Live Version",
          id: info?.publishedVersionId,
          callback: () => {
            info?.publishedVersionId && callback(info?.publishedVersionId);
          },
        });
      }
      if (info?.draftVersionId) {
        views.push({
          label: "Draft Version",
          id: info?.draftVersionId,
          callback: () => {
            info?.draftVersionId && callback(info?.draftVersionId);
          },
        });
      }
    }
    return views;
  }, [factory, info]);

  return (
    <NatDropdown
      style={NatDropdownStyles[DropdownStyles.ADMIN][NatSizeType.XSMALL]}
      toggleButtonOrLabel={label}
      contentItemArray={dropdownViews}
      trackingId={"factory-line-version-dropdown-menu"}
      disabled={!factory}
    />
  );
};
