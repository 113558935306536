export const ImageSearchStructure = {
  tags: {
    name: "Tags",
    type: "dropdown-array",
    index: 1,
  },
};

export const getInitialValues = (defaultValues: any) => {
  return defaultValues ?? [];
};

export const areEqual = (currentTags?: string[], newTags?: string[]) => {
  if (!currentTags || !newTags) {
    return false;
  }

  const n = currentTags.length;
  const m = newTags.length;

  // If lengths of array are not equal means
  // array are not equal
  if (n != m) return false;

  // Sort both arrays
  currentTags.sort();
  newTags.sort();

  // Linearly compare elements
  for (let i = 0; i < n; i++) if (currentTags[i] !== newTags[i]) return false;

  // If all elements were same.
  return true;
};
