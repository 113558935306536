import {functions} from "../index";

export const sendWelcomeEmail = async (uid, configurationId) => {
  const request = {
    uid,
    configurationId,
  };
  return functions
    .httpsCallable("email-sendCustomerPortalWelcomeEmail")(request)
    .then((success) => {
      return success.data;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
