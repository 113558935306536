import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";
import {
  ConditionActionsContainer,
  ConditionCellRowContainer,
  ConditionLabel,
} from "./styles";
import {ConditionProductAssociations} from "./ConditionAssociations/ConditionProductAssociations/ConditionProductAssociations";
import {ConditionSelectionAssociations} from "./ConditionAssociations/ConditionSelectionAssociations/ConditionSelectionAssociations";
import {IProduct} from "@natomas-org/core";
import {InfoSetItemOptionCondition} from "../../../../../shared/interfaces";
import {NatButton} from "../../../../../../_shared/generics/button";
import {NatEditIcon} from "../../../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {CancelButton} from "./_shared/CancelButton";
import {RemoveButton} from "./_shared/RemoveButton";
import {SaveButton} from "./_shared/SaveButton";
import {EditButton} from "./_shared/EditButton";
import {getConditionLabel} from "../../helper";
import {ConditionMultiSelectionController} from "./ConditionMultiSelectionController";
import {
  ConditionMode,
  hasValidMode,
  isMultiCondition,
  isProductCondition,
  isSelectionCondition,
} from "./_shared/helper";

export const ConditionCellRow = (props: {
  save: (arg: any) => void;
  remove: (arg: any) => void;
  title: string;
  condition: InfoSetItemOptionCondition;
  categories: any;
  modifierGroups: any;
  modifierOptions: any;
  productOptions: any;
}) => {
  const {
    save,
    remove,
    condition,
    categories,
    productOptions,
    modifierOptions,
    modifierGroups,
    title,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [mode, setMode] = useState<ConditionMode>(undefined);
  const [localCondition, setLocalCondition] = useState<
    InfoSetItemOptionCondition | undefined
  >(condition);

  useEffect(() => {
    setLocalCondition(condition);
  }, [condition]);

  const selectedModifiers = useMemo(() => {
    if (!localCondition) {
      return [];
    }
    return (
      Object.keys(localCondition?.requiredModifiers)?.map((mid: string) => {
        return modifierOptions.find((o: any) => o.value === mid);
      }) ?? []
    );
  }, [localCondition, modifierOptions]);

  const selectedProducts: {value: string; label: string}[] = useMemo(() => {
    if (!localCondition) {
      return [];
    }
    return (
      localCondition?.targets?.map((pid: string) => {
        const p = productOptions.find((o: any) => o.id === pid);
        return {value: p.id, label: p?.title ?? "Unnamed"};
      }) ?? []
    );
  }, [localCondition, productOptions]);

  const determineMode = () => {
    // Determine mode
    const hasSelections = selectedModifiers?.length > 0;
    const hasProducts = selectedProducts?.length > 0;
    if (hasProducts && hasSelections) {
      setMode("both");
    } else if (hasSelections) {
      setMode("selection");
    } else if (hasProducts) {
      setMode("product");
    } else {
      setMode(undefined);
    }
  };

  useEffect(() => {
    if (!isEditing) {
      determineMode();
    }
  }, [isEditing, selectedProducts, selectedModifiers]);

  const reset = () => {
    setLocalCondition(condition);
    setIsEditing(false);
  };

  const update = (condition: InfoSetItemOptionCondition): void => {
    setLocalCondition(condition);
  };

  const commit = () => {
    if (localCondition) {
      save(localCondition);
    }
    setIsEditing(false);
    setMode(undefined);
  };

  const isValid = () => {
    const hasSelections = selectedModifiers?.length > 0;
    const hasProducts = selectedProducts?.length > 0;
    return hasSelections || hasProducts;
  };

  const isEdited = () => {
    if (localCondition && condition) {
      return !_?.isEqual(localCondition, condition);
    }
    return false;
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const label = useMemo(() => {
    if (localCondition) {
      return getConditionLabel(localCondition, productOptions, modifierOptions);
    }
    return null;
  }, [localCondition, productOptions, modifierOptions]);

  return (
    <ConditionCellRowContainer>
      <ConditionActionsContainer>
        {isEdited() && <SaveButton save={commit} />}
        {isEditing && (
          <>
            <CancelButton cancel={reset} />
            <RemoveButton remove={() => remove(condition)} />
          </>
        )}
        {!isEditing && <EditButton edit={startEditing} isValid={isValid()} />}
      </ConditionActionsContainer>
      {isSelectionCondition(mode) && (
        <ConditionSelectionAssociations
          title={title}
          condition={localCondition ?? condition}
          productOptions={productOptions}
          modifierOptions={modifierOptions}
          categories={categories}
          modifierGroups={modifierGroups}
        />
      )}
      {isProductCondition(mode) && (
        <ConditionProductAssociations
          title={title}
          condition={localCondition ?? condition}
          productOptions={productOptions}
        />
      )}
      {isMultiCondition(mode) && (
        <ConditionSelectionAssociations
          title={title}
          condition={localCondition ?? condition}
          productOptions={productOptions.filter((p: IProduct) => {
            return selectedProducts.some(
              (o: {value: string; label: string}) => p.id === o.value
            );
          })}
          modifierOptions={modifierOptions}
          categories={categories}
          modifierGroups={modifierGroups}
        />
      )}
      {isEditing && hasValidMode(mode) && (
        <ConditionMultiSelectionController
          currentType={localCondition?.type ?? condition?.type}
          currentSelectedModifiers={selectedModifiers}
          modifierOptions={modifierOptions}
          currentSelectedProducts={selectedProducts}
          productOptions={productOptions.map((p: IProduct) => {
            return {value: p.id, label: p?.title ?? "Unnamed"};
          })}
          update={update}
          mode={mode}
        />
      )}
      {isEditing && !hasValidMode(mode) && (
        <ConditionActionsContainer>
          <NatButton
            label={"By Product"}
            clickEvent={() => setMode("product")}
            option={StyleOption.PRIMARY_ALT}
            size={NatSize.XSMALL}
            icon={{icon: <NatEditIcon />, iconPosition: IconPosition.LEFT}}
          />
          <NatButton
            label={"By Selection"}
            clickEvent={() => setMode("selection")}
            option={StyleOption.PRIMARY_ALT}
            size={NatSize.XSMALL}
            icon={{icon: <NatEditIcon />, iconPosition: IconPosition.LEFT}}
          />
          <NatButton
            label={"By Both"}
            clickEvent={() => setMode("both")}
            option={StyleOption.PRIMARY_ALT}
            size={NatSize.XSMALL}
            icon={{icon: <NatEditIcon />, iconPosition: IconPosition.LEFT}}
          />
        </ConditionActionsContainer>
      )}
      {!isEditing && label && <ConditionLabel>{label}</ConditionLabel>}
    </ConditionCellRowContainer>
  );
};
