import React from "react";
import {CatalogBanner} from "./CatalogBanner";
import {
  DetailsSectionContainer,
  DetailsSectionContent,
  DetailsSectionTitle,
} from "./styles";
import {usePage} from "../../../../../../_shared/hooks/usePage";
import {HomeSelectionBanner} from "./HomeSelectionBanner";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../../_shared/hooks/useDesign/useActiveDesign";

export const CTADetailsSection = () => {
  const {site} = useActiveProject();
  const {configuration} = useActiveDesign();
  const {product} = configuration ?? {};
  const {isNatMobile} = usePage();

  const getTitle = () => {
    if (product === undefined) {
      return "Explore our ADU models";
    } else {
      return "Your home selection";
    }
  };

  const getContent = () => {
    if (product === undefined) {
      return <CatalogBanner />;
    } else {
      return (
        <HomeSelectionBanner showEstimate={!!site && !!site?.surveyAnswers} />
      );
    }
  };

  return (
    <DetailsSectionContainer>
      <DetailsSectionTitle>{getTitle()}</DetailsSectionTitle>
      <DetailsSectionContent mobile={isNatMobile}>
        {getContent()}
      </DetailsSectionContent>
    </DetailsSectionContainer>
  );
};
