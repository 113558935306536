import React, {useCallback, useMemo, useState} from "react";
import {B2B_PRICING_DISPLAY_NAME, ProjectPriceMode} from "@natomas-org/core";
import NatSwitch from "../../../../../../../_shared/generics/switch";
import {updateProjectInfoAction} from "../../../../../../../../database/firebase/api/project";
import {PricingOptionsController} from "../../../../../../../portal/views/ProposalBudgetView/components/OP/PricingOptionsController";
import {ControlPanelSectionWrapper} from "../ControlPanelSectionWrapper";
import {FormikProps} from "formik";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";
import ChangePricingModeWarning from "../WarningModals/ChangePricingModeWarning";
import {COMPLEX_PRICING_KEY} from "../project_model";
import {useProjectBudget} from "../../../../../../../_shared/hooks/useProjectBudget/useProjectBudget";

export const PROJECT_PRICE_MODE_KEY = "projectPriceMode";
export const PROJECT_OP_KEY = "projectOP";
export const PROJECT_PHASE1_KEY = "phase1Micros";
export const PROJECT_PHASE2_KEY = "phase2Micros";
export const ControlPanelProjectPriceMode = (props: {
  formik?: FormikProps<any>;
}) => {
  const {
    projectPriceMode,
    id: currentConfigurationId,
    projectOverheadProfit,
    projectPriceSettings,
  } = useActiveProject();
  const {projectBudget} = useProjectBudget();
  const phase1MicrosPerUnit = projectBudget?.phase_1_micros ?? 0;
  const phase2MicrosPerUnit = projectBudget?.phase_2_micros ?? 0;
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [targetOption, setTargetOption] = useState<any>(null);
  const options = useMemo(() => {
    return [
      {id: ProjectPriceMode.DTC, label: "DTC Pricing"},
      {
        id: ProjectPriceMode.CostPlus,
        label: `${B2B_PRICING_DISPLAY_NAME} Pricing`,
      },
    ];
  }, []);

  const activeOption = useMemo(() => {
    let localComparator = projectPriceMode;
    if (props.formik) {
      localComparator = props.formik.values[PROJECT_PRICE_MODE_KEY];
    }
    if (localComparator === ProjectPriceMode.CostPlus) {
      return options[1];
    } else if (localComparator === ProjectPriceMode.DTC) {
      return options[0];
    }
  }, [options, projectPriceMode, props.formik]);

  const changeOption = useCallback(
    (selectedOption: {id: number | string; label: string}) => {
      setTargetOption(selectedOption);
      setShowWarning(true);
    },
    []
  );
  const handleOptionChange = (selectedOption: {
    id: number | string;
    label: string;
  }) => {
    if (
      typeof selectedOption.id === "string" &&
      Object.values(ProjectPriceMode).includes(
        selectedOption.id as ProjectPriceMode
      )
    ) {
      let projectPriceModePayload:
        | {
            mode: ProjectPriceMode.DTC;
            overheadProfitFraction?: number;
            complex_pricing_enabled?: boolean;
          }
        | {
            mode: ProjectPriceMode.CostPlus;
            overheadProfitFraction: number | undefined;
            phase1Micros?: number;
            phase2Micros?: number;
            complex_pricing_enabled?: boolean;
          }
        | undefined;
      console.log("Setting price mode:", selectedOption, props.formik?.values);
      if (selectedOption.id === ProjectPriceMode.CostPlus) {
        projectPriceModePayload = {
          mode: selectedOption.id,
          overheadProfitFraction: projectOverheadProfit,
          phase1Micros:
            props.formik?.values?.[PROJECT_PHASE1_KEY] ?? phase1MicrosPerUnit,
          phase2Micros:
            props.formik?.values?.[PROJECT_PHASE2_KEY] ?? phase1MicrosPerUnit,
          complex_pricing_enabled:
            props.formik?.values?.[COMPLEX_PRICING_KEY] ??
            projectPriceSettings?.complex_pricing_enabled ??
            false,
        };
      } else if (selectedOption.id === ProjectPriceMode.DTC) {
        projectPriceModePayload = {
          mode: selectedOption.id,
          overheadProfitFraction: projectOverheadProfit,
          complex_pricing_enabled:
            props.formik?.values?.[COMPLEX_PRICING_KEY] ??
            projectPriceSettings?.complex_pricing_enabled ??
            false,
        };
      }
      if (!projectPriceModePayload) {
        return;
      }
      if (!props.formik) {
        return updateProjectInfoAction({
          projectId: currentConfigurationId,
          pricingMode: projectPriceModePayload,
        });
      } else {
        props.formik.setFieldValue(
          PROJECT_PRICE_MODE_KEY,
          projectPriceModePayload.mode
        );
        props.formik.setFieldValue(
          PROJECT_PHASE1_KEY,
          props.formik?.values?.[PROJECT_PHASE1_KEY] ?? phase1MicrosPerUnit
        );
        props.formik.setFieldValue(
          PROJECT_PHASE2_KEY,
          props.formik?.values?.[PROJECT_PHASE2_KEY] ?? phase2MicrosPerUnit
        );
        props.formik.setFieldValue(
          COMPLEX_PRICING_KEY,
          props.formik?.values?.[COMPLEX_PRICING_KEY] ??
            projectPriceSettings?.complex_pricing_enabled ??
            false
        );
        return props.formik.setFieldValue(
          PROJECT_OP_KEY,
          projectPriceModePayload.overheadProfitFraction
        );
      }
    }
  };
  return (
    <ControlPanelSectionWrapper
      title={"Pricing Settings"}
      style={{maxWidth: "17.5rem"}}
    >
      <ChangePricingModeWarning
        show={showWarning}
        handleCancel={() => setShowWarning(false)}
        handleConfirm={() => {
          handleOptionChange(targetOption);
          setShowWarning(false);
        }}
        title={
          "Warning! You are about to change the way pricing is viewed by the customer."
        }
        description={
          "If you are changing the setting to CostPlus, ensure that this is a B2B customer. You still must confirm these changes by clicking 'Save'."
        }
      />
      <NatSwitch
        options={options}
        onOptionChange={changeOption}
        activeOption={activeOption}
      />
      <br />
      <PricingOptionsController formik={props.formik} />
    </ControlPanelSectionWrapper>
  );
};
