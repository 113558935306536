import React from "react";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {NatTrashIcon} from "../../../../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";

export const RemoveButton = (props: {remove: () => void}) => {
  return (
    <NatButton
      label={"Remove"}
      clickEvent={props.remove}
      option={StyleOption.DESTRUCTIVE}
      size={NatSize.SMALL}
      icon={{icon: <NatTrashIcon />, iconPosition: IconPosition.ONLY}}
    />
  );
};
