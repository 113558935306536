import {
  BUTTON_FILL_COLOR_GRAY_2,
  CONTAINER_BACKGROUND_COLOR,
  SIDEBAR_COLOR,
  SIDEBAR_COLOR_LIGHT,
  WHITE,
} from "src/components/_shared/colors";
import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../components/portal/views/SSDDashboardView/styles/globals";

export const TableHeaderContainer = styled.thead`
  display: contents;
`;

export const TableHeader = styled.th`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;

  cursor: pointer;
  user-select: none;

  text-align: left;
  font-weight: normal;
  font-size: 0.875rem;

  color: white;
  background-color: ${SIDEBAR_COLOR};
  border-bottom: 1px solid ${BUTTON_FILL_COLOR_GRAY_2};
  &:not(:last-child) {
    border-right: 1px solid ${BUTTON_FILL_COLOR_GRAY_2};
  }
`;
export const TableData = styled.td<{
  bold?: boolean;
  center?: boolean;
  backgroundColor?: string;
}>`
  background-color: ${(props) => props.backgroundColor ?? WHITE};
  padding: 0.5rem 1rem;
  white-space: pre-wrap;
  color: #404040;
  // Formatting children
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  font-family: ${(props) =>
      props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR},
    sans-serif;
  align-items: ${(props) => (props.center ? "center" : "flex-left")};
  border-bottom: 1px solid ${BUTTON_FILL_COLOR_GRAY_2};
  border-right: 1px solid ${BUTTON_FILL_COLOR_GRAY_2};
`;

export const Table = styled.table<{columnWidths: string; fitToWidth: boolean}>`
  display: grid;
  border-collapse: collapse;
  max-height: 100%;
  height: fit-content;
  overflow-y: auto;
  min-width: ${(props) => props.fitToWidth && "100%"};
  grid-template-columns: ${(props) => props.columnWidths};
  border-left: 1px solid ${BUTTON_FILL_COLOR_GRAY_2};
`;

export const TableBody = styled.tbody`
  display: contents;
`;

export const TableRow = styled.tr<{isSelected?: boolean}>`
  display: contents;
  ${TableData} {
    background-color: ${(props) =>
      props.isSelected && CONTAINER_BACKGROUND_COLOR};
  }
  &:hover {
    ${TableData} {
      background-color: ${SIDEBAR_COLOR_LIGHT};
      cursor: pointer;
    }
  }
`;
