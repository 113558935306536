import React from "react";
import {BodyContainer, Header, HeaderContainer} from "./styles";
import CurrentUserDetails from "./CurrentUserDetails";
import {getEstimateService} from "../../../api/Estimate/EstimateService";
import NexusEndpointController from "./NexusEndpointController";
import useActivitySlice from "../../_shared/hooks/useActivity/useActivitySlice";

const getEstimatesByProjectId = async (pid: string) => {
  const e = await getEstimateService();
  return e.getAllByProjectId(pid);
};

const getLatestEstimateProjectRange = async (pid: string) => {
  const e = await getEstimateService();
  return e.getLatestEstimate(pid);
};

const getLatestEstimateProjectRangeWithMerge = async (pid: string) => {
  const e = await getEstimateService();
  return e.getLatestEstimate(pid);
};

const NexusEstimateDemo = () => {
  const {activeProjectId} = useActivitySlice();

  return (
    <>
      <HeaderContainer>
        <Header>villa-nexus estimate environment</Header>
        <CurrentUserDetails />
      </HeaderContainer>
      <BodyContainer>
        <NexusEndpointController
          methodArgs={[activeProjectId]}
          method={getEstimatesByProjectId}
          label={"getEstimatesByProjectId"}
        />
        <NexusEndpointController
          methodArgs={[activeProjectId]}
          method={getLatestEstimateProjectRangeWithMerge}
          label={"getLatestEstimateProjectRangeWithMerge"}
        />
        <NexusEndpointController
          methodArgs={[activeProjectId]}
          method={getLatestEstimateProjectRange}
          label={"getLatestEstimateProjectRange"}
        />
      </BodyContainer>
    </>
  );
};

export default NexusEstimateDemo;
