import styled from "styled-components";

export const CollectionContainer = styled.div<{
  productLineCardsPerRow: number;
  activeItemIndex: number;
}>`
  // This is all unnecessary... but may be a helpful template in the future
  .use-spring-carousel-item {
    // max-width: \${(props) =>
    //   getWidth(
    //     props?.theme?.Card?.columnWidth,
    //     props?.theme?.Page?.columnCount,
    //     props?.theme?.Page?.gutterWidthPercentage
    //   )}%;
    &:nth-child(n) {
      margin-right: ${(props: {
        productLineCardsPerRow: number;
        activeItemIndex: number;
      }) =>
        props.productLineCardsPerRow % 2 !== 0
          ? props.productLineCardsPerRow !== 1
            ? 0
            : 0
          : 0}% !important;
    }

    &:nth-child(
        n
          + ${(props: {
            activeItemIndex: number;
            productLineCardsPerRow: number;
          }) => props.activeItemIndex + 1}
      ) {
      margin-right: 0 !important;
    }
    &:nth-child(
        n
          + ${(props: {
            productLineCardsPerRow: number;
            activeItemIndex: number;
          }) => props.productLineCardsPerRow + props.activeItemIndex}
      ) {
      margin-right: ${(props: {
        productLineCardsPerRow: number;
        activeItemIndex: number;
      }) =>
        props.productLineCardsPerRow % 2 !== 0
          ? props.productLineCardsPerRow !== 1
            ? 0
            : 0
          : 0}% !important;
    }
  }
`;
export const CollectionItemWrapper = styled.div<{
  productLineCardsPerRow: number;
}>`
  width: 100%;
  overflow: hidden;
  padding: 0 ${(props) => props.productLineCardsPerRow}%;
`;
