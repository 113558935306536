export const OVERALL_COST_TAB_NAME = "Summary";
export const UNIT_COST_TAB_NAME = "Home Design";
export const SITE_WORK_COST_TAB_NAME = "Site Work";
export const ADDITIONAL_SITE_WORK_COST_TAB_NAME = "Additional Site Work";
export const PROJECT_CONTINGENCY_TAB_NAME = "Project Contingency";
export const PHASE_1_TAB_NAME = "Preconstruction";
export const PHASE_1_PERMIT_TAB_NAME = "Preconstruction - Permits";
export const PHASE_1_DESIGN_TAB_NAME = "Preconstruction - Design";

export const OVERALL_COST_TAB_ID = "overall";
export const PHASE_1_TAB_ID = "phase1";
