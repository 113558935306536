import {IUserAccess} from "@natomas-org/core";
import {executeRequest} from "../APIExecutor";

const changeUserPermissionsPath = "/permissions/v1/changeUserPermissions";
const changeSuperAdminStatusPath = "/permissions/v1/changeSuperAdminStatus";
const assignEmployeeToManagerPath = "/permissions/v1/assignEmployeeToManager";

export const changeUserPermissions = async (
  uid: string,
  permissions: IUserAccess
) => {
  return executeRequest(changeUserPermissionsPath, {
    target_uid: uid,
    permissions: permissions,
  })
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const changeSuperAdminStatus = async (uid: string, status: boolean) => {
  return executeRequest(changeSuperAdminStatusPath, {
    target_uid: uid,
    status: status,
  })
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const assignEmployeeToManager = async (
  manager_id: string,
  employee_id: boolean
) => {
  return executeRequest(assignEmployeeToManagerPath, {
    manager_id: manager_id,
    employee_id: employee_id,
  })
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const assignEmployeeToSelf = async (
  uid: string,
  employee_id: boolean
) => {
  return assignEmployeeToManager(uid, employee_id);
};
