import styled, {css} from "styled-components";
import {isMobile} from "../../../../_shared/navigation";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../SSDDashboardView/styles/globals";
import {VILLA_APPLE_GREEN, VILLA_LILAC} from "../../../../_shared/colors";

export const PROJECT_SCHEDULE_1 = "#D1EBCC";
export const PROJECT_SCHEDULE_2 = "#FFDF8D";
export const PROJECT_SCHEDULE_3 = "#F08080";
export const PROJECT_SCHEDULE_4 = "#B7CDFF";
export const PROJECT_SCHEDULE_5 = VILLA_LILAC;
const MOBILE_TIMELINE_ROW_HEIGHT = 70;

export const PhaseTitle = styled.div<{
  color: string;
}>`
  font-size: 12px;
  margin-left: 10px;
  font-family: ${FONT_FAMILY_BOLD};
  margin-top: -19px;
  width: fit-content;
  text-align: center;
  border-radius: 4px;
  padding: 0 8px;
  background-color: ${(props) => props.color + "33"};
`;
export const PhaseBox = styled.div<{
  show: boolean;
  startLineColor: string;
  endLineColor: string;
  width: number;
  start: number;
  numberOfRows: number;
}>`
  position: absolute;
  left: ${(props) => props.start + "%"};
  width: ${(props) => props.width + "%"};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  background-image: linear-gradient(
    to right,
    ${(props) => props.startLineColor + "1A"},
    ${(props) => props.endLineColor + "1A"}
      // Addition of 33 makes hex transparent
  );
  border-left: 2px dotted ${(props) => props.startLineColor};
  border-right: 2px dotted ${(props) => props.endLineColor};
  height: ${(props) => `${(1 + props.numberOfRows) * 100}px`};
  opacity: 1;
  pointer-events: none;
  z-index: -1;
`;
//
// export const MainContainer = styled.div<{active: boolean}>`
//   height: 100%;
//   padding-right: 0;
//   display: ${(props) => (props.active ? "block" : "none")};
// `;
//
// export const MobileMainContainer = styled.div<{active: boolean}>`
//   overflow: hidden;
//   width: 100%;
//   display: ${(props) => (props.active ? "block" : "none")};
// `;
//
// export const ProjectCostContainer = styled.div<{isNatMobile: boolean}>`
//   width: 790px;
//   margin-top: 50px;
//   max-width: 100%;
//   overflow-x: auto;
//   ${(props) =>
//     props.isNatMobile ? "" : "box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);"};
//
//   border-radius: 5px;
//   position: relative;
//   display: flex;
//   align-items: center;
//   ${(props) => (props.isNatMobile ? "flex-wrap: wrap;" : "")};
//   ${(props) => (props.isNatMobile ? "height: max-content;" : "height : 160px")};
//   margin-left: 1rem;
// `;
//
// export const ProjectTotalCostContainer = styled.div<{isNatMobile: boolean}>`
//   position: relative;
//   ${(props) =>
//     props.isNatMobile ? "padding-left: 0px;" : "padding-left: 60px;"};
//   ${(props) => (props.isNatMobile ? "text-align: center;" : "")};
//   ${(props) => (props.isNatMobile ? "width: 100%;" : "width: 33%")};
//   line-height: 1.9;
//   min-width: fit-content;
// `;
//
// export const ProjectCostBreakdownContainer = styled.div<{isNatMobile: boolean}>`
//   position: relative;
//   flex-grow: 2;
//   ${(props) =>
//     props.isNatMobile ? "" : "border-left: rgba(0, 0, 0, 0.3) solid 1px;"};
//   ${(props) =>
//     props.isNatMobile ? "padding-left: 0px;" : "padding-left: 50px;"};
// `;
//
// export const UnitSiteBreakdownContainer = styled.div<{isNatMobile: boolean}>`
//   position: relative;
//   display: flex;
//   margin-top: 8px;
//   ${(props) => (props.isNatMobile ? "flex-wrap: wrap" : "")};
// `;
//
// export const BudgetBreakdownTitle = styled.div<{isNatMobile: boolean}>`
//   ${(props) => (props.isNatMobile ? "text-align: center" : "")};
// `;
//
// export const BreakdownContainer = styled.div<{isNatMobile: boolean}>`
//   flex-grow: 2;
//   margin-right: 10px;
//   ${(props) => (props.isNatMobile ? "width: 100%" : "")};
//   ${(props) => (props.isNatMobile ? "text-align: center" : "")};
// `;
//
// export const BreakdownTitle = styled.div<{isNatMobile: boolean}>`
//   font-size: 14px;
//   ${(props) => (props.isNatMobile ? "" : "max-content")};
// `;
//
// export const BreakdownValue = styled.div`
//   font-size: 22px;
// `;
// export const BreakdownValueFlex = styled.div`
//   font-size: 22px;
//   display: flex;
//   align-items: center;
// `;
// export const BreakdownValueFlag = styled.div<{hiddenElement: boolean}>`
//   font-size: 14px;
//   margin-left: 12px;
//   height: fit-content;
//   font-family: ${FONT_FAMILY_REGULAR};
//   padding: 4px 10px;
//   border-radius: 5px;
//   background-color: #ffd1e7;
//   display: ${(props) => (props.hiddenElement === true ? "none" : "block")};
// `;
// export const TabTitle = styled.div`
//   font-size: 20px;
// `;
//
// export const ToggleContainer = styled.div`
//   width: fit-content;
//   border-radius: 100px;
// `;
//
// export const CostTabToggleContainer = styled.div`
//   height: 50px;
//   display: flex;
//   margin-bottom: 40px;
//   border-radius: 100px;
//   position: relative;
//   min-width: fit-content;
//   overflow-x: auto;
//   box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
// `;
//
// export const CostTabToggle = styled.div<{active: boolean}>`
//   line-height: 50px;
//   text-align: center;
//   padding-right: 20px;
//   padding-left: 20px;
//   border-radius: 100px;
//   min-width: fit-content;
//   background-color: ${(props) =>
//     props.active ? "rgba(130, 194, 117, 0.1)" : "none"};
//   box-shadow: ${(props) =>
//     props.active ? `inset 0px 0px 0px 3px #82C275` : "none"};
//   font-family: ${(props) =>
//     props.active ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
//   cursor: pointer;
// `;

// export const ToDoListTag = styled.div`
//   background: rgba(110, 188, 233, 0.3);
//   border-radius: 5px;
//   padding: 4px 10px;
//   width: fit-content;
//   color: rgba(0, 0, 0, 0.8);
//   font-family: ${FONT_FAMILY_MEDIUM};
//   font-size: 14px;
// `;
//
// export const BreakdownPageContainer = styled.div`
//   margin-top: 30px;
//   margin-bottom: 60px;
// `;
//
// export const TabRow = styled.div`
//   height: 60px;
//   display: flex;
//   justify-content: space-between;
//   overflow-x: auto;
//   overflow-y: hidden;
//   border-bottom: solid rgba(0, 0, 0, 0.3) 1px;
//   margin-top: 10px;
//   position: relative;
// `;
// export const TabContainer = styled.div`
//   margin-top: 20px;
//   height: 40px;
//   position: relative;
//   display: flex;
// `;
//
// export const Tab = styled.div<{active: boolean}>`
//   height: 100%;
//   margin-right: 40px;
//   min-width: fit-content;
//   flex-shrink: 0;
//   border-bottom: ${(props) => (props.active ? "3px solid #82c275" : "none")};
//   font-family: ${(props) =>
//     props.active ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
//   position: relative;
//   cursor: pointer;
// `;
//
// export const ProposalContainer = styled.div`
//   width: 100%;
//   //height: calc(100% - 65px);
//   //overflow: hidden;
// `;
//
// export const Container = styled.div`
//   height: calc(100% - 60px);
//   width: 100%;
//   padding-left: 0;
//   overflow: auto;
//   position: relative;
// `;
//
// export const Title = styled.div`
//   font-family: ${FONT_FAMILY_BOLD};
//   font-size: 20px;
//   margin-top: 30px;
//   margin-bottom: 6px;
// `;
//
// export const SubTitle = styled.div`
//   font-size: 14px;
// `;
//
// export const ParentBoxContainer = styled.div`
//   display: flex;
//   margin-top: 21px;
//   margin-bottom: 30px;
//   overflow-x: auto;
//   width: 100%;
// `;
//
// export const BoxContainer = styled.div`
//   width: 300px;
//   height: fit-content;
//   min-width: 300px;
//   min-height: 160px;
//   background: #ffffff;
//   border: 1px solid rgba(0, 0, 0, 0.1);
//   box-sizing: border-box;
//   box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
//   border-radius: 5px;
//   margin-right: 60px;
//   padding: 16px 26px;
//   margin-bottom: 12px;
// `;
//
// export const BoxTitle = styled.div`
//   color: rgba(0, 0, 0, 0.6);
//   font-size: 14px;
//   margin-bottom: 10px;
// `;
// export const BoxContentContainer = styled.div`
//   width: 100%;
//   display: flex;
//   font-size: 16px;
// `;
// export const BoxLeftContent = styled.div`
//   width: 20px;
//   margin-right: 10px;
// `;
// export const BoxRightContent = styled.div`
//   flex-grow: 1;
//   font-size: 16px;
// `;
// export const CallToAction = styled.div`
//   margin-top: 4px;
//   font-size: 14px;
//   cursor: pointer;
//   text-decoration: underline;
// `;
export const TimeLineParent = styled.div`
  //border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  width: 100%;
`;
export const TimeLineHeader = styled.div`
  margin-top: 46px;
  margin-bottom: 34px;
  display: flex;
  justify-content: right;
`;
export const TimeLineHeaderTitle = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 20px;
`;
export const TimeLineHeaderETA = styled.div`
  display: ${isMobile() ? "none" : "flex"};
  font-size: 14px;
  align-items: center;
`;

export const MobileTimeLineHeaderETAComponent = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  div {
    margin-right: 10px;
  }

  white-space: nowrap;
  overflow: hidden;
`;

export const TimeLineHeaderETAComponent = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  div {
    margin-right: 10px;
  }
`;
export const TimeLineFooter = styled.div`
  margin-top: 4px;
  text-align: right;
  font-size: 12px;
`;

export const TimelineContainer = styled.div`
  display: flex;
`;
export const TimelineLeftColumn = styled.div`
  width: 225px;
  flex-shrink: 0;
`;
export const TimelineRightColumn = styled.div<{forPrint?: boolean}>`
  flex-grow: 2;
  overflow-x: ${(props) => (props.forPrint ? "hidden" : "auto")};
`;
export const TimelineTable = styled.div`
  min-width: 900px;
  flex-grow: 2;
  position: relative;
`;
export const TimelineTableCatalog = styled.div`
  min-width: 100%;
  flex-grow: 2;
`;
export const TimelineRowTop = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
`;
export const TimelineRow = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

export const MilestoneSummary = styled.div`
  //padding: 1rem;
  page-break-inside: avoid;
  break-inside: avoid;
`;

export const MilestoneSummaryContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  row-gap: 1rem;
`;

export const MilestoneSummaryWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-basis: 40%;
`;

export const MilestoneSummaryDetails = styled.div`
  color: black;
  padding: 1rem;
  font-family: ${FONT_FAMILY_REGULAR};
  flex-shrink: 40;
`;

export const TimelineToolTip = styled.div`
  background-color: white;
  color: black;
  padding: 1rem;
  font-family: ${FONT_FAMILY_REGULAR};
  max-width: 380px;
`;

export const TimelineToolTipTitle = styled.div<{sizeRem?: number}>`
  font-size: ${(props) =>
    props.sizeRem ? props.sizeRem * 0.5 + "rem" : "18px"};
`;

export const TimelineToolTipFooter = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: #368836;
`;

export const TimelineToolTipContent = styled.div`
  margin-top: 8px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  white-space: pre-wrap;
`;

export const TimelineRowInfoContainer = styled.div`
  padding: 6px 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;
export const InfoTitle = styled.div`
  font-size: 16px;
  font-family: ${FONT_FAMILY_BOLD};
`;
export const InfoSubtitle = styled.div`
  font-size: 12px;
`;
export const RowCell = styled.div<{displayQuarters: boolean}>`
  flex-grow: 2;
  flex-shrink: 2;

  &:last-child {
    flex-grow: ${(props) => (props.displayQuarters ? 0.3 : 2)};
    flex-shrink: ${(props) => (props.displayQuarters ? 0.5 : 3)};
  }
`;
export const MainRowCell = styled.div<{
  primaryCell?: boolean;
  secondaryCell?: boolean;
}>`
  flex-grow: 2;
  flex-shrink: 2;
  border-left: ${(props) =>
    props.secondaryCell
      ? "0.15px solid rgba(0, 0, 0, 0.05)"
      : props.primaryCell
      ? "1.25px solid rgba(0, 0, 0, 0.15)"
      : "1px solid rgba(0, 0, 0, 0.1)"};

  //&:first-child {
  //  border-left: none;
  //}
`;

export const MobileTimelineContainer = styled.div<{
  height: string;
}>`
  padding-bottom: 40px;
  position: relative;
  height: ${(props) => props.height};
`;

export const MobileTimelineLeftColumn = styled.div`
  padding-top: 10px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
`;

export const MobileTimelineRightColumn = styled.div`
  flex-grow: 2;
  overflow-x: auto;
`;

export const MobileTimelineTable = styled.div`
  min-width: 100%;
  flex-grow: 2;
  flex-direction: row;
`;

export const MobileTimelineRowTop = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
`;

export const MobileTimelineRow = styled.td`
  margin-top: 0;
  width: 100%;
  height: ${MOBILE_TIMELINE_ROW_HEIGHT + "px"};
  display: flex;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

export const MobileTimelineRowInfoContainer = styled.div`
  padding: 6px 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const MobileInfoSubtitle = styled.div`
  font-size: 12px;
`;

export const MobileRowCell = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
`;

export const MobileMainRowCell = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  &:first-child {
    border-left: none;
  }
`;

export const MobileMonthLeftColumn = styled.div`
  width: 30px;
  height: 100%;
  flex-shrink: 0;
`;

export const MobileMonthNameRow = styled.td`
  height: 15px;
  width: 40px;
`;

export const MobileStageTitleDiv = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  padding-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 117%;
  width: 80%;
`;

export const MobileStageMonthCountDiv = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  padding-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
`;

export const MobileStageMonthTooltipDiv = styled.div`
  height: 20px;
  width: 20px;
  top: 25px;
  right: 10px;
  position: absolute;
`;

export const MobileStagePhaseDescriptionDiv = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  word-break: break-all;
  padding-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-wrap: normal;
`;

const MilestoneSummaryStyle = css`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  color: black;
  background-color: ${VILLA_APPLE_GREEN};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const MilestoneSummaryIcon = styled.div`
  ${MilestoneSummaryStyle};
`;

export const Milestone = styled.div<{
  offset: number;
  sizeRem: number; // 2rem
  topOffset?: number;
}>`
  ${MilestoneSummaryStyle};
  position: absolute;
  left: ${(props) => props.offset + "%"};
  top: ${(props) => (props.topOffset ? props.topOffset + "%" : "50%")};
  transform: translate(-50%, -50%);
  width: ${(props) => props.sizeRem + "rem"};
  height: ${(props) => props.sizeRem + "rem"};
  border-radius: ${(props) => props.sizeRem / 2 + "rem"};
  color: black;
  background-color: ${VILLA_APPLE_GREEN};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border: solid white 3px;
  font-size: ${(props) =>
    props.sizeRem ? props.sizeRem * 0.5 + "rem" : "18px"};
`;

export const RowBlock = styled.div<{
  width: number;
  bgColor: string;
  start: number;
}>`
  width: ${(props) => props.width + "%"};
  background-color: ${(props) => props.bgColor};
  position: absolute;
  left: ${(props) => props.start + "%"};
  top: 20%;
  bottom: 20%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 12px;
  font-family: ${FONT_FAMILY_BOLD};
  padding-left: 1.5rem;
`;

export const MobileRowBlock = styled.div<{
  monthHeight: number;
  bgColor: string;
  start: number;
  shouldDisplayPostDepositPortalV2: boolean;
}>`
  width: calc(100% - 100px);
  justify-content: flex-start;
  border-left: 10px solid ${(props) => props.bgColor};
  height: ${(props) => props.monthHeight + "%"};
  margin-left: 60px;
  background-color: ${(props) =>
    props.bgColor + "40"}; // Addition to hex represents opacity
  opacity: 1;
  top: ${(props) => props.start + "%"};
  position: absolute;
  bottom: 20%;
  border-radius: 5px;
  display: flex;
  align-content: center;
  flex-direction: column;
  font-size: 12px;
  font-family: ${FONT_FAMILY_BOLD};
  padding-left: 10px;
  overflow: hidden;
`;

export const MobilePhaseTitle = styled.div`
  font-size: 14px;
  margin-left: -22px;
  margin-top: 15px;
  font-family: ${FONT_FAMILY_REGULAR};
  width: max-content;
  text-align: center;
  border-radius: 4px;
  padding: 0 8px;
  transform: rotate(90deg);
`;

export const MobilePhaseBox = styled.div<{
  show: boolean;
  startLineColor: string;
  endLineColor: string;
  monthHeight: number;
  start: number;
  shouldDisplayPostDepositPortalV2: boolean;
}>`
  top: ${(props) => props.start + "%"};
  position: absolute;
  height: ${(props) => props.monthHeight + "%"};
  right: 32px;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  background-image: linear-gradient(
    to bottom,
    ${(props) => props.startLineColor},
    ${(props) => props.endLineColor}
  );
  width: 4px;
  opacity: 1;
  pointer-events: none;
  z-index: 100;
`;

export const MobileStepContainer = styled.div`
  width: 100%;
`;

export const MobileStepSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`;

export const MobileParentBoxContainer = styled.div`
  padding-top: 20px;
  margin-bottom: 0;
  overflow-x: auto;
  width: 100%;
`;

export const MobileTitle = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 6px;
  white-space: nowrap;
  overflow: hidden;
`;
