import React from "react";
import {
  CartItemProductPreviewContainer,
  CartItemProductPreviewDescription,
} from "../CartItemProductPreview/styles";
import {priceTextFromMicros} from "../../../../../../studio/_shared/helper";

interface CartItemPreviewPricingProps {
  unitPrice: number;
  upgradePrice: number;
  totalPrice: number;
}

const CartItemPreviewPricing = (props: CartItemPreviewPricingProps) => {
  const {unitPrice, upgradePrice, totalPrice} = props;

  return (
    <CartItemProductPreviewContainer>
      <CartItemProductPreviewDescription>
        Unit: {priceTextFromMicros(unitPrice, "accurate")}
      </CartItemProductPreviewDescription>
      <CartItemProductPreviewDescription>
        Upgrades: {priceTextFromMicros(upgradePrice, "accurate")}
      </CartItemProductPreviewDescription>{" "}
      <CartItemProductPreviewDescription>
        Total: {priceTextFromMicros(totalPrice, "accurate")}
      </CartItemProductPreviewDescription>
    </CartItemProductPreviewContainer>
  );
};

export default CartItemPreviewPricing;
