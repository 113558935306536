import {executeRequest} from "../APIExecutor";
import {
  BudgetEntryProps,
  ProjectBudget,
  ProjectPriceMode,
} from "@natomas-org/core";

const saveBudgetChangesPath = "/project/v1/budget";
// const saveUnitBudgetChangesPath = "/project/v1/budget/unit";

export const saveBudgetChanges = async (
  project_id: string,
  edits: {[key: string]: BudgetEntryProps},
  baseBudget: ProjectBudget<ProjectPriceMode>,
  onCompleted?: () => void,
  clearBudget?: () => void
) => {
  if (!baseBudget?.entries) {
    return;
  }
  const currentBudgetEntries = baseBudget.entries;
  let updatedBudgetEntries: {[key: string]: BudgetEntryProps} = {};
  currentBudgetEntries.forEach((entry) => {
    if (entry.id) {
      updatedBudgetEntries[entry.id] = entry.getData();
    }
  });
  updatedBudgetEntries = Object.assign(updatedBudgetEntries, edits);

  if (clearBudget) {
    clearBudget();
  }
  const requestPayload: {
    project_id: string;
    edits: {[key: string]: BudgetEntryProps};
  } = {
    project_id,
    edits: updatedBudgetEntries,
  };
  const data = await executeRequest(saveBudgetChangesPath, requestPayload);
  if (onCompleted) {
    onCompleted();
  }
};
