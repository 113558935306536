import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTags} from "../../../../hooks/useTags/useTags";
import {TagType} from "../../../../hooks/useTags/tags";
import {getFormInitialValues, getFormStructure} from "../../../../application";
import {areEqual, getInitialValues, ImageSearchStructure} from "./helper";
import {useFormik} from "formik";
import {SelectionOptionsInputWithArrays} from "../../../../_legacy/SelectionOptionsInput";
import {ImageGallery} from "../_shared/ImageGallery";
import {queryImagesByTag} from "../../../../../../database/firebase/images";
import {convertCollectionSnapshotToMap} from "../../../../../../database/firebase/utilities";
import {ImageFinderSectionProps} from "../index";

const IMAGE_QUERY_LIMIT = 6;

export const SearchByTagSection = (props: ImageFinderSectionProps) => {
  const {selectImage, showEditDetails} = props;

  const [searchTags, setSearchTags] = useState<string[]>([]);
  const [imageIds, setImageIds] = useState<string[]>([]);
  const [isEnd, setIsEnd] = useState<boolean>(true);
  const [latestImageDoc, setLatestImageDoc] = useState<any>(undefined);

  const {getTagTitleById, getTagIdByTitle, tagIds} = useTags(
    TagType.PRODUCT_CATALOG
  );

  const availableTags = useMemo(() => {
    return tagIds?.map((tagId: string) => getTagTitleById(tagId) ?? tagId);
  }, [tagIds]);

  const imageSearchStructure = getFormStructure(ImageSearchStructure);

  const [initialValues] = useState(
    getInitialValues(getFormInitialValues(ImageSearchStructure))
  );

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (!areEqual(searchTags, formik?.values?.["tags"])) {
      setSearchTags(formik?.values?.["tags"]);
      setLatestImageDoc(undefined);
    }
  }, [formik?.values?.["tags"]]);

  const getAllImagesMatchingTags = () => {
    if (searchTags && searchTags.length > 0) {
      queryImagesByTag(IMAGE_QUERY_LIMIT, searchTags, latestImageDoc)?.then(
        (querySnapshot) => {
          if (querySnapshot?.empty) {
            setIsEnd(true);
          } else if (querySnapshot?.size < IMAGE_QUERY_LIMIT) {
            setIsEnd(true);
          } else {
            setIsEnd(false);
            setLatestImageDoc(querySnapshot.docs[querySnapshot.size - 1]);
          }
          const images = convertCollectionSnapshotToMap(querySnapshot);
          const newImageIds = images?.map((image: any) => image?.id);
          // Add all image ids that exist that are not present in imageIds
          if (!!latestImageDoc) {
            setImageIds(
              imageIds.concat(
                newImageIds.filter(
                  (imageId: string) => !imageIds.includes(imageId) && !!imageId
                )
              )
            );
          } else {
            setImageIds(newImageIds);
          }
        }
      );
    } else {
      setIsEnd(true);
      setImageIds([]);
      setLatestImageDoc(undefined);
    }
  };

  // Memoize the query function
  const queryImages = useCallback(getAllImagesMatchingTags, [
    searchTags,
    latestImageDoc,
    imageIds,
  ]);

  // Re-query items if callback changes
  useEffect(() => {
    queryImages();
  }, [searchTags]);

  const TagDropdownSelector = () => {
    const keyInfo = imageSearchStructure?.find((v: any) => v.key === "tags");
    const {key} = keyInfo;
    // @ts-ignore
    let value = formik?.values?.[key];

    if (!!value) {
      value = value?.map((id: string) => getTagTitleById(id)) ?? [];
    }
    return (
      <div>
        <SelectionOptionsInputWithArrays
          key={"image-search-tag-array"}
          options={availableTags}
          handleChange={(value: any) => {
            formik.setFieldValue(
              keyInfo.key,
              value?.map((v: string) => getTagIdByTitle(v))
            );
          }}
          value={value}
          disabled={false}
          isSingleSelect={false}
          limitHeight={true}
        />
        <ImageGallery
          showEditDetails={showEditDetails}
          imageIds={imageIds}
          selectImage={selectImage}
          queryImages={queryImages}
          isEnd={isEnd}
        />
      </div>
    );
  };

  return (
    <>
      <TagDropdownSelector />
    </>
  );
};
