import {IProduct, SelectedModifiers} from "@natomas-org/core";
import {
  InfoSetItem,
  InfoSetItemOption,
  InfoSetItemOptionCondition,
  InfoSetItemOptionConditionType,
} from "../../../../../../../factory-info-set/shared/interfaces";
import {
  CONDITIONS_KEY,
  ID_KEY,
  MULTI_OPTION_KEY,
  OPTIONS_KEY,
  VALUE_KEY,
} from "../../../../../../../factory-info-set/shared/constants";
import {
  IOrderFormTableCellValue,
  OptionEvaluationResult,
  OptionInfo,
  OrderFormTableCellType,
} from "./interfaces";

export const getDataValue = (
  item: InfoSetItem,
  product: IProduct,
  selections?: SelectedModifiers,
  /* savedOrderFormSet will be the most recently saved
   * order form set (if available) for a particular project,
   * Will have dominance until editing
   * */
  orderFormSelections?: any
): IOrderFormTableCellValue => {
  const cell: IOrderFormTableCellValue = {
    locked: false,
    options: undefined,
    type: OrderFormTableCellType.UNKNOWN,
    value: "",
    influencedByModifiers: undefined,
    satisfiedByManualSelection: undefined,
    satisfiedModifiers: undefined,
    satisfiedByProduct: false,
  };

  const id = item[ID_KEY];
  const savedOrderFormSelection = orderFormSelections?.[id];
  if (savedOrderFormSelection) {
    cell.type = OrderFormTableCellType.INTERNAL;
    cell.satisfiedByManualSelection = {
      timestamp: savedOrderFormSelection.timestamp,
      authorId: savedOrderFormSelection.authorId,
    };
    cell.value = savedOrderFormSelection.value;
    cell.locked = true;
  }

  const options = item[OPTIONS_KEY] ?? [];
  const optionCount = options.length;
  if (!optionCount) {
    cell.value = "Loading...";
    return cell;
  } else if (optionCount === 1) {
    const option = options[0];
    if (!isEmptyConditionOption(option)) {
      const info: OptionInfo = evaluateOption(
        option,
        product,
        selections ?? {}
      );
      if (!info?.evaluation?.satisfied) {
        cell.locked = true;
        return cell;
      }
    }
    const v = option[VALUE_KEY];
    if (v?.length > 0) {
      // If the value is declared, and it is the only option, we always assign it.
      // This is the idea behind it being a 'Constant'
      cell.value = v;
      cell.locked = true;
      cell.type = OrderFormTableCellType.DERIVED;
    } else {
      if (!cell.value) {
        cell.locked = false;
      }
      cell.type = OrderFormTableCellType.CUSTOM;
    }
  } else if (optionCount > 1) {
    const sortedOptions = [...options].sort(
      (o1: InfoSetItemOption, o2: InfoSetItemOption) =>
        (o1?.[CONDITIONS_KEY]?.length ?? 0) -
        (o2?.[CONDITIONS_KEY]?.length ?? 0)
    );
    const optionInfos: OptionInfo[] = evaluateOptions(
      sortedOptions,
      product,
      selections ?? {}
    );

    const satisfiedOptionInfos: OptionInfo[] = getSatisfiedOptions(optionInfos);
    cell.satisfiedModifiers = getAllUsedModifiers(satisfiedOptionInfos);
    cell.satisfiedByProduct =
      isSatisfiedByProductSelection(satisfiedOptionInfos);

    const isMultiOptional = item[MULTI_OPTION_KEY];
    const isIntentionalInternalSelection =
      hasManyDefaults(satisfiedOptionInfos);
    const isUnintentionalInternalSelection =
      !isMultiOptional && hasManySatisfiedOptions(satisfiedOptionInfos);
    const isMissingSelection =
      !isMultiOptional && hasOnlyUnsatisfiedOptions(optionInfos);

    if (isMissingSelection) {
      cell.type = OrderFormTableCellType.EMPTY;
      cell.locked = true;
    } else if (isIntentionalInternalSelection) {
      cell.type = OrderFormTableCellType.INTERNAL;
      cell.options = satisfiedOptionInfos.map(
        (info: OptionInfo) => info.option[VALUE_KEY]
      );
    } else if (isUnintentionalInternalSelection) {
      cell.type = OrderFormTableCellType.UNDETERMINED;
      cell.options = satisfiedOptionInfos.map(
        (info: OptionInfo) => info.option[VALUE_KEY]
      );
    } else if (isMultiOptional) {
      if (satisfiedOptionInfos.length > 0) {
        cell.locked = true;
        cell.type = OrderFormTableCellType.DERIVED;
        cell.value = satisfiedOptionInfos
          .map((info: OptionInfo) => info.option[VALUE_KEY])
          .join(", ");
      }
    } else {
      if (satisfiedOptionInfos.length > 0) {
        cell.locked = true;
        cell.type = OrderFormTableCellType.DERIVED;
        cell.value =
          satisfiedOptionInfos[satisfiedOptionInfos.length - 1].option[
            VALUE_KEY
          ];
      }
    }
  }

  return cell;
};

export const evaluateInfoSetItemOption = (
  option: InfoSetItemOption,
  product: IProduct,
  selections: SelectedModifiers
): OptionEvaluationResult => {
  if (isEmptyConditionOption(option)) {
    return {
      usedModifiers: [],
      satisfiedByProduct: false,
      satisfied: true,
    };
  } else {
    const conditions = option[CONDITIONS_KEY];
    return evaluateInfoSetItemOptionConditions(conditions, product, selections);
  }
};

export const isEmptyConditionOption = (option: InfoSetItemOption) => {
  return option[CONDITIONS_KEY]?.length === 0;
};

export const evaluateInfoSetItemOptionConditions = (
  conditions: InfoSetItemOptionCondition[],
  product: IProduct,
  selections: SelectedModifiers
): OptionEvaluationResult => {
  const approvalArray: OptionEvaluationResult[] = conditions?.map(
    (condition: InfoSetItemOptionCondition) => {
      return evaluateInfoSetItemOptionCondition(condition, product, selections);
    }
  );
  const satisfiedCondition = approvalArray.find(
    (cer: OptionEvaluationResult) => cer.satisfied
  );
  return (
    satisfiedCondition ?? {
      usedModifiers: [],
      satisfiedByProduct: false,
      satisfied: false,
    }
  );
};

export const evaluateInfoSetItemOptionCondition = (
  condition: InfoSetItemOptionCondition,
  product: IProduct,
  selections: SelectedModifiers
): OptionEvaluationResult => {
  const targetAnalysis = getTargetAnalysis(condition?.targets ?? [], product);
  const selectionAnalysis = getSelectionAnalysis(condition, selections);

  const isValid = targetAnalysis.isSatisfied && selectionAnalysis.isSatisfied;

  return {
    usedModifiers: selectionAnalysis.usedModifiers,
    satisfiedByProduct: targetAnalysis.isSatisfiedByProduct,
    satisfied: isValid,
  };
};

export const getTargetAnalysis = (
  targets: string[],
  product: IProduct
): {isSatisfied: boolean; isSatisfiedByProduct: boolean} => {
  let isSatisfied = false;
  let isSatisfiedByProduct = false;
  if (targets.length > 0) {
    // If targets is populated, it must include product
    if (product?.id) {
      isSatisfied = targets.includes(product.id);
      isSatisfiedByProduct = isSatisfied;
    }
  } else {
    // If targets is empty, it is always valid
    isSatisfied = true;
    isSatisfiedByProduct = false;
  }
  return {
    isSatisfied: isSatisfied,
    isSatisfiedByProduct: isSatisfiedByProduct,
  };
};

export const getSelectionAnalysis = (
  condition: InfoSetItemOptionCondition,
  selections: SelectedModifiers
): {isSatisfied: boolean; usedModifiers: string[]} => {
  // Required modifiers
  const requirements = Object.keys(condition?.requiredModifiers ?? {});
  // Empty case
  if (requirements.length === 0) {
    return {
      usedModifiers: [],
      isSatisfied: true,
    };
  }
  // Get valid selections
  const requirementApprovals = requirements?.filter(
    (requirementId: string) => selections[requirementId]
  );
  // Derive result of condition
  let conditionResult = false;
  if (condition.type === InfoSetItemOptionConditionType.ANY) {
    conditionResult = requirementApprovals?.length > 0;
  } else if (condition.type === InfoSetItemOptionConditionType.ALL) {
    conditionResult = requirementApprovals?.length === requirements?.length;
  }
  return {
    usedModifiers: requirementApprovals,
    isSatisfied: conditionResult,
  };
};

const isConfiguredAsDefault = (info: OptionInfo): boolean => {
  const evalResult = info.evaluation;
  return (
    evalResult.satisfied &&
    !evalResult.satisfiedByProduct &&
    evalResult.usedModifiers.length === 0
  );
};

const isConfiguredAsConditionalAndSatisfied = (info: OptionInfo): boolean => {
  const evalResult = info.evaluation;
  return (
    evalResult.satisfied &&
    (evalResult.satisfiedByProduct || evalResult.usedModifiers.length > 0)
  );
};

const isConfiguredAsConditionalAndUnsatisfied = (info: OptionInfo): boolean => {
  const evalResult = info.evaluation;
  return !evalResult.satisfied && info?.option?.[CONDITIONS_KEY]?.length > 0;
};

const hasManyDefaults = (options: OptionInfo[]): boolean => {
  return (
    options?.filter((info: OptionInfo) => {
      return isConfiguredAsDefault(info);
    }).length > 1
  );
};

const hasManySatisfiedOptions = (options: OptionInfo[]): boolean => {
  return (
    options?.filter((info: OptionInfo) => {
      return isConfiguredAsConditionalAndSatisfied(info);
    }).length > 1
  );
};

const hasOnlyUnsatisfiedOptions = (options: OptionInfo[]): boolean => {
  return options?.every((info: OptionInfo) => {
    return isConfiguredAsConditionalAndUnsatisfied(info);
  });
};

const isSatisfiedByProductSelection = (options: OptionInfo[]): boolean => {
  return options?.some(
    (info: OptionInfo) => info.evaluation.satisfiedByProduct
  );
};

const getSatisfiedOptions = (optionInfos: OptionInfo[]): OptionInfo[] => {
  return optionInfos.filter((optionInfo: OptionInfo) => {
    return optionInfo.evaluation.satisfied;
  });
};

const getAllUsedModifiers = (infos: OptionInfo[]): string[] => {
  return [
    ...new Set([
      ...infos.map((info: OptionInfo) => info.evaluation.usedModifiers).flat(),
    ]),
  ];
};

const evaluateOptions = (
  options: InfoSetItemOption[],
  product: IProduct,
  selections: SelectedModifiers
): OptionInfo[] => {
  return options.map((op: InfoSetItemOption) => {
    return evaluateOption(op, product, selections);
  });
};

const evaluateOption = (
  option: InfoSetItemOption,
  product: IProduct,
  selections: SelectedModifiers
): OptionInfo => {
  const evalResult = evaluateInfoSetItemOption(
    option,
    product,
    selections ?? {}
  );
  return {
    evaluation: evalResult,
    option: option,
  };
};
