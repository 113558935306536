import React from "react";
import {priceTextFromMicros} from "../../../helper";
import {StudioItemLine} from "../../StudioItemLine";
import {getMicrosForPriceMode, ProjectPriceMode} from "@natomas-org/core";

export const StudioModifierGroupSummary = (props: {
  priceMode: ProjectPriceMode;
  id: string;
  selectedModifiers: any[];
}) => {
  const {id, selectedModifiers, priceMode} = props;

  return (
    <div key={id}>
      {selectedModifiers.map((modifier: any, index: number) => {
        return (
          <StudioItemLine
            key={"studio-item-line-" + modifier?.id + "-" + index}
            id={modifier?.id}
            leftText={modifier?.title}
            rightText={priceTextFromMicros(
              getMicrosForPriceMode(modifier, priceMode),
              "min-sign"
            )}
          />
        );
      })}
    </div>
  );
};
