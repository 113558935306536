import useLatestEstimate from "../useLatestEstimate";
import {AdminEstimateTables} from "../EstimateEditor/tables";
import {EstimateTableConfiguration} from "../_shared/table";
import React, {useMemo, useState} from "react";
import {GenericLoadingComponent} from "../../portal/views/ProposalBudgetView/components/generic/GenericLoadingComponent";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import EstimateTableTabs from "../_shared/EstimateTableTabs";
import EstimateTableDescription from "../CustomerEstimate/EstimateTableDescription";
import styled from "styled-components";
import StaticTable, {GenericTableCellProps} from "../StaticTable";
import {convertLatestEstimateResponseToTableRows} from "../_shared/rangeToRows";
import NatSwitch, {NatSwitchOption} from "../../_shared/generics/switch";

interface IAdminEstimateTableCell {
  title: string;
  priceLow: string;
  priceHigh: string;
  note: string;
  costLow: string;
  costHigh: string;
  gpLow: string;
  gpHigh: string;
  gmLow: string;
  gmHigh: string;
}

const DesktopTableColumns = {
  title: "Title",
  priceLow: "Price (Low)",
  priceHigh: "Price (High)",
  note: "Notes & Comments",
  costLow: "COGS (Low)",
  costHigh: "COGS (High)",
  gpLow: "GP (Low)",
  gpHigh: "GP (High)",
  gmLow: "GM % (Low)",
  gmHigh: "GM % (High)",
};

const AdminEstimateTableModes: NatSwitchOption[] = [
  {
    id: "table",
    label: "Table",
  },
  {
    id: "breakdown",
    label: "Subtotal Only",
  },
];

const AdminEstimateSelectionsTableModes: NatSwitchOption[] = [
  {
    id: "include-selections",
    label: "Show Upgrades",
  },
  {
    id: "summation-only",
    label: "Collapse Upgrades",
  },
];

const AdminEstimateTable = () => {
  const {response, status} = useLatestEstimate();
  const columns = DesktopTableColumns;
  const [tableConfiguration, setTableConfiguration] =
    useState<EstimateTableConfiguration>(AdminEstimateTables.ALL);

  const [tableMode, setTableMode] = useState<NatSwitchOption>(
    AdminEstimateTableModes[0]
  );
  const [showSelections, setShowSelections] = useState<NatSwitchOption>(
    AdminEstimateSelectionsTableModes[0]
  );

  const rows = useMemo(() => {
    return convertLatestEstimateResponseToTableRows(
      response,
      tableConfiguration.key,
      {
        label: tableConfiguration.label,
        hideSelections: showSelections.id === "summation-only",
      }
    );
  }, [
    response,
    showSelections.id,
    tableConfiguration.key,
    tableConfiguration.label,
  ]);

  if (status.fetching) {
    return (
      <>
        {/*{JSON.stringify(status, null, 2)}*/}
        <GenericLoadingComponent />
      </>
    );
  }

  if (!response) {
    return <>No estimate available at this time.</>;
  }

  const hasSelectionOption =
    tableMode.id === "table" &&
    (tableConfiguration.key === "all" ||
      tableConfiguration.key === "Home Design");

  return (
    <ErrorBoundary>
      <EstimateTableContainer>
        {/*<EstimateTableHeader />*/}
        {/*<EstimateTableTotalRange summary={response?.summary} />*/}
        <NatSwitch
          options={AdminEstimateTableModes}
          activeOption={tableMode}
          onOptionChange={() => {
            const isTableMode = tableMode.id === "table";
            setTableMode(
              isTableMode
                ? AdminEstimateTableModes[1]
                : AdminEstimateTableModes[0]
            );
          }}
        />
        {hasSelectionOption && (
          <NatSwitch
            options={AdminEstimateSelectionsTableModes}
            activeOption={showSelections}
            onOptionChange={() => {
              const hasSelections = showSelections.id === "include-selections";
              setShowSelections(
                hasSelections
                  ? AdminEstimateSelectionsTableModes[1]
                  : AdminEstimateSelectionsTableModes[0]
              );
            }}
          />
        )}
      </EstimateTableContainer>
      <EstimateTableContainer>
        <EstimateTableTabs
          tableConfigurationOptions={AdminEstimateTables}
          activeTableConfigurationKey={tableConfiguration.key}
          setTableConfiguration={setTableConfiguration}
        />
        <EstimateTableDescription
          description={tableConfiguration.description}
        />
        <StaticTable
          rows={tableMode.id === "table" ? rows : [rows[rows.length - 1]]}
          columnStyles={{
            title: {
              textColor: "#807D7A",
            },
          }}
          columns={columns}
          getDynamicCellStyle={getDynamicCellStyle}
        />
      </EstimateTableContainer>
    </ErrorBoundary>
  );
};

const getDynamicCellStyle = (
  columnKey: string,
  row: IAdminEstimateTableCell
): GenericTableCellProps => {
  // Custom dynamic cell styling for the priceHigh column
  if (columnKey === "priceHigh") {
    // If the priceLow and priceHigh are the same, set the text color to a muted color for priceHigh
    if (row?.priceLow === row?.priceHigh) {
      return {
        style: {
          textColor: "#807D7A",
        },
      };
    }
  }
  // Custom dynamic cell styling for the title column
  // if (columnKey === "title") {
  //   // If the priceLow and priceHigh are different, add alert
  //   if (row?.priceLow !== row?.priceHigh) {
  //     return {
  //       alert: {
  //         label: "Variable Price",
  //         textColor: WHITE,
  //         backgroundColor: VILLA_SLATE,
  //       },
  //     };
  //   }
  // }
  return {};
};

const EstimateTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 16px;
`;

export default AdminEstimateTable;
