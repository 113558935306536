import React from "react";
import {ButtonProps} from "./interface";
import {Button as StyledButton, Container, Error} from "./style";

const Button = (props: ButtonProps) => {
  const {label, error, theme, ...buttonProps} = props;
  return (
    <Container>
      <StyledButton {...buttonProps} theme={theme}>
        {label}
      </StyledButton>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

export default Button;
