import {InfoSetItem} from "../../../components/factory-info-set/shared/interfaces";
import {getFactoryLineInfoSetRef, getFactoryLineRef} from "../catalog/refs";
import {
  ID_KEY,
  LABEL_KEY,
} from "../../../components/factory-info-set/shared/constants";
import _ from "lodash";

export const getUpdatedFactoryLineItems = (
  current: InfoSetItem[],
  initials: InfoSetItem[]
): InfoSetItem[] => {
  return current?.filter((item: InfoSetItem) => {
    const initial = initials?.find((i: InfoSetItem) => i.id === item.id);
    return isFactoryLineItemValid(item) && !_.isEqual(item, initial);
  });
};

export const updateFactoryLineItems = (id: string, updated: InfoSetItem[]) => {
  const ref = getFactoryLineInfoSetRef(id);
  if (ref) {
    updated?.forEach((item: InfoSetItem) => {
      ref
        .doc(item[ID_KEY])
        .set(item, {merge: true})
        .then(() =>
          console.log(`FactoryLineInfoSet: Updated ${item.label} (${item.id})`)
        );
    });
  }
};

export const updateFactoryLineGlobalValues = (
  id: string,
  payload: {[fieldId: string]: any}
) => {
  const ref = getFactoryLineRef(id);
  if (ref) {
    return ref
      .set(payload, {merge: true})
      .then(() => console.log(`FactoryLine: Updated global value`));
  }
  return null;
};

const isFactoryLineItemValid = (item: InfoSetItem) => {
  return item[LABEL_KEY]?.length > 0;
};
