import {IModifierPricingReview} from "./interface";

export interface IModifierPricingReviewCSVRow {
  modifierId: string;
  modifierTitle: string;
  modifierInternalTitle: string;
  productId: string;
  productTitle: string;
  productInternalTitle: string;
  price: string;
  cost: string;
  condition: string;
  type: string;
  dependencyIds: string; // delimited by ' ~ '
  dependencyTitles: string; // delimited by ' ~ '
}

const convertToCSVRow = (
  review: IModifierPricingReview
): IModifierPricingReviewCSVRow => {
  return {
    modifierId: review?.modifier?.id ?? "NULL",
    modifierTitle: review?.modifier?.title ?? "NULL",
    modifierInternalTitle: review?.modifier?.internal_title ?? "NULL",
    productId: review?.product?.id ?? "NULL",
    productTitle: review?.product?.title ?? "NULL",
    productInternalTitle: review?.product?.internal_title ?? "NULL",
    price: review?.state?.price?.toString() ?? "NULL",
    cost: review?.state?.cost?.toString() ?? "NULL",
    condition: review?.state?.condition ?? "NULL",
    type: review?.state?.type ?? "NULL",
    dependencyIds:
      review?.state?.dependencies?.map((d) => d.id).join(" ~ ") ?? "NULL",
    dependencyTitles:
      review?.state?.dependencies?.map((d) => d.title).join(" ~ ") ?? "NULL",
  };
};

export const convertToCSV = (reviews: IModifierPricingReview[]): string[][] => {
  const rows = reviews.map(convertToCSVRow);
  return rows?.map((row) => {
    return Object.values(row)?.map((v) => {
      // Escape commas
      return v?.toString()?.replace(/,/g, " ");
    });
  });
};
