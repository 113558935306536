import React from "react";
import {SendWelcomeEmailAction} from "./SendWelcomeEmailAction";
import {UpdateLinksInSalesforceAction} from "./UpdateLinksInSalesforceAction";
import {PROJECT_SETTINGS_ADMIN_COMPONENTS} from "../project_model";
import {AdminInput} from "../../../../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {FormikProps} from "formik";
import {ActionButtonContainer} from "../../../../../_shared/styles";
import {ControlPanelSectionWrapper} from "../ControlPanelSectionWrapper";

export const AdminProjectActions = (props: {
  formik: FormikProps<any>;
  initialValues: any;
}) => {
  const {formik, initialValues} = props;
  if (!formik || !initialValues) {
    return null;
  }
  return (
    <ControlPanelSectionWrapper title={"Admin Project Actions"}>
      <ActionButtons {...props} />
      {Object.values(PROJECT_SETTINGS_ADMIN_COMPONENTS).map(
        (fieldInfoProps, idx) => {
          let value = formik.values[fieldInfoProps.key];
          let initial = initialValues[fieldInfoProps.key];
          return (
            <AdminInput
              onChange={(value) => {
                formik.setFieldValue(fieldInfoProps.key, value);
              }}
              initialValue={initial}
              value={value}
              fieldInfo={fieldInfoProps}
              key={`project-settings-${idx}`}
            />
          );
        }
      )}
    </ControlPanelSectionWrapper>
  );
};

const ActionButtons = (props: {
  formik: FormikProps<any>;
  initialValues: any;
}) => {
  return (
    <ActionButtonContainer style={{maxWidth: "100%"}} formatTitle>
      <SendWelcomeEmailAction />
      <UpdateLinksInSalesforceAction />
    </ActionButtonContainer>
  );
};
