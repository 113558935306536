export function isElementOnRightSide(el: HTMLElement | null) {
  if (!el) {
    return false;
  }
  const parent =
    el.parentElement?.parentElement?.parentElement?.parentElement?.getBoundingClientRect();
  if (!parent) {
    return false;
  }

  const bounding = el.getBoundingClientRect();
  return bounding.left - parent.left > parent.width / 2;
}
