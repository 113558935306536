import React, {useContext, useMemo, useState} from "react";
import {
  Customer,
  fullPriceTextFromMicros,
  ProjectPriceMode,
  STRIPE_PHASE_1_PAYMENT_SKU,
} from "@natomas-org/core";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
import {useGlobalConfiguration} from "../../../../../_shared/hooks/useGlobalConfiguration";
import {
  getPhase1PaymentAmount,
  isPhase1Complete,
} from "../../../../_shared/utilities";
import {
  canceledPaymentURL,
  StripeCheckoutButton,
  successfulPaymentURL,
} from "../../../../../_shared/generics/StripeCheckoutButton";
import {
  MobileNextStepsContainer,
  MobileNextStepsCTA,
  MobileNextStepsHelpTextContainer,
  MobileNextStepsSubContainer,
  MobileNextStepsTitle,
  NextStepsActionContainer,
  NextStepsContainer,
  NextStepsCTA,
  NextStepsHelpTextContainer,
  NextStepsInfo,
  NextStepsSubContainer,
  NextStepsTitle,
} from "./styles";
import {MdArrowForwardIos} from "react-icons/md";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {StyleOption} from "../../../../../_shared/generics/_shared";
// @ts-ignore
import {FONT_FAMILY_MEDIUM} from "../../../SSDDashboardView/styles/globals";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {PROPOSAL_GENERATE_PDF} from "../../HCPPresentationView/DTC/components/constants";

export const NextStepsComponent = (props: {
  step: number;
  style?: React.CSSProperties;
}) => {
  const {step, style} = props;
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const {phase1PriceSKU, phase1PriceSKUComplex} = useGlobalConfiguration();
  const {
    id: pid,
    mergedCustomerProjectInfos,
    projectPriceMode,
  } = useActiveProject();
  const [expanded, setExpanded] = useState(false);
  const phase1SKU = useMemo(() => {
    if (
      mergedCustomerProjectInfos?.settings?.pricing_mode
        ?.complex_pricing_enabled
    ) {
      return phase1PriceSKUComplex;
    }
    return phase1PriceSKU;
  }, [
    mergedCustomerProjectInfos?.settings?.pricing_mode?.complex_pricing_enabled,
    phase1PriceSKU,
    phase1PriceSKUComplex,
  ]);

  const {isNatMobile} = usePage();
  let title = "";
  let subtitle = "";
  let helpText = "";
  let expandedHeight = 0;
  const expandedHeightRefund = useDynamicValue({
    forFour: "100%",
    forEight: "9rem",
    forTwelve: "7.75rem",
    forSixteen: "7.75rem",
  });

  let action = null;
  const {customer} = useCurrentCustomer();
  if (
    customer == null ||
    (projectPriceMode === ProjectPriceMode.CostPlus && step > 1) //
  ) {
    return null;
  }

  switch (step) {
    case 1:
      return null;
    case 2:
      title = `Complete the Preconstruction Diligence Fee`;
      subtitle = "What is the refund policy?";

      helpText = "Please review your PSA for more information about this fee.";

      expandedHeight = expandedHeightRefund;
      if (isPhase1Complete(mergedCustomerProjectInfos ?? null)) {
        action = (
          <div
            style={{
              marginLeft: isNatMobile ? "0.5rem" : "",
            }}
          >
            Completed Payment ✅
          </div>
        );
      } else {
        action = (
          <div
            style={{
              textAlign: isNatMobile ? "left" : "center",
            }}
          >
            <StripeCheckoutButton
              buttonOptions={{
                label: "Proceed to payment",
                trackingId: "Next-Steps-Complete-Payment",
                fontFamily: FONT_FAMILY_MEDIUM,
                option: StyleOption.PRIMARY_LINK_ALT,
                style: {padding: 0},
              }}
              checkoutRequest={{
                line_item_ids: [phase1SKU],
                payment_product_type: STRIPE_PHASE_1_PAYMENT_SKU,
                client_reference_id: pid,
                email: Customer.getContactEmail(customer),
                success_url: successfulPaymentURL(),
                cancel_url: canceledPaymentURL(),
              }}
            />
          </div>
        );
      }
      break;
    case 3:
      return null;
    default:
      break;
  }
  if (isNatMobile) {
    return (
      <MobileNextStepsContainer
        expanded={expanded || isGeneratingPDF}
        expandedHeightRem={expandedHeight}
      >
        <MobileNextStepsTitle>{title}</MobileNextStepsTitle>
        <MobileNextStepsSubContainer>
          <MobileNextStepsCTA>{action}</MobileNextStepsCTA>
        </MobileNextStepsSubContainer>
        <NextStepsInfo
          expanded={expanded || isGeneratingPDF}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {subtitle} <MdArrowForwardIos size={14} />
        </NextStepsInfo>
        <MobileNextStepsHelpTextContainer expanded={expanded}>
          {helpText}
        </MobileNextStepsHelpTextContainer>
      </MobileNextStepsContainer>
    );
  }

  return (
    <NextStepsContainer
      expanded={expanded || isGeneratingPDF}
      expandedHeightRem={expandedHeight}
      style={style}
    >
      <NextStepsActionContainer>
        <NextStepsSubContainer>
          <NextStepsTitle>{title}</NextStepsTitle>
          <NextStepsCTA>{action}</NextStepsCTA>
        </NextStepsSubContainer>
        <NextStepsInfo
          expanded={expanded || isGeneratingPDF}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          {subtitle} <MdArrowForwardIos size={14} />
        </NextStepsInfo>
      </NextStepsActionContainer>
      <NextStepsHelpTextContainer>{helpText}</NextStepsHelpTextContainer>
    </NextStepsContainer>
  );
};
