import React from "react";
import {GeneralIntakeFormSubmitter} from "./GeneralIntakeFormSubmitter";
import {useCurrentCustomer} from "../../../../../../_shared/hooks/useCurrentCustomer";
import {Customer} from "@natomas-org/core";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";

export const GeneralIntakeFormTrigger = (props: IIntakeFormTriggerProps) => {
  const {customer} = useCurrentCustomer();

  // Hide if customer is a lead or if customer is not loaded
  if (!customer || (customer?.lead_id && customer?.lead_id.length > 0)) {
    return null;
  }

  const firstName = Customer.getFirstName(customer);
  const lastName = Customer.getLastName(customer);
  const email = Customer.getContactEmail(customer);
  const phoneNumber = Customer.getPrimaryContactPhoneNumber(customer);
  const address = Customer.getContactAddress(customer);

  return (
    <>
      <NatLabel
        ignoreMargin={true}
        label={
          "This user does not have a lead associated with it. Use the 'Trigger' button below to create a lead. It takes up to 15 minutes to process, so avoid spamming this button :)"
        }
        style={{color: "red"}}
        type={NatLabelType.P4}
      />
      <GeneralIntakeFormSubmitter
        intakeInfo={{
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
          address: address,
          utm: {
            page_source: "",
            utm_source: "",
            utm_adgroup: "",
            utm_campaign: "",
            utm_content: "",
            utm_medium: "",
            utm_placement: "",
            utm_term: "",
            key: "",
          },
        }}
        showSubmitButton={true}
      />
    </>
  );
};

interface IIntakeFormTriggerProps {}
