import {getEnumSelectOptions} from "../../../../shared/helper";
import React, {useEffect, useMemo, useRef} from "react";
import Select from "react-select";

export const EnumSelect = (props: {
  enumTarget: any;
  onChange: any;
  onCommitChange: any;
  value: any;
  widthRemOverride?: number;
}) => {
  const {enumTarget, onChange, onCommitChange, value, widthRemOverride} = props;
  const inputRef = useRef(null);

  const options = useMemo(() => {
    return getEnumSelectOptions(enumTarget);
  }, [enumTarget]);

  const valueOption = useMemo(() => {
    return options.find((o: {label: string}) => o.label === value) ?? null;
  }, [options, value]);

  useEffect(() => {
    handleSelect();
  }, [value]);

  const handleSelect = () => {
    // @ts-ignore
    inputRef.current.blur();
  };

  const localOnChange = (e: any) => {
    onChange(e.label);
  };
  return (
    <Select
      styles={{
        container: (styles) => ({
          ...styles,
          minWidth: `${widthRemOverride ?? 8}rem`,
        }),
      }}
      onChange={localOnChange}
      value={valueOption}
      ref={inputRef}
      onBlur={onCommitChange}
      options={options}
    />
  );
};
