import React, {useCallback, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {editModifierGroup} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {EditProductGroupModifierGroup} from "./EditProductGroupModifierGroup";
import {ColumnContainer, MainColumn} from "../../../_shared/styles/AdminStyles";
import {
  RowStatusType,
  TabColumn,
  TabColumnRowSection,
} from "../../components/TabColumn";
import {IStore} from "../../../../_shared/slices/types/Store";
import {getCategoriesWithModifierGroup} from "../../components/TabColumn/helper";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {getInternalTitle, getTitle} from "../../logic/helpers";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

export const ModifierGroupTab = (props: {widthRems: number}) => {
  const {categories, modifierGroups} = useSelectedFactoryLine();
  const {editingEnabled} = useAdminProductCatalog();
  const currentModifierGroup = useSelector(
    (state: IStore) => state.adminStudioManager.editingModifierGroup
  );
  const dispatch = useDispatch();
  const title = "Modifier Groups";

  const newModifierGroup = () => {
    dispatch(editModifierGroup({title: "New Modifier Group"}));
  };

  const changeModifierGroup = useCallback(
    (modifierGroup: any) => {
      dispatch(editModifierGroup(modifierGroup));
    },
    [dispatch]
  );

  const getStatuses = useCallback(
    (modifierGroup: any): RowStatusType => {
      const categoriesWithModifierGroup = getCategoriesWithModifierGroup(
        modifierGroup,
        Object.values(categories ?? {})
      );
      return {
        hasWarning: categoriesWithModifierGroup?.length === 0,
      };
    },
    [categories]
  );

  const modifierGroupArray = useMemo(
    () => Object.values(modifierGroups ?? {})?.filter((m) => !!m),
    [modifierGroups]
  );

  const getModifierGroupRow = useCallback(
    (modifierGroup: any) => {
      return {
        title: getTitle(modifierGroup),
        internal_title: getInternalTitle(modifierGroup),
        key: modifierGroup.id,
        data: modifierGroup,
        action: changeModifierGroup,
        active: currentModifierGroup?.id === modifierGroup.id,
        statuses: getStatuses(modifierGroup),
      };
    },
    [changeModifierGroup, currentModifierGroup?.id, getStatuses]
  );

  const RecentlyArchivedModifierGroupRowSection:
    | TabColumnRowSection
    | undefined = useMemo(() => {
    const modifierGroups = modifierGroupArray?.filter((modifierGroup) =>
      isArchived(modifierGroup)
    );
    if (modifierGroups?.length === 0) {
      return undefined;
    }
    return {
      title: editingEnabled ? "Recently Archived" : "Archived",
      rows: modifierGroups.map((modifierGroup: any) => {
        return getModifierGroupRow(modifierGroup);
      }),
    };
  }, [modifierGroupArray, editingEnabled, getModifierGroupRow]);

  const ModifierGroupRowSection: TabColumnRowSection = useMemo(() => {
    const modifierGroups = modifierGroupArray?.filter(
      (modifierGroup) => !isArchived(modifierGroup)
    );
    return {
      title: RecentlyArchivedModifierGroupRowSection ? "Active" : undefined,
      rows: modifierGroups.map((modifierGroup: any) => {
        return getModifierGroupRow(modifierGroup);
      }),
    };
  }, [
    modifierGroupArray,
    getModifierGroupRow,
    RecentlyArchivedModifierGroupRowSection,
  ]);

  const sections: TabColumnRowSection[] = useMemo(() => {
    const s = [ModifierGroupRowSection];
    if (RecentlyArchivedModifierGroupRowSection) {
      return [...s, RecentlyArchivedModifierGroupRowSection];
    }
    return s;
  }, [ModifierGroupRowSection, RecentlyArchivedModifierGroupRowSection]);

  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={props.widthRems + "rem"}
        headerAddAction={editingEnabled ? newModifierGroup : undefined}
        sections={sections}
      />
      <MainColumn>
        <EditProductGroupModifierGroup />
      </MainColumn>
    </ColumnContainer>
  );
};
