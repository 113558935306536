import React from "react";
import {InfoSetItemTableRow, rowLabelAlreadyExists} from "../../../table";
import {RowStringInput, sanitizeString} from "./RowStringInput";

export const RowLabel = (props: {
  onCommitChange: (arg: string) => void;
  onChange: any;
  otherRows: InfoSetItemTableRow[];
  value: string;
  originalValue: string;
}) => {
  const {onCommitChange, onChange, otherRows, value, originalValue} = props;

  const isValidChange = (value: string) => {
    const sanitizedString = sanitizeString(value);
    return (
      !!sanitizedString && !rowLabelAlreadyExists(otherRows, sanitizedString)
    );
  };

  return (
    <RowStringInput
      isValidChange={isValidChange}
      onChange={onChange}
      onCommitChange={onCommitChange}
      originalValue={originalValue}
      otherRows={otherRows}
      value={value}
    />
  );
};
