import React, {useEffect, useMemo} from "react";
import {
  NatomasModal,
  NatomasModalBackdrop,
  NatomasModalClose,
  NatomasModalContainer,
  NatomasModalContentContainer,
  NatomasModalHeader,
  NatomasModalWrapper,
} from "./styles";
import {NatomasButtonProps} from "../button";
import {usePage} from "../../hooks/usePage";
import {useDynamicValue} from "../../hooks/useDynamicValue";
import {NatBottomSheet} from "./NatBottomSheet";
import {FooterButtons} from "./components";

export enum NatModalSize {
  X_SMALL = 1,
  SMALL = 2,
  NORMAL = 3,
  LARGE = 4,
  FULLSCREEN = 5,
}

export interface NatModalCustomSize {
  width: string;
  height: string;
}

export interface NatModalProps {
  show?: boolean;
  content: JSX.Element;
  onlyShowContent?: boolean;
  backgroundColor?: string;
  modalBackgroundColor?: string;
  handleClose?: () => void;
  header?: string | JSX.Element;
  footer?: JSX.Element;
  footerButtons?: NatomasButtonProps[];
  hideCloseIcon?: boolean;
  size?: NatModalSize;
  centeredContent?: boolean;
  customSize?: NatModalCustomSize;
  customPadding?: string;
  removeFixedPlacementLogic?: boolean;
  enforceHeight?: boolean;
  enforceWidth?: boolean;
}

const getWidth = (
  isMobile: boolean,
  size?: NatModalSize,
  customSize?: NatModalCustomSize
) => {
  let forFour = "84vw";
  let forEight = "60vw";
  let forTwelve = "48vw";
  let forSixteen = "48vw";
  switch (size) {
    case undefined:
      forFour = "84vw";
      forEight = "60vw";
      forTwelve = "48vw";
      forSixteen = "48vw";
      break;
    case NatModalSize.X_SMALL:
      forFour = "48vw";
      forEight = "32vw";
      forTwelve = "24vw";
      forSixteen = "24vw";
      break;
    case NatModalSize.SMALL:
      forFour = "60vw";
      forEight = "42vw";
      forTwelve = "36vw";
      forSixteen = "36vw";
      break;
    case NatModalSize.NORMAL:
      forFour = "84vw";
      forEight = "60vw";
      forTwelve = "48vw";
      forSixteen = "48vw";
      break;
    case NatModalSize.LARGE:
      forFour = "96vw";
      forEight = "84vw";
      forTwelve = "72vw";
      forSixteen = "72vw";
      break;
    case NatModalSize.FULLSCREEN:
      forFour = "98vw";
      forEight = "98vw";
      forTwelve = "98vw";
      forSixteen = "98vw";
      break;
  }

  if (isMobile) {
    forFour = "98vw";
    forEight = "98vw";
    forTwelve = "98vw";
    forSixteen = "98vw";
  } else if (customSize) {
    forFour = "calc(" + customSize.width + " + 4rem)";
    forEight = "calc(" + customSize.width + " + 4rem)";
    forTwelve = "calc(" + customSize.width + " + 4rem)";
    forSixteen = "calc(" + customSize.width + " + 4rem)";
  }
  return [forFour, forEight, forTwelve, forSixteen];
};

const getHeight = (
  isMobile: boolean,
  size?: NatModalSize,
  customSize?: NatModalCustomSize
) => {
  if (isMobile) return "100vh";
  if (customSize) return "calc(" + customSize.height + " + 4rem)";
  switch (size) {
    case undefined:
      return "50vh";
    case NatModalSize.X_SMALL:
      return "48vh";
    case NatModalSize.SMALL:
      return "72vh";
    case NatModalSize.NORMAL:
      return "84vh";
    case NatModalSize.LARGE:
      return "96vh";
    case NatModalSize.FULLSCREEN:
      return "98vh";
  }
};

export const NatModal = (props: NatModalProps) => {
  const {
    show,
    handleClose,
    hideCloseIcon,
    content,
    backgroundColor,
    modalBackgroundColor,
    header,
    footer,
    footerButtons,
    size,
    centeredContent,
    customSize,
    customPadding,
    onlyShowContent,
    removeFixedPlacementLogic,
    enforceWidth,
    enforceHeight,
  } = props;
  const {isNatMobile} = usePage();
  const h = useMemo(() => {
    return getHeight(isNatMobile && !onlyShowContent, size, customSize);
  }, [isNatMobile, onlyShowContent, size, customSize]);
  const w = useMemo(() => {
    return getWidth(isNatMobile && !onlyShowContent, size, customSize);
  }, [isNatMobile, onlyShowContent, size, customSize]);

  const dynamicW = useDynamicValue({
    forFour: w[0],
    forEight: w[1],
    forTwelve: w[2],
    forSixteen: w[3],
  });
  const columned = useDynamicValue({
    forFour: true,
    forEight: true,
    forTwelve: false,
    forSixteen: false,
  });
  useEffect(() => {
    if (!show && !removeFixedPlacementLogic) {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.left = ``;
      document.body.style.right = ``;
      if (scrollY) {
        // TODO why do we need this?
        window.scrollTo(0, parseInt(scrollY || "0") * -1);
      }
    } else if (!removeFixedPlacementLogic) {
      document.body.style.top = "-" + window.scrollY + "px";
      document.body.style.position = "fixed";
      document.body.style.left = `0px`;
      document.body.style.right = `0px`;
    }
  }, [show]);
  const opacity = show ? 1 : 0;

  const close = () => {
    if (handleClose) {
      handleClose();
    }
  };

  if (isNatMobile) {
    return (
      <>
        <NatBottomSheet
          close={close}
          show={show}
          content={content}
          onlyShowContent={onlyShowContent}
          backgroundColor={backgroundColor}
          modalBackgroundColor={modalBackgroundColor}
          handleClose={handleClose}
          header={header}
          footer={footer}
          footerButtons={footerButtons}
          hideCloseIcon={hideCloseIcon}
          size={size}
          centeredContent={centeredContent}
          customSize={customSize}
          customPadding={customPadding}
        />
      </>
    );
  }
  if (onlyShowContent) {
    return (
      <>
        <NatomasModalWrapper hidden={!show}>
          <NatomasModalBackdrop
            backgroundColor={backgroundColor}
            opacity={opacity}
            onClick={close}
          />
          <NatomasModal
            backgroundColor={modalBackgroundColor}
            opacity={opacity}
            height={h}
            width={dynamicW}
            animationSpeed={250}
            show={!!show}
            enforceHeight={enforceHeight ?? false}
            enforceWidth={enforceWidth ?? true}
          >
            {handleClose && !hideCloseIcon && (
              <NatomasModalClose onClick={close} />
            )}
            {content}
          </NatomasModal>
        </NatomasModalWrapper>
      </>
    );
  }
  return (
    <>
      <NatomasModalWrapper hidden={!show}>
        <NatomasModalBackdrop
          backgroundColor={backgroundColor}
          opacity={opacity}
          onClick={close}
        />
        <NatomasModal
          backgroundColor={modalBackgroundColor}
          opacity={opacity}
          height={h}
          width={dynamicW}
          animationSpeed={250}
          show={!!show}
          enforceHeight={enforceHeight ?? false}
          enforceWidth={enforceWidth ?? true}
        >
          <NatomasModalClose
            hidden={!handleClose || hideCloseIcon || !!header}
            onClick={close}
          />
          {header && (
            <NatomasModalHeader
              hidden={!handleClose || hideCloseIcon}
              isMobile={true}
            >
              {header}
            </NatomasModalHeader>
          )}

          <NatomasModalContainer customPadding={customPadding}>
            <NatomasModalContentContainer centered={centeredContent ?? false}>
              {show && content}
            </NatomasModalContentContainer>
            {footer && <div>{footer}</div>}
            {footerButtons && (
              <FooterButtons
                columned={columned}
                footerButtons={footerButtons}
              />
            )}
          </NatomasModalContainer>
        </NatomasModal>
      </NatomasModalWrapper>
    </>
  );
};
