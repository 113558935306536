export interface IModelHomeInfo {
  location_title: string;
  product_title: string;
  schedule_link: string;
  image_url: string;
}

// 09/11/2023 Removed per request - NET-795
// const NORTH_BAY_MODEL_HOME: IModelHomeInfo = {
//   location_title: "Santa Rosa, North Bay",
//   product_title: "The H1200b",
//   schedule_link: "https://calendly.com/villa-model-homes/santa-rosa",
//   image_url:
//     "https://villahomes.com/wp-content/uploads/2022/09/Santa-Rosa-Exterior-scaled.jpg",
// };

const EAST_BAY_MODEL_HOME: IModelHomeInfo = {
  location_title: "Alamo, East Bay",
  product_title: "The H1200b",
  schedule_link:
    "https://calendly.com/villa-model-homes/alamo-model-home-tour-villa",
  image_url:
    "https://villahomes.com/wp-content/uploads/2023/12/Alamo-1-scaled-1-2048x1536-1-scaled.jpg",
};

const SAN_DIEGO_MODEL_HOME: IModelHomeInfo = {
  location_title: "San Diego",
  product_title: "The H1000b",
  schedule_link: "https://calendly.com/villa-model-homes/san-diego",
  image_url:
    "https://villahomes.com/wp-content/uploads/2023/09/Model-Home-Tours-San-Diego.jpg",
};

const SOUTH_BAY_MODEL_HOME: IModelHomeInfo = {
  location_title: "Saratoga, South Bay",
  product_title: "The H800",
  schedule_link:
    "https://calendly.com/villa-model-homes/saratoga-model-home-tour",
  image_url:
    "https://villahomes.com/wp-content/uploads/2023/07/Model-Home-Tours-Saratoga-South-Bay.jpg",
};

export const MODEL_HOMES: IModelHomeInfo[] = [
  EAST_BAY_MODEL_HOME,
  SOUTH_BAY_MODEL_HOME,
  SAN_DIEGO_MODEL_HOME,
];
