import React, {useEffect, useMemo} from "react";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import {AuthenticationFormState} from "./interface";
import {useFormState} from "./useFormState";
import ConfirmMarketingChannel from "./ConfirmMarketingChannel";
import ResetPasswordContent from "./ResetPasswordContent";
import LoginContent from "./LoginContent";
import CreateContactContent from "./CreateContactContent";
import ConfirmCustomerTypeContent from "./ConfirmCustomerTypeContent";
import ConfirmCustomerContent from "./ConfirmCustomerContent";
import {formatPhoneNumber, PhoneNumberFormatType} from "@natomas-org/core";
import ConfirmBusinessContent from "./ConfirmBusinessContent";
import LoggingOutContent from "./LoggingOut";
import {ContentContainer} from "./style";
import LoggedInAs from "./LoggedInAs";
import RegistrationPardotForm from "./RegistrationPardotForm";
import {ButtonProps} from "../../2-component/Button/interface";
import Header from "../../2-component/Header";
import Logo from "../../1-container/images/Logo";
import {logOut} from "../../../database/firebase";
import SignUpContent from "./SignUpContent";
import {useTracking} from "../../../components/_shared/hooks/useTracking";

export interface AuthenticationContentProps {
  redirect?: () => void;
}

const AuthenticationContent = (props: AuthenticationContentProps) => {
  const {
    setMode,
    pardotInformation,
    handlePardot,
    mode,
    schema,
    setSchema,
    authenticatedEmail,
    isFormLoading,
  } = useFormState(props);
  const {trackEvent} = useTracking();

  const backToCustomerType: ButtonProps = useMemo(() => {
    return {
      id: "back-to-customer-type",
      label: "Go back",
      theme: "secondary",
      type: "button",
      onClick: () => setMode(AuthenticationFormState.SELECT_CUSTOMER_TYPE),
    };
  }, [setMode]);

  const content = useMemo(() => {
    const loadingContent = <div>Loading...</div>;
    if (isFormLoading) return loadingContent;
    switch (mode) {
      case AuthenticationFormState.MARKETING_LANDING:
        if (!authenticatedEmail) {
          return loadingContent;
        }
        return (
          <ConfirmMarketingChannel
            email={authenticatedEmail}
            onSkip={handlePardot}
            onSubmit={(marketing_channel?: string) => {
              if (marketing_channel) {
                // Set schema and trigger after assignment
                trackEvent("Registration Marketing Channel", {
                  selection: marketing_channel,
                });
                setSchema((prev) => {
                  return {
                    ...prev,
                    marketing_channel,
                  } as CreateLeadSchema;
                });
              } else {
                trackEvent("Registration Marketing Channel", {
                  selection: "skip",
                });
                handlePardot();
              }
            }}
          />
        );
      case AuthenticationFormState.RESET_PASSWORD:
        return (
          <>
            <Header>{"Reset your password"}</Header>
            <ResetPasswordContent
              email={schema?.email}
              toLogin={() => setMode(AuthenticationFormState.LOGIN_LANDING)}
            />
          </>
        );
      case AuthenticationFormState.LOGIN_LANDING:
        return (
          <>
            <Header>{"Welcome back!"}</Header>
            <LoginContent
              setSchema={setSchema}
              toSignUp={() => setMode(AuthenticationFormState.SIGN_UP_LANDING)}
              toResetPassword={() =>
                setMode(AuthenticationFormState.RESET_PASSWORD)
              }
            />
          </>
        );
      case AuthenticationFormState.SIGN_UP_LANDING:
        return (
          <>
            <Header>{"Create an account"}</Header>
            <SignUpContent
              // On close, go back to the login landing
              close={() => setMode(AuthenticationFormState.LOGIN_LANDING)}
              onSignUp={(values?: CreateLeadSchema) => {
                // If no values are passed, the user is creating a new account
                if (!values) {
                  setMode(AuthenticationFormState.CREATE_CONTACT);
                } else {
                  trackEvent("Registration Sign Up", {
                    auth_user_id: values?.auth_user_id,
                  });
                  setSchema(values);
                }
              }}
            />
          </>
        );
      case AuthenticationFormState.CREATE_CONTACT:
        return (
          <>
            <Header>{"Create an account"}</Header>
            <CreateContactContent
              close={() => setMode(AuthenticationFormState.SIGN_UP_LANDING)}
              onSubmit={(values: CreateLeadSchema) => {
                // Assign the credential to the useFormState
                trackEvent("Registration Create Contact", {
                  auth_user_id: values?.auth_user_id,
                });
                setSchema(values);
                setMode(AuthenticationFormState.SELECT_CUSTOMER_TYPE);
              }}
            />
          </>
        );
      case AuthenticationFormState.SELECT_CUSTOMER_TYPE:
        return (
          <>
            <Header>{"What best describes you?"}</Header>
            <ConfirmCustomerTypeContent
              setCustomerType={(form) => {
                trackEvent("Registration Customer Type", {selection: form});
                setMode(
                  form === "business"
                    ? AuthenticationFormState.CONFIRM_BUSINESS
                    : AuthenticationFormState.CONFIRM_CUSTOMER
                );
              }}
            />
          </>
        );
      case AuthenticationFormState.CONFIRM_CUSTOMER:
        return (
          <>
            <Header>{"Complete your account"}</Header>
            <ConfirmCustomerContent
              schema={schema}
              buttons={[backToCustomerType]}
              onSubmit={(values: any) => {
                trackEvent("Registration Customer Complete", {
                  auth_user_id: values?.auth_user_id,
                });
                setSchema({
                  ...schema,
                  auth_user_id: schema?.auth_user_id,
                  created_from: schema?.created_from,
                  email: schema?.email,
                  phone_number:
                    formatPhoneNumber(
                      values?.["phone-number"],
                      PhoneNumberFormatType.RAW
                    ) ?? "",
                  // marketing_consent:
                  //   values?.["marketing-consent"] === undefined
                  //     ? true
                  //     : values?.["marketing-consent"],
                  first_name: values?.["first-name"],
                  last_name: values?.["last-name"],
                  address: values?.["project-address"],
                  lead_segment: "d2c",
                });
              }}
            />
          </>
        );
      case AuthenticationFormState.CONFIRM_BUSINESS:
        return (
          <>
            <Header>
              {"Provide a few details for your initial property evaluation"}
            </Header>
            <ConfirmBusinessContent
              schema={schema}
              buttons={[backToCustomerType]}
              onSubmit={(values: any) => {
                trackEvent("Registration Business Complete", {
                  auth_user_id: values?.auth_user_id,
                });
                setSchema({
                  ...schema,
                  auth_user_id: schema?.auth_user_id,
                  created_from: schema?.created_from,
                  email: schema?.email,
                  phone_number:
                    formatPhoneNumber(
                      values?.["phone-number"],
                      PhoneNumberFormatType.RAW
                    ) ?? "",
                  // marketing_consent:
                  //   values?.["marketing-consent"] === undefined
                  //     ? true
                  //     : values?.["marketing-consent"],
                  first_name: values?.["first-name"],
                  last_name: values?.["last-name"],
                  address: values?.["project-address"],
                  property_type: values?.["property-type"],
                  company: values?.["company-name"],
                  lead_segment: "b2b",
                });
              }}
            />
          </>
        );
      case AuthenticationFormState.LOGGING_OUT:
        setTimeout(() => {
          setMode(AuthenticationFormState.LOGIN_LANDING);
        }, 4000);
        return (
          <>
            <Header>{"One moment..."}</Header>
            <LoggingOutContent />
          </>
        );
      case AuthenticationFormState.COMPLETE:
        return loadingContent;
    }
  }, [
    isFormLoading,
    mode,
    authenticatedEmail,
    schema,
    setSchema,
    backToCustomerType,
    setMode,
  ]);

  useEffect(() => {
    // Scroll the container up to the top when the mode changes
    const element = document.getElementById("authentication-modal-content");
    const parent = element?.parentElement;
    if (element && parent) {
      element.scrollTo({
        top: 0,
        left: 0,
      });
      parent.scrollTo({
        top: 0,
        left: 0,
      });
    }
  }, [mode]);

  return (
    <ContentContainer id={"authentication-modal-content"}>
      <Logo />
      {content}
      <LoggedInAs
        email={authenticatedEmail}
        logOut={() => {
          logOut({preventPageClearOut: true});
          setSchema(undefined);
          setMode(AuthenticationFormState.LOGIN_LANDING);
        }}
      />
      <RegistrationPardotForm info={pardotInformation ?? null} />
    </ContentContainer>
  );
};

export default AuthenticationContent;
