import {
  IEstimateProjectRangeSummary,
  IEstimateRangeItem,
} from "../../../api/Estimate/EstimateService";
import {TableRowProps} from "../StaticTable";
import {fullPriceTextFromMicros} from "@natomas-org/core";
import {getADURows} from "./getADURows";

export const convertEstimateRangeItemToTableRow = (
  lineItem: IEstimateRangeItem
): TableRowProps<any> => {
  return {
    bucket: lineItem.bucket,
    title: lineItem.name,
    priceLow: fullPriceTextFromMicros(lineItem.price_low),
    priceHigh: fullPriceTextFromMicros(lineItem.price_high),
    costLow: fullPriceTextFromMicros(lineItem?.cost_low ?? 0),
    costHigh: fullPriceTextFromMicros(lineItem?.cost_high ?? 0),
    gpLow: fullPriceTextFromMicros(lineItem?.gross_profit_low ?? 0),
    gpHigh: fullPriceTextFromMicros(lineItem?.gross_profit_high ?? 0),
    gmLow: `${lineItem?.gross_margin_low ?? 0}%`,
    gmHigh: `${lineItem?.gross_margin_high ?? 0}%`,
    note: lineItem.notes ?? "",
  };
};

export const convertEstimateAduItemToTableRow = (
  adu: any,
  options?: {hideEmptyPrices: boolean; hideSelections: boolean}
): TableRowProps<any>[] => {
  return getADURows(adu, options);
};

export const convertEstimateSummaryToTableRow = (
  summary: IEstimateProjectRangeSummary,
  label: string
): TableRowProps<any> => {
  // @ts-ignore
  return {
    title: `${label} Subtotal`,
    priceLow: fullPriceTextFromMicros(summary?.total_price_low),
    priceHigh: fullPriceTextFromMicros(summary?.total_price_high),
    costLow: fullPriceTextFromMicros(summary?.total_cost_low ?? 0),
    costHigh: fullPriceTextFromMicros(summary?.total_cost_high ?? 0),
    gpLow: fullPriceTextFromMicros(summary?.total_gross_profit_low ?? 0),
    gpHigh: fullPriceTextFromMicros(summary?.total_gross_profit_high ?? 0),
    gmLow: `${summary?.total_gross_margin_low ?? 0}%`,
    gmHigh: `${summary?.total_gross_margin_high ?? 0}%`,
    _style: {
      fontWeight: "700",
      backgroundColor: "#EAF0E9",
    },
  };
};
