import React from "react";
import {
  MatrixHeaderCell,
  MatrixTableCell,
  MatrixTableCellIconContainer,
} from "../../../styles";
import {NatSettingsIcon} from "../../../../../../../../../_shared/icon/icons";
import {PriceSetTableCellProps} from "../../_shared/interfaces";
import {CONTAINER_BACKGROUND_COLOR} from "../../../../../../../../../_shared/colors";
import {BackLinkIcon} from "../../../../../../../components/BackLinkIcon";
import {viewModifier, viewProduct} from "../../../../../../../logic/helpers";
import {MatrixSubModeSelector} from "../../../../MatrixSubModeSelector";
import {MatrixTableController} from "../../../../MatrixTableController";
import {AdminProductCatalogMatrixSubModes} from "../../../../../../../../../_shared/slices/types/Store";
import {MatrixTitleModeSelector} from "../../../../MatrixTitleModeSelector";

export const PriceSetTableCell = (props: PriceSetTableCellProps) => {
  const {
    subMode,
    customProps,
    matrixEdits,
    setMatrixEdits,
    setData,
    pageProps,
    titleMode,
  } = props;
  const key = customProps.col + "_" + customProps.row;
  const target = customProps?.cell?.value;
  const getBackLinkAction = () => {
    if (pageProps?.transpose) {
      if (customProps.row === 0) {
        return () => viewModifier(target);
      } else {
        return () => viewProduct(target);
      }
    } else {
      if (customProps.row === 0) {
        return () => viewProduct(target);
      } else {
        return () => viewModifier(target);
      }
    }
  };
  const hasCurrentDependencies =
    customProps?.cell?.dependencies?.value?.filter(
      (d: any) => d.condition !== "default"
    )?.length > 0;
  const defaultPricing = customProps?.cell?.dependencies?.value?.find(
    (d: any) => d.condition === "default"
  );

  const isPositive =
    subMode === AdminProductCatalogMatrixSubModes.PRICE
      ? defaultPricing?.priceMicros > 0
      : defaultPricing?.costMicros > 0;

  const isNegative =
    subMode === AdminProductCatalogMatrixSubModes.PRICE
      ? defaultPricing?.priceMicros < 0
      : defaultPricing?.costMicros < 0;

  if (customProps.col === 0 && customProps.row === 0) {
    return (
      <MatrixTableCell
        key={key}
        horizontalSticky={true}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
        width={"15rem"}
        centered={true}
      >
        <MatrixHeaderCell>
          <MatrixTitleModeSelector />
          <MatrixSubModeSelector />
          <MatrixTableController {...pageProps} />
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  if (customProps.col === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        width={"10rem"}
        horizontalSticky={true}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          {target?.[titleMode] || "Default"}
          {target?.[titleMode] && <BackLinkIcon action={getBackLinkAction()} />}
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  if (customProps.row === 0) {
    return (
      <MatrixTableCell
        key={key}
        centered={true}
        width={!pageProps?.transpose ? "10rem" : undefined}
        backgroundColor={CONTAINER_BACKGROUND_COLOR}
      >
        <MatrixHeaderCell>
          {target?.[titleMode] || "Default"}
          {target?.[titleMode] && <BackLinkIcon action={getBackLinkAction()} />}
        </MatrixHeaderCell>
      </MatrixTableCell>
    );
  }
  return (
    <MatrixTableCell
      key={key}
      {...customProps}
      positive={isPositive}
      negative={isNegative}
    >
      {customProps?.children}
      <MatrixTableCellIconContainer
        hasCurrent={hasCurrentDependencies}
        hasEdits={customProps?.cell?.dependencies?.hasEdits}
        onClick={() => {
          setData({
            title: customProps?.cell?.dependencies?.title,
            setEdit: (newEdits: any) => {
              const edits = {...matrixEdits, ...newEdits};
              setMatrixEdits(edits);
            },
            type: "prices",
            field: "price_set",
            id: customProps?.cell?.key,
            info: customProps?.cell?.dependencies?.value,
          });
        }}
      >
        <NatSettingsIcon />
      </MatrixTableCellIconContainer>
    </MatrixTableCell>
  );
};
