import React, {useMemo} from "react";
import Select from "react-select";

const options = [
  {label: "None", value: undefined},
  {label: "Flip Horizontally", value: "xFlipped"},
];

interface ImageFitSelectorProps {
  id: string;
  updateImageOrientation: (value?: string) => void;
  currentValue?: any;
}

const ImageOrientationSelector = (props: ImageFitSelectorProps) => {
  const {id, updateImageOrientation, currentValue} = props;

  const selectedOption = useMemo(() => {
    return options?.find((o) => o?.value === currentValue) ?? options[0];
  }, [currentValue]);

  const selector = useMemo(() => {
    return (
      <Select
        id={`${id}-image-fit-selector`}
        options={options}
        defaultValue={selectedOption}
        value={selectedOption}
        onChange={(option) => {
          if (!!option) {
            updateImageOrientation(option?.value);
          }
        }}
      />
    );
  }, [id, selectedOption, updateImageOrientation]);
  return <>{selector}</>;
};

export default ImageOrientationSelector;
