import React, {useMemo, useState} from "react";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {
  NatModal,
  NatModalSize,
} from "../../../../_shared/generics/modal/NatModal";
import {useCurrentCustomer} from "../../../../_shared/hooks/useCurrentCustomer";
import {setCurrentProject} from "../../../../_shared/project";
import {ProjectsWrapper} from "../../../../portal/_shared/components/ProjectSelectionCard/styles";
import {ProjectSelectionCard} from "../../../../portal/_shared/components/ProjectSelectionCard";
import {useAddress} from "../../../../_shared/hooks/useAddress";
import {
  Address,
  CustomerProjectInfo,
  IAddressDetails,
  shallowEquals,
} from "@natomas-org/core";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

// TODO Review this implementation
// This modal warns public app users they're about to overwrite their project selection
export const StudioSaveWarningModal = (props: {onConfirmSave: () => void}) => {
  const {customer} = useCurrentCustomer();
  const {catalogAddress: address} = useAddress();
  const {id, mergedCustomerProjectInfos} = useActiveProject();
  const [selectedProject, setSelectedProject] = useState<
    CustomerProjectInfo | undefined
  >(mergedCustomerProjectInfos);
  const [customerProjects, setCustomerProjects]: any = useState([]);

  const handleClose = () => {};
  const changingAddress = useMemo(() => {
    const catalogAddressCoordinates: number[] | null = Address.getCoordinates(
      address ?? undefined
    );
    const projectAddressCoordinates: number[] | null = Address.getCoordinates(
      (selectedProject?.project_address as IAddressDetails) ?? undefined
    );

    return !shallowEquals(catalogAddressCoordinates, projectAddressCoordinates);
  }, [address, selectedProject?.project_address]);
  const content = useMemo(() => {
    if (!changingAddress) {
      return (
        <span>
          Select the project you’d like to change. This action will override
          your previous selection. If that’s ok with you, click proceed.
        </span>
      );
    }
    return (
      <span>
        Select the project you’d like to change. This action will override your
        previous selection. The new project address will be{" "}
        <b>{Address.getFullAddress(address ?? undefined)}</b>. If that’s ok with
        you, click proceed.
      </span>
    );
  }, [address, changingAddress]);
  let titleText = "Are you sure you want to change ADU models?";

  const modalContent = (
    <>
      <div style={{whiteSpace: "pre-wrap"}}>
        <div style={{display: "flex", flexWrap: "wrap"}}>{content}</div>
      </div>
      <br />
      <ProjectsWrapper>
        {Array.isArray(customerProjects) &&
          customerProjects
            .sort((a) => (a.id === id ? -1 : 1))
            .map((customerProject: any) => {
              return (
                <ProjectSelectionCard
                  {...customerProject}
                  key={customerProject.id}
                  customer={customer}
                  handleClick={() => {
                    setSelectedProject(customerProject);
                  }}
                  active={customerProject.id === id}
                />
              );
            })}
      </ProjectsWrapper>
    </>
  );

  const continueWithSave = () => {
    if (!!selectedProject?.id) {
      // Button is only enabled is selectedConfigurationId is not undefined
      setCurrentProject(selectedProject?.id)
        .then(() => {
          props.onConfirmSave();
        })
        .then(() => {
          // store.dispatch(setShowOverwriteProjectWarning(false));
        });
    }
  };

  const primaryButtonText = "Confirm";

  const footerButtons = [
    {
      label: "Dismiss",
      trackingId: "keep-viewing-upgrades",
      disabled: false,
      option: StyleOption.SECONDARY_ALT,
      clickEvent: handleClose,
    },
    {
      label: primaryButtonText,
      clickEvent: () => {
        continueWithSave();
      },
      disabled: !selectedProject?.id, // There should always be one configuration id selected, so this should always be enabled
      option: StyleOption.PRIMARY,
      trackingId: "continue-with-save",
      spinnerEnabled: true,
    },
  ];

  return (
    <NatModal
      size={NatModalSize.NORMAL}
      show={false}
      handleClose={handleClose}
      header={titleText}
      content={modalContent}
      footerButtons={footerButtons}
    />
  );
};
