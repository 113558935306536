import {NatPopupItemElement, NatPopupItemText} from "./NatPopup";
import React from "react";
import {usePage} from "../../../../../hooks/usePage";
import {NatomasPopupItemContainer} from "./styles";
import {DropdownOption} from "../../styles";

export const FormattedListItem = (props: {
  item: NatPopupItemText | NatPopupItemElement;
  idx: number;
  displayThin: boolean;
  bolded: boolean;
  handleClose?: () => void;
  removeBorder?: boolean;
  style?: React.CSSProperties;
}) => {
  const {item, idx, displayThin, bolded} = props;
  const {isNatMobile} = usePage();
  if ("component" in item && !!item?.component) {
    return (
      <NatomasPopupItemContainer
        key={`nat-popup-${item.id}-${idx}`}
        displayThin={displayThin}
        onClick={props.handleClose}
        style={{
          ...props.item.style,
          ...props.style,
          borderTop: idx === 0 || props.removeBorder ? "none" : undefined,
        }}
      >
        <>{item.component}</>
      </NatomasPopupItemContainer>
    );
  }
  let disabled: boolean = false;
  if ("disabled" in item && !!item?.disabled) {
    disabled = item?.disabled(item) === 0;
  }
  let optionContent = null;
  if ("label" in item && !!item?.label) {
    optionContent = item.label;
  }
  if ("icon" in item && !!item?.icon) {
    optionContent = (
      <>
        {item?.icon}
        {optionContent}
      </>
    );
  }
  if (!optionContent) {
    return null;
  }
  return (
    <NatomasPopupItemContainer
      key={`nat-popup-${item.id}-${idx}`}
      displayThin={displayThin}
      style={{
        borderTop: idx === 0 || props.removeBorder ? "none" : undefined,
      }}
    >
      <DropdownOption
        isMobile={isNatMobile}
        key={`nat-dropdown-${idx}-${item.id}`}
        id={`nat-dropdown-${idx}-${item.id}`}
        onClick={() => {
          if ("callback" in item && !!item?.callback) {
            item?.callback(item);
          }
          if (props.handleClose) {
            props.handleClose();
          }
        }}
        disabled={disabled}
        bold={bolded}
        useComponent={false}
        style={{
          ...props.style,
          color: "black",
          borderRadius: "0",
          width: "100%",
        }}
      >
        {optionContent}
      </DropdownOption>
    </NatomasPopupItemContainer>
  );
};
