import React, {useState} from "react";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {NatButton} from "../../../../_shared/generics/button";
import {
  AssociationContainer,
  AssociationSectionContainer,
  AssociationSectionContent,
} from "./styles";
import {
  GenericInputContentContainer,
  InputTitle,
} from "../../../_shared/styles/AdminStyles";

export interface AssociationSection {
  title: string;
  targets: any[];
  getTargetTitle: (target: any) => string;
  toTarget: (target: any) => void;
}

export const getAssociationSection = (
  title: string,
  targets: any[],
  getTargetTitle: (target: any) => string,
  toTarget: (target: any) => void
): AssociationSection => {
  return {
    title,
    targets,
    getTargetTitle,
    toTarget,
  };
};

export interface AssociationsContainerProps {
  sections: AssociationSection[];
}

export const AssociationsContainer = (props: AssociationsContainerProps) => {
  const {sections} = props;
  const [show, setShow] = useState<boolean>(false);
  const getSection = (section: AssociationSection) => {
    return (
      <AssociationSectionContainer key={section?.title}>
        <InputTitle bold={true} title={section?.title} enabled={true} />
        <AssociationSectionContent>
          {section.targets.map((t: any, index: number) => {
            return (
              <NatButton
                key={section.getTargetTitle(t) + "-visit-button-" + index}
                label={section.getTargetTitle(t)}
                size={NatSize.SMALL}
                option={StyleOption.SECONDARY_ALT}
                type={"button"}
                clickEvent={section.toTarget}
                clickEventParam={t}
                trackingId={"admin-link-to-product"}
              />
            );
          })}
        </AssociationSectionContent>
      </AssociationSectionContainer>
    );
  };

  const getSections = () => {
    return sections.map((s: any) => getSection(s));
  };

  const getTitle = () => {
    if (sections?.length === 0) return "No Associations";
    else return "Associations";
  };

  return (
    <AssociationContainer>
      <InputTitle bold={true} title={getTitle()} enabled={true} />
      <div>
        <NatButton
          label={sections?.length > 0 ? (show ? "Hide" : "Show") : "None"}
          disabled={sections?.length === 0}
          type={"button"}
          clickEvent={() => setShow(!show)}
          option={StyleOption.PRIMARY_ALT}
          size={NatSize.SMALL}
        />
      </div>
      {show && sections?.length > 0 && (
        <GenericInputContentContainer>
          {getSections()}
        </GenericInputContentContainer>
      )}
    </AssociationContainer>
  );
};
