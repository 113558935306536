import React, {useEffect} from "react";
import {BASE_URL} from "../../../../../../_shared/application";
import {NatButton, NatLink} from "../../../../../../_shared/generics/button";
import {
  IconPosition,
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {ProposalBudgetSummaryView} from "../../../../../../portal/views/ProposalBudgetView/ProposalBudgetSummaryView";
import useActiveDesign from "../../../../../../_shared/hooks/useDesign/useActiveDesign";
import {PricingOptionsController} from "../../../../../../portal/views/ProposalBudgetView/components/OP/PricingOptionsController";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {useDesigns} from "../../../../../../_shared/hooks/useDesign/useDesigns";
import {useDispatch} from "react-redux";
import {setActiveCartItemId} from "../../../../../../_shared/slices/ActivitySlice/ActivitySlice";
import {ProjectPriceMode} from "@natomas-org/core";
import {executeRequest} from "../../../../../../../database/firebase/api";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {
  NatNewDocumentIcon,
  NatRefreshIcon,
} from "../../../../../../_shared/icon/icons";
import {RevealCostsToggle} from "../../../../../../estimate/RevealCostsToggle";

export const DesignSelectionsSpreadsheet = (props: {FULL_WIDTH?: boolean}) => {
  const {configuration: activeDesign} = useActiveDesign();
  const {cartItemIds, id: pid, projectPriceMode} = useActiveProject();
  const designs = useDesigns(cartItemIds);
  const configuration = activeDesign ?? designs[pid];
  const dispatch = useDispatch();
  let designSpreadsheet = null;
  const spreadsheetId: string | undefined =
    configuration?.configuration?.spreadsheetId ??
    configuration?.spreadsheetId ??
    undefined;
  if (spreadsheetId) {
    designSpreadsheet =
      "https://docs.google.com/spreadsheets/d/" + spreadsheetId;
  }
  console.log("designSpreadsheet", designSpreadsheet);
  useEffect(() => {
    if (!configuration?.id) {
      dispatch(setActiveCartItemId(cartItemIds[0]));
    }
  }, [cartItemIds, configuration?.id, dispatch]);
  let customerPortalURL = "";
  if (pid) {
    customerPortalURL = BASE_URL + "/summary?pid=" + pid;
  }
  return (
    <NatFlex
      FULL_WIDTH={props.FULL_WIDTH ?? true}
      style={{padding: "0.5rem 1rem", margin: "0 0"}}
      ROW
      NOWRAP
      NOGAP
      VERTICAL_ALIGN={NatVerticalAlign.CENTER}
    >
      <NatFlex VERTICAL_ALIGN={NatVerticalAlign.CENTER}>
        <NatButton
          icon={
            designSpreadsheet != null
              ? {icon: <NatRefreshIcon />, iconPosition: IconPosition.ONLY}
              : {
                  icon: <NatNewDocumentIcon />,
                  iconPosition: IconPosition.LEFT,
                }
          }
          label={
            designSpreadsheet != null
              ? ""
              : "Generate Design Selections Spreadsheet"
          }
          size={NatSize.SMALL}
          spinnerEnabled={true}
          clickEvent={() => {
            return executeRequest(
              "/project/v1/generateDesignSelectionSpreadsheet",
              {projectId: pid}
            );
          }}
        />
        <NatLink
          label={"Design Selections Spreadsheet"}
          openInNewTab={true}
          hidden={!designSpreadsheet}
          option={StyleOption.PRIMARY_LINK}
          link={designSpreadsheet ?? undefined}
        />
        <NatLink
          label={"Customer Portal URL"}
          openInNewTab={true}
          option={StyleOption.PRIMARY_LINK}
          link={customerPortalURL}
        />
      </NatFlex>
    </NatFlex>
  );
};
export const BudgetSummaryTab = React.memo(() => {
  const {projectPriceMode} = useActiveProject();
  return (
    <>
      <NatFlex
        FULL_WIDTH
        ROW
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 1rem",
        }}
      >
        <DesignSelectionsSpreadsheet FULL_WIDTH={false} />
        <div
          style={{
            padding: 0,
          }}
          hidden={projectPriceMode === ProjectPriceMode.CostPlus}
        >
          <PricingOptionsController />
        </div>
        <div style={{maxWidth: "fit-content"}}>
          <RevealCostsToggle />
        </div>
      </NatFlex>
      <ProposalBudgetSummaryView
        hideCostCard={projectPriceMode === ProjectPriceMode.CostPlus}
        hideCostSummary={false}
        hideCostCardDescription={true}
        isAdmin={true}
      />
    </>
  );
});
