import React, {useMemo} from "react";
import {
  InfoSetColumnToggle,
  InfoSetColumnToggleContainer,
  InfoSetColumnToggleContainerLabel,
  InfoSetColumnToggleGroup,
  InfoSetColumnToggleSectionContainer,
} from "../../styles";
import {isPermanentField} from "../../table";
import {getColumnToggleTitle} from "../../helper";

interface ColumnControllerProps {
  allColumns: any[];
}

interface IColumnControllerGroup {
  id: string;
  header: string;
  columns: any[];
}

const ColumnController = (props: ColumnControllerProps) => {
  const {allColumns} = props;

  const columnGroups: IColumnControllerGroup[] = useMemo(() => {
    const columnDictionary = allColumns?.reduce((columns, col) => {
      if (isPermanentField(col)) {
        return columns;
      }
      const parentId = col.parent.id;
      const parentHeader = col.parent.Header;
      if (!columns[parentId])
        columns[parentId] = {
          id: parentId,
          header: parentHeader,
          columns: col.parent.columns?.filter((c: any) => !isPermanentField(c)),
        };
      return columns;
    }, {});
    return Object.values(columnDictionary);
  }, [allColumns]);

  return (
    <InfoSetColumnToggleContainer>
      <InfoSetColumnToggleContainerLabel>
        Columns
      </InfoSetColumnToggleContainerLabel>
      <InfoSetColumnToggleSectionContainer>
        {columnGroups.map((columnGroup: IColumnControllerGroup) => {
          return (
            <InfoSetColumnToggleGroup key={`column-group-${columnGroup.id}`}>
              {columnGroup.header}
              {columnGroup.columns.map((column: any, index: number) => {
                const props = column.getToggleHiddenProps();
                return (
                  <>
                    <InfoSetColumnToggle
                      selected={props.checked}
                      onClick={() => {
                        if (props.checked && column?.isSorted) {
                          column?.clearSortBy?.();
                        }
                      }}
                      key={`column-${column.id}`}
                    >
                      <input hidden={true} type="checkbox" {...props} />
                      {getColumnToggleTitle(column)}
                    </InfoSetColumnToggle>
                  </>
                );
              })}
            </InfoSetColumnToggleGroup>
          );
        })}
      </InfoSetColumnToggleSectionContainer>
    </InfoSetColumnToggleContainer>
  );
};

export default ColumnController;
