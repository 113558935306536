import React, {useMemo} from "react";
import {IFactoryInventoryStudioEditorModifierGroup} from "./interface";
import ModifierEditor from "../ModifierSection";
import {
  StudioEditorModifierGroupContainer,
  StudioEditorModifierGroupDescription,
  StudioEditorModifierGroupTitle,
  StudioEditorModifierGroupTitleContainer,
  StudioEditorModifierGroupTitleWrapper,
} from "./styles";
import StudioEditorFieldAddButton, {
  StudioEditorFieldType,
} from "../_shared/StudioEditorFieldAddButton";
import {IFactoryInventoryStudioEditorPopupOptions} from "../PopupContent/interface";
import FieldHighlight from "../_shared/FieldHighlight";
import {getModifierGroupType} from "../PopupContent/ModifierGroup/helper";
import {sortValuesByIndex} from "../../formik/preview";
import {IFactoryInventoryStudioEditorModifier} from "../ModifierSection/interface";
import StudioEditorFieldConditionalController from "../_shared/StudioEditorFieldButton";
import {StudioEditorMode} from "../../../index";

interface ModifierGroupEditorProps {
  id: string;
  initialValue?: IFactoryInventoryStudioEditorModifierGroup;
  currentValue?: IFactoryInventoryStudioEditorModifierGroup;
  setActionEvent?: (
    e: any,
    options?: IFactoryInventoryStudioEditorPopupOptions
  ) => void;
  categoryId?: string;
  mode?: StudioEditorMode;
}

const ModifierGroupEditor = (props: ModifierGroupEditorProps) => {
  const {id, initialValue, currentValue, setActionEvent, categoryId, mode} =
    props;

  const modifiers = useMemo(() => {
    const modifierArray = Object.values(currentValue?.modifiers ?? {});
    return sortValuesByIndex(modifierArray);
  }, [currentValue]);

  const modifierIds: string[] = useMemo(() => {
    return modifiers?.map(
      (modifier: IFactoryInventoryStudioEditorModifier) => modifier?.id
    );
  }, [modifiers]);

  return (
    <StudioEditorModifierGroupContainer
      key={`${id}`}
      showBorder={mode && mode !== StudioEditorMode.EDIT}
    >
      <StudioEditorModifierGroupTitleWrapper>
        <StudioEditorModifierGroupTitleContainer>
          {mode && setActionEvent && (
            <StudioEditorFieldConditionalController
              path={{
                categoryId,
                modifierGroupId: currentValue?.id,
              }}
              mode={mode}
              type={StudioEditorFieldType.MODIFIER_GROUP}
              setActionEvent={setActionEvent}
            />
          )}
          <FieldHighlight
            initialValue={initialValue?.title}
            value={currentValue?.title}
          >
            <StudioEditorModifierGroupTitle>
              {currentValue?.title}
            </StudioEditorModifierGroupTitle>
          </FieldHighlight>
        </StudioEditorModifierGroupTitleContainer>
        {mode === StudioEditorMode.EDIT && (
          <StudioEditorFieldAddButton
            type={StudioEditorFieldType.MODIFIER}
            rootPath={{categoryId, modifierGroupId: currentValue?.id}}
            setActionEvent={setActionEvent}
          />
        )}
      </StudioEditorModifierGroupTitleWrapper>
      {currentValue?.description && (
        <StudioEditorModifierGroupDescription>
          <FieldHighlight
            initialValue={initialValue?.description}
            value={currentValue?.description}
          >
            {currentValue?.description}
          </FieldHighlight>
        </StudioEditorModifierGroupDescription>
      )}
      {modifierIds?.map((modifierId: string, index: number) => {
        const modifier = currentValue?.modifiers?.[modifierId];
        const initialModifierGroup =
          initialValue?.modifiers?.[modifierId] ?? undefined;
        if (!modifier) {
          return null;
        }
        return (
          <ModifierEditor
            key={modifier?.id}
            id={`${id}-modifier-${index}`}
            initialValue={initialModifierGroup}
            currentValue={modifier}
            setActionEvent={setActionEvent}
            categoryId={categoryId}
            modifierGroupId={currentValue?.id}
            modifierGroupType={getModifierGroupType(currentValue)}
            mode={mode}
          />
        );
      })}
    </StudioEditorModifierGroupContainer>
  );
};

export default ModifierGroupEditor;
