import styled from "styled-components";

export const ConditionAssociationsLabel = styled.div<{
  isComplete: boolean;
  isPartial: boolean;
}>`
  background-color: ${(props) =>
    props?.isComplete ? "#b1d7a8" : props?.isPartial ? "#d7bca8" : "#a8c3d7"};
  border-radius: 1rem;
  width: fit-content;
  padding: 0.6rem 0.75rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  height: 2rem;
  cursor: pointer;
`;
