import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const SeriesSummaryProductsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const SeriesSummaryProductLabel = styled.div`
  &:hover {
    font-family: ${FONT_FAMILY_BOLD};
  }
`;
