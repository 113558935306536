import styled from "styled-components";
import {BLACK, WHITE} from "../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";

export const ReadOnlyFactoryInfoSetItemOptionCard = styled.div`
  width: 100%;
  height: auto;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  color: ${BLACK};
  background-color: #dedede;
  padding: 1rem;
  border-top: 1px solid ${BLACK};
`;

export const ReadOnlyFactoryInfoSetItemOptionCardTitle = styled.h5`
  padding: 0;
  margin: 0;
`;

export const ReadOnlyFactoryInfoSetItemSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
  border: 1px solid ${BLACK};
  background-color: ${WHITE};
`;

export const ReadOnlyFactoryInfoSetItemSectionTitle = styled.h3`
  margin: 0;
  padding: 0;
`;

export const ReadOnlyFactoryInfoSetItemSectionTitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  align-items: center;
  cursor: pointer;
`;

export const ReadOnlyFactoryInfoSetItemSectionField = styled.p`
  font-size: 12px;
  font-family: ${FONT_FAMILY_MEDIUM};
  padding: 8px 12px;
  margin: 0;
  border-top: 1px solid ${BLACK};
`;

export const ReadOnlyFactoryInfoSetItemFieldTitle = styled.p`
  font-size: 14px;
  font-family: ${FONT_FAMILY_MEDIUM};
  padding: 0 0 0 8px;
  margin: 0;
`;
export const ReadOnlyFactoryInfoSetItemFieldValue = styled.p`
  font-size: 12px;
  padding: 0 0 0 16px;
  margin: 0;
`;
