import React, {useMemo} from "react";
import {UnitTable} from "./UnitTable";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {
  BUDGET_SECTION_UNIT,
  BudgetEntry,
  LineItemType,
} from "@natomas-org/core";
import {IGenericBudgetSummaryProps} from "../generic/GenericBudgetSummary";
import {TableRow} from "../generic/TableRow";
import {VILLA_APPLE_GREEN_LIGHT_2} from "../../../../../_shared/colors";
import {FONT_FAMILY_BOLD} from "../../../SSDDashboardView/styles/globals";
import {RowContainer} from "../../styles/SiteWorkTableStyles";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {useProjectBudget} from "../../../../../_shared/hooks/useProjectBudget/useProjectBudget";
import {GenericLoadingComponent} from "../generic/GenericLoadingComponent";

export const UnitBudgetSummary = (props: IGenericBudgetSummaryProps) => {
  const {projectPriceMode, isCartEnabled} = useActiveProject();
  const {projectBudget, refreshBudget} = useProjectBudget();
  const {isNatMobile} = usePage();
  const entries = useMemo(() => {
    return projectBudget?.getHomeDesign()?.getFullBudgetEntries();
  }, [projectBudget]);
  const units = useMemo(() => {
    return projectBudget?.getHomeDesign()?.getPricingData()?.units;
  }, [projectBudget]);

  if (!units) {
    return <GenericLoadingComponent />;
  }
  if (!entries) {
    return <div>No data available</div>;
  }
  let footer = null;
  if (isCartEnabled) {
    footer = (
      <RowContainer
        key={"row-footer"}
        isNatMobile={isNatMobile}
        style={{
          paddingTop: "0.5rem",
          backgroundColor: VILLA_APPLE_GREEN_LIGHT_2,
          fontFamily: FONT_FAMILY_BOLD,
        }}
      >
        <TableRow
          data={
            new BudgetEntry({
              displayTitle: "All Units Total",
              price: {
                price: projectBudget?.home_design_total?.price ?? 0,
                cost: null,
                currency: "USD",
              },
              id: LineItemType.SECTION_TOTAL,
              bucket: BUDGET_SECTION_UNIT,
              note: ["ESTIMATE ONLY", "Final Price to be confirmed at ATB"],
            })
          }
          style={{
            backgroundColor: VILLA_APPLE_GREEN_LIGHT_2,
            fontFamily: FONT_FAMILY_BOLD,
          }}
          displayProps={{
            displayDescription: true,
          }}
        />
      </RowContainer>
    );
  }
  return (
    <div>
      {units.map((unit, idx) => {
        return (
          <UnitTable
            {...props}
            unit={unit}
            refresh={refreshBudget}
            projectPriceMode={projectPriceMode}
            isCartEnabled={isCartEnabled}
            includeHeader={idx === 0}
            key={unit.id}
          />
        );
      })}
      {footer}
    </div>
  );
};
