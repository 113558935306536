import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../../../../../_shared/slices/types/Store";
import {INVENTORY_UNIT_PREFIX} from "../../../../../../_shared/constants/labels";
import Select, {createFilter} from "react-select";
import {setAdminView} from "../../../../../../_shared/slices/AdminSlice";
import {MODAL_Z_INDEX} from "../../../../../../_shared/styles";
import {
  NatPopupItem,
  NatPopupItemText,
} from "../../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";

export enum FactoryView {
  INVENTORY = "inventory",
}

const FactoryViewSelectorOptions: NatPopupItemText[] = [
  {label: "All", id: "all"},
  {label: INVENTORY_UNIT_PREFIX, id: FactoryView.INVENTORY},
];

const FactoryViewSelector = () => {
  const currentView = useSelector((state: IStore) => state.administration.view);
  const dispatch = useDispatch();
  const filterConfig = useMemo(() => {
    return {
      ignoreCase: true,
      ignoreAccents: true,
      trim: true,
      matchFrom: "any" as const,
    };
  }, []);

  return (
    <Select
      isSearchable
      filterOption={createFilter(filterConfig)}
      styles={{
        container: (styles) => ({
          ...styles,
          minWidth: "300px",
          zIndex: MODAL_Z_INDEX,
        }),
      }}
      value={
        FactoryViewSelectorOptions?.find(
          (o: NatPopupItem) => o.id === currentView
        ) ?? FactoryViewSelectorOptions[0]
      }
      getOptionValue={(option) => option.id}
      isDisabled={false}
      onChange={(v) => {
        const option = v as NatPopupItem;
        let value: string | null = option.id;
        if (value === "all") {
          value = null;
        }
        dispatch(setAdminView(value));
      }}
      options={FactoryViewSelectorOptions}
    />
  );
};

export default FactoryViewSelector;
