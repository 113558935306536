import styled from "styled-components";

export const NexusTableEditorButtonContainer = styled.div`
  padding: 1rem 1rem;
  gap: 1rem;
  display: flex;
  width: 100%;
  padding-top: 1rem;
  justify-content: space-between;
`;
