import {isBlankString} from "@natomas-org/core";
import {setDeepValue} from "../../../../../_shared/Utils";
import {AdminProductCatalogMatrixModes} from "../../../../../_shared/slices/types/Store";

export const productGroupLevelKey = "productGroup";

export const getProductModifierUpdates = (edits: any) => {
  const productBreakdown = {};
  Object.keys(edits).forEach((key) => {
    let editMap = edits[key];
    const [destination, id] = key.split("_");
    // @ts-ignore
    if (productBreakdown[destination] == null) {
      // @ts-ignore
      productBreakdown[destination] = {};
    }
    Object.keys(editMap).forEach((editKey) => {
      let editValue = editMap[editKey];
      let fieldKey = editKey;
      if (editKey === AdminProductCatalogMatrixModes.PRICE_SET) {
        const priceSet = getPriceSet(editValue);
        // @ts-ignore
        productBreakdown[destination][id] = {
          price_set: priceSet,
        };
      } else if (editKey === AdminProductCatalogMatrixModes.DESCRIPTIONS) {
        fieldKey =
          destination === productGroupLevelKey
            ? "displayDescriptionDefault"
            : "displayDescription";
        // @ts-ignore
        productBreakdown[destination][id] = {
          [fieldKey]: editValue,
        };
      } else if (editKey === AdminProductCatalogMatrixModes.PRODUCT_INFO) {
        // @ts-ignore
        setDeepValue(productBreakdown[destination], id, editValue);
      }
    });
  });

  return productBreakdown;
};

export const priceMicrosToMatrix = (priceMicros: number) => {
  if (priceMicros == null) {
    return "";
  }
  return "" + priceMicros / 100;
};

export const getPriceValue = (entry: string | null): number => {
  if (!entry || isBlankString(entry)) {
    return 0;
  }
  const strippedText = entry.replace(/[$,]/g, "");
  const parsedFloat = parseFloat(strippedText);
  if (isNaN(parsedFloat)) {
    return 0;
  }
  return Math.round(parsedFloat * 100) / 100;
};

export const getPriceSet = (pricing: any[]) => {
  return pricing?.map((price: any) => {
    return {
      price: {
        cost: price?.costMicros,
        price: price?.priceMicros,
        currency: "USD",
      },
      condition: price?.condition,
      dependentModifiers: price?.requiredModifiers ?? [],
      type: price?.type,
    };
  });
};
