import {
  GenericBudgetSummary,
  IGenericBudgetSummaryProps,
} from "../generic/GenericBudgetSummary";
import React from "react";
import {BUDGET_SECTION_ADDITIONAL} from "@natomas-org/core";

export const AdditionalSiteCostsSummary = (
  props: IGenericBudgetSummaryProps
) => {
  return (
    <GenericBudgetSummary displayType={BUDGET_SECTION_ADDITIONAL} {...props} />
  );
};
