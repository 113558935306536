// 96% with 2% gutters on the side.
export const PAGE_ROOT_GUTTER_PERCENTAGE = 2;

export const FOUR_COLUMN_THRESHOLD = 576;
export const FOUR_COLUMN_GUTTER_WIDTH_PERCENTAGE = 2;

export const EIGHT_COLUMN_THRESHOLD = 768;
export const EIGHT_COLUMN_GUTTER_WIDTH_PERCENTAGE = 2;

export const TWELVE_COLUMN_THRESHOLD = 992;
export const TWELVE_COLUMN_GUTTER_WIDTH_PERCENTAGE = 2;

export const SIXTEEN_COLUMN_THRESHOLD = 1400;
export const SIXTEEN_COLUMN_GUTTER_WIDTH_PERCENTAGE = 2;

export const calculateGutterWidthPercentage = (w: number) => {
  if (w < FOUR_COLUMN_THRESHOLD) {
    return FOUR_COLUMN_GUTTER_WIDTH_PERCENTAGE;
  } else if (w < EIGHT_COLUMN_THRESHOLD) {
    return EIGHT_COLUMN_GUTTER_WIDTH_PERCENTAGE;
  } else if (w < TWELVE_COLUMN_THRESHOLD) {
    return TWELVE_COLUMN_GUTTER_WIDTH_PERCENTAGE;
  } else {
    return SIXTEEN_COLUMN_GUTTER_WIDTH_PERCENTAGE;
  }
};

export const calculateContainerWidth = (width: number): number => {
  if (width > calculateBreakpoint(SIXTEEN_COLUMN_THRESHOLD)) {
    return SIXTEEN_COLUMN_THRESHOLD;
  } else if (width > calculateBreakpoint(TWELVE_COLUMN_THRESHOLD)) {
    return TWELVE_COLUMN_THRESHOLD;
  } else if (width > calculateBreakpoint(EIGHT_COLUMN_THRESHOLD)) {
    return EIGHT_COLUMN_THRESHOLD;
  } else if (width > calculateBreakpoint(FOUR_COLUMN_THRESHOLD)) {
    return FOUR_COLUMN_THRESHOLD;
  } else return width * 0.9;
};

export const calculateGutterWidthPixels = (w: number) => {
  if (w <= FOUR_COLUMN_THRESHOLD) {
    return w / (100 / FOUR_COLUMN_GUTTER_WIDTH_PERCENTAGE);
  } else if (w <= EIGHT_COLUMN_THRESHOLD) {
    return w / (100 / EIGHT_COLUMN_GUTTER_WIDTH_PERCENTAGE);
  } else if (w <= TWELVE_COLUMN_THRESHOLD) {
    return w / (100 / TWELVE_COLUMN_GUTTER_WIDTH_PERCENTAGE);
  } else {
    return w / (100 / SIXTEEN_COLUMN_GUTTER_WIDTH_PERCENTAGE);
  }
};

export const calculateNumberOfColumns = (w: number) => {
  if (w < calculateBreakpoint(FOUR_COLUMN_THRESHOLD)) {
    return 4;
  } else if (w < calculateBreakpoint(EIGHT_COLUMN_THRESHOLD)) {
    return 8;
  } else if (w < calculateBreakpoint(TWELVE_COLUMN_THRESHOLD)) {
    return 12;
  } else {
    return 16;
  }
};

export const calculateBreakpoint = (w: number): number => {
  // Get the breakpoint with the addition of the page gutters
  return Math.floor(w * (1 + (PAGE_ROOT_GUTTER_PERCENTAGE * 2) / 100));
};

export const calculateRootPadding = (
  enabled: boolean,
  padding: number
): number => {
  if (enabled) return padding;
  else return 0;
};
