import React, {useMemo} from "react";
import {AdminFactoryInventoryEditorView} from "../../../../../../../../../_shared/slices/CatalogSlice/interfaces";
import {NatToggleSelectorOption} from "../../../../../../../../../_shared/generics/toggle-selector/interfaces";
import {NatToggleSelector} from "../../../../../../../../../_shared/generics/toggle-selector";
import {useDispatch} from "react-redux";
import {useFactoryInventoryEditor} from "../../../../../../../../../_shared/hooks/admin-only-hooks/useFactoryInventoryEditor";
import {getFactoryInventoryEditorToggle} from "./helper";
import {useProduct} from "../../../../../../../../../_shared/hooks/useProductCatalog/useProduct";

export const FactoryInventoryEditorToggle = (props: {locked?: boolean}) => {
  const {locked} = props;
  const {view: editorView, editingProduct} = useFactoryInventoryEditor();
  const {product} = useProduct(
    editingProduct?.id,
    editingProduct?.productGroupId
  );
  const dispatch = useDispatch();

  const options: NatToggleSelectorOption[] =
    useMemo((): NatToggleSelectorOption[] => {
      let o = [
        getFactoryInventoryEditorToggle(
          AdminFactoryInventoryEditorView.CATALOG,
          editorView,
          dispatch
        ),
      ];
      // Product has been saved
      if (product)
        o.push(
          getFactoryInventoryEditorToggle(
            AdminFactoryInventoryEditorView.STUDIO,
            editorView,
            dispatch
          ),
          getFactoryInventoryEditorToggle(
            AdminFactoryInventoryEditorView.PRICING,
            editorView,
            dispatch
          ),
          getFactoryInventoryEditorToggle(
            AdminFactoryInventoryEditorView.INFO,
            editorView,
            dispatch
          )
        );
      return o;
    }, [dispatch, editorView, product]);

  return <NatToggleSelector options={options} locked={locked} />;
};
