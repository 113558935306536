import _ from "lodash";
import {queryMapFeatures} from "../../database/firebase/mapping/query";
import {IEligibleFeatures, NatomasMapFeatureType} from "@natomas-org/core";
import LogRocket from "logrocket";

let lastFetched: {
  latitude: number;
  longitude: number;
  featureTypes: [NatomasMapFeatureType, ...NatomasMapFeatureType[]];
  result: IEligibleFeatures;
};

export const previouslyFetched = (
  featureTypes: [NatomasMapFeatureType, ...NatomasMapFeatureType[]],
  latitude: number,
  longitude: number
) => {
  return _.isEqual(
    {
      latitude: lastFetched?.latitude,
      longitude: lastFetched?.longitude,
      featureTypes: lastFetched?.featureTypes,
    },
    {
      latitude,
      longitude,
      featureTypes,
    }
  );
};

export const getEligibilityFeatures = async (
  featureTypes: [NatomasMapFeatureType, ...NatomasMapFeatureType[]],
  latitude?: number | null,
  longitude?: number | null
): Promise<IEligibleFeatures | undefined> => {
  if (!latitude || !longitude) {
    return undefined;
  }
  if (previouslyFetched(featureTypes, latitude, longitude)) {
    return lastFetched?.result ?? {};
  }
  if (latitude && longitude && featureTypes) {
    return queryMapFeatures({
      collectionId: "mapping",
      coordinates: {
        longitude: Number(longitude),
        latitude: Number(latitude),
      },
      types: featureTypes,
    })
      .then((eligible: IEligibleFeatures) => {
        let r: IEligibleFeatures;
        if (eligible && !_.isEmpty(eligible)) {
          r = eligible;
        } else {
          // If there are no availabilities, show Woodland
          r = {factories: {aduV2: true}};
        }
        lastFetched = {
          longitude: Number(longitude),
          latitude: Number(latitude),
          featureTypes,
          result: r,
        };
        return r;
      })
      .catch((reason: any) => {
        LogRocket.error(
          "Eligibility feature query failed",
          reason,
          latitude,
          longitude,
          featureTypes
        );
        return {};
      });
  }
  return {};
};
