import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {
  setSearchCategories,
  setSearchModifiers,
  setSelectedImageId,
  setZoomModifier,
} from "../../slices/ImageFinderSlice";
import {
  getAdminCategoryLabel,
  getAdminModifierLabel,
} from "../../../admin/_shared/_helpers";

export const useImageFinder = () => {
  const {selectedImageId, searchCategories, searchModifiers, zoomModifier} =
    useSelector((state: IStore) => state?.imageFinder);
  const dispatch = useDispatch();
  const setImageFinderImageId = (id: string) => {
    if (!!id) {
      dispatch(setSelectedImageId(id));
    }
  };
  const setImageFinderCategories = (categories: any) => {
    if (categories) {
      dispatch(setSearchCategories(categories));
    }
  };

  const setImageFinderModifiers = (modifiers: any) => {
    if (modifiers) {
      dispatch(setSearchModifiers(modifiers));
    }
  };

  const setImageFinderZoomModifier = (zoom: number) => {
    dispatch(setZoomModifier(zoom));
  };

  const getCategoryLabel = (id?: string) => {
    return getAdminCategoryLabel(searchCategories, id);
  };

  const getModifierLabel = (id?: string) => {
    return getAdminModifierLabel(searchModifiers, id);
  };

  return {
    currentImageFinderImageId: selectedImageId,
    searchCategories,
    searchModifiers,
    zoomModifier,
    setImageFinderImageId,
    setImageFinderCategories,
    setImageFinderModifiers,
    setImageFinderZoomModifier,
    getModifierLabel,
    getCategoryLabel,
  };
};
