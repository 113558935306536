import {IProjectInfo, ProjectUserType} from "@natomas-org/core";
import {savePayloadToProject} from "../cart";

export const saveProjectUser = async (
  projectInfo: IProjectInfo,
  userId: string,
  userProjectPermission: ProjectUserType
) => {
  const id = projectInfo.id;
  if (!id) return;
  const payload = {...projectInfo.users, [userId]: userProjectPermission};
  return savePayloadToProject(id, {users: payload});
};
