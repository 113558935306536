import styled from "styled-components";

export const BedBathCountButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  margin: 0.15rem 0.15rem;
  align-items: center;
  width: 100%;
`;

export const BedBathCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BedBathFilterContainer = styled.div<{isNatMobile: boolean}>`
  display: ${(props) => (props.isNatMobile ? "flex" : "inherit")};
  flex-direction: ${(props) => (props.isNatMobile ? "column" : "inherit")};
  gap: ${(props) => (props.isNatMobile ? "1rem" : "inherit")};
`;
