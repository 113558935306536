import styled from "styled-components";
import {
  BLACK,
  GRANITE,
  GRAY,
  PROGRESS_COLOR,
  VILLA_INFORMATIONAL,
  WHITE,
} from "../../colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";

export const ProgressBarContainer = styled.div`
  width: 100%;
  height: 2rem;
  position: relative;
`;

export const ProgressBarMeter = styled.div<{width: number}>`
  height: inherit;
  width: ${(props) => props.width}px;
  background-color: ${PROGRESS_COLOR};
  border-radius: 0 1rem 1rem 0;
  transition: all 1s ease-in-out;
`;

export const ProgressBarStepsContainer = styled.div<{padding?: string}>`
  position: absolute;
  top: 0;
  padding-left: ${(props) => props.padding ?? "0"};
  padding-right: ${(props) => props.padding ?? "0"};
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

export const ProgressBarStepContainer = styled.div<{
  bold: boolean;
  disabled: boolean;
  widthPercentage: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.disabled ? GRAY : BLACK)};
  font-family: ${(props) =>
    props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  width: ${(props) => props.widthPercentage}%;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-size: 0.875rem;
  line-height: 2rem;
  border-radius: 2rem;
  margin: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    background-color: ${WHITE};
  }
  transition: background-color 150ms linear;
`;

const DEFAULT_NAT_PROGRESS_BAR_HEIGHT_REM = 2;
export const NatProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;
export const NatProgressBarTextContainer = styled.div`
  height: 100%;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${GRANITE};
`;
export const NatProgressBarOuter = styled.div<{
  totalWidthRem?: number;
  outerColor?: string;
  heightRem?: number;
}>`
  height: ${(props) =>
    `${props.heightRem}rem` ?? `${DEFAULT_NAT_PROGRESS_BAR_HEIGHT_REM}rem`};
  width: ${(props) =>
    props.totalWidthRem ? `${props.totalWidthRem}rem` : "100%"};
  border-radius: ${(props) =>
    props.heightRem
      ? `${props.heightRem / 2}rem`
      : `${DEFAULT_NAT_PROGRESS_BAR_HEIGHT_REM / 2}rem`};
  background: ${(props) => props.outerColor ?? WHITE};
  background: transparent;
  border: 1.5px solid ${GRANITE};
`;
export const NatProgressBarInner = styled.div<{
  widthPercent?: number;
  innerColor?: string;
  heightRem?: number;
}>`
  height: 100%;
  width: ${(props) => props.widthPercent}%;
  border-radius: ${(props) =>
    props.heightRem
      ? `${props.heightRem / 2}rem 0 0 ${props.heightRem / 2}rem`
      : `${DEFAULT_NAT_PROGRESS_BAR_HEIGHT_REM / 2}rem 0 0 ${
          DEFAULT_NAT_PROGRESS_BAR_HEIGHT_REM / 2
        }rem`};
  background: ${(props) => props.innerColor ?? VILLA_INFORMATIONAL};
  transition: width 0.4s ease;
  box-shadow: ${(props) => {
    const heightRem = props.heightRem ?? DEFAULT_NAT_PROGRESS_BAR_HEIGHT_REM;
    const shadowHeightPx = heightRem * 2;
    return `${shadowHeightPx}px ${shadowHeightPx}px ${shadowHeightPx}px rgba(0, 0, 0, 0.3) inset`;
  }};
`;
