import React from "react";
import styled from "styled-components";
import Text from "../../../Text";
import check from "./check.svg";
import x from "./x.svg";

export interface IValidation {
  isValid: boolean;
  message: string;
}

const Validation = (props: {conditions: IValidation[]}) => {
  const {conditions} = props;
  if (conditions.length === 0) {
    return null;
  } else {
    return (
      <Wrapper>
        <Text
          text={"Your password must contain"}
          style={{fontSize: 12, color: "#807D7A", fontFamily: "Inter-Regular"}}
        />
        {conditions.map((condition, index) => (
          <Container>
            <img
              width={12}
              height={12}
              src={condition.isValid ? check : x}
              alt={condition.isValid ? "Check" : "Error"}
              style={{
                marginTop: "6px",
              }}
            />
            <Text
              key={index}
              text={condition.message}
              style={{
                fontFamily: "Inter-Regular",
                fontSize: 12,
                fontWeight: 400,
                color: condition.isValid ? "#368836" : "#EF1800",
              }}
            />
          </Container>
        ))}
      </Wrapper>
    );
  }
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Validation;
