import React from "react";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {ProjectTimelineLayout} from "../../../../ProposalView/ProjectTimelineLayout";
import {TIMELINE_PDF_SCALAR} from "../B2BTimelineSlide/B2BTimelineSlide";
import {FULL_SCREEN_HEIGHT_REM} from "../../../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";

export const DTCTimelineSlide = () => {
  return (
    <NatFlex FULL_WIDTH NOGAP COLUMN NOWRAP style={{height: `min-content`}}>
      <div
        style={{
          maxHeight: `${FULL_SCREEN_HEIGHT_REM * TIMELINE_PDF_SCALAR}rem`,
          minHeight: `${FULL_SCREEN_HEIGHT_REM * TIMELINE_PDF_SCALAR}rem`,
          width: "100%",
        }}
      >
        <ProjectTimelineLayout pdfRenderFormat={true} expandMilestones={true} />
      </div>
    </NatFlex>
  );
};
