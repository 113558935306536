import React, {useCallback, useMemo} from "react";
import {Address, Customer} from "@natomas-org/core";
import {CustomerInfoTab} from "./sections/CustomerInfo";
import {CustomerDatabasePath} from "../_shared/CustomerDatabasePath";
import {
  CustomerDatabaseLayout,
  CustomerDatabaseLayoutContent,
  CustomerDatabaseLayoutMenu,
  CustomerSideBarSectionWrapper,
} from "./styles";
import {useUrl} from "../../../_shared/hooks/useUrl";
import {
  CustomerProjectsTableId,
  CustomerQuickInfoId,
  getCustomerProjectTabs,
  getCustomerTabs,
  IAdminCustomerTab,
} from "./sections/_shared/tabs";
import {
  CustomerTabMenu,
  CustomerTabMenuItem,
} from "./sections/_shared/CustomerProjectTabHeader/styles";
import {setURLQueryKeyValue} from "../../../_shared/navigation/_helpers";
import {CustomerProjectViewContainer} from "./sections/CustomerProjectTabs/styles";
import {CustomerViewsDropdown} from "../_shared/CustomerViewsDropdown";
import {Helmet} from "react-helmet-async";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";

export const CustomerManagerView = () => {
  const {pid, pageId} = useUrl();
  const {customer} = useCurrentCustomer();
  const {address, projectPriceMode} = useActiveProject();
  const projectTabs = useMemo(() => {
    return getCustomerProjectTabs(projectPriceMode);
  }, [projectPriceMode]);
  const customerTabs = getCustomerTabs();

  // Memoize all the tabs menu items
  const buildTabs = useCallback(
    (tabDictionary: {[key: string]: IAdminCustomerTab}) => {
      const tabs = Object.values(tabDictionary);
      return (
        <CustomerTabMenu>
          {tabs.map((tab: IAdminCustomerTab) => {
            return (
              <CustomerTabMenuItem
                key={`${tab.id}`}
                onClick={() => {
                  console.log(tab.id);
                  setURLQueryKeyValue("page", tab.id);
                }}
                selected={tab.id === pageId}
              >
                {tab.title}
              </CustomerTabMenuItem>
            );
          })}
        </CustomerTabMenu>
      );
    },
    [pageId]
  );

  // Select current tab component
  const currentTab = useMemo(() => {
    let component;
    if (pid) {
      component =
        projectTabs[pageId ?? CustomerQuickInfoId]?.component ??
        projectTabs[CustomerQuickInfoId]?.component;
    } else if (pageId) {
      component =
        customerTabs[pageId]?.component ??
        customerTabs[CustomerProjectsTableId]?.component;
    } else {
      component = customerTabs[CustomerProjectsTableId]?.component;
    }
    return (
      <CustomerProjectViewContainer id={"project-view-tab"}>
        {component}
      </CustomerProjectViewContainer>
    );
  }, [customerTabs, pageId, pid, projectTabs]);

  // Memoize all the tabs menu items
  const tabs = useMemo(() => {
    if (pid && projectTabs) {
      return buildTabs(projectTabs);
    } else if (customerTabs) {
      return buildTabs(customerTabs);
    } else {
      return null;
    }
  }, [pid, projectTabs, customerTabs, buildTabs]);

  const title = useMemo(() => {
    if (!customer) return "Customer";
    let title = Customer.getLastName(customer) ?? "Customer";
    if (!!pid) {
      const addressString = ` | ${
        Address.getStreetNumberAndStreet(address ?? undefined) ??
        "Empty Address"
      }`;
      title = title.concat(addressString);
    }
    return title;
  }, [customer, pid, address]);

  return (
    <div style={{height: "100vh"}}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomerDatabasePath
        customer={customer}
        address={address ?? Address.dataToAddress()}
      />
      <CustomerDatabaseLayout>
        <CustomerDatabaseLayoutMenu>
          <CustomerSideBarSectionWrapper>
            <CustomerInfoTab />
          </CustomerSideBarSectionWrapper>
          {!!pid && (
            <CustomerSideBarSectionWrapper>
              <CustomerViewsDropdown />
            </CustomerSideBarSectionWrapper>
          )}
          {tabs}
        </CustomerDatabaseLayoutMenu>
        <CustomerDatabaseLayoutContent>
          {currentTab}
        </CustomerDatabaseLayoutContent>
      </CustomerDatabaseLayout>
    </div>
  );
};
