export const ProductModifierStructure = {
  title: {
    name: "Title",
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    index: 0,
  },
  priceMicros: {
    name: "Price",
    index: 2,
    alwaysEnabled: true,
  },
  prices: {
    name: "Price Dependencies",
    defaultValue: [],
    index: 2,
    type: "priceTable",
  },
  priceAdjustments: {
    name: "Price Adjustments",
    defaultValue: [],
    index: 2,
    type: "priceTable",
  },
  description: {
    name: "Description",
    index: 3,
  },
  displayDescription: {
    name: "Display Description",
    index: 4,
  },
  configurationButtonTitle: {
    name: "Button Title",
    index: 1,
  },
  blockingModifiers: {
    name: "Blocking Modifiers",
    defaultValue: [],
    index: 11,
  },
};

export const BaseModifierStructure = {
  title: {
    name: "Title",
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    index: 0,
  },
  descriptionDefault: {
    name: "Description",
    index: 15,
  },
  displayDescriptionDefault: {
    name: "Display Description",
    index: 16,
  },
  color: {
    name: "Color",
    index: 10,
  },
  secondaryColor: {
    name: "Secondary Color",
    index: 11,
  },
  imageId: {
    name: "Image",
    index: 9,
  },
  optionValues: {
    name: "Modified Options",
    index: 6,
  },
  configurationButtonTitle: {
    name: "Button Title",
    index: 1,
  },
  price_set: {
    name: "Prices",
    index: 3,
  },
  // defaultPriceMicros: {
  //   name: "Global Price",
  //   alwaysEnabled: true,
  //   index: 13,
  // },
  // defaultPrices: {
  //   name: "Global Prices Dependencies",
  //   defaultValue: [],
  //   index: 14,
  //   type: "priceTable",
  // },
  // defaultPriceAdjustments: {
  //   name: "Global Price Adjustments",
  //   defaultValue: [],
  //   index: 15,
  //   type: "priceTable",
  // },
};

export const AdminModifierStructure = {
  title: {
    name: "Title",
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    defaultValue: "",
    index: 0,
  },
  configurationButtonTitle: {
    name: "Button Title",
    index: 1,
  },
  // defaultPriceMicros: {
  //   name: "Global Price",
  //   type: "number",
  //   alwaysEnabled: true,
  //   subType: "price",
  //   index: 2,
  // },
  // descriptionDefault: {
  // 	name: "Description",
  // 	type: "textarea",
  // 	index: 3,
  // },
  price_set: {
    name: "Default Prices",
    defaultValue: [],
    index: 2,
    type: "modifier-dependency",
    subType: "prices",
  },
  displayDescriptionDefault: {
    name: "Display Description",
    type: "textarea",
    index: 3,
  },
  color: {
    name: "Primary Color",
    type: "color",
    index: 10,
  },
  secondaryColor: {
    name: "Secondary Color",
    type: "color",
    index: 11,
  },
  imageId: {
    name: "Image",
    type: "image",
    index: 20,
  },
  optionValues: {
    type: "optionKeyValues",
    name: "Modified Options",
    index: 21,
  },
};

export const AdditionalAdminModifierStructure = {
  color: {
    name: "Color",
    type: "color",
    index: 10,
  },
  secondaryColor: {
    name: "Secondary Color",
    type: "color",
    index: 11,
  },
  imageId: {
    name: "Image",
    type: "image",
    index: 20,
  },
  optionValues: {
    type: "optionKeyValues",
    name: "Modified Options",
    index: 19,
  },
};

export const OverrideModifierStructure = {
  // blockingModifiers: {
  //   name: "Blocking Modifiers",
  //   type: "modifier-select",
  //   defaultValue: {},
  //   index: 8,
  // },
  dependencies: {
    name: "Visibility Dependencies",
    type: "modifier-dependency",
    subType: "visibility",
    defaultValue: [],
    index: 11,
  },
};
