import {useQuery} from "@tanstack/react-query";
import {getAdminInstantQuoteService} from "./index";

const fetchLocations = async (productId?: string): Promise<any> => {
  if (!productId) throw new Error("Product ID is required");
  const response = getAdminInstantQuoteService().then((service) => {
    return service.getLocations(productId);
  });
  console.log("fetchLocation", response);
  return response;
};

function useLocationsByProductId(productId?: string) {
  return useQuery<any[], Error>(
    ["locations", productId],
    () => fetchLocations(productId),
    {cacheTime: 0}
  );
}

export default useLocationsByProductId;
