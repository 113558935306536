import React from "react";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {ProjectPriceMode} from "@natomas-org/core";
import {B2BProposalSummary} from "./Presentation/ProposalSummary/B2BProposalSummary";
import {DTCProposalSummary} from "./Presentation/ProposalSummary/DTCProposalSummary";
import {IGenericBudgetSummaryProps} from "./generic/GenericBudgetSummary";

export const OverallBudgetSummary = (props: IGenericBudgetSummaryProps) => {
  const {projectPriceMode} = useActiveProject();
  if (projectPriceMode === ProjectPriceMode.CostPlus) {
    return (
      <div style={{padding: "0 2rem"}}>
        <B2BProposalSummary presentationView={false} />
      </div>
    );
  }

  return (
    <div>
      <DTCProposalSummary {...props} />
    </div>
  );
};
