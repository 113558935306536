import React, {useMemo} from "react";
import {IFactoryInventoryStudioEditorModifier} from "./interface";
import {
  StudioEditorModifierButtonContainer,
  StudioEditorModifierButtonInfoContainer,
  StudioEditorModifierButtonWrapper,
  StudioEditorModifierColorSwatchContainer,
  StudioEditorModifierContainer,
  StudioEditorModifierDescription,
} from "./styles";
import {priceTextFromMicros} from "../../../../../../../../../../../studio/_shared/helper";
import {IFactoryInventoryStudioEditorPopupOptions} from "../PopupContent/interface";
import {StudioEditorFieldType} from "../_shared/StudioEditorFieldAddButton";
import FieldHighlight from "../_shared/FieldHighlight";
import ModifierButtonTile from "./ModifierButtonTile";
import {
  SHOW_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY,
  SHOW_MULTI_SECENT_DISPLAY_KEY,
} from "../../../../../../../../../../../studio/_shared/constants";
import {MultiSelectCheckBox} from "../../../../../../../../../../../studio/_shared/components/StudioModifier/buttons/styles";
import StudioEditorFieldConditionalController from "../_shared/StudioEditorFieldButton";
import {StudioEditorMode} from "../../../index";

interface ModifierEditorProps {
  id: string;
  initialValue?: IFactoryInventoryStudioEditorModifier;
  currentValue: IFactoryInventoryStudioEditorModifier;
  setActionEvent?: (
    e: any,
    options?: IFactoryInventoryStudioEditorPopupOptions
  ) => void;
  categoryId?: string;
  modifierGroupId?: string;
  modifierGroupType?: string;
  mode?: StudioEditorMode;
}

const ModifierEditor = (props: ModifierEditorProps) => {
  const {
    id,
    currentValue,
    initialValue,
    setActionEvent,
    categoryId,
    modifierGroupId,
    modifierGroupType,
    mode,
  } = props;

  const priceString = useMemo(() => {
    if (currentValue?.priceInMicros === 0 || !currentValue?.priceInMicros) {
      return "Included";
    } else {
      return priceTextFromMicros(currentValue?.priceInMicros, "accurate");
    }
  }, [currentValue]);

  const button = useMemo(() => {
    if (modifierGroupType === SHOW_COLOR_SWATCHES_DISPLAY_KEY) {
      return (
        <StudioEditorModifierColorSwatchContainer>
          <FieldHighlight
            initialValue={initialValue?.title}
            value={currentValue?.title}
          >
            {currentValue?.title}
          </FieldHighlight>
          <ModifierButtonTile
            isSwatch={true}
            color={currentValue?.color}
            secondaryColor={currentValue?.secondaryColor}
            imageId={currentValue?.imageId}
          />
        </StudioEditorModifierColorSwatchContainer>
      );
    } else if (modifierGroupType === SHOW_MULTI_SECENT_DISPLAY_KEY) {
      return (
        <StudioEditorModifierButtonContainer key={`${id}`}>
          <MultiSelectCheckBox active={true}>
            <svg
              viewBox="0 0 20 20"
              width="16"
              height="16"
              className={"checkedSVG"}
              preserveAspectRatio="xMidYMin"
            >
              <polyline points="5 10.75 8.5 14.25 16 6" />
            </svg>
          </MultiSelectCheckBox>
          <StudioEditorModifierButtonInfoContainer>
            <FieldHighlight
              initialValue={initialValue?.title}
              value={currentValue?.title}
            >
              {currentValue?.title}
            </FieldHighlight>
            <FieldHighlight
              initialValue={initialValue?.priceInMicros}
              value={currentValue?.priceInMicros}
            >
              {priceString}
            </FieldHighlight>
          </StudioEditorModifierButtonInfoContainer>
        </StudioEditorModifierButtonContainer>
      );
    } else if (modifierGroupType === SHOW_FULL_COLOR_SWATCHES_DISPLAY_KEY) {
      return (
        <StudioEditorModifierButtonContainer key={`${id}`}>
          <ModifierButtonTile
            color={currentValue?.color}
            secondaryColor={currentValue?.secondaryColor}
            imageId={currentValue?.imageId}
          />
          <StudioEditorModifierButtonInfoContainer>
            <FieldHighlight
              initialValue={initialValue?.title}
              value={currentValue?.title}
            >
              {currentValue?.title}
            </FieldHighlight>
            <FieldHighlight
              initialValue={initialValue?.priceInMicros}
              value={currentValue?.priceInMicros}
            >
              {priceString}
            </FieldHighlight>
          </StudioEditorModifierButtonInfoContainer>
        </StudioEditorModifierButtonContainer>
      );
    } else {
      return (
        <StudioEditorModifierButtonContainer key={`${id}`}>
          <StudioEditorModifierButtonInfoContainer>
            <FieldHighlight
              initialValue={initialValue?.title}
              value={currentValue?.title}
            >
              {currentValue?.title}
            </FieldHighlight>
            <FieldHighlight
              initialValue={initialValue?.priceInMicros}
              value={currentValue?.priceInMicros}
            >
              {priceString}
            </FieldHighlight>
          </StudioEditorModifierButtonInfoContainer>
        </StudioEditorModifierButtonContainer>
      );
    }
  }, [
    currentValue?.color,
    currentValue?.imageId,
    currentValue?.priceInMicros,
    currentValue?.secondaryColor,
    currentValue?.title,
    id,
    initialValue?.priceInMicros,
    initialValue?.title,
    modifierGroupType,
    priceString,
  ]);

  return (
    <StudioEditorModifierContainer
      showBorder={mode && mode !== StudioEditorMode.EDIT}
    >
      <StudioEditorModifierButtonWrapper>
        {mode && setActionEvent && (
          <StudioEditorFieldConditionalController
            path={{
              categoryId,
              modifierGroupId,
              modifierId: currentValue?.id,
            }}
            mode={mode}
            type={StudioEditorFieldType.MODIFIER}
            setActionEvent={setActionEvent}
          />
        )}
        {button}
      </StudioEditorModifierButtonWrapper>
      {currentValue?.description && (
        <StudioEditorModifierDescription>
          <FieldHighlight
            initialValue={initialValue?.description}
            value={currentValue?.description}
          >
            {currentValue?.description}
          </FieldHighlight>
        </StudioEditorModifierDescription>
      )}
    </StudioEditorModifierContainer>
  );
};

export default ModifierEditor;
