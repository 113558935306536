import {IAdminProductCatalogMatrixFilterOptions} from "../../../slices/types/Store";

export const changeIncludeOption = (
  options: IAdminProductCatalogMatrixFilterOptions | undefined,
  include: IAdminProductCatalogMatrixFilterOptions["include"]
) => {
  return {...options, include: include};
};

export const changeFilterIdsOption = (
  options: IAdminProductCatalogMatrixFilterOptions | undefined,
  filterIds: IAdminProductCatalogMatrixFilterOptions["filterIds"]
) => {
  return {...options, filterIds: filterIds};
};

export const changeFilterByOption = (
  options: IAdminProductCatalogMatrixFilterOptions | undefined,
  filterBy: IAdminProductCatalogMatrixFilterOptions["filterBy"]
) => {
  return {...options, filterBy: filterBy, filterIds: []};
};
