import {Address, IAddressDetails, IAreaDetails} from "@natomas-org/core";
import {storeItemInLocalCache} from "../../cache";
import {
  addressLocalStorageKeyCity,
  addressLocalStorageKeyCountry,
  addressLocalStorageKeyCounty,
  addressLocalStorageKeyFullAddress,
  addressLocalStorageKeyLatitude,
  addressLocalStorageKeyLongitude,
  addressLocalStorageKeyState,
  addressLocalStorageKeyStreet,
  addressLocalStorageKeyStreetNumber,
  addressLocalStorageKeyZip,
} from "../../cache/constants";
import {ILocationFromIP} from "@natomas-org/service";
import {setIPAddress} from "../../slices/AddressSlice";
import {fetchIPSource} from "./ip";
import {Dispatch} from "redux";

export const initialize = (dispatch: Dispatch<any>, skipIPFetch: boolean) => {
  if (!skipIPFetch) {
    fetchIPSource().then((r: ILocationFromIP | null) => {
      if (r) {
        dispatch(setIPAddress(r));
      }
    });
  }
};

export const derivePublicAddress = (
  ipSource: IAddressDetails | null | undefined,
  urlSource: IAddressDetails | null | undefined,
  cacheSource: IAddressDetails | null,
  projectSource: IAddressDetails | null | undefined,
  propertySearchSource: IAddressDetails | null | undefined,
  areaAddress: IAreaDetails | null | undefined,
  options: {
    cacheProjectAddress: boolean;
    enforcePropertyAddress: boolean | undefined;
  }
): IAddressDetails | null => {
  // - URL
  // - propertySearch
  // - areaSearch
  // - cache
  // - projectAddress
  // - ip

  if (propertySearchSource && sourceHasCoordinates(propertySearchSource)) {
    // If a search is populated, save it to store and use it.
    saveSourceToCache(propertySearchSource);
    const checkedSource = getSourceWithPropertyAddressCheck(
      propertySearchSource,
      options.enforcePropertyAddress
    );
    if (checkedSource) {
      return checkedSource;
    }
  }
  if (urlSource && sourceHasCoordinates(urlSource)) {
    // If an url source is populated, save it to store and use it.
    saveSourceToCache(urlSource);
    const checkedSource = getSourceWithPropertyAddressCheck(
      urlSource,
      options.enforcePropertyAddress
    );
    if (checkedSource) {
      return checkedSource;
    }
  }
  if (areaAddress && sourceHasCoordinates(areaAddress?.address)) {
    // If an area search address is populated, save it to store and use it.
    saveSourceToCache(areaAddress?.address);
    const checkedSource = getSourceWithPropertyAddressCheck(
      areaAddress?.address,
      options.enforcePropertyAddress
    );
    if (checkedSource) {
      return checkedSource;
    }
  }
  if (cacheSource && sourceHasCoordinates(cacheSource)) {
    // If a cache source exists, use it.
    const checkedSource = getSourceWithPropertyAddressCheck(
      cacheSource,
      options.enforcePropertyAddress
    );
    if (checkedSource) {
      return checkedSource;
    }
  }
  if (projectSource && sourceHasCoordinates(projectSource)) {
    // If a project exists, use it.
    const checkedSource = getSourceWithPropertyAddressCheck(
      projectSource,
      options.enforcePropertyAddress
    );
    if (checkedSource) {
      return checkedSource;
    }
  }
  if (ipSource && sourceHasCoordinates(ipSource)) {
    // If an IP source is populated, save it to store and use it.
    const checkedSource = getSourceWithPropertyAddressCheck(
      ipSource,
      options.enforcePropertyAddress
    );
    if (checkedSource) {
      return checkedSource;
    }
  }
  // Can not find an address.
  return null;
};

const getSourceWithPropertyAddressCheck = (
  source: IAddressDetails,
  enforcePropertyAddress?: boolean
): IAddressDetails | null => {
  if (enforcePropertyAddress === true) {
    if (Address.isInvalidAddress(source)) {
      return null;
    } else {
      return source;
    }
  }
  return source;
};

export const sourceHasCoordinates = (
  source: IAddressDetails | null
): boolean => {
  return !!(source?.latitude && source?.longitude);
};

const saveSourceToCache = (source: IAddressDetails): void => {
  storeItemInLocalCache(
    addressLocalStorageKeyFullAddress,
    source?.full_address ?? undefined
  );
  storeItemInLocalCache(
    addressLocalStorageKeyStreetNumber,
    source?.street_number ?? undefined
  );
  storeItemInLocalCache(
    addressLocalStorageKeyStreet,
    source?.street ?? undefined
  );
  storeItemInLocalCache(addressLocalStorageKeyCity, source?.city ?? undefined);
  storeItemInLocalCache(
    addressLocalStorageKeyState,
    source?.state ?? undefined
  );
  storeItemInLocalCache(addressLocalStorageKeyZip, source?.zip ?? undefined);
  storeItemInLocalCache(
    addressLocalStorageKeyCounty,
    source?.county ?? undefined
  );
  storeItemInLocalCache(
    addressLocalStorageKeyCountry,
    source?.country ?? undefined
  );
  storeItemInLocalCache(
    addressLocalStorageKeyLatitude,
    source?.latitude ?? undefined
  );
  storeItemInLocalCache(
    addressLocalStorageKeyLongitude,
    source?.longitude ?? undefined
  );
};
