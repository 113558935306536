import React from "react";
import {IProduct, ListingStatusDetails} from "@natomas-org/core";
import {useAllProducts} from "../../../components/_shared/hooks/useProductCatalog/useProducts";
import Row from "./row";
import {useHistory} from "react-router-dom";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderContainer,
  TableRow,
} from "./styles";

const AllProductsTable = () => {
  const {products} = useAllProducts({
    listingStatusFilter: {
      [ListingStatusDetails.LISTED]: true,
      [ListingStatusDetails.UNLISTED]: true,
    },
  });

  const history = useHistory();

  const onRowClick = (productId: string) => {
    history.push(`/admin/product/${productId}/instant-quote`);
  };

  return (
    <Table columnWidths={"100px 1fr 1fr auto"} fitToWidth={false}>
      <TableHeaderContainer>
        <TableRow>
          <TableHeader># Loc(s)</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Factory</TableHeader>
          <TableHeader>Product Id</TableHeader>
        </TableRow>
      </TableHeaderContainer>
      <TableBody>
        {products
          .sort((a, b) => {
            // Compare productGroupId
            const groupComparison = (a.productGroupId ?? "").localeCompare(
              b.productGroupId ?? ""
            );
            if (groupComparison !== 0) {
              return groupComparison;
            }
            // If productGroupId is the same, compare title
            return a.title.localeCompare(b.title);
          })
          .map((product: IProduct, index) => (
            <Row
              key={`product-${index}`}
              product={product}
              index={index}
              onRowClick={onRowClick}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default AllProductsTable;
