import React, {MutableRefObject} from "react";
import {IconProps} from "../../../../_shared";
import {INatDropdownStyleOptions} from "../../constants";
import {useNatPopup} from "../../hooks/useNatPopup/useNatPopup";

export interface NatPopupItem {
  id: string;
  label: string;
  style?: React.CSSProperties;
}

export interface NatPopupItemElement extends NatPopupItem {
  component?: JSX.Element;
  style?: React.CSSProperties;
}

export interface NatPopupItemText extends NatPopupItem {
  callback?: (value: NatPopupItem) => void;
  disabled?: (value: NatPopupItem) => number;
  icon?: IconProps;
  style?: React.CSSProperties;
}

export interface NatContentListProps {
  contentItemArray: (NatPopupItemText | NatPopupItemElement)[];
  show?: boolean;
  handleClose?: () => void;
  style: INatDropdownStyleOptions;
  buttonRef?: MutableRefObject<HTMLDivElement | null>;
  trackingId?: string;
  selectedValue?: NatPopupItem;
}

export const POPUP_ANIMATION_SPEED_MS = 777;

export const NatPopup = (props: NatContentListProps) => {
  const {popup} = useNatPopup(props);
  return popup;
};
