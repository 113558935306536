import {
  Contact,
  Customer,
  IAddressDetails,
  IContactInfo,
  IPersonInfo,
  Person,
  PhoneNumber,
  PhoneNumberTypes,
} from "@natomas-org/core";

export const registrationToCustomer = (
  uid: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  address: IAddressDetails
) => {
  let phoneNumber = PhoneNumber.dataToPhoneNumber(
    PhoneNumberTypes.PRIMARY,
    phone
  );

  let contact: IContactInfo = Contact.dataToContactInfo(
    address,
    phoneNumber,
    email
  );

  let person: IPersonInfo = Person.dataToPerson(
    firstName,
    "",
    lastName,
    contact,
    ""
  );

  return Customer.dataToCustomer(uid, person);
};
