import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../views/SSDDashboardView/styles/globals";
import {
  BLACK,
  DIVIDER_COLOR,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
} from "../../../../_shared/colors";

export const ProjectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: auto;
  gap: 10px;
  max-height: min(500px, 40vh);
  border: 2px solid ${DIVIDER_COLOR};
  padding: 10px;
`;
export const ProjectCardContainer = styled.div<{active: boolean}>`
  width: 100%;
  border-radius: 10px;
  background-color: ${(props) =>
    props.active ? VILLA_APPLE_GREEN_LIGHT_1 : "none"};
  border: ${(props) =>
    props.active
      ? `2px solid ${VILLA_APPLE_GREEN}`
      : `2px solid ${DIVIDER_COLOR}`};
  cursor: pointer;
  &:hover {
    border: 2px solid ${BLACK};
  }
`;

export const ProjectCardImgContainer = styled.div`
  position: relative;
  height: 100px;
  width: 100%;
  overflow: hidden;
`;
export const ProjectCardContentContainer = styled.div`
  padding: 20px;
`;

export const Title = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 18px;
`;
