import React, {useMemo} from "react";
import "react-datasheet/lib/react-datasheet.css";
import {BUDGET_SUMMARY_SECTION_TYPE, ProjectPriceMode} from "@natomas-org/core";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {
  GenericBudgetSummary,
  IGenericBudgetSummaryProps,
} from "../generic/GenericBudgetSummary";

export const SiteBudgetSummary = (props: IGenericBudgetSummaryProps) => {
  const {projectPriceMode} = useActiveProject();
  const type = useMemo(() => {
    let toReturn = BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_DTC;
    // Making the following code unreachable to always display site work the same way for both B2B and DTC
    if (projectPriceMode === ProjectPriceMode.CostPlus) {
      toReturn = BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_COSTPLUS;
    }
    return toReturn;
  }, [projectPriceMode]);
  return <GenericBudgetSummary displayType={type} {...props} />;
};
