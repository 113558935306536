import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../SSDDashboardView/styles/globals";

export const CartItemDesignContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CartItemDesignCategoryHeader = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
export const CartItemDesignCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CartItemDesignCategoryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const CartItemDesignCategoryItem = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;
