import {AuthenticationFormState} from "./interface";

export const validateAuthenticationFormState = async (props: {
  target: AuthenticationFormState;
  hasAuthId?: boolean;
  hasLead?: boolean;
  hasEmail?: boolean;
  hasLeadSegment?: boolean;
}) => {
  console.log(props);
  return getValidState(props);
};

const getValidState = async (props: {
  target: AuthenticationFormState;
  hasAuthId?: boolean;
  hasLead?: boolean;
  hasEmail?: boolean;
  hasLeadSegment?: boolean;
}) => {
  const {target, hasEmail, hasLeadSegment, hasAuthId, hasLead} = props;
  // If the target is logging out, return the target
  if (target === AuthenticationFormState.LOGGING_OUT) {
    return target;
  }
  if (target === AuthenticationFormState.RESET_PASSWORD) {
    return target;
  }

  // If the user has a lead, show complete, or marketing if targeted
  // This handles dismissing the modal if the user has submitted!
  if (hasLead) {
    return target === AuthenticationFormState.MARKETING_LANDING
      ? AuthenticationFormState.MARKETING_LANDING
      : AuthenticationFormState.COMPLETE;
  }

  // If the user does not have a lead, show the targeted form, or login
  if (!hasAuthId) {
    return [
      AuthenticationFormState.CREATE_CONTACT,
      AuthenticationFormState.LOGIN_LANDING,
      AuthenticationFormState.SIGN_UP_LANDING,
    ].includes(target)
      ? target
      : AuthenticationFormState.LOGIN_LANDING;
  }

  // All users must have an email
  if (!hasEmail) {
    throw new Error("Credential must have an email");
  }

  if (!hasLeadSegment) {
    return [
      AuthenticationFormState.CONFIRM_BUSINESS,
      AuthenticationFormState.CONFIRM_CUSTOMER,
      AuthenticationFormState.SELECT_CUSTOMER_TYPE,
    ].includes(target)
      ? target
      : AuthenticationFormState.SELECT_CUSTOMER_TYPE;
  }
  return target;
};
