import {HiddenInput} from "./hiddenInput";
import React from "react";
import {IPardotIntakeFormInput} from "@natomas-org/core";
import {isProd} from "../../_shared/application";

export interface IPardotIntakeFormProps {
  formValues: IPardotIntakeFormInput[];
  pardotURL: string;
  customId?: string;
}

export const PardotIntakeForm = (props: IPardotIntakeFormProps) => {
  const {formValues, pardotURL, customId} = props;

  if (!isProd) {
    return (
      <button
        className="hidden"
        type="button"
        onClick={() => console.log("[pardot-registration-local]", formValues)}
        id={customId ?? "hidden-submit-button"}
      />
    );
  }
  return (
    <>
      <form
        className="hidden"
        action={pardotURL}
        method="POST"
        target={"hiddenFrame"}
      >
        {formValues.map((item) => (
          <HiddenInput
            inputName={item.inputName}
            inputValue={item.inputValue != null ? item.inputValue : "null"}
            inputType={item.inputType}
            key={item.inputName}
          />
        ))}

        <button
          className="hidden"
          type="submit"
          id={customId ?? "hidden-submit-button"}
        />
      </form>
      <iframe
        title={"hiddenFrame"}
        name="hiddenFrame"
        width="0"
        height="0"
        style={{display: "none"}}
      />
    </>
  );
};
