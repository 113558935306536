import React from "react";
import {DocumentsView} from "../../../../../../portal/views/DocusignView";
import {CustomerProjectViewContentContainer} from "../styles";

export const DocumentsManagerTab = () => {
  return (
    <>
      <CustomerProjectViewContentContainer>
        <DocumentsView />
      </CustomerProjectViewContentContainer>
    </>
  );
};
