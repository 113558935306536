import React from "react";
import {
  Address,
  Customer,
  formatPhoneNumber,
  PhoneNumber,
  PhoneNumberFormatType,
  Project,
} from "@natomas-org/core";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
import {useUrl} from "../../../../../_shared/hooks/useUrl";
import {
  CustomerInfoSideBarContainer,
  CustomerInfoSideBarFieldInfo,
  CustomerInfoSideBarFieldLabel,
  CustomerInfoSideBarTitle,
} from "./styles";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";

export const CustomerInfoTab = () => {
  const {pid} = useUrl();
  const {customer} = useCurrentCustomer();
  const {info} = useActiveProject();
  if (!customer) {
    return <>Loading customer...</>;
  }
  const phone = Customer.getPrimaryContactPhoneNumber(customer);

  if (info && pid) {
    return (
      <CustomerInfoSideBarContainer>
        <CustomerInfoSideBarTitle>
          Primary Contact Info
        </CustomerInfoSideBarTitle>
        <div>
          <CustomerInfoSideBarFieldLabel>Name</CustomerInfoSideBarFieldLabel>
          <CustomerInfoSideBarFieldInfo>
            {info ? Project.getClientFullName(info) : "Unknown"}
          </CustomerInfoSideBarFieldInfo>
          <CustomerInfoSideBarFieldLabel>Phone</CustomerInfoSideBarFieldLabel>
          <CustomerInfoSideBarFieldInfo>
            {info
              ? formatPhoneNumber(
                  PhoneNumber.getNumber(Project.getClientPhone(info)),
                  PhoneNumberFormatType.PARENTHESIS_AND_DASHES
                ) ?? "Unknown"
              : "Unknown"}
          </CustomerInfoSideBarFieldInfo>
        </div>
        <div>
          <CustomerInfoSideBarFieldLabel>Email</CustomerInfoSideBarFieldLabel>
          <CustomerInfoSideBarFieldInfo>
            {customer ? Customer.getContactEmail(customer) : "Unknown"}
          </CustomerInfoSideBarFieldInfo>
        </div>
      </CustomerInfoSideBarContainer>
    );
  } else {
    return (
      <CustomerInfoSideBarContainer>
        <CustomerInfoSideBarTitle>Account Information</CustomerInfoSideBarTitle>
        <div>
          <CustomerInfoSideBarFieldLabel>Name</CustomerInfoSideBarFieldLabel>
          <CustomerInfoSideBarFieldInfo>
            {Customer.getFullName(customer)}
          </CustomerInfoSideBarFieldInfo>
        </div>
        <div>
          <CustomerInfoSideBarFieldLabel>
            Contact Email
          </CustomerInfoSideBarFieldLabel>
          <CustomerInfoSideBarFieldInfo>
            {Customer.getContactEmail(customer)}
          </CustomerInfoSideBarFieldInfo>
        </div>
        <div>
          <CustomerInfoSideBarFieldLabel>
            Contact Phone
          </CustomerInfoSideBarFieldLabel>
          <CustomerInfoSideBarFieldInfo>
            {phone
              ? formatPhoneNumber(
                  phone,
                  PhoneNumberFormatType.PARENTHESIS_AND_DASHES
                ) ?? "Unknown"
              : "Unknown"}
          </CustomerInfoSideBarFieldInfo>
        </div>
        <div>
          <CustomerInfoSideBarFieldLabel>
            Contact Address
          </CustomerInfoSideBarFieldLabel>
          <CustomerInfoSideBarFieldInfo>
            {Address.getFullAddress(Customer.getContactAddress(customer))}
          </CustomerInfoSideBarFieldInfo>
        </div>
      </CustomerInfoSideBarContainer>
    );
  }
};
