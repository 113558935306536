import {auth} from "../../database/firebase";
import axios from "axios";

export const getAxiosInstance = async (options?: {skipAuth?: boolean}) => {
  let nexusHost = process.env.REACT_APP_NEXUS_HOST;
  if (!nexusHost) {
    console.log("Nexus Host:", process.env.REACT_APP_NEXUS_HOST);
    throw new Error("No nexus host");
  }
  if (options?.skipAuth) {
    return await createAxiosInstance(nexusHost);
  } else {
    const authToken = await getAuthenticationToken();
    return await createAxiosInstanceWithAuth(nexusHost, authToken);
  }
};
export const getAuthenticationToken = async () => {
  console.time("getAuthenticationToken");
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error("No current user");
  }
  const authToken = await currentUser.getIdToken();
  if (!authToken) {
    throw new Error("No auth token");
  }
  console.timeEnd("getAuthenticationToken");
  return authToken;
};

const createAxiosInstance = async (baseURL: string) => {
  console.time("getAxiosInstance");
  const ax = axios.create({
    baseURL: baseURL,
    headers: {
      "request-source": "villa-app",
    },
  });
  console.timeEnd("getAxiosInstance");
  return ax;
};

const createAxiosInstanceWithAuth = async (
  baseURL: string,
  authToken: string
) => {
  console.time("getAxiosInstance");
  const ax = axios.create({
    baseURL: baseURL,
    headers: {
      "request-source": "villa-app",
      "x-firebase-token": authToken,
    },
  });
  console.timeEnd("getAxiosInstance");
  return ax;
};
