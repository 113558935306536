import {
  SideBarItemContainer,
  SideBarItemContentWrapper,
  SideBarItemLabel,
  SideBarItemWrapper,
} from "./styles";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../../slices/types/Store";
import {NatButton} from "../../button";
import {
  NatDownChevron,
  NatEditIcon,
  NatInvisibleIcon,
  NatPlusIcon,
  NatRightChevron,
  NatVisibleIcon,
} from "../../../icon/icons";
import {IconPosition, StyleOption} from "../../_shared";
import {
  setEditingFeatureId,
  toggleFeatureVisibility,
} from "../../../slices/NatomasMapSlice/NatomasMapSlice";
import {LoadingSpinner} from "../../../../mapping/views/components/Common/LoadingSpinner";

export interface IFeatureMapSidebarItem {
  id: string;
  label: string;
  hasFeature: boolean;
  children?: IFeatureMapSidebarItem[];
}

export const FeatureMapSideBarItem = (props: {
  index: string;
  item: IFeatureMapSidebarItem;
  isMenuExpanded: boolean;
  depth?: number;
  isLast?: boolean;
  isHidden?: boolean;
}) => {
  const {index, item, isMenuExpanded, depth, isLast, isHidden} = props;
  const {id, label, hasFeature, children} = item;
  const [isShowingChildren, setShowChildren] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isVisible = useSelector(
    (state: IStore) => state.natomasMap.visible[item?.id]
  );
  const editingFeatureId = useSelector(
    (state: IStore) => state.natomasMap.editingFeatureId
  );

  const isEditing = useMemo(() => {
    return !!editingFeatureId && editingFeatureId === id;
  }, [editingFeatureId, id]);

  const expandChildren = useCallback(() => {
    setShowChildren((current) => !current);
  }, []);

  const level = depth ?? 0;

  return (
    <SideBarItemWrapper expanded={!!children && isShowingChildren}>
      <SideBarItemContainer
        hidden={isHidden ?? false}
        key={`side-bar-item-${index}`}
        active={isEditing ?? false}
        expanded={isMenuExpanded}
        level={level}
        isLast={isLast ?? false}
      >
        <SideBarItemContentWrapper>
          {!!children && (
            <NatButton
              label={"children"}
              icon={{
                icon: isShowingChildren ? (
                  <NatDownChevron />
                ) : (
                  <NatRightChevron />
                ),
                iconPosition: IconPosition.ONLY,
              }}
              option={StyleOption.ADMIN}
              clickEvent={expandChildren}
            />
          )}
          <SideBarItemLabel
            bold={level === 0}
            isEditing={isEditing}
            canHover={!!children}
            onClick={!!children ? expandChildren : undefined}
          >
            {label}
          </SideBarItemLabel>
        </SideBarItemContentWrapper>
        <SideBarItemContentWrapper hidden={!id || !hasFeature}>
          <NatButton
            label={"editing"}
            icon={{
              icon: <NatEditIcon />,
              iconPosition: IconPosition.ONLY,
            }}
            disabled={!isEditing && !!editingFeatureId}
            option={isEditing ? StyleOption.ADMIN_INVERSE : StyleOption.ADMIN}
            clickEvent={() =>
              dispatch(setEditingFeatureId(isEditing ? undefined : id))
            }
          />
          <NatButton
            label={"visibility"}
            disabled={isEditing}
            icon={{
              icon:
                isEditing || isVisible ? (
                  <NatVisibleIcon />
                ) : (
                  <NatInvisibleIcon />
                ),
              iconPosition: IconPosition.ONLY,
            }}
            option={
              isEditing || isVisible
                ? StyleOption.ADMIN_INVERSE
                : StyleOption.ADMIN
            }
            clickEvent={() => dispatch(toggleFeatureVisibility(id))}
          />
        </SideBarItemContentWrapper>
        <SideBarItemContentWrapper hidden={!id || hasFeature}>
          <NatButton
            label={"creating"}
            disabled={!isEditing && !!editingFeatureId}
            icon={{
              icon: <NatPlusIcon />,
              iconPosition: IconPosition.ONLY,
            }}
            option={isEditing ? StyleOption.ADMIN_INVERSE : StyleOption.ADMIN}
            clickEvent={() =>
              dispatch(setEditingFeatureId(isEditing ? undefined : id))
            }
          />
        </SideBarItemContentWrapper>
        {!id && <LoadingSpinner />}
      </SideBarItemContainer>
      {children?.map((item, i: number) => {
        return (
          <FeatureMapSideBarItem
            item={item}
            key={`${index}-${i}`}
            index={`${index}-${i}`}
            depth={level + 1}
            isMenuExpanded={isMenuExpanded}
            isLast={i === children?.length - 1}
            isHidden={!isShowingChildren}
          />
        );
      })}
    </SideBarItemWrapper>
  );
};
