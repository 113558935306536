import styled from "styled-components";
import {GRAY, SIDEBAR_COLOR, VILLA_BLUE} from "../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const CustomerTableContainer = styled.div`
  height: calc(100% - 49px);
`;

export const CustomerHeaderSearchContent = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  column-gap: 0.5rem;
`;

export const CustomerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  column-gap: 0.5rem;
  background-color: ${SIDEBAR_COLOR};
`;

export const CustomerHeaderTitle = styled.div`
  position: absolute;
  top: 0.4rem;
  right: 6.25rem;
  font-size: 0.875rem;
  white-space: nowrap;
  color: ${VILLA_BLUE};
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const CustomerResultsBlurb = styled.div`
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${GRAY};
`;
