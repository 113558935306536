import React from "react";
import styled from "styled-components";
import {VILLA_HIGHLIGHTER_YELLOW} from "../../colors";

export const HighlightedNote = (props: {label: string; hidden?: boolean}) => {
  return (
    <HighlightedNoteContainer hidden={props.hidden}>
      {props.label}
    </HighlightedNoteContainer>
  );
};

const HighlightedNoteContainer = styled.div`
  background-color: ${VILLA_HIGHLIGHTER_YELLOW};
  width: fit-content;
  margin: 0.5rem 0.5rem;
  padding: 0.25rem 0.25rem;
`;
