import {NatSize, NatSizeType, StyleOption} from "../../../generics/_shared";
import {NatDropdown} from "../../../generics/popup/dropdown/NatDropdown";
import React, {useState} from "react";
import {useMap} from "../../useMap";
import {NavigationPaths} from "../paths";
import {useNavigation} from "../index";
import {useUrl} from "../../useUrl";
import {
  AppSwitcherValues,
  CatalogStudioAppInfo,
  CompletedProjectsAppInfo,
  getAppSwitcherValues,
  ProjectAppInfo,
  PropertyMapAppInfo,
} from "./constants";
import styled from "styled-components";
import {
  CATALOG_ROOT_PATH,
  MAP_PATH,
  STUDIO_PATH,
  SUMMARY_PATH,
} from "../../../../../paths";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {
  GRANITE,
  PRIMARY_LINK_ALT,
  VILLA_APPLE_GREEN_LIGHT_1,
} from "../../../colors";
import {useAddress} from "../../useAddress";
import {useCurrentCustomer} from "../../useCurrentCustomer";
import {CUSTOMER_STATUS, useCustomerStatus} from "../../useCustomerStatus";
import {NatPopupItem} from "../../../generics/popup/dropdown/components/NatPopup/NatPopup";
import {AppSwitcherCSS} from "./styles";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../generics/popup/dropdown/constants";
import {usePage} from "../../usePage";
import useActiveProject from "../../useProject/useActiveProject";

const Container = styled.div<{selected: boolean; isNatMobile: boolean}>`
  display: flex;
  align-items: center;
  color: black;
  background-color: ${(props) =>
    props.selected ? VILLA_APPLE_GREEN_LIGHT_1 : "white"};
  padding: 0.5rem;
  text-align: left;
  width: ${(props) => (props.isNatMobile ? "100%" : "22rem")};
  overflow: hidden;
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.selected ? VILLA_APPLE_GREEN_LIGHT_1 : GRANITE + "20"};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  font-size: 2rem;
  padding: 0 1rem;
  margin: 0.5rem;
  color: black;
`;

const InfoWrapper = styled.div`
  margin-right: 1.5rem;
`;

const InfoTitle = styled.div<{selected: boolean}>`
  font-size: 1rem;
  font-family: ${(props) =>
    props.selected ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  color: ${(props) => (props.selected ? PRIMARY_LINK_ALT : "black")};
`;

const InfoSubtitle = styled.div`
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const AppSwitcher = (props: {style?: React.CSSProperties}) => {
  const {parcelView} = useMap();
  const {customer} = useCurrentCustomer();
  const {isNatMobile} = usePage();
  const {info} = useActiveProject();
  const {customerStatus, isCustomerPreDeposit} = useCustomerStatus();
  const {style} = props;
  const {to} = useNavigation();
  const {getURLPath} = useUrl();
  const {catalogAddress: address} = useAddress();
  let appSwitcherItems = getAppSwitcherValues(
    !!customer,
    isCustomerPreDeposit,
    info
  );
  const [show, setShow] = useState(false);
  let currentAppType: AppSwitcherValues = AppSwitcherValues.UNKNOWN;
  const urlPath = getURLPath();
  if (
    (!!customer &&
      !isCustomerPreDeposit &&
      urlPath.includes(CATALOG_ROOT_PATH)) ||
    (!!customer && urlPath.includes(SUMMARY_PATH)) ||
    (!!customer && !isCustomerPreDeposit && urlPath.includes(STUDIO_PATH))
  ) {
    currentAppType = ProjectAppInfo.type;
  } else if (
    urlPath.includes(CATALOG_ROOT_PATH) ||
    urlPath.includes(STUDIO_PATH)
  ) {
    currentAppType = CatalogStudioAppInfo.type;
  } else if (urlPath.includes(MAP_PATH)) {
    if (parcelView) {
      currentAppType = PropertyMapAppInfo.type;
    } else {
      currentAppType = CompletedProjectsAppInfo.type;
    }
  }

  const dropDownOptions: NatPopupItem[] = appSwitcherItems.map((info) => {
    const Icon = info.icon;
    return {
      label: info.title,
      id: info.type,
      component: (
        <Container
          isNatMobile={isNatMobile}
          selected={currentAppType === info.type}
          onClick={() => {
            switch (info.type) {
              case AppSwitcherValues.COMPLETED_PROJECTS:
                !!to && to(NavigationPaths.COMPLETED_PROJECTS_MAP, address);
                break;
              case AppSwitcherValues.PROPERTY_MAP:
                !!to && to(NavigationPaths.PROPERTY_MAP);
                break;
              case AppSwitcherValues.CATALOG:
                !!to && to(NavigationPaths.PUBLIC_CATALOG);
                break;
              case AppSwitcherValues.PROJECT:
                if (
                  customerStatus.depositPaidStatus !== CUSTOMER_STATUS.COMPLETED
                ) {
                  !!to && to(NavigationPaths.SPLASH_SCREEN);
                } else {
                  !!to && to(NavigationPaths.PORTAL);
                }
                break;
              default:
                break;
            }
            setShow(false);
          }}
        >
          <IconWrapper>
            <div>
              <Icon />
            </div>
          </IconWrapper>
          <InfoWrapper>
            <InfoTitle selected={currentAppType === info.type}>
              {info.title}
            </InfoTitle>
            <InfoSubtitle>{info.description}</InfoSubtitle>
          </InfoWrapper>
        </Container>
      ),
    };
  });
  const additionalOptions = isNatMobile
    ? [NatPopupStyleOptions.CENTER_ALIGNED]
    : [];
  const dropdown = (
    <NatDropdown
      contentItemArray={dropDownOptions}
      trackingId={"app-switcher-dropdown"}
      selectedValue={dropDownOptions?.find((item) => {
        return item.id === currentAppType;
      })}
      toggleButtonOrLabel={
        dropDownOptions?.find((item) => {
          return item.id === currentAppType;
        })?.label
      }
      style={{
        ...NatDropdownStyles[DropdownStyles.SELECTION_LIST][NatSizeType.NORMAL],
        TYPE: NatPopupType.HIDE,
        popupCSS: {
          ...style,
          ...AppSwitcherCSS,
          zIndex: 4999997,
        },
        dropdownButtonCSS: {
          width: isNatMobile ? "100%" : "max-content",
          maxWidth: isNatMobile ? "10rem" : undefined,
          height: isNatMobile ? "100%" : undefined,
          fontFamily: FONT_FAMILY_MEDIUM,
          borderRadius: "0.5rem",
          fontSize: NatSize.SMALL,
          color: PRIMARY_LINK_ALT,
          paddingRight: "0.25rem",
          paddingLeft: "0.25rem",
        },
        POPUP_ITEM_TOP_BOTTOM_PADDING: undefined,
        POPUP_ITEM_SIDE_PADDING: undefined,
        DROPDOWN_BUTTON_SIZE: NatSize.SMALL,
        ADDITIONAL_STYLE_OPTIONS: [
          // NatPopupStyleOptions.BOTTOM_SHEET_MOBILE,
          NatPopupStyleOptions.DISABLE_BOLD_SELECTED,
          NatPopupStyleOptions.CLOSE_ON_SELECT,
          NatPopupStyleOptions.NO_PADDING,
          NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
          ...additionalOptions,
        ],
        DROPDOWN_BUTTON_STYLE: StyleOption.APP_SWITCHER,
        DROPDOWN_CHEVRON_COLOR: PRIMARY_LINK_ALT,
      }}
      show={show}
      setShow={setShow}
      handleClose={() => {
        setShow(false);
      }}
    />
  );
  // if (isNatMobile) {
  //   return <div style={{height: "3rem", padding: "0.5rem 0"}}>{dropdown}</div>;
  // }
  return dropdown;
};

export * from "./constants";
