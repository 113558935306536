import {Utilities} from "../../index";
import {
  getFactoryLineVersionModifierRef,
  getFactoryLineVersionProductCustomModifierRef,
} from "../refs";
import {store} from "../../../../store";
import {editModifier} from "../../../../components/_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {
  BaseModifierStructure,
  ProductModifierStructure,
} from "../../../../components/admin/_models/modifier";

export const saveModifierInformation = (
  factoryLineId: string,
  versionId: string,
  modifierId: string,
  payload: any,
  productId?: string
) => {
  const ref = getFactoryLineVersionModifierRef(
    factoryLineId,
    versionId,
    modifierId
  );

  const baseDocument = filterModifierForDestination(payload, "base");
  // @ts-ignore
  baseDocument.prettyDescription = null;

  const info = {...baseDocument, visible: payload?.visible, id: ref?.id};

  store.dispatch(editModifier(info));

  const promises = [];

  promises.push(Utilities.updateOrCreateDocumentInDB(ref, info));

  if (productId != null) {
    const productDocument = filterModifierForDestination(payload, "product");
    promises.push(
      Utilities.updateOrCreateDocumentInDB(
        getFactoryLineVersionProductCustomModifierRef(
          factoryLineId,
          versionId,
          productId,
          modifierId
        ),
        productDocument
      )
    );
  }

  return Promise.all(promises).catch((error) => {
    console.log(error);
  });
};

export const archiveModifier = (
  factoryLineId: string,
  versionId: string,
  modifierId: string,
  productId?: string
) => {
  return saveModifierInformation(
    factoryLineId,
    versionId,
    modifierId,
    {visible: false},
    productId
  );
};

export const undoArchiveModifier = (
  factoryLineId: string,
  versionId: string,
  modifierId: string,
  productId?: string
) => {
  return saveModifierInformation(
    factoryLineId,
    versionId,
    modifierId,
    {visible: true},
    productId
  );
};

export const bulkUpdateProductGroupModifiers = (
  factoryLineId: string,
  versionId: string,
  modifierUpdateBreakdown: any
) => {
  const {productGroup, ...productBreakdown} = modifierUpdateBreakdown;

  let batch = Utilities.batchUpdate();
  let counter = 1;
  if (productGroup != null && Object.keys(productGroup)?.length > 0) {
    Object.keys(productGroup).forEach((modifierId) => {
      const updatePayload = productGroup[modifierId];
      const ref = getFactoryLineVersionModifierRef(
        factoryLineId,
        versionId,
        modifierId
      );
      if (ref) {
        batch.set(ref, updatePayload, {merge: true});
        counter++;
      }
      if (counter >= 500) {
        counter = 1;
        batch.commit();
        batch = Utilities.batchUpdate();
      }
    });
  }

  if (productBreakdown != null && Object.keys(productBreakdown)?.length > 0) {
    Object.keys(productBreakdown).forEach((productId) => {
      const productModifiers = productBreakdown[productId];
      Object.keys(productModifiers).forEach((modifierId) => {
        const updatePayload = productModifiers[modifierId];
        const ref = getFactoryLineVersionProductCustomModifierRef(
          factoryLineId,
          versionId,
          productId,
          modifierId
        );
        if (ref) {
          batch.set(ref, updatePayload, {
            merge: true,
          });
          counter++;
        }
        if (counter >= 500) {
          counter = 1;
          batch.commit();
          batch = Utilities.batchUpdate();
        }
      });
    });
  }

  if (counter > 1) {
    return batch.commit();
  }
  return null;
};

const filterModifierForDestination = (
  payload: any,
  destination: "base" | "product"
) => {
  const filteringObject =
    destination === "base" ? BaseModifierStructure : ProductModifierStructure;

  return Object.keys(payload)
    .filter((key) => filteringObject.hasOwnProperty(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: payload[key],
      };
    }, {});
};
