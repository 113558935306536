import React, {useMemo} from "react";
import {IModifierPricingReview} from "./interface";
import {convertToCSV} from "./csv";
import {CSVLink} from "react-csv";
import {useSelectedFactoryLine} from "../../../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";

interface ModifierPricingReviewCsvProps {
  reviews: IModifierPricingReview[];
}

const ModifierPricingReviewCsv = (props: ModifierPricingReviewCsvProps) => {
  const {details} = useSelectedFactoryLine();
  const {reviews} = props;
  const csvData = useMemo(() => {
    return convertToCSV(reviews);
  }, [reviews]);
  const filename: string = useMemo(() => {
    const date = new Date();
    const template = `MPMS_${
      details?.internal_title ?? details?.title ?? "Unknown Factory"
    }_v${details?.version}_${date.getFullYear()}_${
      date.getMonth() + 1
    }_${date.getDate()}`;
    // Replace all spaces with underscores before returning
    return template.replace(/ /g, "_");
  }, [details]);
  return (
    <>
      <CSVLink
        data={csvData}
        headers={[
          "Modifier Id",
          "Modifier Title",
          "Modifier Internal Title",
          "Product Id",
          "Product Title",
          "Product Internal Title",
          "Price (Cents)",
          "Cost (Cents)",
          "Condition",
          "Type",
          "Modifier Dependency Ids",
          "Modifier Dependency Titles",
        ]}
        target={"_blank"}
        enclosingCharacter={""}
        filename={`${filename}.csv`}
      >
        Download CSV Snapshot
      </CSVLink>
    </>
  );
};

export default ModifierPricingReviewCsv;
