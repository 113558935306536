import "firebase/auth";
import {imageDataLoaded} from "../../../components/_shared/slices/GlobalSlice";
import {store} from "../../../store";
import {collection} from "../utilities";

const imagesFetched = {};
const imagesRef = () => {
  return collection("images");
};

export async function fetchImage(imageId, forceFetch) {
  const shouldPreventFetch = imagesFetched[imageId] === true;
  if (shouldPreventFetch && forceFetch !== true) {
    return;
  }
  imagesFetched[imageId] = true;
  const cachedImage = store.getState().global.images[imageId];
  if (cachedImage && !forceFetch) {
    return cachedImage;
  }

  if (imageId != null && imageId.length > 0) {
    return imagesRef()
      .doc(imageId)
      .get()
      .then((snapshot) => {
        const image = snapshot.data();
        if (image != null) {
          image.id = snapshot.id;
          store.dispatch(imageDataLoaded(image));
        }
        return image;
      });
  }
}
