import React, {useContext, useMemo} from "react";
import styled from "styled-components";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {HiChevronDoubleDown} from "react-icons/hi";
import {NatLink} from "../../../../_shared/generics/button";
import {NatSize} from "../../../../_shared/generics/_shared";
import {
  DTC_PORTAL_NEXT_STEPS_ID,
  DTC_PORTAL_TIMELINE_ID,
  PORTAL_B2B_CLOSING_SLIDE,
  PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS,
  PORTAL_B2B_TIMELINE,
  PORTAL_BUDGET_ID,
  PORTAL_BUDGET_OVERVIEW_ID,
  PORTAL_GREETING_ID,
  PORTAL_SITE_MAP_ID,
  usePresentationSections,
} from "../../../views/HCPDashboardLayout/HCPTitleNav/navigation_ids";
import {
  EMBEDDED_PROPOSAL,
  PROPOSAL_GENERATE_PDF,
} from "../../../views/HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {HCPComponentHR} from "../../../views/FPDashboardLayout/styles";
import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {FULL_SCREEN_WIDTH_REM} from "../../../views/HCPDashboardLayout/HCPUnitView/DesignSelections/constants";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";

export const Bottom = styled.div<{}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface IFullScreenPresentationView {
  children: any;
  headerTitle: string;
  width: any;
  sectionId: string;
  index: number;
  scrollToSection: (sectionId: string) => void;
  displayForPrint: boolean;
  hideLineSeparator?: boolean;
  background?: string;
  hideWhenGeneratingPDF?: boolean;
  formatWithPageSystem?: boolean;
}

export const FullScreenPresentationView = (
  props: IFullScreenPresentationView
) => {
  const {hideWhenGeneratingPDF} = props;
  const {
    isCartEnabled,
    isDevelopmentByVilla,
    mergedCustomerProjectInfos,
    site,
  } = useActiveProject();
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  const embeddedProposal = useContext(EMBEDDED_PROPOSAL);
  const fullWidth = useDynamicValue(fullColumnWidths);
  const embeddedWidth = fullWidth / 2;
  const contextWidth = embeddedProposal ? embeddedWidth : fullWidth;

  const mainStyling = useMemo(() => {
    return {
      background: props.background,
      width: isGeneratingPDF ? `${FULL_SCREEN_WIDTH_REM}rem` : "100%",
      maxWidth: isGeneratingPDF ? `${FULL_SCREEN_WIDTH_REM}rem` : "100%",
      height: "fit-content",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      paddingBottom:
        props.sectionId === PORTAL_BUDGET_OVERVIEW_ID ||
        props.sectionId === PORTAL_B2B_CLOSING_SLIDE ||
        props.sectionId === DTC_PORTAL_NEXT_STEPS_ID ||
        props.sectionId === PORTAL_SITE_MAP_ID
          ? "0"
          : "2rem",
      marginBottom:
        props.sectionId === PORTAL_BUDGET_OVERVIEW_ID ||
        props.sectionId === PORTAL_B2B_CLOSING_SLIDE ||
        props.sectionId === DTC_PORTAL_NEXT_STEPS_ID ||
        props.sectionId === PORTAL_SITE_MAP_ID
          ? "0"
          : "3rem",
      pageBreakBefore:
        props.sectionId === PORTAL_B2B_TIMELINE ||
        props.sectionId === PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS ||
        props.sectionId === PORTAL_B2B_CLOSING_SLIDE ||
        props.sectionId === DTC_PORTAL_TIMELINE_ID ||
        props.sectionId === DTC_PORTAL_NEXT_STEPS_ID ||
        (props.sectionId === PORTAL_SITE_MAP_ID && !isDevelopmentByVilla)
          ? "always"
          : "avoid",
      breakBefore:
        props.sectionId === PORTAL_B2B_TIMELINE ||
        props.sectionId === PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS ||
        props.sectionId === PORTAL_B2B_CLOSING_SLIDE ||
        props.sectionId === DTC_PORTAL_TIMELINE_ID ||
        props.sectionId === DTC_PORTAL_NEXT_STEPS_ID ||
        (props.sectionId === PORTAL_SITE_MAP_ID && !isDevelopmentByVilla)
          ? "always"
          : "avoid",
      pageBreakAfter:
        props.sectionId === PORTAL_GREETING_ID && false ? "always" : undefined,
      breakAfter:
        props.sectionId === PORTAL_GREETING_ID && false ? "always" : undefined,
      alignItems: "flex-start",
      justifyContent: "center",
    } as React.CSSProperties;
  }, [
    isDevelopmentByVilla,
    isGeneratingPDF,
    props.background,
    props.sectionId,
  ]);
  const sections = usePresentationSections();
  const PRESENTATION_SECTIONS = useMemo(() => {
    let sectionsToReturn = sections;
    if (isCartEnabled) {
      sectionsToReturn = sections.filter((section) => {
        if (
          !mergedCustomerProjectInfos?.metadata?.qualificationsAndAssumptions
        ) {
          return section.value !== PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS;
        } else {
          return true;
        }
      });
    }
    return sectionsToReturn.filter((section) => {
      return !(!site?.visible && section.value === PORTAL_BUDGET_ID);
    });
  }, [
    isCartEnabled,
    mergedCustomerProjectInfos?.metadata?.qualificationsAndAssumptions,
    sections,
    site?.visible,
  ]);
  if (hideWhenGeneratingPDF && isGeneratingPDF) {
    return null;
  }
  let hideTitle: boolean = !props.headerTitle;
  if (
    isGeneratingPDF &&
    [PORTAL_SITE_MAP_ID, PORTAL_BUDGET_ID].includes(props.sectionId)
  ) {
    hideTitle = true;
  }
  let mainContent = (
    <PageContainer
      overridePadding={isGeneratingPDF || embeddedProposal}
      overflow={embeddedProposal ? "hidden" : undefined}
    >
      <div id={props.sectionId} />
      <HCPComponentHR hidden={isGeneratingPDF} />
      <PageElement size={contextWidth} height={"auto"}>
        <div
          hidden={hideTitle}
          style={{
            width: "100%",
            textAlign: "center",
            margin: isGeneratingPDF
              ? undefined
              : embeddedProposal
              ? ""
              : "3rem 0 2rem 0",
          }}
        >
          <NatLabel label={props.headerTitle} type={NatLabelType.H2} />
        </div>
        {props.children}
      </PageElement>
    </PageContainer>
  );
  if (props.formatWithPageSystem === false || isGeneratingPDF) {
    mainContent = (
      <div
        style={{
          width: isGeneratingPDF ? `${FULL_SCREEN_WIDTH_REM}rem` : "100%",
          height: "100%",
          pageBreakBefore: "avoid",
          breakBefore: "avoid",
        }}
      >
        <div id={props.sectionId} />
        <div
          hidden={hideTitle}
          style={{textAlign: "center", margin: "3rem 0 2rem 0"}}
        >
          <NatLabel label={props.headerTitle} type={NatLabelType.H2} />
        </div>
        {props.children}
      </div>
    );
  }

  return (
    <div style={mainStyling}>
      {mainContent}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          textAlign: "center",
        }}
        hidden={props.index !== 0}
      >
        <NatLink
          hidden={props.index === PRESENTATION_SECTIONS.length - 1}
          icon={<HiChevronDoubleDown />}
          label={""}
          size={NatSize.XLARGE}
          clickEvent={() => {
            if (props.index < PRESENTATION_SECTIONS.length - 1) {
              const section = PRESENTATION_SECTIONS[props.index + 1];
              props.scrollToSection(section.value);
            }
          }}
        />
      </div>
    </div>
  );
};
