import {IImageDetails, ImageCategory} from "@natomas-org/core";
import {SmartImage} from "../../../generics/image/SmartImage";
import React from "react";

export const CarouselSlide = (props: {
  image: IImageDetails;
  showSelectedImage?: (id: string) => void;
  hideLoading?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <div
      style={{
        overflow: "clip",
        position: "relative",
        width: "100%",
        height: "100%",
        ...props.style,
      }}
      onClick={
        !!props.showSelectedImage
          ? () => {
              props.showSelectedImage &&
                props.showSelectedImage(props.image?.imageId);
            }
          : undefined
      }
    >
      <SmartImage
        style={{
          objectFit: props.image.categories?.includes(ImageCategory.FLOOR_PLAN)
            ? "contain"
            : undefined,
        }}
        key={`smart-image-${props.image?.imageId}`}
        hideLoading={props.hideLoading}
        imageId={props.image?.imageId}
        onFullscreenClick={
          !!props.showSelectedImage
            ? () => {
                props.showSelectedImage &&
                  props.showSelectedImage(props.image?.imageId);
              }
            : undefined
        }
      />
    </div>
  );
};
