import React, {useState} from "react";
import {RowStringInput} from "../../../../factory-info-set/InfoSetTable/components/EditableCell/CellTypes/RowStringInput";
import {
  ACTIONS_KEY,
  COMPLETED_PROJECT_ADDRESS_KEY,
  COMPLETED_PROJECT_CODE_KEY,
  COMPLETED_PROJECT_FEATURED_KEY,
  COMPLETED_PROJECT_IMAGES_KEY,
  COMPLETED_PROJECT_PUBLISHED_KEY,
  COMPLETED_PROJECT_TITLE_KEY,
} from "../../constants";
import _ from "lodash";
import {NatLink} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {PublicProjectInfo} from "@natomas-org/service";
import {EditableCellLinkStyle, NonEditableCellDiv} from "./styles";
import {FeaturedStatus, PublishedStatus} from "../../CompletedProjectsEditor";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../../_shared/generics/popup/dropdown/constants";
import {syncPublicProject} from "../../../../../api/project";
import {openURLInNewTab} from "../../../../_shared/navigation";
import {IPublicProjectInfo} from "@natomas-org/core";
import {NatPopupItemText} from "../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {BASE_URL} from "../../../../_shared/application";

export const EditableCompletedProjectsCell = React.memo((props: any) => {
  const {
    value,
    row,
    column,
    updateCellValue,
    setShowImageManagerModal,
    cellEditing,
    setShowProjectPreviewModal,
  } = props;
  const {id: columnKey} = column;
  const {original: rowValues} = row;
  const projectInfo = rowValues as IPublicProjectInfo | null;
  const [cellValue, setCellValue] = useState(value);
  const onChange = (value: any) => {
    setCellValue(value);
  };

  const onCommitChange = () => {
    if (!_.isEqual(cellValue, value)) {
      updateCellValue(cellEditing?.code, columnKey, cellValue);
      setCellValue(cellValue);
    }
  };
  if (columnKey === COMPLETED_PROJECT_PUBLISHED_KEY) {
    if (!projectInfo) {
      return <NonEditableCellDiv>Project not completed</NonEditableCellDiv>;
    }
    return (
      <input
        type={"checkbox"}
        defaultChecked={cellValue === PublishedStatus.Published}
        disabled={projectInfo?.code !== cellEditing?.code}
        onChange={(e) => {
          updateCellValue(cellEditing?.code, columnKey, e?.target?.checked);
        }}
      />
    );
  }
  if (columnKey === COMPLETED_PROJECT_FEATURED_KEY) {
    if (!projectInfo) {
      return <NonEditableCellDiv>Project not completed</NonEditableCellDiv>;
    } else if (
      !!PublicProjectInfo.getHeroImageId(projectInfo) &&
      !!PublicProjectInfo.getProjectTitle(projectInfo)
    ) {
      return (
        <input
          type={"checkbox"}
          defaultChecked={cellValue === FeaturedStatus.Featured}
          disabled={projectInfo?.code !== cellEditing?.code}
          onChange={(e) => {
            updateCellValue(cellEditing?.code, columnKey, e?.target?.checked);
          }}
        />
      );
    }
    return <NonEditableCellDiv>Insufficient Data</NonEditableCellDiv>;
  }
  if (
    columnKey === COMPLETED_PROJECT_TITLE_KEY &&
    projectInfo?.code === cellEditing?.code
  ) {
    return (
      <RowStringInput
        placeholder={`Project title`}
        isValidChange={() => true}
        onChange={(e: {target: {value: any}}) => {
          onChange(e.target.value);
        }}
        onCommitChange={onCommitChange}
        originalValue={rowValues[columnKey]}
        otherRows={[]}
        value={cellValue}
        type={"textarea"}
      />
    );
  }
  if (
    columnKey === COMPLETED_PROJECT_CODE_KEY &&
    projectInfo &&
    PublicProjectInfo.hasEnrichedData(projectInfo)
  ) {
    return (
      <NatLink
        option={StyleOption.PRIMARY_LINK}
        size={NatSize.SMALL}
        label={value}
        clickEvent={() => {
          setShowProjectPreviewModal(true);
        }}
        style={EditableCellLinkStyle}
      />
    );
  }
  if (columnKey === COMPLETED_PROJECT_IMAGES_KEY) {
    let label = "0 images";
    if ((projectInfo?.imageIds?.length ?? 0) > 0) {
      label = `${projectInfo?.imageIds?.length} images`;
    }
    return (
      <NatLink
        option={StyleOption.PRIMARY_LINK}
        size={NatSize.SMALL}
        label={label}
        clickEvent={() => {
          setShowImageManagerModal(true);
        }}
        style={EditableCellLinkStyle}
      />
    );
  }
  if (columnKey === ACTIONS_KEY) {
    const actions: NatPopupItemText[] = [
      {
        id: "fetch",
        label: "Fetch from SmartSheet",
        callback: () => {
          return syncPublicProject({
            public_project_id: row.original.id,
          });
        },
      },
    ];
    const smartSheetUrl = projectInfo?.ss_root_folder_url;

    if (smartSheetUrl) {
      actions.push({
        id: "open-smartsheet",
        label: "Open in SmartSheet",
        callback: () => {
          return openURLInNewTab(smartSheetUrl);
        },
      });
    }

    const appId = projectInfo?.app_id;
    if (appId) {
      actions.push({
        id: "open-app",
        label: "Open in App",
        callback: () => {
          return openURLInNewTab(BASE_URL + `/admin/customer?pid=${appId}`);
        },
      });
    }

    return (
      <>
        <NatDropdown
          toggleButtonOrLabel={"..."}
          style={{
            TYPE: NatPopupType.HIDE,
            ...NatDropdownStyles[DropdownStyles.PRIMARY],
            ADDITIONAL_STYLE_OPTIONS: [NatPopupStyleOptions.CLOSE_ON_SELECT],
          }}
          contentItemArray={actions}
        />
      </>
    );
  }
  if (
    columnKey === COMPLETED_PROJECT_CODE_KEY ||
    columnKey === COMPLETED_PROJECT_TITLE_KEY ||
    columnKey === COMPLETED_PROJECT_ADDRESS_KEY
  ) {
    return (
      <NonEditableCellDiv
        style={{
          minWidth:
            columnKey === COMPLETED_PROJECT_TITLE_KEY ||
            columnKey === COMPLETED_PROJECT_ADDRESS_KEY
              ? "150px"
              : undefined,
        }}
      >
        {value}
      </NonEditableCellDiv>
    );
  }
  return <NonEditableCellDiv>{value}</NonEditableCellDiv>;
});
