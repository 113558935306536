import {
  AvatarDropdownDotContainer,
  AvatarDropdownItemContainer,
} from "./styles";
import {openURLInNewTab} from "../../../navigation";
import {
  FaFolderOpen,
  FaUserCog,
  FaUserFriends,
  FaUserShield,
} from "react-icons/fa";
import {getStore} from "../../../../../store";
import {BsArrowBarLeft, BsArrowBarRight, BsGlobe} from "react-icons/bs";
import {CgProfile} from "react-icons/cg";
import {RiBuilding3Line} from "react-icons/ri";
import {MdDesignServices} from "react-icons/md";
import {IoCallOutline} from "react-icons/io5";
import {SITE_WORK_PHRASE, VILLA_MAP} from "../../../constants/labels";
import {IoMdSwap} from "react-icons/io";
import React from "react";
import {
  DESIGN_PAGE_ID,
  HOME_PAGE_ID,
  INSTANT_ESTIMATE_PAGE_ID,
  PROFILE_PAGE_ID,
} from "../../../../portal/_shared/navigation/constants";
import {CUSTOMER_STATUS, ICustomerStatus} from "../../useCustomerStatus";
import {
  toContactUs,
  toDashboard,
  toHomePost,
  toInstantQuote,
  toMainSite,
  toProfile,
  toProposalPost,
  toSiteWorkPost,
} from "./navigation";
import {AvatarDropdownDotContainerComponent} from "./components";
import {formNewURL} from "../../../navigation/_helpers";
import {
  CONFIGURATION_ID_URL_KEY,
  PROJECT_ID_URL_KEY,
  USER_ID_URL_KEY,
} from "../../../../../database/firebase/configuration/configuration";
import {NavigationPaths} from "../paths";
import {ADMIN_CUSTOMER_PATH} from "../../../../../paths";
import Marquee from "react-fast-marquee";
import {Address, IAddressDetails} from "@natomas-org/core";
import {logOut} from "../../../../../database/firebase";
import {NatMapIcon, NatProjectIcon} from "../../../icon/icons";
import {toSplashScreen} from "../navigation";

const AVATAR_ADMIN_CUSTOMER_ID = "admin-customer";
const AVATAR_ADMIN_CUSTOMER_PROJECT_ID = "admin-project";
const AVATAR_ADMIN_DESIGN_STUDIO = "admin-design";
const AVATAR_ADMIN_ID = "admin";
const AVATAR_CATALOG_ID = "products";
const AVATAR_CATALOG_ID_ALT = "products-alt";
const AVATAR_SWITCH_PROJECTS = "switch-projects";
const AVATAR_DASHBOARD_ID = HOME_PAGE_ID;
const AVATAR_DASHBOARD_ID_STEP = HOME_PAGE_ID + "-alt";
const AVATAR_DESIGN_STUDIO = DESIGN_PAGE_ID;
const AVATAR_FAQS = "faqs";
const AVATAR_INSTANT_QUOTE_ID = INSTANT_ESTIMATE_PAGE_ID;
const AVATAR_MY_INFO = PROFILE_PAGE_ID;
const AVATAR_POST_DESIGN_STUDIO = "design-post";
const AVATAR_POST_HOME_ID = "my-home";
const AVATAR_POST_PROPOSAL_ID = "proposal-post";
const AVATAR_POST_SITE_PLAN_ID = "site-plan";
const AVATAR_REFER = "refer";
const AVATAR_SIGN_IN_ID = "signin";
const AVATAR_SIGN_OUT_ID = "signout";
const AVATAR_SIGN_UP_ID = "signup";
const AVATAR_WEBSITE_ID = "villa-web";
const AVATAR_SCHEDULE_CALL_ID = "schedule-call";
const AVATAR_BUILD_ID = "build";

export interface IDropdownList {
  [key: string]: {
    label: any;
    value: string;
    action: () => void;
    status?: string;
  };
}

export const getAllDropdownTodoItems = (
  status: any,
  customerStatus: ICustomerStatus,
  to: any,
  projectAddress: IAddressDetails | null | undefined,
  pid: string
): IDropdownList => {
  const {
    depositPaidStatus,
    productSelectedStatus,
    instantEstimateStatus,
    unlockQuoteStatus,
    designProductStatus,
  } = customerStatus;
  const designReadyStatus = status.designReady
    ? CUSTOMER_STATUS.COMPLETED
    : CUSTOMER_STATUS.IN_PROGRESS;
  const sitePlanReadyStatus = status.sitePlanReady
    ? CUSTOMER_STATUS.COMPLETED
    : status.designReady
    ? CUSTOMER_STATUS.IN_PROGRESS
    : CUSTOMER_STATUS.DISABLED;
  const proposalReadyStatus = status.proposalReady
    ? CUSTOMER_STATUS.COMPLETED
    : CUSTOMER_STATUS.DISABLED;
  return {
    [AVATAR_DESIGN_STUDIO]: {
      label: AvatarDropdownDotContainerComponent(
        designProductStatus,
        " Select upgrades"
      ),
      status: designProductStatus,
      value: AVATAR_DESIGN_STUDIO,
      action: () => {
        const state = getStore().getState();
        const p = state?.configuration?.fetchedConfiguration?.product;
        if (p) {
          to(NavigationPaths.STUDIO, {
            productId: p?.id,
            productGroupId: p?.productGroupId,
          });
        } else {
          to(NavigationPaths.STUDIO);
        }
      },
    },
    [AVATAR_ADMIN_ID]: {
      label: (
        <AvatarDropdownDotContainer>
          <FaUserShield />
          <div>Admin</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_ADMIN_ID,
      action: () => to(NavigationPaths.ADMIN),
    },
    [AVATAR_ADMIN_CUSTOMER_ID]: {
      label: (
        <AvatarDropdownDotContainer>
          <FaUserCog />
          <div>Admin - Customer</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_ADMIN_CUSTOMER_ID,
      action: () => {
        const state = getStore().getState();
        const userId = state.activity?.activeCustomerId;
        const newURL = formNewURL({
          pathName: ADMIN_CUSTOMER_PATH,
          params: [
            {key: USER_ID_URL_KEY, value: userId},
            {key: CONFIGURATION_ID_URL_KEY, value: null},
          ],
        });
        openURLInNewTab(newURL);
        // toAdminCustomerManager(userId);
      },
    },
    [AVATAR_ADMIN_CUSTOMER_PROJECT_ID]: {
      label: (
        <AvatarDropdownDotContainer>
          <FaFolderOpen />
          <div>Admin - Project</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_ADMIN_CUSTOMER_PROJECT_ID,
      action: () => {
        const state = getStore().getState();
        const userId = state.activity?.activeCustomerId;
        const projectId = state.activity?.activeProjectId;
        const newURL = formNewURL({
          pathName: ADMIN_CUSTOMER_PATH,
          params: [
            {key: USER_ID_URL_KEY, value: userId},
            {key: PROJECT_ID_URL_KEY, value: projectId},
          ],
        });
        openURLInNewTab(newURL);
        // toAdminCustomerProject(userId, projectId);
      },
    },
    [AVATAR_SIGN_IN_ID]: {
      label: (
        <AvatarDropdownDotContainer>
          <BsArrowBarRight />
          <div>Sign in</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_SIGN_IN_ID,
      action: () => to(NavigationPaths.LOGIN),
    },
    [AVATAR_SIGN_UP_ID]: {
      label: (
        <AvatarDropdownDotContainer>
          <CgProfile />
          <div>Create account</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_SIGN_UP_ID,
      action: () => to(NavigationPaths.INTAKE),
    },
    [AVATAR_CATALOG_ID]: {
      label: AvatarDropdownDotContainerComponent(
        productSelectedStatus,
        " Choose home"
      ),
      status: productSelectedStatus,
      value: AVATAR_CATALOG_ID,
      action: () => to(NavigationPaths.CATALOG),
    },
    [AVATAR_CATALOG_ID_ALT]: {
      label: (
        <AvatarDropdownDotContainer>
          <RiBuilding3Line />
          <div>View all homes</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_CATALOG_ID_ALT,
      action: () => to(NavigationPaths.CATALOG),
    },
    [AVATAR_DASHBOARD_ID_STEP]: {
      label: AvatarDropdownDotContainerComponent(
        CUSTOMER_STATUS.COMPLETED,
        " " + projectAddress != null && !!projectAddress
          ? Address.getStreetNumberAndStreet(projectAddress)
          : "Your lot"
      ),
      status: CUSTOMER_STATUS.COMPLETED,
      value: AVATAR_DASHBOARD_ID,
      action: () => {
        to(NavigationPaths.SPLASH_SCREEN);
      },
    },
    [AVATAR_DASHBOARD_ID]: {
      label: (
        <AvatarDropdownDotContainer>
          <NatProjectIcon />
          <div>Dashboard</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_DASHBOARD_ID,
      action: () => {
        if (depositPaidStatus !== CUSTOMER_STATUS.COMPLETED) {
          toSplashScreen();
        } else {
          toDashboard(to, pid);
        }
      },
    },
    [AVATAR_INSTANT_QUOTE_ID]: {
      label: AvatarDropdownDotContainerComponent(
        productSelectedStatus,
        " Get " + SITE_WORK_PHRASE
      ),
      status: productSelectedStatus,
      value: AVATAR_INSTANT_QUOTE_ID,
      action: () => {
        toInstantQuote(to, pid);
      },
    },
    [AVATAR_POST_DESIGN_STUDIO]: {
      label: AvatarDropdownDotContainerComponent(
        designReadyStatus,
        " Design home"
      ),
      status: designReadyStatus,
      value: AVATAR_POST_DESIGN_STUDIO,
      action: () => {
        to(NavigationPaths.STUDIO);
      },
    },
    [AVATAR_POST_SITE_PLAN_ID]: {
      label: AvatarDropdownDotContainerComponent(
        sitePlanReadyStatus,
        " Confirm site plan"
      ),
      status: sitePlanReadyStatus,
      value: AVATAR_POST_SITE_PLAN_ID,
      action: () => toSiteWorkPost(to, pid),
    },
    [AVATAR_POST_PROPOSAL_ID]: {
      label: AvatarDropdownDotContainerComponent(
        proposalReadyStatus,
        " Project proposal"
      ),
      status: proposalReadyStatus,
      value: AVATAR_POST_PROPOSAL_ID,
      action: () => toProposalPost(to, pid),
    },
    [AVATAR_POST_HOME_ID]: {
      label: (
        <AvatarDropdownDotContainer>
          <RiBuilding3Line />
          <div>My home</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_POST_HOME_ID,
      action: () => toHomePost(to, pid),
    },
    [AVATAR_ADMIN_DESIGN_STUDIO]: {
      label: (
        <AvatarDropdownDotContainer>
          <MdDesignServices />
          <div>Design Studio</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_ADMIN_DESIGN_STUDIO,
      action: () => {
        to(NavigationPaths.STUDIO);
      },
    },
    [AVATAR_MY_INFO]: {
      label: (
        <AvatarDropdownDotContainer>
          <CgProfile />
          <div>My Info</div>
        </AvatarDropdownDotContainer>
      ),
      value: AVATAR_MY_INFO,
      action: () => toProfile(to, pid),
    },
  };
};
export const getAllDropdownResourceItems = (
  setShowReferral: () => void,
  setShowProjectSwitcher: () => void,
  to: any,
  pid: string,
  logOutEmail?: string | null
): IDropdownList => {
  return {
    [AVATAR_WEBSITE_ID]: {
      label: (
        <AvatarDropdownItemContainer>
          <BsGlobe />
          <div>Villa website</div>
        </AvatarDropdownItemContainer>
      ),
      value: AVATAR_WEBSITE_ID,
      action: toMainSite,
    },
    [AVATAR_SCHEDULE_CALL_ID]: {
      label: (
        <AvatarDropdownItemContainer>
          <IoCallOutline />
          <div>Contact us</div>
        </AvatarDropdownItemContainer>
      ),
      value: AVATAR_SCHEDULE_CALL_ID,
      action: toContactUs,
    },
    [AVATAR_BUILD_ID]: {
      label: (
        <AvatarDropdownItemContainer>
          <NatMapIcon />
          <div>{VILLA_MAP}</div>
        </AvatarDropdownItemContainer>
      ),
      value: AVATAR_BUILD_ID,
      action: () => {
        to(NavigationPaths.PROPERTY_MAP);
      },
    },
    [AVATAR_FAQS]: {
      label: <div>FAQs</div>,
      value: AVATAR_FAQS,
      action: () => {},
    },
    [AVATAR_REFER]: {
      label: (
        <AvatarDropdownItemContainer>
          <FaUserFriends />
          <div>Refer friends</div>
        </AvatarDropdownItemContainer>
      ),
      value: AVATAR_REFER,
      action: setShowReferral,
    },
    [AVATAR_SWITCH_PROJECTS]: {
      label: (
        <AvatarDropdownItemContainer>
          <IoMdSwap />
          <div>Switch projects</div>
        </AvatarDropdownItemContainer>
      ),
      value: AVATAR_SWITCH_PROJECTS,
      action: setShowProjectSwitcher,
    },
    [AVATAR_SIGN_OUT_ID]: {
      label: (
        <AvatarDropdownItemContainer>
          <BsArrowBarLeft />
          <div style={{marginRight: "0.5rem"}}>Sign out</div>
          {logOutEmail && (
            <Marquee gradient={false} play={true}>
              <div style={{fontSize: "0.75rem", marginRight: "0.25rem"}}>
                {logOutEmail}
              </div>
            </Marquee>
          )}
        </AvatarDropdownItemContainer>
      ),
      value: AVATAR_SIGN_OUT_ID,
      action: () => {
        logOut({preventPageClearOut: true});
      },
    },
  };
};
export const getSignedInToDoOptions = (
  isCustomerPreDeposit: boolean,
  shouldDisplayPostDepositPortalV2: boolean
): string[] => {
  let options: string[] = [];

  if (isCustomerPreDeposit) {
    // Pre deposit TODOs
    options.push(
      AVATAR_CATALOG_ID,
      AVATAR_DESIGN_STUDIO,
      AVATAR_INSTANT_QUOTE_ID
    );
  } else if (shouldDisplayPostDepositPortalV2) {
  } else {
    // Post deposit TODOs (before HCP)
    options.push(
      AVATAR_POST_DESIGN_STUDIO,
      AVATAR_POST_SITE_PLAN_ID,
      AVATAR_POST_PROPOSAL_ID
    );
  }
  return options;
};
export const getSignedInAdminToDoOptions = (
  hasCustomer: boolean,
  isCustomerPreDeposit: boolean,
  shouldDisplayPostDepositPortalV2: boolean
): string[] => {
  let options: string[] = [];
  if (hasCustomer) {
    options.push(
      ...getSignedInToDoOptions(
        isCustomerPreDeposit,
        shouldDisplayPostDepositPortalV2
      )
    );
  } else {
    options.push(
      AVATAR_ADMIN_ID,
      AVATAR_CATALOG_ID_ALT,
      AVATAR_ADMIN_DESIGN_STUDIO
    );
  }
  return options;
};
export const getSignedOutResourcesOptions = (
  isReferralEnabled: boolean
): string[] => {
  let resources: string[] = [];
  if (isReferralEnabled) {
    resources.push(AVATAR_REFER);
  }
  resources.push(AVATAR_WEBSITE_ID);
  resources.push(AVATAR_SCHEDULE_CALL_ID);
  return resources;
};
export const getSignedInResourcesOptions = (
  shouldDisplayPostDepositPortalV2: boolean,
  isCustomerPreDeposit: boolean,
  customer: any,
  isReferralEnabled: boolean,
  isArchived: boolean
): string[] => {
  let resources: string[] = [];
  if (
    (shouldDisplayPostDepositPortalV2 &&
      !isCustomerPreDeposit &&
      (customer?.project_ids?.length ?? 0) >= 2) ||
    isArchived
  ) {
    resources.push(AVATAR_SWITCH_PROJECTS);
  }
  resources.push(...getSignedOutResourcesOptions(isReferralEnabled));
  resources.push(AVATAR_SIGN_OUT_ID);
  return resources;
};

export const getSignedOutToDoOptions = (): string[] => {
  let options: string[] = [];
  options.push(AVATAR_SIGN_IN_ID, AVATAR_SIGN_UP_ID);
  return options;
};

export const getDashboardOptions = (
  isAdmin: boolean,
  shouldDisplayPostDepositPortalV2: boolean,
  isCustomerPreDeposit: boolean
): string[] => {
  let options: string[] = [];
  if (isAdmin) {
    options.push(
      AVATAR_ADMIN_ID,
      // AVATAR_ADMIN_CUSTOMER_ID,
      AVATAR_ADMIN_CUSTOMER_PROJECT_ID
    );
  }
  options.push(AVATAR_DASHBOARD_ID);
  if (shouldDisplayPostDepositPortalV2) {
    options.push(AVATAR_MY_INFO, AVATAR_CATALOG_ID_ALT);
  } else if (!isCustomerPreDeposit) {
    options.push(AVATAR_MY_INFO, AVATAR_POST_HOME_ID);
  }
  return options;
};
