import {
  HistoryChangeType,
  scrollToTop,
  setMultipleURLQueryKeyValues,
  setURLPathAndMultipleQueries,
  setURLPathAndQuery,
} from "../../navigation/_helpers";
import history from "../../navigation/history";
import {
  ADMIN_COMPLETED_PROJECTS_PATH,
  ADMIN_CUSTOMER_DATABASE_PATH,
  ADMIN_CUSTOMER_PATH,
  ADMIN_DATA_PATH,
  ADMIN_DEVELOPER_PATH,
  ADMIN_EMPLOYEE_MANAGER_PATH,
  ADMIN_PRODUCT_CATALOG_PATH,
  ADMIN_PRODUCT_CATALOG_ROUTING_PATH,
  ADMIN_PRODUCT_INVENTORY_PATH,
  ADMIN_ROOT_PATH,
  CATALOG_INVENTORY_PATH,
  CATALOG_ROOT_PATH,
  DOCUMENT_PATH,
  DOCUMENT_PDF_PATH,
  DOCUMENT_PDF_PROPOSAL_PATH,
  LOGIN_PATH,
  MAP_PATH,
  PAYMENTS_PATH,
  QUIZ_PATH,
  START_PATH,
  STUDIO_PATH,
  SUMMARY_PATH,
} from "../../../../paths";
import {logOut} from "../../../../database/firebase";
import {
  CONFIGURATION_ID_URL_KEY,
  PROJECT_ID_URL_KEY,
  USER_ID_URL_KEY,
} from "../../../../database/firebase/configuration/configuration";
import {IAddressDetails, IProduct} from "@natomas-org/core";
import {store} from "../../../../store";
import {
  MapViewMode,
  URL_PARAM_KEY_CART_ITEM_ID,
  URL_PARAM_KEY_PAGE_ID,
  URL_PARAM_KEY_PRODUCT_GROUP_ID,
  URL_PARAM_KEY_PROJECT_ID,
  URL_PARAM_KEY_UNIT_ID,
  URL_PARAM_KEY_USER_ID,
} from "@natomas-org/service";
import {
  animateMapHome,
  animateMapLeaveHome,
  setGLMapStateZoom,
  setMapStateParcelView,
} from "../../../map/slices/mapSlice";
import {openURLInNewTab} from "../../navigation";
import {MAP_ANIMATION_DURATION_SECONDS} from "../../../mapping/views/theme/constants";
import {HOME_PAGE_ID} from "../../../portal/_shared/navigation/constants";
import {DEFAULT_CA_VIEWPORT} from "../../../mapping/views/components/AreaMap/constants";

// Routes
// ------------------------
// These functions should only be accessed by the useNavigation hook.
export interface INavigateBackOptions {
  scrollToTop: boolean;
}

export const toNavigateBack = () => {
  history.goBack();
};

export const navigateTo = (
  url: string,
  options?: {
    openInNewTab?: boolean;
    mode?: HistoryChangeType;
  }
) => {
  const mode = options?.mode ?? HistoryChangeType.PUSH;

  if (options?.openInNewTab) {
    openURLInNewTab(url);
    return;
  }

  switch (mode) {
    case HistoryChangeType.PUSH:
      history.push(url);
      break;
    case HistoryChangeType.REPLACE:
      history.replace(url);
      break;
  }
  scrollToTop(false);
};

export const toMap = async (
  mapViewMode?: MapViewMode,
  addressToDisplay?: IAddressDetails,
  projectProduct?: IProduct // TODO use product when appropriate
) => {
  const currentState = store.getState();
  if (window.location.pathname.includes(MAP_PATH)) {
    // Navigating within Map
    switch (mapViewMode) {
      case MapViewMode.COMPLETED_PROJECTS:
        if (currentState?.map?.parcelView === true) {
          store.dispatch(setMapStateParcelView(false));
          store.dispatch(
            animateMapLeaveHome({
              duration: MAP_ANIMATION_DURATION_SECONDS * 2000,
            })
          );
        }
        break;
      case MapViewMode.PROPERTY_MAP:
        if (currentState?.map?.parcelView === false) {
          store.dispatch(setMapStateParcelView(true));
          store.dispatch(animateMapHome({}));
        }
        break;
      default:
        break;
    }
    return;
  } else {
    // Navigating to map from another app
    switch (mapViewMode) {
      case MapViewMode.COMPLETED_PROJECTS:
        store.dispatch(setMapStateParcelView(false));
        store.dispatch(setGLMapStateZoom(DEFAULT_CA_VIEWPORT.zoom));
        store.dispatch(animateMapLeaveHome({duration: 50}));
        break;
      case MapViewMode.PROPERTY_MAP:
        store.dispatch(setMapStateParcelView(true));
        store.dispatch(animateMapHome({}));

        break;
      default:
        break;
    }
  }
  setURLPathAndMultipleQueries(MAP_PATH, [
    {
      key: URL_PARAM_KEY_PROJECT_ID,
      value: null,
    },
    {
      key: CONFIGURATION_ID_URL_KEY,
      value: null,
    },
  ]);
  scrollToTop(false);
};

export const toPortal = (options?: {page?: string; pid?: string}) => {
  const keyValueDict: {key: string; value: string}[] = [];
  keyValueDict.push({
    key: URL_PARAM_KEY_PAGE_ID,
    value: options?.page ?? HOME_PAGE_ID,
  });
  setURLPathAndMultipleQueries(SUMMARY_PATH, keyValueDict);
  scrollToTop(false);
};

export const toQuiz = () => {
  setURLPathAndMultipleQueries(QUIZ_PATH, []);
};

export const toSplashScreen = () => {
  setURLPathAndMultipleQueries("/", [
    {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
    {key: URL_PARAM_KEY_UNIT_ID, value: null},
    {key: URL_PARAM_KEY_PAGE_ID, value: null},
    {key: USER_ID_URL_KEY, value: null},
  ]);
  scrollToTop(true);
};

export const toCatalog = (
  preserveCatalogSelections?: boolean,
  gid?: string,
  unit?: string,
  page?: string,
  publicCatalog?: boolean
) => {
  const pid = store.getState()?.activity?.activeProjectId ?? null;
  if (preserveCatalogSelections) {
    setURLPathAndQuery(
      CATALOG_ROOT_PATH,
      URL_PARAM_KEY_PROJECT_ID,
      publicCatalog ? undefined : pid ?? undefined
    );
  } else {
    const keyValueArray = [
      {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: gid ?? null},
      {key: URL_PARAM_KEY_UNIT_ID, value: unit ?? null},
      {key: URL_PARAM_KEY_PAGE_ID, value: page ?? null},
      {key: URL_PARAM_KEY_PROJECT_ID, value: publicCatalog ? null : pid},
      // TODO B2B Deprecated CID param by setting to null
      {key: CONFIGURATION_ID_URL_KEY, value: null},
    ];
    if (publicCatalog) {
      keyValueArray.push({key: USER_ID_URL_KEY, value: null});
    }
    setURLPathAndMultipleQueries(CATALOG_ROOT_PATH, keyValueArray);
  }
  scrollToTop(true);
};

export const toCatalogInventory = (
  preserveCatalogSelections?: boolean,
  gid?: string,
  unit?: string,
  page?: string
) => {
  console.log(preserveCatalogSelections, gid, unit, page);
  if (preserveCatalogSelections) {
    setURLPathAndQuery(CATALOG_INVENTORY_PATH);
  } else {
    setURLPathAndMultipleQueries(CATALOG_INVENTORY_PATH, [
      {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: gid ?? null},
      {key: URL_PARAM_KEY_UNIT_ID, value: unit ?? null},
      {key: URL_PARAM_KEY_PAGE_ID, value: page ?? null},
    ]);
  }
  scrollToTop(true);
};

export const toPayment = () => {
  setURLPathAndQuery(PAYMENTS_PATH);
  scrollToTop(false);
};

export const toIntakeForm = (redirectToBuild?: boolean) => {
  setURLPathAndQuery(START_PATH);
  scrollToTop(false);
};

export const toLogin = () => {
  logOut().then(() => {
    setURLPathAndQuery(LOGIN_PATH);
    scrollToTop(false);
  });
};

export const toStudio = (options?: {
  productId?: string;
  productGroupId?: string;
  cartItemId?: string;
  address?: IAddressDetails;
}) => {
  let productId: string | undefined = undefined;
  let productGroupId: string | undefined = undefined;
  let cartItemId: string | undefined = undefined;
  if (options) {
    productId = options?.productId;
    productGroupId = options?.productGroupId;
    cartItemId = options?.cartItemId;
  }
  if (cartItemId) {
    setURLPathAndMultipleQueries(STUDIO_PATH, [
      {key: URL_PARAM_KEY_CART_ITEM_ID, value: cartItemId ?? null},
    ]);
  } else if (!productId || !productGroupId) {
    setURLPathAndMultipleQueries(STUDIO_PATH, []);
  } else {
    setURLPathAndMultipleQueries(STUDIO_PATH, [
      {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: productGroupId},
      {key: URL_PARAM_KEY_UNIT_ID, value: productId},
      {key: URL_PARAM_KEY_CART_ITEM_ID, value: cartItemId ?? null},
    ]);
  }
  scrollToTop(false);
};

export const toCustomerDatabase = () => {
  setURLPathAndMultipleQueries(ADMIN_CUSTOMER_DATABASE_PATH, [
    {key: URL_PARAM_KEY_USER_ID, value: null},
  ]);
  scrollToTop(false);
};

export const toAdminCustomer = (uid?: string) => {
  if (!uid) {
    return;
  }
  setURLPathAndMultipleQueries(ADMIN_CUSTOMER_PATH, [
    {key: URL_PARAM_KEY_USER_ID, value: uid},
    {key: URL_PARAM_KEY_PROJECT_ID, value: null},
  ]);
  scrollToTop(false);
};

export const toAdminCustomerProject = (
  uid?: string,
  pid?: string,
  page?: string
) => {
  if (!uid || !pid) {
    return;
  }
  setURLPathAndMultipleQueries(ADMIN_CUSTOMER_PATH, [
    {key: URL_PARAM_KEY_USER_ID, value: uid},
    {key: URL_PARAM_KEY_PROJECT_ID, value: pid},
    {key: URL_PARAM_KEY_PAGE_ID, value: page ?? null},
  ]);

  scrollToTop(false);
};

export const toDeveloperDashboard = () => {
  setURLPathAndQuery(ADMIN_DEVELOPER_PATH);
  scrollToTop(false);
};

export const toDocuments = () => {
  setURLPathAndQuery(DOCUMENT_PATH);
  scrollToTop(true);
};

export const toPDFDocuments = () => {
  setURLPathAndQuery(DOCUMENT_PDF_PATH);
  scrollToTop(true);
};

export const toProposalPDF = () => {
  setURLPathAndQuery(DOCUMENT_PDF_PROPOSAL_PATH);
  scrollToTop(true);
};

export const toAdminCompletedProjects = () => {
  setURLPathAndMultipleQueries(ADMIN_COMPLETED_PROJECTS_PATH, []);
  scrollToTop(false);
};
export const toAdminDataExport = () => {
  setURLPathAndMultipleQueries(ADMIN_DATA_PATH, []);
  scrollToTop(false);
};

export const toAdminProductCatalog = () => {
  setURLPathAndMultipleQueries(ADMIN_PRODUCT_CATALOG_PATH, [
    {key: URL_PARAM_KEY_UNIT_ID, value: null},
    {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
    {key: CONFIGURATION_ID_URL_KEY, value: null},
    {key: PROJECT_ID_URL_KEY, value: null},
    {key: URL_PARAM_KEY_PAGE_ID, value: null},
  ]);
  scrollToTop(false);
};

export const toAdminInventory = () => {
  setURLPathAndMultipleQueries(ADMIN_PRODUCT_INVENTORY_PATH, [
    {key: URL_PARAM_KEY_UNIT_ID, value: null},
    {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
    {key: CONFIGURATION_ID_URL_KEY, value: null},
    {key: PROJECT_ID_URL_KEY, value: null},
    {key: URL_PARAM_KEY_PAGE_ID, value: null},
  ]);
  scrollToTop(false);
};

export const toAdminProductCatalogRouting = () => {
  setURLPathAndMultipleQueries(ADMIN_PRODUCT_CATALOG_ROUTING_PATH, [
    {key: URL_PARAM_KEY_UNIT_ID, value: null},
    {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
    {key: CONFIGURATION_ID_URL_KEY, value: null},
    {key: PROJECT_ID_URL_KEY, value: null},
    {key: URL_PARAM_KEY_PAGE_ID, value: null},
  ]);
  scrollToTop(false);
};

export const toAdminEmployeeManagement = () => {
  setURLPathAndQuery(ADMIN_EMPLOYEE_MANAGER_PATH);
  scrollToTop(false);
};

export const toAdmin = () => {
  setURLPathAndMultipleQueries(ADMIN_ROOT_PATH, [
    {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
    {key: URL_PARAM_KEY_UNIT_ID, value: null},
    {key: CONFIGURATION_ID_URL_KEY, value: null},
    {key: PROJECT_ID_URL_KEY, value: null},
    {key: URL_PARAM_KEY_PAGE_ID, value: null},
  ]);
  scrollToTop(false);
};

export const toUnitById = (unitId?: string, productGroupId?: string) => {
  setMultipleURLQueryKeyValues([
    {key: URL_PARAM_KEY_UNIT_ID, value: unitId ?? null},
    {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: productGroupId ?? null},
  ]);
};
