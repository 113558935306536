import React, {useMemo} from "react";
//Hooks
//Assets
import image from "../../../../assets/images/photos/exteriorFeatures2.jpg";
import adu from "../../../../assets/images/trimmed/450_HUD_GABLED_LAPPED.png";
//Shared
import {
  DesignBlurb,
  HelperText,
  NoProductContainer,
  WaitingGraphic,
} from "./styles";
import {NatButton} from "../../../_shared/generics/button";
import {StyleOption} from "../../../_shared/generics/_shared";
import laptop from "../../../../assets/images/graphics/laptop.png";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {YourADU} from "../PaymentsView/components/YourADU";
import {ADUBlurb} from "../PaymentsView/components/ADUBlurb";
import {PageElement} from "../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {DesignStudioView} from "../DesignStudioView";
import {useNavigation} from "../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {Product} from "@natomas-org/core";
import useActiveDesign from "../../../_shared/hooks/useDesign/useActiveDesign";

export const YourUnitView = () => {
  const {configuration, snapshot} = useActiveDesign();
  const {to} = useNavigation();
  const product = useMemo(() => {
    if (configuration?.product) return configuration.product;
    else return null;
  }, [configuration]);

  const dynamicWidth = useDynamicValue({
    forFour: 4,
    forEight: 3,
    forTwelve: 4,
    forSixteen: 6,
  });

  const fullYourADUContentSize = useDynamicValue(fullColumnWidths);

  const blurbComponentSize = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
    forSixteen: 8,
  });

  if (snapshot) {
    return (
      <PageContainer>
        <DesignStudioView useNatPage={true} />
      </PageContainer>
    );
  } else {
    return (
      <>
        <PageContainer>
          {product ? (
            <>
              <ADUBlurb
                imageId={product?.imageId ?? ""}
                title={product?.title}
                description={product?.displayDescription}
                tagline={product?.tagline}
                size={blurbComponentSize}
                showChangeButton={true}
              />
              <PageElement size={fullYourADUContentSize} height={"auto"}>
                <YourADU
                  title={product?.title}
                  frontLength={
                    product?.productDetails.dimensions.frontLength.feet
                  }
                  sideLength={
                    product?.productDetails.dimensions.sideLength.feet
                  }
                  bedroomCount={product?.productDetails.bedrooms}
                  bathroomCount={product?.productDetails.bathrooms}
                  squareFootage={product?.productDetails.squareFeet}
                  // @ts-ignore
                  priceMicros={Product.getUnitPrice(product)}
                />
              </PageElement>
            </>
          ) : (
            <NoProductContainer>
              <WaitingGraphic src={laptop} alt={"Choose your home"} />
              <NatButton
                trackingId={"your-unit-to-catalog"}
                label={"Choose your home"}
                type={"button"}
                option={StyleOption.PRIMARY_ALT}
                clickEvent={() => to(NavigationPaths.CATALOG)}
                spinnerEnabled={false}
              />
            </NoProductContainer>
          )}
        </PageContainer>
        <PageContainer
          marginTop={"auto"}
          backgroundImage={{image: image, height: "40rem"}}
          overflow={"hidden"}
        >
          <DesignBlurb>
            <PageElement size={dynamicWidth} height={"auto"}>
              <img src={adu} alt={""} />

              <HelperText size={"1.25rem"} bold={true} align={"center"}>
                Design Studio
              </HelperText>
              <HelperText size={"1.05rem"} align={"center"}>
                Configure and upgrade your dream home
              </HelperText>
              <NatButton
                label={"Design your home"}
                trackingId={"your-unit-to-design-studio"}
                type={"button"}
                option={StyleOption.PRIMARY_ALT}
                clickEvent={() => to(NavigationPaths.STUDIO)}
                spinnerEnabled={false}
              />
            </PageElement>
          </DesignBlurb>
        </PageContainer>
      </>
    );
  }
};
