import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";

export interface IActivitySlice {
  activeCustomerId?: string;
  activeProjectId?: string;
  activeCartItemId?: string;
}

const getInitialState = (): IActivitySlice => {
  return {
    activeCustomerId: undefined,
    activeProjectId: undefined,
    activeCartItemId: undefined,
  };
};

const activitySlice = createSlice({
  name: "activity",
  initialState: getInitialState(),
  reducers: {
    setActiveCustomerId: (
      state: Draft<IActivitySlice>,
      action: PayloadAction<string | undefined>
    ) => {
      state.activeCustomerId = action.payload;
    },
    setActiveProjectId: (
      state: Draft<IActivitySlice>,
      action: PayloadAction<string | undefined>
    ) => {
      state.activeProjectId = action.payload;
    },
    setActiveCartItemId: (
      state: Draft<IActivitySlice>,
      action: PayloadAction<string | undefined>
    ) => {
      state.activeCartItemId = action.payload;
    },
  },
});

export const activityReducer = activitySlice.reducer;
export const {setActiveCustomerId, setActiveProjectId, setActiveCartItemId} =
  activitySlice.actions;
