export enum FactoryInventoryInformationEditorKey {
  INTERNAL_TITLE = "internalTitle",
  SERIAL_NUMBER = "serialNumber",
  INTERNAL_NOTES = "internalNotes",
  LISTING_STATUS = "listingStatus",
  STORAGE_DATE_TIME = "storageDateTime",
  PRODUCTION_DATE_TIME = "productionDateTime",
  BLACKLINES_URL = "blacklinesUrl",
  CUSTOMER_NAME = "customerName",
  CUSTOMER_PORTAL_LINK = "customerPortalLink",
  EMPLOYEE_NAME = "employeeName",
  SOLD_DATE_TIME = "soldDateTime",
}

export interface IFactoryInventoryInformationEditor {
  [FactoryInventoryInformationEditorKey.INTERNAL_TITLE]?: string;
  [FactoryInventoryInformationEditorKey.INTERNAL_NOTES]?: string;
  [FactoryInventoryInformationEditorKey.SERIAL_NUMBER]?: string;
  [FactoryInventoryInformationEditorKey.LISTING_STATUS]?: string;
  [FactoryInventoryInformationEditorKey.STORAGE_DATE_TIME]?: number;
  [FactoryInventoryInformationEditorKey.PRODUCTION_DATE_TIME]?: number;
  [FactoryInventoryInformationEditorKey.BLACKLINES_URL]?: string;
  [FactoryInventoryInformationEditorKey.CUSTOMER_NAME]?: string;
  [FactoryInventoryInformationEditorKey.CUSTOMER_PORTAL_LINK]?: string;
  [FactoryInventoryInformationEditorKey.EMPLOYEE_NAME]?: string;
  [FactoryInventoryInformationEditorKey.SOLD_DATE_TIME]?: number;
}
