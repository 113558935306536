import React, {useCallback} from "react";
import useLatestEstimate from "../useLatestEstimate";
import {GenericLoadingComponent} from "../../portal/views/ProposalBudgetView/components/generic/GenericLoadingComponent";
import EstimateEditorTable from "./EstimateEditorTable";
import {useEstimateController} from "./useEstimateController";
import {
  getEstimateService,
  IEstimateRangeItem,
} from "../../../api/Estimate/EstimateService";
import {
  IRequestHandlerResult,
  useRequestHandler,
} from "../../admin/NexusDemoView/useRequestDebugger";
import styled from "styled-components";
import useActivitySlice from "../../_shared/hooks/useActivity/useActivitySlice";
import {NatSize, StyleOption} from "../../_shared/generics/_shared";
import {NatButton} from "../../_shared/generics/button";

interface EstimateEditorProps {
  onSave: () => void;
}

const createEstimateByDefaulting = async (pid: string) => {
  const service = await getEstimateService();
  return service.createByDefaulting(pid);
};

const EstimateEditor = (props: EstimateEditorProps) => {
  const {onSave} = props;
  const {response, status} = useLatestEstimate();
  const {save} = useEstimateController();
  const [error, setError] = React.useState<string | undefined>(undefined);
  const saveAndClose = useCallback(
    async (data: IEstimateRangeItem[]) => {
      return save(data).then((res: IRequestHandlerResult) => {
        console.log(res);
        if (res?.error?.response?.data?.detail) {
          // Many details
          let errorString = "Error saving estimate:\n";
          const errors = res?.error?.response?.data?.detail;
          console.log(errors);
          errors?.forEach((e: any, idx: number) => {
            console.error(e);
            errorString += `${idx + 1}: ${e?.msg}\n`;
          });
          console.log(errorString);
          setError(errorString);
          return;
        }
        if (res?.error) {
          // Many details
          let errorString = "Error saving estimate:\n";
          const error = res?.error?.response?.data?.details;
          console.log(error);
          errorString += error;
          setError(errorString);
          return;
        } else {
          setError(undefined);
          onSave();
        }
      });
    },
    [save, onSave]
  );

  const {activeProjectId} = useActivitySlice();

  const {fetch: createByDefaults} = useRequestHandler({
    method: createEstimateByDefaulting,
  });

  const create = useCallback((): Promise<any | null> => {
    if (!activeProjectId) {
      return Promise.resolve(null);
    }
    return createByDefaults(activeProjectId);
  }, [createByDefaults, activeProjectId]);

  const createAndClose = useCallback(() => {
    if (!create) {
      return;
    } else {
      create().then((res: IRequestHandlerResult) => {
        console.log(res);
        if (res.error) {
          setError(res?.error?.response?.data?.details);
          return;
        } else {
          setError(undefined);
          onSave();
        }
      });
    }
  }, [create, onSave]);

  if (status.fetching) {
    return (
      <>
        {/*{JSON.stringify(status, null, 2)}*/}
        <GenericLoadingComponent />
      </>
    );
  }

  if (!response) {
    return (
      <>
        No estimate available at this time.
        <NatButton
          label={"Create new estimate with default values"}
          disabled={!createAndClose}
          option={StyleOption.PRIMARY}
          clickEvent={createAndClose}
        />
      </>
    );
  }
  return (
    <>
      <NatButton
        label={"Create new estimate with default values"}
        disabled={!createAndClose}
        option={StyleOption.DESTRUCTIVE}
        clickEvent={createAndClose}
        size={NatSize.XSMALL}
      />
      {error && <ErrorContainer>{error}</ErrorContainer>}
      <EstimateEditorTable range={response} saveData={saveAndClose} />
    </>
  );
};
export default EstimateEditor;

const ErrorContainer = styled.div`
  padding: 10px;
  margin: 10px;
  width: fit-content;
  border: 1px solid red;
  border-radius: 10px;
  white-space: pre-wrap;
  background-color: #ffcccc;
  color: red;
`;
