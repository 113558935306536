import React from "react";
import {
  ALL_BUYOUT_ADAPTER_INFO,
  ALL_CLOSEOUT_ADAPTER_INFO,
  ALL_DESIGN_ADAPTER_INFO,
  ALL_INSTALLATION_ADAPTER_INFO,
  ALL_PRECONSTRUCTION_ADAPTER_INFO,
  ALL_PRODUCTION_ADAPTER_INFO,
  ALL_TRANSITION_ADAPTER_INFO,
  IProjectInfo,
  Project,
  sumArray,
} from "@natomas-org/core";
import {
  B2B_TIMELINE_INFO,
  GENERIC_INVENTORY_TIMELINE_INFO,
  GENERIC_TIMELINE_INFO,
} from "./TimelineInfoConstants";
import {INextStepInfo} from "./TimelineTypes";

export const calculateCurrentProgress = (
  startDate: number,
  endDate: number
) => {
  const todayTimestamp = new Date().getTime();
  return Math.round(
    Math.max(
      Math.min(
        ((todayTimestamp - startDate) / (endDate - startDate)) * 100,
        100
      ),
      0
    )
  );
};

export interface ITimelineDisplayInfo {
  overallStartDate: Date;
  monthBreakdown: number[];
  nextStepsInfo: INextStepInfo[];
  totalMonths: number;
  overallEndDate: Date;
}

export const getProductionTimelineInfo = (
  factoryDetails: any,
  product?: any,
  date?: Date,
  isCartEnabled?: boolean
): ITimelineDisplayInfo => {
  let monthBreakdown = [1, 3, 2, 4, 3];
  if (factoryDetails?.production_timeline?.length) {
    const timeline: any[] = factoryDetails.production_timeline;
    monthBreakdown = timeline.map((timelineInfo: any) => {
      return timelineInfo.months;
    });
  }
  let nextStepsInfo = getNextSteps(
    monthBreakdown,
    "custom",
    date,
    isCartEnabled
  );
  if (product?.inventory_info) {
    if (product?.timeline?.length > 0) {
      monthBreakdown = product?.timeline?.map((timelineInfo: any) => {
        return timelineInfo.months;
      });
      nextStepsInfo = getNextSteps(
        monthBreakdown,
        "custom",
        date,
        isCartEnabled
      );
    } else {
      monthBreakdown = [1, 3, 1, 3];
      nextStepsInfo = getNextSteps(
        monthBreakdown,
        "inventory",
        date,
        isCartEnabled
      );
    }
  }
  let overallEndDate = new Date();
  nextStepsInfo.forEach((info) => {
    if (overallEndDate < info.endDate) {
      overallEndDate = info.endDate;
    }
  });
  return {
    monthBreakdown,
    totalMonths: sumArray(monthBreakdown),
    nextStepsInfo,
    overallEndDate,
    overallStartDate: date ?? new Date(),
  };
};

export const getActualProjectTimelineInfo = (
  projectSummary: IProjectInfo,
  isCartEnabled: boolean
): ITimelineDisplayInfo => {
  const baseTimelineInfo = isCartEnabled
    ? B2B_TIMELINE_INFO
    : GENERIC_TIMELINE_INFO;
  const nextStepsInfo = baseTimelineInfo.map((info) => {
    let startDate = Number.MAX_SAFE_INTEGER;
    let endDate = 0;
    let eventIds: string[] = [];
    switch (info.id) {
      case "proposal": {
        const event = Project.getEvent(
          projectSummary,
          ALL_TRANSITION_ADAPTER_INFO.event_id
        );
        const transitionStartDate = new Date(event?.start_timestamp ?? 0);
        startDate = new Date(
          transitionStartDate.getFullYear(),
          transitionStartDate.getMonth() - 1,
          transitionStartDate.getDate()
        ).getTime();
        endDate = transitionStartDate.getTime();
        break;
      }
      case "permitting": {
        eventIds = [
          ALL_TRANSITION_ADAPTER_INFO.event_id,
          ALL_DESIGN_ADAPTER_INFO.event_id,
          ALL_PRECONSTRUCTION_ADAPTER_INFO.event_id,
        ];
        break;
      }
      case "ATB": {
        eventIds = [ALL_BUYOUT_ADAPTER_INFO.event_id];
        break;
      }
      case "manufacturing": {
        eventIds = [ALL_PRODUCTION_ADAPTER_INFO.event_id];
        break;
      }
      case "installation": {
        eventIds = [
          ALL_INSTALLATION_ADAPTER_INFO.event_id,
          ALL_CLOSEOUT_ADAPTER_INFO.event_id,
        ];
        break;
      }
    }

    eventIds.forEach((eventId) => {
      const event = Project.getEvent(projectSummary, eventId);
      if (event) {
        startDate = Math.min(startDate, event.start_timestamp);
        endDate = Math.max(endDate, event.end_timestamp);
      }
    });

    return {
      ...info,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      completion_percent: calculateCurrentProgress(startDate, endDate),
    };
  });

  let overallStartDate = new Date();
  let overallEndDate = new Date();
  nextStepsInfo.forEach((info, index) => {
    if (overallEndDate < info.endDate) {
      overallEndDate = info.endDate;
    }
    if (overallStartDate > info.startDate) {
      overallStartDate = info.startDate;
    }
    if (info.phase) {
      // Add the number of months for the additional phases
      let phaseEndDate = info.endDate;
      const additionalSteps = info.phase.additionalSteps;
      for (let i = index; i <= index + additionalSteps; i++) {
        if (phaseEndDate.getTime() < nextStepsInfo[i].endDate.getTime()) {
          phaseEndDate = nextStepsInfo[i].endDate;
        }
      }
      info.phase.phaseEndDate = phaseEndDate;
    }
  });
  return {
    monthBreakdown: [1, 1, 1, 1, 1],
    totalMonths: sumArray([1, 1, 1, 1, 1]),
    nextStepsInfo,
    overallEndDate,
    overallStartDate,
  };
};

const getNextSteps = (
  monthBreakdown: number[],
  type: "inventory" | "custom",
  date?: Date,
  isCartEnabled?: boolean
): INextStepInfo[] => {
  const startDate = date ?? offsetDateByOneMonth(new Date());
  let lastStepEndMonth = 0;
  return monthBreakdown.map((numberOfMonths: number, index: number) => {
    // Set up values
    const startMonth = lastStepEndMonth;
    const endMonth = startMonth + numberOfMonths;
    lastStepEndMonth = endMonth;

    // Copy from generic step
    let genericStepInfo = Object.assign(
      {},
      type === "inventory"
        ? GENERIC_INVENTORY_TIMELINE_INFO[index]
        : GENERIC_TIMELINE_INFO[index]
    );
    if (isCartEnabled) {
      genericStepInfo = B2B_TIMELINE_INFO[index];
    }
    genericStepInfo.startDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + startMonth,
      startDate.getDate()
    );
    genericStepInfo.endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + endMonth,
      startDate.getDate()
    );

    if (genericStepInfo.phase) {
      // Add the number of months for the additional phases
      let phaseEndMonth = startMonth;
      const additionalSteps = genericStepInfo.phase.additionalSteps;
      for (let i = index; i <= index + additionalSteps; i++) {
        phaseEndMonth += monthBreakdown[i];
      }
      genericStepInfo.phase.phaseEndDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + phaseEndMonth,
        startDate.getDate()
      );
    }

    return genericStepInfo;
  });
};

export const offsetDateByOneMonth = (startDate: Date) => {
  return new Date(
    startDate.getFullYear(),
    startDate.getMonth() - 1, // since proposal is 1 month prior
    startDate.getDate()
  );
};

export function chunkArray(array: any[], chunkSize: number = 2): any[][] {
  if (array.length % chunkSize !== 0) {
    throw new Error("Array length must be even");
  }

  const result: number[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}
