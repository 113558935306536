import React, {FormEvent, useState} from "react";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {AdminSaveActionWarning} from "./AdminSaveActionWarning";

export interface AdminBaseActionsProps {
  isUpToDate?: boolean;
  discardAction?: () => void;
  saveAction?: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  saveLabelAddition?: string;
  disabled?: boolean;
}
export const AdminBaseActions = (props: AdminBaseActionsProps) => {
  const {disabled, isUpToDate, discardAction, saveAction, saveLabelAddition} =
    props;
  const {isDraft} = useAdminProductCatalog();
  const [showSaveWarning, setShowSaveWarning] = useState<boolean>(false);

  const getSaveLabel = () => {
    let label = isDraft ? "Save" : "Save (Live)";
    if (saveLabelAddition) {
      label += " " + saveLabelAddition;
    }
    return label;
  };

  return (
    <>
      {discardAction && isUpToDate !== undefined && (
        <NatButton
          type={"button"}
          size={NatSize.SMALL}
          option={StyleOption.SECONDARY_ALT}
          disabled={isUpToDate}
          clickEvent={discardAction}
          label={"Discard Changes"}
          trackingId={"admin-discard-changes"}
        />
      )}
      {saveAction && isUpToDate !== undefined && (
        <NatButton
          type={"button"}
          size={NatSize.SMALL}
          option={StyleOption.PRIMARY_ALT}
          disabled={isUpToDate || disabled}
          clickEvent={isDraft ? saveAction : () => setShowSaveWarning(true)}
          label={getSaveLabel()}
          trackingId={"admin-save"}
        />
      )}
      <AdminSaveActionWarning
        show={showSaveWarning}
        setShow={setShowSaveWarning}
        saveAction={saveAction}
      />
    </>
  );
};
