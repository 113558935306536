import {NavigationButton} from "./styles";
import React, {useRef} from "react";

export const SectionNavigationButton = (props: {
  step: any;
  isSelected: boolean;
  index: number;
  navigate: (i: number) => void;
}) => {
  const {step, isSelected, navigate, index} = props;
  const ref = useRef<HTMLLIElement>(null);
  if (ref && isSelected) {
    ref.current?.scrollIntoView(true);
  }
  return (
    <NavigationButton
      ref={ref}
      selected={isSelected}
      key={step?.id}
      onClick={() => {
        navigate(index);
      }}
    >
      {step?.title}
    </NavigationButton>
  );
};
