import {DesignStudioModifier, IProduct} from "@natomas-org/core";

export enum ReviewStatus {
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error", // Price is set, cost is missing or vice versa
}

export enum ReviewWarningStatus {
  EMPTY = "PRICE & COST = UNSET", // Price and cost are empty
  ZERO = "PRICE & COST = 0", // Price and cost are set to zero
  COST_ZERO = "COST = 0", // Cost is set to zero
  PRICE_ZERO = "PRICE = 0", // Price is set to zero
  COST_EXCEEDS_PRICE = "COST > PRICE", // Cost exceeds price
  COST_EQUALS_PRICE = "COST === PRICE", // Cost equals price
  UNKNOWN = "UNKNOWN", // cant determine
}

export interface IModifierPricingReview {
  modifier: DesignStudioModifier;
  product?: IProduct;
  status: ReviewStatus;
  warningStatus?: ReviewWarningStatus;
  message: string;
  state: IModifierPricingReviewState;
  isEdit?: boolean;
}

export interface IModifierDependency {
  title: string;
  id: string;
}

export interface IModifierPricingReviewMetadata {}

export interface IModifierPricingReviewState {
  price: number | null;
  cost: number | null;
  condition: string;
  type: string;
  dependencies: IModifierDependency[];
}
