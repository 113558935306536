import React from "react";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {B2BTimelineChartDisplayOptions} from "../../../../../../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/ProposalViewerEditor/TimelineEditor/helpers";
import {ProjectTimelineLayout} from "../../../../ProposalView/ProjectTimelineLayout";
import {PaymentScheduleInfo} from "../../../../ProposalView/components/PaymentScheduleInfo";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {FULL_SCREEN_HEIGHT_REM} from "../../../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";

export const TIMELINE_PDF_SCALAR = 0.75;
const TIMELINE_CONTENT_HEIGHT_REM = FULL_SCREEN_HEIGHT_REM * 0.75 - 6;
export const B2BTimelineSlide = (props: {
  displayOptions: B2BTimelineChartDisplayOptions;
}) => {
  const {info} = useActiveProject();
  if (!info?.timeline) {
    return null;
  }
  return (
    <NatFlex FULL_WIDTH NOGAP COLUMN NOWRAP style={{height: `min-content`}}>
      <div
        style={{
          maxHeight: `${TIMELINE_CONTENT_HEIGHT_REM * TIMELINE_PDF_SCALAR}rem`,
          minHeight: `${TIMELINE_CONTENT_HEIGHT_REM * TIMELINE_PDF_SCALAR}rem`,
          width: "100%",
        }}
      >
        <ProjectTimelineLayout
          {...props.displayOptions}
          pdfRenderFormat={true}
        />
      </div>
      <div>
        <PaymentScheduleInfo pdfRenderFormat={true} />
      </div>
    </NatFlex>
  );
};
