import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {IDesign} from "@natomas-org/core";

const useDesign = (id?: string) => {
  return useSelector((state: IStore) => {
    if (!id) {
      return {};
    } else {
      return state.design?.[id] ?? {id: id};
    }
  }) as IDesign;
};

export default useDesign;
