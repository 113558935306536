import React from "react";
import {EmptyPage} from "../_shared/generics/empty-page";
import {useNavigation} from "../_shared/hooks/useNavigation";
import {NavigationPaths} from "../_shared/hooks/useNavigation/paths";

export const DocumentView = () => {
  const {to} = useNavigation();
  return (
    <EmptyPage
      title={"Document View under development"}
      buttons={[
        {label: "PDFs", clickEvent: () => to(NavigationPaths.DOCUMENT_PDF)},
      ]}
    />
  );
};
