import React, {useCallback, useState} from "react";
import {
  scrollToElementWithOffset,
  setURLQuery,
} from "../../../../../_shared/navigation/_helpers";
import {URL_PARAM_KEY_PAGE_ID} from "@natomas-org/service";
import {PhotoViewDismiss} from "../../../../../_shared/generics/presentation/styles";
import {AiOutlineClose} from "react-icons/ai";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../../_shared/hooks/useDynamicValue";
import {HCPPresentationFooter} from "./components/HCPPresentationFooter";
import {
  PORTAL_BUDGET_ID,
  usePresentationSections,
} from "../../HCPTitleNav/navigation_ids";
import {FullScreenPresentationView} from "../../../../_shared/components/FullScreenPresentationView";
import {useDelay} from "../../../../../_shared/hooks/useDelay";
import {PROPOSAL_GENERATE_PDF} from "./components/constants";
import {Container} from "../styles";
import {FULL_SCREEN_WIDTH_REM} from "../../HCPUnitView/DesignSelections/constants";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";

export const DTCPresentationView = (props: IHCPPresentationViewProps) => {
  const {readyToPrint: readyToPrintDefault} = props;
  const fullWidth = useDynamicValue(fullColumnWidths);
  const {site} = useActiveProject();
  const scrollToSection = useCallback((sectionId: string) => {
    scrollToElementWithOffset(sectionId, {top: 0}, true);
  }, []);
  const fadeIn = useDelay({milliseconds: 300}).ready;
  // state for ready to print
  const [readyToPrint, setReadyToPrint] = useState<boolean>(
    readyToPrintDefault ?? false
  );
  const presentationSections = usePresentationSections();
  return (
    <Container
      fadeIn={fadeIn}
      style={
        readyToPrint
          ? {
              minWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
              maxWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
            }
          : {maxWidth: "99vw", overflowX: "clip"}
      }
    >
      <PROPOSAL_GENERATE_PDF.Provider value={readyToPrint}>
        <PhotoViewDismiss
          hidden={readyToPrint || props.hideClose}
          onClick={() => {
            setURLQuery(URL_PARAM_KEY_PAGE_ID, null);
          }}
        >
          <AiOutlineClose />
        </PhotoViewDismiss>

        {presentationSections
          .filter((section) => {
            return !(!site?.visible && section.value === PORTAL_BUDGET_ID);
          })
          .map((sectionInfo, index) => {
            return (
              <FullScreenPresentationView
                displayForPrint={readyToPrint}
                key={sectionInfo.value}
                headerTitle={sectionInfo?.label}
                width={fullWidth}
                sectionId={sectionInfo.value}
                background={sectionInfo.background}
                scrollToSection={scrollToSection}
                hideWhenGeneratingPDF={sectionInfo?.hideWhenGeneratingPDF}
                index={index}
                formatWithPageSystem={!props.hideClose}
              >
                {sectionInfo.component}
              </FullScreenPresentationView>
            );
          })}
        <HCPPresentationFooter
          readyToPrint={readyToPrint}
          setReadyToPrint={setReadyToPrint}
          scrollToSection={scrollToSection}
        />
      </PROPOSAL_GENERATE_PDF.Provider>
    </Container>
  );
};

interface IHCPPresentationViewProps {
  hideClose?: boolean;
  readyToPrint?: boolean;
}
