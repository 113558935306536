/*
 * This file is responsible for allowing the client to subscribe directly to the collection of tags within Firebase
 * */

import {subscribeToCollectionWithCallback} from "../utilities";
import {Utilities} from "../index";
import {PRODUCT_CATALOG_TAGS_COLLECTION_KEY} from "./constants";
import {store} from "../../../store";
import {setProductCatalogTags} from "../../../components/_shared/slices/TagsSlice";

const getProductCatalogCollectionRef = () => {
  return Utilities.collection(PRODUCT_CATALOG_TAGS_COLLECTION_KEY);
};

const storeProductCatalogTagsInState = (tags: any) => {
  store.dispatch(setProductCatalogTags(tags));
};

export const fetchProductCatalogTags = () => {
  return subscribeToCollectionWithCallback(
    getProductCatalogCollectionRef(),
    (tags) => {
      storeProductCatalogTagsInState(tags);
    }
  );
};
