import React from "react";
import {WHITE} from "../../colors";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../page/components/PageContainer";
import {PageElement} from "../page/components/PageElement";
import {HeaderContent, HeaderTitle} from "./styles";
import {PreDepositProgressBar} from "../../../portal/_shared/components/PreDepositProgressBar/PreDepositProgressBar";
import {usePage} from "../../hooks/usePage";
import {isBlankString} from "@natomas-org/core";
import {useProgressBar} from "../../hooks/useProgressBar";

export const Header = (props: {
  children: any;
  height: string;
  width: number;
  hasBorder: boolean;
  titleChildren?: any;
  title?: string | JSX.Element;
  logo?: any;
  noPadding?: boolean;
  hideProgressBar?: boolean;
  transparent?: boolean;
  backgroundColor?: string;
  titleStyle?: React.CSSProperties;
}) => {
  const {
    titleChildren,
    children,
    logo,
    width,
    title,
    noPadding,
    hideProgressBar,
    transparent,
    hasBorder,
    height,
    backgroundColor,
  } = props;

  const {isNatMobile} = usePage();
  const {showProgressBar} = useProgressBar();

  const getTitleContent = () => {
    return (
      <div
        hidden={!logo && !titleChildren}
        style={{
          display: "flex",
          width: "min-content",
        }}
      >
        {!!logo && logo}
        {!!titleChildren && titleChildren}
      </div>
    );
  };

  return (
    <>
      <PageContainer
        border={hasBorder}
        backgroundColor={
          backgroundColor
            ? backgroundColor
            : transparent
            ? "rgba(0,0,0,0)"
            : WHITE
        }
        overridePadding={noPadding}
        stickyOptions={{height: height, mode: PageContainerStickyMode.HEADER}}
      >
        <PageElement size={width} height={height}>
          <HeaderContent isMobile={isNatMobile}>
            {getTitleContent()}
            <HeaderTitle
              hidden={
                !title || (typeof title === "string" && isBlankString(title))
              }
              lineHeight={height}
              isMobile={isNatMobile}
              style={props.titleStyle}
            >
              {title}
            </HeaderTitle>
            {children}
          </HeaderContent>
        </PageElement>
      </PageContainer>
      {showProgressBar && !hideProgressBar && (
        <PreDepositProgressBar hasBorder={true} />
      )}
    </>
  );
};
