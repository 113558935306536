import React from "react";
import {NatCheckCircleFill} from "../../../../../../../_shared/icon/icons";
import {FONT_FAMILY_MEDIUM} from "../../../../styles/globals";
import {GRANITE, VILLA_APPLE_GREEN} from "../../../../../../../_shared/colors";
import {NatLink} from "../../../../../../../_shared/generics/button";
import {NatSize} from "../../../../../../../_shared/generics/_shared";
import {BannerContainer, BannerTitleContainer} from "./styles";
import {useNavigation} from "../../../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../../../_shared/hooks/useNavigation/paths";

export const ThankYouBanner = () => {
  const {to} = useNavigation();
  return (
    <BannerContainer>
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          rowGap: "1.5rem",
        }}
      >
        <BannerTitleContainer>
          <div>
            <NatCheckCircleFill
              style={{color: VILLA_APPLE_GREEN}}
              size={"4rem"}
            />
          </div>
          <div style={{fontFamily: FONT_FAMILY_MEDIUM, fontSize: "1.5rem"}}>
            Thank you for your deposit!
          </div>
        </BannerTitleContainer>
        <div style={{color: GRANITE}}>
          Check your email for a summary of next steps. We can’t wait to start
          working with you!
        </div>
        <div>
          <NatLink
            label={"Take me to my dashboard"}
            size={NatSize.NORMAL}
            style={{fontStyle: "italic"}}
            clickEvent={() => {
              to(NavigationPaths.PORTAL);
            }}
          />
        </div>
      </div>
    </BannerContainer>
  );
};
