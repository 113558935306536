import {NatToggleSelectorOption} from "../../../../../../../../../../../_shared/generics/toggle-selector/interfaces";
import React, {Dispatch, SetStateAction, useMemo} from "react";
import {NatToggleSelector} from "../../../../../../../../../../../_shared/generics/toggle-selector";
import {StudioEditorMode} from "../../../index";

export const FactoryInventoryEditingModeToggle = (props: {
  mode: StudioEditorMode;
  setMode: Dispatch<SetStateAction<StudioEditorMode>>;
}) => {
  const {mode, setMode} = props;

  const options: NatToggleSelectorOption[] =
    useMemo((): NatToggleSelectorOption[] => {
      return [
        getToggleOption(StudioEditorMode.EDIT, mode, setMode),
        getToggleOption(StudioEditorMode.MOVE, mode, setMode),
        getToggleOption(StudioEditorMode.DELETE, mode, setMode),
      ];
    }, [mode, setMode]);

  return <NatToggleSelector options={options} />;
};

const getToggleOption = (
  mode: StudioEditorMode,
  currentMode: StudioEditorMode,
  setMode: Dispatch<SetStateAction<StudioEditorMode>>
) => {
  return {
    id: `${mode}-mode`,
    label: mode,
    isSelected: mode === currentMode,
    clickEvent: () => {
      if (mode !== currentMode) {
        setMode(mode);
      }
    },
  };
};
