import {VILLA_APPLE_GREEN, VILLA_BLUE} from "./colors";

export const DEFAULT_SHADOW = `rgba(60, 64, 67, 0.3) 0 1px 2px 0,
    rgba(60, 64, 67, 0.15) 0 2px 6px 2px`;

export const FOCUS_SHADOW = `rgba(60, 64, 67, 0.15) 0 1px 1px 0,
    rgba(60, 64, 67, 0.1) 0 2px 3px 2px`;

export const STEPH_SHADOW = `#A2A2A2 0 0 0.5px 0, #E4E4E4 0 4px 17px`;
export let GREEN_SHADOW = `0 0 0 2px ${VILLA_APPLE_GREEN}`;

export const GREEN_FOCUS_SHADOW = `${VILLA_BLUE}80 0 0.5px 0.5px 0, ${VILLA_BLUE}80 0 1px 1.5px 1px`;
