import React from "react";
import Form, {IInstantQuoteProductLocationForm} from "./form";
import {
  IInstantQuote,
  IInstantQuoteLocation,
} from "../../../api/InstantQuote/interface";
import {InstantQuoteProductLocationSchema} from "@natomas-org/villa-nexus-client";
import {getAdminInstantQuoteService} from "../../../api/Admin/InstantQuote";
import useAdminInstantQuoteByLocation from "../../../api/Admin/InstantQuote/useAdminInstantQuoteByLocation";

const editProductLocationInstantQuotePoint = async (
  productId: string,
  schema: InstantQuoteProductLocationSchema
): Promise<boolean> => {
  const service = await getAdminInstantQuoteService();
  let response = false;
  try {
    await service.update(productId, schema).then((res) => {
      response = true;
    });
  } catch (error) {
    window.alert("Error creating instant quote location");
  }
  return response;
};

export const LocationPermitPlansLabelSlug = "permits_and_plans";
export const LocationSitePreparationFoundationLabelSlug =
  "site_preparation_and_foundation";
export const LocationUtilitiesLabelSlug = "utilities";
export const LocationVillaProjectManagementLabelSlug =
  "villa_project_management";
export const LocationStandardFinishingLabelSlug = "standard_finishing";

export const getLocationLabelFromKey = (key?: string): string => {
  switch (key) {
    case LocationPermitPlansLabelSlug:
      return "Permits & plans";
    case LocationSitePreparationFoundationLabelSlug:
      return "Site preparation & foundation";
    case LocationUtilitiesLabelSlug:
      return "Utilities";
    case LocationVillaProjectManagementLabelSlug:
      return "Villa project management";
    case LocationStandardFinishingLabelSlug:
      return "Standard finishing";
    default:
      return key ?? "";
  }
};

const getFormData = (
  instantQuote: IInstantQuote | null
): IInstantQuoteProductLocationForm | null => {
  if (!instantQuote) {
    return null;
  }
  return {
    "location-id": instantQuote.uuid,
    "location-name": instantQuote.label,
    "location-latitude": parseFloat(instantQuote.latitude) ?? 0,
    "location-longitude": parseFloat(instantQuote.longitude) ?? 0,
    "location-permits-plans":
      instantQuote.items?.find(
        (item) => item.slug === LocationPermitPlansLabelSlug
      )?.price ?? 0,
    "location-construction-installation":
      instantQuote.items?.find(
        (item) => item.slug === LocationSitePreparationFoundationLabelSlug
      )?.price ?? 0,
    "location-utilities":
      instantQuote.items?.find(
        (item) => item.slug === LocationUtilitiesLabelSlug
      )?.price ?? 0,
    "location-project-management":
      instantQuote.items?.find(
        (item) => item.slug === LocationVillaProjectManagementLabelSlug
      )?.price ?? 0,
    "location-standard-finishing":
      instantQuote.items?.find(
        (item) => item.slug === LocationStandardFinishingLabelSlug
      )?.price ?? 0,
  };
};

const EditLocation = (props: {
  location: IInstantQuoteLocation;
  cancel: () => void;
  afterSubmission: () => void;
}) => {
  const {location, cancel, afterSubmission} = props;
  const instantQuote = useAdminInstantQuoteByLocation(location);
  const formData: IInstantQuoteProductLocationForm | null =
    getFormData(instantQuote);
  if (!formData) {
    return null;
  }

  return (
    <Form
      softLocked={true}
      hidden={false}
      hide={() => cancel()}
      action={async (schema: InstantQuoteProductLocationSchema) => {
        const response = await editProductLocationInstantQuotePoint(
          location.product_id,
          schema
        );
        if (response) {
          afterSubmission();
        }
        return response;
      }}
      initialFormData={formData}
    />
  );
};

export default EditLocation;
