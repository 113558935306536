import {PageContainer} from "../../../../../../_shared/generics/page/components/PageContainer";
import {FAQs} from "../../../../../_shared/components/FAQs";
import React from "react";

export const FAQAndScheduleAssessment = (props: {defaultExpanded: boolean}) => {
  return (
    <PageContainer>
      <FAQs defaultExpanded={props.defaultExpanded} />
    </PageContainer>
  );
};
