import React from "react";
import {Helmet} from "react-helmet-async";
//Core
//Styles
//Shared
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../_shared/generics/page/components/PageContainer";
import {useGlobalConfiguration} from "../../../_shared/hooks/useGlobalConfiguration";
// Feasibility
import {useCustomerStatus} from "../../../_shared/hooks/useCustomerStatus";
//Filters
import {FAQAndScheduleAssessment} from "../../../portal/views/SSDDashboardView/components/ANDY-components/FAQAndScheduleAssessment";
import {RelatedProjects} from "../../_shared/components/RelatedProjects";
import {useProgressBar} from "../../../_shared/hooks/useProgressBar";
import {PROGRESS_BAR_HEIGHT} from "../../../_shared/generics/progress-bar/ProgressBar";
import {useCatalogSession} from "../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {SmartCatalogHeading} from "../../../map/_shared/components/ParcelInfo";
import {useAddress} from "../../../_shared/hooks/useAddress";
import {useIsPublicApp} from "../../../_shared/hooks/useIsPublicApp";
import {NoHomesResetFiltersCTA} from "./components/NoHomesResetFiltersCTA";
import {Filters} from "../../_shared/components/CatalogBar/MapCatalogBar";
import {WHITE} from "../../../_shared/colors";
import {HEADER_Z_INDEX} from "../../../_shared/styles";
import {ProductCards} from "./components/ProductCards";
import {OurSeries} from "./components/Carousel/OurSeries";
import {usePage} from "../../../_shared/hooks/usePage";
import {useUrl} from "../../../_shared/hooks/useUrl";
import {MAP_PATH} from "../../../../paths";

export const MainView = () => {
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {SHOW_FAQ_EVERYWHERE} = useGlobalConfiguration();
  const {sortBy} = useCatalogSession();
  const {getURLPath} = useUrl();
  const {isNatMobile} = usePage();
  const {showProgressBar} = useProgressBar();
  const {catalogAddress, projectAddress} = useAddress();
  const isPublicCatalog = useIsPublicApp();
  const getOffset = (): string => {
    // Add top bump for progress bar
    let total = 103;
    if (showProgressBar) {
      total += PROGRESS_BAR_HEIGHT;
    }
    return total + "px";
  };

  return (
    <>
      <Helmet>
        <title>Villa | Product Catalog</title>
      </Helmet>
      <SmartCatalogHeading
        allowEditingAddress={isPublicCatalog}
        includeProductSelectionPrompt={isPublicCatalog}
        includeFilterAndSortBanner={true}
        includeJurisdictionText={false}
        verticalSpacingRem={1.5}
        address={catalogAddress ?? projectAddress ?? undefined}
        offset={getOffset()}
        isSticky={true}
        hideHr={true}
        hideFilter={true}
      />
      <div style={{height: "1rem"}} />
      <OurSeries />
      {!isNatMobile && !getURLPath()?.includes(MAP_PATH) && (
        <div style={{height: "4rem"}} />
      )}
      <div style={{height: isNatMobile ? "40px" : 0}} />
      <PageContainer
        FLEX
        stickyOptions={{
          mode: PageContainerStickyMode.MODAL,
          offset: "6rem",
          zIndex: HEADER_Z_INDEX - 100,
        }}
        backgroundColor={WHITE}
      >
        <Filters />
      </PageContainer>
      <PageContainer>
        <NoHomesResetFiltersCTA />
        <ProductCards />
        <RelatedProjects
          title={"Our completed Villa homes"}
          description={`We've completed hundreds of homes across CA. Explore the possibilities.`}
          productSort={sortBy}
          includeHorizontalRule={true}
          formatDisplayForMap={false}
        />
      </PageContainer>
      {SHOW_FAQ_EVERYWHERE && isCustomerPreDeposit && (
        <FAQAndScheduleAssessment defaultExpanded={false} />
      )}
    </>
  );
};
