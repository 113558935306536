import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../portal/views/SSDDashboardView/styles/globals";

export const EmailInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding-bottom: 1rem;
  column-gap: 20px;
`;
export const DropdownContainer = styled.div`
  justify-content: center;
  width: 100%;
  display: flex;
`;
export const FormSubtitle = styled.div`
  padding-top: 1rem;
  text-decoration: underline;
  font-family: ${FONT_FAMILY_BOLD};
  display: flex;
  column-gap: 10px;
`;
export const Error = styled.div`
  color: red;
`;

export const SendDocusignFormImagesContainer = styled.div`
  display: flex;
  column-gap: 2rem;
  flex-direction: row;
  padding-bottom: 2rem;
`;
export const SendDocusignFormImageTitleContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
  cursor: pointer;
`;
