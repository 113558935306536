import calendar from "../../../../assets/icons/pngs/calendarIcon16.png";
import clock from "../../../../assets/icons/pngs/clockIcon16.png";
import React, {useContext} from "react";
import {
  MilestoneSummary,
  MilestoneSummaryContainer,
  MilestoneSummaryDetails,
  MilestoneSummaryIcon,
  MilestoneSummaryWrapper,
  TimelineContainer,
  TimeLineFooter,
  TimeLineHeader,
  TimeLineHeaderETA,
  TimeLineHeaderETAComponent,
  TimelineLeftColumn,
  TimeLineParent,
  TimelineRightColumn,
  TimelineRowTop,
  TimelineTable,
  TimelineToolTipContent,
  TimelineToolTipFooter,
  TimelineToolTipTitle,
} from "./styles";
import {
  monthDiff,
  monthStringToQuarterNumber,
  NextStepsTimelineRow,
  TimelineStageInfo,
} from "./components/NextStepsTimeline";
import {
  getActualProjectTimelineInfo,
  getProductionTimelineInfo,
  ITimelineDisplayInfo,
  offsetDateByOneMonth,
} from "./constants/timelineHelpers";
import {
  ALL_TRANSITION_ADAPTER_INFO,
  DateUtils,
  getDefaultTimelineEditorValues,
  Project,
  shallowEquals,
} from "@natomas-org/core";
import NatLabel, {NatLabelType} from "../../../_shared/generics/label/NatLabel";
import {useSelectedFactoryLine} from "../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../_shared/hooks/useDesign/useActiveDesign";
import {getTimelinePropsFromProjectTimelineDictionary} from "../../../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/ProposalViewerEditor/TimelineEditor/helpers";
import {PROPOSAL_GENERATE_PDF} from "../HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {TIMELINE_PDF_SCALAR} from "../ProposalBudgetView/components/Presentation/B2BTimelineSlide/B2BTimelineSlide";
import {
  ALL_MONTHS,
  IProjectTimelineProps,
  IStepMilestone,
  ITimelineProps,
} from "./constants/TimelineTypes";

export const ProjectTimelineLayout = (props: IProjectTimelineProps) => {
  const {info, isDevelopmentByVilla} = useActiveProject();
  const {configuration} = useActiveDesign();
  const {details} = useSelectedFactoryLine();
  const {pdfRenderFormat} = props;
  if (!info) {
    return null;
  }
  const transitionEvent = Project.getEvent(
    info,
    ALL_TRANSITION_ADAPTER_INFO.event_id
  );

  let startDate = offsetDateByOneMonth(
    transitionEvent != null
      ? new Date(transitionEvent.start_timestamp)
      : new Date()
  );

  let timelineInfo: ITimelineDisplayInfo;
  let projectInfo = info;
  if (
    (!projectInfo?.timeline || shallowEquals(projectInfo?.timeline, {})) &&
    isDevelopmentByVilla
  ) {
    projectInfo = Object.assign({}, projectInfo, {
      timeline: getDefaultTimelineEditorValues(
        projectInfo?.created_date ??
          projectInfo?.last_updated_date ??
          new Date().getTime()
      ),
    });
  }
  if (transitionEvent != null || isDevelopmentByVilla) {
    timelineInfo = getActualProjectTimelineInfo(info, isDevelopmentByVilla);
  } else {
    timelineInfo = getProductionTimelineInfo(
      details,
      configuration?.product,
      startDate,
      isDevelopmentByVilla
    );
  }
  const {overallEndDate, nextStepsInfo} = timelineInfo;

  // Normalize the start date to be the 1st day of the month
  // Normalize the end date to be the last day of the month
  // This is needed for proper rendering/calculation of the calendar component
  startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  const standardizedEndDate = new Date(
    overallEndDate.getFullYear(),
    overallEndDate.getMonth() + 1,
    -1
  );

  const timelineProps: ITimelineProps = {
    startDate,
    totalMonths: monthDiff(startDate, overallEndDate),
    nextStepsInfo,
    overallEndDate: standardizedEndDate,
    actualTimeline: transitionEvent != null,
    displayQuarters: isDevelopmentByVilla,
    ...props,
  };
  let modifiedProps = timelineProps;
  if (projectInfo?.timeline && isDevelopmentByVilla) {
    modifiedProps = getTimelinePropsFromProjectTimelineDictionary(
      projectInfo.timeline
    );
  }
  return (
    <div
      style={
        pdfRenderFormat
          ? {
              width: `${100 / TIMELINE_PDF_SCALAR}%`,
              transform: `scale(${TIMELINE_PDF_SCALAR})`,
              transformOrigin: "top left",
            }
          : undefined
      }
    >
      <DesktopTimeline {...modifiedProps} />
    </div>
  );
};

const DesktopTimeline = (props: ITimelineProps) => {
  const {
    totalMonths,
    nextStepsInfo,
    startDate: propsStartDate,
    overallEndDate,
    actualTimeline,
  } = props;
  const dayDuration = 24 * 60 * 60 * 1000;
  const monthDuration = dayDuration * 30;
  const startDate = new Date(propsStartDate);
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);
  // Subtract 1 since proposal stage is technically in the past
  const estimatedMonthsString = DateUtils.getTimeDifferenceString(
    startDate.getTime(),
    overallEndDate.getTime() - dayDuration / monthDuration
  );
  const targetMonth = ALL_MONTHS[overallEndDate.getMonth()];
  const targetYear = overallEndDate.getFullYear();
  const today = new Date();
  const normalizedDuration = overallEndDate.getTime() - startDate.getTime();
  let startPercent =
    (today.getTime() - startDate.getTime()) / normalizedDuration;
  if (props.displayQuarters) {
    startPercent =
      (today.getTime() + monthDuration - startDate.getTime()) /
      normalizedDuration;
  }
  const milestones: IStepMilestone[] = nextStepsInfo
    .map((info) => {
      return info.milestones ?? [];
    })
    .flat();
  let estimatedTimeline = estimatedMonthsString;
  let targetCompletionString = targetMonth + " " + targetYear;
  if (props.displayQuarters) {
    const startDateFromFirstOfMonth = new Date(startDate.getTime());
    startDateFromFirstOfMonth.setDate(1);
    const quarterTimeline =
      DateUtils.getTimeDifferenceMonthsNumber(
        startDateFromFirstOfMonth.getTime(),
        overallEndDate.getTime()
      ) / 3;
    let estimatedTimelinePrefix = "";
    const remainingFraction = quarterTimeline % 1;
    if (remainingFraction === 0) {
    } else if (0.9 < remainingFraction && remainingFraction < 0.1) {
      estimatedTimelinePrefix = "~";
    } else if (remainingFraction > 0.6) {
      estimatedTimelinePrefix = "<";
    } else if (remainingFraction < 0.4) {
      estimatedTimelinePrefix = ">";
    }
    estimatedTimeline = `${estimatedTimelinePrefix} ${Math.round(
      quarterTimeline
    )} Quarters`;
    if (quarterTimeline === 1) {
      estimatedTimeline = estimatedTimeline.replace("Quarters", "Quarter");
    }
    const targetCompletionDate = new Date(startDate.getTime());
    targetCompletionDate.setMonth(
      targetCompletionDate.getMonth() + quarterTimeline * 3
    );
    targetCompletionString = `Q${monthStringToQuarterNumber(
      ALL_MONTHS[targetCompletionDate.getMonth()]
    )} ${targetCompletionDate.getFullYear()}`;
  }
  return (
    <TimeLineParent>
      <TimeLineHeader>
        <TimeLineHeaderETA>
          <TimeLineHeaderETAComponent>
            <img alt="Clock" src={clock} width={18} />
            <div style={{color: "rgba(0, 0, 0, 0.6)"}}>Estimated timeline:</div>
            <div className={"bold"}>{estimatedTimeline}</div>
          </TimeLineHeaderETAComponent>
          <span style={{marginRight: "10px"}}> | </span>
          <TimeLineHeaderETAComponent>
            <img alt="Calendar" src={calendar} width={18} />
            <div style={{color: "rgba(0, 0, 0, 0.6)"}}>Target Completion:</div>
            <div className={"bold"}>{targetCompletionString}</div>
          </TimeLineHeaderETAComponent>
        </TimeLineHeaderETA>
      </TimeLineHeader>
      <TimelineContainer>
        <TimelineLeftColumn>
          <TimelineRowTop />
          {nextStepsInfo.map((info: any) => (
            <TimelineStageInfo key={info.id} info={info} />
          ))}
        </TimelineLeftColumn>
        <TimelineRightColumn forPrint={isGeneratingPDF}>
          <TimelineTable>
            <NextStepsTimelineRow
              totalNumberOfMonths={totalMonths}
              overallStartDate={startDate}
              overallEndDate={overallEndDate}
              displayQuarters={props.displayQuarters}
            />
            {nextStepsInfo.map((info, idx) => (
              <NextStepsTimelineRow
                key={idx}
                info={info}
                displayQuarters={props.displayQuarters}
                overallStartDate={startDate}
                totalNumberOfMonths={totalMonths}
                overallEndDate={overallEndDate}
              />
            ))}
            <div
              style={{
                height: "calc(100% - 40px)",
                width: "1px",
                borderRight: "1.5px dashed black",
                position: "absolute",
                top: "40px",
                left: startPercent * 100 + "%",
              }}
            />
          </TimelineTable>
        </TimelineRightColumn>
      </TimelineContainer>
      <TimeLineFooter hidden={actualTimeline}>
        Jurisdiction timelines may vary*
      </TimeLineFooter>
      <MilestoneSummary hidden={!props.expandMilestones}>
        <NatLabel label={"Payment Schedule"} type={NatLabelType.H3} />
        <MilestoneSummaryContainer>
          {milestones.map((milestone) => {
            return (
              <MilestoneSummaryWrapper
                key={milestone.milestone_index + "-milestone-summary"}
              >
                <MilestoneSummaryIcon style={{marginTop: "1rem"}}>
                  {milestone.milestone_index}
                </MilestoneSummaryIcon>
                <MilestoneSummaryDetails>
                  <TimelineToolTipTitle>{milestone.title}</TimelineToolTipTitle>
                  <TimelineToolTipContent>
                    {milestone.description}
                  </TimelineToolTipContent>
                  <TimelineToolTipFooter>
                    {milestone.footer}
                  </TimelineToolTipFooter>
                </MilestoneSummaryDetails>
              </MilestoneSummaryWrapper>
            );
          })}
        </MilestoneSummaryContainer>
      </MilestoneSummary>
    </TimeLineParent>
  );
};
