import {InputTextField} from "../../admin/_shared/styles/AdminStyles";
import styled from "styled-components";
import {VILLA_BLUE} from "../colors";

const Container = styled.div`
  display: flex;
`;

const DimensionContainer = styled.div`
  margin-right: 20px;
  width: fit-content;
  display: flex;
`;

export const FeetInchesInput = (props) => {
  let {feet, inches, setOutput, enabled} = props;

  if (isNaN(feet)) {
    feet = 0;
  }
  if (isNaN(inches)) {
    inches = 0;
  }

  const markOutput = (feet, inches) => {
    setOutput({
      feet: feet,
      inches: inches,
    });
  };

  const handleFeet = (e) => {
    let {value, min, max} = e.target;
    value = parseInt(value);
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    markOutput(value, inches);
  };

  const handleInches = (e) => {
    let {value, min, max} = e.target;
    value = parseInt(value);
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    markOutput(feet, value);
  };

  return (
    <Container>
      <DimensionContainer>
        <InputTextField
          disabled={!enabled}
          onWheel={(e) => e.target.blur()}
          value={Number(feet).toString()}
          onChange={handleFeet}
          type="number"
          min="0"
          max="250"
          style={{textAlign: "right"}}
        />
        <div
          style={{
            paddingRight: "0.5rem",
            borderBottom: "1px solid " + VILLA_BLUE,
          }}
        >
          '
        </div>
      </DimensionContainer>
      <DimensionContainer>
        <InputTextField
          disabled={!enabled}
          onWheel={(e) => e.target.blur()}
          value={Number(inches).toString()}
          onChange={handleInches}
          type="number"
          min="0"
          max="11"
          style={{textAlign: "right"}}
        />
        <div
          style={{
            paddingRight: "0.5rem",
            borderBottom: "1px solid " + VILLA_BLUE,
          }}
        >
          "
        </div>
      </DimensionContainer>
    </Container>
  );
};
