import {MatrixTable} from "../MatrixTable";
import React, {Dispatch, SetStateAction, useMemo} from "react";
import {
  MODIFIERS_FETCH_ID,
  PRODUCTS_FETCH_ID,
} from "../../../../../../../database/firebase/catalog/fetching";
import {useAdminProductCatalog} from "../../../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";

export interface MatrixTabTableProps {
  loading: boolean;
  matrixEdits: any;
  searchText: string | undefined;
  setMatrixEdits: Dispatch<SetStateAction<any>>;
  setTranspose: Dispatch<SetStateAction<boolean>>;
  transpose: boolean;
  subTabMode: boolean;
}

export const MatrixTabTable = (props: MatrixTabTableProps) => {
  const {
    loading,
    matrixEdits,
    setMatrixEdits,
    searchText,
    setTranspose,
    transpose,
    subTabMode,
  } = props;
  const {isFetching} = useAdminProductCatalog();

  const isLoading = useMemo(() => {
    if (loading) {
      return true;
    } else {
      return isFetching[MODIFIERS_FETCH_ID] || isFetching[PRODUCTS_FETCH_ID];
    }
  }, [loading, isFetching]);

  return (
    <MatrixTable
      heightOffset={!subTabMode ? "64px" : "129px"}
      loading={isLoading}
      matrixEdits={matrixEdits}
      searchText={searchText}
      setMatrixEdits={setMatrixEdits}
      setTranspose={setTranspose}
      transpose={transpose}
    />
  );
};
