import {IEmployee} from "./index";

export const sortEmployeesByName = (employees: IEmployee[]) => {
  return employees
    ?.filter((x: IEmployee) => !!x.authInfo)
    ?.sort((a: IEmployee, b: IEmployee): number => {
      let aName = a?.authInfo?.displayName;
      let bName = b?.authInfo?.displayName;
      if (!aName || !bName) {
        return 0;
      }
      return aName.localeCompare(bName);
    });
};
