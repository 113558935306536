import React, {useMemo} from "react";
import _ from "lodash";
import styled from "styled-components";
import {ADMIN_EDIT} from "../../../../../../../../../../../_shared/colors";

interface FieldHighlightProps {
  children: any;
  initialValue?: any;
  value?: any;
}

const FieldHighlightContainer = styled.div<{isEdited: boolean}>`
  background-color: ${(props) => (props.isEdited ? ADMIN_EDIT : undefined)};
`;

const FieldHighlight = (props: FieldHighlightProps) => {
  const {initialValue, value, children} = props;
  const isEdited = useMemo(() => {
    return !_.isEqual(initialValue, value);
  }, [value, initialValue]);
  return (
    <FieldHighlightContainer isEdited={isEdited}>
      {children}
    </FieldHighlightContainer>
  );
};

export default FieldHighlight;
