import {
  editCategory,
  editModifier,
  editModifierGroup,
  editProduct,
} from "../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {toPage} from "../../../portal/_shared/navigation/PageNavigation";
import {getStore} from "../../../../store";
import {isBlankString} from "@natomas-org/core";

export const viewCategory = (category: any) => {
  getStore().dispatch(editCategory(category));
  toPage("categories");
};

export const viewModifier = (modifier: any) => {
  getStore().dispatch(editModifier(modifier));
  toPage("modifiers");
};

export const viewModifierGroup = (modifierGroup: any) => {
  getStore().dispatch(editModifierGroup(modifierGroup));
  toPage("modifierGroups");
};

export const viewProduct = (product: any) => {
  getStore().dispatch(editProduct(product));
  toPage("products");
};

export const getInternalTitle = (target: any) => {
  return !isBlankString(target.internal_title) ? target.internal_title : "*";
};

export const getTitle = (target: any) => {
  return !isBlankString(target.title) ? target.title : "*";
};

export const containsSearchText = (title: string, searchText?: string) => {
  if (!searchText) {
    return true;
  } else if (!title) {
    return false;
  }
  let t = title.toLowerCase().replace(/\s/g, "");
  let s = searchText.toLowerCase().replace(/\s/g, "");
  return t.includes(s);
};
