import "firebase/auth";
import {auth, Configuration, Utilities} from "../index";
import {
  allowFetchFromUser,
  PROJECT_SMARTSHEET_COLLECTION_KEY,
} from "@natomas-org/core";
import {
  loadedConfiguration,
  loadedConfigurationMap,
  loadedConfigurationSite,
  loadedConfigurationSnapshot,
  loadedProjectDocuments,
  loadedProjectNotes,
  loadedProjectOpportunity,
  loadedProjectSmartsheet,
  loadedProjectSummary,
  setEditingSelections,
} from "../../../components/_shared/slices/ConfigurationSlice";
import {store} from "../../../store";
import {executeRequest} from "../api";
import {
  subscribeToCollectionWithCallback,
  subscribeWithCallback,
} from "../utilities";
import {
  PROJECT_COLLECTION_KEY,
  PROJECT_DOCUMENT_COLLECTION_KEY,
  PROJECT_NOTES_COLLECTION_KEY,
  PROJECT_OPPORTUNITY_COLLECTION_KEY,
} from "../project/constants";
import {CONFIGURATION_SNAPSHOTS_DB_KEY} from "./configuration";

const configurationsLoaded = {};

export const loadConfiguration = async (forceFetch, projectId) => {
  const currentConfigurationId =
    projectId ?? store.getState().configuration.currentConfigurationId;
  if (currentConfigurationId != null) {
    const currentUser = store.getState().global.user;

    if (
      allowFetchFromUser(currentUser?.uid, currentConfigurationId, forceFetch)
    ) {
      return fetchConfiguration(currentConfigurationId);
    }
  }
};

export async function fetchConfiguration(configurationId) {
  const configurationsRef = Utilities.collection("configurations");
  configurationsRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configuration = snapshot.data();
      if (configuration != null) {
        configuration.id = snapshot.id;
        if (
          configurationsLoaded[snapshot.id] !== true &&
          configuration.product != null
        ) {
          configurationsLoaded[snapshot.id] = true;
        }

        if (configuration.configurationSnapshot != null) {
          store.dispatch(
            loadedConfigurationSnapshot({
              snapshot: configuration.configurationSnapshot,
              id: snapshot.id,
            })
          );
        }
      }
      if (!!configuration) {
        store.dispatch(loadedConfiguration(configuration));
      }
    },
    (error) => {
      console.log(error);
    }
  );

  const configurationSnapshotsRef = Utilities.collection(
    CONFIGURATION_SNAPSHOTS_DB_KEY
  );
  configurationSnapshotsRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configuration = snapshot.data();
      if (configuration != null) {
        configuration.id = snapshot.id;
        store.dispatch(setEditingSelections(false));
        store.dispatch(loadedConfigurationSnapshot(configuration));
      } else {
        store.dispatch(setEditingSelections(true));
        store.dispatch(loadedConfigurationSnapshot(null));
      }
    },
    (error) => {
      console.log(error);
    }
  );

  subscribeWithCallback(
    Utilities.collection(PROJECT_OPPORTUNITY_COLLECTION_KEY).doc(
      configurationId
    ),
    (data) => {
      store.dispatch(loadedProjectOpportunity(data));
    }
  );

  subscribeWithCallback(
    Utilities.collection(PROJECT_SMARTSHEET_COLLECTION_KEY).doc(
      configurationId
    ),
    (data) => {
      store.dispatch(loadedProjectSmartsheet(data));
    }
  );

  subscribeWithCallback(
    Utilities.collection(PROJECT_COLLECTION_KEY).doc(configurationId),
    (data) => {
      store.dispatch(loadedProjectSummary(data));
    }
  );
  subscribeToCollectionWithCallback(
    Utilities.collection(PROJECT_COLLECTION_KEY)
      .doc(configurationId)
      .collection(PROJECT_NOTES_COLLECTION_KEY),
    (data) => {
      store.dispatch(loadedProjectNotes(data));
    }
  );
  subscribeToCollectionWithCallback(
    Utilities.collection(PROJECT_COLLECTION_KEY)
      .doc(configurationId)
      .collection(PROJECT_DOCUMENT_COLLECTION_KEY),
    (data) => {
      store.dispatch(loadedProjectDocuments(data));
    }
  );

  const configurationMapRef = Utilities.collection(
    Configuration.Constants.CONFIGURATION_MAPS_DB_KEY
  );
  configurationMapRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configurationMap = snapshot.data();
      if (configurationMap != null) {
        configurationMap.id = snapshot.id;
      }
      store.dispatch(loadedConfigurationMap(configurationMap));
    },
    (error) => {
      console.log(error);
    },
    () => {}
  );

  const configurationSiteRef = Utilities.collection(
    Configuration.Constants.CONFIGURATION_SITE_DB_KEY
  );
  configurationSiteRef.doc(configurationId).onSnapshot(
    (snapshot) => {
      const configurationSite = snapshot.data();
      if (configurationSite != null) {
        configurationSite.id = snapshot.id;
      }
      store.dispatch(loadedConfigurationSite(configurationSite ?? null));
    },
    (error) => {
      console.log(error);
    },
    () => {}
  );

  if (auth.currentUser) {
    return executeRequest("/project/v1/synchronize", {
      projectId: configurationId,
    }).then((data) => {
      console.log(data);
    });
  }
}

export const generateProjectPDF = (configurationId) => {
  return executeRequest("/project/v1/generateAndFetchPSA", {
    projectId: configurationId,
  }).then((data) => {
    return data?.pdfStoragePath;
  });
};

export const getProjectPSA = async (projectId) => {
  const data = await executeRequest("/project/v1/generateAndFetchPSA", {
    projectId,
  });
  return data?.pdfStoragePath;
};
