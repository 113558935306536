import React, {Suspense, useMemo} from "react";
import {IFactorySummary} from "../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {InfoSetItem} from "../../shared/interfaces";
import {INTERNAL_CATEGORY_KEY, LABEL_KEY} from "../../shared/constants";
import {
  ReadOnlyFactoryInfoSetContainer,
  ReadOnlyFactoryInfoSetFactoryInternalTitle,
  ReadOnlyFactoryInfoSetFactoryTitle,
  ReadOnlyFactoryInfoSetInfo,
  ReadOnlyFactoryInfoSetItems,
} from "./styles";
import {shouldShowCategoryLink} from "./helper";
import {ReadOnlyFactoryInfoSetDirectory} from "./ReadOnlyFactoryInfoSetDirectory";
import {LoadingPanel} from "../../../_shared/generics/loading-panel";
import ReadOnlyFactoryInfoSetItem from "../ReadOnlyFactoryInfoSetItemSection/ReadOnlyFactoryInfoSetItem";

// const ReadOnlyFactoryInfoSetItemComponent = React.lazy(
//   () =>
//     import("./../ReadOnlyFactoryInfoSetItemSection/ReadOnlyFactoryInfoSetItem")
// );

export const ReadOnlyFactoryInfoSet = (props: {factory?: IFactorySummary}) => {
  const {factory} = props;
  const {info, infoSet, details} = factory ?? {};

  const items: InfoSetItem[] = useMemo(() => {
    const infoSetItems = Object.values(infoSet ?? {});
    return infoSetItems?.sort((a: InfoSetItem, b: InfoSetItem): number => {
      const category = a[INTERNAL_CATEGORY_KEY].localeCompare(
        b[INTERNAL_CATEGORY_KEY]
      );
      if (category !== 0) {
        return category;
      } else {
        return a[LABEL_KEY].localeCompare(b[LABEL_KEY]);
      }
    });
  }, [infoSet]);

  if (!infoSet) {
    return <LoadingPanel show={true} />;
  }

  return (
    <ReadOnlyFactoryInfoSetContainer>
      <ReadOnlyFactoryInfoSetInfo>
        <ReadOnlyFactoryInfoSetFactoryTitle>
          {info?.globalTitle ?? details?.title}
        </ReadOnlyFactoryInfoSetFactoryTitle>
        <ReadOnlyFactoryInfoSetFactoryInternalTitle>
          {details?.internal_title}
        </ReadOnlyFactoryInfoSetFactoryInternalTitle>
      </ReadOnlyFactoryInfoSetInfo>
      <ReadOnlyFactoryInfoSetDirectory items={items} />
      <ReadOnlyFactoryInfoSetItems>
        {items?.map((item: InfoSetItem, index: number) => {
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <ReadOnlyFactoryInfoSetItem
                item={item}
                showCategoryTitle={shouldShowCategoryLink(
                  item,
                  items[index - 1]
                )}
              />
            </Suspense>
          );
        })}
      </ReadOnlyFactoryInfoSetItems>
    </ReadOnlyFactoryInfoSetContainer>
  );
};
