import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../SSDDashboardView/styles/globals";
import {GRAY_BORDER} from "../../../../../_shared/colors";

export const CartProposalSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid ${GRAY_BORDER};
  border-radius: 10px;
  height: fit-content;
  padding: 24px;
`;

export const CartProposalSummaryHeader = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 20px;
`;
