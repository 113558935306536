const CA_PHONE_NUMBER = "4159681625";
const TX_PHONE_NUMBER = "7372343930";

const WEBINAR_PAGE_SOURCE_FOR_REDIRECT = "webinar-on-demand-gated-ca";
const WEBINAR_REDIRECT_URL =
  "https://villahomes.com/webinar-on-demand-watch-gated-deposit-ca";

export {
  CA_PHONE_NUMBER,
  TX_PHONE_NUMBER,
  WEBINAR_PAGE_SOURCE_FOR_REDIRECT,
  WEBINAR_REDIRECT_URL,
};
