import {InfoSetItemOption} from "../../../../../../../factory-info-set/shared/interfaces";

export interface IOrderFormTableCellValue {
  value: string;
  type: OrderFormTableCellType;
  locked: boolean; // Requires a change externally (derivation from design studio or product selection)
  options?: string[];
  satisfiedModifiers?: string[];
  influencedByModifiers?: string[];
  satisfiedByProduct?: boolean;
  satisfiedByManualSelection?: {
    authorId: string;
    timestamp: number;
  };
}

export enum OrderFormTableCellType {
  UNKNOWN = "Unknown", // No options were available, or the value was not derivable
  UNDETERMINED = "Undetermined", // Multiple options were found, but requires a single selection
  EMPTY = "Empty Selection", // All options requires a valid condition, and none were valid
  CUSTOM = "Custom", // Requires a manual custom input
  INTERNAL = "Internal", // Requires a manual selection from a preset of options
  DERIVED = "Derived", // The value was derived successfully, and logically
}

export interface OptionInfo {
  evaluation: OptionEvaluationResult;
  option: InfoSetItemOption;
}

export interface OptionEvaluationResult {
  usedModifiers: string[];
  satisfiedByProduct: boolean;
  satisfied: boolean;
}
