import React, {useMemo} from "react";
import {IConstructionSpecification} from "./helper";
import ProductLinePageContainer from "../_shared/ProductLinePageContainer";
import {VILLA_COOL_GRAY} from "../../../../../_shared/colors";
import {SectionTitle, SubsectionTitle} from "../_shared/styles";
import {
  ConstructionSpecificationCard,
  ConstructionSpecificationsColumnContainer,
  ConstructionSpecificationsContainer,
} from "./styles";
import ThemedRichTextDiv from "../_shared/ThemedRichTextDiv";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";

interface DesktopConstructionSpecificationsProps {
  constructionSpecs: IConstructionSpecification[];
}

const DesktopConstructionSpecifications = (
  props: DesktopConstructionSpecificationsProps
) => {
  const {constructionSpecs} = props;
  const columnCount = useDynamicValue({
    forFour: 2,
    forEight: 3,
    forTwelve: 3,
    forSixteen: 3,
  });
  const structure: IConstructionSpecification[][] = useMemo(() => {
    const buckets: IConstructionSpecification[][] = Array.from(
      {length: columnCount},
      () => {
        return [];
      }
    );
    let currentBucket = 0;
    constructionSpecs?.forEach((spec: IConstructionSpecification) => {
      buckets[currentBucket] = [...(buckets[currentBucket] ?? []), spec];
      currentBucket = (currentBucket + 1) % columnCount;
    });
    return buckets;
  }, [constructionSpecs, columnCount]);

  return (
    <ProductLinePageContainer
      containerProps={{backgroundColor: VILLA_COOL_GRAY}}
    >
      <SectionTitle>Construction Specifications</SectionTitle>
      <ConstructionSpecificationsColumnContainer>
        {structure?.map(
          (column: IConstructionSpecification[], index: number) => {
            if (!column?.length) {
              return null;
            }
            return (
              <ConstructionSpecificationsContainer key={index}>
                {column?.map(
                  (spec: IConstructionSpecification, index: number) => {
                    return (
                      <ConstructionSpecificationCard key={index}>
                        <SubsectionTitle>{spec.label}</SubsectionTitle>
                        {spec?.items?.map((item: string, index: number) => {
                          return <ThemedRichTextDiv html={item} key={index} />;
                        })}
                      </ConstructionSpecificationCard>
                    );
                  }
                )}
              </ConstructionSpecificationsContainer>
            );
          }
        )}
      </ConstructionSpecificationsColumnContainer>
    </ProductLinePageContainer>
  );
};

export default DesktopConstructionSpecifications;
