import {
  IconProps,
  NatHorizontalAlign,
  NatSize,
  NatSizeType,
  NatVerticalAlign,
  StyleOption,
} from "../../_shared";
import {NatContentListProps} from "./components/NatPopup/NatPopup";
import {BLACK, WHITE} from "../../../colors";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";

export enum NatPopupType {
  MINIMIZE,
  HIDE,
  MODAL_WHEN_EXPANDED,
}

export enum DropdownStyles {
  SELECTION_LIST,
  DYNAMIC_PAGE_TITLE,
  SINGLE_ELEMENT,
  SORT_AND_FILTER,
  SORT_AND_FILTER_ACTIVE,
  POPUP_BUBBLE,
  ADMIN,
  PRIMARY,
  PRIMARY_ALT,
}

const PopupBorderRadius: {[key in NatSizeType]: string} = {
  [NatSizeType.XSMALL]: "0.25rem",
  [NatSizeType.SMALL]: "0.5rem",
  [NatSizeType.NORMAL]: "0.75rem",
  [NatSizeType.LARGE]: "1rem",
  [NatSizeType.XLARGE]: "1.25rem",
};
const PopupPadding: {[key in NatSizeType]: string} = {
  [NatSizeType.XSMALL]: "0.125rem",
  [NatSizeType.SMALL]: "0.25rem",
  [NatSizeType.NORMAL]: "0.5rem",
  [NatSizeType.LARGE]: "1rem",
  [NatSizeType.XLARGE]: "2rem",
};
const DropdownButtonWidth: {[key in NatSizeType]: string} = {
  [NatSizeType.XSMALL]: "calc(100% + 2rem)",
  [NatSizeType.SMALL]: "calc(100% + 3rem)",
  [NatSizeType.NORMAL]: "calc(100% + 4rem)",
  [NatSizeType.LARGE]: "calc(100% + 5rem)",
  [NatSizeType.XLARGE]: "calc(100% + 6rem)",
};
const DropdownButtonPadding: {[key in NatSizeType]: string} = {
  [NatSizeType.XSMALL]: "0.125rem",
  [NatSizeType.SMALL]: "0.25rem",
  [NatSizeType.NORMAL]: "0.5rem",
  [NatSizeType.LARGE]: "0.75rem",
  [NatSizeType.XLARGE]: "0.875rem",
};

const DefaultList = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        TYPE: NatPopupType.HIDE,
        DROPDOWN_BUTTON_STYLE: StyleOption.PRIMARY_DROPDOWN,
        DROPDOWN_BUTTON_SIZE: NatSize[indexKey],
        DROPDOWN_BUTTON_WIDTH: DropdownButtonWidth[indexKey],
        POPUP_BORDER_RADIUS: PopupBorderRadius[indexKey],
        POPUP_ITEM_SIDE_PADDING: PopupPadding[indexKey],
        POPUP_ITEM_TOP_BOTTOM_PADDING: PopupPadding[indexKey],
      } as INatDropdownStyleOptions,
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};

export enum NatPopupStyleOptions {
  NO_ITEMS_BORDER,
  VERTICAL_SPACING_MEDIUM,
  RIGHT_ALIGNED,
  CENTER_ALIGNED,
  NO_PADDING,
  BOTTOM_SHEET_MOBILE,
  NO_CLICK_THRU,
  DISABLE_BOLD_SELECTED,
  HIDE_CLOSE_BUTTON,
  INITIALLY_MINIMIZED,
  USE_FIRST_ARRAY_ITEM_AS_TITLE,
  CLOSE_ON_SELECT,
  HIDE_CHEVRON_DIVIDER,
  HIDE_CHEVRON,
}

const Bubble = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        ...DefaultList[indexKey],
        TYPE: NatPopupType.MINIMIZE,
        popupCSS: {
          visibility: "visible",
          top: "1rem",
          left: "1rem",
        },
        ADDITIONAL_STYLE_OPTIONS: [
          NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE,
        ],
        POPUP_ITEM_TOP_BOTTOM_PADDING: PopupPadding[NatSizeType.NORMAL],
        POPUP_ITEM_SIDE_PADDING: PopupPadding[NatSizeType.NORMAL],
        POPUP_BORDER_RADIUS: PopupBorderRadius[NatSizeType.NORMAL],
      } as INatDropdownStyleOptions,
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const SelectionList = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        ...DefaultList[indexKey],
        dropdownButtonCSS: {margin: "0 0.5rem 0 0"},
        popupCSS: {minWidth: "10rem"},
      } as INatDropdownStyleOptions,
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const DynamicPageTitle = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        ...DefaultList[indexKey],
        DROPDOWN_BUTTON_TEXT_ALIGN: NatHorizontalAlign.CENTER,
        TYPE: NatPopupType.HIDE,
        DROPDOWN_BUTTON_STYLE: StyleOption.PRIMARY_DROPDOWN,
        popupCSS: {
          minWidth: "14rem",
          justifyContent: "center",
          left: `calc(50% - 7rem - 0.75rem)`,
        },
        ADDITIONAL_STYLE_OPTIONS: [
          NatPopupStyleOptions.CLOSE_ON_SELECT,
          NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
        ],
        DROPDOWN_CHEVRON_COLOR: BLACK,
      } as INatDropdownStyleOptions,
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const DropdownInfo = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    return [
      key,
      {
        DROPDOWN_BUTTON_STYLE: StyleOption.FILTERS,
      } as INatDropdownStyleOptions,
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const DropdownInfoActive = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    return [
      key,
      {
        DROPDOWN_BUTTON_STYLE: StyleOption.FILTERS_ACTIVE,
      } as INatDropdownStyleOptions,
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const SingleElement = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        ...DefaultList[indexKey],
        popupCSS: {justifyContent: "center"},
        TYPE: NatPopupType.HIDE,
        ADDITIONAL_STYLE_OPTIONS: [
          NatPopupStyleOptions.NO_ITEMS_BORDER,
          NatPopupStyleOptions.RIGHT_ALIGNED,
          NatPopupStyleOptions.NO_PADDING,
          NatPopupStyleOptions.BOTTOM_SHEET_MOBILE,
        ],
        POPUP_ITEM_SIDE_PADDING: undefined,
        POPUP_ITEM_TOP_BOTTOM_PADDING: undefined,
        POPUP_HEIGHT: "fit-content",
      } as INatDropdownStyleOptions,
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const Primary = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        ...DefaultList[indexKey],
        DROPDOWN_BUTTON_STYLE: StyleOption.SHADOW_TOGGLE,
        DROPDOWN_CHEVRON_COLOR: BLACK,
        POPUP_ITEM_SIDE_PADDING: NatSize.NORMAL,
        ADDITIONAL_STYLE_OPTIONS: [
          NatPopupStyleOptions.CLOSE_ON_SELECT,
          NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
        ],
        dropdownButtonCSS: {
          color: "inherit",
          height: "fit-content",
          paddingLeft: "0.25rem",
          paddingRight: "0.25rem",
        },
        TYPE: NatPopupType.HIDE,
      },
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const PrimaryAlt = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        ...SelectionList[indexKey],
        DROPDOWN_BUTTON_STYLE: StyleOption.PRIMARY_LINK,
        DROPDOWN_CHEVRON_COLOR: WHITE,
        POPUP_ITEM_SIDE_PADDING: NatSize[indexKey],
        ADDITIONAL_STYLE_OPTIONS: [NatPopupStyleOptions.CLOSE_ON_SELECT],
        dropdownButtonCSS: {
          color: "white",
          height: "fit-content",
          paddingLeft: DropdownButtonPadding[indexKey],
          paddingRight: DropdownButtonPadding[indexKey],
        },
        popupCSS: {
          color: WHITE,
        },

        TYPE: NatPopupType.HIDE,
        POPUP_ITEM_TOP_BOTTOM_PADDING: PopupPadding[NatSizeType.NORMAL],
      },
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};
const Admin = Object.fromEntries(
  Object.keys(NatSizeType)?.map((key: string) => {
    const indexKey = key as keyof typeof NatSizeType;
    return [
      key,
      {
        ...DefaultList[indexKey],
        TYPE: NatPopupType.HIDE,
        dropdownButtonCSS: {
          fontFamily: FONT_FAMILY_BOLD,
          width: "max-content",
          height: "fit-content",
          color: "white",
          paddingLeft: DropdownButtonPadding[indexKey],
          paddingRight: DropdownButtonPadding[indexKey],
        },
        popupCSS: {
          minWidth: "14rem",
          margin: "auto",
        },
        ADDITIONAL_STYLE_OPTIONS: [
          NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
          NatPopupStyleOptions.CLOSE_ON_SELECT,
        ],
        DROPDOWN_CHEVRON_COLOR: WHITE,
        DROPDOWN_BUTTON_STYLE: StyleOption.ADMIN_SOLID,
        POPUP_ITEM_SIDE_PADDING: PopupPadding[NatSizeType.NORMAL],
        POPUP_ITEM_TOP_BOTTOM_PADDING: PopupPadding[NatSizeType.NORMAL],
      },
    ];
  })
) as {[sizeKey in NatSizeType]: INatDropdownStyleOptions};

export const NatDropdownStyles: {
  [styleKey in DropdownStyles]: {
    [sizeKey in NatSizeType]: INatDropdownStyleOptions;
  };
} = {
  [DropdownStyles.SELECTION_LIST]: SelectionList,
  [DropdownStyles.SINGLE_ELEMENT]: SingleElement,
  [DropdownStyles.DYNAMIC_PAGE_TITLE]: DynamicPageTitle,
  [DropdownStyles.SORT_AND_FILTER]: DropdownInfo,
  [DropdownStyles.SORT_AND_FILTER_ACTIVE]: DropdownInfoActive,
  [DropdownStyles.POPUP_BUBBLE]: Bubble,
  [DropdownStyles.PRIMARY]: Primary,
  [DropdownStyles.PRIMARY_ALT]: PrimaryAlt,
  [DropdownStyles.ADMIN]: Admin,
};

export interface INatDropdownStyleOptions extends INatPopupStyleOptions {
  DROPDOWN_BUTTON_SIZE?: NatSize;
  DROPDOWN_BUTTON_STYLE?: StyleOption;
  DROPDOWN_BUTTON_WIDTH?: NatSize | string;
  DROPDOWN_BUTTON_TEXT_ALIGN?: NatHorizontalAlign;
  DROPDOWN_BUTTON_TEXT_COLOR?: string;
  DROPDOWN_CHEVRON_COLOR?: string;
  DROPDOWN_ALIGN?: NatHorizontalAlign;
  dropdownButtonCSS?: React.CSSProperties;
}

export interface INatPopupStyleOptions {
  POPUP_BORDER_RADIUS?: NatSize;
  POPUP_ITEM_SIDE_PADDING?: NatSize;
  POPUP_ITEM_TOP_BOTTOM_PADDING?: NatSize;
  POPUP_ITEM_HORIZONTAL_ALIGN?: NatHorizontalAlign;
  POPUP_ITEM_VERTICAL_ALIGN?: NatVerticalAlign;
  POPUP_ITEM_WIDTH?: string;
  POPUP_HEIGHT?: string;
  ADDITIONAL_STYLE_OPTIONS?: NatPopupStyleOptions[];
  TYPE: NatPopupType;
  popupCSS?: React.CSSProperties;
}

export interface NatDropdownProps extends NatContentListProps {
  style: INatDropdownStyleOptions;
  icon?: IconProps;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  toggleButtonOrLabel?: string | JSX.Element;
}
