import React from "react";
import AllProductsTable from "./all";

const AdminProductPage = () => {
  return (
    <div
      className={
        "vcl-container-primary-16 vcl-background-cool-gray vcl-border-primary"
      }
      style={{margin: "16px auto", maxWidth: "1000px"}}
    >
      <div>All products are listed, or unlisted.</div>
      <AllProductsTable />
    </div>
  );
};

export default AdminProductPage;
