import React from "react";
import {getAuthenticationToken} from "../../../api/setup";
import {Button} from "../NexusDemoView/styles";

const AuthTokenView = () => {
  const [token, setToken] = React.useState<string | undefined>(undefined);
  const [refreshTokenButtonText, setRefreshTokenButtonText] = React.useState<string>("Refresh Token");
  const getToken = () => {
    getAuthenticationToken().then((t) => {
      setToken(t);
      console.log(t);
      navigator.clipboard.writeText(t || "");
      setRefreshTokenButtonText("Copied...");
    });
  };

  return (
    <>
      <Button onClick={getToken}>{refreshTokenButtonText}</Button>
      <p>x-firebase-token: {token}</p>
    </>
  );
};

export default AuthTokenView;
