import {ISetFactoryPayloadOptions} from "./index";
import {
  tryFetchingCatalogFactoryCategories,
  tryFetchingCatalogFactoryDetails,
  tryFetchingCatalogFactoryInfoSet,
  tryFetchingCatalogFactoryInventory,
  tryFetchingCatalogFactoryModifierGroups,
  tryFetchingCatalogFactoryModifiers,
  tryFetchingCatalogFactoryProducts,
} from "../../../../root/FetchingManager";
import {IFactoryInfo} from "@natomas-org/core";

export const processOptions = (
  info: IFactoryInfo,
  options: ISetFactoryPayloadOptions
) => {
  const id = info?.id;
  let versionId: string | undefined = undefined;
  if (options?.versionId) {
    versionId = options?.versionId;
  } else if (info?.publishedVersionId) {
    versionId = info?.publishedVersionId;
  } else if (info?.draftVersionId) {
    versionId = info?.draftVersionId;
  } else {
    return;
  }

  if (id && versionId) {
    tryFetchingCatalogFactoryDetails(id, versionId);
    tryFetchingCatalogFactoryProducts(id, versionId);
    tryFetchingCatalogFactoryInventory(id);
    if (options?.fullFetch) {
      tryFetchingCatalogFactoryCategories(id, versionId);
      tryFetchingCatalogFactoryModifierGroups(id, versionId);
      tryFetchingCatalogFactoryModifiers(id, versionId);
      tryFetchingCatalogFactoryInfoSet(id);
    }
  }
};
