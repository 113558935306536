import {useUrl} from "../../components/_shared/hooks/useUrl";
import useMountEffect from "../../components/_shared/hooks/useMountEffect";
import {updateURL} from "../../components/_shared/slices/GlobalSlice";
import {useDispatch} from "react-redux";
import history from "../../components/_shared/navigation/history";

const URLManager = () => {
  const dispatch = useDispatch();
  const {getURL} = useUrl();

  useMountEffect(() => {
    history.listen(() => {
      dispatch(updateURL(getURL()));
    });
  });
  return null;
};

export default URLManager;
