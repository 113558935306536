import React, {useContext, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {IStore} from "../../../../_shared/slices/types/Store";
import {StudioCarouselImage} from "./StudioCarouselImage";
import {Carousel} from "react-bootstrap";
import styled from "styled-components";
import {PROPOSAL_GENERATE_PDF} from "../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/components/constants";

export const StudioCarousel = (props: {
  images: any;
  animate: boolean;
  mobileVersion: boolean;
  alwaysHideCaptions?: boolean;
  positionAbsolute?: boolean;
  yOffsetPx?: number;
  heightPx?: number;
}) => {
  const [index, setIndex] = useState(0);
  const [hideCaption, setHideCaption] = useState(false);
  const {positionAbsolute} = props;
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);

  const handleSelect = (selectedIndex: number, e: any) => {
    if (e != null) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIndex(selectedIndex);
  };

  const step = useSelector((state: IStore) => state.studio.step);
  const [lastStep, setLastStep] = useState(step);

  const {images, animate, mobileVersion, alwaysHideCaptions} = props;
  const [lastImages, setLastImages] = useState(images);

  useEffect(() => {
    if (
      step !== lastStep ||
      images == null ||
      images.length < 2 ||
      lastImages == null ||
      lastImages.length < 1
    ) {
      setIndex(0);
    } else {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (lastImages.length > i) {
          const prevImage = lastImages[i];

          if (
            image.imageId !== prevImage.imageId ||
            image.caption !== prevImage.caption
          ) {
            setIndex(i);
            break;
          } else if (
            !isArrayEqualShallow(image.overlayImages, prevImage.overlayImages)
          ) {
            setIndex(i);
            break;
          }
        } else {
          setIndex(i);
          break;
        }
      }
    }
    setLastStep(step);
    setLastImages(images);
  }, [images, lastImages, lastStep, step]);
  const carouselItems = useMemo(() => {
    return images?.map((image: any, imageIndex: number) => {
      return (
        <Carousel.Item key={imageIndex}>
          <StudioCarouselImage
            index={imageIndex}
            currentIndex={index}
            imageId={image?.imageId}
            objectFit={image?.objectFit}
            caption={image?.caption}
            orientation={image?.orientation}
            overlayImages={image?.overlayImages}
            hideCaption={hideCaption || mobileVersion || alwaysHideCaptions}
            positionAbsolute={positionAbsolute}
            yOffsetPx={0}
          />
        </Carousel.Item>
      );
    });
  }, [
    alwaysHideCaptions,
    hideCaption,
    images,
    index,
    mobileVersion,
    positionAbsolute,
  ]);
  let arrowStyle: React.CSSProperties = {};
  if (props.yOffsetPx) {
    arrowStyle.position = "absolute";
    arrowStyle.top = `${props.yOffsetPx / 2}px`;
  }
  let indicatorStyle: React.CSSProperties = {};
  if (props.yOffsetPx) {
    indicatorStyle.bottom = `${props.yOffsetPx / 2}px`;
  }
  if (isGeneratingPDF) {
    arrowStyle.display = "none";
    indicatorStyle.display = "none";
  }
  let leftArrow: JSX.Element | null = (
    <span className="arrows left-arrow">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/alans-playground-ffa5f.appspot.com/o/Icons%2Fleft-arrow.svg?alt=media&token=c0a40e1d-37e0-4c8b-8899-eb3662534d50"
        alt="left-arrow"
      />
    </span>
  );
  let rightArrow: JSX.Element | null = (
    <span className="arrows right-arrow">
      <img
        src="https://firebasestorage.googleapis.com/v0/b/alans-playground-ffa5f.appspot.com/o/Icons%2Fright-arrow.svg?alt=media&token=b7dc0ae0-f3d3-43d9-a4a4-e37b1e544688"
        alt="right-arrow"
      />
    </span>
  );

  if (images == null || images.length === 0) {
    return null;
  }

  if (images?.length === 1) {
    leftArrow = null;
    rightArrow = null;

    return (
      <div
        className="carousel"
        onClick={() => {
          setHideCaption(!hideCaption);
        }}
      >
        <StudioCarouselImage
          positionAbsolute={positionAbsolute}
          index={0}
          currentIndex={0}
          imageId={images[0].imageId}
          caption={images[0].caption}
          orientation={images[0].orientation}
          objectFit={images[0].objectFit}
          overlayImages={images[0].overlayImages}
          hideCaption={hideCaption || mobileVersion || alwaysHideCaptions}
          yOffsetPx={props.yOffsetPx}
          heightPx={props.heightPx}
        />
      </div>
    );
  }

  return (
    <ArrowStyleContainer
      className="carousel"
      onClick={() => {
        setHideCaption(!hideCaption);
      }}
      arrowStyle={arrowStyle}
      indicatorStyle={indicatorStyle}
    >
      <Carousel
        prevIcon={leftArrow}
        nextIcon={rightArrow}
        interval={animate ? 8000 : null}
        activeIndex={index}
        onSelect={handleSelect}
        style={
          !!props.yOffsetPx
            ? {
                height: props.heightPx ? `${props.heightPx}px` : "unset",
                position: "relative",
                top: `-${props.yOffsetPx}px`,
              }
            : undefined
        }
      >
        {carouselItems}
      </Carousel>
    </ArrowStyleContainer>
  );
};

function isArrayEqualShallow(a: any, b: any) {
  if (a == null && b == null) {
    return true;
  }
  if (a != null && b == null) {
    return false;
  }
  if (a == null && b != null) {
    return false;
  }

  if (a.length !== b?.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (!areEqualShallow(a[i], b[i])) {
      return false;
    }
  }
  return true;
}

function areEqualShallow(a: any, b: any) {
  if (a == null && b == null) {
    return true;
  }
  if (a != null && b == null) {
    return false;
  }
  if (a == null && b != null) {
    return false;
  }

  for (var key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

const ArrowStyleContainer = styled.div<{
  arrowStyle?: React.CSSProperties;
  indicatorStyle?: React.CSSProperties;
}>`
  .carousel-control-prev,
  .carousel-control-next {
    ${(props) => (!!props.arrowStyle ? {...props.arrowStyle} : undefined)}
  }

  .carousel-indicators {
    ${(props) =>
      !!props.indicatorStyle ? {...props.indicatorStyle} : undefined}
  }

  @media print {
    .carousel-control-prev,
    .carousel-control-next,
    .carousel-indicators {
      display: none !important;
    }
  }
`;
