import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useEffect, useMemo} from "react";
import {IProduct, Product} from "@natomas-org/core";
import {
  fetchInventoryUnitConfiguration,
  getSelectionsFromConfiguration,
} from "./helper";
import {setStudioSelections} from "../../slices/StudioSlice";
import {useDeepEffect} from "../useDeepEffect";
import _ from "lodash";
import useActiveDesign from "../useDesign/useActiveDesign";

export const useStudioConfiguration = (
  product: IProduct,
  fullStructure: any,
  isEditing: boolean
) => {
  const {configuration: cartItemConfiguration, snapshot: cartItemSnapshot} =
    useActiveDesign();
  const projectSelectedModifiers = cartItemConfiguration?.selectedModifiers;
  const projectConfigurationSnapshot = cartItemSnapshot?.snapshot;
  const projectConfigurationProduct: IProduct = cartItemConfiguration?.product;
  const cartItemAdditionalLineItemsTotalInMicros =
    cartItemConfiguration?.additionalLineItemsTotalInMicros ?? null;
  const stateSelections = useSelector(
    (state: IStore) => state.studio.selections
  );
  const stateConfiguration = useSelector(
    (state: IStore) => state.studio.configuration
  );
  const dispatch = useDispatch();

  /* Determine selections based off product
   * 1. Product is inventory - load the saved inventory configuration
   * 2. Product is not inventory - load selections from the current project configuration
   */

  const studioProduct = useMemo(() => {
    const productMatchesProjectConfigurationProduct =
      !!projectConfigurationProduct &&
      projectConfigurationProduct?.id === product?.id;
    if (productMatchesProjectConfigurationProduct && !isEditing) {
      console.log(
        "Choosing snapshot from cart item product",
        projectConfigurationProduct
      );
      return projectConfigurationProduct;
    } else if (!!product) {
      console.log("Choosing snapshot from engine", product);
      return product;
    } else {
      return undefined;
    }
  }, [product, projectConfigurationProduct, isEditing]);

  const studioStructure = useMemo(() => {
    let s: any = null;
    if (Product.isInventory(studioProduct) && !!stateConfiguration) {
      console.log(
        "Choosing snapshot from state configuration",
        stateConfiguration?.configurationSnapshot
      );
      s = {
        structure: stateConfiguration?.configurationSnapshot,
        // Convenient to have, but not required.
        images: stateConfiguration?.images,
      };
    } else if (!!projectConfigurationSnapshot) {
      const structure = {
        structure: projectConfigurationSnapshot,
        images: null,
      };
      console.log("Choosing snapshot from cart item configuration", structure);
      s = structure;
    }
    return s;
  }, [stateConfiguration, studioProduct, projectConfigurationSnapshot]);

  useEffect(() => {
    if (!studioProduct) {
      return;
    }
    // FIXME Inventory stores modifiers entirely, while projects store boolean dictionary
    if (Product.isInventory(studioProduct)) {
      if (stateConfiguration?.id === studioProduct?.id) {
        const inventorySelections = getSelectionsFromConfiguration(
          stateConfiguration?.selectedModifiers
        );
        console.log("StudioConfiguration: Setting selections for inventory");
        dispatch(setStudioSelections(inventorySelections));
      } else {
        fetchInventoryUnitConfiguration(studioProduct).then(() =>
          console.log("StudioConfiguration: Fetched " + studioProduct?.id)
        );
      }
    }
  }, [dispatch, studioProduct, stateConfiguration]);

  useDeepEffect(() => {
    // If project selections change, or if the full studio changes, reinitialize the selections for models
    if (
      !Product.isInventory(studioProduct) &&
      !_.isEmpty(projectSelectedModifiers)
    ) {
      console.log("StudioConfiguration: Setting selections for project");
      dispatch(setStudioSelections(projectSelectedModifiers));
    }
  }, [projectSelectedModifiers, fullStructure]);

  // Always return the selections that are in state
  return {
    selections: stateSelections,
    isInventory: Product.isInventory(product),
    studioStructure,
    studioProduct,
    additionalItemsTotalInMicros: cartItemAdditionalLineItemsTotalInMicros,
  };
};
