import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixIncludeOptions,
  AdminProductCatalogMatrixModes,
  IAdminProductCatalogMatrixFilterOptions,
} from "../../../../../_shared/slices/types/Store";

export const getMatrixModeTitle = (mode: AdminProductCatalogMatrixModes) => {
  switch (mode) {
    case AdminProductCatalogMatrixModes.DESCRIPTIONS:
      return "Product/Modifier Descriptions";
    case AdminProductCatalogMatrixModes.PRICE_SET:
      return "Product/Modifier Pricing";
    case AdminProductCatalogMatrixModes.PRODUCT_INFO:
      return "Product Pricing";
    default:
      return null;
  }
};

export const getMatrixFilterLabel = (
  matrixFilters: IAdminProductCatalogMatrixFilterOptions,
  subTabMode: boolean
) => {
  if (subTabMode) {
    return getMatrixSubTabFilterLabel(matrixFilters);
  }
  let label = [];
  if (matrixFilters?.include !== AdminProductCatalogMatrixIncludeOptions.ALL) {
    label.push(matrixFilters?.include);
  }
  if (
    matrixFilters?.filterBy !== AdminProductCatalogMatrixFilterByOptions.NONE &&
    matrixFilters?.filterIds
  ) {
    label.push(
      matrixFilters?.filterIds?.length + " " + matrixFilters?.filterBy + "(s)"
    );
  }
  return label.toString();
};

export const getMatrixSubTabFilterLabel = (
  matrixFilters: IAdminProductCatalogMatrixFilterOptions
) => {
  return matrixFilters?.include?.toString() ?? "Unknown";
};
