import {useUrl} from "../useUrl";
import {MAP_PATH} from "../../../../paths";

export const useProductCatalog = () => {
  const {getURLPath} = useUrl();
  const getBreadcrumbStickyOffset = (isProgressBarEnabled: boolean) => {
    if (getURLPath()?.includes(MAP_PATH)) {
      return 0;
    }
    if (isProgressBarEnabled) {
      return 98;
    }
    return 64;
  };

  const getFilterStickyOffset = (isProgressBarEnabled: boolean) => {
    if (getURLPath().includes(MAP_PATH)) {
      return 64;
    }
    return getBreadcrumbStickyOffset(isProgressBarEnabled) + 39;
  };

  return {
    getBreadcrumbStickyOffset,
    getFilterStickyOffset,
  };
};
