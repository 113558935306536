import {convertStringCase, IPropertyData} from "@natomas-org/core";

export interface ICatalogPropertyInfo {
  jurisdictionName: string | null;
  maxADUSqFt: number | null;
}

export const getCatalogPropertyInfo = (
  propertyData: IPropertyData
): ICatalogPropertyInfo => {
  return {
    jurisdictionName: getJurisdictionName(propertyData),
    maxADUSqFt: getJurisdictionADUSquareFootageLimit(propertyData),
  };
};
export const getJurisdictionName = (
  propertyData: IPropertyData
): string | null => {
  return propertyData?.jurisdictionData?.jurisdiction?.jurisdictionName
    ? convertStringCase(
        propertyData?.jurisdictionData?.jurisdiction?.jurisdictionName?.toLowerCase(),
        {
          upperCaseFirstLetter: true,
          applyToAllWords: true,
        }
      )
    : null;
};
export const getJurisdictionADUSquareFootageLimit = (
  propertyData: IPropertyData
): number | null => {
  return propertyData?.jurisdictionData?.productConstraints?.maxADUSqFt ?? null;
};
