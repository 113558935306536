export interface UTMSliceType {
  page_source: string;
  utm_adgroup: string;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_placement: string;
  utm_source: string;
  utm_term: string;
  key: string;
}

export enum IUTMType {
  page_source = "page_source",
  utm_adgroup = "utm_adgroup",
  utm_campaign = "utm_campaign",
  utm_content = "utm_content",
  utm_medium = "utm_medium",
  utm_placement = "utm_placement",
  utm_source = "utm_source",
  utm_term = "utm_term",
}
