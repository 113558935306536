import React from "react";
import {ProgressBar} from "../../../../_shared/generics/progress-bar/ProgressBar";
import {
  INSTANT_ESTIMATE_PAGE_ID,
  OVERVIEW_ASSESSMENT_PAGE_ID,
} from "../../navigation/constants";
import {useUrl} from "../../../../_shared/hooks/useUrl";
import {
  CUSTOMER_STATUS,
  useCustomerStatus,
} from "../../../../_shared/hooks/useCustomerStatus";
import {
  isProjectInventoryUnit,
  viewInventoryUnitInStudio,
} from "../../../../_shared/hooks/useStudio/helper";
import {SITE_WORK_PHRASE} from "../../../../_shared/constants/labels";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";
import {useActiveCartItem} from "../../../../_shared/hooks/useCart/useCartItem/useActiveCartItem";

const getSteps = (to: any, pid: string, cartItemId?: string) => {
  return [
    {
      title: () => {
        return "Choose home";
      },
      clickEvent: () => {
        to(NavigationPaths.CATALOG);
      },
    },
    {
      title: () => {
        return isProjectInventoryUnit() ? "View upgrades" : "Select upgrades";
      },
      clickEvent: () => {
        if (isProjectInventoryUnit()) {
          viewInventoryUnitInStudio(to);
        } else {
          to(NavigationPaths.STUDIO, {cartItemId: cartItemId});
        }
      },
    },
    {
      title: () => {
        return "Get " + SITE_WORK_PHRASE;
      },
      clickEvent: () => {
        to(NavigationPaths.PORTAL, {
          page: INSTANT_ESTIMATE_PAGE_ID,
          pid: pid,
        });
      },
    },
  ];
};

export const PreDepositProgressBar = (props: {hasBorder: boolean}) => {
  const {customerStatus} = useCustomerStatus();
  const {id: pid} = useActiveProject();
  const {id: cartItemId} = useActiveCartItem() ?? {};
  const {pageId, getURLPath} = useUrl();
  const {to} = useNavigation();
  const getCurrentStep = (): number => {
    if (customerStatus?.instantEstimateStatus === CUSTOMER_STATUS.COMPLETED) {
      return 2;
    } else if (
      customerStatus?.instantEstimateStatus === CUSTOMER_STATUS.IN_PROGRESS &&
      customerStatus?.designProductStatus !== CUSTOMER_STATUS.IN_PROGRESS
    ) {
      return 2;
    } else if (
      customerStatus?.designProductStatus === CUSTOMER_STATUS.IN_PROGRESS
    ) {
      return 1;
    } else if (
      customerStatus?.productSelectedStatus === CUSTOMER_STATUS.IN_PROGRESS
    ) {
      return 0;
    } else return 2;
  };

  const isEnabled = (index: number): boolean => {
    switch (index) {
      case 0:
        return true;
      case 1:
        return customerStatus?.designProductStatus !== CUSTOMER_STATUS.DISABLED;
      case 2:
        return (
          customerStatus?.instantEstimateStatus !== CUSTOMER_STATUS.DISABLED
        );
      case 3:
        return true;
      default:
        return true;
    }
  };

  const getSelectedStep = (): number => {
    if (getURLPath() === "/products") {
      return 0;
    }
    if (getURLPath() === "/design" || getURLPath() === "/studio") {
      return 1;
    }

    if (pageId === INSTANT_ESTIMATE_PAGE_ID) {
      return 2;
    }
    if (pageId === OVERVIEW_ASSESSMENT_PAGE_ID) {
      return 3;
    }
    return -1;
  };

  return (
    <ProgressBar
      hasBorder={props.hasBorder}
      steps={getSteps(to, pid, cartItemId)}
      currentStep={getCurrentStep()}
      selectedStep={getSelectedStep()}
      isEnabled={isEnabled}
    />
  );
};
