import styled from "styled-components";
import {VILLA2_BLUE} from "../../colors";
import React from "react";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {HEADER_Z_INDEX} from "../../../../_shared/styles";

export const AddressContentWrapper = styled.div<{
  forHeader?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.forHeader && !props.isMobile ? "1rem" : "")};
`;
export const AddressContent = styled.div<{
  forHeader?: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: ${(props) => (props.forHeader ? "min-content" : "")};
`;

export const AddressStringWrapper = styled.div`
  min-width: max-content;
  font-size: 1.25rem;
`;
export const getDropdownLocationPopupStyling = (
  center?: boolean
): React.CSSProperties => {
  return {
    marginTop: "0",
    zIndex: HEADER_Z_INDEX + 1,
    left: "inherit",
    transform: center ? "translateX(-25%)" : "",
  };
};
export const DropdownLocationContainer = styled.div`
  flex-direction: row;
  font-size: 1.25rem;
  cursor: pointer;
  display: inline-block;
`;
export const DropdownCombinedSearchBarWrapper = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  width: 400px;
  max-width: 100%;
`;
export const DropdownCombinedSearchBarWrapperMobile = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  width: 100%;
  max-width: 100%;
  background-color: white;
  top: 0;
  left: 0;
  overflow: visible;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
`;
export const DropdownCombinedSearchBarTitle = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  text-align: left;
  font-size: 1rem;
`;
export const Input = styled.input<{
  isLarge: boolean;
  isMobile?: boolean;
}>`
  padding: 8px;
  height: ${(props) => (props.isLarge ? "3rem" : "2rem")};
  font-size: ${(props) => (props.isLarge ? "1.25rem" : "1rem")};
  width: 100%;
  border-radius: ${(props) => (props.isLarge ? "1.5rem" : "1rem")};
  border: ${(props) =>
    props.isLarge ? "1.5px solid dimgray" : "1px solid dimgray"};
  padding-left: ${(props) => (props.isLarge ? "3.25rem" : "2.5rem")};
  padding-right: ${(props) => (props.isLarge ? "3.25rem" : "2rem")};
  margin: 8px 0;
  outline: none;
  box-sizing: border-box;
  transition: 0.3s;
  cursor: text;

  &:focus {
    border-color: ${VILLA2_BLUE};
    box-shadow: 0 0 8px 0 ${VILLA2_BLUE};
  }

  :focus + .left-icon {
    svg {
      fill: ${VILLA2_BLUE};
    }
  }
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

export const SearchBarContainer = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
export const StyledInput = styled.div<{
  isLarge: boolean;
  isMobile?: boolean;
}>`
  height: 100%;
  background: none;
  width: 100%;
  max-width: ${(props) =>
    props.isMobile ? "100%" : props.isLarge ? "40rem" : undefined};

  &.inputWithIcon {
    position: relative;
  }

  .left-icon {
    position: absolute;
    left: 0.5rem;
    top: ${(props) => (props.isLarge ? "calc(50%)" : "calc(50% - 2px)")};
    transform: translateY(-50%);
    svg {
      fill: black;
      transition: 0.3s;
    }
  }

  .right-icon {
    background: transparent;
    border: none;
    position: absolute;
    right: 3px;
    top: ${(props) => (props.isLarge ? "calc(50%)" : "calc(50% - 1px)")};
    transform: translateY(-50%);
    svg {
      transition: 0.3s;
    }
    :focus {
      outline: none !important;
    }
  }
`;
