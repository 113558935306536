import React from "react";
import {
  ProductInfo,
  ProductInfoContainer,
  ProductInfoTitle,
} from "../../styles";
import NatIFrameContainer from "../Matterport";
import {COMPANY_NAME} from "../../../../../_shared/strings/company-info";

interface InteriorSectionProps {
  description?: string;
  matterportUrl?: string;
  children?: React.ReactNode;
}

const InteriorSection = (props: InteriorSectionProps) => {
  const {description, matterportUrl, children} = props;

  return (
    <ProductInfoContainer>
      <ProductInfoTitle>Interior Features</ProductInfoTitle>
      <ProductInfo hidden={!description}>{description}</ProductInfo>
      {matterportUrl && (
        <NatIFrameContainer
          title={`${COMPANY_NAME} x Matterport`}
          url={matterportUrl}
        />
      )}
      {children}
    </ProductInfoContainer>
  );
};

export default InteriorSection;
