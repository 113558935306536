import {
  B2B_CALENDLY_ASSESSMENT_LINK,
  DTC_CALENDLY_ASSESSMENT_LINK,
} from "../../../portal/_shared/components/FAQs/constants";
import {NatModal, NatModalSize} from "../../generics/modal/NatModal";
import React, {useMemo} from "react";
import {useAssessmentModal} from "../../hooks/useAssessmentModal";
import {useDispatch} from "react-redux";
import {setShowAssessmentModal} from "../../slices/SessionSlice/SessionSlice";
import {useCurrentCustomer} from "../../hooks/useCurrentCustomer";

export const AssessmentModal = () => {
  const dispatch = useDispatch();
  const {customer} = useCurrentCustomer();
  const assessmentLink = useMemo(() => {
    if (customer?.used_b2b_intake) {
      return B2B_CALENDLY_ASSESSMENT_LINK;
    } else {
      return DTC_CALENDLY_ASSESSMENT_LINK;
    }
  }, [customer?.used_b2b_intake]);
  return (
    <NatModal
      content={
        <iframe
          title={"Schedule an Assessment"}
          src={assessmentLink}
          style={{height: "80vh", width: "100%", border: "0px"}}
        />
      }
      header={"Schedule an Assessment"}
      customPadding={"1rem 0"}
      size={NatModalSize.FULLSCREEN}
      show={useAssessmentModal().visible}
      handleClose={() => {
        dispatch(setShowAssessmentModal(false));
      }}
    />
  );
};
