import styled from "styled-components";
import {DIVIDER_COLOR, WHITE} from "../../../../../_shared/colors";
import {HEADER_Z_INDEX} from "../../../../../_shared/styles";
import {APP_HEADER_HEIGHT} from "../../../../constants";

export const MapRightStickyHeaderContainer = styled.div<{
  isMobile?: boolean;
}>`
  transition: height 150ms ease-in-out;
  position: sticky;
  top: ${(props) =>
    props.isMobile ? `calc(${APP_HEADER_HEIGHT} + 0rem) ` : "0"};
  background-color: ${WHITE};
  overflow: initial;
  align-items: center;
  display: flex;
  align-content: center;
  justify-items: center;
  justify-content: center;
  z-index: ${`calc(${HEADER_Z_INDEX} - 2)`};
  border-bottom: 1px solid ${DIVIDER_COLOR};
`;

export const MapStickyHeaderItemsContainer = styled.div`
  width: 100%;
`;
export const BuildViewOptionContainerDiv = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 0;
  height: inherit;
  ${(props) => (props.isMobile ? "min-height: 20rem;" : "")};
  ${(props) => (props.isMobile ? "margin: 0 0rem" : "")};
  ${(props) => (props.isMobile ? "z-index: -1" : undefined)};
`;
export const HeaderContainer = styled.div`
  display: flex;
  position: sticky;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  align-items: center;
  top: 0;
  z-index: 50000;
  background-color: white;
`;
