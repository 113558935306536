import {useDispatch, useSelector} from "react-redux";
import {
  editModifierGroup,
  editModifierOverrides,
} from "../slices/CatalogSlice/AdminStudioManagerSlice";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {OverrideModifierStructure} from "../../admin/_models/modifier";
import {getFormInitialValues, getFormStructure} from "../application";
import {AdminForm} from "../../admin/_shared/styles/AdminStyles";
import {AdminInput} from "../../admin/_shared/components/AdminComponentFactory/AdminComponentFactory";
import {NatModal} from "../generics/modal/NatModal";
import {StyleOption} from "../generics/_shared";
import {useAdminProductCatalog} from "../hooks/admin-only-hooks/useAdminProductCatalog";

export const EditModifierOverrides = (props) => {
  const {callback} = props;
  const {updateModifierGroup} = useAdminProductCatalog();
  const modifier = useSelector(
    (state) => state.adminStudioManager.editingModifierOverrides
  );
  const currentModifierGroup = useSelector(
    (state) => state.adminStudioManager.editingModifierGroup
  );

  const dispatch = useDispatch();
  const close = () => {
    dispatch(editModifierOverrides(null));
  };

  const setOverride = (overrideMap) => {
    let overrides = {};
    if (currentModifierGroup?.modifierOverrides != null) {
      overrides = currentModifierGroup.modifierOverrides;
    }
    let editOverrides = {};
    let overwriteKey = false;
    Object.keys(overrides)?.forEach((key) => {
      if (modifier.id === key) {
        if (overrideMap !== null) {
          overwriteKey = true;
          Object.assign(editOverrides, {[key]: overrideMap});
        }
      } else {
        Object.assign(editOverrides, {[key]: overrides[key]});
      }
    });
    if (!overwriteKey && overrideMap !== null) {
      Object.assign(editOverrides, {[modifier.id]: overrideMap});
    }
    const payload = {modifierOverrides: editOverrides};
    updateModifierGroup(currentModifierGroup?.id, payload);
    callback(payload);
    dispatch(
      editModifierGroup(Object.assign({...currentModifierGroup}, payload))
    );
  };

  const deleteModifierOverrideAction = (e) => {
    e.preventDefault();
    setOverride(null);
    close();
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(OverrideModifierStructure),
    onSubmit: (values) => {
      const modifierGroupToSave = Object.assign({}, values);
      const reqModifiers = values["blockingModifiers"];
      if (reqModifiers != null && Object.keys(reqModifiers).length > 0) {
        modifierGroupToSave.blockingModifiers = reqModifiers;
      } else {
        modifierGroupToSave.blockingModifiers = null;
      }

      setOverride(modifierGroupToSave);
      close();
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (currentModifierGroup != null && modifier != null) {
      let overrideValues = {};
      if (
        currentModifierGroup.modifierOverrides != null &&
        currentModifierGroup.modifierOverrides[modifier.id] != null
      ) {
        overrideValues = currentModifierGroup.modifierOverrides[modifier.id];
      }
      for (const key in OverrideModifierStructure) {
        if (overrideValues[key] != null) {
          const value = overrideValues[key];
          formik.setFieldValue(key, value);
        }
      }
    }
  }, [currentModifierGroup, modifier]);

  if (currentModifierGroup == null || modifier == null) {
    return null;
  }

  const editingInputStructure = getFormStructure(OverrideModifierStructure);
  return (
    <NatModal
      show={true}
      customSize={{
        width: "60rem",
        height: "40rem",
      }}
      handleClose={close}
      content={
        <AdminForm onSubmit={formik.handleSubmit}>
          {editingInputStructure.map((keyInfo) => {
            const {key} = keyInfo;
            const value = formik.values[key];

            return (
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                onChange={(value) => {
                  formik.setFieldValue(key, value);
                }}
                handleChange={formik.handleChange}
              />
            );
          })}
        </AdminForm>
      }
      footerButtons={[
        {
          label: "Remove all overrides",
          type: "button",
          disabled: formik.values["dependencies"]?.length === 0,
          clickEvent: deleteModifierOverrideAction,
          option: StyleOption.DESTRUCTIVE,
        },
        {
          label: "Save",
          type: "button",
          disabled: formik.values["dependencies"]?.length === 0,
          clickEvent: () => formik.handleSubmit(),
          option: StyleOption.PRIMARY_ALT,
        },
      ]}
    />
  );
};
