import React from "react";
import {
  StyledSeriesSummaryComparisonRow,
  StyledSeriesSummaryComparisonRowContainer,
  StyledSeriesSummaryComparisonRowHeader,
  StyledSeriesSummaryComparisonRowHeaderLabel,
} from "./styles";
import {Divider} from "../../../../../../../../_shared/generics/divider";

export interface SeriesComparisonRowTemplateOptions {
  ignorePadding?: {
    top?: boolean;
    bottom?: boolean;
  };
}

interface SeriesComparisonRowTemplateProps {
  children: React.ReactNode;
  label?: string;
  options?: SeriesComparisonRowTemplateOptions;
}

const SSCRowTemplate = (props: SeriesComparisonRowTemplateProps) => {
  const {label, children, options} = props;

  return (
    <StyledSeriesSummaryComparisonRow>
      <StyledSeriesSummaryComparisonRowHeader hidden={!label}>
        <StyledSeriesSummaryComparisonRowHeaderLabel>
          {label}
        </StyledSeriesSummaryComparisonRowHeaderLabel>
        <Divider style={{flexGrow: 1}} />
      </StyledSeriesSummaryComparisonRowHeader>
      <StyledSeriesSummaryComparisonRowContainer options={options}>
        {children}
      </StyledSeriesSummaryComparisonRowContainer>
    </StyledSeriesSummaryComparisonRow>
  );
};

export default SSCRowTemplate;
