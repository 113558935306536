import styled from "styled-components";
import {
  BLACK,
  PRIMARY_BUTTON_COLOR,
  PRIMARY_BUTTON_HOVER,
  WHITE,
} from "../../../../_shared/colors";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const StudioControllerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 1rem;
  height: 40px;
  padding: 0 1rem;
`;

export const NavigationContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const NavigationOption = styled.div<{direction: "left" | "right"}>`
  cursor: pointer;
  user-select: none;
  position: absolute;
  left: ${(props) => props.direction === "left" && 0};
  right: ${(props) => props.direction === "right" && 0};
  top: 0;
  bottom: 3px;
  width: 48px;
  display: flex;
  justify-content: ${(props) =>
    props.direction === "left" ? "flex-start" : "flex-end"};
  padding-left: ${(props) => props.direction === "left" && "8px"};
  padding-right: ${(props) => props.direction === "right" && "8px"};
  align-items: center;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    ${(props) => (props.direction === "left" ? 90 : -90)}deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.95) 50%,
    rgba(255, 255, 255, 0.75) 85%,
    rgba(255, 255, 255, 0.5) 95%,
    rgba(255, 255, 255, 0) 100%
  );
  &:hover {
    svg {
      color: ${PRIMARY_BUTTON_COLOR};
    }
  }
`;

export const NavigationOptionsContainer = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
`;

export const NavigationButton = styled.li<{selected: boolean}>`
  font-family: ${FONT_FAMILY_REGULAR};
  margin: 0;
  height: 42px;
  min-width: 120px;
  border-bottom: ${(props) =>
    "2px solid " + (props.selected ? PRIMARY_BUTTON_COLOR : WHITE)};
  color: ${(props) => (props.selected ? PRIMARY_BUTTON_COLOR : BLACK)};
  cursor: pointer;
  user-select: none;
  transition: all 150ms ease-in-out;
  font-family: ${FONT_FAMILY_MEDIUM};
  &:hover {
    color: ${PRIMARY_BUTTON_HOVER};
  }
  ${(props) =>
    !props.selected &&
    `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 12px 0;
    line-height: 16px;
    font-size: 16px;
    max-width: 120px;
    text-align: center;
  `};
  ${(props) =>
    props.selected &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  `};
`;

export const NavigationTitle = styled.li`
  border-bottom: 2px solid ${WHITE};
  font-family: ${FONT_FAMILY_REGULAR};
  padding: 12px 0;
  margin: 0;
  color: ${BLACK};
  height: 42px;
  width: 120px;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  user-select: none;
  transition: all 150ms ease-in-out;
  font-family: ${FONT_FAMILY_MEDIUM};
  cursor: pointer;
  &:hover {
    color: ${PRIMARY_BUTTON_COLOR};
  }
`;
