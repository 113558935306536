import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getParameter} from "../../_shared/cookies";
import {
  Area,
  IGLMapState,
  ILeafletMapState,
  IMapBounds,
  IMapStateAnimationOptions,
  IProductAndFactoryID,
  IPublicProjectInfo,
  IUserFeedbackData,
  UserFeedbackType,
} from "@natomas-org/core";
import {IMapSliceState} from "../../_shared/slices/types/MapTypes";
import {MAP_ANIMATION_DURATION_SECONDS} from "../../mapping/views/theme/constants";
import {DEFAULT_SF_VIEWPORT} from "../../mapping/views/components/AreaMap/constants";
import {DEFAULT_NEARBY_PROJECTS_ZOOM} from "../constants";
import {
  MapViewMode,
  URL_PARAM_KEY_MAP_VIEW_MODE_ID,
} from "@natomas-org/service";
import {BBox} from "@turf/turf";

const getDefaultLeafletMapState = (): ILeafletMapState => {
  return {
    satelliteEnabled: false,
    unitToPreview: null,
    unitPlacementFeedback: {
      message: "",
      type: UserFeedbackType.NEUTRAL,
      icon: undefined,
    },
    isMeasurementActive: false,
    isDimensionActive: false,
    isRotatingADU: false,
    isStatic: false,
    lastCoordinates: null,
    lastSavedCoordinates: null,
    bounds: undefined,
  };
};

const getDefaultGLMapState = (resetURLParams: boolean): IGLMapState => {
  const lat = getParameter("vex") ?? getParameter("lat");
  const long = getParameter("vey") ?? getParameter("lng");
  const mLat = getParameter("mLat");
  const mLong = getParameter("mLng");
  const zoom = getParameter("zoom");
  const pitch = getParameter("pitch");
  const bearing = getParameter("bearing");
  return {
    mLat: resetURLParams
      ? null
      : mLat
      ? parseFloat(mLat)
      : lat
      ? parseFloat(lat)
      : DEFAULT_SF_VIEWPORT.mLat,
    mLng: resetURLParams
      ? null
      : mLong
      ? parseFloat(mLong)
      : long
      ? parseFloat(long)
      : DEFAULT_SF_VIEWPORT.mLng,
    zoom: resetURLParams
      ? null
      : zoom
      ? parseFloat(zoom)
      : DEFAULT_SF_VIEWPORT.zoom,
    bearing: resetURLParams ? null : bearing ? parseFloat(bearing) : 0,
    pitch: resetURLParams ? null : pitch ? parseFloat(pitch) : 0,
    satelliteEnabled: true,
    animation: undefined,
    projectsInViewport: undefined,
  };
};

const getDefaultParcelView = (resetURLParams: boolean): boolean => {
  const parameterValue: null | string = getParameter(
    URL_PARAM_KEY_MAP_VIEW_MODE_ID
  );
  if (parameterValue && !resetURLParams) {
    return parameterValue === MapViewMode.PROPERTY_MAP;
  }
  return true;
};

const getInitialState = (resetURLParams: boolean): IMapSliceState => {
  return {
    glMapState: getDefaultGLMapState(resetURLParams),
    leafletMapState: getDefaultLeafletMapState(),
    parcelView: getDefaultParcelView(resetURLParams),
    infoModalViewed: false,
    addressId: undefined,
  };
};

const mapSlice = createSlice({
  name: "map",
  initialState: getInitialState(false),
  reducers: {
    // AddressId
    setMapStateAddressId: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.addressId = action.payload;
    },

    // Leaflet map reducers
    setLeafletMapSatelliteEnabled: (state, action: PayloadAction<boolean>) => {
      state.leafletMapState.satelliteEnabled = action.payload;
    },
    setLeafletMapIsRotatingADU: (state, action: PayloadAction<boolean>) => {
      state.leafletMapState.isRotatingADU = action.payload;
    },
    setLeafletMapPlacementFeedback: (
      state,
      action: PayloadAction<IUserFeedbackData>
    ) => {
      state.leafletMapState.unitPlacementFeedback = action.payload;
    },
    setLeafletMapLastCoordinates: (state, action: PayloadAction<any>) => {
      state.leafletMapState.lastCoordinates = action.payload;
    },
    setLeafletMapLastSavedCoordinates: (state, action: PayloadAction<any>) => {
      state.leafletMapState.lastSavedCoordinates = action.payload;
    },
    setLeafletMapIsMeasurementActive: (state, action: PayloadAction<any>) => {
      state.leafletMapState.isMeasurementActive = action.payload;
    },
    setLeafletMapIsDimensionActive: (state, action: PayloadAction<any>) => {
      state.leafletMapState.isDimensionActive = action.payload;
    },
    setLeafletMapIsStatic: (state, action: PayloadAction<any>) => {
      state.leafletMapState.isStatic = action.payload;
    },
    setLeafletMapBounds: (
      state,
      action: PayloadAction<L.LatLngBounds | undefined>
    ) => {
      state.leafletMapState.bounds = action.payload;
    },
    resetLeafletMap: (state) => {
      state.leafletMapState = getDefaultLeafletMapState();
    },

    // GL map reducers
    setGLMapState: (state, action: {payload: IGLMapState}) => {
      state.glMapState = Object.assign({}, state.glMapState, action.payload);
    },
    setGLMapStateBearing: (state, action) => {
      state.glMapState.bearing = action.payload;
    },
    setGLMapStateZoom: (state, action) => {
      state.glMapState.zoom = action.payload;
    },
    setGLMapLng: (state, action) => {
      state.glMapState.mLng = action.payload;
    },
    setGLMapLat: (state, action) => {
      state.glMapState.mLat = action.payload;
    },
    setGLMapStatePitch: (state, action) => {
      state.glMapState.pitch = action.payload;
    },
    setMapStateParcelView: (state, action) => {
      state.parcelView = action.payload;
    },
    setMapInfoModalViewed: (state, action: PayloadAction<boolean>) => {
      state.infoModalViewed = action.payload;
    },
    setGLMapSatelliteEnabled: (state, action) => {
      state.glMapState.satelliteEnabled = action.payload;
    },
    resetGLMapAnimationOptionsWithPreviousState: (
      state,
      action: PayloadAction<any>
    ) => {
      if (state.glMapState) {
        state.glMapState.animation = Object.assign({}, {
          previousState: action.payload,
        } as IMapStateAnimationOptions);
      }
    },
    setGLMapAnimationOptionsWithoutPreviousState: (
      state,
      action: PayloadAction<any>
    ) => {
      if (state.glMapState) {
        state.glMapState.animation = Object.assign({}, action.payload, {
          previousState: state.glMapState.animation?.previousState,
        } as IMapStateAnimationOptions);
      }
    },
    resetGLMapAnimationOptionsWithoutPreviousState: (state) => {
      if (state.glMapState) {
        state.glMapState.animation = Object.assign({}, {
          previousState: state.glMapState.animation?.previousState,
          doNotSavePreviousState: true,
        } as IMapStateAnimationOptions);
      }
    },
    resetLeafletMapPlacementFeedback: (state) => {
      state.leafletMapState.unitPlacementFeedback = null;
    },
    setLeafletMapUnitToPreview: (
      state,
      action: PayloadAction<IProductAndFactoryID | null>
    ) => {
      state.leafletMapState.unitToPreview = action.payload;
    },
    setGLMapProjectToPreview: (
      state,
      action: PayloadAction<IPublicProjectInfo | number | string>
    ) => {
      let projectDetails = action.payload;
      if (typeof projectDetails === "string") {
        projectDetails = JSON.parse(projectDetails) as IPublicProjectInfo;
      }
      state.glMapState.projectToPreview = {
        projectDetails: projectDetails,
        fullView: false,
      };
    },
    resetGLMapProjectToPreview: (state) => {
      state.glMapState.projectToPreview = null;
    },
    setGLMapProjectToView: (
      state,
      action: PayloadAction<IPublicProjectInfo | number | string>
    ) => {
      let projectDetails = action.payload;
      if (typeof projectDetails === "string") {
        projectDetails = JSON.parse(projectDetails) as IPublicProjectInfo;
      }
      state.glMapState.projectToPreview = {
        projectDetails: projectDetails,
        fullView: true,
      };
    },
    setGLMapAnimationOptions: (
      state,
      action: PayloadAction<IMapStateAnimationOptions>
    ) => {
      state.glMapState = Object.assign(
        state.glMapState ?? getDefaultGLMapState(true),
        {
          animation: action.payload,
        }
      );
    },

    // Unified map reducers
    animateMapHome: (
      state,
      action: PayloadAction<{
        duration?: number | undefined;
      }>
    ) => {
      const leafletMapStateBounds = state.leafletMapState.bounds;
      const west = leafletMapStateBounds?.getWest();
      const south = leafletMapStateBounds?.getSouth();
      const east = leafletMapStateBounds?.getEast();
      const north = leafletMapStateBounds?.getNorth();
      const bounds: BBox = [west, south, east, north];
      if (leafletMapStateBounds?.isValid() && bounds) {
        state.glMapState.animation = {
          bounds: bounds,
          pitch: 0,
          bearing: 0,
          duration:
            action?.payload?.duration ?? MAP_ANIMATION_DURATION_SECONDS * 1000,
        };
      }
    },
    animateMapLeaveHome: (
      state,
      action: PayloadAction<{
        duration?: number;
        bounds?: IMapBounds;
      }>
    ) => {
      const leafletMapStateBounds = state.leafletMapState.bounds;
      const west = leafletMapStateBounds?.getWest();
      const south = leafletMapStateBounds?.getSouth();
      const east = leafletMapStateBounds?.getEast();
      const north = leafletMapStateBounds?.getNorth();
      const bounds: BBox = [west, south, east, north];
      if (leafletMapStateBounds?.isValid() && bounds) {
        state.glMapState.animation = {
          bounds: bounds,
          pitch: 0,
          bearing: 0,
          duration: 100,
        };
      }
      let animation: IMapStateAnimationOptions = {
        pitch: 0,
        bearing: 0,
        duration:
          action?.payload.duration ?? MAP_ANIMATION_DURATION_SECONDS * 500,
        delay:
          action?.payload.duration ?? MAP_ANIMATION_DURATION_SECONDS * 1000,
      };

      const boundsPayload = action?.payload?.bounds;
      if (!!boundsPayload) {
        animation.bounds = Area.convertIMapBoundsToBBox(boundsPayload);
      } else if (state.glMapState?.zoom && state.glMapState?.zoom < 12) {
        animation.easeTo = state.glMapState?.zoom - 0.01;
      } else {
        animation.easeTo = DEFAULT_NEARBY_PROJECTS_ZOOM;
      }
      state.glMapState.animation = animation;
    },
  },
});

export const mapReducer = mapSlice.reducer;
export const {
  // Address Id
  setMapStateAddressId,

  // GLMap State
  setGLMapState,
  setGLMapLat,
  setGLMapLng,
  setGLMapStateBearing,
  setGLMapStatePitch,
  setGLMapStateZoom,
  setGLMapAnimationOptions,
  setGLMapProjectToPreview,
  setGLMapSatelliteEnabled,
  setGLMapProjectToView,
  resetGLMapProjectToPreview,
  setGLMapAnimationOptionsWithoutPreviousState,
  resetGLMapAnimationOptionsWithPreviousState,
  resetGLMapAnimationOptionsWithoutPreviousState,

  // Leaflet Map State
  setLeafletMapUnitToPreview,
  setLeafletMapSatelliteEnabled,
  setLeafletMapIsRotatingADU,
  setLeafletMapLastCoordinates,
  setLeafletMapLastSavedCoordinates,
  setLeafletMapPlacementFeedback,
  setLeafletMapIsMeasurementActive,
  setLeafletMapIsStatic,
  setLeafletMapIsDimensionActive,
  setLeafletMapBounds,
  resetLeafletMap,

  // Unified Map State
  setMapStateParcelView,
  setMapInfoModalViewed,
  animateMapHome,
  animateMapLeaveHome,
} = mapSlice.actions;
