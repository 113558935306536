import {IHazardData, IJurisdictionData} from "@natomas-org/core";

export const getBooleanValue = (value?: any | null) => {
  if (!value) return "false";
  return "true";
};
export const getValue = (value?: any | null) => {
  if (!value) return "Unknown";
  return value;
};

export const getCityString = (
  jurisdictionData: IJurisdictionData | undefined
): string => {
  return jurisdictionData?.jurisdiction?.jurisdictionName ?? "Not retrieved";
};

export const getFloodZoneString = (
  hazardData: IHazardData | undefined
): string => {
  return getValue(hazardData?.floodZone);
};
export const getLandslideZone = (
  hazardData: IHazardData | undefined
): string => {
  return getBooleanValue(hazardData?.landslideZone);
};
export const getLiquefactionZone = (
  hazardData: IHazardData | undefined
): string => {
  return getBooleanValue(hazardData?.liquefactionZone);
};
export const getFaultZone = (hazardData: IHazardData | undefined): string => {
  return getBooleanValue(hazardData?.faultZone);
};
export const getFireHazardLRA = (
  hazardData: IHazardData | undefined
): string => {
  return getValue(hazardData?.fireHazardRatingLRA);
};

export const getFireHazardSRA = (
  hazardData: IHazardData | undefined
): string => {
  return getValue(hazardData?.fireHazardRatingSRA);
};
