import {
  Address,
  fullPriceTextFromMicros,
  IProduct,
  IProjectPricingMetadata,
  IPublicProjectInfo,
  LineItemTitle,
  LineItemType,
  Product,
} from "@natomas-org/core";
import React, {useState} from "react";
import {SmartImage} from "../../../../_shared/generics/image/SmartImage";
import {
  FlexRow,
  MapProductImageContainer,
  MapProductInfoContainer,
  MapProductSubtitleContainer,
  MapProductTitleContainer,
  MapProductTotalPriceContainer,
  MapProjectDetailsModalContainer,
  PriceBreakdownItemContainer,
  PriceDisclaimerText,
  SiteWorkLineItemContainer,
  SiteWorkLineItemEntryContainer,
} from "./styles";
import {NatSwiper} from "../../../../_shared/generics/swiper";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {scrollToTop} from "../../../../_shared/navigation/_helpers";
import {useDispatch} from "react-redux";
import {tryFilter} from "../../../../_shared/catalog/filter/logic";
import {
  FilterType,
  ICatalogFilter,
} from "../../../../_shared/catalog/filter/types";
import {hardResetFilters} from "../../../../_shared/slices/CatalogSlice/CatalogSessionSlice";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {PublicProjectInfo, PublicProjectMetadata} from "@natomas-org/service";
import {
  NO_PRICE_PLACEHOLDER,
  useDisplayCompletedProjectsPrice,
} from "../../../../_shared/hooks/useCompletedProjects";

export const MapProjectDetailsModal = React.memo(
  (props: {
    projectData: IPublicProjectInfo | null;
    viewOnMapAction: (() => void) | null;
    handleClose: () => void;
    showTextLocation?: boolean;
    hideButton?: boolean;
  }) => {
    const displayCompletedProjectsPrice = useDisplayCompletedProjectsPrice();
    const {isNatMobile} = usePage();
    const {projectData, viewOnMapAction, showTextLocation} = props;
    const isPublicApp = useIsPublicApp();
    const dispatch = useDispatch();
    const {to} = useNavigation();
    const {loggedInUser} = useCurrentUser();
    if (!projectData) {
      return <></>;
    }
    const imageIds = PublicProjectInfo.getImageIds(projectData);
    if (!imageIds || imageIds?.length < 1) {
      return <></>;
    }
    let imageDisplayComponent = (
      <MapProductImageContainer>
        <SmartImage imageId={imageIds[0]} />
      </MapProductImageContainer>
    );
    if (imageIds?.length > 1) {
      const imagesToDisplay: any[] = imageIds.map((imageId, idx) => {
        return {
          title: `Photo ${idx}`,
          style: {height: "100%"},
          content: (
            <MapProductImageContainer>
              <SmartImage key={idx + "-image"} imageId={imageId} />
            </MapProductImageContainer>
          ),
        };
      });
      imageDisplayComponent = (
        <MapProductImageContainer>
          <NatSwiper
            slides={imagesToDisplay}
            pagination={true}
            navigation={true}
          />
        </MapProductImageContainer>
      );
    }

    const product: IProduct | null = PublicProjectInfo.getProduct(projectData);
    const bedBathSqFt: string | null = product
      ? Product.getBedBathSqFtString(product)
      : null;
    const completionYear = PublicProjectInfo.getCompletionYear(projectData);
    const title = PublicProjectInfo.getProjectTitle(projectData);
    const totalPriceMicros = PublicProjectInfo.getTotalPrice(projectData);
    const totalPriceString = totalPriceMicros
      ? fullPriceTextFromMicros(totalPriceMicros ?? 0, {roundToInteger: true})
      : NO_PRICE_PLACEHOLDER;
    const locationString: string | null =
      showTextLocation && !!projectData?.project_address
        ? Address.getCounty(Address.dataToAddress(projectData?.project_address))
        : null;
    const longTitle = !!title && title.length > 18;
    const rangeSqFtMargin = 200;
    let squareFoot;
    if (product) {
      squareFoot = Product.getSqFtValue(product);
    }
    let squareFootLowEnd = 0;
    if (typeof squareFoot === "number" && squareFoot - rangeSqFtMargin > 0) {
      squareFootLowEnd = squareFoot - rangeSqFtMargin;
    }
    let squareFootHighEnd = 1200;
    if (typeof squareFoot === "number" && squareFoot + rangeSqFtMargin < 1200) {
      squareFootHighEnd = squareFoot + rangeSqFtMargin;
    }

    const filterToApply: ICatalogFilter = {
      type: FilterType.SquareFootage,
      value: {low: squareFootLowEnd, high: squareFootHighEnd},
      label: `${squareFootLowEnd} Sq. Ft - ${squareFootHighEnd} Sq. Ft.`,
    };

    return (
      <MapProjectDetailsModalContainer>
        {imageDisplayComponent}
        <MapProductInfoContainer>
          {longTitle && (
            <MapProductTitleContainer>{title ?? ""}</MapProductTitleContainer>
          )}
          <FlexRow>
            {!longTitle && (
              <MapProductTitleContainer>{title ?? ""}</MapProductTitleContainer>
            )}
            <MapProductTitleContainer>
              {totalPriceString && displayCompletedProjectsPrice
                ? `${totalPriceString}*`
                : NO_PRICE_PLACEHOLDER}
            </MapProductTitleContainer>
          </FlexRow>
          <FlexRow
            style={
              isNatMobile
                ? {flexDirection: "column", alignItems: "flex-start"}
                : {}
            }
          >
            <MapProductSubtitleContainer
              isMobile={isNatMobile}
              hidden={
                (displayCompletedProjectsPrice && !bedBathSqFt) ||
                (!displayCompletedProjectsPrice &&
                  !bedBathSqFt &&
                  !locationString)
              }
            >
              {displayCompletedProjectsPrice
                ? bedBathSqFt ?? ""
                : bedBathSqFt ?? locationString ?? ""}
            </MapProductSubtitleContainer>
            <MapProductSubtitleContainer isMobile={isNatMobile}>
              Completed {completionYear ?? "2022"}
            </MapProductSubtitleContainer>
          </FlexRow>
          {displayCompletedProjectsPrice && locationString && (
            <MapProductSubtitleContainer isMobile={isNatMobile}>
              {locationString}
            </MapProductSubtitleContainer>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              gap: "1rem",
            }}
          >
            <NatButton
              trackingId={
                !!loggedInUser
                  ? "account-completed-projects-card-select"
                  : "pa-completed-projects-card-select"
              }
              label={"View similar homes"}
              hidden={props?.hideButton === true}
              option={StyleOption.PRIMARY}
              clickEvent={() => {
                scrollToTop(false);
                if (isPublicApp) {
                  to(NavigationPaths.PUBLIC_CATALOG);
                } else {
                  to(NavigationPaths.CATALOG);
                }
                dispatch(hardResetFilters());
                tryFilter(filterToApply, []);
                props.handleClose();
              }}
            />

            <NatButton
              label={"View on map"}
              option={StyleOption.PRIMARY_LINK_ALT}
              style={{padding: "0px"}}
              clickEvent={viewOnMapAction}
              hidden={!viewOnMapAction}
            />
          </div>
          <PriceDisclaimerText hidden={!displayCompletedProjectsPrice}>
            *Price information is based on the cost of labor and materials at
            time of build.
          </PriceDisclaimerText>
          <PriceBreakdown
            projectData={
              projectData && displayCompletedProjectsPrice ? projectData : null
            }
          />
        </MapProductInfoContainer>
      </MapProjectDetailsModalContainer>
    );
  }
);

const PriceBreakdown = React.memo(
  (props: {projectData: IPublicProjectInfo | null}) => {
    const {projectData} = props;
    if (!projectData) {
      return null;
    }
    const unitPriceMicros = PublicProjectInfo.getProductPrice(projectData);
    const permitPriceMicros = PublicProjectInfo.getLineItemPrice(
      projectData,
      LineItemType.PERMIT_LINE_ITEM_ID
    );
    const showPriceBreakdown =
      PublicProjectInfo.showPriceBreakdown(projectData);
    if (showPriceBreakdown) {
      return (
        <>
          <hr />
          <MapProductTotalPriceContainer>
            Price Breakdown
          </MapProductTotalPriceContainer>
          <PriceBreakdownItemContainer>
            <div>Permit fees</div>
            <div>
              {fullPriceTextFromMicros(permitPriceMicros ?? 0, {
                roundToInteger: true,
              })}
            </div>
          </PriceBreakdownItemContainer>
          <PriceBreakdownItemContainer>
            <div>Unit cost</div>
            <div>
              {fullPriceTextFromMicros(unitPriceMicros ?? 0, {
                roundToInteger: true,
              })}
            </div>
          </PriceBreakdownItemContainer>
          <SiteWorkBreakDownInfo projectData={projectData} />
        </>
      );
    }

    return null;
  }
);
const SiteWorkCostLabel = () => {
  return (
    <>
      <div>Site work cost</div>
    </>
  );
};
const SiteWorkBreakDownInfo = React.memo(
  (props: {projectData: IPublicProjectInfo | null}) => {
    const {projectData} = props;
    const [showBreakdown, setShowBreakdown] = useState<boolean>(false);
    if (!projectData) {
      return null;
    }
    const site_work_line_items: IProjectPricingMetadata[] | null =
      PublicProjectMetadata.getSiteWorkLineItems(
        PublicProjectInfo.getMetadata(projectData)
      );
    if (!!site_work_line_items && site_work_line_items?.length > 0) {
      const permitPrice = PublicProjectInfo.getLineItemPrice(
        projectData,
        LineItemType.PERMIT_LINE_ITEM_ID
      );
      let totalSiteWorkPriceMicrosNoPermit =
        PublicProjectInfo.getTotalSiteWorkPrice(projectData);
      if (permitPrice && totalSiteWorkPriceMicrosNoPermit) {
        totalSiteWorkPriceMicrosNoPermit -= permitPrice;
      }
      let lineItemSumNoPermit = PublicProjectMetadata.getSiteWorkLineItemsSum(
        PublicProjectInfo.getMetadata(projectData)
      );
      if (permitPrice && lineItemSumNoPermit) {
        lineItemSumNoPermit -= permitPrice;
      }
      let siteWorkValueDifferent: number | null = null;
      if (
        totalSiteWorkPriceMicrosNoPermit &&
        lineItemSumNoPermit &&
        totalSiteWorkPriceMicrosNoPermit > lineItemSumNoPermit
      ) {
        siteWorkValueDifferent =
          totalSiteWorkPriceMicrosNoPermit - lineItemSumNoPermit;
      }

      return (
        <>
          <PriceBreakdownItemContainer>
            <FlexRow style={{justifyContent: "flex-start", gap: "1rem"}}>
              <SiteWorkCostLabel />
              <NatButton
                hidden={
                  site_work_line_items?.filter((lineItem) => {
                    return (
                      lineItem?.lineItemType !==
                      LineItemType.PERMIT_LINE_ITEM_ID
                    );
                  })?.length < 1
                }
                label={showBreakdown ? "Show less" : "Show more"}
                option={StyleOption.PRIMARY_LINK_ALT}
                size={NatSize.XSMALL}
                style={{padding: "0px"}}
                clickEvent={() => {
                  setShowBreakdown(!showBreakdown);
                }}
              />
            </FlexRow>
            <div>
              {fullPriceTextFromMicros(totalSiteWorkPriceMicrosNoPermit ?? 0, {
                roundToInteger: true,
              })}
            </div>
          </PriceBreakdownItemContainer>
          {showBreakdown && (
            <SiteWorkLineItemContainer>
              {site_work_line_items
                ?.filter((lineItem) => {
                  return (
                    lineItem?.lineItemType !==
                      LineItemType.PERMIT_LINE_ITEM_ID &&
                    lineItem?.price?.price &&
                    lineItem?.price?.price > 0
                  );
                })
                ?.map((lineItem, idx) => {
                  return (
                    <SiteWorkLineItemEntryContainer key={`line-item-${idx}`}>
                      <div>
                        {lineItem?.title ??
                          LineItemTitle[lineItem?.lineItemType]}
                      </div>
                      <div>
                        {fullPriceTextFromMicros(lineItem?.price?.price ?? 0, {
                          roundToInteger: true,
                        })}
                      </div>
                    </SiteWorkLineItemEntryContainer>
                  );
                })}
              {siteWorkValueDifferent && (
                <SiteWorkLineItemEntryContainer>
                  <div>{LineItemTitle[LineItemType.OTHER_LINE_ITEM_ID]}</div>
                  <div>
                    {fullPriceTextFromMicros(siteWorkValueDifferent, {
                      roundToInteger: true,
                    })}
                  </div>
                </SiteWorkLineItemEntryContainer>
              )}
            </SiteWorkLineItemContainer>
          )}
        </>
      );
    }
    return null;
  }
);
