import useActiveProject from "../useProject/useActiveProject";
import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {clearBudget} from "../../slices/BudgetSlice";
import {ProjectBudget} from "@natomas-org/core";
import {useDesigns} from "../useDesign/useDesigns";
import {useCartItems} from "../useCart/useCartItem/useCartItems";
import {refreshBudget} from "../../../../root/BudgetSliceManager/BudgetSliceManager";

export const useProjectBudget = () => {
  const {
    projectPriceMode,
    cartItemIds,
    id,
    mergedCustomerProjectInfos,
    isCartEnabled,
    phase1Micros,
    phase2Micros,
  } = useActiveProject();
  const designs = useDesigns(cartItemIds);
  const cartItems = useCartItems(cartItemIds);
  const budgets = useSelector((state: IStore) => state.budget);
  const dispatch = useDispatch();

  const totalQuantity = useMemo(() => {
    if (!isCartEnabled) {
      if (cartItems.length > 0) {
        return 1;
      }
    }
    let totalQuantity = 0;
    cartItems?.forEach((cartItem) => {
      totalQuantity += cartItem.quantity;
    });
    return totalQuantity;
  }, [cartItems, isCartEnabled]);

  const unitSelectionSummaryString = useMemo(() => {
    if (!isCartEnabled) {
      const currentDesignId = cartItems?.length > 0 ? cartItems[0]?.id : null;
      if (currentDesignId) {
        return designs[currentDesignId]?.configuration?.product?.title ?? null;
      }
      return null;
    }
    return cartItems
      .map((entry) => {
        return `${designs[entry?.id]?.configuration?.product?.title} (x${
          entry.quantity
        })`;
      })
      .join(", ");
  }, [cartItems, designs, isCartEnabled]);
  const activeProjectBudget = useMemo(() => {
    if (!id || !budgets[id]) {
      return null;
    }
    return new ProjectBudget({
      cartItems: cartItems,
      designs: designs,
      entries: budgets[id] ?? [],
      isCartEnabled: mergedCustomerProjectInfos?.settings?.cart_enabled,
      opFraction:
        mergedCustomerProjectInfos?.settings?.pricing_mode
          ?.overhead_profit_fraction,
      phase2Micros: phase2Micros,
      phase1Micros: phase1Micros,
      projectPriceMode: projectPriceMode,
    });
  }, [
    budgets,
    cartItems,
    designs,
    id,
    mergedCustomerProjectInfos?.settings?.cart_enabled,
    mergedCustomerProjectInfos?.settings?.pricing_mode
      ?.overhead_profit_fraction,
    phase1Micros,
    phase2Micros,
    projectPriceMode,
  ]);
  return {
    projectBudget: budgets[id] ? activeProjectBudget : null,
    refreshBudget: async () => {
      return refreshBudget(id, dispatch);
    },
    clearBudget: () => {
      dispatch(
        clearBudget({
          projectId: id,
        })
      );
    },
    totalUnitQuantity: totalQuantity,
    unitSelectionSummaryString: unitSelectionSummaryString,
  };
};
