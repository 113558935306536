import {
  BedBathCountFilterValue,
  FilterType,
  ICatalogFilter,
} from "../../../../../_shared/catalog/filter/types";
import React, {useCallback, useMemo} from "react";
import {useCatalogSession} from "../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {IndividualFilterTitle} from "../../CatalogBar/styles";
import {NatButton} from "../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";
import {
  changeFilter,
  deleteFilterByType,
} from "../../../../../_shared/catalog/filter/logic";
import {BedBathCountButtonContainer, BedBathCountContainer} from "./styles";
import NatLabel, {
  NatLabelType,
} from "../../../../../_shared/generics/label/NatLabel";
import {GRANITE, VILLA_APPLE_GREEN, WHITE} from "../../../../../_shared/colors";
import {NatInput} from "../../../../../_shared/generics/input/NatInput";
import {NatFlex} from "../../../../../_shared/generics/flex/NatFlex";
import {useDispatch} from "react-redux";
import {setCatalogBedroomStrictMode} from "../../../../../_shared/slices/CatalogSlice/CatalogSessionSlice";
import {usePage} from "../../../../../_shared/hooks/usePage";

export const BedroomCountFilterSelector = () => {
  const {isNatMobile} = usePage();
  const {filters, bedroomFilterStrictMode} = useCatalogSession();
  const dispatch = useDispatch();
  const anyBedroomFilter: ICatalogFilter = useMemo(() => {
    return {
      type: FilterType.Bedroom,
      label: "Any",
      value: BedBathCountFilterValue.Any,
    };
  }, []);
  const onePlusBedroomFilter: ICatalogFilter = useMemo(() => {
    return {
      type: FilterType.Bedroom,
      label: "1+",
      value: BedBathCountFilterValue.OnePlus,
    };
  }, []);
  const twoPlusBedroomFilter: ICatalogFilter = useMemo(() => {
    return {
      type: FilterType.Bedroom,
      label: "2+",
      value: BedBathCountFilterValue.TwoPlus,
    };
  }, []);
  const threePlusBedroomFilter: ICatalogFilter = useMemo(() => {
    return {
      type: FilterType.Bedroom,
      label: "3+",
      value: BedBathCountFilterValue.ThreePlus,
    };
  }, []);
  const oneBedroomFilter: ICatalogFilter = useMemo(() => {
    return {
      type: FilterType.Bedroom,
      label: "1",
      value: BedBathCountFilterValue.One,
    };
  }, []);
  const twoBedroomFilter: ICatalogFilter = useMemo(() => {
    return {
      type: FilterType.Bedroom,
      label: "2",
      value: BedBathCountFilterValue.Two,
    };
  }, []);
  const threeBedroomFilter: ICatalogFilter = useMemo(() => {
    return {
      type: FilterType.Bedroom,
      label: "3",
      value: BedBathCountFilterValue.Three,
    };
  }, []);
  const bedroomFilterOptions = useMemo(() => {
    if (bedroomFilterStrictMode) {
      return [
        anyBedroomFilter,
        oneBedroomFilter,
        twoBedroomFilter,
        threeBedroomFilter,
      ];
    }
    return [
      anyBedroomFilter,
      onePlusBedroomFilter,
      twoPlusBedroomFilter,
      threePlusBedroomFilter,
    ];
  }, [
    anyBedroomFilter,
    bedroomFilterStrictMode,
    oneBedroomFilter,
    onePlusBedroomFilter,
    threeBedroomFilter,
    threePlusBedroomFilter,
    twoBedroomFilter,
    twoPlusBedroomFilter,
  ]);

  const noBedroomFilters: boolean = useMemo(() => {
    return !filters.find((f: ICatalogFilter) => f.type === FilterType.Bedroom);
  }, [filters]);

  const activeOneBedroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bedroom && f.value === oneBedroomFilter.value
    );
  }, [filters, oneBedroomFilter.value]);

  const activeTwoBedroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bedroom && f.value === twoBedroomFilter.value
    );
  }, [filters, twoBedroomFilter.value]);

  const activeThreeBedroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bedroom && f.value === threeBedroomFilter.value
    );
  }, [filters, threeBedroomFilter.value]);

  const activeOnePlusBedroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bedroom && f.value === onePlusBedroomFilter.value
    );
  }, [filters, onePlusBedroomFilter.value]);

  const activeTwoPlusBedroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bedroom && f.value === twoPlusBedroomFilter.value
    );
  }, [filters, twoPlusBedroomFilter.value]);

  const activeThreePlusBedroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bedroom &&
        f.value === threePlusBedroomFilter.value
    );
  }, [filters, threePlusBedroomFilter.value]);

  const setBedroomFilter = useCallback(
    (filter: ICatalogFilter) => {
      switch (filter.value) {
        case BedBathCountFilterValue.Any:
          deleteFilterByType(FilterType.Bedroom);
          break;
        case BedBathCountFilterValue.One:
          changeFilter(oneBedroomFilter);
          break;
        case BedBathCountFilterValue.Two:
          changeFilter(twoBedroomFilter);
          break;
        case BedBathCountFilterValue.Three:
          changeFilter(threeBedroomFilter);
          break;
        case BedBathCountFilterValue.OnePlus:
          changeFilter(onePlusBedroomFilter);
          break;
        case BedBathCountFilterValue.TwoPlus:
          changeFilter(twoPlusBedroomFilter);
          break;
        case BedBathCountFilterValue.ThreePlus:
          changeFilter(threePlusBedroomFilter);
          break;
        default:
          break;
      }
    },
    [
      oneBedroomFilter,
      onePlusBedroomFilter,
      threeBedroomFilter,
      threePlusBedroomFilter,
      twoBedroomFilter,
      twoPlusBedroomFilter,
    ]
  );

  const toggleStrictMode = useCallback(
    (toggleOn: boolean) => {
      dispatch(setCatalogBedroomStrictMode(toggleOn));
      if (toggleOn) {
        switch (
          filters?.find((filter) => filter.type === FilterType.Bedroom)?.value
        ) {
          case BedBathCountFilterValue.OnePlus:
            setBedroomFilter(oneBedroomFilter);
            break;
          case BedBathCountFilterValue.TwoPlus:
            setBedroomFilter(twoBedroomFilter);
            break;
          case BedBathCountFilterValue.ThreePlus:
            setBedroomFilter(threeBedroomFilter);
            break;
        }
      } else {
        switch (
          filters?.find((filter) => filter.type === FilterType.Bedroom)?.value
        ) {
          case BedBathCountFilterValue.One:
            setBedroomFilter(onePlusBedroomFilter);
            break;
          case BedBathCountFilterValue.Two:
            setBedroomFilter(twoPlusBedroomFilter);
            break;
          case BedBathCountFilterValue.Three:
            setBedroomFilter(threePlusBedroomFilter);
            break;
        }
      }
    },
    [
      dispatch,
      filters,
      oneBedroomFilter,
      onePlusBedroomFilter,
      setBedroomFilter,
      threeBedroomFilter,
      threePlusBedroomFilter,
      twoBedroomFilter,
      twoPlusBedroomFilter,
    ]
  );

  return (
    <BedBathCountContainer>
      <IndividualFilterTitle>Bedroom Count</IndividualFilterTitle>
      <BedBathCountButtonContainer>
        {bedroomFilterOptions?.map((bedroomFilter, idx) => {
          let selected = false;
          switch (bedroomFilter.value) {
            case BedBathCountFilterValue.Any:
              selected = noBedroomFilters;
              break;
            case BedBathCountFilterValue.One:
              selected = activeOneBedroomFilter;
              break;
            case BedBathCountFilterValue.Two:
              selected = activeTwoBedroomFilter;
              break;
            case BedBathCountFilterValue.Three:
              selected = activeThreeBedroomFilter;
              break;
            case BedBathCountFilterValue.OnePlus:
              selected = activeOnePlusBedroomFilter;
              break;
            case BedBathCountFilterValue.TwoPlus:
              selected = activeTwoPlusBedroomFilter;
              break;
            case BedBathCountFilterValue.ThreePlus:
              selected = activeThreePlusBedroomFilter;
              break;
            default:
              break;
          }
          return (
            <NatButton
              key={`filter-bedroom-${idx}`}
              label={bedroomFilter.label}
              size={NatSize.SMALL}
              style={{minWidth: "50px"}}
              option={
                selected
                  ? StyleOption.SELECTED_TOGGLE_ON
                  : StyleOption.SELECTED_TOGGLE_OFF
              }
              clickEvent={() => {
                setBedroomFilter(bedroomFilter);
              }}
            />
          );
        })}
      </BedBathCountButtonContainer>
      <NatFlex
        ROW
        style={{
          columnGap: "0.25rem",
          paddingLeft: "0.25rem",
          paddingTop: "0.25rem",
        }}
      >
        <NatInput
          type={"checkbox"}
          style={{
            accentColor: VILLA_APPLE_GREEN,
            color: WHITE,
            minWidth: "14px",
            borderRadius: "0",
          }}
          onChange={(e) => {
            toggleStrictMode(e.target.checked);
          }}
          checked={bedroomFilterStrictMode}
        />
        <NatLabel
          onClick={() => {
            toggleStrictMode(!bedroomFilterStrictMode);
          }}
          style={{color: GRANITE}}
          type={isNatMobile ? NatLabelType.P2 : NatLabelType.P3}
          label={"Use exact match"}
        />
      </NatFlex>
    </BedBathCountContainer>
  );
};
