import React from "react";
import {NatPopupUserFeedbackContainerFull} from "../styled";
import {
  convertStringCase,
  IUserFeedbackData,
  UserFeedbackType,
} from "@natomas-org/core";
import {IMappingDisplayProps} from "../../../../constants";
import {
  NatPopup,
  NatPopupItem,
  NatPopupItemElement,
} from "../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {SaveButton} from "../SaveButton";
import {isMobile} from "../../../../../_shared/navigation";
import {Flex} from "../../../../../_shared/styles/general";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {IModalPayload} from "../../ProjectDetailsModal/constants";
import {
  UserFeedbackElementContainer,
  UserFeedbackElementListContainer,
  UserFeedbackElementSubtitle,
  UserFeedbackElementTitleContainer,
  UserFeedbackRowContainer,
} from "./styles";
import {getIconFromFeedbackType} from "./helpers";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../../../_shared/generics/popup/dropdown/constants";
import {NatSizeType} from "../../../../../_shared/generics/_shared";

export const UserFeedback = (props: {
  userFeedback: IUserFeedbackData[];
  minimal: boolean;
  options: IMappingDisplayProps;
  saveAction: any;
  ahj?: string | null;
  editButton: JSX.Element;
  isEditing: boolean;
  productId?: string;
  placementStatus?: IUserFeedbackData;
  aHJModalPayload?: IModalPayload;
  setAHJModalPayload?: React.Dispatch<
    React.SetStateAction<IModalPayload | undefined>
  >;
  style: React.CSSProperties;
}) => {
  const {isNatMobile} = usePage();
  const {
    userFeedback,
    minimal,
    ahj,
    options,
    saveAction,
    placementStatus,
    editButton,
    isEditing,
    productId,
    style,
  } = props;
  if (!userFeedback || options.hideAHJ === true) {
    return null;
  }

  const title = ahj
    ? convertStringCase(ahj?.toLowerCase(), {
        upperCaseFirstLetter: true,
        applyToAllWords: true,
      })
    : null;
  const getOverallStateType = (): UserFeedbackType => {
    let toReturn = UserFeedbackType.NEUTRAL;
    userFeedback?.forEach((userFeedbackItem) => {
      if (userFeedbackItem?.type === UserFeedbackType.WARNING) {
        toReturn = UserFeedbackType.WARNING;
      } else if (
        toReturn === UserFeedbackType.NEUTRAL &&
        userFeedbackItem?.type === UserFeedbackType.SUCCESS
      ) {
        toReturn = UserFeedbackType.SUCCESS;
      }
    });
    return toReturn;
  };
  const getContent = (forModal?: boolean): NatPopupItem[] => {
    const content: NatPopupItemElement[] = [];
    userFeedback?.forEach((userFeedbackItem, index) => {
      if (userFeedbackItem?.message && userFeedbackItem?.message !== "") {
        content.push({
          id: userFeedbackItem.type ?? userFeedbackItem.message,
          label: userFeedbackItem.message,
          component: (
            <div
              style={{
                fontSize: forModal
                  ? "1.15rem"
                  : isNatMobile
                  ? "0.75rem"
                  : "0.85rem",
                fontFamily: forModal ? FONT_FAMILY_REGULAR : "inherit",
              }}
              key={`userFeedback${userFeedbackItem.message}`}
            >
              <UserFeedbackRow
                key={"user-feedback-" + index}
                userFeedback={userFeedbackItem}
              />
            </div>
          ),
          style: {padding: "0.25rem 0.75rem"},
        });
      }
    });
    options.showSaveButton &&
      content.push({
        id: "save",
        label: "Save",
        component: (
          <Flex
            columnGap={"5px"}
            style={{
              flexDirection: "column",
              flexFlow: "wrap-reverse",
            }}
          >
            {options.showSaveButton && productId && editButton}
            {options.showSaveButton &&
              placementStatus?.type === UserFeedbackType.SUCCESS &&
              isEditing &&
              !isMobile() && <SaveButton saveAction={saveAction} />}
          </Flex>
        ),
        style: {padding: "0.25rem 1rem"},
      });
    return content;
  };

  const modalContent = getContent(true);
  const modalElement = (
    <>
      <UserFeedbackElementContainer>
        {title && <div style={{fontFamily: FONT_FAMILY_BOLD}}>{title}</div>}
        <UserFeedbackElementSubtitle>
          Jurisdiction Requirements
        </UserFeedbackElementSubtitle>
      </UserFeedbackElementContainer>
      <hr />
      <UserFeedbackElementListContainer>
        {modalContent?.map((item, idx) => {
          if (!!item.label) {
            return (
              <div key={`userfeedback-${idx}`}>
                <div style={{fontSize: "0.55rem"}}>{item.label}</div>
                {idx < modalContent?.length - 1 && <hr />}
              </div>
            );
          }
          return <></>;
        })}
      </UserFeedbackElementListContainer>
    </>
  );
  let styleParam = {
    ...NatDropdownStyles[DropdownStyles.POPUP_BUBBLE][NatSizeType.NORMAL],
    TYPE: isNatMobile
      ? NatPopupType.MODAL_WHEN_EXPANDED
      : NatPopupType.MINIMIZE,
    popupCSS: {
      ...NatDropdownStyles[DropdownStyles.POPUP_BUBBLE][NatSizeType.NORMAL]
        .popupCSS,
      ...style,
    },
  };
  const additionalStyleOptions = [
    NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE,
  ];
  if (isNatMobile) {
    additionalStyleOptions.push(NatPopupStyleOptions.INITIALLY_MINIMIZED);
  }
  styleParam.ADDITIONAL_STYLE_OPTIONS = additionalStyleOptions;

  return (
    <NatPopupUserFeedbackContainerFull
      minimal={minimal && !isNatMobile}
      displayOnLeft={true}
    >
      <NatPopup
        contentItemArray={[
          {
            component: (
              <div
                style={{
                  fontSize: isNatMobile ? "0.75rem" : "0.85rem",
                }}
              >
                <UserFeedbackElementTitleContainer>
                  {getIconFromFeedbackType({
                    userFeedbackType: getOverallStateType(),
                    size: isNatMobile ? "1.5rem" : "1.75rem",
                  })}
                  <div
                    style={{display: "flex", flexDirection: "column", gap: "0"}}
                  >
                    {title && (
                      <div style={{fontFamily: FONT_FAMILY_BOLD}}>{title}</div>
                    )}
                    <div
                      style={{
                        fontFamily: FONT_FAMILY_MEDIUM,
                        fontSize: isNatMobile ? "0.65rem" : "0.85rem",
                        color: "rgba(0, 0, 0, 0.67)",
                      }}
                    >
                      Jurisdiction Requirements
                    </div>
                  </div>
                </UserFeedbackElementTitleContainer>
              </div>
            ),
            id: "title",
            label: "Jurisdiction Requirements",
          },
          ...getContent(),
        ]}
        handleClose={() => {}}
        show={true}
        style={styleParam}
        // setModalPayload={setAHJModalPayload}
        // modalPayload={{
        //   isMinimized: aHJModalPayload?.isMinimized ?? true,
        //   modalContent: modalElement,
        // }}
      />
    </NatPopupUserFeedbackContainerFull>
  );
};

const UserFeedbackRow = (props: {
  userFeedback: IUserFeedbackData;
  displayThin?: boolean;
}) => {
  const {userFeedback} = props;
  if (!userFeedback.message) {
    return null;
  }
  const defaultIconSize =
    userFeedback.type === UserFeedbackType.WARNING ? "1.1rem" : "1rem";
  let icon = null;
  if (userFeedback.icon) {
    icon = getIconFromFeedbackType({
      userFeedbackType: userFeedback?.type,
      size: defaultIconSize,
    });
  }
  return (
    <UserFeedbackRowContainer>
      <div style={{minWidth: "1.1rem"}}>{icon}</div>
      <div>{userFeedback.message}</div>
    </UserFeedbackRowContainer>
  );
};
