import React from "react";
import {
  IDesignUpgradeCategoryInfo,
  IDesignUpgradeItemInfo,
} from "@natomas-org/core";
import CartItemDesignCategoryItemPreview from "./CartItemDesignCategoryItemPreview";
import {
  CartItemDesignCategoryContainer,
  CartItemDesignCategoryHeader,
  CartItemDesignCategoryItemContainer,
} from "./styles";
import {priceTextFromMicros} from "../../../../../../studio/_shared/helper";

interface CartItemDesignCategoryPreviewProps {
  category: IDesignUpgradeCategoryInfo;
}

const CartItemDesignCategoryPreview = (
  props: CartItemDesignCategoryPreviewProps
) => {
  const {category} = props;

  return (
    <CartItemDesignCategoryContainer>
      <CartItemDesignCategoryHeader>
        <div>{category.title}</div>
        <div>{priceTextFromMicros(category.price, "accurate")}</div>
      </CartItemDesignCategoryHeader>
      <CartItemDesignCategoryItemContainer>
        {Object.values(category.items)?.map(
          (item: IDesignUpgradeItemInfo, index: number) => {
            return (
              <>
                <CartItemDesignCategoryItemPreview item={item} key={index} />
              </>
            );
          }
        )}
      </CartItemDesignCategoryItemContainer>
    </CartItemDesignCategoryContainer>
  );
};

export default CartItemDesignCategoryPreview;
