import {IFactoryInventoryStudioEditorModifierGroup} from "../Editor/ModifierGroupSection/interface";
import {IFactoryInventoryStudioEditor} from "../../index";
import {IFactoryInventoryStudioEditorModifier} from "../Editor/ModifierSection/interface";
import {
  IFactoryInventoryStudioEditorCategoryImage,
  IFactoryInventoryStudioEditorCategoryImageGroup,
} from "../Editor/CategorySection/CategoryImagesEditor/interface";
import {sortImages} from "../Editor/PopupContent/Rendering/helper";

export const flattenCategories = (configuration: any) => {
  let categories: IFactoryInventoryStudioEditor = {};
  configuration?.configurationSnapshot?.forEach(
    (category: any, index: number) => {
      if (category?.id) {
        categories[category.id] = {
          id: category.id,
          index: category?.index ?? index,
          title: category?.title,
          description: category?.displayDescription,
          modifierGroups: flattenModifierGroups(category),
          images: flattenCategoryImages(category?.renderings, category?.id),
        };
      }
    }
  );
  return categories;
};

export const flattenCategory = (category: any, index?: number) => {
  return {
    id: category.id,
    index: category?.index ?? index ?? 0,
    title: category?.title,
    description: category?.displayDescription,
    modifierGroups: flattenModifierGroups(category),
    images: flattenCategoryImages(category?.renderings, category?.id),
  };
};

export const flattenModifierGroups = (category: any) => {
  let modifierGroups: {
    [id: string]: IFactoryInventoryStudioEditorModifierGroup;
  } = {};
  category?.fullModifierGroups?.forEach((modifierGroup: any, index: number) => {
    if (modifierGroup?.id) {
      modifierGroups[modifierGroup.id] = flattenModifierGroup(
        modifierGroup,
        index
      );
    }
  });
  return modifierGroups;
};

export const flattenModifierGroup = (modifierGroup: any, index?: number) => {
  return {
    id: modifierGroup.id,
    index: modifierGroup?.index ?? index ?? 0,
    title: modifierGroup?.title,
    description: modifierGroup?.displayDescription,
    properties: modifierGroup?.properties,
    modifiers: flattenModifiers(modifierGroup),
  };
};

export const flattenModifiers = (modifierGroup: any) => {
  let modifiers: {
    [id: string]: IFactoryInventoryStudioEditorModifier;
  } = {};
  modifierGroup?.fullModifiers?.forEach((modifier: any, index: number) => {
    if (modifier?.id) {
      modifiers[modifier.id] = flattenModifier(modifier, index);
    }
  });
  return modifiers;
};

export const flattenModifier = (modifier: any, index?: number) => {
  return {
    id: modifier?.id,
    index: modifier?.index ?? index ?? 0,
    title: modifier?.title,
    color: modifier?.color,
    secondaryColor: modifier?.secondaryColor,
    description: modifier?.displayDescriptionDefault,
    imageId: modifier?.imageId,
    priceInMicros: getBaselineDefaultPriceInMicros(modifier?.price_set),
  };
};

const getBaselineDefaultPriceInMicros = (priceSet: any) => {
  const defaultPricing = priceSet?.find(
    (priceObj: any) =>
      priceObj?.type === "baseline" && priceObj.condition === "default"
  );
  return defaultPricing?.price?.price ?? 0;
};

const flattenCategoryImages = (
  renderings: any[],
  categoryId: string
): {
  [id: string]: IFactoryInventoryStudioEditorCategoryImageGroup;
} => {
  const allRenderings = Object.values(renderings ?? {});
  const groups: {
    [id: string]: IFactoryInventoryStudioEditorCategoryImageGroup;
  } = {};
  const overlayGroups: {
    [id: string]: IFactoryInventoryStudioEditorCategoryImage[];
  } = {};
  allRenderings
    ?.filter((image: any) =>
      // Old way
      {
        return (
          !!image?.zIndex ||
          // New way
          image?.overlayGroupPosition > 0
        );
      }
    )
    ?.forEach((image: any) => {
      const groupId = image?.overlayGroupId;
      const position = image?.overlayGroupPosition;
      if (groupId) {
        const newId = image?.id.replaceAll(`${categoryId}-`, "") ?? "";
        const rid = `${categoryId}-${newId}`;
        const img = {
          id: rid,
          imageId: image?.imageId,
          index: position ?? (overlayGroups[groupId]?.length ?? 0) + 1,
          orientation: image?.orientation,
          objectFit: image?.objectFit,
        };
        if (overlayGroups[groupId]) {
          overlayGroups[groupId] = [...overlayGroups[groupId], img];
        } else {
          overlayGroups[groupId] = [img];
        }
      }
    });
  allRenderings
    ?.filter(
      (image: any) =>
        // Old way
        ((!image?.zIndex || image?.zIndex === 0) &&
          !image?.overlayGroupPosition) ||
        // New way
        image?.overlayGroupPosition === 0
    )
    ?.forEach((image: any, index: number) => {
      const newId = image?.id.replaceAll(`${categoryId}-`, "") ?? "";
      const rid = `${categoryId}-${newId}`;
      if (rid) {
        const baseImg = {
          id: rid,
          imageId: image?.imageId,
          index: 0,
          orientation: image?.orientation,
          objectFit: image?.objectFit,
        };
        const overlays = overlayGroups[image?.overlayGroupId] ?? [];
        groups[rid] = {
          id: rid,
          index: index,
          images: sortImages([baseImg, ...overlays]),
        };
      }
    });
  return groups;
};
