import {store} from "../../../../store";
import {editCategory} from "../../../../components/_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {Utilities} from "../../index";
import {getFactoryLineVersionCategoryRef} from "../refs";
import {filterByKeys} from "../helper";
import firebase from "firebase/app";
import {v4 as uuidv4} from "uuid";

export const saveCategoryInformation = (
  factoryLineId: string,
  versionId: string,
  categoryId: string,
  payload: any
) => {
  const ref = getFactoryLineVersionCategoryRef(
    factoryLineId,
    versionId,
    categoryId
  );

  const info = {...payload, id: ref?.id};

  store.dispatch(editCategory(info));

  return Utilities.updateOrCreateDocumentInDB(ref, info);
};

export const archiveCategory = (
  factoryLineId: string,
  versionId: string,
  categoryId: string
) => {
  return saveCategoryInformation(factoryLineId, versionId, categoryId, {
    visible: false,
  });
};

export const revertArchiveCategory = (
  factoryLineId: string,
  versionId: string,
  categoryId: string
) => {
  return saveCategoryInformation(factoryLineId, versionId, categoryId, {
    visible: true,
  });
};

export const saveRenderingToCategory = async (
  factoryLineId: string,
  versionId: string,
  category: any,
  rendering: any
) => {
  const ref = getFactoryLineVersionCategoryRef(
    factoryLineId,
    versionId,
    category?.id
  );
  console.log(ref, factoryLineId, versionId, category, rendering);
  if (!ref) {
    return;
  }

  let renderingId = rendering.id;
  if (renderingId == null) {
    renderingId = uuidv4();
    rendering.id = renderingId;
  }
  const renderObjectToBeSaved = filterByKeys(rendering, {
    requiredModifiers: true,
    imageId: true,
    id: true,
    overlayGroupId: true,
    zIndex: true,
    orientation: true,
    objectFit: true,
  });
  console.log(renderObjectToBeSaved);

  const renderingKey = "renderings." + renderingId;
  return ref.update({
    [renderingKey]: renderObjectToBeSaved,
  });
};

export const deleteRenderingFromCategory = async (
  factoryLineId: string,
  versionId: string,
  category: any,
  renderingId: any
) => {
  const ref = getFactoryLineVersionCategoryRef(
    factoryLineId,
    versionId,
    category?.id
  );
  if (!ref) {
    return;
  }
  if (renderingId != null) {
    const renderingKey = "renderings." + renderingId;
    return ref.update({
      [renderingKey]: firebase.firestore.FieldValue.delete(),
    });
  }
};
