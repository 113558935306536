import React, {useState} from "react";
import {NatModal} from "../../../../_shared/generics/modal/NatModal";
import {NatInfoIcon} from "../../../../_shared/icon/icons";
import styled from "styled-components";
import {BLACK} from "../../../../_shared/colors";

const InstantQuoteDisclaimer = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <NatInfoIcon
        id={"tooltip-id-instant-quote-modal"}
        onClick={() => {
          setShow(true);
        }}
        style={{
          color: "var(--semantic-link)",
          cursor: "pointer",
        }}
      />
      <NatModal
        onlyShowContent={true}
        content={
          <Container>
            <Header>Instant quote</Header>
            <Content>
              Our instant quote is calculated from the base unit price, your
              design selections, and assumptions about basic site work based on
              our experiences across hundreds of builds. This quote is a
              preliminary estimate and subject to change. To get a more accurate
              quote talk to us about a Feasibility Study.
            </Content>
          </Container>
        }
        show={show}
        handleClose={() => setShow(false)}
      />
    </>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  padding: 32px;
`;
const Header = styled.h2`
  font-family: "p22-mackinac-pro", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  text-align: left;
  color: ${BLACK};
`;
const Content = styled.p`
  //styleName: Paragraph/P2/Regular;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: ${BLACK};
`;

export default InstantQuoteDisclaimer;
