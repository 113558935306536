import React, {Dispatch, SetStateAction, useMemo} from "react";
import {NatSize, StyleOption} from "../../../../../../_shared/generics/_shared";
import {NatButton} from "../../../../../../_shared/generics/button";
import {MatrixPriceReviewSummary} from "../../index";

interface WarningSummaryButtonProps {
  summary: MatrixPriceReviewSummary;
  showing: boolean;
  setShowing: Dispatch<SetStateAction<boolean>>;
}

const PriceReviewButton = (props: WarningSummaryButtonProps) => {
  const {summary, showing, setShowing} = props;

  const label = useMemo(() => {
    return `${summary?.edits ?? 0} / ${summary?.errors ?? 0} / ${
      summary?.warnings ?? 0
    } / ${summary?.successes ?? 0}`;
  }, [summary]);

  const theme = useMemo(() => {
    if (summary?.errors) {
      return showing ? StyleOption.ERROR : StyleOption.ERROR_INVERSE;
    } else if (summary?.warnings) {
      return showing ? StyleOption.WARNING : StyleOption.WARNING_INVERSE;
    } else {
      return showing ? StyleOption.PRIMARY : StyleOption.PRIMARY_BLACK;
    }
  }, [showing, summary?.errors, summary?.warnings]);

  return (
    <NatButton
      label={label}
      size={NatSize.SMALL}
      option={theme}
      clickEvent={() => setShowing((prev) => !prev)}
    />
  );
};

export default PriceReviewButton;
