import styled from "styled-components";

export const InstantQuoteMapPinContainer = styled.div`
  position: absolute;
  top: calc(100% + 32px);
  left: 50%;
  transform: translate(-50%, -100%);
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  justify-items: center;
`;
export const InstantQuoteMapPinLabel = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  min-width: 100px;
`;
export const InstantQuoteMapPin = styled.img`
  cursor: pointer;
  width: 36px;
  height: 40px;
  padding-bottom: 4px;
`;
