import NatLabel, {
  NatLabelType,
} from "../../../../../_shared/generics/label/NatLabel";
import React, {SetStateAction, useEffect, useMemo, useState} from "react";
import {useCurrentUser} from "../../../../../_shared/hooks/useCurrentUser";
import {NatFlex} from "../../../../../_shared/generics/flex/NatFlex";
import {NatInput} from "../../../../../_shared/generics/input/NatInput";
import {
  NatHorizontalAlign,
  NatJustifyContent,
  NatSize,
  NatVerticalAlign,
} from "../../../../../_shared/generics/_shared";
import {NatButton} from "../../../../../_shared/generics/button";
import {
  ProjectPriceMode,
  UNIT_PERMIT_FEE_PHASE_1_MICROS,
  UNIT_PERMIT_FEE_PHASE_2_MICROS,
} from "@natomas-org/core";
import {updateProjectInfoAction} from "../../../../../../database/firebase/api/project";
import {FormikProps} from "formik";
import {
  PROJECT_OP_KEY,
  PROJECT_PHASE1_KEY,
  PROJECT_PHASE2_KEY,
  PROJECT_PRICE_MODE_KEY,
} from "../../../../../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/ControlPanelTab/ProjectPriceModeToggle/ControlPanelProjectPriceMode";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import {useIsAdminSystem} from "../../../../../_shared/hooks/useIsAdminSystem";
import styled from "styled-components";

const OVERHEAD_PROFIT_TITLE = "Overhead & Profit";
const PHASE_1_FEE_TITLE = "Phase 1 Fee ($) ";
const PHASE_2_FEE_TITLE = "Phase 2 Fee ($) ";
export const PricingOptionsController = (props: {
  formik?: FormikProps<any>;
}) => {
  const {
    projectOverheadProfit,
    projectPriceMode: stateProjectPricingMode,
    id: pid,
    projectPriceSettings,
  } = useActiveProject();
  const {isAdmin} = useCurrentUser();
  const withinAdminSystem = useIsAdminSystem();
  const [localOP, setLocalOP] = useState(projectOverheadProfit);
  const [localPhase1, setLocalPhase1] = useState(
    projectPriceSettings && "phase1Micros" in projectPriceSettings
      ? (projectPriceSettings?.phase1Micros ?? UNIT_PERMIT_FEE_PHASE_1_MICROS) /
          100
      : UNIT_PERMIT_FEE_PHASE_1_MICROS / 100
  );
  const [localPhase2, setLocalPhase2] = useState(
    projectPriceSettings && "phase2Micros" in projectPriceSettings
      ? (projectPriceSettings?.phase2Micros ?? UNIT_PERMIT_FEE_PHASE_2_MICROS) /
          100
      : UNIT_PERMIT_FEE_PHASE_2_MICROS / 100
  );
  const [isInputValid, setIsInputValid] = useState(false);
  useEffect(() => {
    if (props.formik) {
      setLocalOP(props.formik.values[PROJECT_OP_KEY]);
      setLocalPhase1(
        (props.formik.values[PROJECT_PHASE1_KEY] ??
          UNIT_PERMIT_FEE_PHASE_1_MICROS) / 100
      );
      setLocalPhase2(
        (props.formik.values[PROJECT_PHASE2_KEY] ??
          UNIT_PERMIT_FEE_PHASE_2_MICROS) / 100
      );
    } else {
      setLocalOP(projectOverheadProfit);
      if (projectPriceSettings) {
        if ("phase1Micros" in projectPriceSettings) {
          setLocalPhase1(
            (projectPriceSettings?.phase1Micros ??
              UNIT_PERMIT_FEE_PHASE_1_MICROS) / 100
          );
        }
        if ("phase2Micros" in projectPriceSettings) {
          setLocalPhase2(
            (projectPriceSettings?.phase2Micros ??
              UNIT_PERMIT_FEE_PHASE_2_MICROS) / 100
          );
        }
      }
    }
    setIsInputValid(true);
  }, [projectOverheadProfit, projectPriceSettings, props.formik]);
  const projectPriceMode = useMemo(() => {
    if (props.formik) {
      return props.formik.values[PROJECT_PRICE_MODE_KEY];
    } else {
      return stateProjectPricingMode;
    }
  }, [props.formik, stateProjectPricingMode]);
  const oPTitle = useMemo(() => {
    let title = `${OVERHEAD_PROFIT_TITLE} `;
    if (projectOverheadProfit) {
      title += `= ${projectOverheadProfit * 100}%`;
    }
    title += ": ";
    return title;
  }, [projectOverheadProfit]);
  if (
    !isAdmin ||
    projectPriceMode !== ProjectPriceMode.CostPlus ||
    !withinAdminSystem
  ) {
    return null;
  }
  const OPItem = () => {
    return (
      <PanelItemRowContainer
        ROW
        VERTICAL_ALIGN={NatVerticalAlign.CENTER}
        HORIZONTAL_ALIGN={NatHorizontalAlign.CENTER}
      >
        <NatLabel
          label={oPTitle}
          type={NatLabelType.P2}
          tooltip={`${OVERHEAD_PROFIT_TITLE}: The default OP line item fraction for all sections of this CostPlus project. This is always the OP for the unit.`}
          tooltipIcon={true}
        />
        <NatInput
          type={"number"}
          value={localOP}
          step={0.05}
          style={{
            minWidth: "4rem",
            maxWidth: "4rem",
            marginLeft: "0",
            marginRight: "0",
          }}
          onChange={(e) => {
            setIsInputValid(isDecimalFormInputValid(e));
            const value = parseFloat(e.target.value);
            const cleanedValue = isNaN(value) ? 0 : value;
            setLocalOP(cleanedValue);
            if (props.formik) {
              props.formik.setFieldValue(PROJECT_OP_KEY, cleanedValue);
            }
          }}
        />
      </PanelItemRowContainer>
    );
  };
  const {formik} = props;

  return (
    <NatFlex
      JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
      style={{alignItems: "center", maxWidth: "100%"}}
    >
      <OPItem />
      <PhaseItem
        title={PHASE_1_FEE_TITLE}
        fieldValueKey={PROJECT_PHASE1_KEY}
        value={localPhase1}
        setValue={setLocalPhase1}
        setIsInputValid={setIsInputValid}
        isInputValid={isInputValid}
        formik={formik}
      />
      <PhaseItem
        title={PHASE_2_FEE_TITLE}
        fieldValueKey={PROJECT_PHASE2_KEY}
        value={localPhase2}
        setValue={setLocalPhase2}
        setIsInputValid={setIsInputValid}
        isInputValid={isInputValid}
        formik={formik}
      />
      <NatButton
        label={"Save"}
        hidden={props.formik}
        size={NatSize.SMALL}
        disabled={!isInputValid}
        spinnerEnabled={true}
        clickEvent={() => {
          return updateProjectInfoAction({
            projectId: pid,
            pricingMode: {
              mode: projectPriceMode,
              overheadProfitFraction: localOP,
              phase1Micros: localPhase1 * 100,
              phase2Micros: localPhase2 * 100,
            },
          });
        }}
      />
    </NatFlex>
  );
};

const PanelItemRowContainer = styled(NatFlex)`
  min-width: 18.7rem;
  max-width: 18.7rem;
`;
const PhaseItem = (props: {
  title: string;
  fieldValueKey: string;
  value: number;
  setValue: React.Dispatch<SetStateAction<number>>;
  setIsInputValid: React.Dispatch<SetStateAction<boolean>>;
  isInputValid: boolean;
  formik?: any;
}) => {
  return (
    <PanelItemRowContainer
      ROW
      VERTICAL_ALIGN={NatVerticalAlign.CENTER}
      HORIZONTAL_ALIGN={NatHorizontalAlign.CENTER}
    >
      <NatLabel
        label={props.title}
        type={NatLabelType.P2}
        tooltip={`${props.title}: The relative phase fee for this CostPlus project, per unit.`}
        tooltipIcon={true}
      />
      <NatInput
        type={"number"}
        value={props.value}
        step={100}
        style={{maxWidth: "4.7rem", marginLeft: "0", marginRight: "0"}}
        onChange={(e) => {
          props.setIsInputValid(
            isNumberFormInputValid(e) && props.isInputValid
          );
          const value = parseFloat(e.target.value);
          const cleanedValue = isNaN(value) ? 0 : value;
          props.setValue(cleanedValue);
          if (props.formik) {
            console.log(
              "Setting field value",
              props.fieldValueKey,
              cleanedValue
            );
            props.formik.setFieldValue(props.fieldValueKey, cleanedValue * 100);
          }
        }}
      />
    </PanelItemRowContainer>
  );
};

function isDecimalFormInputValid(
  e: React.ChangeEvent<HTMLInputElement>
): boolean {
  var value = parseFloat(e.target.value);
  return !(value < 0 || value >= 1);
}

function isNumberFormInputValid(
  e: React.ChangeEvent<HTMLInputElement>
): boolean {
  var value = parseFloat(e.target.value);
  return !(value < 0);
}
