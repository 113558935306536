import React from "react";
import styled from "styled-components";
import {
  ADMIN_BUTTON_INACTIVE,
  CONTAINER_BACKGROUND_COLOR,
  DIVIDER_COLOR,
  DROPDOWN_ACCENT_COLOR,
  GRAY,
  SIDEBAR_COLOR,
  STATUS_DISABLED,
  STATUS_DISABLED_ALT,
  STATUS_ENABLED,
  STATUS_ENABLED_ALT,
  VILLA_BLUE,
  WHITE,
} from "../../../_shared/colors";
// @ts-ignore
import Select from "react-select";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../_shared/generics/label/NatLabel";
import {BUTTON_Z_INDEX} from "../../../_shared/styles";

export const AdminForm = styled.form`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const MainLayout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`;

export const MainContentWrapper = styled.div`
  width: 300px;
  flex-grow: 2;
  height: 100%;
  overflow: hidden;
  background: white;
`;

export const InputImageContainer = styled.div`
  max-width: 100%;
  width: 530px;
  position: relative;
`;

export const InputImageWrapper = styled.div`
  height: 300px;
  min-height: 300px;
  width: 100%;
`;

export const GenericInputContentContainer = styled.div`
  width: calc(100%);
  font-size: 1rem;
  padding-bottom: 0.5rem;
  // z-index: ${BUTTON_Z_INDEX};
`;

export const YesNoSelector = styled.input<{value: string; selected: boolean}>`
  padding: 0.5rem;
  border-radius: 1rem;
  border: 2px solid ${GRAY};
  color: ${GRAY};
  background-color: ${CONTAINER_BACKGROUND_COLOR};
  font-size: 1rem;
  margin-right: 0.5rem;
  min-width: 4rem;
  ${(props) =>
    props.selected
      ? props.value === "Yes"
        ? `
    border: 2px solid ${STATUS_ENABLED};
    color: ${STATUS_ENABLED};
    background-color: ${STATUS_ENABLED_ALT};
  `
        : `border: 2px solid ${STATUS_DISABLED};
    color: ${STATUS_DISABLED};
    background-color: ${STATUS_DISABLED_ALT};`
      : null};
`;

export const InputContainer = styled.div<{fullWidth?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 220px;
  width: ${(props) => (props.fullWidth === true ? "100%" : "calc(33% - 40px)")};
  position: relative;

  .MuiPaper-root {
    width: 100%;
  }

  margin-bottom: 1rem;
`;

export const InputTextAreaContainer = styled(InputContainer)`
  min-width: fit-content;
`;

const InputTitleDiv = styled.div<{bold?: boolean; enabled?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;
  color: ${(props) => (props.enabled ? SIDEBAR_COLOR : ADMIN_BUTTON_INACTIVE)};
  font-size: ${(props) => (props.bold ? "1rem" : "1rem")};
  font-family: ${(props) =>
      props.bold ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR},
    sans-serif;
`;

export interface InputTitleProps {
  title?: string;
  bold?: boolean;
  enabled?: boolean;
  type?: NatLabelType;
}

export const InputTitle = (props: InputTitleProps) => {
  const {title, bold, enabled} = props;
  return (
    <NatLabel
      className={"input-title"}
      label={title ?? ""}
      type={props.type ?? NatLabelType.H4}
      style={{color: enabled ? "black" : "gray"}}
      weight={NatLabelWeight.MEDIUM}
    />
  );
  return (
    <InputTitleDiv bold={bold} enabled={enabled}>
      {title?.toUpperCase()}
    </InputTitleDiv>
  );
};

export const InputTextField = styled.input`
  border: none;
  outline: none;
  width: calc(100% - 0.5rem);
  font-size: 1rem;
  border-bottom: 1px solid ${SIDEBAR_COLOR};

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CleanInputTextField = styled.input`
  border: none;
  outline: none;
  font-size: 1rem;
  border-bottom: 1px solid ${SIDEBAR_COLOR};

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputTextArea = styled.textarea`
  border: none;
  outline: none;
  width: calc(100% - 0.5rem);
  font-size: 1rem;
  border-bottom: 1px solid ${SIDEBAR_COLOR};
`;

export const MainView = styled.div`
  width: 100%;
  height: 100vh;
`;

export const MainContent = styled.div<{
  noPadding?: boolean;
  maxWidth?: boolean;
}>`
  padding: ${(props) => (props.noPadding ? 0 : "0 1rem")};
  max-width: ${(props) => (props.maxWidth ? "30rem" : undefined)};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  height: calc(100% - 65px);
`;

export const DesignStudioContainer = styled.div`
  height: 100%;
  position: relative;
`;

export const Header = styled.div`
  height: 4rem;
  margin-bottom: 10px;
  position: relative;
`;

export const ViewOptions = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    &:first-child {
      margin-bottom: 2px !important;
    }
  }
`;

export const ColumnContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
`;

export const InfoDescription = styled.div`
  font-size: 0.675rem;
  color: ${GRAY};
  white-space: pre-wrap;
`;

export const TabColumnContainer = styled.div<{width: string}>`
  width: ${(props) => props.width};
  flex-shrink: 0;
  flex-grow: 0;
  height: 100%;
  max-height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background: ${SIDEBAR_COLOR};
`;

export const MainColumn = styled.div`
  width: 10rem;
  flex-grow: 2;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TabColumnRowContainer = styled.div<{
  active?: boolean;
  customColor?: string;
}>`
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  font-size: 0.75rem;
  border-bottom: rgba(255, 255, 255, 0.2) solid 1px;
  display: flex;
  flex-direction: row;
  font-family: ${FONT_FAMILY_MEDIUM};
  background-color: ${(props) =>
    props.active === true ? "rgba(255, 255,255, 0.2)" : SIDEBAR_COLOR};
  color: ${(props) => props.customColor ?? WHITE};

  &:hover {
    background-color: ${(props) =>
      props.active === true
        ? "rgba(255, 255,255, 0.2)"
        : "rgba(255, 255,255, 0.1)"};
  }
`;

export const SectionHeaderRow = styled.div`
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  user-select: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-family: ${FONT_FAMILY_BOLD};
  color: ${WHITE};

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  transition: all 500ms ease-in-out;
`;

export const SectionRowContainer = styled.div<{collapsed: boolean}>`
  height: ${(props) => (props.collapsed ? "0px" : "auto")};
`;

export const SectionContainer = styled.div`
  height: calc(100% - 4rem);
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${VILLA_BLUE}; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.4); /* color of the scroll thumb */
  }
`;

export const SectionCollapseIconContainer = styled.div<{collapsed: boolean}>`
  height: 100%;
  transform: ${(props) => props.collapsed && "rotate(-180deg)"};
  transition: all 400ms ease-in-out;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  height: 4rem;
  font-size: 0.85rem;
  font-family: ${FONT_FAMILY_REGULAR};
  cursor: default;
  position: relative;
  padding: 0 0.25rem;
`;

export const HeaderSearchContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

export const HeaderSearchModeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
`;

export const HeaderSearchModeLabel = styled.div<{selected: boolean}>`
  font-size: 0.85rem;
  font-family: ${(props) =>
    props.selected ? FONT_FAMILY_MEDIUM : FONT_FAMILY_REGULAR};
  color: ${(props) => (props.selected ? WHITE : DIVIDER_COLOR)};
`;

export const HeaderSearchInput = styled.input`
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  border: 1px solid ${DROPDOWN_ACCENT_COLOR};
  flex-grow: 1;
  cursor: text;
  outline: none;
`;

export const HeaderNewItemButton = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0.5rem;
`;

export const FirstColumnContent = styled.div<{noScroll?: boolean}>`
  width: 100%;
  overflow-y: ${(props) => (props.noScroll ? "hidden" : "auto")};
  overflow-x: hidden;
`;

export const MainContentHeader = styled(TabColumnRowContainer)`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
`;

export const SideBarContainer = styled.div<{widthRems: number}>`
  width: ${(props) => props.widthRems}rem;
  height: 100%;
  background: ${SIDEBAR_COLOR};
  color: white;
  display: flex;
  flex-flow: column;
  transition: width 400ms ease;
  border-right: rgba(255, 255, 255, 0.2) solid 1px;

  .link-text {
    display: block;
  }
`;

export const SideBarHeader = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-bottom: rgba(255, 255, 255, 0.2) solid 1px;
`;

export const SideBarHeaderTitle = styled.div`
  font-size: 1rem;
  font-family: ${FONT_FAMILY_BOLD};
  margin: auto;
`;

export const SideBarMenuItemContainer = styled.div<{
  enabled?: boolean;
  selected?: boolean;
}>`
  width: 100%;
  position: relative;
  cursor: ${(props) => (props.enabled === true ? "pointer" : "default")};
  background-color: ${(props) =>
    props.selected === true ? "rgba(255, 255, 255, 0.1)" : SIDEBAR_COLOR};
  font-family: ${FONT_FAMILY_BOLD};
  opacity: ${(props) => (props.enabled === true ? 1 : 0.5)};
  font-style: ${(props) => (props.enabled === true ? "default" : "italic")};

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  border-bottom: rgba(255, 255, 255, 0.2) solid 1px;
`;

export const BottomMenuItem = styled.div<{
  customBackgroundColor?: string;
  customHoverColor?: string;
}>`
  width: 100%;
  height: 2rem;
  padding: 0.5rem 1rem;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: rgba(255, 255, 255, 0.2) solid 1px;
  background-color: ${(props) => props.customBackgroundColor ?? ""};

  &:hover {
    background-color: ${(props) =>
      props.customHoverColor ?? "rgba(255, 255, 255, 0.1)"};
  }
`;

export const BottomMenuItemIcon = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: right;
  align-items: center;
`;

export const BottomMenuItemText = styled.div`
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const MenuItemContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const ExpandMenuIconFloating = styled.div<{isExpanded?: boolean}>`
  transform: ${(props) => (props.isExpanded ? "rotate(-180deg)" : "")};
  transition: 400ms ease;
  width: 24px;
  position: absolute;
  right: 20px;
  bottom: 8px;
  font-size: 26px;
  cursor: pointer;
`;

export const MenuItemTitle = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  position: relative;
  white-space: nowrap;
`;
export const ControlPanelStyledForm = styled.form`
  width: 100%;

  .input-title {
    font-family: ${FONT_FAMILY_REGULAR} !important;
    font-size: 1.25rem !important;
  }
`;
export const NatStyledForm = styled.form`
  width: 100%;

  input,
  textarea {
    //background: #ffffff;
    ///* Black 30 */
    //
    //border: 1px solid rgba(0, 0, 0, 0.3);
    //padding: 0.5rem;
    //border-radius: 5px;
  }
`;

export const FormInputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

export const FormLabel = styled.label`
  margin: 0 1rem 0 0;
  text-transform: uppercase;
  line-height: 30px;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const FormInput = styled.input`
  width: 100%;
  border: 0;
  flex-grow: 1;
`;

export const FormSelect = styled(Select)`
  flex-grow: 1;
`;

export const NewTabIconContainer = styled.div`
  padding: 0.25rem;
  border-radius: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;

  &:hover {
    background-color: ${WHITE};
  }
`;
