import {useMemo} from "react";
import {
  getStudioCategories,
  getStudioModifierGroups,
  getStudioModifiers,
} from "@natomas-org/service";
import {getFullStudioStructure} from "../StudioEngine";
import {useFactoryLine} from "../../useProductCatalog/useFactoryLine/useFactoryLine";
import {useSelector} from "react-redux";
import {IStore} from "../../../slices/types/Store";
import {ProjectPriceMode} from "@natomas-org/core";

export const useStudioStructure = (
  studioProduct: any,
  pricingMode: ProjectPriceMode
) => {
  // Add flag for pricing mode
  const factoryId = useMemo(() => {
    return studioProduct?.productGroupId;
  }, [studioProduct]);
  const {selectedProductGroupVersionId, selectedProductGroupId} = useSelector(
    (state: IStore) => state.catalogSession
  );
  const versionId = useMemo(() => {
    if (selectedProductGroupId === factoryId) {
      return selectedProductGroupVersionId ?? undefined;
    }
    return undefined;
  }, [factoryId, selectedProductGroupId, selectedProductGroupVersionId]);
  const {categories, modifiers, modifierGroups, productModifiers} =
    useFactoryLine({factoryId: factoryId, versionId: versionId});
  // Product Group Information
  const factoryLineProductModifiers = useMemo(() => {
    return productModifiers?.[studioProduct?.id] ?? {};
  }, [productModifiers, studioProduct?.id]);

  // Studio information
  const studioCategories = useMemo(() => {
    return getStudioCategories(categories, studioProduct);
  }, [studioProduct, categories]);

  const studioModifierGroups = useMemo(() => {
    return getStudioModifierGroups(modifierGroups);
  }, [modifierGroups]);

  const studioModifiers = useMemo(() => {
    return getStudioModifiers(modifiers, factoryLineProductModifiers);
  }, [modifiers, factoryLineProductModifiers]);

  return useMemo(() => {
    return getFullStudioStructure(
      studioCategories,
      studioModifierGroups,
      studioModifiers
    );
  }, [studioCategories, studioModifiers, studioModifierGroups]);
};
