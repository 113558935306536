import {FactoryLineVersionState} from "./interfaces";
import {Utilities} from "../index";
import {
  FACTORY_LINE_CATEGORIES_COLLECTION_KEY,
  FACTORY_LINE_COLLECTION_KEY,
  FACTORY_LINE_INFO_SET_COLLECTION_KEY,
  FACTORY_LINE_MODIFIER_GROUPS_COLLECTION_KEY,
  FACTORY_LINE_MODIFIERS_COLLECTION_KEY,
  FACTORY_LINE_PRODUCT_MODIFIERS_COLLECTION_KEY,
  FACTORY_LINE_PRODUCTS_COLLECTION_KEY,
  FACTORY_LINE_VERSION_COLLECTION_KEY,
  INatFactoryLine,
  INVENTORY_UNIT_COLLECTION_KEY,
} from "@natomas-org/core";
import {getDocData} from "../utilities";
import {errorLogger} from "../../../developerSettings";

export const getFactoryLinesRef = () => {
  try {
    return Utilities.collection(FACTORY_LINE_COLLECTION_KEY);
  } catch (e) {
    errorLogger(e);
  }
  return null;
};

export const getFactoryLineRef = (id: string) => {
  try {
    return getFactoryLinesRef()?.doc(id);
  } catch (e) {
    errorLogger(e);
  }
  return null;
};

export const getFactoryLineInfoSetRef = (id: string) => {
  try {
    let ref = getFactoryLineRef(id)?.collection(
      FACTORY_LINE_INFO_SET_COLLECTION_KEY
    );
    if (ref) {
      return ref;
    }
  } catch (e) {
    errorLogger(e);
  }
  return null;
};

export const getFactoryLineVersionRef = (id: string, version: string) => {
  const ref = getFactoryLinesRef()
    ?.doc(id)
    .collection(FACTORY_LINE_VERSION_COLLECTION_KEY)
    .doc(version);
  if (ref) {
    return ref;
  }
  console.log("Factory line version ref not found.");
  return undefined;
};

export const getFactoryLineVersionProductCustomModifierRef = (
  id: string,
  version: string,
  productId: string,
  modifierId: string
) => {
  if (modifierId) {
    return getFactoryLineVersionProductCustomModifiersRef(
      id,
      version,
      productId
    )?.doc(modifierId);
  } else {
    return getFactoryLineVersionProductCustomModifiersRef(
      id,
      version,
      productId
    )?.doc();
  }
};

export const getFactoryLineVersionProductCustomModifiersRef = (
  id: string,
  version: string,
  productId: string
) => {
  const ref = getFactoryLineVersionProductRef(id, version, productId);
  if (ref) {
    return ref.collection(FACTORY_LINE_PRODUCT_MODIFIERS_COLLECTION_KEY);
  }
  console.log("Custom modifiers ref not found.");
  return undefined;
};

export const getFactoryLineVersionProductRef = (
  id: string,
  version: string,
  productId?: string
) => {
  const ref = getFactoryLineVersionProductsRef(id, version)?.doc(productId);
  if (ref) {
    return ref;
  }
  console.log("Product ref not found, creating one.");
  return getFactoryLineVersionProductsRef(id, version)?.doc();
};

export const getFactoryLineVersionProductsRef = (
  id: string,
  version: string
) => {
  const ref = getFactoryLineVersionRef(id, version)?.collection(
    FACTORY_LINE_PRODUCTS_COLLECTION_KEY
  );
  if (ref) {
    return ref;
  }
  console.log("Products collection ref not found.");
  return undefined;
};

export const getFactoryLineInventoryRef = (id: string) => {
  const ref = getFactoryLineRef(id)?.collection(INVENTORY_UNIT_COLLECTION_KEY);
  if (ref) {
    return ref;
  }
  console.log("Inventory collection ref not found.");
  return undefined;
};

export const getFactoryLineVersionCategoryRef = (
  id: string,
  versionId: string,
  categoryId?: string
) => {
  if (categoryId) {
    return getFactoryLineVersionCategoriesRef(id, versionId)?.doc(categoryId);
  } else {
    return getFactoryLineVersionCategoriesRef(id, versionId)?.doc();
  }
};

export const getFactoryLineVersionCategoriesRef = (
  id: string,
  version: string
) => {
  return getFactoryLineVersionRef(id, version)?.collection(
    FACTORY_LINE_CATEGORIES_COLLECTION_KEY
  );
};

export const getFactoryLineVersionModifierRef = (
  id: string,
  versionId: string,
  modifierId?: string
) => {
  if (modifierId) {
    return getFactoryLineVersionModifiersRef(id, versionId)?.doc(modifierId);
  } else {
    return getFactoryLineVersionModifiersRef(id, versionId)?.doc();
  }
};

export const getFactoryLineVersionModifiersRef = (
  id: string,
  version: string
) => {
  return getFactoryLineVersionRef(id, version)?.collection(
    FACTORY_LINE_MODIFIERS_COLLECTION_KEY
  );
};

export const getFactoryLineVersionModifierGroupRef = (
  id: string,
  version: string,
  modifierGroupId?: string
) => {
  if (modifierGroupId) {
    return getFactoryLineVersionModifierGroupsRef(id, version)?.doc(
      modifierGroupId
    );
  } else {
    return getFactoryLineVersionModifierGroupsRef(id, version)?.doc();
  }
};

export const getFactoryLineVersionModifierGroupsRef = (
  id: string,
  version: string
) => {
  return getFactoryLineVersionRef(id, version)?.collection(
    FACTORY_LINE_MODIFIER_GROUPS_COLLECTION_KEY
  );
};

export const getFactoryLine = (id: string) => {
  const ref = getFactoryLineRef(id);
  if (ref) {
    return getDocData(ref);
  }
  return null;
};

const getFactoryLineVersionId = (
  factoryLine: INatFactoryLine,
  version: FactoryLineVersionState
) => {
  if (
    version === FactoryLineVersionState.PUBLISHED &&
    !!factoryLine?.published_id
  ) {
    return factoryLine.published_id;
  } else if (
    version === FactoryLineVersionState.DRAFT &&
    !!factoryLine?.draft_id
  ) {
    return factoryLine.draft_id;
  }
  return null;
};

export const findFactoryLineVersionRef = async (
  id: string,
  version: FactoryLineVersionState
) => {
  // We need to get the factory line doc to get the published/draft version ids
  const factoryLine = await getFactoryLine(id);
  // If the factory line doc exists, determine which version we need
  if (factoryLine) {
    const versionId = getFactoryLineVersionId(factoryLine, version);
    // If the appropriate version id is found, return the ref to that version
    if (versionId) {
      return getFactoryLineRef(id)
        ?.collection(FACTORY_LINE_VERSION_COLLECTION_KEY)
        .doc(versionId);
    }
  }
  return null;
};
