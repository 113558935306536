import {QuickInfoSectionTitle} from "../styles";
import React, {useMemo} from "react";
import {getSurveySummary} from "../_helpers";
import {InfoItem} from "../../../../../_shared/InfoItem";
import {IJurisdictionData, IPropertyData} from "@natomas-org/core";
import useActiveProject from "../../../../../../../_shared/hooks/useProject/useActiveProject";

export const SiteWorkSection = (props: {
  propertyData: IPropertyData | undefined;
}) => {
  const {site} = useActiveProject();

  const surveyResultsValue = useMemo(() => {
    return getSurveySummary(site?.surveyAnswers);
  }, [site]);

  return (
    <>
      <QuickInfoSectionTitle>Site work</QuickInfoSectionTitle>
      <InfoItem
        label={"Approval/Submittal Requirements"}
        value={getApprovalAndSubmittalString(
          props.propertyData?.jurisdictionData
        )}
        description={
          "Automatically-generated approval and submittal requirements."
        }
      />
      <InfoItem
        label={"Site Work Quiz Results"}
        value={surveyResultsValue}
        description={"Object representing the survey results for site work."}
      />
      <InfoItem
        label={"Estimate Status?"}
        value={
          !site?.surveyAnswers
            ? "N/A"
            : site?.autoGeneratedQuote
            ? "Autogenerated"
            : "Custom"
        }
        description={
          "The site work estimate is either autogenerated or customized by an admin."
        }
      />
    </>
  );
};

const getApprovalAndSubmittalString = (
  jurisdictionData: IJurisdictionData | undefined
): string => {
  return jurisdictionData?.requirements
    ? [
        ...(jurisdictionData?.requirements?.approvalReqs ?? ""),
        ...(jurisdictionData?.requirements?.submittalReqs ?? ""),
      ].toString()
    : "Not generated";
};
