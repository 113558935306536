import styled from "styled-components";
import {VILLA_SLATE} from "../../../../_shared/colors"; //"#ADD6C2";
export const MAP_JSON_ACCENT_COLOR_DARK = VILLA_SLATE;
export const MAP_BUTTONS_TEXT = "#7e7e7e";

// At what zoom level do we stop showing the tooltip for nearby projects?
//  This value is also the threshold of whether click event inspects the parcel
//  or inspects the nearby project.
export const ENABLE_SATELLITE_IN_3D = false;
export const SATELLITE_THRESHOLD = 16;
export const PROJECTS_MAX_ZOOM = 17; // SATELLITE_THRESHOLD;
export const PROJECTS_MAX_ZOOM_TOOLTIP = 17;
export const PROJECTS_ZOOM_ON_CLICK = 16.5;
export const PARCELS_MIN_ZOOM = 14.7;

export const PROJECTS_IN_VIEWPORT_SEARCH_DELAY_MS = 725;

export const DEFAULT_CA_VIEWPORT = {
  zoom: 5.57,
  bearing: 0,
  pitch: 0,
  mLng: -119.7777,
  mLat: 36.7777,
  satelliteView: false,
};

export const DEFAULT_SF_VIEWPORT = {
  zoom: 9.07,
  bearing: 0,
  pitch: 0,
  mLng: -122.30944223623,
  mLat: 37.7932300358935,
  satelliteView: false,
};

// LAYER NAMES
export const CLUSTER_LAYER_NAME = "projects";

// LEGEND CONTENT
export const LEGEND_CIRCLE_DIV = styled.div<{
  color: string;
  borderColor: string;
}>`
  background-color: ${(props) => `${props.color}50`};
  border: ${(props) => `2px solid ${props.borderColor}`};
  height: 22px;
  width: 22px;
  border-radius: 50%;
`;
