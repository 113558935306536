import React from "react";
import {PageContainer} from "../../../../../../../../../../../_shared/generics/page/components/PageContainer";
import StudioEditorWarning from "./StudioEditorWarning";

interface StudioEditorHeaderProps {
  isOlderVersion?: boolean;
}

const StudioEditorHeader = (props: StudioEditorHeaderProps) => {
  const {isOlderVersion} = props;

  return (
    <PageContainer>
      {isOlderVersion && <StudioEditorWarning />}
    </PageContainer>
  );
};

export default StudioEditorHeader;
