import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {setRegistrationModalVisible} from "../slices/SessionSlice/SessionSlice";

export const useRegistration = () => {
  const {visible, signUp, registrationValues, redirectType, isForced} =
    useSelector((state: IStore) => state.session.registration);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(setRegistrationModalVisible(false));
  };

  return {
    close,
    isForced,
    visible,
    signUp,
    registrationValues,
    redirectType,
  };
};
