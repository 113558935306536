import React, {useCallback} from "react";
import {InputProps, InputSelectionProps} from "../../interface";
import {StyledSelect} from "./style";
import {ThemeProvider} from "styled-components";
import {DefaultError} from "../../generic/DefaultError";
import {Container, Label} from "../style";

const Select = (props: InputProps) => {
  const {id, label, error, selectionProps, onChange, additionalContent} = props;
  // Write the value to the input field
  const forceChange = useCallback(
    (value?: string) => {
      return onChange?.({
        target: {
          id,
          value: value ?? "",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    },
    [id, onChange]
  );

  if (!selectionProps) {
    window.alert("SingleSelectList: No selections provided");
    return null;
  }

  return (
    <Container id={`${id}-container`}>
      <Label htmlFor={id} hidden={!label}>
        {label}
      </Label>
      <StyledSelect
        onChange={(event) => forceChange(event.target.value)}
        id={id}
      >
        {selectionProps?.map(
          (selection: InputSelectionProps, index: number) => {
            const selectionId = `${id}-${index}`;
            const selected = props.value === selection.value;
            return (
              <ThemeProvider
                theme={{
                  selected,
                }}
                key={`${selectionId}-theme`}
              >
                <option value={selection.value}>{selection.label}</option>
              </ThemeProvider>
            );
          }
        )}
      </StyledSelect>
      {additionalContent}
      <DefaultError id={id} error={error} />
    </Container>
  );
};

export default Select;
