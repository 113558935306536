import styled from "styled-components";
import {GRANITE} from "../../../../_shared/colors";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

const BannerContainer = styled.div<{height: number}>`
  height: ${(props) => props.height}rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 32px 0;
`;

const MobileBannerContainer = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 32px 0;
`;

const MobileBannerImageContainer = styled.div`
  height: 300px;
  width: 100%;
  position: relative;
`;

const BannerImageContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  max-width: 60%;
  position: relative;
  border-radius: 16px;
  overflow: clip;
`;

const BannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  max-width: 448px;
`;

const BannerBadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

const BannerContentWrapper = styled.div`
  height: 100%;
  max-width: 40%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BannerTitle = styled.h1<{isMobile?: boolean}>`
  font-size: ${(props) => (props?.isMobile ? 24 : 32)}px;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin: 0;
`;

const BannerDescription = styled.h1<{isMobile?: boolean}>`
  font-size: ${(props) => (props?.isMobile ? 16 : 18)}px;
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${GRANITE};
  margin: 0;
`;

export {
  MobileBannerContainer,
  MobileBannerImageContainer,
  BannerBadgeContainer,
  BannerContainer,
  BannerContentContainer,
  BannerContentWrapper,
  BannerDescription,
  BannerImageContainer,
  BannerTitle,
};
