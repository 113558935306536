import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  Cell,
  MetaBase,
  Row,
  TableState,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  ACTIONS_KEY,
  COMPLETED_PROJECT_FEATURED_KEY,
  COMPLETED_PROJECT_IMAGES_KEY,
  COMPLETED_PROJECT_PUBLISHED_KEY,
  completedProjectsColumns,
  completedProjectsHidePricingState,
} from "./constants";
import {useCompletedProjects} from "../../_shared/hooks/useCompletedProjects";
import {
  InfoSetTableElement,
  InfoSetTableRow,
  ReactTableHeaderRowCell,
  ReactTableHeaderRowCellContent,
} from "../../factory-info-set/InfoSetTable/styles";
import {EditableCompletedProjectsCell} from "./components/EditableTextCell/EditableCompletedProjectsCell";
import {
  CompletedProjectsEditorButtonContainer,
  CompletedProjectsEditorContainer,
  CompletedProjectsEditorSwitchContainer,
  CompletedProjectsTableContainer,
  CompletedProjectsValueCell,
  CompletedProjectsWrapper,
} from "./styles";
import {NatModal, NatModalSize} from "../../_shared/generics/modal/NatModal";
import {
  Address,
  ICompletedProjectsDictionary,
  IPublicProjectInfo,
  IPublicProjectMetadata,
  ProjectStatusDetails,
  PublicProjectDisplayType,
} from "@natomas-org/core";
import {NatButton} from "../../_shared/generics/button";
import {saveDataToCompletedProjects} from "./helpers/saveDataToCompletedProjects";
import {CompletedProjectsImageManagerModalContent} from "./components/CompletedProjectsImageManagerModalContent/CompletedProjectsImageManagerModalContent";
import {HEADER_Z_INDEX} from "../../_shared/styles";
import {ReactTableColumnHeader} from "../../factory-info-set/InfoSetTable/components/ColumnHeader";
import {MicroSwitch} from "../../_shared/generics/switch/microSwitch";
import {MapProjectDetailsModal} from "../../mapping/views/components/ProjectDetailsModal";
import {NatSize} from "../../_shared/generics/_shared";
import {Helmet} from "react-helmet-async";
import {usePermissions} from "../../_shared/hooks/usePermissions/usePermissions";
import {VILLA_APPLE_GREEN} from "../../_shared/colors";
import {PublicProjectInfo} from "@natomas-org/service";
import {useWindowSize} from "../../_shared/hooks/useWindowSize";

export enum FeaturedStatus {
  Featured,
  Not_Featured,
  Incomplete_Project,
  Insufficient_Data,
}

export enum PublishedStatus {
  Published,
  Not_Published,
  Incomplete_Project,
}

type CompletedProjectCell = {
  published: PublishedStatus;
  featured: FeaturedStatus;
  title: string;
  metadata: IPublicProjectMetadata | undefined;
  displayType: PublicProjectDisplayType | undefined;
  imageIds: string[];
  address: string;
  stage: string;
  code: string;
  randomizedCoordinates?: {
    lat: number;
    lng: number;
  };
  status_details?: ProjectStatusDetails;
  number?: number;
};
export const CompletedProjectsEditor = () => {
  const {access} = usePermissions();
  const columns = useMemo(() => completedProjectsColumns, []);
  const {allProjects} = useCompletedProjects();
  const completedProjects = useMemo(() => {
    return allProjects ?? {};
  }, [allProjects]);

  const [showPricing, setShowPricing] = useState(false);
  const [selectedColumnId, setSelectedColumnId] = useState<
    string | undefined
  >();
  const [cellSelected, setCellSelected] = useState<
    IPublicProjectInfo | undefined
  >();
  const [editingProject, setEditingProject] = useState<
    IPublicProjectInfo | undefined
  >();
  const [diffsToSave, setDiffsToSave] = useState<ICompletedProjectsDictionary>(
    {}
  );
  const data = useMemo(() => {
    let d: {[projectNumber: number]: CompletedProjectCell} = {};
    Object.values(completedProjects)?.forEach((data: any) => {
      if (!data?.number) {
        return;
      }
      d[data.number] = {
        ...data,
        address: Address.getFullAddress(
          Address.dataToAddress(data?.project_address)
        ),

        stage: data?.status_details?.stage,
        published:
          data?.published === true
            ? PublishedStatus.Published
            : PublishedStatus.Not_Published,
        featured:
          !PublicProjectInfo.getHeroImageId(data) ||
          !PublicProjectInfo.getProjectTitle(data)
            ? FeaturedStatus.Insufficient_Data
            : data?.featured === true
            ? FeaturedStatus.Featured
            : FeaturedStatus.Not_Featured,
      };

      if (diffsToSave[data?.code]?.published !== undefined) {
        d[data.number].published =
          diffsToSave[data?.code]?.published === true
            ? PublishedStatus.Published
            : PublishedStatus.Not_Published;
      }
      if (diffsToSave[data?.code]?.featured !== undefined) {
        d[data.number].featured =
          diffsToSave[data?.code]?.featured === true
            ? FeaturedStatus.Featured
            : FeaturedStatus.Not_Featured;
      }
      const imageIds = diffsToSave[data?.code]?.imageIds;
      if (imageIds !== undefined) {
        d[data.number].imageIds = imageIds;
      }
      const diffTitle = diffsToSave[data?.code]?.title;
      if (!!diffTitle) {
        d[data.number].title = diffTitle;
      }
    });

    return Object.values(d);
  }, [completedProjects, diffsToSave]);
  const [showImageManagerModal, setShowImageManagerModal] =
    useState<boolean>(false);
  const [showProjectPreviewModal, setShowProjectPreviewModal] =
    useState<boolean>(false);

  const onContextMenuRow = (row: Row<any>, e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };
  const defaultColumn = useMemo(() => {
    return {
      Cell: EditableCompletedProjectsCell,
    };
  }, []);
  const onSelectCell = (cell: Cell<any>, e: any) => {
    if (cell.column.id === ACTIONS_KEY) {
      return;
    }
    setCellSelected(cell?.row?.values as IPublicProjectInfo);
    if (!access?.completedProjects) {
      return;
    }
    setEditingProject(cell?.row?.values as IPublicProjectInfo);
    setSelectedColumnId(cell?.column?.id);
  };
  const updateCellValue = useCallback(
    (code: string, columnId: string, value: any) => {
      if (completedProjects && code) {
        const currentProject: any = completedProjects[code];
        const currentDiffs: any = Object.assign({}, diffsToSave);
        const currentProjectEdits: any = Object.assign({}, currentDiffs[code]);

        // if the value is different than the current value, add it to the diffs to save
        let newDiffs;
        if (currentProject && currentProject[columnId] !== value) {
          newDiffs = {
            ...diffsToSave,
            [code]: {
              ...currentProjectEdits,
              [columnId]: value,
              id: completedProjects[code]?.id,
            },
          };
          // if the value is the same as the current value and there are no more edits on the project, remove it from the diffs to save
        } else if (
          Object.keys(currentProjectEdits)?.length === 2 &&
          currentProjectEdits[columnId] !== undefined
        ) {
          delete currentDiffs[code];
          newDiffs = currentDiffs;
          // if the value is the same as the current value, remove it from the project edits to save
        } else {
          delete currentProjectEdits[columnId];
          newDiffs = {
            ...diffsToSave,
            [code]: currentProjectEdits,
          };
        }
        setDiffsToSave(newDiffs);
      }
    },
    [completedProjects, diffsToSave]
  );
  const isCellModified = (cell: any): boolean => {
    if (
      diffsToSave &&
      diffsToSave[cell?.row?.values?.code] &&
      cell?.column?.id
    ) {
      // @ts-ignore
      return diffsToSave[cell?.row?.values?.code][cell?.column?.id] != null;
    }
    return false;
  };

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(
      {
        columns,
        data,
        initialState: completedProjectsHidePricingState,
        defaultColumn,
        updateCellValue,
        selectedColumnId,
        cellEditing: editingProject,
        setShowImageManagerModal,
        setShowProjectPreviewModal,
        autoResetSortBy: false,
        useControlledState: useCallback(
          (state: TableState<object>, meta: MetaBase<object>) => {
            let hiddenColumns = completedProjectsHidePricingState.hiddenColumns;
            if (showPricing) {
              hiddenColumns = [];
            }
            if (!access?.completedProjects) {
              hiddenColumns = [...hiddenColumns, ACTIONS_KEY];
            }
            return Object.assign({}, state, {
              hiddenColumns: hiddenColumns,
            });
          },
          [access?.completedProjects, showPricing]
        ),
      },
      useSortBy,
      useRowSelect
    );

  // optimize the table rendering by only rendering the rows that are visible
  const {startRow, endRow} = useVisibleRows();

  return (
    <CompletedProjectsEditorContainer>
      <Helmet>
        <title>Villa | Admin Completed Projects Editor</title>
      </Helmet>
      <CompletedProjectsEditorButtonContainer>
        <NatButton
          label={"Save Changes"}
          size={NatSize.SMALL}
          style={{width: "max-content"}}
          spinnerEnabled={true}
          clickEvent={async () => {
            return saveDataToCompletedProjects(diffsToSave).then(() => {
              return setDiffsToSave({});
            });
          }}
          hidden={!access?.completedProjects}
          disabled={
            Object.values(diffsToSave)?.length < 1 || !access?.completedProjects
          }
        />
        <CompletedProjectsEditorSwitchContainer>
          <div>Show SmartSheet Project Pricing</div>
          <MicroSwitch
            isChecked={showPricing}
            setChecked={(showPricing) => {
              setShowPricing(showPricing);
            }}
          />
        </CompletedProjectsEditorSwitchContainer>
      </CompletedProjectsEditorButtonContainer>
      <CompletedProjectsWrapper>
        <CompletedProjectsTableContainer showPricing={showPricing}>
          <InfoSetTableElement {...getTableProps()}>
            <thead
              style={{
                zIndex: HEADER_Z_INDEX - 100,
                position: "sticky",
                top: "3rem",
              }}
            >
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const headerProps = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );
                    return (
                      <ReactTableHeaderRowCell
                        {...headerProps}
                        onClick={undefined}
                      >
                        <ReactTableHeaderRowCellContent
                          style={{minWidth: "max-content"}}
                        >
                          <ReactTableColumnHeader
                            onClick={headerProps?.onClick}
                            column={column}
                          />
                        </ReactTableHeaderRowCellContent>
                      </ReactTableHeaderRowCell>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: Row<any>, rowCount) => {
                prepareRow(row);
                return (
                  <InfoSetTableRow
                    {...row.getRowProps()}
                    onContextMenu={(e) => {
                      onContextMenuRow(row, e);
                    }}
                    onClick={() => {
                      if (!access?.completedProjects) {
                        return;
                      }
                      // setCellSelected(row?.original);
                    }}
                    style={{
                      backgroundColor:
                        cellSelected?.code === row?.values?.code
                          ? `${VILLA_APPLE_GREEN}60`
                          : undefined,
                      maxHeight: "4rem",
                      height: "4rem",
                    }}
                  >
                    {row.cells.map((cell) => {
                      const cellProps = cell.getCellProps();
                      const visible =
                        rowCount >= startRow && rowCount <= endRow;
                      let content;
                      if (visible) {
                        content = cell.render("Cell");
                      } else {
                        switch (cell.column.id) {
                          case COMPLETED_PROJECT_FEATURED_KEY:
                          case COMPLETED_PROJECT_PUBLISHED_KEY:
                          case COMPLETED_PROJECT_IMAGES_KEY:
                          case ACTIONS_KEY:
                            content = "...";
                            break;
                          default:
                            content = cell.value;
                            break;
                        }
                      }

                      return (
                        <CompletedProjectsValueCell
                          {...cellProps}
                          onClick={(e) => {
                            onSelectCell(cell, e);
                          }}
                          modified={isCellModified(cell)}
                          centerAlign={
                            cell?.column?.id ===
                              COMPLETED_PROJECT_FEATURED_KEY ||
                            cell?.column?.id === COMPLETED_PROJECT_PUBLISHED_KEY
                          }
                        >
                          {content}
                        </CompletedProjectsValueCell>
                      );
                    })}
                  </InfoSetTableRow>
                );
              })}
            </tbody>
          </InfoSetTableElement>
        </CompletedProjectsTableContainer>
      </CompletedProjectsWrapper>
      <NatModal
        content={
          <CompletedProjectsImageManagerModalContent
            selectedProject={cellSelected}
            setImageIds={(imageIds) => {
              if (!editingProject?.code) {
                return;
              }
              updateCellValue(
                editingProject.code,
                COMPLETED_PROJECT_IMAGES_KEY,
                imageIds
              );
            }}
            handleClose={() => {
              setShowImageManagerModal(false);
            }}
            readonly={!access?.completedProjects}
          />
        }
        show={showImageManagerModal}
        size={NatModalSize.FULLSCREEN}
        handleClose={() => {
          setShowImageManagerModal(false);
        }}
        // need this so that the smart scroll logic doesn't get messed up
        removeFixedPlacementLogic={true}
      />
      <NatModal
        content={
          <MapProjectDetailsModal
            projectData={Object.assign(
              {},
              cellSelected,
              allProjects && cellSelected?.code
                ? {
                    metadata: allProjects[cellSelected?.code]?.metadata,
                  }
                : undefined
            )}
            viewOnMapAction={null}
            handleClose={() => {
              setShowProjectPreviewModal(false);
            }}
            hideButton={true}
          />
        }
        customSize={{height: "100%", width: "30rem"}}
        customPadding={"0 0"}
        show={showProjectPreviewModal}
        handleClose={() => {
          setShowProjectPreviewModal(false);
        }}
        onlyShowContent={true}
        // need this so that the smart scroll logic doesn't get messed up
        removeFixedPlacementLogic={true}
      />
    </CompletedProjectsEditorContainer>
  );
};

function useVisibleRows() {
  const [scrollY, setScrollY] = useState(window.scrollY);

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {height} = useWindowSize();
  const {startRow, endRow} = useMemo(() => {
    const rowHeight = 64; // 4rem;
    const buffer = 120; // buffer up and down 120 px;

    const start = Math.max(Math.floor((scrollY - buffer) / rowHeight), 0);
    const end = Math.max(
      Math.ceil((scrollY + height + buffer) / rowHeight),
      100
    );
    return {
      startRow: start,
      endRow: end,
    };
  }, [height, scrollY]);

  return {startRow, endRow};
}
