import React, {useCallback, useEffect, useState} from "react";
import {IFactoryInventoryStudioEditorModifierGroup} from "../../ModifierGroupSection/interface";
import {
  FactoryInventoryInput,
  FactoryInventoryInputType,
} from "../../../../../_shared/FactoryInventoryInput";
import {NatButton} from "../../../../../../../../../../../../_shared/generics/button";
import ModifierGroupSelectorType from "./ModifierGroupSelectorType";
import {
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../../_shared/generics/_shared";
import {DefaultFieldEditorProps} from "../FieldEditor";
import {StudioEditorPopupControllerContainer} from "../styles";

interface ModifierGroupFieldEditorProps extends DefaultFieldEditorProps {
  path: {
    categoryId?: string;
  };
  initialValue?: IFactoryInventoryStudioEditorModifierGroup;
  currentValue?: IFactoryInventoryStudioEditorModifierGroup;
}

const ModifierGroupFieldEditor = (props: ModifierGroupFieldEditorProps) => {
  const {initialValue, currentValue, path, reset, setValue} = props;
  const {id} = currentValue ?? {};

  const [edits, setEdits] = useState(currentValue);

  useEffect(() => {
    setEdits(currentValue);
  }, [currentValue]);

  const updateModifierGroup = useCallback(() => {
    const categoryId = path?.categoryId;
    if (id && categoryId) {
      setValue(edits);
      reset();
    }
  }, [edits, id, path?.categoryId, reset, setValue]);

  const updateEdits = useCallback(
    (fieldKey: string, value: any) => {
      setEdits((current: any) => {
        return {
          ...current,
          [fieldKey]: value,
        };
      });
    },
    [setEdits]
  );

  if (!path?.categoryId || !initialValue?.id) {
    return null;
  }

  return (
    <>
      <FactoryInventoryInput
        id={`${id}-title`}
        key={`${id}-title`}
        label={"Title"}
        type={FactoryInventoryInputType.TEXT}
        initialValue={initialValue?.title}
        value={edits?.title}
        onChange={(value: any) => {
          updateEdits("title", value);
        }}
        resetChange={() =>
          // @ts-ignore
          setEdits((current) => {
            return {
              ...current,
              title: initialValue?.title,
            };
          })
        }
      />
      <FactoryInventoryInput
        id={`${id}-description`}
        key={`${id}-description`}
        label={"Description"}
        type={FactoryInventoryInputType.TEXT_BOX}
        initialValue={initialValue?.description}
        value={edits?.description}
        onChange={(value: any) => {
          updateEdits("description", value);
        }}
        resetChange={() =>
          // @ts-ignore
          setEdits((current) => {
            return {
              ...current,
              description: initialValue?.description,
            };
          })
        }
      />
      <ModifierGroupSelectorType
        id={`${id}-type`}
        key={`${id}-type`}
        edits={edits}
        initialValues={initialValue}
        updateEdits={updateEdits}
      />
      <StudioEditorPopupControllerContainer>
        <NatButton
          size={NatSize.SMALL}
          label={"Save Changes"}
          clickEvent={updateModifierGroup}
          option={StyleOption.ADMIN_SOLID}
        />
      </StudioEditorPopupControllerContainer>
    </>
  );
};

export default ModifierGroupFieldEditor;
