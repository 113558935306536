import React, {useMemo, useState} from "react";
import styled from "styled-components";
import {
  IProjectNoteDetails,
  IProjectTaskStatus,
  ProjectNoteType,
} from "@natomas-org/core";
import {useActiveProjectNotes} from "../../../_shared/hooks/useProject/useActiveProjectNotes";
import {ProjectTaskListItem} from "./ProjectTaskListItem";
import NatLabel, {NatLabelType} from "../../../_shared/generics/label/NatLabel";
import {NatLink} from "../../../_shared/generics/button";
import {NatSize} from "../../../_shared/generics/_shared";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";

export const useProjectTasksList = (props: IProjectTasksListProps) => {
  const {public_facing, onEditTask} = props;
  const {projectNotes} = useActiveProjectNotes();
  const {id: pid} = useActiveProject();
  const {isAdmin} = useCurrentUser();
  const [showCompletedTasksLocal, setShowCompletedTasksLocal] = useState(
    !public_facing
  );
  const showCompletedTasks =
    props.showCompletedTasks ?? showCompletedTasksLocal;
  const taskToggleText = useMemo(() => {
    if (public_facing && showCompletedTasksLocal) {
      return "Show incomplete tasks";
    } else {
      return (showCompletedTasksLocal ? "Hide" : "Show") + " completed tasks";
    }
  }, [public_facing, showCompletedTasksLocal]);
  const sortedTasks = useMemo(() => {
    return projectNotes
      .sort((a: IProjectNoteDetails, b: IProjectNoteDetails) => {
        if (
          a.details?.completion_timestamp &&
          !b.details?.completion_timestamp
        ) {
          return 1;
        }
        if (
          !a.details?.completion_timestamp &&
          b.details?.completion_timestamp
        ) {
          return -1;
        }
        if (
          a.details?.completion_timestamp &&
          b.details?.completion_timestamp
        ) {
          return (
            b.details?.completion_timestamp - a.details?.completion_timestamp
          );
        }

        return b.timestamp - a.timestamp;
      })
      .filter((note: IProjectNoteDetails) => {
        return note.type === ProjectNoteType.TASK;
      });
  }, [projectNotes]);

  const completedTaskCount = useMemo(() => {
    return sortedTasks
      .filter((note: IProjectNoteDetails) => {
        return note.details?.status === IProjectTaskStatus.COMPLETED;
      })
      .filter((note) => {
        return !(!isAdmin && note.hidden);
      }).length;
  }, [isAdmin, sortedTasks]);
  const filteredTasks = useMemo(() => {
    return sortedTasks
      .filter((note: IProjectNoteDetails) => {
        if (!showCompletedTasks) {
          return note.details?.status !== IProjectTaskStatus.COMPLETED;
        } else if (public_facing) {
          return note.details?.status === IProjectTaskStatus.COMPLETED;
        } else {
          return true;
        }
      })
      .filter((note: IProjectNoteDetails) => {
        return !(!isAdmin && note.hidden);
      });
  }, [sortedTasks, showCompletedTasks, public_facing, isAdmin]);

  return {
    TaskList: (
      <Container>
        <div style={{textAlign: "center"}}>
          <NatLabel
            label={"No tasks due"}
            type={NatLabelType.P1}
            hidden={filteredTasks.length > 0}
          />
        </div>
        {filteredTasks.map((task) => {
          return (
            <ProjectTaskListItem
              public_facing={public_facing}
              projectId={pid}
              onEditTask={onEditTask}
              key={task.id}
              task={task}
            />
          );
        })}
      </Container>
    ),
    ToggleElement: (
      <div style={{textAlign: "center"}}>
        <NatLink
          clickEvent={() => {
            setShowCompletedTasksLocal(!showCompletedTasks);
          }}
          size={NatSize.SMALL}
          label={taskToggleText}
          hidden={completedTaskCount === 0}
        />
      </div>
    ),
  };
};

const Container = styled.div`
  gap: 1rem;
`;

interface IProjectTasksListProps {
  public_facing: boolean;
  showCompletedTasks?: boolean;
  onEditTask?: (note: IProjectNoteDetails) => void;
}
