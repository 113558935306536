import React, {useCallback, useState} from "react";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../../_shared/hooks/useDynamicValue";
import {
  scrollToElementWithOffset,
  setURLQuery,
} from "../../../../../_shared/navigation/_helpers";
import {useDelay} from "../../../../../_shared/hooks/useDelay";
import {PROPOSAL_GENERATE_PDF} from "../DTC/components/constants";
import {PhotoViewDismiss} from "../../../../../_shared/generics/presentation/styles";
import {URL_PARAM_KEY_PAGE_ID} from "@natomas-org/service";
import {AiOutlineClose} from "react-icons/ai";
import {
  PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS,
  PORTAL_BUDGET_ID,
  PORTAL_BUDGET_OVERVIEW_ID,
  usePresentationSections,
} from "../../HCPTitleNav/navigation_ids";
import {FullScreenPresentationView} from "../../../../_shared/components/FullScreenPresentationView";
import {HCPPresentationFooter} from "../DTC/components/HCPPresentationFooter";
import {Container} from "../styles";
import {FULL_SCREEN_WIDTH_REM} from "../../HCPUnitView/DesignSelections/constants";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";

export const B2BPresentationView = (props: {
  hideClose?: boolean;
  readyToPrint?: boolean;
}) => {
  const {mergedCustomerProjectInfos, site} = useActiveProject();
  const {metadata} = mergedCustomerProjectInfos ?? {};
  const fullWidth = useDynamicValue(fullColumnWidths);
  const scrollToSection = useCallback((sectionId: string) => {
    scrollToElementWithOffset(sectionId, {top: 0}, true);
  }, []);
  const fadeIn = useDelay({milliseconds: 300}).ready;
  const presentationSections = usePresentationSections();
  // state for ready to print
  const [readyToPrint, setReadyToPrint] = useState<boolean>(
    props.readyToPrint ?? false
  );

  return (
    <Container
      fadeIn={fadeIn}
      style={
        readyToPrint
          ? {
              minWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
              maxWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
            }
          : {maxWidth: "99vw", overflowX: "clip"}
      }
    >
      <PROPOSAL_GENERATE_PDF.Provider value={readyToPrint}>
        <PhotoViewDismiss
          hidden={readyToPrint || props.hideClose}
          onClick={() => {
            setURLQuery(URL_PARAM_KEY_PAGE_ID, null);
          }}
        >
          <AiOutlineClose />
        </PhotoViewDismiss>
        {presentationSections
          ?.filter((section) => {
            let displaySection = true;
            if (!site?.visible) {
              displaySection =
                displaySection && section.value !== PORTAL_BUDGET_ID;
            } else {
              displaySection =
                displaySection && section.value !== PORTAL_BUDGET_OVERVIEW_ID;
            }
            if (!metadata?.qualificationsAndAssumptions) {
              displaySection =
                displaySection &&
                section.value !== PORTAL_B2B_QUALIFICATIONS_AND_ASSUMPTIONS;
            }
            return displaySection;
          })
          ?.map((sectionInfo, index) => {
            return (
              <FullScreenPresentationView
                key={sectionInfo.value}
                headerTitle={sectionInfo?.label}
                width={fullWidth}
                sectionId={sectionInfo.value}
                background={sectionInfo.background}
                scrollToSection={scrollToSection}
                hideWhenGeneratingPDF={sectionInfo?.hideWhenGeneratingPDF}
                index={index}
                displayForPrint={readyToPrint}
                formatWithPageSystem={
                  sectionInfo.formatWithPageSystem && !props.hideClose
                }
              >
                {sectionInfo.component}
              </FullScreenPresentationView>
            );
          })}
        <HCPPresentationFooter
          readyToPrint={readyToPrint}
          setReadyToPrint={setReadyToPrint}
          scrollToSection={scrollToSection}
        />
      </PROPOSAL_GENERATE_PDF.Provider>
    </Container>
  );
};
