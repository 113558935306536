import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
  width: 100%;
`;
export const SectionTitle = styled.h3`
  font-size: 20px;
  font-family: ${FONT_FAMILY_BOLD};
  margin: 0;
  padding-bottom: 16px;
`;

export const SubsectionTitle = styled.h3`
  font-size: 16px;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin: 0;
  padding-bottom: 16px;
`;

export const SectionWrapper = styled.div`
  padding: 64px 0;
`;
