import {Dispatch, SetStateAction} from "react";
import {Row} from "react-table";
import {ARCHIVED_KEY, ID_KEY} from "../shared/constants";
import {
  copyItemSetRows,
  copyOptionTableRow,
  InfoSetItemTableRow,
  newKeyTableRow,
  newOptionTableRow,
} from "./table";

export interface RowContextMenuItem {
  label: string;
  action: () => void;
  icon?: any;
  group?: number;
}

export const addOptionRow = (
  setData: Dispatch<SetStateAction<InfoSetItemTableRow[]>>,
  ref: Row<InfoSetItemTableRow>
) => {
  setData((old) => [...old, newOptionTableRow(ref.original)]);
};

export const addItemSet = (
  setData: Dispatch<SetStateAction<InfoSetItemTableRow[]>>
) => {
  setData((old: InfoSetItemTableRow[]) => [...old, newKeyTableRow()]);
};

export const copyOptionRow = (
  setData: Dispatch<SetStateAction<InfoSetItemTableRow[]>>,
  ref: Row<InfoSetItemTableRow>
) => {
  setData((old) => [...old, copyOptionTableRow(ref.original)]);
};

export const copyItemSet = (
  setData: Dispatch<SetStateAction<InfoSetItemTableRow[]>>,
  ref: Row<InfoSetItemTableRow>
) => {
  setData((old) => {
    const rows = old.filter(
      (r: InfoSetItemTableRow) => r[ID_KEY] === ref.original[ID_KEY]
    );
    const newRows = copyItemSetRows(rows);
    return [...old, ...newRows];
  });
};

export const changeArchiveStatusOfItemSet = (
  setData: Dispatch<SetStateAction<InfoSetItemTableRow[]>>,
  ref: Row<InfoSetItemTableRow>,
  archiveStatus: boolean
) => {
  setData((old) => {
    const preserve = old.filter(
      (r: InfoSetItemTableRow) => r[ID_KEY] !== ref.original[ID_KEY]
    );
    const rows = old
      .filter((r: InfoSetItemTableRow) => r[ID_KEY] === ref.original[ID_KEY])
      .map((item: InfoSetItemTableRow) =>
        Object.assign({}, item, {[ARCHIVED_KEY]: archiveStatus})
      );
    return [...preserve, ...rows];
  });
};

export const changeArchiveStatusOfOption = (
  setData: Dispatch<SetStateAction<InfoSetItemTableRow[]>>,
  ref: Row<InfoSetItemTableRow>,
  archiveStatus: boolean
) => {
  // We also turn on the flag to not reset the page
  setData((old) => {
    let current = [...old];
    current[ref.index] = Object.assign({}, current[ref.index], {
      [ARCHIVED_KEY]: archiveStatus,
    });
    return current;
  });
};
