import React from "react";
import {
  DateUtils,
  ICalendlyDetails,
  IEventInfo,
  IProjectInfo,
  Person,
  Project,
  TeamMemberRole,
} from "@natomas-org/core";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {MODEL_HOMES} from "./ModelHomeInfo";
import NatLabel, {
  NatLabelType,
} from "../../../../../_shared/generics/label/NatLabel";
import {PageElementWrapper} from "../../../../../_shared/generics/page/components/PageElementWrapper";
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {ModelHomeCard} from "./ModelHomeCard";
import {AiOutlineCalendar, AiOutlineClockCircle} from "react-icons/ai";
import {NatLink} from "../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";
import {GoLocation} from "react-icons/go";
import {ADU_HERO_IMAGE_URL} from "../../../../../intake-form/constants";

export const ModelHomeTourSummary = (props: IModelHomeTourSummaryProps) => {
  const {projectSummary, modelHomeTourEvent} = props;
  const size = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
    forSixteen: 8,
  });

  const teamMember = Project.getTeamMemberForRole(
    projectSummary,
    TeamMemberRole.HC
  );

  const eventDetails = modelHomeTourEvent.details as ICalendlyDetails;
  const foundModelHome = MODEL_HOMES.find((info) =>
    eventDetails?.original_calendly_link?.includes(info.schedule_link)
  );

  const title = foundModelHome?.location_title
    ? `You’re all set for the ${foundModelHome.location_title} model home tour!`
    : eventDetails.meeting_name ?? "Your tour is scheduled!";

  const startDate = new Date(modelHomeTourEvent.start_timestamp);
  const endDate = new Date(modelHomeTourEvent.end_timestamp);
  const dayOfTheWeek = DateUtils.getDayOfTheWeek(startDate);
  const monthAndDay = DateUtils.getCalendarMonthAndDayAndSuffix(startDate, {});

  const startTime = DateUtils.getClockTime(startDate, true, {});
  const endTime = DateUtils.getClockTime(endDate, true, {});

  const location = eventDetails.location;

  return (
    <div style={{minHeight: "20rem"}}>
      <NatLabel
        label={
          "Come visit one of our model homes below to see the quality of construction, design flow, and features and finishes. One of our experts will be on hand to answer any questions."
        }
        type={NatLabelType.P1}
      />
      <PageElementWrapper>
        <PageElement size={size} height={"20rem"}>
          <ModelHomeCard
            title={foundModelHome?.location_title ?? eventDetails.meeting_name}
            subtitle={foundModelHome?.product_title ?? ""}
            imageURL={foundModelHome?.image_url ?? ADU_HERO_IMAGE_URL}
          />
        </PageElement>
        <PageElement size={size} height={"20rem"}>
          <NatLabel label={title} type={NatLabelType.H3} />
          <NatLabel
            label={
              <>
                <AiOutlineCalendar />
                {` ${dayOfTheWeek}, ${monthAndDay}`}
              </>
            }
            type={NatLabelType.P3}
          />
          <NatLabel
            hidden={!foundModelHome}
            label={
              <>
                <AiOutlineClockCircle />
                {` ${startTime} - ${endTime}`}
              </>
            }
            type={NatLabelType.P3}
          />
          <NatLabel
            label={
              <>
                <GoLocation />
                {` ${location ?? "Check your email for details"}`}
              </>
            }
            type={NatLabelType.P3}
          />
          <div style={{margin: "0.5rem 0"}}>
            <NatLink
              size={NatSize.SMALL}
              style={{width: "fit-content", padding: 0}}
              option={StyleOption.PRIMARY_LINK_ALT}
              label={"Have questions? Contact your home consultant"}
              hidden={teamMember == null}
              link={
                teamMember
                  ? `mailto:${Person.getContactEmail(teamMember.personInfo)}`
                  : ""
              }
            />
          </div>
          <div style={{margin: "0.5rem 0"}}>
            <NatLink
              size={NatSize.SMALL}
              style={{width: "fit-content", padding: 0}}
              option={StyleOption.PRIMARY_LINK_ALT}
              label={"Need to reschedule?"}
              clickEvent={props.rescheduleAction}
            />
          </div>
        </PageElement>
      </PageElementWrapper>
    </div>
  );
};

interface IModelHomeTourSummaryProps {
  projectSummary: IProjectInfo;
  modelHomeTourEvent: IEventInfo;
  rescheduleAction: () => void;
}
