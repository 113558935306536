import {GenericNavBar} from "../../../../_shared/generics/GenericNavBar";
import React, {FormEvent} from "react";
import {Header} from "../../../../_shared/generics/header";
import {NavigationButtons} from "../../../../_shared/generics/header/styles";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {AdminBaseActions} from "./AdminBaseActions";

export interface AdminTabHeaderProps {
  tabs?: any;
  title?: any;
  setTab?: any;
  deleteAction?: any;
  undoDeleteAction?: any;
  discardAction?: () => void;
  saveAction?: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  isUpToDate?: boolean;
  additionalActions?: JSX.Element;
  width?: string;
}

export const AdminTabHeader = (props: AdminTabHeaderProps) => {
  const {
    tabs,
    setTab,
    deleteAction,
    undoDeleteAction,
    discardAction,
    saveAction,
    isUpToDate,
    additionalActions,
    title,
    width,
  } = props;

  const {editingEnabled} = useAdminProductCatalog();
  const autoWidth = useDynamicValue(fullColumnWidths);
  const w = width ?? autoWidth;

  return (
    <>
      <Header
        hideProgressBar={true}
        title={title}
        hasBorder={true}
        width={w}
        height={"63px"}
        noPadding={true}
      >
        {tabs && setTab && <GenericNavBar tabs={tabs} onChange={setTab} />}
        <NavigationButtons>
          {editingEnabled && deleteAction && (
            <NatButton
              type={"button"}
              size={NatSize.SMALL}
              option={StyleOption.DESTRUCTIVE}
              clickEvent={deleteAction}
              label={"Archive"}
              trackingId={"admin-archive"}
            />
          )}
          {editingEnabled && undoDeleteAction && (
            <NatButton
              type={"button"}
              size={NatSize.SMALL}
              option={StyleOption.DESTRUCTIVE}
              clickEvent={undoDeleteAction}
              label={"Revert Archive"}
              trackingId={"admin-revert-archive"}
            />
          )}
          {additionalActions}
          <AdminBaseActions
            saveAction={saveAction}
            discardAction={discardAction}
            isUpToDate={isUpToDate}
          />
        </NavigationButtons>
      </Header>
    </>
  );
};
