import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const InventoryUnitDetailsContainer = styled.div`
  //max-width: 600px;
`;

export const InventoryBlurbDropdown = styled.div<{expanded: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  svg {
    transform: ${(props) => (props.expanded ? "rotate(90deg)" : "")};
    transition: transform 75ms ease-in-out;
  }
`;

export const InventoryBlurbDropdownLabel = styled.div`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-right: 1rem;
`;

export const InventoryBlurbDropdownContent = styled.div`
  margin-bottom: 1rem;
`;
