import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {GRANITE} from "../../../../../../../_shared/colors";

export const SSCComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SSCComponentLabel = styled.div`
  font-size: 24px;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const SSCComponentSubLabel = styled.div`
  font-size: 11px;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${GRANITE};
`;

export const SSCComponentText = styled.div`
  font-size: 14px;
  font-family: ${FONT_FAMILY_REGULAR};
`;
