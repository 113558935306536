import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";

export const StepCardContainer = styled.div`
  padding: 1rem 1rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  gap: 1rem;
  border-radius: 1rem;
`;

export const StepCardNumberContainer = styled.div<{size: string}>`
  position: absolute;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: center;
  display: flex;
  ${(props) => `font-size: calc(${props.size} - 0.5rem);`}
  font-family: ${FONT_FAMILY_BOLD};
  padding: 0 0;
  margin-top: 1px;
  z-index: 10 !important;
`;
