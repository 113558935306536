import {IFactory, IFactoryVersion} from "../../../slices/FactorySlice";

export const getFactoryVersion = (factory?: IFactory, versionId?: string): IFactoryVersion | undefined => {
  if (factory) {
    if (factory?.info) {
      let vid = versionId ?? factory?.info?.publishedVersionId;
      if (vid) {
        return factory?.versions?.[vid];
      }
    }
  }
  return undefined;
}
