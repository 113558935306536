import styled from "styled-components";
import {
  ADMIN_SELECTED,
  BLACK,
  SLIDER_RAIL,
  SLIDER_THUMB,
  SLIDER_TRACK,
  VILLA_BLUE,
  WHITE,
} from "../../../../colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {Slider} from "@mui/material";
import {IMAGE_OVERLAY_Z_INDEX, MODAL_Z_INDEX} from "../../../../styles";

export const ImageDropZoneContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid ${VILLA_BLUE};
  border-radius: 1rem;
  background-color: ${ADMIN_SELECTED};
`;

export const ImageRemoveWarningContainer = styled.div`
  //min-width: 530px;
  height: inherit;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(80, 80, 80, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  z-index: ${MODAL_Z_INDEX};
`;

export const ImageRemoveWarningLabel = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 14px;
  color: ${WHITE};
  text-align: center;
`;
export const ImageRemoveWarningDescription = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 12px;
  text-align: center;
  color: ${WHITE};
`;

export const ImageDropZoneDetails = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
`;

export const ImageDropZoneEditButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: ${IMAGE_OVERLAY_Z_INDEX} !important;
  display: flex;
  flex-direction: row;
  column-gap: 4px;
`;

export const ImageDropZoneButtonContainer = styled.div`
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 0.5rem;
`;

export const ImageDisplayContainer = styled.div`
  width: 100%;
  height: 100%;
  //background-color: white;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const ImageEmptyAssignmentContainer = styled.div`
  width: 100%;
  font-family: ${FONT_FAMILY_MEDIUM};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageAssigmentOptionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const ImageUploadOptionContainer = styled.div`
  width: fit-content;
  height: 100%;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
`;

export const ImageSearchOptionContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 1rem;
`;

export const ImageDragDropUploadContainer = styled.div`
  height: 10rem;
  width: 10rem;
  border: 3px dashed ${BLACK};
  color: ${BLACK};
  font-size: 1.125rem;
  font-family: ${FONT_FAMILY_BOLD};
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${BLACK};
    color: ${WHITE};
    border-color: ${WHITE};
    border-style: none;
  }
`;

export const DropZoneOrLabel = styled.div`
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${BLACK};
`;

export const NatNumberSlider = styled(Slider)({
  "color": "#52af77",
  "height": 8,
  "& .MuiSlider-track": {
    backgroundColor: SLIDER_TRACK,
    border: "none",
  },
  "& .MuiSlider-rail": {
    backgroundColor: SLIDER_RAIL,
    opacity: 1,
    border: "none",
  },
  "& .MuiSlider-thumb": {
    "height": 20,
    "width": 20,
    "backgroundColor": SLIDER_THUMB,
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    "lineHeight": 1.2,
    "fontSize": 14,
    "fontFamily": FONT_FAMILY_BOLD,
    "background": "unset",
    "padding": 0,
    "width": 52,
    "height": 20,
    "borderRadius": 8,
    "backgroundColor": "white",
    "color": "black",
    "transformOrigin": "bottom left",
    "&:before": {display: "none"},
  },
});
