import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {NatUndoIcon} from "../../../../../../../_shared/icon/icons";
import {NatButton} from "../../../../../../../_shared/generics/button";
import React from "react";

export const CancelButton = (props: {cancel: () => void}) => {
  return (
    <NatButton
      label={"Cancel"}
      clickEvent={props.cancel}
      option={StyleOption.PRIMARY_ALT}
      size={NatSize.SMALL}
      icon={{icon: <NatUndoIcon />, iconPosition: IconPosition.ONLY}}
    />
  );
};
