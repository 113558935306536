import {IPublicProjectInfo, Product} from "@natomas-org/core";
import {PublicProjectInfo} from "@natomas-org/service";
import {getStore} from "../../../../../store";

export const getEnrichedPopupContent = (
  enrichedProjectDetails: IPublicProjectInfo
): string => {
  // Deprecating
  // const siteWorkPrice = getTotalSiteWorkPrice(enrichedProjectDetails);
  // const productPrice = getProductPrice(enrichedProjectDetails);
  const projectNumber = PublicProjectInfo.getProjectNumber(
    enrichedProjectDetails
  );
  const projectTitle = PublicProjectInfo.getProjectTitle(
    enrichedProjectDetails
  );
  const product = PublicProjectInfo.getProduct(enrichedProjectDetails);
  const store = getStore();
  const imageAlt = "Project ADU";
  const imageId = PublicProjectInfo.getHeroImageId(enrichedProjectDetails);
  const bedBathSqFtString = product
    ? Product.getBedBathSqFtString(product) ?? ""
    : "";
  const label = projectNumber ? `Villa #${projectNumber}` : "";

  if (imageId) {
    const imageUrl = store.getState().global.images[imageId];
    if (
      !!imageUrl &&
      !!imageUrl[PublicProjectInfo.HOVER_IMAGE_RESOLUTION]?.url
    ) {
      return `<div class="project-point-container">
        <img alt="${imageAlt}" src="${
        imageUrl[PublicProjectInfo.HOVER_IMAGE_RESOLUTION]?.url
      }" class="adu-image"/>
        <div class="enriched-text-container">
          <div class="flex-row">
            <div class="enriched-title">
              <h3 class="h3">${label}</h3>
            </div>
            <h6 class="h6">${projectTitle}</h6>
          </div>
            <div class="enriched-subtitle">${bedBathSqFtString}</div>
        </div>
      </div>`;
    }
  }

  return `<div>
      <h3 class="h3">${label}</h3>
      <h6 class="h6">${projectTitle}</h6>
    </div>`;
};
