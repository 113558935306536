import React, {useState} from "react";
import TeamMemberPhoto from "../../../../0-app/1-container/team-member-photo";

const TeamMemberPhotoDemo = () => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  return (
    <div>
      <label htmlFor={"email"}>Email</label>
      <input
        type="email"
        id={"email"}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
      />
      <br />
      <button type={"submit"} onClick={() => setEmail(inputValue)}>
        Fetch
      </button>
      <br />
      {email ?? "None"}
      <br />
      <div style={{height: 128, width: 128}}>
        <TeamMemberPhoto email={email} />
      </div>
    </div>
  );
};

export default TeamMemberPhotoDemo;
