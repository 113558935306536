import {
  addressDetailsNatToFS,
  addressFSToNatomas,
  Contact,
  Customer,
  IContactInfo,
  ICustomerInfo,
  IPersonInfo,
  NatomasUserInfo,
  Person,
  PhoneNumber,
  PhoneNumberTypes,
  V0UserInfo,
} from "@natomas-org/core";

const natomasCustomerToCustomerInfo = (
  natUser: NatomasUserInfo
): ICustomerInfo => {
  let phone = PhoneNumber.dataToPhoneNumber(
    PhoneNumberTypes.PRIMARY,
    natUser.phone
  );

  let address = addressFSToNatomas(natUser.default_address);

  let contact: IContactInfo = Contact.dataToContactInfo(
    address,
    phone,
    natUser.email
  );

  let person: IPersonInfo = Person.dataToPerson(
    natUser.first_name,
    "",
    natUser.last_name,
    contact,
    ""
  );

  return Customer.dataToCustomer(
    natUser.user_id ?? natUser.id,
    person,
    natUser.lead_id,
    natUser.project_ids,
    natUser.archived_project_ids,
    natUser.initial_fee_paid,
    natUser.events,
    natUser.status,
    natUser.status_details,
    natUser.default_project_id,
    natUser.cart_library,
    natUser.used_b2b_intake
  );
};

export const v0UserToNatomasCustomerInfo = (
  userInfo: V0UserInfo,
  userId: string
): NatomasUserInfo => {
  return {
    default_address: {
      street_address: userInfo.address,
      city: userInfo.city,
      state: userInfo.state,
      zip: userInfo.zip,
      county: "",
      country: "US",
    },
    email: userInfo.email,
    events: [],
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    lead_id: "",
    phone: userInfo.phoneNumber,
    status: "",
    user_id: userId,
  };
};

const customerInfoToNatomasCustomer = (customer: ICustomerInfo) => {
  let natomasAddress = addressDetailsNatToFS(
    Customer.getContactAddress(customer)
  );

  let natUser: NatomasUserInfo = {
    user_id: customer.user_id,
    first_name: Customer.getFirstName(customer),
    last_name: Customer.getLastName(customer),
    lead_id: customer.lead_id,
    // project_ids: customer.project_ids, We do not save project ids like this
    // archived_project_ids: customer.archived_project_ids, We do not save project ids like this
    phone: Customer.getPrimaryContactPhoneNumber(customer),
    email: Customer.getContactEmail(customer),
    initial_fee_paid: customer.initial_fee_paid,
    events: customer.events,
    default_address: natomasAddress,
    status: customer.status,
    cart_library: customer.cart_library ?? [],
    used_b2b_intake: customer.used_b2b_intake,
  };
  return natUser;
};

export {customerInfoToNatomasCustomer, natomasCustomerToCustomerInfo};
