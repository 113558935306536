import styled from "styled-components";
import {
  DIVIDER_COLOR,
  STEPH_SHADOW,
  WHITE,
} from "../../../../../../../../../../../../_shared/colors";
import {MODAL_Z_INDEX} from "../../../../../../../../../../../../_shared/styles";

const totalHeight = "798px";
const totalWidth = "1397px";
const stickyBaseHeight = "227px";
const layersWidth = "500px";
const spacing = "12px";

export const RenderingFieldEditorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: ${totalHeight};
  max-width: ${totalWidth};
`;

export const RenderingFieldEditorLayersWrapper = styled.div`
  border-right: 1px solid ${DIVIDER_COLOR};
`;
export const RenderingFieldEditorLayersContainer = styled.div`
  height: calc(${totalHeight} - ${stickyBaseHeight});
  width: calc(${layersWidth} - 2 * ${spacing});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
`;
export const RenderingFieldEditorBaseImageContainer = styled.div`
  height: calc(${stickyBaseHeight});
  width: calc(${layersWidth} - 2 * ${spacing});
  padding: ${spacing};
  row-gap: ${spacing};
  border-top: 1px solid ${DIVIDER_COLOR};
`;
export const RenderingFieldEditorPreview = styled.div`
  height: calc(${totalHeight});
  width: calc(${totalWidth} - (${layersWidth} - 2 * ${spacing}));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const RenderingFieldEditorController = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  z-index: ${MODAL_Z_INDEX};
  background-color: ${WHITE};
  box-shadow: ${STEPH_SHADOW};
  border-radius: 24px;
  padding: 8px;
`;

export const RenderingFieldEditorPreviewWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RenderingFieldEditorPreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: all 150ms ease-in-out;
`;

export const RenderingFieldEditorLayerImageContainer = styled.div`
  min-height: 202px;
  height: 202px;
  width: 300px;
`;

export const RenderingFieldEditorLayerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-bottom: 1px solid ${DIVIDER_COLOR};
`;

export const RenderingFieldEditorLayerController = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 0 12px;
`;

export const RenderingFieldEditorLayerButtonController = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
  width: 100%;
`;

export const RenderingFieldEditorLayerContainer = styled.div`
  width: fit-content;
`;
