import {NatSwiper} from "../../swiper";
import {NatPresentation} from "../../presentation";
import React from "react";
import {ILightboxPhoto} from "../types";

export const ImageCarousel = (props: {
  show: boolean;
  close: () => void;
  images: ILightboxPhoto[];
}) => {
  const {show, close, images} = props;

  return (
    <NatPresentation show={show} close={close}>
      <NatSwiper
        autoFormatSlides={false}
        slides={images.map((image) => {
          return {
            content: (
              <img
                src={image.url}
                alt={image.title}
                style={{maxHeight: "90vh"}}
              />
            ),
            width: "100%",
          };
        })}
        navigation={true}
        alternativeNavigationButtons={true}
        zoom={true}
        loopSlides={true}
        onSlideClick={(clickTarget, index) => {
          if (clickTarget === "background") {
            // TODO not great i know, but this is the only way to prevent the click from bubbling down to other elements after closing
            setTimeout(close, 200);
            // close();
          }
        }}
        isKeyboardEnabled={true}
      />
    </NatPresentation>
  );
};
