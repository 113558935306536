import React, {useState} from "react";
import {VersionManagerContainer} from "./styles";
import {NatButton} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {PublishWarningModal} from "./PublishWarningModal";
import {AdminActionController} from "../../../../_shared/generics/admin-controller";

export const VersionSideBarManagerContainer = () => {
  const {isDraft} = useAdminProductCatalog();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  return (
    <VersionManagerContainer>
      <AdminActionController hideLabels={true} />
      {isDraft && (
        <div>
          <NatButton
            label={"Publish"}
            type={"button"}
            size={NatSize.NORMAL}
            option={StyleOption.ADMIN_SOLID}
            clickEvent={() => {
              setShowConfirmation(true);
            }}
          />
        </div>
      )}
      <PublishWarningModal
        show={showConfirmation}
        setShow={setShowConfirmation}
      />
    </VersionManagerContainer>
  );
};
