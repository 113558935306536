import React, {useCallback, useEffect, useMemo, useState} from "react";
import CatalogEditorHeader from "../FactoryInventoryCatalogEditor/components/Header";
import {FactoryInventoryEditorProps} from "../../index";
import {useFormik} from "formik";
import _ from "lodash";
import {getConfigurationData, getPricingSummary} from "./helper";
import {IConfigurationData, IFactoryInventoryModifierInfo} from "./interfaces";
import {getTableColumns} from "./components/structure";
import FactoryInventoryPricingCell from "./components/FactoryInventoryPricingCell";
import {
  InfoSetTableRow,
  ReactTableHeaderRowCell,
  ReactTableHeaderRowCellContent,
} from "../../../../../../../../factory-info-set/InfoSetTable/styles";
import {ReactTableColumnHeader} from "../../../../../../../../factory-info-set/InfoSetTable/components/ColumnHeader";
import {useTable} from "react-table";
import {PageContainer} from "../../../../../../../../_shared/generics/page/components/PageContainer";
import {priceTextFromMicros} from "../../../../../../../../studio/_shared/helper";
import StudioEditorHeader from "../FactoryInventoryStudioEditor/components/Editor/StudioEditorHeader";
import {
  savingModifierPricingModifications,
  savingProductPricingModifications,
} from "./saving";
import {
  InventoryPricingEditorColumnSection,
  InventoryPricingEditorContainer,
  InventoryPricingInfoContainer,
  InventoryPricingInfoRowContainer,
  InventoryPricingTableElement,
} from "./styles";
import {initializeInventoryPricingEditor} from "./formik/initialize";
import {
  FactoryInventoryPricingEditorKey,
  IFactoryInventoryPricingEditorValues,
} from "./formik/interfaces";
import {IFactoryInventoryEditorStructureValue} from "../FactoryInventoryCatalogEditor/formik/structure";
import {FactoryInventoryInput} from "../_shared/FactoryInventoryInput";
import {FactoryInventoryPricingEditorStructure} from "./formik/structure";
import TotalPriceInput from "./components/TotalPriceInput";

const FactoryInventoryPricingEditor = (props: FactoryInventoryEditorProps) => {
  const {product, configuration} = props;

  const {
    categories,
    modifierGroups,
    modifiers: initialModifiers,
  }: IConfigurationData = useMemo(
    () => getConfigurationData(configuration),
    [configuration]
  );

  const columns = useMemo(() => {
    return getTableColumns();
  }, []);

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = useMemo(
    () => ({
      Cell: FactoryInventoryPricingCell,
    }),
    []
  );

  const initialValues: IFactoryInventoryPricingEditorValues = useMemo(() => {
    return initializeInventoryPricingEditor(product, initialModifiers);
  }, [product, initialModifiers]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: IFactoryInventoryPricingEditorValues) => {
      let promises = [];
      if (hasModifierPricingEdits) {
        const modifierPromise = savingModifierPricingModifications(
          product,
          configuration,
          values?.modifiers
        );
        promises.push(modifierPromise);
      }
      if (hasProductPricingEdits) {
        const modifierPromise = savingProductPricingModifications(
          product,
          values?.product
        );
        promises.push(modifierPromise);
      }
      return Promise.all(promises);
    },
  });

  useEffect(() => {
    formik?.setValues(initialValues);
  }, [initialValues]);

  const modifierArray: IFactoryInventoryModifierInfo[] = useMemo(
    () => Object.values(formik?.values?.modifiers),
    [formik?.values]
  );

  const setModifierPrice: (modifierId: string, value: number) => void =
    useCallback(
      (modifierId: string, value: number) => {
        formik?.setFieldValue(`modifiers.${modifierId}.priceInMicros`, value);
      },
      [formik]
    );

  const setProductField: (fieldId: string, value: number) => void = useCallback(
    (fieldId: string, value: number) => {
      formik?.setFieldValue(`product.${fieldId}`, value);
    },
    [formik]
  );

  const [targetModifierId, setTargetModifierId] = useState<string | undefined>(
    undefined
  );

  const hasEdits = useMemo(() => {
    return !_.isEqual(initialValues, formik.values);
  }, [formik.values, initialValues]);

  const hasProductPricingEdits = useMemo(() => {
    return !_.isEqual(initialValues?.product, formik.values?.product);
  }, [formik.values, initialValues]);

  const hasModifierPricingEdits = useMemo(() => {
    return !_.isEqual(initialValues?.modifiers, formik.values?.modifiers);
  }, [formik.values, initialValues]);

  const resetChanges = useCallback(() => {
    formik.resetForm();
    formik.setValues(initialValues);
    setTargetModifierId(undefined);
  }, [formik, initialValues]);

  const pricingSummary = useMemo(() => {
    const upgradeTotal = modifierArray.reduce(
      (n, {priceInMicros}) => n + priceInMicros,
      0
    );
    return getPricingSummary(formik?.values?.product, upgradeTotal);
  }, [modifierArray, formik?.values?.product]);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      // @ts-ignore
      setModifierPrice,
      defaultColumn,
      // @ts-ignore
      columns,
      selectedId: targetModifierId,
      categories: categories,
      modifierGroups: modifierGroups,
      initialData: initialModifiers,
      data: modifierArray,
    });

  if (!initialModifiers || !product) {
    return null;
  }

  return (
    <>
      <CatalogEditorHeader
        resetAllChanges={resetChanges}
        hasEdits={hasEdits}
        saveChanges={formik?.submitForm}
        hideEditingToggle={true}
      />
      <StudioEditorHeader
        isOlderVersion={!!configuration && configuration?.builderVersion !== 2}
      />
      <br />
      <PageContainer>
        <InventoryPricingEditorContainer>
          <InventoryPricingEditorColumnSection>
            <InventoryPricingInfoContainer>
              <InventoryPricingInfoRowContainer>
                <div>Unit Price</div>
                {priceTextFromMicros(pricingSummary?.basePrice, "accurate")}
              </InventoryPricingInfoRowContainer>
              <InventoryPricingInfoRowContainer>
                <div>Upgrades Total</div>
                {priceTextFromMicros(pricingSummary?.upgradeTotal, "accurate")}
              </InventoryPricingInfoRowContainer>
              <InventoryPricingInfoRowContainer bold={true}>
                <div>Total Price</div>
                {priceTextFromMicros(pricingSummary?.totalPrice, "accurate")}
              </InventoryPricingInfoRowContainer>
            </InventoryPricingInfoContainer>
            <InventoryPricingInfoContainer>
              <InventoryPricingInfoRowContainer>
                <div>Factory Cost </div>
                {priceTextFromMicros(pricingSummary?.baseCost, "accurate")}
              </InventoryPricingInfoRowContainer>
              <InventoryPricingInfoRowContainer>
                <div>Sales Tax </div>
                {priceTextFromMicros(pricingSummary?.salesTaxCost, "accurate")}
              </InventoryPricingInfoRowContainer>
              <InventoryPricingInfoRowContainer bold={true}>
                <div>Total Cost</div>
                {priceTextFromMicros(pricingSummary?.totalCost, "accurate")}
              </InventoryPricingInfoRowContainer>
            </InventoryPricingInfoContainer>
            <InventoryPricingInfoContainer>
              <InventoryPricingInfoRowContainer>
                <div>Margin (%)</div>
                {`${pricingSummary?.marginPercentage}%`}
              </InventoryPricingInfoRowContainer>
              <InventoryPricingInfoRowContainer bold={true}>
                <div>Margin ($)</div>
                {priceTextFromMicros(pricingSummary?.marginAmount, "accurate")}
              </InventoryPricingInfoRowContainer>
            </InventoryPricingInfoContainer>
          </InventoryPricingEditorColumnSection>
          <InventoryPricingEditorColumnSection>
            {Object.keys(FactoryInventoryPricingEditorStructure).map((id) => {
              const key: IFactoryInventoryEditorStructureValue =
                FactoryInventoryPricingEditorStructure[id];
              // @ts-ignore
              const initial: number = initialValues?.product?.[id];
              // @ts-ignore
              const current: number = formik.values?.product?.[id];
              return (
                <FactoryInventoryInput
                  id={id}
                  key={id}
                  label={key.label}
                  type={key.type}
                  initialValue={initial}
                  value={current}
                  onChange={(value: any) => {
                    setProductField(id, value);
                  }}
                  resetChange={() => setProductField(id, initial)}
                />
              );
            })}
            <TotalPriceInput
              initialValue={
                initialValues?.product?.[
                  FactoryInventoryPricingEditorKey.UNIT_PRICE
                ]
              }
              currentValue={
                formik.values?.product?.[
                  FactoryInventoryPricingEditorKey.UNIT_PRICE
                ]
              }
              upgradePricingInMicros={pricingSummary?.upgradeTotal}
              setValue={(v: number) =>
                setProductField(FactoryInventoryPricingEditorKey.UNIT_PRICE, v)
              }
            />
          </InventoryPricingEditorColumnSection>
          <InventoryPricingTableElement {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => {
                    const headerProps = column
                      .getHeaderProps
                      // column.getSortByToggleProps()
                      ();
                    return (
                      <ReactTableHeaderRowCell
                        {...headerProps}
                        onClick={undefined}
                      >
                        <ReactTableHeaderRowCellContent>
                          <ReactTableColumnHeader
                            column={column}
                            onClick={headerProps?.onClick}
                          />
                        </ReactTableHeaderRowCellContent>
                      </ReactTableHeaderRowCell>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <InfoSetTableRow
                    selected={row?.original?.id === targetModifierId}
                    {...row.getRowProps()}
                    onClick={() => setTargetModifierId(row?.original?.id)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </InfoSetTableRow>
                );
              })}
            </tbody>
          </InventoryPricingTableElement>
        </InventoryPricingEditorContainer>
      </PageContainer>
    </>
  );
};

export default FactoryInventoryPricingEditor;
