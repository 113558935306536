import React, {useState} from "react";
import {WaitingForDesign} from "../../_shared/components/WaitingForDesign";
import {DesignSummaryContent} from "./components/DesignSummaryContent";
import {DesignContainer} from "./styles";
import {DesignSummaryList} from "./components/DesignSummaryList";
import {isMobile} from "../../../_shared/navigation";
import {useProduct} from "../../../_shared/hooks/useProductCatalog/useProduct";
import {usePage} from "../../../_shared/hooks/usePage";
import useActiveDesign from "../../../_shared/hooks/useDesign/useActiveDesign";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";

export const DesignStudioView = (useNatPage) => {
  const {isProjectLocked} = useActiveProject();
  const {snapshot, configuration} = useActiveDesign();
  const {product} = useProduct(
    configuration?.product?.id,
    configuration?.product?.productGroupId
  );
  const [selectedConfigurationPage, setSelectedConfigurationPage] =
    useState(null);
  const {isNatMobile} = usePage();
  if (product == null || snapshot == null) {
    return (
      <DesignContainer isMobile={isNatMobile}>
        <WaitingForDesign relative={isMobile()} />
      </DesignContainer>
    );
  }
  if (!isNatMobile && useNatPage) {
    return (
      <DesignContainer id={"design-view-container"} isMobile={false}>
        <DesignSummaryList
          configuration={configuration}
          configurationSnapshot={snapshot}
          currentProduct={product}
          selectedPage={selectedConfigurationPage}
          setSelectedPage={setSelectedConfigurationPage}
          useNatPage={useNatPage}
        />
        <DesignSummaryContent
          currentProduct={product}
          configurationPage={selectedConfigurationPage}
          configurationSnapshot={snapshot}
          useNatPage={useNatPage}
          isProjectLocked={isProjectLocked}
        />
      </DesignContainer>
    );
  }

  return (
    <DesignContainer isMobile={isNatMobile}>
      <DesignSummaryList
        configuration={configuration}
        configurationSnapshot={snapshot}
        currentProduct={product}
        selectedPage={selectedConfigurationPage}
        setSelectedPage={setSelectedConfigurationPage}
        useNatPage={false}
      />
      <DesignSummaryContent
        currentProduct={product}
        configurationPage={selectedConfigurationPage}
        configurationSnapshot={snapshot}
        useNatPage={false}
        isProjectLocked={isProjectLocked}
      />
    </DesignContainer>
  );
};
