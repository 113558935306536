import React from "react";
import SSCImageRow from "./rows/SSCImage/SSCImageRow";
import SSCTitleRow from "./rows/SSCTitle/SSCTitleRow";
import SSCTimelineRow from "./rows/SSCTimeline/SSCTimelineRow";
import SSCCustomOptionsRow from "./rows/SSCCustomOptions/SSCCustomOptionsRow";
import SSCStandardFeaturesRow from "./rows/SSCStandardFeatures/SSCStandardFeaturesRow";
import SSCServiceRow from "./rows/SSCService/SSCServiceRow";
import SSCPricingRow from "./rows/SSCPricing";
import SSCProductsRow from "./rows/SSCProducts";
import {SeriesSummaryColumnProps} from "../interfaces";
import {SeriesSummaryComparisonContainer} from "./styles";
import {SeriesSummaryComparisonSecondary} from "../SeriesBreakdownAdapter";

interface SeriesSummaryComparisonViewProps {
  primary: SeriesSummaryColumnProps;
  other: SeriesSummaryColumnProps;
  secondary: SeriesSummaryComparisonSecondary;
}

const SeriesSummaryComparisonView = (
  props: SeriesSummaryComparisonViewProps
) => {
  const {primary, other, secondary} = props;

  return (
    <SeriesSummaryComparisonContainer>
      <SSCImageRow
        primaryImageId={primary.imageId}
        otherImageId={other.imageId}
      />
      <SSCTitleRow primaryTitle={primary.title} secondary={secondary} />
      <SSCPricingRow
        primaryPricing={primary.startingAtPrice}
        otherPricing={other.startingAtPrice}
      />
      <SSCTimelineRow
        primaryTimelineInMonths={primary.timelineInMonths}
        otherTimelineInMonths={other.timelineInMonths}
      />
      <SSCServiceRow
        primaryService={primary.fullTurnKeyDescription}
        otherService={other.fullTurnKeyDescription}
      />
      <SSCStandardFeaturesRow
        primaryContent={primary.standardFeatures}
        otherContent={other.standardFeatures}
      />
      <SSCCustomOptionsRow
        primaryContent={primary.customFeatures}
        otherContent={other.customFeatures}
      />
      <SSCProductsRow
        primary={{
          products: primary.products,
          factoryId: primary.id,
        }}
        other={{
          products: other.products,
          factoryId: other.id,
        }}
      />
    </SeriesSummaryComparisonContainer>
  );
};

export default SeriesSummaryComparisonView;
