import * as Yup from "yup";
import {COMPLETE_VALIDATION_STRUCTURE} from "./FormModels";

export const constructValidation = (formMap: Map<string, any>) => {
  return constructValidationWithStructure(
    formMap,
    COMPLETE_VALIDATION_STRUCTURE
  );
};

export const constructValidationWithStructure = (
  formMap: Map<string, any>,
  globalValidationStructure: any
) => {
  const validationStructure: any = {};
  formMap.forEach((value, key) => {
    const validation = globalValidationStructure[key];
    if (validation) {
      validationStructure[key] = validation;
    }
  });
  return Yup.object().shape(validationStructure);
};
