export const ModifierGroupTypeFullText = {
  label: "Title + Price",
  value: "title-and-price",
};

export const ModifierGroupTypeDefault = {
  label: "Default",
  value: "default",
};

export const ModifierGroupTypeSwatch = {
  label: "Swatch",
  value: "swatch",
};

export const ModifierGroupTypeMultiSelect = {
  label: "Multi-Select",
  value: "multi-select",
};

export const ModifierGroupTypeEmbeddedSwatch = {
  label: "Embedded Swatch",
  value: "embedded-swatch",
};

export const ModifierGroupTypeOptions = [
  ModifierGroupTypeDefault,
  ModifierGroupTypeFullText,
  ModifierGroupTypeEmbeddedSwatch,
  ModifierGroupTypeSwatch,
  ModifierGroupTypeMultiSelect,
];
