import {
  Address,
  convertStringCase,
  IHazardData,
  IJurisdictionData,
  IPropertyData,
  isBlankString,
} from "@natomas-org/core";
import React from "react";
import {FONT_FAMILY_BOLD} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {NatBulletPoint} from "../../../../../_shared/icon/icons";
import {ColumnFlex, ModalInfoTableItemValue, RowFlex} from "./styles";
import {useCurrentUser} from "../../../../../_shared/hooks/useCurrentUser";
import {ADMIN_BLUE} from "../../../../../_shared/colors";

const getValue = (value?: any | null) => {
  if (value === null || value === "") {
    return "Unknown";
  } else if (value === true) {
    return "Yes";
  } else if (value === false) {
    return "No";
  }
  return value;
};

export const PropertyInformationTable = (props: {
  propertyData: IPropertyData;
}) => {
  const {propertyData} = props;
  const {isNatMobile} = usePage();
  const {isAdmin} = useCurrentUser();
  const hazardData: IHazardData | null | undefined = propertyData?.hazardData;
  let hazards: string[] = [];
  hazardData?.floodZone && hazards.push(`Flood Zone: ${hazardData?.floodZone}`);
  if (
    !!hazardData?.fireHazardRatingSRA &&
    hazardData?.fireHazardRatingSRA !== "None"
  ) {
    hazards.push(`Fire Hazard: ${hazardData?.fireHazardRatingSRA}`);
  } else {
    hazards.push(`Fire Hazard: ${hazardData?.fireHazardRatingLRA ?? "None"}`);
  }
  hazardData?.faultZone
    ? hazards.push(`Fault Zone`)
    : hazards.push(`Not Within a Fault Zone`);
  hazardData?.landslideZone
    ? hazards.push(`Landslide Zone`)
    : hazards.push(`Not Within a Landslide Zone`);
  hazardData?.liquefactionZone
    ? hazards.push(`Liquefaction Zone`)
    : hazards.push("Not Within a Liquefaction Zone");

  interface IListStructure {
    title: string;
    value?: string[];
  }
  const ITEMS: IListStructure[] = [
    {
      title: "Street Address",
      value: propertyData?.address?.full_address
        ? [
            propertyData?.address?.full_address.substr(
              0,
              propertyData?.address?.full_address.indexOf(",")
            ),
          ]
        : undefined,
    },
    {
      title: "City",
      value: [propertyData?.address?.city] ?? undefined,
    },
    {
      title: "Zip",
      value: [propertyData?.address?.zip] ?? undefined,
    },
    {
      title: "State",
      value: [propertyData?.address?.state],
    },
    {
      title: "Parcel Number",
      value: propertyData?.propertyDetails?.assessorParcelNumber
        ? [propertyData?.propertyDetails?.assessorParcelNumber]
        : undefined,
    },
    {
      title: "Lot Sq. Ft.",
      value: propertyData?.propertyDetails?.calculatedSqFt
        ? [
            `${propertyData?.propertyDetails?.calculatedSqFt} Sq. Ft. (${(
              Number(propertyData?.propertyDetails?.assessorLotSqFt) / 43560
            )?.toFixed(2)} Acres)`,
          ]
        : propertyData?.propertyDetails?.assessorLotSqFt
        ? [
            `${propertyData?.propertyDetails?.assessorLotSqFt} Sq. Ft. (${(
              Number(propertyData?.propertyDetails?.assessorLotSqFt) / 43560
            )?.toFixed(2)} Acres)`,
          ]
        : undefined,
    },
    {
      title: "Building SqFt",
      value: propertyData?.propertyDetails?.buildingSqFt
        ? [`${propertyData?.propertyDetails?.buildingSqFt} Sq. Ft.`]
        : undefined,
    },
    {
      title: "Owner Occupied",
      value: !!propertyData?.propertyDetails?.ownerOccupied
        ? [getValue(propertyData?.propertyDetails?.ownerOccupied)]
        : undefined,
    },
    {
      title: "Date Sold",
      value: propertyData?.propertyDetails?.dateTransfer
        ? [propertyData?.propertyDetails?.dateTransfer]
        : undefined,
    },
    {
      title: "Hazard Info",
      value: hazards,
    },
    {
      title: "Serviceable Zones",
      value: propertyData?.serviceableZoneIds?.map((validZoneInfo) => {
        return <div style={{color: ADMIN_BLUE}}>{validZoneInfo}</div>;
      }),
    },
  ].filter((item) => {
    const valuesToInclude = [
      "parcel number",
      "lot sqft",
      "building sqft",
      "hazard info",
    ];
    if (isAdmin) {
      valuesToInclude.push("serviceable zones");
    }
    return valuesToInclude.includes(item.title.toLowerCase());
  });

  return (
    <div
      style={{
        padding: isNatMobile ? "0 0" : "1rem 2rem",
      }}
    >
      <div
        style={{
          fontStyle: FONT_FAMILY_BOLD,
          fontSize: "1.5rem",
        }}
      >
        {`${Address.getStreetNumberAndStreet(
          propertyData?.address
        )}, ${Address.getState(propertyData?.address)} ${Address.getZip(
          propertyData?.address
        )}`}
      </div>
      {ITEMS?.map((item, index) => {
        if (!!item.value && item.value?.length > 0) {
          return (
            <RowFlex style={{gap: "0.5rem"}} key={item.title + index}>
              <NatBulletPoint
                size={"7px"}
                color={"black"}
                style={{
                  maxWidth: "4px",
                  maxHeight: "4px",
                  minWidth: "4px",
                  minHeight: "4px",
                  marginTop: "16px",
                }}
              />
              <ColumnFlex>
                <ModalInfoTableItemValue>{item?.title}</ModalInfoTableItemValue>
                {item.value?.map((val, index2) => {
                  if (!isBlankString(val)) {
                    return <div key={val + index2}>{val}</div>;
                  }
                  return null;
                })}
              </ColumnFlex>
            </RowFlex>
          );
        }
        return null;
      })}
    </div>
  );
};

export const JurisdictionInformationTable = (props: {
  jurisdictionData: IJurisdictionData;
}) => {
  const {jurisdictionData} = props;
  const {isNatMobile} = usePage();
  const {isAdmin} = useCurrentUser();
  let requirements: (string | JSX.Element)[] = [];
  if (
    jurisdictionData?.requirements?.approvalReqs &&
    jurisdictionData?.requirements?.approvalReqs?.length > 0
  ) {
    requirements = [
      ...requirements,
      ...jurisdictionData?.requirements?.approvalReqs,
    ];
  }
  // Don't show submittal requirements
  // if (
  //   jurisdictionData?.requirements?.submittalReqs &&
  //   jurisdictionData?.requirements?.submittalReqs?.length > 0
  // ) {
  //   requirements = [
  //     ...requirements,
  //     ...jurisdictionData?.requirements?.submittalReqs,
  //   ];
  // }
  requirements = requirements
    ?.filter((unfilteredRequirement) => {
      // Removing requirements over 40 characters aims to eliminate any long urls or sanitized requirement data from Smartsheet
      return (
        unfilteredRequirement?.toString()?.length > 0 &&
        unfilteredRequirement?.toString()?.length < 40
      );
    })
    .map((requirement) => {
      return convertStringCase(requirement?.toString(), {
        upperCaseFirstLetter: true,
        applyToAllWords: true,
      });
    });

  let setbacks: (string | JSX.Element)[] = [];
  if (jurisdictionData?.placementConstraints?.primarySetBacks) {
    setbacks.push(
      `Primary: ${jurisdictionData?.placementConstraints?.primarySetBacks} ft.`
    );
  }
  if (jurisdictionData?.placementConstraints?.frontSetback) {
    setbacks.push(
      `Front: ${jurisdictionData?.placementConstraints?.frontSetback} ft.`
    );
  }
  if (jurisdictionData?.placementConstraints?.sideAndRearSetBacks) {
    setbacks.push(
      `Side and Rear: ${jurisdictionData?.placementConstraints?.sideAndRearSetBacks} ft.`
    );
  }
  if (setbacks?.length > 0) {
    setbacks.push(
      <i>
        Setback requirements are project-specific and are provided only as an
        informed estimate.
      </i>
    );
  }

  interface IListStructure {
    title: string;
    value?: (string | JSX.Element)[];
  }
  const ITEMS: IListStructure[] = [
    {
      title: "Smartsheet Object ID",
      value: jurisdictionData?.jurisdiction?.jurisdictionId
        ? [
            <div style={{color: ADMIN_BLUE}}>
              {jurisdictionData?.jurisdiction?.jurisdictionId}
            </div>,
          ]
        : undefined,
    },
    {
      title: "Setback Regulations",
      value: [
        ...setbacks?.map((setback) => {
          return setback;
        }),
      ],
    },

    {
      title: "Maximum ADU Size",
      value: jurisdictionData?.productConstraints?.maxADUSqFt
        ? [`${jurisdictionData?.productConstraints?.maxADUSqFt} Sq. Ft.`]
        : undefined,
    },
    {
      title: "Potential Requirements",
      value: requirements?.map((requirement) => {
        return requirement;
      }),
    },
  ].filter((item) => {
    const valuesToInclude = [
      "setback regulations",
      "maximum adu size",
      "potential requirements",
    ];
    if (isAdmin) {
      valuesToInclude.push("smartsheet object id");
    }
    return valuesToInclude.includes(item.title.toLowerCase());
  });

  return (
    <div
      style={{
        padding: isNatMobile ? "0 0" : "1rem 2rem",
      }}
    >
      <div
        style={{
          fontStyle: FONT_FAMILY_BOLD,
          fontSize: "1.5rem",
        }}
      >
        {jurisdictionData?.jurisdiction?.jurisdictionName
          ? `${convertStringCase(
              jurisdictionData?.jurisdiction?.jurisdictionName,
              {
                upperCaseFirstLetter: true,
                applyToAllWords: true,
              }
            )} Jurisdiction`
          : "Authority Having Jurisdiction"}
      </div>
      {ITEMS?.map((item, index) => {
        if (!!item.value && item.value?.length > 0) {
          return (
            <RowFlex style={{gap: "0.5rem"}} key={item.title + index}>
              <NatBulletPoint
                size={"7px"}
                color={"black"}
                style={{
                  maxWidth: "4px",
                  maxHeight: "4px",
                  minWidth: "4px",
                  minHeight: "4px",
                  marginTop: "16px",
                }}
              />
              <ColumnFlex>
                <ModalInfoTableItemValue>{item?.title}</ModalInfoTableItemValue>
                {item.value?.map(
                  (val: string | JSX.Element, index2: number) => {
                    if (val) {
                      return (
                        <div key={"jurisdiction-item-" + index2}>{val}</div>
                      );
                    }
                    return null;
                  }
                )}
              </ColumnFlex>
            </RowFlex>
          );
        }
        return null;
      })}
    </div>
  );
};
