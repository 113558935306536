import styled from "styled-components";
import {SIDEBAR_COLOR, WHITE} from "../../../../_shared/colors";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../../_shared/generics/path/styles";
import {NatomasButton} from "../../../../_shared/generics/button/styles";
import {NatomasDropdown} from "../../../../_shared/generics/popup/dropdown/styles";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const CustomerDatabaseBreadcrumbContainer = styled.div`
  max-height: 45px;
  height: 45px;
  padding: 0 1rem;
  background-color: ${SIDEBAR_COLOR};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Breadcrumb} {
    justify-content: flex-start;
    align-items: center;
    color: ${WHITE};
  }
  ${BreadcrumbItem} {
    &:hover {
      color: ${WHITE};
      :after {
        color: ${WHITE};
      }
      font-family: ${FONT_FAMILY_MEDIUM};
    }
  }
  ${NatomasButton} {
    padding: 0 !important;
  }
  ${NatomasDropdown} {
    margin: 0 !important;
  }
`;
