import React, {useMemo} from "react";
import {StudioEditorFieldType} from "../../_shared/StudioEditorFieldAddButton";
import ModifierSection from "../../ModifierSection";
import ModifierGroupSection from "../../ModifierGroupSection";
import CategorySection from "../../CategorySection";

interface ImportPreviewProps {
  type?: StudioEditorFieldType;
  value?: any;
}

const ImportPreview = (props: ImportPreviewProps) => {
  const {type, value} = props;

  return useMemo(() => {
    if (!value || !type) {
      return null;
    }
    if (type === StudioEditorFieldType.MODIFIER) {
      return (
        <ModifierSection
          id={value?.id}
          currentValue={value}
          initialValue={value}
        />
      );
    } else if (type === StudioEditorFieldType.MODIFIER_GROUP) {
      return (
        <ModifierGroupSection
          id={value?.id}
          initialValue={value}
          currentValue={value}
        />
      );
    } else if (type === StudioEditorFieldType.CATEGORY) {
      return (
        <CategorySection
          id={value?.id}
          initialValue={value}
          currentValue={value}
        />
      );
    }
    return null;
  }, [value, type]);
};

export default ImportPreview;
