import styled from "styled-components";
import {DIVIDER_COLOR} from "../../_shared/colors";
import {HEADER_Z_INDEX} from "../../_shared/styles";

export const CompletedProjectsTableContainer = styled.div<{
  showPricing: boolean;
}>`
  display: flex;
  transition: all 100ms ease-in-out;
  height: 100%;
  justify-content: ${(props) => (props.showPricing ? "left" : "center")};
  min-width: fit-content;
  width: 100%;
  position: relative;
  top: 3rem;
`;
export const CompletedProjectsWrapper = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
`;

export const CompletedProjectsValueCell = styled.td<{
  modified: boolean;
  centerAlign: boolean;
}>`
  ${(props) => (props.modified ? "background-color: yellow" : undefined)};
  ${(props) => (props.centerAlign ? "text-align: center" : "left")};
  min-width: max-content;
`;
export const CompletedProjectsEditorContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  flex-direction: column;
  padding: 1rem 1rem;
  padding-top: 0;
`;
export const CompletedProjectsEditorButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background-color: white;
  height: 3rem;
  align-items: center;
  padding: 0 1rem;
  z-index: ${HEADER_Z_INDEX};
  border-bottom: 1px solid ${DIVIDER_COLOR};
`;

export const CompletedProjectsEditorSwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  z-index: inherit;
`;
