import {
  InfoSetItem,
  InfoSetItemInternalCategory,
  InfoSetItemOption,
  InfoSetItemOptionAssembly,
  InfoSetItemOptionCondition,
  InfoSetItemOptionExternalCategory,
  InfoSetItemOptionOmit,
  InfoSetItemOptionPricingPackage,
  InfoSetItemOptionSource,
} from "../shared/interfaces";
import {
  ARCHIVED_KEY,
  ASSEMBLY_KEY,
  CONDITIONS_KEY,
  DETAILS_KEY,
  EXTERNAL_CATEGORY_KEY,
  EXTERNAL_DESCRIPTION_KEY,
  ID_KEY,
  INCLUDED_ORDER_FORM_KEY,
  INTERNAL_CATEGORY_KEY,
  INTERNAL_NOTES_KEY,
  LABEL_KEY,
  MULTI_OPTION_KEY,
  OMIT_KEY,
  OPTIONS_KEY,
  PRICING_PACKAGE_KEY,
  SOURCE_KEY,
  VALUE_KEY,
  VARIANTS_KEY,
} from "../shared/constants";

export interface InfoSetItemTableRow {
  key: string;
  [ID_KEY]: string;
  [LABEL_KEY]: string;
  [INCLUDED_ORDER_FORM_KEY]: boolean;
  [INTERNAL_CATEGORY_KEY]: InfoSetItemInternalCategory;
  [MULTI_OPTION_KEY]: boolean;
  [VALUE_KEY]: string;
  [DETAILS_KEY]: string;
  [VARIANTS_KEY]: string;
  [CONDITIONS_KEY]: InfoSetItemOptionCondition[];
  [OMIT_KEY]: InfoSetItemOptionOmit;
  [SOURCE_KEY]: InfoSetItemOptionSource;
  [ASSEMBLY_KEY]: InfoSetItemOptionAssembly;
  [PRICING_PACKAGE_KEY]: InfoSetItemOptionPricingPackage;
  [ARCHIVED_KEY]: boolean;
  [EXTERNAL_CATEGORY_KEY]: InfoSetItemOptionExternalCategory;
  [EXTERNAL_DESCRIPTION_KEY]: string; // Rich text editor
  [INTERNAL_NOTES_KEY]: string; // Rich text editor
}

export const tableRowToOption = (
  option: InfoSetItemTableRow
): InfoSetItemOption => {
  return {
    [ASSEMBLY_KEY]: option?.[ASSEMBLY_KEY] ?? InfoSetItemOptionAssembly.UNSET,
    [CONDITIONS_KEY]: option?.[CONDITIONS_KEY] ?? [],
    [DETAILS_KEY]: option?.[DETAILS_KEY] ?? "",
    [OMIT_KEY]: option?.[OMIT_KEY] ?? InfoSetItemOptionOmit.UNSET,
    [PRICING_PACKAGE_KEY]:
      option?.[PRICING_PACKAGE_KEY] ?? InfoSetItemOptionPricingPackage.UNSET,
    [SOURCE_KEY]: option?.[SOURCE_KEY] ?? InfoSetItemOptionSource.UNSET,
    [VALUE_KEY]: option?.[VALUE_KEY] ?? "",
    [VARIANTS_KEY]: option?.[VARIANTS_KEY] ?? "",
    [EXTERNAL_DESCRIPTION_KEY]: option?.[EXTERNAL_DESCRIPTION_KEY] ?? "",
    [EXTERNAL_CATEGORY_KEY]:
      option?.[EXTERNAL_CATEGORY_KEY] ??
      InfoSetItemOptionExternalCategory.UNSET,
    [INTERNAL_NOTES_KEY]: option?.[INTERNAL_NOTES_KEY] ?? "",
  };
};

export const tableRowsToDatabase = (items: InfoSetItemTableRow[]): any => {
  const uniqueIds = [...new Set(items.map((item) => item.id))];

  let updates: InfoSetItem[] = [];
  uniqueIds.forEach((id: string) => {
    const rows = items.filter((item: InfoSetItemTableRow) => {
      return item.id === id;
    });

    const persistentRows: InfoSetItemTableRow[] =
      rows.filter((row: InfoSetItemTableRow) => !row[ARCHIVED_KEY]) ?? [];

    let structure: InfoSetItem = {
      [ID_KEY]: rows[0][ID_KEY],
      [LABEL_KEY]: rows[0][LABEL_KEY],
      [INTERNAL_CATEGORY_KEY]: rows[0][INTERNAL_CATEGORY_KEY],
      [INCLUDED_ORDER_FORM_KEY]: rows[0][INCLUDED_ORDER_FORM_KEY],
      [MULTI_OPTION_KEY]: rows[0][MULTI_OPTION_KEY],
      [OPTIONS_KEY]: persistentRows.map(
        (row: InfoSetItemTableRow): InfoSetItemOption => {
          return tableRowToOption(row);
        }
      ),
      [ARCHIVED_KEY]: persistentRows?.length === 0,
    };
    updates.push(structure);
  });
  return updates;
};

export const databaseToTableRows = (
  items: InfoSetItem[]
): InfoSetItemTableRow[] => {
  const r = items?.map((item: InfoSetItem) => {
    if (!item.options || item.options.length === 0) {
      return newOptionTableRow(item);
    } else {
      return item?.options?.map(
        (option: InfoSetItemOption): InfoSetItemTableRow => {
          return {
            key: getLocalKey(),
            [ID_KEY]: item[ID_KEY],
            [LABEL_KEY]: item[LABEL_KEY],
            [INCLUDED_ORDER_FORM_KEY]: item[INCLUDED_ORDER_FORM_KEY],
            [INTERNAL_CATEGORY_KEY]: item[INTERNAL_CATEGORY_KEY],
            [MULTI_OPTION_KEY]: item[MULTI_OPTION_KEY] ?? false,
            [VALUE_KEY]: option[VALUE_KEY] ?? "",
            [DETAILS_KEY]: option[DETAILS_KEY] ?? "",
            [VARIANTS_KEY]: option[VARIANTS_KEY] ?? "",
            [CONDITIONS_KEY]: option[CONDITIONS_KEY] ?? [],
            [OMIT_KEY]: option[OMIT_KEY] ?? InfoSetItemOptionOmit.UNSET,
            [SOURCE_KEY]: option[SOURCE_KEY] ?? InfoSetItemOptionSource.UNSET,
            [ASSEMBLY_KEY]:
              option[ASSEMBLY_KEY] ?? InfoSetItemOptionAssembly.UNSET,
            [PRICING_PACKAGE_KEY]:
              option[PRICING_PACKAGE_KEY] ??
              InfoSetItemOptionPricingPackage.UNSET,
            [ARCHIVED_KEY]: item[ARCHIVED_KEY] ?? false,
            [EXTERNAL_DESCRIPTION_KEY]: option[EXTERNAL_DESCRIPTION_KEY] ?? "",
            [EXTERNAL_CATEGORY_KEY]:
              option[EXTERNAL_CATEGORY_KEY] ??
              InfoSetItemOptionExternalCategory.UNSET,
            [INTERNAL_NOTES_KEY]: option[INTERNAL_NOTES_KEY] ?? "",
          };
        }
      );
    }
  });
  return r?.flat();
};

export const newKeyTableRow = (): InfoSetItemTableRow => {
  return {
    key: getLocalKey(),
    [ID_KEY]: newKeyItemId(),
    [LABEL_KEY]: "",
    [INCLUDED_ORDER_FORM_KEY]: true,
    [INTERNAL_CATEGORY_KEY]: InfoSetItemInternalCategory.UNSET,
    [MULTI_OPTION_KEY]: false,
    [VALUE_KEY]: "",
    [DETAILS_KEY]: "",
    [VARIANTS_KEY]: "",
    [CONDITIONS_KEY]: [],
    [OMIT_KEY]: InfoSetItemOptionOmit.UNSET,
    [SOURCE_KEY]: InfoSetItemOptionSource.UNSET,
    [ASSEMBLY_KEY]: InfoSetItemOptionAssembly.UNSET,
    [PRICING_PACKAGE_KEY]: InfoSetItemOptionPricingPackage.UNSET,
    [ARCHIVED_KEY]: false,
    [EXTERNAL_DESCRIPTION_KEY]: "",
    [EXTERNAL_CATEGORY_KEY]: InfoSetItemOptionExternalCategory.UNSET,
    [INTERNAL_NOTES_KEY]: "",
  };
};

export const newOptionTableRow = (row: {
  [ID_KEY]: string;
  [LABEL_KEY]: string;
  [INCLUDED_ORDER_FORM_KEY]: boolean;
  [INTERNAL_CATEGORY_KEY]: InfoSetItemInternalCategory;
  [MULTI_OPTION_KEY]: boolean;
  [ARCHIVED_KEY]: boolean;
}): InfoSetItemTableRow => {
  return {
    key: getLocalKey(),
    [ID_KEY]: row[ID_KEY],
    [LABEL_KEY]: row[LABEL_KEY],
    [INCLUDED_ORDER_FORM_KEY]: row[INCLUDED_ORDER_FORM_KEY],
    [INTERNAL_CATEGORY_KEY]: row[INTERNAL_CATEGORY_KEY],
    [MULTI_OPTION_KEY]: row[MULTI_OPTION_KEY],
    [VALUE_KEY]: "",
    [DETAILS_KEY]: "",
    [VARIANTS_KEY]: "",
    [CONDITIONS_KEY]: [],
    [OMIT_KEY]: InfoSetItemOptionOmit.UNSET,
    [SOURCE_KEY]: InfoSetItemOptionSource.UNSET,
    [ASSEMBLY_KEY]: InfoSetItemOptionAssembly.UNSET,
    [PRICING_PACKAGE_KEY]: InfoSetItemOptionPricingPackage.UNSET,
    [ARCHIVED_KEY]: row[ARCHIVED_KEY],
    [EXTERNAL_DESCRIPTION_KEY]: "",
    [EXTERNAL_CATEGORY_KEY]: InfoSetItemOptionExternalCategory.UNSET,
    [INTERNAL_NOTES_KEY]: "",
  };
};

export const newKeyItemId = () => {
  // TODO Generate on DB
  return getLocalKey();
};

export const getLocalKey = () => {
  return (
    Math.random().toString(36).slice(4) + Math.random().toString(36).slice(4)
  );
};

export const copyOptionTableRow = (
  row: InfoSetItemTableRow
): InfoSetItemTableRow => {
  return {
    ...row,
    key: getLocalKey(),
    [VALUE_KEY]: row[VALUE_KEY] + " (Copy)",
  };
};

export const copyItemSetRows = (rows: InfoSetItemTableRow[]) => {
  if (!rows || rows.length === 0) {
    return [];
  }
  const newId = newKeyItemId();
  const newLabel = rows[0][LABEL_KEY] + " (Copy)";
  return rows.map((r: InfoSetItemTableRow) => {
    return {
      ...r,
      key: getLocalKey(),
      [ID_KEY]: newId,
      [LABEL_KEY]: newLabel,
    };
  });
};

export const isProtectedField = (key: string) => {
  switch (key) {
    case ID_KEY:
    case LABEL_KEY:
    case INCLUDED_ORDER_FORM_KEY:
    case INTERNAL_CATEGORY_KEY:
    case MULTI_OPTION_KEY:
      return true;
    default:
      return false;
  }
};

export const isPermanentField = (column: any) => {
  return column?.locked;
};

// Update cell by row / column
export const updateCell = (
  table: InfoSetItemTableRow[],
  rowIndex: number,
  columnId: string,
  value: any
) => {
  return table.map((row, index) => {
    if (index === rowIndex) {
      return {
        ...table[rowIndex],
        [columnId]: value,
      };
    }
    return row;
  });
};

// Update cells that share an internal id
export const updateCells = (
  table: InfoSetItemTableRow[],
  rowIndex: number,
  columnId: string,
  value: any
) => {
  const id = table[rowIndex][ID_KEY];
  return table.map((row) => {
    if (row[ID_KEY] === id) {
      return {
        ...row,
        [columnId]: value,
      };
    }
    return row;
  });
};

export const rowLabelAlreadyExists = (
  otherRows: InfoSetItemTableRow[],
  searchLabel: string
) => {
  const targetRows = otherRows.reduce(
    (unique: InfoSetItemTableRow[], item) =>
      unique.find(
        (i: InfoSetItemTableRow) => item[ID_KEY].localeCompare(i[ID_KEY]) === 0
      )
        ? unique
        : [...unique, item],
    []
  );
  if (targetRows?.length === 0) {
    return false;
  }
  return !!targetRows?.find((row: InfoSetItemTableRow) => {
    return row[LABEL_KEY].localeCompare(searchLabel) === 0;
  });
};

export const rowOptionValueAlreadyExists = (
  otherRows: InfoSetItemTableRow[],
  rowId: string,
  searchValue: string
) => {
  const targetRows = otherRows.filter(
    (row: InfoSetItemTableRow) => row[ID_KEY].localeCompare(rowId) === 0
  );
  if (targetRows?.length === 0) {
    return false;
  }
  return !!targetRows?.find((row: InfoSetItemTableRow) => {
    return row[VALUE_KEY].localeCompare(searchValue) === 0;
  });
};
