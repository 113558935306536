import React, {useMemo, useState} from "react";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import CartSection from "./components/CartSection/CartSection";
import useCartLibrary from "../../../_shared/hooks/useCart/useCartLibrary";
import {Divider} from "../../../_shared/generics/divider";
import {CartRowContainer, CartViewContainer} from "./styles";
import useCartLibraryController from "../../../_shared/hooks/useCart/Controllers/useCartLibraryController";
import CartItemProjectSections from "./components/CartProjectSection/CartItemProjectSections";
import CartViewProjectSwitcher from "./components/CartViewProjectSwitcher";
import CartItemPreview from "./components/CartItemPreview";
import CartProposalSummarySection from "./components/CartProposalSummarySection";
import {ICartItem} from "@natomas-org/core";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import CartItemDisplayCodeEditor from "./components/CartItemDisplayCodeEditor";
import {usePage} from "../../../_shared/hooks/usePage";
import {CartSectionSortBy} from "./interfaces";
import useActiveProject from "../../../_shared/hooks/useProject/useActiveProject";
import {
  STRIKE_THROUGH_COLOR,
  VILLA_FOREST_GREEN,
} from "../../../_shared/colors";

const CartView = () => {
  const {isNatMobile} = usePage();
  const {isAdmin} = useCurrentUser();
  const {isProjectLocked} = useActiveProject();
  const {activeProjectCartItems, customerCartItems} = useCartLibrary();

  const {
    removeProjectCartItem,
    removeProjectCartItems,
    addProjectCartItemFromCartLibrary,
    addProjectCartItemsFromCartLibrary,
  } = useCartLibraryController();

  const [previewCartItem, setPreviewCartItem] = useState<ICartItem | undefined>(
    undefined
  );
  const [displayCodeCartItem, setDisplayCodeCartItem] = useState<
    ICartItem | undefined
  >(undefined);

  const proposalActions = useMemo(() => {
    return {
      add: false,
      move: !isProjectLocked && isAdmin,
      quantity: !isProjectLocked && isAdmin,
      edit: !isProjectLocked,
      copy: true,
    };
  }, [isProjectLocked, isAdmin]);

  const draftActions = useMemo(() => {
    return {
      add: !isProjectLocked && isAdmin,
      move: !isProjectLocked && isAdmin,
      quantity: !isProjectLocked && isAdmin,
      edit: !isProjectLocked,
      copy: true,
    };
  }, [isProjectLocked, isAdmin]);

  return (
    <PageContainer>
      <CartViewContainer>
        <CartViewProjectSwitcher />
        <CartItemPreview
          cartItem={previewCartItem}
          onClose={() => {
            setPreviewCartItem(undefined);
          }}
        />
        <CartItemDisplayCodeEditor
          cartItem={displayCodeCartItem}
          onClose={() => {
            setDisplayCodeCartItem(undefined);
          }}
        />
        <CartRowContainer
          alternateDirection={isNatMobile ? "column-reverse" : undefined}
        >
          <CartSection
            isMobile={isNatMobile}
            actionsAvailable={proposalActions}
            cartItems={activeProjectCartItems}
            label={"Proposal"}
            move={{
              label: "Move to Drafts",
              color: STRIKE_THROUGH_COLOR,
              singleEvent: removeProjectCartItem,
              multipleEvent: removeProjectCartItems,
            }}
            previewItem={setPreviewCartItem}
            editDisplayCodeItem={setDisplayCodeCartItem}
            sortBy={CartSectionSortBy.LIST}
          />
          <CartProposalSummarySection />
        </CartRowContainer>
        <Divider />
        <CartSection
          isMobile={isNatMobile}
          actionsAvailable={draftActions}
          cartItems={customerCartItems}
          label={"Your Drafts"}
          move={{
            label: "Add to Proposal",
            color: VILLA_FOREST_GREEN,
            singleEvent: addProjectCartItemFromCartLibrary,
            multipleEvent: addProjectCartItemsFromCartLibrary,
          }}
          previewItem={setPreviewCartItem}
          editDisplayCodeItem={setDisplayCodeCartItem}
          sortBy={CartSectionSortBy.UNIT}
        />
        <CartItemProjectSections
          actionsAvailable={{
            move: false,
            edit: false,
            copy: true,
            add: false,
            quantity: false,
          }}
        />
      </CartViewContainer>
    </PageContainer>
  );
};

export default CartView;
