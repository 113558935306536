import React from "react";
import {
  getBasePriceFromProduct,
  getBedBathString,
  getLengthAndWidthString,
  getSqFtString,
} from "../../../product";
import {priceTextFromMicros} from "../../../helper";
import {
  StudioSummaryUnit,
  StudioSummaryUnitLine,
  StudioSummaryUnitTitle,
} from "./styles";
import {OneLine} from "../../../../../_shared/_legacy/one-line";
import {ProjectPriceMode} from "@natomas-org/core";

export const StudioSummaryUnitDescription = (props: {
  product: any;
  hideDimensions: boolean;
  priceMode: ProjectPriceMode;
}) => {
  const {product, hideDimensions, priceMode} = props;
  return (
    <StudioSummaryUnit>
      <StudioSummaryUnitTitle>{product?.title}</StudioSummaryUnitTitle>
      <StudioSummaryUnitLine>
        {product?.displayDescription}
      </StudioSummaryUnitLine>

      <OneLine
        id={"one-line-unit-details"}
        leftText={getBedBathString(product, "line")}
        rightText={priceTextFromMicros(
          getBasePriceFromProduct(product, priceMode),
          "min"
        )}
      />
      {!hideDimensions && (
        <OneLine
          id={"one-line-unit-dimensions"}
          leftText={getLengthAndWidthString(product)}
          rightText={getSqFtString(product)}
        />
      )}
    </StudioSummaryUnit>
  );
};
