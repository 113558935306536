// @ts-ignore
import {Environment} from "../../../database/firebase/types";
import {getFirebaseConfiguration} from "../../../database/firebase/firebaseConfig";

// @ts-ignore
export const isE2ERunning = window.Cypress != null;
export const isQA = window.location.host.includes("-qa");
export const isStaging = window.location.host.includes("-staging");
export const isProd = window.location.host.includes("app.villahomes.com");
export const isDev = !isQA && !isStaging && !isProd;
// These are set in the github workflow yml files
// To run functions locally, set these in your .env file
// Example: APP_ENVIRONMENT=local API_ENVIRONMENT=local
const app_environment = process.env.REACT_APP_APP_ENVIRONMENT;
const api_environment = process.env.REACT_APP_API_ENVIRONMENT;
export const BASE_URL =
  app_environment === "production"
    ? "https://app.villahomes.com"
    : app_environment === "staging"
    ? "https://natomas-staging.web.app"
    : app_environment === "qa"
    ? "https://natomas-qa.web.app"
    : "http://localhost:3000";
// .developerSettings decommission
const getBackendEnvironment = () => {
  if (api_environment === "production") {
    return Environment.PRODUCTION;
  } else if (api_environment === "staging") {
    return Environment.STAGING;
  } else {
    return Environment.DEVELOPMENT;
  }
};
// Use staging backend for E2E tests
export const backendConfig = getFirebaseConfiguration(getBackendEnvironment());
export const functionsHost = process.env.REACT_APP_FUNCTIONS_HOST;
export const functionsPort = process.env.REACT_APP_FUNCTIONS_PORT;

export function isProdBackend() {
  return backendConfig.projectId === "natomas-app" || isProd;
}

export const API_BASE_URL = () => {
  console.log(`Functions: ${api_environment}`);
  console.log(`Database: ${app_environment}`);
  console.log(`Backend: ${backendConfig.projectId}`);

  if (functionsHost) {
    console.log(`Functions: ${functionsHost}`);
    return `http://127.0.0.1:${functionsPort ?? "5001"}/${
      backendConfig.projectId
    }/us-central1/api`;
  }
  const FUNCTIONS_BASE_URL =
    api_environment === "production"
      ? "https://us-central1-natomas-app.cloudfunctions.net"
      : api_environment === "staging"
      ? "https://us-central1-natomas-staging.cloudfunctions.net"
      : "https://us-central1-natomas-qa.cloudfunctions.net";
  console.log(`Functions: /api`);
  return `${FUNCTIONS_BASE_URL}/api`;
};
