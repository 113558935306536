import React, {Fragment, useEffect, useRef} from "react";
import {useCatalogBannerProducts} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useCatalogBannerProducts";
import {ProductCard} from "../../../../../_shared/components/ProductCard/ProductCard";
import {ScrollableCardList} from "./ScrollableCardList";
import {isProductEligible} from "../../../../../../_shared/hooks/useMappingFeatures/helper";
import {
  IEligibleFeatures,
  IProduct,
  IProductInstantQuoteDataDictionary,
  IProductParcelFitDataDictionary,
  IQuizResults,
  ProjectPriceMode,
} from "@natomas-org/core";
import {dummyElement} from "../OurSeries";
import {useCatalogSession} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {
  useProductFitsOnLot,
  useProductSiteWork,
} from "../../../../../../_shared/hooks/useProductParcelData";
import {CollectionItemWrapper} from "../styles";
import {sortProductsByRecommendation} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/helpers";
import {useProductRecommendations} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/useProductRecommendations";
import {isMobile} from "../../../../../../_shared/navigation";
import {a, useSpring} from "@react-spring/web";
import {GRANITE, WARM_NEUTRAL} from "../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../../portal/views/SSDDashboardView/styles/globals";
import styled from "styled-components";
import {usePage} from "../../../../../../_shared/hooks/usePage";
import {MAX_SERIES_PRODUCTS} from "../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogBannerProducts/constants";

const ExpandedContent = React.memo(
  (props: {
    recommendedExpanded: boolean;
    quiz: IQuizResults | null;
    productLineCardsPerRow: number;
    allInEstimateCatalog: boolean;
    recommendationsForProducts: {
      [p: string]: {
        percentScore?: number | undefined;
        matches?: string[] | undefined;
      };
    };
    isAdmin: boolean;
    adminCatalogMode: boolean;
    eligibility?: IEligibleFeatures | undefined;
    isCustomerPreDeposit: boolean;
    productFitsOnLotData: IProductParcelFitDataDictionary | null | undefined;
    productInstantQuoteData:
      | IProductInstantQuoteDataDictionary
      | null
      | undefined;
    priceMode: ProjectPriceMode;
  }) => {
    const {
      recommendedExpanded,
      quiz,
      recommendationsForProducts,
      allInEstimateCatalog,
      productInstantQuoteData,
      productFitsOnLotData,
    } = props;
    if (!recommendedExpanded || !quiz || quiz?.mixedProducts?.length < 1) {
      return null;
    }
    return (
      <Fragment>
        <div style={{height: "20px"}} />
        <ScrollableCardList
          productLineCardsPerRow={props.productLineCardsPerRow}
          title={"You may also like..."}
          items={
            quiz?.mixedProducts?.length > 0
              ? Object.values(quiz?.mixedProducts)
                  ?.sort((a: IProduct, b: IProduct) => {
                    return sortProductsByRecommendation(
                      recommendationsForProducts,
                      a,
                      b
                    );
                  })
                  ?.map((product, idx) => {
                    return {
                      id: `${idx}`,
                      renderItem: (
                        <CollectionItemWrapper
                          className={`item`}
                          productLineCardsPerRow={props.productLineCardsPerRow}
                        >
                          <ProductCard
                            percentRecommendation={
                              recommendationsForProducts[product.id ?? -1]
                                ?.percentScore ?? -1
                            }
                            allInEstimate={
                              allInEstimateCatalog && product?.id
                                ? productInstantQuoteData?.[product?.id]
                                : undefined
                            }
                            productFitsOnLotData={
                              product?.id
                                ? productFitsOnLotData?.[product?.id]
                                : undefined
                            }
                            key={`product-card`}
                            isEligible={
                              props.isAdmin &&
                              props.adminCatalogMode &&
                              isProductEligible(
                                props.eligibility,
                                product?.productGroupId,
                                product?.id
                              )
                            }
                            isCustomerPreDeposit={props.isCustomerPreDeposit}
                            product={product}
                            style={{
                              width: "100%",
                              padding: "0 0",
                            }}
                          />
                        </CollectionItemWrapper>
                      ),
                    };
                  })
              : [dummyElement]
          }
        />
      </Fragment>
    );
  }
);

export const RecommendedContent = React.memo(
  (props: {
    productLineCardsPerRow: number;
    isCustomerPreDeposit?: boolean;
    adminCatalogMode?: boolean;
    isAdmin?: boolean;
    eligibility?: IEligibleFeatures | undefined;
  }) => {
    // console.log("Rerender RecommendedContent");
    const {productInstantQuoteData} = useProductSiteWork({
      productId: undefined,
    });
    const {productFitsOnLotData} = useProductFitsOnLot({productId: undefined});
    const {quiz} = useCatalogBannerProducts();
    const {allInEstimateCatalog, priceMode} = useCatalogSession();
    const recommendationsForProducts = useProductRecommendations();
    const [recommendedExpanded, setRecommendedExpanded] = React.useState(true);
    const contentRef = useRef<HTMLDivElement>(null);
    const {isNatMobile} = usePage();
    const [style, setStyle] = useSpring(
      () => ({height: recommendedExpanded ? "340px" : "0px"}),
      []
    );
    useEffect(() => {
      setStyle({
        height: `${contentRef.current?.offsetHeight}px`,
      });
    }, [recommendedExpanded, setStyle]);
    if (!quiz) {
      return null;
    }

    return (
      <div>
        <ScrollableCardList
          hideArrows={isMobile()} // Only for users that can swipe via touch
          title={quiz?.contextTitle ?? null}
          productLineCardsPerRow={props.productLineCardsPerRow}
          items={
            quiz?.seriesProducts?.length > 0
              ? Object.values(quiz?.seriesProducts)
                  ?.sort((a: IProduct, b: IProduct) => {
                    return sortProductsByRecommendation(
                      recommendationsForProducts,
                      a,
                      b
                    );
                  })
                  .slice(0, MAX_SERIES_PRODUCTS)
                  .map((product, idx) => {
                    return {
                      id: `${idx}`,
                      renderItem: (
                        <CollectionItemWrapper
                          className={`item`}
                          productLineCardsPerRow={props.productLineCardsPerRow}
                        >
                          <ProductCard
                            percentRecommendation={
                              recommendationsForProducts[product.id ?? -1]
                                ?.percentScore ?? -1
                            }
                            allInEstimate={
                              allInEstimateCatalog && product?.id
                                ? productInstantQuoteData?.[product?.id]
                                : undefined
                            }
                            productFitsOnLotData={
                              product?.id
                                ? productFitsOnLotData?.[product?.id]
                                : undefined
                            }
                            key={`product-card-series`}
                            isEligible={
                              props.isAdmin &&
                              props.adminCatalogMode &&
                              isProductEligible(
                                props.eligibility,
                                product?.productGroupId,
                                product?.id
                              )
                            }
                            isCustomerPreDeposit={props.isCustomerPreDeposit}
                            product={product}
                            style={{
                              width: "100%",
                              padding: "0 0",
                            }}
                          />
                        </CollectionItemWrapper>
                      ),
                    };
                  })
              : [dummyElement]
          }
        />
        <a.div style={style}>
          <div ref={contentRef}>
            <ExpandedContent
              priceMode={priceMode}
              adminCatalogMode={props.adminCatalogMode ?? false}
              allInEstimateCatalog={allInEstimateCatalog}
              isAdmin={props.isAdmin ?? false}
              productFitsOnLotData={productFitsOnLotData}
              isCustomerPreDeposit={props.isCustomerPreDeposit ?? false}
              productInstantQuoteData={productInstantQuoteData}
              productLineCardsPerRow={props.productLineCardsPerRow}
              quiz={quiz}
              recommendationsForProducts={recommendationsForProducts}
              recommendedExpanded={recommendedExpanded}
              eligibility={props.eligibility}
            />
          </div>
        </a.div>
        <ExpandLinkSpan
          hidden={quiz?.mixedProducts?.length < 1}
          style={
            isNatMobile
              ? {paddingBottom: "1rem", position: "relative", top: "1rem"}
              : undefined
          }
          onClick={() => {
            setRecommendedExpanded(!recommendedExpanded);
          }}
        >
          {recommendedExpanded ? "See less" : "See more"}
        </ExpandLinkSpan>
        <div style={{height: "1rem"}} />
      </div>
    );
  }
);

const ExpandLinkSpan = styled.span<{}>`
  color: ${GRANITE};
  font: ${FONT_FAMILY_MEDIUM};
  text-decoration: underline;
  cursor: pointer;
  width: max-content;
  background-color: ${WARM_NEUTRAL};

  &:hover {
    color: black;
  }
`;
