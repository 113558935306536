import React, {useContext, useMemo} from "react";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {useDelay} from "../../../../../../_shared/hooks/useDelay";
import {
  AccountName,
  PresentationContainer,
  PresentationTitle,
} from "../../styles";
import {Wrapper} from "./styles";
import NatLabel, {
  NatLabelType,
  NatLabelWeight,
} from "../../../../../../_shared/generics/label/NatLabel";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import {Address, Customer} from "@natomas-org/core";
import {useCurrentCustomer} from "../../../../../../_shared/hooks/useCurrentCustomer";
import {
  BLACK20,
  GRANITE,
  VILLA_APPLE_GREEN,
  VILLA_SLATE,
} from "../../../../../../_shared/colors";
import {
  NatJustifyContent,
  NatVerticalAlign,
} from "../../../../../../_shared/generics/_shared";
import {
  NatHouseCity,
  NatLocationPin,
} from "../../../../../../_shared/icon/icons";
import {FOOTER_SPLASH_TEXT} from "./constants";
import {
  FULL_SCREEN_HEIGHT_REM,
  FULL_SCREEN_WIDTH_REM,
} from "../../../HCPUnitView/DesignSelections/constants";
import {PROPOSAL_GENERATE_PDF} from "../../DTC/components/constants";
import {ALL_MONTHS} from "../../../../ProposalView/constants/TimelineTypes";
import {useProjectBudget} from "../../../../../../_shared/hooks/useProjectBudget/useProjectBudget";

const HeaderImage =
  "https://firebasestorage.googleapis.com/v0/b/natomas-staging.appspot.com/o/assets%2Fportal%2Fpresentation%2FB2BVilla.jpg?alt=media";
export const B2BPresentationSplash = () => {
  const {address} = useActiveProject();
  const {unitSelectionSummaryString, totalUnitQuantity} = useProjectBudget();
  const {customer} = useCurrentCustomer();
  const fadeIn = useDelay({milliseconds: 100}).ready;
  const isGeneratingPDF = useContext(PROPOSAL_GENERATE_PDF);

  const presentationTitle = useMemo(() => {
    return `Build Proposal for ${totalUnitQuantity} Villa Homes`;
  }, [totalUnitQuantity]);

  if (!address || !customer) {
    return null;
  }
  const generatedDate = new Date();
  const dateString = `${
    ALL_MONTHS[generatedDate.getMonth()]
  } ${generatedDate.getDate()}, ${generatedDate.getFullYear()}`;
  const fullName = Customer.getFullName(customer);
  return (
    <PresentationContainer
      fadeIn={fadeIn}
      style={
        isGeneratingPDF
          ? {
              alignItems: "flex-start",
              minHeight: `${FULL_SCREEN_HEIGHT_REM}rem`,
              maxHeight: `${FULL_SCREEN_HEIGHT_REM}rem`,
              minWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
              maxWidth: `${FULL_SCREEN_WIDTH_REM}rem`,
            }
          : {maxWidth: "99vw", overflow: "hidden", minHeight: "50rem"}
      }
    >
      <Wrapper>
        <img
          width={"3840px"}
          src={HeaderImage}
          alt={"start project"}
          height={"900px"}
          style={{
            objectFit: "cover",
            objectPosition: "20% 50%",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
        <NatFlex
          FULL_WIDTH
          ROW
          NOWRAP
          VERTICAL_ALIGN={NatVerticalAlign.CENTER}
          FULL_HEIGHT
        >
          <div
            style={{
              width: "100%",
              padding: "2rem 6rem",
              color: VILLA_SLATE,
              height: "100%",
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0",
            }}
          >
            <AccountName style={{alignContent: "center", margin: "0 0"}}>
              <NatLabel
                label={fullName}
                type={NatLabelType.H4}
                weight={NatLabelWeight.MEDIUM}
                style={{
                  textAlign: "left",
                  fontSize: "30px",
                  lineHeight: "30px",
                }}
              />
            </AccountName>
            <PresentationTitle style={{alignContent: "center", margin: "0 0"}}>
              <NatLabel
                label={presentationTitle}
                type={NatLabelType.H1}
                weight={NatLabelWeight.MEDIUM}
                style={{
                  textAlign: "left",
                  fontSize: "48px",
                  lineHeight: "48px",
                }}
              />
            </PresentationTitle>
          </div>
          <div
            style={{
              border: `0.5px solid ${BLACK20}`,
              height: "calc(100% - 160px)",
            }}
          />
          <NatFlex
            FULL_WIDTH
            style={{
              paddingLeft: "2rem",
            }}
          >
            <RightHandContentItem
              title={"Property Address"}
              lineStrings={[
                Address.getStreetNumberAndStreet(address),
                Address.getCityStateZip(address),
              ]}
              icon={<NatLocationPin size={"2rem"} color={VILLA_APPLE_GREEN} />}
            />
            {unitSelectionSummaryString && (
              <RightHandContentItem
                title={"Unit Selections"}
                lineStrings={[unitSelectionSummaryString]}
                icon={<NatHouseCity size={"2rem"} color={VILLA_APPLE_GREEN} />}
              />
            )}
          </NatFlex>
        </NatFlex>
        <NatFlex
          FULL_WIDTH
          ROW
          NOWRAP
          JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
          style={{
            padding: "1rem 2rem",
            color: GRANITE,
          }}
        >
          <NatLabel
            label={FOOTER_SPLASH_TEXT}
            type={NatLabelType.P3}
            style={{
              textAlign: "left",
            }}
          />
          <NatLabel
            label={dateString}
            type={NatLabelType.P3}
            style={{
              textAlign: "left",
            }}
          />
        </NatFlex>
      </Wrapper>
    </PresentationContainer>
  );
};

const RightHandContentItem = (props: {
  icon: JSX.Element;
  title: string;
  lineStrings: string[];
}) => {
  const {icon, title, lineStrings} = props;
  return (
    <NatFlex FULL_WIDTH ROW>
      <div
        style={{width: "3.5rem", paddingRight: "0.5rem", paddingLeft: "1rem"}}
      >
        {icon}
      </div>
      <NatFlex COLUMN NOGAP style={{color: VILLA_SLATE, maxWidth: "60%"}}>
        <NatLabel
          label={title}
          type={NatLabelType.H1}
          weight={NatLabelWeight.MEDIUM}
          style={{
            alignItems: "center",
            textAlign: "left",
            margin: "0 0 0 0",
            paddingBottom: "0.5rem",
            fontSize: "28px",
            lineHeight: "28px",
          }}
        />
        {lineStrings.map((lineString) => {
          return (
            <NatLabel
              label={lineString}
              type={NatLabelType.P1}
              style={{
                textAlign: "left",
                margin: "0 0",
                fontSize: "24px",
                lineHeight: "24px",
              }}
            />
          );
        })}
      </NatFlex>
    </NatFlex>
  );
};
