import {ProductDetailContainer, ProductDetailTitle} from "../../../styles";
import React from "react";
import {usePage} from "../../../../../../../../../_shared/hooks/usePage";
import {IProduct, Product} from "@natomas-org/core";
import {MapRightContainer} from "../../../../../../styles";
import {useFactoryLine} from "../../../../../../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {getLengthAndWidthString} from "../../../../../../../../../_shared/_legacy/logic/ProductUtils";
import CatalogUnitDetails from "../../../../../../../../../_shared/catalog/views/product/details";
import {useGlobalConfiguration} from "../../../../../../../../../_shared/hooks/useGlobalConfiguration";
import {getProductionTimelineInfo} from "../../../../../../../../../portal/views/ProposalView/constants/timelineHelpers";

export const ProductHeader = (props: {
  product: IProduct | undefined | null;
  rootVersion?: boolean;
}) => {
  const {isNatMobile} = usePage();
  const {product} = props;
  const {INVENTORY_PROGRAM} = useGlobalConfiguration();
  const {details} = useFactoryLine({factoryId: product?.productGroupId});

  if (!product || !details) {
    return null;
  }

  return (
    <MapRightContainer isMobile={isNatMobile}>
      <ProductDetailContainer isMobile={isNatMobile}>
        <ProductDetailTitle>
          <h2>{product.title}</h2>
        </ProductDetailTitle>
        <div>
          <CatalogUnitDetails
            details={{
              bedroomCount: product?.productDetails?.bedrooms,
              bathroomCount: product?.productDetails?.bathrooms,
              factoryTimelineTotal:
                getProductionTimelineInfo(details)?.totalMonths,
              dimensions: getLengthAndWidthString(product),
              squareFootage: product?.productDetails?.squareFeet,
              unitTimelineTotal: Product.isInventory(product)
                ? INVENTORY_PROGRAM?.DELIVERY_MONTHS
                : undefined,
            }}
            showIcons={false}
            showTimeline={false}
          />
        </div>
      </ProductDetailContainer>
    </MapRightContainer>
  );
};
