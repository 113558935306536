import {ITableSortingDetails} from "../../../../_shared/components/AdminTable/table";

export interface UserRowInfo {
  first_name: string;
  last_name: string;
  email: string;
  user_id: string;
  last_login_timestamp: number;
  created_timestamp: number;
}

const sortUserByKey = (
  sortingDetails: ITableSortingDetails,
  key: string,
  groupA: any,
  groupB: any
) => {
  if (!sortingDetails?.mode) {
    return 1;
  }
  const nameA = groupA[key];
  const nameB = groupB[key];
  if (nameA < nameB) {
    return -1 * sortingDetails.mode;
  }
  if (nameA > nameB) {
    return sortingDetails.mode;
  }

  // names must be equal
  return 0;
};

const sortUsersByKey = (
  sortingDetails: ITableSortingDetails,
  key: string,
  users: any[]
) => {
  return users.sort((a: any, b: any) => {
    return sortUserByKey(sortingDetails, key, a, b);
  });
};

export const sortUsers = (
  sortingDetails: ITableSortingDetails,
  users: UserRowInfo[]
) => {
  // Since all the fields are strings currently - we can just pass the key
  if (sortingDetails.column) {
    return sortUsersByKey(sortingDetails, sortingDetails.column, users);
  } else {
    return users;
  }
};

export const sortProjects = (
  sortingDetails: ITableSortingDetails,
  projects: any[]
) => {
  // Since all the fields are strings currently - we can just pass the key
  if (sortingDetails.column) {
    return sortUsersByKey(sortingDetails, sortingDetails.column, projects);
  } else {
    return projects;
  }
};
