import {InfoSetItemOptionCondition} from "../../../../../../../shared/interfaces";
import {DesignStudioModifier, IProduct} from "@natomas-org/core";
import {useInfoSetItemOptionCondition} from "../../../../../../useInfoSetItemOptionCondition";
import React, {useMemo, useState} from "react";
import {IProductAssociationDetails} from "../../../../../_shared/interfaces";
import {getRatioNumerator} from "../helper";
import {ConditionAssociationsAccess} from "../ConditionAssociationsAccess";
import {NatMicroModal} from "../../../../../../../../_shared/generics/micro-modal";
import {ConditionAssociationsTable} from "../ConditionAssociationsTable";
import {isOptionPossibleUnderConditionForProduct} from "../../../../../_shared/productVerification";

export const ConditionSelectionAssociations = (props: {
  condition: InfoSetItemOptionCondition;
  title?: string;
  productOptions: IProduct[];
  modifierOptions: any[];
  categories: any;
  modifierGroups: any;
}) => {
  const {
    condition,
    title,
    modifierOptions,
    modifierGroups,
    categories,
    productOptions,
  } = props;
  const {type, required} = useInfoSetItemOptionCondition(condition);
  const [event, setEvent] = useState(undefined);
  const validProducts: IProductAssociationDetails[] = useMemo(() => {
    if (
      !productOptions ||
      productOptions?.length === 0 ||
      required?.length === 0
    ) {
      return [];
    }
    let validatedProducts: IProductAssociationDetails[] = [];

    // Local memoized structure to skip previously verified categories/modifier groups
    let verifiedStructure: {
      [categoryId: string]: {[modifierGroupId: string]: string[]};
    } = {};

    // Determine valid products from eligible
    productOptions.forEach((product: IProduct) => {
      // If there are no categories, exit immediately
      if (
        !product?.configuratorPages ||
        product.configuratorPages.length === 0
      ) {
        return;
      } else {
        // Tracking which modifiers are available to the product
        let containsCheckArray: string[] = [];

        // Categories
        product.configuratorPages.forEach((categoryId: string) => {
          // If the category has already been verified, just load it from local state
          if (verifiedStructure?.[categoryId]) {
            let verifiedRequirementsFromCategory = Object.values(
              verifiedStructure[categoryId]
            );
            containsCheckArray.push(...verifiedRequirementsFromCategory.flat());
            return;
          } else {
            const category = categories?.[categoryId];

            // If category is not found, or has no modifierGroups, exit immediately
            if (!category || category.modifierGroups?.length === 0) {
              return;
            }

            // ModifierGroups
            category.modifierGroups.forEach((modifierGroupId: string) => {
              // If the modifierGroup has already been verified, just load it from local state
              if (verifiedStructure?.[categoryId]?.[modifierGroupId]) {
                containsCheckArray.push(
                  ...verifiedStructure[categoryId][modifierGroupId]
                );
                return;
              } else {
                const modifierGroup = modifierGroups?.[modifierGroupId];

                // If modifierGroup is not found, or has no modifiers, exit immediately
                if (!modifierGroup || modifierGroup.modifiers?.length === 0) {
                  return;
                }

                // Modifiers
                required?.forEach((requirementId: string) => {
                  // If we have not saved this modifier yet, and it is valid,
                  // add to structure, and push to check array
                  // TODO Add logic to determine if certain "ALL" selections are impossible
                  // - They share a modifier group and only one can be selected
                  if (
                    !containsCheckArray.includes(requirementId) &&
                    modifierGroup.modifiers.includes(requirementId)
                  ) {
                    verifiedStructure[categoryId] = Object.assign(
                      {},
                      verifiedStructure?.[categoryId],
                      {
                        [modifierGroupId]: [
                          ...(verifiedStructure?.[categoryId]?.[
                            modifierGroupId
                          ] ?? []),
                          requirementId,
                        ],
                      }
                    );
                    containsCheckArray.push(requirementId);
                    return;
                  }
                });
              }
            });
          }
        });
        validatedProducts.push({
          product: product,
          contains: containsCheckArray,
          isValid: isOptionPossibleUnderConditionForProduct(
            type,
            required.length,
            containsCheckArray
          ),
        });
      }
    });
    return validatedProducts;
  }, [type, required, productOptions, modifierGroups, categories]);

  const modifiers = useMemo(() => {
    return required
      .map((r: string) => modifierOptions.find((m: any) => m.value === r))
      .filter((m: DesignStudioModifier) => !!m);
  }, [required, modifierOptions]);

  return (
    <>
      <ConditionAssociationsAccess
        eligibleCount={getRatioNumerator(
          type,
          required?.length ?? 0,
          validProducts
        )}
        clickEvent={(e: any) => {
          setEvent(e);
        }}
        totalCount={productOptions?.length}
      />
      <NatMicroModal
        id={`condition-${title}`}
        draggable={true}
        title={title}
        event={event}
      >
        <ConditionAssociationsTable
          products={productOptions}
          modifiers={modifiers}
          validProducts={validProducts}
        />
      </NatMicroModal>
    </>
  );
};
