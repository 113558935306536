import {IAddressDetails} from "@natomas-org/core";

export const PROJECT_COLLECTION_KEY = "natomas/v1/project";
export const PROJECT_NOTES_COLLECTION_KEY = "note";
export const PROJECT_DOCUMENT_COLLECTION_KEY = "document";
export const PROJECT_OPPORTUNITY_COLLECTION_KEY =
  "natomas/v1/project_opportunity";

export interface IProjectContactInfo {
  first_name?: string;
  last_name?: string;
  phone?: string;
  address?: IAddressDetails;
}

export const dataToProjectContactInfo = (
  first?: string,
  last?: string,
  phone?: string,
  address?: IAddressDetails
): IProjectContactInfo => {
  return {
    first_name: first,
    last_name: last,
    phone: phone,
    address: address,
  };
};
