import {
  Address,
  counties,
  ICatalogTag,
  states as stateOptions,
} from "@natomas-org/core";
import {FieldType} from "../../_shared/generics/table/TableTypes";
import {statusField} from "./fields";
import {ValueAndLabel} from "../_shared/components/AdminComponentFactory/constants";
import {AdminComponentFieldInfoProps} from "../_shared/components/AdminComponentFactory/AdminInputProps";

export const getAvailableCounties = (states: string[]) => {
  let eligibleCounties = counties.filter((item) => states.includes(item.state));
  return eligibleCounties.map((countyInfo) => {
    return Address.getCountyStateString(
      countyInfo.countyName,
      countyInfo.state
    );
  });
};

export const AdditionalFactoryLineStructure = {
  states: {
    name: "Serviceable States",
    type: "dropdown-array",
    subtype: "multi",
    alwaysEnabled: true,
    defaultValue: [],
    options: stateOptions,
    index: 4,
  },
  counties: {
    name: "Serviceable Counties",
    type: "dropdown-array",
    subtype: "multi",
    alwaysEnabled: true,
    defaultValue: [],
    options: [],
    index: 5,
  },
  production_timeline: {
    name: "Production Timeline",
    type: "table",
    alwaysEnabled: true,
    tableStructure: {
      rowCount: 5,
      columns: [
        {
          dataField: "stage",
          name: "Stage",
          type: FieldType.STRING,
        },
        {
          dataField: "months",
          name: "# of Months",
          type: FieldType.NUMBER,
        },
      ],
      rigidValues: [
        ["Proposal"],
        ["Permitting"],
        ["Authorization to Build"],
        ["Manufacturing"],
        ["Installation"],
      ],
    },
    index: 13,
  },
  optionValues: {
    type: "optionKeyValues",
    defaultValue: {},
    index: 20,
  },
};

export const factoryLineTags: ValueAndLabel[] = [
  {label: "Limited design choices", value: ICatalogTag.FEW_DESIGN_CHOICES},
  {label: "Customizable", value: ICatalogTag.MANY_DESIGN_CHOICES},
  {label: "Cost effective", value: ICatalogTag.LOWEST_PRICE},
  {label: "Quickest delivery", value: ICatalogTag.QUICKEST_DELIVERY},
  {label: "Wheelchair-friendly", value: ICatalogTag.WHEELCHAIR_FRIENDLY},
];

export const FactoryLineStructure = {
  title: {
    name: "Display Title",
    type: "input",
    alwaysEnabled: true,
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    type: "input",
    index: 0,
  },
  displayDescription: {
    name: "Display Description",
    index: 2,
    type: "textarea",
  },
  status: statusField,
  imageId: {
    name: "Image",
    alwaysEnabled: true,
    type: "image",
    index: 12,
  },
  sales_tax_percent: {
    key: "sales_tax_percent",
    name: "Total Sales Tax Rate %",
    type: "number",
    subType: "decimal",
    index: 4,
    alwaysEnabled: true,
    // defaultValue: "" + Price.MANUFACTURED_HOME_SALES_TAX_PERCENT.toFixed(3),
  } as AdminComponentFieldInfoProps,
  tags: {
    name: "Tags",
    index: 3,
    type: "dropdown-array",
    subtype: "multi",
    options: factoryLineTags,
    defaultValue: [],
    alwaysEnabled: true,
  },
  ...AdditionalFactoryLineStructure,
};
