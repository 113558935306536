import React from "react";
import {StudioContent} from "../_shared/components/StudioContent";
import {StudioUnitCard} from "../_shared/components/StudioUnitCard";
import {StudioState} from "../../_shared/slices/types/Store";
import {useStudioSettings} from "../../_shared/hooks/useStudio/useStudioSettings";
import {useStudioSmartScroll} from "../../_shared/hooks/useStudio/useStudioSmartScroll";
import {ProjectPriceMode} from "@natomas-org/core";

interface StudioStaticViewProps {
  priceMode: ProjectPriceMode;
  product?: any;
  snapshot?: any;
}

const StudioStaticView = (props: StudioStaticViewProps) => {
  const {product, snapshot, priceMode} = props;
  const {summary} = snapshot ?? {};
  const {step, section} = useStudioSettings();
  const price = summary?.totalUnitPrice?.price
    ? summary?.totalUnitPrice?.price / 100
    : 0;

  const scrollingProps = useStudioSmartScroll({
    hasHeader: true,
    hasProgressBar: false,
    isMobile: false,
    length: summary?.structure?.length,
  });

  return (
    <>
      <StudioContent
        priceMode={priceMode}
        step={step}
        section={section}
        mode={StudioState.SHOWCASE}
        stackedMode={false}
        formik={undefined}
        price={0}
        product={product}
        summary={summary}
        structure={summary?.structure}
        height={"100%"}
        options={{hideDisclaimer: true}}
        eligibleCategories={summary?.structure}
        {...scrollingProps}
      />
      <StudioUnitCard
        priceMode={priceMode}
        stackedMode={false}
        isEditing={false}
        price={price}
        product={product}
        structure={summary?.structure}
        selectedModifiers={summary?.selectedModifiers}
        controller={<></>}
        {...scrollingProps}
      />
    </>
  );
};

export default StudioStaticView;
