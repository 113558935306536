import React from "react";
import {
  ProgressBarContainer,
  ProgressBarMeter,
  ProgressBarStepContainer,
  ProgressBarStepsContainer,
} from "./styles";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../page/components/PageContainer";
import {usePage} from "../../hooks/usePage";
import {WHITE} from "../../colors";
import {PATH_Z_INDEX} from "../../styles";

export interface ProgressBarStep {
  title: () => string;
  clickEvent?: () => void;
}

export interface ProgressBarProps {
  steps: ProgressBarStep[];
  currentStep: number;
  selectedStep: number;
  isEnabled: (index: number) => boolean;
  hasBorder: boolean;
}

export const PROGRESS_BAR_HEIGHT = 34;

export const ProgressBar = (props: ProgressBarProps) => {
  const {steps, currentStep, selectedStep, isEnabled, hasBorder} = props;
  const {containerPadding, pageWidth} = usePage();

  const ProgressBarSteps = () => {
    const width = 100 / steps.length;
    return (
      <ProgressBarStepsContainer padding={containerPadding + "px"}>
        {steps.map((step: ProgressBarStep, index: number) => {
          const disabled = !isEnabled(index);
          return (
            <ProgressBarStepContainer
              onClick={() => !disabled && step.clickEvent && step.clickEvent()}
              widthPercentage={width}
              bold={index === selectedStep}
              disabled={disabled}
              key={"progress-bar-step-" + index}
            >
              {step.title()}
            </ProgressBarStepContainer>
          );
        })}
      </ProgressBarStepsContainer>
    );
  };

  const getMeterWidth = (): number => {
    return containerPadding + (1 / steps?.length) * currentStep * pageWidth;
  };

  return (
    <PageContainer
      stickyOptions={{
        offset: "65px",
        height: PROGRESS_BAR_HEIGHT + "px",
        mode: PageContainerStickyMode.MODAL,
        zIndex: PATH_Z_INDEX,
      }}
      border={hasBorder}
      backgroundColor={WHITE}
      overridePadding
    >
      <ProgressBarContainer id={"progress-bar"}>
        <ProgressBarMeter width={getMeterWidth()} />
        <ProgressBarSteps />
      </ProgressBarContainer>
    </PageContainer>
  );
};
