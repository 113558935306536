import React from "react";
import {NatomasSwiper} from "./styles";
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Zoom,
} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import {PaginationOptions} from "swiper/types";
import {usePage} from "../../hooks/usePage";
import SwiperClass from "swiper/types/swiper-class";

SwiperCore.use([
  EffectFade,
  EffectCube,
  EffectCoverflow,
  EffectFlip,
  Autoplay,
  Navigation,
  Pagination,
  Zoom,
  Mousewheel,
  Keyboard,
]);

// NatSwiper has the ability to have titles but we are turning it off for now.
const CUSTOMIZABLE_PAGINATION = true;

export interface NatSwiperSlide {
  content: JSX.Element;
  title?: string;
  width?: string;
}

export type ClickTarget = "content" | "background" | "other";

export interface NatSwiperProps {
  slides: NatSwiperSlide[];
  pagination?: boolean;
  navigation?: boolean;
  autoFormatSlides?: boolean;
  hasTitles?: boolean;
  borderRadius?: string;
  alternativeNavigationButtons?: boolean;
  loopSlides?: boolean;
  vertical?: boolean;
  autoplay?: boolean;
  isNested?: boolean;
  spaceBetween?: number; // pixels
  effect?: "slide" | "fade" | "cube" | "coverflow" | "flip";
  onSlideChangeAction?: (activeIndex: number) => void;
  zoom?: boolean;
  isKeyboardEnabled?: boolean;
  onSlideClick?: (target: ClickTarget, slideIndex: number) => void;
  slidesPerView?: number;
}

export const NatSwiper = (props: NatSwiperProps) => {
  const {
    pagination,
    autoFormatSlides,
    navigation,
    slides,
    hasTitles,
    borderRadius,
    loopSlides,
    vertical,
    autoplay,
    spaceBetween,
    effect,
    alternativeNavigationButtons,
    onSlideChangeAction,
    zoom,
    onSlideClick,
    isKeyboardEnabled,
    slidesPerView,
  } = props;

  const {isNatMobile} = usePage();

  const getPaginationSettings = (): PaginationOptions | boolean => {
    if (hasTitles && CUSTOMIZABLE_PAGINATION) {
      return {
        clickable: true,
        renderBullet: function (index: number, className: string) {
          return (
            '<span class="' +
            className +
            '">' +
            slides[index]?.title +
            "</span>"
          );
        },
      };
    } else return {clickable: true};
  };

  return (
    <>
      {slides?.length > 0 && (
        <NatomasSwiper
          hasTitles={hasTitles && CUSTOMIZABLE_PAGINATION}
          borderRadius={borderRadius}
          alternativeButtons={alternativeNavigationButtons}
          isNatMobile={isNatMobile}
        >
          <Swiper
            effect={effect ?? "slide"}
            loop={loopSlides ?? slides.length > 1}
            grabCursor={false}
            setWrapperSize
            zoom={zoom}
            centeredSlides
            spaceBetween={spaceBetween ?? 0}
            autoplay={
              autoplay
                ? {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }
                : false
            }
            keyboard={{enabled: isKeyboardEnabled}}
            threshold={10}
            direction={vertical ? "vertical" : "horizontal"}
            slidesPerView={
              slidesPerView ? slidesPerView : autoFormatSlides ? "auto" : 1
            }
            pagination={pagination ? getPaginationSettings() : false}
            navigation={navigation ?? false}
            className="mySwiper"
            onSlideChange={(swiper: SwiperClass) => {
              // For some reason Swiper uses 1 indexing!?
              if (onSlideChangeAction) {
                onSlideChangeAction(swiper.realIndex);
              }
            }}
            onClick={(swiper, touch) => {
              if (onSlideClick) {
                if (touch.target instanceof Element) {
                  let clickTarget: ClickTarget = "other";
                  if (touch?.target?.className === "swiper-zoom-container") {
                    clickTarget = "background";
                  } else if (touch?.target.tagName === "IMG") {
                    clickTarget = "content";
                  }
                  onSlideClick(clickTarget, swiper.realIndex);
                }
              }
            }}
          >
            {slides.map((slide: NatSwiperSlide, index: number) => {
              return (
                <SwiperSlide
                  style={{
                    width: slide.width ? slide.width : "100%",
                  }}
                  zoom={zoom}
                  key={"swiper-slide-" + index}
                >
                  {slide.content}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </NatomasSwiper>
      )}
    </>
  );
};
