const COMPANY_NAME = "Villa";
const COMPANY_SIGNATURE = "@ 2021 NATOMAS LABS, Inc"
const COMPANY_CSLB = "CSLB#1077688";
const COMPANY_DL = "DL#1564605"
const COMPANY_PHONE_NUMBER = "415.968.1625"
const MADE_IN_USA = "Made in USA";

export {
  COMPANY_NAME,
  COMPANY_SIGNATURE,
  COMPANY_CSLB,
  COMPANY_DL,
  COMPANY_PHONE_NUMBER,
  MADE_IN_USA
}
