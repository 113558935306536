import React, {useMemo} from "react";
import Select, {components} from "react-select";
import {FONT_FAMILY_REGULAR} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {ResetButton} from "../_shared/ResetButton";
import {NatDownChevron} from "../../../../_shared/icon/icons";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <NatDownChevron />
    </components.DropdownIndicator>
  );
};

export const InfoSetColumnSelectFilter = (columnProps: any) => {
  const {id, preFilteredRows, filterValue, setFilter} = columnProps.column;

  const options = useMemo(() => {
    const options: {label: string; value: string}[] = [];
    options.push({
      label: "All",
      value: "",
    });
    preFilteredRows.forEach((row: any) => {
      if (!options.find((o: any) => o.value === row.values[id])) {
        options.push({
          label: row.values[id],
          value: row.values[id],
        });
      }
    });
    return options.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }, [id, preFilteredRows]);

  const selected =
    options.find((o) => o.value === filterValue) ??
    options.find((o) => o.value === "");

  return (
    <>
      <Select
        maxMenuHeight={300}
        components={{DropdownIndicator}}
        styles={{
          container: (provided) => ({
            ...provided,
            minWidth: "8rem",
            fontFamily: FONT_FAMILY_REGULAR,
            fontWeight: "normal",
          }),

          // @ts-ignore
          control: (provided) => ({
            ...provided,
            minHeight: "24px",
            height: "24px",
          }),

          valueContainer: (provided) => ({
            ...provided,
            height: "24px",
            padding: "0 6px",
          }),

          input: (provided) => ({
            ...provided,
            margin: "0px",
            color: "transparent",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: "24px",
          }),
        }}
        onChange={(option: any) => {
          setFilter(option?.value || undefined);
        }}
        value={selected}
        options={options}
        defaultValue={options.find((o) => o.value === "")}
      />
      <ResetButton show={!!filterValue} clickEvent={() => setFilter("")} />
    </>
  );
};
