import {
  DEFAULT_OVERHEAD_PROFIT_FRACTION,
  getTextFromPriceMicros,
  IConfiguration,
  IConfigurationSite,
  IProjectInfo,
  Product,
  ProjectPriceMode,
  UpdateProjectInfoRequest,
} from "@natomas-org/core";
import {
  CART_ENABLED_KEY,
  COMPLEX_PRICING_KEY,
  LOCK_DESIGN_KEY,
  PRIMARY_CONTACT_EMAIL_KEY,
  PROJECT_STAGE_KEY,
  UNIT_PRICING_KEY,
} from "./project_model";
import {
  PROJECT_OP_KEY,
  PROJECT_PHASE1_KEY,
  PROJECT_PHASE2_KEY,
  PROJECT_PRICE_MODE_KEY,
} from "./ProjectPriceModeToggle/ControlPanelProjectPriceMode";
import {updateProjectInfoAction} from "../../../../../../../database/firebase/api/project";

export const getInitialControlPanelTabValues = (
  configuration: IConfiguration | null | undefined,
  configurationSite: IConfigurationSite | null | undefined,
  projectSummary: IProjectInfo | null | undefined,
  defaultValues: any
) => {
  let values = defaultValues;
  values[UNIT_PRICING_KEY] = getTextFromPriceMicros(
    Product.getUnitPrice(configuration?.product, {
      excludeInventoryUpgrades: true,
    })
  );
  values[LOCK_DESIGN_KEY] =
    projectSummary?.settings?.design_lock?.is_locked ?? false;
  values[CART_ENABLED_KEY] = projectSummary?.settings?.cart_enabled ?? false;
  values[COMPLEX_PRICING_KEY] =
    projectSummary?.settings?.pricing_mode?.complex_pricing_enabled ?? false;
  values[PRIMARY_CONTACT_EMAIL_KEY] =
    projectSummary?.metadata.assignedPrimaryTeamContactEmail ?? undefined;
  values[PROJECT_OP_KEY] =
    projectSummary?.settings?.pricing_mode?.overhead_profit_fraction ??
    DEFAULT_OVERHEAD_PROFIT_FRACTION;
  values[PROJECT_PRICE_MODE_KEY] =
    projectSummary?.settings?.pricing_mode?.mode ?? ProjectPriceMode.DTC;
  values["stage"] = projectSummary?.status_details.stage ?? "Unknown";
  if (
    projectSummary?.settings?.pricing_mode &&
    values[PROJECT_PRICE_MODE_KEY] === ProjectPriceMode.CostPlus
  ) {
    if (
      "phase1Micros" in projectSummary?.settings?.pricing_mode &&
      projectSummary?.settings?.pricing_mode?.phase1Micros
    ) {
      values[PROJECT_PHASE1_KEY] =
        projectSummary?.settings?.pricing_mode?.phase1Micros;
    }
    if (
      "phase2Micros" in projectSummary?.settings?.pricing_mode &&
      projectSummary?.settings?.pricing_mode?.phase2Micros
    ) {
      values[PROJECT_PHASE2_KEY] =
        projectSummary?.settings?.pricing_mode?.phase2Micros;
    }
  }
  return values;
};

export const saveProjectChanges = (
  configuration: IConfiguration | null | undefined,
  configurationSite: IConfigurationSite | null | undefined,
  projectSummary: IProjectInfo | null | undefined,
  newValues: any
) => {
  if (!projectSummary?.id) {
    return;
  }
  const request: UpdateProjectInfoRequest = {projectId: projectSummary?.id};

  // TODO B2B - Uncomment when we have a way to set unit pricing for cart items
  // const unitPriceMicros = getPriceMicrosFromTextToSave(
  //   newValues[UNIT_PRICING_KEY]
  // );
  // if (
  //   unitPriceMicros != null &&
  //   Product.getUnitPrice(configuration?.product) !== unitPriceMicros
  // ) {
  //   request.unitPriceMicros = unitPriceMicros;
  // }

  if (
    typeof newValues[CART_ENABLED_KEY] === "boolean" &&
    projectSummary?.settings?.cart_enabled !== newValues[CART_ENABLED_KEY]
  ) {
    request.cartEnabled = newValues[CART_ENABLED_KEY];
  }

  console.log("Lock value", newValues[LOCK_DESIGN_KEY]);
  console.log("Lock type", typeof newValues[LOCK_DESIGN_KEY]);

  if (typeof newValues[LOCK_DESIGN_KEY] === "boolean") {
    request.designLocked = newValues[LOCK_DESIGN_KEY] ?? null;
  }
  const pricingPayload: {
    mode: ProjectPriceMode | undefined;
    overheadProfitFraction: undefined | number;
    phase1Micros: undefined | number;
    phase2Micros: undefined | number;
    complex_pricing_enabled: undefined | boolean;
  } = {
    mode: projectSummary?.settings?.pricing_mode?.mode,
    overheadProfitFraction: undefined,
    phase1Micros: undefined,
    phase2Micros: undefined,
    complex_pricing_enabled: undefined,
  };
  if (
    typeof newValues[PROJECT_PRICE_MODE_KEY] === "string" &&
    Object.values(ProjectPriceMode).includes(
      newValues[PROJECT_PRICE_MODE_KEY] as ProjectPriceMode
    ) &&
    projectSummary?.settings.pricing_mode?.mode !==
      newValues[PROJECT_PRICE_MODE_KEY]
  ) {
    pricingPayload.mode = newValues[PROJECT_PRICE_MODE_KEY] as ProjectPriceMode;
  }
  if (
    typeof parseFloat(newValues[PROJECT_OP_KEY]) === "number" &&
    projectSummary?.settings.pricing_mode?.overhead_profit_fraction !==
      parseFloat(newValues[PROJECT_OP_KEY])
  ) {
    pricingPayload.overheadProfitFraction = parseFloat(
      newValues[PROJECT_OP_KEY]
    );
  }

  if (
    typeof newValues[COMPLEX_PRICING_KEY] === "boolean" &&
    projectSummary?.settings?.pricing_mode?.complex_pricing_enabled !==
      newValues[COMPLEX_PRICING_KEY]
  ) {
    pricingPayload.complex_pricing_enabled = newValues[COMPLEX_PRICING_KEY];
  }
  if (
    typeof parseFloat(newValues[PROJECT_PHASE1_KEY]) === "number" &&
    projectSummary?.settings?.pricing_mode &&
    "phase1Micros" in projectSummary?.settings?.pricing_mode &&
    projectSummary?.settings.pricing_mode?.phase1Micros !==
      parseFloat(newValues[PROJECT_PHASE1_KEY])
  ) {
    pricingPayload.phase1Micros = parseFloat(newValues[PROJECT_PHASE1_KEY]);
  }
  console.log("phase1Micros value", newValues[PROJECT_PHASE1_KEY]);

  if (
    typeof parseFloat(newValues[PROJECT_PHASE2_KEY]) === "number" &&
    projectSummary?.settings?.pricing_mode &&
    "phase2Micros" in projectSummary?.settings?.pricing_mode &&
    projectSummary?.settings.pricing_mode?.phase2Micros !==
      parseFloat(newValues[PROJECT_PHASE2_KEY])
  ) {
    pricingPayload.phase2Micros = parseFloat(newValues[PROJECT_PHASE2_KEY]);
  }
  console.log("phase2Micros value", pricingPayload.phase2Micros);

  if (pricingPayload.mode != null) {
    request.pricingMode = {
      // @ts-ignore
      mode: pricingPayload.mode,
      complex_pricing_enabled: pricingPayload?.complex_pricing_enabled,
      overheadProfitFraction: pricingPayload?.overheadProfitFraction,
      phase1Micros: pricingPayload?.phase1Micros,
      phase2Micros: pricingPayload?.phase2Micros,
    };
  }

  const primaryContactEmail = newValues[PRIMARY_CONTACT_EMAIL_KEY];
  if (
    typeof primaryContactEmail === "string" &&
    projectSummary?.metadata.assignedPrimaryTeamContactEmail !==
      primaryContactEmail
  ) {
    request.primaryContactEmail = primaryContactEmail;
  }

  const projectStage = newValues[PROJECT_STAGE_KEY];
  if (projectSummary?.status_details.stage !== projectStage && projectStage) {
    request.stage = projectStage;
  }

  if (Object.keys(request).length > 1) {
    // > 1 because projectId is always included
    return updateProjectInfoAction(request);
  }
  return;
};
