import React from "react";
import {ConditionControllerContainer} from "../styles";
import {
  InfoSetItemOptionCondition,
  InfoSetItemOptionConditionType,
} from "../../../../../../shared/interfaces";
import {
  ConditionMode,
  getSelectionMap,
  hasProductSelector,
  hasSelectionSelector,
  isMultiCondition,
} from "../_shared/helper";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {EnumSelect} from "../../EnumSelect";

const animatedComponents = makeAnimated();

export const ConditionMultiSelectionController = (props: {
  currentType: InfoSetItemOptionConditionType;
  currentSelectedModifiers: any[];
  modifierOptions: any[];
  currentSelectedProducts: any[];
  productOptions: any[];
  update: (condition: InfoSetItemOptionCondition) => void;
  mode: ConditionMode;
}) => {
  const {
    currentType,
    currentSelectedModifiers,
    modifierOptions,
    currentSelectedProducts,
    productOptions,
    update,
    mode,
  } = props;

  const updateConditionTargets = (e: any) => {
    const modifiers = getSelectionMap(currentSelectedModifiers);
    const products = e.map((s: any) => s.value);
    update({
      type: currentType,
      requiredModifiers: modifiers,
      targets: products,
    });
  };

  const updateConditionType = (e: any) => {
    let type: InfoSetItemOptionConditionType = e;
    const modifiers = getSelectionMap(currentSelectedModifiers);
    const products = currentSelectedProducts.map((s: any) => s.value);
    update({
      type: type,
      requiredModifiers: modifiers,
      targets: products,
    });
  };

  const updateConditionRequiredModifiers = (e: any) => {
    const changes: {value: string; label: string}[] = e;
    const modifiers = getSelectionMap(changes);
    const products = currentSelectedProducts.map((s: any) => s.value);
    update({
      type: currentType,
      requiredModifiers: modifiers,
      targets: products,
    });
  };

  return (
    <ConditionControllerContainer>
      {hasProductSelector(mode) && (
        <Select
          styles={{
            container: (styles) => ({
              ...styles,
              minWidth: "10rem",
              flexGrow: 1,
            }),
          }}
          options={productOptions}
          components={animatedComponents}
          placeholder="Products..."
          value={currentSelectedProducts}
          onChange={updateConditionTargets}
          isSearchable={true}
          isClearable={true}
          isMulti
        />
      )}
      {isMultiCondition(mode) && "AND"}
      {currentSelectedModifiers?.length > 1 && (
        <EnumSelect
          enumTarget={InfoSetItemOptionConditionType}
          onChange={updateConditionType}
          onCommitChange={null}
          value={currentType}
          widthRemOverride={6}
        />
      )}
      {hasSelectionSelector(mode) && (
        <Select
          styles={{
            container: (styles) => ({
              ...styles,
              minWidth: "10rem",
              flexGrow: 1,
            }),
          }}
          options={modifierOptions}
          components={animatedComponents}
          placeholder="Selections..."
          value={currentSelectedModifiers}
          onChange={updateConditionRequiredModifiers}
          isSearchable={true}
          isClearable={true}
          isMulti
        />
      )}
    </ConditionControllerContainer>
  );
};
