import {AxiosInstance} from "axios";
import {
  CreateEstimateLineItemSchema,
  CreateEstimateSchema,
  EstimatesApi,
} from "@natomas-org/villa-nexus-client";
import {getAxiosInstance} from "../setup";
import {EstimateTables} from "../../components/estimate/CustomerEstimate/tables";

export interface IEstimateRangeItem {
  name: string;
  notes: string | null;
  bucket: string;
  price_low: number;
  price_high: number;
  cost_low?: number;
  cost_high?: number;
  uuid?: string;
  gross_profit_low?: number;
  gross_profit_high?: number;
  gross_margin_high?: number;
  gross_margin_low?: number;
  locked?: boolean;
}

export interface IEstimateProjectRangeSummary {
  total_price_low: number;
  total_price_high: number;
  total_cost_low: number;
  total_cost_high: number;
  total_gross_profit_low: number;
  total_gross_profit_high: number;
  total_gross_margin_low: number;
  total_gross_margin_high: number;
}

// TODO: AD Import this form villa-nexus-client
// Note the plurality of this interface!
// A list of ADU objects and a summary that summarizes all the ADUs.
export interface IEstimateRangeAdus {
  adus: IEstimateRangeAdu[];
  summary: IEstimateProjectRangeSummary;
}

// TODO: AD Import this from villa-nexus-client
// The ADU object that's returned by the nexus
export interface IEstimateRangeAdu {
  adu_id: string;
  base_cost: number;
  base_price: number;
  description: string;
  factory_id: string;
  product_id: string;
  selections: IEstimateRangeAduSelection[];
  summary: IEstimateProjectRangeSummary;
  tax: number;
  title: string;
}

// TODO: AD Import this from villa-nexus-client
export interface IEstimateRangeAduSelection {
  cost: number;
  id: string;
  notes: string;
  price: number;
  title: string;
}

export interface IEstimateProjectRangeBucket {
  summary: IEstimateProjectRangeSummary;
  line_items: IEstimateRangeItem[];
  adus?: IEstimateRangeAdu[];
}

export interface IEstimateProjectRange {
  summary: IEstimateProjectRangeSummary;
  buckets: {
    [key: string]: IEstimateProjectRangeBucket;
  };
}

export interface IEstimateProjectRangeProperties {
  disableMargin: boolean;
  disableCost: boolean;
}

export class EstimateService {
  private axiosInstance;
  private client;
  getLatestEstimate: (
    pid: string,
    properties?: IEstimateProjectRangeProperties
  ) => Promise<IEstimateProjectRange>;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new EstimatesApi(undefined, undefined, this.axiosInstance);
    this.getLatestEstimate = async (
      pid: string,
      properties?: IEstimateProjectRangeProperties
    ) => {
      const {data} = await this.client.getLatestEstimatedProjectRange(
        pid,
        properties?.disableCost ?? false,
        properties?.disableMargin ?? false
      );
      return data;
    };
  }

  async getAllByProjectId(pid: string) {
    if (!pid) {
      throw new Error("pid is required");
    }
    return this.client.getEstimatesByProjectId(pid);
  }

  async createByDefaulting(pid: string) {
    if (!pid) {
      throw new Error("pid is required");
    }
    const schema: CreateEstimateSchema = {
      estimate_line_items: [],
    };
    return this.client.createEstimate(pid, schema, true);
  }

  async createByEstimateEntries(pid: string, entries: IEstimateRangeItem[]) {
    if (!pid) {
      throw new Error("pid is required");
    }
    if (!entries) {
      throw new Error("entries is required");
    }
    const filteredEntries = entries.filter((entry) => {
      // Skip all locked Home Design items
      if (entry.bucket === EstimateTables.HD.key) {
        if (entry.locked) {
          return false;
        }
      }
      return true;
    });
    console.log("toSave", filteredEntries);
    const estimateLineItemSchema: CreateEstimateLineItemSchema[] =
      filteredEntries.map((entry) => {
        return {
          name: entry.name ?? "",
          bucket: entry.bucket,
          notes: entry.notes ?? "",
          price_low: entry.price_low ?? 0,
          price_high: entry.price_high ?? 0,
          cost_low: entry.cost_low ?? 0,
          cost_high: entry.cost_high ?? 0,
        };
      });
    const schema: CreateEstimateSchema = {
      estimate_line_items: estimateLineItemSchema,
    };
    return this.client.createEstimate(pid, schema);
  }
}

export const getEstimateService = async () => {
  const axiosInstance = await getAxiosInstance();
  return new EstimateService(axiosInstance);
};
