import {IFactoryInventoryModifierInfo} from "../interfaces";

export enum FactoryInventoryPricingEditorKey {
  UNIT_COST = "unitCost",
  UNIT_PRICE = "unitPrice",
  SALES_TAX = "salesTaxPrice",
}

export interface IFactoryInventoryPricingEditor {
  [FactoryInventoryPricingEditorKey.UNIT_COST]?: number;
  [FactoryInventoryPricingEditorKey.UNIT_PRICE]?: number;
  [FactoryInventoryPricingEditorKey.SALES_TAX]?: number;
}

export interface IFactoryInventoryPricingEditorValues {
  modifiers: {[id: string]: IFactoryInventoryModifierInfo};
  product: IFactoryInventoryPricingEditor;
}
