import styled from "styled-components";
import {DIVIDER_COLOR, ERROR_COLOR, VALID_COLOR} from "../../colors";

interface StyledAddressInputProps {
  hasError: boolean;
}

export const StyledNatAddressInput = styled.input<StyledAddressInputProps>`
  z-index: inherit;
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
  border: 1px solid
    ${(props) => (props?.hasError ? ERROR_COLOR : DIVIDER_COLOR)};
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 20px;
  background: 0 0;
`;

export const StyledAddressInput = styled.input<StyledAddressInputProps>`
  height: 100%;
  width: 100%;
  border: ${(props: StyledAddressInputProps) =>
    props.hasError ? "2px solid" : "1px solid"};
  border-radius: 2px;
  border-color: ${(props: StyledAddressInputProps) =>
    props.hasError ? ERROR_COLOR : "default"};
  z-index: inherit;
  padding-right: 2rem;
  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

interface DescriptionProps {
  show: boolean;
}

export const Description = styled.div<DescriptionProps>`
  position: relative;
  display: ${(props: DescriptionProps) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  margin-top: 0.25rem;
`;

export const ResolutionDescription = styled.div<{status: string}>`
  display: flex;
  flex-direction: row;
  p {
    margin: 0 0 0 0.5rem;
    color: ${(props) => (props.status === "error" ? ERROR_COLOR : VALID_COLOR)};
    font-size: 1rem;
  }
`;
