import React from "react";
import {GRAY_BORDER, VILLA_APPLE_GREEN} from "../../colors";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";

export type NatSwitchOption = {
  id: string | number;
  label: string;
  activeColor?: string;
};

interface NatSwitchProps {
  options: NatSwitchOption[];
  activeOption: NatSwitchOption | undefined;
  onOptionChange: (selectedOption: NatSwitchOption) => void;
  activeColor?: string;
  inactiveColor?: string;
}

const NatSwitch: React.FC<NatSwitchProps> = ({
  options,
  activeOption,
  activeColor = VILLA_APPLE_GREEN,
  inactiveColor = GRAY_BORDER,
  onOptionChange = (option) => {},
}) => {
  if (!activeOption) return null;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        columnGap: "0.25rem",
      }}
    >
      {options.map((option) => (
        <button
          key={option.id}
          onClick={() => onOptionChange(option)}
          style={{
            backgroundColor:
              option.id === activeOption.id
                ? option?.activeColor ?? activeColor
                : inactiveColor,
            color: "white",
            padding: "10px",
            borderRadius: "3px",
            border: "none",
            cursor: "pointer",
            fontSize: "14px",
            outline: "none",
            fontFamily: FONT_FAMILY_BOLD,
          }}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default NatSwitch;
