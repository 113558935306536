import React from "react";
import {StepCardContainer, StepCardNumberContainer} from "./styles";
import {NatBulletPoint} from "../../../../../_shared/icon/icons";
import {VILLA_APPLE_GREEN} from "../../../../../_shared/colors";

export const StepCard = (props: {index: number; text: string}) => {
  const BULLET_SIZE = "1.475rem";
  const {index, text} = props;
  return (
    <StepCardContainer>
      <StepCardNumberContainer size={BULLET_SIZE}>
        {index}
      </StepCardNumberContainer>
      <div style={{position: "absolute", zIndex: 1}}>
        <NatBulletPoint size={BULLET_SIZE} color={VILLA_APPLE_GREEN} />
      </div>
      <div
        style={{
          paddingLeft: BULLET_SIZE,
          marginLeft: "0.5rem",
          textAlign: "left",
        }}
      >
        {text}
      </div>
    </StepCardContainer>
  );
};
