import styled from "styled-components";
import {
  BLACK,
  GRAY,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
  WHITE,
} from "../../../../../../../../../../../_shared/colors";

export const StudioEditorModifierButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  background-color: ${VILLA_APPLE_GREEN_LIGHT_1};
  border: 2px solid ${VILLA_APPLE_GREEN};
  border-radius: 8px;
  overflow: hidden;
`;

export const StudioEditorModifierButtonInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 12px;
`;

export const StudioEditorModifierButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const StudioEditorModifierButtonTileContainer = styled.div<{
  isSwatch?: boolean;
}>`
  position: relative;
  ${(props) =>
    props?.isSwatch
      ? `
  height: 198px;
  width: 198px;
  border-radius: 8px;
  `
      : `
  height: 48px;
  width: 48px;
  `}
  overflow: hidden;
`;

export const StudioEditorModifierContainer = styled.div<{showBorder?: boolean}>`
  border: ${(props) => props?.showBorder && `1px solid ${BLACK}`};
  border-radius: 8px;
  padding: ${(props) => props?.showBorder && `8px`};
  background-color: ${WHITE};
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
`;

export const StudioEditorModifierDescription = styled.div`
  font-size: 12px;
  color: ${GRAY};
`;

export const StudioEditorModifierColorSwatchContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
