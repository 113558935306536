import styled from "styled-components";
import {isMobile} from "../../../../_shared/navigation";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../SSDDashboardView/styles/globals";
import {FULL_SCREEN_HEIGHT_REM} from "../../HCPDashboardLayout/HCPUnitView/DesignSelections/constants";

export const SitePlanContainer = styled.div<{heightInherit: boolean}>`
  height: ${(props) =>
    isMobile()
      ? "unset"
      : props.heightInherit
      ? "inherit"
      : "calc(100% - 85px)"};
  width: 100%;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;
`;

export const BottomContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-wrap: wrap;
`;

export const FlexContainer = styled.div`
  position: relative;
  width: 50%;
  height: 600px;
  text-align: center;
  padding: 10px;
  min-width: 400px;
  flex-grow: 2;
`;

export const WaitingContent = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;

export const SitePlanButtonContainer = styled.div<{hideElement: boolean}>`
  padding-top: 6px;
  display: ${(props) => (props.hideElement === true ? "none" : "flex")};
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-shrink: 2;
  column-gap: 12px;
`;

export const SitePlanButton = styled.button<{primary: boolean}>`
  background: ${(props) => (props.primary ? "black" : "white")};
  color: ${(props) => (!props.primary ? "black" : "white")};
  font-family: ${(props) =>
    props.primary ? FONT_FAMILY_BOLD : FONT_FAMILY_REGULAR};
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const SitePlanImagePreview = styled.div<{isMobile: boolean}>`
  position: relative;
  min-height: 800px;
  // height: \${(props) => (props.isMobile === true ? "60vh" : "600px")};
  //width: 100%;
  overflow: hidden;
  page-break-after: always;
  break-after: always;
`;

export const SitePlanComponentContainer = styled.div<{
  breakBefore?: boolean;
  isGeneratingPDF?: boolean;
}>`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 25px;
  page-break-before: ${(props) =>
    props.breakBefore === true ? "always" : "avoid"};
  break-before: ${(props) => (props.breakBefore === true ? "page" : "avoid")};
  page-break-inside: avoid;
  break-inside: avoid;
  page-break-after: always;
  break-after: page;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: ${(props) =>
    props.isGeneratingPDF === true && FULL_SCREEN_HEIGHT_REM
      ? `${FULL_SCREEN_HEIGHT_REM * 0.9}rem`
      : undefined};
  overflow: ${(props) =>
    props.isGeneratingPDF === true ? "hidden" : undefined};
`;

export const SiteAddressContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
`;

export const SiteAddressImage = styled.img`
  width: 300px;
  max-width: 100%;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
`;

export const MappingPreviewContainer = styled.div`
  position: relative;
  height: 540px;
  width: 100%;
  overflow: hidden;
`;
