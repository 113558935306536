import {
  BedBathCountFilterValue,
  FilterType,
  ICatalogFilter,
} from "../../../../../_shared/catalog/filter/types";
import React, {useMemo} from "react";
import {useCatalogSession} from "../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {IndividualFilterTitle} from "../../CatalogBar/styles";
import {NatButton} from "../../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";
import {
  changeFilter,
  deleteFilterByType,
} from "../../../../../_shared/catalog/filter/logic";
import {BedBathCountButtonContainer, BedBathCountContainer} from "./styles";

export const BathCountFilterSelector = () => {
  const {filters} = useCatalogSession();

  const anyBathroomFilter: ICatalogFilter = {
    type: FilterType.Bathroom,
    label: "Any",
    value: BedBathCountFilterValue.Any,
  };
  const oneBathroomFilter: ICatalogFilter = {
    type: FilterType.Bathroom,
    label: "1+",
    value: BedBathCountFilterValue.OnePlus,
  };
  const twoBathroomFilter: ICatalogFilter = {
    type: FilterType.Bathroom,
    label: "2+",
    value: BedBathCountFilterValue.TwoPlus,
  };
  const bathroomFilterOptions = [
    anyBathroomFilter,
    oneBathroomFilter,
    twoBathroomFilter,
  ];

  const noBedroomFilters: boolean = useMemo(() => {
    return !filters.find((f: ICatalogFilter) => f.type === FilterType.Bathroom);
  }, [filters]);

  const activeOneBathroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bathroom && f.value === oneBathroomFilter.value
    );
  }, [filters, oneBathroomFilter.value]);

  const activeTwoBathroomFilter: boolean = useMemo(() => {
    return !!filters.find(
      (f: ICatalogFilter) =>
        f.type === FilterType.Bathroom && f.value === twoBathroomFilter.value
    );
  }, [filters, twoBathroomFilter.value]);

  const setBathroomFilter = (filter: ICatalogFilter) => {
    switch (filter.value) {
      case BedBathCountFilterValue.Any:
        deleteFilterByType(FilterType.Bathroom);
        break;
      case BedBathCountFilterValue.OnePlus:
        changeFilter(oneBathroomFilter);
        break;
      case BedBathCountFilterValue.TwoPlus:
        changeFilter(twoBathroomFilter);
        break;
      default:
        break;
    }
  };

  return (
    <BedBathCountContainer style={{paddingTop: "1rem"}}>
      <IndividualFilterTitle>Bathrooms</IndividualFilterTitle>
      <BedBathCountButtonContainer>
        {bathroomFilterOptions?.map((bathroomFilter, idx) => {
          let selected = false;
          switch (bathroomFilter.value) {
            case BedBathCountFilterValue.Any:
              selected = noBedroomFilters;
              break;
            case BedBathCountFilterValue.OnePlus:
              selected = activeOneBathroomFilter;
              break;
            case BedBathCountFilterValue.TwoPlus:
              selected = activeTwoBathroomFilter;
              break;
            default:
              break;
          }
          return (
            <NatButton
              key={`bath-button-${idx}`}
              label={bathroomFilter.label}
              option={
                selected
                  ? StyleOption.SELECTED_TOGGLE_ON
                  : StyleOption.SELECTED_TOGGLE_OFF
              }
              clickEvent={() => {
                setBathroomFilter(bathroomFilter);
              }}
              size={NatSize.SMALL}
            />
          );
        })}
      </BedBathCountButtonContainer>
    </BedBathCountContainer>
  );
};
