import {
  BLACKLINES_PDF_DOCUMENT_INFO,
  DECOR_CONFIRMATION_PDF_DOCUMENT_INFO,
  IPDFDocumentInfo,
  IRecipient,
  SITE_PLANS_PDF_DOCUMENT_INFO,
  StringParameter,
  TEAM_MEMBERS,
} from "@natomas-org/core";

export interface ISendDocusignProps {
  title: string;
  defaultFileName: string;
  pdfDocument: IPDFDocumentInfo;
  subject: string;
  body: string;
  senderEmail: string;
  defaultCCList: IRecipient[];
}

const getIRecipientFromEmail = (email: string): IRecipient => {
  return {
    email: TEAM_MEMBERS[email].email ?? email,
    name: `${TEAM_MEMBERS[email].first_name} ${TEAM_MEMBERS[email].last_name}`,
  } as IRecipient;
};

const KATE_RECIPIENT: IRecipient = getIRecipientFromEmail(
  "knixa@villahomes.com"
);
const AARON_RECIPIENT: IRecipient = getIRecipientFromEmail(
  "amatthews@villahomes.com"
);
const MARLY_RECIPIENT: IRecipient = getIRecipientFromEmail(
  "mcarlisle@villahomes.com"
);
const SARAH_RECIPIENT: IRecipient = getIRecipientFromEmail(
  "sarah@villahomes.com"
);
const LINDSEY_RECIPIENT: IRecipient = getIRecipientFromEmail(
  "lnegro@villahomes.com"
);

export const SEND_SITE_PLAN: ISendDocusignProps = {
  title: "Send Site Plan Docusign",
  defaultFileName: "Site Plan",
  pdfDocument: SITE_PLANS_PDF_DOCUMENT_INFO,
  subject: `${StringParameter.PROJECT_ID} - ${StringParameter.CUSTOMER_LAST_NAME} | Site plan for your home`,
  body: `Hi ${StringParameter.CUSTOMER_FIRST_NAME},\n\nPlease find your site plan attached for your review. Your initials and signature approves our usage of this plan in the permit plan set that will be submitted to your jurisdiction and used in the construction of your home.\n\nPlease let me know if you have any questions. Thank you!`,
  senderEmail: "ccteam@villahomes.com",
  defaultCCList: [getIRecipientFromEmail("precon@villahomes.com")],
};
export const SEND_BLACKLINES: ISendDocusignProps = {
  title: "Send Blacklines Docusign",
  defaultFileName: "Blacklines",
  pdfDocument: BLACKLINES_PDF_DOCUMENT_INFO,
  subject: `${StringParameter.PROJECT_ID} - ${StringParameter.CUSTOMER_LAST_NAME} | Blacklines`,
  body: `Hello ${StringParameter.CUSTOMER_FIRST_NAME},\n\nPlease find your blacklines attached. Your initials and signature confirm your design choices and the approved blacklines will be used in the ordering of your home.\n\nPlease let me know if you have any questions. Thank you!\n\n`,
  senderEmail: "ccteam@villahomes.com",
  defaultCCList: [
    getIRecipientFromEmail("fashraf@villahomes.com"),
    getIRecipientFromEmail("productoperations@villahomes.com"),
    getIRecipientFromEmail("precon@villahomes.com"),
  ],
};
export const SEND_DECOR_CONFIRMATION: ISendDocusignProps = {
  title: "Send Decor Confirmation Docusign",
  defaultFileName: "Decor Confirmation",
  pdfDocument: DECOR_CONFIRMATION_PDF_DOCUMENT_INFO,
  subject: `${StringParameter.PROJECT_ID} | Decor Confirmation`,
  body: `Hi ${StringParameter.CUSTOMER_FIRST_NAME},\n\nPlease sign your decor confirmation.\n\nThank you,\n`,
  senderEmail: "ccteam@villahomes.com",
  defaultCCList: [KATE_RECIPIENT],
};

export const SEND_DOCUSIGN_SELECTION = [
  {label: SEND_SITE_PLAN.title, value: SEND_SITE_PLAN},
  {label: SEND_BLACKLINES.title, value: SEND_BLACKLINES},
  {label: SEND_DECOR_CONFIRMATION.title, value: SEND_DECOR_CONFIRMATION},
];

export const PDF_TO_DOCUSIGN_PROPS: {
  [document_id: string]: ISendDocusignProps;
} = {
  [DECOR_CONFIRMATION_PDF_DOCUMENT_INFO.document_id]: SEND_DECOR_CONFIRMATION,
  [SITE_PLANS_PDF_DOCUMENT_INFO.document_id]: SEND_SITE_PLAN,
  [BLACKLINES_PDF_DOCUMENT_INFO.document_id]: SEND_BLACKLINES,
};
