import React from "react";
import {FactoryInventoryEditorToggle} from "../../../_shared/FactoryInventoryEditorToggle";
import {FactoryInventoryEditingToggle} from "../../../_shared/FactoryInventoryEditingToggle";
import {NatButton} from "../../../../../../../../../../_shared/generics/button";
import {
  NatSize,
  StyleOption,
} from "../../../../../../../../../../_shared/generics/_shared";
import {setProductInInventoryEditor} from "../../../../../../../../../../_shared/slices/CatalogSlice/AdminFactoryInventoryEditorSlice";
import FactoryManagementHeader from "../../../../../../_shared/FactoryManagementHeader";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import {DIVIDER_COLOR} from "../../../../../../../../../../_shared/colors";

interface CatalogEditorHeaderProps {
  resetAllChanges?: () => void;
  saveChanges?: () => void;
  hasEdits: boolean;
  hideEditingToggle?: boolean;
  additionalControls?: JSX.Element;
}

const LeftAlignedGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: flex-start;
  align-items: center;
`;

const RightAlignedGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: flex-end;
  align-items: center;
`;

const AdditionalControlsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AdditionalControlsSpacer = styled.div`
  width: 1px;
  background-color: ${DIVIDER_COLOR};
  height: 40px;
  margin: 0 4px;
`;

const CatalogEditorHeader = (props: CatalogEditorHeaderProps) => {
  const {
    resetAllChanges,
    saveChanges,
    hasEdits,
    additionalControls,
    hideEditingToggle,
  } = props;
  const dispatch = useDispatch();

  return (
    <FactoryManagementHeader
      children={
        <AdditionalControlsContainer>
          <LeftAlignedGroup>
            <FactoryInventoryEditorToggle locked={hasEdits} />
            {!hideEditingToggle && (
              <>
                <AdditionalControlsSpacer />
                <FactoryInventoryEditingToggle />
              </>
            )}
            {!!additionalControls && (
              <>
                <AdditionalControlsSpacer />
                {additionalControls}
              </>
            )}
          </LeftAlignedGroup>
          <RightAlignedGroup>
            <NatButton
              hidden={!hasEdits || !resetAllChanges}
              label={"Abandon All Changes"}
              size={NatSize.SMALL}
              option={StyleOption.DESTRUCTIVE}
              clickEvent={resetAllChanges}
            />
            <NatButton
              hidden={!hasEdits || !saveChanges}
              label={"Save"}
              size={NatSize.SMALL}
              option={StyleOption.PRIMARY_BLACK}
              clickEvent={saveChanges}
              spinnerEnabled={true}
            />
            <NatButton
              hidden={hasEdits}
              label={"Exit"}
              size={NatSize.SMALL}
              option={StyleOption.PRIMARY_BLACK}
              clickEvent={() => {
                dispatch(setProductInInventoryEditor(undefined));
              }}
            />
          </RightAlignedGroup>
        </AdditionalControlsContainer>
      }
    />
  );
};

export default CatalogEditorHeader;
