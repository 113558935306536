import React, {useEffect} from "react";
import {ProductOptionKeyStructure} from "../../../../_models/productOption";
import {ProductOptionKey} from "../../../../_shared/constants/ProductOption";
import {useFormik} from "formik";
import {saveOptionKeyToDB} from "../../../../_shared/components/ProductOptions/ProductOptionHelper";
import {OptionEditor} from "../styles";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {
  FormInput,
  GenericInputContentContainer,
  InputTitle,
  NatStyledForm,
} from "../../../../_shared/styles/AdminStyles";

export const OptionKeyEditor = (props: {
  currentKey: ProductOptionKey | null;
  setCurrentKey: any;
}) => {
  const {currentKey, setCurrentKey} = props;

  const initialValues = {};
  Object.keys(ProductOptionKeyStructure).forEach((key) => {
    // @ts-ignore
    initialValues[key] = "";
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (currentKey != null) {
        const valueToSave = Object.assign({id: currentKey.id}, values);
        saveOptionKeyToDB(valueToSave).then(() => setCurrentKey(null));
      }
    },
  });

  useEffect(() => {
    if (currentKey == null) {
      formik.resetForm();
    } else {
      for (const key in ProductOptionKeyStructure) {
        if (key !== "id") {
          // Skip id
          // @ts-ignore
          if (currentKey[key] != null) {
            // @ts-ignore
            formik.setFieldValue(key, currentKey[key]);
          } else {
            formik.setFieldValue(key, "");
          }
        }
      }
    }
  }, [currentKey]);

  const getFormTitle = () => {
    if (currentKey?.id) {
      return "Internal id";
    } else {
      return "";
    }
  };

  return (
    <OptionEditor>
      <NatStyledForm onSubmit={formik.handleSubmit}>
        {currentKey?.id && (
          <>
            <InputTitle bold={true} title={getFormTitle()} />
            <GenericInputContentContainer>
              {currentKey?.id}
            </GenericInputContentContainer>
          </>
        )}
        {Object.keys(ProductOptionKeyStructure).map((key) => {
          return (
            <>
              <InputTitle bold={true} title={key} />
              <GenericInputContentContainer>
                <FormInput
                  id={key}
                  name={key}
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values[key]}
                />
              </GenericInputContentContainer>
            </>
          );
        })}
        <NatButton
          style={{marginTop: "0.5rem"}}
          option={StyleOption.PRIMARY_ALT}
          label={"Submit"}
          type="submit"
          trackingId={"admin-option-key-submit-change"}
        />
      </NatStyledForm>
    </OptionEditor>
  );
};
