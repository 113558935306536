import {executeRequest} from "../APIExecutor";

const updateProductCatalogTagPath = "/tags/v1/updateProductCatalogTag";

export const updateProductCatalogTag = (
  tagId: string | null,
  tagLabel: string
) => {
  return executeRequest(updateProductCatalogTagPath, {
    tagId,
    tagLabel,
  }).then((data) => {
    console.log(data);
  });
};
