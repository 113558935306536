import {useDispatch} from "react-redux";
import {
  setProductGroupId,
  setProductGroupVersionId,
  setProductId,
} from "../../../slices/CatalogSlice/CatalogSessionSlice";
import {useCallback} from "react";
import {useCatalogSessionState} from "./useCatalogSessionState";
import {
  HistoryChangeType,
  setMultipleURLQueryKeyValues,
} from "../../../navigation/_helpers";
import {
  URL_PARAM_KEY_PRODUCT_GROUP_ID,
  URL_PARAM_KEY_UNIT_ID,
} from "@natomas-org/service";
import {IProduct} from "@natomas-org/core";

export const useCatalogSessionDispatcher = () => {
  const {selectedProductGroupId, selectedProductId} = useCatalogSessionState();
  const dispatch = useDispatch();

  const selectProductById: (id: string | null) => void = useCallback(
    (productId: string | null) => {
      if (selectedProductId !== productId) {
        dispatch(setProductId(productId));
        setMultipleURLQueryKeyValues(
          [{key: URL_PARAM_KEY_UNIT_ID, value: productId}],
          HistoryChangeType.PUSH
        );
      }
    },
    [dispatch, selectedProductId]
  );

  const selectProduct: (product?: IProduct) => void = useCallback(
    (product?: IProduct) => {
      if (!product) {
        return;
      }
      const {id: productId, productGroupId, versionId} = product;
      if (!productId || !productGroupId) {
        return;
      }
      if (selectedProductId !== productId) {
        dispatch(setProductId(productId));
        dispatch(setProductGroupId(productGroupId));
        dispatch(setProductGroupVersionId(versionId ?? null));
        setMultipleURLQueryKeyValues(
          [
            {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: productGroupId},
            {key: URL_PARAM_KEY_UNIT_ID, value: productId},
          ],
          HistoryChangeType.PUSH
        );
      }
    },
    [dispatch, selectedProductId]
  );

  const selectFactoryById: (id: string | null) => void = useCallback(
    (factoryId: string | null) => {
      if (selectedProductGroupId !== factoryId) {
        dispatch(setProductId(null));
        dispatch(setProductGroupId(factoryId));
        dispatch(setProductGroupVersionId(null));
        setMultipleURLQueryKeyValues(
          [{key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: factoryId}],
          HistoryChangeType.PUSH
        );
      }
    },
    [dispatch, selectedProductGroupId]
  );

  const resetCatalogSessionSelections: () => void = useCallback(() => {
    dispatch(setProductId(null));
    dispatch(setProductGroupId(null));
    dispatch(setProductGroupVersionId(null));
    setMultipleURLQueryKeyValues(
      [
        {key: URL_PARAM_KEY_PRODUCT_GROUP_ID, value: null},
        {key: URL_PARAM_KEY_UNIT_ID, value: null},
      ],
      HistoryChangeType.PUSH
    );
  }, [dispatch]);

  return {
    selectProduct,
    selectProductById,
    selectFactoryById,
    resetCatalogSessionSelections,
  };
};
