import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../../../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK} from "../../../../../_shared/colors";

export const StudioCategorySummarySectionTitle = styled.div`
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding-bottom: 1.25rem;
  color: ${BLACK};
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const StudioCategorySummarySection = styled.div`
  padding: 0 1.25rem;
  margin-bottom: 28rem;
`;
