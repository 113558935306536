import React, {useEffect, useState} from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import {isMobile} from "../../navigation";
import PuffLoader from "react-spinners/PuffLoader";
import {IImageInfo, IImageUrl, ImageCropMode} from "@natomas-org/core";
import {NatFullscreenIcon} from "../../icon/icons";
import {FullScreenIconBackgroundContainer} from "../../../map/views/MapMainView/components/MapContentSidebar/MapCatalogContainer/styles";
import {useImage} from "../../hooks/useImage";

const omit = (obj: any, omitKey: any) =>
  Object.keys(obj).reduce((result: any, key) => {
    if (key !== omitKey) {
      result[key] = obj[key];
    }
    return result;
  }, {});

export enum ImageQuality {
  DEFAULT,
  X_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  X_LARGE,
  ORIGINAL,
}

export interface ISmartImageProps {
  imageId?: string | null;
  smallImageURL?: string | null;
  imageURL?: string | null;
  hideLoading?: any;
  blur?: any;
  size?: any;
  quality?: ImageQuality;
  shouldNotFade?: any;
  caption?: any;
  hideCaption?: any;
  style?: React.CSSProperties;
  onFullscreenClick?: any;
  onClick?: any;
  width?: any; // not currently used
  forceWidth?: string;
  forceHeight?: string;
  height?: any;
  preserveAspectRatio?: any;
  alt?: any;
  positionAbsolute?: boolean;
}

export const SmartImage = (props: ISmartImageProps) => {
  const {
    imageId,
    hideLoading,
    caption,
    hideCaption,
    style,
    imageURL,
    smallImageURL,
    size,
    quality,
    positionAbsolute,
    forceWidth,
    forceHeight,
  } = props;
  const image = useImage(imageId);
  const [smallImageLoaded, setSmallImageLoaded] = useState(false);
  const [missingImageId, setMissingImageId] = useState<
    string | undefined | null
  >(null);

  useEffect(() => {
    setSmallImageLoaded(false);
    if (image == null) {
      setMissingImageId(null);
    } else if (missingImageId != null && missingImageId !== imageId) {
      setMissingImageId(null);
    }
  }, [image, imageId]);

  const shouldFadeImage = missingImageId === imageId;

  if (image == null && !imageURL) {
    if (hideLoading === true) {
      return null;
    }
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-22px, -22px)",
          textAlign: "center",
        }}
      >
        <PuffLoader size="45px" />
      </div>
    );
  }

  let filteredProps = omit(props, "imageId");
  filteredProps = omit(filteredProps, "imageURL");
  filteredProps = omit(filteredProps, "caption");
  filteredProps = omit(filteredProps, "image");
  filteredProps = omit(filteredProps, "size");
  filteredProps = omit(filteredProps, "blur");
  filteredProps = omit(filteredProps, "hideLoading");
  filteredProps = omit(filteredProps, "shouldNotFade");
  filteredProps = omit(filteredProps, "hideCaption");
  filteredProps = omit(filteredProps, "onFullscreenClick");
  filteredProps = omit(filteredProps, "quality");

  if (filteredProps.width == null) {
    filteredProps.width = "100%";
  }
  if (filteredProps.height == null && positionAbsolute !== false) {
    filteredProps.height = "100%";
  }
  if (props?.style?.height !== "unset") {
    filteredProps.height = null;
  }
  if (filteredProps.style) {
    filteredProps.style = Object.assign(
      {},
      filteredProps.style,
      {
        height: !filteredProps.height ? "100%" : filteredProps.height,
        width: null,
        position: positionAbsolute === false ? "relative" : "absolute",
      },
      !!forceWidth ? {minWidth: forceWidth, maxWidth: forceWidth} : {},
      !!forceHeight ? {minHeight: forceHeight, maxHeight: forceHeight} : {}
    );
  }

  if (
    (image?.crop_mode === ImageCropMode.CONTAIN ||
      image?.crop_mode === ImageCropMode.COVER) &&
    style?.objectFit == null
  ) {
    const existingStyle = style ?? {};
    filteredProps.style = {
      ...existingStyle,
      objectFit:
        image?.crop_mode === ImageCropMode.CONTAIN ? "contain" : "cover",
      height:
        !props.forceHeight && props.style?.height === "unset"
          ? "unset"
          : "100%",
    };
  }

  let loadingImageURL = smallImageURL;
  let mainImageURL = imageURL;
  if (imageId && image) {
    loadingImageURL = image["100"]?.url;
    mainImageURL = image["2500"]?.url;
    if (size != null && image[size as keyof IImageInfo] != null) {
      mainImageURL = (image[size as keyof IImageInfo] as IImageUrl)?.url;
    } else if (quality) {
      switch (quality) {
        case ImageQuality.X_SMALL:
          mainImageURL = image["300"]?.url;
          break;
        case ImageQuality.SMALL:
          mainImageURL = image["500"]?.url;
          break;
        case ImageQuality.MEDIUM:
          mainImageURL = image["750"]?.url;
          break;
        case ImageQuality.LARGE:
          mainImageURL = image["1500"]?.url;
          break;
        case ImageQuality.X_LARGE:
          mainImageURL = image["2500"]?.url;
          break;
        case ImageQuality.ORIGINAL:
          mainImageURL = image["original"]?.url;
          break;
        default:
          mainImageURL = image["1500"]?.url;
          break;
      }
    }
  }

  if (loadingImageURL == null && mainImageURL == null) {
    return null;
  }

  const allowCaptions = !isMobile() && hideCaption !== true;
  if (props.blur === false) {
    let heightProp = "inherit";
    if (!!props.forceHeight) {
      heightProp = props.forceHeight;
    }
    filteredProps.height = heightProp;
    return (
      // @ts-ignore
      <ProgressiveImage
        src={mainImageURL}
        style={{height: heightProp}}
        // @ts-ignore
        css={`
          height: inherit;
        `}
        placeholder={loadingImageURL}
      >
        {(src: any, loading: boolean) => {
          const isStillLoading =
            loading && (props.shouldNotFade !== true || shouldFadeImage);
          const largeImageView = (
            <img
              className={
                "fullDiv image-fade " +
                (isStillLoading ? "" : "image-fade-loaded")
              }
              {...filteredProps}
              src={mainImageURL}
              alt=""
            />
          );

          let captionDiv = null;

          if (
            !isStillLoading &&
            caption != null &&
            caption.length > 0 &&
            allowCaptions
          ) {
            captionDiv = (
              <div className="rendering-image-caption">
                {/* <div style={{fontStyle: "italic"}}>{"Rendering Includes:"}</div> */}
                <div
                  className="bold"
                  style={{
                    margin: "10px",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  {caption}
                </div>
              </div>
            );
          }

          return (
            <div
              className={positionAbsolute === false ? undefined : "fullDiv"}
              style={
                positionAbsolute === false
                  ? {
                      position: "relative",
                      padding: "inherit",
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      height: heightProp,
                    }
                  : {height: heightProp}
              }
            >
              {largeImageView}
              {captionDiv}
            </div>
          );
        }}
      </ProgressiveImage>
    );
  }

  return (
    // @ts-ignore
    <ProgressiveImage
      src={mainImageURL}
      placeholder={loadingImageURL}
      style={{height: props.height ?? "inherit"}}
      css={`
        height: inherit;
      `}
    >
      {(src: any, loading: boolean) => {
        const largeImageView = (
          <img
            className={
              positionAbsolute === false
                ? undefined
                : "fullDiv image-fade" + (loading ? "" : " image-fade-loaded")
            }
            style={
              positionAbsolute === false
                ? {
                    position: "relative",
                    padding: "inherit",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    height: props.forceHeight ?? "inherit",
                  }
                : {height: props.forceHeight ?? "inherit"}
            }
            {...filteredProps}
            src={mainImageURL}
            alt=""
          />
        );

        let captionDiv = null;

        if (
          !loading &&
          caption != null &&
          caption.length > 0 &&
          allowCaptions
        ) {
          captionDiv = <div className="rendering-image-caption">{caption}</div>;
        }

        const placeholder = (
          <img
            className={
              "fullDiv blur-image" +
              (loading ? "" : " blur-image-hide") +
              (smallImageLoaded ? "" : " blur-image-transition")
            }
            {...filteredProps}
            src={loadingImageURL}
            onLoad={() => {
              setSmallImageLoaded(true);
            }}
            alt=""
          />
        );

        return (
          <div
            className={positionAbsolute === false ? undefined : "fullDiv"}
            style={
              positionAbsolute === false
                ? {
                    position: "relative",
                    padding: "inherit",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    height: "inherit",
                  }
                : {height: "inherit"}
            }
          >
            {!!props.onFullscreenClick && (
              <FullScreenIconBackgroundContainer>
                {/*<FullScreenIconContainer>*/}
                <NatFullscreenIcon
                  size={"1.25rem"}
                  color={"white"}
                  onClick={props.onFullscreenClick}
                />
                {/*</FullScreenIconContainer>*/}
              </FullScreenIconBackgroundContainer>
            )}
            {largeImageView}
            {placeholder}
            {captionDiv}
          </div>
        );
      }}
    </ProgressiveImage>
  );
};
