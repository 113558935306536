import React from "react";
import {isBlankString} from "@natomas-org/core";
import styled from "styled-components";
import {DesignSquareButton} from "./styles";
import {SmartImage} from "../../generics/image/SmartImage";

export const ColorButton = (props) => {
  let style = {};
  if (!isBlankString(props.firstColor) && !isBlankString(props.secondColor)) {
    style.background = `linear-gradient(135deg, ${props.firstColor} 50%, ${props.secondColor} calc(50% + 1px))`;
  } else {
    style.backgroundColor = props.firstColor ?? props.secondColor;
  }

  if (isBlankString(props.firstColor) && props.imageId) {
    style.width = "100px";
    style.height = "100px";
    return (
      <ColorTextButtonWrapper
        id={"modifier-" + props.buttonId}
        active={props.isSelected}
        disabled={props.isDisabled}
        onClick={props.onClick}
        style={style}
      >
        <SmartImage
          imageId={props.imageId}
          style={{padding: "2px", borderRadius: "10px"}}
        />
      </ColorTextButtonWrapper>
    );
  }

  return (
    <ColorTextButtonWrapper
      id={"modifier-" + props.buttonId}
      active={props.isSelected}
      disabled={props.isDisabled}
      onClick={props.onClick}
      style={style}
    />
  );
};
const ColorTextButtonWrapper = styled(DesignSquareButton)`
  width: 100px;
  height: 100px;
  border-radius: 15px;
  outline: none;
  transition: all 0.35s;
  border-image-outset: 2px;
  margin-top: 15px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
  img {
    overflow: hidden;
    pointer-events: none;
  }
`;
