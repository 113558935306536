import {useCatalogSession} from "./useCatalogSession";
import {useMemo} from "react";
import {productSatisfiesFilters} from "../../../catalog/filter/logic";
import {sortProducts} from "../../../catalog/filter/sorting";
import {useProductFitsOnLot} from "../../useProductParcelData";
import {IProduct} from "@natomas-org/core";

export const useCatalogFilteredProducts = (productMap: {[id: string]: any}) => {
  const {filters, sortBy} = useCatalogSession();
  const {productFitsOnLotData} = useProductFitsOnLot({productId: undefined});

  return useMemo(() => {
    const productArray = Object.values(productMap);
    const products = productArray?.filter((p: IProduct) => {
      if (!p) {
        return false;
      }

      return productSatisfiesFilters(
        p,
        filters,
        productFitsOnLotData ?? undefined
      );
    });
    return sortProducts(products, sortBy);
  }, [productMap, sortBy, filters, productFitsOnLotData]);
};
