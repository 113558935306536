import React, {useState} from "react";
import {useFormik} from "formik";
import {ConditionalRendering} from "../../../../_shared/components/ConditionalRendering";
import {getFormInitialValues} from "../../../../../_shared/application";
import {
  ADDRESS_KEY,
  FIRST_NAME_KEY,
  LAST_NAME_KEY,
  PHONE_NUMBER_KEY,
  PROJECT_DETAILS_FORM,
} from "../../../../../_shared/forms/FormModels";
import {PersonalInfoRow} from "../PersonalInfoRow";
import profile from "../../../../../../assets/icons/pngs/profile_circle.png";
import phone from "../../../../../../assets/icons/pngs/phone_circle.png";
import pin from "../../../../../../assets/icons/pngs/pin_circle.png";
import {
  Address,
  Customer,
  formatPhoneNumber,
  IAddressDetails,
  ICustomerInfo,
  IProjectInfo,
  PhoneNumber,
  PhoneNumberFormatType,
  Project,
} from "@natomas-org/core";
import {constructValidation} from "../../../../../_shared/forms/FormHelper";
import {useDeepEffect} from "../../../../../_shared/hooks/useDeepEffect";
import {saveProjectInfo} from "../../../../../../database/firebase/project";
import {useCurrentCustomer} from "../../../../../_shared/hooks/useCurrentCustomer";
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {ButtonContainer, SectionHeader} from "../../styles";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {HOME_PAGE_ID} from "../../../../_shared/navigation/constants";
import {toPage} from "../../../../_shared/navigation/PageNavigation";
import {
  CustomerJourneyStepName,
  useTracking,
} from "../../../../../_shared/hooks/useTracking";
import {getNewProjectInfo} from "../../../../../_shared/project";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import useActiveDesign from "../../../../../_shared/hooks/useDesign/useActiveDesign";

const lineItems = [
  {title: "Contact's Name", logo: profile, type: "name"},
  {title: "Contact's Phone", logo: phone, type: "phone"},
  {title: "Project Address", logo: pin, type: "address"},
];

const getValue = (
  title: string,
  customer: ICustomerInfo | null,
  projectSummary?: IProjectInfo | null
) => {
  if (!projectSummary && !customer) return null;
  if (!projectSummary && customer) {
    if (title === "Contact's Name") {
      return Customer.getFullName(customer);
    } else if (title === "Contact's Phone") {
      return formatPhoneNumber(
        Customer.getPrimaryContactPhoneNumber(customer),
        PhoneNumberFormatType.PARENTHESIS_AND_DASHES
      );
    } else if (title === "Project Address") {
      return Address.getFullAddress(Customer.getContactAddress(customer));
    }
  } else if (projectSummary) {
    if (title === "Contact's Name") {
      return Project.getClientFullName(projectSummary);
    } else if (title === "Contact's Phone") {
      return formatPhoneNumber(
        PhoneNumber.getNumber(Project.getClientPhone(projectSummary)),
        PhoneNumberFormatType.PARENTHESIS_AND_DASHES
      );
    } else if (title === "Project Address") {
      return projectSummary?.address
        ? Address.getFullAddress(Project.getAddress(projectSummary))
        : null;
    }
  } else return null;
};

export const ProjectDetailsForm = () => {
  const {customer} = useCurrentCustomer();
  const {trackJourneyStep} = useTracking();
  const {id, address: projectAddress, info} = useActiveProject();
  const {configuration} = useActiveDesign();
  const [editInfo, setEditInfo] = useState(false);
  const size = useDynamicValue({
    forFour: 4,
    forEight: 4,
    forTwelve: 6,
    forSixteen: 8,
  });

  const setFormikValues = () => {
    if (!info && !customer) return;
    else if (!info && customer) {
      formik.setFieldValue(
        FIRST_NAME_KEY,
        Customer.getFirstName(customer) ?? ""
      );
      formik.setFieldValue(LAST_NAME_KEY, Customer.getLastName(customer) ?? "");
      formik.setFieldValue(
        ADDRESS_KEY,
        Customer.getContactAddress(customer) ?? {}
      );
      formik.setFieldValue(
        PHONE_NUMBER_KEY,
        Customer.getPrimaryContactPhoneNumber(customer) ?? ""
      );
    } else if (info) {
      formik.setFieldValue(
        FIRST_NAME_KEY,
        Project.getClientFirstName(info) ?? ""
      );
      formik.setFieldValue(
        LAST_NAME_KEY,
        Project.getClientLastName(info) ?? ""
      );
      formik.setFieldValue(ADDRESS_KEY, Project.getAddress(info) ?? "");
      formik.setFieldValue(
        PHONE_NUMBER_KEY,
        PhoneNumber.getNumber(Project.getClientPhone(info)) ?? ""
      );
    }
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(PROJECT_DETAILS_FORM),
    validationSchema: constructValidation(PROJECT_DETAILS_FORM),
    onSubmit: (values) => {
      if (!customer) return;
      // @ts-ignore
      const {firstName, lastName, phoneNumber, address} = values;
      setEditInfo(false);
      const toSaveInfo = getNewProjectInfo(
        firstName,
        lastName,
        phoneNumber,
        address ?? projectAddress ?? Customer.getContactAddress(customer),
        id,
        info
      );
      saveProjectInfo(id, toSaveInfo).then(() => {
        trackJourneyStep(CustomerJourneyStepName.INFO_SUBMITTED);
      });
    },
  });

  useDeepEffect(() => {
    // Everytime user updates - update form
    setFormikValues();
  }, [info, customer]);

  useDeepEffect(() => {
    if (editInfo) {
      setFormikValues();
    }
  }, [editInfo]);

  return (
    <>
      {id && (
        <PageElement size={size} height={"auto"}>
          <SectionHeader>Project Information</SectionHeader>
          {lineItems.map((lineItem, index) => (
            <PersonalInfoRow
              {...lineItem}
              key={"personal-info-" + index}
              value={getValue(lineItem.title, customer, info)}
              editInfo={editInfo}
              formik={formik}
              setAddressObject={(addressObj: IAddressDetails | null) => {
                addressObj && formik.setFieldValue(ADDRESS_KEY, addressObj);
              }}
            />
          ))}
          <ButtonContainer>
            <ConditionalRendering
              mode={"owner"}
              approvedRender={
                <NatButton
                  type={"button"}
                  trackingId={
                    "project-details-" + editInfo
                      ? "discard-changes"
                      : "edit-info"
                  }
                  id={editInfo ? "discard-changes" : "edit-info"}
                  label={editInfo ? "Discard Changes" : "Edit Info"}
                  clickEvent={() => {
                    setEditInfo(!editInfo);
                  }}
                  option={StyleOption.SECONDARY_ALT}
                />
              }
              deniedRender={null}
            />
            <ConditionalRendering
              mode={"owner"}
              show={editInfo}
              approvedRender={
                <NatButton
                  clickEvent={() => {
                    return formik.submitForm();
                  }}
                  disabled={Object.keys(formik.errors).length !== 0}
                  trackingId={"project-details-save-changes"}
                  id={"project-details-save-changes"}
                  label={"Save Changes"}
                  type={"button"}
                  option={StyleOption.PRIMARY_ALT}
                  spinnerEnabled={true}
                />
              }
            />
            <ConditionalRendering
              mode={"owner"}
              show={!editInfo && !info}
              approvedRender={
                <NatButton
                  clickEvent={() => {
                    formik.submitForm().then(() => toPage(HOME_PAGE_ID));
                  }}
                  trackingId={"project-details-confirm-and-continue"}
                  label={"Confirm and continue"}
                  type={"button"}
                  option={StyleOption.PRIMARY_ALT}
                  spinnerEnabled={true}
                />
              }
            />
            <ConditionalRendering
              mode={"owner"}
              show={!editInfo && info !== null && !configuration?.product}
              approvedRender={
                <NatButton
                  clickEvent={() => {
                    toPage(HOME_PAGE_ID);
                  }}
                  trackingId={"project-details-continue"}
                  label={"Continue"}
                  type={"button"}
                  option={StyleOption.PRIMARY_ALT}
                  spinnerEnabled={false}
                />
              }
            />
          </ButtonContainer>
        </PageElement>
      )}
    </>
  );
};
