import {PricingMode} from "../slices/GlobalSlice";
import {getProductPricingInfo} from "../_legacy/logic/ProductUtils";

export const calculatePriceRange = (
  products: any[],
  pricingMode: PricingMode
) => {
  let min = Infinity;
  let max = -Infinity;
  products.forEach((p: any) => {
    const price = getProductPricingInfo(pricingMode, p)?.price;
    if (min > price) {
      min = price;
    }
    if (max < price) {
      max = price;
    }
  });
  if (min !== Infinity && max !== -Infinity) {
    const minString = "$" + Math.floor(min / 100000) + "k";
    const maxString = "$" + Math.floor(max / 100000) + "k";
    return "From " + minString + " to " + maxString;
  }
  return "";
};
