import React, {useMemo} from "react";
import {FactoryInventoryTable} from "../ProductCatalogView/tabs/FactoriesTab/FactoryViews/FactoryInventoryView/FactoryInventoryTable";
import {
  IAdminFactoryLine,
  useAdminFactoryLines,
} from "../../_shared/hooks/admin-only-hooks/useAdminFactoryLine/useAdminFactoryLines";
import {IFactoryInventoryTableItem} from "../ProductCatalogView/tabs/FactoriesTab/FactoryViews/FactoryInventoryView/FactoryInventoryTable/interfaces";
import {IProduct} from "@natomas-org/core";
import {getFactoryInventoryTableItem} from "../ProductCatalogView/tabs/FactoriesTab/FactoryViews/FactoryInventoryView/FactoryInventoryTable/helper";

const AdminInventoryView = () => {
  const factories = useAdminFactoryLines();

  const allInventoryUnits: IFactoryInventoryTableItem[] = useMemo(() => {
    return factories
      ?.map((factory: IAdminFactoryLine) => {
        return Object.values(factory?.inventory ?? {})?.map((p: IProduct) => {
          const title =
            factory?.info?.name ??
            factory?.info?.globalTitle ??
            "Unnamed Factory";
          return getFactoryInventoryTableItem(p, title);
        });
      })
      .flat();
  }, [factories]);
  return (
    <FactoryInventoryTable
      inventory={allInventoryUnits}
      options={{showFactoryColumn: true}}
    />
  );
};

export default AdminInventoryView;
