import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {STEPH_SHADOW, WHITE} from "../../../colors";

export const NatMasonryLayout = (props: {
  componentWidthPx: number;
  components: (JSX.Element | null)[];
}) => {
  const [columns, setColumns] = useState(3);
  const componentWidthPx = props.componentWidthPx;
  const masonryRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (masonryRef.current) {
      const ro = new ResizeObserver(([entry]) => {
        const width = entry.contentRect.width;
        const colCount = Math.floor(width / componentWidthPx);
        if (colCount < 1) {
          setColumns(1);
        } else {
          setColumns(colCount);
        }
      });

      ro.observe(masonryRef.current);

      return () => {
        if (masonryRef.current) {
          ro.unobserve(masonryRef.current);
        }
      };
    }
  }, [componentWidthPx]);

  const columnItems: JSX.Element[][] = Array.from({length: columns}, () => []);
  if (!props.components) {
    return null;
  }
  props.components.forEach((item, index) => {
    if (!item) {
      return;
    }
    columnItems[index % columns].push(item);
  });
  return (
    <MasonryContainer ref={masonryRef}>
      {columnItems.map((columnItem, index) => (
        <MasonryColumn key={index + "column"}>
          {columnItem.map((item, index) => (
            <MasonryTile key={index + "tile"}>{item}</MasonryTile>
          ))}
        </MasonryColumn>
      ))}
    </MasonryContainer>
  );
};

const MasonryContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const MasonryColumn = styled.div`
  flex: 1;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
`;

const MasonryTile = styled.div<{minTileWidth?: string}>`
  background-color: ${WHITE};
  border-radius: 12px;
  box-shadow: ${STEPH_SHADOW};
  margin: 10px 0;
  padding: 20px;
  min-width: ${(props) => props.minTileWidth || undefined};
`;
