import {generatePayload} from "../FactoryInventoryStudioEditor/components/formik/saving";
import {
  updateInventoryUnit,
  updateInventoryUnitUpgradeSelections,
} from "../../../../../../../../../database/firebase/api/catalog";
import {flattenCategories} from "../FactoryInventoryStudioEditor/components/formik/initialize";
import {IProduct} from "@natomas-org/core";
import {
  FactoryInventoryPricingEditorKey,
  IFactoryInventoryPricingEditor,
} from "./formik/interfaces";

export const savingModifierPricingModifications = async (
  product: any,
  baseConfiguration: any,
  pricingOverrides: any
) => {
  const values = flattenCategories(baseConfiguration);
  const payload = generatePayload(product, values, pricingOverrides);
  return await updateInventoryUnitUpgradeSelections(payload);
};

export const savingProductPricingModifications = async (
  product: IProduct,
  values: IFactoryInventoryPricingEditor
) => {
  const toSave = formatValues(product, values);
  return updateInventoryUnit(toSave);
};

const formatValues = (product: any, values: IFactoryInventoryPricingEditor) => {
  return {
    id: product?.id,
    productGroupId: product?.productGroupId,
    price: {
      price: values?.[FactoryInventoryPricingEditorKey.UNIT_PRICE],
      cost:
        (values?.[FactoryInventoryPricingEditorKey.UNIT_COST] ?? 0) +
        (values?.[FactoryInventoryPricingEditorKey.SALES_TAX] ?? 0),
    },
    inventory_info: {
      salesTaxPrice: {
        price: values?.[FactoryInventoryPricingEditorKey.SALES_TAX],
        cost: values?.[FactoryInventoryPricingEditorKey.SALES_TAX],
      },
    },
  };
};
