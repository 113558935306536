import styled from "styled-components";
import {FONT_FAMILY_REGULAR} from "../../../portal/views/SSDDashboardView/styles/globals";

export const AdminActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionButtonContainer = styled.div<{
  formatTitle?: boolean;
  style?: React.CSSProperties;
}>`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  max-width: 20rem;
  row-gap: 1rem;

  .input-title {
    ${(props) =>
      props.formatTitle &&
      `font-size: 1.25rem !important; font-family: ${FONT_FAMILY_REGULAR} !important;`}
  }

  input {
    margin: 1rem 1rem 0 1rem;

    &:last-child {
      margin: 1rem;
    }

    flex-grow: 1;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
  }

  ${(props) => (props.style ? {...props.style} : undefined)}
}
`;
