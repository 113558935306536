import {useDispatch, useSelector} from "react-redux";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixIncludeOptions,
  AdminProductCatalogMatrixModes,
  AdminProductCatalogMatrixSubModes,
  AdminProductCatalogMatrixTitleModes,
  IAdminProductCatalogMatrixFilterOptions,
  IStore,
} from "../../../slices/types/Store";
import {
  setAdminProductCatalogMatrixFilters,
  setAdminProductCatalogMatrixMode,
  setAdminProductCatalogMatrixSubMode,
  setAdminProductCatalogMatrixTitleMode,
} from "../../../slices/AdminSlice";
import {
  changeFilterByOption,
  changeFilterIdsOption,
  changeIncludeOption,
} from "./helper";
import {useEffect, useMemo} from "react";
import {useCurrentUser} from "../../useCurrentUser";
import {
  updateCategoryInformation,
  updateFactoryLineInformation,
  updateModifierGroupInformation,
  updateModifierInformation,
  updateModifierInformationInBulk,
  updateProductInformation,
} from "./saving";
import {useSelectedFactoryLine} from "../../useProductCatalog/useSelectedFactoryLine";
import {tryFetchingCatalogFactory} from "../../../../../root/FetchingManager";

export const useAdminProductCatalog = () => {
  const factoryLine = useSelectedFactoryLine();
  const {info, details} = factoryLine ?? {};
  const {isAdmin} = useCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (info?.id && details?.versionId) {
      tryFetchingCatalogFactory(info?.id, {
        versionId: details?.versionId,
        fullFetch: true,
      });
    }
  }, [details?.versionId, info?.id]);

  const {matrixFilters, matrixMode, matrixSubMode, matrixTitleMode} =
    useSelector((state: IStore) => state?.administration?.productCatalog);
  const isFetching = useSelector(
    (state: IStore) => state.administration?.isFetching
  );

  // isLive represents if the current version is the current live version
  const isLive = useMemo(() => {
    if (!details ?? !info) {
      return false;
    }
    return details?.versionId === info?.publishedVersionId;
  }, [details, info]);

  // isDraft represents if the current version is the current live version
  const isDraft = useMemo(() => {
    if (!details ?? !info) {
      return false;
    }
    return details?.versionId === info?.draftVersionId;
  }, [details, info]);

  const editingEnabled = useMemo(() => {
    // is the user an admin and is the current version draft
    return isAdmin && isDraft;
  }, [isDraft, isAdmin]);

  const updateCategory = (categoryId: string, payload: any) => {
    if (info) {
      return updateCategoryInformation(info, isLive, categoryId, payload);
    }
  };

  const updateProduct = (productId: string, payload: any) => {
    if (info) {
      return updateProductInformation(info, isLive, productId, payload);
    }
  };

  const updateFactoryLine = (payload: any) => {
    if (info) {
      return updateFactoryLineInformation(info, isLive, payload);
    }
  };

  const updateModifierGroup = (modifierGroupId: string, payload: any) => {
    if (info) {
      return updateModifierGroupInformation(
        info,
        isLive,
        modifierGroupId,
        payload
      );
    }
  };

  const updateModifier = (
    modifierId: string,
    payload: any,
    productId?: string
  ) => {
    if (info) {
      return updateModifierInformation(
        info,
        isLive,
        modifierId,
        payload,
        productId
      );
    }
  };

  const updateModifiersInBulk = (payload: any) => {
    return updateModifierInformationInBulk(info, isLive, payload);
  };

  // Filter functions
  const resetAdminProCatFilters = () => {
    dispatch(
      setAdminProductCatalogMatrixFilters({
        filterIds: [],
        filterBy: AdminProductCatalogMatrixFilterByOptions.NONE,
        include: AdminProductCatalogMatrixIncludeOptions.ALL,
      })
    );
  };

  const updateAdminProCatFilters = (
    filters: IAdminProductCatalogMatrixFilterOptions
  ) => {
    dispatch(setAdminProductCatalogMatrixFilters(filters));
  };

  const updateAdminProCatFilterInclude = (
    include: IAdminProductCatalogMatrixFilterOptions["include"]
  ) => {
    dispatch(
      setAdminProductCatalogMatrixFilters(
        changeIncludeOption(matrixFilters, include)
      )
    );
  };

  const updateAdminProCatFilterBy = (
    fb: IAdminProductCatalogMatrixFilterOptions["filterBy"]
  ) => {
    dispatch(
      setAdminProductCatalogMatrixFilters(
        changeFilterByOption(matrixFilters, fb)
      )
    );
  };

  const updateAdminProCatFilterIds = (
    fids: IAdminProductCatalogMatrixFilterOptions["filterIds"]
  ) => {
    dispatch(
      setAdminProductCatalogMatrixFilters(
        changeFilterIdsOption(matrixFilters, fids)
      )
    );
  };

  const updateAdminProCatMatrixMode = (
    mode: AdminProductCatalogMatrixModes
  ) => {
    dispatch(setAdminProductCatalogMatrixMode(mode));
  };

  const updateAdminProCatMatrixSubMode = (
    mode: AdminProductCatalogMatrixSubModes
  ) => {
    dispatch(setAdminProductCatalogMatrixSubMode(mode));
  };

  const updateAdminProCatMatrixTitleMode = (
    mode: AdminProductCatalogMatrixTitleModes
  ) => {
    dispatch(setAdminProductCatalogMatrixTitleMode(mode));
  };

  // Exports
  return {
    resetAdminProCatFilters,
    updateAdminProCatFilterInclude,
    updateAdminProCatFilterBy,
    updateAdminProCatFilterIds,
    updateAdminProCatFilters,
    updateAdminProCatMatrixMode,
    updateAdminProCatMatrixSubMode,
    updateAdminProCatMatrixTitleMode,
    matrixFilters: matrixFilters ?? undefined,
    matrixMode,
    matrixSubMode,
    matrixTitleMode,
    isFetching,
    factoryLine,
    isDraft,
    editingEnabled,
    title: details?.internal_title ?? details?.title,
    updateCategory,
    updateProduct,
    updateFactoryLine,
    updateModifier,
    updateModifierGroup,
    updateModifiersInBulk,
  };
};
