import React, {Fragment, useEffect, useState} from "react";
import {BottomSheet, BottomSheetRef} from "react-spring-bottom-sheet";
import styled from "styled-components";
import "./ImplementationStyle.css";
import {DynamicScrollComponentAnchor} from "./DynamicScrollComponentAnchor";
import {usePage} from "../../../../_shared/hooks/usePage";
import {useUrl} from "../../../../_shared/hooks/useUrl";
import {MAP_PATH} from "../../../../../paths";
import {
  INFO_TABLE_MAX_WIDTH,
  INFO_TABLE_MIN_WIDTH,
  INFO_TABLE_WIDTH,
} from "../../../../map/constants";
import {IAddressDetails, IProduct} from "@natomas-org/core";
import {PriceCardContainer} from "../../../../catalog/_shared/components/PriceCard/styles";
import {NatFlex} from "../../../../_shared/generics/flex/NatFlex";
import {DIVIDER_COLOR} from "../../../../_shared/colors";
import {NLuxButton} from "../../../../_shared/generics/button/spring/NLuxButton";
import {IconPosition, StyleOption} from "../../../../_shared/generics/_shared";
import {NatCloseIcon} from "../../../../_shared/icon/icons";
import {usePriceCard} from "../../../../catalog/_shared/components/PriceCard";

const getMapSheetStyling = (open: boolean, isSticky: boolean) => {
  return {
    width: `calc(${INFO_TABLE_WIDTH} - 2rem)`,
    minWidth: `calc(${INFO_TABLE_MIN_WIDTH} - 2rem)`,
    maxWidth: `calc(${INFO_TABLE_MAX_WIDTH}, - 2rem)`,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    right: "1rem",
    bottom: 0,
    border: open ? "none" : undefined,
    boxShadow: open ? "none" : undefined,
    backgroundColor: "white",
  };
};
const getRegularSheetStyling = (open: boolean, isSticky: boolean) => {
  return {
    width: `calc(94vw)`,
    paddingLeft: "2vw",
    paddingRight: "2vw",
    bottom: 0,
    backgroundColor: "white",
  };
};

const HEADER_HEIGHT = 87;
const FULL_HEIGHT = 282;
const SHOW_BUTTON = "show";
const HIDE_BUTTON = "hide";
const CONTENT_HEIGHT = FULL_HEIGHT - HEADER_HEIGHT;
function getStickyTargetFromStart(start: number): number | null {
  if (start > 0 && start <= HEADER_HEIGHT + CONTENT_HEIGHT / 2) {
    return HEADER_HEIGHT;
  }
  if (start > HEADER_HEIGHT + CONTENT_HEIGHT / 2) {
    return FULL_HEIGHT;
  }
  return null;
}
export const MobilePriceCard = styled(
  (props: {
    children?: JSX.Element;
    product: IProduct | undefined;
    address: IAddressDetails | undefined;
  }) => {
    const {isNatMobile} = usePage();
    const {getURLPath} = useUrl();
    const [open, setOpen] = useState(true);
    const [isSticky, setIsSticky] = useState(false);
    const forceDisplay = getURLPath()?.includes(MAP_PATH) && !isNatMobile;
    const sheetRef = React.useRef<BottomSheetRef>(null);
    function dismissBottomSheet() {
      sheetRef?.current?.snapTo(() => 20, {source: HIDE_BUTTON});
      setHideHeaderCTA(false);
    }
    const {headerElement, bodyElement, modals} = usePriceCard({
      address: props.address,
      headerButtonAction: undefined,
      hideLinks: isSticky,
      product: props.product,
    });

    useEffect(() => {
      if (isSticky) {
        sheetRef?.current?.snapTo(({headerHeight}) => headerHeight, {
          source: SHOW_BUTTON,
        });
      }
      if (!isSticky) {
        sheetRef?.current?.snapTo(() => 0, {source: SHOW_BUTTON});
      }
    }, [isSticky]);
    const [hideHeaderCTA, setHideHeaderCTA] = useState(
      !!sheetRef?.current?.height && sheetRef?.current?.height > 250
    );
    if (!forceDisplay && !isNatMobile) {
      return null;
    }
    const toggleButton = (
      <NLuxButton
        option={hideHeaderCTA ? StyleOption.PRIMARY_ALT : StyleOption.PRIMARY}
        label={hideHeaderCTA ? "" : "Continue"}
        icon={
          hideHeaderCTA
            ? {
                icon: <NatCloseIcon />,
                iconPosition: IconPosition.ONLY,
              }
            : undefined
        }
        clickEvent={() => {
          sheetRef?.current?.snapTo(
            ({height}) => {
              return (
                getStickyTargetFromStart(
                  hideHeaderCTA ? HEADER_HEIGHT : FULL_HEIGHT
                ) ?? height
              );
            },
            {
              source: hideHeaderCTA ? SHOW_BUTTON : HIDE_BUTTON,
            }
          );
        }}
        transitionPercent={hideHeaderCTA ? 0 : 100}
      />
    );
    return (
      <Fragment>
        <BottomSheetWrapper>
          <DynamicScrollComponentAnchor setIsSticky={setIsSticky}>
            <PriceCardContainer>
              {headerElement}
              <div
                style={{
                  borderBottom: `0.5px solid ${DIVIDER_COLOR}`,
                  width: "100%",
                  height: "0.5px",
                }}
              />
              {bodyElement}
            </PriceCardContainer>
          </DynamicScrollComponentAnchor>
          <BottomSheet
            scrollLocking={false}
            ref={sheetRef}
            style={
              forceDisplay
                ? getMapSheetStyling(open, isSticky)
                : getRegularSheetStyling(open, isSticky)
            }
            blocking={false}
            open={open}
            onDismiss={dismissBottomSheet}
            draggable={false}
            onSpringStart={(event) => {
              if (event.type === "SNAP" && event.source === HIDE_BUTTON) {
                setHideHeaderCTA(true);
              } else if (
                event.type === "SNAP" &&
                event.source === SHOW_BUTTON
              ) {
                setHideHeaderCTA(false);
              }
            }}
            onSpringEnd={(event) => {
              if (
                event.type === "SNAP" &&
                event.source === "dragging" &&
                isSticky &&
                !!sheetRef?.current?.height
              ) {
                const target = getStickyTargetFromStart(
                  sheetRef?.current?.height
                );
                setHideHeaderCTA(target === FULL_HEIGHT);
              }
            }}
            header={
              <NatFlex
                ROW
                NOWRAP
                style={{borderBottom: `0.5px solid ${DIVIDER_COLOR}`}}
              >
                {headerElement}
                {toggleButton}
              </NatFlex>
            }
            maxHeight={627}
            defaultSnap={() => 0}
            snapPoints={() => {
              if (!isSticky) {
                return [0];
              } else {
                return [HEADER_HEIGHT, FULL_HEIGHT];
              }
            }}
          >
            <SheetBody>{bodyElement}</SheetBody>
          </BottomSheet>
        </BottomSheetWrapper>
        {modals}
      </Fragment>
    );
  }
)`
  display: ${(props) =>
    props.theme?.Page?.columnCount === 4 ? undefined : "none"};
`;

const BottomSheetWrapper = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 !important;
  width: 100%;
`;

const SheetBody = styled.div`
  height: 20rem;
`;
