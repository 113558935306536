import styled from "styled-components";
import {GRAY, WHITE} from "../../../../../../../../../../../../_shared/colors";

export const ColorOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  width: 100%;
`;

export const TabSelectionContainer = styled.div`
  flex-direction: row;
  display: flex;
  column-gap: 4px;
  padding: 0 4px;
  width: 100%;
  background-color: ${GRAY};
`;

export const TabSelector = styled.div<{isSelected: boolean}>`
  font-size: 14px;
  user-select: none;
  flex-direction: row;
  display: flex;
  padding: 4px 20px;
  border-radius: 16px 16px 0 0;
  margin-top: 2px;
  background-color: ${(props) => (props.isSelected ? WHITE : `#c4c4c4`)};
  cursor: pointer;
  ${(props) =>
    !props.isSelected &&
    `&:hover {
    background-color: #dedede;
  }`}
`;
