import styled from "styled-components";
import {
  BLACK,
  GRAY_BORDER_LIGHT,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_LIGHT_1,
  WHITE,
} from "src/components/_shared/colors";

export const Label = styled.label`
  color: ${BLACK};
  font-family: "p22-mackinac-pro", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  // Custom styles
  padding: 0;
  margin-bottom: 12px;
`;

export const SelectionLabel = styled.label`
  // Custom Styles
  border: ${(props) =>
    props.theme.selected
      ? `1px solid #368836`
      : `1px solid ${GRAY_BORDER_LIGHT}`};
  background-color: ${(props) =>
    props.theme.selected ? VILLA_APPLE_GREEN_LIGHT_1 : WHITE};
  color: ${(props) => (props.theme.selected ? "#368836" : BLACK)};
  border-radius: 10px;
  cursor: pointer;\
  font-size: 16px;
  padding: 16px;
  width: 100%;
  margin: 0; // label has default margin
  // Format
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: ${VILLA_APPLE_GREEN_LIGHT_1};
  }
  img {
    scale: ${(props) => (props.theme.selected ? 1 : 0)};
    transition: scale 200ms ease-in-out;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const CheckContainer = styled.img`
  height: 20px;
  width: 20px;
  padding: 3px;
  background-color: ${VILLA_APPLE_GREEN};
  border-radius: 10px;
`;
