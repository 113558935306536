import {NatButton, NatLink} from "../../button";
import {IconPosition, NatSize, StyleOption} from "../../_shared";
import {
  DOCUSIGN_DOCUMENT_IDS,
  EventStatus,
  IDocumentDetails,
  IDocumentTypes,
  IDocusignTaskDetails,
  IEventInfo,
  IGCloudFileStorageDetails,
} from "@natomas-org/core";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import {PDF_TO_DOCUSIGN_PROPS} from "../../../../docusign/views/constants";
import {
  getFormattedEventTitle,
  getHasNewDocusignBeenSent,
  getProjectDocumentIdFromDocumentDetails,
} from "../helpers";
import {ColoredStatusLabel} from "../styles";
import {downloadFileToDesktop} from "../../../../portal/views/SitePlanView/logic";
import {STATUS_ENABLED, STATUS_INCOMPLETE} from "../../../colors";
import {openURLInNewTab} from "../../../navigation";
import {BsTrash} from "react-icons/bs";
import {NatModal} from "../../modal/NatModal";
import {isProd, isStaging} from "../../../application";
import ReactTooltip from "react-tooltip";
import {HideItemButton} from "../../../../admin/CustomerDatabase/CustomerManagerView/sections/_shared/HideItemButton";

import {updateProjectDocumentInfo} from "../../../../../database/firebase/project/documents";
import {FONT_FAMILY_BOLD} from "../../../../portal/views/SSDDashboardView/styles/globals";
import NatLabel, {NatLabelType} from "../../label/NatLabel";
import {NatDownloadIcon, NatEditIcon} from "../../../icon/icons";
import {SendDocusignForm} from "../../../../docusign/views/SendDocusignForm";
import {useGlobalConfiguration} from "../../../hooks/useGlobalConfiguration";

export const DocumentLink = (props: {
  onClick: () => void;
  documentDetails: IDocumentDetails;
  styleOption?: StyleOption;
}) => {
  const {documentDetails, onClick} = props;
  return <NatLink label={documentDetails?.title} clickEvent={onClick} />;
};

export const DocusignButton = (props: {
  currentDocument: IDocumentDetails;
  docusignStatus: EventStatus;
  projectId: string;
}) => {
  const {docusignStatus, currentDocument} = props;
  const [showModalForm, setShowModalForm] = useState<boolean>(false);
  const [errorString, setErrorString] = useState<string>("Error");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  return (
    <div>
      <DocusignErrorModal
        errorString={errorString}
        setShowSendError={setShowErrorModal}
        showSendError={showErrorModal}
      />
      <SendDocusignForm
        projectId={props.projectId}
        show={showModalForm}
        setShow={setShowModalForm}
        initialProps={PDF_TO_DOCUSIGN_PROPS[currentDocument?.document_id]}
        documentDetails={currentDocument}
        setSendErrorString={setErrorString}
        setShowSendError={setShowErrorModal}
      />
      <SendDocusignButton
        documentId={currentDocument?.document_id}
        docusignStatus={docusignStatus}
        setShowModalForm={setShowModalForm}
      />
    </div>
  );
};

const DocusignErrorModal = (props: {
  errorString: string;
  showSendError: boolean;
  setShowSendError: Dispatch<SetStateAction<boolean>>;
}) => {
  const {errorString, setShowSendError, showSendError} = props;
  return (
    <NatModal
      content={
        <div>
          <div
            style={{
              fontFamily: FONT_FAMILY_BOLD,
              fontSize: "20px",
              paddingBottom: "0.5rem",
            }}
          >
            Could not Send Docusign
          </div>
          <div>{errorString}</div>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "1rem",
            }}
          >
            <NatButton
              label={"Okay"}
              type={"button"}
              option={StyleOption.PRIMARY}
              clickEvent={() => {
                setShowSendError(false);
                return;
              }}
            />
          </div>
        </div>
      }
      show={showSendError}
      handleClose={() => {
        setShowSendError(false);
      }}
    />
  );
};

export const DocumentDownloadIcon = (props: {
  document: IDocumentDetails | null | undefined;
}) => {
  const {document} = props;
  if (!document || document.type !== IDocumentTypes.GCLOUD_STORAGE) {
    return <></>;
  }
  return (
    <NatButton
      label={""}
      type={"button"}
      clickEvent={() => {
        if (!document?.url) {
          return;
        }
        return downloadFileToDesktop(document?.url, `${document?.title}`);
      }}
      option={StyleOption.ICON_ONLY}
      style={{
        backgroundColor: "white",
        border: "1px solid black",
      }}
      icon={{
        icon: <NatDownloadIcon />,
        iconPosition: IconPosition.ONLY,
      }}
      tooltip={"Download Document"}
      id={document?.document_id + "_download_document"}
    />
  );
};

export const HideDocumentIcon = (props: {
  currentDocument: IDocumentDetails | null | undefined;
  projectId: string | null | undefined;
  onHide?: () => void;
}) => {
  const {currentDocument, projectId} = props;

  const projectDocumentId = useMemo(() => {
    if (
      !projectId ||
      !currentDocument ||
      currentDocument.type !== IDocumentTypes.GCLOUD_STORAGE
    ) {
      return null;
    }
    return getProjectDocumentIdFromDocumentDetails(currentDocument) ?? null;
  }, [currentDocument, projectId]);

  if (
    !projectId ||
    !currentDocument ||
    currentDocument.type !== IDocumentTypes.GCLOUD_STORAGE ||
    !projectDocumentId
  ) {
    return null;
  }

  return (
    <HideItemButton
      clickEvent={() => {
        return updateProjectDocumentInfo(projectId, projectDocumentId, {
          hidden: !currentDocument?.hidden,
        }).then(() => {
          props.onHide?.();
        });
      }}
      hidden={currentDocument?.hidden}
      itemId={projectDocumentId}
      tooltipTitle={`document in "Project Documents" section`}
    />
  );
};

export const EditButtonWithIcon = (props: {
  onClick?: () => void;
  tooltipTitle: string;
}) => {
  const {onClick, tooltipTitle} = props;
  return (
    <NatButton
      label={""}
      type={"button"}
      clickEvent={onClick}
      option={StyleOption.ICON_ONLY}
      style={{border: "1px solid black"}}
      icon={{icon: <NatEditIcon />, iconPosition: IconPosition.ONLY}}
      tooltip={tooltipTitle}
      id={"edit-button"}
    />
  );
};

export const DeleteDocumentIcon = (props: {
  currentDocument: IDocumentDetails | null | undefined;
  projectId: string | null | undefined;
  onComplete?: () => void;
}) => {
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const {currentDocument, projectId, onComplete} = props;
  if (
    !currentDocument ||
    !projectId ||
    currentDocument.type !== IDocumentTypes.GCLOUD_STORAGE
  ) {
    return <></>;
  }
  const currentDocumentDetails =
    currentDocument?.details as IGCloudFileStorageDetails;
  const projectDocumentId =
    getProjectDocumentIdFromDocumentDetails(currentDocument);

  return (
    <div>
      <NatModal
        content={
          <div>
            <NatLabel
              label={`Are you sure you want to delete '${currentDocument.title}'?`}
              type={NatLabelType.P1}
            />
            <div
              style={{display: "flex", flexDirection: "row", columnGap: "1rem"}}
            >
              <NatButton
                label={"Cancel"}
                type={"button"}
                option={StyleOption.SECONDARY_ALT}
                clickEvent={() => {
                  setShowWarningModal(false);
                  return;
                }}
              />
              <NatButton
                label={"Delete"}
                type={"button"}
                option={StyleOption.DESTRUCTIVE}
                spinnerEnabled={true}
                clickEvent={() => {
                  if (!currentDocumentDetails.storageRefPath) {
                    setShowWarningModal(false);
                    return;
                  }
                  if (projectDocumentId) {
                    return updateProjectDocumentInfo(
                      projectId,
                      projectDocumentId,
                      {deleted: true, details: currentDocument?.details}
                    )
                      .then(() => {
                        onComplete?.();
                        setShowWarningModal(false);
                        return null;
                      })
                      .catch((e) => {
                        console.log(e);
                        setShowWarningModal(false);
                        return null;
                      });
                  }
                }}
              />
            </div>
          </div>
        }
        show={showWarningModal}
        handleClose={() => {
          setShowWarningModal(false);
        }}
      />
      <NatButton
        label={""}
        type={"button"}
        clickEvent={() => {
          setShowWarningModal(true);
        }}
        option={StyleOption.ICON_ONLY}
        style={{border: "1px solid black"}}
        icon={{icon: <BsTrash />, iconPosition: IconPosition.ONLY}}
        tooltip={"Delete document"}
        id={"delete-document-button"}
      />
    </div>
  );
};

export const DocusignStatus = (props: {
  event: IEventInfo | undefined | null;
}) => {
  const {event} = props;
  const urlPrefix =
    isProd || isStaging
      ? "https://app.docusign.com/documents/details/"
      : "https://appdemo.docusign.com/documents/details/";
  if (!event || !(event?.details as IDocusignTaskDetails).envelope_id) {
    return <></>;
  }
  return (
    <div style={{marginLeft: "-0.25rem"}}>
      <NatButton
        style={{padding: "0 !important"}}
        type={"button"}
        spinnerEnabled={false}
        key={`NatButton${event.event_id}`}
        label={getFormattedEventTitle(event)}
        size={NatSize.SMALL}
        option={StyleOption.PRIMARY_LINK}
        clickEvent={() => {
          openURLInNewTab(
            `${urlPrefix}${
              (event?.details as IDocusignTaskDetails).envelope_id
            }`
          );
        }}
      />
    </div>
  );
};

export const DocusignStatusLabel = (props: {
  status: EventStatus | undefined;
}) => {
  const {status} = props;
  if (!status) {
    return <></>;
  }
  switch (status) {
    case EventStatus.IN_PROGRESS:
      return <ColoredStatusLabel color={"#D18700"}>Sent</ColoredStatusLabel>;
    case EventStatus.COMPLETED:
      return (
        <ColoredStatusLabel color={STATUS_ENABLED}>Signed</ColoredStatusLabel>
      );
    case EventStatus.SCHEDULED:
      return (
        <ColoredStatusLabel color={STATUS_INCOMPLETE}>
          Not Sent
        </ColoredStatusLabel>
      );
    default:
      return <></>;
  }
};

export const NewDocusignStatusLabel = (props: {
  event: IEventInfo | null;
  docToDisplay: IDocumentDetails | null;
}) => {
  const {event, docToDisplay} = props;
  const hasNewDocusignBeenSent = getHasNewDocusignBeenSent(event, docToDisplay);
  if (!hasNewDocusignBeenSent) {
    return <></>;
  } else {
    return (
      <div data-tip data-for={"tooltip-outdated-docusign"}>
        <ColoredStatusLabel color={"#D18700"}>
          {hasNewDocusignBeenSent}
        </ColoredStatusLabel>
        <ReactTooltip
          place={"right"}
          id={"tooltip-outdated-docusign"}
          effect="solid"
        >
          A new document has been uploaded since the Docusign was sent.
        </ReactTooltip>
      </div>
    );
  }
};

const SendDocusignButton = (props: {
  docusignStatus: EventStatus;
  setShowModalForm: React.Dispatch<React.SetStateAction<boolean>>;
  documentId?: string;
}) => {
  const {docusignStatus, setShowModalForm} = props;
  const {IS_DOCUSIGN_LIVE} = useGlobalConfiguration();
  const buttonDisabled = useMemo(() => {
    return (
      !IS_DOCUSIGN_LIVE ||
      !props.documentId ||
      !DOCUSIGN_DOCUMENT_IDS.includes(props.documentId)
    );
  }, [IS_DOCUSIGN_LIVE, props.documentId]);
  if (buttonDisabled) {
    return null;
  }
  return (
    <NatButton
      size={NatSize.NORMAL}
      option={
        docusignStatus === EventStatus.IN_PROGRESS ||
        docusignStatus === EventStatus.COMPLETED
          ? StyleOption.SECONDARY_ALT
          : StyleOption.PRIMARY
      }
      label={"Send Docusign"}
      type={"button"}
      clickEvent={() => {
        setShowModalForm(true);
      }}
      spinnerEnabled={false}
    />
  );
};
