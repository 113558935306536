import firebase from "firebase";
import {redirectToSignInWithGoogle} from "./google";
import {redirectToSignInWithFacebook} from "./facebook";

export enum Provider {
  GOOGLE = "google.com",
  FACEBOOK = "facebook.com",
}

export const tryProviders = async (email: string) => {
  const providers = await getProviders(email);
  return processProviders(providers);
};

const getProviders = async (email: string) => {
  const auth = firebase.auth();
  const providers = await auth
    .fetchSignInMethodsForEmail(email)
    .then((result: string[]) => {
      if (result?.length > 0) {
        return result;
      }
    });
  return providers ?? [];
};

const processProviders = (providers: string[]) => {
  if (providers?.includes("google.com")) {
    return redirectToSignInWithGoogle();
  } else if (providers?.includes("facebook.com")) {
    return redirectToSignInWithFacebook();
  } else {
    return null;
  }
};

export const signInWithPopup = async (options: {
  provider: Provider;
  verifiedByEmail?: boolean;
}) => {
  try {
    const provider = await getProvider(options?.provider);
    const auth = firebase.auth();
    return auth.signInWithPopup(provider);
  } catch (error: any) {
    if (error.code === "auth/popup-closed-by-user") {
      if (options?.verifiedByEmail) {
        return new Error(
          "Our records indicate that you registered with Google. Please sign in with Google."
        );
      }
      return new Error("Sign in with Google was cancelled.");
    }
    return new Error(error.message);
  }
};

export const getProvider = async (provider: Provider) => {
  let instance = null;
  switch (provider) {
    case Provider.GOOGLE:
      instance = new firebase.auth.GoogleAuthProvider();
      break;
    case Provider.FACEBOOK:
      instance = new firebase.auth.FacebookAuthProvider();
      break;
    default:
      throw new Error("Provider not found");
  }
  instance.setCustomParameters({
    prompt: "select_account",
  });
  return instance;
};
