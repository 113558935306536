import {fullPriceTextFromMicros} from "@natomas-org/core";
import {
  IEstimateProjectRange,
  IEstimateProjectRangeSummary,
  IEstimateRangeItem,
} from "../../../api/Estimate/EstimateService";

export const getTotalEstimateRange = (
  summary?: IEstimateProjectRangeSummary
): {isRange: boolean; result: string | null} => {
  if (!summary) return {isRange: false, result: null};
  // Updates
  const roundUpToNearestTenDollar = (price: number): number => {
    return Math.ceil(price / 1000) * 1000;
  };

  const lowTotal = roundUpToNearestTenDollar(summary.total_price_low);
  const highTotal = roundUpToNearestTenDollar(summary.total_price_high);

  const low: string = fullPriceTextFromMicros(lowTotal);
  const high: string = fullPriceTextFromMicros(highTotal);

  if (lowTotal === highTotal) {
    return {
      isRange: false,
      result: low,
    };
  } else {
    return {isRange: true, result: `${low} - ${high}`};
  }
};

export const getAllLineItems = (
  range: IEstimateProjectRange | undefined
): IEstimateRangeItem[] => {
  if (!range) return [];
  const buckets = Object.values(range?.buckets ?? {});
  return buckets
    .flatMap((bucket) => bucket?.line_items ?? [])
    .sort((a, b) => {
      if (a.locked === b.locked) {
        return 0;
      } else if (a.locked) {
        return 1;
      } else {
        return -1;
      }
    });
};
