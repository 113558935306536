import React, {useEffect, useMemo, useState} from "react";
import {
  BLACK,
  GRANITE,
  HIGHLIGHT_SKY,
  VILLA_COOL_GRAY,
  WHITE,
} from "../../../../../../_shared/colors";
import styled from "styled-components";
import {FONT_FAMILY_BOLD} from "../../../../../../portal/views/SSDDashboardView/styles/globals";
import {IModifierPricingReview, ReviewWarningStatus} from "./interface";
import {priceTextFromMicros} from "@natomas-org/core";
import {getEnumSelectOptions} from "../../../../../../factory-info-set/shared/helper";
import {summarizeModifierPricingReview} from "./formatting";

const MarkerLabel = styled.div<{
  color?: string;
  backgroundColor?: string;
  float?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-family: ${FONT_FAMILY_BOLD};
  text-transform: uppercase;
  color: ${(props) => props.color || BLACK};
  background-color: ${(props) => props.backgroundColor || VILLA_COOL_GRAY};
  padding: 4px 8px;
  border-radius: 8px;
  :hover {
    border: 2px solid ${(props) => props.backgroundColor || VILLA_COOL_GRAY};
    margin: -2px;
  }
  cursor: pointer;
  user-select: none;
`;

const MarkerToggle = styled.div<{
  selected: boolean;
  color?: string;
  backgroundColor?: string;
}>`
  font-size: 11px;
  font-family: ${FONT_FAMILY_BOLD};
  text-transform: uppercase;
  color: ${(props) => (props?.selected ? props.color : props.backgroundColor)};
  background-color: ${(props) =>
    props?.selected ? props.backgroundColor : props.color};
  padding: 8px 12px;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
`;

const MarkerToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  margin-left: auto;
`;

const MarkerRowContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

const MarkerRow = styled.div<{backgroundColor?: string}>`
  display: flex;
  flex-direction: row;
  font-family: ${FONT_FAMILY_BOLD};
  justify-content: flex-start;
  background-color: ${(props) => props.backgroundColor || VILLA_COOL_GRAY};
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
`;

const Marker = styled.div<{
  color?: string;
  backgroundColor?: string;
  float?: boolean;
}>`
  font-size: 11px;
  color: ${(props) => props.color || BLACK};
  background-color: ${(props) => props.backgroundColor || VILLA_COOL_GRAY};
  padding: 4px 8px;
  border-radius: 8px;
  width: fit-content;
  margin-left: ${(props) => (props?.float ? "auto" : "0")};
`;

interface PriceReviewSectionProps {
  label: string;
  rows: IModifierPricingReview[];
  defaultState: boolean;
  color?: string;
  backgroundColor?: string;
}

const PriceReviewSection = (props: PriceReviewSectionProps) => {
  const {defaultState, label, rows, color, backgroundColor} = props;
  const [showSection, setShowSection] = useState(defaultState);
  const [filter, setFilter] = useState<ReviewWarningStatus | null>(null);

  const filterOptions = useMemo(() => {
    const options: any[] = getEnumSelectOptions(ReviewWarningStatus);
    return options?.filter((o) =>
      rows.some((row: IModifierPricingReview) => {
        return row.warningStatus === o.label;
      })
    );
  }, [rows]);

  const filteredRows = useMemo(() => {
    if (!filter) {
      return rows;
    }
    return rows?.filter((row) => {
      return row?.warningStatus === filter;
    });
  }, [filter, rows]);

  const filterLabel = useMemo(() => {
    if (filter) {
      return `Showing ${filteredRows?.length} out of ${rows?.length}`;
    } else {
      return null;
    }
  }, [filter, filteredRows?.length, rows?.length]);

  useEffect(() => {
    return () => {
      setShowSection(defaultState);
    };
  }, [defaultState]);

  return (
    <>
      <MarkerLabel
        backgroundColor={backgroundColor}
        color={WHITE}
        onClick={() => setShowSection((prevState) => !prevState)}
      >
        {label} ({rows?.length})
      </MarkerLabel>
      <MarkerRowContainer hidden={!showSection}>
        <MarkerToggleContainer
          hidden={!filterOptions || filterOptions?.length === 0}
        >
          <div color={GRANITE} style={{fontSize: 10}}>
            {filterLabel}
          </div>
          <MarkerToggle
            color={WHITE}
            backgroundColor={backgroundColor}
            selected={!filter}
            onClick={() => setFilter(null)}
          >
            ALL
          </MarkerToggle>
          {filterOptions?.map((option) => {
            return (
              <MarkerToggle
                color={WHITE}
                backgroundColor={backgroundColor}
                selected={option.label === filter}
                onClick={() => setFilter(option.label)}
              >
                {option.label}
              </MarkerToggle>
            );
          })}
        </MarkerToggleContainer>
        {filteredRows?.map((review) => {
          const {
            productTitle,
            modifierTitle,
            grossMargin,
            grossProfitMargin,
            priceLabel,
            costLabel,
          } = summarizeModifierPricingReview(review);
          return (
            <>
              <MarkerRow backgroundColor={color}>
                <Marker
                  hidden={!productTitle}
                  backgroundColor={HIGHLIGHT_SKY}
                  color={WHITE}
                >
                  {productTitle}
                </Marker>
                <Marker color={backgroundColor}>{modifierTitle}</Marker>
                <Marker color={backgroundColor}>
                  Condition: {review?.state?.condition?.toUpperCase()}
                </Marker>
                <Marker color={backgroundColor}>
                  Dependents:
                  {review?.state?.dependencies?.reduce(
                    (acc, curr, currentIndex: number) => {
                      if (currentIndex === 0) {
                        return curr?.title;
                      }
                      return `${acc}, ${curr?.title}`;
                    },
                    ""
                  )}
                </Marker>
                <Marker color={backgroundColor}>
                  Type: {review?.state?.type?.toUpperCase()}
                </Marker>
                <Marker color={backgroundColor} float={true}>
                  Gross Margin: {priceTextFromMicros(grossMargin, "accurate")}
                </Marker>
                <Marker color={backgroundColor}>
                  Gross Profit Margin: {(grossProfitMargin * 100).toFixed(2)}%
                </Marker>
                <Marker color={backgroundColor}>Price: {priceLabel}</Marker>
                <Marker color={backgroundColor}>Cost: {costLabel}</Marker>
                <Marker
                  hidden={!review.warningStatus}
                  color={WHITE}
                  backgroundColor={backgroundColor}
                >
                  {review?.warningStatus?.toUpperCase()}
                </Marker>
                <Marker color={WHITE} backgroundColor={backgroundColor}>
                  {review?.message}
                </Marker>
              </MarkerRow>
            </>
          );
        })}
      </MarkerRowContainer>
    </>
  );
};

export default PriceReviewSection;
