import MaterialTable from "material-table";
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import Select from "react-select";
import {
  convertGlobalOptionValuesToSelectionOptionsWithKey,
  convertMapIntoSelections,
  convertSelectionsToMap,
} from "./ProductOptionHelper";
import {isBlankString} from "@natomas-org/core";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";

function getDataFromEntries(optionEntries, optionValues) {
  return Object.keys(optionEntries).map((optionKey) => {
    const optionValue = optionEntries[optionKey];
    if (typeof optionValue === "string") {
      return {
        key: optionKey,
        value: optionValue,
      };
    } else {
      const value = convertMapIntoSelections(optionValue, optionValues);
      return {
        key: optionKey,
        value: value,
      };
    }
  });
}

function convertOptionKeysToOptions(optionKeys) {
  if (optionKeys == null) {
    return [];
  }
  return Object.keys(optionKeys).map((key) => {
    const optionKey = optionKeys[key];
    return {
      label:
        optionKey.name != null && optionKey.name.length > 0
          ? optionKey.name
          : key,
      value: key,
    };
  });
}

export const ProductOptionKeyToValueTable = (props) => {
  const {optionEntries, handleChange, enabled} = props;
  const optionValues = useSelector((state) => state.options.optionValues);
  const optionKeys = useSelector((state) => state.options.optionKeys);
  const [allData, setData] = useState(
    getDataFromEntries(optionEntries, optionValues)
  );
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false);
  }, [enabled]);

  const productKeyLookUp = {};
  Object.keys(optionKeys).forEach((key) => {
    const optionKey = optionKeys[key];
    productKeyLookUp[key] = optionKey.name != null ? optionKey.name : key;
  });

  const productOptionLookUp = {};
  Object.keys(optionValues).forEach((id) => {
    const optionValue = optionValues[id];
    productOptionLookUp[id] = optionValue.name != null ? optionValue.name : id;
  });

  useEffect(() => {
    const newData = getDataFromEntries(optionEntries, optionValues);
    setData(newData);
  }, [optionEntries, optionValues]);

  const updateRow = (index, newData) => {
    const dataUpdate = [...allData];
    dataUpdate[index] = newData;
    const newDataToSave = [...dataUpdate];
    setData(newDataToSave);
    if (Array.isArray(newData.value)) {
      const newDataToSaveToProduct = [...dataUpdate];
      newDataToSaveToProduct[index].callback = convertSelectionsToMap(
        newData.value
      );
      handleChange(newDataToSaveToProduct);
    } else {
      handleChange(newDataToSave);
    }
  };

  const [columns, setColumns] = useState([
    {
      title: "Option Key",
      field: "key",
      lookup: productKeyLookUp,
      editComponent: (props) => {
        const optionKey = props.value;
        const selectValue = {
          label: productKeyLookUp[optionKey],
          value: optionKey,
        };

        return (
          <Select
            value={selectValue}
            onChange={(value) => {
              props.onChange(value.value);
            }}
            placeholder={"Option Key"}
            options={convertOptionKeysToOptions(optionKeys)}
          />
        );
      },
    },
    {
      title: "Option Value",
      field: "value",
      lookup: productOptionLookUp,
      render: (rowData) => {
        const optionKey = rowData.key;
        const fullOptionKey = optionKeys[optionKey];
        const isMultiSelect =
          fullOptionKey != null && !isBlankString(fullOptionKey.groupKeySuffix);
        let selectValue = rowData.value;

        if (!isMultiSelect) {
          const optionValueId = rowData.value;
          selectValue = {
            label: productOptionLookUp[optionValueId],
            value: optionValueId,
          };
        }

        return (
          <Select
            value={selectValue}
            onChange={(value) => {
              const row = rowData.tableData.id;
              const newData = Object.assign({}, rowData);
              delete newData.tableData;
              if (!isMultiSelect) {
                newData.value = value.value;
              } else {
                newData.value = value;
              }
              updateRow(row, newData);
            }}
            isMulti={isMultiSelect}
            placeholder={"Option Value"}
            options={convertGlobalOptionValuesToSelectionOptionsWithKey(
              optionValues,
              optionKey
            )}
          />
        );
      },
      editComponent: (props) => {
        const {rowData} = props;
        const optionKey = rowData.key;
        const fullOptionKey = optionKeys[optionKey];
        const isMultiSelect =
          fullOptionKey != null && !isBlankString(fullOptionKey.groupKeySuffix);
        const optionValueId = props.value;
        let selectValue = {
          label: productOptionLookUp[optionValueId],
          value: optionValueId,
        };

        if (isMultiSelect) {
          selectValue = props.value;
        }

        return (
          <Select
            value={selectValue}
            onChange={(value) => {
              if (!isMultiSelect) {
                props.onChange(value.value);
              } else {
                props.onChange(value);
              }
            }}
            isMulti={isMultiSelect}
            placeholder={"Option Value"}
            options={convertGlobalOptionValuesToSelectionOptionsWithKey(
              optionValues,
              optionKey
            )}
          />
        );
      },
    },
  ]);

  useEffect(() => {
    setColumns([
      {
        title: "Product Key",
        field: "key",
        lookup: productKeyLookUp,
        editComponent: (props) => {
          const optionKey = props.value;
          const selectValue = {
            label: productKeyLookUp[optionKey],
            value: optionKey,
          };

          return (
            <Select
              value={selectValue}
              onChange={(value) => {
                props.onChange(value.value);
              }}
              placeholder={"Option Key"}
              options={convertOptionKeysToOptions(optionKeys)}
            />
          );
        },
      },
      {
        title: "Product Value",
        field: "value",
        lookup: productOptionLookUp,
        render: (rowData) => {
          const optionKey = rowData.key;
          const fullOptionKey = optionKeys[optionKey];
          const isMultiSelect =
            fullOptionKey != null &&
            !isBlankString(fullOptionKey.groupKeySuffix);
          let selectValue = rowData.value;

          if (!isMultiSelect) {
            const optionValueId = rowData.value;
            selectValue = {
              label: productOptionLookUp[optionValueId],
              value: optionValueId,
            };
          }

          return (
            <Select
              value={selectValue}
              onChange={(value) => {
                const row = rowData.tableData.id;
                const newData = Object.assign({}, rowData);
                delete newData.tableData;
                if (!isMultiSelect) {
                  newData.value = value.value;
                } else {
                  newData.value = value;
                }
                updateRow(row, newData);
              }}
              isMulti={isMultiSelect}
              placeholder={"Option Value"}
              options={convertGlobalOptionValuesToSelectionOptionsWithKey(
                optionValues,
                optionKey
              )}
            />
          );
        },
        editComponent: (props) => {
          const {rowData} = props;
          const optionKey = rowData.key;
          const fullOptionKey = optionKeys[optionKey];
          const isMultiSelect =
            fullOptionKey != null &&
            !isBlankString(fullOptionKey.groupKeySuffix);
          const optionValueId = props.value;
          let selectValue = {
            label: productOptionLookUp[optionValueId],
            value: optionValueId,
          };

          if (isMultiSelect) {
            selectValue = props.value;
          }

          return (
            <Select
              value={selectValue}
              onChange={(value) => {
                if (!isMultiSelect) {
                  props.onChange(value.value);
                } else {
                  props.onChange(value);
                }
              }}
              isMulti={isMultiSelect}
              placeholder={"Option Value"}
              options={convertGlobalOptionValuesToSelectionOptionsWithKey(
                optionValues,
                optionKey
              )}
            />
          );
        },
      },
    ]);
  }, [allData]);

  const entryArray = useMemo(() => {
    const array = Object.values(optionEntries);
    if (!array || array.length === 0) {
      return "None";
    } else {
      return array.map((valueId) => {
        return (
          <div key={valueId}>
            {productOptionLookUp[valueId] ?? "Unknown Option"}
          </div>
        );
      });
    }
  }, [productOptionLookUp, optionEntries]);

  if (!show) {
    return (
      <div>
        {entryArray}
        <br />
        {enabled && (
          <NatButton
            label={"Edit"}
            option={StyleOption.PRIMARY_ALT}
            type={"button"}
            clickEvent={() => {
              setShow(true);
            }}
          />
        )}
        <br />
      </div>
    );
  }

  return (
    <MaterialTable
      title={""}
      columns={columns}
      data={allData}
      options={{
        search: false,
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve, reject) => {
            const newDataToSave = [...allData, newData];
            setData(newDataToSave);
            handleChange(newDataToSave);
            resolve();
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            const index = oldData.tableData.id;
            updateRow(index, newData);
            resolve();
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve, reject) => {
            const dataDelete = [...allData];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            const newDataToSave = [...dataDelete];
            setData(newDataToSave);
            handleChange(newDataToSave);
            resolve();
          }),
      }}
    />
  );
};
