import React from "react";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";
import {SSCTitleDiv} from "./styles";

interface SSCTitleProps {
  title: string;
}

const SSCTitle = (props: SSCTitleProps) => {
  const {title} = props;

  return (
    <SSCBlockContainer>
      <SSCTitleDiv>{title}</SSCTitleDiv>
    </SSCBlockContainer>
  );
};

export default SSCTitle;
