import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: auto;
  flex-grow: 1;
  width: 100%;
  max-width: 450px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap-reverse;
  position: sticky;
  bottom: 0;
  background: white;
  padding: 16px 0;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const Label = styled.label`
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  font-family: "p22-mackinac-pro", sans-serif;
`;

export const InputContainer = styled.div<{gap: "16px" | 0}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.gap};
  // For div width < 600px
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
`;
