import React, {useCallback, useMemo} from "react";
import {DesignStudioModifier} from "@natomas-org/core";
import {
  ID_KEY,
  INTERNAL_CATEGORY_KEY,
  LABEL_KEY,
} from "../../../../../../../factory-info-set/shared/constants";
import {
  OrderFormTableCellContainer,
  OrderFormTableContainer,
  OrderFormTableRow,
} from "./styles";
import {orderFormColumns} from "./columns";
import {useFilters, useSortBy, useTable} from "react-table";
import {OrderFormTableCell} from "./OrderFormTableCell";
import {InfoSetColumnTextFilter} from "../../../../../../../factory-info-set/InfoSetTable/components/ColumnTextFilter";
import {updateOrderFormSelections} from "../../../../../../../../database/firebase/project/orderFormSelections";
import {useCurrentUser} from "../../../../../../../_shared/hooks/useCurrentUser";
import {getHeaderProps} from "../../../../../../../factory-info-set/InfoSetTable/structure";
import {
  InfoSetTableElement,
  ReactTableHeaderRowCell,
  ReactTableHeaderRowCellContent,
} from "../../../../../../../factory-info-set/InfoSetTable/styles";
import {ReactTableColumnHeader} from "../../../../../../../factory-info-set/InfoSetTable/components/ColumnHeader";

export const OrderFormTable = (props: {
  configurationId: string;
  modifiers: {[key: string]: DesignStudioModifier};
  orderFormSelections: any;
  tableData?: any;
}) => {
  const {tableData, modifiers, configurationId, orderFormSelections} = props;
  const {uid} = useCurrentUser();
  const columns = useMemo(() => orderFormColumns, []);

  const defaultColumn = useMemo(
    () => ({
      Cell: OrderFormTableCell,
      Filter: InfoSetColumnTextFilter,
    }),
    []
  );

  const initialState = useMemo(() => {
    return {
      sortBy: [
        {
          id: INTERNAL_CATEGORY_KEY,
          desc: false,
        },
        {
          id: LABEL_KEY,
          desc: false,
        },
      ],
      hiddenColumns: [ID_KEY],
    };
  }, []);

  const updateCellValue = useCallback(
    (id: string, value: string) => {
      if (configurationId) {
        const newSelections = {
          orderFormSelections: {
            ...orderFormSelections,
            [id]: {
              authorId: uid,
              timestamp: Date.now(),
              value: value,
            },
          },
        };
        return updateOrderFormSelections(configurationId, newSelections);
      }
    },
    [configurationId, orderFormSelections, uid]
  );

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(
      {
        // @ts-ignore
        updateCellValue,
        initialState,
        columns,
        data: tableData,
        // @ts-ignore
        defaultColumn,
        // @ts-ignore
        modifiers,
        autoResetSortBy: false,
        autoResetFilters: false,
      },
      useFilters,
      useSortBy
    );

  return (
    <OrderFormTableContainer>
      <InfoSetTableElement {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                const headerProps = getHeaderProps(column);
                return (
                  <ReactTableHeaderRowCell {...headerProps} onClick={undefined}>
                    <ReactTableHeaderRowCellContent>
                      <ReactTableColumnHeader
                        onClick={headerProps?.onClick}
                        column={column}
                      />
                      {column.canFilter ? column.render("Filter") : null}
                    </ReactTableHeaderRowCellContent>
                  </ReactTableHeaderRowCell>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <OrderFormTableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <OrderFormTableCellContainer {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </OrderFormTableCellContainer>
                  );
                })}
              </OrderFormTableRow>
            );
          })}
        </tbody>
      </InfoSetTableElement>
    </OrderFormTableContainer>
  );
};
