import React from "react";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import styled from "styled-components";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";

export const QualificationsAndAssumptions = () => {
  const {mergedCustomerProjectInfos} = useActiveProject();
  const {metadata} = mergedCustomerProjectInfos ?? {};
  if (metadata?.qualificationsAndAssumptions) {
    return (
      <div>
        <NatLabel
          label={"Qualifications & Assumptions"}
          type={NatLabelType.H2}
          style={{
            width: "100%",
            textAlign: "center",
            paddingBottom: "1rem",
          }}
        />
        <QualificationsAndAssumptionsTextContainer>
          <div
            dangerouslySetInnerHTML={{
              __html: metadata?.qualificationsAndAssumptions,
            }}
          />
        </QualificationsAndAssumptionsTextContainer>
      </div>
    );
  }
  return null;
};

const QualificationsAndAssumptionsTextContainer = styled.div`
  border-radius: 0.625rem;
  border: 1px solid var(--black-30, rgba(0, 0, 0, 0.3));
  background: rgba(226, 226, 226, 0.1);
  padding: 2rem;
  min-height: 20rem;
`;
