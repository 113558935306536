import {getProductRow} from "../_shared/helper";
import {IProduct} from "@natomas-org/core";
import {priceMicrosToMatrix} from "../../../utilities";
import {readDeepValue} from "../../../../../../../../_shared/Utils";
import {
  getProductMatrixModel,
  ProductStructure,
} from "../../../../../../../_models/product";

const getProductInfoRow = (
  key: string,
  title: string,
  type: string,
  products: IProduct[],
  edits: any
) => {
  let row: any = [
    {
      value: {
        title: title,
      },
      key: key,
      readOnly: true,
    },
  ];
  products?.forEach((p: IProduct) => {
    const pKey = p?.id + "_" + key;
    const editValue = edits?.[pKey]?.["product_info"];
    // @ts-ignore
    const v = readDeepValue(p, key);
    let value = priceMicrosToMatrix(v);
    if (!!edits?.[pKey]) {
      value = priceMicrosToMatrix(editValue);
    }
    row.push({
      // @ts-ignore
      key: pKey,
      value: value,
      valueType: type,
    });
  });
  return row;
};

export const getProductInfoTable = (products: IProduct[], edits: any) => {
  let table: any[][] = [];
  table.push(getProductRow(products));
  const rowDict = getProductMatrixModel(ProductStructure);
  const rowKeys = rowDict ? Object.values(rowDict) : [];
  rowKeys?.forEach((r: any) => {
    const rowType =
      r?.type === "number" && r?.subType === "price" ? "currency" : "string";
    table.push(getProductInfoRow(r?.path, r?.name, rowType, products, edits));
  });
  return table;
};
