import {PageContainer} from "../../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../../_shared/generics/page/components/PageElement";
import {NotServiceableBlurb} from "../../../views/styles";
import React from "react";
import {NatButton} from "../../../../_shared/generics/button";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {
  HOME_PAGE_ID,
  PROFILE_PAGE_ID,
} from "../../../../portal/_shared/navigation/constants";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../_shared/hooks/useDynamicValue";

export const NonServiceableBlurb = (props: {show: boolean; pid: string}) => {
  const {to} = useNavigation();
  const fullWidth = useDynamicValue(fullColumnWidths);
  if (props?.show) {
    return (
      <PageContainer>
        <PageElement size={fullWidth} height={"16rem"}>
          <NotServiceableBlurb>
            Sorry, there are no available ADUs in your area yet. We will contact
            you once we can service your area.
            <NatButton
              label={"Back to Dashboard"}
              type={"button"}
              trackingId={"catalog-back-to-dashboard"}
              option={StyleOption.PRIMARY_ALT}
              clickEvent={() =>
                to(NavigationPaths.PORTAL, {page: HOME_PAGE_ID, pid: props.pid})
              }
            />
            <NatButton
              label={"Change your project address"}
              type={"button"}
              trackingId={"catalog-back-to-profile"}
              option={StyleOption.SECONDARY_ALT}
              clickEvent={() => {
                to(NavigationPaths.PORTAL, {
                  page: PROFILE_PAGE_ID,
                  pid: props.pid,
                });
              }}
            />
          </NotServiceableBlurb>
        </PageElement>
      </PageContainer>
    );
  } else {
    return <></>;
  }
};
