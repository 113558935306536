import styled from "styled-components";

export const MainContainer = styled.div`
  margin-bottom: 0px;
`;
export const TableContainer = styled.div`
  width: 100%;
  //overflow-x: auto;
`;

export const HeaderRowContainer = styled.div`
  height: 50px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  position: sticky;
  top: 0px;
  display: flex;
  font-size: 14px;
  background-color: white;
  z-index: 10;
`;
export const Opaque = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(73, 166, 43, 0.4);
`;

export const RowContainer = styled.div<{isNatMobile: boolean}>`
  min-height: 50px;
  width: 100%;
  min-width: fit-content;
  ${(props) =>
    props.isNatMobile ? "" : "border-top: solid rgba(0, 0, 0, 0.1) 1px;"};
  position: relative;
  display: flex;
  font-size: 16px;
`;

export const FinalRowContainer = styled.div<{isNatMobile: boolean}>`
  min-height: 50px;
  width: 100%;
  min-width: fit-content;
  border-top: solid rgba(0, 0, 0, 0.1) 1px;
  border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
  position: relative;
  display: flex;
  font-size: 16px;
  ${(props) => (props.isNatMobile ? "margin-bottom: 20px;" : "")};
  ${(props) =>
    props.isNatMobile
      ? "background-color: white;"
      : "background-color: rgba(130, 194, 117, 0.4);"};
`;

export const ColumnText = styled.div`
  position: relative;
  margin: 10px;
  margin-left: 30px;
`;

export const FirstColumn = styled.div`
  min-height: 100%;
  min-width: 160px;
  max-width: 250px;
  width: 25%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const SecondColumn = styled.div`
  min-height: 100%;
  min-width: 160px;
  max-width: 200px;
  width: 15%;
  position: relative;
  display: flex;
  align-items: center;
  border-left: solid rgba(0, 0, 0, 0.1) 1px;
  border-right: solid rgba(0, 0, 0, 0.1) 1px;
`;

export const ThirdColumn = styled.div`
  min-height: 100%;
  min-width: 160px;
  flex-grow: 2;
  max-width: 50%;
  width: 30%;
  position: relative;
  display: flex;
  align-items: center;
`;

export const ErrorDiv = styled.div`
  position: relative;
  color: red;
`;
