import {
  AdminApi,
  InstantQuoteProductLocationSchema,
} from "@natomas-org/villa-nexus-client";
import {AxiosInstance} from "axios";
import {getAxiosInstance} from "../../setup";

export class AdminInstantQuoteService {
  private axiosInstance;
  private client;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new AdminApi(undefined, undefined, this.axiosInstance);
  }
  async create(productId: string, schema: InstantQuoteProductLocationSchema) {
    return this.client.createInstantQuoteProductPricing(productId, schema);
  }
  async update(productId: string, schema: InstantQuoteProductLocationSchema) {
    return this.client.updateInstantQuoteProductPricing(productId, schema);
  }

  async getLocations(productId: string) {
    return this.client
      .getInstantQuoteLocationsForProduct(productId)
      .then((res) => {
        return res?.data;
      });
  }

  async getLocation(locationId: string) {
    return this.client
      .getInstantQuotePricingLocationItems(locationId)
      .then((res) => {
        return res?.data;
      });
  }
}

export const getAdminInstantQuoteService = async () => {
  // InstantQuoteService does not require authentication to use
  const axiosInstance = await getAxiosInstance();
  return new AdminInstantQuoteService(axiosInstance);
};
