import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK, GRANITE} from "../../../../_shared/colors";

export const FilterModalTitleWrapper = styled.div`
  border-bottom: ${(props) =>
    props.theme?.Page?.isNatMobile ? "1px solid lightgray" : undefined};
  width: 100%;
  display: flex;
  flex-direction: row;
`;
export const FilterModalTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  align-items: center;
  color: ${(props) => (props.theme?.Page?.isNatMobile ? BLACK : GRANITE)};
  font-family: ${(props) =>
    props.theme?.Page?.isNatMobile ? FONT_FAMILY_BOLD : FONT_FAMILY_MEDIUM};
  justify-content: ${(props) =>
    props.theme?.Page?.isNatMobile ? "center" : "space-between"};
  width: 100%;
  padding: 0 1rem;
`;

export const IndividualFilterTitle = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${GRANITE};
  font-size: 0.85rem;
`;
