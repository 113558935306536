export const getEnumSelectOptions = <T extends object>(
  targetEnum: T
): {value: keyof typeof targetEnum; label: T[keyof T]}[] => {
  // ts-ignore
  return (Object.keys(targetEnum) as Array<keyof typeof targetEnum>)
    .map((key: keyof typeof targetEnum) => {
      return {
        value: key,
        label: targetEnum[key],
      };
    })
    .sort((a: any, b: any) => a.label.localeCompare(b.label));
};
