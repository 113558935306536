import {handleAddressSearch} from "./google-maps";
import {useCallback, useEffect, useState} from "react";
import {IAddressDetails} from "@natomas-org/core";

export const useAddress = (address?: string) => {
  const [details, setDetails] = useState<any | null>(null);

  const captureSearch = useCallback((details: IAddressDetails | null) => {
    setDetails(details);
  }, []);

  useEffect(() => {
    if (address) {
      handleAddressSearch(address, captureSearch, true);
    }
  }, [address]);

  return {
    raw: address,
    details,
  };
};
