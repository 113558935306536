import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";
import {NatButton} from "../../../../../_shared/generics/button";
import React from "react";
import {setStudioHash} from "../../../../../_shared/slices/StudioSlice";
import {useDispatch} from "react-redux";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import useActiveDesign from "../../../../../_shared/hooks/useDesign/useActiveDesign";
import useActivitySliceDispatcher from "../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";

export const StudioSectionButton = (props: {
  step: number;
  cartItemId?: string;
}) => {
  const {step} = props;
  const {configuration} = useActiveDesign();
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const {product} = configuration ?? {};
  const {to} = useNavigation();
  const dispatch = useDispatch();
  return (
    <>
      <br />
      {!product?.inventory_info && (
        <NatButton
          hidden={product?.disableCustomization}
          id={"studio-model-cta-step-" + step}
          label={"Edit"}
          type={"button"}
          size={NatSize.SMALL}
          option={StyleOption.PRIMARY_LINK}
          clickEvent={() => {
            dispatch(setStudioHash("category_step_container_" + step));
            setActiveCartItemById(props.cartItemId);
            to(NavigationPaths.STUDIO, {
              productId: product?.id,
              productGroupId: product?.productGroupId,
              cartItemId: props.cartItemId,
            });
          }}
        />
      )}
      {!!product?.inventory_info && (
        <NatButton
          id={"studio-inventory-cta-step-" + step}
          label={"View"}
          type={"button"}
          size={NatSize.SMALL}
          option={StyleOption.PRIMARY_LINK}
          clickEvent={() => {
            dispatch(setStudioHash("category_step_container_" + step));
            setActiveCartItemById(props.cartItemId);
            to(NavigationPaths.STUDIO, {
              productId: product?.id,
              productGroupId: product?.productGroupId,
              cartItemId: props.cartItemId,
            });
          }}
        />
      )}
    </>
  );
};
