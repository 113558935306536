import styled from "styled-components";
import {
  BLACK,
  PRIMARY_BUTTON_COLOR,
  VILLA_BLUE,
} from "../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../views/SSDDashboardView/styles/globals";

export const FAQContainer = styled.div`
  margin: 32px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;
export const FAQTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  margin: 0;
`;
export const FAQItemTitle = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 18px;
  user-select: none;
`;
export const FAQItemContent = styled.div<{
  expanded: boolean;
  isMobile: boolean;
}>`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 16px;
  cursor: auto;
  padding: ${(props) => (props.expanded ? "12px 0" : " 0")};
  ${(props) => (props.expanded ? "max-height: 450px" : "max-height: 0")};
  overflow: hidden;
  ${(props) => (props.expanded ? "opacity: 1" : "opacity: 0")};
  ${(props) => (props.expanded ? "" : "visibility: hidden")};
  transition: all 250ms;
  a {
    color: ${BLACK};
    text-decoration: underline;
  }
`;
export const FAQItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: auto;
  width: 100%;
`;
export const FAQLineAndArrowContainer = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;
export const Chevron = styled.div<{isActive: boolean}>`
  margin: 4px;
  width: 8px;
  height: 8px;
  border-top: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  transition: transform 200ms ease;

  ${(props) =>
    props.isActive &&
    `
      transform: rotate(135deg);
    `}
`;
export const FAQLink = styled.a`
  color: ${VILLA_BLUE};
  :hover {
    color: ${PRIMARY_BUTTON_COLOR};
  }
  font-family: ${FONT_FAMILY_BOLD};
  cursor: pointer;
`;
