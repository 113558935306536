import styled from "styled-components";

export const CartProjectSectionCollapsibleContainer = styled.div<{
  isOpen: boolean;
}>`
  max-height: ${(props) => (props.isOpen ? "fit-content" : "30px")};
  transition: 0.3s ease;
`;

export const CartProjectSectionChevron = styled.div<{isRotated: boolean}>`
  height: fit-content;
  width: fit-content;
  transform: ${(props) => (props.isRotated ? "rotate(90deg)" : "rotate(0deg)")};
  transition: transform 0.3s ease;
`;

export const CartProjectSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 16px;
  line-height: 16px;
  user-select: none;
  cursor: pointer;
`;
