import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../portal/views/SSDDashboardView/styles/globals";

export const ProductHelperRowContent = styled.tr<{complete?: boolean}>`
  background-color: ${(props) => (props.complete ? "#c6ffc7" : "#ffb4b4")};
  cursor: pointer;

  &:hover {
    background-color: #d2dbff;
  }
`;
export const ProductHelperTableHeader = styled.thead`
  position: sticky;
  top: 0;
  background-color: #d2dbff;
  border-bottom: 1px solid black;
`;

export const ProductHelperTable = styled.table`
  width: 100%;
  font-size: 0.85rem;
  font-family: ${FONT_FAMILY_REGULAR};
  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
  }
  th {
    font-family: ${FONT_FAMILY_MEDIUM};
    font-weight: normal !important;
  }
`;

export const ProductHelperKey = styled.div`
  padding: 0.5rem;
  flex-wrap: wrap;
  display: flex;
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_REGULAR};
`;

export const ItemList = styled.div`
  width: 100%;
  font-size: 0.85rem;
  font-family: ${FONT_FAMILY_REGULAR};
  max-height: 20rem;
  overflow-y: auto;
  position: relative;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
`;

export const ItemListHeader = styled.div`
  width: 100%;
  font-size: 0.85rem;
  padding: 0.5rem;
  line-height: 0.85rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  background-color: #fafafa;
  position: sticky;
  top: 0;
  border-bottom: 1px solid black;
`;

export const MissingModifierRowContainer = styled.div`
  font-size: 0.85rem;
  padding: 0.5rem;
  line-height: 0.85rem;
  border-bottom: 1px solid black;
  &:last-child {
    border-bottom: 0;
  }
  font-family: ${FONT_FAMILY_REGULAR};
  background-color: #f1f1f1;
`;
