import React, {useCallback, useMemo, useState} from "react";
import {Product, NatomasMapFeature} from "@natomas-org/core";
import {NatomasMap} from "../_shared/generics/feature-map";
import {
  IAdminFactoryLine,
  useAdminFactoryLines,
} from "../_shared/hooks/admin-only-hooks/useAdminFactoryLine/useAdminFactoryLines";
import {FeatureMapSideBar} from "../_shared/generics/feature-map/FeatureMapSideBar";
import {RoutingMapContainer} from "./styles";
import {IFeatureMapSidebarItem} from "../_shared/generics/feature-map/FeatureMapSideBar/sidebarItem";
import {useSelector} from "react-redux";
import {IStore} from "../_shared/slices/types/Store";
import {
  IAdminProduct,
  useAdminProducts,
} from "../_shared/hooks/admin-only-hooks/useAdminProducts";
import {updateMappingDoc} from "../../database/firebase/mapping/saving";
import {
  getAdminFactoryLineFeatureLabel,
  getAdminFactoryLineNatomasMapFeature,
  getAdminProductFeatureLabel,
  getAdminProductNatomasMapFeature,
} from "./helper";
import {getFeatureMapSideBarItem} from "../_shared/generics/feature-map/FeatureMapSideBar/helper";

const RoutingMap = () => {
  const allFactoryLines: IAdminFactoryLine[] = useAdminFactoryLines();
  const allProducts: IAdminProduct[] = useAdminProducts();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const {visible, editingFeatureId} = useSelector(
    (state: IStore) => state.natomasMap
  );

  const saveFeatureChanges = (f: NatomasMapFeature) => {
    if (editingFeatureId && !isSaving) {
      setIsSaving(true);
      updateMappingDoc(editingFeatureId, f).then((r) => {
        if (!r) {
          window.alert("Failed to update feature. Please try again.");
        }
        setIsSaving(false);
      });
    }
  };

  const getFactoryLineSidebarItem = useCallback(
    (
      factoryLine: IAdminFactoryLine,
      products: IAdminProduct[]
    ): IFeatureMapSidebarItem => {
      const label = getAdminFactoryLineFeatureLabel(factoryLine);
      const itemChildren: IFeatureMapSidebarItem[] = products
        ?.sort(
          (a: IAdminProduct, b: IAdminProduct) =>
            Product.getPriceInMicros(a.details.price, a.details.priceMicros) -
            Product.getPriceInMicros(b.details.price, b.details.priceMicros)
        )
        .map((p: IAdminProduct) => {
          const pLabel = getAdminProductFeatureLabel(p);
          return getFeatureMapSideBarItem(p?.id, pLabel, !!p?.mapping);
        });
      return getFeatureMapSideBarItem(
        factoryLine?.id,
        label,
        !!factoryLine?.mapping,
        itemChildren ?? []
      );
    },
    []
  );

  const getFactoryLineSidebarItems = useCallback(
    (
      factoryLines: IAdminFactoryLine[],
      allProducts: IAdminProduct[]
    ): IFeatureMapSidebarItem[] => {
      return factoryLines?.map((factoryLine) => {
        const factoryLineProducts = allProducts?.filter(
          (p: IAdminProduct) => p?.details?.productGroupId === factoryLine?.id
        );
        return getFactoryLineSidebarItem(factoryLine, factoryLineProducts);
      });
    },
    [getFactoryLineSidebarItem]
  );

  const items: IFeatureMapSidebarItem[] = useMemo(() => {
    const populatedFactoryLines = allFactoryLines?.filter(
      (factoryLine) => !!factoryLine?.mapping
    );
    const blankFactoryLines = allFactoryLines?.filter(
      (factoryLine) => !factoryLine?.mapping
    );
    return [
      ...getFactoryLineSidebarItems(populatedFactoryLines, allProducts),
      ...getFactoryLineSidebarItems(blankFactoryLines, allProducts),
    ];
  }, [allFactoryLines, allProducts, getFactoryLineSidebarItems]);

  const feature: NatomasMapFeature = useMemo(() => {
    const f = allFactoryLines?.find(
      (feature) => feature?.id === editingFeatureId
    );
    if (f && editingFeatureId) {
      return f?.mapping ? f.mapping : getAdminFactoryLineNatomasMapFeature(f);
    }
    const p = allProducts?.find((feature) => feature?.id === editingFeatureId);
    if (p && editingFeatureId && p?.details?.productGroupId) {
      return p?.mapping ? p.mapping : getAdminProductNatomasMapFeature(p);
    }
    return {
      label: `Feature: None`,
    };
  }, [allFactoryLines, allProducts, editingFeatureId]);

  const otherFeatures: NatomasMapFeature[] = useMemo(() => {
    const factoryLineMappingFeatures: NatomasMapFeature[] = allFactoryLines
      ?.filter(
        (factoryLine) =>
          factoryLine?.id !== editingFeatureId && visible[factoryLine?.id]
      )
      ?.map((factoryLine) => {
        if (factoryLine?.mapping) {
          return factoryLine.mapping;
        } else {
          return getAdminFactoryLineNatomasMapFeature(factoryLine);
        }
      });
    const productMappingFeatures: NatomasMapFeature[] = allProducts
      ?.filter(
        (adminProduct) =>
          adminProduct?.id !== editingFeatureId && visible[adminProduct?.id]
      )
      ?.map((adminProduct) => {
        if (adminProduct?.mapping) {
          return adminProduct.mapping;
        } else {
          return getAdminProductNatomasMapFeature(adminProduct);
        }
      });
    return [...factoryLineMappingFeatures, ...productMappingFeatures];
  }, [allFactoryLines, allProducts, editingFeatureId, visible]);

  return (
    <RoutingMapContainer>
      <FeatureMapSideBar items={items} title={"Catalog Routing"} />
      <NatomasMap
        allowEditing={!!editingFeatureId && !isSaving}
        feature={feature}
        saveFeature={!isSaving ? saveFeatureChanges : undefined}
        otherFeatures={otherFeatures}
      />
    </RoutingMapContainer>
  );
};

export default RoutingMap;
