import {useDispatch, useSelector} from "react-redux";
import {IStore} from "../slices/types/Store";
import {setShowAssessmentModal} from "../slices/SessionSlice/SessionSlice";

export const useAssessmentModal = () => {
  const dispatch = useDispatch();
  const set = (visible: boolean) => {
    return dispatch(setShowAssessmentModal(visible));
  };
  return {
    set,
    visible: useSelector((state: IStore) => state.session.showAssessmentModal),
  };
};
