import styled from "styled-components";
import {BORDER_RADIUS_TO_WIDTH_RATIO} from "../../constants";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";
import {BLACK, GRANITE} from "../../../_shared/colors";

export const ProjectSummaryContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const ProjectCarouselMobileContainer = styled.div`
  width: min-content;
`;

export const ProjectSummaryTextContainer = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProjectSummaryImageWrapper = styled.div<{
  widthRem: number;
  heightRem?: number;
}>`
  position: relative;
  width: ${(props) => `${props.widthRem}rem`};
  height: ${(props) => `${props.heightRem ?? props.widthRem * 0.67}rem`};
  overflow: hidden;
  align-items: center;
  border-radius: ${(props) =>
    `${props.widthRem * BORDER_RADIUS_TO_WIDTH_RATIO}rem`};
`;
export const ProjectSummaryTextTitle = styled.div`
  font-size: 2.15rem;
`;

export const ProjectSummaryTextSubtitle = styled.div`
  font-size: 1.5rem;
`;

export const ProjectSummaryButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding-top: 1rem;
`;

export const ProductSummaryTextSubtitle = styled.div<{isMobile?: boolean}>`
  font-size: ${(props) =>
    props.isMobile ? `0.85rem;color: ${GRANITE}` : "1rem"};
`;
export const ProductSummaryTextTitle = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${BLACK};
  font-size: 1.25rem;
`;
export const ProductSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  justify-content: center;
  width: min-content;
  align-items: flex-start;
  padding-bottom: 2rem;
`;

export const ProductSummaryWrapper = styled.div<{isNatMobile: boolean}>`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20rem;
  padding: ${(props) => (props.isNatMobile ? `0 0.25rem` : "0 0")};
`;

export const SplashScreenProjectSummaryProductContextTextContainer = styled.div`
  height: 2rem;
`;

export const ProjectSelectionCarouselContainer = styled.div`
  .slide.carousel.project-selector .carousel-control-prev {
    z-index: 99;
    box-sizing: border-box;
    height: 2rem;
    width: 2rem;
    top: calc(50% - 1rem);
    background-color: black !important;
    border-radius: 50%;
  }
  .slide.carousel.project-selector .carousel-control-next {
    z-index: 99;
    top: calc(50% - 1rem);
    height: 2rem;
    width: 2rem;
    background-color: black !important;
    border-radius: 50%;
  }
  .carousel-indicators {
    max-height: 1rem;
  }
  .carousel-indicators li {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: gray;
    height: 10px;
    width: 20px;
  }

  .carousel-indicators .active {
    border-top: unset;
    border-bottom: unset;
    border: 1px solid transparent;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 10px;
    background-color: black;
    height: 10px;
    width: 20px;
  }
`;
