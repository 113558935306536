import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {NatUndoIcon} from "../../../../_shared/icon/icons";
import {NatButton} from "../../../../_shared/generics/button";
import React from "react";

export const ResetButton = (props: {show: boolean; clickEvent: any}) => {
  return (
    <NatButton
      label={"Clear"}
      hidden={!props.show}
      clickEvent={props.clickEvent}
      option={StyleOption.PRIMARY_ALT}
      size={NatSize.XSMALL}
      icon={{
        icon: <NatUndoIcon />,
        iconPosition: IconPosition.ONLY,
      }}
    />
  );
};
