import {ICartItem} from "@natomas-org/core";

export enum CartSectionSortBy {
  LIST = "LIST",
  UNIT = "UNIT",
}

export interface CartActionsAvailable {
  move: boolean;
  add: boolean;
  edit: boolean;
  copy: boolean;
  quantity: boolean;
}

export interface CartBasicSectionProps {
  isMobile: boolean;
  sortBy: CartSectionSortBy;
  actionsAvailable: CartActionsAvailable;
  cartItems: ICartItem[];
  previewItem?: (cartItem: ICartItem) => void;
  editDisplayCodeItem?: (cartItem: ICartItem) => void;
  label?: string; // {LABEL} Units
  move?: {
    label: string;
    color: string;
    singleEvent: (id: string) => void;
    multipleEvent: (ids: string[]) => void;
  };
}

export interface CartSectionActions {
  areActionsAvailable: boolean;
  actionsAvailable: CartActionsAvailable;
  hasSelectedItems: boolean;
  areAllItemsSelected: boolean;
  select: {
    show: boolean;
    label: string;
    event: () => void;
  };
  move: {
    show: boolean;
    label: string;
    event: () => void;
  };
  add: {
    show: boolean;
    label: string;
    event: () => void;
  };
}
