import styled from "styled-components";
import {WHITE} from "../../../../_shared/colors";
import {IMAGE_Z_INDEX} from "../../../../_shared/styles";

export const StudioImageManagerContainer = styled.div<{
  height: string;
  stickied?: boolean;
}>`
  height: ${(props) => props.height};
  position: ${(props) => (props.stickied ? "fixed" : "relative")};
  border-right: ${(props) => !props.stickied && `1px solid rgba(0, 0, 0, 0.1)`};
  background-color: ${WHITE};
  z-index: ${IMAGE_Z_INDEX};
  top: ${(props) => props.stickied && "0"};
  ${(props) =>
    props.stickied &&
    `
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 17px rgba(97, 97, 97, 0.17);
  `}
`;
