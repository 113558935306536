import styled from "styled-components";
import {VALID_COLOR} from "../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../SSDDashboardView/styles/globals";

export type TAlternateDirection = "row-reverse" | "column" | "column-reverse";
export const CartViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
`;

export const CartRowContainer = styled.div<{
  alternateDirection?: TAlternateDirection;
  alternateGap?: number;
}>`
  display: flex;
  flex-direction: ${(props) => props.alternateDirection ?? "row"};
  gap: ${(props) => props.alternateGap ?? "16"}px;
`;

export const CartViewSwitchProjectsLink = styled.a`
  font-size: 16px;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${VALID_COLOR};
  cursor: pointer;
  user-select: none;
`;
