import {Dispatch, SetStateAction} from "react";
import {priceMicrosToMatrix, productGroupLevelKey} from "../../../../utilities";
import {DependenciesDataProps} from "../../../../DependenciesModal";
import {getProductRow} from "../../_shared/helper";
import {
  AdminProductCatalogMatrixModes,
  AdminProductCatalogMatrixSubModes,
  AdminProductCatalogMatrixTitleModes,
  IAdminProductCatalogMatrixFilterOptions,
} from "../../../../../../../../../_shared/slices/types/Store";
import {DesignStudioModifier} from "@natomas-org/core";

export interface AdminPriceSetTableProps {
  products: any;
  modifiers: string[];
  productModifiers: any;
  matrixEdits: any;
  setMatrixEdits: any;
  options: {
    matrixMode: AdminProductCatalogMatrixModes;
    transpose: boolean;
    setData: Dispatch<SetStateAction<DependenciesDataProps | undefined>>;
    filters: IAdminProductCatalogMatrixFilterOptions;
  };
}

const getModifierRow = (
  modifier: any,
  products: any[],
  productModifiers: any,
  matrixEdits: any,
  matrixSubMode: AdminProductCatalogMatrixSubModes,
  matrixTitleMode: AdminProductCatalogMatrixTitleModes
) => {
  // FIXME: This is strange - it seems there are modifier ids that aren't in currentGroupModifiers
  if (!modifier) {
    return;
  }
  const title = getModifierTitle(modifier, matrixTitleMode);
  const cellKey = productGroupLevelKey + "_" + modifier?.id;
  const row: any[] = [
    {
      value: modifier,
      readOnly: true,
    },
    getCell(matrixEdits, cellKey, modifier, title, matrixSubMode),
  ];
  const productCells = products.map((product: any) => {
    const specificProductModifiers = productModifiers[product.id];
    const cellKey = product.id + "_" + modifier?.id;
    const s_modifier =
      specificProductModifiers != null
        ? specificProductModifiers[modifier?.id]
        : null;
    return getCell(
      matrixEdits,
      cellKey,
      s_modifier,
      title,
      matrixSubMode,
      product
    );
  });

  return [...row, ...productCells];
};

export const getPriceSetTable = (
  products: any,
  modifiers: DesignStudioModifier[],
  productModifiers: any,
  matrixEdits: any,
  matrixSubMode: AdminProductCatalogMatrixSubModes,
  matrixTitleMode: AdminProductCatalogMatrixTitleModes
) => {
  let table: any[] = [];
  table.push(getProductRow(products, true));

  modifiers.forEach((modifier: any) => {
    if (!modifier) {
      return;
    }
    table.push(
      getModifierRow(
        modifier,
        products,
        productModifiers,
        matrixEdits,
        matrixSubMode,
        matrixTitleMode
      )
    );
  });
  return table;
};

const getDependencies = (
  currentDependencies: any[],
  matrixEditDependencies: any[],
  defaultValue?: {price: number; cost: number}
) => {
  let value: any[] = [];

  let hasDependencies = currentDependencies?.length > 0;
  let editDependencies = matrixEditDependencies;
  if (hasDependencies || editDependencies) {
    if (hasDependencies && !editDependencies) {
      value = currentDependencies?.map((d: any) => {
        return {
          priceMicros: d?.price?.price,
          costMicros: d?.price?.cost,
          requiredModifiers: d?.dependentModifiers,
          condition: d?.condition,
          type: d?.type,
        };
      });
    }
    if (editDependencies) {
      value = editDependencies;
    }
  }
  if (!value?.find((d: any) => d?.condition === "default")) {
    return [
      {
        priceMicros: defaultValue?.price,
        costMicros: defaultValue?.cost,
        requiredModifiers: [],
        condition: "default",
        type: "baseline",
      },
      ...value,
    ];
  }
  return value;
};

const getCellValue = (
  modifier: any,
  matrixSubMode: AdminProductCatalogMatrixSubModes,
  editValue?: any
) => {
  const defaultPrice = modifier?.price_set?.find(
    (price: any) => price?.condition === "default" && price?.type === "baseline"
  );
  if (matrixSubMode === AdminProductCatalogMatrixSubModes.PRICE) {
    return editValue != null
      ? {
          price: editValue?.priceMicros,
          cost: editValue?.costMicros,
        }
      : defaultPrice?.price;
  } else {
    return editValue != null
      ? {
          price: editValue?.priceMicros,
          cost: editValue?.costMicros,
        }
      : defaultPrice?.price;
  }
};

const getCell = (
  matrixEdits: any,
  cellKey: any,
  modifier: any,
  modifierTitle: string,
  matrixSubMode: AdminProductCatalogMatrixSubModes,
  product?: any
) => {
  const matrixEditDependencies =
    matrixEdits?.[cellKey] != null
      ? matrixEdits?.[cellKey]?.["price_set"]
      : null;

  const matrixEditValue = matrixEditDependencies?.find(
    (d: any) => d?.condition === "default"
  );
  let value = getCellValue(modifier, matrixSubMode, matrixEditValue);
  let title = !!product ? product?.title : "Default";
  let dependencies = {
    value: getDependencies(
      modifier?.price_set ?? [],
      matrixEditDependencies,
      value
    ),
    hasEdits:
      matrixEditDependencies?.filter((d: any) => d?.condition !== "default")
        ?.length > 0,
    title: "Price Settings: " + title + " ~ " + modifierTitle,
  };

  let cellValue =
    matrixSubMode === AdminProductCatalogMatrixSubModes.PRICE
      ? priceMicrosToMatrix(value?.price)
      : priceMicrosToMatrix(value?.cost);

  return {
    value: cellValue,
    cost: value?.cost,
    price: value?.price,
    key: cellKey,
    valueType: "currency",
    dependencies: dependencies,
  };
};

export const getModifierTitle = (
  modifier: DesignStudioModifier,
  titleMode: AdminProductCatalogMatrixTitleModes
) => {
  return titleMode === AdminProductCatalogMatrixTitleModes.TITLE
    ? modifier?.title
    : modifier?.internal_title;
};
