import styled from "styled-components";
import {BREAKPOINT_DESKTOP_SMALL, colors} from "../../theme/constants";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const MapViewContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse;
  }
  #map__back-btn {
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colors.textGrey};
    height: 40px;
    padding: 20px;
    background: #ffffff;
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 4999;
    svg {
      margin-right: 8px;
    }
  }
  .leaflet-right .leaflet-control {
    margin-right: 24px;
  }
  .leaflet-control-zoom.leaflet-control {
    margin-top: 20px;
  }
  @media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
    .leaflet-right .leaflet-control {
      margin-right: 24px;
    }
    .leaflet-control-zoom.leaflet-control {
      margin-bottom: 22px;
    }
  }
`;

export const MapContainerWrapper = styled.div<{isListExpanded: boolean}>`
  flex: ${(props) => (props.isListExpanded ? "0" : "1")};
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  transition: flex 0.4s;
  .leaflet-container {
    z-index: 0;
  }
  @media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
    height: 100%;
    max-height: 100vh;
  }
`;

export const TitleOverlayContainer = styled.div<{minimal: boolean}>`
  position: absolute;
  top: ${(props) => (props.minimal ? "10px" : "30px")};
  left: ${(props) => (props.minimal ? "10px" : "30px")};
  z-index: 4999;
  background-color: rgba(50, 50, 50, 0.77);
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  color: white;
  padding: 8px 16px;
  max-width: 40%;
`;

export const TitleText = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.625rem;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const SubtitleText = styled.div`
  font-size: 1rem;
  font-family: ${FONT_FAMILY_REGULAR};
  margin-bottom: 8px;
`;

export const NatPopupUserFeedbackContainerFull = styled.div<{
  minimal: boolean;
  displayOnLeft?: boolean;
}>`
  z-index: 4999;
  ${(props) => (props.displayOnLeft ? "font-size: 16px" : "font-size: 20px")};
  max-width: 50%;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.minimal ? 0.7 : 1)};
  &:hover {
    opacity: 1;
  }
`;

export const DisclaimerContainer = styled.div`
  max-width: 50%;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 4999;
  background: white;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #000;
  padding: 8px 16px;
  font-size: 0.8rem;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;
export const MapToolbarContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 0;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    width: 40px;
    padding: 0;
    height: 40px;
    background: #ffffff;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  @media screen and (min-width: ${BREAKPOINT_DESKTOP_SMALL}px) {
    bottom: 40px;
    right: 172px;
  }
`;

export const MapButtonContainer = styled.div`
  height: 3.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
`;
