import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {IActivitySlice} from "../../slices/ActivitySlice/ActivitySlice";

const useActivitySlice = () => {
  return (useSelector((state: IStore) => state.activity) ??
    {}) as IActivitySlice;
};

export default useActivitySlice;
