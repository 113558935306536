import React from "react";
import {MdArrowForwardIos} from "react-icons/md";
import {DrawerToggleArrowWrapper} from "./styles";

export const DrawerToggle = (props: {currentState: boolean}) => {
  const {currentState} = props;

  return (
    <DrawerToggleArrowWrapper expanded={currentState}>
      <MdArrowForwardIos size={14} />
    </DrawerToggleArrowWrapper>
  );
};
