import {openURLInNewTab} from "../../../navigation";
import {
  DESIGN_PAGE_ID,
  HOME_PAGE_ID,
  INSTANT_ESTIMATE_PAGE_ID,
  OVERVIEW_ASSESSMENT_PAGE_ID,
  PROFILE_PAGE_ID,
  PROPOSAL_PAGE_ID,
  SITE_PAGE_ID,
} from "../../../../portal/_shared/navigation/constants";
import {NavigationPaths} from "../paths";

export const toProposal = (to: any, pid: string) => {
  to(NavigationPaths.PORTAL, {page: OVERVIEW_ASSESSMENT_PAGE_ID, pid: pid});
};
export const toInstantQuote = (to: any, pid: string) => {
  to(NavigationPaths.PORTAL, {page: INSTANT_ESTIMATE_PAGE_ID, pid: pid});
};
export const toProposalPost = (to: any, pid: string) => {
  to(NavigationPaths.PORTAL, {page: PROPOSAL_PAGE_ID, pid: pid});
};
export const toDashboard = (to: any, pid: string) => {
  to(NavigationPaths.PORTAL, {page: HOME_PAGE_ID, pid: pid});
};
export const toHomePost = (to: any, pid: string) => {
  to(NavigationPaths.PORTAL, {page: DESIGN_PAGE_ID, pid: pid});
};
export const toSiteWorkPost = (to: any, pid: string) => {
  to(NavigationPaths.PORTAL, {page: SITE_PAGE_ID, pid: pid});
};
export const toProfile = (to: any, pid: string) => {
  to(NavigationPaths.PORTAL, {page: PROFILE_PAGE_ID, pid: pid});
};
export const toMainSite = () => {
  openURLInNewTab("https://www.villahomes.com");
};
export const toContactUs = () => {
  openURLInNewTab("https://villahomes.com/contact-us");
}
