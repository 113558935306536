export const convertOptionKeysToOptions = (optionKeys: any) => {
  if (optionKeys == null) {
    return [];
  }
  return Object.keys(optionKeys).map((key) => {
    const optionKey = optionKeys[key];
    return {
      label:
        optionKey.name != null && optionKey.name.length > 0
          ? optionKey.name
          : key,
      value: key,
    };
  });
};

export const convertOptionKeyToOptions = (
  optionKeyId: any,
  optionKeys: any
) => {
  if (optionKeys == null || optionKeys[optionKeyId] == null) {
    return {
      label: optionKeyId,
      value: optionKeyId,
    };
  }
  const optionKey = optionKeys[optionKeyId];
  return [
    {
      label: optionKey.name,
      value: optionKey.id,
    },
  ];
};

export const convertDBOptionKeysToOptionsCurrentSelection = (
  optionKeyIdMap: any,
  optionKeys: any
) => {
  if (optionKeys == null || optionKeyIdMap == null) {
    return [];
  }

  return Object.keys(optionKeyIdMap).map((optionKeyId) => {
    const optionKey = optionKeys[optionKeyId];
    return {
      label: optionKey.name,
      value: optionKey.id,
    };
  });
};

export const convertOptionsToOptionKey = (options: any) => {
  if (options == null || options.length < 1) {
    return null;
  }
  return options[0].value;
};

export const convertKeyOptionsToDBKeys = (options: any) => {
  if (options == null || options.length < 1) {
    return null;
  }

  const optionKeyIdMap = {};
  options.forEach((option: any) => {
    // @ts-ignore
    optionKeyIdMap[option.value] = true;
  });
  return optionKeyIdMap;
};
