import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useMemo} from "react";
import {getProjectNotes} from "./helper";

export const useProjectNotes = (projectId?: string) => {
  const notes = useSelector((state: IStore) =>
    getProjectNotes(state, projectId)
  );

  const getProjectNoteById = (noteId: string) => {
    if (!notes) {
      return null;
    }
    return notes[noteId];
  };
  const projectNotesList = useMemo(() => {
    if (!notes) {
      return [];
    }
    return Object.values(notes)
      .filter((note) => {
        return note != null;
      })
      .sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
  }, [notes]);

  return {
    getProjectNoteById,
    projectNotes: projectNotesList,
  };
};
