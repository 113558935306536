import React from "react";
import {FONT_FAMILY_BOLD} from "../../../portal/views/SSDDashboardView/styles/globals";
import {NatButton} from "../../../_shared/generics/button";
import {SmartImage} from "../../../_shared/generics/image/SmartImage";
import {
  AppSplashImageContainer,
  AppSplashImageWrapper,
  AppTileContainer,
} from "./styles";
import {usePage} from "../../../_shared/hooks/usePage";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {BORDER_RADIUS_TO_WIDTH_RATIO} from "../../constants";

export const AppTile = (props: {
  title: string;
  ctaAction: () => void;
  ctaText: string;
  description?: string;
  imageId: string;
  backgroundColor: string;
  textColor: string;
  trackingId?: string;
}) => {
  const {
    title,
    ctaAction,
    ctaText,
    description,
    imageId,
    backgroundColor,
    textColor,
    trackingId,
  } = props;
  const {isNatMobile} = usePage();
  const imageHeight = useDynamicValue({
    forFour: 12,
    forEight: 16,
    forTwelve: 20,
    forSixteen: 24,
  });
  return (
    <AppTileContainer
      isMobile={isNatMobile}
      backgroundColor={backgroundColor}
      textColor={textColor}
    >
      <AppSplashImageWrapper>
        <AppSplashImageContainer
          style={{
            width: `${imageHeight * 1.35}rem`,
            height: `${imageHeight}rem`,
            borderRadius: `${imageHeight * BORDER_RADIUS_TO_WIDTH_RATIO}rem`,
          }}
        >
          <SmartImage imageURL={imageId} style={{objectFit: "cover"}} />
        </AppSplashImageContainer>
      </AppSplashImageWrapper>
      <br />
      <div style={{fontSize: "1.5rem", fontFamily: FONT_FAMILY_BOLD}}>
        {title}
      </div>
      <br />
      {description && <div>{description}</div>}
      <br />
      <NatButton
        label={ctaText}
        clickEvent={ctaAction}
        trackingId={trackingId}
      />
    </AppTileContainer>
  );
};
