import styled from "styled-components";
import {
  BUBBLE_BACKGROUND_COLOR,
  VILLA_APPLE_GREEN,
  VILLA_APPLE_GREEN_HOVER,
  WHITE,
} from "../../../colors";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const CartButtonContainer = styled.div`
  position: relative;
  background-color: ${VILLA_APPLE_GREEN};
  display: inline-flex;
  justify-content: center;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: ${VILLA_APPLE_GREEN_HOVER};
  }
  svg {
    height: 20px;
    width: 20px;
  }
  transition: all ease-in-out 100ms;
`;

export const CartButtonCountBubble = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  height: 20px;
  width: 20px;
  font-size: 16px;
  font-family: ${FONT_FAMILY_MEDIUM};
  color: ${WHITE};
  background-color: ${BUBBLE_BACKGROUND_COLOR};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  &:hover {
    scale: 1.1;
  }
  transition: all ease-in-out 100ms;
`;
