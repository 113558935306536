import {IProduct} from "@natomas-org/core";

export function curveNumber(
  value: number,
  lowerBound: number,
  upperBound: number,
  values: number[]
): number {
  const min = Math.min(...values);
  const max = Math.max(...values);
  const range = max - min;
  const targetRange = upperBound - lowerBound;
  const normalizedValue = (value - min) / range;
  const curvedValue = normalizedValue * targetRange + lowerBound;
  return Math.round(curvedValue);
}

export function reduceMap(
  data: {[productId: string]: number},
  numElements: number
) {
  // Convert the data structure into an array of key-value pairs
  const entries = Object.entries(data);
  const numElementsToRemove = entries?.length - numElements;
  // Sort the array based on the values in ascending order
  entries.sort((a, b) => a[1] - b[1]);

  // Remove the desired number of elements from the beginning of the sorted array
  let reducedEntries = entries;
  if (numElementsToRemove > 0) {
    reducedEntries = entries.slice(numElementsToRemove);
  }
  // Convert the reduced array back into the original data structure
  return Object.fromEntries(reducedEntries);
}

export const sortProductsByRecommendation = (
  recommendationsForProducts: {[productId: string]: {percentScore?: number}},
  a: IProduct,
  b: IProduct
) => {
  if (!a?.id || !b?.id) {
    return 0;
  }
  const aRecommendation: number | undefined =
    recommendationsForProducts?.[a?.id]?.percentScore;
  const bRecommendation: number | undefined =
    recommendationsForProducts?.[b?.id]?.percentScore;
  if (!aRecommendation) {
    return 1;
  } else if (!bRecommendation) {
    return -1;
  }
  if (aRecommendation && bRecommendation) {
    return bRecommendation - aRecommendation;
  }
  return 0;
};
