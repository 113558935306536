import React, {useCallback} from "react";
import styled from "styled-components";
import {
  ADMIN_BUTTON_ACTIVE,
  BLACK,
  VILLA_BLUE,
  WHITE,
} from "../../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {IFactoryInventoryTableItem} from "./interfaces";
import {getStatusLabel} from "./helper";

interface AdminInventoryStatusControllerProps {
  inventory: IFactoryInventoryTableItem[];
  filters: any;
  setFilter: any;
}

const InventoryStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  background-color: ${VILLA_BLUE};
`;

const InventoryStatusToggleContainerLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  height: 35px;
  border-bottom: 1px solid ${BLACK};
  background-color: ${VILLA_BLUE};
  color: ${WHITE};
  font-family: ${FONT_FAMILY_MEDIUM};
`;
const InventoryStatusTogglesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 4px;
`;

const InventoryColumnToggle = styled.label<{selected: boolean}>`
  margin: 0;
  height: auto;
  font-size: 12px;
  padding: 6px;
  color: ${WHITE};
  font-family: ${FONT_FAMILY_MEDIUM};
  user-select: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: ${(props) =>
    props.selected ? ADMIN_BUTTON_ACTIVE : VILLA_BLUE};
`;

const AdminInventoryStatusController = (
  props: AdminInventoryStatusControllerProps
) => {
  const {inventory, setFilter, filters} = props;

  const statusFilterValue: number[] = filters?.find(
    (filter: {id: string}) => filter?.id === "status"
  )?.value;

  const updateFilter = useCallback(
    (num: number, statusFilterValue?: number[]) => {
      let currents = statusFilterValue ?? [];
      let value;
      if (currents?.includes(num)) {
        value = currents?.filter((filterValue: number) => num !== filterValue);
      } else {
        value = [...currents, num];
      }
      setFilter(value);
    },
    [setFilter]
  );

  const getStatusCount = (status: number) => {
    return inventory?.filter((item) => item.status === status).length;
  };

  return (
    <InventoryStatusContainer>
      <InventoryStatusToggleContainerLabel>
        Status
      </InventoryStatusToggleContainerLabel>
      <InventoryStatusTogglesContainer>
        {[4, 3, 2, 1, 0].map((status) => {
          return (
            <InventoryColumnToggle
              selected={
                !statusFilterValue || statusFilterValue?.includes(status)
              }
              onClick={() => updateFilter(status, statusFilterValue)}
              key={`show-${status}-statuses`}
            >
              {getStatusLabel(status)} ({getStatusCount(status)})
            </InventoryColumnToggle>
          );
        })}
      </InventoryStatusTogglesContainer>
    </InventoryStatusContainer>
  );
};

export default AdminInventoryStatusController;
