import {
  ADUQuiz_PlannedUse,
  CatalogQuizAnswers,
  HTMLInputTypeAttributes,
  IPardotIntakeFormInput,
} from "@natomas-org/core";
import {
  ADUQuestion,
  ADUQuiz_QuestionIds,
  aDUQuizPlannedUseTextMap,
  ADUQuizQuestions,
} from "../ADUQuizStructure";

const getPardotBaseValuesFromADUQuizQuestions = (
  questionId: ADUQuiz_QuestionIds,
  questions: ADUQuestion[]
): {inputName: string; inputType: HTMLInputTypeAttributes} | null => {
  const basePardotInfo = questions?.find(
    (question) => question.questionId === questionId
  )?.pardotInformation;
  if (!basePardotInfo) {
    return null;
  } else {
    return {
      inputName: basePardotInfo.inputName,
      inputType: basePardotInfo.inputType,
    };
  }
};
export const enrichQuizResultsWithPardotInformation = (
  answers: CatalogQuizAnswers
): CatalogQuizAnswers => {
  const pardotValues: IPardotIntakeFormInput[] = [];
  for (let answer in ADUQuiz_QuestionIds) {
    const answerKey =
      ADUQuiz_QuestionIds[answer as keyof typeof ADUQuiz_QuestionIds];

    const key: ADUQuiz_QuestionIds = answerKey as ADUQuiz_QuestionIds;
    const basePardotValues = getPardotBaseValuesFromADUQuizQuestions(
      key as ADUQuiz_QuestionIds,
      ADUQuizQuestions
    );
    const inputValue = answers[key];
    if (basePardotValues && !!inputValue) {
      let sanitizedInputValue: string = inputValue.toString();
      if (key === ADUQuiz_QuestionIds.PlannedUse) {
        sanitizedInputValue = (inputValue as ADUQuiz_PlannedUse[])
          .map((val) => aDUQuizPlannedUseTextMap[val])
          .toString();
      }
      if (key === ADUQuiz_QuestionIds.Budget) {
        sanitizedInputValue =
          ADUQuizQuestions.find(
            (question) => question.questionId === ADUQuiz_QuestionIds.Budget
          )?.answers.find((answer) => answer.answerValue === inputValue)
            ?.answerText || "";
      }
      pardotValues.push(
        Object.assign({}, basePardotValues, {
          inputValue: sanitizedInputValue,
        })
      );
    }
  }
  return Object.assign({}, answers, {pardotInformation: pardotValues});
};
