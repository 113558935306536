import {store} from "../../../../../store";
import {getProductPricingInfo} from "../../../../_shared/_legacy/logic/ProductUtils";
import {PricingMode} from "../../../../_shared/slices/GlobalSlice";
import {ListingStatus} from "@natomas-org/core";

export const getRelatedProducts = (
  baseProduct: any,
  products: any[],
  desiredCount: number
) => {
  if (desiredCount <= 0 || !baseProduct || !products || products.length === 0) {
    return [];
  }
  let relatedProducts: any[] = [];
  if (baseProduct.inventory_info) {
    // Base product is inventory
    // Structure: Base Model - up to 5 Inventory Units of that Model - More Models
    // 1. Get the model, and append it to list
    const baseModel = getModelProduct(baseProduct, products);
    // If no base model, exit
    if (!baseModel) {
      return relatedProducts;
    }
    relatedProducts.push(baseModel);
    // 2. Grab inventory units based on this model
    const inventoryUnits = getInventoryProducts(baseModel, products, [
      baseProduct,
    ]);
    // 3. Sorted by DIFFERENCE in price, low -> high
    const sortedInventoryUnits = getSortedListByBasePriceDifference(
      baseProduct,
      inventoryUnits
    );
    // 4. Append up to 5 units sorted by DIFFERENCE in price
    for (
      let i = 0;
      i < 5 &&
      i < sortedInventoryUnits.length &&
      relatedProducts.length < desiredCount;
      i++
    ) {
      relatedProducts.push(sortedInventoryUnits[i]);
    }
    // 5. Remove the base product + inventory units from the products list
    const otherModels = getOtherModels(baseModel, products);
    // 6. Sort products by the DIFFERENCE in price
    const sortedOtherModels = getSortedListByBasePriceDifference(
      baseModel,
      otherModels
    );
    // 7. Append until the total count is desiredCount or we run out
    for (
      let j = 0;
      j < sortedOtherModels.length && relatedProducts.length < desiredCount;
      j++
    ) {
      relatedProducts.push(otherModels[j]);
    }
  } else {
    // Base product is a model
    // Structure: Up to 3 Inventory Units of that Model - More Models
    // 1. Grab any inventory units of the model
    const inventoryUnits = getInventoryProducts(baseProduct, products);
    // 2. Sorted by DIFFERENCE in price, low -> high
    const sortedInventoryUnits = getSortedListByBasePriceDifference(
      baseProduct,
      inventoryUnits
    );
    // 3. Append up to 3 inventory units based on this model
    for (
      let i = 0;
      i < 3 &&
      i < sortedInventoryUnits.length &&
      relatedProducts.length < desiredCount;
      i++
    ) {
      relatedProducts.push(sortedInventoryUnits[i]);
    }
    // 4. Remove the base product + inventory units from the products list
    const otherModels = getOtherModels(baseProduct, products);
    // 5. Sort products by the DIFFERENCE in price
    const sortedOtherModels = getSortedListByBasePriceDifference(
      baseProduct,
      otherModels
    );
    // 6. Append until the total count is desiredCount or we run out
    for (
      let j = 0;
      j < sortedOtherModels.length && relatedProducts.length < desiredCount;
      j++
    ) {
      relatedProducts.push(otherModels[j]);
    }
  }
  return relatedProducts;
};

const getOtherModels = (baseProduct: any, products: any[]) => {
  // Exclude the base product and all inventory units
  return products.filter(
    (p: any) =>
      baseProduct.id !== p.id &&
      !p.inventory_info &&
      ListingStatus.isListed(p?.status)
  );
};

const getSortedListByBasePriceDifference = (
  baseProduct: any,
  products: any[]
) => {
  const state = store.getState();
  const pricingMode = state?.global.config?.PRICING_MODE ?? PricingMode.UNIT;
  const baseProductPrice = getProductPricingInfo(
    pricingMode,
    baseProduct
  ).price;
  return products.sort((a: any, b: any) => {
    const aPrice = Math.abs(
      baseProductPrice - getProductPricingInfo(pricingMode, a).price
    );
    const bPrice = Math.abs(
      baseProductPrice - getProductPricingInfo(pricingMode, b).price
    );
    return aPrice - bPrice;
  });
};

const isExcluded = (product: any, excluding?: any[]) => {
  return excluding?.find((p: any) => p.id === product.id);
};

const getInventoryProducts = (
  baseProduct: any,
  products: any[],
  excluding?: any[]
) => {
  return products.filter(
    (p: any) =>
      p?.inventory_info?.originalProductId === baseProduct?.id &&
      !isExcluded(p, excluding) &&
      ListingStatus.isListed(p?.status)
  );
};

const getModelProduct = (inventoryUnit: any, products: any[]) => {
  if (inventoryUnit.inventory_info.original_product_visibility === "hidden") {
    return null;
  }
  return products.find(
    (p: any) => p.id === inventoryUnit.inventory_info.originalProductId
  );
};
