import React, {useState} from "react";
import {useAdminFactoryLine} from "../_shared/hooks/admin-only-hooks/useAdminFactoryLine";
import {useCatalogSession} from "../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {InfoSetTable} from "./InfoSetTable";
import {NatToggleSelector} from "../_shared/generics/toggle-selector";
import {FactoryInfoSetModeContainer} from "./styles";
import FactoryInfoForm from "./FactoryInfoForm";

export const FactoryInfoSet = () => {
  const {selectedProductGroupId, selectedProductGroupVersionId} =
    useCatalogSession();
  const factory = useAdminFactoryLine(
    selectedProductGroupId ?? undefined,
    selectedProductGroupVersionId ?? undefined
  );
  const [mode, setMode] = useState<"infoset" | "more">("infoset");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <FactoryInfoSetModeContainer>
        <NatToggleSelector
          options={[
            {
              id: "infoset",
              label: "InfoSet",
              isSelected: mode === "infoset",
              clickEvent: () => {
                setMode("infoset");
              },
            },
            {
              id: "more",
              label: "More",
              isSelected: mode === "more",
              clickEvent: () => {
                setMode("more");
              },
            },
          ]}
        />
      </FactoryInfoSetModeContainer>
      {mode === "infoset" && factory && (
        <InfoSetTable factoryLine={factory ?? {}} />
      )}
      {mode === "more" && factory?.info && (
        <FactoryInfoForm info={factory?.info} />
      )}
    </div>
  );
};
