import styled from "styled-components";
import {BLACK} from "../../../../../../../../_shared/colors";

export const ConditionAssociationsTableContainer = styled.table`
  font-size: 0.75rem;
  transition: all 150ms ease-in;
  overflow: auto;
  display: block;
  position: relative;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  background-color: #656a76;

  thead {
    th {
      &:first-child {
        left: 0;
        top: 0;
        position: sticky;
      }

      background-color: #e6ebff;
      border-right: 1px solid ${BLACK};
    }
  }

  tbody {
    tr {
      height: 3rem;

      &:last-child {
        td {
          border-bottom: 0;
        }
      }

      td {
        &:first-child {
          left: 0;
          position: sticky;
        }

        min-width: 10rem;
      }
    }
  }
`;
