import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  NatModal,
  NatModalSize,
} from "../../../../../_shared/generics/modal/NatModal";
import {ICartItem} from "@natomas-org/core";
import {useCartItemMetadata} from "../../../../../_shared/hooks/useCart/useCartItem/useCartItemMetadata";
import useCatalogCartController from "../../../../../_shared/hooks/useCart/Controllers/useCatalogCartController";
import _ from "lodash";
import NatTextInput from "../../../../../_shared/forms/inputs/NatTextInput";
import {NatSize, StyleOption} from "../../../../../_shared/generics/_shared";

interface CartItemDisplayCodeEditorProps {
  cartItem: ICartItem | undefined;
  onClose: () => void;
}

const CartItemDisplayCodeEditor = (props: CartItemDisplayCodeEditorProps) => {
  const {cartItem, onClose} = props;

  const {details, id} = cartItem ?? {};
  const {display_code} = details ?? {};

  const [editorDisplayCode, setEditorDisplayCode] = useState(display_code);
  const {source} = useCartItemMetadata(cartItem);
  const {updateCartItemDisplayCode} = useCatalogCartController();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const inputValue: string = event.target.value;
      // Do something with the input value
      setEditorDisplayCode(inputValue);
    },
    []
  );

  useEffect(() => {
    setEditorDisplayCode(display_code);
  }, [display_code]);

  const reset: () => void = useCallback(() => {
    setEditorDisplayCode(display_code);
  }, [display_code]);

  const saveChanges: () => void = useCallback(() => {
    if (!cartItem) {
      return;
    }
    if (!editorDisplayCode) {
      reset();
      return;
    }
    if (editorDisplayCode?.length === 0) {
      reset();
      return;
    }
    updateCartItemDisplayCode(editorDisplayCode, cartItem, source);
    onClose();
  }, [
    cartItem,
    editorDisplayCode,
    onClose,
    reset,
    source,
    updateCartItemDisplayCode,
  ]);

  const canSave: boolean = useMemo(() => {
    return !_.isEqual(editorDisplayCode, display_code);
  }, [editorDisplayCode, display_code]);

  return (
    <NatModal
      show={!!cartItem}
      handleClose={() => {
        reset();
        onClose();
      }}
      header={`Change label of '${display_code}'`}
      size={NatModalSize.FULLSCREEN}
      content={
        <NatTextInput
          type={"text"}
          id={`cart-item-${id}-setter`}
          placeholder={"Cart Item Label"}
          value={editorDisplayCode ?? ""}
          onChange={handleInputChange}
          onBlur={handleInputChange}
        />
      }
      footerButtons={[
        {
          label: "Cancel",
          clickEvent: () => {
            reset();
            onClose();
          },
          size: NatSize.SMALL,
          option: StyleOption.SECONDARY,
        },
        {
          label: "Save",
          clickEvent: saveChanges,
          disabled: !canSave,
          size: NatSize.SMALL,
          option: StyleOption.PRIMARY,
        },
      ]}
    />
  );
};

export default CartItemDisplayCodeEditor;
