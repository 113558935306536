import styled from "styled-components";

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 16px;
`;

export const Label = styled.h1<{isMobile?: boolean}>`
  font-size: ${(props) => (props.isMobile ? "1.25rem" : "2rem")};
  padding: 0;
  margin: 0;
`;
