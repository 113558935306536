import {
  InfoSubtitle,
  InfoTitle,
  MainRowCell,
  Milestone,
  MobileMonthNameRow,
  MobilePhaseBox,
  MobilePhaseTitle,
  MobileRowBlock,
  MobileStageMonthCountDiv,
  MobileStageMonthTooltipDiv,
  MobileStageTitleDiv,
  MobileTimelineRow,
  PhaseBox,
  PhaseTitle,
  RowBlock,
  RowCell,
  TimelineRow,
  TimelineRowInfoContainer,
  TimelineRowTop,
  TimelineToolTip,
  TimelineToolTipContent,
  TimelineToolTipFooter,
  TimelineToolTipTitle,
} from "../styles";
import ReactTooltip from "react-tooltip";
import React from "react";
import {DateUtils} from "@natomas-org/core";
import {GoInfo} from "react-icons/go";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {ALL_MONTHS, INextStepInfo} from "../constants/TimelineTypes";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

// export function monthDiff(d1: Date, d2: Date) {
//   let months;
//   months = (d2.getFullYear() - d1.getFullYear()) * 12;
//   months -= d1.getMonth();
//   months += d2.getMonth();
//   return months <= 0 ? 0 : months;
// }

function daysInMonth(year: number, month: number): number {
  return new Date(year, month + 1, 0).getDate();
}

export function monthDiff(date1: Date, date2: Date): number {
  // Make sure date1 is the earlier date
  if (date1 > date2) {
    [date1, date2] = [date2, date1];
  }

  let start = new Date(date1.getFullYear(), date1.getMonth(), 1);
  let end = new Date(date2.getFullYear(), date2.getMonth(), 1);

  let months = 0;
  while (start < end) {
    months++;
    start.setMonth(start.getMonth() + 1);
  }

  if (start > date2) {
    const daysOver =
      (start.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
    const fraction =
      daysOver / daysInMonth(date2.getFullYear(), date2.getMonth());
    return months - fraction;
  }

  return months;
}

const SHIFT_FRACTION = 0.02;

export const NextStepsTimelineRow = (props: {
  info?: INextStepInfo;
  totalNumberOfMonths: number;
  overallStartDate: Date;
  overallEndDate: Date;
  displayQuarters?: boolean;
}) => {
  const {
    info,
    overallStartDate: propsOverallStartDate,
    overallEndDate: propsOverallEndDate,
    displayQuarters,
  } = props;
  let overallStartDate = propsOverallStartDate;
  let overallEndDate = propsOverallEndDate;
  let totalNumberOfMonths = monthDiff(overallStartDate, overallEndDate);
  const durationDiv = [];
  const months = ALL_MONTHS;
  let startingMonth =
    (overallStartDate.getMonth() + months.length) % months.length;
  if (displayQuarters) {
    startingMonth = Math.floor(overallStartDate.getMonth() / 3) * 3;
    const endingMonth = Math.floor(overallEndDate.getMonth() / 3) * 3 + 1;
    overallStartDate.setMonth(startingMonth);
    overallEndDate.setMonth(endingMonth);
    totalNumberOfMonths = monthDiff(overallStartDate, overallEndDate) + 1;
  }
  let startingDivIncrement = 0;
  let endingDivIncrement = totalNumberOfMonths + 1;
  if (displayQuarters) {
    startingDivIncrement = 0;
    endingDivIncrement = totalNumberOfMonths - 1;
  }
  for (let i = startingDivIncrement; i < endingDivIncrement; i++) {
    const month = months[(startingMonth + i) % months.length];
    durationDiv.push(month);
  }
  if (info == null) {
    return (
      <TimelineRowTop>
        {durationDiv.map((month, index: number) => {
          if (displayQuarters) {
            if (index === durationDiv.length) {
              return <RowCell displayQuarters={displayQuarters} />;
            }
            const quarterNumber = monthStringToQuarterNumber(month);
            if (
              !quarterNumber ||
              ![0, 3, 6, 9].includes(ALL_MONTHS.indexOf(month))
            ) {
              return null;
            }
            let yearSuffix = parseInt(
              overallStartDate.getFullYear().toString().slice(2)
            );
            const elapsedMonths = index + overallStartDate.getMonth();
            if (elapsedMonths > 11) {
              yearSuffix += Math.floor((elapsedMonths + 1) / 12);
            }
            const quarterText = `Q${quarterNumber}'${yearSuffix}`;
            return (
              <RowCell
                displayQuarters={displayQuarters}
                key={"dates-row" + index / 3}
                style={{
                  textAlign: props.displayQuarters ? "center" : undefined,
                }}
              >
                {quarterText}
              </RowCell>
            );
          } else {
            return (
              <RowCell
                key={"dates-row" + index}
                displayQuarters={!!displayQuarters}
              >
                {month}
              </RowCell>
            );
          }
        })}
      </TimelineRowTop>
    );
  }
  const cells = durationDiv.map((month, index) => {
    const secondaryCell =
      props.displayQuarters && monthStringToQuarterNumber(month) === null;
    return (
      <MainRowCell
        key={info.id + index}
        secondaryCell={ALL_MONTHS.indexOf(month) % 3 !== 0}
        primaryCell={props.displayQuarters && !secondaryCell}
      />
    );
  });

  const milestones =
    info.milestones?.map((milestone) => {
      const dataTipId =
        "timeline-row-milestone-tip-" + milestone.milestone_index;
      return (
        <div key={dataTipId}>
          <Milestone
            sizeRem={2.25}
            data-tip
            data-for={dataTipId}
            key={"milestone-" + milestone.milestone_index}
            offset={milestone.offset_percentage}
            topOffset={milestone.top_offset_percentage}
          >
            {milestone.milestone_index}
          </Milestone>

          <ReactTooltip
            className={"opaque tool-tip"}
            backgroundColor={"white"}
            id={dataTipId}
            effect="solid"
            getContent={() => {
              return (
                <TimelineToolTip>
                  <TimelineToolTipTitle>{milestone.title}</TimelineToolTipTitle>
                  <TimelineToolTipContent>
                    {milestone.description}
                  </TimelineToolTipContent>
                  <TimelineToolTipFooter>
                    {milestone.footer}
                  </TimelineToolTipFooter>
                </TimelineToolTip>
              );
            }}
          />
        </div>
      );
    }) ?? [];

  const {startDate, endDate, color, title, details} = info;

  let content: string | null = DateUtils.getTimeDifferenceString(
    startDate.getTime(),
    endDate.getTime()
  );

  // if (info.completion_percent && info.completion_percent === 100) {
  //   content = `Completed`;
  // }

  const monthDuration = 1000 * 60 * 60 * 24 * 30;
  let normalizedDuration =
    overallEndDate.getTime() - overallStartDate.getTime() + monthDuration;
  let startPercent =
    (startDate.getTime() - overallStartDate.getTime() + monthDuration) /
    normalizedDuration;
  let endPercent =
    (endDate.getTime() - overallStartDate.getTime() + monthDuration) /
    normalizedDuration;
  if (displayQuarters) {
    normalizedDuration = overallEndDate.getTime() - overallStartDate.getTime();
    startPercent =
      (startDate.getTime() - overallStartDate.getTime()) / normalizedDuration +
      SHIFT_FRACTION;
    endPercent =
      (endDate.getTime() - overallStartDate.getTime()) / normalizedDuration +
      SHIFT_FRACTION;
    content = null;
  }
  return (
    <TimelineRow>
      {cells}
      <ReactTooltip
        className={"opaque tool-tip"}
        backgroundColor={"white"}
        id={"timeline-row-tip-" + info.id}
        effect="solid"
        getContent={() => {
          return (
            <TimelineToolTip>
              <TimelineToolTipTitle>{title}</TimelineToolTipTitle>
              <TimelineToolTipContent>{details}</TimelineToolTipContent>
            </TimelineToolTip>
          );
        }}
      />
      <RowBlock
        className={"clickable"}
        bgColor={color}
        start={startPercent * 100}
        width={(endPercent - startPercent) * 100}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          data-tip
          data-for={"timeline-row-tip-" + info.id}
        />
        <div style={{display: "flex", whiteSpace: "pre"}}>{content}</div>
        {milestones}
      </RowBlock>
      <TimelinePhase
        overallStartDate={overallStartDate}
        info={info}
        totalNumberOfMonths={totalNumberOfMonths}
        overallEndDate={overallEndDate}
        normalizedDuration={normalizedDuration}
        startPercent={startPercent}
        endPercent={endPercent}
      />
    </TimelineRow>
  );
};

export const TimelinePhase = (props: {
  info: INextStepInfo;
  totalNumberOfMonths: number;
  overallStartDate: Date;
  overallEndDate: Date;
  normalizedDuration: number;
  startPercent: number;
  endPercent: number;
}) => {
  const {info, overallStartDate, overallEndDate, totalNumberOfMonths} = props;
  const {phase, startDate, color} = info;
  const {isDevelopmentByVilla} = useActiveProject();
  if (phase == null) {
    return null;
  }
  let normalizedDuration =
    overallEndDate.getTime() - overallStartDate.getTime();
  let startPercent =
    (startDate.getTime() - overallStartDate.getTime()) / normalizedDuration +
    SHIFT_FRACTION;
  let endPercent =
    (phase.phaseEndDate.getTime() - overallStartDate.getTime()) /
      normalizedDuration +
    SHIFT_FRACTION;

  if (!isDevelopmentByVilla) {
    const monthDuration = 1000 * 60 * 60 * 24 * 30;
    normalizedDuration =
      overallEndDate.getTime() - overallStartDate.getTime() + monthDuration;
    startPercent =
      (startDate.getTime() - overallStartDate.getTime() + monthDuration) /
      normalizedDuration;
    endPercent =
      (phase.phaseEndDate.getTime() -
        overallStartDate.getTime() +
        monthDuration) /
      normalizedDuration;
  }
  const endLineColor = phase.endColor;

  return (
    <React.Fragment>
      <PhaseBox
        show={true}
        startLineColor={color}
        endLineColor={endLineColor}
        start={startPercent * 100}
        width={(endPercent - startPercent) * 100}
        numberOfRows={phase.additionalSteps}
      >
        <PhaseTitle color={color}>{phase.title}</PhaseTitle>
      </PhaseBox>
    </React.Fragment>
  );
};

export const TimelineStageInfo = (props: {info: INextStepInfo}) => {
  const {info} = props;

  return (
    <TimelineRow>
      <TimelineRowInfoContainer>
        <InfoTitle>{info.title}</InfoTitle>
        <InfoSubtitle>{info.subtitle}</InfoSubtitle>
      </TimelineRowInfoContainer>
    </TimelineRow>
  );
};

export const MobileTimeline = (props: {
  info: INextStepInfo[];
  totalNumberOfMonths: number;
  overallStartDate: Date;
  overallEndDate: Date;
}) => {
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {info, totalNumberOfMonths, overallStartDate, overallEndDate} = props;
  const months = ALL_MONTHS;
  const startingMonth =
    (overallStartDate.getMonth() + months.length) % months.length;
  const monthDivs = [];
  for (let i = 0; i < totalNumberOfMonths; i++) {
    const month = months[(startingMonth + i) % months.length];
    monthDivs.push(month);
  }

  if (info == null) {
    return (
      <>
        {monthDivs.map((month, index: number) => (
          <table style={{width: "100%"}} key={"dates" + index}>
            <tbody>
              <tr>
                <MobileMonthNameRow key={"dates" + index}>
                  {month}
                </MobileMonthNameRow>
                <MobileTimelineRow />
              </tr>
            </tbody>
          </table>
        ))}
      </>
    );
  }
  const cells = monthDivs.map((month, index) => {
    return (
      <table style={{width: "calc(100% - 32px)"}} key={month + index}>
        <tbody>
          <tr>
            <MobileMonthNameRow key={"dates" + index}>
              {month}
            </MobileMonthNameRow>
            <MobileTimelineRow />
          </tr>
        </tbody>
      </table>
    );
  });

  return (
    <>
      {cells}
      {info.map((rowInfo, index: number) => {
        let {startDate, endDate, color, title, details} = rowInfo;
        const normalizedDuration =
          overallEndDate.getTime() - overallStartDate.getTime();
        const startPercent =
          (startDate.getTime() - overallStartDate.getTime()) /
          normalizedDuration;
        const endPercent =
          (endDate.getTime() - overallStartDate.getTime()) / normalizedDuration;
        let totalMonths = DateUtils.getTimeDifferenceString(
          startDate.getTime(),
          endDate.getTime()
        );
        if (rowInfo.completion_percent && rowInfo.completion_percent > 0) {
          totalMonths = `${rowInfo.completion_percent}% complete`;
        }

        return (
          <MobileRowBlock
            key={"next-steps-timeline-index-" + index}
            bgColor={color}
            start={startPercent * 100}
            monthHeight={(endPercent - startPercent) * 100}
            shouldDisplayPostDepositPortalV2={!isCustomerPreDeposit}
          >
            <ReactTooltip
              className={"opaque tool-tip"}
              backgroundColor={"white"}
              id={"timeline-row-tip-" + rowInfo.id}
              place="left"
              effect="solid"
              getContent={() => {
                return (
                  <div style={{maxWidth: "200px"}}>
                    <TimelineToolTip>
                      <TimelineToolTipTitle>{title}</TimelineToolTipTitle>
                      <TimelineToolTipContent>{details}</TimelineToolTipContent>
                    </TimelineToolTip>
                  </div>
                );
              }}
            />
            <MobileStageTitleDiv>{title}</MobileStageTitleDiv>
            <MobileStageMonthCountDiv>{totalMonths}</MobileStageMonthCountDiv>
            <MobileStageMonthTooltipDiv>
              <GoInfo
                size={20}
                data-tip
                data-for={"timeline-row-tip-" + rowInfo.id}
              />
            </MobileStageMonthTooltipDiv>
          </MobileRowBlock>
        );
      })}
      {info.map((rowInfo, index: number) => {
        if (!rowInfo.phase) {
          return null;
        }
        return (
          <MobileTimelinePhase
            key={`phasekey${index}`}
            overallStartDate={overallStartDate}
            info={rowInfo}
            totalNumberOfMonths={totalNumberOfMonths}
            overallEndDate={overallEndDate}
          />
        );
      })}
    </>
  );
};

export const MobileTimelinePhase = (props: {
  info: INextStepInfo;
  totalNumberOfMonths: number;
  overallStartDate: Date;
  overallEndDate: Date;
}) => {
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {info, overallStartDate, overallEndDate} = props;
  const {phase} = info;
  if (phase == null) {
    return null;
  }
  let {startDate, color} = info;
  const endLineColor = phase.endColor;

  const normalizedDuration =
    overallEndDate.getTime() - overallStartDate.getTime();
  const startPercent =
    (startDate.getTime() - overallStartDate.getTime()) / normalizedDuration;
  const endPercent =
    (phase.phaseEndDate.getTime() - overallStartDate.getTime()) /
    normalizedDuration;

  return (
    <React.Fragment>
      <MobilePhaseBox
        show={true}
        startLineColor={color}
        endLineColor={endLineColor}
        start={startPercent * 100}
        monthHeight={(endPercent - startPercent) * 100}
        shouldDisplayPostDepositPortalV2={!isCustomerPreDeposit}
      >
        <MobilePhaseTitle>{phase.title}</MobilePhaseTitle>
      </MobilePhaseBox>
    </React.Fragment>
  );
};

export function monthStringToQuarterNumber(month: string): number | null {
  switch (month) {
    case ALL_MONTHS[0]:
    case ALL_MONTHS[1]:
    case ALL_MONTHS[2]:
      return 1;
    case ALL_MONTHS[3]:
    case ALL_MONTHS[4]:
    case ALL_MONTHS[5]:
      return 2;
    case ALL_MONTHS[6]:
    case ALL_MONTHS[7]:
    case ALL_MONTHS[8]:
      return 3;
    case ALL_MONTHS[9]:
    case ALL_MONTHS[10]:
    case ALL_MONTHS[11]:
      return 4;
    default:
      return null;
  }
}
