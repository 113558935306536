import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  verifyFactoryLineState,
  verifyFactoryLineVersionState,
} from "./initialize";
import {InfoSetItem} from "../../../factory-info-set/shared/interfaces";
import {IFactoryInfo, IProduct, mergeObjects} from "@natomas-org/core";

export enum FactoryVersionField {
  DETAILS = "details",
  CATEGORIES = "categories",
  MODIFIERS = "modifiers",
  MODIFIER_GROUPS = "modifierGroups",
  PRODUCTS = "products",
  PRODUCT_MODIFIERS = "productModifiers",
}

export enum FactoryField {
  INFO = "info",
  INFO_SET = "infoSet",
  INVENTORY = "inventory",
}

export interface IFactoryVersion {
  [FactoryVersionField.DETAILS]?: any;
  [FactoryVersionField.CATEGORIES]?: any;
  [FactoryVersionField.MODIFIERS]?: any;
  [FactoryVersionField.MODIFIER_GROUPS]?: any;
  [FactoryVersionField.PRODUCTS]?: IFactoryProductMap;
  [FactoryVersionField.PRODUCT_MODIFIERS]?: any;
}

export interface IFactoryInfoSet {
  [id: string]: InfoSetItem;
}

export interface IFactoryProductMap {
  [id: string]: IProduct;
}

export interface IFactory {
  [FactoryField.INFO]?: IFactoryInfo;
  [FactoryField.INFO_SET]?: IFactoryInfoSet;
  [FactoryField.INVENTORY]?: IFactoryProductMap;
  versions: {
    [versionId: string]: IFactoryVersion;
  };
}

export interface IFactorySlice {
  [factoryId: string]: IFactory;
}

export interface ISetFactoryPayload {
  factoryId: string;
  payload: any;
}

export interface ISetFactoryPayloadOptions {
  fullFetch?: boolean;
  force?: boolean;
  versionId?: string;
}

export interface ISetFactoryInfoPayload {
  factoryId: string;
  payload: any;
  options?: ISetFactoryPayloadOptions;
}

export interface IChangeFactoryVersionPayload {
  factoryId: string;
  versionId: string;
  type: FactoryVersionField;
}

export interface ISetFactoryVersionPayload
  extends IChangeFactoryVersionPayload {
  payload: any;
}

export interface IRemoveFactoryVersionPayload
  extends IChangeFactoryVersionPayload {
  id: string;
}

export interface IUpdateFactoryVersionPayload
  extends IChangeFactoryVersionPayload {
  payload: any;
  id: string;
}

const getInitialState = (): IFactorySlice => {
  return {};
};

const factorySlice = createSlice({
  name: "factory",
  initialState: getInitialState(),
  reducers: {
    mergeFactoryVersionValue: (
      state,
      action: PayloadAction<ISetFactoryVersionPayload>
    ) => {
      const {factoryId, versionId, type, payload} = action.payload;
      verifyFactoryLineVersionState(state, factoryId, versionId);
      // @ts-ignore
      const currentValue = state[factoryId].versions[versionId][type];
      // @ts-ignore
      state[factoryId].versions[versionId][type] = mergeObjects(
        currentValue,
        payload
      );
    },
    updateFactoryVersionValue: (
      state,
      action: PayloadAction<IUpdateFactoryVersionPayload>
    ) => {
      const {factoryId, versionId, type, id, payload} = action.payload;
      verifyFactoryLineVersionState(state, factoryId, versionId);
      if (type === FactoryVersionField.PRODUCT_MODIFIERS) {
        const currentValues = state[factoryId].versions[versionId][type][id];
        // @ts-ignore
        state[factoryId].versions[versionId][type][id] = {
          ...currentValues,
          ...payload,
        };
      } else {
        // @ts-ignore
        state[factoryId].versions[versionId][type][id] = payload;
      }
    },
    removeFactoryVersionValue: (
      state,
      action: PayloadAction<IRemoveFactoryVersionPayload>
    ) => {
      const {factoryId, versionId, type, id} = action.payload;
      verifyFactoryLineVersionState(state, factoryId, versionId);
      // @ts-ignore
      state[factoryId].versions[versionId][type][id] = undefined;
    },
    setFactoryInfoSet: (state, action: PayloadAction<ISetFactoryPayload>) => {
      const {factoryId, payload} = action.payload;
      verifyFactoryLineState(state, factoryId);
      // @ts-ignore
      const currentValue = state[factoryId][FactoryField.INFO_SET] ?? {};
      // @ts-ignore
      state[factoryId][FactoryField.INFO_SET] = {...currentValue, ...payload};
    },
    setFactoryInventory: (state, action: PayloadAction<ISetFactoryPayload>) => {
      const {factoryId, payload} = action.payload;
      verifyFactoryLineState(state, factoryId);
      // @ts-ignore
      const currentValue = state[factoryId][FactoryField.INVENTORY] ?? {};
      // @ts-ignore
      state[factoryId][FactoryField.INVENTORY] = {...currentValue, ...payload};
    },
    setFactoryInfo: (state, action: PayloadAction<ISetFactoryInfoPayload>) => {
      const {factoryId, payload} = action.payload;
      verifyFactoryLineState(state, factoryId);
      // @ts-ignore
      const currentValue = (state[factoryId][FactoryField.INFO] = payload);
      // @ts-ignore
      state[factoryId][FactoryField.INFO] = {...currentValue, ...payload};
    },
  },
});

export const factoryReducer = factorySlice.reducer;
export const {
  mergeFactoryVersionValue,
  setFactoryInfoSet,
  setFactoryInfo,
  setFactoryInventory,
  removeFactoryVersionValue,
  updateFactoryVersionValue,
} = factorySlice.actions;
