import {checkCookie, getCookie} from "./cookie";

export const getParameter = (
  parameter: any,
  options?: {useCookie: boolean}
) => {
  //Grab url and create URLSearchParams object instance
  const url = document.location.search.substring(1);
  const params = new URLSearchParams(url);

  //Checks to see if URL has that parameter, if not then return error string
  if (!params.has(parameter)) {
    //If no parameter is found, see if it exists as a cookie
    if (!options?.useCookie) {
      return null;
    }

    const cookie = getCookie(parameter);
    if (cookie !== null) {
      return cookie;
    }

    return `${parameter} was not found in URL or in cookie.`;
  }

  // Ensures that p is not null before storing.
  const value = params.get(parameter);
  if (value && options?.useCookie) {
    //See if parameter has been stored inside a cookie, if not it'll create it and if it already exists it'll leave it alone
    checkCookie(parameter, value, 3);
  }

  return value;
};

export const getURLParameter = (parameter: string): string | null => {
  //Grab url and create URLSearchParams object instance
  const url = document.location.search.substring(1);
  const params = new URLSearchParams(url);
  if (params.has(parameter)) {
    // Ensures that p is not null before storing.
    let p = params.get(parameter);
    if (p) {
      //See if parameter has been stored inside a cookie, if not it'll create it and if it already exists it'll leave it alone
      checkCookie(parameter, p, 3);
      //If it does find the parameter, then return its value
      return p;
    }
  }
  return null;
};
