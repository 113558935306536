import React, {useMemo} from "react";
import {Document, PDFViewer} from "@react-pdf/renderer";
import styled from "styled-components";
import useProjectProposalInfo from "./proposal/useProjectProposalInfo";
import {EmptyPage} from "../../_shared/generics/empty-page";
import {BLACK, GRAY_BORDER_LIGHT, VILLA_SLATE} from "../../_shared/colors";
import useLatestEstimate from "../../estimate/useLatestEstimate";
import EstimateTemplate from "./proposal/EstimateTemplate";
import useActiveCustomer from "../../_shared/hooks/useCustomer/useActiveCustomer";
import {PDFProposal, Person, ProjectPriceMode} from "@natomas-org/core";
import {convertLatestEstimateResponseToTableRows} from "../../estimate/_shared/rangeToRows";
import {EstimateTables} from "../../estimate/CustomerEstimate/tables";
import {IEstimateProjectRange} from "../../../api/Estimate/EstimateService";
import useActiveProject from "../../_shared/hooks/useProject/useActiveProject";
import ProposalTemplate from "./proposal/template";

interface ReactPDFDemoViewProps {}

const PDFViewContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${VILLA_SLATE};
  overflow: hidden;

  iframe {
    border: none;
  }
`;

// This function is used to convert the estimate response to customer friendly table values
export const useCustomerEstimateTableValues = (
  response: IEstimateProjectRange | undefined
) => {
  const tables = useMemo(() => {
    let allFilteredEntries: {
      [title: string]: PDFProposal.Nat_RPDF_Table_Text_Data_Row[];
    } = {};

    Object.keys(EstimateTables).forEach((key) => {
      const tableConfiguration = EstimateTables[key];
      const tableRowProps = convertLatestEstimateResponseToTableRows(
        response,
        tableConfiguration.key,
        {
          label: tableConfiguration.label,
          hideEmptyPrices: true,
        }
      );
      const headerStyle: {
        header: boolean;
        bgColor: string;
      } = {
        header: true,
        bgColor: GRAY_BORDER_LIGHT,
      };
      const rPDFTableTextDataRows: PDFProposal.Nat_RPDF_Table_Text_Data_Row[] =
        [
          {
            data: [
              {text: "Item", ...headerStyle},
              {
                text: "Price (Low)",
                ...headerStyle,
              },
              {
                text: "Price (High)",
                ...headerStyle,
              },
              {
                text: "Notes & Comments",
                ...headerStyle,
              },
            ],
          },
        ];
      tableRowProps.forEach((row) => {
        const genericStyle: {
          bold?: boolean;
          textColor?: string;
          bgColor?: string;
        } = {
          bold: undefined,
          textColor: undefined,
          bgColor: undefined,
        };
        if (row?._style) {
          genericStyle.bold = row._style?.fontWeight
            ? parseInt(row._style.fontWeight) > 400
            : false;
          genericStyle.textColor = row._style.textColor;
          genericStyle.bgColor = row._style.backgroundColor;
        }
        // Do not include columns that are not needed
        if (!row?.["title"] || !row?.["priceLow"] || !row?.["priceHigh"]) {
          return;
        }
        const rowCells: PDFProposal.Nat_RPDF_Table_Text_Data_Entry[] = [
          {
            text: row?.["title"],
            bold: genericStyle.bold,
            textColor: genericStyle.textColor ?? BLACK,
            bgColor: genericStyle.bgColor,
          },
          {
            text: row?.["priceLow"],
            bold: genericStyle.bold,
            textColor: genericStyle.textColor ?? BLACK,
            bgColor: genericStyle.bgColor,
          },
          {
            text: row?.["priceHigh"],
            bold: genericStyle.bold,
            textColor: genericStyle.textColor ?? BLACK,
            bgColor: genericStyle.bgColor,
          },
          {
            text: row?.["note"] ?? "",
            bold: genericStyle.bold,
            textColor: genericStyle.textColor ?? BLACK,
            bgColor: genericStyle.bgColor,
          },
        ];
        rPDFTableTextDataRows.push({data: rowCells, key: row._key});
      });
      allFilteredEntries[tableConfiguration.label] = rPDFTableTextDataRows;
    });
    return allFilteredEntries;
  }, [response]);
  return response ? tables : null;
};
const ProposalPDFView = (props: ReactPDFDemoViewProps) => {
  const {id, projectPriceMode} = useActiveProject();
  const {data, fetching} = useProjectProposalInfo();

  const {response} = useLatestEstimate({
    disableMargin: true,
    disableCost: true,
  });
  const tables = useCustomerEstimateTableValues(response);

  const {info} = useActiveCustomer();
  const content = () => {
    if (projectPriceMode !== ProjectPriceMode.CostPlus) {
      console.log("ProposalPDFView - using nexus (DTC) template");
      if (!data) {
        console.log("ProposalPDFView - loading data");
        return <div>Loading info...</div>;
      } else if (!response) {
        console.log("ProposalPDFView - loading response");
        return <div>Loading estimate...</div>;
      } else if (!tables) {
        console.log("ProposalPDFView - loading tables");
        return <div>Loading tables...</div>;
      } else if (response && data && tables) {
        console.log("ProposalPDFView - loaded");
        return (
          <EstimateTemplate
            projectRange={{...response}}
            projectProposal={{...data, tables: tables}}
          />
        );
      }
    } else {
      if (!data) {
        console.log("ProposalPDFView - loading data");
        return <div>Loading...</div>;
      } else {
        console.log("ProposalPDFView - using legacy (CostPlus) template");
        return <ProposalTemplate {...data} />;
      }
    }
  };

  if (fetching) {
    console.log("ProposalPDFView - fetching");
    return <EmptyPage title={"Getting PDF Details..."} />;
  }

  if (!id) {
    return <EmptyPage title={"No project id found."} />;
  }
  if (!info?.person_info) {
    console.log("ProposalPDFView - no person info found");
    return <EmptyPage title={"No customer info found."} />;
  }

  const fileTitle = `${Person.getFullName(
    info.person_info
  )} - Villa Homes Project Proposal`;

  const subject = `${new Date().toLocaleDateString()}`;

  const creator = `Villa Homes Engineering`;

  return (
    <PDFViewContainer>
      <PDFViewer style={{height: "100%", width: "100%"}}>
        <Document
          title={fileTitle}
          author={"Villa Homes"}
          subject={subject}
          creator={creator}
          producer={creator}
        >
          {content()}
        </Document>
      </PDFViewer>
    </PDFViewContainer>
  );
};
export default ProposalPDFView;
