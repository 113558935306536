import styled from "styled-components";
import {MAP_SIDE_PADDING} from "../../../../../constants";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const BuildProductCardImage = styled.div<{
  height: string;
  overrideRadius?: boolean;
}>`
  height: ${(props) => props.height};
  position: relative;
  min-width: 10rem;
  ////width: -webkit-fill-available;
  ////width: -moz-available;
  //width: ;
  .fullDiv {
    border-radius: ${(props) =>
      props.overrideRadius ? "1rem 0 0 0" : "1rem 0 0 1rem"};
  }
`;

export const ProductSectionWrapper = styled.div`
  margin-bottom: 5rem;
`;

export const CatalogCategoryTitleWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  //justify-content: space-between;
  align-items: center;
`;

export const CatalogCategoryTitle = styled.div`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const BuildProductCardTitle = styled.div`
  white-space: nowrap;
  width: 100%;
  font-size: 1.125rem;
  font-style: normal;
  align-items: flex-start;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BuildProductCardPriceRowDescriptor = styled.span`
  padding-left: 0.15rem;
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_MEDIUM};
  margin-bottom: 0.15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ProductDetailContainer = styled.div<{
  isMobile: boolean;
}>`
  padding: 1rem;
  padding-left: 0;
  padding-right: 0;
  position: sticky;
  top: ${(props) => (props.isMobile ? "4rem" : 0)};
`;

export const ProductDetailTitle = styled.div`
  //display: flex;
  //flex-direction: row;
  //justify-content: space-between;
  //align-items: flex-end;
  //column-gap: 1rem;
`;
export const ProductDetailBackButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  overflow: hidden;
`;
export const ProductsContainerContainer = styled.div`
  /* width */

  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #b1b1b1;
    border-radius: 4px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ProductLineRowContainer = styled.div`
  padding-left: ${MAP_SIDE_PADDING};
  padding-right: 0.25rem;
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 7rem;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
`;
export const ProductLineRowImage = styled.div`
  height: 6rem;
  min-width: 9rem;
  max-width: 9rem;
  position: relative;
  .fullDiv {
    border-radius: 0.5rem;
  }
`;

export const ProductLineTitleAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

export const ProductLineTitleContainer = styled.div`
  font-size: 1rem;
  font-family: ${FONT_FAMILY_MEDIUM};
`;
export const ProductLineDescriptionContainer = styled.div`
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_REGULAR};
`;
export const NatSwiperWrapper = styled.div`
  #swiper-wrapper {
    width: 10rem;
  }
`;

export const FullScreenIconBackgroundContainer = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 99;
  svg {
    transition: transform 0.3s ease-in-out;
  }
  svg:hover {
    transform: scale(1.2);
  }
`;
