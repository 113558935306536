const subscriptions: {[key: string]: () => void} = {};
const subscribingAttempts: {[key: string]: boolean} = {};

export interface IFetchingReturn {
  unsubscribe: (() => void) | null;
}

export const processFetchingReturn = (id: string, r: IFetchingReturn) => {
  storeSubscription(id, r.unsubscribe);
  setSubscribingAttempt(id, false);
  return r;
};

export const hasActiveSubscription = (subscriptionId: string) => {
  return !!subscriptions[subscriptionId] || subscribingAttempts[subscriptionId];
};

export const setSubscribingAttempt = (subscriptionId: string, attempting: boolean) => {
  subscribingAttempts[subscriptionId] = attempting;
}

export const storeSubscription = (subscriptionId: string, unsubscribe: any) => {
  if (unsubscribe) {
    console.log(`Storing subscription ${subscriptionId}`);
    subscriptions[subscriptionId] = unsubscribe;
  }
};

export const unsubscribeFromSubscription = (subscriptionId: string) => {
  subscriptions[subscriptionId]?.();
  delete subscriptions[subscriptionId];
}

export const unsubscribeFromAllSubscriptions = () => {
  Object.keys(subscriptions).forEach((subscriptionId) => {
    unsubscribeFromSubscription(subscriptionId);
  });
}

