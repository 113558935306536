import styled from "styled-components";

export const PORTAL_HEADER_BUTTON_WIDTH = "14rem";
export const MOBILE_PORTAL_HEADER_BUTTON_WIDTH = "6rem";

export const PortalHeaderButtonContainer = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  flex-grow: 1;
  max-width: ${(props) =>
    props.isMobile
      ? MOBILE_PORTAL_HEADER_BUTTON_WIDTH
      : PORTAL_HEADER_BUTTON_WIDTH};
  min-width: ${(props) =>
    props.isMobile
      ? MOBILE_PORTAL_HEADER_BUTTON_WIDTH
      : PORTAL_HEADER_BUTTON_WIDTH};
  align-items: center;
`;
export const PortalHeaderLogoContainer = styled.div<{
  isMobile: boolean;
}>`
  display: flex;
  gap: 0.5rem;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-start;
  column-gap: 1rem;
  align-content: center;
  align-items: center;
  max-width: ${(props) =>
    props.isMobile
      ? MOBILE_PORTAL_HEADER_BUTTON_WIDTH
      : PORTAL_HEADER_BUTTON_WIDTH};
  min-width: ${(props) =>
    props.isMobile
      ? MOBILE_PORTAL_HEADER_BUTTON_WIDTH
      : PORTAL_HEADER_BUTTON_WIDTH};
`;
