import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";

export const InventoryBannerSubTitle = styled.div<{size: string}>`
  font-size: ${(props) => props.size};
  font-family: ${FONT_FAMILY_MEDIUM};
  text-align: center;
`;

export const InventoryBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InventorySpacer = styled.div`
  height: 1rem;
`;
