import {useEffect, useState} from "react";
import {SketchPicker} from "react-color";
import {NatButton} from "../generics/button";

export const ColorPickerComp = ({value, onChange, keyName}) => {
  const [color, setColor] = useState(value);
  const handleChange = (color) => {
    setColor(color);
    onChange(color);
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  return (
    <div
      key={keyName}
      style={{
        alignItems: "center",
        padding: 10,
      }}
    >
      <SketchPicker
        color={color ?? ""}
        onChange={(color, event) => {
          handleChange(color);
        }}
      />

      <NatButton
        label={"Remove color"}
        type={"button"}
        clickEvent={() => {
          handleChange("");
        }}
      />
    </div>
  );
};
