import {ProductLineInfoBlockTitle} from "../styles";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  ADMIN_FACTORY_LINE_PATH,
  ADMIN_PRODUCT_CATALOG_ROUTING_PATH,
  ADMIN_PRODUCT_LINE_PATH,
  CATALOG_ROOT_PATH,
} from "../../../../../../../../paths";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {
  NatCopyIcon,
  NatDatabaseIcon,
  NatEditIcon,
  NatMapFeatureIcon,
  NatProductDatabaseIcon,
} from "../../../../../../../_shared/icon/icons";
import React, {Fragment, useState} from "react";
import {NatFlex} from "../../../../../../../_shared/generics/flex/NatFlex";
import {CopyFactoryLineWarningModal} from "../../../_shared/CopyFactoryLineWarningModal";

export const FactoryLineBaseBlock = (props: {
  details: any;
  isPublishedVersion: boolean;
}) => {
  const {details, isPublishedVersion} = props;
  const [showCopyModal, setShowCopyModal] = useState(false);
  return (
    <Fragment>
      <ProductLineInfoBlockTitle>Links</ProductLineInfoBlockTitle>
      <NatFlex COLUMN FULL_WIDTH>
        <NatButton
          label={"View Catalog Page"}
          clickEvent={() => {
            window.open(
              window.location.origin + CATALOG_ROOT_PATH + "?gid=" + details?.id
            );
          }}
          option={StyleOption.PRIMARY_BLACK}
          size={NatSize.SMALL}
          icon={{
            icon: <NatProductDatabaseIcon />,
            iconPosition: IconPosition.RIGHT,
          }}
        />
        <NatButton
          label={"Edit Studio"}
          clickEvent={() => {
            window.open(
              window.location.origin +
                ADMIN_PRODUCT_LINE_PATH +
                "?gid=" +
                details?.id
            );
          }}
          option={StyleOption.PRIMARY_BLACK}
          size={NatSize.SMALL}
          icon={{icon: <NatEditIcon />, iconPosition: IconPosition.RIGHT}}
        />
        <NatButton
          label={"Edit InfoSet"}
          clickEvent={() => {
            window.open(
              window.location.origin +
                ADMIN_FACTORY_LINE_PATH +
                "?gid=" +
                details?.id
            );
          }}
          option={StyleOption.PRIMARY_BLACK}
          size={NatSize.SMALL}
          icon={{
            icon: <NatDatabaseIcon />,
            iconPosition: IconPosition.RIGHT,
          }}
        />
        <NatButton
          label={"Edit Routing"}
          clickEvent={() => {
            window.open(
              window.location.origin + ADMIN_PRODUCT_CATALOG_ROUTING_PATH
            );
          }}
          option={StyleOption.PRIMARY_BLACK}
          size={NatSize.SMALL}
          icon={{
            icon: <NatMapFeatureIcon />,
            iconPosition: IconPosition.RIGHT,
          }}
        />
        <CopyFactoryLineWarningModal
          factoryLineId={details?.id}
          show={showCopyModal}
          isPublished={!isPublishedVersion}
          setShow={setShowCopyModal}
        />

        <NatButton
          label={"Duplicate Factory"}
          clickEvent={() => setShowCopyModal(true)}
          option={StyleOption.PRIMARY_BLACK}
          size={NatSize.SMALL}
          icon={{
            icon: <NatCopyIcon />,
            iconPosition: IconPosition.RIGHT,
          }}
        />
      </NatFlex>
    </Fragment>
  );
};
