import React from "react";
import {SeriesSummaryComparisonSecondary} from "../../../SeriesBreakdownAdapter";
import Select from "react-select";
import {SSCSelectorContainer} from "./styles";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";
import SSCViewSeriesComponent from "./SSCViewSeriesComponent";
import {
  BLACK,
  PRIMARY_BUTTON_HOVER,
  VILLA_APPLE_GREEN,
} from "../../../../../../../../_shared/colors";

const SSCSelector = (props: SeriesSummaryComparisonSecondary) => {
  const {id, setOptionId, options} = props;
  const selectOptions = options?.map((option) => ({
    value: option.id,
    label: option.title,
  }));

  return (
    <SSCBlockContainer>
      <SSCSelectorContainer>
        <Select
          onChange={(option) => {
            if (option?.value) {
              setOptionId(option?.value);
            }
          }}
          styles={{
            option: (styles, {data, isDisabled, isFocused, isSelected}) => ({
              ...styles,
              "cursor": isDisabled ? "not-allowed" : "pointer",
              "color": BLACK,
              "backgroundColor": isDisabled
                ? undefined
                : isFocused
                ? PRIMARY_BUTTON_HOVER
                : undefined,
              ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                  ? isSelected
                    ? VILLA_APPLE_GREEN
                    : undefined
                  : undefined,
              },
            }),
          }}
          getOptionLabel={(option) => option.label}
          options={selectOptions}
          value={selectOptions?.find((o) => o.value === id)}
        />
        <SSCViewSeriesComponent id={id} />
      </SSCSelectorContainer>
    </SSCBlockContainer>
  );
};

export default SSCSelector;
