import React, {useState} from "react";
import GoogleMapReact from "google-map-react";
import pin from "../../../components/admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/QuickInfoTab/GeneralInfoSection/assets/pin.png";
import {
  InstantQuoteMapPin,
  InstantQuoteMapPinContainer,
  InstantQuoteMapPinLabel,
} from "./styles";
// TODO Remove this
const GOOGLE_MAPS_API_KEY = "AIzaSyCwZWAlda8aZcqdbNqTaU_AqZ2Dqj7-vMM";
const defaultZoom = 12;
const Map = (props: {
  latitude: number;
  longitude: number;
  label: string;
  setCoordinates: (lat: number, lng: number) => void;
}) => {
  const {latitude, longitude, label, setCoordinates} = props;
  const [zoom, setZoom] = useState<number>(defaultZoom);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{key: GOOGLE_MAPS_API_KEY}}
      defaultCenter={{
        lat: 37.7749,
        lng: -122.4194,
      }}
      center={{
        lat: latitude,
        lng: longitude,
      }}
      defaultZoom={defaultZoom}
      zoom={zoom}
      onClick={(value) => {
        const {lat, lng} = value;
        setCoordinates(lat, lng);
        setZoom(14);
      }}
    >
      <GoogleMapPin lat={latitude} lng={longitude} text={label} />
    </GoogleMapReact>
  );
};

const GoogleMapPin = ({text}: any) => {
  const [addressLabelVisible, setAddressLabelVisible] =
    useState<boolean>(false);
  return (
    <>
      <InstantQuoteMapPinContainer>
        <InstantQuoteMapPin
          src={pin}
          alt="pin"
          onClick={() => {
            setAddressLabelVisible(!addressLabelVisible);
          }}
        />
        <InstantQuoteMapPinLabel
          style={{
            visibility: addressLabelVisible ? "visible" : "hidden",
          }}
        >
          {text}
        </InstantQuoteMapPinLabel>
      </InstantQuoteMapPinContainer>
    </>
  );
};

export default Map;
