import firebase from "firebase";
import React from "react";
import {CreateLeadSchema} from "@natomas-org/villa-nexus-client";
import UserCredential = firebase.auth.UserCredential;

export type ISetSchema = React.Dispatch<
  React.SetStateAction<CreateLeadSchema | undefined>
>;

export const handleCredential = (
  credential: UserCredential
): CreateLeadSchema => {
  const email = credential?.user?.email ?? "";
  const firstName =
    //@ts-ignore
    credential?.additionalUserInfo?.profile?.given_name ?? "";
  const lastName =
    //@ts-ignore
    credential?.additionalUserInfo?.profile?.family_name ?? "";
  const firebase_auth_id = credential?.user?.uid ?? "";
  return {
    email,
    first_name: firstName,
    last_name: lastName,
    auth_user_id: firebase_auth_id,
  };
};
