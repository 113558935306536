import {ITableSortingDetails} from "../../../_shared/components/AdminTable/table";
import {ITag} from "../../../../_shared/slices/types/Store";

const sortTagByTitle = (
  sortingDetails: ITableSortingDetails,
  groupA: ITag,
  groupB: ITag
) => {
  if (!sortingDetails?.mode || !sortingDetails?.column) {
    return 1;
  }
  // @ts-ignore
  const nameA = groupA[sortingDetails.column];
  // @ts-ignore
  const nameB = groupB[sortingDetails.column];
  if (nameA < nameB) {
    return -1 * sortingDetails.mode;
  }
  if (nameA > nameB) {
    return sortingDetails.mode;
  }

  // names must be equal
  return 0;
};

const sortTagsByKey = (sortingDetails: ITableSortingDetails, tags: ITag[]) => {
  return tags.sort((a: any, b: any) => {
    return sortTagByTitle(sortingDetails, a, b);
  });
};

export const sortTags = (
  sortingDetails: ITableSortingDetails,
  tags: {[key: string]: ITag}
) => {
  if (!tags) {
    return [];
  }
  if (!sortingDetails) {
    return Object.keys(tags);
  }

  const tagArray = Object.values(tags);

  const sortedTags = sortTagsByKey(sortingDetails, tagArray);

  return sortedTags?.map((tag: ITag) => tag.id);
};
