import React, {useMemo} from "react";
import {SeriesSummaryComparisonRowProps} from "../index";
import {useFactoryLine} from "../../../../../../../_shared/hooks/useProductCatalog/useFactoryLine/useFactoryLine";
import {useUnfilteredCatalogProducts} from "../../../../../../../_shared/hooks/useProductCatalog/useProducts";
import {
  ISeriesSummaryColumnDetails,
  ISeriesSummaryColumnInfo,
  ISeriesSummaryColumnProductInfo,
  SeriesSummaryColumnProps,
} from "../../interfaces";
import {
  getSSCDetailsProps,
  getSSCInfoProps,
  getSSCProductInfoProps,
} from "./helper";
import SeriesSummaryComparison from "../../SeriesSummaryComparison";

const SSCBreakdown = (props: SeriesSummaryComparisonRowProps) => {
  const {primaryId, secondary} = props;

  // Primary Factory
  const {info: primaryInfo, details: primaryDetails} = useFactoryLine({
    factoryId: primaryId,
  });
  const {
    unfilteredCatalogProducts: primaryUnfilteredProducts,
    info: primaryProductInfo,
  } = useUnfilteredCatalogProducts({
    onlyFactory: primaryId,
    excludeInventory: true,
  });

  // Other Factory
  const {info: otherInfo, details: otherDetails} = useFactoryLine({
    factoryId: secondary?.id,
  });
  const {
    unfilteredCatalogProducts: otherUnfilteredProducts,
    info: otherProductInfo,
  } = useUnfilteredCatalogProducts({
    onlyFactory: secondary?.id,
    excludeInventory: true,
  });

  const primaryInfoBoundProps: ISeriesSummaryColumnInfo | null = useMemo(() => {
    return getSSCInfoProps(primaryInfo);
  }, [primaryInfo]);

  const primaryDetailBoundProps: ISeriesSummaryColumnDetails | null =
    useMemo(() => {
      return getSSCDetailsProps(primaryDetails);
    }, [primaryDetails]);

  const primaryProductBoundProps: ISeriesSummaryColumnProductInfo | null =
    useMemo(() => {
      return getSSCProductInfoProps(
        primaryProductInfo,
        primaryUnfilteredProducts
      );
    }, [primaryProductInfo, primaryUnfilteredProducts]);

  const otherInfoBoundProps: ISeriesSummaryColumnInfo | null = useMemo(() => {
    return getSSCInfoProps(otherInfo);
  }, [otherInfo]);

  const otherDetailBoundProps: ISeriesSummaryColumnDetails | null =
    useMemo(() => {
      return getSSCDetailsProps(otherDetails);
    }, [otherDetails]);

  const otherProductBoundProps: ISeriesSummaryColumnProductInfo | null =
    useMemo(() => {
      return getSSCProductInfoProps(otherProductInfo, otherUnfilteredProducts);
    }, [otherProductInfo, otherUnfilteredProducts]);

  if (
    !primaryInfoBoundProps ||
    !primaryDetailBoundProps ||
    !primaryProductBoundProps
  ) {
    return null;
  }

  const primaryProps: SeriesSummaryColumnProps = {
    ...primaryInfoBoundProps,
    ...primaryDetailBoundProps,
    ...primaryProductBoundProps,
  };

  if (
    !otherInfoBoundProps ||
    !otherDetailBoundProps ||
    !otherProductBoundProps
  ) {
    return null;
  }

  const otherProps: SeriesSummaryColumnProps = {
    ...otherInfoBoundProps,
    ...otherDetailBoundProps,
    ...otherProductBoundProps,
  };

  return (
    <SeriesSummaryComparison
      primary={primaryProps}
      other={otherProps}
      secondary={secondary}
    />
  );
};

export default SSCBreakdown;
