import React, {ForwardedRef, forwardRef} from "react";
import styled from "styled-components";
import {NatSearchIcon} from "../../icon/icons";

type ChildRef = HTMLInputElement | null;

export const NatInput = forwardRef(
  (props: INatInputProps, ref: ForwardedRef<ChildRef>) => {
    const {onFocusStyle, onBlur, onFocus, showSearchIcon} = props;
    const [isFocused, setIsFocused] = React.useState(false);
    let inputStyle = props.style ?? {};
    let containerStyle = props.containerStyle ?? {};

    if (isFocused && onFocusStyle) {
      containerStyle = onFocusStyle;
    }
    if (showSearchIcon) {
      inputStyle = {
        ...inputStyle,
        textIndent: "1rem",
      };
    }

    return (
      <Wrapper style={containerStyle}>
        <Icon hidden={!showSearchIcon}>
          <NatSearchIcon />
        </Icon>
        <Input
          ref={ref}
          {...props}
          style={inputStyle}
          onFocus={(e) => {
            onFocus && onFocus(e);
            setIsFocused(true);
          }}
          onBlur={(e) => {
            onBlur && onBlur(e);
            setIsFocused(false);
          }}
        />
      </Wrapper>
    );
  }
);

const Wrapper = styled.div`
  position: relative;
  transition: all 0.2s ease-in-out;
`;

const Input = styled.input`
  background-color: #ffffff;
  /* Black 30 */

  font-size: 0.875rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 100%;
`;

const Icon = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  width: 1.75rem;
  justify-content: center;
  display: flex;
  align-items: center;
`;

interface INatInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  containerStyle?: React.CSSProperties;
  onFocusStyle?: {};
  showSearchIcon?: boolean;
}
