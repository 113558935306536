import {
  GenericBudgetSummary,
  IGenericBudgetSummaryProps,
} from "../generic/GenericBudgetSummary";
import {BUDGET_SUMMARY_SECTION_TYPE} from "@natomas-org/core";
import React from "react";

export const Phase1DesignBudgetSummary = (
  props: IGenericBudgetSummaryProps
) => {
  const type = BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DESIGN_COSTPLUS;
  return (
    <GenericBudgetSummary
      displayType={type}
      isAdmin={props.isAdmin}
      viewOnly={props.viewOnly}
      isFullView={props.isFullView}
      {...props}
    />
  );
};
