import React from "react";
import {PageContainer} from "../../../../../../_shared/generics/page/components/PageContainer";
import {PageElement} from "../../../../../../_shared/generics/page/components/PageElement";
import {ThankYouBanner} from "./components/ThankYouBanner";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../../../_shared/hooks/useDynamicValue";
import {SurveyContainer} from "./components/SurveyContainer";
import {useGlobalConfiguration} from "../../../../../../_shared/hooks/useGlobalConfiguration";

export const ThankYouPage = () => {
  const fullWidth = useDynamicValue(fullColumnWidths);
  const {SHOW_POST_SSD_SURVEY} = useGlobalConfiguration();
  return (
    <PageContainer overridePadding={false}>
      <br />
      <PageElement size={fullWidth} height={"auto"}>
        <ThankYouBanner />
      </PageElement>
      <br />
      <div hidden={!SHOW_POST_SSD_SURVEY}>
        {/* Remove this hidden tag until we can pipe into salesforce */}
        <PageElement size={fullWidth} height={"auto"}>
          <SurveyContainer />
        </PageElement>
      </div>
    </PageContainer>
  );
};
