import React, {useMemo} from "react";
import {
  IModifierValidation,
  IProductValidationDetails,
} from "../InfoSetTable/components/_shared/interfaces";
import {ProductHelperRowContent} from "./styles";
import {InfoSetItemTableRow} from "../InfoSetTable/table";
import {ID_KEY} from "../shared/constants";

export const ProductHelperRow = (props: {
  validationDetails: IProductValidationDetails;
  setChildModalInfo: any;
  infoSetData: InfoSetItemTableRow[];
}) => {
  const {validationDetails, infoSetData, setChildModalInfo} = props;
  const {product, modifierValidation, missingInfoSetKeys} = validationDetails;

  const invalidModifiers = useMemo(() => {
    return Object.values(modifierValidation)?.filter(
      (v: IModifierValidation) => v?.appearances?.length === 0
    );
  }, [modifierValidation]);

  const missingKeys = useMemo(() => {
    return missingInfoSetKeys?.map((key: string) =>
      infoSetData.find((row: InfoSetItemTableRow) => row[ID_KEY] === key)
    );
  }, [missingInfoSetKeys, infoSetData, product]);

  const isComplete = invalidModifiers?.length === 0;

  const allKeysCertain = missingInfoSetKeys?.length === 0;

  return (
    <ProductHelperRowContent
      complete={isComplete}
      onClick={(e: any) => {
        setChildModalInfo?.({event: e, product, invalidModifiers, missingKeys});
      }}
    >
      <td>{product?.title}</td>
      <td>
        {allKeysCertain ? "✔" : `${missingKeys?.length} key(s) uncertain`}
      </td>
      <td>{isComplete ? "✔" : `${invalidModifiers?.length} missing`}</td>
    </ProductHelperRowContent>
  );
};
