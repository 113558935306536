import React from "react";
import {Page, Text, View} from "@react-pdf/renderer";
import {styles} from "./styles";
// TODO Add this to core
// import {ICartProduct} from "@natomas-org/core";
import {IPageProps} from "./template";

interface ITitlePageProps extends IPageProps {
  firstName?: string;
  lastName?: string;
  address?: string;
  units?: any[]; // ICartsProduct[];
  projectRange?: {
    value: string;
    label: string;
  };
}

const TitlePage = (props: ITitlePageProps) => {
  const {firstName, lastName, units, address, footer, projectRange} = props;
  if (!props) {
    return null;
  } else {
    const EstimatedRangeText = projectRange ? (
      <View style={styles.view}>
        <Text style={styles.subtitle}>{projectRange.label}</Text>
        <Text style={styles.text}>{projectRange.value}</Text>
      </View>
    ) : null;
    return (
      <Page orientation={"landscape"} size={"A4"} style={styles.page}>
        <Text style={styles.subtitle}>
          {firstName} {lastName}
        </Text>
        <Text style={styles.title}>Villa Homes Project Proposal</Text>
        <View style={styles.view}>
          <Text style={styles.subtitle}>Address</Text>
          <Text style={styles.text}>{address}</Text>
        </View>
        <View style={styles.view}>
          <Text style={styles.subtitle}>Units</Text>
          {units?.map((unit, index) => {
            return (
              <Text style={styles.text} key={index}>
                {unit.product.title}{" "}
                {unit.quantity > 1 ? `(x${unit.quantity})` : ""}
              </Text>
            );
          })}
        </View>
        {EstimatedRangeText}
        {footer}
      </Page>
    );
  }
};

export default TitlePage;
