import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../portal/views/SSDDashboardView/styles/globals";

interface NatToggleSelectorOptionContainerProps {
  isSelected: boolean;
  isLocked: boolean;
  colorProps: NatToggleSelectorColorProps;
}

export interface NatToggleSelectorColorProps {
  isSelected: string;
  isLocked: string;
  isHovering: string;
  default: string;
}

const getBackgroundColor = (
  isHovering: boolean,
  isSelected: boolean,
  isLocked: boolean,
  colorProps: NatToggleSelectorColorProps
): string => {
  if (isSelected) {
    return colorProps?.isSelected;
  }
  if (isLocked) {
    return colorProps?.isLocked;
  }
  if (isHovering) {
    return colorProps?.isHovering;
  }
  return colorProps?.default;
};

export const NatToggleSelectorOptionContainer = styled.div<NatToggleSelectorOptionContainerProps>`
  user-select: none;
  padding: 8px 12px;
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 12px;
  background-color: ${(props: NatToggleSelectorOptionContainerProps) =>
    getBackgroundColor(
      false,
      props.isSelected,
      props.isLocked,
      props?.colorProps
    )};
  cursor: ${(props: NatToggleSelectorOptionContainerProps) =>
    props.isLocked ? "not-allowed" : "pointer"};
  transition: background-color linear 200ms;

  &:not(:last-child) {
    border-right: 1px solid ${(props) => props?.colorProps?.isSelected};
  }

  &:hover {
    background-color: ${(props: NatToggleSelectorOptionContainerProps) =>
      getBackgroundColor(
        true,
        props.isSelected,
        props.isLocked,
        props?.colorProps
      )};
  }
`;

export const NatToggleSelectorOptionsContainer = styled.div<{
  borderColor: string;
}>`
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props?.borderColor};
  border-radius: 8px;
  overflow: hidden;
`;
