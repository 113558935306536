import {IEligibleFeatures} from "@natomas-org/core";

export const isFactoryEligible = (
  eligibility?: IEligibleFeatures,
  factoryId?: string
) => {
  if (!factoryId) {
    return false;
  }
  return eligibility?.factories?.[factoryId] ?? false;
};

export const isProductEligible = (
  eligibility?: IEligibleFeatures,
  factoryId?: string,
  productId?: string
) => {
  if (!productId || !factoryId) {
    return false;
  }
  let isProductAvailable =
    eligibility?.products?.[factoryId]?.[productId] ?? false;

  // If the product availability is not defined, look for factory availability
  if (!isProductAvailable) {
    isProductAvailable = isFactoryEligible(eligibility, factoryId);
  }

  return isProductAvailable;
};
