import {StyleSheet} from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: "18px",
    fontFamily: "Inter",
    marginBottom: 20,
  },
  sitePlanTitle: {
    fontSize: "12px",
    fontFamily: "Inter",
    padding: 10,
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "16px",
    marginBottom: 10,
  },
  secondary_subtitle: {
    fontFamily: "Inter",
    fontSize: "12px",
    marginBottom: 2,
  },
  text: {
    fontSize: "14px",
    marginBottom: 4,
  },
  textSmall: {
    fontSize: "11px",
  },
  view: {marginBottom: 10},
  sitePlanHeader: {
    padding: 10,
    fontSize: 10,
    color: "grey",
    borderBottom: "1px solid grey",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: "12px",
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  footer: {
    position: "absolute",
    fontSize: "10px",
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    right: 0,
    color: "grey",
    borderTop: "1px solid grey",
  },
  footerContent: {
    height: "100%",
    width: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexSpacedContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  page: {padding: 60},
  pageNoPadding: {padding: 0},
});
