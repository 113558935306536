import {MeApi} from "@natomas-org/villa-nexus-client";
import {AxiosInstance} from "axios";
import {getAxiosInstance} from "../setup";

export class MeService {
  private axiosInstance;
  private client;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new MeApi(undefined, undefined, this.axiosInstance);
  }

  async get(create?: boolean) {
    return await this.client.getMe(create);
  }

  async create() {
    return await this.client.createMe();
  }

  async getPortfolios() {
    return await this.client.getMyPortfolios();
  }
}

export const getMeService = async () => {
  const axiosInstance = await getAxiosInstance();
  return new MeService(axiosInstance);
};
