import React, {useCallback, useMemo} from "react";
import {StyleOption} from "../../../../_shared/generics/_shared";
import {setShowSavingConfirmation} from "../../../../_shared/slices/StudioSlice";
import {useDispatch} from "react-redux";
import {useCustomerStatus} from "../../../../_shared/hooks/useCustomerStatus";
import {
  INSTANT_ESTIMATE_PAGE_ID,
  OVERVIEW_ASSESSMENT_PAGE_ID,
} from "../../../../portal/_shared/navigation/constants";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import {
  NatModal,
  NatModalSize,
} from "../../../../_shared/generics/modal/NatModal";
import useActiveDesign from "../../../../_shared/hooks/useDesign/useActiveDesign";
import useActiveProject from "../../../../_shared/hooks/useProject/useActiveProject";

export const StudioSavingModal = () => {
  const dispatch = useDispatch();
  const {id, configuration} = useActiveDesign();
  const {isCartEnabled} = useActiveProject();
  const {isCustomerPreDeposit, customerStatus} = useCustomerStatus();
  const {isShowingSavingConfirmation, isSaving} = useStudioSettings();
  const {dangerouslyNavigateTo} = useNavigation();

  const handleClose = useCallback(() => {
    dispatch(setShowSavingConfirmation(false));
  }, [dispatch]);

  const navigatePostSave = useCallback(() => {
    handleClose();
    if (isCustomerPreDeposit) {
      dangerouslyNavigateTo(NavigationPaths.PORTAL, {
        page: INSTANT_ESTIMATE_PAGE_ID,
      });
    } else if (isCartEnabled) {
      dangerouslyNavigateTo(NavigationPaths.CART);
    } else {
      dangerouslyNavigateTo(
        NavigationPaths.PORTAL,
        OVERVIEW_ASSESSMENT_PAGE_ID,
        id
      );
    }
  }, [
    customerStatus.instantEstimateStatus,
    dangerouslyNavigateTo,
    handleClose,
    id,
    isCartEnabled,
    isCustomerPreDeposit,
  ]);
  let content: any = "";
  if (configuration != null) {
    content =
      "Your ADU is looking great. Now that we’ve calculated your base unit plus upgrades let’s determine your site work to round out your instant quote.";
  }

  let titleText;
  if (isSaving) {
    titleText = undefined;
    content = "Saving your design...";
  } else {
    titleText = "Design Saved!";
  }

  const modalContent = (
    <>
      <div style={{whiteSpace: "pre-wrap"}}>{content}</div>
      <br />
    </>
  );

  const primaryButtonText = isCustomerPreDeposit
    ? "View quote"
    : isCartEnabled
    ? "Continue to cart"
    : "Continue to proposal";

  const isStudioSaving =
    configuration == null || configuration.id == null || isSaving;

  const footerButtons = useMemo(() => {
    return [
      {
        label: "Dismiss",
        trackingId: "keep-viewing-upgrades",
        disabled: isStudioSaving,
        option: StyleOption.SECONDARY_ALT,
        clickEvent: handleClose,
      },
      {
        disabled: isStudioSaving,
        label: primaryButtonText,
        clickEvent: navigatePostSave,
        option: StyleOption.PRIMARY,
        trackingId: "continue-to-proposal",
      },
    ];
  }, [isStudioSaving, handleClose, primaryButtonText, navigatePostSave]);

  return (
    <NatModal
      size={NatModalSize.NORMAL}
      show={isShowingSavingConfirmation}
      handleClose={handleClose}
      header={titleText}
      content={modalContent}
      footerButtons={footerButtons}
    />
  );
};
