import {
  changeFilter,
  getProductCategoryFilter,
} from "../../../../../_shared/catalog/filter/logic";
import {ProductCategoryType} from "../../../../../_shared/catalog/filter/types";
import {INVENTORY_UNIT_PREFIX} from "../../../../../_shared/constants/labels";

export const inventoryUnitFilter = getProductCategoryFilter(
  INVENTORY_UNIT_PREFIX ?? "Readybuilt",
  ProductCategoryType.INVENTORY_UNIT
);
export const modelFilter = getProductCategoryFilter(
  "Built to order",
  ProductCategoryType.MODEL
);

export const addInventoryUnitFilter = () => {
  changeFilter(inventoryUnitFilter);
};

export const addModelFilter = () => {
  changeFilter(modelFilter);
};
