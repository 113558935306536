import {getStatusLabel} from "../../admin/ProductCatalogView/tabs/FactoriesTab/FactoryViews/FactoryInventoryView/FactoryInventoryTable/helper";
import {InfoSetCatalogColumn, InfoSetColumnGroup} from "./structure";

export const getColumnToggleTitle = (column: any) => {
  if (column && column?.filterValue) {
    if (column.filterValue === "true" || column.filterValue === "false") {
      return `${column.Header} (Only ${column.filterValue ? "Yes" : "No"})`;
    }
    if (column.id === "status") {
      if (column?.filterValue?.length > 0) {
        return `${column.Header} (Only ${column?.filterValue?.map(
          (filterValue: number) => getStatusLabel(filterValue)
        )})`;
      } else {
        return `${column.Header} (None)`;
      }
    }
    return `${column.Header} (Only ${column.filterValue})`;
  }
  if (column) {
    return `${column.Header}`;
  }
};

export const getInfoSetTableHeaderBackgroundColor = (header: string) => {
  switch (header) {
    case InfoSetColumnGroup.CATALOG:
      return "#8C3FF6";
    case InfoSetCatalogColumn.DESCRIPTION:
      return "#AEE0FC";
    case InfoSetCatalogColumn.CATEGORY:
      return "#0090F7";
    case InfoSetCatalogColumn.INTERNAL_NOTES:
      return "#FFBEBC";
    default:
      return "#e6e6e6";
  }
};
