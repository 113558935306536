import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CategoryStructure} from "../../../_models/category";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";

import {DNDEditor} from "../../components/DragAndDropEditor/DNDEditor";
import {getBlankItemSection} from "../ProductsTab/EditProduct";
import {
  MainContent,
  MainView,
  NatStyledForm,
} from "../../../_shared/styles/AdminStyles";
import {AdminInput} from "../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {editCategory} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {EditModifierGroupOverrides} from "../../../../_shared/_legacy/EditModifierGroupOverrides";
import {RenderingsTab} from "../../components/RenderingsSubtab/RenderingsTab";
import {AdminTabHeader} from "../../components/Header/AdminTabHeader";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {
  AdminProductCatalogMatrixFilterByOptions,
  AdminProductCatalogMatrixIncludeOptions,
  IStore,
} from "../../../../_shared/slices/types/Store";
import {
  AssociationsContainer,
  AssociationSection,
} from "../../components/AssociationsContainer";
import {getProductSection} from "../../components/AssociationsContainer/predefinedSections";
import {getProductModifierUpdates} from "../MatrixTab/_shared/utilities";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {
  archiveCategory,
  revertArchiveCategory,
} from "../../../../../database/firebase/catalog/saving";
import {DuplicateFactoryLineElementButton} from "../ProductsTab/DuplicateFactoryLineElementButton";
import {DuplicateFactoryLineElementRequestType} from "@natomas-org/core";
import {MatrixTab} from "../MatrixTab";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

const tabs = [
  {id: "details", title: "Basic Info"},
  {id: "renderings", title: "Renderings"},
  {id: "structure", title: "Structure"},
  {id: "matrix", title: "Matrix"},
];

const getInitialValues = (obj: any) => {
  const initialValues = getFormInitialValues(CategoryStructure);
  if (obj != null) {
    for (const key in CategoryStructure) {
      let initialValue = null;
      if (key === "sections") {
        const value = obj[key];
        const initialListOfModifierGroups =
          obj["modifierGroups"] != null ? obj["modifierGroups"] : [];
        let newSections = value != null ? Object.assign({}, value) : {};

        if (Object.keys(newSections)?.length < 1) {
          newSections = getBlankItemSection(initialListOfModifierGroups);
        } else {
          Object.keys(newSections).forEach((key, index) => {
            const section = newSections[key];
            if (section.items == null) {
              if (index === 0) {
                newSections[key] = Object.assign(
                  {items: initialListOfModifierGroups},
                  newSections[key]
                );
              } else {
                newSections[key] = Object.assign({items: []}, newSections[key]);
              }
            }
          });
        }

        initialValue = newSections;
      } else {
        initialValue = obj[key];
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditCategory = () => {
  const editingCategory = useSelector(
    (state: IStore) => state.adminStudioManager.editingCategory
  );
  const {
    updateAdminProCatFilters,
    editingEnabled,
    updateCategory,
    updateModifiersInBulk,
  } = useAdminProductCatalog();
  const {
    categories,
    modifierGroups,
    details,
    products: factoryProducts,
  } = useSelectedFactoryLine();
  const category = useMemo(() => {
    return categories != null && categories[editingCategory?.id] != null
      ? categories[editingCategory?.id]
      : editingCategory;
  }, [categories, editingCategory]);

  const [lastCategoryId, setLastCategoryId] = useState("");
  const [matrixEdits, setMatrixEdits] = useState<any>(null);
  const [tab, setTab] = useState(tabs[0].id);
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(editCategory(null));
  }, [dispatch]);

  const undoDeleteAction = useCallback(() => {
    if (details?.id && details?.versionId && category?.id) {
      revertArchiveCategory(details?.id, details?.versionId, category?.id);
    }
  }, [category, details?.id, details?.versionId]);

  const deleteAction = useCallback(() => {
    if (details?.id && details?.versionId && category?.id) {
      archiveCategory(details?.id, details?.versionId, category?.id);
    } else {
      close();
    }
  }, [category?.id, close, details?.id, details?.versionId]);

  const updateMatrixFilters = () => {
    updateAdminProCatFilters({
      include: AdminProductCatalogMatrixIncludeOptions.ALL,
      filterBy: AdminProductCatalogMatrixFilterByOptions.CATEGORY,
      filterIds: [category?.id],
    });
  };

  const changeTab = (id: string) => {
    if (id === "matrix") {
      updateMatrixFilters();
    }
    setTab(id);
  };

  const initialValues = getInitialValues(category);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      if (matrixEdits) {
        saveMatrixEdits();
      }
      const infoToSave = Object.assign({}, values);
      updateCategory(category?.id, infoToSave);
    },
  });

  useEffect(() => {
    if (category != null && lastCategoryId !== category.id) {
      formik.resetForm();
      setLastCategoryId(category.id);
      formik.setValues(initialValues);
      updateMatrixFilters();
    }
  }, [category]);

  if (category == null) {
    return null;
  }

  const libraryIds = Object.keys(modifierGroups ?? {});
  const configurationPageStructureForForm = getFormStructure(CategoryStructure);

  const getAssociationSections = (): AssociationSection[] | undefined => {
    const products = Object.values(factoryProducts ?? {})?.filter((p: any) => {
      return p?.configuratorPages?.includes(editingCategory.id);
    });
    if (!products ?? !products?.length) return [];
    else {
      return [getProductSection(products)];
    }
  };

  let mainContent;
  if (tab === "details") {
    mainContent = (
      <MainContent>
        <NatStyledForm onSubmit={formik.handleSubmit}>
          {configurationPageStructureForForm.map((keyInfo) => {
            const {key} = keyInfo;
            // @ts-ignore
            const value = formik.values[key];
            if (keyInfo.hiddenInput === true) {
              return null;
            }

            // @ts-ignore
            let initial = initialValues[key];

            return (
              <AdminInput
                key={key}
                value={value}
                initialValue={initial}
                disabled={!keyInfo?.alwaysEnabled && !editingEnabled}
                fieldInfo={keyInfo}
                onChange={(value: any) => {
                  formik.setFieldValue(key, value);
                }}
              />
            );
          })}
        </NatStyledForm>
        <AssociationsContainer sections={getAssociationSections() ?? []} />
      </MainContent>
    );
  } else if (tab === "renderings") {
    mainContent = (
      <MainContent>
        <RenderingsTab
          category={category}
          renderings={category.renderings}
          modifierGroupIds={category.modifierGroups}
        />
      </MainContent>
    );
  } else if (tab === "matrix") {
    mainContent = (
      <MatrixTab
        subTabMode={true}
        setMatrixEdits={setMatrixEdits}
        matrixEdits={matrixEdits}
      />
    );
  } else {
    const key = "modifierGroups";
    // @ts-ignore
    const sections = formik.values["sections"];
    // @ts-ignore
    const value = formik.values[key];
    mainContent = (
      <MainContent>
        <div style={{padding: "1rem 0"}}>
          <DNDEditor
            sections={sections}
            fieldKey={key}
            formik={formik}
            savedIds={value}
            libraryIds={libraryIds}
            type={"modifierGroup"}
            callback={(newSavedIds: any, newSections: any) => {
              if (newSections) {
                formik.setFieldValue("sections", newSections);
              }
              formik.setFieldValue(key, newSavedIds);
            }}
          />
        </div>
      </MainContent>
    );
  }

  const resetMatrixEdits = () => {
    setMatrixEdits(null);
  };

  const saveMatrixEdits = () => {
    const dbUpdates = getProductModifierUpdates(matrixEdits);
    updateModifiersInBulk(dbUpdates)?.then(() => {
      resetMatrixEdits();
    });
  };

  const upToDate = isDeepEqual(formik.values, initialValues) && !matrixEdits;
  return (
    <MainView>
      <AdminTabHeader
        tabs={tabs}
        setTab={changeTab}
        discardAction={() => {
          resetMatrixEdits();
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        isUpToDate={upToDate}
        undoDeleteAction={isArchived(category) ? undoDeleteAction : undefined}
        deleteAction={!isArchived(category) ? deleteAction : undefined}
        saveAction={formik.handleSubmit}
        additionalActions={
          <>
            {editingEnabled && category?.id && details?.id && (
              <DuplicateFactoryLineElementButton
                factoryLineId={details?.id}
                requestId={category?.id}
                requestType={DuplicateFactoryLineElementRequestType.CATEGORY}
                resetAction={close}
              />
            )}
          </>
        }
      />
      {mainContent}
      <EditModifierGroupOverrides
        callback={(valuesChanged: any) => {
          Object.keys(valuesChanged).forEach((key) => {
            formik.setFieldValue(key, valuesChanged[key]);
          });
        }}
      />
    </MainView>
  );
};
