import React, {useState} from "react";
import VCL from "@natomas-org/villa-component-library";
import {IProduct} from "@natomas-org/core";
import {useProduct} from "../../../components/_shared/hooks/useProductCatalog/useProduct";
import {IInstantQuoteLocation} from "../../../api/Admin/InstantQuote/interface";
import EditLocation from "./EditLocation";
import CreateLocation from "./CreateLocation";
import Header from "./header";
import useLocationsByProductId from "../../../api/Admin/InstantQuote/useAdminLocationsByProductId";
import MultiPointMap from "./MultiPointMap";

const Page = (props: {product?: IProduct}) => {
  const {product: propProduct} = props;
  const {factoryDetails, product} = useProduct(
    propProduct?.id,
    propProduct?.productGroupId
  );
  const productId = product?.id;
  const {data: locations, refetch} = useLocationsByProductId(product?.id);
  const [activeLocation, setActiveLocation] = useState<IInstantQuoteLocation>();
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const hideCreateButton = !!activeLocation || showCreateForm;
  if (!productId) {
    return null;
  }
  return (
    <div className={"vcl-container-primary-32"}>
      <Header title={product?.title} />
      <VCL.Components.Atoms.Subtitle
        properties={{
          children: factoryDetails?.internal_title ?? "Unknown factory",
        }}
      />
      <VCL.Components.Atoms.Tabs
        tabs={[
          {
            id: "instant-quote",
            name: "Instant Quote",
            content: "Instant Quote content",
          },
        ]}
        activeId={"instant-quote"}
        onClick={(id: string) => console.log(id)}
      />
      <div className={"vcl-container-row-spaced"}>
        <VCL.Components.Atoms.Dropdown
          options={locations?.map((location) => location.label) ?? []}
          onSelect={(label: string) => {
            const location = locations?.find((loc) => loc.label === label);
            setActiveLocation(location);
          }}
          selectedOption={activeLocation?.label}
          placeholder={"Select a location..."}
        />
        <VCL.Components.Atoms.Button
          hidden={hideCreateButton}
          label={"Create new instant quote location"}
          type={"button"}
          size={"small"}
          theme={"secondary"}
          onClick={() => setShowCreateForm(true)}
        />
      </div>
      {!!activeLocation && (
        <EditLocation
          location={activeLocation}
          cancel={() => setActiveLocation(undefined)}
          afterSubmission={() => {
            setActiveLocation(undefined);
            refetch();
          }}
        />
      )}
      {showCreateForm && (
        <CreateLocation
          productId={productId}
          cancel={() => setShowCreateForm(false)}
          afterSubmission={() => {
            setShowCreateForm(false);
            refetch();
          }}
        />
      )}
      {!activeLocation && !showCreateForm && (
        <div
          style={{
            minWidth: "100%",
            height: "400px",
            position: "relative",
            borderRadius: "16px",
            overflow: "hidden",
          }}
        >
          <MultiPointMap
            points={
              locations?.map((location) => {
                return {
                  latitude: location.latitude,
                  longitude: location.longitude,
                  label: location.label,
                };
              }) ?? []
            }
            edit={(lat, lng) => {
              const location = locations?.find(
                (loc) => loc.latitude === lat && loc.longitude === lng
              );
              if (location) {
                setActiveLocation(location);
              } else {
                console.error("Selected location not found");
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Page;
