import React, {useCallback, useEffect, useState} from "react";
import {queryImagesByAuthor} from "../../../../../../database/firebase/images";
import {useCurrentUser} from "../../../../hooks/useCurrentUser";
import {ImageGallery} from "../_shared/ImageGallery";
import {convertCollectionSnapshotToMap} from "../../../../../../database/firebase/utilities";
import {ImageFinderSectionProps} from "../index";

const IMAGE_QUERY_LIMIT = 3;

export const MyUploadsSection = (props: ImageFinderSectionProps) => {
  const {selectImage, showEditDetails} = props;
  const {uid} = useCurrentUser();

  const [imageIds, setImageIds] = useState<string[]>([]);
  const [latestImageDoc, setLatestImageDoc] = useState<any>(undefined);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const getAllImagesMatchingUserId = () => {
    if (uid) {
      queryImagesByAuthor(IMAGE_QUERY_LIMIT, uid, latestImageDoc)?.then(
        (querySnapshot: any) => {
          // Nothing found
          if (querySnapshot?.empty) {
            setIsEnd(true);
          } else {
            // We know it is end of list if true
            if (querySnapshot?.size < IMAGE_QUERY_LIMIT) {
              setIsEnd(true);
            } else {
              setIsEnd(false);
            }
            const images = convertCollectionSnapshotToMap(querySnapshot);
            const newImageIds = images?.map((image: any) => image?.id);
            // Add all image ids that exist that are not present in imageIds
            setImageIds(
              imageIds.concat(
                newImageIds.filter(
                  (imageId: string) => !imageIds.includes(imageId) && !!imageId
                )
              )
            );
            setLatestImageDoc(querySnapshot.docs[querySnapshot.size - 1]);
          }
        }
      );
    } else {
      setIsEnd(true);
      setLatestImageDoc(undefined);
      setImageIds([]);
    }
  };

  // Memoize the query function
  const queryImages = useCallback(getAllImagesMatchingUserId, [
    uid,
    latestImageDoc,
    imageIds,
  ]);

  // Re-query items if callback changes
  useEffect(() => {
    queryImages();
  }, [uid]);

  return (
    <ImageGallery
      imageIds={imageIds}
      showEditDetails={showEditDetails}
      selectImage={selectImage}
      queryImages={queryImages}
      isEnd={isEnd}
    />
  );
};
