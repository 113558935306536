import styled from "styled-components";

export const RowStatusContainer = styled.div`
  padding: 0 0.5rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RowStatusIcon = styled.div<{color: string; sizePx?: number}>`
  border: 1px solid white;
  border-radius: 100%;
  height: ${(props) => (props.sizePx ?? 12) + 1}px;
  width: ${(props) => (props.sizePx ?? 12) + 1}px;
  margin-left: -0.25rem;
  background-color: ${(props) => props.color};
`;

export const RowStatus = styled.div`
  margin-left: -0.25rem;
`;

export const RowStatusTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
