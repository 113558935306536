import React from "react";
import useTeamMemberPhoto from "./useTeamMemberPhoto";
import Photo from "./Photo";

const TeamMemberPhoto = ({email}: {email?: string}) => {
  const {data, isSuccess, isFetching, isLoading} = useTeamMemberPhoto(email);
  return (
    <Photo
      url={isSuccess ? data?.url : undefined}
      loading={isFetching ?? isLoading}
    />
  );
};

export default TeamMemberPhoto;
