import {useUrl} from "./useUrl";
import {ADMIN_ROOT_PATH} from "../../../paths";
import {useMemo} from "react";

export const useIsAdminSystem = () => {
  const {getURLPath} = useUrl();
  return useMemo(() => {
    return getURLPath().includes(ADMIN_ROOT_PATH);
  }, [getURLPath]);
};
