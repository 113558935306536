import {getSelectionMap} from "../_shared/helper";
import {updateUserDesignConfiguration} from "../../../database/firebase/api/user";
import {Utilities} from "../../../database/firebase";
import {CONFIGURATION_DESIGN_DB_KEY} from "../../../database/firebase/configuration/configuration";

export const buildConfigurationObject = (summary: any) => {
  const images = summary?.structure
    ?.map((category: any) => {
      return category?.activeImages ?? [];
    })
    .flat();
  return {
    product: summary?.product,
    selectedModifiers: getSelectionMap(summary?.selectedModifiers),
    upgradesInMicros: summary?.upgradesInMicros,
    upgradePrice: summary?.upgradePrice,
    totalUnitPrice: summary?.totalUnitPrice,
    configurationSnapshot: summary?.structure,
    images: images,
  };
};
export const saveStudioDesignToConfiguration = async (
  projectId: string,
  formik: any,
  summary: any,
  designId?: string
) => {
  /* Data flow for saving configuration to project
   * 1. Build configuration object
   * 2. Execute save configuration api endpoint
   * 3. Set new configuration id in state
   *   a. If no configuration id yet, fetch configuration + set id in history
   * 4. return configuration id
   * */

  const images = summary?.structure
    ?.map((category: any) => {
      return category?.activeImages ?? [];
    })
    .flat();
  console.log("Save studio design to configuration", formik, designId);
  const id = Utilities.collection(CONFIGURATION_DESIGN_DB_KEY).doc(
    designId ?? undefined
  ).id;
  console.log("id", id);
  const configuration = {
    product: summary?.product,
    selectedModifiers: getSelectionMap(summary?.selectedModifiers),
    upgradesInMicros: summary?.upgradesInMicros,
    upgradePrice: summary?.upgradePrice,
    totalUnitPrice: summary?.totalUnitPrice,
    configurationSnapshot: summary?.structure,
    configurationId: id,
    id: id,
    images: images,
  };
  console.log("configuration", configuration);
  await updateUserDesignConfiguration(projectId, configuration).then((res) =>
    console.log(res)
  );
  console.log("configurationId", id);
  return id;
};

// const setConfigurationIdInHistory = (configurationId: string) => {
//   let pathname = window.location.pathname;
//   let searchParams = new URLSearchParams(window.location.search);
//   searchParams.set("cid", configurationId);
//   history.push({pathname: pathname, search: searchParams.toString()});
// };
