import {
  getEstimateService,
  IEstimateRangeItem,
} from "../../../api/Estimate/EstimateService";
import useActivitySlice from "../../_shared/hooks/useActivity/useActivitySlice";
import {useCallback} from "react";
import {useRequestHandler} from "../../admin/NexusDemoView/useRequestDebugger";

const saveEstimateByAllEstimateServiceItems = async (
  pid: string,
  items: IEstimateRangeItem[]
) => {
  const e = await getEstimateService();
  return e.createByEstimateEntries(pid, items);
};
export const useEstimateController = () => {
  const {activeProjectId} = useActivitySlice();

  const {fetch: saveEstimate} = useRequestHandler({
    method: saveEstimateByAllEstimateServiceItems,
    maxTry: 3,
  });

  const save = useCallback(
    (items: IEstimateRangeItem[]) => {
      if (!activeProjectId) {
        throw new Error("No active project");
      } else if (!items || items.length === 0) {
        throw new Error("No items to save");
      } else {
        return saveEstimate(activeProjectId, items);
      }
    },
    [activeProjectId, saveEstimate]
  );

  return {save};
};
