import React from "react";
import styled from "styled-components";
import {DesignButton, MultiSelectCheckBox, TextContainer} from "./styles";

export const MultiSelectTextButton = (props: any) => {
  let priceText = props.price;
  if (priceText == null) {
    priceText = "Included";
  }
  const svgClass = props.isSelected === true ? "checkedSVG" : "";

  return (
    <MultiSelectButton
      id={"modifier-" + props.buttonId}
      onClick={props.onClick}
      active={props.isSelected}
      disabled={props.isDisabled}
    >
      <MultiSelectCheckBox active={props.isSelected}>
        <svg
          viewBox="0 0 20 20"
          width="16"
          height="16"
          className={svgClass}
          preserveAspectRatio="xMidYMin"
        >
          <polyline points="5 10.75 8.5 14.25 16 6" />
        </svg>
      </MultiSelectCheckBox>
      <TextContainer>
        <p>{props.buttonText}</p>
        <p>{priceText}</p>
      </TextContainer>
    </MultiSelectButton>
  );
};

const MultiSelectButton = styled(DesignButton)`
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
`;
