import React, {Dispatch, SetStateAction} from "react";
import {IOrderFormTableCellValue} from "../../interfaces";
import {NatWarningDiamondIcon} from "../../../../../../../../../_shared/icon/icons";
import {OrderFormTableCellEditorInfo} from "./OrderFormTableCellEditorInfo";
import {OrderFormTableCellIconContainer} from "../styles";
import {OrderFormTableCellLockToggle} from "./OrderFormTableCellLockToggle";

export const OrderFormTableCellController = (props: {
  cell: IOrderFormTableCellValue;
  hasValue: boolean;
  locked: boolean;
  setLocked: Dispatch<SetStateAction<boolean>>;
}) => {
  const {cell, hasValue, locked, setLocked} = props;

  return (
    <OrderFormTableCellIconContainer>
      <OrderFormTableCellEditorInfo cell={cell} />
      {hasValue ? (
        <OrderFormTableCellLockToggle locked={locked} setLocked={setLocked} />
      ) : (
        <NatWarningDiamondIcon color={"darkorange"} size={20} />
      )}
    </OrderFormTableCellIconContainer>
  );
};
