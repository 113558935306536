import React from "react";
import {StudioCarousel} from "../../../../../../../../../../../../studio/_shared/components/StudioCarousel";
import {ImageWrapper} from "./styles";

interface RenderingPreviewProps {
  image?: any;
}

const RenderingPreview = (props: RenderingPreviewProps) => {
  const {image} = props;

  return (
    <ImageWrapper>
      <StudioCarousel images={[image]} animate={false} mobileVersion={false} />
    </ImageWrapper>
  );
};

export default RenderingPreview;
