import styled from "styled-components";
import {DIVIDER_COLOR, ERROR_COLOR, WHITE} from "../../../colors";

export const StyledNatTextInput = styled.input<{hasError: boolean}>`
  border: 1px solid
    ${(props) => (props?.hasError ? ERROR_COLOR : DIVIDER_COLOR)};
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 20px;
  background: ${WHITE};
`;
