import {
  BUDGET_SECTION_ADDITIONAL,
  BUDGET_SECTION_UNIT,
  BUDGET_SUMMARY_SECTION_TYPE,
  BudgetEntryProps,
  entryIsEditable,
  getBreakdownTitleText,
  getPriceMicrosFromText,
  ProjectBudget,
  ProjectPriceMode,
  TBudgetSection,
} from "@natomas-org/core";
import React, {useMemo, useState} from "react";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {MainContainer, RowContainer} from "../../styles/SiteWorkTableStyles";
import {TableEditorButtonContainer} from "./styles";
import {NatButton} from "../../../../../_shared/generics/button";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {IGenericBudgetSummaryProps} from "./GenericBudgetSummary";
import {GenericLoadingComponent} from "./GenericLoadingComponent";
import {useProjectBudget} from "../../../../../_shared/hooks/useProjectBudget/useProjectBudget";
import {
  Column,
  DataSheetGrid,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";
import {SelectComponent, SelectOptions} from "./EditorComponents/SelectColumn";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";

export enum BudgetColumns {
  TITLE = "title",
  BUCKET = "bucket",
  DESCRIPTION = "description",
  PRICE = "price",
  COST = "cost",
  PRICE_HIGH = "price_high",
  COST_HIGH = "cost_high",
  PRICE_LOW = "price_low",
  COST_LOW = "cost_low",
  GP_LOW = "gp_low",
  GP_HIGH = "gp_high",
  GM_LOW = "gm_low",
  GM_HIGH = "gm_high",
}

interface BudgetCellProps {
  id: string;
  displayTitle: string | null;
  price: number | null;
  cost: number | null;
  bucket: TBudgetSection;
  currency?: string;
  note: string | undefined;
}

export const GenericBudgetSummaryEditor = (
  props: IGenericBudgetSummaryProps & {
    displayType: TBudgetSection | null;
    saveModifications: (
      requestPayload: {
        [key: string]: BudgetEntryProps;
      },
      baseBudget: ProjectBudget<ProjectPriceMode>
    ) => void;
    columns: BudgetColumns[];
  }
) => {
  const {projectBudget} = useProjectBudget();
  const {projectPriceMode} = useActiveProject();
  const data: BudgetCellProps[] = [];

  props.budgetEntries?.forEach((entry) => {
    if (!entry?.id || !entry?.bucket || !entryIsEditable(entry)) {
      return;
    }
    let priceDecimal = entry.price?.price ?? null;
    if (priceDecimal) {
      priceDecimal = parseFloat((priceDecimal / 100).toFixed(2));
    }
    let costDecimal = entry.price?.cost ?? null;
    if (costDecimal) {
      costDecimal = parseFloat((costDecimal / 100).toFixed(2));
    }
    let note: string | undefined = undefined;
    if (entry.note && Array.isArray(entry.note)) {
      note = entry.note.join("\n");
    } else {
      note = entry.note;
    }
    const entryFormatted = {
      bucket: entry.bucket as TBudgetSection,
      id: entry.id,
      price: priceDecimal,
      cost: costDecimal,
      displayTitle: entry.displayTitle,
      currency: entry.price?.currency ?? undefined,
      note: note,
    };

    data.push(entryFormatted);
  });

  const [tableData, setTableData] = useState<BudgetCellProps[]>(data);
  const isNatMobile = usePage().isNatMobile;

  const selectColumn = (
    options: SelectOptions
  ): Column<string | null, SelectOptions> => ({
    // @ts-ignore
    component: SelectComponent,
    columnData: options,
    disableKeys: true,
    keepFocus: true,
    disabled: options.disabled,
    deleteValue: () => null,
    copyValue: ({rowData}) =>
      options.choices.find((choice) => choice.value === rowData)?.label ?? null,
    pasteValue: ({value}) =>
      options.choices.find((choice) => choice.label === value)?.value ?? null,
  });

  const buckets = useMemo(() => {
    if (projectPriceMode === ProjectPriceMode.DTC) {
      return DTC_BUCKETS;
    } else {
      return B2B_BUCKETS;
    }
  }, [projectPriceMode]);
  const onPaste = (column: any, data: any) => {
    let newValue = data?.value;
    console.log("Pasted value: ", column, data);
    if (column === BudgetColumns.PRICE || column === BudgetColumns.COST) {
      const valueString = data?.rowData?.[column]?.toString();
      if (valueString) {
        newValue = getPriceMicrosFromText(valueString) / 100;
        console.log("New value: ", newValue);
      }
    }
    data.rowData[column] = newValue;
    console.log(data);
    return data?.rowData;
  };

  const prePasteValue = (values: string[]): any[] | Promise<any[]> => {
    console.log(values);
    return values;
  };

  const BUCKET_COLUMN = {
    ...keyColumn(
      "bucket",
      selectColumn({
        choices: [
          ...buckets.map((bucket) => ({
            value: bucket,
            label:
              getBreakdownTitleText(
                bucket as TBudgetSection,
                projectPriceMode
              ) ?? "",
          })),
        ],
      })
    ),
    title: "Section",
  };
  const TITLE_COLUMN = {
    ...keyColumn("displayTitle", textColumn),
    title: "Item",
    disabled: function (rowData: BudgetCellProps) {
      const customTitle = [
        BUDGET_SECTION_ADDITIONAL,
        BUDGET_SECTION_UNIT,
      ].includes(props.displayType ?? rowData.bucket);
      return !customTitle;
    },
  };
  const DESCRIPTION_COLUMN = {
    ...keyColumn("note", textColumn),
    title: "Notes & Comments",
  };
  const PRICE_COLUMN = {
    ...keyColumn("price", textColumn),
    title: "Price",
    pasteValue: (data: any) => onPaste(BudgetColumns.PRICE, data),
    prePasteValue: prePasteValue,
  };
  const COST_COLUMN = {
    ...keyColumn("cost", textColumn),
    title: "Cost",
    pasteValue: (data: any) => onPaste(BudgetColumns.COST, data),
    prePasteValue: prePasteValue,
  };

  const columns: any[] = [];
  props.columns.forEach((column) => {
    switch (column) {
      case BudgetColumns.BUCKET:
        columns.push(BUCKET_COLUMN);
        break;
      case BudgetColumns.TITLE:
        columns.push(TITLE_COLUMN);
        break;
      case BudgetColumns.DESCRIPTION:
        columns.push(DESCRIPTION_COLUMN);
        break;
      case BudgetColumns.PRICE:
        columns.push(PRICE_COLUMN);
        break;
      case BudgetColumns.COST:
        columns.push(COST_COLUMN);
        break;
      default:
        break;
    }
  });

  if (!data || !projectBudget) {
    return <GenericLoadingComponent />;
  }

  return (
    <MainContainer>
      <div>
        {/*{headers}*/}
        <DataSheetGrid
          // @ts-ignore
          value={tableData}
          addRowsComponent={false}
          // @ts-ignore
          onChange={(value) => setTableData(value)}
          columns={columns}
        />

        <RowContainer isNatMobile={isNatMobile}></RowContainer>
      </div>
      <br />
      <GenericLoadingComponent hidden={!!data} />
      <TableEditorButtonContainer hidden={!data}>
        <NatButton
          label={`Save ${
            props.displayType
              ? getBreakdownTitleText(props.displayType, projectPriceMode)
              : "Project"
          } Breakdown`}
          // type={"button"}
          clickEvent={() => {
            const budgetEntryProps: {[key: string]: BudgetEntryProps} = {};
            tableData.forEach((cellData) => {
              budgetEntryProps[cellData.id] =
                budgetCellDataToBudgetEntry(cellData);
            });
            props.saveModifications(budgetEntryProps, projectBudget);
          }}
          option={StyleOption.PRIMARY}
        />
      </TableEditorButtonContainer>
    </MainContainer>
  );
};

const budgetCellDataToBudgetEntry = (
  cellData: BudgetCellProps
): BudgetEntryProps => {
  return {
    id: cellData.id,
    bucket: cellData.bucket,
    displayTitle: cellData.displayTitle,
    note: cellData.note,
    price: {
      price: cellData.price ? Math.round(cellData.price * 100) : null,
      cost: cellData.cost ? Math.round(cellData.cost * 100) : null,
      currency: cellData.currency ?? null,
    },
  };
};

const DTC_BUCKETS = [
  BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_DTC,
  BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DTC,
  BUDGET_SECTION_UNIT,
  BUDGET_SECTION_ADDITIONAL,
];

const B2B_BUCKETS = [
  BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_DESIGN_COSTPLUS,
  BUDGET_SUMMARY_SECTION_TYPE.PRE_CON_PERMITS_COSTPLUS,
  BUDGET_SUMMARY_SECTION_TYPE.SITE_WORK_DTC,
  BUDGET_SECTION_UNIT,
  BUDGET_SECTION_ADDITIONAL,
];
