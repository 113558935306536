import React, {useEffect, useState} from "react";
import NumberInput from "./NumberInput";
import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../../portal/views/SSDDashboardView/styles/globals";

const TimelinePeriodInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  width: fit-content;
`;
const TimelinePeriodInputLabel = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  text-align: right;
  width: 200px;
  max-width: 200px;
`;

export interface ITimelinePeriod {
  months: number;
  stage: string;
  startMonth?: number;
}

export interface TimelinePeriodProps {
  id: string;
  initialValue: ITimelinePeriod;
  onChange: (value: any) => void;
}

const TimelinePeriodInput = (props: TimelinePeriodProps) => {
  const {id, onChange, initialValue} = props;
  const {months, stage} = initialValue;

  const [currentMonths, setCurrentMonths] = useState(months ?? 0);

  useEffect(() => {
    if (initialValue?.months !== currentMonths) {
      onChange({
        ...initialValue,
        months: currentMonths,
      });
    }
  }, [onChange, initialValue, currentMonths]);

  return (
    <TimelinePeriodInputContainer>
      <TimelinePeriodInputLabel>{stage}</TimelinePeriodInputLabel>
      <NumberInput
        id={`${id}-${stage}`}
        onChange={setCurrentMonths}
        initialValue={currentMonths}
        range={[0, 11]}
      />
      months
    </TimelinePeriodInputContainer>
  );
};

export default TimelinePeriodInput;
