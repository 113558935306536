import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  Address,
  ICompletedProjectsDictionary,
  IPropertyData,
  IPropertyDataDictionary,
  IPublicProjectInfo,
  IPublicProjectMetadata,
  LOCATION_RANDOMIZATION_RADIUS_METERS,
  PublicProjectDisplayType,
} from "@natomas-org/core";
import {randomizeCoordinatesWithinGivenRadius} from "@natomas-org/service";
import {Point} from "@turf/helpers";

export interface ICompletedProjectsSlice {
  allProjects: ICompletedProjectsDictionary | undefined;
  propertyData: ICompletedProjectsDictionary | undefined;
}

const getInitialState = (): ICompletedProjectsSlice => {
  return {allProjects: undefined, propertyData: undefined};
};

const completedProjectsSlice = createSlice({
  name: "completedProjects",
  initialState: getInitialState(),
  reducers: {
    setCompletedProjects: (
      state,
      action: PayloadAction<ICompletedProjectsDictionary>
    ) => {
      state.allProjects = Object.assign({}, state, action.payload);
    },
    storePropertyData: (
      state,
      action: PayloadAction<IPropertyDataDictionary>
    ) => {
      const propertyDataDictionary = action.payload;
      if (!state.propertyData) {
        state.propertyData = {} as ICompletedProjectsDictionary;
      }
      Object.keys(propertyDataDictionary).forEach((propertyDataKeyId) => {
        if (state.propertyData) {
          const dataToSet = convertPropertyDataToTargetPublicProjectInfo(
            propertyDataDictionary[propertyDataKeyId]
          );
          if (dataToSet) {
            state.propertyData[propertyDataKeyId] = dataToSet;
          }
        }
      });
    },
  },
});

export const completedProjectsReducer = completedProjectsSlice.reducer;
export const {setCompletedProjects, storePropertyData} =
  completedProjectsSlice.actions;

function convertPropertyDataToTargetPublicProjectInfo(
  propertyData: IPropertyData
): IPublicProjectInfo | null {
  if (!propertyData) {
    return null;
  }
  const address = propertyData?.address;
  const addressCoordinates: number[] | null = Address.getCoordinates(
    address ?? undefined
  );
  let randomizedCoordinates: {lat: number; lng: number} | undefined = undefined;
  if (!!addressCoordinates && addressCoordinates?.length > 1) {
    const randomizedCoordinatesPoint: Point =
      randomizeCoordinatesWithinGivenRadius(
        addressCoordinates[0],
        addressCoordinates[1],
        LOCATION_RANDOMIZATION_RADIUS_METERS
      );
    if (
      !!randomizedCoordinatesPoint?.coordinates &&
      randomizedCoordinatesPoint?.coordinates?.length > 1
    ) {
      randomizedCoordinates = {
        lat: randomizedCoordinatesPoint?.coordinates[1],
        lng: randomizedCoordinatesPoint?.coordinates[0],
      };
    }
  }

  return Object.assign({
    randomizedCoordinates: randomizedCoordinates,
    code: propertyData?.id,
    displayType: PublicProjectDisplayType.TARGET,
    title: Address.getFullAddressNoCountry(address),
    published: true,
    featured: false,
    imageIds: [],
    metadata: {} as unknown as IPublicProjectMetadata,
    project_address: propertyData?.address,
  });
}
