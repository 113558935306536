import {useMemo} from "react";
import {useSelectedFactoryLine} from "../../../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {getBadModifiers, getBadProductModifiers} from "./helper";
import {IModifierPricingReview, ReviewStatus} from "./interface";

export interface PricingReviewResult {
  success: IModifierPricingReview[];
  warning: IModifierPricingReview[];
  error: IModifierPricingReview[];
  edit: IModifierPricingReview[];
  all: IModifierPricingReview[];
}

const usePricingReview = (edits: any): PricingReviewResult => {
  // Preliminary data
  const {
    products: allProducts,
    productModifiers: allProductModifiers,
    modifiers: allModifiers,
  } = useSelectedFactoryLine();

  const productModifiersToReview = useMemo(() => {
    return getBadProductModifiers(
      allProductModifiers,
      allProducts,
      allModifiers,
      edits
    );
  }, [allModifiers, allProductModifiers, allProducts, edits]);

  const modifiersToReview = useMemo(() => {
    return getBadModifiers(allModifiers, edits);
  }, [allModifiers, edits]);

  // All to review
  const all = useMemo(() => {
    return [...modifiersToReview, ...productModifiersToReview];
  }, [modifiersToReview, productModifiersToReview]);

  const success = useMemo(() => {
    return all?.filter((review) => review?.status === ReviewStatus.SUCCESS);
  }, [all]);

  const warning = useMemo(() => {
    return all?.filter((review) => review?.status === ReviewStatus.WARNING);
  }, [all]);

  const error = useMemo(() => {
    return all?.filter((review) => review?.status === ReviewStatus.ERROR);
  }, [all]);

  const edit = useMemo(() => {
    return all?.filter((review) => review?.isEdit === true);
  }, [all]);

  return {
    success,
    all,
    warning,
    error,
    edit,
  };
};

export default usePricingReview;
