import styled from "styled-components";
import {BLACK, GRAY} from "../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const ModifierGroupDescription = styled.p`
  margin: 0;
  padding: 0 1rem;
  font-size: 0.875rem;
  color: ${GRAY};
`;

export const ModifierGroupWrapper = styled.div`
  background-color: white;
  margin-bottom: 4rem;
`;

export const ModifierGroupContainer = styled.div`
  transition: all 100ms ease;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  padding: 0 1rem;
`;

export const ModifierGroupTitleWrapper = styled.div<{inSection: boolean}>`
  z-index: 97;
  background-color: white;
  font-size: 1.2rem;
  color: ${BLACK};
  font-family: ${FONT_FAMILY_MEDIUM};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1rem;
`;
