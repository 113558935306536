import {Configuration, firestore, Utilities} from "../index";
import {
  addressDetailsNatToFS,
  allowFetchFromUser,
  Customer,
  ICustomerInfo,
  IEventInfo,
  isBlankString,
  mergeUserInfoAndUserLeadInfo,
  NatomasUserInfo,
  V0UserInfo,
} from "@natomas-org/core";
import {getStore, store} from "../../../store";
import {getParameter} from "../../../components/_shared/cookies";
import {
  USER_ACTION_SUB_COLLECTION_KEY,
  USER_COLLECTION_KEY,
  USER_LEAD_INFO_COLLECTION_KEY,
  USER_PERMISSIONS_COLLECTION_KEY,
} from "./constants";
import {
  collection,
  deleteCollection,
  subscribeToCollectionWithCallback,
  subscribeWithCallback,
} from "../utilities";
import {syncUserInfoWithSalesForce} from "../api/user";
import {
  storeBulkUserPermissions,
  storeUserActions,
  storeUserAuthInfo,
  storeUserPermissions,
} from "../../../components/_shared/slices/UserSlice";
import {userLoaded} from "../../../components/_shared/slices/GlobalSlice";
import {
  natomasCustomerToCustomerInfo,
  v0UserToNatomasCustomerInfo,
} from "../adapters";
import {isAdmin} from "../../../components/_shared/user/UserUtils";
import {getUserAuthInfo} from "../api/admin";
import firebase from "firebase/app";
import {updateCartItems} from "../../../components/_shared/slices/CartSlice/CartSlice";

const getNatomasUsersRef = (userId: string) => {
  return collection(USER_COLLECTION_KEY).doc(userId);
};

const getNatomasUserLeadInfoRef = (userId: string) => {
  return Utilities.collection(USER_LEAD_INFO_COLLECTION_KEY).doc(userId);
};

const getUserActionCollectionRef = (userId: string) => {
  return Utilities.collection(USER_COLLECTION_KEY)
    .doc(userId)
    .collection(USER_ACTION_SUB_COLLECTION_KEY);
};

const getPermissionsCollectionRef = () => {
  return Utilities.collection(USER_PERMISSIONS_COLLECTION_KEY);
};

const getUserPermissionsDocRef = (userId: string) => {
  return Utilities.collection(USER_PERMISSIONS_COLLECTION_KEY).doc(userId);
};

const getUsersRef = (userId: string) => {
  return firestore.collection("users").doc(userId);
};

const mergeUserAndStore = (
  userInfo: NatomasUserInfo | undefined,
  userLeadInfo: NatomasUserInfo | undefined
) => {
  const user = mergeUserInfoAndUserLeadInfo(userInfo, userLeadInfo);
  if (user) {
    const natomasCustomer = natomasCustomerToCustomerInfo(user);
    // const uidParam = getParameter(USER_ID_URL_KEY);
    //
    // // Automatically select configuration from projects if only one
    // if (
    //   getStore().getState().configuration.currentConfigurationId == null &&
    //   natomasCustomer.project_ids.length >= 1
    // ) {
    //   if (uidParam == null || uidParam === natomasCustomer.user_id) {
    //     if (isEmployeeEmail(Customer.getContactEmail(natomasCustomer))) {
    //       console.log("avoiding admin project fetch");
    //     } else {
    //       if (
    //         natomasCustomer.default_project_id &&
    //         natomasCustomer.project_ids.includes(
    //           natomasCustomer.default_project_id
    //         )
    //       ) {
    //         getStore().dispatch(
    //           setConfigurationId(natomasCustomer.default_project_id)
    //         );
    //       } else {
    //         getStore().dispatch(
    //           setConfigurationId(natomasCustomer.project_ids[0])
    //         );
    //       }
    //     }
    //   }
    // }
    return storeUserInState(natomasCustomer);
  }
};

export const fetchAndStoreNatomasUser = async (uid: string) => {
  const initialUser = (await Utilities.getDocData(
    getUsersRef(uid)
  )) as V0UserInfo;

  let userLeadInfo: NatomasUserInfo;
  let userInfo: NatomasUserInfo;
  if (initialUser) {
    userLeadInfo = v0UserToNatomasCustomerInfo(initialUser, uid);
    mergeUserAndStore(undefined, userLeadInfo);
  }

  subscribeWithCallback(getNatomasUserLeadInfoRef(uid), (data) => {
    if (data) {
      userLeadInfo = data as NatomasUserInfo;
      mergeUserAndStore(userInfo, userLeadInfo);
    }
  });
  subscribeWithCallback(getNatomasUsersRef(uid), (data) => {
    userInfo = data as NatomasUserInfo;
    if (userInfo?.cart_library) {
      const cartLibrary = userInfo.cart_library;
      store.dispatch(updateCartItems({cartItems: cartLibrary}));
    }
    mergeUserAndStore(userInfo, userLeadInfo);
  });
};

export const getUserInfoFromCID = async (cid: string | null) => {
  if (isBlankString(cid)) {
    return null;
  }

  const configuration = await Configuration.Service.getConfiguration(cid);
  if (configuration != null && configuration.uid != null) {
    return fetchAndStoreNatomasUser(configuration.uid);
  }

  return null;
};

export const setNatomasUser = async (uid: string, natUser: NatomasUserInfo) => {
  return Utilities.updateOrCreateDocumentInDB(getNatomasUsersRef(uid), natUser);
};

export const updateNatomasUserEvents = (
  uid: string,
  customer: ICustomerInfo,
  callback: any
) => {
  return setNatomasUserEvents(uid, Customer.getEvents(customer), callback);
};

export const setNatomasUserEvents = (
  uid: string,
  events: IEventInfo[],
  callback: any
) => {
  // @ts-ignore
  const natomasUserDBObject: NatomasUserInfo = {
    events: events,
  };
  return Utilities.updateOrCreateDocumentInDB(
    getNatomasUsersRef(uid),
    natomasUserDBObject
  )?.then(callback);
};

export const updateNatomasUserContactInfo = (
  uid: string,
  customer: ICustomerInfo,
  callback: any
) => {
  // @ts-ignore
  const natomasUserDBObject: NatomasUserInfo = {
    first_name: Customer.getFirstName(customer),
    last_name: Customer.getLastName(customer),
    phone: Customer.getPrimaryContactPhoneNumber(customer),
    default_address: addressDetailsNatToFS(
      Customer.getContactAddress(customer)
    ),
  };

  return Utilities.updateOrCreateDocumentInDB(
    getNatomasUsersRef(uid),
    natomasUserDBObject
  )?.then(callback);
};

export const markUserAction = (uid: string, actionId: string, payload: any) => {
  return Utilities.updateOrCreateDocumentInDB(
    getUserActionCollectionRef(uid).doc(actionId),
    payload
  );
};

export const resetUserAction = (uid: string) => {
  return deleteCollection(getUserActionCollectionRef(uid));
};

export const fetchUserActions = (userId: string) => {
  return subscribeToCollectionWithCallback(
    getUserActionCollectionRef(userId),
    (actions) => {
      storeUserActionInfoInState(actions, userId);
    }
  );
};

export const fetchAllEmployees = () => {
  return subscribeToCollectionWithCallback(
    getPermissionsCollectionRef(),
    (employees) => {
      storeBulkPermissionsInState(employees);
      Object.keys(employees).forEach((eid: string) => {
        const userAuths = getStore().getState().user?.userAuthInfo;
        if (!!userAuths && userAuths[eid] == null) {
          fetchUserAuthInfo(eid).then(() =>
            console.log("Fetched user auth info: " + eid)
          );
        }
      });
    }
  );
};

export const fetchUserPermissions = (userId: string) => {
  return subscribeWithCallback(getUserPermissionsDocRef(userId), (data) => {
    storeUserPermissionsInState(data, userId);
  });
};

export const fetchUserAuthInfo = (userId: string, forceFetch?: boolean) => {
  if (!forceFetch) {
    const userAuths = getStore().getState().user?.userAuthInfo;

    const userAuth = userAuths && userAuths[userId];
    if (userAuth) {
      return Promise.resolve(userAuth);
    }
  }
  return getUserAuthInfo(userId).then((r) => {
    if (r == null) {
      return null;
    }

    return storeUserAuthInfoInState(r, userId);
  });
};

export const fetchUser = async (uid: string, forceFetch: boolean) => {
  if (uid != null) {
    const currentUser = store.getState().global.user;
    if (!allowFetchFromUser(currentUser?.uid, uid, forceFetch)) {
      return;
    }
    if (isAdmin(currentUser) && uid === currentUser?.uid) {
      return;
    }

    fetchAndStoreNatomasUser(uid);
    fetchUserActions(uid);
    if (isAdmin(currentUser)) {
      fetchUserPermissions(uid);
      fetchUserAuthInfo(uid);
    }
    // This is creating a project
    return syncUserInfoWithSalesForce(uid);
  }

  return getUserInfoFromCID(getParameter("cid"));
};

const storeUserInState = (data: ICustomerInfo) => {
  if (data != null) {
    store.dispatch(userLoaded(data));
  }
};

const storeUserActionInfoInState = (actions: any, userId: string) => {
  if (actions != null) {
    store.dispatch(storeUserActions({uid: userId, actions: actions}));
  }
};

const storeUserPermissionsInState = (permissions: any, userId: string) => {
  store.dispatch(storeUserPermissions({uid: userId, permissions: permissions}));
};

const storeBulkPermissionsInState = (permissions: any) => {
  store.dispatch(storeBulkUserPermissions(permissions));
};

const storeUserAuthInfoInState = (
  authInfo: firebase.UserInfo,
  userId: string
) => {
  store.dispatch(storeUserAuthInfo({uid: userId, authInfo: authInfo}));
};
