import React from "react";
import VCL from "@natomas-org/villa-component-library";
import {useHistory} from "react-router-dom";

const Header = (props: {title: string}) => {
  return (
    <div className={"vcl-container-row-spaced"}>
      <VCL.Components.Atoms.Header
        properties={{children: props?.title ?? "Unknown product"}}
      />
      <Back />
    </div>
  );
};

const Back = () => {
  const history = useHistory();

  return (
    <VCL.Components.Atoms.Button
      label={"Back to Products"}
      theme={"secondary"}
      size={"small"}
      onClick={() => {
        history.push("/admin/product");
      }}
    />
  );
};

export default Header;
