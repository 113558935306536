export const formatPhoneNumber = (preformatted: string): string => {
  // Sanitize the phone number first
  const cleanNumber = sanitizePhoneNumber(preformatted);
  // Get the first 10 digits of the phone number
  const phoneNumber = getFirstTenDigits(cleanNumber);
  // Apply the format based on the length of the cleaned phone number
  if (phoneNumber.length <= 10) {
    return phoneNumber.replace(
      /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
      (_, g1, g2, g3) => {
        let formattedNumber = "";
        if (g1) {
          formattedNumber += `(${g1}`;
        }
        if (g2) {
          formattedNumber += `) ${g2}`;
        }
        if (g3) {
          formattedNumber += `-${g3}`;
        }
        return formattedNumber;
      }
    );
  } else {
    // Format for numbers with more than 10 digits
    return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3");
  }
};

export const sanitizePhoneNumber = (preformatted: string): string => {
  return preformatted.replace(/\D/g, "");
};

export const getLastTenDigits = (phoneNumber: string): string => {
  return phoneNumber.substring(phoneNumber.length - 10);
};

const getFirstTenDigits = (phoneNumber: string): string => {
  return phoneNumber.substring(0, Math.min(10, phoneNumber.length));
};
