import styled from "styled-components";
import {NAT_POPUP_Z_INDEX} from "../../../../../styles";

export const PopupModalContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
`;
export const DEFAULT_DROPDOWN_STYLING: React.CSSProperties = {
  padding: "0 1rem",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  zIndex: NAT_POPUP_Z_INDEX,
};
