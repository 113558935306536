import styled from "styled-components";
import {GRAY} from "../../../../../../../_shared/colors";
import {NatomasButton} from "../../../../../../../_shared/generics/button/styles";
import {FONT_FAMILY_REGULAR} from "../../../../styles/globals";

export const HomeSelectionTitle = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 2rem;
  margin-top: 0.75rem;
`;

export const HomeSelectionTagline = styled.div`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const HomeSelectionDetails = styled.div`
  font-size: 1rem;
  color: ${GRAY};
  margin-bottom: 0.5rem;
`;

export const HomeSelectionPriceRange = styled.div`
  font-size: 1rem;
  color: ${GRAY};
  margin-bottom: 1rem;
`;
export const HomeSelectionButtons = styled.div`
  ${NatomasButton} {
    margin-bottom: 0.5rem;
  }
`;
