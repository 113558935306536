import React from "react";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";
import {SSCComponentText} from "../../_shared/styles";

interface SSCServiceProps {
  service: string;
}

const SSCService = (props: SSCServiceProps) => {
  const {service} = props;

  return (
    <SSCBlockContainer>
      <SSCComponentText>{service}</SSCComponentText>
    </SSCBlockContainer>
  );
};

export default SSCService;
