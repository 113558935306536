import React, {useCallback, useState} from "react";
import NatLabel, {
  NatLabelType,
} from "../../../../_shared/generics/label/NatLabel";
import {ModelHomeList} from "./components/ModelHomeList";
import {useCalendly} from "../../../../_shared/calendly/useCalendly";
import {IModelHomeInfo} from "./components/ModelHomeInfo";
import {EventScheduledEvent} from "react-calendly";
import {fetchAndSaveCalendlyEvent} from "../../../../../database/firebase/api/event";
import {
  Address,
  IEventId,
  IEventInfo,
  IProjectInfo,
  Person,
  Project,
  TeamMemberRole,
} from "@natomas-org/core";
import {ModelHomeTourSummary} from "./components/ModelHomeTourSummary";
import {NatLink} from "../../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../../_shared/generics/_shared";

interface IModelHomeTourProps {
  projectId: string;
  projectSummary: IProjectInfo;
  modelHomeTourEvent?: IEventInfo | null;
}
export const ModelHomeTour = (props: IModelHomeTourProps) => {
  const {projectSummary, modelHomeTourEvent, projectId} = props;
  const {setCalendlyEventInfo, calendlyWidget, setPrefill, customerInfo} =
    useCalendly();
  const [isRescheduling, setIsRescheduling] = useState(false);
  const selectModelHome = useCallback(
    (modelHomeInfo: IModelHomeInfo) => {
      setPrefill({
        customAnswers: {
          a1: customerInfo.phone,
          a2: Address.getFullAddress(Project.getAddress(projectSummary)),
        },
      });
      setCalendlyEventInfo({
        url: modelHomeInfo.schedule_link,
        eventScheduled: (
          e: EventScheduledEvent,
          eventURI: string,
          inviteeURI: string,
          originalCalendlyLink
        ) => {
          return fetchAndSaveCalendlyEvent(
            IEventId.MODEL_HOME_TOUR,
            eventURI,
            projectId,
            originalCalendlyLink
          ).then(() => {
            return setIsRescheduling(false);
          });
        },
      });
    },
    [projectId, customerInfo]
  );

  if (modelHomeTourEvent && projectSummary && !isRescheduling) {
    return (
      <ModelHomeTourSummary
        modelHomeTourEvent={modelHomeTourEvent}
        projectSummary={projectSummary}
        rescheduleAction={() => {
          setIsRescheduling(true);
        }}
      />
    );
  }

  const teamMember = Project.getTeamMemberForRole(
    projectSummary,
    TeamMemberRole.HC
  );

  return (
    <div style={{minHeight: "20rem"}}>
      <NatLabel
        label={
          "Come visit one of our model homes below to see the quality of construction, design flow, and features and finishes. One of our experts will be on hand to answer any questions."
        }
        type={NatLabelType.P1}
      />
      <div hidden={!isRescheduling} style={{margin: "1rem 0"}}>
        <NatLink
          size={NatSize.SMALL}
          style={{width: "fit-content", padding: 0}}
          option={StyleOption.PRIMARY_LINK_ALT}
          label={"Want to keep your original tour?"}
          clickEvent={() => {
            setIsRescheduling(false);
          }}
        />
      </div>
      <ModelHomeList selectModelHome={selectModelHome} />

      <div style={{marginTop: "1rem"}}>
        <NatLink
          size={NatSize.SMALL}
          option={StyleOption.PRIMARY_LINK_ALT}
          label={
            "Not in your area? Contact your Home Consultant for potential accommodations."
          }
          link={
            teamMember
              ? `mailto:${Person.getContactEmail(teamMember.personInfo)}`
              : ""
          }
        />
      </div>

      {calendlyWidget}
    </div>
  );
};
