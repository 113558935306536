import React, {useCallback, useEffect, useRef} from "react";
import {InputProps} from "../../interface";
import {handleAddressSearch, handleScriptLoad} from "./google-maps";
import {Container, Input as StyledInput, Label} from "../style";
import {Address as A, IAddressDetails} from "@natomas-org/core";
import {DefaultError} from "../../generic/DefaultError";

const Address = (props: InputProps) => {
  const {
    id,
    label,
    // use the propOnChange alias for the forceChange
    onChange,
    additionalContent,
    setError,
    error,
  } = props;
  const autoCompleteRef = useRef(null);

  // Write the value to the input field
  const forceChange = useCallback(
    (value: string) => {
      return onChange?.({
        target: {
          id,
          value: value ?? "",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    },
    [id, onChange]
  );

  // Validate the IAAddressDetails object
  const validateAddressObject = useCallback(
    (values: IAddressDetails | null) => {
      if (values && values?.latitude && values?.longitude) {
        const formatted = A.getFullAddress(values);
        setError?.(undefined);
        forceChange(formatted);
      } else {
        setError?.("Please enter a valid address.");
      }
    },
    [forceChange, setError]
  );

  // Search for the address, create the IAAddressDetails object, and validate it
  const validateAddress = useCallback(
    async (preformatted: string, skipEqualityCheck: boolean) => {
      await handleAddressSearch(
        preformatted,
        validateAddressObject,
        skipEqualityCheck
      );
    },
    [validateAddressObject]
  );

  // Handle the input change event, validate the address, and update the input field
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Prevent the default paste behavior if needed
      event.preventDefault();
      const {value} = event.target;
      // Assign typed values immediately
      forceChange(value);
      setError?.("Please enter a valid address.");
      // Validate and overwrite the value with the formatted address if needed
      return validateAddress(value, false);
    },
    [forceChange, setError, validateAddress]
  );

  // Handle the paste event, validate the address, and update the input field
  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      // Prevent the default paste behavior if needed
      event.preventDefault();
      // Extract the pasted text from the clipboard event
      const preformatted = event.clipboardData.getData("text");
      return validateAddress(preformatted, true);
    },
    [validateAddress]
  );

  // Load the Google Maps API script
  useEffect(() => {
    handleScriptLoad(autoCompleteRef, validateAddressObject);
  }, []);

  return (
    <Container key={`${id}-container`}>
      <Label htmlFor={id} id={`${id}-label`} hidden={!label}>
        {label}
      </Label>
      <StyledInput
        {...props}
        key={`${id}-input`}
        type={"search"}
        id={id}
        name={id}
        onPaste={handlePaste}
        onChange={handleChange}
        autoComplete={"shipping street-address"}
        ref={autoCompleteRef}
        data-private
      />
      {additionalContent}
      <DefaultError id={id} error={error} />
    </Container>
  );
};

export default Address;
