import {
  ICompletedProjectsDictionary,
  PROJECT_PUBLIC_COLLECTION_KEY,
} from "@natomas-org/core";
import {Utilities} from "../../../../database/firebase";

export const saveDataToCompletedProjects = async (
  dataToSave: ICompletedProjectsDictionary
) => {
  const entryArray = Object.values(dataToSave);
  const savingPromises: any[] = [];
  entryArray?.forEach((entry) => {
    console.log(entry);
    if (!entry?.id) {
      return;
    }
    if (
      entry?.title == null &&
      entry?.imageIds?.length === 0 &&
      entry?.published == null &&
      entry?.featured == null
    ) {
      // Currently, we're only saving title, published, featured, and/or imageIds.
      // If these changes are not present, don't do anything
      return;
    }
    const dataToSave: any = {};
    if (entry?.title != null) {
      dataToSave.title = entry.title;
    }
    if (entry?.published != null) {
      dataToSave.published = entry.published;
    }
    if (entry?.featured != null) {
      dataToSave.featured = entry?.featured;
    }
    if (entry?.imageIds) {
      dataToSave.imageIds = entry?.imageIds;
    }
    savingPromises.push(
      Utilities.setDocumentWithMerge(
        Utilities.collection(PROJECT_PUBLIC_COLLECTION_KEY),
        entry?.id?.toString(),
        dataToSave
      )
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        })
    );
  });
  return await Promise.all(savingPromises);
};
