import React, {useMemo, useCallback} from "react";
import {calculatePriceRange} from "../../../../../../../_shared/catalog/helpers";
import {useGlobalConfiguration} from "../../../../../../../_shared/hooks/useGlobalConfiguration";
import {CatalogBannerSublistCard} from "./SublistCard/CatalogBannerSublistCard";
import {
  getBedroomFilter,
  tryFilter,
} from "../../../../../../../_shared/catalog/filter/logic";
import {useDynamicValue} from "../../../../../../../_shared/hooks/useDynamicValue";
import {useNavigation} from "../../../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../../../_shared/hooks/useNavigation/paths";
import {useCatalogSession} from "../../../../../../../_shared/hooks/useProductCatalog/useCatalogSession/useCatalogSession";
import {
  useCatalogProducts
} from "../../../../../../../_shared/hooks/useProductCatalog/useProducts";

export interface ProductSublistCardProps {
  title: string;
  priceRange: string;
  clickEvent: () => void;
  image?: string | null;
}

export const CatalogBanner = () => {
  const {PRICING_MODE} = useGlobalConfiguration();
  const products = useCatalogProducts();
  const {filters} = useCatalogSession();
  const {to} = useNavigation();

  const cardWidth = useDynamicValue({
    forFour: 4,
    forEight: 8 / 3,
    forTwelve: 4,
    forSixteen: 16 / 3,
  });

  const getBedroomCardProps = useCallback(
    (bedroomCount: number): ProductSublistCardProps | undefined => {
      const bedrooms = products
        ? products.filter((product: any) => {
            return product.productDetails?.bedrooms === bedroomCount;
          })
        : [];
      if (bedrooms?.length === 0) return undefined;
      else {
        const priceRange = calculatePriceRange(bedrooms, PRICING_MODE);
        return {
          title: bedroomCount + " bedroom homes",
          priceRange: priceRange,
          image: bedrooms[0].imageId,
          clickEvent: () => {
            tryFilter(
              getBedroomFilter(bedroomCount, bedroomCount + " bedroom"),
              filters
            );
            to(NavigationPaths.CATALOG);
          },
        };
      }
    },
    [PRICING_MODE, filters, products, to]
  );

  const oneBedroomCard: ProductSublistCardProps | undefined = useMemo(() => {
    return getBedroomCardProps(1);
  }, [getBedroomCardProps]);

  const twoBedroomCard: ProductSublistCardProps | undefined = useMemo(() => {
    return getBedroomCardProps(2);
  }, [getBedroomCardProps]);

  const threeBedroomCard: ProductSublistCardProps | undefined = useMemo(() => {
    return getBedroomCardProps(3);
  }, [getBedroomCardProps]);

  return (
    <>
      {oneBedroomCard && (
        <CatalogBannerSublistCard size={cardWidth} card={oneBedroomCard} />
      )}
      {twoBedroomCard && (
        <CatalogBannerSublistCard size={cardWidth} card={twoBedroomCard} />
      )}
      {threeBedroomCard && (
        <CatalogBannerSublistCard size={cardWidth} card={threeBedroomCard} />
      )}
    </>
  );
};
