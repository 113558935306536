import styled from "styled-components";
import {HCP_PRESENTATION_FOOTER_HEIGHT} from "./DTC/components/constants";

export const Container = styled.div<{fadeIn: boolean}>`
  transition: opacity 1s ease-in-out, height 1s ease-in-out;
  opacity: ${(props) => (props.fadeIn ? 1 : 0)};
  display: block;
  page-break-after: avoid;
  break-after: avoid;
  page-break-before: avoid;
  break-before: avoid;
`;

export const PresentationContainer = styled.div<{fadeIn: boolean}>`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: opacity 1s ease-in-out, height 1s ease-in-out;
  height: ${(props) =>
    props.fadeIn
      ? `calc(100vh - ${HCP_PRESENTATION_FOOTER_HEIGHT} - 3rem)`
      : "100vh"};
`;

export const PresentationTitle = styled.div`
  //font-size: 3.5rem;
  margin-top: 1rem;
`;
export const AccountName = styled.div`
  //font-size: 3.5rem;
  margin-top: 1rem;
`;

export const PresentationSubtitle = styled.div`
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
