export const readDeepValue = (obj, deepPath) => {
  let s = deepPath.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (obj && k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }
  return obj;
};

export const setDeepValue = (obj, deepPath, value) => {
  var pList = deepPath.split(".");
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    var elem = pList[i];
    if (!obj[elem]) obj[elem] = {};
    obj = obj[elem];
  }

  obj[pList[len - 1]] = value;
};

// TODO Where should this go?
export const exportToJson = (objectData) => {
  let filename = "export.json";
  let contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    var blob = new Blob(
      [decodeURIComponent(encodeURI(JSON.stringify(objectData, null, 2)))],
      {type: contentType}
    );
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement("a");
    a.download = filename;
    a.href =
      "data:" +
      contentType +
      "," +
      encodeURIComponent(JSON.stringify(objectData, null, 2));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

/*

This function is used when you have a function that you don't know how many times it will be called
And you want to limit it to only trigger at most once given n milliseconds
This is very useful for events like onMouseOver or others that can trigger hundreds of times a second

How this works is you pass in a function and milliseconds timeout period,
and it returns a function that will only fire at most once if called many times during that timeout period.

On the first call, it will trigger the function immediately, mark the function as "waiting", and set a timeout to reset the function.
If the function is called while it is timed-out, it will remember the arguments of the call and trigger on last "wrap-up" call once the timeout is over.
This is to ensure that the last trigger of the function is honored even though it is throttled.

*/
export const throttleCallback = (callbackFn, waitInMilliseconds) => {
  let wait = false;
  let anotherCall = false;
  let waitingArgs = null;
  return (...args) => {
    if (!wait && callbackFn) {
      callbackFn(...args);
      wait = true;
      setTimeout(function () {
        if (waitingArgs || anotherCall) {
          callbackFn(...waitingArgs);
        }
        anotherCall = false;
        waitingArgs = null;
        wait = false;
      }, waitInMilliseconds);
    } else {
      anotherCall = true;
      waitingArgs = args;
    }
  };
};
