import {auth} from "../index";
import axios from "axios";
import {API_BASE_URL, isProd} from "../../../components/_shared/application";
import {randomId} from "@natomas-org/core";
import {store} from "../../../store";

export const executeRequest = async (
  endpoint: string,
  payload?: any,
  defaultHeaders?: any
) => {
  const headers: any = defaultHeaders ?? {};

  let authToken = "";
  if (auth.currentUser) {
    authToken = await auth.currentUser.getIdToken();
    headers["Authorization"] = "Bearer " + authToken;
  }
  if (store?.getState()?.global?.app_version?.current_version) {
    headers["app_version"] =
      store?.getState()?.global?.app_version?.current_version;
  }

  const uri = API_BASE_URL() + endpoint;
  const uuid = `(${randomId(5)}) ${endpoint}`;

  if (!isProd) {
    console.time(uuid);
  }

  if (payload) {
    const response = await axios
      .post(uri, payload, {
        headers,
      })
      .catch((error) => {
        return null;
      });
    const responseData = response?.data;
    if (responseData?.httpCode === 400) {
      if (!isProd) {
        console.timeEnd(uuid);
        console.log(responseData);
      }
      throw responseData;
    }

    if (!isProd) {
      console.timeEnd(uuid);
    }
    return responseData;
  }

  return axios
    .get(uri, {
      headers,
    })
    .then((response) => {
      if (!isProd) {
        console.timeEnd(uuid);
      }
      return response.data;
    })
    .catch((error) => {
      return null;
    });
};
