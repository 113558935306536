import React, {useCallback} from "react";
import {getSeriesSummaryColumnProductLabel} from "./helper";
import {
  SeriesSummaryProductLabel,
  SeriesSummaryProductsSection,
} from "./styles";
import {ISeriesSummaryColumnProduct} from "../../../interfaces";
import {useNavigation} from "../../../../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../../../../_shared/hooks/useNavigation/paths";
import SSCBlockContainer from "../../_shared/SSCBlockContainer";

export interface SSCProductsComponentProps {
  factoryId: string;
  products: ISeriesSummaryColumnProduct[];
}

const SSCProductsComponent = (props: SSCProductsComponentProps) => {
  const {factoryId, products} = props;
  const {to} = useNavigation();

  const navigateToProduct = useCallback(
    (productId: string) => {
      // TODO Open in new tab!
      to(NavigationPaths.UNIT, productId, factoryId);
    },
    [to, factoryId]
  );

  if (!factoryId || !products) {
    return null;
  }

  return (
    <SSCBlockContainer>
      <SeriesSummaryProductsSection>
        {products
          .sort((a, b) => a.squareFootage - b.squareFootage)
          .map((product) => {
            const label = getSeriesSummaryColumnProductLabel(product);
            return (
              <SeriesSummaryProductLabel
                key={product.id}
                onClick={() => navigateToProduct(product.id)}
              >
                {label}
              </SeriesSummaryProductLabel>
            );
          })}
      </SeriesSummaryProductsSection>
    </SSCBlockContainer>
  );
};

export default SSCProductsComponent;
