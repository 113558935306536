import {IHCPViewProps} from "../interfaces";
import React from "react";
import {PORTAL_LINKS_ID} from "../HCPTitleNav/navigation_ids";
import {PortalSection} from "../../../_shared/components/PortalSection";
import {HelpfulLinks} from "../../../_shared/components/HelpfulLinks";

export const HCPHelpfulLinks = (props: IHCPViewProps) => {
  const {width} = props;

  return (
    <PortalSection
      headerTitle={"Useful Links"}
      width={width}
      sectionId={PORTAL_LINKS_ID}
    >
      <HelpfulLinks />
    </PortalSection>
  );
};
