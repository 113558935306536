import React from "react";
import {StyledNatTextInput} from "./styles";
import {MAX_CHARACTER_LENGTH} from "../../FormConstants";
import {NatInputProps} from "../interfaces";

export type NatTextInputType = "text" | "tel" | "password" | "email";

export interface NatTextInputOptionsProps {
  autoComplete?: string;
  disabled?: boolean;
  hasError?: boolean;
}

interface NatTextInputProps extends NatInputProps {
  type: NatTextInputType;
  onBlur: any;
  options?: NatTextInputOptionsProps;
}

const NatTextInput = (props: NatTextInputProps) => {
  const {id, type, value, onChange, onBlur, placeholder, options} = props;

  return (
    <StyledNatTextInput
      id={id}
      name={id}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      maxLength={MAX_CHARACTER_LENGTH}
      value={value}
      disabled={options?.disabled ?? false}
      autoComplete={options?.autoComplete ?? "off"}
      hasError={options?.hasError ?? false}
    />
  );
};

export default NatTextInput;
