import {
  ISmartCatalogHeading,
  SmartCatalogHeading,
} from "../../../../map/_shared/components/ParcelInfo";
import {
  PageContainer,
  PageContainerStickyMode,
} from "../../../../_shared/generics/page/components/PageContainer";
import {HEADER_Z_INDEX} from "../../../../_shared/styles";
import {WHITE} from "../../../../_shared/colors";
import {Filters} from "./MapCatalogBar";
import React, {Fragment, useMemo} from "react";
import {useIsPublicApp} from "../../../../_shared/hooks/useIsPublicApp";
import {useAddress} from "../../../../_shared/hooks/useAddress";

export const MainCatalogBar = (props: {overrides?: ISmartCatalogHeading}) => {
  const {overrides} = props;
  const isPublicCatalog = useIsPublicApp();
  const {catalogAddress, projectAddress} = useAddress();

  const smartCatalogHeadingProps: ISmartCatalogHeading = useMemo(() => {
    let defaults: ISmartCatalogHeading = {
      address: catalogAddress ?? projectAddress ?? undefined,
      allowEditingAddress: isPublicCatalog,
      includeProductSelectionPrompt: false,
      includeFilterAndSortBanner: true,
      includeJurisdictionText: false,
      hideFilter: true,
      isSticky: true,
      verticalSpacingRem: 1.5,
    };
    return {...defaults, ...overrides};
  }, [overrides, catalogAddress, projectAddress, isPublicCatalog]);

  return (
    <Fragment>
      <SmartCatalogHeading {...smartCatalogHeadingProps} />
      <PageContainer
        FLEX
        stickyOptions={{
          mode: PageContainerStickyMode.MODAL,
          offset: "6rem",
          zIndex: HEADER_Z_INDEX - 100,
        }}
        backgroundColor={WHITE}
        border={true}
      >
        <Filters />
      </PageContainer>
    </Fragment>
  );
};
