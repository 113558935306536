export const OverrideModifierGroupStructure = {
  dependencies: {
    name: "Visibility Dependencies",
    type: "modifier-dependency",
    subType: "visibility",
    defaultValue: [],
    index: 11,
  },
  // requiredModifiers: {
  //   name: "Required Modifiers",
  //   type: "modifier-select",
  //   defaultValue: {},
  //   index: 8,
  // },
};

export const ProductModifierGroupStructure = {
  title: {
    name: "Title",
    defaultValue: "",
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    defaultValue: "",
    index: 0,
  },
  sectionId: {
    name: "Section ID",
    defaultValue: "",
    index: 7,
  },
  parentModifiers: {
    name: "Parent Modifiers",
    defaultValue: [],
    index: 8,
  },
};

export const BaseModifierGroupStructure = {
  title: {
    name: "Title",
    defaultValue: "",
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    defaultValue: "",
    index: 0,
  },
  properties: {
    name: "Properties",
    defaultValue: [],
    index: 5,
  },
  modifiers: {
    name: "Modifiers",
    defaultValue: [],
    index: 10,
  },
  prettyDescription: {
    name: "Description",
    defaultValue: "",
    index: 1,
  },
  modifierOverrides: {
    name: "Modifier Overrides",
    defaultValue: {},
    index: 11,
  },
};

export const EditModifierGroupStructure = {
  ...BaseModifierGroupStructure,
  ...ProductModifierGroupStructure,
};

export const AdminModifierGroupStructure = {
  title: {
    name: "Title",
    defaultValue: "",
    index: 0,
  },
  internal_title: {
    name: "Internal Title",
    defaultValue: "",
    index: 0,
  },
  prettyDescription: {
    name: "Description",
    defaultValue: "",
    type: "textarea",
    index: 1,
  },
  hideTitle: {
    name: "Hide Title in Studio?",
    defaultValue: "",
    type: "yes-no",
    index: 2,
  },
  properties: {
    name: "Properties",
    defaultValue: [],
    index: 5,
  },
  modifiers: {
    name: "Modifiers",
    defaultValue: [],
    index: 10,
  },
};
