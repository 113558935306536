import {
  ADU_PLACEMENT_FEASIBLE,
  NO_ADU_SELECTED_MESSAGE,
} from "./UserFeedback/constants";
import {DEFAULT_RESTRICTED_ZONE_COLOR} from "./index";
import {colors, MAP_STYLES} from "../../theme/constants";
import {
  getADUFromMapLayers,
  getADUImageFromMapLayers,
  getCurrentRestrictedZoneLayer,
  getNonAduLayers,
  getNonAduStructures,
  UNIT_LAYER_PREFIX,
} from "../../helpers/structures";
import {
  DEFAULT_JURISDICTION_DATA,
  ILeafletMapState,
  IProduct,
  IPropertyData,
  IPropertyGeometryData,
  UserFeedbackType,
} from "@natomas-org/core";
import {isMobile} from "../../../../_shared/navigation";
import {
  convertMultipolygonToPolygon,
  convertSearchPayloadFromDBFormat,
  getFeasibleAreaFallback,
  getSetbacks,
} from "@natomas-org/service";
import turfBooleanWithin from "@turf/boolean-within";
import turfBuffer from "@turf/buffer";
import turfBooleanOverlap from "@turf/boolean-overlap";
import L, {Polygon as LeafLetPolygon} from "leaflet";
import turfBbox from "@turf/bbox";
import turfBboxPolygon from "@turf/bbox-polygon";
import turfDifference from "@turf/difference";
import {GeoJsonObject} from "geojson";
import {
  setLeafletMapIsRotatingADU,
  setLeafletMapLastCoordinates,
  setLeafletMapPlacementFeedback,
} from "../../../../map/slices/mapSlice";
import React from "react";
import {store} from "../../../../../store";
import {collection} from "../../../../../database/firebase/utilities";
import {Configuration} from "../../../../../database/firebase";
import {VILLA_APPLE_GREEN} from "../../../../_shared/colors";

export const getSanitizedParcelGeometryData = (
  propertyGeometryData: IPropertyGeometryData | undefined | null
) => {
  if (!propertyGeometryData) {
    return null;
  }
  return convertSearchPayloadFromDBFormat(propertyGeometryData);
};
export const getBounds = (
  propertyGeometryData: IPropertyGeometryData | undefined | null
) => {
  if (!propertyGeometryData?.parcelGeometry) {
    return null;
  }
  const geoJson = L.geoJson(
    propertyGeometryData?.parcelGeometry as GeoJsonObject,
    {}
  );
  if (geoJson) {
    return geoJson.getBounds();
  }
  return null;
};
export const getViewPortCenter = (
  propertyData: IPropertyData | null | undefined
) => {
  if (!propertyData || !propertyData?.propertyGeometry) {
    return null;
  }
  const searchData = convertSearchPayloadFromDBFormat(
    propertyData?.propertyGeometry ?? undefined
  );
  if (searchData) {
    const {center} = searchData;
    return center;
  }
  return null;
};
export const getCornersFromLatLngs = (latLngs: any): any => {
  if (!latLngs || latLngs?.length < 1) {
    return;
  }
  if (latLngs.length === 1) {
    return getCornersFromLatLngs(latLngs[0]);
  }
  return [latLngs[0], latLngs[1], latLngs[3]];
};
export const resetADULayer = (
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData | undefined,
  map: any
) => {
  if (map && map.hasLayer(mapLayers.buildings)) {
    if (!leafletMapState.unitToPreview) {
      const currentRestrictedZoneLayer =
        getCurrentRestrictedZoneLayer(mapLayers);
      if (!currentRestrictedZoneLayer) {
        return;
      }
      currentRestrictedZoneLayer.setStyle({
        fillColor: DEFAULT_RESTRICTED_ZONE_COLOR,
      });
      store.dispatch(
        setLeafletMapPlacementFeedback({
          message: NO_ADU_SELECTED_MESSAGE,
          type: UserFeedbackType.NEUTRAL,
          icon: true,
        })
      );
    }
    mapLayers?.buildings
      ?.getLayers()
      .filter((i: any) => i.options.className.includes(UNIT_LAYER_PREFIX))
      .forEach((layer: any) => {
        if (layer) {
          if (layer.dragging) {
            layer.dragging.disable();
          }
          if (layer.transform) {
            layer.transform.disable({rotation: false, scaling: false});
          }
          if (mapLayers.buildings.hasLayer(layer)) {
            mapLayers.buildings.removeLayer(layer);
          }
          if (map.hasLayer(layer)) {
            map.removeLayer(layer);
          }
        }
      });
  }
};
export const setPopOverHandlers = (
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  map: any,
  layer: any
) => {
  if (!layer) {
    return;
  }
  layer.on("mousemove", (e: any) => {
    if (store.getState().map.leafletMapState.isRotatingADU) {
      map?.closePopup();
      return;
    }
    if (!map) {
      return;
    }
    let text = "";
    const className = e.propagatedFrom?.options?.className;
    if (className.includes(UNIT_LAYER_PREFIX) || !className) {
      map?.dragging.disable();
      map?.closePopup();
      return;
    }
    if (className === "non-adu") {
      text = "Residence on property";
      e.propagatedFrom.setStyle({fillColor: colors.primaryResidenceBlue});
    } else if (className === "feasibleZone") {
      text = "Buildable area";
      // e.propagatedFrom.setStyle({fillColor: colors.feasibleZoneBlue});
    } else if (className === "restrictedZone") {
      text = "Restricted setbacks";
      // e.propagatedFrom.setStyle({fillColor: colors.feasibleZoneBlue});
    } else if (className === "parcel") {
      text = "Your property line";
      e.propagatedFrom.setStyle({
        color: colors.propertyLineBlue,
      });
    }
    if (!e?.latlng) {
      return;
    }
    var popup = L.popup({
      className: "map-popup",
      keepInView: true,
      autoPan: false,
      closeButton: false,
    })
      .setLatLng(e?.latlng)
      .setContent(`<p>${text}</p>`)
      .openOn(map);
    layer.openPopup(popup);
  });
  layer.on("mouseout", (e: any) => {
    let color = "";
    const className = e.propagatedFrom.options.className;

    map?.closePopup();
    if (className.includes(UNIT_LAYER_PREFIX)) {
      map?.dragging.enable();
      return;
    }
    if (className === "restrictedZone") return;
    if (className === "non-adu") {
      color = colors.primaryResidenceGrey;
    } else if (className === "feasibleZone") {
      return;
    } else if (className === "parcel") {
      color = colors.propertyLineGrey;
      e.propagatedFrom.setStyle({color: color});
    }
    e.propagatedFrom.setStyle({fillColor: color});
  });
};
export const updateDimensions = (
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  layer: any
) => {
  if (!layer) {
    return;
  }
  if (!leafletMapState.isDimensionActive && layer.hideMeasurements) {
    layer.hideMeasurements();
  } else if (layer.showMeasurements) {
    layer.showMeasurements({
      formatArea: () => null,
      imperial: true,
    });
    layer.updateMeasurements();
  }
};
export const activateSatellite = (
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  map: any,
  dispatchLayer: React.Dispatch<any>
) => {
  if (!mapLayers.feasibleZone || !mapLayers.parcel) {
    return;
  }
  const stripesPattern = getStripesPattern();
  mapLayers.feasibleZone.setStyle({
    fillOpacity: 1,
    fillPattern: stripesPattern,
    fillColor: "transparent",
  });
  mapLayers.parcel.setStyle({color: "white"});

  const satelliteTile = L.tileLayer(MAP_STYLES.SATELLITE, {
    id: "satellite-map",
    tileSize: 512,
    detectRetina: true,
    zoomOffset: -1,
    maxZoom: 23,
    minZoom: 17,
    attribution:
      ' © <a target="_blank" rel="noreferrer" href="https://www.mapbox.com/">Mapbox</a>',
  });
  dispatchLayer({
    type: "ADD_OR_UPDATE_LAYER",
    payload: {satellite: satelliteTile},
  });
  map?.addLayer(satelliteTile);

  if (mapLayers.streets && map?.hasLayer(mapLayers.streets)) {
    map?.removeLayer(mapLayers.streets);
  }
};
export const matchImageToUnit = (
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  latLngs: any
) => {
  const aduImage = getADUImageFromMapLayers(mapLayers);
  const corners = getCornersFromLatLngs(latLngs);
  if (aduImage) {
    aduImage.reposition(corners[0], corners[1], corners[2]);
  }
};
export const verifyAduPlacement = (
  propertyData: IPropertyData,
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  product: IProduct | undefined,
  aduLayer?: any
) => {
  const currentRestrictedZoneLayer = getCurrentRestrictedZoneLayer(mapLayers);
  const feasibleZone = getUpdatedFeasibleZone(
    propertyData,
    mapLayers,
    leafletMapState,
    sanitizedPropertyGeometryData
  );

  if (
    !mapLayers.feasibleZone ||
    !feasibleZone ||
    !aduLayer ||
    leafletMapState.isStatic == null
  ) {
    return;
  }
  const primarySetback =
    propertyData?.jurisdictionData?.placementConstraints?.primarySetBacks ??
    DEFAULT_JURISDICTION_DATA.placementConstraints.primarySetBacks;
  const sideAndRear =
    propertyData?.jurisdictionData?.placementConstraints?.sideAndRearSetBacks ??
    DEFAULT_JURISDICTION_DATA.placementConstraints.sideAndRearSetBacks;
  const frontSetback =
    propertyData?.jurisdictionData?.placementConstraints?.frontSetback ??
    DEFAULT_JURISDICTION_DATA.placementConstraints.frontSetback;
  const latLngs = aduLayer.getLatLngs();
  if (latLngs?.length > 0) {
    const coordinates = latLngs[0].map((latLng: any) => {
      return {
        lat: latLng.lat,
        lng: latLng.lng,
      };
    });
    store.dispatch(setLeafletMapLastCoordinates(coordinates));
    matchImageToUnit(
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometryData,
      coordinates
    );
  }
  const poly = L.polygon(latLngs, {
    // @ts-ignore
    transform: !leafletMapState.isStatic && !isMobile(),
  });
  if (!currentRestrictedZoneLayer) {
    return;
  }
  const aduPolygon = convertMultipolygonToPolygon(poly.toGeoJSON(13));
  const isOverlappingSetback = !turfBooleanWithin(aduPolygon, feasibleZone);
  let isOverlappingPrimaryResidence = false;
  if (
    getNonAduStructures(mapLayers) &&
    getNonAduStructures(mapLayers).length > 0
  ) {
    const primaryRes = getNonAduStructures(mapLayers)[0];
    const bufferredPrimaryRes = turfBuffer(primaryRes, 4, {
      units: "feet",
    });
    isOverlappingPrimaryResidence = turfBooleanOverlap(
      aduPolygon,
      bufferredPrimaryRes
    );
  }
  const isOverlappingRestrictedZone = turfBooleanOverlap(
    aduPolygon,
    currentRestrictedZoneLayer.feature
  );
  if (!product) {
    store.dispatch(
      setLeafletMapPlacementFeedback({
        message: NO_ADU_SELECTED_MESSAGE,
        type: UserFeedbackType.NEUTRAL,
        icon: true,
      })
    );
  } else if (isOverlappingPrimaryResidence) {
    store.dispatch(
      setLeafletMapPlacementFeedback({
        message: "ADU must not touch other buildings.",
        type: UserFeedbackType.WARNING,
        icon: true,
      })
    );
  } else if (isOverlappingSetback) {
    const message = `ADU must be ≥ ${sideAndRear} ft from property lines, ≥ ${primarySetback} ft from primary residence, and ≥ ${frontSetback} ft from street.`;
    store.dispatch(
      setLeafletMapPlacementFeedback({
        message: message,
        type: UserFeedbackType.WARNING,
        icon: true,
      })
    );

    // showPopupOnFeasibleZone(feasibleZone);
  } else {
    store.dispatch(
      setLeafletMapPlacementFeedback({
        message: ADU_PLACEMENT_FEASIBLE,
        type: UserFeedbackType.SUCCESS,
        icon: true,
      })
    );
  }

  const restrictedZoneColor =
    isOverlappingRestrictedZone || isOverlappingSetback
      ? "#ffaf7b"
      : VILLA_APPLE_GREEN;

  currentRestrictedZoneLayer.setStyle({
    fillColor: restrictedZoneColor,
  });
};
export const getStripesPattern = () => {
  // @ts-ignore
  return "";
  // @ts-ignore
  // const stripePattern = new L.StripePattern({
  //   color: "#FFFFFF",
  //   spaceColor: "transparent",
  //   opacity: 0.55,
  //   spaceOpacity: 0.1,
  //   weight: 3,
  //   spaceWeight: 3,
  //   height: 6,
  //   angle: 135,
  // }).addTo(map);
  //
  // mapLayers.feasibleZone &&
  //   mapLayers.feasibleZone.setStyle({
  //     fillOpacity: 0.2,
  //     fillPattern: stripePattern,
  //     fillColor: "transparent",
  //   });
  // return stripePattern;
};
export const setRotateHandlers = (
  propertyData: IPropertyData,
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  product: IProduct | undefined,
  layer: any
) => {
  layer.on("rotate", (e: any) => {
    if (!store.getState().map.leafletMapState.isRotatingADU) {
      store.dispatch(setLeafletMapIsRotatingADU(true));
    }
  });
  layer.on("rotateend", (e: any) => {
    store.dispatch(setLeafletMapIsRotatingADU(false));
    verifyAduPlacement(
      propertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometryData,
      product,
      e.layer
    );
    updateDimensions(leafletMapState, sanitizedPropertyGeometryData, e.layer);
  });
};
export const setAduDragRotateHandlers = (
  propertyData: IPropertyData,
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  unitLayer: LeafLetPolygon<any>,
  product: IProduct | undefined
) => {
  if (unitLayer == null || isMobile()) {
    return;
  }
  setRotateHandlers(
    propertyData,
    mapLayers,
    leafletMapState,
    sanitizedPropertyGeometryData,
    product,
    unitLayer
  );

  // @ts-ignore
  if (unitLayer.transform) {
    // @ts-ignore
    unitLayer.transform.setOptions({
      rotation: !leafletMapState.isStatic,
      scaling: false,
      boundsOptions: {
        dashArray: "3, 3",
        color: colors.propertyLineBlue,
        weight: 1.5,
      },
      rotateHandleOptions: {
        color: colors.propertyLineBlue,
        fill: true,
        fillColor: colors.propertyLineBlue,
        weight: 1,
      },
    });
  }

  unitLayer.on("drag", function () {
    try {
      // @ts-ignore
      if (this.transform && this.transform.enabled()) {
        // This updates the rotation handler
        // @ts-ignore
        this.transform._updateHandlers();
        // @ts-ignore
        const bbox = turfBbox(L.polygon(this.getLatLngs()).toGeoJSON(13));
        const polygon = turfBboxPolygon(bbox);
        const geoJson = L.geoJson(polygon);
        const layers = geoJson.getLayers()[0];
        // @ts-ignore
        const latLngs = layers.getLatLngs();
        // @ts-ignore
        this.transform._rect.setLatLngs(latLngs);
      }
    } catch (e) {
      console.error("Error on drag: ", e);
    }
  });

  unitLayer.on("dragend", function () {
    try {
      // @ts-ignore
      if (this.transform) {
        // @ts-ignore
        this.transform.enable({
          rotation: !leafletMapState.isStatic,
          scaling: false,
        });
        // @ts-ignore
        updateDimensions(this);
        verifyAduPlacement(
          propertyData,
          mapLayers,
          leafletMapState,
          sanitizedPropertyGeometryData,
          product,
          unitLayer
        );
      }
    } catch (e) {
      console.error("Error on dragend: ", e);
    }
  });
};
export const setUnitTransformAndRotation = (
  propertyData: IPropertyData,
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  product: IProduct | undefined,
  unitLayer: any
) => {
  if (isMobile()) {
    return;
  }
  if (unitLayer && unitLayer.transform) {
    if (leafletMapState.isStatic) {
      unitLayer.dragging.disable();
      unitLayer.transform.disable({rotation: false, scaling: false});
    } else {
      unitLayer.dragging.enable();
      unitLayer.transform.enable({rotation: true, scaling: false});
      setAduDragRotateHandlers(
        propertyData,
        mapLayers,
        leafletMapState,
        sanitizedPropertyGeometryData,
        unitLayer,
        product
      );
    }
  }
};
export const deActivateSatellite = (
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  map: any
) => {
  if (!map || !mapLayers) {
    return;
  }
  if (mapLayers.feasibleZone) {
    mapLayers.feasibleZone.setStyle({
      fillOpacity: 1,
      fillColor: "#dfe1e3",
      fillPattern: null,
    });
  }
  if (mapLayers.streets) {
    map?.addLayer(mapLayers.streets);
  }
};
export const resetUnitPlacementToLastSave = (
  propertyData: IPropertyData,
  mapLayers: any,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  leafletMapState: ILeafletMapState
) => {
  if (!leafletMapState?.lastSavedCoordinates || !mapLayers) {
    return;
  }
  const latLngs: [number, number][] = [];
  leafletMapState.lastSavedCoordinates.forEach((coord: any) => {
    if (!!coord.lat && !!coord.lng) {
      latLngs.push([coord.lat, coord.lng]);
    }
  });
  if (latLngs?.length > 0) {
    setUnitLatLngs(
      propertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometryData,
      getADUFromMapLayers(mapLayers),
      latLngs
    );
  }
};
export const setUnitLatLngs = (
  propertyData: IPropertyData,
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  aduLayer: any,
  latLngs: any
) => {
  if (!mapLayers) {
    return;
  }
  const aduFromBuildingsLayer = getADUFromMapLayers(mapLayers);
  if (aduFromBuildingsLayer) {
    aduFromBuildingsLayer.setLatLngs(latLngs);
    matchImageToUnit(
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometryData,
      latLngs
    );
    verifyAduPlacement(
      propertyData,
      mapLayers,
      leafletMapState,
      sanitizedPropertyGeometryData,
      aduLayer
    );
  }
};
export const addBuildings = (
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData,
  map: any
) => {
  if (!map || !mapLayers || !sanitizedPropertyGeometryData?.buildingsGeometry) {
    return;
  }
  const nonAduLayers = getNonAduLayers(mapLayers);
  nonAduLayers.forEach((layer: any) => {
    if (map?.hasLayer(layer)) {
      map.removeLayer(layer);
    }
    mapLayers.buildings.removeLayer(layer);
  });

  sanitizedPropertyGeometryData?.buildingsGeometry?.forEach((building: any) => {
    const buildingLayer = L.polygon(
      // @ts-ignore
      L.geoJson(building).getLayers()[0].getLatLngs(),
      {
        // @ts-ignore
        transform: false,
        editing: false,
        draggable: false,
      }
    );
    buildingLayer.setStyle({
      color: colors.primaryResidenceGrey,
      weight: 0,
      opacity: 0,
      fillColor: colors.primaryResidenceGrey,
      fillOpacity: 1,
      className: "non-adu",
    });
    buildingLayer.addTo(mapLayers.buildings);
    updateDimensions(
      leafletMapState,
      sanitizedPropertyGeometryData,
      buildingLayer
    );
  });
};
export const getUpdatedFeasibleZone = (
  propertyData: IPropertyData,
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData
) => {
  if (!sanitizedPropertyGeometryData?.parcelGeometry) {
    return null;
  }
  let calculatedFeasibleZone = null;
  if (
    !!sanitizedPropertyGeometryData?.streetCoordinates &&
    !!sanitizedPropertyGeometryData?.streetCoordinates?.lat &&
    !!sanitizedPropertyGeometryData?.streetCoordinates?.lng
  ) {
    calculatedFeasibleZone = getSetbacks(
      sanitizedPropertyGeometryData?.streetCoordinates,
      getNonAduStructures(mapLayers),
      sanitizedPropertyGeometryData?.parcelGeometry,
      {
        front:
          propertyData?.jurisdictionData?.placementConstraints?.frontSetback ??
          DEFAULT_JURISDICTION_DATA.placementConstraints.frontSetback,
        sideAndRear:
          propertyData?.jurisdictionData?.placementConstraints
            ?.sideAndRearSetBacks ??
          DEFAULT_JURISDICTION_DATA.placementConstraints.sideAndRearSetBacks,
        primary:
          propertyData?.jurisdictionData?.placementConstraints
            ?.primarySetBacks ??
          DEFAULT_JURISDICTION_DATA.placementConstraints.primarySetBacks,
      }
    );
  } else if (sanitizedPropertyGeometryData?.parcelGeometry) {
    calculatedFeasibleZone = getFeasibleAreaFallback(
      sanitizedPropertyGeometryData?.parcelGeometry
    );
  }
  return calculatedFeasibleZone;
};
export const getUpdatedRestrictedZone = (
  propertyData: IPropertyData,
  mapLayers: any,
  leafletMapState: ILeafletMapState,
  sanitizedPropertyGeometryData: IPropertyGeometryData
) => {
  const feasibleZone = getUpdatedFeasibleZone(
    propertyData,
    mapLayers,
    leafletMapState,
    sanitizedPropertyGeometryData
  );
  let calculatedRestrictedZone = null;
  if (feasibleZone && sanitizedPropertyGeometryData?.parcelGeometry) {
    calculatedRestrictedZone = turfDifference(
      sanitizedPropertyGeometryData?.parcelGeometry as any,
      feasibleZone
    );
  }
  return calculatedRestrictedZone;
};

export const getConfigurationMapRef = (currentConfigurationId: string) => {
  return collection(Configuration.Constants.CONFIGURATION_MAPS_DB_KEY).doc(
    currentConfigurationId
  );
};

export const getConfigurationSiteRef = (currentConfigurationId: string) => {
  return collection(Configuration.Constants.CONFIGURATION_SITE_DB_KEY).doc(
    currentConfigurationId
  );
};
