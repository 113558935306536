import {PublicAppSearchBar} from "../../../../map/_shared/components/PublicAppSearchBar";
import {ADDRESS_BAR_STYLE} from "../../../../map/_shared/components/PublicAppSearchBar/constants";
import {PortalHeader} from "../../../../portal/_shared/components/PortalHeader";
import React from "react";
import {useCurrentUser} from "../../../../_shared/hooks/useCurrentUser";
import {usePage} from "../../../../_shared/hooks/usePage";

export const SplashScreenHeader = () => {
  const {loggedInUser} = useCurrentUser();
  const {isNatMobile} = usePage();
  let title;
  if (!loggedInUser) {
    title = (
      <PublicAppSearchBar addressBarStyle={ADDRESS_BAR_STYLE.PIN_HEADER} />
    );
  }
  return (
    <PortalHeader
      showAppSwitcher={false}
      title={!isNatMobile ? title : undefined}
    />
  );
};
