import {executeRequest} from "../../database/firebase/api";
import {SyncPublicProjectRequest} from "@natomas-org/core";

export const syncPublicProject = (request: SyncPublicProjectRequest) => {
  return executeRequest("/project/v1/syncPublicProject", request);
};

export function extractNumbers(inputString: string): string {
  let numbers: string = "";

  // Iterate through each character in the input string
  for (let char of inputString) {
    // Check if the character is a digit
    if (!isNaN(parseInt(char))) {
      // Append the digit to the numbers string
      numbers += char;
    }
  }

  // Return the concatenated string of numbers
  return numbers;
}
