import React, {useCallback, useEffect, useState} from "react";
import NumberInput from "./NumberInput";
import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../../portal/views/SSDDashboardView/styles/globals";

const LengthContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  padding: 4px;
`;

const LengthLabel = styled.div`
  width: 60px;
  max-width: 60px;
  text-align: right;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const DefaultDimension: IDimension = {feet: 0, inches: 0};

export interface IDimension {
  feet: number;
  inches: number;
}

interface DimensionInputProps {
  id: string;
  initialValue: IDimension;
  onChange: (value: IDimension) => void;
  label?: string;
}

const LengthInput = (props: DimensionInputProps) => {
  const {id, initialValue, label, onChange} = props;
  const [currentFeet, setCurrentFeet] = useState<number>(
    initialValue?.feet ?? 0
  );
  const [currentInches, setCurrentInches] = useState<number>(
    initialValue?.inches ?? 0
  );

  const [range] = useState<[number, number]>([0, 11]);

  useEffect(() => {
    setCurrentFeet(initialValue?.feet);
    setCurrentInches(initialValue?.inches);
  }, [initialValue?.feet, initialValue?.inches]);

  const updateLength = useCallback(
    (d: "feet" | "inches", value: number) => {
      let newDimension: IDimension = {
        feet: currentFeet,
        inches: currentInches,
      };
      if (d === "feet") {
        newDimension.feet = value;
      } else {
        newDimension.inches = value;
      }
      onChange(newDimension);
    },
    [currentFeet, currentInches, onChange]
  );

  return (
    <LengthContainer>
      <LengthLabel>{label}</LengthLabel>
      <NumberInput
        label={"Feet"}
        id={`${id}-feet-input`}
        key={`${id}-feet-input`}
        onChange={(v: number) => updateLength("feet", v)}
        initialValue={currentFeet}
        range={[0, 99]}
      />
      <NumberInput
        label={"Inches"}
        id={`${id}-inches-input`}
        key={`${id}-inches-input`}
        onChange={(v: number) => updateLength("inches", v)}
        initialValue={currentInches}
        range={range}
      />
    </LengthContainer>
  );
};

export default LengthInput;
