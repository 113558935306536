import React, {useMemo, useState} from "react";
import {IProjectNoteDetails, ProjectNoteType} from "@natomas-org/core";
import {ProjectNote} from "../../../admin/CustomerDatabase/CustomerManagerView/sections/CustomerProjectTabs/ProjectNotesTab/ProjectNote";
import {
  FPMeetingNotesContainer,
  FPMeetingNotesListContainer,
  PortalTabSectionButtonAndContentContainer,
} from "../FPDashboardLayout/FPMeetingNotes/styles";
import {ProjectNoteStepper} from "./ProjectNoteStepper";
import {useForm} from "react-hook-form";
import {NatInput} from "../../../_shared/generics/input/NatInput";
import {NatButton} from "../../../_shared/generics/button";
import {NatSize, StyleOption} from "../../../_shared/generics/_shared";
import {usePage} from "../../../_shared/hooks/usePage";
import NatLabel, {NatLabelType} from "../../../_shared/generics/label/NatLabel";
import {NatFlex} from "../../../_shared/generics/flex/NatFlex";

const TOTAL_NOTES_PER_PAGE = 4;

export const ProjectNotesList = (props: IProjectNotesListProps) => {
  const {projectNotes, onNoteClick, public_facing} = props;
  const [currentPage, setCurrentPage] = useState(0);
  const {watch, register, setValue} = useForm({
    defaultValues: {
      search: "",
      noteType: ProjectNoteType.NOTE,
    },
  });
  const search = watch("search");
  const noteType = watch("noteType");

  const filteredNotes = useMemo(() => {
    return projectNotes
      .sort(
        (a: IProjectNoteDetails, b: IProjectNoteDetails) =>
          b.timestamp - a.timestamp
      )
      .filter((note: IProjectNoteDetails) => {
        if (note.type !== noteType) {
          return false;
        }
        if (search) {
          return note.content.toLowerCase().includes(search.toLowerCase());
        }
        return true;
      });
  }, [search, noteType, projectNotes]);

  const totalPages = Math.ceil(filteredNotes.length / TOTAL_NOTES_PER_PAGE);
  const safeCurrentPage =
    currentPage < totalPages ? currentPage : totalPages - 1;

  const noteTypeText = useMemo(() => {
    return noteType === ProjectNoteType.NOTE ? "notes" : "project updates";
  }, [noteType]);
  const currentNotes = useMemo(() => {
    if (!filteredNotes || filteredNotes.length === 0) {
      return (
        <div style={{textAlign: "center"}}>
          <NatLabel label={`No ${noteTypeText}`} type={NatLabelType.P1} />
        </div>
      );
    }

    return filteredNotes
      .slice(
        safeCurrentPage * TOTAL_NOTES_PER_PAGE,
        (safeCurrentPage + 1) * TOTAL_NOTES_PER_PAGE
      )
      .map((note: IProjectNoteDetails, index: number) => (
        <ProjectNote
          public_facing={public_facing}
          key={"project-note-" + index}
          {...note}
          onEdit={onNoteClick ? () => onNoteClick(note) : null}
        />
      ));
  }, [
    filteredNotes,
    safeCurrentPage,
    noteTypeText,
    public_facing,
    onNoteClick,
  ]);

  const {isNatMobile} = usePage();

  return (
    <FPMeetingNotesContainer>
      <PortalTabSectionButtonAndContentContainer>
        <NatFlex>
          <NatButton
            label={"View client notes"}
            option={
              noteType === ProjectNoteType.NOTE
                ? StyleOption.SELECTED_TOGGLE_ON
                : StyleOption.SELECTED_TOGGLE_OFF
            }
            size={NatSize.SMALL}
            clickEvent={() => {
              setValue("noteType", ProjectNoteType.NOTE);
            }}
          />
          <NatButton
            label={"View project updates"}
            option={
              noteType === ProjectNoteType.UPDATE
                ? StyleOption.SELECTED_TOGGLE_ON
                : StyleOption.SELECTED_TOGGLE_OFF
            }
            size={NatSize.SMALL}
            clickEvent={() => {
              setValue("noteType", ProjectNoteType.UPDATE);
            }}
          />
        </NatFlex>
        <NatInput
          {...register("search")}
          placeholder={`Search ${noteTypeText}...`}
          containerStyle={{
            width: isNatMobile ? "100%" : "200px",
            maxWidth: "100%",
          }}
          showSearchIcon
          onFocusStyle={{
            width: isNatMobile ? "100%" : "200px",
            flexGrow: 1,
            maxWidth: "100%",
          }}
        />
      </PortalTabSectionButtonAndContentContainer>
      <FPMeetingNotesListContainer>
        {currentNotes}
        <ProjectNoteStepper
          onChange={(number) => {
            setCurrentPage(number);
          }}
          currentPage={safeCurrentPage}
          totalPages={totalPages}
        />
      </FPMeetingNotesListContainer>
    </FPMeetingNotesContainer>
  );
};

interface IProjectNotesListProps {
  projectNotes: IProjectNoteDetails[];
  public_facing: boolean;
  onNoteClick: (note: IProjectNoteDetails) => void;
}
