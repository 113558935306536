import {
  formatPhoneNumber,
  getLastTenDigits,
  sanitizePhoneNumber,
} from "./format";
import React, {useCallback} from "react";
import {InputProps} from "../../interface";
import {Container, Input as StyledInput, Label} from "../style";
import {DefaultError} from "../../generic/DefaultError";

const Phone = (props: InputProps) => {
  const {
    id,
    label,
    error,
    onPaste,
    onChange,
    additionalContent,
    setError,
    ...rest
  } = props;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // Prevent the default paste behavior if needed
      event.preventDefault();
      const {value} = event.target;
      const formatted = formatPhoneNumber(value);
      if (formatted.length !== 14) {
        setError?.("Please enter a valid phone number.");
      } else if (error) {
        setError?.(undefined);
      }
      onChange?.({
        target: {
          id,
          value: formatted,
        },
      } as React.ChangeEvent<HTMLInputElement>);
    },
    [onChange, id, setError]
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      // Prevent the default paste behavior if needed
      event.preventDefault();
      // Extract the pasted text from the clipboard event
      const preformatted = event.clipboardData.getData("text");
      // Sanitize the phone number first
      const cleanNumber = sanitizePhoneNumber(preformatted);
      // Get the first 10 digits of the phone number
      const phoneNumber = getLastTenDigits(cleanNumber);
      // Pass the pasted text to the onChange handler
      handleChange({
        target: {
          id,
          value: phoneNumber,
        },
      } as React.ChangeEvent<HTMLInputElement>);

      // Call the onPaste handler if provided
      onPaste?.(event);
    },
    [handleChange, id, onPaste]
  );

  return (
    <Container key={`${id}-container`}>
      <Label htmlFor={id} id={`${id}-label`} hidden={!label}>
        {label}
      </Label>
      <StyledInput
        {...rest}
        id={id}
        key={id}
        name={id}
        onChange={handleChange}
        onPaste={handlePaste}
        autoComplete={"tel"}
        data-private
      />
      {additionalContent}
      <DefaultError id={id} error={error} />
    </Container>
  );
};

export default Phone;
