import React, {useCallback} from "react";
import {ImageStatus, IProductImage} from "@natomas-org/core";
import CatalogImageInput from "./CatalogImageInput";
import styled from "styled-components";

const ImagesInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  min-width: 400px;
`;

interface ImagesInputProps {
  id: string;
  onChange: (value: any) => void;
  initialValue: IProductImage[];
}

const ImagesInput = (props: ImagesInputProps) => {
  const {id, onChange, initialValue} = props;

  const updateImages = useCallback(
    (index: number, value: IProductImage | null) => {
      let p = [...initialValue];
      if (!initialValue && value) {
        p = [value];
        onChange(p);
      } else if (!!value) {
        p[index] = value;
        onChange(p);
      } else {
        p.splice(index, 1);
        onChange(p);
      }
    },
    [initialValue, onChange]
  );
  return (
    <ImagesInputContainer>
      {initialValue?.map((image: IProductImage, index: number) => {
        return (
          <CatalogImageInput
            onChange={(value: IProductImage | null) =>
              updateImages(index, value)
            }
            id={`${id}-${index}`}
            initialValue={image}
          />
        );
      })}
      <CatalogImageInput
        onChange={(value: IProductImage | null) =>
          updateImages(initialValue?.length ?? 0, value)
        }
        id={`${id}-${initialValue?.length ?? 0}`}
        initialValue={{
          imageId: null,
          status: ImageStatus.UNLISTED,
          categories: undefined,
        }}
      />
    </ImagesInputContainer>
  );
};

export default ImagesInput;
