import React, {useState} from "react";
import {
  ModifierGroupContainer,
  ModifierGroupTitleWrapper,
  ModifierGroupWrapper,
} from "./styles";
import {StudioModifier} from "../StudioModifier";
import {TextInputModal} from "./TextInputModal";
import {
  getModifierGroupDescription,
  getSelectedModifiers,
  getSubtitleForSelectedModifier,
} from "./helper";
import {StudioModifierUnderline} from "../StudioModifierUnderline";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import {StudioState} from "../../../../_shared/slices/types/Store";

export const StudioModifierGroup = (props: any) => {
  const {
    hideUnderline,
    modifierGroup,
    formik,
    inSection,
    clickEvent,
    id,
    mode,
    isMobile,
    projectPriceMode,
  } = props;

  const {isEditing} = useStudioSettings();
  const [showNote, setShowNote] = useState(false);
  const modifierGroupTitle = modifierGroup.title;
  const hideGroupTitle = modifierGroup?.hideTitle;
  const selectedModifiers = getSelectedModifiers(modifierGroup);
  const modifiers =
    mode !== StudioState.DESIGNER && !isEditing
      ? selectedModifiers
      : modifierGroup?.fullModifiers;
  const modifierDivs =
    modifiers == null || modifiers?.length === 0
      ? null
      : modifiers.map((modifier: any, index: any) => {
          return (
            <StudioModifier
              isMobile={isMobile}
              clickEvent={clickEvent}
              modifier={modifier}
              modifierGroup={modifierGroup}
              key={modifierGroup.id + modifier.id + index}
              projectPriceMode={projectPriceMode}
            />
          );
        });

  const subtitleView = getSubtitleForSelectedModifier(
    selectedModifiers,
    modifierGroup
  );
  const descriptionView = getModifierGroupDescription(modifierGroup);

  // TODO: Removed note functionality for now NET-962
  // const doesNoteExist =
  //   formik != null &&
  //   formik.values != null &&
  //   isBlankString(formik.values["note-" + modifierGroup.id]);

  if (!modifierDivs) {
    return null;
  }

  return (
    <ModifierGroupWrapper id={id}>
      <ModifierGroupTitleWrapper inSection={inSection}>
        {/*<ReactTooltip id={"tooltip-" + modifierGroup.id} effect="solid">*/}
        {/*  Add Note*/}
        {/*</ReactTooltip>*/}
        {!hideGroupTitle && <div>{modifierGroupTitle}</div>}
        {/*<div*/}
        {/*  className=" clickable"*/}
        {/*  data-tip*/}
        {/*  data-for={"tooltip-" + modifierGroup.id}*/}
        {/*  onClick={() => {*/}
        {/*    setShowNote(true);*/}
        {/*  }}*/}
        {/*  style={{*/}
        {/*    opacity: doesNoteExist ? 0.3 : 1,*/}
        {/*    display:*/}
        {/*      !isMarketingPage() && (isEditing || !doesNoteExist)*/}
        {/*        ? "block"*/}
        {/*        : "none",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {!doesNoteExist ? (*/}
        {/*    <NatEditCommentIcon size={20} />*/}
        {/*  ) : (*/}
        {/*    <NatCommentIcon size={20} />*/}
        {/*  )}*/}
        {/*</div>*/}
      </ModifierGroupTitleWrapper>

      {descriptionView}
      {subtitleView}
      <ModifierGroupContainer>{modifierDivs}</ModifierGroupContainer>
      <TextInputModal
        modifierGroup={modifierGroup}
        show={showNote}
        setShow={setShowNote}
        formik={formik}
      />
      {hideUnderline ? null : <StudioModifierUnderline />}
    </ModifierGroupWrapper>
  );
};
