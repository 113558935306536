import React from "react";
import {Container, Input as StyledInput, Label} from "./style";
import {InputProps} from "../interface";
import {DefaultError} from "../generic/DefaultError";

const DefaultInput = (props: InputProps) => {
  const {id, label, error, additionalContent, onChange, ...input} = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (input.required && (!e.target.value || e.target.value === "")) {
      props.setError?.("Required");
    } else if (error) {
      props.setError?.(undefined);
    }
    onChange?.(e);
  };

  return (
    <Container key={`${id}-container`}>
      <Label htmlFor={id} id={`${id}-label`} hidden={!label}>
        {label}
      </Label>
      <StyledInput
        {...input}
        id={id}
        key={id}
        name={id}
        onChange={handleChange}
        data-private
      />
      {additionalContent}
      <DefaultError id={id} error={error} />
    </Container>
  );
};

export default DefaultInput;
