import {convertEditorToPreview, getAllModifiers} from "./preview";
import {getSummaryPricing} from "@natomas-org/service";
import {updateInventoryUnitUpgradeSelections} from "../../../../../../../../../../../database/firebase/api/catalog";
import {ProjectPriceMode} from "@natomas-org/core";

export const saveStudioEditorChanges = async (product: any, values: any) => {
  const payload = generatePayload(product, values);
  return await updateInventoryUnitUpgradeSelections(payload);
};

export const generatePayload = (
  product: any,
  values: any,
  pricingOverrides?: any
) => {
  const snapshot = convertStudioEditorChangesToConfiguration(
    values,
    pricingOverrides
  );
  const selectedModifiers = getAllModifiers(snapshot);
  // TODO: How to handle pricing mode for admin system
  const pricing = getSummaryPricing(
    product,
    selectedModifiers,
    0,
    ProjectPriceMode.DTC
  );

  const images = snapshot
    ?.map((category: any) => {
      return category?.activeImages ?? [];
    })
    .flat();
  return {
    configurationSnapshot: snapshot,
    images: images,
    product: product,
    selectedModifiers: getSelectionMap(selectedModifiers),
    totalUnitPrice: pricing?.totalUnitPrice,
    upgradePrice: pricing?.upgradePrice,
    upgradesInMicros: pricing?.upgradesInMicros,
    builderVersion: 2,
  };
};

const convertStudioEditorChangesToConfiguration = (
  values: any,
  pricingOverrides?: any
) => {
  return convertEditorToPreview(values, pricingOverrides);
};

const getSelectionMap = (selectedModifiers: any[]) => {
  let selectionMap: {[key: string]: boolean} = {};
  selectedModifiers?.forEach((modifier: any) => {
    if (modifier?.id) {
      selectionMap[modifier.id] = modifier;
    }
  });
  return selectionMap;
};
