import styled from "styled-components";
import {
  BUTTON_Z_INDEX,
  IMAGE_OVERLAY_Z_INDEX,
} from "../../../../../../../../../../../../_shared/styles";
import {
  ADMIN_SELECTED,
  GRAY,
  VILLA_BLUE,
  WHITE,
} from "../../../../../../../../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../../../../../portal/views/SSDDashboardView/styles/globals";

export const ImageEditorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  width: 100%;
`;

export const ImageContainer = styled.div`
  flex-grow: 1;
  border: 1px solid ${GRAY};
  border-radius: 16px;
  overflow: hidden;
  position: relative;
`;

export const ImageCountLabel = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 16px;
  font-size: 16px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${WHITE};
  z-index: ${IMAGE_OVERLAY_Z_INDEX};
  background-color: ${VILLA_BLUE};
  border: 2px solid ${WHITE};
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const ImagePreviewContainer = styled.div`
  width: 100%;
  height: 320px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${ADMIN_SELECTED};
  .carousel {
    height: inherit;
    width: inherit;

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      display: none;
    }
    .slide {
      height: 100%;
      .carousel-control-next,
      .carousel-control-prev {
        z-index: ${BUTTON_Z_INDEX};
      }
    }
    .carousel-inner {
      height: 100%;

      .carousel-item {
        height: 100%;
        .fullDiv {
          border-radius: 0;
        }
      }
    }
  }
`;
