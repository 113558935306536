export const ID_KEY = "id";
export const LABEL_KEY = "label";
export const INCLUDED_ORDER_FORM_KEY = "includedInOrderForm";
export const INTERNAL_CATEGORY_KEY = "internalCategory";
export const OPTIONS_KEY = "options";
export const MULTI_OPTION_KEY = "isMultiOption";
export const VALUE_KEY = "value";
export const DETAILS_KEY = "details";
export const VARIANTS_KEY = "variants";
export const CONDITIONS_KEY = "conditions";
export const OMIT_KEY = "omit";
export const SOURCE_KEY = "source";
export const ASSEMBLY_KEY = "assembly";
export const PRICING_PACKAGE_KEY = "pricingPackage";
export const ARCHIVED_KEY = "archived";
export const EXTERNAL_DESCRIPTION_KEY = "externalDescription";
export const EXTERNAL_CATEGORY_KEY = "externalCategory";
export const INTERNAL_NOTES_KEY = "internalNotes";
