import React, {useMemo} from "react";
import {AccountDetailsForm} from "./components/AccountDetailsForm/AccountDetailsForm";
import {ProjectDetailsForm} from "./components/ProjectDetailsForm/ProjectDetailsForm";
//Shared
//Styles
import {FormsContainer} from "./styles";
import {PageContainer} from "../../../_shared/generics/page/components/PageContainer";
import {useDynamicValue} from "../../../_shared/hooks/useDynamicValue";
import {useCurrentUser} from "../../../_shared/hooks/useCurrentUser";
import {useCurrentCustomer} from "../../../_shared/hooks/useCurrentCustomer";
import {AdminBlurb} from "./components/AdminBlurb/AdminBlurb";
import {useCustomerStatus} from "../../../_shared/hooks/useCustomerStatus";

export const AccountView = () => {
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {loggedInUser, isAdmin} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const flexDirection = useDynamicValue({
    forFour: "column",
    forEight: "row",
    forTwelve: "row",
    forSixteen: "row",
  });

  const content = useMemo(() => {
    if (isAdmin && !customer) {
      return <AdminBlurb email={loggedInUser?.email} />;
    } else {
      return (
        <>
          {!isCustomerPreDeposit && <ProjectDetailsForm />}
          <AccountDetailsForm />
        </>
      );
    }
  }, [customer, isAdmin, loggedInUser?.email]);

  return (
    <PageContainer>
      <FormsContainer direction={flexDirection}>{content}</FormsContainer>
    </PageContainer>
  );
};
