import React from "react";
import styled from "styled-components";
import {DIVIDER_COLOR} from "../../../../../../../../../../_shared/colors";
import {NatLockedIcon} from "../../../../../../../../../../_shared/icon/icons";

const PriceContainerSticky = styled.div`
  position: sticky;
  top: 8rem;
  width: 320px;
  z-index: 100;
  height: 400px;
`;

const DummyStickyCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  align-items: center;
  border-radius: 1rem;
  background-color: ${DIVIDER_COLOR};
  padding: 1.5rem;
  margin-bottom: 1rem;
  height: 100%;
`;

const PriceCardPreview = () => {
  return (
    <PriceContainerSticky>
      <DummyStickyCard>
        Pricing Card
        <NatLockedIcon />
      </DummyStickyCard>
    </PriceContainerSticky>
  );
};

export default PriceCardPreview;
