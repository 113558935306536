import VCL from "@natomas-org/villa-component-library";
import {UpdateLeadQuestionsSchema} from "@natomas-org/villa-nexus-client";
import React, {useState} from "react";
import {
  BoldSubtitle,
  InstantQuoteFormGroup,
  InstantQuoteFormInputDiv,
  InstantQuoteFormLabel,
  InstantQuoteFormTextArea,
  InstantQuoteFormWrapper,
  InstantQuoteQuestion,
} from "./styles";
import {ThemeProvider} from "styled-components";
import {getLeadService} from "src/api/Lead";
import {IInstantQuoteCustomerData} from "./interface";
import {BLACK, VILLA_APPLE_GREEN} from "../../../../../_shared/colors";
import check from "./check.svg";

export interface IInstantQuoteQuestionareQuestion {
  key: string;
  input_type: string;
  updateStateFunction: Function;
  state: string;
  question: string;
  selections: string[];
}

const submitQuestionnaireForm = async (schema: UpdateLeadQuestionsSchema) => {
  const leadService = await getLeadService();
  return leadService.update(schema);
};

const QuestionnaireForm = (props: {
  customerData: IInstantQuoteCustomerData;
  onSubmit?: () => void;
}) => {
  let [timeline, setTimeline] = useState("");
  let [budget, setBudget] = useState("");
  let [otherInfo, setOtherInfo] = useState("");

  let timelineQuestion: IInstantQuoteQuestionareQuestion = {
    key: "timeline",
    input_type: "button",
    question: "How soon do you want to start?",
    updateStateFunction: setTimeline,
    state: timeline,
    selections: ["ASAP", "1-2 months", "6 months", "1+ year(s)"],
  };
  let budgetQuestion: IInstantQuoteQuestionareQuestion = {
    key: "budget",
    input_type: "button",
    question: "What is your overall project budget?",
    updateStateFunction: setBudget,
    state: budget,
    selections: [
      "$250,000 or less",
      "$250,000 - $350,000",
      "$350,000 - $450,000",
      "$450,000+",
    ],
  };

  const renderQuestions = (questions: IInstantQuoteQuestionareQuestion[]) => {
    return (
      <>
        {questions.map((question, _) => {
          return (
            <InstantQuoteQuestion>
              <InstantQuoteFormLabel htmlFor={question.key}>
                {question.question}
              </InstantQuoteFormLabel>
              {question.selections?.map((selection, index) => {
                const inputKey = `${question.key}-${index}`;
                const selected = !!selection && question.state === selection;
                return (
                  <ThemeProvider
                    theme={{
                      selected,
                    }}
                  >
                    <InstantQuoteFormInputDiv
                      key={inputKey}
                      id={inputKey}
                      onClick={() => {
                        question.updateStateFunction(selection);
                      }}
                    >
                      {selection}
                      <img
                        style={{
                          height: "20px",
                          width: "20px",
                          padding: "3px",
                          backgroundColor: VILLA_APPLE_GREEN,
                          borderRadius: "10px",
                        }}
                        src={check}
                        alt={"Selected"}
                      />
                    </InstantQuoteFormInputDiv>
                  </ThemeProvider>
                );
              })}
            </InstantQuoteQuestion>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <BoldSubtitle>Optional</BoldSubtitle>
        <InstantQuoteFormWrapper>
          <InstantQuoteFormGroup>
            {renderQuestions([timelineQuestion, budgetQuestion])}
            <InstantQuoteQuestion>
              <InstantQuoteFormLabel htmlFor="additionalInformation">
                Anything else you want to tell us about your project?
              </InstantQuoteFormLabel>
              <InstantQuoteFormTextArea
                placeholder="You can tell us how you’re planning to use your ADU, what’s driving your timeline, specifics about your property or anything else you want us to know!"
                key="additionalInformation"
                onChange={(e) => {
                  setOtherInfo(e.target.value);
                }}
              />
            </InstantQuoteQuestion>
          </InstantQuoteFormGroup>
        </InstantQuoteFormWrapper>
      </div>
      <VCL.Components.Atoms.Button
        theme={"primary"}
        label={"Complete"}
        style={{
          maxWidth: "350px",
          minWidth: "250px",
          margin: "0 auto",
          fontFamily: "Inter-Bold,serif",
          // TODO Change this to be a part of the VCL
          color: BLACK,
        }}
        disabled={!props.customerData}
        onClick={() => {
          let updatedSchema: UpdateLeadQuestionsSchema = {
            lead_first_name: props.customerData["first-name"],
            lead_last_name: props.customerData["last-name"],
            lead_email: props.customerData["email"],
            timeline_answer: timeline,
            budget_answer: budget,
            additional_information_answer: otherInfo,
          };
          submitQuestionnaireForm(updatedSchema)
            .then(() => {
              // Optionally onSubmit process
              props?.onSubmit?.();
            })
            .catch(() => {
              window.alert(
                "There was an issue submitting your answers. Try again."
              );
              // TODO remove dev only
              props?.onSubmit?.();
            });
        }}
      />
    </>
  );
};

export default QuestionnaireForm;
