import React, {Fragment, useEffect, useState} from "react";
import {Address, IAddressDetails} from "@natomas-org/core";
import {AdminInput} from "../../../../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {FactoryLineGlobalValuesStructure} from "./interfaces";
import {
  InputTitle,
  NatStyledForm,
} from "../../../../../../_shared/styles/AdminStyles";
import {
  ActionContainer,
  ProductLineInfoBlockInfo,
  ProductLineInfoBlockInfoRow,
  ProductLineInfoBlockTitle,
} from "../styles";
import {getInitialValues} from "./helper";
import {useFormik} from "formik";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../../../../_shared/application";
import {NatButton} from "../../../../../../../_shared/generics/button";
import {
  NatCancelIcon,
  NatEditIcon,
  NatSaveIcon,
} from "../../../../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../_shared/generics/_shared";
import {AddressInput} from "../../../../../../../_shared/generics/address-input/AddressInput";
import {updateFactoryLineGlobalValues} from "../../../../../../../../database/firebase/factoryLine/saving";

export const FactoryLineGlobalValues = (props: {info: any}) => {
  const {info} = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const structure = getFormStructure(FactoryLineGlobalValuesStructure);

  const [initialValues, setInitialValues] = useState(
    getInitialValues(
      info,
      getFormInitialValues(FactoryLineGlobalValuesStructure)
    )
  );

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {
      updateFactoryLineGlobalValues(info?.id, values);
      setIsEditing(false);
    },
  });

  useEffect(() => {
    const newInitialValues = getInitialValues(
      info,
      getFormInitialValues(FactoryLineGlobalValuesStructure)
    );
    setInitialValues(newInitialValues);
    formik.setValues(newInitialValues);
  }, [info]);

  return (
    <Fragment>
      <ProductLineInfoBlockTitle>
        Global Factory Info
        <ActionContainer>
          <NatButton
            label={"Save"}
            hidden={!isEditing}
            icon={{
              icon: <NatSaveIcon />,
              iconPosition: IconPosition.ONLY,
            }}
            size={NatSize.SMALL}
            option={StyleOption.PRIMARY_BLACK}
            clickEvent={formik.handleSubmit}
          />
          <NatButton
            label={"Edit"}
            icon={{
              icon: isEditing ? <NatCancelIcon /> : <NatEditIcon />,
              iconPosition: IconPosition.ONLY,
            }}
            size={NatSize.SMALL}
            option={StyleOption.PRIMARY_BLACK}
            clickEvent={() => setIsEditing((current) => !current)}
          />
        </ActionContainer>
      </ProductLineInfoBlockTitle>
      {isEditing ? (
        <NatStyledForm>
          {structure.map((keyInfo) => {
            const {key} = keyInfo;
            // @ts-ignore
            let value = formik.values[key];
            // @ts-ignore
            let initial = initialValues[key];
            if (key === "address") {
              return (
                <>
                  <InputTitle
                    bold={true}
                    enabled={true}
                    title={"Factory Address"}
                  />
                  <AddressInput
                    placeholder={Address.getFullAddress(
                      info?.address ?? undefined
                    )}
                    setAddress={(address: string) => {}}
                    setAddressObject={(e: IAddressDetails | null) =>
                      formik.setFieldValue(key, e)
                    }
                    value={formik.values.address}
                  />
                </>
              );
            }
            return (
              <AdminInput
                key={key}
                value={value}
                fieldInfo={keyInfo}
                initialValue={initial}
                onChange={(value: any) => {
                  formik.setFieldValue(key, value);
                }}
              />
            );
          })}
        </NatStyledForm>
      ) : (
        <ProductLineInfoBlockInfo>
          <ProductLineInfoBlockInfoRow>
            <div>Global Title</div>
            {info?.globalTitle ?? "None"}
          </ProductLineInfoBlockInfoRow>
          <ProductLineInfoBlockInfoRow>
            <div>Factory Name</div>
            {info?.name ?? "None"}
          </ProductLineInfoBlockInfoRow>
          <ProductLineInfoBlockInfoRow>
            <div>Factory Location</div>
            {Address.getFullAddress(info?.address ?? undefined)}
          </ProductLineInfoBlockInfoRow>
        </ProductLineInfoBlockInfo>
      )}
    </Fragment>
  );
};
