import {OpenFactoryInfoSetButton} from "../info-set/OpenFactoryInfoSetButton";
import React from "react";
import {AdminControllerLabel, AdminControlsContainer} from "./styles";
import {useAdminProductCatalog} from "../../hooks/admin-only-hooks/useAdminProductCatalog";
import {VersionSelectionDropdown} from "./VersionSelectionDropdown";
import {useAdminSettings} from "../../hooks/admin-only-hooks/useAdminSettings";

export const AdminActionController = (props: {hideLabels?: boolean}) => {
  const {hideLabels} = props;
  const {canDemo, isAdmin} = useAdminSettings();
  const {factoryLine} = useAdminProductCatalog();
  const {info} = factoryLine ?? {};

  if (!isAdmin) {
    return null;
  }
  return (
    <AdminControlsContainer>
      <AdminControllerLabel hidden={hideLabels ?? false}>
        {info?.name}
      </AdminControllerLabel>
      <AdminControllerLabel hidden={hideLabels ?? false}>
        {info?.globalTitle}
      </AdminControllerLabel>
      {info?.id && <OpenFactoryInfoSetButton factoryId={info.id} />}
      {canDemo && <VersionSelectionDropdown factory={factoryLine} />}
    </AdminControlsContainer>
  );
};
