import styled from "styled-components";
import {MODAL_Z_INDEX} from "../../styles";

export const PresentationViewContainer = styled.div`
  position: fixed;
  z-index: ${MODAL_Z_INDEX + 200};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const PresentationViewBackdrop = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
`;

export const PhotoViewDismiss = styled.div`
  position: fixed;
  z-index: 2;
  width: 50px;
  height: 50px;
  color: white;
  font-size: 32px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  cursor: pointer;
`;

export const PresentationTopRight = styled.div`
  position: fixed;
  z-index: 2;
  top: 0.5rem;
  right: 1rem;
  color: white;
  font-size: 32px;
  text-align: center;
  cursor: pointer;
`;
