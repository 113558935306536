import {
  subscribeToCollectionWithCallback,
  subscribeWithCallback,
} from "../../database/firebase/utilities";
import {Utilities} from "../../database/firebase";
import {
  ICartItem,
  PROJECT_COLLECTION_KEY,
  PROJECT_DOCUMENTS_COLLECTION,
  PROJECT_SMARTSHEET_COLLECTION_KEY,
} from "@natomas-org/core";
import {store} from "../../store";
import {
  ProjectInfoSource,
  updateProjectCartItemIds,
  updateProjectDocuments,
  updateProjectInfo,
  updateProjectNotes,
  updateProjectSite,
} from "../../components/_shared/slices/ProjectSlice/ProjectSlice";
import {
  PROJECT_NOTES_COLLECTION_KEY,
  PROJECT_OPPORTUNITY_COLLECTION_KEY,
} from "../../database/firebase/project/constants";
import {
  hasActiveSubscription,
  processFetchingReturn,
  setSubscribingAttempt,
} from "../subscriptions";
import {CONFIGURATION_SITE_DB_KEY} from "../../database/firebase/configuration/configuration";
import {subscribeToDesign, subscribeToDesignSnapshotDoc} from "./configuration";
import {updateCartItem} from "../../components/_shared/slices/CartSlice/CartSlice";
import {enhanceNatomasProjectDoc} from "./migration";

export enum ProjectIdSource {
  ADMIN = "admin",
  CUSTOMER = "customer",
}

export interface ProjectSubscriptionMetadata {
  projectId: string;
  projectIdSource?: ProjectIdSource;
  projectIdSourceId?: string;
}

export const subscribeToProject = async (
  metadata: ProjectSubscriptionMetadata
) => {
  return Promise.all([
    subscribeToNatomasProjectDoc(metadata),
    subscribeToSalesforceProjectDoc(metadata),
    subscribeToSmartsheetProjectDoc(metadata),
    subscribeToProjectNotes(metadata),
    subscribeToProjectDocuments(metadata),
    subscribeToProjectSiteDoc(metadata),
  ]);
};

export const subscribeToNatomasProjectDoc = async (
  metadata: ProjectSubscriptionMetadata
) => {
  const {
    projectId: docId,
    projectIdSourceId: sourceId,
    projectIdSource: source,
  } = metadata;
  const ref = Utilities.collection(PROJECT_COLLECTION_KEY).doc(docId);
  const subscriptionId = `project-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);
  if (source === ProjectIdSource.CUSTOMER && ref && sourceId) {
    await enhanceNatomasProjectDoc(ref, {ownerId: sourceId, projectId: docId});
  }
  if (ref && !isSubscribed) {
    setSubscribingAttempt(subscriptionId, true);
    const unsubscribe = await subscribeWithCallback(ref, (data: any) => {
      store.dispatch(
        updateProjectInfo({
          id: docId,
          source: ProjectInfoSource.NATOMAS,
          payload: data ?? null,
        })
      );
      if (data) {
        const cartItems = data?.cart;
        if (!cartItems) {
          // Legacy project does not have cart
          subscribeToDesign(docId, {isLegacy: true});
          subscribeToDesignSnapshotDoc(docId);
        } else {
          let ids: string[] = [];
          cartItems?.forEach((item: ICartItem) => {
            const id = item?.id;
            if (id) {
              ids.push(id);
              store.dispatch(updateCartItem({id, cartItem: item}));
            }
          });
          store.dispatch(
            updateProjectCartItemIds({
              id: docId,
              cartItemIds: ids,
            })
          );
        }
      }
    }).catch((e) => {
      setSubscribingAttempt(subscriptionId, false);
    });
    if (unsubscribe) {
      processFetchingReturn(subscriptionId, {unsubscribe});
    }
  }
};

export const subscribeToSalesforceProjectDoc = async (
  metadata: ProjectSubscriptionMetadata
) => {
  const {projectId: docId} = metadata;
  const ref = Utilities.collection(PROJECT_OPPORTUNITY_COLLECTION_KEY).doc(
    docId
  );
  const subscriptionId = `project-salesforce-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    setSubscribingAttempt(subscriptionId, true);
    const unsubscribe = await subscribeWithCallback(ref, (data: any) => {
      store.dispatch(
        updateProjectInfo({
          id: docId,
          source: ProjectInfoSource.SALESFORCE,
          payload: data ?? null,
        })
      );
    }).catch((e) => {
      setSubscribingAttempt(subscriptionId, false);
    });
    if (unsubscribe) {
      processFetchingReturn(subscriptionId, {unsubscribe});
    }
  }
};

export const subscribeToSmartsheetProjectDoc = async (
  metadata: ProjectSubscriptionMetadata
) => {
  const {projectId: docId} = metadata;
  const ref = Utilities.collection(PROJECT_SMARTSHEET_COLLECTION_KEY).doc(
    docId
  );
  const subscriptionId = `project-smartsheet-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    setSubscribingAttempt(subscriptionId, true);
    const unsubscribe = await subscribeWithCallback(ref, (data: any) => {
      store.dispatch(
        updateProjectInfo({
          id: docId,
          source: ProjectInfoSource.SMARTSHEET,
          payload: data ?? null,
        })
      );
    }).catch((e) => {
      setSubscribingAttempt(subscriptionId, false);
    });
    if (unsubscribe) {
      processFetchingReturn(subscriptionId, {unsubscribe});
    }
  }
};

export const subscribeToProjectNotes = async (
  metadata: ProjectSubscriptionMetadata
) => {
  const {projectId: docId} = metadata;
  const ref = Utilities.collection(PROJECT_COLLECTION_KEY)
    .doc(docId)
    .collection(PROJECT_NOTES_COLLECTION_KEY);

  const subscriptionId = `project-notes-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    setSubscribingAttempt(subscriptionId, true);
    const unsubscribe = await subscribeToCollectionWithCallback(
      ref,
      (data: any) => {
        if (data) {
          store.dispatch(
            updateProjectNotes({
              id: docId,
              notes: data,
            })
          );
        }
      }
    ).catch((e) => {
      setSubscribingAttempt(subscriptionId, false);
    });
    if (unsubscribe) {
      processFetchingReturn(subscriptionId, {unsubscribe});
    }
  }
};

export const subscribeToProjectDocuments = async (
  metadata: ProjectSubscriptionMetadata
) => {
  const {projectId: docId} = metadata;
  const ref = Utilities.collection(PROJECT_COLLECTION_KEY)
    .doc(docId)
    .collection(PROJECT_DOCUMENTS_COLLECTION);

  const subscriptionId = `project-documents-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    setSubscribingAttempt(subscriptionId, true);
    const unsubscribe = await subscribeToCollectionWithCallback(
      ref,
      (data: any) => {
        if (data) {
          store.dispatch(
            updateProjectDocuments({
              id: docId,
              docs: data,
            })
          );
        }
      }
    ).catch((e) => {
      setSubscribingAttempt(subscriptionId, false);
    });
    if (unsubscribe) {
      processFetchingReturn(subscriptionId, {unsubscribe});
    }
  }
};

export const subscribeToProjectSiteDoc = async (
  metadata: ProjectSubscriptionMetadata
) => {
  const {projectId: docId} = metadata;
  const ref = Utilities.collection(CONFIGURATION_SITE_DB_KEY).doc(docId);
  const subscriptionId = `project-site-${docId}`;
  const isSubscribed = hasActiveSubscription(subscriptionId);

  if (ref && !isSubscribed) {
    setSubscribingAttempt(subscriptionId, true);
    const unsubscribe = await subscribeWithCallback(ref, (data: any) => {
      if (data) {
        store.dispatch(
          updateProjectSite({
            id: docId,
            site: data,
          })
        );
      }
    }).catch((e) => {
      setSubscribingAttempt(subscriptionId, false);
    });
    if (unsubscribe) {
      processFetchingReturn(subscriptionId, {unsubscribe});
    }
  }
};
