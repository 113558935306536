import React from "react";
import {isBlankString} from "@natomas-org/core";
import {DesignButton, ImageContainer, TextContainer} from "./styles";
import styled from "styled-components";

export const FullColorTextButton = (props) => {
  return (
    <ColorTextButtonWrapper
      id={"modifier-" + props.buttonId}
      onClick={props.onClick}
      active={props.isSelected}
      disabled={props.isDisabled}
    >
      <ImageContainer
        width={"50px"}
        style={{
          background: isBlankString(props.secondColor)
            ? props.firstColor
            : `linear-gradient(135deg, ${props.firstColor} 50%, ${props.secondColor} calc(50% + 1px))`,
          minHeight: "50px",
        }}
      />
      <TextContainer>
        <p>{props.buttonText}</p>
        <p>{props.price}</p>
      </TextContainer>
    </ColorTextButtonWrapper>
  );
};

const ColorTextButtonWrapper = styled(DesignButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 18px;
`;
