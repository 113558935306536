import {
  AdminFactoryInventoryEditorView,
  IAdminFactoryInventoryEditorSlice,
} from "./interfaces";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Utilities} from "../../../../database/firebase";
import {subscribeWithCallback} from "../../../../database/firebase/utilities";
import {store} from "../../../../store";

const getInitialState = (): IAdminFactoryInventoryEditorSlice => {
  return {
    editingProduct: undefined,
    editingConfiguration: undefined,
    view: null,
    isEditing: false,
  };
};

const adminFactoryInventoryEditorSlice = createSlice({
  name: "adminFactoryInventoryEditor",
  initialState: getInitialState(),
  reducers: {
    clearInventoryEditor: (state) => {
      state.editingConfiguration = undefined;
      state.editingProduct = undefined;
      state.isEditing = false;
      state.view = null;
    },
    setProductInInventoryEditor: (state, action) => {
      // If we are setting a product into the editor, default to catalog view
      if (!state.editingProduct && !!action.payload) {
        state.view = AdminFactoryInventoryEditorView.CATALOG;
        state.isEditing = true;
        subscribeToInventoryUnitConfiguration(action.payload?.id);
      }
      if (!action.payload) {
        state.view = null;
        state.isEditing = false;
        state.editingConfiguration = undefined;
      }
      state.editingProduct = action.payload;
    },
    setConfigurationInInventoryEditor: (state, action) => {
      state.editingConfiguration = action.payload;
    },
    setViewInInventoryEditor: (
      state,
      action: PayloadAction<AdminFactoryInventoryEditorView>
    ) => {
      state.view = action.payload;
    },
    setIsEditingInInventoryEditor: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
  },
});

export const adminFactoryInventoryEditorReducer =
  adminFactoryInventoryEditorSlice.reducer;
export const {
  setIsEditingInInventoryEditor,
  setProductInInventoryEditor,
  setViewInInventoryEditor,
  setConfigurationInInventoryEditor,
} = adminFactoryInventoryEditorSlice.actions;

const subscribing: {[id: string]: () => void} = {};
const subscribeToInventoryUnitConfiguration = async (id: string) => {
  if (!id) {
    return;
  }
  if (subscribing[id]) {
    subscribing[id]();
    delete subscribing[id];
  }
  const docRef = Utilities.collection("natomas/v1/inventory").doc(id);
  const unsubscribe = await subscribeWithCallback(docRef, (data: any) => {
    const productId =
      store.getState()?.adminFactoryInventoryEditor?.editingProduct?.id;
    if (!!data?.id && data?.id === productId) {
      store.dispatch(setConfigurationInInventoryEditor(data ?? {}));
    }
  });
  if (unsubscribe) {
    subscribing[id] = unsubscribe;
  }
};
