import React from "react";
import {NatModal} from "../../generics/modal/NatModal";
import {resetWarning, setShowWarning} from "../../slices/NavigationSlice";
import {useDispatch} from "react-redux";
import {StyleOption} from "../../generics/_shared";
import {NatomasButtonProps} from "../../generics/button";
import {getRequestedPathFunction} from "./paths";
import {getStore, store} from "../../../../store";
import {INavigationWarning} from "../../slices/types/Store";

const toLatestNavigationRequest = () => {
  const {latestNavigationRequestType: t, latestNavigationRequestArgs: a} =
    getStore().getState().navigation;
  const toRequest = getRequestedPathFunction(t);
  if (!!toRequest) {
    toRequest(...a);
    store.dispatch(resetWarning());
  }
};

export const getNavigationWarningModal = (
  show: boolean,
  warning?: INavigationWarning
) => {
  return (
    <NavigationWarningModal
      show={show}
      warning={warning}
      latestNavRequest={toLatestNavigationRequest}
    />
  );
};

const NavigationWarningModal = (props: {
  show: boolean;
  warning?: INavigationWarning;
  latestNavRequest?: any;
}): JSX.Element | null => {
  const {show, warning, latestNavRequest} = props;

  const dispatch = useDispatch();

  if (!warning || !latestNavRequest) {
    return null;
  }

  const {
    header,
    description,
    customCancelLabel,
    customContinueLabel,
    additionalButtons,
  } = warning;

  const getButtons = (): NatomasButtonProps[] => {
    let buttons: NatomasButtonProps[] = [
      {
        id: "warning-cancel-button",
        label: customCancelLabel ?? "Cancel",
        type: "button",
        option: StyleOption.SECONDARY,
        clickEvent: () => dispatch(setShowWarning(false)),
      },
    ];
    if (additionalButtons) {
      additionalButtons.forEach((button: NatomasButtonProps) => {
        buttons.push(button);
      });
    }
    buttons.push({
      id: "warning-continue-button",
      label: customContinueLabel ?? "Continue",
      type: "button",
      option: StyleOption.DESTRUCTIVE,
      clickEvent: () => {
        latestNavRequest();
      },
    });
    return buttons;
  };

  return (
    <NatModal
      show={show}
      hideCloseIcon={true}
      content={<>{description}</>}
      centeredContent={false}
      header={header}
      footerButtons={getButtons()}
    />
  );
};
