import React from "react";
import {ICartItem} from "@natomas-org/core";
import {useProduct} from "../../../../_shared/hooks/useProductCatalog/useProduct";

interface CartItemInfoProps {
  item: ICartItem;
}

const CartItemProductInfo = (props: CartItemInfoProps) => {
  const {item} = props;
  const {product} = useProduct(
    item?.details?.productId,
    item?.details?.factoryId
  );
  const {title} = product ?? {};
  return <div>{title}</div>;
};

export default CartItemProductInfo;
