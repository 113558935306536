import styled from "styled-components";
import icon from "./chevron_down.svg";

export const StyledSelect = styled.select`
  // Custom Styles
  width: 100%;
  outline: 0;
  font-size: 16px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid var(--black-10);
  font-family: Inter-Regular, serif;
  font-weight: 400;
  &:focus {
    border-color: var(--semantic-informational);
  }
  &:disabled {
    cursor: not-allowed;
    background: #f1f3f5;
    border-color: #acacac;
  }
  appearance: none;
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-color: #fff;
  background-image: url(${icon});
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
`;
