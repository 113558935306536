import styled from "styled-components";
import {BLACK, GRAY} from "../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../../portal/views/SSDDashboardView/styles/globals";

export const InfoItemRowContainer = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;
export const InfoLabel = styled.div`
  font-size: 1rem;
  color: ${BLACK};
  margin-right: 1rem;
  font-family: ${FONT_FAMILY_BOLD};
`;
export const InfoDescription = styled.div`
  font-size: 0.675rem;
  color: ${GRAY};
  white-space: pre-wrap;
`;
export const InfoValue = styled.div<{
  customColor?: string;
  hasClickEvent?: boolean;
}>`
  font-size: 1rem;
  color: ${(props) => props.customColor ?? GRAY};
  white-space: pre-wrap;
  font-family: ${FONT_FAMILY_REGULAR};
  cursor: ${(props) => (props.hasClickEvent ? "pointer" : "default")};
  &:hover {
    font-family: ${(props) => props.hasClickEvent && FONT_FAMILY_BOLD};
  }
`;

export const InfoDisclaimer = styled.div`
  font-size: 0.675rem;
  margin: 1rem 0 0 0;
  color: ${GRAY};
  font-style: italic;
  &:before {
    content: "Disclaimer: ";
  }
`;
