import React from "react";
import {BodyContainer, Header, HeaderContainer} from "./styles";
import CurrentUserDetails from "./CurrentUserDetails";
import NexusEndpointController from "./NexusEndpointController";
import {getMeService} from "../../../api/Me";

const getMe = async () => {
  const me = await getMeService();
  return me.get();
};

const createMe = async () => {
  const me = await getMeService();
  return me.create();
};

const getPortfolios = async () => {
  const me = await getMeService();
  return me.getPortfolios();
};

const NexusMeApiDemo = () => {
  return (
    <>
      <HeaderContainer>
        <Header>villa-nexus /me environment</Header>
        <CurrentUserDetails />
      </HeaderContainer>
      <BodyContainer>
        <NexusEndpointController method={getMe} label={"getMe"} />
        <NexusEndpointController method={createMe} label={"createMe"} />
        <NexusEndpointController
          method={getPortfolios}
          label={"getPortfolios"}
        />
      </BodyContainer>
    </>
  );
};

export default NexusMeApiDemo;
