import React, {useMemo, useState} from "react";
import {Product} from "@natomas-org/core";
import {
  IOrderFormTableCellValue,
  OrderFormTableCellType,
} from "./OrderFormTable/interfaces";
import {InfoSetItem} from "../../../../../../factory-info-set/shared/interfaces";
import {LoadingPanel} from "../../../../../../_shared/generics/loading-panel";
import {OrderFormAlertBox} from "./OrderFormAlertBox";
import {OrderFormDownloader} from "./OrderFormDownloader";
import {OrderFormAssistanceContainer, OrderFormTabContainer} from "./styles";
import {OrderFormTable} from "./OrderFormTable";
import {getDataValue} from "./OrderFormTable/derivation";
import {useAdminFactoryLine} from "../../../../../../_shared/hooks/admin-only-hooks/useAdminFactoryLine";
import {
  ARCHIVED_KEY,
  CONDITIONS_KEY,
  ID_KEY,
  INCLUDED_ORDER_FORM_KEY,
  INTERNAL_CATEGORY_KEY,
  LABEL_KEY,
  VALUE_KEY,
} from "../../../../../../factory-info-set/shared/constants";
import _ from "lodash";
import {
  OrderFormTableControllerContainer,
  OrderFormTableControllerHeader,
} from "./OrderFormAlertBox/styles";
import {NatToggleSelector} from "../../../../../../_shared/generics/toggle-selector";

import {EmptyPage} from "../../../../../../_shared/generics/empty-page";
import {INVENTORY_UNIT_PREFIX} from "../../../../../../_shared/constants/labels";
import useActiveDesign from "../../../../../../_shared/hooks/useDesign/useActiveDesign";
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";

export interface OrderFormTabItem {
  [ID_KEY]: string;
  [INTERNAL_CATEGORY_KEY]: string;
  [LABEL_KEY]: string;
  [VALUE_KEY]: IOrderFormTableCellValue;
  [CONDITIONS_KEY]: IOrderFormTableCellValue;
}

const isItemSatisfied = (item: OrderFormTabItem): boolean => {
  return isItemSatisfiedByManualSelection(item) || isItemDerived(item);
};

const isItemDerived = (item: OrderFormTabItem): boolean => {
  const value = item[VALUE_KEY];
  if (value) {
    return value.type === OrderFormTableCellType.DERIVED;
  }
  return false;
};

const isItemSatisfiedByManualSelection = (item: OrderFormTabItem): boolean => {
  const value = item[VALUE_KEY];
  if (value) {
    return !!value.satisfiedByManualSelection;
  }
  return false;
};

const isAllSatisfied = (items: OrderFormTabItem[]): boolean => {
  return (
    items?.length > 0 &&
    items.every((item: OrderFormTabItem) => isItemSatisfied(item))
  );
};

export const OrderFormTab = () => {
  const {mergedCustomerProjectInfos, address, isCartEnabled} =
    useActiveProject();
  const {id, configuration} = useActiveDesign();
  const {product, selections, orderFormSelections} = configuration ?? {};
  const {modifiers, infoSet} = useAdminFactoryLine(product?.productGroupId);

  const allCustomerSelections = useMemo(
    () => Object.keys(selections ?? {}),
    [selections]
  );
  const [allUsedModifiers, setAllUsedModifiers] = useState<string[]>([]);
  const [showOnlyManual, setShowOnlyManual] = useState<boolean>(false);

  const orderFormItems = useMemo(() => {
    if (infoSet) {
      const items = Object.values(infoSet);
      return items
        .filter((i: InfoSetItem) => {
          return !i[ARCHIVED_KEY] && i[INCLUDED_ORDER_FORM_KEY];
        })
        .sort(
          (a: InfoSetItem, b: InfoSetItem) =>
            a[INTERNAL_CATEGORY_KEY].localeCompare(b[INTERNAL_CATEGORY_KEY]) ??
            a[LABEL_KEY].localeCompare(b[LABEL_KEY])
        );
    }
    return [];
  }, [infoSet]);

  const tableData: OrderFormTabItem[] = useMemo(() => {
    if (!orderFormItems || !product) {
      return [];
    }
    let usedModifiers: string[] = [];
    const dataItems = orderFormItems?.map((item: InfoSetItem) => {
      const value: IOrderFormTableCellValue = getDataValue(
        item,
        product,
        selections,
        orderFormSelections
      );
      value?.satisfiedModifiers?.forEach((modifierId: string) => {
        if (!usedModifiers.includes(modifierId)) {
          usedModifiers.push(modifierId);
        }
      });
      return {
        [ID_KEY]: item[ID_KEY],
        [INTERNAL_CATEGORY_KEY]: item[INTERNAL_CATEGORY_KEY],
        [LABEL_KEY]: item[LABEL_KEY],
        [VALUE_KEY]: value,
        [CONDITIONS_KEY]: value,
      };
    });
    setAllUsedModifiers(usedModifiers);
    return dataItems;
  }, [orderFormItems, orderFormSelections, product, selections]);

  const manualTableData: OrderFormTabItem[] = useMemo(() => {
    return tableData?.filter((item: OrderFormTabItem) => {
      return !isItemDerived(item);
    });
  }, [tableData]);

  const isDownloadable = useMemo(() => {
    return isAllSatisfied(tableData);
  }, [tableData]);

  if (isCartEnabled) {
    return (
      <EmptyPage
        title={`Project is Cart Enabled (Control Panel -> Project Settings).\nOrder Form Manager has not been migrated yet to support this.`}
      />
    );
  }

  if (!product) {
    return <EmptyPage title={`Project does not have a product selected.`} />;
  }

  if (!address) {
    return <EmptyPage title={`Project address has not been configured.`} />;
  }

  if (product && Product.isInventory(product)) {
    return (
      <EmptyPage
        title={`${INVENTORY_UNIT_PREFIX}s cannot utilize the Order Form Manager`}
      />
    );
  }

  return (
    <>
      <LoadingPanel
        show={
          !_.isEmpty(infoSet) &&
          !!product &&
          (!tableData || tableData?.length === 0)
        }
        relativePositioning={true}
      />

      <OrderFormTabContainer>
        <OrderFormTable
          tableData={showOnlyManual ? manualTableData : tableData}
          modifiers={modifiers ?? {}}
          configurationId={id}
          orderFormSelections={orderFormSelections}
        />
      </OrderFormTabContainer>
      <OrderFormAssistanceContainer>
        <OrderFormDownloader
          isDownloadable={isDownloadable}
          tableData={tableData}
          address={address}
          code={mergedCustomerProjectInfos?.code ?? "UNKNOWN"}
          product={product}
          projectId={id}
        />
        <OrderFormAlertBox
          allFieldsSatisfied={isDownloadable}
          modifiers={modifiers ?? {}}
          allSelectionModifierIds={allCustomerSelections}
          usedSelectionModifierIds={allUsedModifiers}
          address={address}
          product={product}
          code={mergedCustomerProjectInfos?.code}
        />
        <OrderFormTableControllerContainer>
          <OrderFormTableControllerHeader>
            Table Mode
          </OrderFormTableControllerHeader>
          <NatToggleSelector
            options={[
              {
                id: "all",
                label: "All",
                isSelected: !showOnlyManual,
                clickEvent: () => setShowOnlyManual(false),
              },
              {
                id: "todo",
                label: "Internal",
                isSelected: showOnlyManual,
                clickEvent: () => setShowOnlyManual(true),
              },
            ]}
          />
        </OrderFormTableControllerContainer>
      </OrderFormAssistanceContainer>
    </>
  );
};
