import {HeaderSearchInput} from "../../../../../_shared/styles/AdminStyles";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useDebounce} from "../../../../../../_shared/hooks/useDebounce";

export interface MatrixTableSearchInputProps {
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
}

export const MatrixTableSearchInput = (props: MatrixTableSearchInputProps) => {
  const {setSearchText} = props;
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const debouncedValue = useDebounce(searchValue, 250);

  useEffect(() => {
    setSearchText(debouncedValue);
  }, [debouncedValue, setSearchText]);

  return (
    <HeaderSearchInput
      spellCheck={false}
      onChange={(e) => {
        if (!e.target.value || e.target.value?.length <= 0) {
          setSearchValue(undefined);
        } else {
          setSearchValue(e.target.value);
        }
      }}
      placeholder={"Search (3 character minimum)"}
    />
  );
};
