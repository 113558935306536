import {
  FactoryInventoryInformationEditorKey,
  IFactoryInventoryInformationEditor,
} from "./interfaces";

export const getInitialInventoryInformationEditorValues = (
  product: IFactoryInventoryInformationEditor
) => {
  const keys = Object.keys(FactoryInventoryInformationEditorKey);
  const initialState: {[key: string]: any} = {};
  keys.forEach((key: string) => {
    let k = key as keyof typeof FactoryInventoryInformationEditorKey;
    initialState[FactoryInventoryInformationEditorKey[k]] =
      product[FactoryInventoryInformationEditorKey[k]] ?? "";
  });
  return initialState;
};
