import styled from "styled-components";
import {FONT_FAMILY_MEDIUM} from "../../../../../../../../../../../portal/views/SSDDashboardView/styles/globals";
import {
  BLACK,
  GRAY,
  WHITE,
} from "../../../../../../../../../../../_shared/colors";

export const StudioEditorModifierGroupContainer = styled.div<{
  showBorder?: boolean;
}>`
  border: ${(props) => props?.showBorder && `1px solid ${BLACK}`};
  border-radius: 8px;
  padding: ${(props) => props?.showBorder && `8px`};
  background-color: ${WHITE};
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

export const StudioEditorModifierGroupTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
  width: 100%;
`;

export const StudioEditorModifierGroupTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

export const StudioEditorModifierGroupTitle = styled.div`
  font-size: 20px;
  font-family: ${FONT_FAMILY_MEDIUM};
`;

export const StudioEditorModifierGroupDescription = styled.div`
  font-size: 14px;
  color: ${GRAY};
`;
