import {useEffect} from "react";
import {fetchUser} from "../../database/firebase/user";
import useActivitySlice from "../../components/_shared/hooks/useActivity/useActivitySlice";
import {useUsers} from "../../components/_shared/hooks/useUser/useUsers";
import {useCurrentUser} from "../../components/_shared/hooks/useCurrentUser";
import useActivitySliceDispatcher from "../../components/_shared/hooks/useCustomer/useActivitySliceDispatcher";

const CustomerManager = () => {
  const {activeCustomerId, activeProjectId, activeCartItemId} =
    useActivitySlice();
  const {setActiveCustomerById, setActiveCartItemById, setActiveProjectById} =
    useActivitySliceDispatcher();
  const customers = useUsers();
  const {isAdmin, loggedInUser, uid, authInitialized} = useCurrentUser();
  // Clear activity slice if there is no active customer
  useEffect(() => {
    if (authInitialized && !uid) {
      if (activeCustomerId) {
        setActiveCustomerById(undefined);
      }
      if (activeProjectId) {
        setActiveProjectById(undefined);
      }
      if (activeCartItemId) {
        setActiveCartItemById(undefined);
      }
    }
  }, [
    activeCustomerId,
    activeProjectId,
    activeCartItemId,
    setActiveCustomerById,
    setActiveProjectById,
    setActiveCartItemById,
    uid,
    authInitialized,
    isAdmin,
  ]);

  useEffect(() => {
    if (uid && !isAdmin && uid !== activeCustomerId) {
      // If current user is not an admin, set them as the active customer
      setActiveCustomerById(uid);
    }
  }, [activeCustomerId, isAdmin, setActiveCustomerById, uid]);

  useEffect(() => {
    if (activeCustomerId && loggedInUser && !customers[activeCustomerId]) {
      // Fetch customer information
      fetchUser(activeCustomerId, false);
    }
  }, [activeCustomerId, customers, loggedInUser]);

  return null;
};

export default CustomerManager;
