import React from "react";
import {NatButtonSection} from "./sections/NatButtonSection";
import {NatIconButtonSection} from "./sections/NatIconButtonSection";
import {NatLinkSection} from "./sections/NatLinkSection";
import {NatDropdownSection} from "./sections/NatDropdownSection";

const NatLibraryPreview = () => {
  return (
    <>
      <NatButtonSection />
      <NatIconButtonSection />
      <NatLinkSection />
      <NatDropdownSection />
    </>
  );
};

export default NatLibraryPreview;
