import {useSelector} from "react-redux";
import {IStore} from "../../slices/types/Store";
import {useFactoryLine} from "./useFactoryLine/useFactoryLine";

export const useSelectedFactoryLine = () => {
  const {selectedId, selectedVersionId} = useSelectedFactoryLineIds();
  return useFactoryLine({
    factoryId: selectedId ?? undefined,
    versionId: selectedVersionId ?? undefined,
  });
};

export const useSelectedFactoryLineIds = () => {
  const selectedId = useSelector(
    (state: IStore) => state.catalogSession.selectedProductGroupId
  );
  const selectedVersionId = useSelector(
    (state: IStore) => state.catalogSession.selectedProductGroupVersionId
  );
  return {
    selectedVersionId,
    selectedId,
  };
};
