import React, {Fragment, useMemo, useState} from "react";
import {TimelineEditor} from "./TimelineEditor/TimelineEditor";
import {NatFlex} from "../../../../../../_shared/generics/flex/NatFlex";
import NatLabel, {
  NatLabelType,
} from "../../../../../../_shared/generics/label/NatLabel";
import {
  CostTabToggle,
  CostTabToggleContainer,
  ToggleContainer,
} from "../../../../../../portal/views/ProposalBudgetView/styles";
import {QualificationsAndAssumptionsEditor} from "./QualificationsAndAssumptionsEditor/QualificationsAndAssumptionsEditor";
import {NatToggleSelector} from "../../../../../../_shared/generics/toggle-selector";
import {EditCartItemFullScreenDesigns} from "./EditCartItemFullScreenDesigns/EditCartItemFullScreenDesigns";
// @ts-ignore
import useActiveProject from "../../../../../../_shared/hooks/useProject/useActiveProject";
import {EMBEDDED_PROPOSAL} from "../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/components/constants";
import {
  B2BProposalPreview,
  DTCProposalPreview,
} from "./useProjectProposalDownload/useProjectProposalDownload";
import {B2BPresentationView} from "../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/B2B/B2BPresentationView";
import {DTCPresentationView} from "../../../../../../portal/views/HCPDashboardLayout/HCPPresentationView/DTC/DTCPresentationView";
import {updateProjectProposalDisplayType} from "../../../../../../../database/firebase/api/project";
import {ProposalDisplayType} from "@natomas-org/core";
import {
  NatJustifyContent,
  NatSize,
  NatVerticalAlign,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {NatButton} from "../../../../../../_shared/generics/button";
import ProposalPDFViewButton from "../../../../../DeveloperView/ProposalPDFButton";

const developmentByVillaTabs = [
  {
    id: "timeline",
    label: "Timeline",
    component: <TimelineEditor displayQuarters={true} />,
  },
  {
    id: "qualificationsAndAssumptions",
    label: "Qualifications & Assumptions",
    component: <QualificationsAndAssumptionsEditor />,
  },
  {
    id: "designSelections",
    label: "Full Screen Design Selections",
    component: <EditCartItemFullScreenDesigns />,
  },
];
const backyardByVillaTabs = [
  {
    id: "timeline",
    label: "Timeline",
    component: <TimelineEditor displayQuarters={true} />,
  },
];
export const ProposalPreview = (props: {
  hidden?: boolean;
  zoomLevel?: number;
  embedded: boolean;
}) => {
  const {isDevelopmentByVilla} = useActiveProject();

  if (props.embedded) {
    return (
      <EMBEDDED_PROPOSAL.Provider value={props.embedded}>
        {isDevelopmentByVilla ? (
          <B2BPresentationView hideClose={true} readyToPrint={false} />
        ) : (
          <DTCPresentationView hideClose={true} readyToPrint={false} />
        )}
      </EMBEDDED_PROPOSAL.Provider>
    );
  }
  return (
    <div
      id={"proposal-preview-internal"}
      hidden={props.hidden}
      style={{
        height: "fit-content",
        width: "fit-content",
        position: "absolute",
        top: 0,
        left: 0,
        // top: props.zoomLevel ? `${(props.zoomLevel / 4) * 100}` : undefined,
        transform: props.zoomLevel ? `scale(${props.zoomLevel})` : undefined,
      }}
    >
      <EMBEDDED_PROPOSAL.Provider value={props.embedded}>
        {isDevelopmentByVilla ? <B2BProposalPreview /> : <DTCProposalPreview />}
      </EMBEDDED_PROPOSAL.Provider>
    </div>
  );
};

export const ProposalViewerAndEditor = () => {
  const [tab, setTab] = React.useState(developmentByVillaTabs[0]);
  const [preview, setPreview] = useState<boolean>(false);
  const {isDevelopmentByVilla, id} = useActiveProject();
  const tabs = useMemo(() => {
    if (isDevelopmentByVilla) {
      return developmentByVillaTabs;
    }
    return backyardByVillaTabs;
  }, [isDevelopmentByVilla]);
  const EditContent = () => {
    return (
      <NatFlex
        ROW
        style={{width: "fit-content"}}
        id={"project-proposal-view-tab"}
      >
        <ToggleContainer style={{width: "fit-content"}}>
          <CostTabToggleContainer>
            {tabs.map((tabInfo) => {
              return (
                <CostTabToggle
                  onClick={() => {
                    setTab(tabInfo);
                  }}
                  active={tab.id === tabInfo.id}
                  key={tabInfo.id}
                >
                  {tabInfo.label}
                </CostTabToggle>
              );
            })}
          </CostTabToggleContainer>
        </ToggleContainer>
        {developmentByVillaTabs.map((tabInfo: any) => {
          return (
            <NatFlex
              FULL_WIDTH
              ROW
              key={tabInfo.id}
              hidden={tab.id !== tabInfo.id}
            >
              {tabInfo.component}
            </NatFlex>
          );
        })}
      </NatFlex>
    );
  };

  return (
    <Fragment>
      <NatFlex COLUMN NOWRAP style={{padding: "2rem 2rem"}}>
        <NatLabel label={"Proposal Viewer/Editor"} type={NatLabelType.H2} />
        {(preview || !isDevelopmentByVilla) && (
          <div style={{width: "fit-content"}}>
            <ProposalPDFViewButton />
          </div>

          // <ProjectProposalDownloadButton />
        )}
        <NatFlex COLUMN NOWRAP>
          <NatFlex
            ROW
            FULL_WIDTH
            NOWRAP
            VERTICAL_ALIGN={NatVerticalAlign.CENTER}
            JUSTIFY_CONTENT={NatJustifyContent.SPACE_BETWEEN}
          >
            <NatToggleSelector
              style={{width: "fit-content"}}
              options={[
                {
                  id: "preview",
                  label: "Preview",
                  isSelected: preview,
                  clickEvent: () => {
                    setPreview(true);
                  },
                },
                {
                  id: "edit",
                  label: "Edit",
                  isSelected: !preview,
                  clickEvent: () => {
                    setPreview(false);
                  },
                },
              ]}
            />
            <NatButton
              label={
                isDevelopmentByVilla
                  ? "Display B2B Proposal"
                  : "Display DTC Proposal"
              }
              clickEvent={() => {
                return updateProjectProposalDisplayType(
                  id,
                  isDevelopmentByVilla
                    ? ProposalDisplayType.BackyardByVilla
                    : ProposalDisplayType.DevelopmentByVilla
                );
              }}
              style={{width: "fit-content"}}
              spinnerEnabled={true}
              size={NatSize.SMALL}
              option={StyleOption.ADMIN_SOLID}
            />
          </NatFlex>
          {preview ? <ProposalPreview embedded={true} /> : <EditContent />}
        </NatFlex>
      </NatFlex>
    </Fragment>
  );
};
