import {storage, Utilities} from "../../index";
import {
  IDocumentDetails,
  IGCloudFileStorageDetails,
  PROJECT_DOCUMENTS_COLLECTION,
  ProjectDestination,
} from "@natomas-org/core";

const getProjectDocumentRef = (projectId: string, documentId: string) => {
  return Utilities.collection(ProjectDestination.NATOMAS)
    .doc(projectId)
    .collection(PROJECT_DOCUMENTS_COLLECTION)
    .doc(documentId);
};

export const updateProjectDocumentInfo = async (
  projectId: string,
  documentId: string,
  documentDetails: Partial<IDocumentDetails>
) => {
  const documentRef = getProjectDocumentRef(projectId, documentId);
  const data: Partial<IDocumentDetails> = {
    document_id: documentId,
  };

  let ref = `project/${projectId}/${documentId}`;
  if (documentDetails.title != null) {
    data.title = documentDetails.title;
  }
  if (documentDetails.hidden != null) {
    data.hidden = documentDetails.hidden;
  }
  if (documentDetails.deleted != null) {
    data.deleted = documentDetails.deleted;
  }
  if (
    (documentDetails?.details as IGCloudFileStorageDetails)?.storageRefPath !=
    null
  ) {
    ref = (documentDetails?.details as IGCloudFileStorageDetails)
      ?.storageRefPath;
  }
  if (data.deleted) {
    console.log("Deleting file from storage", ref);
    try {
      const fileStorageBaseRef = storage.ref(ref);
      await fileStorageBaseRef.delete();
      console.log("Deleted file from storage", ref);
    } catch (e) {
      console.log("Error deleting file from storage", ref);
    }
    return Utilities.deleteDocument(documentRef);
  }
  return Utilities.setDocWithMerge(documentRef, data);
};
