import React, {useMemo} from "react";
import {
  FactoryInventoryInput,
  FactoryInventoryInputType,
} from "../../_shared/FactoryInventoryInput";
import {priceTextFromMicros} from "../../../../../../../../../studio/_shared/helper";

interface TotalPriceInputProps {
  currentValue?: any;
  initialValue?: any;
  upgradePricingInMicros: number;
  setValue: (num: number) => void;
}

const TotalPriceInput = (props: TotalPriceInputProps) => {
  const {currentValue, initialValue, upgradePricingInMicros, setValue} = props;

  const initial = useMemo(() => {
    return (initialValue ?? 0) + upgradePricingInMicros;
  }, [initialValue, upgradePricingInMicros]);

  const current = useMemo(() => {
    return (currentValue ?? 0) + upgradePricingInMicros;
  }, [currentValue, upgradePricingInMicros]);

  const formattedPriceBreakdown = useMemo(() => {
    return {
      upgradePriceString: priceTextFromMicros(
        upgradePricingInMicros,
        "accurate"
      ),
      basePriceString: priceTextFromMicros(currentValue ?? 0, "accurate"),
    };
  }, [currentValue, upgradePricingInMicros]);

  return (
    <FactoryInventoryInput
      id={"total-price-input"}
      key={"total-price-input"}
      label={"Total Price"}
      description={`${formattedPriceBreakdown.basePriceString} + ${formattedPriceBreakdown.upgradePriceString}, (Base + Upgrades)`}
      type={FactoryInventoryInputType.PRICE}
      initialValue={initial}
      value={current}
      onChange={(value: number) => {
        setValue(value - upgradePricingInMicros);
      }}
      resetChange={() => setValue(initialValue - upgradePricingInMicros)}
    />
  );
};

export default TotalPriceInput;
