export const CategoryStructure = {
  title: {
    key: "title",
    name: "Title",
    index: 0,
    type: "input",
  },
  internal_title: {
    key: "internal_title",
    name: "Internal Title",
    index: 2,
    type: "input",
  },
  heading: {
    key: "heading",
    name: "Heading",
    index: 3,
    type: "input",
  },
  displayDescription: {
    key: "displayDescription",
    name: "Display Description",
    index: 4,
    type: "textarea",
  },
  isAdminSelection: {
    key: "isAdminSelection",
    name: "Admin Selection Only",
    index: 10,
    defaultValue: false,
    type: "yes-no",
  },
  modifierGroups: {
    key: "modifierGroups",
    name: "Modifier Groups",
    defaultValue: [],
    hiddenInput: true,
    index: 5,
  },
  modifierGroupOverrides: {
    key: "modifierGroupOverrides",
    name: "Modifier Groups",
    defaultValue: {},
    hiddenInput: true,
    index: 7,
  },
  sections: {
    key: "sections",
    name: "Sections",
    defaultValue: {},
    hiddenInput: true,
    index: 6,
  },
};

export const SectionStructure = {
  title: {
    key: "title",
    name: "Title",
    index: 0,
    type: "input",
  },
  index: {
    key: "index",
    name: "Internal Title",
    index: 1,
    defaultValue: "General",
    type: "number",
  },
  items: {
    key: "items",
    name: "Items",
    index: 2,
    defaultValue: [],
  },
};
