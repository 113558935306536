import React from "react";
import {PortalHeader} from "../../_shared/components/PortalHeader";
import {HCPGreeting} from "./HCPGreetingView/HCPGreeting";
import {HCPCalendar} from "./HCPCalendarView/HCPCalendar";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../_shared/hooks/useDynamicValue";
import {
  CART_PAGE_ID,
  PRESENTATION_PAGE_ID,
  PROFILE_PAGE_ID,
} from "../../_shared/navigation/constants";
import {HCPBudget} from "./HCPBudgetView/HCPBudget";
import {HCPTimeline} from "./HCPTimelineView/HCPTimeline";
import {HCPNextSteps} from "./HCPNextSteps/HCPNextSteps";
import {HCPUnitView} from "./HCPUnitView/HCPUnitView";
import {AccountView} from "../AccountView";
import {HCPTitleNav} from "./HCPTitleNav";
import {HCPDocumentsAndTasksView} from "./HCPDocumentsView/HCPDocumentsAndTasksView";
import {FPSiteMap} from "../FPDashboardLayout/FPSiteMapView/FPSiteMap";
import {HCPModelHomeTour} from "./HCPModelHomeTour/HCPModelHomeTour";
import {HCPHelpfulLinks} from "./HCPHelpfulLinks/HCPHelpfulLinks";
import {PortalPresentationRoot} from "./HCPPresentationView/PortalPresentationRoot";
import CartView from "../CartView";
import {SDRBanner} from "../../_shared/components/FAQs/components/SDRBanner";

export const HCPDashboardLayout = (props: {pageId: string | null}) => {
  const fullWidth = useDynamicValue(fullColumnWidths);
  if (props.pageId === PROFILE_PAGE_ID) {
    return (
      <>
        <PortalHeader title={<>Dashboard</>} showAppSwitcher={false} />
        <AccountView />
      </>
    );
  }

  if (props.pageId === PRESENTATION_PAGE_ID) {
    return <PortalPresentationRoot />;
  }

  if (props.pageId === CART_PAGE_ID) {
    return (
      <>
        <PortalHeader title={<>Cart</>} showAppSwitcher={false} />
        <CartView />
      </>
    );
  }

  return (
    <>
      <PortalHeader title={<HCPTitleNav />} showAppSwitcher={false} />
      <HCPGreeting />
      <HCPNextSteps width={fullWidth} />
      <HCPCalendar width={fullWidth} />
      <HCPModelHomeTour width={fullWidth} />
      <FPSiteMap width={fullWidth} />
      <HCPUnitView width={fullWidth} />
      <HCPBudget width={fullWidth} />
      <HCPTimeline width={fullWidth} />
      <HCPDocumentsAndTasksView width={fullWidth} />
      <HCPHelpfulLinks width={fullWidth} />
      <br />
      <br />
      <br />
    </>
  );
};
