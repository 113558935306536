import React, {ReactElement} from "react";
import {RowLabelContextMenuHeader, RowLabelContextMenuItem} from "./styles";
import {RowContextMenuItem} from "../../actions";

export interface InfoSetItemContextMenuProps {
  items: RowContextMenuItem[];
  title?: string;
}

const sortContextMenuItems = (
  items: RowContextMenuItem[]
): RowContextMenuItem[] => {
  return items.sort((a: RowContextMenuItem, b: RowContextMenuItem) => {
    return (a.group ?? 0) - (b.group ?? 0) || a.label.localeCompare(b.label);
  });
};

const isNewGroup = (
  item: RowContextMenuItem,
  index: number,
  array: RowContextMenuItem[]
): boolean => {
  return item?.group !== array[index - 1]?.group && index > 0;
};

export const InfoSetItemContextMenu = (
  props: InfoSetItemContextMenuProps
): ReactElement => {
  const {items} = props;
  return (
    <>
      {sortContextMenuItems(items).map(
        (
          item: RowContextMenuItem,
          index: number,
          array: RowContextMenuItem[]
        ) => {
          return (
            <RowLabelContextMenuItem
              borderTop={isNewGroup(item, index, array)}
              key={`context-menu-item-${index}`}
              onClick={item.action}
            >
              {item?.icon} {item.label}
            </RowLabelContextMenuItem>
          );
        }
      )}
      {items?.length === 0 && (
        <div>
          <RowLabelContextMenuHeader italics={true}>
            Empty
          </RowLabelContextMenuHeader>
        </div>
      )}
    </>
  );
};
