import React from "react";
/*
 * A tab version of the image finder, with some additional features.
 * */
import {ImageFinder} from "../../../../_shared/generics/image/ImageFinder";
import {ImagesTabContainer} from "./styles";

export const ImagesTab = () => {
  return (
    <ImagesTabContainer>
      <ImageFinder
        title={"Image Search"}
        style={{height: "calc(100% - 50px)", overflowY: "auto"}}
        showEditDetails={true}
      />
    </ImagesTabContainer>
  );
};
