import styled from "styled-components";

export const SublistCardContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    img {
      transform: scale(1.025);
      transition: all 150ms ease;
    }
  }
`;

export const SublistCardTitle = styled.div`
  margin-top: 0.5rem;
  font-size: 1rem;
`;

export const SublistCardPriceRange = styled.div`
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
`;
