import {executeRequest} from "../APIExecutor";

const duplicateCartItemPath = "/project/v1/duplicateCartItem";

export const duplicateCartItemToCartLibrary = (
  customerId: string,
  projectId: string,
  cartItemId: string
) => {
  return executeRequest(duplicateCartItemPath, {
    customerId,
    projectId,
    cartItemId,
  }).then((data) => {
    console.log(data);
  });
};
