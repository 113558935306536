import styled from "styled-components";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
} from "../../../SSDDashboardView/styles/globals";
import {
  BLACK,
  GRAY,
  GRAY_BORDER_LIGHT,
  VILLA_BLUE,
  WHITE,
} from "../../../../../_shared/colors";
import {BUTTON_Z_INDEX} from "../../../../../_shared/styles";

export const DesignSelectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  /* width */

  ::-webkit-scrollbar {
    height: 6px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: ${VILLA_BLUE};
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  height: auto;
`;

const FULL_SCREEN_SIZE_SCALAR = 1.25;
export const DesignSelectionsCarousel = styled.div<{
  width?: number | string;
  carouselSize: number;
  columnMode: boolean;
  pageBreakAfter?: boolean;
  noBorder?: boolean;
  formatFullScreen?: boolean;
  carouselHeightRem?: number;
  multipleSlides?: boolean;
  presentationMode?: boolean;
}>`
  height: auto;
  width: ${(props) => (props.width ? props.width : undefined)};
  min-width: ${(props) => (props.width ? props.width : undefined)};
  display: flex;
  flex-grow: 1;
  flex-direction: ${(props) =>
    props.columnMode && !props.formatFullScreen ? "column" : "row"};
  border-radius: ${(props) => (props.formatFullScreen ? undefined : "1rem")};
  border: ${(props) =>
    props.noBorder
      ? `0px solid ${GRAY_BORDER_LIGHT}`
      : `1px solid ${GRAY_BORDER_LIGHT}`};
  page-break-after: avoid;
  page-break-inside: avoid;
  break-after: avoid;
  break-inside: avoid;
  margin: ${(props) => (props.formatFullScreen ? undefined : "1rem 0 2rem 0")};
  overflow: hidden;

  .carousel {
    height: ${(props) =>
      props.multipleSlides === true
        ? undefined
        : props.formatFullScreen && props.carouselSize
        ? `${props.carouselSize}px`
        : // : props.carouselSize
        // ? `${props.carouselSize / (1 + FULL_SCREEN_SIZE_SCALAR)}px`
        props.carouselHeightRem
        ? `${props.carouselHeightRem}rem`
        : // : undefined
        props.columnMode
        ? "14rem"
        : props.presentationMode
        ? "17rem"
        : "24rem"};
    flex-grow: 1;
    width: ${(props) =>
      !props.multipleSlides && props.formatFullScreen && props.carouselSize
        ? `${props.carouselSize * FULL_SCREEN_SIZE_SCALAR}px`
        : `${props.carouselSize}px`};

    .fullDiv {
      border-radius: ${(props) =>
        props.noBorder || props.formatFullScreen
          ? "0"
          : props.columnMode
          ? "1rem 1rem 0 0"
          : "1rem 0 0 1rem"};
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      display: none;
    }

    .slide {
      height: 100%;

      .carousel-control-next,
      .carousel-control-prev {
        z-index: ${BUTTON_Z_INDEX};
      }
    }

    .carousel-inner {
      height: 100%;
      border-radius: ${(props) =>
        props.noBorder || props.formatFullScreen
          ? "0"
          : props.columnMode
          ? "1rem 1rem 0 0"
          : "1rem 0 0 1rem"};

      .carousel-item {
        height: 100%;

        .fullDiv {
          border-radius: 0;
        }
      }
    }
  }
`;

export const DesignSelectionsSlideInfo = styled.div<{width: number | string}>`
  padding: 2rem;
  width: ${(props) => props.width}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DesignSelectionsCarouselWrapper = styled.div`
  border-radius: 1rem 0 0 1rem;
  display: flex;
  height: 100%;
`;

export const DesignSelectionsPagePrice = styled.div`
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: ${GRAY};
  font-family: ${FONT_FAMILY_MEDIUM};
  text-align: left;
`;

export const DesignSelectionsPageTitle = styled.div`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY_BOLD};
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const DesignSelectionsPageDetailsDropdown = styled.div<{
  expanded: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 0.85rem;
  font-family: ${FONT_FAMILY_BOLD};

  svg {
    margin-left: 0.5rem;
    transform: ${(props) => (props.expanded ? "rotate(90deg)" : "")};
    transition: transform 75ms ease-in-out;
  }
`;

export const DesignSelectionsPageDetailsContainer = styled.div<{
  expanded: boolean;
}>`
  transition: max-height 200ms ease-out;
  overflow-x: auto;
  margin: 1rem 0 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const DesignSelectionsPageDetail = styled.div<{
  color?: string;
  maxColumnCount?: number;
}>`
  font-size: 0.75rem;
  margin: 0.25rem 0;
  color: ${(props) => props.color ?? BLACK};
  background-color: ${WHITE};
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: ${(props) => `${100 / (props.maxColumnCount ?? 1)}%`};
  max-width: ${(props) => `${100 / (props.maxColumnCount ?? 1) - 5}%`};
  flex-grow: 1;
  width: fit-content;
}

%
;
`;
export const DesignSelectionsPageDetailTitle = styled.div`
  font-family: ${FONT_FAMILY_BOLD};
  font-size: 0.85rem;
  min-width: 100%;
  max-width: 100%;
`;
