import React, {useEffect, useMemo, useState} from "react";
import {AdminInput} from "../../../../../../admin/_shared/components/AdminComponentFactory/AdminComponentFactory";
import {useTags} from "../../../../../hooks/useTags/useTags";
import {TagType} from "../../../../../hooks/useTags/tags";
import {useImage} from "../../../../../hooks/useImage";
import {NatButton} from "../../../../button";
import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../../application";
import {
  getInitialValues,
  ImageDetailStructure,
  saveImageDetails,
} from "./helper";
import {useFormik} from "formik";
import {isDeepEqual} from "../../../../../../admin/ProductLineView/logic/AdminEditorHelper";
import {NatSize} from "../../../../_shared";
import {ImageDropZoneButtonContainer} from "../styles";

export const ImageAssignmentFields = (props: {
  imageId: string;
  close?: () => void;
}) => {
  const {imageId, close} = props;
  const imageInfo = useImage(imageId);
  const {getTagTitleById, getTagIdByTitle, tagIds} = useTags(
    TagType.PRODUCT_CATALOG
  );

  const availableTags = useMemo(() => {
    return tagIds?.map((tagId: string) => getTagTitleById(tagId));
  }, [tagIds]);

  const imageDetailStructure = getFormStructure(ImageDetailStructure);

  const [initialValues, setInitialValues] = useState(
    getInitialValues(imageInfo, getFormInitialValues(ImageDetailStructure))
  );

  useEffect(() => {
    const newInitialValues = getInitialValues(
      imageInfo,
      getFormInitialValues(ImageDetailStructure)
    );
    setInitialValues(newInitialValues);
    formik.setValues(newInitialValues);
  }, [imageInfo]);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any) => {
      const infoToSave = Object.assign({}, values);
      await saveImageDetails(imageId, infoToSave);
      close?.();
    },
  });

  return (
    <>
      {imageDetailStructure.map((keyInfo: any, index: number) => {
        const {key} = keyInfo;
        // @ts-ignore
        let value = formik?.values?.[key];
        // @ts-ignore
        let initial = initialValues?.[key];

        if (key === "tags") {
          if (!!value) {
            value = value?.map((id: string) => getTagTitleById(id)) ?? [];
          }
          if (!!initial) {
            initial = initial?.map((id: string) => getTagTitleById(id)) ?? [];
          }
          keyInfo["options"] = availableTags;
        }

        return (
          <div key={"image-assignment-field-" + index}>
            <AdminInput
              key={key}
              value={value}
              fieldInfo={keyInfo}
              initialValue={initial}
              onChange={(value: any) => {
                if (key === "tags") {
                  formik.setFieldValue(
                    key,
                    value?.map((v: string) => getTagIdByTitle(v))
                  );
                } else {
                  formik.setFieldValue(key, value);
                }
              }}
            />
          </div>
        );
      })}
      <ImageDropZoneButtonContainer>
        {!isDeepEqual(formik.values, initialValues) && (
          <NatButton
            label={"Update"}
            type={"button"}
            spinnerEnabled={true}
            size={NatSize.SMALL}
            clickEvent={() => formik.handleSubmit()}
          />
        )}
      </ImageDropZoneButtonContainer>
    </>
  );
};
