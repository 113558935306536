export interface ITableColumnInfo {
  dataField: string;
  type: FieldType;
  name: string;
  primary_key?: boolean;
  hidden?: boolean;
}

export enum FieldType {
  STRING,
  NUMBER,
  PRICE,
}
