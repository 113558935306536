import {NavigationPaths} from "../../../_shared/hooks/useNavigation/paths";
import {IProjectInfo} from "@natomas-org/core";
import {VILLA_MAP} from "../../../_shared/constants/labels";
import {navigateTo} from "../../../_shared/hooks/useNavigation/navigation";
import {formNewURL} from "../../../_shared/navigation/_helpers";
import {CATALOG_ROOT_PATH, STUDIO_PATH, SUMMARY_PATH} from "../../../../paths";
import {
  URL_PARAM_KEY_PAGE_ID,
  URL_PARAM_KEY_PRODUCT_GROUP_ID,
  URL_PARAM_KEY_UNIT_ID,
} from "@natomas-org/service";
import {HOME_PAGE_ID} from "../../../portal/_shared/navigation/constants";

export const getViewsDropdownOptions = (
  configuration: any,
  projectSummary: IProjectInfo | null | undefined,
  dispatch: any,
  to: any
) => {
  return [
    {
      id: "Dashboard",
      label: "Dashboard",
      callback: () => {
        const newUrl = formNewURL({
          pathName: SUMMARY_PATH,
          params: [{key: URL_PARAM_KEY_PAGE_ID, value: HOME_PAGE_ID}],
          useAbsoluteURL: true,
        });
        navigateTo(newUrl, {openInNewTab: true});
      },
    },
    {
      id: "Catalog",
      label: "Catalog",
      callback: () => {
        const newUrl = formNewURL({
          pathName: CATALOG_ROOT_PATH,
          useAbsoluteURL: true,
        });
        navigateTo(newUrl, {openInNewTab: true});
      },
    },
    {
      id: "Studio",
      label: "Studio",
      callback: () => {
        const url = formNewURL({
          pathName: STUDIO_PATH,
          params: [
            {
              key: URL_PARAM_KEY_PRODUCT_GROUP_ID,
              value: configuration?.product?.productGroupId,
            },
            {key: URL_PARAM_KEY_UNIT_ID, value: configuration?.product?.id},
          ],
          useAbsoluteURL: true,
        });
        navigateTo(url, {openInNewTab: true});
      },
    },
    {
      id: VILLA_MAP,
      label: `View in ${VILLA_MAP}`,
      callback: () => {
        to(NavigationPaths.PROPERTY_MAP, undefined, configuration?.product);
      },
    },
  ];
};
