import styled from "styled-components";
import {BLACK, GRAY, GRAY_BORDER} from "../../../../../_shared/colors";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_REGULAR,
} from "../../../SSDDashboardView/styles/globals";

export const CartSectionContainer = styled.div<{
  hasFrame: boolean;
  willExpand: boolean;
}>`
  ${(props) =>
    props.hasFrame &&
    `
    border: 1px solid ${GRAY_BORDER};
    border-radius: 10px;
  `};
  ${(props) =>
    props.willExpand &&
    `
    flex-grow: 1;
  `};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  height: fit-content;
`;

export const CartSectionSoftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CartItemSectionHeaderContainer = styled.div`
  align-items: baseline;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
`;

export const CartItemSectionHeader = styled.div`
  font-size: 21px;
  font-family: ${FONT_FAMILY_BOLD};
`;

export const CartItemSectionHeaderSubtext = styled.div`
  font-size: 16px;
  font-family: ${FONT_FAMILY_REGULAR};
  color: ${GRAY};
`;

export const CartItemSectionAction = styled.a`
  color: ${BLACK};
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  padding: 0;
  user-select: none;
  text-decoration: underline;
`;
