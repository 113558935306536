import styled from "styled-components";
import {
  ProductInfo,
  ProductInfoTitle,
} from "../../../../catalog/views/ProductView/styles";
import React from "react";
import {DIVIDER_COLOR} from "../../../colors";

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-top: 1px solid ${DIVIDER_COLOR};
  padding-top: 32px;
  margin-top: 32px;
`;
export const Section = (props: {
  label?: string;
  description?: string;
  children: any;
}) => {
  const {label, description, children} = props;
  return (
    <SectionContainer>
      {label && <ProductInfoTitle>{label}</ProductInfoTitle>}
      {description && <ProductInfo>{description}</ProductInfo>}
      {children}
    </SectionContainer>
  );
};
