import React, {CSSProperties, useCallback, useEffect, useMemo} from "react";
import {RiInformationLine} from "react-icons/ri";
import {
  DesktopRenderingContainer,
  DesktopStickyMenu,
  InformationIconContainer,
  ScrollableContainer,
  StudioContentContainer,
} from "./styles";
import {StudioImageManager} from "../StudioImageManager";
import {StudioCategoryShell} from "../StudioPage/StudioCategoryShell";
import {StudioSummaryShell} from "../StudioPage/StudioCategoryShell/StudioSummaryShell";
import {StudioState} from "../../../../_shared/slices/types/Store";
import {ISessionState} from "../../../../_shared/modals/RegistrationModal/RegistrationTypes";
import {
  setShowEditingConfirmation,
  setStudioSelection,
  setStudioStep,
} from "../../../../_shared/slices/StudioSlice";
import {useStudioSettings} from "../../../../_shared/hooks/useStudio/useStudioSettings";
import {useDispatch} from "react-redux";
import {
  IStudioFooterStep,
  SectionNavigation,
} from "../StudioController/SectionNavigation";
import {store} from "../../../../../store";
import {NatButton} from "../../../../_shared/generics/button";
import {NatLeftArrowIcon} from "../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../_shared/generics/_shared";
import {ProjectPriceMode} from "@natomas-org/core";

export interface StudioContentProps {
  stackedMode: boolean;
  formik: any;
  price: number;
  mode: StudioState;
  priceMode: ProjectPriceMode;
  height?: string;
  product?: any;
  section?: string;
  step?: number;
  structure?: any;
  eligibleCategories?: any[];
  summary?: any;
  options?: IStudioOptions;
  smartScroll?: any;
  atBottom?: boolean;
}

export interface IStudioOptions {
  headless?: boolean;
  backButtonOptions?: {
    title?: string;
    onClick?: () => void;
  };
  showRegistration?: (options: ISessionState) => void;
  hidePrice?: boolean;
  hideBack?: boolean;
  hideDisclaimer?: boolean;
  showDisclaimer?: () => void;
  stackedMode?: boolean;
}

export const StudioContent = (props: StudioContentProps) => {
  const {
    mode,
    product,
    summary,
    structure,
    eligibleCategories,
    section,
    stackedMode,
    height,
    formik,
    step,
    options,
    smartScroll,
    priceMode,
  } = props;
  const {isEditing} = useStudioSettings();
  const dispatch = useDispatch();

  let contentWrapper: CSSProperties = {};

  if (!stackedMode) {
    contentWrapper.width = "469px";
    contentWrapper.position = "absolute";
    contentWrapper.right = 0;
    contentWrapper.top =
      !options?.hideBack && !!options?.backButtonOptions ? `98px` : `43px`;
    contentWrapper.bottom = "0px";
    contentWrapper.overflow = "hidden auto";
  }

  useEffect(() => {
    if (stackedMode) {
      window.addEventListener("scroll", smartScroll, {passive: true});
    } else {
      window.removeEventListener("scroll", smartScroll);
    }
    return () => {
      window.removeEventListener("scroll", smartScroll);
    };
  }, [stackedMode, smartScroll]);

  const selectEvent = useCallback(
    (modifier: any, modifierGroup: any) => {
      // if (!loggedInUser && options?.showRegistration) {
      //   options?.showRegistration({
      //     visible: true,
      //     signUp: true,
      //     redirectType: INTAKE_FORM_REDIRECT_TYPE.STUDIO,
      //   });
      // } else
      if (!isEditing) {
        dispatch(setShowEditingConfirmation(true));
      } else {
        dispatch(
          setStudioSelection({
            modifier: modifier,
            modifierGroup: modifierGroup,
            isValidating: false,
          })
        );
      }
    },
    [dispatch, isEditing]
  );

  const footerSteps = useMemo(() => {
    return (
      eligibleCategories?.map((category: any): IStudioFooterStep => {
        return {id: category?.id ?? "", title: category?.title ?? ""};
      }) ?? []
    );
  }, [eligibleCategories]);

  const navigateStep = (step: number) => {
    console.log("StudioContent: navigate step", step);
    const doc = document.getElementById("category_step_container_" + step);
    doc?.scrollIntoView(true);
    store.dispatch(setStudioStep(step));
  };

  return (
    <StudioContentContainer height={height} stackedMode={stackedMode}>
      {stackedMode && (
        <StudioImageManager
          height={"30vh"}
          categories={eligibleCategories}
          product={product}
          isMobile={stackedMode}
          step={step}
          disclaimer={
            <InformationIconContainer
              data-tip
              data-for={"rendering_disclaimer"}
              onClick={options?.showDisclaimer ?? undefined}
              top={"calc(30vh - 36px)"}
              left={"12px"}
            >
              <RiInformationLine />
            </InformationIconContainer>
          }
        />
      )}
      {!stackedMode && (
        <>
          <DesktopRenderingContainer hidden={stackedMode}>
            <StudioImageManager
              height={"100%"}
              categories={eligibleCategories}
              product={product}
              isMobile={stackedMode}
              step={step}
            />
            <InformationIconContainer
              data-tip
              data-for={"rendering_disclaimer"}
              onClick={options?.showDisclaimer ?? undefined}
              top={"12px"}
              right={"12px"}
            >
              <RiInformationLine />
            </InformationIconContainer>
          </DesktopRenderingContainer>
          <DesktopStickyMenu
            hasBackButton={!options?.hideBack && !!options?.backButtonOptions}
          >
            <div
              style={{
                marginLeft: "28px",
                marginTop: "-4px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {!options?.hideBack && options?.backButtonOptions && (
                <NatButton
                  label={options?.backButtonOptions?.title ?? ""}
                  icon={{
                    icon: <NatLeftArrowIcon />,
                    iconPosition: IconPosition.LEFT,
                  }}
                  size={NatSize.SMALL}
                  option={StyleOption.PRIMARY_LINK}
                  clickEvent={options?.backButtonOptions?.onClick ?? null}
                />
              )}
            </div>
            <SectionNavigation
              steps={footerSteps}
              currentStepIndex={step ?? 0}
              hidden={false}
              navigateStep={navigateStep}
              productTitle={product?.title}
              productId={product?.id}
              factoryId={product?.productGroupId}
            />
          </DesktopStickyMenu>
        </>
      )}
      <ScrollableContainer
        style={contentWrapper}
        stackedMode={stackedMode}
        id={"main-design-content-wrapper"}
        onScroll={smartScroll}
      >
        {eligibleCategories?.map((category: any, index: number) => {
          return (
            <StudioCategoryShell
              mode={mode}
              structure={structure}
              product={product}
              category={category}
              formik={formik}
              index={index}
              isMobile={stackedMode}
              key={category.id}
              step={index}
              section={section}
              selectModifier={selectEvent}
              priceMode={priceMode}
            />
          );
        })}
        <StudioSummaryShell
          priceMode={priceMode}
          summary={summary}
          formik={formik}
          index={eligibleCategories?.length ?? 0}
          isMobile={stackedMode}
          key={"summary_section"}
          step={eligibleCategories?.length ?? 0}
        />
      </ScrollableContainer>
    </StudioContentContainer>
  );
};
