import firebase from "firebase";
import {tryProviders} from "./provider";

export const signInWithEmail = async (email: string, password: string) => {
  if (!email || !password) {
    throw new Error("Email and password are required");
  }
  const auth = firebase.auth();
  return auth
    .signInWithEmailAndPassword(email, password)
    .then((result) => {
      return result;
    })
    .catch(async (error) => {
      if (error.code === "auth/wrong-password") {
        // If the user has a provider, sign in with that provider
        const result = await tryProviders(email);
        if (result) {
          return result;
        } else {
          throw error;
        }
      } else {
        throw new Error(error.message);
      }
    });
};

export const signUpWithEmail = async (email: string, password: string) => {
  if (!email || !password) {
    throw new Error("Email and password are required");
  }
  const auth = firebase.auth();
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};
