import {
  INatDropdownStyleOptions,
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../constants";
import {
  NatPopupItemElement,
  NatPopupItemText,
  POPUP_ANIMATION_SPEED_MS,
} from "../../../components/NatPopup/NatPopup";
import React from "react";
import {
  NatomasPopupChevronContainer,
  NatomasPopupTitleContainer,
} from "../../../components/NatPopup/styles";
import {FiChevronDown} from "react-icons/fi";

export const PopupTitleElement = (props: {
  style: INatDropdownStyleOptions;
  contentItemArray: (NatPopupItemText | NatPopupItemElement)[];
  displayThin: boolean;
  itemCSS: React.CSSProperties;
  isMinimized: boolean;
}) => {
  const {style, isMinimized, displayThin, itemCSS, contentItemArray} = props;
  const firstArrayAsTitle = style.ADDITIONAL_STYLE_OPTIONS?.includes(
    NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE
  );
  if (contentItemArray?.length < 1 || !firstArrayAsTitle) {
    return null;
  }

  return (
    <NatomasPopupTitleContainer
      displayThin={displayThin}
      expanded={false}
      style={{
        cursor: "pointer",
        ...itemCSS,
      }}
    >
      {(contentItemArray[0] as NatPopupItemElement)?.component ??
        contentItemArray[0]?.label}
      <NatomasPopupChevronContainer
        expanded={isMinimized}
        animationSpeed={POPUP_ANIMATION_SPEED_MS * 0.25}
        hidden={style?.TYPE === NatPopupType.HIDE}
      >
        <FiChevronDown size={"1.5rem"} />
      </NatomasPopupChevronContainer>
    </NatomasPopupTitleContainer>
  );
};
