import {executeRequest} from "../APIExecutor";
import {SearchUsersRequest} from "@natomas-org/core";
import firebase from "firebase";

const assignedUsers = "/admin/v1/assigned-users";
const searchUsers = "/admin/v1/searchUsers";
const updateEmail = "/admin/v1/updateUserEmail";
const updatePassword = "/admin/v1/updateUserPassword";
const updateOpportunityLinks = "/admin/v1/updateOpportunityLinks";
// const refreshProjectMarginURI = "/admin/v1/refreshUnitMargin";
const getUser = "/admin/v1/getUserAuthInfo";
const migrateInventoryPath = "/admin/v1/migrateInventory";
const publishFactoryLinePath = "/admin/v1/publishFactoryLineDraft";
const firestoreExportPath = "/admin/v1/firestore-export";
const firestoreExportTransferPath = "/admin/v1/firestore-export/transfer";

export const searchUsersWithSearchText = (
  searchRequest: SearchUsersRequest
): Promise<string | null> => {
  return executeRequest(searchUsers, searchRequest)
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const findUsersForAdmin = (): Promise<string | null> => {
  return executeRequest(assignedUsers, {})
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const updateUserEmail = (uid: string, email: string) => {
  return executeRequest(updateEmail, {
    uid: uid,
    email: email,
  })
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const updateUserPassword = (uid: string, password: string) => {
  return executeRequest(updatePassword, {
    uid: uid,
    password: password,
  })
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getUserAuthInfo = (uid?: string, email?: string) => {
  return executeRequest(getUser, {
    uid: uid,
    email: email,
  })
    .then((r: any) => {
      if (r.error) {
        console.error(r.error);
        return null;
      }

      return r as firebase.UserInfo;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const updateProjectLinksInSalesforce = (uid: string, pid: string) => {
  return executeRequest(updateOpportunityLinks, {
    uid: uid,
    pid: pid,
  })
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

// export const refreshProjectMarginToSalesforce = (pid: string) => {
//   return executeRequest(refreshProjectMarginURI, {
//     projectId: pid,
//   })
//     .then((r: any) => {
//       return r;
//     })
//     .catch((e) => {
//       console.error(e);
//       return null;
//     });
// };

export const migrateInventory = () => {
  return executeRequest(migrateInventoryPath, {})
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const publishFactoryLineDraft = (id: string) => {
  return executeRequest(publishFactoryLinePath, {factoryLineId: id})
    .then((r: any) => {
      return r;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};
export const firestoreExport = async () => {
  try {
    console.log("firestoreExport");
    return await executeRequest(firestoreExportPath, {});
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const firestoreExportTransfer = async (
  bucket: string,
  folder: string
) => {
  try {
    console.log("firestoreExportTransfer");
    return await executeRequest(firestoreExportTransferPath, {
      bucket,
      folder,
    });
  } catch (e) {
    console.error(e);
    return null;
  }
};
