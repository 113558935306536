import {useMemo} from "react";
import {
  FactoryField,
  FactoryVersionField,
  IFactory,
  IFactoryVersion,
} from "../../slices/FactorySlice";
import {useCatalogFilteredProducts} from "./useCatalogSession/useCatalogFilteredProducts";
import {IProduct, ListingStatusDetails} from "@natomas-org/core";
import {useCatalogSession} from "./useCatalogSession/useCatalogSession";
import {isProductEligible} from "../useMappingFeatures/helper";
import {useAllFactories} from "./useFactorySlice";
import {getFactoryVersion} from "./useFactoryLine/helper";
import {ListingStatusFilter} from "./helper";
import {isArchived} from "../../../../database/firebase/catalog/fetching";

export const useAllPublishedAndRoutedProducts = (options?: {
  onlyFactory?: string;
  excludeInventory?: boolean;
}) => {
  const {eligibility, adminCatalogMode} = useCatalogSession();
  const lsf: ListingStatusFilter | undefined = useMemo(() => {
    if (adminCatalogMode) {
      return undefined;
    }
    return {
      [ListingStatusDetails.LISTED]: true,
    };
  }, [adminCatalogMode]);
  const {models, inventory} = useAllProducts({
    ...options,
    listingStatusFilter: lsf,
  });

  const _products: IProduct[] = useMemo(() => {
    if (options?.excludeInventory) {
      return models;
    } else {
      return [...models, ...inventory];
    }
  }, [models, inventory, options?.excludeInventory]);

  const allProducts: IProduct[] = useMemo(() => {
    return _products
      ?.filter((p: IProduct) => {
        if (!adminCatalogMode) {
          if (eligibility) {
            const factoryId = p?.productGroupId;
            const productId = p?.id;
            if (!isProductEligible(eligibility, factoryId, productId)) {
              return false;
            }
          }
          return true;
        }
        return true;
      })
      .flat();
  }, [_products, adminCatalogMode, eligibility]);

  return allProducts;
};

const usePublishedModelsFromFactories = (
  factories: IFactory[],
  options: {listingStatusFilter?: ListingStatusFilter}
): IProduct[] => {
  const {listingStatusFilter} = options;
  return useMemo(() => {
    return factories
      ?.map((factory: IFactory) => {
        const version: IFactoryVersion | undefined = getFactoryVersion(factory);
        if (version) {
          return Object.values(version[FactoryVersionField.PRODUCTS] ?? {});
        } else return [];
      })
      .flat()
      .filter((product: IProduct) => {
        if (isArchived(product)) {
          return false;
        }
        if (listingStatusFilter) {
          return listingStatusFilter[product.status];
        }
        return true;
      });
  }, [factories, listingStatusFilter]);
};

const useInventoryFromFactories = (
  factories: IFactory[],
  options: {listingStatusFilter?: ListingStatusFilter}
): IProduct[] => {
  const {listingStatusFilter} = options;
  return useMemo(() => {
    return factories
      ?.map((factory: IFactory) => {
        if (factory?.[FactoryField.INVENTORY]) {
          return Object.values(factory[FactoryField.INVENTORY] ?? {});
        } else return [];
      })
      .flat()
      .filter((product: IProduct) => {
        if (isArchived(product)) {
          return false;
        }
        if (listingStatusFilter) {
          return listingStatusFilter[product.status];
        }
        return true;
      });
  }, [factories, listingStatusFilter]);
};

export const useAllProducts = (options: {
  onlyFactory?: string;
  listingStatusFilter?: ListingStatusFilter;
}) => {
  const allFactories: IFactory[] = useAllFactories(options);
  const allModels = usePublishedModelsFromFactories(allFactories, options);
  const allInventory = useInventoryFromFactories(allFactories, options);
  return {
    models: allModels,
    inventory: allInventory,
    products: [...allModels, ...allInventory],
  };
};

export interface ICatalogProductsMetadata {
  lowestPrice: number | null;
  highestPrice: number | null;
  lowestSqFt: number;
  highestSqFt: number;
}
export interface ICatalogProductsSummary {
  unfilteredCatalogProducts: IProduct[];
  info: ICatalogProductsMetadata;
}

export const useUnfilteredCatalogProducts = (options?: {
  onlyFactory?: string;
  excludeInventory?: boolean;
}): ICatalogProductsSummary => {
  const unfilteredCatalogProducts = useAllPublishedAndRoutedProducts(options);

  const lowestPrice = unfilteredCatalogProducts?.reduce((p1, p2) => {
    return !p1 || (!!p2?.price?.price && p2?.price?.price < p1)
      ? p2.price?.price
      : p1;
  }, unfilteredCatalogProducts[0]?.price?.price);
  const highestPrice = unfilteredCatalogProducts?.reduce((p1, p2) => {
    return !p1 || (!!p2?.price?.price && p2?.price?.price > p1)
      ? p2.price?.price
      : p1;
  }, unfilteredCatalogProducts[0]?.price?.price);
  const lowestSqFt = unfilteredCatalogProducts?.reduce((p1, p2) => {
    return !p1 ||
      (!!p2?.productDetails?.squareFeet && p2?.productDetails?.squareFeet < p1)
      ? p2.productDetails?.squareFeet
      : p1;
  }, unfilteredCatalogProducts[0]?.productDetails?.squareFeet);
  const highestSqFt = unfilteredCatalogProducts?.reduce((p1, p2) => {
    return !p1 ||
      (!!p2?.productDetails?.squareFeet && p2?.productDetails?.squareFeet > p1)
      ? p2.productDetails?.squareFeet
      : p1;
  }, unfilteredCatalogProducts[0]?.productDetails?.squareFeet);

  // product?.productDetails?.squareFeet

  return {
    unfilteredCatalogProducts: unfilteredCatalogProducts,
    info: {
      lowestPrice: lowestPrice,
      highestPrice: highestPrice,
      lowestSqFt: lowestSqFt,
      highestSqFt: highestSqFt,
    },
  };
};
export const useCatalogProducts = (options?: {onlyFactory?: string}) => {
  const allProducts = useAllPublishedAndRoutedProducts(options);
  return useCatalogFilteredProducts(allProducts);
};
