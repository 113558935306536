import React, {Dispatch, SetStateAction} from "react";
import {StyleOption} from "../../../../../_shared/generics/_shared";
import {HOME_PAGE_ID} from "../../../../_shared/navigation/constants";
import {toPage} from "../../../../_shared/navigation/PageNavigation";
import {DashboardLink, WarningContainer} from "./styles";
import {NatModal} from "../../../../../_shared/generics/modal/NatModal";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";

export const ChangeModelModal = (props: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}) => {
  const {to} = useNavigation();

  const closeChangeModalModal = () => {
    props.setShow(false);
  };

  const changeModel = () => {
    props.setShow(false);
    to(NavigationPaths.CATALOG);
  };

  return (
    <NatModal
      show={props.show}
      handleClose={closeChangeModalModal}
      content={
        <WarningContainer>
          <div>
            Selecting a new home will reset your current design choices, and you
            will need to design your home again. If you would like some
            assistance, or have any questions, please reach out to us through
            <DashboardLink onClick={() => toPage(HOME_PAGE_ID)}>
              {" your dashboard"}
            </DashboardLink>
            .
          </div>
        </WarningContainer>
      }
      customSize={{
        width: "24rem",
        height: "20rem",
      }}
      header={"Not the right one?"}
      footerButtons={[
        {
          label: "Dismiss",
          trackingId: "your-unit-warning-dismiss",
          clickEvent: closeChangeModalModal,
          option: StyleOption.SECONDARY_ALT,
          type: "button",
        },
        {
          label: "Continue to Catalog",
          option: StyleOption.PRIMARY_ALT,
          clickEvent: changeModel,
          type: "button",
          trackingId: "your-unit-continue-to-catalog",
        },
      ]}
    />
  );
};
