import {useDispatch} from "react-redux";
import {useIPAddress} from "../components/_shared/hooks/useAddress/ip";
import {useEffect} from "react";
import {setIPAddress} from "../components/_shared/slices/AddressSlice";

export const AddressSliceManager = (): null => {
  const dispatch = useDispatch();
  const {data: ip} = useIPAddress();
  useEffect(() => {
    if (ip) {
      dispatch(setIPAddress(ip));
    }
  }, [dispatch, ip]);
  return null;
};
