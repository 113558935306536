import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ICartItem} from "@natomas-org/core";
import CartItemRow, {CartSectionItemProps} from "../CartSectionItem";
import useActivitySliceDispatcher from "../../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import {useNavigation} from "../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../_shared/hooks/useNavigation/paths";
import CartSectionWrapper from "./CartSectionWrapper";
import CartSectionHeader from "./CartSectionHeader";
import {duplicateCartItemToCartLibrary} from "../../../../../../database/firebase/api/cart";
import useActivitySlice from "../../../../../_shared/hooks/useActivity/useActivitySlice";
import CartSectionByUnits from "./CartSectionByUnits";
import {shouldShowDivider, sortCartItemsById} from "../helper";
import {
  CartBasicSectionProps,
  CartSectionActions,
  CartSectionSortBy,
} from "../../interfaces";
import {Divider} from "../../../../../_shared/generics/divider";
import {BLACK} from "../../../../../_shared/colors";

const CartSection = (props: CartBasicSectionProps) => {
  const {
    isMobile,
    actionsAvailable,
    label,
    cartItems,
    move,
    previewItem,
    editDisplayCodeItem,
    sortBy,
  } = props;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const {setActiveCartItemById} = useActivitySliceDispatcher();
  const {activeCustomerId, activeProjectId} = useActivitySlice();
  const {to} = useNavigation();

  useEffect(() => {
    if (isMobile && selectedItems?.length > 0) {
      setSelectedItems([]);
    }
  }, [setSelectedItems, isMobile, selectedItems?.length]);

  const copyCartItem = useCallback(
    (cartItemId: string) => {
      if (!activeCustomerId || !activeProjectId) return;
      duplicateCartItemToCartLibrary(
        activeCustomerId,
        activeProjectId,
        cartItemId
      );
    },
    [activeCustomerId, activeProjectId]
  );

  const actions: CartSectionActions = useMemo(() => {
    const areActionsAvailable: boolean = cartItems?.length > 0;
    const hasSelectedItems: boolean = selectedItems.length > 0;
    const areAllItemsSelected: boolean =
      selectedItems.length === cartItems?.length;
    return {
      areActionsAvailable,
      actionsAvailable,
      hasSelectedItems,
      areAllItemsSelected,
      select: {
        show: !isMobile && actionsAvailable.move && areActionsAvailable,
        label: areAllItemsSelected ? "Deselect All" : "Select All",
        event: areAllItemsSelected
          ? () => {
              setSelectedItems([]);
            }
          : () => {
              setSelectedItems(
                cartItems?.map((item: ICartItem) => item.id) ?? []
              );
            },
      },
      move: {
        show:
          (actionsAvailable.move && areActionsAvailable && hasSelectedItems) ??
          false,
        label: `${move?.label} (${selectedItems.length})`,
        event: () => {
          move?.multipleEvent(selectedItems);
        },
      },
      add: {
        show: !isMobile && actionsAvailable.add,
        label: "Add Unit",
        event: () => {
          to(NavigationPaths.CATALOG);
          setActiveCartItemById(undefined);
        },
      },
    };
  }, [
    actionsAvailable,
    cartItems,
    isMobile,
    move,
    selectedItems,
    setActiveCartItemById,
    to,
  ]);

  const getCartItemRow: (item: ICartItem) => CartSectionItemProps = useCallback(
    (item: ICartItem): CartSectionItemProps => {
      const cartItemId = item.id;
      const currentlySelected = selectedItems.includes(cartItemId);
      const showIndividualActions =
        actions.areActionsAvailable && !actions.hasSelectedItems;
      const showAdminActions =
        showIndividualActions && !actions.hasSelectedItems;
      return {
        cartItem: item,
        select: {
          show:
            !isMobile && actionsAvailable.move && actions.areActionsAvailable,
          isSelected: currentlySelected,
          event: currentlySelected
            ? () =>
                setSelectedItems((current: string[]) =>
                  current.filter((id: string) => id !== cartItemId)
                )
            : () =>
                setSelectedItems((current: string[]) => [
                  ...current,
                  cartItemId,
                ]),
        },
        move: {
          show: actionsAvailable.move && showIndividualActions,
          label: move?.label ?? "",
          color: move?.color ?? BLACK,
          event: () => {
            move?.singleEvent(cartItemId);
          },
        },
        editUnit: {
          show: actionsAvailable.edit && showIndividualActions,
          label: "Change Unit",
          event: () => {
            setActiveCartItemById(cartItemId);
            to(NavigationPaths.CATALOG);
          },
        },
        editDesign: {
          show: actionsAvailable.edit,
          label: "View/Edit Design",
          event: () => {
            setActiveCartItemById(cartItemId);
            to(NavigationPaths.STUDIO, {cartItemId: cartItemId});
          },
        },
        previewItem:
          previewItem ??
          ((i: ICartItem) => {
            console.log(i);
          }),
        editDisplayCodeItem:
          editDisplayCodeItem ??
          ((i: ICartItem) => {
            console.log(i);
          }),
        copyUnit: {
          show: actionsAvailable.copy && showIndividualActions,
          label: "Duplicate to Drafts",
          event: () => copyCartItem(cartItemId),
        },
        setQuantity: {
          // If you can move, you can set quantity
          show: actionsAvailable.quantity && showAdminActions,
        },
      };
    },
    [
      selectedItems,
      actions.areActionsAvailable,
      actions.hasSelectedItems,
      isMobile,
      actionsAvailable.move,
      actionsAvailable.edit,
      actionsAvailable.copy,
      actionsAvailable.quantity,
      move,
      previewItem,
      editDisplayCodeItem,
      setActiveCartItemById,
      to,
      copyCartItem,
    ]
  );

  const CartItemContainer: JSX.Element | null = useMemo(() => {
    if (sortBy === CartSectionSortBy.LIST) {
      return (
        <>
          {sortCartItemsById(cartItems)?.map(
            (cartItem: ICartItem, index: number) => {
              return (
                <>
                  {shouldShowDivider(index, cartItems.length) && <Divider />}
                  <CartItemRow
                    {...getCartItemRow(cartItem)}
                    isMobile={isMobile}
                    key={`cart-${label}-item-${index}`}
                  />
                </>
              );
            }
          )}
        </>
      );
    } else if (sortBy === CartSectionSortBy.UNIT) {
      return (
        <CartSectionByUnits
          cartItems={cartItems}
          getCartItemRow={getCartItemRow}
          isMobile={isMobile}
        />
      );
    } else {
      return null;
    }
  }, [sortBy, cartItems, getCartItemRow, label, isMobile]);

  if (CartItemContainer === null) {
    return null;
  }

  return (
    <CartSectionWrapper willExpand={true} hasFrame={!!label}>
      <CartSectionHeader
        label={label}
        count={cartItems?.length}
        actions={actions}
        setSelectedCartItemIds={!isMobile ? setSelectedItems : undefined}
      />
      <Divider />
      {CartItemContainer}
    </CartSectionWrapper>
  );
};

export default CartSection;
