import styled from "styled-components";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";
import {ERROR_COLOR, GRANITE, GRAY} from "../../colors";
import {NatSize} from "../../generics/_shared";

export const ModalContentContainer = styled.div<{isMobile?: boolean}>`
  padding: ${(props) => (props.isMobile ? "1rem 1rem" : "1rem")};
  font-size: 0.8rem;
  width: 100%;
`;

export const RegistrationFormLayout = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: inherit;

  & > div {
    width: 100%;
  }
`;

export const FormSubtitleText = styled.form<{isMobile: boolean}>`
  font-family: ${FONT_FAMILY_REGULAR};
  font-size: ${(props) => (props.isMobile ? NatSize.SMALL : "0.775rem")};
  color: ${GRANITE};
  padding-bottom: 1rem;
  text-align: left;
`;

export const RegistrationContentContainer = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  max-height: 100vh;
  align-content: center;
  align-items: center;
  width: 100%;
  overflow-y: auto;
  padding-top: 1rem;
  justify-content: space-between;
  padding-bottom: 0.25rem;
`;

export const NatFormErrorMessage = styled.div`
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${ERROR_COLOR};
  gap: 4px;
`;

export const NatFormMessage = styled.div`
  min-height: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${GRAY};
  gap: 4px;
`;

export const SubmissionSection = styled.div<{sticky: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  position: ${(props) => (props.sticky ? "sticky" : "relative")};
  bottom: 0;
  background-color: white;
  padding: 1rem 0;
  // border-top: 1px solid \${GRANITE};
`;

export const SubmissionStatus = styled.div`
  margin-top: 0;
  font-size: 0.75rem;
  font-family: ${FONT_FAMILY_MEDIUM};
`;
