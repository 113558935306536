import {IProduct} from "@natomas-org/core";
import {
  FactoryInventoryCatalogEditorKey,
  IFactoryInventoryCatalogEditor,
} from "./interfaces";
import {INVENTORY_UNIT_PREFIX} from "../../../../../../../../../_shared/constants/labels";

export const flattenProductToCatalogEditor = (
  p?: IProduct
): IFactoryInventoryCatalogEditor => {
  return {
    [FactoryInventoryCatalogEditorKey.TITLE]:
      p?.title?.replace(INVENTORY_UNIT_PREFIX, "").trim() ?? "",
    [FactoryInventoryCatalogEditorKey.BASE_DESC]: p?.displayDescription ?? "",
    [FactoryInventoryCatalogEditorKey.INTERIOR_DESC]:
      p?.interiorDescription ?? "",
    [FactoryInventoryCatalogEditorKey.EXTERIOR_DESC]:
      p?.exteriorDescription ?? "",
    [FactoryInventoryCatalogEditorKey.SQUARE_FOOTAGE]:
      p?.productDetails?.squareFeet ?? 0,
    [FactoryInventoryCatalogEditorKey.BATHROOM_COUNT]:
      p?.productDetails?.bathrooms ?? 0,
    [FactoryInventoryCatalogEditorKey.BEDROOM_COUNT]:
      p?.productDetails?.bedrooms ?? 0,
    [FactoryInventoryCatalogEditorKey.IMAGES]: p?.images ?? [],
    [FactoryInventoryCatalogEditorKey.DEFAULT_IMAGE_ID]:
      p?.imageId ?? undefined,
    [FactoryInventoryCatalogEditorKey.DIMENSIONS]:
      p?.productDetails?.dimensions ?? undefined,
    [FactoryInventoryCatalogEditorKey.TIMELINE]: p?.timeline ?? [],
    [FactoryInventoryCatalogEditorKey.TAGLINE]: p?.tagline ?? "",
  };
};
