import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {
  setActiveCartItemId,
  setActiveCustomerId,
  setActiveProjectId,
} from "../../slices/ActivitySlice/ActivitySlice";
import {replaceURLQueryKeyValue} from "../../navigation/_helpers";
import {
  URL_PARAM_KEY_CART_ITEM_ID,
  URL_PARAM_KEY_PROJECT_ID,
  URL_PARAM_KEY_USER_ID,
} from "@natomas-org/service";

const useActivitySliceDispatcher = () => {
  const dispatch = useDispatch();

  const setActiveCustomerById: (id?: string) => void = useCallback(
    (id?: string) => {
      dispatch(setActiveCustomerId(id));
      replaceURLQueryKeyValue(URL_PARAM_KEY_USER_ID, id ?? null);
    },
    [dispatch]
  );

  const setActiveProjectById: (id?: string) => void = useCallback(
    (id?: string) => {
      dispatch(setActiveProjectId(id));
      replaceURLQueryKeyValue(URL_PARAM_KEY_PROJECT_ID, id ?? null);
    },
    [dispatch]
  );

  const setActiveCartItemById: (id?: string) => void = useCallback(
    (id?: string) => {
      dispatch(setActiveCartItemId(id));
      replaceURLQueryKeyValue(URL_PARAM_KEY_CART_ITEM_ID, id ?? null);
    },
    [dispatch]
  );

  return {
    setActiveCustomerById,
    setActiveProjectById,
    setActiveCartItemById,
  };
};

export default useActivitySliceDispatcher;
