import React from "react";
import {useParams} from "react-router-dom";
import Page from "./page";
import {useAllProducts} from "../../../components/_shared/hooks/useProductCatalog/useProducts";
import {ListingStatusDetails} from "@natomas-org/core";

const AdminProductInstantQuotePage = () => {
  const {products} = useAllProducts({
    listingStatusFilter: {
      [ListingStatusDetails.LISTED]: true,
      [ListingStatusDetails.UNLISTED]: true,
    },
  });

  const {productId} = useParams<{
    productId: string;
  }>();

  const product = products.find((product) => product.id === productId);

  return (
    <div
      className={"vcl-container-primary-16 vcl-border-primary"}
      style={{margin: "16px auto", maxWidth: "1000px"}}
    >
      <Page product={product} />
    </div>
  );
};

export default AdminProductInstantQuotePage;
