import React from "react";
import {IProduct, ListingStatus, ListingStatusDetails} from "@natomas-org/core";
import {useProduct} from "../../../components/_shared/hooks/useProductCatalog/useProduct";
import {TableData, TableRow} from "./styles";
import {WARNING_FILL, WHITE} from "../../../components/_shared/colors";
import {ListingStatusTag} from "../../../components/admin/ProductCatalogView/tabs/FactoriesTab/_shared/ListingStatusTag";
import useLocationsByProductId from "../../../api/Admin/InstantQuote/useAdminLocationsByProductId";

interface IRow {
  product: IProduct;
  index: number;
  onRowClick: (productId: string) => void;
}
const Row = (props: IRow) => {
  const {product, onRowClick} = props;
  const {factoryDetails} = useProduct(product.id, product.productGroupId);
  const {data: locations} = useLocationsByProductId(product.id);

  const openInNewTab = () => {
    window.open(`/admin/product/${product.id}/instant-quote`, "_blank");
  };

  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    switch (event.button) {
      case 1:
        // Middle click
        openInNewTab();
        break;
      case 2:
        // Right click
        openInNewTab();
        break;
      default:
        // Other buttons (left click, etc.)
        break;
    }
  };

  return (
    <TableRow
      key={product.id}
      onClick={() => onRowClick(product.id ?? "")}
      onMouseDown={handleMouseDown}
    >
      <TableData
        backgroundColor={locations?.length === 0 ? WARNING_FILL : WHITE}
      >
        {locations?.length ?? 0}
      </TableData>
      <TableData>
        {product.title}{" "}
        <ListingStatusTag
          status={ListingStatus.convertDBListingStatus(product.status)}
        />
      </TableData>
      <TableData>
        {factoryDetails?.internal_title}{" "}
        <ListingStatusTag
          status={factoryDetails?.status ?? ListingStatusDetails.NULL}
        />
      </TableData>
      <TableData>{product.id}</TableData>
    </TableRow>
  );
};

export default Row;
