import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {
  getIntegerValue,
  getPriceMicrosFromText,
  getTextFromPriceMicros,
  isBlankString,
} from "@natomas-org/core";
import {readDeepValue, setDeepValue} from "../../../../_shared/Utils";

import {
  getFormInitialValues,
  getFormStructure,
} from "../../../../_shared/application";
import {
  AdminForm,
  MainContent,
  MainView,
} from "../../../_shared/styles/AdminStyles";
import {AdminInput} from "../../../_shared/components/AdminComponentFactory/AdminComponentFactory";
import {AdminTabHeader} from "../../components/Header/AdminTabHeader";
import {isDeepEqual} from "../../logic/AdminEditorHelper";
import {
  AdditionalFactoryLineStructure,
  FactoryLineStructure,
  getAvailableCounties,
} from "../../../_models/factoryLine";
import {NatDropdown} from "../../../../_shared/generics/popup/dropdown/NatDropdown";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {
  DropdownStyles,
  NatDropdownStyles,
} from "../../../../_shared/generics/popup/dropdown/constants";
import {NatSizeType} from "../../../../_shared/generics/_shared";

export const getBlankItemSection = (items: any) => {
  return {
    initial: {
      items: items,
      index: 0,
      title: "General",
    },
  };
};

const productStructureForForm = getFormStructure(FactoryLineStructure);

const getInitialValues = (obj: any) => {
  const structure = FactoryLineStructure;
  const initialValues = getFormInitialValues(structure);
  if (obj != null) {
    for (const key in structure) {
      // @ts-ignore
      const keyInfo = structure[key];
      let initialValue = obj[key];
      if (keyInfo.path != null) {
        initialValue = readDeepValue(obj, keyInfo.path);
      }

      if (initialValue != null) {
        if (keyInfo.subType === "price") {
          initialValue = getTextFromPriceMicros(initialValue);
        } else if (typeof initialValue === "number") {
          initialValue = "" + initialValue;
        }
      }

      if (initialValue != null) {
        // @ts-ignore
        initialValues[key] = initialValue;
      }
    }
  }

  return initialValues;
};

export const EditProductGroup = () => {
  const {updateFactoryLine, editingEnabled, factoryLine} =
    useAdminProductCatalog();
  const {details} = factoryLine ?? {};
  const tabs = [
    {id: "details", title: "General"},
    {id: "advanced", title: "Advanced Details"},
  ];
  const [tab, setTab] = useState(tabs[0].id);

  const initialValues = getInitialValues(details);
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      let valueToSave = {};
      for (const key in values) {
        // @ts-ignore
        let value = values[key];
        // @ts-ignore
        const keyInfo = FactoryLineStructure[key];
        if (keyInfo.subType === "price") {
          value = getPriceMicrosFromText(value);
        } else if (keyInfo.type === "number") {
          if (keyInfo.subType === "decimal") {
            value = parseFloat(value);
          } else {
            value = getIntegerValue(value);
          }

          if (isNaN(value)) {
            value = null;
          }
        }

        if (keyInfo.path != null) {
          setDeepValue(valueToSave, keyInfo.path, value);
        } else {
          // @ts-ignore
          valueToSave[key] = value;
        }
      }

      // @ts-ignore
      if (isBlankString(valueToSave?.imageId)) {
        // @ts-ignore
        valueToSave["imageId"] = null;
      }

      updateFactoryLine(valueToSave);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues(initialValues);
  }, [details]);

  if (details == null) {
    return null;
  }

  let formStructure = productStructureForForm.filter((keyInfo) => {
    // @ts-ignore
    return AdditionalFactoryLineStructure[keyInfo.key] == null;
  });

  if (tab === "advanced") {
    formStructure = productStructureForForm.filter((keyInfo) => {
      // @ts-ignore
      return AdditionalFactoryLineStructure[keyInfo.key] != null;
    });
  }

  const viewInCatalog = () => {
    window.open(window.location.origin + "/products?gid=" + details?.id);
  };

  const viewInDesignStudio = () => {
    window.open(window.location.origin + "/design?gid=" + details?.id);
  };

  const content = (
    <AdminForm onSubmit={formik.handleSubmit}>
      {formStructure.map((keyInfo) => {
        const {key} = keyInfo;
        // @ts-ignore
        const value = formik.values[key];
        if (key === "configuratorPages") {
          return null;
        }
        if (key === "counties") {
          // @ts-ignore
          if (formik.values["states"]?.length === 0) {
            // @ts-ignore
            formik.values["counties"] = [];
            return null;
          } else {
            // @ts-ignore
            let sts = formik.values["states"];
            keyInfo.options = getAvailableCounties(sts);
          }
        }

        // @ts-ignore
        let initial = initialValues[key];

        return (
          <AdminInput
            key={key}
            value={value}
            fieldInfo={keyInfo}
            initialValue={initial}
            disabled={!keyInfo?.alwaysEnabled && !editingEnabled}
            onChange={(value: any) => {
              formik.setFieldValue(key, value);
            }}
          />
        );
      })}
    </AdminForm>
  );
  return (
    <MainView>
      <AdminTabHeader
        tabs={tabs}
        setTab={setTab}
        isUpToDate={isDeepEqual(formik.values, initialValues)}
        discardAction={() => {
          formik.resetForm();
          formik.setValues(initialValues);
        }}
        additionalActions={
          <NatDropdown
            style={NatDropdownStyles[DropdownStyles.PRIMARY][NatSizeType.SMALL]}
            toggleButtonOrLabel={"Views"}
            contentItemArray={[
              {id: "catalog", label: "Catalog", callback: viewInCatalog},
              {id: "studio", label: "Studio", callback: viewInDesignStudio},
            ]}
            trackingId={"admin-tab-options"}
          />
        }
        saveAction={formik.handleSubmit}
      />
      <MainContent>{content}</MainContent>
    </MainView>
  );
};
