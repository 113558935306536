import {
  fullPriceTextFromMicros,
  IPublicProjectInfo,
  Product,
} from "@natomas-org/core";
import {PublicProjectInfo} from "@natomas-org/service";
import React from "react";
import {
  resetGLMapProjectToPreview,
  setGLMapProjectToPreview,
} from "../../../../map/slices/mapSlice";
import {Card, CardValues} from "../ProductCard/ProductCard";
import {store} from "../../../../../store";
import {
  NO_PRICE_PLACEHOLDER,
  useDisplayCompletedProjectsPrice,
} from "../../../../_shared/hooks/useCompletedProjects";

function getCardValuesForProject<T extends IPublicProjectInfo>(
  {...project}: T,
  onClick: () => void,
  setProjectOnOver?: boolean,
  showPrice?: boolean
): CardValues | null {
  const completionYear = PublicProjectInfo.getCompletionYear(project);
  const totalPriceMicros = PublicProjectInfo.getTotalPrice(project);
  const displayPrice = !!totalPriceMicros && showPrice;
  const totalPriceString = displayPrice
    ? fullPriceTextFromMicros(totalPriceMicros ?? 0, {
        roundToInteger: true,
      })
    : NO_PRICE_PLACEHOLDER;
  const title = PublicProjectInfo.getProjectTitle(project);
  const product = PublicProjectInfo.getProduct(project);

  if (!project?.imageIds || !completionYear) {
    return null;
  }

  return {
    title: title ?? "",
    priceString: totalPriceString,
    priceSubtext: displayPrice ? "project total" : "",
    subtextLine1: product && Product.getBedBathSqFtString(product),
    subtextLine2: completionYear.toString(),
    primaryImageId: project?.imageIds?.[0] ?? null,
    hoverImageId: project?.imageIds?.[1] ?? null,
    clickAction: onClick,
    labels: [],
    onMouseEnter: setProjectOnOver
      ? () => {
          store.dispatch(setGLMapProjectToPreview(project));
        }
      : undefined,
    onMouseLeave: setProjectOnOver
      ? () => {
          store.dispatch(resetGLMapProjectToPreview());
        }
      : undefined,
  };
}

export const ProjectCard = (props: {
  project: IPublicProjectInfo;
  onClick: () => void;
  setStateOnHover?: boolean;
}) => {
  const displayCompletedProjectsPrice = useDisplayCompletedProjectsPrice();
  const values = getCardValuesForProject(
    props.project,
    props.onClick,
    props.setStateOnHover,
    displayCompletedProjectsPrice
  );
  return values ? <Card cardValues={values} height={"15rem"} /> : null;
};
