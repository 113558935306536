import styled from "styled-components";
import {WHITE} from "../_shared/colors";

export const StudioWrapper = styled.div<{overflowHidden: boolean}>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: ${(props) => (props.overflowHidden ? "" : "hidden")};
  background-color: ${WHITE};
`;

export const StudioRootContainer = styled.div`
  max-width: 100vw;
  position: relative;
  overflow: hidden;
`;
