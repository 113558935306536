import React, {useCallback, useMemo} from "react";
import {
  DesignSelectionsCarousel,
  DesignSelectionsCarouselWrapper,
  DesignSelectionsPagePrice,
  DesignSelectionsPageTitle,
  DesignSelectionsSlideInfo,
} from "./styles";
import {getUpgradesList} from "../../../../../_shared/snapshot";
import {PageDetailsDropdown} from "./PageDetailsDropdown";
import {PageElement} from "../../../../../_shared/generics/page/components/PageElement";
import {PageContainer} from "../../../../../_shared/generics/page/components/PageContainer";
import {useDynamicValue} from "../../../../../_shared/hooks/useDynamicValue";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {NatSwiper} from "../../../../../_shared/generics/swiper";
import {StudioSectionButton} from "./StudioSectionButton";
import {DesignCTABlurb} from "./DesignCTABlurb";
import {StudioCarousel} from "../../../../../studio/_shared/components/StudioCarousel";
import useActiveProject from "../../../../../_shared/hooks/useProject/useActiveProject";
import useDesign from "../../../../../_shared/hooks/useDesign/useDesign";
import {
  getAllSelectedModifiersFromSnapshot,
  getRenderings,
} from "@natomas-org/core";

export const DesignSelections = React.memo(
  (props: {width: number; designId: string; cartItemId: string}) => {
    const {width} = props;
    const {pageWidth, isNatMobile} = usePage();
    const {configuration, snapshot} = useDesign(props.designId);
    const {projectPriceMode, isProjectLocked} = useActiveProject();
    const {product} = configuration ?? {};

    const carouselSize: number =
      pageWidth *
      useDynamicValue({
        forFour: 1,
        forEight: 0.6,
        forTwelve: 0.675,
        forSixteen: 0.75,
      });

    const allSelectedModifiers = useMemo(() => {
      if (!snapshot) {
        return [];
      }
      const allSelectedModifiersArray = getAllSelectedModifiersFromSnapshot(
        snapshot?.snapshot
      );
      const allSelectedModifiersObject = {};
      allSelectedModifiersArray.forEach((modifier: any) => {
        // @ts-ignore
        allSelectedModifiersObject[modifier.id] = modifier;
      });
      return allSelectedModifiersObject;
    }, [snapshot]);

    const getImages = useCallback(
      (index: number) => {
        if (!snapshot?.snapshot?.[index]) {
          return [];
        }
        const images = getRenderings(
          null,
          snapshot?.snapshot?.[index],
          allSelectedModifiers,
          snapshot?.snapshot?.[index]?.selectedModifierGroups
        ).images;
        if (images?.length === 0) {
          return [
            {
              imageId: product?.imageId,
            },
          ];
        } else {
          return images;
        }
      },
      [allSelectedModifiers, snapshot?.snapshot, product?.imageId]
    );

    const upgradeList = useMemo(() => {
      return getUpgradesList(snapshot, projectPriceMode, true);
    }, [projectPriceMode, snapshot]);

    const slides = useMemo(() => {
      return upgradeList?.map((item, index: number) => {
        return {
          width: pageWidth + "px",
          content: (
            <DesignSelectionsCarousel
              carouselSize={carouselSize}
              width={pageWidth && false ? `${pageWidth}px` : undefined}
              columnMode={isNatMobile}
            >
              <DesignSelectionsCarouselWrapper>
                <StudioCarousel
                  images={getImages(index)}
                  animate={true}
                  mobileVersion={false}
                  alwaysHideCaptions={true}
                />
              </DesignSelectionsCarouselWrapper>
              <DesignSelectionsSlideInfo
                width={!isNatMobile ? pageWidth - carouselSize : pageWidth}
              >
                <DesignSelectionsPageTitle>
                  {item.title}
                  {!isProjectLocked && (
                    <StudioSectionButton
                      step={index}
                      cartItemId={props.cartItemId}
                    />
                  )}
                </DesignSelectionsPageTitle>
                <DesignSelectionsPagePrice>
                  {item.priceText + " in upgrades"}
                </DesignSelectionsPagePrice>
                <PageDetailsDropdown
                  alwaysExpanded={false}
                  details={item.alternativePoints ?? []}
                />
              </DesignSelectionsSlideInfo>
            </DesignSelectionsCarousel>
          ),
        };
      });
    }, [
      carouselSize,
      isProjectLocked,
      getImages,
      isNatMobile,
      pageWidth,
      props.cartItemId,
      upgradeList,
    ]);

    if (!product) {
      return null;
    } else if (!snapshot) {
      return (
        <>
          <br />
          <DesignCTABlurb cartItemId={props.cartItemId} />
          <br />
        </>
      );
    } else {
      return (
        <>
          <PageContainer overridePadding={true}>
            <PageElement size={width} height={"auto"}>
              <NatSwiper
                autoFormatSlides={true}
                slides={slides}
                navigation={true}
                spaceBetween={32}
                loopSlides={false}
                alternativeNavigationButtons={true}
              />
            </PageElement>
          </PageContainer>
        </>
      );
    }
  }
);
