import {NatButton} from "../button";
import {IconPosition, NatSize, StyleOption} from "../_shared";
import {NatDatabaseIcon} from "../../icon/icons";
import React from "react";
import {ADMIN_FACTORY_LINE_INFO_PATH} from "../../../../paths";

export const OpenFactoryInfoSetButton = (props: {factoryId: string}) => {
  const {factoryId} = props;
  if (!factoryId) {
    return null;
  }
  return (
    <NatButton
      label={"factory-info-set"}
      option={StyleOption.ADMIN_SOLID}
      size={NatSize.SMALL}
      icon={{icon: <NatDatabaseIcon />, iconPosition: IconPosition.ONLY}}
      clickEvent={() => {
        window.open(
          window.location.origin +
            ADMIN_FACTORY_LINE_INFO_PATH +
            "?gid=" +
            factoryId
        );
      }}
    />
  );
};
