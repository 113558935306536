import {ButtonProps} from "./interface";
import Button from "./index";
import React from "react";

export const Buttons = (props: {buttons: ButtonProps[]}) => {
  const {buttons} = props;
  return (
    <>
      {buttons.map((input, index) => (
        <Button {...input} key={index} />
      ))}
    </>
  );
};
