import React, {useMemo} from "react";
import {Address, Customer, IAddressDetails} from "@natomas-org/core";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../../_shared/generics/path/styles";
import {CustomerDatabaseBreadcrumbContainer} from "./styles";
import {AdminHelpIcon} from "../../../_shared/components/AdminHelpIcon";
import {DOC_LINK_ROOT} from "../../../_shared/constants/links";
import {NavigationPaths} from "../../../../_shared/hooks/useNavigation/paths";
import {useNavigation} from "../../../../_shared/hooks/useNavigation";
import {useProject} from "../../../../_shared/hooks/useProject/useProject";
import {useCartItems} from "../../../../_shared/hooks/useCart/useCartItem/useCartItems";
import {useDesigns} from "../../../../_shared/hooks/useDesign/useDesigns";
import useActivitySliceDispatcher from "../../../../_shared/hooks/useCustomer/useActivitySliceDispatcher";
import useActivitySlice from "../../../../_shared/hooks/useActivity/useActivitySlice";

export const CustomerDatabasePath = (props: {
  customer: any;
  address: IAddressDetails;
}) => {
  const {customer, address} = props;
  const {to} = useNavigation();
  const {activeCustomerId: uid, activeProjectId: pid} = useActivitySlice();
  const {setActiveProjectById, setActiveCustomerById} =
    useActivitySliceDispatcher();
  const project = useProject(pid ?? undefined);
  const cartItems = useCartItems(project?.cartItemIds);
  const designIds = useMemo(() => {
    const toReturn: string[] = [];
    cartItems?.forEach((cartItem) => {
      if (cartItem.details?.configurationId) {
        toReturn.push(cartItem.details?.configurationId);
      }
    });
    return toReturn;
  }, [cartItems]);
  const designs = useDesigns(designIds);
  const unitDescription = useMemo(() => {
    if (cartItems) {
      return ` [${cartItems
        .map((cartItem) => {
          let unitTitle: string | undefined;
          let quantity: string | undefined;
          if (designs && cartItem.id && designs[cartItem.id]) {
            unitTitle = designs[cartItem.id].configuration?.product?.title;
          }
          if (unitTitle) {
            unitTitle = `: ${unitTitle}`;
          } else {
            unitTitle = "";
          }
          if (project.isCartEnabled && cartItem.quantity) {
            quantity = ` x${cartItem.quantity}`;
          } else {
            quantity = "";
          }
          return `${cartItem.details?.display_code}${unitTitle}${quantity}`;
        })
        .join(", ")}]`;
    }
    return "";
  }, [cartItems, designs, project.isCartEnabled]);

  return (
    <CustomerDatabaseBreadcrumbContainer>
      <div>
        <Breadcrumb isMobile={false} separator={"/"}>
          <BreadcrumbItem
            id={"breadcrumb-admin"}
            clickable={true}
            onClick={() => {
              to(NavigationPaths.ADMIN);
            }}
          >
            Admin
          </BreadcrumbItem>
          <BreadcrumbItem
            id={"breadcrumb-database"}
            clickable={true}
            onClick={() => {
              setActiveCustomerById(undefined);
              setActiveProjectById(undefined);
              to(NavigationPaths.ADMIN_CUSTOMER_DATABASE);
            }}
          >
            Customer Database
          </BreadcrumbItem>
          {uid && customer && (
            <BreadcrumbItem
              id={"breadcrumb-customer-name"}
              clickable={true}
              onClick={() => {
                setActiveProjectById(undefined);
              }}
            >
              {Customer.getFullName(customer) ?? "Unknown"}
            </BreadcrumbItem>
          )}
          {pid && address && (
            <>
              <BreadcrumbItem
                id={"breadcrumb-customer-name"}
                clickable={true}
                onClick={() => {}}
              >
                {truncateString(
                  `${Address.getStreetAndCity(address)}${unitDescription}`,
                  100
                )}
              </BreadcrumbItem>
            </>
          )}
        </Breadcrumb>
      </div>
      <AdminHelpIcon link={DOC_LINK_ROOT} />
    </CustomerDatabaseBreadcrumbContainer>
  );
};

function truncateString(input: string, maxLength: number): string {
  // Check if the string is longer than maxLength characters
  if (input.length > maxLength) {
    // Truncate the string to maxLength characters and append "..."
    return input.substring(0, maxLength - 3) + "...";
  }
  // If the string is maxLength characters or shorter, return it as is
  return input;
}
