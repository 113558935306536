import React from "react";
import {SitePlanView} from "../../../../../../portal/views/FPDashboardLayout/FPSiteMapView/SitePlanView";
import {CustomerProjectViewContentContainer} from "../styles";

export const SitePlanManagerTab = () => {
  return (
    <>
      <CustomerProjectViewContentContainer>
        <SitePlanView admin={true} />
      </CustomerProjectViewContentContainer>
    </>
  );
};
