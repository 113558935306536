import {IProduct} from "@natomas-org/core";
import {FactoryInventoryInformationEditorKey} from "./interfaces";
import {updateInventoryUnit} from "../../../../../../../../../../database/firebase/api/catalog";

export const saveInventoryInformation = (product: IProduct, values: any) => {
  const toSave = formatValues(product, values);
  console.log(toSave);
  return updateInventoryUnit(toSave);
};

const formatValues = (product: any, values: any) => {
  let sold = undefined;
  if (isSold(values)) {
    sold = {
      customerName:
        values?.[FactoryInventoryInformationEditorKey.CUSTOMER_NAME],
      customerPortalLink:
        values?.[FactoryInventoryInformationEditorKey.CUSTOMER_PORTAL_LINK],
      employeeName:
        values?.[FactoryInventoryInformationEditorKey.EMPLOYEE_NAME],
      soldDateTime:
        values?.[FactoryInventoryInformationEditorKey.SOLD_DATE_TIME],
    };
  }
  return {
    id: product?.id,
    productGroupId: product?.productGroupId,
    status: values?.[FactoryInventoryInformationEditorKey.LISTING_STATUS],
    internal_title:
      values?.[FactoryInventoryInformationEditorKey.INTERNAL_TITLE],
    inventory_info: {
      blacklinesUrl:
        values?.[FactoryInventoryInformationEditorKey.BLACKLINES_URL],
      storageDateTime:
        values?.[FactoryInventoryInformationEditorKey.STORAGE_DATE_TIME],
      productionDateTime:
        values?.[FactoryInventoryInformationEditorKey.PRODUCTION_DATE_TIME],
      serialNumber:
        values?.[FactoryInventoryInformationEditorKey.SERIAL_NUMBER],
      internalNotes:
        values?.[FactoryInventoryInformationEditorKey.INTERNAL_NOTES],
      sold: sold,
    },
  };
};

const isSold = (values: any) => {
  return !!values?.[FactoryInventoryInformationEditorKey.CUSTOMER_NAME];
};
