import {BedroomCountFilterSelector} from "./BedroomCountFilterSelector";
import {BathCountFilterSelector} from "./BathCountFilterSelector";
import React from "react";
import {usePage} from "../../../../../_shared/hooks/usePage";
import {BedBathFilterContainer} from "./styles";

export const BedAndBathFilterContent = () => {
  const {isNatMobile} = usePage();
  return (
    <BedBathFilterContainer isNatMobile={isNatMobile}>
      <BedroomCountFilterSelector />
      <BathCountFilterSelector />
    </BedBathFilterContainer>
  );
};
