import {IValidation} from "./Validation";

export const validatePassword = (password: string): IValidation[] => {
  const minLength = 8;
  const maxLength = 64;
  const uppercaseRegex = /[A-Z]/;
  const numberOrSpecialCharRegex = /[\d!@#$%^&*(),.?":{}|<>]/;

  let conditions: IValidation[] = [
    {
      isValid: password.length >= minLength && password.length <= maxLength,
      message: `Between ${minLength} and ${maxLength} characters in length`,
    },
    {
      isValid: uppercaseRegex.test(password),
      message: `At least 1 uppercase letter`,
    },
    {
      isValid: numberOrSpecialCharRegex.test(password),
      message: `At least 1 number or 1 special character (e.g. !, #, $, &, %, etc.)`,
    },
  ];

  const isOverallValid = conditions.every((condition) => condition.isValid);

  if (isOverallValid) {
    return [];
  } else {
    return conditions;
  }
};
