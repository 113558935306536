import {ImageCategory, IProduct} from "@natomas-org/core";
import _ from "lodash";

export const isProductListable = (product: IProduct, configuration: any) => {
  const hasConfiguration = !!configuration && !_.isEmpty(configuration);
  const hasFloorPlanImage = product?.images?.find((image) =>
    image?.categories?.includes(ImageCategory.FLOOR_PLAN)
  );
  return hasFloorPlanImage && hasConfiguration;
};
