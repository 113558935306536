import React, {useCallback, useMemo} from "react";

import {useDispatch, useSelector} from "react-redux";
import {EditCategory} from "./EditCategory";
import {ColumnContainer, MainColumn} from "../../../_shared/styles/AdminStyles";
import {
  RowStatusType,
  TabColumn,
  TabColumnRowSection,
} from "../../components/TabColumn";
import {editCategory} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {IStore} from "../../../../_shared/slices/types/Store";
import {getProductsWithCategory} from "../../components/TabColumn/helper";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {getInternalTitle, getTitle} from "../../logic/helpers";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

export const CategoryTab = (props: {widthRems: number}) => {
  const {categories, products} = useSelectedFactoryLine();
  const {editingEnabled} = useAdminProductCatalog();
  const dispatch = useDispatch();
  const title = "Categories";
  const currentCategory = useSelector(
    (state: IStore) => state.adminStudioManager.editingCategory
  );

  const getStatuses = useCallback(
    (category: any): RowStatusType => {
      const productsWithCategory = getProductsWithCategory(
        category,
        Object.values(products ?? {})
      );
      return {
        hasWarning: productsWithCategory?.length === 0,
      };
    },
    [products]
  );

  const newCategory = useCallback(() => {
    dispatch(editCategory({title: "New Category"}));
  }, [dispatch]);

  const changeCategory = useCallback(
    (category: any) => {
      dispatch(editCategory(category));
    },
    [dispatch]
  );

  const categoryArray = useMemo(
    () => Object.values(categories ?? {})?.filter((c) => !!c),
    [categories]
  );

  const getCategoryRow = useCallback(
    (category: any) => {
      return {
        title: getTitle(category),
        internal_title: getInternalTitle(category),
        key: category.id,
        data: category,
        action: changeCategory,
        active: currentCategory?.id === category?.id,
        statuses: getStatuses(category),
      };
    },
    [changeCategory, currentCategory?.id, getStatuses]
  );
  const RecentlyArchivedCategoryRowSection: TabColumnRowSection | undefined =
    useMemo(() => {
      const categories = categoryArray?.filter((category) =>
        isArchived(category)
      );
      if (categories?.length === 0) {
        return undefined;
      }
      return {
        title: editingEnabled ? "Recently Archived" : "Archived",
        rows: categories.map((category: any) => {
          return getCategoryRow(category);
        }),
      };
    }, [categoryArray, editingEnabled, getCategoryRow]);

  const CategoryRowSection: TabColumnRowSection = useMemo(() => {
    const categories = categoryArray?.filter(
      (category) => !isArchived(category)
    );
    return {
      title: RecentlyArchivedCategoryRowSection ? "Active" : undefined,
      rows: categories.map((category: any) => {
        return getCategoryRow(category);
      }),
    };
  }, [categoryArray, getCategoryRow, RecentlyArchivedCategoryRowSection]);

  const sections: TabColumnRowSection[] = useMemo(() => {
    const s = [CategoryRowSection];
    if (RecentlyArchivedCategoryRowSection) {
      return [...s, RecentlyArchivedCategoryRowSection];
    }
    return s;
  }, [CategoryRowSection, RecentlyArchivedCategoryRowSection]);

  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={props.widthRems + "rem"}
        headerAddAction={editingEnabled ? newCategory : undefined}
        sections={sections}
      />
      <MainColumn>
        <EditCategory />
      </MainColumn>
    </ColumnContainer>
  );
};
