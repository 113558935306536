import React, {useMemo} from "react";
import {
  getConstructionSpecifications,
  IConstructionSpecification,
} from "./helper";
import {useColumnCount} from "../../../../../_shared/hooks/usePage";
import DesktopConstructionSpecifications from "./DesktopConstructionSpecifications";
import MobileConstructionSpecifications from "./MobileConstructionSpecifications";

interface ConstructionSpecificationsProps {
  infoSet: any;
}

const ConstructionSpecifications = (props: ConstructionSpecificationsProps) => {
  const {infoSet} = props;
  const {isNatMobile} = useColumnCount();

  const constructionSpecs: IConstructionSpecification[] = useMemo(() => {
    if (!infoSet) {
      return [];
    }
    return getConstructionSpecifications(infoSet);
  }, [infoSet]);

  if (isNatMobile) {
    return (
      <MobileConstructionSpecifications constructionSpecs={constructionSpecs} />
    );
  } else {
    return (
      <DesktopConstructionSpecifications
        constructionSpecs={constructionSpecs}
      />
    );
  }
};

export default ConstructionSpecifications;
