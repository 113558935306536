export const MAP_BUTTON_SPACING = 7;

export const colors = {
  feasibleZoneBlue: "#9cb2ff", //"#d8dff7",
  feasibleZoneGrey: "#c9e59f", //"#dfe1e3",
  primaryResidenceBlue: "#92a6ed",
  primaryResidenceGrey: "#505050",
  propertyLineBlue: "#4E6FE5",
  propertyLineGrey: "#415053",
  aduRed: "#F3887C",
  textGrey: "#3E5054",
  textBlue: "#4E6FE5",
};

// TODO - bad
export const MAP_BOX_TOKEN =
  "pk.eyJ1IjoiamFja2Nvbm5vbGx5NDE1IiwiYSI6ImNrbnVzZnFybjBkb3cycXJxcjJ2OXVkYTQifQ.HrxEp44-s_3xcQiztYeeBA";
const MAP_BOX_STUDIO_TOKEN =
  "pk.eyJ1IjoiamFja2Nvbm5vbGx5NDE1IiwiYSI6ImNrbnVzYzlwbjBkbDEyd3BqNzhhbmY0OW0ifQ.bEma15QYKYcFZCI7NZZNaw";

export const MAP_STYLES = {
  BUILD3D: `https://api.mapbox.com/styles/v1/jackconnolly415/cl8aacu6n002415qppbmydjoy/tiles/256/{z}/{x}/{y}?access_token=${MAP_BOX_STUDIO_TOKEN}`,
  PLAIN: `https://api.mapbox.com/styles/v1/jackconnolly415/cko22mh1s1gr717oblo8aisus/tiles/256/{z}/{x}/{y}?access_token=${MAP_BOX_STUDIO_TOKEN}`,
  SATELLITE: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/{z}/{x}/{y}?access_token=${MAP_BOX_TOKEN}`,
};

export const BREAKPOINT_MOBILE_WIDTH = 576;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_DESKTOP_SMALL = 992;
export const BREAKPOINT_DESKTOP_LARGE = 1240;
export const BREAKPOINT_DESKTOP_X_LARGE = 1600;
export const MAP_ANIMATION_DURATION_SECONDS = 0.7;
export const MAP_BUTTON_Z_INDEX = 17;
