import React from "react";
import {DateUtils} from "@natomas-org/core";
import {IOrderFormTableCellValue} from "../../interfaces";
import {OrderFormTableCellEditorInfoContainer} from "../styles";
import {useEmployeeInfo} from "../../../../../../../../../_shared/hooks/useEmployeeInfo";

const getLastUpdateTime = (timestamp?: number) => {
  if (timestamp) {
    const date: Date = new Date(timestamp);
    const dateUpdated: string = DateUtils.getMonthAndDayShortHand(date, {
      showYear: true,
    });
    return dateUpdated;
  }
  return ``;
};

export const OrderFormTableCellEditorInfo = (props: {
  cell: IOrderFormTableCellValue;
}) => {
  const {satisfiedByManualSelection, value} = props?.cell ?? {};
  const {authorId, timestamp} = satisfiedByManualSelection ?? {};
  const authorInfo = useEmployeeInfo(authorId);
  const lastUpdateTime: string = getLastUpdateTime(timestamp);

  if (!value || value?.length === 0) {
    return null;
  }

  return (
    <OrderFormTableCellEditorInfoContainer>
      {authorInfo?.displayName ?? "Unknown"} {lastUpdateTime}
    </OrderFormTableCellEditorInfoContainer>
  );
};
