import {IFactoryInventoryTableItem} from "./interfaces";
import {IProduct, IStatus, ListingStatusDetails} from "@natomas-org/core";
import {getDaysAgo} from "../../../../../../_shared/_helpers";

export const getFactoryInventoryTableItem = (
  product?: IProduct,
  factoryTitle?: string
): IFactoryInventoryTableItem => {
  const storageDateTime = product?.inventory_info?.storageDateTime;
  const productionDateTime = product?.inventory_info?.productionDateTime;
  const blacklinesUrl = product?.inventory_info?.blacklinesUrl;
  const unitPrice = product?.price?.price ?? 0;
  const upgradePrice = product?.inventory_info?.upgradePrice?.price ?? 0;
  const price = unitPrice + upgradePrice;
  const totalCost = product?.price?.cost ?? 0;
  const margin = price - totalCost;
  const salesTax = product?.inventory_info?.salesTaxPrice?.cost ?? 0;
  const factoryCost = totalCost - salesTax;
  const marginPercentage = Math.round((margin / price) * 1000000) / 10000;
  const daysSinceStorage: string = getDaysAgo(storageDateTime, {
    onlyDescriptor: true,
  });
  return {
    id: product?.id ?? undefined,
    factoryId: product?.productGroupId ?? undefined,
    factoryTitle: factoryTitle ?? undefined,
    title: product?.title ?? undefined,
    internalTitle: product?.internal_title ?? undefined,
    status: getStatusSortValue(
      !!product?.inventory_info?.sold ? "sold" : product?.status ?? undefined
    ),
    price: price,
    upgradePrice: upgradePrice ?? undefined,
    unitPrice: unitPrice,
    cost: totalCost ?? undefined,
    salesTax: salesTax ?? undefined,
    factoryCost: factoryCost ?? undefined,
    margin: margin ?? undefined,
    marginPercentage: marginPercentage ?? undefined,
    serialNumber: product?.inventory_info?.serialNumber ?? "",
    daysSinceStorageDateTime: daysSinceStorage,
    storageDateTime: storageDateTime,
    productionDateTime: productionDateTime,
    blacklinesUrl: blacklinesUrl,
    createdDateTime: product?.created_date ?? undefined,
    updatedDateTime: product?.last_update_date ?? undefined,
  };
};

const getStatusSortValue = (sortByValue?: IStatus | "sold"): number => {
  if (sortByValue === "sold") {
    return 4;
  }
  if (sortByValue === ListingStatusDetails.LISTED) {
    return 3;
  }
  if (sortByValue === ListingStatusDetails.UNLISTED) {
    return 2;
  }
  if (sortByValue === ListingStatusDetails.HIDDEN) {
    return 1;
  }
  return 0;
};

export const getStatusValue = (sortByValue?: number): string => {
  if (sortByValue === 4) {
    return "sold";
  }
  if (sortByValue === 3) {
    return ListingStatusDetails.LISTED;
  }
  if (sortByValue === 2) {
    return ListingStatusDetails.UNLISTED;
  }
  if (sortByValue === 1) {
    return ListingStatusDetails.HIDDEN;
  }
  return "";
};

export const getStatusLabel = (sortByValue?: number): string => {
  if (sortByValue === 4) {
    return "Sold";
  }
  if (sortByValue === 3) {
    return "Listed";
  }
  if (sortByValue === 2) {
    return "Unlisted";
  }
  if (sortByValue === 1) {
    return "Hidden";
  }
  return "Unknown";
};

export const sortByStatus = (rowA: any, rowB: any) => {
  return rowB?.original?.status - rowA?.original?.status;
};
