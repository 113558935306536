import {
  CreateLeadSchema,
  ProjectAddressSchema,
  ProjectsApi,
  ProjectSchema,
} from "@natomas-org/villa-nexus-client";
import {AxiosInstance} from "axios";
import {getAxiosInstance} from "../setup";
import {extractNumbers} from "./index";
import {IAddressDetails} from "@natomas-org/core";

export class ProjectService {
  private axiosInstance;
  private client;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
    // @ts-ignore
    this.client = new ProjectsApi(undefined, undefined, this.axiosInstance);
  }

  createNewProject = async (
    lead: CreateLeadSchema,
    address: IAddressDetails
  ) => {
    if (!lead.auth_user_id) {
      throw new Error("createNewProject requires auth_user_id");
    }

    const projectAddress: ProjectAddressSchema = {
      city: address.city,
      state: address.state,
      zip: address.zip,
      street: address.street,
      street_address: `${address.street_number} ${address.street}`,
      full_address: address.full_address,
      street_number: address.street_number,
      country: address.country,
      county: address.county,
      latitude: address.latitude,
      longitude: address.longitude,
      geohash: address.geohash,
    };

    const schema: ProjectSchema = {
      project_id: null,
      auth_user_id: lead.auth_user_id as string,
      first_name: lead.first_name,
      last_name: lead.last_name,
      email: lead.email,
      phone: extractNumbers(lead.phone_number as string),
      address_info: projectAddress,
    };
    return this.client.createProject(schema);
  };
}

export const getProjectService = async () => {
  // ProjectService does not require authentication to use
  const axiosInstance = await getAxiosInstance({skipAuth: false});
  return new ProjectService(axiosInstance);
};
