import {AdminHelpContentRow} from "../../styles";
import React from "react";
import {BsFolderSymlink} from "react-icons/bs";

export const MatrixHelp = () => {
  return (
    <>
      <AdminHelpContentRow>
        <BsFolderSymlink /> - back link to the association.
      </AdminHelpContentRow>
    </>
  );
};
