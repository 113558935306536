import {IFactoryInfoSet} from "../../../../../_shared/slices/FactorySlice";
import {
  InfoSetItem,
  InfoSetItemOption,
} from "../../../../../factory-info-set/shared/interfaces";

// Locally defined for parsing
interface IConstructionSpecifications {
  [key: string]: IConstructionSpecification;
}

export interface IConstructionSpecification {
  label: string;
  items: string[];
}

export const getConstructionSpecifications = (
  infoSet: IFactoryInfoSet
): IConstructionSpecification[] => {
  const allInfoSetItemIds: string[] = Object.keys(infoSet);
  const specifications: IConstructionSpecifications = {};
  allInfoSetItemIds?.forEach((id: string) => {
    const itemInfo: InfoSetItem = infoSet[id];
    const {options} = itemInfo;
    options?.forEach((option: InfoSetItemOption) => {
      const {externalDescription, externalCategory} = option;
      if (externalCategory && externalDescription) {
        const label: string = externalCategory;
        specifications[label] = {
          label: label,
          items: [...(specifications[label]?.items || []), externalDescription],
        };
      }
    });
  });
  return Object.values(specifications)?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
};
