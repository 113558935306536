import styled from "styled-components";
import {GRAY80} from "../../../../../_shared/colors";
import {FONT_FAMILY_MEDIUM} from "../../../SSDDashboardView/styles/globals";

export const NextStepsContainer = styled.div<{
  expanded: boolean;
  expandedHeightRem: number;
}>`
  height: ${(props) => (props.expanded ? props.expandedHeightRem : "4.25rem")};
  overflow: hidden;
  transition: visibility 0s, opacity 350ms linear, height 350ms ease-out;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 4px 17px rgba(97, 97, 97, 0.17);
  border-radius: 8px;
  margin-bottom: 0.75rem;
  vertical-align: text-top;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const MobileNextStepsContainer = styled.div<{
  expanded: boolean;
  expandedHeightRem: number;
}>`
  height: ${(props) => (props.expanded ? props.expandedHeightRem : "100%")};
  overflow: hidden;
  transition: visibility 0s, opacity 350ms linear, height 350ms ease-out;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 4px 17px rgba(97, 97, 97, 0.17);
  border-radius: 8px;
  margin-bottom: 0.75rem;
  vertical-align: text-top;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const NextStepsSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const MobileNextStepsSubContainer = styled.div`
  padding-bottom: 1rem;
`;

export const NextStepsActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: 75px;
`;
export const MobileNextStepsActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;
export const NextStepsHelpTextContainer = styled.div`
  font-size: 0.9rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  color: ${GRAY80};
`;

export const MobileNextStepsHelpTextContainer = styled.div<{
  expanded: boolean;
}>`
  font-size: 0.9rem;
  padding-top: 0.5rem;
  color: ${GRAY80};
  display: ${(props) => (props.expanded ? "" : "none")};
`;

export const NextStepsTitle = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 18px;
  line-height: 133%;
`;

export const MobileNextStepsTitle = styled.div`
  font-family: ${FONT_FAMILY_MEDIUM};
  font-size: 18px;
  line-height: 133%;
  padding-bottom: 0.5rem;
`;

export const MobileNextStepsCTA = styled.div`
  font-size: 14px;
  line-height: 133%;
`;

export const NextStepsCTA = styled.div`
  font-size: 16px;
  line-height: 133%;
`;

export const NextStepsInfo = styled.div<{expanded: boolean}>`
  font-size: 14px;
  line-height: 133%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 0.25rem;
  svg {
    transform: ${(props) => (props.expanded ? "rotate(90deg)" : "")};
    transition: transform 75ms ease-in-out;
  }
`;
