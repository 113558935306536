// This is not in our system, but I think there *might*
// be a future for products being listed in multiple lines
import {isBlankString} from "@natomas-org/core";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

export const isMultiLine = (groups: any[]): boolean => {
  return !doProductsShareGroupId(groups);
};

export const isMultiProduct = (products: any[]): boolean => {
  return products?.length > 1;
};

export const isMultiCategory = (categories: any[]): boolean => {
  return categories?.length > 1;
};

export const isMultiModifierGroup = (modifierGroups: any[]): boolean => {
  return modifierGroups?.length > 1;
};

export const doProductsShareGroupId = (toSearch: any[]): boolean => {
  if (!toSearch?.length) return true;
  let i = toSearch[0];
  return toSearch.every((p: any) => p.productGroupId === i.productGroupId);
};

export const getProductsWithCategory = (
  category: any,
  products: any[] | undefined
) => {
  if (!products) return [];
  return products?.filter((p: any) => {
    return !isArchived(p) && p?.configuratorPages?.includes(category.id);
  });
};

export const getCategoryTitle = (category: any) => {
  return !isBlankString(category.internal_title)
    ? category.internal_title
    : !isBlankString(category.title)
    ? category.title
    : category.id;
};

export const getModifierGroupTitle = (modifierGroup: any) => {
  return !isBlankString(modifierGroup.title)
    ? modifierGroup.title
    : modifierGroup.id;
};

export const getProductTitle = (product: any) => {
  return !isBlankString(product.title) ? product.title : product.id;
};

export const getCategoriesWithModifierGroup = (
  modifierGroup: any,
  categories: any[] | undefined
) => {
  if (!categories) return [];
  return categories.filter((c: any) => {
    return !isArchived(c) && c?.modifierGroups?.includes(modifierGroup.id);
  });
};

export const getModifierGroupsWithModifier = (
  modifier: any,
  modifierGroups: any[] | undefined
) => {
  if (!modifierGroups) return [];
  return modifierGroups.filter((c: any) => {
    return !isArchived(c) && c?.modifiers?.includes(modifier.id);
  });
};

export const getDistinctProducts = (products: any[]) => {
  return Array.from(new Set(products?.map((p: any) => p)));
};

export const getProductsWithCategories = (
  categories: any[],
  products: any[]
) => {
  return categories
    .map((c: any) => {
      return getProductsWithCategory(c, products);
    })
    .flat();
};

export const getCategoriesWithModifierGroups = (
  modifierGroups: any[],
  categories: any[]
) => {
  return modifierGroups
    .map((m: any) => {
      return getCategoriesWithModifierGroup(m, categories);
    })
    .flat()
    .sort((a: any, b: any) => a.title.localeCompare(b.title));
};

export const getDistinctProductWithCategories = (
  categories: any[],
  products: any[] | undefined
) => {
  if (!products) return [];
  return getDistinctProducts(
    getProductsWithCategories(categories, products)
  ).sort((a: any, b: any) => a.title.localeCompare(b.title));
};
