import {
  Address,
  Customer,
  ICustomerInfo,
  IProduct,
  IProjectInfo,
  Project,
} from "@natomas-org/core";
import React, {useMemo, useState} from "react";
import {
  StyledLinkSpan,
  WelcomeCTAButtonContainer,
  WelcomeCTAWrapper,
} from "./styles";
import {NatButton, NatLink} from "../../../../../../_shared/generics/button";
import {
  NatSize,
  NatSizeType,
  StyleOption,
} from "../../../../../../_shared/generics/_shared";
import {useNavigation} from "../../../../../../_shared/hooks/useNavigation";
import {NavigationPaths} from "../../../../../../_shared/hooks/useNavigation/paths";
import {
  OVERVIEW_ASSESSMENT_PAGE_ID,
  PROFILE_PAGE_ID,
} from "../../../../../_shared/navigation/constants";
import {
  FEASIBILITY_STATUS,
  useCustomerStatus,
} from "../../../../../../_shared/hooks/useCustomerStatus";
import {getProjectSummaryComponent} from "./helpers";
import {FONT_FAMILY_BOLD, FONT_FAMILY_REGULAR} from "../../../styles/globals";
import {usePage} from "../../../../../../_shared/hooks/usePage";
import {POPUP_OVERRIDE_Z_INDEX} from "../../../../../../_shared/styles";
import {
  DropdownStyles,
  NatDropdownStyles,
  NatPopupStyleOptions,
  NatPopupType,
} from "../../../../../../_shared/generics/popup/dropdown/constants";
import {NatPopup} from "../../../../../../_shared/generics/popup/dropdown/components/NatPopup/NatPopup";

export const WelcomeCTA = (props: {
  projectSummary: IProjectInfo | null | undefined;
  customer: ICustomerInfo;
  product: IProduct | null | undefined;
}) => {
  const {feasibility_status, customerStatus} = useCustomerStatus();
  const {projectSummary, customer, product} = props;
  const {isNatMobile} = usePage();
  const {to} = useNavigation();
  const [showProposalTextPopup, setShowProposalTextPopup] =
    useState<boolean>(false);
  const sectionInfo = useMemo(() => {
    return getProjectSummaryComponent(
      customerStatus,
      projectSummary,
      feasibility_status,
      customer,
      product,
      to
    );
  }, [
    customerStatus,
    projectSummary,
    feasibility_status,
    customer,
    product,
    to,
  ]);
  if (!projectSummary) {
    return null;
  }

  if (feasibility_status === FEASIBILITY_STATUS.INFEASIBLE) {
    return (
      <WelcomeCTAWrapper>
        <div style={{fontSize: "2rem"}}>{`Welcome, ${Customer.getFirstName(
          customer
        )}`}</div>
        {/*👋*/}
        <br />
        <div>
          {sectionInfo.titles.map((title) => (
            <div>{title}</div>
          ))}
        </div>
        <br />

        <div>{sectionInfo.footerTexts}</div>

        <br />
        <WelcomeCTAButtonContainer>
          <NatButton
            size={isNatMobile ? NatSize.NORMAL : NatSize.LARGE}
            option={StyleOption.PRIMARY_ALT}
            label={"Change my address"}
            type={"button"}
            clickEvent={() => {
              to(NavigationPaths.PORTAL, {page: PROFILE_PAGE_ID});
            }}
            style={{flexGrow: 1, minWidth: "fit-content"}}
          />
          <NatButton
            size={isNatMobile ? NatSize.NORMAL : NatSize.LARGE}
            option={StyleOption.PRIMARY}
            label={"Order Feasibility Study"}
            trackingId={"splash-screen-submit-proposal-fee"}
            type={"button"}
            clickEvent={() => {
              to(NavigationPaths.PORTAL, {page: OVERVIEW_ASSESSMENT_PAGE_ID});
            }}
            style={{
              flexGrow: 1,
              minWidth: "fit-content",
            }}
          />
        </WelcomeCTAButtonContainer>
        <br />
      </WelcomeCTAWrapper>
    );
  }

  return (
    <WelcomeCTAWrapper>
      <div style={{fontSize: "2rem"}}>{`Welcome, ${Customer.getFirstName(
        customer
      )}`}</div>
      <br />
      <div>
        {`Great news! Villa can build at `}
        <div style={{fontFamily: FONT_FAMILY_BOLD}}>
          {Address.getStreetNumberAndStreet(Project.getAddress(projectSummary))}
          <NatLink
            style={{marginLeft: "0.5rem"}}
            label={"edit"}
            option={StyleOption.PRIMARY_LINK}
            clickEvent={() => {
              to(NavigationPaths.PORTAL, {page: PROFILE_PAGE_ID});
            }}
          />
        </div>
      </div>
      <br />

      <div>
        Once you're ready, submit a $500 fee to unlock a comprehensive{" "}
        <StyledLinkSpan
          onClick={() => {
            setShowProposalTextPopup(!showProposalTextPopup);
          }}
          style={{}}
        >
          Villa Feasibility Study
        </StyledLinkSpan>
        .
        <NatPopup
          contentItemArray={[
            {
              component: (
                <div style={{minWidth: "22rem"}}>
                  What is a Villa Feasibility Study?
                  <hr />
                  <div
                    style={{
                      fontFamily: FONT_FAMILY_REGULAR,
                      fontSize: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    Our expert Home Consultants will assess your property in
                    detail and create a custom site map and utility plan. This
                    includes a deep dive into easements and zoning, delivery and
                    installation feasibility confirmation, local permitting
                    rules and regulations, confirmation of which floor plans fit
                    on your property and more. Once this is delivered to you we
                    will set up a design consultation session.
                  </div>
                </div>
              ),
              id: "title",
              label: "What’s the Villa Feasibility Study?",
            },
          ]}
          handleClose={() => {
            setShowProposalTextPopup(false);
          }}
          show={showProposalTextPopup}
          style={{
            ...NatDropdownStyles[DropdownStyles.SELECTION_LIST][
              NatSizeType.LARGE
            ],
            TYPE: NatPopupType.HIDE,
            ADDITIONAL_STYLE_OPTIONS: [
              NatPopupStyleOptions.USE_FIRST_ARRAY_ITEM_AS_TITLE,
              NatPopupStyleOptions.HIDE_CHEVRON,
              NatPopupStyleOptions.HIDE_CHEVRON_DIVIDER,
            ],
            popupCSS: {
              marginTop: "0.5rem",
              zIndex: POPUP_OVERRIDE_Z_INDEX,
            },
          }}
        />
      </div>

      <br />
      <WelcomeCTAButtonContainer>
        {sectionInfo.buttons.map((buttonProp, index) => (
          <NatButton
            {...buttonProp}
            size={isNatMobile ? NatSize.NORMAL : NatSize.LARGE}
            key={"button-" + index}
            style={{
              flexGrow: 1,
              minWidth: "fit-content",
            }}
          />
        ))}
        <NatButton
          size={isNatMobile ? NatSize.NORMAL : NatSize.LARGE}
          option={StyleOption.PRIMARY}
          label={"Order Feasibility Study"}
          trackingId={"splash-screen-submit-proposal-fee"}
          type={"button"}
          clickEvent={() => {
            to(NavigationPaths.PORTAL, {page: OVERVIEW_ASSESSMENT_PAGE_ID});
          }}
          style={{
            flexGrow: 1,
            minWidth: "fit-content",
          }}
        />
      </WelcomeCTAButtonContainer>
      <br />
    </WelcomeCTAWrapper>
  );
};
