import {FactoryInventoryInputType} from "../../_shared/FactoryInventoryInput";
import {IFactoryInventoryEditorStructure} from "../../FactoryInventoryCatalogEditor/formik/structure";
import {FactoryInventoryInformationEditorKey} from "./interfaces";

export const FactoryInventoryInformationEditorStructure: IFactoryInventoryEditorStructure =
  {
    [FactoryInventoryInformationEditorKey.LISTING_STATUS]: {
      label: "Listing Status",
      type: FactoryInventoryInputType.LISTING_STATUS,
    },
    [FactoryInventoryInformationEditorKey.CUSTOMER_NAME]: {
      label: "Customer Name",
      type: FactoryInventoryInputType.TEXT,
    },
    [FactoryInventoryInformationEditorKey.CUSTOMER_PORTAL_LINK]: {
      label: "Customer Portal Link",
      type: FactoryInventoryInputType.URL,
    },
    [FactoryInventoryInformationEditorKey.EMPLOYEE_NAME]: {
      label: "Employee Name (Sold By)",
      type: FactoryInventoryInputType.TEXT,
    },
    [FactoryInventoryInformationEditorKey.SOLD_DATE_TIME]: {
      label: "Sold Date",
      type: FactoryInventoryInputType.DATE_TIME,
    },
    [FactoryInventoryInformationEditorKey.INTERNAL_TITLE]: {
      label: "Internal Name",
      type: FactoryInventoryInputType.TEXT,
    },
    [FactoryInventoryInformationEditorKey.INTERNAL_NOTES]: {
      label: "Internal Notes",
      type: FactoryInventoryInputType.TEXT_BOX,
    },
    [FactoryInventoryInformationEditorKey.SERIAL_NUMBER]: {
      label: "Serial Number",
      type: FactoryInventoryInputType.TEXT,
    },
    [FactoryInventoryInformationEditorKey.BLACKLINES_URL]: {
      label: "Sales Quote / Blacklines",
      type: FactoryInventoryInputType.URL,
    },
    [FactoryInventoryInformationEditorKey.STORAGE_DATE_TIME]: {
      label: "Storage Date",
      type: FactoryInventoryInputType.DATE_TIME,
    },
    [FactoryInventoryInformationEditorKey.PRODUCTION_DATE_TIME]: {
      label: "Production Date",
      type: FactoryInventoryInputType.DATE_TIME,
    },
    [FactoryInventoryInformationEditorKey.PRODUCTION_DATE_TIME]: {
      label: "Production Date",
      type: FactoryInventoryInputType.DATE_TIME,
    },
  };
