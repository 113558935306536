//Packages
import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
//Core
import {Customer, FULL_PORTAL_STAGES, Project} from "@natomas-org/core";
//Styles
import {PortalLayout} from "./styles";
//Shared
import {
  getPageComponent,
  getTitleForPageId,
} from "../_shared/navigation/PageNavigation";
import {useUrl} from "../../_shared/hooks/useUrl";
import {Page} from "../../_shared/generics/page";
import {getStore} from "../../../store";
import {updateAdditionLeftPadding} from "../../_shared/slices/PageSlice";
import {LoadingPanel} from "../../_shared/generics/loading-panel";
import {useCurrentUser} from "../../_shared/hooks/useCurrentUser";
import {useCustomerStatus} from "../../_shared/hooks/useCustomerStatus";
import {useCurrentCustomer} from "../../_shared/hooks/useCurrentCustomer";
import {HCPDashboardLayout} from "./HCPDashboardLayout/HCPDashboardLayout";
import {PortalHeader} from "../_shared/components/PortalHeader";
import {FPDashboardLayout} from "./FPDashboardLayout/FPDashboardLayout";
import {THANK_YOU_PAGE_ID} from "../_shared/navigation/constants";
import useActiveProject from "../../_shared/hooks/useProject/useActiveProject";

export const ProjectSummaryLayout = () => {
  const {pageId} = useUrl();
  const {isCustomerPreDeposit} = useCustomerStatus();
  const {isAdmin, isSignedIn} = useCurrentUser();
  const {customer} = useCurrentCustomer();
  const {info, id: pid} = useActiveProject();
  const shouldShowPostDepositThankYouPage =
    pageId === THANK_YOU_PAGE_ID && !isCustomerPreDeposit;
  const shouldDisplayPostDepositPortalV2 =
    !isCustomerPreDeposit && !shouldShowPostDepositThankYouPage;

  const currentStage = Project.getCurrentStage(info);
  const shouldDisplayFullPortal =
    currentStage &&
    FULL_PORTAL_STAGES.includes(currentStage) &&
    !shouldShowPostDepositThankYouPage;

  useEffect(() => {
    getStore().dispatch(updateAdditionLeftPadding(0));
    return () => {
      getStore().dispatch(updateAdditionLeftPadding(0));
    };
  }, []);

  const getSelectedTab = (tab: string | null): JSX.Element => {
    return getPageComponent(tab);
  };

  let browserPageTitle = "Villa | Project Summary";
  if (customer && isAdmin) {
    browserPageTitle = `Villa | ${Customer.getLastName(customer)} Project`;
  }

  let mainContent: any;
  if (shouldDisplayFullPortal) {
    mainContent = <FPDashboardLayout pageId={pageId} />;
  } else if (shouldDisplayPostDepositPortalV2) {
    mainContent = <HCPDashboardLayout pageId={pageId} />;
  } else {
    mainContent = (
      <>
        <PortalHeader
          title={getTitleForPageId(pageId)}
          showAppSwitcher={false}
        />
        {getSelectedTab(pageId)}
      </>
    );
  }

  return (
    <>
      <LoadingPanel show={!customer || !pid} showAuth={true} />
      <PortalLayout id={"portal-content-wrapper"}>
        <Helmet>
          <title>{browserPageTitle}</title>
          <meta name="description" content="Your Villa Dashboard" />
        </Helmet>
        <ErrorBoundary>
          {/*id={"main-content-wrapper"} is needed for mobile menu*/}
          <Page id={"main-content-wrapper"}>{mainContent}</Page>
        </ErrorBoundary>
      </PortalLayout>
    </>
  );
};
