import React, {useMemo} from "react";
import {IFactoryInventoryStudioEditorCategory} from "./interface";
import ModifierGroupEditor from "../ModifierGroupSection";
import {
  StudioEditorCategoryContainer,
  StudioEditorCategoryDescription,
  StudioEditorCategoryImagesContainer,
  StudioEditorCategorySelectionContainer,
  StudioEditorCategoryTitle,
  StudioEditorCategoryTitleContainer,
  StudioEditorCategoryTitleWrapper,
} from "./styles";
import StudioEditorFieldAddButton, {
  StudioEditorFieldType,
} from "../_shared/StudioEditorFieldAddButton";
import CategoryImagesEditor from "./CategoryImagesEditor";
import {IFactoryInventoryStudioEditorCategoryImageGroup} from "./CategoryImagesEditor/interface";
import {IFactoryInventoryStudioEditorPopupOptions} from "../PopupContent/interface";
import FieldHighlight from "../_shared/FieldHighlight";
import {sortValuesByIndex} from "../../formik/preview";
import {IFactoryInventoryStudioEditorModifierGroup} from "../ModifierGroupSection/interface";
import StudioEditorFieldConditionalController from "../_shared/StudioEditorFieldButton";
import {StudioEditorMode} from "../../../index";

interface CategoryEditorProps {
  id: string;
  initialValue?: IFactoryInventoryStudioEditorCategory;
  currentValue?: IFactoryInventoryStudioEditorCategory;
  setActionEvent?: (
    e: any,
    options?: IFactoryInventoryStudioEditorPopupOptions
  ) => void;
  mode?: StudioEditorMode;
}

const CategoryEditor = (props: CategoryEditorProps) => {
  const {id, initialValue, currentValue, setActionEvent, mode} = props;

  const modifierGroups = useMemo(() => {
    const modifierGroupArray = Object.values(
      currentValue?.modifierGroups ?? {}
    );
    return sortValuesByIndex(modifierGroupArray);
  }, [currentValue]);

  const modifierGroupIds: string[] = useMemo(() => {
    return modifierGroups?.map(
      (modifierGroup: IFactoryInventoryStudioEditorModifierGroup) =>
        modifierGroup?.id
    );
  }, [modifierGroups]);

  const imageGroups: IFactoryInventoryStudioEditorCategoryImageGroup[] =
    useMemo(() => {
      return Object.values(currentValue?.images ?? {});
    }, [currentValue]);

  return (
    <StudioEditorCategoryContainer>
      <StudioEditorCategoryImagesContainer>
        {sortValuesByIndex(imageGroups)?.map(
          (
            imageGroup: IFactoryInventoryStudioEditorCategoryImageGroup,
            index: number
          ) => {
            return (
              <CategoryImagesEditor
                key={`${id}-images-${index}`}
                id={`${id}-images-${index}`}
                imageGroup={imageGroup}
                setActionEvent={setActionEvent}
                categoryId={currentValue?.id}
                mode={mode}
              />
            );
          }
        )}
        {mode === StudioEditorMode.EDIT && (
          <StudioEditorFieldAddButton
            type={StudioEditorFieldType.RENDERING}
            setActionEvent={setActionEvent}
            rootPath={{categoryId: currentValue?.id}}
          />
        )}
      </StudioEditorCategoryImagesContainer>
      <StudioEditorCategorySelectionContainer>
        <StudioEditorCategoryTitleWrapper>
          <StudioEditorCategoryTitleContainer>
            {mode && setActionEvent && (
              <StudioEditorFieldConditionalController
                mode={mode}
                path={{categoryId: currentValue?.id}}
                type={StudioEditorFieldType.CATEGORY}
                setActionEvent={setActionEvent}
              />
            )}
            <FieldHighlight
              initialValue={initialValue?.title}
              value={currentValue?.title}
            >
              <StudioEditorCategoryTitle>
                {currentValue?.title}
              </StudioEditorCategoryTitle>
            </FieldHighlight>
          </StudioEditorCategoryTitleContainer>
          {mode === StudioEditorMode.EDIT && (
            <StudioEditorFieldAddButton
              type={StudioEditorFieldType.MODIFIER_GROUP}
              setActionEvent={setActionEvent}
              rootPath={{categoryId: currentValue?.id}}
            />
          )}
        </StudioEditorCategoryTitleWrapper>
        {currentValue?.description && (
          <StudioEditorCategoryDescription>
            <FieldHighlight
              initialValue={initialValue?.description}
              value={currentValue?.description}
            >
              {currentValue?.description}
            </FieldHighlight>
          </StudioEditorCategoryDescription>
        )}
        {modifierGroupIds?.map((modifierGroupId: string, index: number) => {
          const modifierGroup = currentValue?.modifierGroups?.[modifierGroupId];
          const initialModifierGroup =
            initialValue?.modifierGroups?.[modifierGroupId] ?? undefined;
          if (!modifierGroup) {
            return null;
          }
          return (
            <ModifierGroupEditor
              key={modifierGroup?.id}
              id={`${id}-modifier-group-${index}`}
              currentValue={modifierGroup}
              initialValue={initialModifierGroup}
              setActionEvent={setActionEvent}
              categoryId={currentValue?.id}
              mode={mode}
            />
          );
        })}
      </StudioEditorCategorySelectionContainer>
    </StudioEditorCategoryContainer>
  );
};

export default CategoryEditor;
