import {RowStatusIcon} from "../TabColumn/styles";
import {
  STATUS_HIDDEN,
  STATUS_LISTED,
  STATUS_UNLISTED,
} from "../../../../_shared/colors";
import React from "react";
import {BsFillExclamationDiamondFill} from "react-icons/bs";

export const StatusListedIcon = <RowStatusIcon color={STATUS_LISTED} />;
export const StatusUnlistedIcon = <RowStatusIcon color={STATUS_UNLISTED} />;
export const StatusHiddenIcon = <RowStatusIcon color={STATUS_HIDDEN} />;

export const StatusHasWarning = <BsFillExclamationDiamondFill color={"red"} />;
