import {Route} from "react-router-dom";
import {CATALOG_ROOT_PATH} from "../paths";
import React from "react";
import PortalCatalog, {
  CatalogMode,
} from "../components/catalog/views/PortalCatalog";

const CatalogRoutes = () => {
  return (
    <>
      <Route path={CATALOG_ROOT_PATH}>
        <PortalCatalog defaultMode={CatalogMode.PRODUCT_LINES} />
      </Route>
    </>
  );
};

export default CatalogRoutes;
