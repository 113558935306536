import {AdminHelpContentRow} from "../../styles";
import React from "react";
import {
  StatusHasWarning,
  StatusHiddenIcon,
  StatusListedIcon,
  StatusUnlistedIcon,
} from "../../../RowStatusIcons";
import {BsFolderSymlink} from "react-icons/bs";

export const ProductsHelp = () => {
  return (
    <>
      <AdminHelpContentRow>
        {StatusListedIcon} - listed product.
      </AdminHelpContentRow>
      <AdminHelpContentRow>
        {StatusUnlistedIcon}- unlisted product.
      </AdminHelpContentRow>
      <AdminHelpContentRow>
        {StatusHiddenIcon}- hidden product.
      </AdminHelpContentRow>
      <AdminHelpContentRow>
        {StatusHasWarning}- unknown listing status.
      </AdminHelpContentRow>
      <AdminHelpContentRow>
        <BsFolderSymlink /> - navigate to the association.
      </AdminHelpContentRow>
    </>
  );
};
