import {OrderFormTableCellValueContainer} from "./styles";
import Select from "react-select";
import {OrderFormTableCellController} from "./shared/OrderFormTableCellController";
import React, {useState} from "react";
import {IOrderFormTableCellValue} from "../interfaces";
import {NatWarningDiamondIcon} from "../../../../../../../../_shared/icon/icons";

export const OrderFormTableCellSelectionInput = (props: {
  cell: IOrderFormTableCellValue;
  setValue: (newValue: string) => void;
}) => {
  const {cell, setValue} = props;
  const [locked, setLocked] = useState<boolean>(cell.locked ?? false);
  // TODO bug discovered - this should be moved to derivation, validation doesnt notice when a configured value doesnt exist as an option post updating the InfoSet
  const options = cell.options?.map((o: string) => {
    return {
      label: o,
      value: o,
    };
  });

  if (!options || options?.length === 0) {
    return (
      <OrderFormTableCellValueContainer>
        ~
        <NatWarningDiamondIcon color={"darkorange"} size={20} />
      </OrderFormTableCellValueContainer>
    );
  }

  const selectedOption = options?.find((o: any) => o.value === cell.value);
  return (
    <OrderFormTableCellValueContainer>
      {locked && !!selectedOption ? (
        <>{selectedOption?.value}</>
      ) : (
        <Select
          styles={{
            container: (styles) => ({
              ...styles,
              minWidth: "6rem",
              flexGrow: 1,
            }),
          }}
          options={options}
          value={selectedOption}
          onChange={(o: any) => {
            setValue(o.value);
          }}
        />
      )}
      <OrderFormTableCellController
        cell={cell}
        hasValue={!!selectedOption}
        setLocked={setLocked}
        locked={locked}
      />
    </OrderFormTableCellValueContainer>
  );
};
