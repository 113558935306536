import React from "react";
import {NatomasLink} from "./styles";
import {
  getBoxShadow,
  getEnabledBackgroundColor,
  getEnabledBorderColor,
  getHoverBackgroundColor,
  getHoverBorderColor,
  getHoverBoxShadow,
  getHoverTextColor,
  getHoverTextDecoration,
  getInitialBackgroundColor,
  getInitialBorderColor,
  getInitialTextColor,
  getInitialTextDecoration,
  getTextSize,
  NatSize,
  StyleOption,
} from "../_shared";
import {trackButtonClick} from "../../hooks/useTracking";
import {
  FONT_FAMILY_BOLD,
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../portal/views/SSDDashboardView/styles/globals";

export interface ILinkButton {
  label?: string;
  id?: string;
  trackingId?: string;
  link?: string;
  weight?: NatFontWeight;
  clickEvent?: any;
  clickEventParam?: any;
  icon?: any;
  option?: StyleOption;
  size?: NatSize;
  hidden?: boolean;
  hideUnderline?: boolean;
  overrideTextColor?: string;
  openInNewTab?: boolean;
  style?: React.CSSProperties;
}

const NatWeight = {
  REGULAR: FONT_FAMILY_REGULAR,
  MEDIUM: FONT_FAMILY_MEDIUM,
  BOLD: FONT_FAMILY_BOLD,
} as const;

type NatFontWeight = keyof typeof NatWeight;

const NatLink = (props: ILinkButton): JSX.Element => {
  const {
    link,
    clickEvent,
    label,
    option: _option,
    size,
    clickEventParam,
    icon,
    hidden,
    trackingId,
    hideUnderline,
    overrideTextColor,
    id,
    style,
    openInNewTab,
  } = props;
  const attributes: any = {};
  if (link && !clickEvent) {
    attributes.href = link;
    if (openInNewTab) {
      attributes.target = "__blank";
    }
  }
  const doAction = () => {
    trackButtonClick(label ?? "empty_label", id, trackingId);
    if (clickEventParam) clickEvent(clickEventParam);
    else if (clickEvent) clickEvent();
  };
  const option = _option ?? StyleOption.PRIMARY_LINK;
  return (
    <>
      <NatomasLink
        initialBackgroundColor={getInitialBackgroundColor(option)}
        initialTextColor={overrideTextColor ?? getInitialTextColor(option)}
        initialTextDecoration={getInitialTextDecoration(option)}
        hoverBackgroundColor={getHoverBackgroundColor(option)}
        hoverTextColor={getHoverTextColor(option)}
        hoverTextDecoration={
          hideUnderline ? "none" : getHoverTextDecoration(option)
        }
        boxShadow={getBoxShadow(option)}
        hoverBoxShadow={getHoverBoxShadow(option)}
        initialBorderColor={getInitialBorderColor(option)}
        hoverBorderColor={getHoverBorderColor(option)}
        enabledBackgroundColor={getEnabledBackgroundColor(option)}
        enabledBorderColor={getEnabledBorderColor(option)}
        onClick={() => doAction()}
        fontFamily={props.weight ? NatWeight[props.weight] : undefined}
        size={getTextSize(size)}
        {...attributes}
        hidden={hidden}
        id={id}
        name={trackingId}
        style={style}
      >
        {label}
        {icon && <span style={{marginLeft: label ? "8px" : 0}}>{icon}</span>}
      </NatomasLink>
    </>
  );
};

export {NatLink};
