import styled from "styled-components";
import {DropdownOption} from "../../../../_shared/generics/popup/dropdown/styles";
import {FONT_FAMILY_REGULAR} from "../../SSDDashboardView/styles/globals";

export const HCPTitleNavWrapper = styled.div`
  ${DropdownOption} {
    font-size: 1rem !important;
    font-family: ${FONT_FAMILY_REGULAR};
  }
`;
