import styled from "styled-components";
import {
  CONTAINER_BACKGROUND_COLOR,
  DIVIDER_COLOR,
} from "../../../../../../_shared/colors";
import {
  FONT_FAMILY_MEDIUM,
  FONT_FAMILY_REGULAR,
} from "../../../../../../portal/views/SSDDashboardView/styles/globals";

export const FactoryViewWrapper = styled.div`
  min-height: 100%;
  flex-grow: 1;
  background-color: ${CONTAINER_BACKGROUND_COLOR};
`;
export const FactoryViewContainer = styled.div`
  height: auto;
  max-height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 16px;
  column-gap: 16px;
  padding: 16px;
`;

export const ProductLineInfoBlockInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const ProductLineInfoBlockTitle = styled.h2`
  font-size: 20px;
  font-family: ${FONT_FAMILY_MEDIUM};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProductLineInfoBlockInfoRow = styled.div`
  padding: 6px 0 4px 0;
  margin: 0;
  font-size: 14px;
  font-family: ${FONT_FAMILY_REGULAR};
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${DIVIDER_COLOR};
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
`;
