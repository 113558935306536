// Address cache keys
export const addressLocalStorageKeyFullAddress =
  "villa-catalog-address-full-address";
export const addressLocalStorageKeyStreetNumber =
  "villa-catalog-address-street-number";
export const addressLocalStorageKeyStreet = "villa-catalog-address-street";
export const addressLocalStorageKeyCity = "villa-catalog-address-city";
export const addressLocalStorageKeyState = "villa-catalog-address-state";
export const addressLocalStorageKeyZip = "villa-catalog-address-zip";
export const addressLocalStorageKeyCounty = "villa-catalog-address-county";
export const addressLocalStorageKeyCountry = "villa-catalog-address-country";
export const addressLocalStorageKeyLatitude = "villa-catalog-address-latitude";
export const addressLocalStorageKeyLongitude =
  "villa-catalog-address-longitude";

// Survey cache keys
export const surveyLocalStorageKey = "villa-catalog-survey";
