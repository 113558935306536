import React, {useCallback, useMemo} from "react";

import {
  getModels,
  sortProducts,
} from "../../../../_shared/_legacy/logic/ProductGroupUtils";
import {editProduct} from "../../../../_shared/slices/CatalogSlice/AdminStudioManagerSlice";
import {useDispatch, useSelector} from "react-redux";
import {EditProduct} from "./EditProduct";
import {ColumnContainer, MainColumn} from "../../../_shared/styles/AdminStyles";
import {
  TabColumn,
  TabColumnRow,
  TabColumnRowSection,
} from "../../components/TabColumn";
import {IStore} from "../../../../_shared/slices/types/Store";
import {ListingStatus} from "@natomas-org/core";
import {
  StatusHiddenIcon,
  StatusListedIcon,
  StatusUnlistedIcon,
} from "../../components/RowStatusIcons";
import {useAdminProductCatalog} from "../../../../_shared/hooks/admin-only-hooks/useAdminProductCatalog";
import {getInternalTitle, getTitle} from "../../logic/helpers";
import {useSelectedFactoryLine} from "../../../../_shared/hooks/useProductCatalog/useSelectedFactoryLine";
import {isArchived} from "../../../../../database/firebase/catalog/fetching";

export const ProductsTab = (props: {widthRems: number}) => {
  const {products: factoryProducts} = useSelectedFactoryLine();
  const products = useMemo(
    () => Object.values(factoryProducts ?? {})?.filter((p: any) => !!p),
    [factoryProducts]
  );
  const {editingEnabled} = useAdminProductCatalog();
  const currentProduct = useSelector(
    (state: IStore) => state.adminStudioManager.editingProduct
  );
  const dispatch = useDispatch();
  const title = "Products";
  const newProduct = () => {
    dispatch(editProduct({title: "New Product"}));
  };
  const changeProduct = useCallback(
    (product: any) => {
      dispatch(editProduct(product));
    },
    [dispatch]
  );

  const getProductStatuses = (product: any) => {
    let s = ListingStatus.convertDBListingStatus(product?.status);
    if (ListingStatus.isListed(s)) {
      return StatusListedIcon;
    } else if (ListingStatus.isUnlisted(s)) {
      return StatusUnlistedIcon;
    } else if (ListingStatus.isHidden(s)) {
      return StatusHiddenIcon;
    } else return undefined;
  };

  const getProductRow = useCallback(
    (product: any): TabColumnRow => {
      let s = getProductStatuses(product);
      return {
        title: getTitle(product),
        internal_title: getInternalTitle(product),
        key: product.id,
        data: product,
        action: changeProduct,
        active: currentProduct?.id === product?.id,
        statuses: {
          hasWarning: !s,
          customStatuses: s,
        },
      };
    },
    [changeProduct, currentProduct?.id]
  );

  const ArchivedProductRowSection: TabColumnRowSection | undefined =
    useMemo((): TabColumnRowSection | undefined => {
      const p = products?.filter((product) => isArchived(product));
      if (!p || p?.length === 0) {
        return undefined;
      }
      return {
        title: editingEnabled ? "Recently Archived" : "Archived",
        rows:
          sortProducts(getModels(p)).map(
            (product: any): TabColumnRow => getProductRow(product)
          ) ?? [],
      };
    }, [products, editingEnabled, getProductRow]);

  const ProductRowSection: TabColumnRowSection =
    useMemo((): TabColumnRowSection => {
      const p = products?.filter((product) => !isArchived(product));
      return {
        title: ArchivedProductRowSection ? "Active" : undefined,
        rows:
          sortProducts(getModels(p)).map(
            (product: any): TabColumnRow => getProductRow(product)
          ) ?? [],
      };
    }, [products, getProductRow, ArchivedProductRowSection]);

  const sections: TabColumnRowSection[] = useMemo(() => {
    const s = [ProductRowSection];
    if (ArchivedProductRowSection) {
      return [...s, ArchivedProductRowSection];
    }
    return s;
  }, [ProductRowSection, ArchivedProductRowSection]);

  return (
    <ColumnContainer>
      <TabColumn
        title={title}
        width={props.widthRems + "rem"}
        headerAddAction={editingEnabled ? newProduct : undefined}
        sections={sections}
      />
      <MainColumn>
        <EditProduct />
      </MainColumn>
    </ColumnContainer>
  );
};
