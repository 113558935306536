import React from "react";
import {SurveyComponentContainer} from "./styles";
import {Widget} from "@typeform/embed-react";
import {PageElement} from "../../../../../../../_shared/generics/page/components/PageElement";
import {
  fullColumnWidths,
  useDynamicValue,
} from "../../../../../../../_shared/hooks/useDynamicValue";
import {usePage} from "../../../../../../../_shared/hooks/usePage";
import {useCurrentCustomer} from "../../../../../../../_shared/hooks/useCurrentCustomer";
import {Customer} from "@natomas-org/core";

export const SurveyContainer = () => {
  const fullWidth = useDynamicValue(fullColumnWidths);
  const {isNatMobile} = usePage();
  const height = isNatMobile ? "36rem" : "46rem";
  const {customer} = useCurrentCustomer();
  if (!customer) {
    return null;
  }

  const email = Customer.getContactEmail(customer);
  const firstName = Customer.getFirstName(customer);

  return (
    <SurveyComponentContainer>
      <PageElement size={fullWidth} height={height}>
        <Widget
          hidden={{email: email, first_name: firstName ?? "there"}}
          id={"HHMmMyHb"}
          style={{width: "100%", height: "100%"}}
          inlineOnMobile={true}
        />
      </PageElement>
    </SurveyComponentContainer>
  );
};
