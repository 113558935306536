import styled from "styled-components";

export const StudioPageWrapper = styled.div<{isMobile: boolean}>`
  display: ${(props) => (props.isMobile ? "block" : "flex")};
  flex-wrap: wrap;
  margin-bottom: ${(props) => (!props.isMobile ? "40px" : "0px")};
`;

export const StudioPageDisplayDescription = styled.div`
  font-style: italic;
  font-size: 0.75rem;
  margin: 0;
  padding: 0.5rem 1rem;
`;

export const CategoryHeader = styled.div`
  width: 100%;
  padding: 2rem 1rem;
`;

export const ResultUnderline = styled.div`
  margin: 33px 0;
  border-bottom: 2px solid #e8e8e8;
  width: 100%;
`;
