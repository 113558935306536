import {ProjectTimelineStage} from "@natomas-org/core";
import {
  PROJECT_SCHEDULE_1,
  PROJECT_SCHEDULE_2,
  PROJECT_SCHEDULE_3,
  PROJECT_SCHEDULE_4,
  PROJECT_SCHEDULE_5,
} from "../styles";

import {INextStepInfo} from "./TimelineTypes";

export const GENERIC_TIMELINE_INFO: INextStepInfo[] = [
  {
    id: "proposal",
    title: ProjectTimelineStage.ProjectProposal,
    subtitle:
      "We provide a ballpark estimate of your project's costs and help you finalize your design selections.",
    details:
      "• Review detailed site plan\n" +
      "• Select floor plan and design\n" +
      "• Approve estimated budget summary\n" +
      "• Complete payment to enter Phase 1",
    color: PROJECT_SCHEDULE_1,
    startDate: new Date(),
    endDate: new Date(),
    milestones: [
      {
        title: "$500 Villa Feasibility Study",
        description:
          "The Villa Feasibility Study includes a review of your site’s specific requirements and a fine-tuned range of anticipated costs for your project.",
        footer: "",
        milestone_index: 1,
        offset_percentage: 0,
      },
    ],
  },
  {
    id: "permitting",
    title: ProjectTimelineStage.PermittingAndDesign,
    subtitle:
      "Our preconstruction team prepares for and submits all permits for your project.",
    details:
      "• Confirm Project Services Agreement (PSA) and scope of work\n" +
      "• Confirm home design\n" +
      "• Prep and submit permit package* \n" +
      "• Prep factory home order",
    color: PROJECT_SCHEDULE_2,
    phase: {
      title: "Phase 1",
      phaseEndDate: new Date(),
      additionalSteps: 1,
      endColor: PROJECT_SCHEDULE_3,
    },
    startDate: new Date(),
    endDate: new Date(),
    milestones: [
      {
        title: "$2–4K Preconstruction Diligence Fee",
        description:
          "The Preconstruction Diligence Fee is for consulting services related to preparing to submit for permits, such as site plans, floor plans, and site visits from electricians and utilities providers.",
        footer: "This unlocks all next steps for your project!",
        milestone_index: 2,
        offset_percentage: 0,
      },
      {
        title: "Permit Preparation and Design Phase Consulting fees",
        description:
          "• This takes care of third-party design and Villa engineering fees associated with surveys, reports and permit preparation specific to your project.\n" +
          "• Timing of payment within the permitting phase varies by jurisdiction.\n",
        footer: "Services provided by third-party vendors are nonrefundable.",
        milestone_index: 3,
        offset_percentage: 50,
      },
    ],
  },
  {
    id: "ATB",
    title: ProjectTimelineStage.AuthorizationToBuild,
    subtitle:
      "We will present the total project costs and details of constructing your home.",
    details:
      "• Permits approved\n" +
      "• Preconstruction buyout finalized\n" +
      "• Authorization to Build (ATB) package presented",
    color: PROJECT_SCHEDULE_3,
    startDate: new Date(),
    endDate: new Date(),
    milestones: [
      {
        title: "Escrow fully funded",
        description:
          "With permits approved and bids secured, we’re almost ready to start building. We will present you with a contract, which includes a final total project price. This prevents any big surprise costs down the road!",
        footer:
          "You receive your final total project price and escrow is fully funded.",
        milestone_index: 4,
        offset_percentage: 95,
      },
    ],
  },
  {
    id: "manufacturing",
    title: ProjectTimelineStage.Manufacturing,
    subtitle:
      "Our factory partner builds your home while we prepare your property for installation.",
    details:
      "• Fully fund escrow \n" +
      "• Permit issuance \n" +
      "• Submit factory order \n" +
      "• Factory home production \n" +
      "• Complete site prep and foundation work",
    color: PROJECT_SCHEDULE_4,
    phase: {
      title: "Phase 2",
      phaseEndDate: new Date(),
      additionalSteps: 1,
      endColor: PROJECT_SCHEDULE_5,
    },
    startDate: new Date(),
    endDate: new Date(),
    milestones: [
      // {
      //   title: "Manufacturing ~40% of the project cost",
      //   description:
      //     "The manufacturing payment secures your production slot. Our factory partner builds your home in a climate controlled environment while we prepare your lot.",
      //   footer: "Home construction begins!",
      //   milestone_index: 4,
      //   offset_percentage: 0,
      // },
    ],
  },
  {
    id: "installation",
    title: ProjectTimelineStage.Installation,
    subtitle:
      "We handle delivery, installation, and the finishing touches of your new home!",
    details:
      "• Home delivery and installation \n" +
      "• Utility hook up \n" +
      "• Complete home finishes \n" +
      "• Final inspection/walkthrough \n" +
      "• Issue certificate of occupancy \n" +
      "• Introduce home warranty program",
    color: PROJECT_SCHEDULE_5,
    startDate: new Date(),
    endDate: new Date(),
    milestones: [
      // {
      //   title: "Installation ~50% of project cost",
      //   description:
      //     "The installation payment covers transportation, install logistics and site prep.",
      //   footer: "Billed upon progress completed.",
      //   milestone_index: 5,
      //   offset_percentage: 0,
      // },
      // {
      //   title: "Closeout ~10% of project cost",
      //   description:
      //     "The closeout payment covers any remaining site work after final inspection is complete.",
      //   footer: "Grab the keys and pop the champagne!",
      //   milestone_index: 6,
      //   offset_percentage: 95,
      // },
    ],
  },
];
export const B2B_TIMELINE_INFO: INextStepInfo[] = [
  {
    ...GENERIC_TIMELINE_INFO[0],
    milestones: [
      {
        title: "Preconstruction Services Payment 1",
        description: "Paid at PSA",
        footer: "",
        milestone_index: 1,
        offset_percentage: 0,
      },
    ],
  },
  {
    ...GENERIC_TIMELINE_INFO[1],
    milestones: [
      {
        title: "Design Phase Services Payment(s)",
        description: "Paid on a cost plus basis",
        footer: "",
        milestone_index: 2,
        offset_percentage: 0,
      },
      {
        title: "Preconstruction Services Payment 2",
        description: "Paid at permit approval",
        footer: "",
        milestone_index: 3,
        offset_percentage: 100,
        top_offset_percentage: 10,
      },
      {
        title: "Permit Fee Payment",
        description: "Paid at permit approval",
        footer: "",
        milestone_index: 4,
        offset_percentage: 100,
        top_offset_percentage: 90,
      },
    ],
  },
  {
    ...GENERIC_TIMELINE_INFO[2],
    milestones: [
      {
        title: "ATB Payment",
        description:
          "ATB Payment: Construction services and unit cost are funded and payments are rolling through the end of the project (less retainage).",
        footer: "",
        milestone_index: 5,
        offset_percentage: 100,
      },
    ],
  },
  {
    ...GENERIC_TIMELINE_INFO[3],
    milestones: [],
  },
  {
    ...GENERIC_TIMELINE_INFO[4],
    milestones: [
      {
        title: "Retainage Payment",
        description: "Final percentage paid at closeout",
        footer: "",
        milestone_index: 6,
        offset_percentage: 100,
      },
    ],
  },
];
export const GENERIC_INVENTORY_TIMELINE_INFO: INextStepInfo[] = [
  {
    ...GENERIC_TIMELINE_INFO[0],
  },
  {
    ...GENERIC_TIMELINE_INFO[1],
    phase: {
      title: "Phase 1",
      phaseEndDate: new Date(),
      additionalSteps: 1,
      endColor: PROJECT_SCHEDULE_2,
    },
  },

  {
    ...GENERIC_TIMELINE_INFO[2],
    milestones: [
      {
        title: "Authorization to Build (ATB) ~40% of the project cost",
        description:
          "The ATB payment covers permit approval fees and preconstruction buyout. We will present the buyout results and a not-to-exceed quote.",
        footer: "Your final quote is locked and escrow is fully funded.",
        milestone_index: 4,
        offset_percentage: 100,
      },
    ],
  },
  {
    ...GENERIC_TIMELINE_INFO[4],
    phase: {
      title: "Phase 2",
      phaseEndDate: new Date(),
      additionalSteps: 0,
      endColor: PROJECT_SCHEDULE_5,
    },
    milestones: [
      {
        title: "Installation ~50% of project cost",
        description:
          "The installation payment covers transportation, install logistics and site prep.",
        footer: "Billed upon progress completed.",
        milestone_index: 5,
        offset_percentage: 35,
      },
      {
        title: "Closeout ~10% of project cost",
        description:
          "The closeout payment covers any remaining site work after final inspection is complete.",
        footer: "Grab the keys and pop the champagne!",
        milestone_index: 6,
        offset_percentage: 95,
      },
    ],
  },
];
