import {getLocationFromIP, ILocationFromIP} from "@natomas-org/service";
import {useQuery} from "@tanstack/react-query";

export const fetchIPSource = async (): Promise<ILocationFromIP | null> => {
  return getLocationFromIP().then((r: ILocationFromIP | null) => {
    return r;
  });
};

export const useIPAddress = () => {
  return useQuery<ILocationFromIP | null, Error>(["ip-location"], () => {
    return fetchIPSource();
  });
};
