import * as turf from "@turf/turf";
import {convertMultipolygonToPolygon} from "@natomas-org/service";

const L = window.L;
export const UNIT_LAYER_PREFIX = "unit";
export const UNIT_BASE_LAYER_NAME = `${UNIT_LAYER_PREFIX}-main`;
export const UNIT_IMAGE_LAYER_NAME = `${UNIT_LAYER_PREFIX}-image`;

/** Gets structures such as the primary residence, garage
 * @returns Array of buildings that are not the ADU
 */
export function getNonAduStructures(mapLayers: any | undefined) {
  if (!mapLayers) {
    return null;
  }
  try {
    const result: any = [];
    const buildingLayers = getNonAduLayers(mapLayers);
    buildingLayers.forEach((layer: any) => {
      const geoJson = convertMultipolygonToPolygon(
        L.polygon(layer.getLatLngs()).toGeoJSON(13)
      );
      result.push(geoJson);
    });
    result.sort((a: any, b: any) => turf.area(b) - turf.area(a));
    return result;
  } catch (e) {
    return false;
  }
}
export function getNonAduLayers(mapLayers: any | undefined) {
  if (!mapLayers.buildings) {
    return null;
  }
  return mapLayers.buildings
    .getLayers()
    .filter((i: any) => i.options.className === "non-adu");
}
export function getCurrentRestrictedZoneLayer(mapLayers: any | undefined) {
  if (!mapLayers?.restrictedZone) {
    return null;
  }
  try {
    const restrictedZoneLayers = mapLayers?.restrictedZone?.getLayers();
    if (restrictedZoneLayers && restrictedZoneLayers?.length > 0) {
      return restrictedZoneLayers[0];
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
}
export function getADUFromMapLayers(mapLayers: any | undefined) {
  if (!mapLayers?.buildings) {
    return null;
  }
  try {
    return mapLayers?.buildings
      ?.getLayers()
      .find((i: any) => i?.options?.className?.includes(UNIT_BASE_LAYER_NAME));
  } catch (e) {
    console.log(e);
    // message.error("Oops! Error loading ADU - please try again later.", 10);
    return null;
  }
}
export function getADUImageFromMapLayers(mapLayers: any | undefined) {
  if (!mapLayers?.buildings) {
    return null;
  }
  try {
    return mapLayers.buildings
      .getLayers()
      .find((i: any) => i?.options?.className?.includes(UNIT_IMAGE_LAYER_NAME));
  } catch (e) {
    console.log(e);
    return null;
  }
}
