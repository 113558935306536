import React, {useEffect, useMemo, useState} from "react";
import {getModifierGroupType, processTypeChanges} from "./helper";
import {
  ModifierGroupTypeSwatch,
  ModifierGroupTypeDefault,
  ModifierGroupTypeMultiSelect,
  ModifierGroupTypeOptions,
  ModifierGroupTypeEmbeddedSwatch,
  ModifierGroupTypeFullText,
} from "./ModifierGroupType";
import Select from "react-select";
import {NatButton} from "../../../../../../../../../../../../_shared/generics/button";
import {NatResetIcon} from "../../../../../../../../../../../../_shared/icon/icons";
import {
  IconPosition,
  NatSize,
  StyleOption,
} from "../../../../../../../../../../../../_shared/generics/_shared";
import {
  InputContainer,
  InputLabelContainer,
  InputLabel,
} from "../../../../../_shared/FactoryInventoryInput/styles";
import _ from "lodash";
interface ModifierGroupSelectorTypeProps {
  id: string;
  updateEdits: (field: string, value?: any) => void;
  edits?: any;
  initialValues?: any;
}

const ModifierGroupSelectorType = (props: ModifierGroupSelectorTypeProps) => {
  const {id, edits, initialValues, updateEdits} = props;

  const initialOption = useMemo(() => {
    const derivedType = getModifierGroupType(edits);
    if (derivedType === "showColorSwatches") {
      return ModifierGroupTypeSwatch;
    }
    if (derivedType === "multiSelectKey") {
      return ModifierGroupTypeMultiSelect;
    }
    if (derivedType === "showFullColor") {
      return ModifierGroupTypeEmbeddedSwatch;
    }
    if (derivedType === "showFullText") {
      return ModifierGroupTypeFullText;
    }
    return ModifierGroupTypeDefault;
  }, [edits]);

  const [selectedOption, setSelectedOption] = useState(initialOption);

  useEffect(() => {
    setSelectedOption(initialOption);
  }, [initialOption]);

  const isEdited = useMemo(() => {
    return !_.isEqual(edits?.properties, initialValues?.properties);
  }, [edits?.properties, initialValues?.properties]);

  return useMemo(() => {
    return (
      <InputContainer key={id} isEdited={isEdited}>
        <InputLabelContainer>
          <InputLabel htmlFor={id}>Type</InputLabel>
          <NatButton
            hidden={!isEdited}
            label={"reset"}
            clickEvent={() =>
              updateEdits("properties", initialValues?.properties)
            }
            icon={{icon: <NatResetIcon />, iconPosition: IconPosition.ONLY}}
            size={NatSize.XSMALL}
            option={StyleOption.PRIMARY_BLACK}
          />
        </InputLabelContainer>
        <Select
          id={`${id}-type-selector`}
          options={ModifierGroupTypeOptions}
          defaultValue={initialOption}
          value={selectedOption}
          onChange={(option) => {
            if (!!option) {
              setSelectedOption(option);
              processTypeChanges(option?.value, updateEdits);
            }
          }}
        />
      </InputContainer>
    );
  }, [
    id,
    initialOption,
    initialValues?.properties,
    isEdited,
    selectedOption,
    updateEdits,
  ]);
};

export default ModifierGroupSelectorType;
